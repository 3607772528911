import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import { useParams } from "react-router-dom";
import {
  JobPriority,
  JobTypeSelection,
  fetchIntegrationModulesProto,
  fetchJob,
  fetchMachinePrograms,
  fetchSimplifiedProcessTypes,
  fetchSimplifiedProcessTypesProto,
  fetchWorkOrdersProtoMongo,
  filterWorkOrderByDateRange,
  makeDateString,
  taskHasModule,
} from "../../../helpers";
import { Job, JobMapped } from "../../../models/model";
import {
  MeetingTaskListsView,
  PpicIntegrationModules,
  PpicMachinePrograms,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import chroma from "chroma-js";
import ColorHash from "color-hash";

export type JobInfoTab = "WO Calendar" | "Dept Template";
export const jobInfoTabs = ["WO Calendar", "Dept Template"] as JobInfoTab[];

const JobInfoPage = () => {
  const ctx = useContext(AppContext);

  const { id } = useParams() as { id: any };

  useEffect(() => {
    handleInit();
  }, []);

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const simplifiedProcessTypes = useRef(
    PpicSimplifiedProcessTypes.fromPartial({})
  );
  const integrationModulesProto = useRef(
    PpicIntegrationModules.fromPartial({})
  );
  const machineProgramsProto = useRef(
    PpicMachinePrograms.fromPartial({ machinePrograms: [] })
  );

  const curDate = useRef(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  const datePad = curDate.current.getDay();
  const monthEnd = new Date(
    curDate.current.getFullYear(),
    curDate.current.getMonth() + 1,
    0
  ).getDate();

  const paddedDate = [
    ...[...Array(datePad)].map((_) => null),
    ...[...Array(monthEnd)].map((_, i) => i + 1),
  ];
  const woDetailsModalShowDate = useRef(new Date());

  const chunkedDate = [...Array(6)]
    .map((_, i) => i)
    .map((n) => {
      return paddedDate.slice(n * 7, (n + 1) * 7);
      // return [n * 7, (n + 1) * 7 - 1];
    });

  const tab = useRef("WO Calendar" as JobInfoTab);
  const job = useRef(
    null as {
      jobPriority: JobPriority;
      jobTypeSelections: JobTypeSelection[] | undefined;
      job: JobMapped;
    } | null
  );
  const wos = useRef(MeetingTaskListsView.fromPartial({}));

  const handleInit = () => {
    fetchJobData();
    fetchWosData();
    fetchProgramsDataProto();
    fetchPpicIntegrationModulesData();
    fetchSimplifiedProcessTypesData();
  };
  const fetchJobData = async () => {
    const d = await fetchJob({ id: id, apiKey: ctx?.apiKey ?? "" });

    if (d) {
      job.current = d;
      render();
    }
  };

  const fetchProgramsDataProto = async () => {
    const d = await fetchMachinePrograms({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      machineProgramsProto.current = d;
      render();
    }
  };

  const fetchPpicIntegrationModulesData = async () => {
    const d = await fetchIntegrationModulesProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      integrationModulesProto.current = d;
      render();
    }
  };

  const fetchSimplifiedProcessTypesData = async () => {
    const d = await fetchSimplifiedProcessTypesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      simplifiedProcessTypes.current = d;
      render();
    }
  };

  const woDetailsModalShow = useRef(false);

  const fetchWosData = async () => {
    const d = await fetchWorkOrdersProtoMongo({ extJobId: id });

    if (d) {
      wos.current = d;
      render();
    }
  };

  return (
    <>
      <div className="m-3">
        <div>
          <div>
            <h4>Job Info: {job.current?.job.job?.name}</h4>
          </div>
          <hr className="border border-dark" />
          <div className="d-flex">
            {jobInfoTabs.map((j) => {
              return (
                <>
                  <div className="mx-2">
                    <button
                      onClick={() => {
                        tab.current = j;
                        render();
                      }}
                      className={`btn btn-sm ${
                        tab.current === j
                          ? `btn-primary`
                          : `btn-outline-primary`
                      }`}
                    >
                      {j}
                    </button>
                  </div>
                </>
              );
            })}
          </div>
          <div>
            <hr className="border border-dark" />
          </div>
          <div className="d-flex">
            <button
              onClick={() => {
                curDate.current = new Date(
                  curDate.current.getFullYear(),
                  curDate.current.getMonth() - 1,
                  curDate.current.getDate()
                );
                render();
              }}
              className="btn btn-sm btn-primary px-1 py-0 mx-2"
            >
              <ChevronLeft />
              Prev
            </button>
            <button
              onClick={() => {
                curDate.current = new Date(
                  curDate.current.getFullYear(),
                  curDate.current.getMonth() + 1,
                  curDate.current.getDate()
                );
                render();
              }}
              className="btn btn-sm btn-primary px-1 py-0 mx-2"
            >
              <ChevronRight />
              Next
            </button>
          </div>
          <div>
            {tab.current === "WO Calendar" ? (
              <>
                <div>
                  <div>
                    Date ref:{" "}
                    {Intl.DateTimeFormat("en-US", {
                      dateStyle: "full",
                    } as any).format(curDate.current)}{" "}
                    (pad: {datePad}){/* = {JSON.stringify(paddedDate)} */}
                  </div>
                  {/* <div>
                    <pre>{JSON.stringify(chunkedDate, null, 2)}</pre>
                  </div> */}
                </div>

                <div>
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr className="">
                      {["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"].map(
                        (h, i) => {
                          return (
                            <>
                              <th
                                className={`${
                                  i === 0 || i === 6 ? `bg-danger` : `bg-dark`
                                } text-light`}
                              >
                                {h}
                              </th>
                            </>
                          );
                        }
                      )}
                    </tr>
                    {chunkedDate.map((c) => {
                      return (
                        <>
                          <tr>
                            {c.map((d) => {
                              if (d) {
                                const date = new Date(
                                  curDate.current.getFullYear(),
                                  curDate.current.getMonth(),
                                  d
                                );

                                const wosData = filterWorkOrderByDateRange(
                                  wos.current,
                                  makeDateString(date),
                                  makeDateString(date),
                                  null
                                );

                                return (
                                  <td
                                    className={`border border-dark ${
                                      makeDateString(date) ===
                                      `${makeDateString(new Date())}`
                                        ? `bg-info`
                                        : `bg-light`
                                    }`}
                                  >
                                    <div className="d-flex align-items-end">
                                      <div>
                                        <h4>
                                          {d}
                                          {/* {makeDateString(date)} */}
                                        </h4>
                                      </div>
                                      <div
                                        className="text-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          woDetailsModalShow.current = true;
                                          woDetailsModalShowDate.current = date;
                                          render();
                                        }}
                                      >
                                        {(wosData?.length ?? 0) > 0
                                          ? ` (${wosData?.length})`
                                          : ``}
                                      </div>
                                    </div>
                                  </td>
                                );
                              }

                              return (
                                <>
                                  <td className="border border-dark"></td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={woDetailsModalShow.current}
        onHide={() => {
          woDetailsModalShow.current = false;
          render();
        }}
      >
        <ModalHeader>
          <div>
            <h4>
              WO Detail{" "}
              {(() => {
                try {
                  return Intl.DateTimeFormat("en-US", {
                    dateStyle: "full",
                  } as any).format(woDetailsModalShowDate.current);
                } catch (e) {
                  return "";
                }
              })()}
            </h4>
          </div>
        </ModalHeader>
        <ModalBody>
          {(() => {
            const wosData = filterWorkOrderByDateRange(
              wos.current,
              makeDateString(woDetailsModalShowDate.current),
              makeDateString(woDetailsModalShowDate.current),
              null
            );

            return (
              <>
                {" "}
                <div
                  className="overflow-auto"
                  style={{ height: "50vh", resize: "vertical" }}
                >
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    {[
                      "#",
                      "WOID",
                      "Task",
                      "Module",
                      "Program",
                      "Prog.Prcs",

                      `Qty`,
                      "Duration",
                      "SPV",
                      "PIC",
                      "Status",
                    ].map((h) => (
                      <>
                        <th
                          className="bg-dark text-light p-0 m-0"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    ))}
                    {wosData
                      ?.flatMap((tL) =>
                        tL.taskList?.meetingTasks.map((mt) => ({
                          taskList: tL,
                          meetingTask: mt,
                        }))
                      )
                      .map((d, i) => {
                        return (
                          <>
                            <tr>
                              <td className="border border-dark p-0 m-0">
                                {i + 1}
                              </td>
                              <td
                                className="border border-dark p-0 m-0"
                                style={{
                                  backgroundColor: new ColorHash({
                                    lightness: 0.8,
                                  }).hex(
                                    d?.taskList.taskList?.masterJavaBaseModel
                                      ?.id ?? ""
                                  ),
                                }}
                              >
                                {d?.taskList.taskList?.masterJavaBaseModel?.id}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {d?.meetingTask.description}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                <div className="d-flex">
                                  {(() => {
                                    const label = (() => {
                                      if (
                                        d?.taskList.taskList?.extSetId &&
                                        d?.taskList.taskList?.extSetId !==
                                          "0" &&
                                        d?.taskList.taskList?.extSetId !== ""
                                      ) {
                                        return "SET";
                                      } else if (
                                        d?.taskList.taskList?.extModuleId &&
                                        d?.taskList.taskList?.extModuleId !==
                                          "0" &&
                                        d?.taskList.taskList?.extModuleId !== ""
                                      ) {
                                        return "MOD";
                                      } else if (
                                        d?.taskList.taskList?.extModuleId &&
                                        d?.taskList.taskList?.extSubModuleId !==
                                          "0" &&
                                        d?.taskList.taskList?.extSubModuleId !==
                                          ""
                                      ) {
                                        return "SUBM";
                                      } else {
                                        return "";
                                      }
                                    })();

                                    return label ? (
                                      <>
                                        <div
                                          className="rounded rounded-md px-1 py-0"
                                          style={{
                                            backgroundColor: new ColorHash({
                                              lightness: 0.7,
                                            }).hex(label),
                                          }}
                                        >
                                          {label}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    );
                                  })()}
                                  <div>
                                    {
                                      integrationModulesProto.current.modules.find(
                                        (m) =>
                                          `${m.masterJavaBaseModel?.id}` ===
                                            `${d?.taskList.taskList?.extSetId}` ||
                                          `${m.masterJavaBaseModel?.id}` ===
                                            `${d?.taskList.taskList?.extModuleId}` ||
                                          `${m.masterJavaBaseModel?.id}` ===
                                            `${d?.taskList.taskList?.extSubModuleId}`
                                      )?.name
                                    }
                                  </div>
                                </div>
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {
                                  machineProgramsProto.current.machinePrograms.find(
                                    (p) =>
                                      `${p.masterJavaBaseModel?.id}` ===
                                      `${d?.taskList.taskList?.extProgramId}`
                                  )?.name
                                }
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {d?.taskList.taskList?.extProgramRunQty}
                                {d?.taskList.taskList?.extIntegrationModuleQty}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {
                                  simplifiedProcessTypes.current.processTypes.find(
                                    (t) =>
                                      `${t.masterJavaBaseModel?.id}` ===
                                      `${d?.taskList.taskList?.extProcessTypeId}`
                                  )?.name
                                }
                              </td>

                              <td className="border border-dark p-0 m-0">
                                {(() => {
                                  if (taskHasModule(d?.taskList)) {
                                    return `${d?.taskList.taskList?.totalTimeHours?.toFixed(
                                      1
                                    )} h`;
                                  } else {
                                    return `${(
                                      (d?.meetingTask.durationMins ?? 0) / 60
                                    ).toFixed(1)} h`;
                                  }
                                })()}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {
                                  ctx?.extUsers.find(
                                    (u) =>
                                      `${u.id}` ===
                                      `${d?.taskList.taskList?.extInChargeId}`
                                  )?.username
                                }
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {d?.meetingTask.meetingTaskInCharges
                                  .map((c) => {
                                    return `${
                                      ctx?.extUsers.find(
                                        (u) => `${u.id}` === `${c.extUserId}`
                                      )?.username
                                    }`;
                                  })
                                  .join(", ")}
                              </td>
                              <td
                                className="border border-dark p-0 m-0"
                                style={{
                                  backgroundColor: (() => {
                                    switch (d?.meetingTask.status) {
                                      case "OUTSTANDING":
                                        return "lightsalmon";
                                      case "COMPLETED":
                                        return "lightgreen";
                                      default:
                                        return "";
                                    }
                                  })(),
                                }}
                              >
                                {d?.meetingTask.status}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </table>
                </div>
              </>
            );
          })()}
        </ModalBody>
      </Modal>
    </>
  );
};

export default JobInfoPage;
