import { Box, Button, TextField } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CheckRequestStatus } from "../../../helpers";
import { Warehouse } from "../../../models/model";
import { initialWarehouse } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const WarehouseForm = () => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [warehouse, setWarehouse] = useState<Warehouse>({
    ...initialWarehouse,
  });

  const { id } = useParams() as any;

  useEffect(() => {
    const parsedId = parseInt(id ? id : "");

    if (!isNaN(parsedId)) {
      fetchWarehouseData(parsedId);
    }
  }, []);

  const fetchWarehouseData = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/warehouses/${id}`
      );

      if (response.status !== 200)
        throw "fetching warehouse " + id.toString() + " error";

      setWarehouse(await response.json());
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <Box>
      <Box display="flex">
        <Box>
          <Link to="/warehouses">
            <Button color="primary">
              <ChevronLeftIcon />
              Back
            </Button>
          </Link>
        </Box>

        <Button color="primary" variant="contained">
          Save
        </Button>

        <CheckRequestStatus requestStatus={requestStatus} />
      </Box>

      <Box my={1}>
        <TextField
          label="Name"
          value={warehouse.name}
          onChange={(evt) =>
            setWarehouse({ ...warehouse, name: evt.target.value })
          }
        />
      </Box>
    </Box>
  );
};

export default WarehouseForm;
