import React, { useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  extractItemDesc,
  fetchBomLeveled,
  fetchBomLeveledsProtoDetailedJob,
  fetchExtCrmPurchaseOrdersProto,
  fetchExtInventory,
  fetchJobFull,
  fetchMaterialRequests,
  fetchMRByJobProduct,
  fetchWarehouseItemsProto,
  intlFormat,
  JobPriority,
  JobTypeSelection,
} from "../../../helpers";
import {
  CrmPurchaseOrders,
  PpicBomLeveled,
  WarehouseItem,
  WarehouseItems,
} from "../../../masterbigsystem";
import {
  ExtInventory,
  Job,
  JobMapped,
  MaterialRequest,
} from "../../../models/model";
import { Print } from "@material-ui/icons";

type ViewMode = "Bom" | "MR History";

const CostReportPage = () => {
  const ctx = useContext(AppContext);
  const { id } = useParams() as { id: string };

  const [job, setJob] = useState<{
    jobPriority: JobPriority;
    jobTypeSelections: JobTypeSelection[] | undefined;
    job: JobMapped;
  } | null>(null);
  const [items, setItems] = useState<WarehouseItems>(
    WarehouseItems.fromPartial({ items: [] })
  );
  const [materialRequests, setMaterialRequests] = useState<MaterialRequest[]>(
    []
  );
  const [inventory, setInventory] = useState<ExtInventory[]>([]);

  const [loading, setLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [materialRequestsLoading, setMaterialRequestsLoading] = useState(false);
  const [inventoryLoading, setInventoryLoading] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>("Bom");
  const [jobBomLeveled, setJobBomLeveled] = useState(
    PpicBomLeveled.fromPartial({})
  );
  const mrByJobProduct = useRef([] as MaterialRequest[]);
  const pos = useRef(CrmPurchaseOrders.fromPartial({}));

  const printMode = useRef(false);
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchFullJobData();
    fetchItemsData();
    fetchMaterialRequestsData();
    fetchExtInventoryData();
    fetchExtCrmPurchaseOrdersData();
    fetchExtMrbyJobProductData();
  };

  const fetchExtInventoryData = async () => {
    setInventoryLoading(true);
    setInventory(
      await fetchExtInventory({ apiKey: ctx?.apiKey ?? "", all: true })
    );
    setInventoryLoading(false);
  };

  const fetchExtCrmPurchaseOrdersData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
      render();
    }
  };
  const fetchExtMrbyJobProductData = async () => {
    if (!isNaN(parseInt(id))) {
      const d = await fetchMRByJobProduct({
        apiKey: ctx?.apiKey ?? "",
        jobId: id,
      });

      if (d) {
        mrByJobProduct.current = d;
        render();
      }
    }
  };

  const fetchFullJobData = async () => {
    setLoading(true);

    const j = await fetchJobFull({ id: id, apiKey: ctx?.apiKey ?? "" });
    setJob(j);

    if (j?.job.job?.id) {
      fetchJobBomLeveledData(j.job.job.id);
    }

    setLoading(false);
  };

  const fetchJobBomLeveledData = async (jobId: any) => {
    setJobBomLeveled(
      await fetchBomLeveledsProtoDetailedJob({
        jobId: jobId,
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const fetchItemsData = async () => {
    setItemsLoading(true);
    setItems(await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" }));
    setItemsLoading(false);
  };
  const fetchMaterialRequestsData = async () => {
    setMaterialRequestsLoading(true);
    setMaterialRequests(
      await fetchMaterialRequests({ apiKey: ctx?.apiKey ?? "", extJobId: id })
    );
    setMaterialRequestsLoading(false);
  };

  const mrItemsFlattened = [
    ...new Set(
      materialRequests
        .flatMap((m) => m.materialRequestItems ?? [])
        .map((mi) => mi.extItemId)
        .filter((mi) => mi)
        .map((mi) => mi as number)
    ),
  ].map((extItemId) => {
    const fItem = items?.items?.find((i) => `${i.id}` === `${extItemId}`);
    const mrQty = materialRequests
      .flatMap((mr) => mr.materialRequestItems ?? [])
      .filter((mri) => `${mri.extItemId}` === `${extItemId}`)
      .reduce((acc, mri) => acc + (mri.qty ?? 0), 0.0);
    const mrNums = materialRequests.filter((mr) =>
      mr.materialRequestItems?.find(
        (mri) => `${mri.extItemId}` === `${extItemId}`
      )
    ).length;

    return { item: fItem, qty: mrQty, mrNums: mrNums };
  });

  const itemsNotInBom = [
    ...new Set(
      mrByJobProduct.current
        .flatMap((m) => m.materialRequestItems)

        .filter((i) => {
          const foundJBL = jobBomLeveled.children.find(
            (cx) => `${cx.extItemId}` === `${i?.extItemId}`
          );

          return !foundJBL;
        })
        .map((i) => `${i?.extItemId}`)
    ),
  ].map((itemId) => {
    const foundMRs = mrByJobProduct.current.filter((m) =>
      m.materialRequestItems?.find((i) => `${i.extItemId}` === `${itemId}`)
    );

    console.log("found mr id", itemId, foundMRs);

    return {
      itemId: itemId,
      mrs: foundMRs,
      total: foundMRs
        .flatMap((m) => m.materialRequestItems)
        .filter((i) => `${i?.extItemId}` === `${itemId}`)
        .reduce((acc, i) => acc + (i?.qty ?? 0.0), 0.0),
    };
  });

  const totalMrPriceCountByMR = mrByJobProduct.current
    .flatMap((m) => m.materialRequestItems)
    .reduce((acc, i) => {
      const foundPrice =
        inventory.find((ix) => `${ix.productId}` === `${i?.extItemId}`)
          ?.priceRp ?? 0;
      return acc + foundPrice * (i?.qty ?? 0);
    }, 0.0);

  const priceAuth =
    ctx?.gspeAppUser?.roles.find(
      (r) =>
        r.name?.toLowerCase().includes("finance") ||
        r.name?.toLowerCase().includes("admin")
    ) || ctx?.gspeAppUser?.username?.toLowerCase().includes("admin");

  return (
    <>
      <div>
        <div>
          <div className="d-flex">
            <h5>
              Cost report for job:{" "}
              {loading ? (
                <>
                  <Spinner animation="border" />
                </>
              ) : (
                <>{job?.job.job?.name}</>
              )}
            </h5>
            <div>
              {itemsLoading ? (
                <>
                  <Spinner animation="border" /> Items data loading...
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              {materialRequestsLoading ? (
                <>
                  <Spinner animation="border" /> MR data loading...
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              {inventoryLoading ? (
                <>
                  <Spinner animation="border" /> Price data loading...
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {mrByJobProduct.current.flatMap((m) => m.materialRequestItems).length}{" "}
        item(s) total used in MR.
        <hr className="border border-dark" />
        <div>
          <strong>POs:</strong>
        </div>
        <div>
          <ol>
            {job?.job.job?.jobPurchaseOrders?.map((p) => {
              const foundPO = pos.current.purchaseOrders.find(
                (px) => `${px.id}` === `${p.extPurchaseOrderId}`
              );
              return (
                <>
                  <li>
                    <div>
                      <div>
                        {foundPO?.purchaseOrderNumber} ({foundPO?.account?.name}
                        )
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>
        <hr className="border border-dark" />
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              printMode.current = true;
              render();

              setTimeout(() => {
                window.print();
              }, 1000);
            }}
          >
            <Print /> Print
          </button>
        </div>
        <div className="d-flex">
          <button
            className={`${
              viewMode === "Bom" ? `btn btn-primary` : `btn-outline-primary`
            }`}
            onClick={() => {
              setViewMode("Bom");
            }}
          >
            BOM
          </button>
          <button
            className={`${
              viewMode === "MR History"
                ? `btn btn-primary`
                : `btn-outline-primary`
            }`}
            onClick={() => {
              setViewMode("MR History");
            }}
          >
            MR
          </button>
        </div>
        <>
          <div>
            <ol>
              {job?.job.jobBomLeveleds
                ?.filter((l) => !l.jobBomLeveled?.hidden)
                ?.map((l) => {
                  return (
                    <>
                      <li>
                        <div>
                          <div>
                            {l.jobBomLeveled?.bomLeveled?.name} (x
                            {l.jobBomLeveled?.qty})
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
            </ol>
          </div>
        </>
        {(() => {
          switch (viewMode) {
            case "Bom":
              const totalBomPrice = jobBomLeveled.children.reduce((acc, c) => {
                const iPrice =
                  inventory.find((ix) => `${ix.productId}` === `${c.extItemId}`)
                    ?.priceRp ?? 0;

                const itemPrice = isNaN(parseFloat(`${iPrice}` ?? ""))
                  ? 0.0
                  : parseFloat(`${iPrice}` ?? "");

                return acc + (c.qty ?? 0) * (itemPrice ?? 0);
              }, 0);

              const totalMrPrice = jobBomLeveled.children.reduce((acc, c) => {
                const iPrice = inventory.find(
                  (ix) => `${ix.productId}` === `${c.extItemId}`
                )?.priceRp;

                const itemPrice = isNaN(parseFloat(`${iPrice}` ?? ""))
                  ? 0.0
                  : parseFloat(`${iPrice}` ?? "");

                return acc + (c.inMr ?? 0) * (itemPrice ?? 0);
              }, 0);

              return (
                <>
                  <div
                    className={`${
                      printMode.current ? `` : `overflow-auto`
                    } shadow shadow-md border border-dark`}
                    style={{
                      height: printMode.current ? undefined : "75vh",
                      resize: "vertical",
                    }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      {[
                        `#`,
                        `Item`,
                        `Qty`,
                        `In MR`,
                        `Variance`,
                        // `Orig. Curr`,
                        `Individual Price`,
                        <>
                          <div>
                            <div>BOM Price</div>
                            {priceAuth ? (
                              <>
                                {" "}
                                <div>
                                  (Total{" "}
                                  {Intl.NumberFormat(
                                    navigator.language ?? "id-ID",
                                    { style: "currency", currency: "IDR" }
                                  ).format(totalBomPrice)}
                                  )
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>,
                        <>
                          <div>
                            <div>MR Price</div>
                            {priceAuth ? (
                              <>
                                <div>
                                  (Total{" "}
                                  {Intl.NumberFormat(
                                    navigator.language ?? "id-ID",
                                    { style: "currency", currency: "IDR" }
                                    // ).format(totalMrPrice)}
                                  ).format(totalMrPriceCountByMR)}
                                  )
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </>,
                        `UM`,
                      ].map((h) => (
                        <>
                          <th
                            className={`${
                              printMode.current
                                ? `border border-dark`
                                : `bg-dark text-light`
                            } p-0 m-0`}
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        </>
                      ))}
                      {jobBomLeveled.children.map((c, i) => {
                        const foundItem = items.items.find(
                          (ix) => `${ix.id}` === `${c.extItemId}`
                        );
                        const iPrice =
                          inventory.find(
                            (ix) => `${ix.productId}` === `${c.extItemId}`
                          )?.priceRp ?? 0;

                        const itemPrice = isNaN(parseFloat(`${iPrice}` ?? ""))
                          ? 0.0
                          : parseFloat(`${iPrice}` ?? "");

                        return (
                          <>
                            <tr>
                              <td className="border border-dark p-0 m-0">
                                {i + 1}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {itemsLoading ? (
                                  <>Loading...</>
                                ) : (
                                  <>
                                    {extractItemDesc(foundItem, {
                                      skipLowercase: true,
                                    })}
                                  </>
                                )}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                <strong>{c.qty?.toFixed(1)}</strong>
                              </td>

                              <td
                                className={`border border-dark p-0 m-0 ${(() => {
                                  if (c.qty === 0) {
                                    return `bg-danger text-light`;
                                  } else if ((c.inMr ?? 0) < (c.qty ?? 0)) {
                                    return `bg-danger text-light`;
                                  } else if ((c.inMr ?? 0) > (c.qty ?? 0)) {
                                    return `bg-warning text-dark`;
                                  } else if ((c.inMr ?? 0) === (c.qty ?? 0)) {
                                    return `bg-success text-light`;
                                  } else {
                                    return ``;
                                  }
                                })()}`}
                              >
                                <strong>{c.inMr}</strong>
                              </td>
                              <td className="border border-dark p-0 m-0">
                                <strong>
                                  {((c.qty ?? 0) - (c.inMr ?? 0)).toFixed(1)}
                                </strong>
                              </td>

                              {/* <td className="border border-dark p-0 m-0">
                                {c.priceOriginalCurrency}
                              </td> */}
                              <td className="border border-dark p-0 m-0">
                                {priceAuth ? (
                                  <>
                                    <strong>
                                      {" "}
                                      {Intl.NumberFormat(
                                        navigator.language ?? "id-ID",
                                        { style: "currency", currency: "IDR" }
                                      ).format(itemPrice ?? 0)}
                                    </strong>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {priceAuth ? (
                                  <>
                                    {" "}
                                    {Intl.NumberFormat(
                                      navigator.language ?? "id-ID",
                                      { style: "currency", currency: "IDR" }
                                    ).format((c.qty ?? 0) * (itemPrice ?? 0))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {priceAuth ? (
                                  <>
                                    {Intl.NumberFormat(
                                      navigator.language ?? "id-ID",
                                      { style: "currency", currency: "IDR" }
                                    ).format((c.inMr ?? 0) * (itemPrice ?? 0))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {foundItem?.defaultUm}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                      {/* Items not in BOM */}
                      {itemsNotInBom.map((c, i) => {
                        const foundItem = items.items.find(
                          (ix) => `${ix.id}` === `${c.itemId}`
                        );
                        const iPrice =
                          inventory.find(
                            (ix) => `${ix.productId}` === `${c.itemId}`
                          )?.priceRp ?? 0;

                        const itemPrice = isNaN(parseFloat(`${iPrice}` ?? ""))
                          ? 0.0
                          : parseFloat(`${iPrice}` ?? "");

                        return (
                          <>
                            <tr className="bg-warning">
                              <td className="border border-dark p-0 m-0">
                                {i + 1}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {itemsLoading ? (
                                  <>Loading...</>
                                ) : (
                                  <>
                                    {extractItemDesc(foundItem, {
                                      skipLowercase: true,
                                    })}
                                  </>
                                )}
                              </td>
                              <td className="border border-dark p-0 m-0"></td>

                              <td className="border border-dark p-0 m-0">
                                <strong>{c.total}</strong>
                              </td>

                              <td className="border border-dark p-0 m-0"></td>

                              {/* <td className="border border-dark p-0 m-0">
                                {c.priceOriginalCurrency}
                              </td> */}
                              <td className="border border-dark p-0 m-0">
                                <strong>
                                  {priceAuth ? (
                                    <>
                                      {Intl.NumberFormat(
                                        navigator.language ?? "id-ID",
                                        { style: "currency", currency: "IDR" }
                                      ).format(itemPrice ?? 0)}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </strong>
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {/* {Intl.NumberFormat(
                                  navigator.language ?? "id-ID",
                                  { style: "currency", currency: "IDR" }
                                ).format((c.total ?? 0) * (itemPrice ?? 0))} */}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {priceAuth ? (
                                  <>
                                    {Intl.NumberFormat(
                                      navigator.language ?? "id-ID",
                                      { style: "currency", currency: "IDR" }
                                    ).format((c.total ?? 0) * (itemPrice ?? 0))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {foundItem?.defaultUm}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </>
              );
            case "MR History":
              return (
                <>
                  <div>
                    <strong>MR History</strong>
                  </div>

                  <div>
                    <div
                      className={`${
                        printMode.current ? `` : `overflow-auto`
                      } shadow shadow-md border border-dark`}
                      style={{
                        height: "55vh",
                        resize: "vertical",
                      }}
                    >
                      <table
                        className="table table-sm"
                        style={{ borderCollapse: "separate" }}
                      >
                        {[
                          `#`,
                          "MR",
                          "MR Date",
                          `MFR`,
                          "PN",
                          "PartName",
                          "PartDesc",
                          `Qty`,
                          "WH",
                          `UM`,
                        ].map((h) => (
                          <>
                            <th
                              className={`${
                                printMode.current
                                  ? `border border-dark`
                                  : `bg-dark text-light`
                              } p-0 m-0`}
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          </>
                        ))}
                        {mrByJobProduct.current
                          .flatMap((m) =>
                            m.materialRequestItems?.map((i) => ({
                              mr: m,
                              item: i,
                            }))
                          )
                          .map((m, i) => {
                            const foundItem = items.items.find(
                              (ix) => `${ix.id}` === `${m?.item.extItemId}`
                            );
                            const foundInv = inventory.find(
                              (ix) => `${ix.id}` === `${m?.item.extInventoryId}`
                            );

                            return (
                              <>
                                <>
                                  <tr>
                                    <td className="border border-dark p-0 m-0">
                                      {i + 1}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      MR/PPIC/
                                      {new Date(
                                        m?.mr.createdAt ?? ""
                                      ).getFullYear()}
                                      /{m?.mr.id}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {intlFormat({
                                        date: m?.mr.neededDate ?? "",
                                        dateStyle: "long",
                                      })}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {foundItem?.mfr}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {foundItem?.partNum}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {foundItem?.partName}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {foundItem?.partDesc}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {m?.item.qty.toFixed(1)}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {foundInv?.warehouse?.warehouse?.name}:
                                      {foundInv?.warehouse?.whName}
                                    </td>

                                    <td className="border border-dark p-0 m-0">
                                      {foundItem?.defaultUm}
                                    </td>
                                  </tr>
                                </>
                              </>
                            );
                          })}
                      </table>
                    </div>
                  </div>
                </>
              );
          }
        })()}
      </div>
    </>
  );
};
export default CostReportPage;
