import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { CheckRequestStatus } from "../../../helpers";
import { ExtInventory } from "../../../models/model";
import { RequestStatus } from "../../../models/RequestStatus";

const WarehouseSummaryPage = () => {
  const apiKey = localStorage.getItem("apiKey");

  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    requestStatus: RequestStatus.NotAsked,
    inventoryList: [] as Array<ExtInventory> | undefined,
    partNumberSearch: "",
    partNameSearch: "",
    partDescSearch: "",
  });

  useEffect(() => {
    fetchInventory();
  }, []);

  const fetchInventory = async () => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ext-inventory`,
        {
          headers: {
            authorization: state.apiKey,
          },
        }
      );

      const inventoryData = (await response.json()) as ExtInventory[];

      setState({
        ...state,
        requestStatus: RequestStatus.Success,
        inventoryList: inventoryData,
      });
    } catch (e) {
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };
  // useEffect(() => {
  //   fetchPageData(`
  //     ${process.env.REACT_APP_BASE_URL}/warehousesummary/search/paged?name=${props.pageData.searchInput}&warehouseName=${props.warehouseSummaryData.warehouseName}&page=${props.pageData.currentPage}&perPage=${props.pageData.rowsPerPage}`)
  // }, [])

  return (
    <div>
      <div className="d-flex align-items-center">
        <h3>Warehouse Summary</h3>
        <div className="ml-2">
          <CheckRequestStatus requestStatus={state.requestStatus} />
        </div>
      </div>
      <div className="d-flex flex-wrap flex-lg-nowrap my-2">
        <Form.Control
          className="mx-1"
          placeholder="Search by part number.."
          onChange={(e) =>
            setState({ ...state, partNumberSearch: e.target.value })
          }
        />
        <Form.Control
          className="mx-1"
          placeholder="Search by part name.."
          onChange={(e) =>
            setState({ ...state, partNameSearch: e.target.value })
          }
        />
        <Form.Control
          className="mx-1"
          placeholder="Search by part desc.."
          onChange={(e) =>
            setState({ ...state, partDescSearch: e.target.value })
          }
        />
      </div>
      <div
        style={{
          height: "60vh",
          resize: "vertical",
          border: "2px solid gray ",
          overflow: "auto",
        }}
      >
        <Table size="sm" bordered style={{ borderCollapse: "separate" }}>
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "darkblue",
              }}
            >
              <th className="text-white text-center">Manufacturer</th>
              <th className="text-white text-center">GSPE Part Number</th>
              <th className="text-white text-center">Part Name</th>
              <th className="text-white text-center">Part Desc</th>
              <th className="text-white text-center">WH</th>

              <th className="text-white text-center">Qty</th>
              <th className="text-white text-center">UM</th>
              {/* <th className="text-white text-center">Warehouse</th> */}
            </tr>
          </thead>
          <tbody>
            {state.inventoryList
              ?.filter((inventory) => {
                return (
                  inventory.products?.partNum
                    ?.toLowerCase()
                    .includes(state.partNumberSearch.trim().toLowerCase()) &&
                  inventory.products.partName
                    ?.toLowerCase()
                    .includes(state.partNameSearch.trim().toLowerCase()) &&
                  inventory.products.partDesc
                    ?.toLowerCase()
                    .includes(state.partDescSearch.trim().toLowerCase())
                );
              })
              .map((inventory) => {
                return (
                  <tr>
                    <td>{inventory.products?.mfr}</td>
                    <td>{inventory.products?.partNum}</td>
                    <td>{inventory.products?.partName}</td>
                    <td>{inventory.products?.partDesc}</td>
                    <td>
                      {inventory.warehouse?.warehouse?.name}:
                      {inventory.warehouse?.whName}
                    </td>

                    <td>{inventory.qty}</td>
                    <td>{inventory.products?.defaultUm}</td>
                    {/* <td>{inventory.warehouse?.name}</td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default WarehouseSummaryPage;
