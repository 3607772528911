import { Add, WhatshotSharp } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchWorkingHoursProto } from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicWorkingHour,
  PpicWorkingHours,
} from "../../../masterbigsystem";

const WorkingHourPage = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [workingHours, setWorkingHours] = useState(
    PpicWorkingHours.fromPartial({ workingHours: [] })
  );

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchWorkingHoursProtoData();
  };

  const fetchWorkingHoursProtoData = async () => {
    setLoading(true);
    const ret = await fetchWorkingHoursProto({ apiKey: ctx?.apiKey ?? "" });

    if (ret) {
      setWorkingHours(ret);
    }

    setLoading(false);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/workinghours-proto`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(PpicWorkingHours.toJSON(workingHours)),
        }
      );

      window.location.reload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const setWorkingHourHour = () => {};
  const setWorkingHourMinute = () => {};

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>Working Hour</h4>
          </div>
          <div>
            {loading ? (
              <>
                <div>Loading...</div>
              </>
            ) : (
              <>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setWorkingHours({
                ...workingHours,
                workingHours: [
                  ...workingHours.workingHours,
                  PpicWorkingHour.fromPartial({
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: v4(),
                    }),
                  }),
                ],
              });
            }}
          >
            <Add /> Add
          </button>
        </div>
        <div
          className="border border-dark rounded rounded-md overflow-auto"
          style={{ height: "65vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "#",
                "Label",
                "Work Start",
                "Work End",
                "Break Start",
                "Break End",
              ].map((h) => (
                <>
                  <th
                    className="bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              ))}
            </tr>
            {workingHours.workingHours.map((wh, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <input
                        className="form-control form-control-sm"
                        placeholder="Label.."
                        defaultValue={wh.label}
                        onBlur={(e) => {
                          setWorkingHours({
                            ...workingHours,
                            workingHours: workingHours.workingHours.map((whx) =>
                              whx &&
                              whx.masterJavaBaseModel?.uuid ===
                                wh.masterJavaBaseModel?.uuid
                                ? { ...whx, label: e.target.value }
                                : whx
                            ),
                          });
                        }}
                      />{" "}
                    </td>
                    <td className="border border-dark">
                      <div className="d-flex">
                        <input
                          defaultValue={Math.floor((wh.start ?? 0) / 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        start: isNaN(parseInt(e.target.value))
                                          ? whx.start
                                          : Math.floor(
                                              parseInt(e.target.value) * 60
                                            ) +
                                            ((whx.start ?? 0) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                        <input
                          defaultValue={Math.floor((wh.start ?? 0) % 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        start: isNaN(parseInt(e.target.value))
                                          ? whx.start
                                          : Math.floor(
                                              (whx.start ?? 0) -
                                                ((whx.start ?? 0) % 60)
                                            ) +
                                            (parseInt(e.target.value) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                      </div>
                      <div>
                        {Math.floor((wh.start ?? 0) / 60)}:
                        {(wh.start ?? 0) % 60}
                      </div>
                    </td>
                    <td className="border border-dark">
                      <div className="d-flex">
                        <input
                          defaultValue={Math.floor((wh.end ?? 0) / 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        end: isNaN(parseInt(e.target.value))
                                          ? whx.end
                                          : Math.floor(
                                              parseInt(e.target.value) * 60
                                            ) +
                                            ((whx.end ?? 0) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                        <input
                          defaultValue={Math.floor((wh.end ?? 0) % 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        end: isNaN(parseInt(e.target.value))
                                          ? whx.end
                                          : Math.floor(
                                              (whx.end ?? 0) -
                                                ((whx.end ?? 0) % 60)
                                            ) +
                                            (parseInt(e.target.value) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                      </div>
                      <div>
                        {Math.floor((wh.end ?? 0) / 60)}:{(wh.end ?? 0) % 60}
                      </div>
                    </td>
                    <td className="border border-dark">
                      <div className="d-flex">
                        <input
                          defaultValue={Math.floor((wh.breakStart ?? 0) / 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        breakStart: isNaN(
                                          parseInt(e.target.value)
                                        )
                                          ? whx.breakStart
                                          : Math.floor(
                                              parseInt(e.target.value) * 60
                                            ) +
                                            ((whx.breakStart ?? 0) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                        <input
                          defaultValue={Math.floor((wh.breakStart ?? 0) % 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        breakStart: isNaN(
                                          parseInt(e.target.value)
                                        )
                                          ? whx.breakStart
                                          : Math.floor(
                                              (whx.breakStart ?? 0) -
                                                ((whx.breakStart ?? 0) % 60)
                                            ) +
                                            (parseInt(e.target.value) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                      </div>
                      <div>
                        {Math.floor((wh.breakStart ?? 0) / 60)}:
                        {(wh.breakStart ?? 0) % 60}
                      </div>
                    </td>
                    <td className="border border-dark">
                      <div className="d-flex">
                        <input
                          defaultValue={Math.floor((wh.breakEnd ?? 0) / 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        breakEnd: isNaN(
                                          parseInt(e.target.value)
                                        )
                                          ? whx.breakEnd
                                          : Math.floor(
                                              parseInt(e.target.value) * 60
                                            ) +
                                            ((whx.breakEnd ?? 0) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                        <input
                          defaultValue={Math.floor((wh.breakEnd ?? 0) % 60)}
                          className="form-control form-control-sm"
                          style={{ width: 50 }}
                          onBlur={(e) => {
                            setWorkingHours({
                              ...workingHours,
                              workingHours: workingHours.workingHours.map(
                                (whx) =>
                                  whx &&
                                  whx.masterJavaBaseModel?.uuid ===
                                    wh.masterJavaBaseModel?.uuid
                                    ? {
                                        ...whx,
                                        breakEnd: isNaN(
                                          parseInt(e.target.value)
                                        )
                                          ? whx.breakEnd
                                          : Math.floor(
                                              (whx.breakEnd ?? 0) -
                                                ((whx.breakEnd ?? 0) % 60)
                                            ) +
                                            (parseInt(e.target.value) % 60),
                                      }
                                    : whx
                              ),
                            });
                          }}
                        />
                      </div>
                      <div>
                        {Math.floor((wh.breakEnd ?? 0) / 60)}:
                        {(wh.breakEnd ?? 0) % 60}
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};
export default WorkingHourPage;
