import React, { ReactNode, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CheckRequestStatus } from "../../../helpers";
import { CrudInfo, initialCrudInfoNoData } from "../../../models/CrudInfo";
import { MaterialRequest } from "../../../models/model";
import { initialPageNoContent, Page } from "../../../models/Page";
import { RequestStatus } from "../../../models/RequestStatus";
import { ChevronLeftIcon, ChevronRightIcon } from "../Icons";

const StatefulCrudTableBs = <T extends {}>(props: {
  url: string;
  head: Array<string>;
  mapper: (obj: T) => { id: number; content: ReactNode[] };
  // refresh: () => void;
  additionalUrl?: string;
  additionalFilter?: (obj: T) => boolean;
}) => {
  const apiKey = localStorage.getItem("apiKey");

  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    searchInput: "",
    crudState: {
      ...initialCrudInfoNoData,
      data: { ...initialPageNoContent, content: [] },
    } as CrudInfo<T>,
    materialRequest: null as MaterialRequest | null,
  });

  useEffect(() => {
    if (state.crudState.requestStatus === RequestStatus.NotAsked) {
      fetchData();
    }
  });

  useEffect(() => {
    setState({
      ...state,
      crudState: { ...state.crudState, requestStatus: RequestStatus.NotAsked },
    });
  }, [props.additionalUrl]);

  const fetchData = async () => {
    try {
      setState({
        ...state,
        crudState: { ...state.crudState, requestStatus: RequestStatus.Loading },
      });

      const response = await fetch(
        `
        ${process.env.REACT_APP_BASE_URL}/${props.url}/search/paged
        ?name=${encodeURI(state.crudState.searchInput)}
        &page=${state.crudState.currentPage}
        &perPage=${state.crudState.rowsPerPage}${props.additionalUrl}`
          .split(" ")
          .join("")
      );

      if (response.status !== 200) throw `Error fetching ${props.url}`;

      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Success,
          data: (await response.json()) as Page<T>,
        },
      });
    } catch (e) {
      setState({
        ...state,
        crudState: { ...state.crudState, requestStatus: RequestStatus.Error },
      });
    }
  };

  const handleMovePage = (type: "Prev" | "Next") => {
    console.log(
      "Current page:",
      state.crudState.currentPage,
      ", total pages:",
      state.crudState.data.totalPages
    );

    switch (type) {
      case "Prev":
        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.NotAsked,
            currentPage:
              state.crudState.currentPage - 1 < 0
                ? 0
                : state.crudState.currentPage - 1,
          },
        });
        break;

      case "Next":
        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.NotAsked,
            currentPage:
              state.crudState.currentPage + 1 >= state.crudState.data.totalPages
                ? state.crudState.currentPage
                : state.crudState.currentPage + 1,
          },
        });
        break;
    }
  };

  return (
    <div>
      <div className="d-flex">
        <div className="ml-2">
          <Link to={`/${props.url}/add`}>
            <Button>Add</Button>
          </Link>
        </div>
        <Form.Control
          className="mx-2"
          placeholder="Search..."
          onChange={(e) => {
            setState({
              ...state,
              crudState: {
                ...state.crudState,
                searchInput: e.target.value,
                requestStatus: RequestStatus.NotAsked,
              },
            });
          }}
          value={state.crudState.searchInput}
        />
      </div>
      <div className="d-flex m-2 align-items-center">
        <Button variant="secondary" onClick={() => handleMovePage("Prev")}>
          <ChevronLeftIcon />
        </Button>
        <Button
          className="ml-2"
          variant="secondary"
          onClick={() => handleMovePage("Next")}
        >
          <ChevronRightIcon />
        </Button>
        <div className="font-weight-bold ml-2">
          {state.crudState.currentPage + 1}/{state.crudState.data.totalPages}
        </div>
        <div className="ml-2">Show per page:</div>
        <div className="ml-2">
          <select
            onChange={(e) =>
              setState({
                ...state,
                crudState: {
                  ...state.crudState,
                  requestStatus: RequestStatus.NotAsked,
                  rowsPerPage: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                  currentPage: 0,
                },
              })
            }
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="All">All</option>
          </select>
        </div>
        <div className="ml-2">
          <CheckRequestStatus requestStatus={state.crudState.requestStatus} />
        </div>
      </div>
      <div
        className="my-2"
        style={{
          height: "70vh",
          border: "2px solid gray",
          overflow: "auto",
          resize: "vertical",
        }}
      >
        <Table size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "darkblue",
                zIndex: 1,
              }}
            >
              {props.head.map((head, i) => {
                const headName =
                  head.toLowerCase() === "name" ? { minWidth: 250 } : {};
                return (
                  <td
                    className="text-white align-middle text-center font-weight-bold"
                    style={{
                      ...headName,
                      ...(i === 0
                        ? {
                            position: "sticky",
                            left: 0,
                            top: 0,
                            backgroundColor: "darkblue",
                            zIndex: 1,
                          }
                        : { zIndex: 1 }),
                    }}
                  >
                    {head}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {state.crudState.data.content
              .filter((d) => {
                if (props.additionalFilter) {
                  return props.additionalFilter?.(d);
                } else {
                  return true;
                }
              })
              .map((obj) => {
                const mappedObj = props.mapper(obj);

                return (
                  <tr>
                    {(mappedObj.content as ReactNode[]).map((content, i) => {
                      return (
                        <td
                          style={
                            i === 0
                              ? {
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: "white",
                                  border: "1px solid black",
                                  maxWidth: 300,
                                }
                              : { border: "1px solid black" }
                          }
                        >
                          {i === 0 ? (
                            <div>
                              <a
                                // className="text-nowrap"
                                href={`/#/${props.url}/${mappedObj.id}`}
                              >{`${content}`}</a>
                            </div>
                          ) : (
                            content
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default StatefulCrudTableBs;
