import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  MasterJavaBaseModel,
  PpicMRAuthorizationDefault,
  PpicMRAuthorizationDefaults,
} from "../../../masterbigsystem";
import { fetchMRAuthorizationDefaults } from "../../../helpers";
import { v4 } from "uuid";
import { ExtUser } from "../../../models/model";

const DefaultMRAuthPage = () => {
  const ctx = useContext(AppContext);

  const mrDefaults = useRef(
    PpicMRAuthorizationDefaults.fromPartial({ defaults: [] })
  );
  const mrDefaultDeleteIds = useRef([] as (string | undefined)[]);

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchMrAuthorizationDefaultsData();
  };

  const fetchMrAuthorizationDefaultsData = async () => {
    const d = await fetchMRAuthorizationDefaults({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      mrDefaults.current = d;
      render();
    }
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/mrauthorizationdefaults-save-bulk`,
        {
          method: "post",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(mrDefaults.current),
        }
      );

      await Promise.all(
        mrDefaultDeleteIds.current.map(async (i) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/mrauthorizationdefaults/${i}`,
            {
              method: "delete",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(mrDefaults.current),
            }
          );
        })
      );
      render();
        window.location.reload();
    } catch (e) {}
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>MR Auth Default</h4>
          </div>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <Select
            options={ctx?.extUsers.map((u) => ({
              label: `${u.name} (${u.departmentName})`,
              value: u,
            }))}
            onChange={(u) => {
              const val = u as { value: ExtUser };

              if (
                !mrDefaults.current.defaults.find(
                  (ux) => `${ux.extUserId}` === `${val.value.id}`
                )
              ) {
                mrDefaults.current = {
                  ...mrDefaults.current,
                  defaults: [
                    ...mrDefaults.current.defaults,
                    PpicMRAuthorizationDefault.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      extUserId: val.value.id ? `${val.value.id}` : undefined,
                    }),
                  ],
                };
              }
              render();
            }}
          />
        </div>

        <div>
          <ol>
            {mrDefaults.current.defaults.map((d) => {
              const foundUser = ctx?.extUsers.find(
                (u) => `${u.id}` === `${d.extUserId}`
              );

              return (
                <>
                  <li>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        mrDefaults.current = {
                          ...mrDefaults.current,
                          defaults: mrDefaults.current.defaults.filter(
                            (dx) => `${dx.extUserId}` !== `${d.extUserId}`
                          ),
                        };

                        mrDefaultDeleteIds.current = [
                          ...mrDefaultDeleteIds.current,
                          d.masterJavaBaseModel?.id,
                        ];
                        render();
                      }}
                    >
                      {foundUser?.name ?? ""} ({foundUser?.departmentName ?? ""}
                      )
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>
      </div>
    </>
  );
};

export default DefaultMRAuthPage;
