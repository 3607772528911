import { Divider } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import {
    fetchExtProject,
    fetchProjectsIdName
} from "../../../helpers";
import { ExtProjectSimple, ProjectView } from "../../../models/model";
import {
    initialProject,
    initialProjectView
} from "../../../models/modelinitials";

const ProjectNewDetail = () => {
  const { id } = useParams() as { id: string };
  const ctx = useContext(AppContext);

  const [projectView, setProjectView] = useState<ProjectView>({
    ...initialProjectView,
    project: { ...initialProject },
  });

  const [extProject, setExtProject] = useState<ExtProjectSimple | null>(null);

  const [projects, setProjects] = useState<
    { id: number | null; name: string | null }[]
  >([]);

  useEffect(() => {
    fetchData();
  }, [projectView]);

  const fetchData = async () => {
    const [projects, extProject] = await Promise.all([
      fetchProjectsIdName(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
      projectView.project?.id
        ? fetchExtProject(
            ctx?.baseUrl ?? "",
            ctx?.apiKey ?? "",
            projectView.project.id
          )
        : null,
    ]);

    if (!isNaN(parseInt(id ?? ""))) {
      try {
        // Fetch and set ext project
        const extProject = await fetchExtProject(
          ctx?.baseUrl ?? "",
          ctx?.apiKey ?? "",
          id
        );

        const resp = await fetch(
          `${ctx?.baseUrl}/projects-byextprojectid?extProjectId=${id}`,
          { headers: { authorization: ctx?.apiKey ?? "" } }
        );

        try {
          const project = await resp.json();

          console.log("Project view:", project);

          setProjectView(project);
        } catch (e) {
          console.error(e);
        }

        console.log("setting Ext project:", extProject);

        setExtProject(extProject);
      } catch (e) {
        console.error(e);

        // return null;
      }
    }
    setProjects(projects);
    // setExtProject(extProject);
  };

  const handleSave = async () => {
    console.log("saving", projectView);

    try {
      const resp = await fetch(`${ctx?.baseUrl}/projects-save`, {
        method: "post",
        headers: {
          authorization: ctx?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...projectView,
          project: projectView.project
            ? { ...projectView.project, extProjectId: extProject?.id }
            : projectView.project,
        }),
      });

      if (resp.status !== 201) throw await resp.text();

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <h4>Project (ID {id})</h4>

          <div className="ml-2">
            <button
              onClick={() => {
                handleSave();
              }}
              className="btn btn-sm btn-primary"
            >
              <Save /> Save
            </button>
          </div>
        </div>

        <div className="my-3">
          <Divider style={{ color: "black" }} />
        </div>

        {/* <div>
          Select job
          <Autocomplete
            size="small"
            renderInput={(params) => (
              <TextField fullWidth {...params} variant="outlined" />
            )}
            options={projects}
            onChange={(
              _: any,
              v: { id: number | null; name: string | null } | null
            ) => {
              setProjectView({
                ...projectView,
                project: projectView.project
                  ? {
                      ...projectView.project,
                      id: v?.id ?? null,
                    }
                  : projectView.project,
              });
            }}
            getOptionLabel={(project) => project.name ?? ""}
          />
        </div> */}

        {extProject ? (
          <div className="my-3">
            <div>
              <strong>Project Name: </strong> {extProject?.name}
            </div>
            <div>
              <strong>Organization: </strong> {extProject?.organization}
            </div>
            <div>
              <strong>Customer: </strong> {extProject?.customer}
            </div>
            <div>
              <strong>Start: </strong>{" "}
              {extProject?.start ? (
                Intl.DateTimeFormat(navigator.language ?? "en-US", {
                  dateStyle: "full",
                  timeZone: "utc",
                } as any).format(new Date(extProject.start))
              ) : (
                <span className="text-danger">None</span>
              )}
            </div>
            <div>
              <strong>End: </strong>{" "}
              {extProject?.end ? (
                Intl.DateTimeFormat(navigator.language ?? "en-US", {
                  dateStyle: "full",
                  timeZone: "utc",
                } as any).format(new Date(extProject.end))
              ) : (
                <span className="text-danger">None</span>
              )}
            </div>
            <div>
              <strong>Deadline: </strong>{" "}
              {extProject?.deadline ? (
                Intl.DateTimeFormat(navigator.language ?? "en-US", {
                  dateStyle: "full",
                  timeZone: "utc",
                } as any).format(new Date(extProject.deadline))
              ) : (
                <span className="text-danger">None</span>
              )}
            </div>

            <div>
              <div>
                <strong>Products:</strong>
              </div>

              <ol>
                {extProject.projectProducts.map((projectProduct) => {
                  return (
                    <li>
                      <div>
                        <div>
                          {projectProduct.name}:{" "}
                          {projectProduct.productNumber &&
                          projectProduct.productNumber !== "" ? (
                            projectProduct.productNumber
                          ) : (
                            <strong className="text-danger">
                              No Product Number
                            </strong>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* <small>
          <pre>{JSON.stringify(extProject, null, 2)}</pre>
        </small>

        <small>
          <pre>{JSON.stringify(projects, null, 2)}</pre>
        </small> */}
      </div>
    </>
  );
};

export default ProjectNewDetail;
