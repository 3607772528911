import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { ExtItem, MaterialRequest } from "../../../models/model";
import { RequestStatus } from "../../../models/RequestStatus";
import StatefulCrudTableBs from "../../mini-components/StatefulCrudTable/StatefulCrudTableBs";

const MaterialRequestPage = () => {
  const appState = useContext(AppContext);
  const apiKey = localStorage.getItem("apiKey");

  const [state, setState] = useState({
    items: [] as Array<ExtItem>,
    requestStatus: RequestStatus.NotAsked,
  });

  useEffect(() => {
    (async () => {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const items = await fetchItems();

      setState({
        ...state,
        requestStatus: RequestStatus.Success,
        items: items ? items : state.items,
      });
    })();
  }, []);

  const fetchItems = async (): Promise<Array<ExtItem> | null> => {
    try {
      const response = await fetch(`${appState?.baseUrl}/ext-items`, {
        headers: {
          authorization: appState?.apiKey ?? "",
        },
      });

      if (response.status !== 200) throw "Error fetching api items";
      return (await response.json()) as Array<ExtItem>;
    } catch (e) {
      return null;
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <h3>Material Request Page</h3>
      </div>
      <div className="mt-2">
        <StatefulCrudTableBs
          url="materialrequests"
          head={["Name", "MR Number", "Approved", "Created", "Updated", "Created By"]}
          mapper={(materialRequest: MaterialRequest) => {
            return {
              id: materialRequest.id ?? 0,
              content: [
                materialRequest.name,
                `MR-${materialRequest.id}`,
                "0",
                materialRequest.createdAt ? materialRequest.createdAt : "",
                materialRequest.updatedAt ? materialRequest.updatedAt : "",
                materialRequest.createdBy ? materialRequest.createdBy.name : "",
              ],
            };
          }}
        />
      </div>
      <div className="mt-2">
        Debug:
        <div>
          <pre>{JSON.stringify(state.items, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
};

export default MaterialRequestPage;
