import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchSimplifiedProcessTypesProto } from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicSimplifiedProcessType,
  PpicSimplifiedProcessTypePic,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import { ExtUser } from "../../../models/model";

const ProgramTreeSettingsPage = () => {
  const ctx = useContext(AppContext);

  const [simplifiedProcessTypes, setSimplifiedProcessTypes] = useState(
    PpicSimplifiedProcessTypes.fromPartial({})
  );
  const [
    simplifiedProcessTypePicDeleteIds,
    setSimplifiedProcessTypePicDeleteIds,
  ] = useState<string[]>([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchSimplifiedProcessTypeData();
  };

  const fetchSimplifiedProcessTypeData = async () => {
    const d = await fetchSimplifiedProcessTypesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      setSimplifiedProcessTypes(d);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>Program Tree Settings</h5>
          </div>
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={async () => {
                try {
                  const resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/simplifiedprocesstypes`,
                    {
                      method: "post",
                      headers: {
                        authorization: ctx?.apiKey ?? "",
                        "content-type": "application/json",
                      },
                      body: JSON.stringify(
                        PpicSimplifiedProcessTypes.toJSON(
                          simplifiedProcessTypes
                        )
                      ),
                    }
                  );

                  // Delete pics
                  await Promise.all([
                    ...simplifiedProcessTypePicDeleteIds.map(async (id) => {
                      try {
                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/simplifiedprocesstypepics/${id}/empty`,
                          {
                            method: "delete",
                            headers: { "content-type": "application/json" },
                          }
                        );
                      } catch (e) {
                        console.error(e);
                      }
                    }),
                  ]);

                  window.location.reload();
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
        <hr className="border border-dark" />
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ height: "75vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "#",
                "Name",
                // "Default Machine",
                "Default SPV",
                "Default PIC",
              ].map((h) => (
                <th
                  className="bg-dark text-light"
                  style={{ position: "sticky", top: 0 }}
                >
                  {h}
                </th>
              ))}
            </tr>
            {simplifiedProcessTypes.processTypes.map((pt, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">{pt.name}</td>
                    {/* <td className="border border-dark">{pt.machineId}</td> */}
                    <td className="border border-dark">
                      <Select
                        options={ctx?.extUsers ?? []}
                        getOptionLabel={(u) =>
                          `${u.name} (${u.departmentName})`
                        }
                        onChange={(v) => {
                          const val = v as unknown as ExtUser;

                          setSimplifiedProcessTypes({
                            ...simplifiedProcessTypes,
                            processTypes:
                              simplifiedProcessTypes.processTypes.map((ptx) =>
                                `${ptx.masterJavaBaseModel?.id}` ===
                                `${pt.masterJavaBaseModel?.id}`
                                  ? {
                                      ...ptx,
                                      extSpvId: val.id
                                        ? `${val.id}`
                                        : undefined,
                                    }
                                  : ptx
                              ),
                          });
                        }}
                        value={ctx?.extUsers.find(
                          (u) => `${u.id}` === `${pt.extSpvId}`
                        )}
                      />
                    </td>
                    <td className="border border-dark">
                      <Select
                        options={ctx?.extUsers ?? []}
                        getOptionLabel={(u) =>
                          `${u.name} (${u.departmentName})`
                        }
                        onChange={(v) => {
                          const val = v as unknown as ExtUser;

                          const newUser =
                            PpicSimplifiedProcessTypePic.fromPartial({
                              extUserId: val.id ? `${val.id}` : undefined,
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({
                                  uuid: v4(),
                                }),
                            });

                          if (
                            !simplifiedProcessTypes.processTypes
                              .find(
                                (ptx) =>
                                  `${ptx.masterJavaBaseModel?.id}` ===
                                  `${pt.masterJavaBaseModel?.id}`
                              )
                              ?.pics.find(
                                (p) => `${p.extUserId}` === `${val.id}`
                              )
                          ) {
                            setSimplifiedProcessTypes({
                              ...simplifiedProcessTypes,
                              processTypes:
                                simplifiedProcessTypes.processTypes.map((ptx) =>
                                  `${ptx.masterJavaBaseModel?.id}` ===
                                  `${pt.masterJavaBaseModel?.id}`
                                    ? {
                                        ...ptx,
                                        pics: ptx.pics
                                          ? [...ptx.pics, newUser]
                                          : [newUser],
                                      }
                                    : ptx
                                ),
                            });
                          }
                        }}
                        // value={ctx?.extUsers.find(
                        //   (u) => `${u.id}` === `${pt.extPicId}`
                        // )}
                      />

                      <div>
                        {/* {JSON.stringify(pt.pics)} */}
                        <ol>
                          {" "}
                          {pt.pics.map((p) => {
                            const u = ctx?.extUsers.find(
                              (u) => `${u.id}` === `${p.extUserId}`
                            );
                            return (
                              <>
                                <li>
                                  <div>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        setSimplifiedProcessTypePicDeleteIds([
                                          ...simplifiedProcessTypePicDeleteIds,
                                          p.masterJavaBaseModel?.id ?? "",
                                        ]);
                                        setSimplifiedProcessTypes({
                                          ...simplifiedProcessTypes,
                                          processTypes:
                                            simplifiedProcessTypes.processTypes.map(
                                              (ptx) =>
                                                `${ptx.masterJavaBaseModel?.id}` ===
                                                `${pt.masterJavaBaseModel?.id}`
                                                  ? {
                                                      ...ptx,
                                                      pics: ptx.pics.filter(
                                                        (px) =>
                                                          `${px.extUserId}` !==
                                                          `${p.extUserId}`
                                                      ),
                                                    }
                                                  : ptx
                                            ),
                                        });
                                      }}
                                    >
                                      {u?.name} ({u?.departmentName})
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ol>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default ProgramTreeSettingsPage;
