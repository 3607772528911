import React, { createContext, useState } from "react";
import MainComponent from "./components/MainComponent";
import { CrmCustomers } from "./masterbigsystem";
import {
  ExtCustomer,
  ExtDepartment,
  ExtOrganization,
  ExtUser,
  MaterialType,
  ProcessType,
  Ral,
  User,
} from "./models/model";
import { RequestStatus } from "./models/RequestStatus";

export interface IAppState {
  apiKey: string | null;
  setApiKey: (_: string | null) => void;

  gspeAppApiKey: string | null;
  setGspeAppApiKey: (_: string | null) => void;

  baseUrl: string | undefined;
  setBaseUrl: (_: string | undefined) => void;

  loggedIn: boolean;
  setLoggedIn: (_: boolean) => void;

  loginRequestStatus: RequestStatus;
  setLoginRequestStatus: (_: RequestStatus) => void;

  appBarVisible: boolean;
  setAppBarVisible: (_: boolean) => void;

  processTypes: ProcessType[];
  setProcessTypes: (_: ProcessType[]) => void;

  rals: Ral[];
  setRals: (_: Ral[]) => void;

  materialTypes: MaterialType[];
  setMaterialTypes: (_: MaterialType[]) => void;

  extOrganizations: ExtOrganization[];
  setExtOrganizations: (_: ExtOrganization[]) => void;

  extUsers: ExtUser[];
  setExtUsers: (_: ExtUser[]) => void;

  user: User | null | undefined;
  setUser: (_: User | null | undefined) => void;

  gspeAppUser: ExtUser | null | undefined;
  setGspeAppUser: (_: ExtUser | null | undefined) => void;

  extCustomers: ExtCustomer[];
  setExtCustomers: (_: ExtCustomer[]) => void;

  extCrmCustomers: CrmCustomers;
  setExtCrmCustomers: (_: CrmCustomers) => void;

  extDepartments: ExtDepartment[];
  setExtDepartments: (_: ExtDepartment[]) => void;

  projectProductsIdName: { id: number | null; name: string | null }[];
  setProjectProductsIdName: (
    _: { id: number | null; name: string | null }[]
  ) => void;

  projectsIdName: { id: number | null; name: string | null }[];
  setProjectsIdName: (_: { id: number | null; name: string | null }[]) => void;
}

export const AppContext = createContext<IAppState | null>(null);

const App = () => {
  const [apiKey, setApiKey] = useState(localStorage.getItem("apiKey"));
  const [gspeAppApiKey, setGspeAppApiKey] = useState(
    localStorage.getItem("gspeAppApiKey")
  );

  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_BASE_URL);
  const [appBarVisible, setAppBarVisible] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginRequestStatus, setLoginRequestStatus] = useState(
    RequestStatus.Loading
  );
  const [extCrmCustomers, setExtCrmCustomers] = useState(
    CrmCustomers.fromPartial({ customers: [] })
  );
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [rals, setRals] = useState<Ral[]>([]);
  const [materialTypes, setMaterialTypes] = useState<MaterialType[]>([]);
  const [extOrganizations, setExtOrganizations] = useState<ExtOrganization[]>(
    []
  );
  const [extCustomers, setExtCustomers] = useState<ExtCustomer[]>([]);
  const [extUsers, setExtUsers] = useState<ExtUser[]>([]);
  const [user, setUser] = useState<User | null | undefined>(null);

  const [gspeAppUser, setGspeAppUser] = useState<ExtUser | null | undefined>(
    null
  );

  const [extDepartments, setExtDepartments] = useState<ExtDepartment[]>([]);

  const [projectProductsIdName, setProjectProductsIdName] = useState<
    { id: number | null; name: string | null }[]
  >([]);

  const [projectsIdName, setProjectsIdName] = useState<
    { id: number | null; name: string | null }[]
  >([]);

  return (
    <AppContext.Provider
      value={{
        gspeAppUser: gspeAppUser,
        setGspeAppUser: setGspeAppUser,

        gspeAppApiKey: gspeAppApiKey,
        setGspeAppApiKey: setGspeAppApiKey,

        apiKey: apiKey,
        setApiKey: setApiKey,

        baseUrl: baseUrl,
        setBaseUrl: setBaseUrl,

        appBarVisible: appBarVisible,
        setAppBarVisible: setAppBarVisible,

        loggedIn: loggedIn,
        setLoggedIn: setLoggedIn,

        loginRequestStatus: loginRequestStatus,
        setLoginRequestStatus: setLoginRequestStatus,

        processTypes: processTypes,
        setProcessTypes: setProcessTypes,

        rals: rals,
        setRals: setRals,

        materialTypes: materialTypes,
        setMaterialTypes: setMaterialTypes,

        extOrganizations: extOrganizations,
        setExtOrganizations: setExtOrganizations,

        extCustomers: extCustomers,
        setExtCustomers: setExtCustomers,

        extCrmCustomers: extCrmCustomers,
        setExtCrmCustomers: setExtCrmCustomers,

        extUsers: extUsers,
        setExtUsers: setExtUsers,

        user: user,
        setUser: setUser,

        extDepartments: extDepartments,
        setExtDepartments: setExtDepartments,

        projectProductsIdName: projectProductsIdName,
        setProjectProductsIdName: setProjectProductsIdName,

        projectsIdName: projectsIdName,
        setProjectsIdName: setProjectsIdName,
      }}
    >
      <MainComponent />
    </AppContext.Provider>
  );
};

export default App;
