import React, { ReactNode, useState } from "react";
import { Button, Table } from "react-bootstrap";

const GenericOrderableTable = <T extends { ordering: number | null }>(props: {
  head: string[];
  body: T[];
  mapper: (item: T, index: number) => ReactNode;
  onDelete: (item: T, index: number) => void;
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [onMove, setOnMove] = useState(false);

  return (
    <>
      <Table size="sm">
        <thead>
          <tr>
            {["Actions", "Ordering", ...props.head].map((head) => (
              <th className="text-center align-middle bg-secondary text-light">
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.body.map((item, i) => (
            <tr>
              <td className="border border-secondary align-middle text-center">
                <Button
                  size="sm"
                  disabled={onMove && selectedIndex === i}
                  onClick={() => {
                    console.log("Indexes to swap", i, selectedIndex);

                    if (onMove) {
                      if (selectedIndex !== null) {
                        console.log("Indexes to swap", i, selectedIndex);

                        const a = { ...props.body[selectedIndex] };
                        const b = { ...props.body[i] };

                        props.body[i] = a;
                        props.body[selectedIndex] = b;

                        setOnMove(false);
                        setSelectedIndex(null);
                      }
                    } else {
                      setOnMove(true);
                      setSelectedIndex(i);
                    }
                  }}
                >
                  {onMove ? "Here" : "Move"}
                </Button>
                <Button
                  onClick={() => props.onDelete(item, i)}
                  size="sm"
                  className="mx-1"
                >
                  Delete
                </Button>
              </td>
              <td className="border border-secondary">{item.ordering}</td>
              {props.mapper(item, i)}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default GenericOrderableTable;
