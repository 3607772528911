import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalDialog, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchPpicAssistances } from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicManpowerAssistance,
  PpicManpowerAssistances,
} from "../../../masterbigsystem";
import { ExtDepartment, ExtUser } from "../../../models/model";

const MPAssistancePage = () => {
  const ctx = useContext(AppContext);
  const [mpAssistances, setMpAssistances] = useState(
    PpicManpowerAssistances.fromPartial({})
  );
  const [newMpAssistance, setNewMpAssistance] =
    useState<PpicManpowerAssistance | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMpAssistancesData();
  }, []);

  const fetchMpAssistancesData = async () => {
    try {
      setLoading(true);

      const d = await fetchPpicAssistances({ apiKey: ctx?.apiKey ?? "" });

      if (!d) {
        return;
      }

      setMpAssistances(d);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/manpowerassistances`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(newMpAssistance),
        }
      );

      fetchMpAssistancesData();
      setNewMpAssistance(null);
    } catch (e) {
    } finally {
    }
  };

  return (
    <>
      <div className="m-3">
        <div>
          <div>
            <h4>MP Assistance</h4>
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setNewMpAssistance(
                PpicManpowerAssistance.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                })
              );
            }}
          >
            Add New
          </button>
        </div>

        <div
          className="overflow-auto border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr
              className="bg-dark text-light"
              style={{ position: "sticky", top: 0 }}
            >
              {[
                "#",
                "User",
                "From Dept",
                "To Dept",
                "For Date",
                "Created",
                "Updated",
                "Action",
              ].map((r) => {
                return (
                  <>
                    <th>{r}</th>
                  </>
                );
              })}
            </tr>
            {mpAssistances.assistances.map((a) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">
                      {a.masterJavaBaseModel?.id}
                    </td>
                    <td className="border border-dark">
                      {
                        ctx?.extUsers.find(
                          (u) => `${u.id}` === `${a.extUserId}`
                        )?.username
                      }
                    </td>
                    <td className="border border-dark">
                      {
                        ctx?.extDepartments.find(
                          (d) =>
                            `${d.id}` ===
                            `${
                              ctx?.extUsers.find(
                                (u) => `${u.id}` === `${a.extUserId}`
                              )?.departmentId
                            }`
                        )?.name
                      }
                    </td>
                    <td className="border border-dark">
                      {
                        ctx?.extDepartments.find(
                          (d) => `${d.id}` === `${a.extDeptToId}`
                        )?.name
                      }
                    </td>
                    <td className="border border-dark">{a.forDate}</td>
                    <td className="border border-dark">
                      {(() => {
                        try {
                          return Intl.DateTimeFormat(
                            navigator.language ?? "en-US",
                            { dateStyle: "medium", timeStyle: "medium" } as any
                          ).format(
                            new Date(a.masterJavaBaseModel?.createdAt ?? "")
                          );
                        } catch (e) {
                          return "";
                        }
                      })()}
                    </td>
                    <td className="border border-dark">
                      {(() => {
                        try {
                          return Intl.DateTimeFormat(
                            navigator.language ?? "en-US",
                            { dateStyle: "medium", timeStyle: "medium" } as any
                          ).format(
                            new Date(a.masterJavaBaseModel?.updatedAt ?? "")
                          );
                        } catch (e) {
                          return "";
                        }
                      })()}{" "}
                    </td>
                    <td className="border border-dark">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          setNewMpAssistance(a);
                        }}
                      >
                        <small>Edit</small>
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
      <Modal
        show={newMpAssistance ? true : false}
        onHide={() => {
          setNewMpAssistance(null);
        }}
      >
        <ModalHeader>Add/Edit MP Assistance</ModalHeader>
        <ModalBody>
          <label>
            <strong>User</strong>
          </label>
          <div>
            <Select
              value={(() => {
                const f = ctx?.extUsers.find(
                  (u) => `${newMpAssistance?.extUserId}` === `${u.id}`
                );

                if (!f) {
                  return null;
                }

                return {
                  label: `${f.username} (${
                    ctx?.extDepartments.find(
                      (d) => `${d.id}` === `${f.departmentId}`
                    )?.name ?? "No dept"
                  })`,
                  value: f,
                };
              })()}
              options={ctx?.extUsers.map((u) => ({
                label: `${u.username} (${
                  ctx.extDepartments.find(
                    (d) => `${d.id}` === `${u.departmentId}`
                  )?.name ?? "No dept"
                })`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtUser };

                setNewMpAssistance({
                  ...newMpAssistance,
                  extUserId: val.value.id ? `${val.value.id}` : undefined,
                });
              }}
            />
          </div>

          <label>
            <strong> To dept</strong>
          </label>
          <div>
            <Select
              value={(() => {
                const f = ctx?.extDepartments.find(
                  (d) => `${newMpAssistance?.extDeptToId}` === `${d.id}`
                );

                if (!f) {
                  return null;
                }

                return {
                  label: f.name ?? "No name",
                  value: f,
                };
              })()}
              options={ctx?.extDepartments.map((d) => ({
                label: d.name ?? "No name",
                value: d,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtDepartment };

                setNewMpAssistance({
                  ...newMpAssistance,
                  extDeptToId: val.value.id ? `${val.value.id}` : undefined,
                });
              }}
            />
          </div>
          <label>
            <strong>For date</strong>
          </label>
          <div>
            <input
              type="date"
              className="form-control form-control-sm"
              defaultValue={newMpAssistance?.forDate}
              key={newMpAssistance?.masterJavaBaseModel?.uuid}
              onBlur={(e) => {
                if (e.target.value && e.target.value !== "") {
                  setNewMpAssistance({
                    ...newMpAssistance,
                    forDate: e.target.value,
                  });
                }
              }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          {loading ? (
            <>
              {" "}
              <div className="spinner-border spinner-border-sm"></div>
            </>
          ) : (
            <>
              <div>
                {" "}
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  <small>Save</small>
                </button>
              </div>
            </>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MPAssistancePage;
