import { Sort, initialSort } from "./Sort";

export interface Pageable {
  sort: Sort,
  pageNumber: number,
  pageSize: number,
  offset: number,
  unpaged: boolean,
  paged: boolean
}

export const initialPageable: Pageable = {
  sort: {...initialSort},
  pageNumber: 0,
  pageSize: 0,
  offset: 0,
  unpaged: false,
  paged: true
}