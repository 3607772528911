import React, { useContext, useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
// import "./print-media-query.css";
import { AppContext } from "../../../App";
import {
  PpicHandover,
  ppicHandoverTypeFromJSON,
  ppicHandoverTypeToJSON,
  PpicJobs,
  WarehouseItems,
} from "../../../masterbigsystem";
import {
  fetchHandoverProto,
  fetchJobsProtoSimple,
  fetchWarehouseItemsProto,
  intlFormat,
} from "../../../helpers";

const HandoverPrintPage = () => {
  const ctx = useContext(AppContext);

  // const handover = useRef(null as PpicHandover | null);
  const poLoading = useRef(false);
  const { id } = useParams() as { id: string };
  const [ppicHandover, setPpicHandover] = useState<PpicHandover>(
    PpicHandover.fromPartial({})
  );
  const items = useRef(WarehouseItems.fromPartial({}));
  const jobs = useRef(PpicJobs.fromPartial({}));

  const handleInit = async () => {
    ctx?.setAppBarVisible(false);
    handleFetchHandoverProtoData();
    handleFetchItemsProtoData();
    // handleFetchItems()
    handleFetchJobsProtoData();
  };

  const handleFetchItemsProtoData = async () => {
    const d = await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      items.current = d;
      render();
    }
  };

  const handleFetchJobsProtoData = async () => {
    const d = await fetchJobsProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      jobs.current = d;
      render();
    }
  };

  const handleFetchHandoverProtoData = async () => {
    if (!isNaN(parseInt(id))) {
      setPpicHandover(
        await fetchHandoverProto({ id: id, apiKey: ctx?.apiKey ?? "" })
      );
    }
  };

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    if (items.current.items.length > 0) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [items.current]);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <h4>Handover Print</h4>
          </div>

          {/* <div>
            <div>
              <table className="table table-sm">
                <tr>
                  <th className="border border-dark">No. Dokumen</th>
                  <th className="border border-dark"> QF-PPC-0002</th>
                </tr>
                <tr>
                  <th className="border border-dark">No. Revisi</th>
                  <th className="border border-dark">No. 01</th>
                </tr>
                <tr>
                  <th className="border border-dark">Tanggal Efektif</th>
                  <th className="border border-dark">
                    {intlFormat({
                      date: foundPR?.createdAt,
                      dateStyle: "medium",
                    })}
                  </th>
                </tr>
              </table>
            </div>
          </div> */}
        </div>

        <div>
          <div>
            Number: LEFTOVER/PPIC/
            {new Date(
              ppicHandover.masterJavaBaseModel?.createdAt ?? new Date()
            ).getFullYear()}
            /{ppicHandover.masterJavaBaseModel?.id}
          </div>

          <div>
            Type:{" "}
            {ppicHandoverTypeToJSON(
              ppicHandoverTypeFromJSON(ppicHandover.type)
            )}
          </div>

          <div>
            Job:{" "}
            {
              jobs.current.jobs.find(
                (j) =>
                  `${j.masterJavaBaseModel?.id}` === `${ppicHandover.extJobId}`
              )?.name
            }
          </div>

          <div>Handover by: {ppicHandover.handoverBy}</div>

          <div>
            Date:{" "}
            {intlFormat({
              date: ppicHandover.date,
              dateStyle: "long",
              timeStyle: "short",
            })}
          </div>
        </div>
        {/* <div>
          <hr className="border border-dark" />
        </div> */}

        <div>
          <hr className="border border-dark" />
        </div>

        <div>
          <table className="table table-sm">
            <tr>
              {["#", "MFR", "PN", "Part Name", "Desc", "Qty", "UM"].map((h) => {
                return (
                  <>
                    <th className="border border-dark">{h}</th>
                  </>
                );
              })}
            </tr>
            {ppicHandover?.items.map((i, i_) => {
              const foundItem = items.current.items.find(
                (ix) => `${ix.id}` === `${i.productId}`
              );
              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i_ + 1} </td>
                    <td className="border border-dark p-0 m-0">
                      {foundItem?.mfr}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {foundItem?.partNum}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {foundItem?.partName}
                    </td>

                    <td className="border border-dark p-0 m-0">
                      {foundItem?.partDesc}
                    </td>

                    <td className="border border-dark p-0 m-0">{i.qty}</td>

                    <td className="border border-dark p-0 m-0">
                      {foundItem?.defaultUm}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>

        <div
          style={{ marginTop: "2em" }}
          className="d-flex justify-content-around"
        >
          <div>
            <div className="mb-5">
              <strong>Requested:</strong>
            </div>
            <div className="my-5 text-light">I</div>
            <div className="mt-5">
              <hr className="border-bottom border-dark" />
            </div>
          </div>

          <div>
            <div className="mb-5">
              <strong>Approved:</strong>
            </div>
            <div className="my-5 text-light">I</div>
            <div className="mt-5">
              <hr className="border-bottom border-dark" />
            </div>
          </div>

          <div>
            <div className="mb-5">
              <strong>Received:</strong>
            </div>
            <div className="my-5 text-light">I</div>
            <div className="mt-5">
              <hr className="border-bottom border-dark" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HandoverPrintPage;
