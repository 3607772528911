import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchTaskListTemplate } from "../../../helpers";
import {
  MasterJavaBaseModel,
  MeetingMeetingTaskListTemplate,
  MeetingMeetingTaskListTemplateItem,
  MeetingTaskList,
  TaskListTemplate,
} from "../../../masterbigsystem";

const WorkOrderTemplateDetailPage = () => {
  const ctx = useContext(AppContext);
  const history = useHistory();

  const { id } = useParams() as { id: string };

  const [templateItemDeleteIds, setTemplateItemDeleteIds] = useState<string[]>(
    []
  );
  const [template, setTemplate] = useState(
    MeetingMeetingTaskListTemplate.fromPartial({})
  );

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    if (!isNaN(parseInt(id))) {
      fetchTemplateData();
    }
  };

  const fetchTemplateData = async () => {
    setTemplate(
      await fetchTaskListTemplate({ apiKey: ctx?.apiKey ?? "", id: id })
    );
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>WO Template Detail</h5>
          </div>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={async () => {
                try {
                  const resp = await fetch(
                    `${process.env.REACT_APP_MEETING_URL}/tasklisttemplates-proto`,
                    {
                      method: "post",
                      headers: { authorization: ctx?.apiKey ?? "" },
                      body: JSON.stringify(
                        MeetingMeetingTaskListTemplate.toJSON(template)
                      ),
                    }
                  );

                  if (resp.status !== 200) throw await resp.text();

                  await Promise.all(
                    templateItemDeleteIds.map(async (i) => {
                      try {
                        const resp = await fetch(
                          `${process.env.REACT_APP_MEETING_URL}/tasklisttemplateitems/${i}/empty`,
                          {
                            method: "delete",
                            headers: { authorization: ctx?.apiKey ?? "" },
                          }
                        );
                      } catch (e) {
                        console.error(e);
                      }
                    })
                  );

                  history.push("/workordertemplates");
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
        <hr />
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setTemplate({
                ...template,
                items: [
                  ...template.items,
                  MeetingMeetingTaskListTemplateItem.fromPartial({
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: v4(),
                    }),
                  }),
                ],
              });
            }}
          >
            Add
          </button>
          <input
            className="form-control form-control-sm"
            placeholder="Name..."
            defaultValue={template.name}
            onBlur={(e) => {
              setTemplate({ ...template, name: e.target.value });
            }}
          />
        </div>
        <div className="overflow-auto border border-dark shadow shadow-md">
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["Action", "#", "Name", "Working Mins"].map((h) => (
                <th className="bg-dark text-light">{h}</th>
              ))}
            </tr>
            {template.items.map((i, i_) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setTemplateItemDeleteIds([
                            ...templateItemDeleteIds,
                            i.masterJavaBaseModel?.id ?? "",
                          ]);

                          setTemplate({
                            ...template,
                            items: template.items
                              ? template.items.filter((ix, ix_) => ix_ !== i_)
                              : template.items,
                          });
                        }}
                      >
                        Delete
                      </button>
                    </td>

                    <td className="border border-dark">{i_ + 1}</td>
                    <td className="border border-dark">
                      {/* {i.name} */}
                      <textarea
                        placeholder="Task name..."
                        className="form-control form-control-sm"
                        key={`inp-${i_}`}
                        // defaultValue={i.name}
                        onBlur={(e) => {
                          setTemplate({
                            ...template,
                            items: template.items.map((ix, ix_) =>
                              ix_ == i_ ? { ...ix, name: e.target.value } : ix
                            ),
                          });
                        }}
                      />{" "}
                      <pre>{i.name}</pre>
                    </td>
                    <td className="border border-dark">
                      {/* {i.name} */}
                      <input
                        placeholder="Mins..."
                        className="form-control form-control-sm"
                        key={`mns-${i_}`}
                        // defaultValue={i.workingMins}
                        onBlur={(e) => {
                          setTemplate({
                            ...template,
                            items: template.items.map((ix, ix_) =>
                              ix_ == i_
                                ? {
                                    ...ix,
                                    workingMins: isNaN(parseInt(e.target.value))
                                      ? ix.workingMins
                                      : parseInt(e.target.value),
                                  }
                                : ix
                            ),
                          });
                        }}
                        style={{ width: 75 }}
                      />{" "}
                      {i.workingMins}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default WorkOrderTemplateDetailPage;
