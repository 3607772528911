import DateFnsUtils from '@date-io/date-fns'
import { Box, Button, CardContent, Checkbox, Fab, Grid, List, ListItem, ListItemIcon, Paper, Switch, TextField } from '@material-ui/core'
import { blue, deepPurple, green, orange, red } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useState } from 'react'
import { Layer, Line, Rect, Stage, Text } from 'react-konva'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import AsyncAutocomplete from '../../mini-components/CrudTable/components/AsyncAutocomplete'
import AsyncAutocompleteJobs from '../../mini-components/CrudTable/components/AsyncAutocompleteJobs'
import SyncAutocomplete from '../../mini-components/CrudTable/components/SyncAutocomplete'
import SyncAutocompleteValue from '../../mini-components/CrudTable/components/SyncAutocompleteValue'

const defaultMachinePosition = { x: 10, y: 50 }
const jobXPosition = { start: 150, end: (15 * window.innerWidth/18) + 150  }

const WorkOrderAdd = ({ currentLink, changeLink }) => {
  const [job, setJob] = useState(jobDefaults)
  const [selectedJob, setSelectedJob] = useState(null)
  const [selectedProcessType, setSelectedProcessType] = useState(null)
  const [machines, setMachines] = useState([])
  const [selectedWorkOrderMachine, setSelectedWorkOrderMachine] = useState(machineDefaults)
  const [requestStatus, setRequestStatus] = useState('notAsked')
  const [displayWorkOrderSummary, setDisplayWorkOrderSummary] = useState(false)
  const [workOrdersToView, setWorkOrdersToView] = useState([])
  const [workOrderModifications, setWorkOrderModifications] = useState([])
  const [workOrderToAdd, setWorkOrderToAdd] = useState(initialWorkOrderToAdd)
  const [autoFill, setAutoFill] = useState(true)
  const [manpowerType, setManpowerType] = useState('Individual')
  const [individualManpower, setIndividualManpower] = useState(null)
  const [leaderManpower, setLeaderManpower] = useState(null)
  const [teamManpowerMembers, setTeamManpowerMembers] = useState([])
  const [tempManpowerMember, setTempManpowerMember] = useState(null)

  // const [requestStatus, setRequestStatus] = useState('notAsked')
  const query = new URLSearchParams(useLocation().search)
  const dateInfo = query.get('date')

  useEffect(() => {
    if(currentLink !== `/workorderadd?date=${dateInfo}`) {
      changeLink(`/workorderadd?date=${dateInfo}`)
    }
  })
  
  // workOrdersToView.forEach(workOrder => console.log(workOrder.name))

  useEffect(() => {
    (async () => {
      if(requestStatus === 'notAsked') {
        setRequestStatus('loading')

        const processType = selectedProcessType === null ? processTypeDefaults : selectedProcessType

        const workOrdersResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/workorders/bydate?date=${dateInfo}`)
        const machinesResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/machines/byprocesstype?name=${processType.name}`)
        
        const workOrdersJson = await workOrdersResponse.json()
        const machinesJson = await machinesResponse.json()


        setWorkOrdersToView(workOrdersJson)
        setMachines(machinesJson)

        setRequestStatus('success')
      }
    })()
  })

  // Helper functions
  const secondsToHms = secondsToParse => {
    const hours = (() => {
      const hrs = Math.floor(secondsToParse / 3600)
      return hrs < 10 ? `0${hrs}` : `${hrs}`
    })()

    const minutes = (() => {
      const mins = Math.floor(secondsToParse / 60) % 60
      return mins < 10 ? `0${mins}` : `${mins}`
    })()

    const seconds = (() => {
      const secs = secondsToParse % 60
      return secs < 10 ? `0${secs}` : `${secs}`
    })()

    return `${hours}:${minutes}:${seconds}`
  }

  // Manpower in Job Card
  const ViewManpowerCard = props => {
    const manpower = props.data

    if(manpower !== null) {

      if(manpower.individual != null) {
        return (
          < >
            <strong>Worker:</strong>&nbsp;
            {manpower.individual.name}
          </>
        )
      }
      else if(manpower.workOrderTeam !== null){
        return (
          <Box>
            <Box>
              <strong>Team Leader:</strong>&nbsp;{manpower.workOrderTeam.leader.name}
            </Box>
            <Box>
              <strong>Members:</strong>&nbsp;
              {manpower.workOrderTeam.members.map(member => member.name).join(', ')}
            </Box>
            
          </Box>
        )
      }
    }
    else {
      return ''
    }
  }

  const handleAddManpowerMember = () => {
    console.log('manpower member to add:', tempManpowerMember)

    const newManpowerMember = [...teamManpowerMembers]
    newManpowerMember.unshift(tempManpowerMember)

    setTeamManpowerMembers(newManpowerMember)
  }

  const handleDeleteMember = (memberId) => {
    const newTeamManpowerMember = [...teamManpowerMembers]
    const foundManpowerMember = newTeamManpowerMember.find(member => member.id === memberId)

    newTeamManpowerMember.splice(foundManpowerMember, 1)
    setTeamManpowerMembers(newTeamManpowerMember)
  }

  const handleSave = async () => {
    try {
      // Finally, modify the work orders.
      console.log('saving work order data!', workOrderModifications)
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/workorders`, {
        method: 'PUT',
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify(workOrderModifications)
      })
      const responseText = await response.text()
      console.log('modify success:', responseText)
      window.location.reload()
    }
    catch(err) {
      console.log('error:', err)
    }
  }
  
  const handleSelectProcessType = async (processType) => { 
    const machinesResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/machines/byprocesstype?name=${processType === null ? '' : processType.name}`)
    const machinesJson = await machinesResponse.json()
    setSelectedProcessType(processType)
    setMachines(machinesJson)
  }

  const handleAddWorkOrder = () => {
    console.log('work order to view', workOrdersToView)
    console.log('work orders in stock:', workOrderToAdd)

    const timeList = 
      workOrdersToView
      .filter(workOrder => workOrder.machine.name === selectedWorkOrderMachine.name)
      .map(workOrder => workOrder.endTime)

    const highestTime = timeList.length > 0 ? timeList.sort().reverse()[0] : 25200 // If list is empty, default to 25200 (seconds for 7.00)
    const totalTime = workOrderToAdd.fepSubProcess.value.time * parseInt(workOrderToAdd.programRun)

    const startTimeDate = workOrderToAdd.startTime

    const startTime = autoFill ? highestTime : startTimeDate.getHours() * 3600 + startTimeDate.getMinutes() * 60 + startTimeDate.getSeconds()

    console.log('time list:', timeList)
    // console.log('start time:', startTime)
    timeList.length > 0 ? console.log('time list exists') : console.log('time list doesnt exist')

    const fepProcessIndex = 
      job
      .fep
      .fepProcesses
      .findIndex(fepProcess => fepProcess.processType.name === selectedProcessType.name)
      // .indexOf(job.fep.fepProcesses.find(fepProcess => fepProcess.processType.name === selectedProcessType.name))
    
    const fepSubProcessIndex =
      job.fep.fepProcesses[fepProcessIndex].fepSubProcesses
      .findIndex(subProcess => subProcess.name === workOrderToAdd.fepSubProcess.value.name)
      // .indexOf(job.fep.fepProcesses[fepProcessIndex].fepSubProcesses.find(subProcess => subProcess.name === workOrderToAdd.fepSubProcess.value.name))

    const foundFepSubProcess = job.fep.fepProcesses[fepProcessIndex].fepSubProcesses[fepSubProcessIndex]

    console.log('found fep sub proces', foundFepSubProcess)

    // Manpower
    const manpowerToInsert = (() => {
      switch(manpowerType) {
        case 'Individual':
          return {
            ...initialManpower,
            individual: individualManpower
          }

        case 'Team':
          return {
            ...initialManpower,
            workOrderTeam: {
              ...initialWorkOrderTeam,
              leader: leaderManpower,
              members: teamManpowerMembers
            }
          }

        default:
          return
      }
    })()

    console.log('work order to add sub process:', workOrderToAdd.fepSubProcess)
    console.log('selected machine', selectedWorkOrderMachine)

    const newWorkOrder = {
      ...workOrderDefaults,
      uuid: uuidv4(),
      fepSubProcess: workOrderToAdd.fepSubProcess.value,
      programRun: workOrderToAdd.programRun,
      machine: selectedWorkOrderMachine,
      startTime: startTime,
      endTime: startTime + totalTime,
      date: dateInfo,
      job: {id: selectedJob.id, name: selectedJob.name},
      name: `WO_${foundFepSubProcess.name}_${btoa(Date.now().toString() + Math.random().toString())}`,
      // fepSubProcess : { id: foundFepSubProcess.id, name: foundFepSubProcess.name },
      manpower: manpowerToInsert
    }

    const newWorkOrdersToView = [...workOrdersToView]
    newWorkOrdersToView.unshift(newWorkOrder)

    console.log('new work orders to view:', newWorkOrdersToView)

    setWorkOrdersToView(newWorkOrdersToView)

    const newJob = {...job}
    newJob.workOrders.unshift(newWorkOrder)

    setJob(newJob)

    const newWorkOrderModifications = [...workOrderModifications]

    const workOrderToModify = {
      ...initialWorkOrderModification,
      name: newWorkOrder.name,
      uuid: uuidv4(),
      programRun: newWorkOrder.programRun,
      startTime: newWorkOrder.startTime,
      endTime: newWorkOrder.endTime,
      date: newWorkOrder.date,
      machine: {
        machineLogToAdd: null,
        data: {
          id: newWorkOrder.machine.id, 
          name: newWorkOrder.machine.name
        }
      },
      fepSubProcess: { id: newWorkOrder.fepSubProcess.id, name: newWorkOrder.fepSubProcess.name },
      manpower: manpowerToInsert,
      jobId: selectedJob.id
    }

    newWorkOrderModifications.unshift({ type: 'add', data: workOrderToModify })

    setWorkOrderModifications(newWorkOrderModifications)

    console.log('work order modifications:', newWorkOrderModifications)
  }

  const handleDeleteWorkOrder = (workOrderUuid, subProcessName) => {
    const newWorkOrdersToView = [...workOrdersToView]
    const foundWorkOrderToView = newWorkOrdersToView.find(workOrder => workOrder.uuid === workOrderUuid)
    newWorkOrdersToView.splice(newWorkOrdersToView.indexOf(foundWorkOrderToView), 1)
    
    setWorkOrdersToView(newWorkOrdersToView)

    const fepProcessIndex = 
      job.fep.fepProcesses
      .indexOf(job.fep.fepProcesses.find(fepProcess => fepProcess.processType.name === selectedProcessType.name))
    
    const fepSubProcessIndex = 
      job.fep.fepProcesses[fepProcessIndex]
      .fepSubProcesses
      .indexOf(job.fep.fepProcesses[fepProcessIndex].fepSubProcesses.find(subProcess => subProcess.name === subProcessName))
    
    const newJob = {...job}
    // const workOrders = newJob.fep.fepProcesses[fepProcessIndex].fepSubProcesses[fepSubProcessIndex].workOrders
    const foundWorkOrder = newJob.workOrders.find(workOrder => workOrder.uuid === workOrderUuid)

    console.log('found work order:', workOrderUuid, foundWorkOrder)

    newJob.workOrders.splice(newJob.workOrders.findIndex(workOrder => workOrder.uuid === workOrderUuid), 1)
    // workOrders.splice(workOrders.indexOf(foundWorkOrder), 1)

    setJob(newJob)

    const workOrderToModify = {
      type: 'delete',
      data: {
        ...initialWorkOrderModification,
        id: foundWorkOrder.id
      }
    }

    const newWorkOrderModification = [...workOrderModifications, workOrderToModify]

    setWorkOrderModifications(newWorkOrderModification)
    console.log('work order modification:', newWorkOrderModification)
  }

  const handleDeleteWorkOrderByView = selectedWorkOrder => {
    const workOrderToModify = {
      type: 'delete',
      data: {
        ...initialWorkOrderModification,
        id: selectedWorkOrder.id  
      }
    }

    // console.log('selectedwork order:', selectedWorkOrder)

    setWorkOrderModifications([...workOrderModifications, workOrderToModify])
    setWorkOrdersToView([...workOrdersToView].filter(workOrder => workOrder.id !== selectedWorkOrder.id))
    
    console.log('work order modifications:', workOrderModifications)
  }

  const handleSelectJob = jobValue => {
    (async () => {
      setSelectedJob(jobValue)
      
      if(jobValue !== null) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/jobs/${jobValue.id}`)
        const jobJson = await response.json()
        
        setJob(jobJson)
      }
    })()
  }

  const MachinesPainter = props => {
    const machinePositionClone = {...defaultMachinePosition} 

    return props.machines.map(machine => {
      const positionX = machinePositionClone.x
      const positionY = machinePositionClone.y

      machinePositionClone.y = machinePositionClone.y + 30
      // console.log('new machine position:'

      return (
        < >
          <Text
            x={positionX}
            y={positionY}
            text={machine.name}
            fontSize={16}
          />
          {
            props.workOrders
            .filter(workOrder => workOrder.machine.name === machine.name)
            .map(workOrder => {
              // 25200 is seconds representation of 7.00
              // 30600 is seconds representation of 8.30
              // 79200 is seconds representation of 22.00
              // 32400 is the subtraction of seconds representation of 17.30 and 8.30
              // 54000 is the subtraction of seconds representation of 22.00 and 7.00
              const jobWindowInterval = jobXPosition.end - jobXPosition.start
              // console.log('job window interval', jobXPosition.end, jobXPosition.start, jobWindowInterval)
              const normalizedStartTime = ((workOrder.startTime - 25200) / (54000 / jobWindowInterval)) + jobXPosition.start
              const normalizedEndTime = ((workOrder.endTime - 25200) / (54000 / jobWindowInterval)) + jobXPosition.start
              
              // console.log('start time:', normalizedStartTime)

              // console.log('job', workOrder.name)
              // console.log('inner width & job x end:', window.innerWidth, jobXPosition.end)
              // console.log('job start time:', normalizedStartTime)
              // console.log('job end time:', normalizedEndTime)

              return (
                <Rect 
                  x={normalizedStartTime}
                  y={positionY}
                  width={normalizedEndTime - normalizedStartTime}
                  height={20}
                  fill="orange"
                />
              )
            })
          }
        </>
      )  
    }) 
  }

  return (
    < >
      <Box my={1}>
        <Button variant="contained" color="primary" onClick={() => handleSave()}>Save</Button>   
      </Box>
      <div>
        Work order add page
      </div>
      <div>
        {dateInfo === null || dateInfo === '' ? 'No date provided' : query.get('date')}
      </div>
      <Box my={2}>
        <AsyncAutocomplete
          url={`${process.env.REACT_APP_BASE_URL}/processtypes/search`}
          label="Select Process Type"
          value={selectedProcessType}
          onChange={value => {
            handleSelectProcessType(value)
          }}
          param="name"
        />
      </Box>
      <Box my={1}>
        <AsyncAutocompleteJobs
          url={`${process.env.REACT_APP_BASE_URL}/jobs/search`}
          label="Select Job"
          value={selectedJob}
          onChange={value => {
            handleSelectJob(value)
            setRequestStatus('notAsked')
          }}
          param="name"
        />
      </Box>
      <Box mt={5} mb={1}>
        Work Order for {dateInfo}
      </Box>
      <Box display="flex" alignItems="center">
        Display Work Order Summary:
        <Box ml={2}>
          <Switch checked={displayWorkOrderSummary} onClick={() => setDisplayWorkOrderSummary(!displayWorkOrderSummary)} />
        </Box>
        
      </Box>
      <Box>
      {
        !displayWorkOrderSummary 
        ? < ></>
        : <Box>
            <List>
              { 
                workOrdersToView.map(workOrder => 
                  <ListItem button onClick={() => handleDeleteWorkOrderByView(workOrder)}>
                    <ListItemIcon>
                      <DeleteIcon />  
                    </ListItemIcon>
                    
                    (Job: {workOrder.job.name})&nbsp;

                    <strong>{workOrder.machine === null ? '' : workOrder.machine.name}&nbsp;
                      ({secondsToHms(workOrder.startTime)}-{secondsToHms(workOrder.endTime)})&nbsp;
                    </strong>&nbsp;

                    {
                      (() => {
                        const unwrappedMachine = workOrder.machine === null ? machineDefaults : workOrder.machine
                        
                        if(unwrappedMachine.processType.name === 'Punching') {
                          return `${workOrder.fepSubProcess === null ? '' : workOrder.fepSubProcess.programName}`
                        }
                        else {
                          const outputItems = workOrder.fepSubProcess === null ? [] : workOrder.fepSubProcess.fepSubProcessOutputItem
                          const outputItemNames = outputItems.map(outputItem => outputItem.item.partName)

                          return `${outputItemNames.length > 0 ? outputItemNames[0] : ''}`
                        }
                      })()
                    }&nbsp;

                    x{workOrder.programRun}
                  </ListItem>
                )
              }
            </List>
          </Box>
      }
      
      </Box>
      {
        job.id === 0 || selectedProcessType === null
        ? < ></>
        : <Box my={2}>
            <Box alignItems="center">
              <h3>Work orders:</h3>
              {
                job.fep === null
                ? <></>
                : <Box>
                    <Box>
                      Job qty: {job.qty}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box>
                        View FEP: {job.fep?.name} v{job.fep?.version}
                      </Box>
                      <Box>
                        {
                          job.fep?.isActive 
                          ? <strong style={{color: green[500]}}>[ Active ]</strong>
                          : <strong style={{color: red[500]}}>[ Inactive] </strong>
                        }
                      </Box>
                      <Box ml={2}>
                        <a href={`/fep/${job.fep?.id}`} target="_blank">
                          <Button variant="outlined" color="primary">
                            FEP
                          </Button>
                        </a>
                      </Box>
                    </Box>
                  </Box>
              }
              
              <Box>
                {/* Work order lists */}
                <Grid container>
                {
                  (() => {
                    return workOrdersToView.map(workOrderToView => {
                      const filteredSubProcess = job.fep.fepProcesses
                        .find(fepProcess => fepProcess.processType.name === selectedProcessType.name)
                        .fepSubProcesses
                        .find(subProcess => subProcess.uuid === workOrderToView.fepSubProcess.uuid)

                      console.log('filterd sub proces', filteredSubProcess, workOrderToView)

                      if (selectedProcessType.name === 'Punching') {
                        return (
                          filteredSubProcess === undefined
                          ? < ></>
                          : <Box m={1}>
                              <Paper elevation={3} style={{width: 275}}>
                                <CardContent>
                                  <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                      <strong style={{color: blue[500]}}>{filteredSubProcess.programName}</strong>&nbsp;
                                      <strong style={{color: deepPurple[500]}}>{workOrderToView.machine.name}</strong>
                                    </Box>
                                    <Fab 
                                      size="small" 
                                      color="secondary" 
                                      variant="round"
                                      onClick={() => handleDeleteWorkOrder(workOrderToView.uuid, filteredSubProcess.name)}
                                    >
                                      <CloseIcon />
                                    </Fab>
                                  </Box>
                                  <strong>{workOrderToView.job.name}</strong>
                                  {/* {filteredSubProcess.name}, */}
                                  {/* <strong style={{color: orange[800]}}>{workOrderToView.programRun}/{filteredSubProcess.programRun * job.qty}</strong>&nbsp; */}
                                  <strong style={{color: orange[800]}}>{workOrderToView.programRun}/{filteredSubProcess.programRun}</strong>&nbsp;
                                  <div>
                                    ({secondsToHms(workOrderToView.startTime)} - {secondsToHms(workOrderToView.endTime)})
                                  </div>
                                  <div>
                                    Time needed: {secondsToHms(workOrderToView.endTime - workOrderToView.startTime)}
                                  </div>
                                  <Box my={1}>
                                    <ViewManpowerCard data={workOrderToView.manpower} />
                                  </Box>
                                </CardContent>
                              </Paper>
                            </Box>  
                        )
                      }
                      else {
                        return (
                          filteredSubProcess === undefined
                          ? < ></>
                          : <Box m={1}>
                              <Paper elevation={3} style={{width: 275}}>
                                <CardContent>
                                  <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Box>
                                      <strong style={{color: blue[500]}}>
                                        {filteredSubProcess.fepSubProcessOutputItem.length > 0 ? filteredSubProcess.fepSubProcessOutputItem[0].item.partName : ''}
                                      </strong>
                                      &nbsp;
                                      <strong style={{color: deepPurple[500]}}>{workOrderToView.machine.name}</strong>
                                    </Box>
                                    <Fab 
                                      size="small" 
                                      color="secondary" 
                                      variant="round"
                                      onClick={() => handleDeleteWorkOrder(workOrderToView.uuid, filteredSubProcess.name)}
                                    >
                                      <CloseIcon />
                                    </Fab>
                                  </Box>
                                  <strong>{workOrderToView.job.name}</strong>
                                  {/* {filteredSubProcess.name}, */}
                                  {/* <strong style={{color: orange[800]}}>{workOrderToView.programRun}/{filteredSubProcess.programRun * job.qty}</strong>&nbsp; */}
                                  <strong style={{color: orange[800]}}>{workOrderToView.programRun}/{filteredSubProcess.programRun}</strong>&nbsp;
                                  <div>
                                    ({secondsToHms(workOrderToView.startTime)} - {secondsToHms(workOrderToView.endTime)})
                                  </div>
                                  <div>
                                    Time needed: {secondsToHms(workOrderToView.endTime - workOrderToView.startTime)}
                                  </div>
                                  <Box my={1}>
                                    <ViewManpowerCard data={workOrderToView.manpower} />
                                  </Box>
                                </CardContent>
                              </Paper>
                            </Box>
                        )
                      }
                    })
                  })()
                }
                </Grid>
              </Box>
              
            </Box>
            <Box display="flex" alignItems="center" my={2}>
                <SyncAutocompleteValue
                  label="FEP Sub Process"
                  value={workOrderToAdd === null ? null : workOrderToAdd.fepSubProcess}
                  options={(() => {
                    if(selectedProcessType.name === 'Punching') {
                        return (
                          job.fep.fepProcesses
                            .find(fepProcess => fepProcess.processType.name === selectedProcessType.name)
                            .fepSubProcesses
                            .map(subProcess => {
                              const programRun = subProcess.programRun
                              // console.log('no of program runs:', programRun)

                              // Filter work order via sub process from job, then reduce
                              const filteredWorkOrders = job.workOrders
                                .filter(workOrder => workOrder.fepSubProcess.uuid === subProcess.uuid)

                              const runsDone = filteredWorkOrders.reduce((acc, workOrder) => acc + workOrder.programRun, 0)
                              const subProcessPunchingDisplay = `${subProcess.programName}:(${runsDone}/${programRun})`

                              return {label: subProcessPunchingDisplay, value: subProcess}
                            })
                        )
                    }
                    else {
                      return (
                        job.fep.fepProcesses
                          .find(fepProcess => fepProcess.processType.name === selectedProcessType.name).fepSubProcesses
                          .map(subProcess => {
                            // Filter work order via sub process from job, then reduce
                            const filteredWorkOrders = job.workOrders
                              .filter(workOrder => workOrder.fepSubProcess.uuid === subProcess.uuid)

                            const runsDone = filteredWorkOrders.reduce((acc, workOrder) => acc + workOrder.programRun, 0)
                            const unwrappedInputItem = subProcess.fepSubProcessOutputItem.length > 0 ? subProcess.fepSubProcessOutputItem[0].item.partName : '' 
                            const totalToDo = subProcess.fepSubProcessOutputItem.length > 0 ? subProcess.fepSubProcessOutputItem[0].qty * job.qty : 0
                            
                            const subProcessPunchingDisplay = `${unwrappedInputItem}:(${runsDone}/${totalToDo})`
                            
                            return {label: subProcessPunchingDisplay, value: subProcess}
                          })
                      ) 
                    }
                  })()}
                  onChange={value => {
                    console.log(value)
                    setWorkOrderToAdd({...workOrderToAdd, fepSubProcess: value})
                  }}
                />
                <Box mx={1}>
                  <TextField style={{width: 75}} label="Run" type="number" onInput={event => setWorkOrderToAdd({...workOrderToAdd, programRun: parseInt(event.target.value.trim())})} />
                </Box>
                <Box mx={1}>
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/machines/byprocesstype`}
                    label="Machine"
                    value={selectedWorkOrderMachine}
                    onChange={value => setSelectedWorkOrderMachine(value)}
                    param="name"
                    paramOverride={selectedProcessType.name}
                  />
                </Box>
                <Box>
                  {
                    workOrderToAdd.fepSubProcess === null
                    ? < ></>
                    : < >
                        <Box>
                          <Box>
                            {
                              // Filter work order based on sub process
                              job.workOrders
                                .filter(workOrder => workOrder.fepSubProcess.uuid === workOrderToAdd.fepSubProcess.value.uuid)
                                .reduce((acc, workOrder) => acc + workOrder.programRun, 0)
                            }
                            /
                            {
                              (() => {
                                const foundSubProcess = job.fep.fepProcesses
                                  .find(fepProcess => fepProcess.processType.name === selectedProcessType.name)
                                  .fepSubProcesses
                                  .find(subProcess => subProcess.uuid === workOrderToAdd.fepSubProcess.value.uuid)

                                switch(selectedProcessType.name) {
                                  case 'Punching':
                                    return foundSubProcess !== null ? foundSubProcess.programRun : 0

                                  default:
                                    return foundSubProcess.fepSubProcessOutputItem.length > 0 ? foundSubProcess.fepSubProcessOutputItem[0].qty * job.qty : 0
                                }
                              })()
                             
                            }
                          </Box>
                        </Box>
                        <Box>
                          Total time:&nbsp;
                          <strong>
                            {
                              (() => {
                                const timeSecs = workOrderToAdd.fepSubProcess.value.time * parseInt(workOrderToAdd.programRun)
                                return secondsToHms(timeSecs)
                              })()
                            }
                          </strong>
                        </Box>
                      </>
                  }
                </Box>
                <Box ml={3}>
                  <Fab size="small" color="primary" onClick={() => handleAddWorkOrder()}><AddIcon /></Fab>
                </Box>
                <Box display="flex" alignItems="center" ml={2} mt={2}>
                  <Checkbox checked={autoFill} onClick={() => setAutoFill(!autoFill)} />
                  Autofill
                  {
                    autoFill
                    ? < ></>
                    : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box ml={2}>
                          <KeyboardTimePicker
                            ampm={false}
                            openTo="hours"
                            views={['hours', 'minutes', 'seconds']}
                            format="HH:mm:ss" 
                            label="Start Time"
                            value={workOrderToAdd.startTime}
                            placeholder="08:30:00"
                            mask="__:__:__"
                            onChange={date => setWorkOrderToAdd({...workOrderToAdd, startTime: date})}
                          />
                        </Box>
                      </MuiPickersUtilsProvider>
                  }
                </Box>
                
              </Box>
              <Box>
                <Box>
                  <SyncAutocomplete 
                    label="Manpower Type"
                    value={manpowerType}
                    options={['Individual', 'Team']}
                    onChange={value => setManpowerType(value)}
                  />
                </Box>
                <Box>
                  {
                    (() => {
                      switch(manpowerType) {
                        case 'Individual':
                          return (
                            <AsyncAutocomplete 
                              url={`${process.env.REACT_APP_BASE_URL}/roles/bynamecontaining/production/users`}
                              label="Person in Charge"
                              value={individualManpower}
                              onChange={(value) => setIndividualManpower(value)}
                              param="name" 
                            />
                          )

                        case 'Team':
                          return (
                            <Box>
                              <Box display="flex" my={1}>
                                <Box>
                                  <Box>
                                    <AsyncAutocomplete 
                                      url={`${process.env.REACT_APP_BASE_URL}/roles/bynamecontaining/production/users`}
                                      label="Leader"
                                      value={leaderManpower}
                                      onChange={(value) => setLeaderManpower(value)}
                                      param="name" 
                                    />
                                  </Box>
                                </Box>
                                <Box ml={2} display="flex">
                                  <AsyncAutocomplete 
                                    url={`${process.env.REACT_APP_BASE_URL}/roles/bynamecontaining/production/users`}
                                    label="Member"
                                    value={tempManpowerMember}
                                    onChange={(value) => setTempManpowerMember(value)}
                                    param="name" 
                                  />
                                  <Box ml={2}>
                                    <Button 
                                      variant="outlined" 
                                      color="primary"
                                      onClick={() => handleAddManpowerMember()}
                                    >
                                      Add
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                              <Box>
                                <List>
                                  {
                                    teamManpowerMembers.map(member => (
                                      <ListItem button onClick={() => handleDeleteMember(member.id)}>
                                        <ListItemIcon>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        <Box ml={1}>
                                          {member.name}  
                                        </Box>
                                      </ListItem>
                                    ))
                                  }
                                </List>
                                
                              </Box>
                            </Box>
                            
                            
                          )

                        default:
                          return < ></>
                      }
                    })()
                  }
                </Box>
              </Box>
          </Box>
      }
      <Box border={1}>
        <Stage width={window.innerWidth} height={window.innerHeight}>
          <Layer>
            <Text 
              x={10}
              y={10}
              text="Machine"
              fontSize={16}
              onClick={() => alert('test')}
              style={{cursor: 'pointer'}}
            />
            {
              [...Array(16).keys()].map(content => {
                const xPos = (content * (window.innerWidth/18)) + jobXPosition.start
                // console.log('xpos & hour:', xPos, content + 7)

                return (
                  < >
                    <Text x={xPos + 5} text={`${content + 7}`} fontSize={16} />
                    <Line points={[xPos, 0, xPos, window.innerHeight]} stroke="black" />
                  </>
                )
              })
            }
            <Line points={[jobXPosition.end, 0, jobXPosition.end, window.innerHeight]} stroke="red" />
            <MachinesPainter machines={machines} workOrders={workOrdersToView} />
          </Layer>
        </Stage>
      </Box>
    </>
  )
}

// Redux stuff
const mapStateToProps = state => {
  return { currentLink: state.currentLink }
}

const mapDispatchToProps = dispatch => {
  return { changeLink: (link) => dispatch({type: 'CHANGE_LINK', link: link}) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderAdd)

// const fepDefaults = {
//   "id": 0,
//   "name": "",
//   "isActive": false,
//   "version": 1,
//   "fepProcesses": []
// }

const processTypeDefaults = {
  "id": 0,
  "name": "",
}

const jobDefaults = {
  id:	0,
  name:	"",
  qty: 0,
  priority:	"",
  qtyDone: 0,
  productionDeadline:	"2020-01-10",
  deliveryDeadline:	"2020-01-10",
  deadline:	"2020-01-10",
  jobDate:	"2018-12-31",
  referenceType: "",
  item:	null,
  poStatus:	null,
  ral: null,
  assignedTo:	null,
  sales: null,
  bom: null,
  fep: null,
  individualEntity: null,
  materialRequests:	null,
  purchaseRequests:	null,
  jobTypes:	null,
  uom: null,
}
 
const workOrderDefaults = {
  "id": 0,
  "name": "",
  "programRun": 0,
  "startTime": 50000,
  "endTime": 60000,
  "date": "2020-02-15",
  "machine": null,
  "fepSubProcess": null,
  "workOrderActuals": []
}

const machineDefaults = {
  "id": 1,
  "name": "",
  "description": "LVD 1",
  "purchasedDate": "2019-12-29",
  "status": "active",
  "processType": {
    "id": 2,
    "name": "Punching"
  },
  "maintenances": [],
  "calendars": []
}

const initialWorkOrderToAdd = {
  id: 0, fepSubProcess : null, programRun: 0, machine: null, startTime: 0, endTime: 0, job: null
}

const initialMachinePostBody = {
  id: 0,
  name: ''
}

const initialFepSubProcesPostBody = {
  id: 0,
  name: ''
}

const initialWorkOrderModification = {
  id: 0,
  name: '',
  programRun: 0,
  startTime: 0,
  endTime: 0,
  date: '2019-12-30',
  machine: initialMachinePostBody,
  fepSubProcess: initialFepSubProcesPostBody
}

const initialManpower = {
  id: 0,
  individual: null,
  workOrderTeam: null
}

const initialWorkOrderTeam = {
  leader: null,
  members: []
}