import React, { useEffect, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { makeReadableDateString, TableCellBordered } from "../../../helpers";
import { Job, PanelCode } from "../../../models/model";
import { RequestStatus } from "../../../models/RequestStatus";

const wiringProcesses = [
  { label: "Preparation Cable - Grouping", value: "Grouping" },
  { label: "Preparation Cable - Tubing/Crimping", value: "Tubing/Crimping" },
  { label: "Preparation Panel - Dinrail", value: "Dinrail" },
  {
    label: "Preparation Panel - Cable/Support Duct",
    value: "Cable/Support Duct",
  },
  { label: "Preparation Panel - Pasang Terminal", value: "Pasang Terminal" },
  { label: "Preparation Panel - Cutout Panel", value: "Cutout Panel" },
  { label: "Preparation Panel - Component", value: "Component" },
  { label: "Preparation Panel - Labeling", value: "Labeling" },
  { label: "Preparation Panel - Mimik/Nameplate", value: "Mimik/Nameplate" },
  { label: "Preparation panel - Ducting dan Pasang", value: "Tubing/Crimping" },

  { label: "Busbar - Potong", value: "Potong" },
  { label: "Busbar - Bending", value: "Bending" },
  { label: "Busbar - Lubang", value: "Lubang" },
  { label: "Busbar - Baut", value: "Baut" },
  { label: "Busbar - Heatshrink", value: "Heatshrink" },
  { label: "Busbar - Pasang", value: "Pasang" },
  { label: "Interkoneksi - WK", value: "WK" },
  { label: "Interkoneksi - LR", value: "LR" },
  { label: "Interkoneksi - Interkoneksi", value: "Interkoneksi" },
  { label: "Interkoneksi - Closing", value: "Closing" },
];

const WiringReport = () => {
  const apiKey = localStorage.getItem("apiKey");

  const [search, setSearch] = useState("");
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [jobs, setJobs] = useState<Job[]>([]);

  useEffect(() => {
    fetchWiringReport();
  }, []);

  const fetchWiringReport = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/wiringmanpower?all=true`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      const jobsData: Job[] | null | undefined = await response.json();

      console.log("Jobs data:", jobsData);

      if (jobsData) {
        setJobs([...jobsData]);
      }

      if (response.status !== 200)
        throw "Error fetching wiring manpower report";
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <h3>Wiring Report Page</h3>
        {requestStatus === RequestStatus.Loading ? (
          <Spinner animation="border" />
        ) : (
          <></>
        )}
      </div>
      <div className="d-flex">
        <div className="flex-grow-1">
          <Form.Control
            placeholder="Search by job name or customer name..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          height: "85vh",
          overflow: "auto",
          resize: "vertical",
          border: "1px solid gray",
        }}
      >
        <div>
          <Table size="sm" style={{ borderCollapse: "separate" }}>
            <thead>
              <tr style={{ backgroundColor: "lightblue" }}>
                <th
                  className="border border-dark align-middle text-center"
                  style={{
                    top: 0,
                    left: 0,
                    position: "sticky",
                    backgroundColor: "lightblue",
                    zIndex: 1,
                  }}
                >
                  <div style={{ minWidth: 400 }}>Job</div>
                </th>
                <th
                  className="border border-dark align-middle text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "lightblue",
                  }}
                >
                  Customer
                </th>
                <th
                  className="border border-dark align-middle text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "lightblue",
                    left: 411,
                    minWidth: 200,
                    zIndex: 1,
                  }}
                >
                  Code
                </th>
                <th
                  className="border border-dark align-middle text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "lightblue",
                  }}
                >
                  Connections
                </th>
                <th
                  className="border border-dark align-middle text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "lightblue",
                  }}
                >
                  Prod. Deadline
                </th>
                <th
                  className="border border-dark align-middle text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "lightblue",
                    left: 611,
                    zIndex: 1,
                  }}
                >
                  Percentage
                </th>
                {/* <TableCellBordered>Test</TableCellBordered> */}
                {wiringProcesses.map((wiringProcess) => {
                  return (
                    <th
                      className="border border-dark align-middle text-center"
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "yellow",
                      }}
                    >
                      {wiringProcess.value}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {jobs
                .filter((job) => {
                  const criterion =
                    `${job.name}${job.individualEntity?.name}`.toLowerCase();

                  return criterion.includes(search.toLowerCase());
                })
                .map((job) => {
                  // Remove only first element
                  const newPanelCodes = [...(job.panelCodes ?? [])];
                  newPanelCodes.shift();

                  const fetchWiringProcessTypeLength = (
                    panelCode: PanelCode
                  ) => {
                    return panelCode.panelCodeWiringProcessTypes?.filter(
                      (wiringProcessType) => wiringProcessType.includes
                    ).length;
                  };

                  const fetchPanelCodeInfo = (panelCode: PanelCode) => {
                    return panelCode.panelCodeWiringProcessTypes
                      ?.filter((wiringProcessType) => wiringProcessType.includes)
                      .map((wiringProcessType) => {
                        const filteredWiringProcessTypes =
                          panelCode.panelCodeWiringProcessTypes?.filter(
                            (wiringProcessType) => wiringProcessType.includes
                          ).length;

                        const filteredWorkOrders =
                          panelCode.wiringWorkOrders.filter(
                            (wiringWorkOrder) =>
                              wiringWorkOrder.wiringProcessType ===
                              wiringProcessType.wiringProcessType?.name
                          );

                        const workOrdersLength = filteredWorkOrders.length;

                        const workOrdersUsed = filteredWorkOrders.filter(
                          (wiringWorkOrder) =>
                            wiringWorkOrder.doneStatus !== "CANCELLED"
                        ).length;

                        const done = filteredWorkOrders.filter(
                          (wiringWorkOrder) =>
                            wiringWorkOrder.doneStatus === "FINISHED"
                        ).length;

                        const fract =
                          workOrdersUsed > 0 ? done / workOrdersUsed : 0;
                        const percent = Math.round(fract * 100);

                        // return `${wiringProcessType.wiringProcessType?.name} | T${workOrdersLength} U${workOrdersUsed} F${done} P${percent}% W${percent / filteredWiringProcessTypes}%`;

                        return percent / (filteredWiringProcessTypes ?? 1);
                      })
                      .reduce((acc, percent) => acc + percent, 0);
                  };

                  // const firstPanelCode = fetchPanelCodeInfo(job.panelCodes[0]).join(", ");
                  const firstPanelCodePercentage = job.panelCodes?.[0]
                    ? Math.round(fetchPanelCodeInfo(job.panelCodes?.[0]) ?? 0)
                    : 0;

                  const panelCodePercentageColor = (percentage: number) => {
                    if (percentage <= 0) {
                      return "salmon";
                    } else if (percentage <= 33) {
                      return "yellow";
                    } else if (percentage < 100) {
                      return "lightblue";
                    } else {
                      return "lightgreen";
                    }
                  };

                  const fetchTotalWiringProcess = (
                    panelCode: PanelCode,
                    processName: string
                  ) => {
                    const totalWiringProcesses =
                      panelCode.wiringWorkOrders.filter(
                        (wiringWorkOrder) =>
                          wiringWorkOrder.wiringProcessType === processName
                      ).length;

                    const totalWiringProcessesDone =
                      panelCode.wiringWorkOrders.filter(
                        (wiringWorkOrder) =>
                          wiringWorkOrder.wiringProcessType === processName &&
                          wiringWorkOrder.doneStatus === "FINISHED"
                      ).length;

                    const color = (() => {
                      return panelCode.panelCodeWiringProcessTypes?.find(
                        (wiringProcessType) =>
                          wiringProcessType.wiringProcessType?.name ===
                          processName
                      )?.includes
                        ? (() => {
                            if (totalWiringProcessesDone === 0) {
                              return "salmon";
                            } else if (
                              totalWiringProcessesDone < totalWiringProcesses
                            ) {
                              return "yellow";
                            } else {
                              return "lightgreen";
                            }
                          })()
                        : "black";
                    })();

                    return {
                      color: color,
                      label: `${totalWiringProcessesDone}/${totalWiringProcesses}`,
                    };
                  };

                  return (
                    <>
                      <tr>
                        <TableCellBordered
                          rowSpan={job.panelCodes?.length}
                          style={{
                            left: 0,
                            position: "sticky",
                            backgroundColor: "white",
                            minWidth: 300,
                          }}
                        >
                          {job.name}
                        </TableCellBordered>
                        <TableCellBordered rowSpan={job.panelCodes?.length}>
                          {job.individualEntity?.name}
                        </TableCellBordered>
                        <TableCellBordered
                          style={{
                            backgroundColor: "white",
                            position: "sticky",
                            minWidth: 200,
                            left: 411,
                          }}
                        >
                          {" "}
                          {job.panelCodes?.[0].type} -{" "}
                          {job.panelCodes?.[0].name} :{" "}
                          {job.panelCodes?.[0].serialNumber}{" "}
                        </TableCellBordered>
                        <TableCellBordered>
                          {job.panelCodes?.[0].connections}
                        </TableCellBordered>
                        <TableCellBordered
                          rowSpan={job.panelCodes?.length ?? 0}
                        >
                          {makeReadableDateString(
                            new Date(job.productionDeadline)
                          )}
                        </TableCellBordered>
                        {/* <TableCellBordered style={{ backgroundColor: "salmon" }}>0%</TableCellBordered> */}
                        <TableCellBordered
                          style={{
                            backgroundColor: panelCodePercentageColor(
                              firstPanelCodePercentage
                            ),
                            position: "sticky",
                            left: 611,
                          }}
                        >
                          {/* {fetchWiringProcessTypeLength(job.panelCodes[0])} */}
                          {/* <span className="bg-danger text-white">{JSON.stringify(job.panelCodes[0].panelCodeWiringProcessTypes.map(wiringProcessType => wiringProcessType.wiringProcessType?.name).join(", "))}</span> */}
                          {/* {firstPanelCode} */}
                          {firstPanelCodePercentage}%
                          {/* {JSON.stringify(job.panelCodes[0].wiringWorkOrders.map(wiringWorkOrder => `${wiringWorkOrder.wiringProcessType}: ${wiringWorkOrder.isDone}`))} */}
                        </TableCellBordered>
                        {wiringProcesses.map((wiringProcess) => {
                          return (
                            <TableCellBordered
                              style={{
                                backgroundColor: job.panelCodes?.[0]
                                  ? fetchTotalWiringProcess(
                                      job.panelCodes[0],
                                      wiringProcess.value
                                    ).color
                                  : "",
                              }}
                            >
                              {job.panelCodes?.[0]
                                ? fetchTotalWiringProcess(
                                    job.panelCodes[0],
                                    wiringProcess.value
                                  ).label
                                : ""}
                            </TableCellBordered>
                          );
                        })}
                      </tr>
                      {newPanelCodes.map((panelCode) => {
                        // const panelCodeInfo = panelCode.panelCodeWiringProcessTypes
                        //   .filter(wiringProcessType => wiringProcessType.includes)
                        //   .map(wiringProcessType => wiringProcessType.wiringProcessType?.name)
                        //   .join(", ")
                        // const panelCodeInfo = fetchPanelCodeInfo(panelCode).join(", ");
                        const panelCodePercentage = Math.round(
                          fetchPanelCodeInfo(panelCode) ?? 0
                        );

                        return (
                          <tr>
                            <TableCellBordered
                              style={{
                                backgroundColor: "white",
                                position: "sticky",
                                minWidth: 200,
                                left: 411,
                              }}
                            >
                              {panelCode.type} - {panelCode.name} :{" "}
                              {panelCode.serialNumber}
                            </TableCellBordered>
                            <TableCellBordered>
                              {panelCode.connections}
                            </TableCellBordered>
                            {/* <TableCellBordered style={{ backgroundColor: "salmon" }}>0%</TableCellBordered> */}
                            <TableCellBordered
                              style={{
                                backgroundColor:
                                  panelCodePercentageColor(panelCodePercentage),
                                position: "sticky",
                                left: 611,
                              }}
                            >
                              {/* <span className="bg-danger text-white">{JSON.stringify(panelCode.panelCodeWiringProcessTypes.map(wiringProcessType => wiringProcessType.wiringProcessType?.name).join(", "))}</span> */}
                              {/* {panelCodeInfo} */}
                              {Math.round(fetchPanelCodeInfo(panelCode) ?? 0)}%
                              {/* {JSON.stringify(panelCode.wiringWorkOrders.map(wiringWorkOrder => `${wiringWorkOrder.wiringProcessType}: ${wiringWorkOrder.isDone}`))} */}
                            </TableCellBordered>
                            {wiringProcesses.map((wiringProcess) => {
                              return (
                                <TableCellBordered
                                  style={{
                                    backgroundColor: fetchTotalWiringProcess(
                                      panelCode,
                                      wiringProcess.value
                                    ).color,
                                  }}
                                >
                                  {
                                    fetchTotalWiringProcess(
                                      panelCode,
                                      wiringProcess.value
                                    ).label
                                  }
                                </TableCellBordered>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default WiringReport;
