import {
  Box,
  Button,
  CircularProgress,
  createMuiTheme,
  Dialog,
  Fab,
  List,
  ListItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { orange } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import {
  compareDeadlineInterval,
  days,
  makeDateString,
  makeReadableDateString,
  priorityColor,
  TableCellBordered,
} from "../../../helpers";
import {
  Job,
  PanelCode,
  User,
  WiringProcessType,
  WiringWorkOrder,
} from "../../../models/model";
import {
  initialJob,
  initialWiringWorkOrder,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";

const orangeTheme = createMuiTheme({
  palette: {
    primary: { main: orange[800] },
  },
});

type MoveWeek = "Next" | "Prev";

const WiringManpowerPage = () => {
  const apiKey = localStorage.getItem("apiKey");

  const history = useHistory();

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [addManpowerTitleInfo, setAddManpowerTitleInfo] = useState<{
    jobId: number;
    worker: User | null;
    processType: string;
    panelCode: PanelCode | null;
    jobName: string;
    date: string;
    wiringProcessType: WiringProcessType | null;
    description: string;
  }>({
    jobId: 0,
    worker: null,
    processType: "",
    panelCode: null,
    jobName: "",
    date: "",
    wiringProcessType: null,
    description: "",
  });
  // const [jobs, setJobs] = useState<CrudInfo<Job>>({
  //   ...initialCrudInfoNoData,
  //   data: {
  //     ...initialPageNoContent,
  //     content: []
  //   }
  // });
  const [jobs, setJobs] = useState<Job[]>([]);

  // Search
  const [jobSearchInput, setJobSearchInput] = useState<string>("");

  // Delete IDs
  const [wiringWorkOrderDeleteIds, setWiringWorkOrderDeleteIds] = useState<
    number[]
  >([]);

  // Predefined process type
  const [predefinedProcessType, setPredefinedProcessType] =
    useState<boolean>(true);
  const [wiringProcessTypes, setWiringProcessTypes] = useState<
    WiringProcessType[]
  >([]);

  useEffect(() => {
    if (requestStatus === RequestStatus.NotAsked) {
      fetchWiringWorkOrders();
      fetchWiringProcessTypes();
    }
  });

  const handleMoveWeek = (moveWeek: MoveWeek) => {
    console.log("Move week", moveWeek);
    switch (moveWeek) {
      case "Next":
        console.log("Next");
        setSelectedDate(
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            selectedDate.getDate()
          )
        );
        break;

      case "Prev":
        console.log("Prev");
        setSelectedDate(
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() - 1,
            selectedDate.getDate()
          )
        );
        break;
    }

    setRequestStatus(RequestStatus.NotAsked);
  };

  const fetchWiringWorkOrders = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/wiringmanpower?start=${start}&end=${end}`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Error fetching work orders";

      const jobsData: Job[] | null | undefined = await response.json();

      if (jobsData) {
        setJobs([...jobsData]);
      }

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.error(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const fetchWiringProcessTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/wiringprocesstypes`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Error fetching wiring process types!";

      const wiringProcessTypesData = await response.json();

      if (wiringProcessTypesData) {
        setWiringProcessTypes([...wiringProcessTypesData]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const startDate = makeDateString(
    new Date(selectedDate?.getFullYear(), selectedDate?.getMonth(), 1)
  );
  const endDate = makeDateString(
    new Date(selectedDate?.getFullYear(), selectedDate?.getMonth() + 1, 0)
  );

  const lastDate = new Date(endDate).getDate();

  const start = makeDateString(
    new Date(selectedDate?.getFullYear(), selectedDate?.getMonth(), 0)
  );
  const end = makeDateString(
    new Date(
      selectedDate?.getFullYear(),
      selectedDate?.getMonth(),
      lastDate + 1
    )
  );

  // jobs.forEach(job => console.log(`Job ${job.name}`, job.deadlines.map(deadline => deadline.deadlineDetails.map(detail => detail.plan))));

  const handleOpenAddManpowerDialog = (
    id: number,
    panelCode: PanelCode,
    jobName: string,
    date: string
  ) => {
    setAddManpowerTitleInfo({
      jobId: id,
      worker: null,
      processType: "",
      panelCode: panelCode,
      jobName: jobName,
      date: date,
      wiringProcessType: null,
      description: "",
    });
    setDialogOpen(true);
  };

  const handleAddWiringWorkOrder = (e: any) => {
    const newWiringWorkOrder: WiringWorkOrder = {
      ...initialWiringWorkOrder,
      job: {
        ...initialJob,
        id: addManpowerTitleInfo.jobId,
      },
      panelCode: addManpowerTitleInfo.panelCode
        ? addManpowerTitleInfo.panelCode
        : null,
      uuid: uuidv4(),
      isCustom: !predefinedProcessType,
      wiringProcessType: addManpowerTitleInfo.processType,
      wiringProcessTypeFix: addManpowerTitleInfo.wiringProcessType,
      worker: addManpowerTitleInfo.worker ? addManpowerTitleInfo.worker : null,
      wiringDescription: addManpowerTitleInfo.description,
      date: addManpowerTitleInfo.date,
    };

    console.log("New wiring work order:", newWiringWorkOrder);

    const newJobs = [...jobs];
    const foundJob = newJobs.find(
      (job) => job.id === addManpowerTitleInfo.jobId
    );

    if (foundJob) {
      foundJob.wiringWorkOrders = [
        { ...newWiringWorkOrder },
        ...(foundJob.wiringWorkOrders ?? []),
      ];
    }

    setJobs([...newJobs]);
  };

  const handleDeleteWiringWorkOrder = (
    jobId: number,
    wiringWorkOrderInput: WiringWorkOrder
  ) => {
    const newJobs = [...jobs];
    const foundJob = newJobs.find((job) => job.id === jobId);

    if (foundJob) {
      foundJob.wiringWorkOrders =
        foundJob.wiringWorkOrders?.filter(
          (wiringWorkOrder) =>
            wiringWorkOrder.uuid !== wiringWorkOrderInput.uuid
        ) ?? [];
    }

    setWiringWorkOrderDeleteIds([
      ...wiringWorkOrderDeleteIds,
      wiringWorkOrderInput.id,
    ]);
    setJobs(newJobs);
  };

  const handleSave = async (e: any) => {
    console.log("Saving!");

    try {
      setRequestStatus(RequestStatus.Loading);

      await Promise.all(
        jobs.map(async (job) => {
          await Promise.all(
            job.wiringWorkOrders?.map(async (wiringWorkOrder) => {
              return await fetch(
                `${process.env.REACT_APP_BASE_URL}/wiringworkorders`,
                {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: apiKey ? apiKey : "",
                  },
                  body: JSON.stringify({
                    ...wiringWorkOrder,
                    job: {
                      ...initialJob,
                      id: job.id,
                    },
                  }),
                }
              );
            }) ?? []
          );
        })
      );

      // Delete ids
      await Promise.all(
        wiringWorkOrderDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/wiringworkorders/${id}`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        })
      );

      setRequestStatus(RequestStatus.Success);
      window.location.reload();
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <ThemeProvider theme={createMuiTheme()}>
      <div>
        Wiring Manpower Page | start: {startDate}, end: {endDate}
        <ThemeProvider theme={orangeTheme}>
          <div style={{ display: "flex", margin: 15 }}>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleMoveWeek("Prev")}
              >
                <ChevronLeftIcon /> Prev Month
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleMoveWeek("Next")}
              >
                Next Month <ChevronRightIcon />
              </Button>
            </div>
            <div>
              <ThemeProvider theme={createMuiTheme()}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </ThemeProvider>
            </div>
            <ThemeProvider theme={createMuiTheme()}>
              {requestStatus === RequestStatus.Loading ? (
                <CircularProgress disableShrink />
              ) : (
                <></>
              )}
            </ThemeProvider>
          </div>
        </ThemeProvider>
        <div style={{ display: "flex", marginTop: 15, marginBottom: 15 }}>
          <TextField
            label="Search by job name..."
            onChange={(e) => setJobSearchInput(e.target.value)}
          />
        </div>
        <div style={{ overflow: "auto", border: "solid 2px black" }}>
          <TableContainer
            component={Paper}
            style={{ display: "flex", height: "75vh", resize: "vertical" }}
          >
            <Table size="small">
              <TableHead>
                <TableRow
                  style={{
                    position: "sticky",
                    top: 0,
                    left: 0,
                    backgroundColor: "paleturquoise",
                    zIndex: 1,
                  }}
                >
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: 10,
                        borderRadius: 19,
                        backgroundColor: "cadetblue",
                        color: "white",
                        fontSize: 18,
                      }}
                    >
                      Job
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "paleturquoise",
                      fontSize: 18,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: 10,
                        borderRadius: 19,
                        backgroundColor: "cadetblue",
                        color: "white",
                        fontSize: 18,
                      }}
                    >
                      Panel Code
                    </div>
                  </TableCell>
                  {[...Array(new Date(endDate).getDate()).keys()]
                    .map((date) => date + 1)
                    .map((date) => (
                      <TableCell style={{ fontSize: 18 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "cadetblue",
                            color: "white",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          <div>{date}</div>
                          <div>
                            {
                              days[
                                new Date(
                                  selectedDate.getFullYear(),
                                  selectedDate.getMonth(),
                                  date
                                ).getDay()
                              ]
                            }
                          </div>
                        </div>
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs
                  .filter((job) =>
                    job.name
                      .toLowerCase()
                      .includes(jobSearchInput.toLowerCase())
                  )
                  .map((job) => {
                    return (
                      <>
                        <TableRow>
                          <TableCellBordered
                            style={{
                              whiteSpace: "nowrap",
                            }}
                            rowSpan={(job.panelCodes?.length ?? 0) + 1}
                          >
                            <div>
                              {job.name} - Qty: <strong>{job.qty}</strong>
                            </div>
                            <div>
                              <strong style={{ marginLeft: 5, marginRight: 5 }}>
                                {job.individualEntity?.name}
                              </strong>
                              <strong
                                style={{
                                  backgroundColor: priorityColor(job.priority),
                                  color: "white",
                                  padding: 2,
                                }}
                              >
                                Priority: {job.priority}
                              </strong>
                              <strong
                                style={{
                                  backgroundColor: compareDeadlineInterval(
                                    new Date(),
                                    job.productionDeadline
                                  ),
                                  padding: 2,
                                  marginLeft: 2,
                                }}
                              >
                                (
                                {makeReadableDateString(
                                  new Date(job.productionDeadline)
                                )}
                                )
                              </strong>
                            </div>
                          </TableCellBordered>
                        </TableRow>
                        {job.panelCodes?.map((panelCode) => {
                          const interconnectionClosingPlan = job.deadlines
                            ?.filter(
                              (deadline) => deadline.name === "Interkoneksi"
                            )
                            .map((deadline) => deadline.deadlineDetails)
                            .flat()
                            .find(
                              (deadlineDetail) =>
                                deadlineDetail?.name === "Closing"
                            )?.plan;

                          const productionDeadline = job.productionDeadline;

                          const closestDeadline = (() => {
                            if (!interconnectionClosingPlan) {
                              return productionDeadline;
                            } else {
                              const interconnectionClosingPlanTime = new Date(
                                interconnectionClosingPlan
                              ).getTime();
                              const productionDeadlineTime = new Date(
                                productionDeadline
                              ).getTime();

                              return productionDeadlineTime <
                                interconnectionClosingPlanTime
                                ? productionDeadline
                                : interconnectionClosingPlan;
                            }
                          })();

                          const closestDeadlineTime = (() => {
                            if (!interconnectionClosingPlan) {
                              return new Date(productionDeadline).getTime();
                            } else {
                              const interconnectionClosingPlanTime = new Date(
                                interconnectionClosingPlan
                              ).getTime();
                              const productionDeadlineTime = new Date(
                                productionDeadline
                              ).getTime();

                              return productionDeadlineTime <
                                interconnectionClosingPlanTime
                                ? productionDeadlineTime
                                : interconnectionClosingPlanTime;
                            }
                          })();

                          return (
                            <TableRow>
                              <TableCell
                                style={{
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor: "lightgrey",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <div
                                  style={{
                                    backgroundColor: "floralwhite",
                                    padding: 10,
                                  }}
                                >
                                  <div>
                                    <strong>{job.name}</strong>
                                  </div>
                                  {/* {job.name} - {panelCode.name} - {panelCode.type} */}
                                  <div>
                                    {panelCode.name} - {panelCode.type} |{" "}
                                    <strong>
                                      SN: {panelCode.serialNumber}
                                    </strong>
                                  </div>
                                  <div>
                                    Prod DL:
                                    <strong
                                      style={{
                                        backgroundColor:
                                          compareDeadlineInterval(
                                            new Date(),
                                            productionDeadline
                                          ),
                                      }}
                                    >
                                      {makeReadableDateString(
                                        new Date(productionDeadline)
                                      )}
                                    </strong>
                                    , Interconnection Closing DL:
                                    <strong
                                      style={{
                                        backgroundColor:
                                          interconnectionClosingPlan
                                            ? compareDeadlineInterval(
                                                new Date(),
                                                interconnectionClosingPlan
                                              )
                                            : "none",
                                      }}
                                    >
                                      {interconnectionClosingPlan
                                        ? makeReadableDateString(
                                            new Date(interconnectionClosingPlan)
                                          )
                                        : ""}
                                    </strong>
                                  </div>
                                  {/* <div>Closest: {closestDeadline}, prod dl: {productionDeadline}, closing: {interconnectionClosingPlan}  </div> */}
                                </div>
                              </TableCell>
                              {[...Array(new Date(endDate).getDate()).keys()]
                                .map((date) => date + 1)
                                .map((date) => {
                                  const cellDate = new Date(
                                    selectedDate.getFullYear(),
                                    selectedDate.getMonth(),
                                    date
                                  );
                                  const dateString = makeDateString(cellDate);

                                  const matchedDeadlines = jobs
                                    .filter((jobArg) => jobArg.id === job.id)
                                    .map((job) => {
                                      const filteredDeadlines =
                                        job.deadlines?.filter((deadline) =>
                                          deadline.deadlineDetails
                                            .map((detail) => detail.plan)
                                            .includes(dateString)
                                        );

                                      // const deadlineDetails =
                                      //   filteredDeadlines
                                      //     .map(deadline => deadline.deadlineDetails)
                                      //     .flat();

                                      return {
                                        jobName: job.name,
                                        deadlines: filteredDeadlines,
                                      };
                                    });

                                  const weekendCheckerColor =
                                    cellDate.getDay() === 0 ||
                                    cellDate.getDay() === 6
                                      ? "orangered"
                                      : "white";

                                  return (
                                    <TableCellBordered
                                      style={{
                                        whiteSpace: "nowrap",
                                        padding: 0,
                                        margin: 0,
                                        backgroundColor: weekendCheckerColor,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          backgroundColor:
                                            (job.wiringWorkOrders
                                              ?.filter(
                                                (wiringWorkOrder) =>
                                                  wiringWorkOrder.date ===
                                                  dateString
                                              )
                                              .filter(
                                                (wiringWorkOrder) =>
                                                  wiringWorkOrder.panelCode
                                                    ?.id === panelCode.id
                                              )
                                              .filter((wiringWorkOrder) =>
                                                wiringWorkOrder.date
                                                  ? new Date(
                                                      wiringWorkOrder.date
                                                    ).getTime() >
                                                    closestDeadlineTime
                                                  : false
                                              ).length ?? 0) > 0
                                              ? "red"
                                              : weekendCheckerColor,
                                        }}
                                      >
                                        {matchedDeadlines.map((deadline) => {
                                          return (
                                            <Box>
                                              {deadline.deadlines?.map(
                                                (deadline) => {
                                                  const filteredDeadlineDetailString =
                                                    deadline.deadlineDetails
                                                      .filter(
                                                        (deadlineDetail) =>
                                                          deadlineDetail.plan ===
                                                          dateString
                                                      )
                                                      .map(
                                                        (deadlineDetail) =>
                                                          deadlineDetail.name
                                                      )
                                                      .join(", ");

                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        margin: 5,
                                                        padding: 5,
                                                        backgroundColor:
                                                          orange[100],
                                                        borderRadius: 15,
                                                      }}
                                                    >
                                                      <div>
                                                        <strong>
                                                          {deadline.name}:
                                                        </strong>
                                                      </div>
                                                      <div>
                                                        {
                                                          filteredDeadlineDetailString
                                                        }
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </Box>
                                          );
                                        })}
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <button
                                            style={{
                                              cursor: "pointer",
                                              display:
                                                cellDate.getDay() === 0
                                                  ? "none"
                                                  : "inline",
                                            }}
                                            onClick={(e) =>
                                              handleOpenAddManpowerDialog(
                                                job.id ?? 0,
                                                panelCode,
                                                job.name,
                                                dateString
                                              )
                                            }
                                          >
                                            Add
                                          </button>
                                        </div>
                                        <ul>
                                          {job.wiringWorkOrders
                                            ?.filter(
                                              (wiringWorkOrder) =>
                                                wiringWorkOrder.panelCode
                                                  ?.id === panelCode.id
                                            )
                                            .filter(
                                              (wiringWorkOrder) =>
                                                wiringWorkOrder.date ===
                                                dateString
                                            )
                                            .map((wiringWorkOrder) => {
                                              return (
                                                <li>
                                                  {wiringWorkOrder.worker?.name}{" "}
                                                  -{" "}
                                                  {wiringWorkOrder.isCustom
                                                    ? `${wiringWorkOrder.wiringProcessType}: ${wiringWorkOrder.wiringDescription}`
                                                    : `${wiringWorkOrder.wiringProcessTypeFix?.wiringProcessTypeGroup?.name} - ${wiringWorkOrder.wiringProcessTypeFix?.name}`}
                                                  :{" "}
                                                  {
                                                    wiringWorkOrder.wiringDescription
                                                  }
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    </TableCellBordered>
                                  );
                                })}
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
          <div style={{ margin: 20 }}>
            <h3>Manpower for date: {addManpowerTitleInfo.date}</h3>
            <h3>
              Job: {addManpowerTitleInfo.jobName} - Code:{" "}
              {addManpowerTitleInfo.panelCode?.name}
            </h3>
          </div>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            Type: Predefined{" "}
            <Switch
              checked={!predefinedProcessType}
              onClick={(e) => setPredefinedProcessType(!predefinedProcessType)}
            />{" "}
            Custom
          </div>
          <div style={{ display: "flex", margin: 20, alignItems: "center" }}>
            <div style={{ width: 200 }}>
              <AsyncSelectWrapper
                label="Worker"
                url={encodeURI(
                  `${process.env.REACT_APP_BASE_URL}/roles/byname/Production Worker/users`
                )}
                param="name"
                value={addManpowerTitleInfo.worker}
                onChange={(selected) =>
                  setAddManpowerTitleInfo({
                    ...addManpowerTitleInfo,
                    worker: selected,
                  })
                }
              />
            </div>

            <div style={{ width: 400, marginLeft: 15 }}>
              {predefinedProcessType ? (
                <>
                  Wiring Process Type
                  
                  <Select
                    options={wiringProcessTypes.map((wiringProcessType) => {
                      return {
                        label: `${wiringProcessType.wiringProcessTypeGroup?.name} - ${wiringProcessType.name}`,
                        value: wiringProcessType,
                      };
                    })}
                    onChange={(selected) =>
                      setAddManpowerTitleInfo({
                        ...addManpowerTitleInfo,
                        processType: (
                          selected as {
                            label: string;
                            value: WiringProcessType;
                          }
                        ).label,
                        wiringProcessType: (
                          selected as {
                            label: string;
                            value: WiringProcessType;
                          }
                        ).value,
                      })
                    }
                    value={{
                      label: addManpowerTitleInfo.wiringProcessType
                        ? `${addManpowerTitleInfo.wiringProcessType?.wiringProcessTypeGroup?.name} - ${addManpowerTitleInfo.wiringProcessType?.name}`
                        : "",
                      value: addManpowerTitleInfo.wiringProcessType,
                    }}
                  />
                </>
              ) : (
                <>
                  Custom Process Type
                  <TextField
                    label="Process type.."
                    fullWidth
                    // value={addManpowerTitleInfo.processType}
                    onChange={(e) =>
                      setAddManpowerTitleInfo({
                        ...addManpowerTitleInfo,
                        processType: e.target.value,
                      })
                    }
                  />
                </>
              )}
            </div>
            <div style={{ marginLeft: 15, width: 200 }}>
              <TextField
                fullWidth
                label="Description"
                onChange={(e) =>
                  setAddManpowerTitleInfo({
                    ...addManpowerTitleInfo,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div style={{ margin: 10 }}>
            <Fab
              size="small"
              color="primary"
              onClick={handleAddWiringWorkOrder}
            >
              <AddIcon />
            </Fab>
          </div>
          <div style={{ margin: 10 }}>
            <h3>Work Order List:</h3>
            <List>
              {jobs
                .find((job) => job.id === addManpowerTitleInfo.jobId)
                ?.wiringWorkOrders?.filter(
                  (wiringWorkOrder) =>
                    wiringWorkOrder.date === addManpowerTitleInfo.date
                )
                .map((wiringWorkOrder) => {
                  return (
                    <ListItem>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() =>
                          handleDeleteWiringWorkOrder(
                            addManpowerTitleInfo.jobId,
                            wiringWorkOrder
                          )
                        }
                      >
                        Delete
                      </Button>
                      <div style={{ margin: 5 }}>
                        {/* {wiringWorkOrder.worker?.name} - {wiringWorkOrder.wiringProcessType} */}
                        {wiringWorkOrder.worker?.name} -{" "}
                        {wiringWorkOrder.isCustom
                          ? `${wiringWorkOrder.wiringProcessType}: ${wiringWorkOrder.wiringDescription}`
                          : `${wiringWorkOrder.wiringProcessTypeFix?.wiringProcessTypeGroup?.name} - ${wiringWorkOrder.wiringProcessTypeFix?.name}: ${wiringWorkOrder.wiringDescription}`}
                      </div>
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default WiringManpowerPage;
