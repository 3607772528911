import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import { fetchToolInventories, intlFormat } from "../../../helpers";
import { v4 } from "uuid";
import { Link } from "react-router-dom";

const ToolsPage = () => {
  const ctx = useContext(AppContext);

  const toolInventories = useRef([] as any[]);
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  const toolNameFilter = useRef("");
  const serialNumberFilter = useRef("");

  const fetchToolInventoriesData = async () => {
    const d = await fetchToolInventories({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      toolInventories.current = d;
      render();
    }
  };

  const handleInit = async () => {
    fetchToolInventoriesData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/toolinventories-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(toolInventories.current),
        }
      );

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>Tools</h4>
          {/* <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div> */}
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div className="d-flex">
          <div>
            <Link to={`/tooldetail/new`}>
              <button
                className="btn btn-sm btn-primary"
                // onClick={() => {
                //   toolInventories.current = [
                //     ...toolInventories.current,
                //     { uuid: v4() },
                //   ];
                //   render();
                // }}
              >
                Add
              </button>
            </Link>
          </div>
          <div>
            <div>
              <small>
                <strong>Tool Name</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                placeholder="Tool name..."
                onChange={(e) => {
                  toolNameFilter.current = e.target.value;
                  render();
                }}
              />
            </div>
          </div>
          <div>
            <div>
              <small>
                <strong>SN</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                placeholder="SN..."
                onChange={(e) => {
                  serialNumberFilter.current = e.target.value;
                  render();
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="overflow-auto border border-dark"
          style={{ resize: "vertical", height: "75vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            {[
              "#",
              "Pembelian",
              "Nama",
              "Kategori",
              "Deskripsi",
              "SN",
              "Spesifikasi",
              "Brand",
              "Tipe",
              "Kalibrasi",
              "Kalibrasi Exp.",

              "No. kalibrasi",
              "Lokasi",
              "Status",
              "Action",
            ].map((h) => {
              return (
                <>
                  <th
                    className="bg-dark text-light px-1 m-0"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              );
            })}
            {toolInventories.current
              .filter(
                (t) =>
                  (toolNameFilter.current !== ""
                    ? t?.toolName
                        ?.toLowerCase()
                        ?.includes(toolNameFilter.current?.toLowerCase() ?? "")
                    : true) &&
                  (serialNumberFilter.current !== ""
                    ? t?.serialNumber
                        ?.toLowerCase()
                        ?.includes(
                          serialNumberFilter.current?.toLowerCase() ?? ""
                        )
                    : true)
              )
              .map((t, i) => {
                return (
                  <>
                    <tr key={t.uuid}>
                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">
                        {intlFormat({
                          date: t.purchaseDate,
                          dateStyle: "medium",
                        })}
                      </td>
                      <td className="border border-dark">{t.toolName}</td>
                      <td className="border border-dark">{t.category}</td>
                      <td className="border border-dark">{t.description}</td>
                      <td className="border border-dark">{t.serialNumber}</td>
                      <td className="border border-dark">{t.specification}</td>
                      <td className="border border-dark">{t.brand}</td>
                      <td className="border border-dark">{t.type}</td>
                      <td className="border border-dark">
                        {intlFormat({
                          date: t.dateCalibration,
                          dateStyle: "medium",
                        })}
                      </td>
                      <td className="border border-dark">
                        {intlFormat({
                          date: t.dateCalibrationExpiration,
                          dateStyle: "medium",
                        })}
                      </td>

                      <td className="border border-dark">
                        {t.calibrationNumber}
                      </td>
                      <td className="border border-dark">
                        {t.storageLocation}
                      </td>
                      <td className="border border-dark">{t.status}</td>
                      <td className="border border-dark">
                        <Link to={`/tooldetail/${t?.id}`}>
                          <button className="btn btn-sm btn-primary">
                            Details
                          </button>
                        </Link>
                      </td>
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      </div>
    </>
  );
};

export default ToolsPage;
