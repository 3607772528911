export interface Sort {
  sorted: boolean,
  unsorted: boolean,
  empty: boolean
}

export const initialSort: Sort = {
  sorted: false,
  unsorted: true,
  empty: true
}