import React from 'react'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'
import { Ral, Uom } from '../../../models/model'

const UomPage = (props: any) => {
  const mapperFunc = (uom: Uom) => {
    return {
      id: uom.id,
      content: [ uom.name ]
    }
  }

  return (
    <StatefulCrudTable<Uom>  
      url="uoms"
      head={['Name']}
      mapper={mapperFunc}
    />
  )
}

export default UomPage