import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import { fetchIntegrations } from "../../../helpers";
import { IntegrationView } from "../../../models/model";

const IntegrationListPage = () => {
  const ctx = useContext(AppContext);

  const [integrations, setIntegrations] = useState<IntegrationView[]>([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchIntegrationsData();
  };

  const fetchIntegrationsData = async () => {
    setIntegrations(await fetchIntegrations({ apiKey: ctx?.apiKey ?? "" }));
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex">
          <h4>Product Trees</h4>
          <div className="mx-2">
            <Link to="/integrationlist/new">
              <button className="btn btn-sm btn-primary">Add</button>
            </Link>
          </div>
        </div>

        <hr className="border border-dark" />

        <div
          className="overflow-auto shadow shadow-md my-3"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm table-hover table-bordered table-striped"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "Action", "Name", "Category", "Created", "Updated"].map(
                (th) => (
                  <td
                    className="border border-dark bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {th}
                  </td>
                )
              )}
            </tr>
            {(() => {
              const integrationsSorted = [...integrations];

              integrationsSorted.sort(
                (a, b) => (b.integration?.id ?? 0) - (a.integration?.id ?? 0)
              );

              return integrationsSorted;
            })().map((i, i_) => {
              return (
                <tr>
                  <td className="border border-dark">{i_ + 1}</td>
                  <td className="border border-dark">
                    <div className="d-flex">
                      <Link to={`/integrationlist/${i.integration?.id}`}>
                        <button className="btn btn-sm btn-primary">
                          Details
                        </button>
                      </Link>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={async () => {
                          if (
                            window.confirm(
                              `Really delete ${i.integration?.name}? This action cannot be undone.`
                            )
                          ) {
                            try {
                              const resp = await fetch(
                                `${process.env.REACT_APP_BASE_URL}/integrations/${i.integration?.id}/empty`,
                                {
                                  method: "delete",
                                  headers: { authorization: ctx?.apiKey ?? "" },
                                }
                              );

                              if (resp.status !== 200) throw await resp.text();
                              handleInit();
                            } catch (e) {}
                          }
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                  <td className="border border-dark">{i.integration?.name}</td>
                  <td className="border border-dark">
                    {i.integration?.category}
                  </td>

                  <td className="border border-dark">
                    {i.integration?.createdAt}
                  </td>
                  <td className="border border-dark">
                    {i.integration?.updatedAt}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default IntegrationListPage;
