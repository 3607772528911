import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalDialog, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { isForInStatement } from "typescript";
import { AppContext } from "../../../App";
import {
  checkBomDocumentTypeColor,
  createBomListFlattened,
  fetchExtInventory,
  fetchExtPurchaseRequestItemsOutstanding,
  fetchItems,
} from "../../../helpers";
import {
  BomLeveledRecursive,
  ExtInventory,
  ExtItem,
  ExtPrItemOutstanding,
  ExtPurchaseRequestPostBodyJson,
  ExtReserveList,
  JobBomLeveledView,
  JobMapped,
} from "../../../models/model";
import {
  initialExtPurchaseRequestPostBody,
  initialExtPurchaseRequestPostBodyItemJson,
  initialExtPurchaseRequestPostBodyJson,
  makeDateString,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import OutstandingJobForm from "./OutstandingJobForm";

const JobBomListSimulation = () => {
  const { id } = useParams() as { id: string };
  const ctx = useContext(AppContext);

  const [loading, setLoading] = useState(RequestStatus.NotAsked);
  const [jobsMapped, setJobsMapped] = useState<JobMapped | null>(null);
  const [items, setItems] = useState<ExtItem[]>([]);
  const [jobReserveList, setJobReserveList] = useState<ExtReserveList[]>([]);
  const [prItemsOutstanding, setPrItemsOutstanding] = useState<
    ExtPrItemOutstanding[]
  >([]);

  const [inventory, setInventory] = useState<ExtInventory[]>([]);

  const [showPrDialog, setShowPrDialog] = useState(false);
  const [prQtys, setPrQtys] = useState<
    {
      extItemId: number;
      prValue: number;
      qtyPrModifiable: number;

      inStock: ExtInventory | null | undefined;
    }[]
  >([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      setLoading(RequestStatus.Loading);

      const [
        itemsData,
        inventoryData,
        jobReserveListData,
        prItemsOutstandingData,
      ] = await Promise.all([
        fetchItems(process.env.REACT_APP_BASE_URL ?? "", ctx?.apiKey ?? ""),
        fetchExtInventory({
          apiKey: ctx?.apiKey ?? "",
        }),
        (async () => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/ext-reserve-list`
            );

            if (resp.status !== 200) throw await resp.text();

            return await resp.json();
          } catch (e) {
            return [];
          }
        })(),
        fetchExtPurchaseRequestItemsOutstanding({
          apiKey: ctx?.apiKey ?? "",
        }),
      ]);

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/${id}/full?compose=true`,
        {
          headers: { authorization: ctx?.apiKey ?? "" },
        }
      );

      if (resp.status !== 200) throw await resp.text();

      const jobsMapped = (await resp.json()) as JobMapped;

      const bomListFlattened = createBomListFlattened(
        jobsMapped.jobBomLeveleds ?? [],
        jobsMapped.jobBomLeveledsMapped ?? []
      );

      setPrQtys(
        [
          ...new Set(
            bomListFlattened
              .filter((b) => b.bom.bom?.bomLeveled?.extItemId)
              .map((b) => b.bom.bom?.bomLeveled?.extItemId)
          ),
        ]
          .filter((ix) => itemsData?.find((i) => i.id === ix))
          .map((extItemId) => {
            const inStock = inventoryData.find(
              (ix) => ix.products?.id === extItemId
            );

            const prNeeded = bomListFlattened
              .filter((bx) => bx?.bom.bom?.bomLeveled?.extItemId === extItemId)
              .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0);
            return {
              extItemId: extItemId ?? 0,
              inStock: inStock,
              prValue: prNeeded,
              qtyPrModifiable: prNeeded - (inStock?.qtyBalance ?? 0),

              prInShipmentQty: prItemsOutstandingData
                .filter(
                  (i) =>
                    i.productId === extItemId &&
                    (i.pr?.status === 0, // qty pr outstanding
                    i.pr?.status === 1, // qty pr approved
                    i.pr?.status === 2) // qty in shipment currently
                )
                .reduce((acc, i) => acc + (i.qtyPr ?? 0), 0),
            };
          })
      );

      setInventory(inventoryData);
      setJobsMapped(jobsMapped);
      setJobReserveList(jobReserveListData);
      setItems(itemsData ?? []);
      setPrItemsOutstanding(prItemsOutstandingData ?? []);

      // handleFetchExtPurchaseRequestItemsOutstanding({
      //   apiKey: ctx?.apiKey ?? "",
      // });
    } catch (e) {
      console.log("[error handle init]", e);
    } finally {
      setLoading(RequestStatus.NotAsked);
    }
  };

  async function handleFetchExtPurchaseRequestItemsOutstanding(params: {
    apiKey?: string | null;
  }) {
    setPrItemsOutstanding(
      await fetchExtPurchaseRequestItemsOutstanding({
        apiKey: params.apiKey ?? "",
      })
    );
  }

  const bomListFlattened = createBomListFlattened(
    jobsMapped?.jobBomLeveleds ?? [],
    jobsMapped?.jobBomLeveledsMapped ?? []
  );

  return (
    <>
      <Modal
        show={showPrDialog}
        onHide={() => {
          setShowPrDialog(false);
        }}
      >
        {(() => {
          const filteredPrItems = [
            ...new Set(
              bomListFlattened
                .filter((b) => b.bom.bom?.bomLeveled?.extItemId)
                .map((b) => b.bom.bom?.bomLeveled?.extItemId)
            ),
          ].filter((ix) => {
            const foundPrQty = prQtys.find((p) => p.extItemId === ix);
            return items.find(
              (i) => i.id === ix && foundPrQty && foundPrQty.qtyPrModifiable > 0
            );
          });

          const filteredPrItemsMapped = filteredPrItems.map((p) => {
            const foundPrQty = prQtys.find((i) => i.extItemId === p);

            return {
              item: foundPrQty,
              // prValue:
            };
          });

          return (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Automatic Create PR</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ol>
                  {filteredPrItems.map((itemId) => {
                    const foundItem = items.find((i) => i.id === itemId);
                    const foundPrQty = prQtys.find(
                      (p) => p.extItemId === itemId
                    );

                    return (
                      <>
                        <li>
                          <div className="d-flex align-items-center">
                            <div>
                              ({foundItem?.partNum ?? "[NO PART NUM]"} -{" "}
                              {foundItem?.mfr ?? "[NO MFR]"}){" "}
                              {foundItem?.partName} - {foundItem?.partDesc}
                            </div>
                            <div>
                              <input
                                className="form-control form-control-sm"
                                style={{ width: 75 }}
                                onChange={(e) => {
                                  setPrQtys(
                                    prQtys.map((px, ix) =>
                                      px.extItemId === itemId
                                        ? px
                                          ? {
                                              ...px,
                                              qtyPrModifiable: isNaN(
                                                parseFloat(e.target.value)
                                              )
                                                ? px.qtyPrModifiable
                                                : parseFloat(e.target.value),
                                            }
                                          : px
                                        : px
                                    )
                                  );
                                }}
                                defaultValue={foundPrQty?.qtyPrModifiable ?? 0}
                              />
                            </div>
                            <div className="mx-1">
                              {foundItem?.defaultUm} (in stock:{" "}
                              {
                                prQtys.find((p) => p.extItemId === itemId)
                                  ?.inStock?.qtyBalance
                              }
                              )
                            </div>
                          </div>
                          <div className="text-dark">
                            <hr className="border border-dark" />
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ol>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={async () => {
                      const newPr: ExtPurchaseRequestPostBodyJson = {
                        ...initialExtPurchaseRequestPostBodyJson,
                        prDate: new Date(
                          `${makeDateString(new Date())}T00:00:00Z`
                        )
                          .toISOString()
                          .split("T")[0],
                        prNumber: `PR/PPIC/${new Date().getFullYear()}/${
                          new Date().getMonth() + 1 < 10
                            ? `0${new Date().getMonth() + 1}`
                            : `${new Date().getMonth() + 1}`
                        }/${
                          new Date().getDate() < 10
                            ? `0${new Date().getDate()}`
                            : `${new Date().getDate()}`
                        }/`,
                        jobId: jobsMapped?.job?.id ?? 0,
                        extPurchaseRequestPostBodyItemsJson:
                          filteredPrItemsMapped.map((i) => ({
                            ...initialExtPurchaseRequestPostBodyItemJson,
                            productId: i.item?.extItemId ?? 0,
                            qtyPr: i.item?.qtyPrModifiable ?? 0,
                            jobId: jobsMapped?.job?.id ?? 0,
                          })),
                      };

                      try {
                        const resp = await fetch(
                          `${ctx?.baseUrl}/ext-purchase-requests-save`,
                          {
                            method: "post",
                            headers: {
                              authorization: ctx?.apiKey ?? "",
                              "content-type": "application/json",
                            },
                            body: JSON.stringify(newPr),
                          }
                        );

                        if (resp.status !== 200) throw await resp.text();
                      } catch (e) {
                        console.error(e);
                      }

                      setShowPrDialog(false);
                    }}
                  >
                    Create
                  </button>
                </div>
              </Modal.Footer>
            </>
          );
        })()}
      </Modal>
      <div className="m-3">
        <div className="d-flex align-items-end">
          <h5>
            PR simulation (BOM gabungan) for job [{jobsMapped?.job?.name}]
          </h5>

          <div>
            {loading === RequestStatus.Loading ? (
              <Spinner animation="border" />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="my-2">
          <hr />
        </div>
        <div>BOMs</div>
        <div>
          <ol>
            {jobsMapped?.jobBomLeveleds?.map((b) => {
              return (
                <li>
                  {b.jobBomLeveled?.qty}x {b?.jobBomLeveled?.bomLeveled?.name}
                </li>
              );
            })}
          </ol>
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setShowPrDialog(true);
            }}
          >
            Create PR
          </button>
        </div>
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ resize: "vertical", height: "60vh" }}
        >
          <table
            className="table table-sm table-striped table-bordered table-hover"
            style={{ borderCollapse: "separate" }}
          >
            <thead>
              {[
                "#",
                "BOM",
                "Type",
                "Name",
                "Item name",
                "Qty",
                "Qty multiplied",
                "Qty needed total",
                // "Qty in shipment",
                "MR for job",

                "Qty MR",

                "Rsv. for job",
                "Qty reserved",
                "In stock",
                "Free stock",
                "In shipment for job",
                "Qty in shipment",
                "Qty to PR",
                // "Balance",
                "UoM",
              ].map((h) => (
                <th
                  className="border border-dark bg-dark text-white sticky-top"
                  style={{ zIndex: 1 }}
                >
                  {h}
                </th>
              ))}
            </thead>
            <tbody>
              {bomListFlattened?.map((b, i) => {
                const foundItem = items.find(
                  (i) => i.id === b?.bom.bom?.bomLeveled?.extItemId
                );
                const itemInvalidBg =
                  b.bom.bom?.bomLeveled?.type === "Component"
                    ? !foundItem
                      ? "bg-danger text-light"
                      : bomListFlattened.filter(
                          (bx) =>
                            bx?.bom.bom?.bomLeveled?.extItemId ===
                            b?.bom.bom?.bomLeveled?.extItemId
                        ).length > 1
                      ? "bg-warning"
                      : ""
                    : "";

                const inStock = inventory.find(
                  (ix) => ix.products?.id === b.bom.bom?.bomLeveled?.extItemId
                );
                const flattenedNeededQty = bomListFlattened
                  .filter(
                    (bx) =>
                      bx?.bom.bom?.bomLeveled?.extItemId &&
                      bx?.bom.bom?.bomLeveled?.extItemId ===
                        b?.bom.bom?.bomLeveled?.extItemId
                  )
                  .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0);

                const qtyMaterialRequestedForJob = jobReserveList
                  .filter(
                    (r) =>
                      r.products?.id === b.bom.bom?.bomLeveled?.extItemId &&
                      r.transactionType === "mr" &&
                      r.jobId === jobsMapped?.job?.id
                  )
                  ?.reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0);
                const qtyToPr =
                  flattenedNeededQty -
                  (inStock?.qtyBalance ?? 0) -
                  qtyMaterialRequestedForJob;
                const filteredPrItemsOutstandingItemQty =
                  prItemsOutstanding.filter(
                    (i) => i.productId === b.bom.bom?.bomLeveled?.extItemId
                  );

                return (
                  <tr
                    // className={`${}`}
                    style={{
                      backgroundColor: !b.bom.bom?.bomLeveled?.type
                        ? "hotpink"
                        : checkBomDocumentTypeColor(
                            b.bom.bom?.bomLeveled?.type
                          ),
                    }}
                  >
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">{b.parent?.name}</td>
                    <td className="border border-dark">
                      {!b.bom.bom?.bomLeveled?.type ? (
                        <strong>BOM</strong>
                      ) : (
                        b.bom.bom?.bomLeveled?.type
                      )}
                    </td>
                    <td className="border border-dark">
                      {b.bom?.bom?.bomLeveled?.name}
                    </td>
                    <td className={`border border-dark ${itemInvalidBg}`}>
                      {b.bom?.bom?.bomLeveled?.type === "Component"
                        ? !foundItem &&
                          b.bom.bom?.bomLeveled?.type === "Component"
                          ? "Selected item invalid"
                          : `(${foundItem?.partNum ?? "[NO PARTNUM]"} - ${
                              foundItem?.mfr ?? "[NO MFR]"
                            }) - ${foundItem?.partName ?? "[NO PART NAME]"}: ${
                              foundItem?.partDesc ?? "[NO PART NAME]"
                              // } (0.0 x 0.0 x 0.0 mm)`
                            }`
                        : ""}
                    </td>
                    <td className={`border border-dark ${itemInvalidBg}`}>
                      {b.bom.bom?.bomLeveled?.qty}
                    </td>
                    <td className={`border border-dark ${itemInvalidBg}`}>
                      {b.bom.multiplier}
                    </td>
                    <td className={`border border-dark ${itemInvalidBg}`}>
                      {b.bom.bom?.bomLeveled?.type === "Component" ? (
                        flattenedNeededQty
                      ) : (
                        <></>
                      )}
                    </td>
                    {/* <td className="border border-dark bg-danger text-light">
                      0
                    </td> */}
                    <td className="border border-dark">
                      {qtyMaterialRequestedForJob}
                    </td>
                    <td className="border border-dark">
                      {jobReserveList
                        .filter(
                          (r) =>
                            r.products?.id ===
                              b.bom.bom?.bomLeveled?.extItemId &&
                            r.transactionType === "mr"
                          // r.jobId === jobsMapped?.job?.id
                        )
                        ?.reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0)}
                    </td>
                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component"
                          ? ""
                          : "bg-dark text-light"
                      }`}
                    >
                      {jobReserveList
                        .filter(
                          (r) =>
                            r.products?.id ===
                              b.bom.bom?.bomLeveled?.extItemId &&
                            r.transactionType === "rsv" &&
                            r.jobId === jobsMapped?.job?.id
                        )
                        ?.reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0)}{" "}
                    </td>
                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component"
                          ? ""
                          : "bg-dark text-light"
                      }`}
                    >
                      {jobReserveList
                        .filter(
                          (r) =>
                            r.products?.id ===
                              b.bom.bom?.bomLeveled?.extItemId &&
                            r.transactionType === "rsv"
                        )
                        ?.reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0)}
                    </td>
                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component" &&
                        (inStock?.qty ?? 0) < (flattenedNeededQty ?? 0)
                          ? "bg-danger text-light"
                          : ""
                      }`}
                    >
                      {inStock?.qty}
                    </td>

                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component" &&
                        (inStock?.qty ?? 0) < (flattenedNeededQty ?? 0)
                          ? "bg-danger text-light"
                          : ""
                      }`}
                    >
                      {inStock?.qtyBalance}
                    </td>
                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component"
                          ? ""
                          : "bg-dark text-light"
                      }`}
                    >
                      {filteredPrItemsOutstandingItemQty
                        .filter((i) => i?.jobId === jobsMapped?.job?.id)
                        .reduce((acc, i) => acc + (i.qtyPr ?? 0), 0)}
                    </td>
                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component"
                          ? ""
                          : "bg-dark text-light"
                      }`}
                    >
                      {filteredPrItemsOutstandingItemQty.reduce(
                        (acc, i) => acc + (i.qtyPr ?? 0),
                        0
                      )}
                    </td>
                    <td
                      className={`border border-dark ${
                        b.bom.bom?.bomLeveled?.type === "Component"
                          ? qtyToPr > 0
                            ? "bg-light"
                            : "bg-success"
                          : "bg-dark"
                      }`}
                    >
                      {qtyToPr < 0 ? <strong>None</strong> : qtyToPr}
                    </td>
                    {/* <td className="border border-dark">0</td> */}
                    <td className="border border-dark">
                      {foundItem?.defaultUm}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <div>
          {JSON.stringify(prItemsOutstanding)}
        </div> */}
        {/* <small>
          <pre>{JSON.stringify(jobsMapped?.jobBomLeveledsMapped, null, 2)}</pre>
        </small> */}
      </div>
    </>
  );
};

export default JobBomListSimulation;
