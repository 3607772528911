import React from 'react'
import { BomDocument } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const BomDocumentPage = (props: any) => {
  const mapperFunc = (bomDocument: BomDocument) => {
    return {
      id: bomDocument.id,
      content: [ bomDocument.name, bomDocument.version.toString() ]
    }
  }

  return (
    <StatefulCrudTable<BomDocument>  
      url="bomdocuments"
      head={['Name', 'Version']}
      mapper={mapperFunc}
    />
  )
}

export default BomDocumentPage