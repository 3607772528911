import React, { useContext, useEffect, useRef, useState } from "react";
import { initialJob, initialPanelCode } from "../../../models/modelinitials";
import { Job, JobMapped } from "../../../models/model";
import { AppContext } from "../../../App";
import { JobPriority, JobTypeSelection, fetchJob } from "../../../helpers";
import { useHistory, useParams } from "react-router-dom";
import { v4 } from "uuid";

const JobSimpleMode = () => {
  const { id } = useParams() as { id: any };
  const ctx = useContext(AppContext);
  const job = useRef({ job: { ...initialJob } } as unknown as {
    jobPriority: JobPriority;
    jobTypeSelections: JobTypeSelection[] | undefined;
    job: JobMapped;
  });
  const history = useHistory();

  useEffect(() => {
    handleInit();
  }, []);

  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const handleInit = async () => {
    fetchJobData();
  };

  const fetchJobData = async () => {
    if (isNaN(parseInt(id))) {
      return;
    }
    const d = await fetchJob({ id: id, apiKey: ctx?.apiKey ?? "" });

    if (d) {
      job.current = d;
      render();
    }
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/jobs-save`, {
        method: "post",
        headers: {
          authorization: ctx?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify(job.current.job),
      });

      history.push("/jobs");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>Job Simple Mode</h4>
          </div>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>

        <div>
          <hr className="border border-dark" />
        </div>

        <div>
          <small>
            <strong>Job name</strong>
          </small>
        </div>

        <div>
          <div>
            <input
              placeholder="Job name.."
              value={job.current.job.job?.name}
              className="form-control form-control-sm"
              onBlur={(e) => {}}
            />
          </div>
        </div>

        <div>
          <small>
            <strong>Products</strong>
          </small>
        </div>

        <div
          className="btn btn-sm btn-primary"
          onClick={() => {
            job.current.job.job?.panelCodes?.push({
              ...initialPanelCode,
              uuid: v4(),
            });
            render();
          }}
        >
          Add
        </div>
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ height: "65vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            {["#", "Type", "Name"].map((h) => {
              return (
                <>
                  <th className="bg-dark text-light">{h}</th>
                </>
              );
            })}
            {job.current.job.job?.panelCodes?.map((c, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <input
                        key={`ctype-${c.uuid}`}
                        className="form-control form-control-sm"
                        defaultValue={c.type ?? ""}
                        onBlur={(e) => {
                          c.type = e.target.value;
                          render();
                        }}
                      />
                    </td>
                    <td className="border border-dark">
                      <input
                        key={`cname-${c.uuid}`}
                        className="form-control form-control-sm"
                        defaultValue={c.name ?? ""}
                        onBlur={(e) => {
                          c.name = e.target.value;
                          render();
                        }}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default JobSimpleMode;
