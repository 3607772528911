import React, { useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  fetchBomLeveledsProtoSimple,
  fetchBomLeveledsRecursive,
  fetchBomLeveledsRecursiveProto,
  fetchExtInventory,
  fetchHandoverProto,
  fetchHandoversOuts,
  fetchHandoversProto,
  fetchJobsProtoSimple,
  fetchPanelCodesProtoSimple,
  fetchWarehouseItemsProto,
  getItemFullDescription,
  getItemFullDescriptionProto,
  getPanelCodeCompleteSerialNumber,
  getPanelCodeCompleteSerialNumberProto,
  getWarehouseItemDescription,
  intlFormat,
  makeDateString,
} from "../../../helpers";
import {
  PpicHandoverItems,
  PpicHandovers,
  PpicHandoverType,
  ppicHandoverTypeToJSON,
  PpicHandover_Status,
  ppicHandover_StatusToJSON,
  PpicJobs,
  PpicPanelCodes,
  WarehouseItems,
  handoverQAStatusFromJSON,
  HandoverQAStatus,
  ppicHandoverTypeFromJSON,
  PpicPanelCodeSerialNumberManual,
  PpicPanelCodeSerialNumber,
  PpicPanelCode,
  PpicJob,
  PpicBomLeveleds,
} from "../../../masterbigsystem";
import chroma from "chroma-js";
import ColorHash from "color-hash";
import { BomLeveledRecursive, ExtInventory } from "../../../models/model";
import { Print } from "@material-ui/icons";

export type ByCreatedDateType = "Created" | "HO Date";
export type HandoverViewType = "Default" | "Report Table" | "Outs Cost";
export const handoverTypes = [
  {
    label: ppicHandoverTypeToJSON(PpicHandoverType.FINISHED_GOODS),
    value: PpicHandoverType.FINISHED_GOODS,
  },
  {
    label: ppicHandoverTypeToJSON(PpicHandoverType.MR_LEFTOVERS),
    value: PpicHandoverType.MR_LEFTOVERS,
  },
  {
    label: ppicHandoverTypeToJSON(PpicHandoverType.MAT_INSTALLATION),
    value: PpicHandoverType.MAT_INSTALLATION,
  },
];
export const handoverStatuses = [
  {
    label: ppicHandover_StatusToJSON(PpicHandover_Status.APPROVED),
    value: PpicHandover_Status.APPROVED,
  },
  {
    label: ppicHandover_StatusToJSON(PpicHandover_Status.OUTSTANDING),
    value: PpicHandover_Status.OUTSTANDING,
  },
  {
    label: ppicHandover_StatusToJSON(PpicHandover_Status.CANCELLED),
    value: PpicHandover_Status.CANCELLED,
  },
  {
    label: "All",
    value: null,
  },
];
const HandoverPage = () => {
  const ctx = useContext(AppContext);

  const [, refersh] = useState(false);
  const render = () => {
    refersh((n) => !n);
  };

  const from = useRef(
    new Date(`${makeDateString(new Date())}T00:00:00`).toISOString()
  );
  const to = useRef(
    new Date(`${makeDateString(new Date())}T23:59:59`).toISOString()
  );

  const fromHoDate = useRef(
    new Date(`${makeDateString(new Date())}T00:00:00`).toISOString()
  );
  const toHoDate = useRef(
    new Date(`${makeDateString(new Date())}T23:59:59`).toISOString()
  );
  const selectedOutsCost = useRef("Outs" as "Outs" | "Completed");
  const dateFilterType = useRef("Created" as ByCreatedDateType);

  const [panelCodes, setPanelCodes] = useState(PpicPanelCodes.fromPartial({}));
  const [handovers, setHandovers] = useState(PpicHandovers.fromPartial({}));
  const [jobs, setJobs] = useState(PpicJobs.fromPartial({ jobs: [] }));
  const [itemsLoading, setItemsLoading] = useState(false);
  const bomLeveleds = useRef(
    PpicBomLeveleds.fromPartial({}) as PpicBomLeveleds
  );
  const bomLeveledsLoading = useRef(false);
  const [handoverStatusFilter, setHandoverStatusFilter] =
    useState<PpicHandover_Status | null>(null);
  const handoversOuts = useRef(PpicHandovers.fromPartial({}));
  const [handoverTypeFilter, setHandoverTypeFilter] =
    useState<PpicHandoverType | null>(PpicHandoverType.FINISHED_GOODS);

  const [items, setItems] = useState<WarehouseItems>(
    WarehouseItems.fromPartial({})
  );
  const [jobFilter, setJobFilter] = useState<PpicJob | null>(null);
  const handoverViewType = useRef<HandoverViewType>("Default");
  const handoverItemRemarkExpandIds = useRef<string[]>([]);
  const hoLoading = useRef(false);

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(
    () => {
      // handleRefetch();
      handleRefetch();
    },
    // [handoverStatusFilter, handoverTypeFilter]
    []
  );

  const handleRefetch = () => {
    fetchHandoversData();
  };

  const handleInit = async () => {
    fetchJobsData();
    fetchPanelCodesData();
    fetchWarehouseItemsData();
    fetchBomLeveledsSimple();
    // fetchExtInventoryData();
    fetchHandoversOutsData();
  };
  const fetchHandoversOutsData = async () => {
    const d = await fetchHandoversOuts(ctx?.apiKey ?? "");

    if (d) {
      handoversOuts.current = d;

      render();
    }
  };

  const fetchBomLeveledsSimple = async () => {
    const d = await fetchBomLeveledsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      bomLeveleds.current = d;

      render();
    }
  };

  const fetchHandoversData = async () => {
    hoLoading.current = true;
    render();

    setHandovers(
      await (async () => {
        const d = await fetchHandoversProto({
          apiKey: ctx?.apiKey ?? "",
          from: from.current,
          to: to.current,
          type: handoverTypeFilter,
          status: handoverStatusFilter,
          dateFilterType: dateFilterType.current,
          dateFilterFrom: fromHoDate.current,
          dateFilterTo: toHoDate.current,
          extJobId: jobFilter?.masterJavaBaseModel?.id,
          showCancelled: true
        });

        d.handovers.reverse();

        return d;
      })()
    );

    hoLoading.current = false;
    render();
  };
  const fetchWarehouseItemsData = async () => {
    setItemsLoading(true);
    setItems(await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" }));
    setItemsLoading(false);
  };
  const fetchPanelCodesData = async () => {
    const d = await fetchPanelCodesProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setPanelCodes(d);
    }
  };

  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
      withProducts: true,
      all: true,
    });

    if (d) {
      setJobs(d);
    }
  };

  const snFlattened: {
    job: PpicJob;
    panelCode: PpicPanelCode;
    serialNumber?: PpicPanelCodeSerialNumber;
    serialNumberManual?: PpicPanelCodeSerialNumberManual;
    type: "auto" | "manual";
  }[] = jobs.jobs.flatMap((j) => [
    ...j.panelCodes.flatMap((c) =>
      c.serialNumbers.flatMap((s) => ({
        job: j,
        panelCode: c,
        serialNumber: s,
        type: "auto" as "auto" | "manual",
      }))
    ),
    ...j.panelCodes.flatMap((c) =>
      c.serialNumberManuals.flatMap((s) => ({
        job: j,
        panelCode: c,
        serialNumberManual: s,
        type: "manual" as "auto" | "manual",
      }))
    ),
  ]);

  const outsCostMapped = handoversOuts.current.handovers.flatMap((h) =>
    h.items.map((i) => ({ item: i, handover: h }))
  );

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>Handover</h5>
          </div>

          {itemsLoading ? (
            <>
              <div>
                <Spinner animation="border" />
                <small>Items loading...</small>
              </div>
            </>
          ) : (
            <></>
          )}
          <div>
            <Link to="/handover/new">
              <button className="btn btn-sm btn-primary">Add</button>
            </Link>{" "}
          </div>
        </div>
        <hr className="border border-dark" />
        <div className="d-flex">
          {(["Default", "Report Table", "Outs Cost"] as HandoverViewType[]).map(
            (h) => (
              <div>
                <button
                  onClick={() => {
                    handoverViewType.current = h;
                    render();
                  }}
                  className={`btn btn-sm ${
                    handoverViewType.current === h
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                >
                  {h}
                </button>
              </div>
            )
          )}
        </div>
        <div className="d-flex align-items-end">
          <div className="d-flex">
            {(["Created", "HO Date"] as ByCreatedDateType[]).map((d) => (
              <>
                <div>
                  <button
                    onClick={() => {
                      dateFilterType.current = d;
                      render();

                      // fetchHandoversData();
                    }}
                    className={`btn btn-sm ${
                      dateFilterType.current === d
                        ? `btn-primary`
                        : `btn-outline-primary`
                    }`}
                  >
                    {d}
                  </button>
                </div>
              </>
            ))}
          </div>

          {dateFilterType.current === "Created" ? (
            <>
              <div className="d-flex align-items-end">
                <div>
                  <div>
                    <small>
                      <strong>From</strong>
                    </small>
                  </div>
                  <div>
                    <input
                      defaultValue={makeDateString(new Date(from.current))}
                      type="date"
                      className="form-control form-control-sm"
                      onBlur={(e) => {
                        from.current = new Date(
                          `${makeDateString(
                            new Date(e.target.value ?? "")
                          )}T00:00:00`
                        ).toISOString();

                        render();

                        // fetchHandoversData();
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <small>
                      <strong>To</strong>
                    </small>
                  </div>
                  <div>
                    <input
                      defaultValue={makeDateString(new Date(to.current))}
                      type="date"
                      className="form-control form-control-sm"
                      onBlur={(e) => {
                        to.current = new Date(
                          `${makeDateString(
                            new Date(e.target.value ?? "")
                          )}T23:59:59`
                        ).toISOString();

                        render();

                        // fetchHandoversData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {dateFilterType.current === "HO Date" ? (
            <>
              <div className="d-flex align-items-end">
                <div>
                  <div>
                    <small>
                      <strong>From HO Date</strong>
                    </small>
                  </div>
                  <div>
                    <input
                      defaultValue={makeDateString(
                        new Date(fromHoDate.current)
                      )}
                      type="date"
                      className="form-control form-control-sm"
                      onBlur={(e) => {
                        fromHoDate.current = new Date(
                          `${makeDateString(
                            new Date(e.target.value ?? "")
                          )}T00:00:00`
                        ).toISOString();

                        render();

                        // fetchHandoversData();
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <small>
                      <strong>To HO Date</strong>
                    </small>
                  </div>
                  <div>
                    <input
                      defaultValue={makeDateString(new Date(toHoDate.current))}
                      type="date"
                      className="form-control form-control-sm"
                      onBlur={(e) => {
                        toHoDate.current = new Date(
                          `${makeDateString(
                            new Date(e.target.value ?? "")
                          )}T23:59:59`
                        ).toISOString();

                        render();

                        // fetchHandoversData();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="mx-2">
            {hoLoading.current ? (
              <>
                <div className="spinner-border spinner-border-sm"></div>
              </>
            ) : (
              <>
                {" "}
                <button
                  className="btn btn-sm btn-primary px-1 py-0"
                  onClick={() => {
                    fetchHandoversData();
                  }}
                >
                  Get Data
                </button>
              </>
            )}
          </div>

          <div className="flex-grow-1">
            <Select
              placeholder="Handover status..."
              options={handoverStatuses}
              value={handoverStatuses.find(
                (s) => s.value === handoverStatusFilter
              )}
              onChange={(v) => {
                const val = v as unknown as {
                  label: string;
                  value: PpicHandover_Status | null;
                };

                setHandoverStatusFilter(val.value);
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              placeholder="Handover type..."
              options={handoverTypes}
              value={handoverTypes.find((s) => s.value === handoverTypeFilter)}
              onChange={(v) => {
                const val = v as unknown as {
                  label: string;
                  value: PpicHandoverType | null;
                };

                setHandoverTypeFilter(val.value);
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              placeholder="Job..."
              options={jobs.jobs.map((j) => ({ label: j.name, value: j }))}
              onChange={(v) => {
                const val = v as {
                  value: PpicJob;
                };
  
                setJobFilter(val.value);
              }}
            />
          </div>
          <div>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/handoversreport?start=${
                from.current
              }&end=${to.current}&extJobId=${
                jobFilter?.masterJavaBaseModel?.id ?? ""
              }&handoverType=${handoverTypeFilter ?? ""}`}
              target="_blank"
            >
              <button className="btn btn-sm btn-primary">
                Download Report
              </button>
            </a>
          </div>
        </div>
        <hr className="border border-dark" />

        {handoverViewType.current === "Default" ? (
          <>
            {" "}
            <div
              className="overflow-auto border border-dark shadow shadow-md"
              style={{ resize: "vertical", height: "75vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "ID",
                    "Panel Codes/Products",
                    "Job",
                    // "Date",
                    // "Status",
                    "Created",
                    "Date",
                    "By",
                    "Type",
                    "To notify",
                    "QC Passed?",
                    "WH Passed?",
                    "Cancelled",
                    "Print",
                  ].map((h) => (
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  ))}
                </tr>
                {handovers.handovers.map((h, i) => {
                  const foundItem = h.materialInstallationItemId
                    ? items.items.find(
                        (i) => `${i.id}` === `${h.materialInstallationItemId}`
                      )
                    : null;
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          {h.masterJavaBaseModel?.id}
                        </td>

                        <td className="border border-dark">
                          {h.type &&
                          ppicHandoverTypeToJSON(h.type) ===
                            ppicHandoverTypeToJSON(
                              PpicHandoverType.MAT_INSTALLATION
                            ) ? (
                            <>
                              <div className="d-flex">
                                <div>
                                  {getWarehouseItemDescription(foundItem)} (
                                  {h?.items?.length ?? 0} items)
                                </div>
                                <div>
                                  <Link
                                    to={`/handover/${h.masterJavaBaseModel?.id}`}
                                  >
                                    <button className="btn btn-sm btn-primary">
                                      <small>Details</small>
                                    </button>
                                  </Link>
                                </div>
                              </div>{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="d-flex justify-content-between">
                                <div>
                                  <ol>
                                    {h.items.map((i) => {
                                      const fItem = items.items.find(
                                        (ix) => `${i.productId}` === `${ix.id}`
                                      );
                                      return (
                                        <>
                                          <li>
                                            <div>
                                              {(h.type &&
                                                ppicHandoverTypeToJSON(
                                                  h.type
                                                ) ===
                                                  ppicHandoverTypeToJSON(
                                                    PpicHandoverType.FINISHED_GOODS
                                                  )) ||
                                              !h.type ? (
                                                <>
                                                  {(() => {
                                                    const fPanelCode = jobs.jobs
                                                      .map((j) => j.panelCodes)
                                                      .flat()
                                                      .find(
                                                        (pc) =>
                                                          pc.serialNumbers.find(
                                                            (sn) =>
                                                              `${sn.masterJavaBaseModel?.id}` ===
                                                              `${i.panelCodeSerialNumberId}`
                                                          ) ||
                                                          pc.serialNumberManuals.find(
                                                            (sn) =>
                                                              `${sn.masterJavaBaseModel?.id}` ===
                                                              `${i.panelCodeSerialNumberManualId}`
                                                          )
                                                      );
                                                    const fSerialNumber =
                                                      jobs.jobs
                                                        .map(
                                                          (j) => j.panelCodes
                                                        )
                                                        .flat()
                                                        .map(
                                                          (pc) =>
                                                            pc.serialNumbers
                                                        )
                                                        .flat()
                                                        .find(
                                                          (sn) =>
                                                            `${sn.masterJavaBaseModel?.id}` ===
                                                            `${i.panelCodeSerialNumberId}`
                                                        );

                                                    const fItem =
                                                      items.items.find(
                                                        (ix) =>
                                                          `${ix.id}` ===
                                                          `${i.productId}`
                                                      );

                                                    return (
                                                      <>
                                                        {fPanelCode ? (
                                                          <>
                                                            {`${fPanelCode.type}: ${fPanelCode.name}`}
                                                            :{" "}
                                                            {
                                                              i.panelCodeSerialNumberFullName
                                                            }
                                                            {itemsLoading &&
                                                            fItem?.partNum ? (
                                                              <>
                                                                <Spinner animation="border" />
                                                              </>
                                                            ) : (
                                                              <>
                                                                (
                                                                {fItem ? (
                                                                  fItem.partNum
                                                                ) : (
                                                                  <span className="text-danger">
                                                                    No item
                                                                    attached
                                                                  </span>
                                                                )}
                                                                )
                                                              </>
                                                            )}
                                                          </>
                                                        ) : (
                                                          `Invalid Panel Code`
                                                        )}
                                                      </>
                                                    );
                                                  })()}
                                                </>
                                              ) : (
                                                <></>
                                              )}

                                              {h.type &&
                                              ppicHandoverTypeToJSON(h.type) ===
                                                ppicHandoverTypeToJSON(
                                                  PpicHandoverType.MR_LEFTOVERS
                                                ) ? (
                                                <>
                                                  {getWarehouseItemDescription(
                                                    fItem
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}

                                              {h.type &&
                                              ppicHandoverTypeToJSON(h.type) ===
                                                ppicHandoverTypeToJSON(
                                                  PpicHandoverType.MR_LEFTOVERS
                                                ) ? (
                                                <>: {i.qty ?? 0} unit(s)</>
                                              ) : (
                                                <></>
                                              )}

                                              {i.pics.length ?? 0 > 0 ? (
                                                <>
                                                  (
                                                  {i.pics
                                                    .map(
                                                      (p) =>
                                                        ctx?.extUsers.find(
                                                          (u) =>
                                                            `${u.id}` ===
                                                            `${p.extUserId}`
                                                        )?.username
                                                    )
                                                    .join(",")}
                                                  )
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ol>
                                </div>
                                <div>
                                  <Link
                                    to={`/handover/${h.masterJavaBaseModel?.id}`}
                                  >
                                    <button className="btn btn-sm btn-primary">
                                      <small>Details</small>
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </>
                          )}
                        </td>
                        <td className={`border border-dark`}>
                          {
                            jobs.jobs.find(
                              (j) =>
                                `${j.masterJavaBaseModel?.id}` ===
                                `${h.extJobId}`
                            )?.name
                          }
                        </td>
                        {/* <td className={`border border-dark`}>
                        {(() => {
                          try {
                            return Intl.DateTimeFormat("en-US", {
                              dateStyle: "medium",
                              // timeStyle: "long",
                              hourCycle: "h23",
                            } as any).format(new Date(h.date ?? ""));
                          } catch (e) {
                            return "Date invalid";
                          }
                        })()}{" "}
                      </td> */}

                        {/* <td
                          className={`border border-dark ${(() => {
                            switch (h.status) {
                              case PpicHandover_Status.OUTSTANDING:
                                return `bg-warning`;
                              case PpicHandover_Status.APPROVED:
                                return `bg-info`;
                              case PpicHandover_Status.CANCELLED:
                                return `bg-danger`;
                              default:
                                return `bg-light`;
                            }
                          })()}`}
                        >
                          <strong>{h.status}</strong>
                        </td> */}

                        <td className="border border-dark">
                          {(() => {
                            try {
                              return Intl.DateTimeFormat("en-US", {
                                dateStyle: "medium",
                                timeStyle: "short",
                                hourCycle: "h23",
                              } as any).format(
                                new Date(h.masterJavaBaseModel?.createdAt ?? "")
                              );
                            } catch (e) {
                              return "Date invalid";
                            }
                          })()}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {(() => {
                            try {
                              return Intl.DateTimeFormat("en-US", {
                                dateStyle: "medium",
                                // timeStyle: "short",
                              } as any).format(
                                new Date(h.date?.split("T")?.[0] ?? "")
                              );
                            } catch (e) {
                              return "";
                            }
                          })()}
                          {/* {h.date?.split("T")?.[0]} */}
                        </td>
                        <td className={`border border-dark`}>
                          <small>{h.handoverBy}</small>
                        </td>
                        <td className="border border-dark">
                          {h.type ? ppicHandoverTypeToJSON(h.type) : ""}
                        </td>
                        <td className="border border-dark">
                          <ol>
                            {h.handoverNotifyUsers.map((u) => {
                              return (
                                <>
                                  <li>
                                    <div>
                                      <div>
                                        {ctx?.extUsers.find(
                                          (ux) =>
                                            `${ux.id}` === `${u.extUserId}`
                                        )?.username ?? "No name"}
                                      </div>
                                    </div>
                                  </li>{" "}
                                </>
                              );
                            })}
                          </ol>
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor: chroma
                              .scale([
                                "lightsalmon",
                                "yellow",
                                "lightgreen",
                                "lightblue",
                              ])(
                                h.items.filter(
                                  (i) =>
                                    handoverQAStatusFromJSON(i.qaStatus) ===
                                    HandoverQAStatus.QA_PASSED
                                ).length / h.items.length
                              )
                              .hex(),
                          }}
                        >
                          <strong>
                            {
                              h.items.filter(
                                (i) =>
                                  handoverQAStatusFromJSON(i.qaStatus) ===
                                  HandoverQAStatus.QA_PASSED
                              ).length
                            }
                            /{h.items.length}
                          </strong>
                          {ppicHandoverTypeFromJSON(h.type) ===
                          PpicHandoverType.FINISHED_GOODS ? (
                            <>
                              <ol>
                                {h.items.map((i) => {
                                  const foundPanelCode = jobs.jobs
                                    .flatMap((j) => j.panelCodes)
                                    .find(
                                      (c) =>
                                        c.serialNumbers.find(
                                          (s) =>
                                            `${s.masterJavaBaseModel?.id}` ===
                                            `${i.panelCodeSerialNumberId}`
                                        ) ||
                                        c.serialNumberManuals.find(
                                          (s) =>
                                            `${s.masterJavaBaseModel?.id}` ===
                                            `${i.panelCodeSerialNumberManualId}`
                                        )
                                    );
                                  const foundSN = i.panelCodeSerialNumberId
                                    ? jobs.jobs
                                        .flatMap((j) => j.panelCodes)
                                        .flatMap((c) => c.serialNumbers)
                                        .find(
                                          (s) =>
                                            `${s.masterJavaBaseModel?.id}` ===
                                            `${i.panelCodeSerialNumberId}`
                                        )
                                    : null;

                                  const foundManualSN =
                                    i.panelCodeSerialNumberManualId
                                      ? jobs.jobs
                                          .flatMap((j) => j.panelCodes)
                                          .flatMap((c) => c.serialNumberManuals)
                                          .find(
                                            (s) =>
                                              `${s.masterJavaBaseModel?.id}` ===
                                              `${i.panelCodeSerialNumberManualId}`
                                          )
                                      : null;

                                  return (
                                    <>
                                      {handoverQAStatusFromJSON(i.qaStatus) ===
                                      HandoverQAStatus.QA_PASSED ? (
                                        <>
                                          {" "}
                                          {foundSN ? (
                                            <>
                                              <li>
                                                {getPanelCodeCompleteSerialNumberProto(
                                                  foundPanelCode,
                                                  foundSN
                                                )}
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {foundManualSN ? (
                                            <>
                                              <li>
                                                <div>
                                                  <div>
                                                    {foundManualSN.serialNumber}
                                                  </div>
                                                  <div>
                                                    <ol>
                                                      {foundPanelCode?.serialNumberRects.map(
                                                        (r) => {
                                                          return (
                                                            <>
                                                              <li>
                                                                <div>
                                                                  {r.name}
                                                                </div>
                                                              </li>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </ol>
                                                  </div>
                                                </div>
                                              </li>{" "}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </ol>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor: chroma
                              .scale([
                                "lightsalmon",
                                "yellow",
                                "lightgreen",
                                "lightblue",
                              ])(
                                h.items.filter(
                                  (i) =>
                                    handoverQAStatusFromJSON(i.whStatus) ===
                                    HandoverQAStatus.QA_PASSED
                                ).length / h.items.length
                              )
                              .hex(),
                          }}
                        >
                          <strong>
                            {
                              h.items.filter(
                                (i) =>
                                  handoverQAStatusFromJSON(i.whStatus) ===
                                  HandoverQAStatus.QA_PASSED
                              ).length
                            }
                            /{h.items.length}
                          </strong>
                          {ppicHandoverTypeFromJSON(h.type) ===
                          PpicHandoverType.FINISHED_GOODS ? (
                            <>
                              <ol>
                                {h.items.map((i) => {
                                  const foundPanelCode = jobs.jobs
                                    .flatMap((j) => j.panelCodes)
                                    .find(
                                      (c) =>
                                        c.serialNumbers.find(
                                          (s) =>
                                            `${s.masterJavaBaseModel?.id}` ===
                                            `${i.panelCodeSerialNumberId}`
                                        ) ||
                                        c.serialNumberManuals.find(
                                          (s) =>
                                            `${s.masterJavaBaseModel?.id}` ===
                                            `${i.panelCodeSerialNumberManualId}`
                                        )
                                    );
                                  const foundSN = i.panelCodeSerialNumberId
                                    ? jobs.jobs
                                        .flatMap((j) => j.panelCodes)
                                        .flatMap((c) => c.serialNumbers)
                                        .find(
                                          (s) =>
                                            `${s.masterJavaBaseModel?.id}` ===
                                            `${i.panelCodeSerialNumberId}`
                                        )
                                    : null;

                                  const foundManualSN =
                                    i.panelCodeSerialNumberManualId
                                      ? jobs.jobs
                                          .flatMap((j) => j.panelCodes)
                                          .flatMap((c) => c.serialNumberManuals)
                                          .find(
                                            (s) =>
                                              `${s.masterJavaBaseModel?.id}` ===
                                              `${i.panelCodeSerialNumberManualId}`
                                          )
                                      : null;

                                  return (
                                    <>
                                      {handoverQAStatusFromJSON(i.whStatus) ===
                                      HandoverQAStatus.QA_PASSED ? (
                                        <>
                                          {" "}
                                          {foundSN ? (
                                            <>
                                              <li>
                                                {getPanelCodeCompleteSerialNumberProto(
                                                  foundPanelCode,
                                                  foundSN
                                                )}
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {foundManualSN ? (
                                            <>
                                              <li>
                                                {foundManualSN.serialNumber}
                                              </li>{" "}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })}
                              </ol>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td
                          className={`border border-dark ${
                            h?.cancellationDate ? `bg-danger text-light` : ``
                          }`}
                        >
                          {h?.cancellationDate
                            ? intlFormat({
                                date: h?.cancellationDate,
                                dateStyle: "medium",
                                timeStyle: "short",
                              })
                            : ""}
                        </td>

                        <td className="border border-dark">
                          <a
                            target="_blank"
                            href={`/#/handover/${h.masterJavaBaseModel?.id}/print`}
                          >
                            <button className="btn btn-sm btn-primary">
                              <Print />
                            </button>
                          </a>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {handoverViewType.current === "Report Table" ? (
          <>
            <div
              className="overflow-auto border border-dark"
              style={{ resize: "vertical", height: "60vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "ID",
                    "Job",
                    "Product",
                    // "Qty",
                    "SN",
                    "PN BOM",
                    "Prod Time",
                    // "Remark",
                    "QC Recv",
                    "QC Send",
                    "QC By",
                    "QCRemark",

                    "WH Recv",
                    "WH Send",
                    "WH By",
                    "WHRemark",
                  ].map((h) => {
                    return (
                      <>
                        <th
                          className="bg-dark text-light p-0 m-0"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      </>
                    );
                  })}
                </tr>
                {handovers.handovers
                  .filter(
                    (h) =>
                      ppicHandoverTypeFromJSON(h.type) ===
                        PpicHandoverType.FINISHED_GOODS &&
                      (jobFilter
                        ? `${h.extJobId}` ===
                          `${jobFilter.masterJavaBaseModel?.id}`
                        : true)
                  )

                  .flatMap((h) =>
                    h.items.map((i) => ({ handover: h, item: i }))
                  )
                  .map((d, i) => {
                    const foundSNAuto = snFlattened.find(
                      (s) =>
                        `${s.serialNumber?.masterJavaBaseModel?.id}` ===
                        `${d.item.panelCodeSerialNumberId}`
                    );
                    const foundSNManual = snFlattened.find(
                      (s) =>
                        `${s.serialNumberManual?.masterJavaBaseModel?.id}` ===
                        `${d.item.panelCodeSerialNumberManualId}`
                    );

                    const snAutoString = getPanelCodeCompleteSerialNumberProto(
                      foundSNAuto?.panelCode,
                      foundSNAuto?.serialNumber
                    );

                    const foundJob = jobs.jobs.find(
                      (j) =>
                        `${j.masterJavaBaseModel?.id}` ===
                        `${d.handover.extJobId}`
                    );

                    const foundItem = items.items.find(
                      (ix) =>
                        `${ix.id}` ===
                        (() => {
                          if (foundSNAuto?.panelCode.extItemId) {
                            return `${foundSNAuto.panelCode.extItemId}`;
                          } else if (foundSNManual?.panelCode.extItemId) {
                            return `${foundSNManual.panelCode.extItemId}`;
                          } else {
                            return null;
                          }
                        })()
                    );
                    const foundPanelCode = jobs.jobs
                      .flatMap((j) => j.panelCodes)
                      .find(
                        (c) =>
                          c.serialNumbers.find(
                            (s) =>
                              `${s.masterJavaBaseModel?.id}` ===
                              `${d.item.panelCodeSerialNumberId}`
                          ) ||
                          c.serialNumberManuals.find(
                            (s) =>
                              `${s.masterJavaBaseModel?.id}` ===
                              `${d.item.panelCodeSerialNumberManualId}`
                          )
                      );

                    return (
                      <>
                        <tr>
                          <td className="border border-dark p-0 m-0">
                            {i + 1}
                          </td>
                          <td
                            className="border border-dark p-0 m-0"
                            style={{
                              backgroundColor: new ColorHash({
                                lightness: [0.8],
                              }).hex(d.handover.masterJavaBaseModel?.id ?? ""),
                            }}
                          >
                            {d.handover.masterJavaBaseModel?.id}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundJob?.shortName && foundJob.shortName !== ""
                              ? foundJob.shortName
                              : foundJob?.name}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {/* {foundItem
                              ? getItemFullDescriptionProto(foundItem)
                              : ""} */}
                            {foundPanelCode?.type}:{foundPanelCode?.name}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundSNAuto ? <>{snAutoString}</> : <></>}
                            {foundSNManual ? (
                              <>
                                {foundSNManual.serialNumberManual?.serialNumber}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {
                              items.items.find(
                                (i) =>
                                  `${i.id}` === `${foundPanelCode?.extItemId}`
                              )?.partNum
                            }
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {(() => {
                              try {
                                return Intl.DateTimeFormat("en-US", {
                                  dateStyle: "short",
                                  timeStyle: "short",
                                } as any).format(
                                  new Date(
                                    d.item.masterJavaBaseModel?.createdAt ?? ""
                                  )
                                );
                              } catch (e) {
                                return "";
                              }
                            })()}
                          </td>

                          <td className="border border-dark p-0 m-0">
                            <div className="d-flex">
                              <div>
                                {(() => {
                                  try {
                                    return Intl.DateTimeFormat("en-US", {
                                      dateStyle: "long",
                                      // timeStyle: "short",
                                    } as any).format(
                                      new Date(
                                        d.item.qaUserCheckedTimestamp ?? ""
                                      )
                                    );
                                  } catch (e) {
                                    return "";
                                  }
                                })()}
                              </div>
                              {d.item.qaStatus ? (
                                <>
                                  {handoverQAStatusFromJSON(d.item.qaStatus) ===
                                  HandoverQAStatus.QA_PASSED ? (
                                    <>
                                      <div className="bg-success text-light">
                                        <small>
                                          <strong>PASS</strong>
                                        </small>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="bg-danger text-light">
                                        <small>
                                          <strong>NOPAS</strong>
                                        </small>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>

                          <td className="border border-dark p-0 m-0">
                            <div>
                              {(() => {
                                try {
                                  return Intl.DateTimeFormat("en-US", {
                                    dateStyle: "long",
                                    // timeStyle: "short",
                                  } as any).format(
                                    new Date(d.item.qaSendTimestamp ?? "")
                                  );
                                } catch (e) {
                                  return "";
                                }
                              })()}
                            </div>
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {
                              ctx?.extUsers.find(
                                (u) => `${u.id}` === `${d.item.qaUserId}`
                              )?.username
                            }
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {d.item.qaRemark && d.item.qaRemark !== "" ? (
                              <>
                                {handoverItemRemarkExpandIds.current.find(
                                  (i) =>
                                    `${i}` ===
                                    `${d.item.masterJavaBaseModel?.id}`
                                ) ? (
                                  <>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handoverItemRemarkExpandIds.current =
                                          handoverItemRemarkExpandIds.current.filter(
                                            (i) =>
                                              `${i}` !==
                                              `${d.item.masterJavaBaseModel?.id}`
                                          );
                                        render();
                                      }}
                                    >
                                      {d.item.qaRemark}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() => {
                                        handoverItemRemarkExpandIds.current.push(
                                          d.item.masterJavaBaseModel?.id ?? ""
                                        );
                                        render();
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="text-primary"
                                    >
                                      Expand
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {/* {d.item.qaRemark} */}
                            {/* {JSON.stringify(
                              d.item.qaRemark && d.item.qaRemark !== ""
                            )} */}
                          </td>

                          <td className="border border-dark p-0 m-0">
                            <div className="d-flex">
                              <div>
                                {(() => {
                                  try {
                                    return Intl.DateTimeFormat("en-US", {
                                      dateStyle: "long",
                                      // timeStyle: "short",
                                    } as any).format(
                                      new Date(
                                        d.item.whUserCheckedTimestamp ?? ""
                                      )
                                    );
                                  } catch (e) {
                                    return "";
                                  }
                                })()}
                              </div>
                              {d.item.whStatus ? (
                                <>
                                  {handoverQAStatusFromJSON(d.item.whStatus) ===
                                  HandoverQAStatus.QA_PASSED ? (
                                    <>
                                      <div className="bg-success text-light">
                                        <small>
                                          <strong>PASS</strong>
                                        </small>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="bg-danger text-light">
                                        <small>
                                          <strong>NOPAS</strong>
                                        </small>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>

                          <td className="border border-dark p-0 m-0">
                            <div>
                              {(() => {
                                try {
                                  return Intl.DateTimeFormat("en-US", {
                                    dateStyle: "long",
                                    // timeStyle: "short",
                                  } as any).format(
                                    new Date(d.item.whSendTimestamp ?? "")
                                  );
                                } catch (e) {
                                  return "";
                                }
                              })()}
                            </div>
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {
                              ctx?.extUsers.find(
                                (u) => `${u.id}` === `${d.item.whUserId}`
                              )?.username
                            }
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {d.item.whRemark && d.item.whRemark !== "" ? (
                              <>
                                {handoverItemRemarkExpandIds.current.find(
                                  (i) =>
                                    `${i}` ===
                                    `${d.item.masterJavaBaseModel?.id}`
                                ) ? (
                                  <>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handoverItemRemarkExpandIds.current =
                                          handoverItemRemarkExpandIds.current.filter(
                                            (i) =>
                                              `${i}` !==
                                              `${d.item.masterJavaBaseModel?.id}`
                                          );
                                        render();
                                      }}
                                    >
                                      {d.item.whRemark}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() => {
                                        handoverItemRemarkExpandIds.current.push(
                                          d.item.masterJavaBaseModel?.id ?? ""
                                        );
                                        render();
                                      }}
                                      style={{ cursor: "pointer" }}
                                      className="text-primary"
                                    >
                                      Expand
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {/* {d.item.qaRemark} */}
                            {/* {JSON.stringify(
                              d.item.qaRemark && d.item.qaRemark !== ""
                            )} */}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {handoverViewType.current === "Outs Cost" ? (
          <>
            <div className="d-flex">
              <div>
                <button
                  onClick={async () => {
                    hoLoading.current = true;
                    render();

                    const d = await fetchHandoversOuts(
                      ctx?.apiKey ?? "",
                      false
                    );
                    handoversOuts.current = d;

                    hoLoading.current = false;
                    render();
                  }}
                  className={`btn btn-sm ${
                    selectedOutsCost.current === "Outs"
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                >
                  Outs
                </button>
              </div>
              <div>
                <button
                  onClick={async () => {
                    hoLoading.current = true;
                    render();

                    const d = await fetchHandoversOuts(ctx?.apiKey ?? "", true);
                    handoversOuts.current = d;

                    hoLoading.current = false;
                    render();
                  }}
                  className={`btn btn-sm ${
                    selectedOutsCost.current === "Completed"
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                >
                  Completed
                </button>
              </div>
            </div>
            <div
              className="border border-dark overflow-auto"
              style={{ resize: "vertical", height: "75vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "HO ID",
                    "HO Item ID",
                    "HO Created",
                    "Job",
                    "PanelCode",
                    "Price",
                    "BOM",
                    "Item ID",
                    "SN",
                    "PN",
                    "Part Name",
                    "Part Desc",
                  ].map((h) => {
                    return (
                      <>
                        <th
                          className="bg-dark text-light"
                          style={{ position: "sticky", top: 1 }}
                        >
                          {h}
                        </th>
                      </>
                    );
                  })}
                </tr>
                {outsCostMapped.map((c, i) => {
                  const foundItem = items.items.find(
                    (ix) => `${ix.id}` === `${c.item.panelCodeExtItemId}`
                  );

                  const foundJob = jobs.jobs.find((j) =>
                    j.panelCodes.find(
                      (cx) =>
                        cx.serialNumbers.find(
                          (s) =>
                            `${s.masterJavaBaseModel?.id}` ===
                            `${c.item.panelCodeSerialNumberId}`
                        ) ||
                        cx.serialNumberManuals.find(
                          (s) =>
                            `${s.masterJavaBaseModel?.id}` ===
                            `${c.item.panelCodeSerialNumberManualId}`
                        )
                    )
                  );

                  const panelCode = foundJob?.panelCodes.find(
                    (cx) =>
                      cx.serialNumbers.find(
                        (s) =>
                          `${s.masterJavaBaseModel?.id}` ===
                          `${c.item.panelCodeSerialNumberId}`
                      ) ||
                      cx.serialNumberManuals.find(
                        (s) =>
                          `${s.masterJavaBaseModel?.id}` ===
                          `${c.item.panelCodeSerialNumberManualId}`
                      )
                  );

                  const sn = panelCode?.serialNumbers.find(
                    (s) =>
                      `${s.masterJavaBaseModel?.id}` ===
                      `${c.item.panelCodeSerialNumberId}`
                  );
                  const snManual = panelCode?.serialNumberManuals.find(
                    (s) =>
                      `${s.masterJavaBaseModel?.id}` ===
                      `${c.item.panelCodeSerialNumberManualId}`
                  );
                  const foundBom = bomLeveleds.current.bomLeveleds.find(
                    (b) =>
                      `${b.extItemToCreateId}` ===
                      `${c.item.panelCodeExtItemId}`
                  );

                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          {c.handover.masterJavaBaseModel?.id}
                        </td>
                        <td className="border border-dark">
                          {c.item.masterJavaBaseModel?.id}
                        </td>
                        <td
                          className="border border-dark"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {intlFormat({
                            date: c.handover.masterJavaBaseModel?.createdAt,
                            dateStyle: "medium",
                            timeStyle: "short",
                          })}
                        </td>
                        <td className="border border-dark">{foundJob?.name}</td>
                        <td className="border border-dark">
                          {panelCode?.type}:{panelCode?.name}
                        </td>
                        <td
                          className={`border border-dark ${
                            (c.item.snapshotPriceIdr ?? 0) > 0
                              ? `bg-success text-light`
                              : `bg-danger`
                          }`}
                        >
                          {/* {c.item.snapshotPriceIdr ?? 0 > 0
                            ? "OK"
                            : Intl.NumberFormat("en-US").format(
                                c.item.snapshotPriceIdr ?? 0
                              )} */}
                          {Intl.NumberFormat("en-US").format(
                            c.item.snapshotPriceIdr ?? 0
                          )}
                        </td>
                        <td className="border border-dark">{foundBom?.name}</td>

                        <td className="border border-dark">
                          {c.item.panelCodeExtItemId}
                        </td>
                        <td className="border border-dark">
                          {sn
                            ? getPanelCodeCompleteSerialNumberProto(
                                panelCode,
                                sn
                              )
                            : ""}
                          {snManual ? snManual.serialNumber : ""}
                        </td>

                        <td className="border border-dark">
                          {foundItem?.partNum}
                        </td>

                        <td
                          className="border border-dark"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {foundItem?.partName}
                        </td>
                        <td
                          className="border border-dark"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {foundItem?.partDesc}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default HandoverPage;
