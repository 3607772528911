import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  fetchAutoMRWarehouseFilters,
  fetchExtInventory,
} from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicAutoMRWarehouseFilter,
  PpicAutoMRWarehouseFilters,
} from "../../../masterbigsystem";
import { ExtInventory, ExtWarehouseDetail } from "../../../models/model";
import { v4 } from "uuid";

const AutoMRWarehouseFilterPage = () => {
  const ctx = useContext(AppContext);

  const handleInit = async () => {
    fetchAutoMRWarehouseFiltersData();
    fetchInventoryData();
  };
  const inventory = useRef([] as ExtInventory[]);
  const autoMrWarehouseFilters = useRef(
    PpicAutoMRWarehouseFilters.fromPartial({})
  );

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const fetchAutoMRWarehouseFiltersData = async () => {
    const d = await fetchAutoMRWarehouseFilters({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      autoMrWarehouseFilters.current = d;
      render();
    }
  };

  const fetchInventoryData = async () => {
    const d = await fetchExtInventory({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      inventory.current = d;
      render();
    }
  };

  useEffect(() => {
    handleInit();
  }, []);

  const warehouses = (() => {
    const whFlats = inventory.current.map((i) => i.warehouse?.warehouse);

    const whIds = [...new Set(whFlats.map((w) => `${w?.id}`))];

    return whIds
      .map((i) => whFlats.find((w) => `${w?.id}` === `${i}`))
      .filter((i) => i)
      .map((i) => i as ExtWarehouseDetail);
  })();

  return (
    <>
      <div>
        <div className="d-flex">
          <h5>Auto MR Warehouse Filter</h5>

          <button
            className="btn btn-sm btn-primary"
            onClick={async () => {
              try {
                const resp = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/automrwarehousefilters-proto-save`,
                  {
                    method: "post",
                    headers: {
                      authorization: ctx?.apiKey ?? "",
                      "content-type": "application/json",
                    },
                    body: JSON.stringify(autoMrWarehouseFilters.current),
                  }
                );
                window.location.reload();
              } catch (e) {
                alert("Failed saving");
              }
            }}
          >
            Save
          </button>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        Default WH:{" "}
        <strong>
          {
            warehouses.find(
              (w) =>
                `${w.id}` ===
                `${
                  autoMrWarehouseFilters.current.filters.find((_) => true)
                    ?.warehouseId
                }`
            )?.name
          }
        </strong>
        <div
          className="border border-dark overflow-auto"
          style={{ resize: "vertical", height: "75vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            {["#", "Warehouse", "Desc", "Action"].map((h) => {
              return (
                <>
                  <th
                    className="bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              );
            })}
            {warehouses.map((w, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i + 1}</td>
                    <td className="border border-dark p-0 m-0">{w.name}</td>
                    <td className="border border-dark p-0 m-0">
                      {w.description}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          const foundAutoMrWarehouseFilter =
                            autoMrWarehouseFilters.current.filters.find(
                              (_) => true
                            );

                          if (foundAutoMrWarehouseFilter) {
                            foundAutoMrWarehouseFilter.warehouseId = w.id
                              ? `${w.id}`
                              : undefined;

                            render();
                            return;
                          }

                          autoMrWarehouseFilters.current.filters.push(
                            PpicAutoMRWarehouseFilter.fromPartial({
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({ uuid: v4() }),
                              warehouseId: w.id ? `${w.id}` : undefined,
                            })
                          );
                          render();
                        }}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default AutoMRWarehouseFilterPage;
