import {
  Add,
  Cancel,
  CancelOutlined,
  Check,
  CheckCircle,
  Close,
} from "@material-ui/icons";
import chroma from "chroma-js";
import ColorHash from "color-hash";
import { get, set } from "idb-keyval";
import murmur from "murmurhash-js";
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Link } from "react-router-dom";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  compareDateStringUtcColor,
  extractProtoMeetingTaskTargetDate,
  fetchCustomers,
  fetchDepartmentTemplatesView,
  fetchJobsDeptProgress,
  fetchMachines,
  fetchUsers,
  fetchWorkOrdersProto,
  fetchJobsProtoSimple,
  fetchMachinePrograms,
  fetchPanelCodesProtoSimple,
  fetchProcessTypes,
  fetchIntegrationModulesProto,
  makeDateString,
  makeReadableDateStringIntlDateOnlyUtc,
  makeReadableDateStringShortIntl,
  fetchSimplifiedProcessTypes,
  fetchSimplifiedProcessTypesProto,
  getWorkOrderTimeMinutes,
  fetchRcemDepartmentsProto,
  fetchDepartmentTemplatesProto,
  fetchJobsDataProto,
  TaskListInfo,
  fetchMeetingTaskComments,
  filterWorkOrderByDate,
  makeReadableDateStringUTC,
  makeReadableDateStringIntlUtc,
  fetchWorkOrdersProtoMongo,
  fetchPanelCodeDepartmentTemplateItemsProto,
  filterWorkOrderByDateRange,
  fetchAttendanceNotes,
  fetchDepartmentGroupsProto,
} from "../../../helpers";
import {
  AuthserverDepartmentGroup,
  AuthserverDepartmentGroups,
  MasterJavaBaseModel,
  MeetingMeetingTask,
  MeetingMeetingTaskComment,
  MeetingMeetingTaskComments,
  MeetingMeetingTaskTargetDate,
  MeetingTaskList,
  MeetingTaskListsView,
  MeetingTaskListView,
  PpicDepartmentTemplate,
  PpicDepartmentTemplates,
  PpicIntegrationModules,
  PpicJob,
  PpicJobs,
  PpicMachinePrograms,
  PpicPanelCodeDepartmentTemplateItems,
  PpicRcemDepartments,
  PpicRcemProcesses,
  PpicSimplifiedProcessTypes,
  TaskListDepartmentCategory,
} from "../../../masterbigsystem";
import {
  DepartmentTemplate,
  DepartmentTemplateView,
  ExtDepartment,
  ExtUser,
  IndividualEntity,
  Job,
  JobDeptCategory,
  Machine,
  PanelCode,
  ProcessType,
  User,
} from "../../../models/model";
import { AttendanceNote } from "../../../models/meeting";

type JobDeptView =
  | "Process"
  | "Department"
  | "Timeline"
  | "WO Timeline"
  | "Process"
  | "Machine"
  | "Manpower"
  | "Work Order"
  | "Comments"
  | "Dept Summ";

type DeptSummType = "MinMax" | "Mean";
const deptSummTypeArr: DeptSummType[] = ["MinMax", "Mean"];

const taskListDepartmentCategories = [
  TaskListDepartmentCategory.SALES,
  TaskListDepartmentCategory.PPC,
  TaskListDepartmentCategory.ELEC,
  TaskListDepartmentCategory.MECH,
  TaskListDepartmentCategory.PURCH,
  TaskListDepartmentCategory.WH1,
  TaskListDepartmentCategory.PROD,
  TaskListDepartmentCategory.QCQA,
  TaskListDepartmentCategory.WH2,
  TaskListDepartmentCategory.LOGISTICS,
  TaskListDepartmentCategory.WH3,
  TaskListDepartmentCategory.SERVICE1,
  TaskListDepartmentCategory.SERVICE2,
  TaskListDepartmentCategory.SERVICE3,
  TaskListDepartmentCategory.WH4,
  TaskListDepartmentCategory.FI,
];

const JobDeptProgressPage = () => {
  const ctx = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobsProto, setJobsProto] = useState<PpicJobs | null | undefined>(null);
  const [selectedDeptSummType, setSelectedDeptSummType] =
    useState<DeptSummType>("MinMax");
  const [moveInDate, setMoveInDate] = useState<string | null>(null);
  const [moveInDateSlideOnly, setMoveInDateSlideOnly] = useState<string | null>(
    null
  );
  const selectedDateFrom = useRef(makeDateString(new Date()));
  const selectedDateTo = useRef(
    makeDateString(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    )
  );

  const daysFromTo = Math.round(
    (new Date(selectedDateTo.current).getTime() -
      new Date(selectedDateFrom.current).getTime()) /
      86400000
  );

  const [
    panelCodeDepartmentTemplateItemsProto,
    setPanelCodeDepartmentTemplateItemsProto,
  ] = useState(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({
      departmentTemplateItems: [],
    })
  );
  const [depts, setDepts] = useState<DepartmentTemplateView[]>([]);
  const [view, setView] = useState<JobDeptView>("Process");
  const [users, setUsers] = useState<User[]>([]);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [modalContent, setModalContent] = useState<ReactNode | null>(null);
  const [workOrderModalOpen, setWorkOrderModalOpen] = useState(false);
  const [selectedWorkOrderModalUser, setSelectedWorkOrderModalUser] =
    useState<ExtUser | null>(null);
  const [selectedWorkOrderModalDate, setSelectedWorkOrderModalDate] = useState<
    string | null
  >(null);
  const [departmentTemplates, setDepartmentTemplates] =
    useState<PpicDepartmentTemplates | null>(null);
  const [selectedWorkOrders, setSelectedWorkOrders] = useState<
    MeetingTaskListView[]
  >([]);
  const [filters, setFilters] = useState({
    jobId: null as number | null,
    individualEntityId: null as number | null,
    status: "outstanding" as "outstanding" | "all",
    extUserId: null as number | null | undefined,
    extDepartmentId: null as number | null | undefined,
    extDepartmentIds: [] as (number | null | undefined)[],
    jobDeptCategory: null as JobDeptCategory | null | undefined,
  });
  const [woJob, setWoJob] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );

  const [customers, setCustomers] = useState<IndividualEntity[]>([]);

  const [workOrders, setWorkOrders] = useState<
    MeetingTaskListsView | null | undefined
  >(MeetingTaskListsView.fromPartial({ taskLists: [] }));

  const [
    panelCodeRelatedCommentDeleteIds,
    setPanelCodeRelatedCommentDeleteIds,
  ] = useState<string[]>([]);
  const [
    selectedRelatedWorkOrderExtPanelCodeId,
    setSelectedRelatedWorkOrderExtPanelCodeId,
  ] = useState<string | null | undefined>(null);
  const [
    selectedRelatedWorkOrderDepartmentTemplateItemId,
    setSelectedRelatedWorkOrderDepartmentTemplateItemId,
  ] = useState<string | null | undefined>(null);

  const [panelCodeRelatedComments, setPanelCodeRelatedComments] =
    useState<MeetingMeetingTaskComments>(
      MeetingMeetingTaskComments.fromPartial({ comments: [] })
    );
  const [panelCodeRelatedCommentsLoading, setPanelCodeRelatedCommentsLoading] =
    useState(false);

  const [
    openPanelCodeRelatedWorkOrdersModal,
    setOpenPanelCodeRelatedWorkOrdersModal,
  ] = useState(false);

  const [machinePrograms, setMachinePrograms] = useState<
    PpicMachinePrograms | null | undefined
  >(null);
  const [integrationModules, setIntegrationModules] =
    useState<PpicIntegrationModules | null>(null);
  const [processTypes, setProcessTypes] =
    useState<PpicSimplifiedProcessTypes | null>(null);
  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  const [showFullPanelCode, setShowFullPanelCode] = useState(false);
  const [hiddenJobDeptProcesses, setHiddenJobDeptProcesses] = useState<
    DepartmentTemplate[]
  >([]);
  const [showReschedulerModal, setShowReschedulerModal] = useState(false);
  const [reschedulerUser, setReschedulerUser] = useState<ExtUser | null>(null);

  const [showReschedulerModalLoading, setShowReschedulerModalLoading] =
    useState(false);
  const [reschedulerMeetingTaskList, setReschedulerMeetingTaskList] = useState(
    MeetingTaskListsView.fromPartial({})
  );
  const [rescheduleTarget, setRescheduleTarget] = useState(
    makeDateString(new Date())
  );
  const [selectedTaskListToMove, setSelectedTaskListToMove] =
    useState<MeetingTaskListView | null>(null);
  const [taskListsValid, setTaskListsValid] = useState<MeetingTaskListView[]>(
    []
  );
  const [incompleteTaskLists, setIncompleteTaskLists] = useState<
    MeetingTaskListView[]
  >([]);
  const [combinedTaskLists, setCombinedTaskLists] = useState<
    MeetingTaskListView[]
  >([]);
  const [combinedTaskListsFormer, setCombinedTaskListsFormer] = useState<
    MeetingTaskListView[]
  >([]);
  const [incompleteTasks, setIncompleteTasks] = useState<MeetingMeetingTask[]>(
    []
  );
  const attendanceNotes = useRef([] as AttendanceNote[]);
  const [openRescheduleDeptModal, setOpenRescheduleDeptModal] = useState(false);
  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };
  // useEffect(() => {
  //   handleInit();
  // }, []);

  useEffect(() => {
    handleGetInitialData();
  }, []);

  const handleGetInitialData = async () => {
    // fetchJobs();
    fetchDepts();
    fetchUsersData();
    fetchMachinesData();
    fetchCustomersData();
    fetchMachineProgramsData();
    fetchIntegrationModulesData();
    fetchProcessTypesData();
    fetchRcemDepartmentsData();
    fetchDepartmentTemplatesProtoData();
    fetchPanelCodeDeparmentTemplateItemsData();
    fetchPpicJobsProtoData();
    fetchExtDepartmentGroupsProtoData();
  };

  const handleInit = async () => {
    // init idb keyval
    if (await get("hiddenJobDeptProcesses")) {
      setHiddenJobDeptProcesses((await get("hiddenJobDeptProcesses")) as any);
    }
    fetchAttendanceNotesData();

    // setLoading(true);
    // await Promise.all([
    fetchTaskListsProtoData();
    // fetchPpicJobsProtoData(),
    fetchJobs();
    // ]);
    // setLoading(false);
  };

  const fetchAttendanceNotesData = async () => {
    attendanceNotes.current = await fetchAttendanceNotes({
      apiKey: ctx?.apiKey ?? "",
      from: new Date(`${selectedDateFrom.current}T00:00:00`).toISOString(),
      to: new Date(`${selectedDateTo.current}T00:00:00`).toISOString(),
    });

    render();
  };

  useEffect(() => {
    if (jobs.length > 0 && view === "Dept Summ") {
      fetchWoFromJob();
    }
  }, [view, jobs]);

  const fetchWoFromJob = async () => {
    setWoJob(
      await fetchWorkOrdersProto({
        extJobIds: jobs.map((j) => j.id).filter((j) => j),
      })
    );
  };

  const departmentGroups = useRef(
    AuthserverDepartmentGroups.fromPartial({ groups: [] })
  );

  // If dept groups and users is complete
  useEffect(() => {
    // Found dept group
    if (
      (departmentGroups.current.groups.length ?? 0) > 0 &&
      ctx?.gspeAppUser?.departmentId
    ) {
      const foundDeptGroup = departmentGroups.current.groups.find((g) =>
        g.departments.find(
          (d) =>
            `${d.masterJavaBaseModel?.id}` ===
            `${ctx.gspeAppUser?.departmentId}`
        )
      );

      if (foundDeptGroup) {
        filters.extDepartmentIds = foundDeptGroup.departments.map((d) =>
          isNaN(parseInt(d.masterJavaBaseModel?.id ?? ""))
            ? 0
            : parseInt(d.masterJavaBaseModel?.id ?? "")
        );
        render();
      }
    }
  }, [departmentGroups.current, ctx?.gspeAppUser]);

  const fetchExtDepartmentGroupsProtoData = async () => {
    const d = await fetchDepartmentGroupsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      departmentGroups.current = d;
      render();
    }
  };

  const fetchPanelCodeDeparmentTemplateItemsData = async () => {
    setPanelCodeDepartmentTemplateItemsProto(
      await fetchPanelCodeDepartmentTemplateItemsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const fetchJobs = async () => {
    setJobs(
      await fetchJobsDeptProgress({
        apiKey: ctx?.apiKey ?? "",
        extJobId: filters.jobId,
      })
    );
  };

  const fetchDepts = async () => {
    setDepts(await fetchDepartmentTemplatesView({ apiKey: ctx?.apiKey ?? "" }));
  };

  const fetchUsersData = async () => {
    setUsers(await fetchUsers({ apiKey: ctx?.apiKey ?? "" }));
  };

  const fetchMachinesData = async () => {
    setMachines(await fetchMachines({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchProcessTypesData = async () => {
    setProcessTypes(
      await fetchSimplifiedProcessTypesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  const fetchCustomersData = async () => {
    setCustomers(await fetchCustomers({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchRcemDepartmentsData = async () => {
    setRcemDepartments(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchDepartmentTemplatesProtoData = async () => {
    setDepartmentTemplates(
      await fetchDepartmentTemplatesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchPpicJobsProtoData = async () => {
    setJobsProto(
      await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
        // category: filters.jobDeptCategory,
      })
    );
  };

  const fetchTaskListsProtoData = async () => {
    setWorkOrders(
      await fetchWorkOrdersProtoMongo({
        extUserIds:
          (filters.extDepartmentIds.length ?? 0) > 0
            ? ctx?.extUsers
                .filter((u) =>
                  filters.extDepartmentIds.find(
                    (i) => `${u.departmentId}` === `${i}`
                  )
                )
                .map((u) => `${u.id}`)
            : filters.extUserId
            ? [`${filters.extUserId}`]
            : undefined,
        extJobId: filters.jobId ? `${filters.jobId}` : undefined,
        apiKey: ctx?.apiKey ?? "",
        // Backward 7 days
        from: selectedDateFrom.current,
        // Forward 7 days
        to: selectedDateTo.current,
      })
    );
  };
  const fetchMachineProgramsData = async () => {
    setMachinePrograms(
      await fetchMachinePrograms({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };
  const fetchIntegrationModulesData = async () => {
    setIntegrationModules(
      await fetchIntegrationModulesProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };

  const mappedDepts = depts
    .map((d) => d.departmentTemplateItems)
    .flat()
    .filter(
      (d) =>
        !hiddenJobDeptProcesses.find(
          (h) =>
            `${h.id}` === `${d.departmentTemplateItem?.departmentTemplate?.id}`
        )
    );
  const mappedPanelCodes = (() => {
    const jobsData = jobs
      .filter(
        (j) =>
          // Filter by job dept category
          (filters.jobDeptCategory
            ? filters.jobDeptCategory === "PPC"
              ? !j.jobDeptCategory ||
                j.jobDeptCategory === filters.jobDeptCategory
              : j.jobDeptCategory === filters.jobDeptCategory
            : true) &&
          // Filter by job
          (filters.jobId ? j.id === filters.jobId : true) &&
          // Filter by customer
          (filters.individualEntityId
            ? j.individualEntity?.id === filters.individualEntityId
            : true) &&
          // Filter by closed
          (() => {
            switch (filters.status) {
              case "outstanding":
                return !j.closingDate;
              case "all":
                return true;
              default:
                return true;
            }
          })()
      )
      .map((j) => j.panelCodes?.map((pc) => ({ job: j, panelCode: pc })))
      .flat()
      .filter((pc) =>
        (() => {
          switch (filters.status) {
            case "outstanding":
              return !pc?.panelCode.closed;
            case "all":
              return true;
            default:
              return true;
          }
        })()
      );

    jobsData.sort(
      (a, b) =>
        // (a?.job.deliveryDeadline.localeCompare(b?.job.deliveryDeadline ?? "") ??
        //   0) ||
        a?.panelCode.deadline?.localeCompare(b?.panelCode.deadline ?? "") ?? 0
    );

    return jobsData;
  })();

  const PanelCodeViewInfo = (props: {
    panelCode?: PanelCode | null;
    job?: Job | null;
    full?: boolean | null;
    index?: number | null;
  }) => {
    return (
      <div
        style={{ width: props.full ? undefined : 200 }}
        className="bg-light px-1"
      >
        <div>
          <small>
            <strong>
              {props.index}.{" "}
              {showFullPanelCode
                ? props.panelCode?.type
                : props.panelCode?.type?.substring(0, 6)}{" "}
              {showFullPanelCode
                ? props.panelCode?.name
                : props.panelCode?.name.substring(0, 6)}{" "}
              {showFullPanelCode
                ? props.panelCode?.serialNumber
                : props.panelCode?.serialNumber?.substring(0, 6)}{" "}
            </strong>
          </small>
        </div>
        {props.job ? (
          <>
            {" "}
            <div>
              <small>
                <strong>
                  {showFullPanelCode
                    ? props.job?.name
                    : props.job?.name
                        .split(" ")
                        .map((s) => s.substring(0, 6))
                        .join(" ")}{" "}
                  (
                  {showFullPanelCode
                    ? customers.find(
                        (c) => c.id === props.job?.individualEntity?.id
                      )?.name
                    : customers
                        .find((c) => c.id === props.job?.individualEntity?.id)
                        ?.name.split(" ")
                        .map((s) => s.substring(0, 6))
                        .join(" ")}
                  )
                </strong>
              </small>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const UserInfo = (props: { user?: User | null; index?: number | null }) => {
    return (
      <div style={{ width: 200 }} className="bg-light overflow-auto">
        <div>
          <pre>
            <strong>
              {props?.index}. {props.user?.name} (
              {props.user?.roles?.map((r) => r.name ?? "").join(", ")})
            </strong>
          </pre>
        </div>
      </div>
    );
  };

  const MachineInfo = (props: {
    machine?: Machine | null;

    index?: number | null;
  }) => {
    return (
      <div style={{ width: 200 }} className="bg-light overflow-auto">
        <div>
          <pre>
            <strong>
              {props?.index}. {props.machine?.name} (
              {props.machine?.processType?.name})
            </strong>
          </pre>
        </div>
      </div>
    );
  };

  const handleReschedule = async (tLs: MeetingTaskListView[]) => {
    try {
      setLoading(true);
      console.log("[task lists]", tLs);

      const resp = await fetch(
        `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-save-bulk`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(
            MeetingTaskListsView.toJSON(
              MeetingTaskListsView.fromPartial({
                taskLists: tLs,
              })
            )
          ),
        }
      );
      // window.location.reload();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-end">
          <h4>
            Job Dept Progress
            {ctx?.gspeAppUser
              ? ` | ${ctx.gspeAppUser.username} (${
                  ctx.extDepartments.find(
                    (d) => `${d.id}` === `${ctx.gspeAppUser?.departmentId}`
                  )?.name ?? ""
                }, DeptGroup: ${
                  departmentGroups.current.groups.find((g) =>
                    g.departments.find(
                      (d) =>
                        `${d.masterJavaBaseModel?.id}` ===
                        `${ctx.gspeAppUser?.departmentId}`
                    )
                  )?.name ?? ""
                })`
              : ""}
          </h4>
          {loading ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <></>
          )}
        </div>
        <hr />
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex">
            View by:{" "}
            {(
              [
                "Process",
                // "Department",
                "Timeline",
                "WO Timeline",
                "Manpower",
                // "Machine",

                "Work Order",
                "Comments",
                "Dept Summ",
              ] as JobDeptView[]
            ).map((n) => {
              return (
                <div className="mx-3">
                  <button
                    onClick={(e) => {
                      setView(n);
                    }}
                    className={`btn btn-sm ${
                      view === n ? `btn-primary` : `btn-outline-primary`
                    }`}
                  >
                    {n}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-between">
          <div className="flex-grow-1">
            <Select
              styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
              options={[
                { label: "None", value: PpicJob.fromPartial({}) },
                ...(jobsProto?.jobs?.map((j) => ({
                  label: j.name ?? "No name",
                  value: j,
                })) ?? []),
              ]}
              placeholder="Select job..."
              onChange={(v) => {
                const val = v as unknown as { value: PpicJob };
                // // const vType = v as unknown as { value: Job };
                setFilters({
                  ...filters,
                  jobId: val.value.masterJavaBaseModel?.id
                    ? !isNaN(parseInt(val.value.masterJavaBaseModel?.id))
                      ? parseInt(val.value.masterJavaBaseModel?.id)
                      : null
                    : null,
                });
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
              options={[
                { label: "None", value: null },
                ...customers.map((c) => ({ label: c.name, value: c })),
              ]}
              placeholder="Select customer..."
              onChange={(v) => {
                const vType = v as unknown as { value: IndividualEntity };

                setFilters({
                  ...filters,
                  individualEntityId: vType.value?.id ?? null,
                });
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
              options={[
                { label: "None", value: null },
                ...(ctx?.extUsers.map((u) => ({
                  label: `${u.name} (${u.departmentName})`,
                  value: u,
                })) ?? []),
              ]}
              placeholder="Select user..."
              onChange={(v) => {
                const vType = v as unknown as { value: ExtUser | null };

                setFilters({
                  ...filters,
                  extUserId: vType.value?.id,
                  extDepartmentId: null,
                });
              }}
            />
          </div>
          <div className="flex-grow-1">
            <div>
              <div>
                <Select
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
                  options={ctx?.extDepartments ?? []}
                  placeholder="Select dept..."
                  getOptionLabel={(d) => d.name ?? ""}
                  onChange={(v) => {
                    const vType = v as unknown as ExtDepartment;

                    setFilters({
                      ...filters,
                      extDepartmentId: vType.id,
                      extDepartmentIds: filters.extDepartmentIds.find(
                        (i) => `${i}` === `${vType.id}`
                      )
                        ? filters.extDepartmentIds
                        : [...filters.extDepartmentIds, vType.id],
                      extUserId: null,
                    });
                  }}
                />
              </div>
              <div>
                {filters.extDepartmentIds.map((i, i_) => {
                  return (
                    <>
                      <div className="border border-dark rounded rounded-md px-1">
                        <div className="d-flex justify-content-between">
                          <div>
                            {i_ + 1}){" "}
                            {
                              ctx?.extDepartments.find(
                                (d) => `${d.id}` === `${i}`
                              )?.name
                            }
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setFilters({
                                ...filters,
                                extDepartmentIds:
                                  filters.extDepartmentIds.filter(
                                    (ix) => `${ix}` !== `${i}`
                                  ),
                              });
                            }}
                          >
                            <CancelOutlined />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setOpenRescheduleDeptModal(true);
                  }}
                >
                  Reschedule Dept
                </button>
              </div> */}
            </div>
          </div>
          <div className="flex-grow-1">
            <Select
              styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
              options={
                [
                  { label: "PPC", value: "PPC" },
                  { label: "General", value: "OTHERS" },
                ] as { label: JobDeptCategory; value: JobDeptCategory }[]
              }
              placeholder="Select categ..."
              onChange={(v) => {
                const vType = v as unknown as {
                  label: JobDeptCategory;
                  value: JobDeptCategory;
                };

                setFilters({
                  ...filters,
                  jobDeptCategory: vType.value,
                });
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
              options={
                departmentGroups.current.groups.map((g) => ({
                  label: g.name ?? "",
                  value: g,
                })) ?? []
              }
              placeholder="Select dept group..."
              // getOptionLabel={(d) => d.name ?? ""}
              onChange={(v) => {
                const dg = v as {value: AuthserverDepartmentGroup};


                setFilters({
                  ...filters,
                  extDepartmentId: null,
                  extDepartmentIds: dg.value.departments?.map((d) =>
                    isNaN(parseInt(d.masterJavaBaseModel?.id ?? ""))
                      ? 0
                      : parseInt(d.masterJavaBaseModel?.id ?? "")
                  )??[],
                });
              }}
            />
          </div>

          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleInit();
              }}
            >
              Get data
            </button>
          </div>

          <div
            className="d-flex align-items-end"
            onClick={() => {
              setShowFullPanelCode(!showFullPanelCode);
            }}
            style={{ cursor: "pointer" }}
          >
            <div>Show full names?</div>
            <div>
              <input type="checkbox" checked={showFullPanelCode} />
            </div>
          </div>
        </div>
        {view === "Process" ? (
          <>
            <div
              className="overflow-auto"
              style={{ height: "75vh", resize: "vertical" }}
            >
              <div className="d-flex flex-wrap">
                {hiddenJobDeptProcesses.map((h) => (
                  <>
                    <div
                      className="p-1 m-1 border border-dark rounded rounded-md"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        if (await get("hiddenJobDeptProcesses")) {
                          await set(
                            "hiddenJobDeptProcesses",
                            (
                              (await get("hiddenJobDeptProcesses")) as
                                | DepartmentTemplate[]
                                | null
                                | undefined
                            )?.filter((hx) => `${hx.id}` !== `${h.id}`)
                          );
                          setHiddenJobDeptProcesses(
                            hiddenJobDeptProcesses.filter(
                              (hx) => `${hx.id}` !== `${h.id}`
                            )
                          );
                        }
                      }}
                    >
                      {h.name}
                    </div>
                  </>
                ))}
              </div>
              <table
                className="table table-sm table-bordered table-hover"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["Product Name", "Job", "Qty", "Deadline"].map((h) => (
                    <th
                      className="border border-dark bg-dark text-light sticky-top top-0"
                      style={{
                        left: h === "Product Name" ? 0 : "undefined",
                        zIndex: h === "Product Name" ? 10 : undefined,
                      }}
                    >
                      <div style={{ maxWidth: 400 }}>{h}</div>
                    </th>
                  ))}
                  {mappedDepts.map((d) => {
                    return (
                      <>
                        <th
                          className="border border-dark bg-dark sticky-top top-0"
                          style={{ zIndex: 1, whiteSpace: "nowrap" }}
                        >
                          <div>
                            <div
                              onClick={async () => {
                                if (
                                  d.departmentTemplateItem
                                    ?.departmentTemplate &&
                                  !hiddenJobDeptProcesses.find(
                                    (h) =>
                                      `${h.id}` ===
                                      `${d.departmentTemplateItem?.departmentTemplate?.id}`
                                  )
                                ) {
                                  setHiddenJobDeptProcesses([
                                    ...hiddenJobDeptProcesses,
                                    d.departmentTemplateItem
                                      ?.departmentTemplate,
                                  ]);

                                  if (await get("hiddenJobDeptProcesses")) {
                                    set("hiddenJobDeptProcesses", [
                                      ...(await get("hiddenJobDeptProcesses")),
                                      d.departmentTemplateItem
                                        .departmentTemplate,
                                    ]);
                                  } else {
                                    set("hiddenJobDeptProcesses", [
                                      d.departmentTemplateItem
                                        .departmentTemplate,
                                    ]);
                                  }
                                } else {
                                }
                              }}
                              style={{
                                cursor: "pointer",
                                backgroundColor: new ColorHash({
                                  lightness: [0.7],
                                }).hex(
                                  d.departmentTemplateItem?.departmentTemplate
                                    ?.name ?? ""
                                ),
                              }}
                            >
                              <small>
                                <strong>
                                  {showFullPanelCode
                                    ? d.departmentTemplateItem
                                        ?.departmentTemplate?.name
                                    : d.departmentTemplateItem?.departmentTemplate?.name?.substring(
                                        0,
                                        5
                                      )}
                                </strong>
                              </small>
                            </div>
                            <div
                              style={{
                                backgroundColor: new ColorHash({
                                  lightness: [0.7],
                                }).hex(d.departmentTemplateItem?.name ?? ""),
                              }}
                            >
                              <strong>
                                {showFullPanelCode
                                  ? d.departmentTemplateItem?.name
                                  : d.departmentTemplateItem?.name?.substring(
                                      0,
                                      5
                                    )}
                              </strong>
                            </div>
                          </div>
                        </th>
                      </>
                    );
                  })}
                </tr>
                {mappedPanelCodes.map((pc, i) => {
                  return (
                    <>
                      <tr>
                        <td
                          className="border border-dark bg-light"
                          style={{ position: "sticky", left: 0, zIndex: 1 }}
                        >
                          <div style={{ width: 200 }}>
                            <small>
                              <strong>
                                {i + 1}.{" "}
                                {showFullPanelCode
                                  ? pc?.panelCode.type
                                  : pc?.panelCode.type
                                      ?.split(" ")
                                      .map((s) => s.substring(0, 6))
                                      .join(" ")}{" "}
                                {showFullPanelCode
                                  ? pc?.panelCode.name
                                  : pc?.panelCode.name
                                      ?.split(" ")
                                      .map((s) => s.substring(0, 6))
                                      .join(" ")}
                              </strong>
                            </small>
                          </div>
                        </td>
                        <td
                          className="border border-dark"
                          style={{ zIndex: 1 }}
                        >
                          <div style={{ width: 250 }}>
                            <Link to={`/jobs/${pc?.job.id}`}>
                              <small>
                                <small>
                                  <strong>
                                    {showFullPanelCode
                                      ? pc?.job.name
                                      : pc?.job.name
                                          .split(" ")
                                          .map((s) => s.substring(0, 6))
                                          .join(" ")}{" "}
                                    (
                                    {(() => {
                                      try {
                                        return makeReadableDateStringIntlDateOnlyUtc(
                                          new Date(
                                            `${pc?.job.deliveryDeadline}T00:00:00Z`
                                          )
                                        );
                                      } catch (e) {
                                        return "";
                                      }
                                    })()}
                                    )
                                  </strong>
                                </small>
                              </small>
                            </Link>
                          </div>
                        </td>
                        <td
                          className="border border-dark"
                          style={{ zIndex: 1 }}
                        >
                          <small>
                            <strong>{pc?.panelCode.qty}</strong>
                          </small>
                        </td>
                        <td
                          className="border border-dark"
                          style={{ zIndex: 1 }}
                        >
                          <small>
                            <strong>
                              {(() => {
                                try {
                                  return makeReadableDateStringIntlDateOnlyUtc(
                                    new Date(
                                      `${pc?.panelCode.deadline}T00:00:00Z`
                                    )
                                  );
                                } catch (e) {
                                  return <></>;
                                }
                              })()}
                            </strong>
                          </small>
                        </td>
                        {mappedDepts.map((i, i_) => {
                          const foundPanelCodeDepartmentTemplateItem =
                            pc?.panelCode.panelCodeDepartmentTemplateItems?.find(
                              (dti) =>
                                dti &&
                                dti.departmentTemplateItem?.id ===
                                  i.departmentTemplateItem?.id
                            );

                          return (
                            <>
                              <td
                                className="border border-dark"
                                style={{
                                  backgroundColor:
                                    foundPanelCodeDepartmentTemplateItem
                                      ? foundPanelCodeDepartmentTemplateItem.excluded
                                        ? "gray"
                                        : foundPanelCodeDepartmentTemplateItem.completedDate
                                        ? "lightgreen"
                                        : compareDateStringUtcColor({
                                            current: `${makeDateString(
                                              new Date()
                                            )}T00:00:00Z`,
                                            date: foundPanelCodeDepartmentTemplateItem?.startDate,
                                          })
                                      : "gray",
                                }}
                              >
                                <div className="d-flex">
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={async () => {
                                      setOpenPanelCodeRelatedWorkOrdersModal(
                                        true
                                      );
                                      if (pc?.panelCode.id) {
                                        setSelectedRelatedWorkOrderExtPanelCodeId(
                                          `${pc.panelCode.id}`
                                        );

                                        setPanelCodeRelatedCommentsLoading(
                                          true
                                        );
                                        setPanelCodeRelatedComments(
                                          await fetchMeetingTaskComments({
                                            apiKey: ctx?.apiKey ?? "",
                                            extPanelCodeId:
                                              pc.panelCode.id !== null &&
                                              pc.panelCode.id !== undefined
                                                ? `${pc.panelCode.id}`
                                                : undefined,
                                            extSubProcessId:
                                              foundPanelCodeDepartmentTemplateItem
                                                ?.departmentTemplateItem?.id !==
                                                null &&
                                              foundPanelCodeDepartmentTemplateItem
                                                ?.departmentTemplateItem?.id !==
                                                undefined
                                                ? `${foundPanelCodeDepartmentTemplateItem?.departmentTemplateItem?.id}`
                                                : undefined,
                                          })
                                        );
                                        setPanelCodeRelatedCommentsLoading(
                                          false
                                        );
                                        setSelectedRelatedWorkOrderDepartmentTemplateItemId(
                                          i.departmentTemplateItem?.id
                                            ? `${i.departmentTemplateItem?.id}`
                                            : undefined
                                        );
                                      }
                                    }}
                                  >
                                    {foundPanelCodeDepartmentTemplateItem?.startDate ? (
                                      <>
                                        <small>
                                          <strong>
                                            {(() => {
                                              try {
                                                return Intl.DateTimeFormat(
                                                  "id-ID",
                                                  {
                                                    dateStyle: "short",
                                                    timeZone: "utc",
                                                  } as any
                                                ).format(
                                                  new Date(
                                                    foundPanelCodeDepartmentTemplateItem.startDate
                                                  )
                                                );
                                              } catch (e) {
                                                return "";
                                              }
                                            })()}
                                          </strong>
                                        </small>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {foundPanelCodeDepartmentTemplateItem?.completedDate ? (
                                      <>
                                        <hr
                                          className="border border-dark"
                                          style={{ margin: 0 }}
                                        />
                                        <small
                                          style={{
                                            backgroundColor:
                                              foundPanelCodeDepartmentTemplateItem?.completedDate &&
                                              foundPanelCodeDepartmentTemplateItem?.startDate
                                                ? (() => {
                                                    try {
                                                      return new Date(
                                                        foundPanelCodeDepartmentTemplateItem.startDate
                                                      ).getTime() <
                                                        new Date(
                                                          foundPanelCodeDepartmentTemplateItem.completedDate
                                                        ).getTime()
                                                        ? "lightsalmon"
                                                        : "";
                                                    } catch (e) {
                                                      return "";
                                                    }
                                                  })()
                                                : "",
                                          }}
                                        >
                                          <strong>
                                            {(() => {
                                              try {
                                                return Intl.DateTimeFormat(
                                                  "id-ID",
                                                  {
                                                    dateStyle: "short",
                                                    timeZone: "utc",
                                                  } as any
                                                ).format(
                                                  new Date(
                                                    foundPanelCodeDepartmentTemplateItem.completedDate
                                                  )
                                                );
                                              } catch (e) {
                                                return "";
                                              }
                                            })()}
                                          </strong>
                                        </small>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {foundPanelCodeDepartmentTemplateItem?.numComments !==
                                    null &&
                                  foundPanelCodeDepartmentTemplateItem?.numComments !==
                                    undefined ? (
                                    <>
                                      <div>
                                        <small className="bg-danger rounded-circle font-weight-bold text-light p-1">
                                          {
                                            foundPanelCodeDepartmentTemplateItem?.numComments
                                          }
                                        </small>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Department" ? (
          <>
            <div
              className="overflow-auto"
              style={{ height: "75vh", resize: "vertical" }}
            >
              <table
                className="table table-sm table-bordered table-hover"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["Product Name", "Job"].map((h, i) => (
                    <th
                      className="border border-dark bg-dark text-light sticky-top top-0"
                      style={{
                        left: h === "Product Name" ? 0 : "undefined",
                        zIndex: h === "Product Name" ? 10 : undefined,
                      }}
                    >
                      <div style={{ maxWidth: 400 }}>{h}</div>
                    </th>
                  ))}
                  {depts.map((d) => {
                    return (
                      <>
                        <th
                          className="border border-dark bg-dark text-light sticky-top top-0"
                          style={{ zIndex: 1, whiteSpace: "nowrap" }}
                        >
                          <div>
                            <div>
                              <strong>
                                {d?.departmentTemplate?.name?.substring(0, 5)}
                              </strong>
                            </div>
                          </div>
                        </th>
                      </>
                    );
                  })}
                </tr>
                {mappedPanelCodes.map((pc, i) => {
                  return (
                    <>
                      <tr>
                        <td
                          className="border border-dark"
                          style={{ position: "sticky", left: 0, zIndex: 1 }}
                        >
                          <div className="d-flex">
                            <PanelCodeViewInfo
                              panelCode={pc?.panelCode}
                              // job={pc?.job}
                              full
                              index={i + 1}
                            />
                          </div>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>{pc?.job.name}</strong>
                          </small>
                        </td>
                        {depts.map((d) => {
                          return (
                            <>
                              <td
                                className={`border border-dark ${(() => {
                                  if (i % 1 === 2) {
                                    return "bg-success";
                                  } else if (i % 3 === 0) {
                                    return "bg-dark";
                                  } else if (i % 7 === 0) {
                                    return "bg-danger";
                                  } else if (i % 5 === 0) {
                                    return "bg-warning";
                                  } else {
                                    return "";
                                  }
                                })()}`}
                              ></td>
                            </>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Timeline" ? (
          <>
            <div>
              <div className="d-flex">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() - 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Prev
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() + 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className="overflow-auto"
              style={{ height: "75vh", resize: "vertical" }}
            >
              <table
                className="table table-sm table-bordered table-hover"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["Product Name", "Job"].map((h) => (
                    <th
                      className="border border-dark bg-dark text-light sticky-top top-0"
                      style={{
                        left: h === "Product Name" ? 0 : "undefined",
                        zIndex: h === "Product Name" ? 10 : undefined,
                      }}
                    >
                      <div style={{ maxWidth: 400 }}>{h}</div>
                    </th>
                  ))}
                  {[...Array(31)].map((_, i) => {
                    const curDate = new Date(
                      selectedDate.getTime() + 86400000 * i
                    );
                    return (
                      <th
                        style={{ zIndex: 1 }}
                        className={`border border-dark text-light sticky-top top-0 ${
                          new Date(
                            selectedDate.getTime() + 86400000 * i
                          ).getUTCDay() === 0 ||
                          new Date(
                            selectedDate.getTime() + 86400000 * i
                          ).getUTCDay() === 6
                            ? "bg-danger"
                            : new Date(curDate).getTime() ===
                              new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate()
                              ).getTime()
                            ? `bg-info`
                            : "bg-dark"
                        }`}
                      >
                        <div>
                          <small>
                            <strong>
                              {makeReadableDateStringShortIntl(
                                new Date(selectedDate.getTime() + 86400000 * i)
                              )}
                            </strong>
                          </small>
                        </div>
                        <div>
                          <small>
                            <strong>
                              {
                                [
                                  "Sun",
                                  "Mon",
                                  "Tue",
                                  "Wed",
                                  "Thu",
                                  "Fri",
                                  "Sat",
                                ][
                                  new Date(
                                    selectedDate.getTime() + 86400000 * i
                                  ).getUTCDay()
                                ]
                              }
                            </strong>
                          </small>
                        </div>
                      </th>
                    );
                  })}
                </tr>
                {mappedPanelCodes.map((pc, i) => {
                  return (
                    <>
                      <tr>
                        <td
                          className="border border-dark"
                          style={{ position: "sticky", left: 0, zIndex: 1 }}
                        >
                          <div className="d-flex">
                            <PanelCodeViewInfo
                              panelCode={pc?.panelCode}
                              // job={pc?.job}
                              index={i + 1}
                            />
                          </div>
                        </td>
                        <td className="border border-dark">
                          <div style={{ width: 250 }}>
                            <small>
                              <small>
                                <strong>
                                  {showFullPanelCode
                                    ? pc?.job.name
                                    : pc?.job.name
                                        .split(" ")
                                        .map((s) => s.substring(0, 6))
                                        .join(" ")}
                                </strong>
                              </small>
                            </small>
                          </div>
                        </td>

                        {[...Array(31)].map((_, i) => {
                          const curDate = new Date(
                            selectedDate.getTime() + 86400000 * i
                          );

                          const foundDtis =
                            pc?.panelCode.panelCodeDepartmentTemplateItems?.filter(
                              (dti) => {
                                try {
                                  return (
                                    new Date(dti.startDate ?? "").getTime() <=
                                      curDate.getTime() &&
                                    new Date(dti.endDate ?? "").getTime() >=
                                      curDate.getTime()
                                  );
                                } catch (e) {
                                  return false;
                                }
                              }
                            );

                          return (
                            <td className={`border border-dark`}>
                              <ol>
                                {foundDtis?.map((dti) => {
                                  return (
                                    <>
                                      <li>
                                        <div className="d-flex">
                                          <div
                                            className={`${
                                              dti.completedDate
                                                ? "text-light"
                                                : "text-dark"
                                            }`}
                                            style={{
                                              whiteSpace: "nowrap",
                                              backgroundColor: dti.completedDate
                                                ? "green"
                                                : new ColorHash({
                                                    lightness: [0.7],
                                                  }).hex(
                                                    dti.departmentTemplateItem
                                                      ?.name ?? ""
                                                  ),
                                            }}
                                          >
                                            <small>
                                              <strong>
                                                {depts
                                                  .find((d) =>
                                                    d.departmentTemplateItems.find(
                                                      (dtix) =>
                                                        dtix
                                                          .departmentTemplateItem
                                                          ?.id ===
                                                        dti
                                                          .departmentTemplateItem
                                                          ?.id
                                                    )
                                                  )
                                                  ?.departmentTemplate?.name?.substring(
                                                    0,
                                                    2
                                                  )
                                                  .toUpperCase()}
                                                :{" "}
                                                {dti.departmentTemplateItem?.name?.substring(
                                                  0,
                                                  6
                                                )}
                                              </strong>
                                            </small>
                                          </div>
                                          {/* {dti.completedDate &&
                                          dti.completedDate !== "" ? (
                                            <div
                                              className=" font-weight-bold"
                                              style={{
                                                backgroundColor: "lightgreen",
                                              }}
                                            >
                                              <small>
                                                <strong>OK</strong>
                                              </small>
                                            </div>
                                          ) : (
                                            <></>
                                          )} */}

                                          {/* <div>{curDate.getTime()}</div>
                                          <div>{pc?.panelCode.panelCodeDepartmentTemplateItems?.length}</div> */}
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ol>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "WO Timeline" ? (
          <>
            <div>
              <div className="d-flex">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() - 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Prev
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() + 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Next
                </button>
              </div>
              <div
                className="overflow-auto border border-dark"
                style={{ height: "75vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {["Product Name", "Job"].map((h) => (
                      <>
                        <th className="bg-dark text-light">{h}</th>
                      </>
                    ))}
                    {[...Array(31)].map((_, i) => {
                      const curDate = new Date(
                        selectedDate.getTime() + 86400000 * i
                      );
                      return (
                        <th
                          style={{ zIndex: 1, position: "sticky", top: 0 }}
                          className={`border border-dark text-light  ${
                            new Date(
                              selectedDate.getTime() + 86400000 * i
                            ).getUTCDay() === 0 ||
                            new Date(
                              selectedDate.getTime() + 86400000 * i
                            ).getUTCDay() === 6
                              ? "bg-danger"
                              : new Date(curDate).getTime() ===
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  new Date().getDate()
                                ).getTime()
                              ? `bg-info`
                              : "bg-dark"
                          }`}
                        >
                          <div>
                            <small>
                              <strong>
                                {makeReadableDateStringShortIntl(
                                  new Date(
                                    selectedDate.getTime() + 86400000 * i
                                  )
                                )}
                              </strong>
                            </small>
                          </div>
                          <div>
                            <small>
                              <strong>
                                {
                                  [
                                    "Sun",
                                    "Mon",
                                    "Tue",
                                    "Wed",
                                    "Thu",
                                    "Fri",
                                    "Sat",
                                  ][
                                    new Date(
                                      selectedDate.getTime() + 86400000 * i
                                    ).getUTCDay()
                                  ]
                                }
                              </strong>
                            </small>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                  {mappedPanelCodes.map((pc, i) => {
                    return (
                      <>
                        <tr>
                          <td
                            className="border border-dark bg-light"
                            style={{ position: "sticky", left: 0, zIndex: 1 }}
                          >
                            <div className="d-flex">
                              <PanelCodeViewInfo
                                panelCode={pc?.panelCode}
                                // job={pc?.job}
                                index={i + 1}
                              />
                            </div>
                          </td>
                          <td className="border border-dark">
                            <div style={{ width: 250 }}>
                              <small>
                                <small>
                                  <strong>
                                    {showFullPanelCode
                                      ? pc?.job.name
                                      : pc?.job.name
                                          .split(" ")
                                          .map((s) => s.substring(0, 6))
                                          .join(" ")}
                                  </strong>
                                </small>
                              </small>
                            </div>
                          </td>

                          {[...Array(31)].map((_, i) => {
                            const curDate = new Date(
                              selectedDate.getTime() + 86400000 * i
                            );

                            const wos = filterWorkOrderByDate(
                              workOrders,
                              makeDateString(curDate),
                              null
                            )?.filter(
                              (tL) =>
                                `${tL.taskList?.extPanelCodeId}` ===
                                `${pc?.panelCode.id}`
                            );

                            const wosTime = wos?.reduce(
                              (acc, tL) =>
                                acc +
                                getWorkOrderTimeMinutes(
                                  tL.taskList,
                                  rcemDepartments,
                                  integrationModules,
                                  machinePrograms,
                                  departmentTemplates,
                                  panelCodeDepartmentTemplateItemsProto,
                                  null,
                                  curDate
                                ).totalHours,
                              0.0
                            );
                            const wosTimeActual = wos?.reduce(
                              (acc, tL) =>
                                acc +
                                getWorkOrderTimeMinutes(
                                  tL.taskList,
                                  rcemDepartments,
                                  integrationModules,
                                  machinePrograms,
                                  departmentTemplates,
                                  panelCodeDepartmentTemplateItemsProto,
                                  null,
                                  curDate
                                ).totalActualHours,
                              0.0
                            );

                            return (
                              <td
                                className={`border border-dark ${
                                  (wos?.length ?? 0) > 0 ? `` : `bg-secondary`
                                }`}
                              >
                                <div>
                                  {(wos?.length ?? 0) > 0 ? (
                                    <>
                                      {" "}
                                      <div className="d-flex">
                                        <div className="d-flex justify-content-center border-right border-secondary flex-grow-1">
                                          <small>
                                            <strong>
                                              {wosTimeActual?.toFixed(1)}
                                            </strong>
                                          </small>
                                        </div>
                                        <div className="d-flex justify-content-center border-left border-secondary flex-grow-1">
                                          <small>
                                            <strong>
                                              {wosTime?.toFixed(1)}
                                            </strong>
                                          </small>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Process" ? (
          <>
            <div>
              <div style={{ height: "75vh", resize: "vertical" }}>
                <table className="table table-sm"></table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Manpower" ? (
          (() => {
            const filteredUsers = ctx?.extUsers
              .filter((u) =>
                filters.extUserId ? u.id === filters.extUserId : true
              )
              .filter((u) =>
                // filters.extDepartmentId
                //   ? u.departmentId === filters.extDepartmentId
                //   : true

                (filters.extDepartmentIds.length ?? 0) > 0
                  ? filters.extDepartmentIds.find(
                      (i) => `${u.departmentId}` === `${i}`
                    )
                  : true
              );

            return (
              <>
                <div>
                  <div className="d-flex">
                    <div>
                      <div>
                        <strong>From</strong>
                      </div>
                      <div>
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          defaultValue={selectedDateFrom.current}
                          onBlur={(e) => {
                            selectedDateFrom.current = e.target.value;
                            render();
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <div>
                        <strong>To</strong>
                      </div>
                      <div>
                        <input
                          className="form-control form-control-sm"
                          type="date"
                          defaultValue={selectedDateTo.current}
                          onBlur={(e) => {
                            selectedDateTo.current = e.target.value;
                            render();
                          }}
                        />
                      </div>
                    </div>

                    {/* <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setSelectedDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth() - 1,
                            selectedDate.getDate()
                          )
                        );
                      }}
                    >
                      Prev
                    </button>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setSelectedDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth() + 1,
                            selectedDate.getDate()
                          )
                        );
                      }}
                    >
                      Next
                    </button> */}
                  </div>
                </div>
                <div
                  className="overflow-auto"
                  style={{ height: "75vh", resize: "vertical" }}
                >
                  <table
                    className="table table-sm table-bordered table-hover"
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {["Manpower Name"].map((h) => (
                        <th
                          className="border border-dark bg-dark text-light sticky-top top-0"
                          style={{
                            left: h === "Manpower Name" ? 0 : "undefined",
                            zIndex: h === "Manpower Name" ? 10 : undefined,
                          }}
                        >
                          <div style={{ maxWidth: 400 }}>{h}</div>
                        </th>
                      ))}
                      {[...Array(daysFromTo)].map((_, i) => {
                        const curDate =
                          new Date(selectedDateFrom.current).getTime() +
                          86400000 * i;

                        const inCharges = filterWorkOrderByDate(
                          workOrders,
                          makeDateString(new Date(curDate)),
                          null
                        )
                          ?.map((tL) => tL.taskList?.meetingTasks)
                          .flat()
                          .map((mt) => mt?.meetingTaskInCharges ?? [])
                          .flat();

                        const uniqInCharges = [
                          ...new Set(
                            inCharges
                              ?.filter((ic) => ic && ic.extUserId !== "0")
                              ?.map((ic) => ic.extUserId as string)
                          ),
                        ].filter((uid) =>
                          filteredUsers && filteredUsers.length > 0
                            ? filteredUsers.find((u) => `${u.id}` === uid)
                            : true
                        );

                        console.log(
                          new Date(curDate).toISOString().split("T")[0],
                          uniqInCharges
                        );

                        return (
                          <th
                            style={{ zIndex: 1, whiteSpace: "nowrap" }}
                            className={`border border-dark ${
                              new Date(
                                new Date(selectedDateFrom.current).getTime() +
                                  86400000 * i
                              ).getDay() === 0 ||
                              new Date(
                                new Date(selectedDateFrom.current).getTime() +
                                  86400000 * i
                              ).getDay() === 6
                                ? "bg-danger"
                                : new Date(curDate).getTime() ===
                                  new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  ).getTime()
                                ? `bg-info`
                                : "bg-dark"
                            } text-light sticky-top top-0`}
                          >
                            <div>
                              <div>
                                <small>
                                  <strong>
                                    {makeReadableDateStringShortIntl(
                                      new Date(curDate)
                                    )}
                                  </strong>
                                </small>
                              </div>
                              {/* <div>
                                {new Date(
                                selectedDate.getTime() + 86400000 * i
                              ).getDay()}
                              </div> */}
                              <div>
                                <small>
                                  <strong>
                                    {uniqInCharges.length}/
                                    {filteredUsers?.length} (
                                    {(
                                      ((uniqInCharges.length ?? 0) /
                                        (filteredUsers?.length ?? 1)) *
                                      100
                                    ).toFixed(0)}
                                    %)
                                  </strong>
                                </small>
                              </div>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                    {filteredUsers?.map((u, i) => {
                      const foundNotes = attendanceNotes.current.filter((n) => {
                        return (
                          `${n.extUserId}` === `${u.id}` &&
                          new Date(n.date ?? "").getTime() >=
                            new Date(
                              `${makeDateString(new Date())}T00:00:00`
                            ).getTime() &&
                          new Date(n.date ?? "").getTime() <=
                            new Date(
                              `${makeDateString(new Date())}T23:59:59`
                            ).getTime()
                        );
                      });

                      return (
                        <>
                          <tr>
                            <td
                              className="border border-dark bg-light"
                              style={{
                                position: "sticky",
                                left: 0,
                                zIndex: 1,
                                width: 200,
                              }}
                            >
                              <div
                                style={{
                                  width: 200,
                                  backgroundColor: workOrders?.taskLists?.find(
                                    (tL) =>
                                      `${tL.taskList?.extInChargeId}` ===
                                        `${u.id}` ||
                                      tL?.taskList?.meetingTasks
                                        ?.map(
                                          (mt) => mt.meetingTaskInCharges ?? []
                                        )
                                        ?.flat()
                                        .find(
                                          (mtic) =>
                                            `${mtic.extUserId}` === `${u.id}`
                                        )
                                  )
                                    ? "lightgreen"
                                    : undefined,
                                }}
                              >
                                <div className="overflow-auto d-flex justify-content-between">
                                  <small>
                                    <strong>
                                      {u.name} ({u.departmentName})
                                    </strong>
                                  </small>
                                  {(() => {
                                    const calculateReschedule = async (props: {
                                      simple: boolean;
                                    }) => {
                                      // Open reschedule modals
                                      setShowReschedulerModal(true);
                                      setShowReschedulerModalLoading(true);
                                      setReschedulerUser(u);

                                      // Fetch relevant tasklist
                                      try {
                                        const resp = await fetch(
                                          `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-mongo?extUserId=${u.id}`,
                                          {
                                            headers: {
                                              authorization: ctx?.apiKey ?? "",
                                            },
                                          }
                                        );

                                        if (resp.status !== 200)
                                          throw await resp.text();

                                        const reschedulerMeetingTaskListData =
                                          MeetingTaskListsView.fromPartial(
                                            await resp.json()
                                          );
                                        setReschedulerMeetingTaskList(
                                          reschedulerMeetingTaskListData
                                        );

                                        const incompleteTaskLists = (
                                          JSON.parse(
                                            JSON.stringify(
                                              reschedulerMeetingTaskListData
                                            )
                                          ) as MeetingTaskListsView
                                        ).taskLists.filter(
                                          (tL) =>
                                            // Filter still outstanding
                                            tL.taskList?.meetingTasks.find(
                                              (mt) =>
                                                mt?.status === "OUTSTANDING"
                                            ) &&
                                            // Filter not SPV
                                            `${tL.taskList.extInChargeId}` !==
                                              `${reschedulerUser?.id}` &&
                                            // Filter meeting tasks
                                            (() => {
                                              const meetingTasks =
                                                tL.taskList.meetingTasks;
                                              meetingTasks.sort(
                                                (a, b) =>
                                                  extractProtoMeetingTaskTargetDate(
                                                    b
                                                  )?.date?.localeCompare(
                                                    extractProtoMeetingTaskTargetDate(
                                                      a
                                                    )?.date ?? ""
                                                  ) ?? 0
                                              );
                                              const latesteEndDate =
                                                extractProtoMeetingTaskTargetDate(
                                                  meetingTasks?.[0]
                                                )?.date;

                                              if (latesteEndDate) {
                                                // 1 day before rescheduleTarget
                                                return (
                                                  new Date(
                                                    rescheduleTarget
                                                  ).getTime() -
                                                    86400000 ===
                                                  new Date(
                                                    latesteEndDate
                                                  ).getTime()
                                                );
                                              } else {
                                                return false;
                                              }
                                            })()
                                        );

                                        const taskListsValid = (
                                          JSON.parse(
                                            JSON.stringify(
                                              reschedulerMeetingTaskListData
                                            )
                                          ) as MeetingTaskListsView
                                        ).taskLists.filter(
                                          (tL) =>
                                            // Filter not SPV
                                            `${tL.taskList?.extInChargeId}` !==
                                              `${reschedulerUser?.id}` &&
                                            // Filter meeting tasks
                                            (() => {
                                              const meetingTasks =
                                                tL.taskList?.meetingTasks ?? [];
                                              const startDates = meetingTasks
                                                .map((mt) => mt.start)
                                                .filter((start) => start)
                                                .map(
                                                  (start) => start as string
                                                );

                                              const endDates = meetingTasks
                                                .map(
                                                  (mt) =>
                                                    extractProtoMeetingTaskTargetDate(
                                                      mt
                                                    )?.date
                                                )
                                                .filter((end) => end)
                                                .map((end) => end as string);

                                              startDates.sort((a, b) =>
                                                a.localeCompare(b)
                                              );
                                              endDates.sort((a, b) =>
                                                b.localeCompare(a)
                                              );

                                              const earliestStartDate =
                                                startDates?.[0];
                                              const farthestEndDate =
                                                endDates?.[0];

                                              if (
                                                earliestStartDate &&
                                                farthestEndDate
                                              ) {
                                                return (
                                                  new Date(
                                                    rescheduleTarget
                                                  ).getTime() <=
                                                    new Date(
                                                      earliestStartDate
                                                    ).getTime() &&
                                                  new Date(
                                                    rescheduleTarget
                                                  ).getTime() <=
                                                    new Date(
                                                      farthestEndDate
                                                    ).getTime()
                                                );
                                              } else {
                                                return false;
                                              }
                                            })()
                                        );

                                        // Sort valid task lists according to the earliest date
                                        taskListsValid.sort((a, b) => {
                                          const aMeetingTasks: MeetingMeetingTask[] =
                                            a.taskList?.meetingTasks ?? [];
                                          const bMeetingTasks: MeetingMeetingTask[] =
                                            b.taskList?.meetingTasks ?? [];

                                          aMeetingTasks.sort((a, b) =>
                                            a.start && b.start
                                              ? a.start.localeCompare(b.start)
                                              : 0
                                          );
                                          bMeetingTasks.sort((a, b) =>
                                            a.start && b.start
                                              ? a.start.localeCompare(b.start)
                                              : 0
                                          );

                                          if (
                                            aMeetingTasks?.[0] &&
                                            bMeetingTasks?.[0]
                                          ) {
                                            return (
                                              aMeetingTasks?.[0].start?.localeCompare(
                                                bMeetingTasks?.[0].start ?? ""
                                              ) ?? 0
                                            );
                                          } else {
                                            return 0;
                                          }
                                        });

                                        // For display only
                                        const incompleteTasks = (
                                          JSON.parse(
                                            JSON.stringify(
                                              reschedulerMeetingTaskListData
                                            )
                                          ) as MeetingTaskListsView
                                        ).taskLists
                                          .map(
                                            (tL) => tL.taskList?.meetingTasks
                                          )
                                          .flat()
                                          .filter(
                                            (mt) =>
                                              mt && mt?.status === "OUTSTANDING"
                                          )
                                          .map(
                                            (mt) => mt as MeetingMeetingTask
                                          );

                                        let rescheduleTargetDatePointer =
                                          new Date(rescheduleTarget);

                                        // Combinedtasklists
                                        const combinedTaskLists: MeetingTaskListView[] =
                                          [
                                            ...JSON.parse(
                                              JSON.stringify(
                                                incompleteTaskLists
                                              )
                                            ),
                                            ...JSON.parse(
                                              JSON.stringify(taskListsValid)
                                            ),
                                          ];
                                        setCombinedTaskListsFormer(
                                          JSON.parse(
                                            JSON.stringify(combinedTaskLists)
                                          )
                                        );

                                        if (!props.simple) {
                                          // Make all tasks blank
                                          combinedTaskLists.forEach((tL) => {
                                            tL.taskList?.meetingTasks.forEach(
                                              (mt) => {
                                                mt.start = undefined;
                                                mt.meetingTaskTargetDates = [];
                                              }
                                            );
                                          });

                                          combinedTaskLists.forEach((tL) => {
                                            const assignWoToDatePointer =
                                              () => {
                                                tL.taskList?.meetingTasks.forEach(
                                                  (mt) => {
                                                    mt.start = makeDateString(
                                                      rescheduleTargetDatePointer
                                                    );

                                                    mt.meetingTaskTargetDates =
                                                      [
                                                        ...mt.meetingTaskTargetDates,
                                                        MeetingMeetingTaskTargetDate.fromPartial(
                                                          {
                                                            date: makeDateString(
                                                              rescheduleTargetDatePointer
                                                            ),
                                                            masterJavaBaseModel:
                                                              MasterJavaBaseModel.fromPartial(
                                                                {
                                                                  uuid: v4(),
                                                                }
                                                              ),
                                                          }
                                                        ),
                                                      ];
                                                  }
                                                );
                                              };

                                            // Filter task list time. If more than 8hrs, assign and return.
                                            const woTime =
                                              getWorkOrderTimeMinutes(
                                                tL.taskList
                                                  ? {
                                                      ...tL.taskList,
                                                    }
                                                  : MeetingTaskList.fromPartial(
                                                      {}
                                                    ),
                                                rcemDepartments,
                                                integrationModules,
                                                machinePrograms,
                                                departmentTemplates,
                                                panelCodeDepartmentTemplateItemsProto,
                                                reschedulerUser?.id,
                                                null
                                              );

                                            const wos = filterWorkOrderByDate(
                                              MeetingTaskListsView.fromPartial({
                                                taskLists: combinedTaskLists,
                                              }),
                                              makeDateString(
                                                rescheduleTargetDatePointer
                                              ),
                                              reschedulerUser
                                            );
                                            console.log(
                                              makeDateString(
                                                rescheduleTargetDatePointer
                                              ),
                                              wos
                                            );

                                            const peekWos = [
                                              ...(wos ?? []),
                                              tL,
                                            ];
                                            const peekWosTime =
                                              peekWos
                                                ?.map((tL) =>
                                                  getWorkOrderTimeMinutes(
                                                    tL.taskList
                                                      ? {
                                                          ...tL.taskList,
                                                        }
                                                      : MeetingTaskList.fromPartial(
                                                          {}
                                                        ),
                                                    rcemDepartments,
                                                    integrationModules,
                                                    machinePrograms,
                                                    departmentTemplates,
                                                    panelCodeDepartmentTemplateItemsProto,
                                                    reschedulerUser?.id,
                                                    null
                                                  )
                                                )
                                                .reduce(
                                                  (acc, time) =>
                                                    acc +
                                                    (time.totalHours ?? 0),
                                                  0.0
                                                ) ?? 0.0;

                                            const curDateWosTime =
                                              wos
                                                ?.map((tL) =>
                                                  getWorkOrderTimeMinutes(
                                                    tL.taskList
                                                      ? {
                                                          ...tL.taskList,
                                                        }
                                                      : MeetingTaskList.fromPartial(
                                                          {}
                                                        ),
                                                    rcemDepartments,
                                                    integrationModules,
                                                    machinePrograms,
                                                    departmentTemplates,
                                                    panelCodeDepartmentTemplateItemsProto,
                                                    reschedulerUser?.id,
                                                    null
                                                  )
                                                )
                                                .reduce(
                                                  (acc, time) =>
                                                    acc +
                                                    (time.totalHours ?? 0),
                                                  0.0
                                                ) ?? 0.0;

                                            console.log(
                                              "[peek wos time]",
                                              peekWosTime
                                            );

                                            if (woTime.totalHours >= 8) {
                                              if (curDateWosTime !== 0) {
                                                rescheduleTargetDatePointer =
                                                  new Date(
                                                    rescheduleTargetDatePointer.getTime() +
                                                      86400000
                                                  );
                                              }

                                              assignWoToDatePointer();

                                              rescheduleTargetDatePointer =
                                                new Date(
                                                  rescheduleTargetDatePointer.getTime() +
                                                    86400000
                                                );

                                              return;
                                            }

                                            if (peekWosTime > 8) {
                                              console.log(
                                                "peek WO time is high",
                                                peekWosTime
                                              );
                                              rescheduleTargetDatePointer =
                                                new Date(
                                                  rescheduleTargetDatePointer.getTime() +
                                                    86400000
                                                );
                                            }

                                            assignWoToDatePointer();
                                          });
                                        }
                                        console.log(
                                          "[incomplete task list]",
                                          incompleteTaskLists
                                        );
                                        console.log(
                                          "[valid task lists]",
                                          taskListsValid
                                        );

                                        // set everything
                                        setTaskListsValid(taskListsValid);
                                        setIncompleteTaskLists(
                                          incompleteTaskLists
                                        );
                                        setCombinedTaskLists(combinedTaskLists);

                                        setIncompleteTasks(incompleteTasks);
                                      } catch (e) {
                                      } finally {
                                        setShowReschedulerModalLoading(false);
                                      }
                                    };

                                    return (
                                      <>
                                        {/* <div>
                                        <button
                                          className="btn btn-sm btn-outline-primary ml-1"
                                          onClick={() =>
                                            calculateReschedule({
                                              simple: false,
                                            })
                                          }
                                        >
                                          <small>Reschedule</small>
                                        </button>
                                      </div> */}
                                        <div>
                                          <button
                                            className="btn btn-sm btn-outline-primary ml-1"
                                            onClick={() =>
                                              calculateReschedule({
                                                simple: true,
                                              })
                                            }
                                          >
                                            <small>Reschedule</small>
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })()}

                                  {/* <UserInfo user={u} index={i + 1} /> */}
                                </div>
                                {(foundNotes.length ?? 0) > 0 ? (
                                  <>
                                    <div className="bg-warning">
                                      {foundNotes.map((n) => {
                                        return (
                                          <>
                                            <div>
                                              <small>
                                                <strong>{n.note}</strong>
                                              </small>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                            {[...Array(daysFromTo)].map((_, i) => {
                              const curDate = makeDateString(
                                new Date(
                                  new Date(selectedDateFrom.current).getTime() +
                                    86400000 * i
                                )
                              );

                              const foundWos = filterWorkOrderByDate(
                                workOrders,
                                curDate,
                                u
                              );

                              const totalTime =
                                // foundWos
                                //   ?.map((wo) =>
                                //     getWorkOrderTimeMinutes(
                                //       wo.taskList,
                                //       rcemDepartments,
                                //       integrationModules,
                                //       machinePrograms,
                                //       departmentTemplates,
                                //       panelCodeDepartmentTemplateItemsProto,
                                //       u.id,
                                //       curDate
                                //     )
                                //   )
                                //   .reduce(
                                //     (acc, woTime) =>
                                //       acc + (woTime.totalHours ?? 0),
                                //     0.0
                                //   ) ?? 0;
                                foundWos
                                  ?.flatMap((tL) => tL.taskList?.meetingTasks)
                                  // .filter((mt) => mt?.status === "COMPLETED")
                                  .reduce(
                                    (acc, mt) =>
                                      acc +
                                      (mt?.meetingTaskInCharges.find(
                                        (c) => `${c.extUserId}` === `${u.id}`
                                      )?.totalTimeHoursTask ?? 0),
                                    0
                                  ) ?? 0;

                              const foundNotes = attendanceNotes.current.filter(
                                (n) => {
                                  return (
                                    `${n.extUserId}` === `${u.id}` &&
                                    new Date(n.date ?? "").getTime() >=
                                      new Date(
                                        `${curDate}T00:00:00`
                                      ).getTime() &&
                                    new Date(n.date ?? "").getTime() <=
                                      new Date(`${curDate}T23:59:59`).getTime()
                                  );
                                }
                              );

                              const totalActualTime =
                                // foundWos
                                //   ?.map((wo) =>
                                //     getWorkOrderTimeMinutes(
                                //       wo.taskList,
                                //       rcemDepartments,
                                //       integrationModules,
                                //       machinePrograms,
                                //       departmentTemplates,
                                //       panelCodeDepartmentTemplateItemsProto,
                                //       u.id,
                                //       curDate
                                //     )
                                //   )
                                //   .reduce(
                                //     (acc, woTime) =>
                                //       acc + (woTime.totalActualHours ?? 0),
                                //     0.0
                                //   ) ?? 0;
                                foundWos
                                  ?.flatMap((tL) => tL.taskList?.meetingTasks)
                                  .filter((mt) => mt?.status === "COMPLETED")
                                  .reduce(
                                    (acc, mt) =>
                                      acc +
                                      (mt?.meetingTaskInCharges.find(
                                        (c) => `${c.extUserId}` === `${u.id}`
                                      )?.totalTimeHoursTask ?? 0),
                                    0
                                  ) ?? 0;

                              const color = (() => {
                                if (totalTime >= 7) {
                                  return "lightgreen";
                                } else if (totalTime >= 5) {
                                  return "gold";
                                } else {
                                  return "lightsalmon";
                                }
                              })();

                              const colorActual = (() => {
                                if (totalActualTime >= totalTime) {
                                  return "lightgreen";
                                } else if (totalActualTime <= 0) {
                                  return "lightsalmon";
                                } else {
                                  return "gold";
                                }
                              })();

                              return (
                                <td
                                  className="border border-dark"
                                  style={{
                                    backgroundColor:
                                      (foundNotes.length ?? 0) > 0
                                        ? `red`
                                        : (foundWos?.length ?? 0) > 0
                                        ? color
                                        : "gray",
                                  }}
                                >
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSelectedWorkOrderModalUser(u);
                                      setSelectedWorkOrderModalDate(curDate);
                                      setWorkOrderModalOpen(true);
                                      setSelectedWorkOrders(foundWos ?? []);
                                      setModalContent(
                                        <>
                                          <div>
                                            <ol></ol>
                                          </div>
                                        </>
                                      );
                                    }}
                                  >
                                    {/* <div>
                                      <pre>
                                        {JSON.stringify(
                                          foundWos?.map((wo) =>
                                            getWorkOrderTimeMinutes(
                                              wo.taskList,
                                              rcemDepartments,
                                              integrationModules,
                                              machinePrograms,
                                              departmentTemplates,
                                              u.id,
                                              curDate
                                            )
                                          ),
                                          null,
                                          2
                                        )}
                                      </pre>
                                    </div> */}
                                    <div>
                                      <div>
                                        {(foundWos?.length ?? 0) > 0 ? (
                                          <>
                                            <div>
                                              <div className="d-flex">
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      colorActual,
                                                  }}
                                                  className="d-flex justify-content-center flex-grow-1 border-right border-dark"
                                                >
                                                  <small>
                                                    <strong>
                                                      {totalActualTime.toFixed(
                                                        1
                                                      )}
                                                    </strong>
                                                  </small>
                                                </div>
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      foundWos?.find((tL) =>
                                                        tL.taskList?.meetingTasks.find(
                                                          (mt) =>
                                                            mt.extPicCompletedDate
                                                        )
                                                      )
                                                        ? `aquamarine`
                                                        : color,
                                                  }}
                                                  className="d-flex justify-content-center flex-grow-1 border-left border-dark"
                                                >
                                                  <small>
                                                    <strong>
                                                      {totalTime.toFixed(1)}
                                                    </strong>
                                                  </small>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        {(foundNotes.length ?? 0) > 0 ? (
                                          <>
                                            <div className="bg-warning">
                                              {foundNotes.map((n) => {
                                                return (
                                                  <>
                                                    <OverlayTrigger
                                                      // trigger="hover"
                                                      placement="left"
                                                      overlay={
                                                        <Tooltip id="tooltip">
                                                          {n.note}
                                                        </Tooltip>
                                                      }
                                                    >
                                                      <div>
                                                        <small>
                                                          <strong>
                                                            {n.note?.substring(
                                                              0,
                                                              10
                                                            )}
                                                            {(n.note?.length ??
                                                              0) > 10
                                                              ? `...`
                                                              : ""}
                                                          </strong>
                                                        </small>
                                                      </div>
                                                    </OverlayTrigger>
                                                  </>
                                                );
                                              })}
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </div>
              </>
            );
          })()
        ) : (
          <></>
        )}

        {view === "Machine" ? (
          <>
            <div className="d-flex">
              <div>Work Orders</div>
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() - 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Prev
                </button>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() + 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className="overflow-auto"
              style={{ height: "75vh", resize: "vertical" }}
            >
              <table
                className="table table-sm table-bordered table-hover"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["Machine"].map((h) => (
                    <th
                      className="border border-dark bg-dark text-light sticky-top top-0"
                      style={{
                        left: h === "Machine" ? 0 : "undefined",
                        zIndex: h === "Machine" ? 10 : undefined,
                      }}
                    >
                      <div style={{ maxWidth: 400 }}>{h}</div>
                    </th>
                  ))}
                  {[...Array(31)].map((_, i) => {
                    return (
                      <th
                        style={{ zIndex: 1 }}
                        className="border border-dark bg-dark text-light sticky-top top-0"
                      >
                        <small>
                          <strong>
                            {makeReadableDateStringShortIntl(selectedDate)}
                          </strong>
                        </small>
                      </th>
                    );
                  })}
                </tr>
                {machines.map((m, i) => {
                  return (
                    <>
                      <tr>
                        <td
                          className="border border-dark"
                          style={{ position: "sticky", left: 0, zIndex: 1 }}
                        >
                          <div className="d-flex">
                            <MachineInfo machine={m} index={i + 1} />
                          </div>
                        </td>
                        {[...Array(31)].map((_, i) => {
                          return (
                            <td
                              className={`border border-dark ${(() => {
                                if (i % 2 === 0) {
                                  return "bg-success";
                                } else if (i % 3 === 0) {
                                  return "bg-dark";
                                } else if (i % 7 === 0) {
                                  return "bg-danger";
                                } else if (i % 5 === 0) {
                                  return "bg-warning";
                                } else {
                                  return "";
                                }
                              })()}`}
                            >
                              <small>
                                <strong>
                                  {((i / 366) * 100).toFixed(1)} h (
                                  {((i / 366) * 100).toFixed(1)} %)
                                </strong>
                              </small>
                            </td>
                          );
                        })}
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Work Order" ? (
          <>
            <div>
              <div className="d-flex">
                <div>Work Orders</div>
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setSelectedDate(
                        new Date(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth() - 1,
                          selectedDate.getDate()
                        )
                      );
                    }}
                  >
                    Prev
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      setSelectedDate(
                        new Date(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth() + 1,
                          selectedDate.getDate()
                        )
                      );
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
              <div></div>
              <div
                className="border border-dark overflow-auto"
                style={{ height: "65vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "#",
                      "Task",
                      ...[...Array(30)]
                        .map((_, i) => selectedDate.getTime() + i * 86400000)
                        .map((d) =>
                          Intl.DateTimeFormat("id-ID", {
                            dateStyle: "short",
                          } as any).format(new Date(d))
                        ),
                    ].map((h) => (
                      <th
                        className="border border-dark bg-dark text-light"
                        style={{ position: "sticky", top: 0, zIndex: 1 }}
                      >
                        <small>
                          <strong>{h}</strong>
                        </small>
                      </th>
                    ))}
                  </tr>
                  {workOrders?.taskLists
                    ?.map((tL, i) => {
                      return (
                        tL?.taskList?.meetingTasks?.map((mt) => ({
                          taskList: tL.taskList,
                          meetingTask: mt,
                        })) ?? []
                      );
                    })
                    .flat()
                    ?.map((mt, j) => {
                      return (
                        <>
                          <tr>
                            <td className="border border-dark">{j + 1}</td>
                            <td
                              className="border border-dark bg-light"
                              style={{ position: "sticky", left: 0 }}
                            >
                              <div
                                style={{
                                  width: 300,
                                }}
                              >
                                <div>
                                  <small>
                                    <strong>
                                      {mt.taskList?.name}:{" "}
                                      {mt.meetingTask?.description}
                                      {mt.meetingTask.extSubProcessId &&
                                      mt.meetingTask.extSubProcessId !== `0` ? (
                                        <>
                                          {(() => {
                                            const foundDti =
                                              departmentTemplates?.templates
                                                .map((dt) => dt.items ?? [])
                                                .flat()
                                                .find(
                                                  (dti) =>
                                                    `${dti.masterJavaBaseModel?.id}` ===
                                                    `${mt.meetingTask.extSubProcessId}`
                                                );

                                            const foundDt =
                                              departmentTemplates?.templates.find(
                                                (dt) =>
                                                  dt.items.find(
                                                    (dti) =>
                                                      `${dti.masterJavaBaseModel?.id}` ===
                                                      `${mt.meetingTask.extSubProcessId}`
                                                  )
                                              );

                                            return (
                                              <>
                                                <small>
                                                  ({foundDt?.name}:{" "}
                                                  {foundDti?.name})
                                                </small>
                                              </>
                                            );
                                          })()}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </strong>
                                  </small>
                                </div>
                                <div>
                                  <small>
                                    {[
                                      ...new Set(
                                        mt?.meetingTask?.meetingTaskInCharges?.map(
                                          (mtic) =>
                                            ctx?.extUsers?.find(
                                              (u) =>
                                                `${u.id}` ===
                                                `${mtic.extUserId}`
                                            )?.name ?? ""
                                        )
                                      ),
                                    ].join(", ")}
                                  </small>
                                </div>
                                {mt.taskList?.extJobId &&
                                mt.taskList?.extJobId !== `0` ? (
                                  <>
                                    {" "}
                                    <div>
                                      <small>
                                        <small>
                                          {
                                            jobs.find(
                                              (j) =>
                                                `${j.id}` ===
                                                `${mt.taskList?.extJobId}`
                                            )?.name
                                          }
                                        </small>
                                      </small>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {mt.taskList?.extPanelCodeId &&
                                mt.taskList?.extPanelCodeId !== `0` ? (
                                  <>
                                    {" "}
                                    <div>
                                      <small>
                                        <small>
                                          {(() => {
                                            const foundPcode =
                                              mappedPanelCodes.find(
                                                (pc) =>
                                                  `${pc?.panelCode.id}` ===
                                                  `${mt.taskList?.extPanelCodeId}`
                                              );

                                            return foundPcode ? (
                                              <>
                                                {foundPcode.panelCode.type}:{" "}
                                                {foundPcode.panelCode.name}
                                              </>
                                            ) : (
                                              <></>
                                            );
                                          })()}
                                        </small>
                                      </small>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <div>
                                  <small>
                                    <strong>
                                      {getWorkOrderTimeMinutes(
                                        mt.taskList,
                                        rcemDepartments,
                                        integrationModules,
                                        machinePrograms,
                                        departmentTemplates,
                                        panelCodeDepartmentTemplateItemsProto,
                                        null,
                                        null
                                      ).totalHours.toFixed(2)}{" "}
                                      hrs total
                                    </strong>
                                  </small>
                                </div>
                              </div>
                            </td>
                            {[...Array(31)]
                              .map(
                                (_, i) => selectedDate.getTime() + i * 86400000
                              )
                              .map((d) => {
                                const date = makeDateString(new Date(d));

                                const start = mt.meetingTask.start;
                                const end = extractProtoMeetingTaskTargetDate(
                                  mt.meetingTask
                                )?.date;

                                return (
                                  <>
                                    <td
                                      className="border border-dark"
                                      style={{
                                        backgroundColor:
                                          mt.meetingTask.status ===
                                            "COMPLETED" &&
                                          mt.meetingTask.completedDate === date
                                            ? "deepskyblue"
                                            : date && start && end
                                            ? (() => {
                                                try {
                                                  return date === start ||
                                                    (new Date(date).getTime() >=
                                                      new Date(
                                                        start
                                                      ).getTime() &&
                                                      new Date(
                                                        date
                                                      ).getTime() <=
                                                        new Date(
                                                          end ?? ""
                                                        ).getTime())
                                                    ? "lightgreen"
                                                    : "gray";
                                                } catch (e) {
                                                  return "gray";
                                                }
                                              })()
                                            : "gray",
                                      }}
                                    >
                                      {/* {date}  */}
                                    </td>
                                    {/* {Intl.DateTimeFormat("id-ID", {
                                      dateStyle: "short",
                                    } as any).format(new Date(d))} */}
                                  </>
                                );
                              })}
                          </tr>
                        </>
                      );
                    })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Comments" ? (
          <>
            <div>
              <h4>Comments Summary</h4>
            </div>
            <div className="d-flex">
              <div>Date ({`${selectedDate}`})</div>
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() - 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Prev
                </button>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setSelectedDate(
                      new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth() + 1,
                        selectedDate.getDate()
                      )
                    );
                  }}
                >
                  Next
                </button>
              </div>
            </div>
            <div
              className="overflow-auto border border-dark"
              style={{ height: "65vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["#", "By", "Comment", "Timestamp", "Information"].map(
                    (h) => (
                      <th
                        className="bg-dark font-weight-bold text-light"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    )
                  )}
                </tr>
                {(() => {
                  const list = workOrders?.taskLists
                    .map((tL) => {
                      return tL.taskList?.meetingTasks
                        .map((mt) => {
                          return mt.meetingTaskComments.map((c) => {
                            return {
                              taskList: tL,
                              meetingTask: mt,
                              comment: c,
                            };
                          });
                        })
                        .flat();
                    })
                    .flat();

                  list?.sort(
                    (a, b) =>
                      b?.comment.masterJavaBaseModel?.createdAt?.localeCompare(
                        a?.comment.masterJavaBaseModel?.createdAt ?? ""
                      ) ?? 0
                  );

                  return list;
                })()?.map((c, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          {c?.comment.extUserId &&
                          c?.comment.extUserId !== "0" ? (
                            (() => {
                              const foundUser = ctx?.extUsers.find(
                                (u) => `${u.id}` === `${c.comment.extUserId}`
                              );

                              return (
                                <>
                                  {foundUser?.name}: (
                                  {foundUser?.departmentName})
                                </>
                              );
                            })()
                          ) : (
                            <>Admin (Admin)</>
                          )}
                        </td>

                        <td
                          className="border border-dark"
                          style={{ width: "70vw" }}
                        >
                          <pre>{c?.comment.comment}</pre>
                        </td>
                        <td className="border border-dark">
                          {(() => {
                            try {
                              return Intl.DateTimeFormat(
                                navigator.language ?? "en-US",
                                {
                                  timeStyle: "medium",
                                  dateStyle: "short",
                                  hourCycle: "h23",
                                } as any
                              ).format(
                                new Date(
                                  c?.comment.masterJavaBaseModel?.createdAt ??
                                    ""
                                )
                              );
                            } catch (e) {
                              return "";
                            }
                          })()}
                        </td>

                        <td className="border border-dark">
                          <TaskListInfo
                            customers={ctx?.extCustomers}
                            crmCustomers={ctx?.extCrmCustomers}
                            tL={c?.taskList}
                            extUsers={ctx?.extUsers ?? []}
                            jobsProto={jobsProto ?? PpicJobs.fromPartial({})}
                            machines={machines}
                            processTypes={
                              processTypes ??
                              PpicSimplifiedProcessTypes.fromPartial({})
                            }
                            rcemDepartments={rcemDepartments}
                            integrationModules={integrationModules}
                            machinePrograms={machinePrograms}
                            departmentTemplates={departmentTemplates}
                            panelCodeDepartmentTemplateItems={
                              panelCodeDepartmentTemplateItemsProto
                            }
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {view === "Dept Summ" ? (
          <>
            <div className="m-3">
              <div>
                {deptSummTypeArr.map((d) => {
                  return (
                    <>
                      <button
                        className={`btn btn-sm ${
                          selectedDeptSummType === d
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                        onClick={() => {
                          setSelectedDeptSummType(d);
                        }}
                      >
                        {d}
                      </button>
                    </>
                  );
                })}
              </div>
              <div
                className="overflow-auto shadow shadow-md border border-dark"
                style={{ height: "75vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "#",
                      "Dept",
                      ...[...Array(4)].map((_, i) => {
                        const curDateFrom = new Date(
                          selectedDate.getTime() + 86400000 * (8 * i)
                        );
                        const curDateTo = new Date(
                          selectedDate.getTime() + 86400000 * (8 * i + 7)
                        );

                        const weekStr = `${makeDateString(
                          curDateFrom
                        )}-${makeDateString(curDateTo)}`;

                        return `Week ${i + 1} (${weekStr})`;
                      }),
                    ].map((h) => {
                      return (
                        <>
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                  {ctx?.extDepartments
                    .filter((d) => `${d.id}` === `${filters.extDepartmentId}`)
                    .map((d, i) => {
                      return (
                        <tr>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">{d.name}</td>

                          {[...Array(4)].map((_, i) => {
                            const curDateFrom = new Date(
                              selectedDate.getTime() + 86400000 * (8 * i)
                            );
                            const curDateTo = new Date(
                              selectedDate.getTime() + 86400000 * (8 * i + 7)
                            );

                            const depUsers = ctx.extUsers
                              .filter((u) => `${u.departmentId}` === `${d.id}`)
                              .map((u) => {
                                const filteredWos = filterWorkOrderByDateRange(
                                  workOrders,
                                  makeDateString(curDateFrom),
                                  makeDateString(curDateTo),
                                  u
                                );
                                console.log(
                                  `user wo ${u.username}: ${filteredWos?.length}`
                                );

                                return {
                                  user: u,
                                  // time: 0,
                                  time:
                                    (filteredWos?.reduce(
                                      (acc, tL) =>
                                        acc +
                                        getWorkOrderTimeMinutes(
                                          tL.taskList,
                                          rcemDepartments,
                                          integrationModules,
                                          machinePrograms,
                                          departmentTemplates,
                                          panelCodeDepartmentTemplateItemsProto,
                                          // PpicPanelCodeDepartmentTemplateItems.fromPartial(
                                          //   { departmentTemplateItems: [] }
                                          // ),
                                          u?.id,
                                          // makeDateString(curDate)
                                          null
                                        ).totalHours,
                                      0.0
                                    ) ?? 0) / (filteredWos?.length ?? 1),
                                };
                              });

                            depUsers.sort(
                              (a, b) => (b.time ?? 0) - (a.time ?? 0)
                            );

                            const maxUser = depUsers?.[0];
                            const minUser = depUsers?.slice(-1)?.[0];

                            return (
                              <>
                                <td className="border border-dark">
                                  <div>
                                    {/* <small>
                                    {makeDateString(curDateFrom)}-
                                    {makeDateString(curDateTo)}
                                  </small> */}
                                    <div
                                      style={{
                                        backgroundColor: chroma
                                          .scale([
                                            "lightsalmon",
                                            "yellow",
                                            "lightgreen",
                                            "lightblue",
                                          ])((maxUser?.time ?? 0) / 8)
                                          .hex(),
                                      }}
                                    >
                                      <strong>
                                        Max: {maxUser?.user.username} (
                                        {maxUser?.time.toFixed(1)}) h
                                      </strong>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: chroma
                                          .scale([
                                            "lightsalmon",
                                            "yellow",
                                            "lightgreen",
                                            "lightblue",
                                          ])((minUser?.time ?? 0) / 8)
                                          .hex(),
                                      }}
                                    >
                                      <strong>
                                        Min: {minUser?.user.username} (
                                        {minUser?.time.toFixed(1)}) h
                                      </strong>{" "}
                                    </div>
                                  </div>
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <Modal
          size="xl"
          show={openPanelCodeRelatedWorkOrdersModal}
          onHide={() => {
            setOpenPanelCodeRelatedWorkOrdersModal(false);
          }}
        >
          <ModalHeader>
            Work Order Comments for Panel:{" "}
            {(() => {
              const fPanel = jobs
                .map(
                  (j) =>
                    j.panelCodes?.map((pcx) => ({ job: j, panelCode: pcx })) ??
                    []
                )
                .flat()
                .find(
                  (pcx) =>
                    `${pcx.panelCode.id}` ===
                    `${selectedRelatedWorkOrderExtPanelCodeId}`
                );

              return `${fPanel?.panelCode?.type ?? ""} - ${
                fPanel?.panelCode?.name ?? ""
              } from job: ${fPanel?.job.name ?? ""}`;
            })()}
          </ModalHeader>
          <ModalBody>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setPanelCodeRelatedComments({
                    ...panelCodeRelatedComments,
                    comments: [
                      ...panelCodeRelatedComments.comments,
                      MeetingMeetingTaskComment.fromPartial({
                        extPanelCodeId:
                          selectedRelatedWorkOrderExtPanelCodeId ?? undefined,
                        extSubProcessId:
                          selectedRelatedWorkOrderDepartmentTemplateItemId ??
                          undefined,
                        masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                          uuid: v4(),
                        }),
                      }),
                    ],
                  });
                }}
              >
                <Add />
                Add{" "}
              </button>
            </div>
            <div>
              <hr className="border border-dark" />
            </div>
            <div>
              <ol>
                {panelCodeRelatedComments.comments.map((c) => {
                  return (
                    <>
                      <li>
                        <div>
                          <div>
                            <div className="d-flex">
                              <div>
                                <small>
                                  {(() => {
                                    const fUser = ctx?.extUsers.find(
                                      (u) => `${u.id}` === `${c.extUserId}`
                                    );
                                    return `${fUser?.name ?? "Admin"} (${
                                      fUser?.departmentName ?? "Admin"
                                    }) on ${(() => {
                                      try {
                                        return Intl.DateTimeFormat("en-US", {
                                          timeStyle: "short",
                                          dateStyle: "short",
                                          hourCycle: "h23",
                                        } as any).format(
                                          new Date(
                                            c.masterJavaBaseModel?.createdAt ??
                                              ""
                                          )
                                        );
                                      } catch (e) {
                                        return "[undefined time]";
                                      }
                                    })()}`;
                                  })()}
                                </small>
                              </div>
                              <div>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    setPanelCodeRelatedCommentDeleteIds([
                                      ...panelCodeRelatedCommentDeleteIds,
                                      c.masterJavaBaseModel?.id ?? "",
                                    ]);
                                    setPanelCodeRelatedComments({
                                      ...panelCodeRelatedComments,
                                      comments:
                                        panelCodeRelatedComments.comments.filter(
                                          (cx) =>
                                            cx.masterJavaBaseModel?.uuid !==
                                            c.masterJavaBaseModel?.uuid
                                        ),
                                    });
                                  }}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                          <div>
                            <textarea
                              className="form-control form-control-sm"
                              placeholder="Comment..."
                              key={`mtc-${c.masterJavaBaseModel?.uuid}`}
                              defaultValue={c.comment}
                              onBlur={(e) => {
                                setPanelCodeRelatedComments({
                                  ...panelCodeRelatedComments,
                                  comments:
                                    panelCodeRelatedComments.comments.map(
                                      (cx) =>
                                        cx &&
                                        cx.masterJavaBaseModel?.uuid ===
                                          c.masterJavaBaseModel?.uuid
                                          ? { ...cx, comment: e.target.value }
                                          : cx
                                    ),
                                });
                              }}
                            />
                          </div>
                          <hr className="border border-dark" />

                          {/* <div>
                            DEBUG: PanelCode {c.extPanelCodeId}, SubProcess{" "}
                            {c.extSubProcessId}
                          </div> */}
                        </div>
                      </li>
                    </>
                  );
                })}
              </ol>
            </div>
          </ModalBody>
          <ModalFooter>
            {panelCodeRelatedCommentsLoading ? (
              <>
                <Spinner animation="border" />{" "}
              </>
            ) : (
              <>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={async () => {
                    try {
                      setPanelCodeRelatedCommentsLoading(true);

                      await Promise.all([
                        (async () => {
                          try {
                            const resp = await fetch(
                              `${process.env.REACT_APP_MEETING_URL}/meetingtaskcomments-save-bulk`,
                              {
                                method: "POST",
                                headers: {
                                  authorization: ctx?.apiKey ?? "",
                                  "content-type": "application/json",
                                },
                                body: JSON.stringify(panelCodeRelatedComments),
                              }
                            );
                          } catch (e) {
                            return null;
                          }
                        })(),
                      ]);

                      // then delete all
                      await Promise.all(
                        panelCodeRelatedCommentDeleteIds.map(async (id) => {
                          try {
                            const resp = await fetch(
                              `${process.env.REACT_APP_MEETING_URL}/meetingtaskcomments/${id}/empty`,
                              {
                                method: "delete",
                                headers: {
                                  authorization: ctx?.apiKey ?? "",
                                },
                              }
                            );
                          } catch (e) {
                            return null;
                          }
                        })
                      );

                      setOpenPanelCodeRelatedWorkOrdersModal(false);
                      // handleInit();
                    } catch (e) {
                      console.error(e);
                    } finally {
                      setPanelCodeRelatedCommentsLoading(false);
                    }
                  }}
                >
                  Save Comments
                </button>
              </>
            )}
          </ModalFooter>
        </Modal>

        <Modal
          size="xl"
          show={workOrderModalOpen}
          onHide={() => {
            setWorkOrderModalOpen(false);
          }}
        >
          <ModalHeader>
            {selectedWorkOrderModalUser?.name} - {selectedWorkOrderModalDate}
          </ModalHeader>
          <ModalBody>
            <div>
              <strong>
                Total time from {selectedWorkOrders.length} WO(s):{" "}
                {selectedWorkOrders
                  .reduce(
                    (acc, tL) =>
                      acc +
                      getWorkOrderTimeMinutes(
                        tL.taskList,
                        rcemDepartments,
                        integrationModules,
                        machinePrograms,
                        departmentTemplates,
                        panelCodeDepartmentTemplateItemsProto,
                        selectedWorkOrderModalUser?.id,
                        selectedWorkOrderModalDate,
                        true
                      ).totalHours,
                    0.0
                  )
                  .toFixed(1)}{" "}
                h (
                {selectedWorkOrders
                  .map((tL) =>
                    getWorkOrderTimeMinutes(
                      tL.taskList,
                      rcemDepartments,
                      integrationModules,
                      machinePrograms,
                      departmentTemplates,
                      panelCodeDepartmentTemplateItemsProto,
                      selectedWorkOrderModalUser?.id,
                      selectedWorkOrderModalDate,
                      true
                    ).totalHours.toFixed(1)
                  )
                  .join(" + ")}
                )
              </strong>
            </div>
            <hr />
            <div>
              <ol>
                {selectedWorkOrders.map((tL) => (
                  <>
                    <li>
                      <div>
                        <TaskListInfo
                          customers={ctx?.extCustomers}
                          crmCustomers={ctx?.extCrmCustomers}
                          tL={tL}
                          extUsers={ctx?.extUsers ?? []}
                          jobsProto={jobsProto ?? PpicJobs.fromPartial({})}
                          machines={machines}
                          processTypes={
                            processTypes ??
                            PpicSimplifiedProcessTypes.fromPartial({})
                          }
                          rcemDepartments={rcemDepartments}
                          integrationModules={integrationModules}
                          machinePrograms={machinePrograms}
                          departmentTemplates={departmentTemplates}
                          exUserId={selectedWorkOrderModalUser?.id}
                          date={selectedWorkOrderModalDate}
                          panelCodeDepartmentTemplateItems={
                            panelCodeDepartmentTemplateItemsProto
                          }
                        />
                      </div>
                    </li>
                  </>
                ))}
              </ol>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="xl"
          show={showReschedulerModal}
          onHide={() => {
            setShowReschedulerModal(false);
          }}
        >
          {(() => {
            const dates = [...Array(31)].map((_, i) =>
              makeDateString(
                new Date(
                  new Date(
                    new Date(rescheduleTarget).getFullYear(),
                    new Date(rescheduleTarget).getMonth(),
                    new Date(rescheduleTarget).getDate()
                  ).getTime() +
                    86400000 * (i - 1)
                )
              )
            );

            const incompleteTaskListsTime = incompleteTaskLists
              .map((tL) =>
                getWorkOrderTimeMinutes(
                  tL.taskList,
                  rcemDepartments,
                  integrationModules,
                  machinePrograms,
                  departmentTemplates,
                  panelCodeDepartmentTemplateItemsProto,
                  reschedulerUser?.id,
                  null
                )
              )
              .reduce((acc, time) => acc + (time.totalHours ?? 0), 0.0);

            return (
              <>
                <ModalHeader>
                  <h4>Rescheduler</h4>
                </ModalHeader>
                <ModalBody>
                  <div>
                    <strong>Incomplete tasks:</strong>
                  </div>
                  {showReschedulerModalLoading ? (
                    <>
                      <Spinner animation="border" />
                    </>
                  ) : (
                    <>
                      {(() => {
                        return (
                          <div>
                            <div>
                              Task lists with incomplete tasks before date, no
                              SPV: {incompleteTaskLists.length}
                            </div>

                            <div>
                              Incomplete tasks: {incompleteTasks.length}
                            </div>
                            <div>
                              Incomplete tasks time:{" "}
                              {incompleteTaskListsTime.toFixed(2)} hrs
                            </div>

                            <hr />

                            {/* <div>Before:</div>

                            <div className="overflow-auto">
                              <table
                                className="table table-sm"
                                style={{ borderCollapse: "separate" }}
                              >
                                <tr>
                                  {dates.map((d) => (
                                    <>
                                      <th
                                        className={`${
                                          makeDateString(new Date()) === d
                                            ? `bg-info`
                                            : `bg-dark`
                                        } text-light`}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {d}
                                      </th>
                                    </>
                                  ))}
                                </tr>
                                <tr>
                                  {dates.map((d) => {
                                    const wos = filterWorkOrderByDate(
                                      MeetingTaskListsView.fromPartial({
                                        taskLists: [
                                          ...incompleteTaskLists,
                                          ...taskListsValid,
                                        ],
                                      }),
                                      d,
                                      reschedulerUser
                                    );
                                    console.log(`[${d}]`, taskListsValid, wos);
                                    const wosTime =
                                      wos
                                        ?.map((tL) =>
                                          getWorkOrderTimeMinutes(
                                            tL.taskList
                                              ? {
                                                  ...tL.taskList,
                                                }
                                              : MeetingTaskList.fromPartial({}),
                                            rcemDepartments,
                                            integrationModules,
                                            machinePrograms,
                                            departmentTemplates,
                                            reschedulerUser?.id,
                                            d
                                          )
                                        )
                                        .reduce(
                                          (acc, time) =>
                                            acc + (time.totalHours ?? 0),
                                          0.0
                                        ) ?? 0.0;

                                    return (
                                      <>
                                        <td className="border border-dark">
                                          <div>
                                            <small>
                                              <strong>
                                                {wos?.length} (
                                                {wosTime.toFixed(1)} hrs)
                                              </strong>
                                            </small>
                                          </div>
                                          <div>
                                            <small>
                                              <small>
                                                <ol>
                                                  {wos?.map((tL) => {
                                                    return (
                                                      <>
                                                        <li>
                                                          <strong>
                                                            {
                                                              tL.taskList
                                                                ?.masterJavaBaseModel
                                                                ?.id
                                                            }
                                                          </strong>
                                                        </li>
                                                      </>
                                                    );
                                                  })}
                                                </ol>
                                              </small>
                                            </small>
                                          </div>
                                        </td>
                                      </>
                                    );
                                  })}
                                </tr>
                              </table>
                            </div>

                            <div>After:</div> */}

                            {(() => {
                              return (
                                <div className="overflow-auto">
                                  <table
                                    className="table table-sm"
                                    style={{ borderCollapse: "separate" }}
                                  >
                                    <tr>
                                      {dates.map((d) => (
                                        <>
                                          <th
                                            className={`${
                                              makeDateString(new Date()) === d
                                                ? `bg-info`
                                                : new Date(d).getDay() === 0 ||
                                                  new Date(d).getDay() === 6
                                                ? `bg-danger`
                                                : `bg-dark`
                                            } text-light`}
                                            style={{ whiteSpace: "nowrap" }}
                                          >
                                            <div>
                                              <div>{d}</div>
                                              <div>
                                                {moveInDate ? (
                                                  <>
                                                    <button
                                                      onClick={() => {
                                                        console.log(
                                                          "Moving",
                                                          moveInDate,
                                                          "to",
                                                          d
                                                        );
                                                        setMoveInDate(null);

                                                        const newCombinedTaskLists =
                                                          [
                                                            // Less than date
                                                            ...(filterWorkOrderByDate(
                                                              MeetingTaskListsView.fromPartial(
                                                                {
                                                                  taskLists: [
                                                                    ...combinedTaskLists,
                                                                  ],
                                                                }
                                                              ),
                                                              moveInDate,
                                                              reschedulerUser,
                                                              true
                                                            ) ?? []),
                                                          ];

                                                        let datePointer = d;

                                                        const includedWos = [
                                                          ...combinedTaskLists.filter(
                                                            (tL) =>
                                                              !newCombinedTaskLists.find(
                                                                (tLx) =>
                                                                  `${tLx.taskList?.masterJavaBaseModel?.id}` ===
                                                                  `${tL.taskList?.masterJavaBaseModel?.id}`
                                                              )
                                                            // &&
                                                            // // Exclude if date pointer is less than start
                                                            // (() => {
                                                            //   const mtSorted =
                                                            //     tL.taskList?.meetingTasks.sort(
                                                            //       (a, b) =>
                                                            //         (
                                                            //           a.start ??
                                                            //           ""
                                                            //         )?.localeCompare(
                                                            //           b.start ??
                                                            //             ""
                                                            //         )
                                                            //     );

                                                            //   return (
                                                            //     mtSorted?.[0]
                                                            //       ?.start ??
                                                            //     ""
                                                            //   );
                                                            // })()
                                                          ),
                                                        ];

                                                        includedWos.forEach(
                                                          (tL) => {
                                                            console.log(
                                                              `Filtering for ${d}, ${newCombinedTaskLists.length} WOs`
                                                            );
                                                            const wos =
                                                              filterWorkOrderByDate(
                                                                MeetingTaskListsView.fromPartial(
                                                                  {
                                                                    taskLists: [
                                                                      ...newCombinedTaskLists,
                                                                      {
                                                                        ...tL,
                                                                        taskList:
                                                                          tL.taskList
                                                                            ? {
                                                                                ...tL.taskList,
                                                                                meetingTasks:
                                                                                  tL.taskList.meetingTasks.map(
                                                                                    (
                                                                                      mt
                                                                                    ) => ({
                                                                                      ...mt,
                                                                                      start:
                                                                                        datePointer,
                                                                                      meetingTaskTargetDates:
                                                                                        [
                                                                                          ...mt.meetingTaskTargetDates,
                                                                                          MeetingMeetingTaskTargetDate.fromPartial(
                                                                                            {
                                                                                              masterJavaBaseModel:
                                                                                                MasterJavaBaseModel.fromPartial(
                                                                                                  {
                                                                                                    uuid: v4(),
                                                                                                  }
                                                                                                ),
                                                                                              date: datePointer,
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                    })
                                                                                  ),
                                                                              }
                                                                            : tL.taskList,
                                                                      },
                                                                    ],
                                                                  }
                                                                ),
                                                                datePointer,
                                                                reschedulerUser
                                                              ) ?? [];
                                                            const wosTime =
                                                              wos
                                                                ?.map((tL) =>
                                                                  getWorkOrderTimeMinutes(
                                                                    tL.taskList
                                                                      ? {
                                                                          ...tL.taskList,
                                                                        }
                                                                      : MeetingTaskList.fromPartial(
                                                                          {}
                                                                        ),
                                                                    rcemDepartments,
                                                                    integrationModules,
                                                                    machinePrograms,
                                                                    departmentTemplates,
                                                                    panelCodeDepartmentTemplateItemsProto,
                                                                    reschedulerUser?.id,
                                                                    datePointer
                                                                  )
                                                                )
                                                                .reduce(
                                                                  (acc, time) =>
                                                                    acc +
                                                                    (time.totalHours ??
                                                                      0),
                                                                  0.0
                                                                ) ?? 0.0;

                                                            console.log(
                                                              `WO time ${tL.taskList?.masterJavaBaseModel?.id}: ${wosTime}`
                                                            );
                                                            if (wosTime > 8) {
                                                              console.log(
                                                                "WO time overload detect for",
                                                                tL.taskList
                                                                  ?.masterJavaBaseModel
                                                                  ?.id,
                                                                datePointer,
                                                                wosTime,
                                                                ", moving to",
                                                                makeDateString(
                                                                  new Date(
                                                                    new Date(
                                                                      datePointer
                                                                    ).getTime() +
                                                                      86400000
                                                                  )
                                                                )
                                                              );
                                                              datePointer =
                                                                makeDateString(
                                                                  new Date(
                                                                    new Date(
                                                                      datePointer
                                                                    ).getTime() +
                                                                      86400000
                                                                  )
                                                                );
                                                            }

                                                            newCombinedTaskLists.push(
                                                              {
                                                                ...tL,
                                                                taskList:
                                                                  tL.taskList
                                                                    ? {
                                                                        ...tL.taskList,
                                                                        meetingTasks:
                                                                          tL.taskList.meetingTasks.map(
                                                                            (
                                                                              mt
                                                                            ) => ({
                                                                              ...mt,
                                                                              start:
                                                                                datePointer,
                                                                              meetingTaskTargetDates:
                                                                                [
                                                                                  ...mt.meetingTaskTargetDates,
                                                                                  MeetingMeetingTaskTargetDate.fromPartial(
                                                                                    {
                                                                                      masterJavaBaseModel:
                                                                                        MasterJavaBaseModel.fromPartial(
                                                                                          {
                                                                                            uuid: v4(),
                                                                                          }
                                                                                        ),
                                                                                      date: datePointer,
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                            })
                                                                          ),
                                                                      }
                                                                    : tL.taskList,
                                                              }
                                                            );
                                                          }
                                                        );

                                                        console.log(
                                                          "new combined tasklists:",
                                                          newCombinedTaskLists
                                                        );

                                                        setCombinedTaskLists(
                                                          newCombinedTaskLists
                                                        );
                                                      }}
                                                      className="btn btn-sm btn-outline-success"
                                                    >
                                                      Move here
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <button
                                                      onClick={() => {
                                                        setMoveInDate(d);
                                                      }}
                                                      className="btn btn-sm btn-outline-primary"
                                                    >
                                                      Move in date
                                                    </button>{" "}
                                                  </>
                                                )}
                                              </div>
                                              <div>
                                                {moveInDateSlideOnly ? (
                                                  <>
                                                    <button
                                                      className="btn btn-sm btn-outline-success"
                                                      onClick={() => {
                                                        let newCombinedTaskLists =
                                                          [
                                                            // Less than date
                                                            ...(filterWorkOrderByDate(
                                                              MeetingTaskListsView.fromPartial(
                                                                {
                                                                  taskLists: [
                                                                    ...combinedTaskLists,
                                                                  ],
                                                                }
                                                              ),
                                                              moveInDateSlideOnly,
                                                              reschedulerUser,
                                                              true
                                                            ) ?? []),
                                                          ];

                                                        let dateMove =
                                                          new Date(
                                                            d
                                                          ).getTime() -
                                                          new Date(
                                                            moveInDateSlideOnly
                                                          ).getTime();

                                                        // if (dateMove < 0) {
                                                        //   alert(
                                                        //     "Cannot reschedule date backwards"
                                                        //   );
                                                        //   return;
                                                        // }

                                                        const includedWos = [
                                                          ...combinedTaskLists.filter(
                                                            (tL) =>
                                                              !newCombinedTaskLists.find(
                                                                (tLx) =>
                                                                  `${tLx.taskList?.masterJavaBaseModel?.id}` ===
                                                                  `${tL.taskList?.masterJavaBaseModel?.id}`
                                                              )
                                                          ),
                                                        ];

                                                        newCombinedTaskLists = [
                                                          ...newCombinedTaskLists,
                                                          ...includedWos.map(
                                                            (tL) => {
                                                              return {
                                                                ...tL,
                                                                taskList:
                                                                  tL.taskList
                                                                    ? {
                                                                        ...tL.taskList,
                                                                        meetingTasks:
                                                                          tL.taskList?.meetingTasks.map(
                                                                            (
                                                                              mt
                                                                            ) => {
                                                                              return {
                                                                                ...mt,
                                                                                start:
                                                                                  mt.start
                                                                                    ? makeDateString(
                                                                                        new Date(
                                                                                          new Date(
                                                                                            mt.start
                                                                                          ).getTime() +
                                                                                            dateMove
                                                                                        )
                                                                                      )
                                                                                    : mt.start,
                                                                                meetingTaskTargetDates:
                                                                                  mt.start
                                                                                    ? [
                                                                                        ...mt.meetingTaskTargetDates,
                                                                                        MeetingMeetingTaskTargetDate.fromPartial(
                                                                                          {
                                                                                            date: makeDateString(
                                                                                              new Date(
                                                                                                new Date(
                                                                                                  mt.start
                                                                                                ).getTime() +
                                                                                                  dateMove
                                                                                              )
                                                                                            ),
                                                                                            masterJavaBaseModel:
                                                                                              MasterJavaBaseModel.fromPartial(
                                                                                                {
                                                                                                  uuid: v4(),
                                                                                                }
                                                                                              ),
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    : mt.meetingTaskTargetDates,
                                                                              };
                                                                            }
                                                                          ),
                                                                      }
                                                                    : tL.taskList,
                                                              };
                                                            }
                                                          ),
                                                        ];

                                                        setCombinedTaskLists(
                                                          newCombinedTaskLists
                                                        );
                                                        setMoveInDateSlideOnly(
                                                          null
                                                        );
                                                      }}
                                                    >
                                                      Here
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <button
                                                      className="btn btn-sm btn-outline-success"
                                                      onClick={() => {
                                                        setMoveInDateSlideOnly(
                                                          d
                                                        );
                                                      }}
                                                    >
                                                      Slide
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </th>
                                        </>
                                      ))}
                                    </tr>
                                    <tr>
                                      {dates.map((d) => {
                                        const wos = filterWorkOrderByDate(
                                          MeetingTaskListsView.fromPartial({
                                            taskLists: combinedTaskLists,
                                          }),
                                          d,
                                          reschedulerUser
                                        );
                                        const wosTime =
                                          wos
                                            ?.map((tL) =>
                                              getWorkOrderTimeMinutes(
                                                tL.taskList
                                                  ? {
                                                      ...tL.taskList,
                                                    }
                                                  : MeetingTaskList.fromPartial(
                                                      {}
                                                    ),
                                                rcemDepartments,
                                                integrationModules,
                                                machinePrograms,
                                                departmentTemplates,
                                                panelCodeDepartmentTemplateItemsProto,
                                                reschedulerUser?.id,
                                                d
                                              )
                                            )
                                            .reduce(
                                              (acc, time) =>
                                                acc + (time.totalHours ?? 0),
                                              0.0
                                            ) ?? 0.0;
                                        return (
                                          <>
                                            <td className="border border-dark">
                                              <div>
                                                <div>
                                                  <small>
                                                    <strong>
                                                      {wos?.length} (
                                                      {wosTime.toFixed(1)} hrs)
                                                    </strong>
                                                  </small>
                                                </div>
                                                <div>
                                                  {selectedTaskListToMove ? (
                                                    <>
                                                      <button
                                                        className="btn btn-outline-primary btn-sm"
                                                        onClick={() => {
                                                          setCombinedTaskLists(
                                                            combinedTaskLists.map(
                                                              (tL) =>
                                                                `${tL.taskList?.masterJavaBaseModel?.id}` ===
                                                                `${selectedTaskListToMove.taskList?.masterJavaBaseModel?.id}`
                                                                  ? {
                                                                      ...tL,
                                                                      taskList:
                                                                        tL.taskList
                                                                          ? {
                                                                              ...tL.taskList,
                                                                              meetingTasks:
                                                                                tL
                                                                                  .taskList
                                                                                  .meetingTasks
                                                                                  ? tL.taskList.meetingTasks.map(
                                                                                      (
                                                                                        mt
                                                                                      ) =>
                                                                                        mt
                                                                                          ? {
                                                                                              ...mt,
                                                                                              start:
                                                                                                d,
                                                                                              meetingTaskTargetDates:
                                                                                                mt.meetingTaskTargetDates
                                                                                                  ? [
                                                                                                      ...mt.meetingTaskTargetDates,
                                                                                                      MeetingMeetingTaskTargetDate.fromPartial(
                                                                                                        {
                                                                                                          date: d,
                                                                                                          masterJavaBaseModel:
                                                                                                            MasterJavaBaseModel.fromPartial(
                                                                                                              {
                                                                                                                uuid: v4(),
                                                                                                              }
                                                                                                            ),
                                                                                                        }
                                                                                                      ),
                                                                                                    ]
                                                                                                  : mt.meetingTaskTargetDates,
                                                                                            }
                                                                                          : mt
                                                                                    )
                                                                                  : tL
                                                                                      .taskList
                                                                                      .meetingTasks,
                                                                            }
                                                                          : tL.taskList,
                                                                    }
                                                                  : tL
                                                            )
                                                          );
                                                          setSelectedTaskListToMove(
                                                            null
                                                          );
                                                        }}
                                                      >
                                                        Move here
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <ul>
                                                    {wos?.map((tL) => {
                                                      return (
                                                        <>
                                                          <li>
                                                            <div
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedTaskListToMove(
                                                                  tL
                                                                );
                                                              }}
                                                            >
                                                              <div>
                                                                {getWorkOrderTimeMinutes(
                                                                  tL.taskList
                                                                    ? {
                                                                        ...tL.taskList,
                                                                      }
                                                                    : MeetingTaskList.fromPartial(
                                                                        {}
                                                                      ),
                                                                  rcemDepartments,
                                                                  integrationModules,
                                                                  machinePrograms,
                                                                  departmentTemplates,
                                                                  panelCodeDepartmentTemplateItemsProto,
                                                                  reschedulerUser?.id,
                                                                  d
                                                                ).totalHours.toFixed(
                                                                  1
                                                                )}{" "}
                                                                hrs
                                                              </div>
                                                              <div>
                                                                <small>
                                                                  <strong>
                                                                    #
                                                                    {
                                                                      tL
                                                                        .taskList
                                                                        ?.masterJavaBaseModel
                                                                        ?.id
                                                                    }
                                                                  </strong>
                                                                </small>
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </>
                                                      );
                                                    })}
                                                  </ul>
                                                </div>
                                              </div>{" "}
                                            </td>
                                          </>
                                        );
                                      })}
                                    </tr>
                                  </table>
                                </div>
                              );
                            })()}
                          </div>
                        );
                      })()}
                    </>
                  )}
                  <div>
                    <strong>Incompletes</strong>
                    <ol>
                      {incompleteTaskLists.map((tL) => {
                        return (
                          <>
                            <li>
                              <div>
                                <div>
                                  <TaskListInfo
                                    customers={ctx?.extCustomers}
                                    crmCustomers={ctx?.extCrmCustomers}
                                    tL={tL}
                                    extUsers={ctx?.extUsers ?? []}
                                    jobsProto={
                                      jobsProto ?? PpicJobs.fromPartial({})
                                    }
                                    machines={machines}
                                    processTypes={
                                      processTypes ??
                                      PpicSimplifiedProcessTypes.fromPartial({})
                                    }
                                    rcemDepartments={rcemDepartments}
                                    integrationModules={integrationModules}
                                    machinePrograms={machinePrograms}
                                    departmentTemplates={departmentTemplates}
                                    exUserId={reschedulerUser?.id}
                                    panelCodeDepartmentTemplateItems={
                                      panelCodeDepartmentTemplateItemsProto
                                    }
                                  />
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>
                  <div>
                    <strong>valids</strong>
                    <ol>
                      {taskListsValid.map((tL) => {
                        return (
                          <>
                            <li>
                              <div>
                                <div>
                                  <TaskListInfo
                                    customers={ctx?.extCustomers}
                                    crmCustomers={ctx?.extCrmCustomers}
                                    tL={tL}
                                    extUsers={ctx?.extUsers ?? []}
                                    jobsProto={
                                      jobsProto ?? PpicJobs.fromPartial({})
                                    }
                                    machines={machines}
                                    processTypes={
                                      processTypes ??
                                      PpicSimplifiedProcessTypes.fromPartial({})
                                    }
                                    rcemDepartments={rcemDepartments}
                                    integrationModules={integrationModules}
                                    machinePrograms={machinePrograms}
                                    departmentTemplates={departmentTemplates}
                                    exUserId={reschedulerUser?.id}
                                    panelCodeDepartmentTemplateItems={
                                      panelCodeDepartmentTemplateItemsProto
                                    }
                                  />
                                </div>
                                <div>
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => {
                                      return (
                                        <>
                                          <li>
                                            <div>
                                              <div>
                                                {mt.description}, {mt.start} to{" "}
                                                {
                                                  extractProtoMeetingTaskTargetDate(
                                                    mt
                                                  )?.date
                                                }
                                                ,{" "}
                                                <small>
                                                  {mt.meetingTaskInCharges
                                                    .map(
                                                      (mtic) =>
                                                        `${
                                                          ctx?.extUsers.find(
                                                            (u) =>
                                                              `${u.id}` ===
                                                              `${mtic.extUserId}`
                                                          )?.name
                                                        }`
                                                    )
                                                    .join(", ")}
                                                </small>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ol>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>
                </ModalBody>
                <ModalFooter>
                  {loading ? (
                    <>
                      <Spinner animation="border" />
                    </>
                  ) : (
                    <>
                      <div>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            handleReschedule(
                              combinedTaskLists.filter((tL) => {
                                const foundTL = combinedTaskListsFormer.find(
                                  (tLx) =>
                                    tLx.taskList?.masterJavaBaseModel?.id ===
                                    tL.taskList?.masterJavaBaseModel?.id
                                );

                                return foundTL
                                  ? murmur.murmur3(
                                      JSON.stringify(
                                        MeetingTaskListView.toJSON(foundTL)
                                      )
                                    ) !==
                                      murmur.murmur3(
                                        JSON.stringify(
                                          MeetingTaskListView.toJSON(tL)
                                        )
                                      )
                                  : true;
                              })
                            );
                            setShowReschedulerModal(false);
                          }}
                        >
                          Reschedule
                        </button>
                      </div>
                    </>
                  )}
                </ModalFooter>
              </>
            );
          })()}
        </Modal>
      </div>
    </>
  );
};

export default JobDeptProgressPage;
