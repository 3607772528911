import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { Route, Switch } from "react-router-dom";

const RouteSwitcher = (props: { 
  routes: {
    linkFrom: string,
    component: ReactNode
  }[]
}) => {
  const routes = props.routes
  
  return (
    <Switch>
      {
        routes.map(route => {
          return (
            <Route key={route.linkFrom} exact path={route.linkFrom}>
              <Box m={3}>
                {route.component}
              </Box>
            </Route>
          )
        })
      }
    </Switch>
  )
}

export default RouteSwitcher