import { Container } from "@material-ui/core";
import { Add, ChevronLeft, ChevronRight } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { createTextChangeRange } from "typescript";
import { AppContext } from "../../App";
import {
  fetchDateWorkOrdersRange,
  fetchPanelTemplateProcessTypeGeneralItemSubProcessWorkers,
  makeDateString,
  months,
} from "../../helpers";
import {
  ExtProjectWorkOrder,
  ExtProjectWorkOrderView,
  PanelTemplateProcessTypeGeneralItemSubProcessWorker,
} from "../../models/model";

const WorkOrderV2Page = () => {
  const state = useContext(AppContext);
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [expandDates, setExpandDates] = useState<string[]>([]);
  const [subProcessWorkers, setSubProcessWorkers] = useState<
    PanelTemplateProcessTypeGeneralItemSubProcessWorker[]
  >([]);
  const [showWorkersDialog, setShowWorkersDialog] = useState(false);
  const [showWorkersDialogContent, setShowWorkersDialogContent] = useState(
    <></>
  );

  const lastDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  ).getDate();

  const firstDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  ).getDate();

  const dates = [...Array(lastDate)].map((_, i) => i + 1);
  const pad =
    selectedDate.getDay() === 0
      ? [...Array(6)].map((_) => null)
      : [...Array(selectedDate.getDay() - 1)].map((_) => null);

  const paddedDates = [...pad, ...dates];

  const slicedDate = [...Array(6)].map((_, i) =>
    paddedDates.slice(i * 7, (i + 1) * 7)
  );

  const [workOrdersRange, setWorkOrdersRange] = useState<
    ExtProjectWorkOrderView[]
  >([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const workOrdersRange = await fetchDateWorkOrdersRange(
      state?.baseUrl ?? "",
      state?.apiKey ?? "",
      makeDateString(
        new Date(selectedDate.getFullYear(), selectedDate.getMonth(), firstDate)
      ),
      makeDateString(
        new Date(selectedDate.getFullYear(), selectedDate.getMonth(), lastDate)
      )
    );

    console.log(
      "dates:",
      dates.map(
        (date) =>
          `${makeDateString(
            new Date(selectedDate.getFullYear(), selectedDate.getMonth(), date)
          )}T00:00:00Z`
      )
    );

    const subProcessWorkers = (
      await Promise.all(
        dates
          .map(
            (date) =>
              `${makeDateString(
                new Date(
                  selectedDate.getFullYear(),
                  selectedDate.getMonth(),
                  date
                )
              )}T00:00:00Z`
          )
          .map((dateStr) =>
            fetchPanelTemplateProcessTypeGeneralItemSubProcessWorkers(
              state?.baseUrl ?? "",
              state?.apiKey ?? "",
              dateStr
            )
          )
      )
    ).flat();

    setSubProcessWorkers(subProcessWorkers);
    setWorkOrdersRange(workOrdersRange);
  };

  const dateRangeWorkers = () => {};

  return (
    <>
      <div className="m-3 ">
        <div className="d-flex align-items-center">
          <h5>Work Order</h5>
        </div>

        <hr />

        {/* <div>{makeReadableDateString(selectedDate)}</div>
        <div>First: {firstDate}</div>
        <div>Last: {lastDate}</div>
        <div>
          Dates:{" "}
          {paddedDates
            .map(
              (date) =>
                `${
                  date
                    ? new Date(
                        selectedDate.getFullYear(),
                        selectedDate.getMonth(),
                        date
                      ).getDay()
                    : 0
                }:${
                  date
                    ? makeDateString(
                        new Date(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth(),
                          date
                        )
                      )
                    : 0
                }`
            )
            .join(", ")}
        </div>
        <div>{JSON.stringify(slicedDate)}</div> */}
        {/* <div>{JSON.stringify(workOrdersRange)}</div> */}
        <div className="my-3">
          <Container>
            <div className="d-flex">
              <div className="mx-2">
                <Button
                  size="sm"
                  onClick={() => {
                    const newDate = new Date(
                      selectedDate.getFullYear(),
                      selectedDate.getMonth() - 1,
                      1
                    );
                    setSelectedDate(newDate);
                  }}
                >
                  <ChevronLeft fontSize="inherit" /> Prev
                </Button>
              </div>
              <div className="mx-2">
                <Button
                  onClick={() => {
                    const newDate = new Date(
                      selectedDate.getFullYear(),
                      selectedDate.getMonth() + 1,
                      1
                    );

                    setSelectedDate(newDate);
                  }}
                  size="sm"
                >
                  Next <ChevronRight fontSize="inherit" />
                </Button>
              </div>
              <div className="mx-2">
                {months[selectedDate.getMonth()]} {selectedDate.getFullYear()}
              </div>
            </div>

            <div className="my-2 shadow shadow-md">
              <Table size="sm" style={{ borderCollapse: "separate" }}>
                <thead>
                  <tr>
                    {[
                      "Senin",
                      "Selasa",
                      "Rabu",
                      "Kamis",
                      "Jumat",
                      "Sabtu",
                      "Minggu",
                    ].map((day) => (
                      <th className="bg-secondary border border-secondary text-light">
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
                {slicedDate.map((dateWeek) => {
                  return (
                    <tr>
                      {dateWeek.map((date) => {
                        const day = date
                          ? new Date(
                              selectedDate.getFullYear(),
                              selectedDate.getMonth(),
                              date
                            ).getDay()
                          : null;

                        const workOrders = date
                          ? workOrdersRange.filter(
                              (workOrder) =>
                                workOrder.extProjectWorkOrder?.date ===
                                `${makeDateString(
                                  new Date(
                                    selectedDate.getFullYear(),
                                    selectedDate.getMonth(),
                                    date
                                  )
                                )}T00:00:00Z`
                            )
                          : [];

                        const yyyyMmDdStr = makeDateString(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth(),
                            date ?? 0
                          )
                        );

                        const totalSubProcessWorkers = subProcessWorkers
                          .filter((subProcessWorker) =>
                            workOrders.find(
                              (workOrder) =>
                                workOrder.extProjectWorkOrder?.uuid ===
                                subProcessWorker.workOrderUuid
                            )
                          )
                          .filter(
                            (subProcessWorker) =>
                              subProcessWorker &&
                              subProcessWorker.extUserId !== 0
                          );

                        return (
                          <td
                            className={`border border-secondary ${
                              date &&
                              makeDateString(new Date()) ===
                                makeDateString(
                                  new Date(
                                    selectedDate.getFullYear(),
                                    selectedDate.getMonth(),
                                    date
                                  )
                                )
                                ? "bg-info text-light"
                                : ""
                            } ${(() => {
                              switch (day) {
                                case 0:
                                  return "text-danger";
                                case 6:
                                  return "text-primary";
                                default:
                                  return "";
                              }
                            })()}`}
                          >
                            <div>
                              {/* <div>
                                {date ? (
                                  makeDateString(
                                    new Date(
                                      selectedDate.getFullYear(),
                                      selectedDate.getMonth(),
                                      date
                                    )
                                  )
                                ) : (
                                  <></>
                                )}
                              </div> */}
                              <div className="d-flex justify-content-between">
                                <div className="font-weight-bold">{date}</div>
                                {date ? (
                                  <div>
                                    <Link
                                      to={`/workordersv2/${makeDateString(
                                        new Date(
                                          selectedDate.getFullYear(),
                                          selectedDate.getMonth(),
                                          date
                                        )
                                      )}`}
                                    >
                                      <Button size="sm">
                                        <Add fontSize="inherit" />
                                      </Button>
                                    </Link>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              {date ? (
                                <div>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (
                                        expandDates.find(
                                          (expandDate) =>
                                            expandDate === yyyyMmDdStr
                                        )
                                      ) {
                                        setExpandDates(
                                          expandDates.filter(
                                            (expandDate) =>
                                              expandDate !== yyyyMmDdStr
                                          )
                                        );
                                      } else {
                                        setExpandDates([
                                          ...expandDates,
                                          yyyyMmDdStr,
                                        ]);
                                      }
                                    }}
                                  >
                                    <small
                                      className={`font-weight-bold shadow shadow-md px-1 ${
                                        workOrders.length > 0
                                          ? "bg-success text-light"
                                          : "bg-danger text-light"
                                      }`}
                                    >
                                      Work orders: {workOrders.length}
                                    </small>
                                  </div>
                                  <div>
                                    {(() => {
                                      const totalWorkersAssigned =
                                        [
                                          ...new Set(
                                            totalSubProcessWorkers.map(
                                              (subProcessWorker) =>
                                                subProcessWorker.extUserId
                                            )
                                          ),
                                        ].length ?? 0;

                                      return (
                                        <strong
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            setShowWorkersDialog(true);
                                            setShowWorkersDialogContent(
                                              <div className="p-2">
                                                <h4>
                                                  Date:{" "}
                                                  {Intl.DateTimeFormat(
                                                    navigator.language ??
                                                      "en-US",
                                                    {
                                                      dateStyle: "long",
                                                      // timeZone: "utc",
                                                    } as any
                                                  ).format(
                                                    new Date(
                                                      selectedDate.getFullYear(),
                                                      selectedDate.getMonth(),
                                                      date
                                                    )
                                                  )}
                                                </h4>
                                                <ol>
                                                  {state?.extUsers
                                                    .filter((extUser) =>
                                                      extUser.departmentName
                                                        ?.toLowerCase()
                                                        .includes("wiring")
                                                    )
                                                    .map((extUser) => {
                                                      return (
                                                        <li
                                                          className={`font-weight-bold ${
                                                            totalSubProcessWorkers.find(
                                                              (
                                                                totalSubProcessWorker
                                                              ) =>
                                                                totalSubProcessWorker.extUserId ===
                                                                extUser.id
                                                            )
                                                              ? "text-success"
                                                              : "text-danger"
                                                          }`}
                                                        >
                                                          {extUser.name}
                                                        </li>
                                                      );
                                                    })}
                                                </ol>
                                              </div>
                                            );
                                          }}
                                          className={`p-1 ${
                                            totalWorkersAssigned <=
                                            (state?.extUsers?.filter(
                                              (extUser) =>
                                                extUser.departmentName
                                                  ?.toLowerCase()
                                                  .includes("wiring")
                                            )?.length ?? 0)
                                              ? "bg-warning"
                                              : "bg-success"
                                          }`}
                                        >{`${totalWorkersAssigned}/${
                                          state?.extUsers?.filter((extUser) =>
                                            extUser.departmentName
                                              ?.toLowerCase()
                                              .includes("wiring")
                                          )?.length
                                        }`}</strong>
                                      );
                                    })()}
                                  </div>
                                  {expandDates.find(
                                    (expandDate) => expandDate === yyyyMmDdStr
                                  ) ? (
                                    <div>
                                      {workOrders.map((workOrder, i) => (
                                        <div>
                                          <div>
                                            {i + 1}){" "}
                                            {
                                              state?.projectsIdName.find(
                                                (project) =>
                                                  project.id ===
                                                  workOrder.extProjectWorkOrder
                                                    ?.extProjectId
                                              )?.name
                                            }
                                            :{" "}
                                            {
                                              state?.projectProductsIdName.find(
                                                (projectProduct) =>
                                                  projectProduct.id ===
                                                  workOrder.extProjectWorkOrder
                                                    ?.extProductId
                                              )?.name
                                            }
                                            :{" "}
                                            {
                                              workOrder.extProjectWorkOrder
                                                ?.panelTemplateProcessTypeGeneralItemSubProcess
                                                ?.processTypeGeneralItemSubProcess
                                                ?.processTypeGeneralItem
                                                ?.processTypeGeneral?.name
                                            }
                                            :{" "}
                                            {
                                              workOrder.extProjectWorkOrder
                                                ?.panelTemplateProcessTypeGeneralItemSubProcess
                                                ?.processTypeGeneralItemSubProcess
                                                ?.processTypeGeneralItem?.name
                                            }
                                            :{" "}
                                            <strong>
                                              {
                                                workOrder.extProjectWorkOrder
                                                  ?.panelTemplateProcessTypeGeneralItemSubProcess
                                                  ?.processTypeGeneralItemSubProcess
                                                  ?.name
                                              }
                                            </strong>
                                            :{" "}
                                          </div>
                                          <div>
                                            <small>
                                              {subProcessWorkers
                                                .filter(
                                                  (subProcessWorker) =>
                                                    subProcessWorker.workOrderUuid ===
                                                    workOrder
                                                      .extProjectWorkOrder?.uuid
                                                )
                                                .map((worker) => {
                                                  const foundExtUser =
                                                    state?.extUsers.find(
                                                      (extUser) =>
                                                        extUser.id ===
                                                        worker.extUserId
                                                    );
                                                  return `${foundExtUser?.name} (${foundExtUser?.departmentName})`;
                                                })}
                                            </small>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </Table>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        show={showWorkersDialog}
        onHide={() => {
          setShowWorkersDialog(false);
        }}
      >
        {showWorkersDialogContent}
      </Modal>
    </>
  );
};

export default WorkOrderV2Page;
