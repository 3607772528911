import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { compareAssignedAndNeededQty, makeDateString } from '../../../helpers';
import { Job } from '../../../models/model';
import { RequestStatus } from '../../../models/RequestStatus';

const MonthlySummary = () => {
  const params = new URLSearchParams(useLocation().search);
  const apiKey = localStorage.getItem("apiKey");

  const start = params.get("start");
  const end = params.get("end");
  
  const dateWithCurrentMonth = start
    ? new Date(new Date(start).getFullYear(), new Date(start).getMonth(), new Date(start).getDate() + 1)
    : new Date();

  const lastDate = new Date(dateWithCurrentMonth.getFullYear(), dateWithCurrentMonth.getMonth() + 1, 0).getDate();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NotAsked);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedProcessTypeName, setSelectedProcessTypeName] = useState<string | null>(null);

  useEffect(() => {
    if(start && end)
      fetchWorkOrderMonthlySummary(start, end);
  }, []);

  const fetchWorkOrderMonthlySummary = async (start: string, end: string) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/workorderrevsmonthlysummary?start=${start}&end=${end}`, {
        headers: { "authorization": apiKey ? apiKey : "" }
      });
      if(response.status !== 200) throw "Error fetching work order summary bretween";

      const jobs: Job[] | null | undefined = await response.json();
      
      if(jobs) {
        setJobs([...jobs]);  
      }
      setRequestStatus(RequestStatus.Success);
    } catch(e) {
      setRequestStatus(RequestStatus.Error);    
    }
  }

  const processTypes = [...new Set(
    jobs
      .map(job => job.workOrderRevs)
      .flat()
      .map(workOrder => workOrder?.machine?.processType?.name)
  )];

  return (
    <Box>
      <Box display="flex">
        <Link to="/workordersrev">
          <Button
            color="primary"
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Link>
        {requestStatus === RequestStatus.Loading ? <CircularProgress disableShrink /> : <></>}
      </Box>

      <Box display="flex" alignItems="center">
        <h2>Monthly Summary</h2>
        <Box mx={1} width={300}>
          {/* {JSON.stringify(processTypes)} */}
          <Select
            placeholder="Filter process type..."
            options={processTypes.map(processTypeName => ({ label: processTypeName, value: processTypeName }))}
            value={ selectedProcessTypeName ? { value: selectedProcessTypeName, label: selectedProcessTypeName } : null}
            onChange={selected => setSelectedProcessTypeName((selected as { value: string, label: string }).value)}
          />
        </Box>
      </Box>
      {/* <h3>Start: {params.get("start")}, end: {params.get("end")}</h3> */}
      <Box>
        {[...Array(lastDate).keys()]
          .map(date => date + 1)
          .map(date => {
            const dateString = makeDateString(new Date(dateWithCurrentMonth.getFullYear(), dateWithCurrentMonth.getMonth(), date));

            return (
              <Box>
                <Box>
                  <h3>{dateString}</h3>
                </Box>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow style={{backgroundColor: blue[100]}}>
                        <TableCell>Job</TableCell>
                        <TableCell>Process Name</TableCell>
                        <TableCell>Process Type</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Done</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobs
                        .map(job => 
                          job.workOrderRevs
                            ?.filter(workOrder => selectedProcessTypeName ? workOrder.machine?.processType?.name === selectedProcessTypeName : true)
                            .filter(workOrder => workOrder.date === dateString)
                            .map(workOrder => {
                              const done = workOrder.workOrderActualRevs.reduce((acc, workOrderActual) => acc + workOrderActual.amount, 0);
                              const needed = workOrder.amount;

                              return (
                                <TableRow>
                                  <TableCell>{job.name}</TableCell>
                                  <TableCell>
                                    {(() => {
                                      if(workOrder.fepMulti) {
                                        return workOrder.fepMulti.name;
                                      } else if(workOrder.partToPart) {
                                        return workOrder.partToPart.name;
                                      } else {
                                        return "";
                                      }
                                    })()}
                                  </TableCell>
                                  <TableCell>
                                    {workOrder.machine?.processType?.name}
                                  </TableCell>
                                  <TableCell>
                                    {done}
                                  </TableCell>
                                  <TableCell
                                    style={{backgroundColor: compareAssignedAndNeededQty(done, needed)}}
                                  >
                                    {done}
                                  </TableCell>
                                </TableRow>
                              )
                            }))
                        .flat()
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
          })
        }
      </Box>
      {/* <pre>
        {JSON.stringify(jobs, null, 2)}
      </pre> */}
    </Box>
  )
}

export default MonthlySummary;