import React from "react";

const DeliveryPlanPage = () => {
  return (
    <>
      {/* test */}
      <div style={{ height: "75vh", width: "95vw" }}>
        <iframe
          style={{ height: "75vh", width: "100vw" }}
          src={`${process.env.REACT_APP_MEETING_UI_URL}/?iframe=true/#/delivery-plan`}
        />
      </div>
    </>
  );
};

export default DeliveryPlanPage;
