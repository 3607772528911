import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner, Table } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { BomDocument, BomMulti, ExtItem } from '../../../models/model';
import { initialBomDocument, initialBomMulti } from '../../../models/modelinitials';
import { RequestStatus } from '../../../models/RequestStatus';
import { ChevronLeftIcon } from '../../mini-components/Icons';

const BomDocumentForm = () => {
  const apiKey = localStorage.getItem("apiKey");

  const { id } = useParams() as {id:string};
  const history = useHistory();

  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    requestStatus: RequestStatus.NotAsked,
    bomDocument: { ...initialBomDocument } as BomDocument,
    selectedItem: null as ExtItem | null,
    qty: "",
    childOf: null as BomMulti | null,
    bomMultisIdToDelete: [] as Array<number>,
    items: [] as Array<ExtItem>
  });

  useEffect(() => {
    (async () => {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const items = await fetchExtItems();
      const bomDocument = await (async () => {
        const parsedId = parseInt(id ? id : ""); 

        if(!isNaN(parsedId)) {
          return await fetchBomDocument(parsedId);
        } else {
          return null;
        }
      })();

      setState({
        ...state,
        requestStatus: RequestStatus.Success,
        items: items ? items : [],
        bomDocument: bomDocument ? bomDocument : state.bomDocument
      });
    })();
  }, []);

  const fetchExtItems = async (): Promise<Array<ExtItem> | null | undefined> => {
     try {
      const itemsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/ext-items`, {
        headers: {
          "authorization": state.apiKey
        }
      });

      return await itemsResponse.json() as Array<ExtItem>;
     } catch(e) {
        console.log(e);
        return null;
     }
  }

  const fetchBomDocument = async (id: number): Promise<BomDocument | null | undefined> => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/bomdocuments/${id}`, {
        headers: {
          "authorization": state.apiKey
        }
      });

      return await response.json() as BomDocument;
      
      
      // setState({ 
      //   ...state, 
      //   requestStatus: RequestStatus.Success,
      //   bomDocument: bomDocument, 
      //   items: items
      // });
    } catch(e) {
      console.log(e);
      // setState({ ...state, requestStatus: RequestStatus.Error });
      return null;
    }
  }

  const handleSave = async () => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/bomdocuments/save`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "authorization": state.apiKey
        },
        body: JSON.stringify(state.bomDocument)
      });

      if(response.status !== 201) throw "Error saving bom document.";

      history.push("/bomdocuments");
    } catch(e) {
      console.log(e);
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  }

  const handleCopy = async () => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/bomdocuments/copy`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "authorization": state.apiKey
        },
        body: JSON.stringify(state.bomDocument)
      });

      if(response.status !== 201) throw "Error saving bom document.";

      history.push("/bomdocuments");
    } catch(e) {
      console.log(e);
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  }

  

  const handleInsertBomMulti = (e: any) => {
    e.preventDefault(); 

    const newState = {...state};

    const newBomMulti = {
      ...initialBomMulti,
      uuid: uuidv4(),
      extItemId: newState.selectedItem ? newState.selectedItem.id : 0,
      qty: isNaN(parseFloat(state.qty)) ? 0 : parseFloat(state.qty),
    } as BomMulti;

    // console.log("New bom multi:", newBomMulti);
    
    // Check if child of a bomMulti
    const checkAndPushBomMulti = (bomMultiUuid: string, bomMulti: BomMulti, bomMultiToInsert: BomMulti) => {
      bomMulti.children.forEach(bomMulti => {
        checkAndPushBomMulti(bomMultiUuid, bomMulti, bomMultiToInsert);
      });
      
      if(bomMulti.uuid === bomMultiUuid) {
        // console.log("Match found: ", bomMulti.uuid, "=" , bomMultiUuid)
        // bomMulti.children.push(bomMultiToInsert);
        bomMulti.children = [...bomMulti.children, bomMultiToInsert]

        // console.log("After add: ", bomMulti)
      }
    }

    if(state.childOf) {
      const parent = state.childOf;

      newState.bomDocument.bomMultis.forEach(bomMulti => {
        checkAndPushBomMulti(parent.uuid, bomMulti, newBomMulti);
      }); 
    } else {
      newState.bomDocument.bomMultis.push(newBomMulti);      
    }

    // console.log("New state: ", newState);

    newState.qty = "";

    setState(newState); 
  }

  const handleDeleteBomMulti = (bomMultiUuid: string) => {
    const newState = {...state};

    const traverseAndSplice = (bomMultis: Array<BomMulti>, bomMultiUuid: string) => {
      const foundBomMultiIndex = bomMultis.findIndex(bomMulti => bomMulti.uuid === bomMultiUuid);
      const foundBomMulti = bomMultis[foundBomMultiIndex];

      console.log("Found bom multi index:", foundBomMulti, foundBomMultiIndex, bomMultiUuid);

      if(foundBomMulti) {
        newState.bomMultisIdToDelete = [...newState.bomMultisIdToDelete, foundBomMulti.id];

        bomMultis.splice(foundBomMultiIndex, 1); 
      }

      bomMultis.forEach(bomMulti => {
        traverseAndSplice(bomMulti.children, bomMultiUuid);
      });
    }

    traverseAndSplice(newState.bomDocument.bomMultis, bomMultiUuid);
    setState(newState);

    // const foundBomMultiIndex = newState.bomDocument.bomMultis
    //   .findIndex(bomMulti => bomMulti.uuid === bomMultiUuid);
    
    // const foundBomMulti = newState.bomDocument.bomMultis[foundBomMultiIndex];
    
    // if(foundBomMulti) {
    //   newState.bomMultisIdToDelete.push(foundBomMulti.id);
    //   newState.bomDocument.bomMultis.splice(foundBomMultiIndex, 1);
      
    //   setState(newState);  
    // }
  }

  const flattenedBomMultis = [] as Array<{ order: Array<number>, bomMulti: BomMulti }>;

  const flattenBomMultis = (bomMulti: BomMulti, order: Array<number>) => {
    // console.log(bomMulti);
    flattenedBomMultis.push({ order: order, bomMulti: bomMulti });

    bomMulti.children.forEach((bomMulti, i) => {
      flattenBomMultis(bomMulti, [...order, i + 1]);
    })
  }

  state.bomDocument.bomMultis.forEach((bomMulti, i) => flattenBomMultis(bomMulti, [i + 1]))
 
  // console.log("Flattened bom multis: ", flattenedBomMultis);

  return (
    <div>
      <div className="d-flex">
        <div>
          <Link to="/bomdocuments">
            <Button variant="outline-info">
              <ChevronLeftIcon /> Back
            </Button>
          </Link>
        </div>
        <h3 className="ml-2">BOM Document</h3>
        <div className="ml-2">
          <Button 
            variant="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </div>
        <div className="ml-2">
          <Button 
            variant="primary"
            onClick={() => handleCopy()}
          >
            Save Copy
          </Button>
        </div>
        <div className="ml-2">
          {state.requestStatus === RequestStatus.Loading
            ? <Spinner animation="border" variant="primary" />
            : <></>
          }
        </div>
      </div>
      <div className="d-flex">
        <div style={{ width: 400 }}>
          <Form.Label>BOM Name</Form.Label>
          <Form.Control 
            placeholder="BOM Name..."
            value={state.bomDocument.name}
            onChange={e => setState({ 
              ...state, 
              bomDocument: {
                ...state.bomDocument,
                name: e.target.value
              } 
            })}
          />
        </div>
        <div className="mx-2" style={{ width: 400 }}>
          <Form.Label>BOM Number</Form.Label>
          <Form.Control
            placeholder="BOM Number..."
            value={state.bomDocument.number}
            onChange={e => setState({ 
              ...state, 
              bomDocument: {
                ...state.bomDocument,
                number: e.target.value
              } 
            })}
          />
        </div>
        <div className="mx-2" style={{ width: 400 }}>
          <Form.Label>Finished good</Form.Label>
          <Select 
            options={state.items.map(item => ({ label: `${item.partNum} - ${item.mfr} - ${item.partName} - ${item.partDesc}`, value: item }))}             
          />
          {/* <Form.Control
            placeholder="..."
            value={state.bomDocument.number}
            onChange={e => setState({ 
              ...state, 
              bomDocument: {
                ...state.bomDocument,
                number: e.target.value
              } 
            })}
          /> */}
        </div>
      </div>
      <div className="mt-3">
        <h4>Bom Items select:</h4>
      </div>
      <form>
        <div className="my-2 d-flex align-items-center">
            <div style={{ width: 500 }}>
              <Select
                isClearable
                value={state.selectedItem 
                  ? { 
                      label: `${state.selectedItem.partNum} - ${state.selectedItem.mfr} - ${state.selectedItem.partName} - ${state.selectedItem.partDesc}`, 
                      value: state.selectedItem 
                    }
                  : null
                }
                onChange={selected => setState({
                  ...state,
                  selectedItem: selected ? (selected as { label: string, value: ExtItem }).value : null
                })}
                options={state.items.map(item => ({ label: `${item.partNum} - ${item.mfr} - ${item.partName} - ${item.partDesc}`, value: item }))}
              />
            </div>
            <div className="ml-2">
              Qty: 
            </div>
            <div className="d-flex align-items-center ml-1" style={{ width: 100 }}>
              <Form.Control
                onChange={e => setState({ ...state, qty: e.target.value })}
                value={state.qty}
                placeholder="Qty..."
              />
            </div>
            <div className="ml-2">
              {state.selectedItem?.defaultUm}
            </div>
            <div className="ml-2" style={{ width: 400 }}>
              <Select
                isClearable
                placeholder="Child of..."
                options={flattenedBomMultis.map(bomMulti => {
                  const foundExtItem = state.items.find(extItem => extItem.id === bomMulti.bomMulti.extItemId);

                  return { 
                    label: `${bomMulti.order.join(".")} - ${foundExtItem?.partNum} - ${foundExtItem?.partName} - ${foundExtItem?.partDesc}`, 
                    value: bomMulti
                  }
                })}
                onChange={selected => setState({
                  ...state,
                  childOf: selected ? (selected as unknown as { label: string, value: BomMulti }).value.bomMulti : null
                })}
              />
            </div>
            <div className="ml-2">
              <Button
                type="submit"
                variant="outline-primary"
                onClick={handleInsertBomMulti}
              >
                Insert
              </Button>
            </div>
        </div>
      </form>
      <div className="overflow-auto" style={{ height: "50vh", border: "2px solid gray", resize: "vertical" }}>
        <Table bordered size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr style={{ backgroundColor: "darkblue", top: 0, position: "sticky" }}>
              <th className="text-light text-center">Delete</th>
              <th className="text-light text-center">Order</th>
              <th className="text-light text-center">Mfr</th>
              <th className="text-light text-center">GSPE Part Number</th>
              <th className="text-light text-center">Part Name</th>
              <th className="text-light text-center">Part Desc</th>
              <th className="text-light text-center">Qty</th>
              <th className="text-light text-center">UM</th>
            </tr>
          </thead>
          <tbody>
            {flattenedBomMultis.map((bomMulti, i) => {
              const foundExtItem = state.items.find(extItem => extItem.id === bomMulti.bomMulti.extItemId);
 
              return (
                <tr>
                  <td>
                    <Button 
                      size="sm" 
                      variant="outline-danger"
                      onClick={() => handleDeleteBomMulti(bomMulti.bomMulti.uuid)}
                    >
                      Delete
                    </Button>
                  </td>
                  <td>{bomMulti.order.join(".")}</td>
                  <td>{foundExtItem?.mfr}</td>
                  <td>{foundExtItem?.partNum}</td>
                  <td>{foundExtItem?.partName}</td>
                  <td>{foundExtItem?.partDesc}</td>
                  <td>{bomMulti.bomMulti.qty}</td>
                  <td>{foundExtItem?.defaultUm}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {/* <div>
        Debug bomMulti:
        <pre>
          {JSON.stringify(state.bomDocument.bomMultis, null, 2)}
        </pre>
      </div>
      <div>
        Debug flattenedBomMulti:
        <pre>
          {JSON.stringify(flattenedBomMultis, null, 2)}
        </pre>
      </div> */}
      <div>
        Debug item:
        <pre style={{ whiteSpace: "pre-wrap" }}>
{JSON.stringify(state.items)}
        </pre>
      </div>
    </div>
  );
}

export default BomDocumentForm;