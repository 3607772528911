import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import {
    fetchProjects,
    fetchProjectsIdName
} from "../../../helpers";
import { Project } from "../../../models/model";

const ProjectNew = () => {
  const ctx = useContext(AppContext);

  const [projects, setProjects] = useState<Project[]>([]);
  const [extProjects, setExtProjects] = useState<
    {
      id: number | null;
      name: string | null;
      customer?: { text: string | null } | null;
    }[]
  >([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const [extProjects, projects] = await Promise.all([
      fetchProjectsIdName(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
      fetchProjects(ctx?.baseUrl ?? "", ctx?.apiKey ?? ""),
    ]);

    setProjects(projects);
    setExtProjects(extProjects);
  };

  return (
    <>
      <div className="m-3">
        <h4>Projects</h4>

        <div
          className="shadow shadow-md overflow-auto"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm table-bordered table-hover"
            style={{ borderCollapse: "separate" }}
          >
            <thead>
              <tr>
                {["#", "Name", "Customer", "Mapped"].map((head) => (
                  <th
                    className="border-secondary bg-secondary text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {extProjects.map((project, i) => {
                return (
                  <tr style={{ cursor: "pointer" }}>
                    <td className="border-secondary">{i + 1}</td>
                    <td className="border-secondary">
                      <Link to={`/projectsnew/${project.id}`}>
                        {project.name}{" "}
                      </Link>
                    </td>
                    <td className="border-secondary">
                      {project.customer?.text ?? (
                        <strong className="text-danger">None</strong>
                      )}
                    </td>
                    <td className="border-secondary">
                      {projects.find(
                        (projectX) => projectX.extProjectId === project.id
                      ) ? (
                        <strong className="text-success">Yes</strong>
                      ) : (
                        <strong className="text-danger">No</strong>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* <small>
          <pre>{JSON.stringify(extProjects, null, 2)}</pre>
        </small>

        <small>
          <pre>{JSON.stringify(projects, null, 2)}</pre>
        </small> */}
      </div>
    </>
  );
};

export default ProjectNew;
