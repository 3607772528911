import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  createMuiTheme,
  Divider,
  Switch,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import {
  blue,
  blueGrey,
  green,
  lightGreen,
  orange,
  red,
} from "@material-ui/core/colors";
import {
  Add,
  Delete,
  DirectionsRunSharp,
  FormatUnderlinedTwoTone,
  Satellite,
} from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import PowerIcon from "@material-ui/icons/Power";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  usePickerState,
} from "@material-ui/pickers";
import { datePickerDefaultProps } from "@material-ui/pickers/constants/prop-types";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import chroma from "chroma-js";
import { exists, stat } from "fs";
import { V } from "jointjs";
import murmur from "murmurhash-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Form,
  FormCheck,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { TLSSocket } from "tls";
import { isConstructorDeclaration, StringLiteralLike } from "typescript";
import { v4 as uuidv4, v3, v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  checkLoadColor,
  checkLoadColorDay,
  CheckRequestStatus,
  extractProtoMeetingTaskTargetDate,
  fetchBomLeveleds,
  fetchDepartmentTemplateCategories,
  fetchDepartmentTemplatesProto,
  fetchDepartmentTemplatesView,
  fetchExtCrmBposProto,
  fetchExtCrmDjos,
  fetchExtCrmPurchaseOrdersProto,
  fetchExtDepartments,
  fetchExtEngBomApprovals,
  fetchFepDocumentsSimple,
  fetchFepLeveleds,
  fetchHolidaysProto,
  fetchIntegrationModulesProto,
  fetchIntegrations,
  fetchJobsProtoSimple,
  fetchMachineProgramMachinesProto,
  fetchMachinePrograms,
  fetchMachineProgramsNonProto,
  fetchMachines,
  fetchMachinesProto,
  fetchPanelCodeDepartmentTemplateItemsProto,
  fetchPanelCodeDepartmentTemplatePresetsProto,
  fetchPartNumberMatchesProto,
  fetchProject,
  fetchRcemDepartmentsProto,
  fetchRcemSubProcessSkillsProto,
  fetchSimplifiedProcessTypes,
  fetchSimplifiedProcessTypesProto,
  fetchSkillsProto,
  fetchWarehouseItemsProto,
  fetchWorkOrderProtoMongo,
  fetchWorkOrdersProto,
  fetchWorkOrdersProtoMongo,
  filterNotHiddenAndSort,
  filterWorkOrderByDate,
  filterWorkOrderByDateRange,
  flattenModules,
  formatDateTimeIntl,
  getPanelCodeCompleteSerialNumber,
  getPanelCodeCompleteSerialNumberProto,
  getSkillLevelIcon,
  getWorkOrderTimeMinutes,
  intlFormat,
  jobDeptCategories,
  makeDateString,
  makeReadableDateStringIntl,
  makeReadableDateStringIntlDateOnlyUtc,
  makeReadableDateStringIntlUtc,
  mapIntegrationModulesToWorkOrders,
  searchItems,
  secondsToHms,
  TableCellBordered,
  TaskListInfo,
} from "../../../helpers";
import {
  CrmBPO,
  CrmBPOs,
  CrmDJOs,
  CrmProduct,
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  MasterJavaBaseModel,
  MeetingAutoAssignParams,
  MeetingMeetingTask,
  MeetingMeetingTaskComment,
  MeetingMeetingTaskInCharge,
  MeetingMeetingTaskTargetDate,
  MeetingTaskList,
  MeetingTaskListInCharge,
  MeetingTaskListsView,
  MeetingTaskListView,
  PpicDepartmentTemplates,
  PpicExtDepartmentHiddenJobOverviewCol,
  PpicHolidays,
  PpicIntegrationModules,
  PpicIntegrationModuleType,
  ppicIntegrationModuleTypeFromJSON,
  ppicIntegrationModuleTypeToJSON,
  PpicJobs,
  PpicMachine,
  PpicMachineProgramMachines,
  PpicMachinePrograms,
  PpicMachines,
  PpicPanelCode,
  PpicPanelCodeDepartmentTemplateItem,
  PpicPanelCodeDepartmentTemplateItems,
  PpicPanelCodeDepartmentTemplatePreset,
  PpicPanelCodeDepartmentTemplatePresets,
  PpicPartNumberMatch,
  PpicPartNumberMatches,
  PpicRcemDepartments,
  PpicRcemSubProcess,
  PpicRcemSubProcessSkills,
  PpicSimplifiedProcessTypes,
  PpicSkill,
  PpicSkills,
  PpicSkillWorker,
  ppicSkillWorker_SkillLevelToNumber,
  TaskListDepartmentCategory,
  taskListDepartmentCategoryFromJSON,
  taskListDepartmentCategoryToJSON,
  WarehouseItem,
  WarehouseItems,
} from "../../../masterbigsystem";
import { Project } from "../../../models/meeting";
import {
  Bom,
  BomDocument,
  BomLeveled,
  BomLeveledRecursive,
  Deadline,
  DepartmentTemplate,
  DepartmentTemplateCategory,
  DepartmentTemplateItem,
  DepartmentTemplateItemView,
  DepartmentTemplateView,
  ExtDepartment,
  ExtUser,
  Fep,
  FepDocument,
  FepLeveled,
  IndividualEntity,
  Integration,
  IntegrationModule,
  IntegrationModuleType,
  IntegrationModuleView,
  IntegrationView,
  Item,
  Job,
  JobBomLeveled,
  JobBPO,
  JobDeptCategory,
  JobDJO,
  JobFep,
  JobFepLeveled,
  JobIntegrationList,
  JobIntegrationListView,
  JobMapped,
  JobPanelType,
  JobPO,
  JobType,
  Machine,
  MachineProgram,
  MachineProgramExclusion,
  PanelCode,
  PanelCodeDepartmentTemplate,
  PanelCodeDepartmentTemplateItem,
  PanelCodeMachineProgram,
  PanelCodeSerialNumber,
  PanelCodeSerialNumberManual,
  PanelCodeSerialNumberRect,
  PanelCodeType,
  PoStatus,
  Ral,
  SimplifiedProcessType,
  Uom,
  User,
  WiringProcessType,
} from "../../../models/model";
import {
  defaultBomLeveled,
  defaultFepLeveled,
  defaultIntegration,
  defaultJobBomLeveled,
  defaultJobBomLeveledView,
  defaultJobFep,
  defaultJobFepLeveled,
  defaultJobFepLeveledView,
  defaultJobIntegrationList,
  defaultJobIntegrationListView,
  defaultJobPanelCodeSerialNumberDone,
  defaultPanelCodeMachineProgram,
  defaultPanelCodeSerialNumber,
  defaultPanelCodeSerialNumberRect,
  initialBomDocument,
  initialDeadline,
  initialDeadlineDetail,
  initialDepartmentTemplate,
  initialFepDocument,
  initialJob,
  initialJobBPO,
  initialJobDJO,
  initialJobPanelType,
  initialJobPurchaseOrder,
  initialMachineProgramExclusion,
  initialMeetingTaskInCharge,
  initialPanelCode,
  initialPanelCodeDepartmentTemplate,
  initialPanelCodeDepartmentTemplateItem,
  initialPanelCodeRemark,
  initialPanelCodeSerialNumberManual,
  initialPanelCodeWiringProcessType,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";
import AsyncAutocomplete from "../../mini-components/CrudTable/components/AsyncAutocomplete";
import SyncAutocomplete from "../../mini-components/CrudTable/components/SyncAutocomplete";
import ColorHash from "color-hash";

enum JobTypeSelection {
  Cabinet = "Cabinet",
  Wiring = "Wiring",
}

enum JobPriority {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

const greyTheme = createMuiTheme({
  palette: { primary: { main: blueGrey[500] } },
});
const greenTheme = createMuiTheme({
  palette: { primary: { main: lightGreen[800] } },
});
const yellowTheme = createMuiTheme({
  palette: { primary: { main: orange[500] } },
});
const redTheme = createMuiTheme({ palette: { primary: { main: red[500] } } });

type JDPViewModeType = "Table" | "Block";
const jdpViewModeTypes = ["Table", "Block"] as JDPViewModeType[];

const OutstandingJobForm = () => {
  const history = useHistory();
  let { id } = useParams() as { id: string };
  const apiKey = localStorage.getItem("apiKey");
  const ctx = useContext(AppContext);

  const bomApprovals = useRef([] as any[]);
  const treeFilter = useRef(null as PpicIntegrationModuleType | null);
  const selectedJdpViewModeType = useRef("Block" as JDPViewModeType);
  const [autoAssignFilters, setAutoAssignFilters] = useState({
    department: null as ExtDepartment | null,
    skill: null as PpicSkill | null,
    intervalFrom: null as string | null,
    intervalTo: null as string | null,
    holidayTemplateStr: null as string | null,
    includeSaturday: false,
    filterOnlyPriority: null as boolean | null,
  });

  const [selectedPanelCodeJDPPresets, setSelectedPanelCodeJDPPresets] =
    useState<
      {
        panelCode: PanelCode;
        jdpPreset: PpicPanelCodeDepartmentTemplatePreset;
      }[]
    >([]);
  const jobPurchaseOrderDeleteIds = useRef([] as (number | null)[]);
  const jobBPODeleteIds = useRef([] as (number | null)[]);

  const [crmDjos, setCrmDjos] = useState(CrmDJOs.fromPartial({ djos: [] }));
  const [selectedPanelCodeForRemark, setSelectedPanelCodeForRemark] =
    useState<PanelCode | null>(null);
  const [showPanelCodeRemarkModal, setShowPanelCodeRemarkModal] =
    useState(false);
  const [dataGot, setDataGot] = useState(false);
  const [dataProductTreeGot, setDataProductTreeGot] = useState(false);
  const [jdpPresets, setJdpPrests] = useState(
    PpicPanelCodeDepartmentTemplatePresets.fromPartial({ presets: [] })
  );
  const [extWoCleanupIds, setExtWoCleanupIds] = useState<string[]>([]);
  const [showWorkOrderCleanupDetailModal, setShowWorkOrderCleanupDetailModal] =
    useState(false);
  const [workOrderCleanupDetail, setWorkOrderCleanupDetail] = useState(
    MeetingTaskListView.fromPartial({})
  );
  const [productTreeExtDepartment, setProductTreeExtDepartment] =
    useState<ExtDepartment | null>(null);
  const [productTreeExtUser, setProductTreeExtUser] = useState<ExtUser | null>(
    null
  );
  const [selectedJobProjectDetail, setSelectedJobProjectDetail] = useState(
    null as Project | null
  );

  const [showProjectDetail, setShowProjectDetail] = useState(false);
  const [includedModules, setIncludedModules] = useState<
    { panel: PanelCode; module: IntegrationModuleView }[]
  >([]);
  const [
    panelCodeDepartmentTemplateItemsLoading,
    setPanelCodeDepartmentTemplateItemsLoading,
  ] = useState(false);

  useEffect(() => {
    setDataGot(false);
    setDataProductTreeGot(false);
  }, [autoAssignFilters]);

  const [holidays, setHolidays] = useState(
    PpicHolidays.fromPartial({ holidays: [] })
  );

  const [showWorkOrderCleanupModal, setShowWorkOrderCleanupModal] =
    useState(false);

  const [
    selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
    setSelectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
  ] = useState<PanelCodeDepartmentTemplateItem | null>(null);

  const [
    selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItemPanelCode,
    setSelectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItemPanelCode,
  ] = useState<PanelCode | null>(null);

  const [
    autoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
    setAutoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
  ] = useState(true);

  const [
    assignedDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
    setAssignedDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
  ] = useState<string | null>(null);

  const [
    extUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
    setExtUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
  ] = useState<ExtUser | null>(null);

  const [
    openManualDeptTemplateWoAssignmentModal,
    setOpenManualDeptTemplateWoAssignmentModal,
  ] = useState(false);
  const panelCodeDepartmentTemplateItemsProto = useRef(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({
      departmentTemplateItems: [],
    })
  );
  const [autoAssignWoChecker, setAutoAssignWoChecker] = useState(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [
    selectedPanelCodeDepartmentTemplateItems,
    setSelectedPanelCodeDepartmentTemplateItems,
  ] = useState<PanelCodeDepartmentTemplateItem[]>([]);
  const [sendWoLoading, setSendWoLoading] = useState(false);
  const [jobs, setJobs] = useState(PpicJobs.fromPartial({ jobs: [] }));
  const [workOrdersFromLoading, setWorkOrdersFromLoading] = useState(false);
  const [fepDocuments, setFepDocuments] = useState<FepDocument[]>([]);
  const [multiFep, setMultiFep] = useState(true);
  const [multiBom, setMultiBom] = useState(true);
  const [jobFepDeleteIds, setjobFepDeleteIds] = useState<number[]>([]);
  const [jobIntegrationListDeleteIds, setjobIntegrationListDeleteIds] =
    useState<number[]>([]);

  const [fepLeveleds, setFepLeveleds] = useState<FepLeveled[]>([]);
  const [bomLeveleds, setBomLeveleds] = useState<BomLeveledRecursive[]>([]);
  const [departmentTemplateDeleteIds, setDepartmentTemplateDeleteIds] =
    useState<number[]>([]);

  const [rcemDepartments, setRcemDepartments] =
    useState<PpicRcemDepartments | null>(null);
  const [items, setItems] = useState<WarehouseItems | null>(null);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [extDepartments, setExtDepartments] = useState<ExtDepartment[]>([]);

  const [processTypes, setProcessTypes] = useState(
    PpicSimplifiedProcessTypes.fromPartial({
      processTypes: [],
    }) as PpicSimplifiedProcessTypes | null
  );

  const [machinesDb, setMachinesDb] = useState<Machine[]>([]);

  const [integrations, setIntegrations] = useState<IntegrationView[]>([]);
  const [
    departmentTemplateDetailDeleteIds,
    setDepartmentTemplateDetailDeleteIds,
  ] = useState<number[]>([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState<
    MeetingTaskListView | null | undefined
  >(null);
  const [rcemSubProcessSkills, setRcemSubProcessSkills] =
    useState<PpicRcemSubProcessSkills>(
      PpicRcemSubProcessSkills.fromPartial({ rcemSubProcessSkills: [] })
    );
  const [selectedIntegrationModule, setSelectedIntegrationModule] =
    useState<IntegrationModuleView | null>(null);

  const [filters, setFilters] = useState({
    panelCode: null as PanelCode | null,
    component: null as string | null,
    picComplete: null as string | null,
    spvComplete: null as string | null,
    date: makeDateString(new Date()) as string | null,
    treeCategory: null as PpicIntegrationModuleType | null,
  });
  const [detailedView, setDetailedView] = useState(false);

  const [
    filterGenerateDepartmentTemplate,
    setFilterGenerateDepartmentTemplate,
  ] = useState<DepartmentTemplateView[]>([]);

  const [viewType, setViewType] = useState<
    | "GeneralInfo"
    | "DeptTemplate"
    | "IntegrationList"
    | "IntegrationListFlattened"
    | "ProgramTree"
    | "Comments"
    | "SN Progress"
    | "EmptyTest"
    | "EmptyTest2"
    | "ProductBatch"
  >("GeneralInfo");
  const [deptTemplateViewType, setDeptTemplateViewType] = useState<
    "Manual Assign" | "Auto Assign" | "Auto Assign 2" | "Auto Assign 3"
  >("Manual Assign");
  const [newSaveStatus, setNewSaveStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [purchaseOrders, setPurchaseOrders] = useState<
    CrmPurchaseOrders | null | undefined
  >(null);
  const [bpos, setBpos] = useState<CrmBPOs | null | undefined>(null);

  const [purchaseOrdersLoading, setPurchaseOrdersLoading] = useState(false);
  const [showWarehouseItemDialog, setshowWarehouseItemDialog] = useState(false);
  const [searchWarehouseItem, setSearchWarehouseItem] = useState("");
  const [showWarehouseItem, setShowWarehouseItem] = useState(true);
  const selectedJdpEditMode = useRef("View");

  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    job: {
      job: { ...initialJob } as Job,
      fepDocuments: [],
      jobBomLeveleds: [],
      jobFepLeveleds: [],
      jobBomLeveledsMapped: [],
      jobMaterialRequests: [],
      jobPurchaseRequests: [],
      jobIntegrationList: [],
    } as JobMapped,
    jobTypes: [] as Array<JobType>,
    uoms: [] as Array<Uom>,
    requestStatus: RequestStatus.NotAsked,
    jobTypeSelections: [JobTypeSelection.Cabinet],
    jobPriority: JobPriority.Low,
    panelTypeQty: 0,
    selectedPanelType: { ...initialJobPanelType } as JobPanelType,
    jobPanelType: { ...initialJobPanelType } as JobPanelType,
    panelCode: { ...initialPanelCode } as PanelCode,
    panelCodeDeleteIds: [] as Array<number>,
    wiringProcessTypes: [] as Array<WiringProcessType>,
  });
  const [departmentTemplates, setDepartmentTemplates] = useState<
    DepartmentTemplateView[]
  >([]);
  const [templateCategories, setTemplateCategories] = useState(
    [] as DepartmentTemplateCategory[]
  );
  const [departmentTemplatesAll, setDepartmentTemplatesAll] = useState<
    DepartmentTemplateView[]
  >([]);

  useEffect(() => {
    if (state.job.job?.extProjectId) {
      (async () => {
        const p = await fetchProject({
          apiKey: ctx?.apiKey ?? "",
          id: state.job.job?.extProjectId,
        });

        if (p) {
          setSelectedJobProjectDetail(p);
        }
      })();
    }
  }, [state?.job.job?.extProjectId]);

  const [departmentTemplatesProto, setDepartmentTemplatesProto] =
    useState<PpicDepartmentTemplates>(
      PpicDepartmentTemplates.fromPartial({ templates: [] })
    );
  useEffect(() => {
    (async () => {
      const res = await fetch(
        `${process.env.REACT_APP_MEETING_URL}/projects/${state.job.job?.extProjectId}`,
        { headers: { authorization: ctx?.apiKey ?? "" } }
      );

      if (res.status !== 200) throw await res.text();

      setProject(await res.json());
    })();
  }, [state?.job.job?.extProjectId]);

  const [
    selectedPanelCodeDepartmentTemplateItem,
    setSelectedPanelCodeDepartmentTemplateItem,
  ] = useState<PanelCodeDepartmentTemplateItem | null | undefined>(null);
  const [
    selectedPanelCodeDepartmentTemplate,
    setSelectedPanelCodeDepartmentTemplate,
  ] = useState<PanelCodeDepartmentTemplate | null | undefined>(null);

  const [selectedPanelCode, setSelectedPanelCode] = useState<
    PanelCode | null | undefined
  >(null);

  const [showWorkOrderOpen, setShowWorkOrderOpen] = useState(false);
  const [workerSim, setWorkerSim] = useState(10);
  const [integrationModules, setIntegrationModules] =
    useState<PpicIntegrationModules | null>(
      PpicIntegrationModules.fromPartial({})
    );
  const [showCreateWoBulkDialog, setShowCreateWoBulkDialog] = useState(false);
  const [createWoBulkIntegrationList, setCreateWoBulkIntegrationList] =
    useState<JobIntegrationListView | null | undefined>(null);
  const [createWoBulkWorkOrders, setCreateWoBulkWorkOrders] = useState<
    MeetingTaskList[]
  >([]);
  const [machinePrograms, setMachinePrograms] = useState<MachineProgram[]>([]);
  const [machineProgramsProto, setMachineProgramsProto] =
    useState<PpicMachinePrograms>(
      PpicMachinePrograms.fromPartial({ machinePrograms: [] })
    );

  const [simplifiedProcessTypes, setSimplifiedProcessTypes] = useState<
    SimplifiedProcessType[]
  >([]);
  const [showTableProcesses, setShowTableProcesses] = useState(false);
  const [showClosedProducts, setShowClosedProducts] = useState(false);
  const [rcemDepts, setRcemDepts] = useState<
    PpicRcemDepartments | null | undefined
  >(null);
  const [workOrdersNewAdded, setWorkOrdersNewAdded] = useState<
    MeetingTaskListView[]
  >([]);
  const [machines, setMachines] = useState<PpicMachines | null | undefined>(
    null
  );
  const [jobsProtoSimple, setJobsProtoSimple] = useState<
    PpicJobs | null | undefined
  >(null);
  const [loadingPartNumberMatches, setLoadingPartNumberMatches] =
    useState(false);
  const [partNumberMatches, setPartNumberMatches] =
    useState<PpicPartNumberMatches | null>(null);
  const [expandJobIntegrationListUuids, setExpandJobIntegrationListUuids] =
    useState<string[]>([]);

  const v3TaskListsDialogDetail = useRef(MeetingTaskListsView.fromPartial({}));
  const selectedPanelCodeDepartmentTemplateItemsAutoAssignV3 = useRef(
    [] as PanelCodeDepartmentTemplateItem[]
  );
  const taskListsAutoAssignV3 = useRef(MeetingTaskListsView.fromPartial({}));
  const taskListsAutoAssignV3Former = useRef(
    MeetingTaskListsView.fromPartial({})
  );

  const autoAssignV3SelectedDept = useRef(null as ExtDepartment | null);
  const autoAssignV3From = useRef(null as string | null);
  const autoAssignV3To = useRef(null as string | null);

  const woDataGotV3 = useRef(false);
  const woDataLoadingV3 = useRef(false);

  const autoAssignLoadingV3 = useRef(false);

  const flattenedSubProcesses = rcemDepts?.departments
    ?.map(
      (d) =>
        d.processes
          ?.map((p) =>
            p.subProcesses?.map((sp) => ({
              subProcess: sp,
              process: p,
              dept: d,
            }))
          )
          .flat() ?? []
    )
    .flat();
  const [expandedPanelCodeDeptTemplates, setExpandedPanelCodeDeptTemplates] =
    useState<string[]>([]);
  const [expandedPanelCodeComments, setexpandedPanelCodeComments] = useState<
    string[]
  >([]);
  const [
    autoAssignDeptTemplateFilterParams,
    setAutoAssignDeptTemplateFilterParams,
  ] = useState({ deptId: null as number | null });

  const [woDataAllFromDate, setWoDataAllFromDate] = useState(
    makeDateString(new Date(new Date().getTime() - 86400000 * 7)).split("T")[0]
  );

  const expandedPOTable = useRef([] as number[]);

  const [woLoaded, setWoLoaded] = useState(false);
  const [
    jobPanelCodeSerialNumberDoneDeleteIds,
    setJobPanelCodeSerialNumberDoneDeleteIds,
  ] = useState<number[]>([]);
  const [
    includedPanelCodeDepartmentTemplates,
    setIncludedPanelCodeDepartmentTemplates,
  ] = useState<{ panelCode: PanelCode; dt: PanelCodeDepartmentTemplate }[]>([]);
  const [skills, setSkills] = useState(PpicSkills.fromPartial({}));
  const [selectedSkill, setSelectedSkill] = useState<PpicSkill | null>(null);
  const [machineProgramMachines, setMachineProgramMachines] = useState(
    PpicMachineProgramMachines.fromPartial({})
  );
  const [
    handleSaveJobWoSpecificDeptsShowModal,
    sethandleSaveJobWoSpecificDeptsShowModal,
  ] = useState(false);
  const [openSNRectModal, setOpenSNRectModal] = useState(false);

  const [itemSelectMode, setItemSelectMode] = useState(
    "Warehouse" as "Warehouse" | "MI"
  );

  const [selectedPanelCodeMIItem, setSelectedPanelCodeMIItem] =
    useState<PanelCode | null>(null);
  const [selectedRectSerialNumber, setSelectedRectSerialNumber] =
    useState<PanelCodeSerialNumber | null>(null);
  const [selectedRectSerialNumberManual, setSelectedRectSerialNumberManual] =
    useState<PanelCodeSerialNumberManual | null>(null);
  const [parallelSequentialMode, setParallelSequentialMode] = useState<
    "Parallel" | "Sequential"
  >("Sequential");
  const [project, setProject] = useState<Project | null>(null);
  const selectedBatch = useRef(null as number | null);

  const jobBomLeveledWos = useRef([] as MeetingTaskListView[]);

  useEffect(() => {
    console.log("jbl state", state.job.jobBomLeveleds, jobBomLeveledWos);

    state.job.jobBomLeveleds?.forEach(async (l) => {
      if (
        !jobBomLeveledWos.current.find(
          (w) => `${w.taskList?.extBomLeveledId}` === `${l.jobBomLeveled?.id}`
        )
      ) {
        const wos = await fetchWorkOrdersProtoMongo({
          apiKey: ctx?.apiKey ?? "",
          extBomLeveledId: l.jobBomLeveled?.bomLeveled?.id,
        });

        wos.taskLists.forEach((tL) => {
          jobBomLeveledWos.current.push(tL);
        });

        render();
      }
    });
  }, [state.job.jobBomLeveleds]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    setState({ ...state, requestStatus: RequestStatus.Loading });

    const parsedId = id ? parseInt(id) : NaN;

    const fetchedJob = await (async () => {
      if (!isNaN(parsedId) && state.requestStatus === RequestStatus.NotAsked) {
        return await fetchJob(parsedId);
      } else {
        return null;
      }
    })();

    fetchPpicMachineProgramMachinesData();
    fetchIntegrationsData();
    fetchProgramsData();
    fetchSimplifiedProcessTypesData();
    fetchRcemDepartmentsProtoData();
    fetchMachinesData();
    fetchPurchaseOrdersData();
    fetchBPOsData();
    fetchJobsData();
    fetchPartNumberMatchesData();
    // fetchItemsData();
    fetchPpicIntegrationModulesData();
    fetchExtDepartmentsData();
    // fetchTaskListsProtoDataAllFrom();
    fetchRcemDepartmentsData();
    fetchProgramsDataProto();
    fetchDepartmentTemplatesProtoData();
    fetchskillsData();
    fetchHolidaysData();

    fetchProcessTypesData();
    fetchMachinesData2();
    handleFetchDjoData();
    fetchPresetsData();
    fetchItemsData();
    fetchBomApprovalsData();

    const [
      jobTypes,
      wiringProcessTypes,

      fepDocumentsData,
      fepLeveledsData,
      bomLeveledsData,

      departmentTemplatesData,
      departmentTemplatesDataAll,
      deptTemplateCategories,
      workOrdersProtoData,
    ] = await Promise.all([
      fetchJobTypes(),
      fetchWiringProcessTypes(),
      [],
      [],
      [],
      fetchDepartmentTemplatesView({ apiKey: ctx?.apiKey ?? "" }),
      fetchDepartmentTemplatesView({
        apiKey: ctx?.apiKey ?? "",
        categoryAll: true,
      }),
      fetchDepartmentTemplateCategories({ apiKey: ctx?.apiKey ?? "" }),
      null,
      // fetchTaskListsProtoData(),
    ]);

    (async () => {
      const fepDocumentsData = await fetchFepDocumentsSimple({
        baseUrl: process.env.REACT_APP_BASE_URL ?? "",
        apiKey: apiKey ?? "",
      });

      setFepDocuments(fepDocumentsData);
    })();

    (async () => {
      const fepLeveledsData = await fetchFepLeveleds({
        apiKey: ctx?.apiKey ?? "",
      });

      setFepLeveleds(fepLeveledsData);
    })();

    (async () => {
      const bomLeveledsData = await fetchBomLeveleds({
        apiKey: ctx?.apiKey ?? "",
        recursive: false,
      });

      setBomLeveleds(bomLeveledsData);
    })();

    setState({
      ...state,
      job: fetchedJob ? fetchedJob.job : state.job,
      jobPriority: fetchedJob ? fetchedJob.jobPriority : state.jobPriority,
      jobTypeSelections: fetchedJob?.jobTypeSelections
        ? fetchedJob.jobTypeSelections
        : state.jobTypeSelections,
      jobTypes: jobTypes ? jobTypes : state.jobTypes,
      wiringProcessTypes: wiringProcessTypes
        ? wiringProcessTypes
        : state.wiringProcessTypes,
      requestStatus: RequestStatus.Success,
    });
    // setDepartmentTemplates(departmentTemplatesData);
    setDepartmentTemplatesAll(departmentTemplatesDataAll);
    setTemplateCategories(deptTemplateCategories);
    // setFilterGenerateDepartmentTemplate(departmentTemplatesData);
    handleFetchRcemProcessSkills();

    if (workOrdersProtoData) {
      workOrders.current = structuredClone(workOrdersProtoData);
      render();
      workOrdersFormer.current = structuredClone(workOrdersProtoData);
    }
    console.log("Job types state: ", wiringProcessTypes, jobTypes);
  };

  // If auto assign 2/3 is selected, fetch panel code dept template items
  useEffect(() => {
    (async () => {
      if (
        deptTemplateViewType === "Auto Assign 2" ||
        (deptTemplateViewType === "Auto Assign 3" &&
          panelCodeDepartmentTemplateItemsProto.current.departmentTemplateItems
            .length === 0)
      ) {
        setPanelCodeDepartmentTemplateItemsLoading(true);
        await fetchPanelCodeDeparmentTemplateItemsData();
        setPanelCodeDepartmentTemplateItemsLoading(false);
      }
    })();
  }, [deptTemplateViewType]);

  const handleFetchRcemProcessSkills = async () => {
    const fetched = await fetchRcemSubProcessSkillsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (fetched) {
      setRcemSubProcessSkills(fetched);
    }
  };
  const fetchPresetsData = async () => {
    setJdpPrests(
      await fetchPanelCodeDepartmentTemplatePresetsProto({
        apiKey: ctx?.apiKey ?? "",
      })
    );
  };
  const handleFetchDjoData = async () => {
    const fetched = await fetchExtCrmDjos({
      apiKey: ctx?.apiKey ?? "",
    });

    if (fetched) {
      setCrmDjos(fetched);
    }
  };

  const workOrders = useRef(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const workOrdersFormer = useRef(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );

  const workOrdersFrom = useRef(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const workOrdersFromFormer = useRef(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );

  // const [workOrdersFromFormer, setWorkOrdersFromFormer] = useState<
  //   MeetingTaskListsView | null | undefined
  // >(MeetingTaskListsView.fromPartial({ taskLists: [] }));

  const [customQtyPanelIds, setCustomQtyPanelIds] = useState<
    {
      panelCodeId: number;
      qty: number;
      jobIntegrationListId: number;
    }[]
  >([]);
  const [workOrderDeleteIds, setWorkOrderDeleteIds] = useState<string[]>([]);
  const [panelCodeSerialNumberDeleteIds, setpanelCodeSerialNumberDeleteIds] =
    useState<string[]>([]);

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const [
    panelCodeSerialNumberManualDeleteIds,
    setpanelCodeSerialNumberManualDeleteIds,
  ] = useState<number[]>([]);
  const [
    panelCodeSerialNumberRectDeleteIds,
    setpanelCodeSerialNumberRectDeleteIds,
  ] = useState<number[]>([]);

  const [meetingTaskInChargeDeleteIds, setMeetingTaskInChargeDeleteIds] =
    useState<string[]>([]);
  const [searchItemString, setSearchItemString] = useState("");
  const [selectedPanelCodeWarehouseItem, setSelectedPanelCodeWarehouseItem] =
    useState<PanelCode | null>(null);

  const [excludedTaskListModuleIds, setExcludedTaskListModuleIds] = useState<
    string[]
  >([]);

  const modulesFlattened = flattenModules(state?.job.jobIntegrationList ?? []);

  const sortedDeptTemplates = (() => {
    const departmentTemplatesSorted = [...departmentTemplates].filter(
      (t) =>
        !t.departmentTemplate?.hidden &&
        state.job.job?.panelCodes
          ?.flatMap((c) => c.panelCodeDepartmentTemplateItems)
          .find((pcdti) =>
            t.departmentTemplateItems.find(
              (dti) =>
                `${dti.departmentTemplateItem?.id}` ===
                `${pcdti?.departmentTemplateItem?.id}`
            )
          )
    );

    departmentTemplatesSorted.sort((a, b) => {
      if (a.departmentTemplate?.ordering && b.departmentTemplate?.ordering) {
        return a.departmentTemplate.ordering - b.departmentTemplate.ordering;
      } else {
        return 0;
      }
    });

    return departmentTemplatesSorted;
  })();

  const fetchHolidaysData = async () => {
    setHolidays(await fetchHolidaysProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchRcemDepartmentsData = async () => {
    setRcemDepartments(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  // const fetchWarehouseItemsData = async () => {
  //   setitems(
  //     await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" })
  //   );
  // };

  const fetchMachinesData2 = async () => {
    setMachinesDb(await fetchMachines({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchProcessTypesData = async () => {
    setProcessTypes(
      await fetchSimplifiedProcessTypesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchskillsData = async () => {
    const d = await fetchSkillsProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setSkills(d);
    }
  };
  const fetchDepartmentTemplatesProtoData = async () => {
    const d = await fetchDepartmentTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      setDepartmentTemplatesProto(d);
    }
  };

  const fetchProgramsData = async () => {
    setMachinePrograms(
      await fetchMachineProgramsNonProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchPanelCodeDeparmentTemplateItemsData = async () => {
    panelCodeDepartmentTemplateItemsProto.current =
      await fetchPanelCodeDepartmentTemplateItemsProto({
        apiKey: ctx?.apiKey ?? "",
      });

    render();
  };
  const fetchPpicMachineProgramMachinesData = async () => {
    setMachineProgramMachines(
      await fetchMachineProgramMachinesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchProgramsDataProto = async () => {
    const d = await fetchMachinePrograms({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      setMachineProgramsProto(d);
    }
  };

  const fetchPpicIntegrationModulesData = async () => {
    setIntegrationModules(
      await fetchIntegrationModulesProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchSimplifiedProcessTypesData = async () => {
    setSimplifiedProcessTypes(
      await fetchSimplifiedProcessTypes({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchItemsData = async () => {
    setItemsLoading(true);
    setItems(await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" }));
    setItemsLoading(false);
  };
  const fetchIntegrationsData = async () => {
    setIntegrations(await fetchIntegrations({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchRcemDepartmentsProtoData = async () => {
    setRcemDepts(
      await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchMachinesData = async () => {
    setMachines(await fetchMachinesProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchJobsData = async () => {
    setJobsProtoSimple(
      await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
      })
    );
  };
  const fetchPartNumberMatchesData = async () => {
    setLoadingPartNumberMatches(true);
    setPartNumberMatches(
      await fetchPartNumberMatchesProto({ apiKey: ctx?.apiKey ?? "" })
    );
    setLoadingPartNumberMatches(false);
  };

  const fetchPurchaseOrdersData = async () => {
    setPurchaseOrdersLoading(true);
    setPurchaseOrders(
      await fetchExtCrmPurchaseOrdersProto({ apiKey: ctx?.apiKey ?? "" })
    );
    setPurchaseOrdersLoading(false);
  };

  const fetchBPOsData = async () => {
    // setPurchaseOrdersLoading(true);
    setBpos(await fetchExtCrmBposProto({ apiKey: ctx?.apiKey ?? "" }));
    // setPurchaseOrdersLoading(false);
  };

  const fetchExtDepartmentsData = async () => {
    setExtDepartments(
      await fetchExtDepartments(
        process.env.REACT_APP_BASE_URL ?? "",
        ctx?.apiKey ?? ""
      )
    );
  };

  const holidaysFiltered = holidays.holidays.filter((h) =>
    autoAssignFilters.holidayTemplateStr
      ? `${h.templateName?.toLowerCase()}` ===
        `${autoAssignFilters.holidayTemplateStr.toLowerCase()}`
      : false
  );

  const fetchJob = async (id: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/${id}/full?type=jobInput&compose=true`,
        {
          headers: { authorization: state.apiKey },
        }
      );

      const job = (await response.json()) as JobMapped;

      // Map jobTypes to jobTypeSelections
      const newJobTypeSelections = job.job?.jobTypes
        ?.map((jobType) => {
          switch (jobType.name) {
            case "Cabinet":
              return JobTypeSelection.Cabinet;

            case "Wiring":
              return JobTypeSelection.Wiring;

            default:
              return null;
          }
        })
        .filter((jobType): jobType is JobTypeSelection => jobType !== null);

      // Map jobPriority to JobPriority
      const jobPriority = (() => {
        switch (job.job?.priority) {
          case "Low":
            return JobPriority.Low;

          case "Medium":
            return JobPriority.Medium;

          case "High":
            return JobPriority.High;

          default:
            return JobPriority.Low;
        }
      })();

      return {
        jobPriority: jobPriority,
        jobTypeSelections: newJobTypeSelections,
        job: job,
      };
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const fetchWiringProcessTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/wiringprocesstypes`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) throw "Failed fetching wiring process types";

      const wiringProcessTypesData: Array<WiringProcessType> =
        await response.json();

      return wiringProcessTypesData;
    } catch (e) {
      return null;
    }
  };

  const fetchTaskListsProtoData = async () => {
    return fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      extJobId: id,
      filterProductTreeOnly: true,
      filterProgramTreeOnly: true,
    });
  };
  const fetchTaskListsProtoDataAllFrom = async () => {
    setWorkOrdersFromLoading(true);

    const d = await fetchWorkOrdersProto({
      apiKey: ctx?.apiKey ?? "",
      from: woDataAllFromDate,
    });
    workOrdersFrom.current = d;
    workOrdersFromFormer.current = d;

    render();

    setWorkOrdersFromLoading(false);
  };

  const fetchBomApprovalsData = async () => {
    const d = await fetchExtEngBomApprovals({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      bomApprovals.current = d;
    }
    render();
  };

  const fetchJobTypes = async () => {
    try {
      const jobTypesResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobtypes`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      const jobTypesData: JobType[] = await jobTypesResponse.json();
      console.log("Job types data: ", jobTypesData);

      return jobTypesData;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const fetchUoms = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/uoms`, {
      headers: { authorization: apiKey ? apiKey : "" },
    });
    setState({ ...state, uoms: await response.json() });
  };

  // Helper functions
  const handleSave = async (params: {
    withWo: boolean;
    parallelWo?: boolean;
  }) => {
    // console.log("jblwo", jobBomLeveledWos.current);
    // return;

    const foundIncompleteApproval = jobBomLeveledWos.current.find((tL) =>
      tL.taskList?.meetingTasks.find((mt) => mt.status !== "COMPLETED")
    );

    if (!window.confirm("BOM still not approved. Continue?")) {
      return;
    }

    console.log("saving..., selected job type:", state.jobTypeSelections);

    const jobToSend = {
      ...state.job.job,
    } as Job;

    const mappedJobTypes = state.jobTypeSelections.map((jobTypeSelection) => {
      console.log("Job types selection:", jobTypeSelection, state.jobTypes);
      switch (jobTypeSelection) {
        case "Cabinet":
          return state.jobTypes.find(
            (jobType) => jobType.name === "Cabinet"
          ) as JobType;

        case "Wiring":
          return state.jobTypes.find(
            (jobType) => jobType.name === "Wiring"
          ) as JobType;

        default:
          return null;
      }
    });

    console.log("mapped job type:", mappedJobTypes);

    const mappedPriority = (() => {
      switch (state.jobPriority) {
        case JobPriority.Low:
          return "Low";

        case JobPriority.Medium:
          return "Medium";

        case JobPriority.High:
          return "High";
      }
    })();

    if (jobToSend?.jobTypes) {
      jobToSend.jobTypes = mappedJobTypes.filter(
        (jobType): jobType is JobType =>
          jobType !== null && jobType !== undefined
      );
    }

    jobToSend.priority = mappedPriority;

    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/jobs`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: apiKey ?? "",
        },
        body: JSON.stringify({ ...jobToSend, jobIntegrationList: [] } as Job),
      });

      if (response.status !== 201) throw await response.text();

      const jobResponse: Job | null = await (async () => {
        try {
          return await response.json();
        } catch (e) {
          return null;
        }
      })();

      // If job is new, save new deadlines
      if (id !== undefined && isNaN(parseInt(id))) {
        await Promise.all(
          [
            {
              name: "Engineering Mechanical Document",
              // details: [ "Punching", "Bending", "Welding", "Assembling", "Cabinet" ]
              details: ["Drawing", "Program", "BOM"],
            },
            {
              name: "Engineering Electrical Document",
              details: [
                "Layout",
                "SLD",
                "Zplant",
                "FW/LO",
                "Schema",
                "TL",
                "WL",
                "BOM Elec",
                "Cutout",
                "Nameplate",
                "Mimik",
              ],
            },
            {
              name: "Production Process",
              details: ["Punch", "Bend", "Weld", "Grind", "Coat", "Assembly"],
            },
            {
              name: "Wiring",
              details: [
                "Preparation Cable",
                "Preparation Panel",
                "Interconnection",
                "Busbar",
              ],
            },
            {
              name: "QC",
              details: [
                "Panel",
                "Point to Point",
                "Test On",
                "NC Mechanical",
                "NC Electrical",
                "Validasi",
              ],
            },
            {
              name: "Preparation Cable",
              details: ["Grouping", "Tubing/Crimping"],
            },
            {
              name: "Preparation Panel",
              details: [
                "Dinrail",
                "Cable/Support Duct",
                "Cutout Panel",
                "Component",
                "Labeling",
                "Mimik/Nameplate",
              ],
            },
            {
              name: "Busbar",
              details: ["Potong", "Bending", "Lubang", "Heatshrink", "Pasang"],
            },
            {
              name: "Interkoneksi",
              details: ["WK", "LR", "Interkoneksi", "Closing"],
            },
          ].map(async (deadline) => {
            // Post deadline
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/deadlines`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: apiKey ? apiKey : "",
                },
                body: JSON.stringify({
                  ...initialDeadline,
                  name: deadline.name,
                  uuid: uuidv4(),
                  job: {
                    ...initialJob,
                    id: jobResponse ? jobResponse.id : 0,
                  },
                }),
              }
            );

            const deadlineResponse: Deadline | null = await (async () => {
              try {
                return await response.json();
              } catch (e) {
                return null;
              }
            })();

            // Post deadline details
            return Promise.all(
              deadline.details.map(async (detail) => {
                await fetch(
                  `${process.env.REACT_APP_BASE_URL}/deadlinedetails`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      authorization: apiKey ? apiKey : "",
                    },
                    body: JSON.stringify({
                      ...initialDeadlineDetail,
                      uuid: uuidv4(),
                      name: detail,
                      deadline: {
                        ...initialDeadline,
                        id: deadlineResponse ? deadlineResponse.id : 0,
                      },
                    }),
                  }
                );
              })
            );
          })
        );
      }

      // Save panel codes
      if (jobResponse) {
        console.log("saving panel code:", state.job.job?.panelCodes);
        await Promise.all([
          ...(state.job.job?.panelCodes?.map(async (panelCode) => {
            console.log(
              "unincluded pc wpt ",
              state.wiringProcessTypes
                .filter(
                  (wpt) =>
                    !panelCode.panelCodeWiringProcessTypes?.find(
                      (pcwpt) => pcwpt.wiringProcessType?.name === wpt.name
                    )
                )
                .map((wpt) => ({
                  ...initialPanelCodeWiringProcessType,
                  wiringProcessType: wpt,
                }))
            );

            try {
              // Check panelcode departmenttemplates. If no task list ID, create and map/assign
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/panelcodes`,
                {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: apiKey ? apiKey : "",
                  },
                  body: JSON.stringify({
                    ...panelCode,
                    job: {
                      ...initialJob,
                      id: jobResponse?.id,
                    },

                    // Save panel code wiring process type if not exists
                    panelCodeWiringProcessTypes: [
                      ...(panelCode.panelCodeWiringProcessTypes ?? []),
                      ...state.wiringProcessTypes
                        .filter(
                          (wpt) =>
                            !panelCode.panelCodeWiringProcessTypes?.find(
                              (pcwpt) =>
                                pcwpt.wiringProcessType?.name === wpt.name
                            )
                        )
                        .map((wpt) => ({
                          ...initialPanelCodeWiringProcessType,
                          wiringProcessType: wpt,
                          includes: true,
                        })),
                    ],
                  } as PanelCode),
                }
              );

              if (response.status !== 200) throw await response.text();

              const panelCodeResponse: PanelCode | null | undefined =
                await response.json();

              if (panelCodeResponse && params.withWo) {
                panelCodeResponse.panelCodeDepartmentTemplates =
                  await Promise.all(
                    panelCodeResponse.panelCodeDepartmentTemplates?.map(
                      async (pcdt) => {
                        console.log("DEPT TEMPLATE", pcdt.departmentTemplate);

                        // Dont autosave task list id
                        if (false) {
                          // if (!pcdt.extTaskListId) {
                          //   console.log(
                          //     "[NO TASK LIST ID]",
                          //     pcdt.departmentTemplate?.name
                          //   );
                          //   console.log("starting fetch...");
                          //   const resp = await fetch(
                          //     `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-save`,
                          //     {
                          //       method: "POST",
                          //       headers: {
                          //         "content-type": "application/json",
                          //         authorization: ctx?.apiKey ?? "",
                          //       },
                          //       body: JSON.stringify(
                          //         MeetingTaskListView.toJSON(
                          //           MeetingTaskListView.fromPartial({
                          //             taskList: MeetingTaskList.fromPartial({
                          //               masterJavaBaseModel:
                          //                 MasterJavaBaseModel.fromPartial({
                          //                   uuid: uuidv4(),
                          //                 }),
                          //               // extInChargeId:
                          //               //   pcdt.departmentTemplate?.extHeadId &&
                          //               //   pcdt.departmentTemplate?.extHeadId !== 0
                          //               //     ? `${pcdt.departmentTemplate?.extHeadId}`
                          //               //     : undefined,
                          //               extPanelCodeId: panelCodeResponse?.id
                          //                 ? `${panelCodeResponse?.id}`
                          //                 : undefined,
                          //               extJobId: jobResponse.id
                          //                 ? `${jobResponse.id}`
                          //                 : undefined,
                          //               meetingTasks:
                          //                 departmentTemplates
                          //                   .find(
                          //                     (dt) =>
                          //                       `${dt.departmentTemplate?.id}` ===
                          //                       `${pcdt.departmentTemplate?.id}`
                          //                   )
                          //                   ?.departmentTemplateItems.filter(
                          //                     (dti) =>
                          //                       // Filter grey
                          //                       !panelCode.panelCodeDepartmentTemplateItems?.find(
                          //                         (pcdti) =>
                          //                           `${pcdti.departmentTemplateItem?.id}` ===
                          //                           `${dti.departmentTemplateItem?.id}`
                          //                       )?.excluded &&
                          //                       !dti.departmentTemplateItem
                          //                         ?.hidden
                          //                   )
                          //                   .map((dti) => {
                          //                     const foundPanelCodeDepartmentTemplateItem =
                          //                       panelCode.panelCodeDepartmentTemplateItems?.find(
                          //                         (pcdti) =>
                          //                           `${pcdti.departmentTemplateItem?.id}` ===
                          //                           `${dti.departmentTemplateItem?.id}`
                          //                       );
                          //                     return MeetingMeetingTask.fromPartial(
                          //                       {
                          //                         masterJavaBaseModel:
                          //                           MasterJavaBaseModel.fromPartial(
                          //                             { uuid: uuidv4() }
                          //                           ),
                          //                         extSubProcessId: dti
                          //                           .departmentTemplateItem?.id
                          //                           ? `${dti.departmentTemplateItem?.id}`
                          //                           : undefined,
                          //                         description:
                          //                           dti.departmentTemplateItem
                          //                             ?.name ?? undefined,
                          //                         start:
                          //                           foundPanelCodeDepartmentTemplateItem?.startDate
                          //                             ? foundPanelCodeDepartmentTemplateItem?.startDate?.split(
                          //                                 "T"
                          //                               )[0] ?? ""
                          //                             : undefined,
                          //                         meetingTaskTargetDates: [
                          //                           MeetingMeetingTaskTargetDate.fromPartial(
                          //                             {
                          //                               masterJavaBaseModel:
                          //                                 MasterJavaBaseModel.fromPartial(
                          //                                   {
                          //                                     uuid: v4(),
                          //                                   }
                          //                                 ),
                          //                               date: foundPanelCodeDepartmentTemplateItem?.endDate?.split(
                          //                                 "T"
                          //                               )[0],
                          //                             }
                          //                           ),
                          //                         ],
                          //                       }
                          //                     );
                          //                   }) ?? [],
                          //             }),
                          //           })
                          //         )
                          //       ),
                          //     }
                          //   );
                          //   console.log(
                          //     "[TASK LIST PROGRESS]",
                          //     pcdt.departmentTemplate?.name,
                          //     resp.status,
                          //     resp.statusText,
                          //     pcdt.extTaskListId
                          //   );
                          //   if (resp.status !== 200) throw await resp.text();
                          //   const taskList = MeetingTaskList.fromPartial(
                          //     await resp.json()
                          //   );
                          //   pcdt.extTaskListId =
                          //     taskList.masterJavaBaseModel?.id &&
                          //     taskList.masterJavaBaseModel.id !== `0`
                          //       ? isNaN(
                          //           parseInt(taskList.masterJavaBaseModel?.id)
                          //         )
                          //         ? null
                          //         : parseInt(taskList.masterJavaBaseModel?.id)
                          //       : null;
                          //   console.log(
                          //     "[TASK LIST SAVED]",
                          //     pcdt.departmentTemplate?.name,
                          //     taskList.masterJavaBaseModel?.id,
                          //     pcdt.extTaskListId
                          //   );
                          // }
                        }

                        return pcdt;
                      }
                    ) ?? []
                  );

                // re-save panelcode
                const response = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/panelcodes`,
                  {
                    method: "POST",
                    headers: {
                      "content-type": "application/json",
                      authorization: apiKey ? apiKey : "",
                    },
                    body: JSON.stringify({
                      ...panelCodeResponse,
                      job: {
                        ...initialJob,
                        id: jobResponse?.id,
                      },
                    }),
                  }
                );
              }
            } catch (e) {
              console.error(e);
            }
          }) ?? []),
          ...(state.job.fepDocuments?.map(async (j) => {
            try {
              await fetch(`${process.env.REACT_APP_BASE_URL}/jobfeps-save`, {
                method: "POST",
                headers: {
                  "content-type": "application/json",
                  authorization: apiKey ? apiKey : "",
                },
                body: JSON.stringify({
                  ...j,
                  job: jobResponse ?? null,
                } as JobFep),
              });
            } catch (e) {
              console.error(e);
            }
          }) ?? []),

          ...(state.job.jobFepLeveleds?.map(async (f) => {
            try {
              await fetch(
                `${process.env.REACT_APP_BASE_URL}/jobfepleveleds-save`,
                {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: apiKey ? apiKey : "",
                  },
                  body: JSON.stringify({
                    ...f.jobFepLeveled,
                    job: { ...initialJob, id: jobResponse?.id },
                  } as JobFepLeveled),
                }
              );
            } catch (e) {
              console.error(e);
            }
          }) ?? []),

          ...(params.withWo
            ? [saveWorkOrdersProductProgramTree(params.parallelWo)]
            : []),
        ]);

        // Save job bom leveleds single
        try {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/jobbomleveleds-save-bulk`,
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: apiKey ?? "",
              },
              body: JSON.stringify(
                state.job.jobBomLeveleds?.map((j) => {
                  const foundPanelCode = state.job.job?.panelCodes?.find(
                    (c) => `${c.id}` === `${j.jobBomLeveled?.extPanelCodeId}`
                  );
                  console.log("found panel code jbl:", foundPanelCode);
                  return {
                    ...j.jobBomLeveled,
                    qty: foundPanelCode?.qty
                      ? foundPanelCode.qty
                      : j.jobBomLeveled?.qty,

                    job: { ...initialJob, id: jobResponse?.id },
                  };
                }) ?? []
              ),
            }
          );
        } catch (e) {
          console.error(e);
        }

        // Save job integration list
        await Promise.all([
          ...(state.job.jobIntegrationList?.map(async (i) => {
            try {
              await fetch(
                `${process.env.REACT_APP_BASE_URL}/jobintegrationlist-save`,
                {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                    authorization: apiKey ? apiKey : "",
                  },
                  body: JSON.stringify({
                    ...i.jobIntegrationList,
                    job: { ...initialJob, id: jobResponse?.id },
                    // integration: {
                    //   ...defaultIntegration,
                    //   id: i.jobIntegrationList?.integration?.id ?? null,
                    // } as Integration,
                  } as JobIntegrationList),
                }
              );
            } catch (e) {
              console.error(e);
            }
          }) ?? []),
        ]);
      }

      // setState({ ...state, requestStatus: RequestStatus.Success });

      await Promise.all([
        // Delete panel codes
        ...state.panelCodeDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/panelcodes/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Delete jobpo
        ...jobPurchaseOrderDeleteIds.current.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/job-purchase-orders/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Save job fep documents
        ...jobFepDeleteIds.map(async (id) => {
          await fetch(`${process.env.REACT_APP_BASE_URL}/jobfeps/${id}/empty`, {
            method: "DELETE",
            headers: { authorization: apiKey ? apiKey : "" },
          });
        }),
        // Empty panelCodeDepartmentTemplates
        ...departmentTemplateDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplates/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty panelCodeDepartmentTemplateItems
        ...departmentTemplateDetailDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplateitems/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty jobintegrationlist
        ...jobIntegrationListDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/jobintegrationlist/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty taskList
        ...workOrderDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_MEETING_URL}/tasklists/${id}/empty-all`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty incharge
        ...meetingTaskInChargeDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_MEETING_URL}/meetingtaskincharges/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty panel code serial numbers
        ...panelCodeSerialNumberDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/panelcodeserialnumbers/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty panel code serial numbers manuals
        ...panelCodeSerialNumberManualDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/panelcodeserialnumbermanuals/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
        // Empty panel code serial numbers rect
        ...panelCodeSerialNumberRectDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/panelcodeserialnumberrects/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),

        // Empty panel code serial number done
        ...jobPanelCodeSerialNumberDoneDeleteIds.map(async (id) => {
          await fetch(
            `${process.env.REACT_APP_BASE_URL}/jobpanelcodeserialnumberdones/${id}/empty`,
            {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            }
          );
        }),
      ]);

      // Post save
      await Promise.all([
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/refresh-job-time/${jobResponse?.id}`
        ),
        await fetch(
          `${process.env.REACT_APP_BASE_URL}/refresh-serial-number-seq`
        ),
      ]);

      history.push("/jobs");
    } catch (e) {
      console.log(e);
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  const handleSendWoDepts = () => {
    sethandleSaveJobWoSpecificDeptsShowModal(true);
  };

  const saveWorkOrdersProductProgramTree = async (parallelWo?: boolean) => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const parallelWoUrl = parallelWo
        ? `/tasklists-proto-save-bulk?parallel=true`
        : `/tasklists-proto-save-bulk`;

      const allTaskLists = [
        ...(workOrders?.current?.taskLists ?? []),
        ...(workOrdersFrom?.current?.taskLists ?? []),
      ];

      console.log("all tasklists: ", allTaskLists);
      console.log(
        "Only dept template: ",
        allTaskLists.filter((tL) => tL.taskList?.extSubProcessId)
      );
      console.log("workOrders:", workOrders);

      const filteredHashTaskList = allTaskLists.filter((tL) =>
        tL.taskList?.masterJavaBaseModel?.id
          ? (() => {
              // Find hash. If different, save bulk
              const foundTL = [
                ...(workOrdersFormer.current?.taskLists ?? []),
                ...(workOrdersFromFormer.current?.taskLists ?? []),
              ].find(
                (tLx) =>
                  tLx.taskList?.masterJavaBaseModel?.id ===
                  tL.taskList?.masterJavaBaseModel?.id
              );

              console.log(
                `${tL.taskList.masterJavaBaseModel.id} ${foundTL?.taskList?.extIntegrationModuleQty}:${tL.taskList.extIntegrationModuleQty} | ${foundTL?.taskList?.extIntegrationModuleQtyActual}:${tL.taskList.extIntegrationModuleQtyActual}`
              );
              return foundTL
                ? murmur.murmur3(
                    JSON.stringify(MeetingTaskListView.toJSON(foundTL))
                  ) !==
                    murmur.murmur3(
                      JSON.stringify(MeetingTaskListView.toJSON(tL))
                    )
                : true;
            })()
          : // || !tL.taskList.deptCategory
            true
      );
      console.log("Filtered task hash list:", filteredHashTaskList);
      // return
      const resp = await fetch(
        `${process.env.REACT_APP_MEETING_URL}${parallelWoUrl}`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(
            MeetingTaskListsView.toJSON(
              MeetingTaskListsView.fromPartial({
                taskLists: filteredHashTaskList.map((tL) => ({
                  ...tL,
                  taskList: tL.taskList
                    ? {
                        ...tL.taskList,
                        deptCategory: TaskListDepartmentCategory.PROD,
                      }
                    : tL.taskList,
                })),
              })
            )
          ),
        }
      );

      // history.push("/jobs");
    } catch (e) {
      return null;
    } finally {
      // setState({ ...state, requestStatus: RequestStatus.Success });
    }
  };

  const handleChangeOutstandingJobName = (name: string) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, name: name } : state.job.job,
      },
    });
  };

  const handleChangeOutstandingJobPoStatus = (poStatus: PoStatus) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, poStatus: poStatus }
          : state.job.job,
      },
    });
  };

  const handleChangeJobDate = (jobDate: MaterialUiPickersDate) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, jobDate: makeDateString(jobDate as Date) }
          : state.job.job,
      },
    });
  };

  const handleChangeAssignedTo = (assignedTo: User) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, assignedTo: assignedTo }
          : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobIndividualEntity = (
    individualEntity: IndividualEntity
  ) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, individualEntity: individualEntity }
          : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobItem = (item: Item) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, item: item } : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobQty = (qty: string) => {
    try {
      setState({
        ...state,
        job: {
          ...state.job,
          job: state.job.job
            ? {
                ...state.job.job,
                qty: isNaN(parseInt(qty)) ? 0 : parseInt(qty),
              }
            : state.job.job,
        },
      });
    } catch (e) {
      console.log("Error parsing job.");
    }
  };

  const handleChangeOutstandingJobUom = (uom: Uom) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, uom: uom } : state.job.job,
      },
    });
  };
  const handleChangeJobPriority = (jobPriority: JobPriority) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, priority: jobPriority }
          : state.job.job,
      },
    });
  };
  const handleChangeJobTypeSelection = (jobTypeSelection: JobTypeSelection) => {
    // NO redux
    const newJobTypesSelectionsState = [...state.jobTypeSelections];

    if (newJobTypesSelectionsState.includes(jobTypeSelection)) {
      newJobTypesSelectionsState.splice(
        newJobTypesSelectionsState.findIndex(
          (selection) => selection === jobTypeSelection
        ),
        1
      );
    } else {
      newJobTypesSelectionsState.unshift(jobTypeSelection);
    }

    setState({ ...state, jobTypeSelections: newJobTypesSelectionsState });
  };

  const handleChangeOutstandingJobRal = (ral: Ral) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, ral: ral } : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobSales = (sales: User) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, sales: sales } : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobBom = (bom: Bom) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, bom: bom } : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobFep = (fep: Fep) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job ? { ...state.job.job, fep: fep } : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobReferenceType = (referenceType: string) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, referenceType: referenceType }
          : state.job.job,
      },
    });
  };
  const handleChangeOutstandingJobPoNumber = (poNumber: string) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? { ...state.job.job, poNumber: poNumber }
          : state.job.job,
      },
    });
  };

  const handleChangeProductionDeadline = (
    productionDeadline: MaterialUiPickersDate
  ) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? {
              ...state.job.job,
              productionDeadline: makeDateString(productionDeadline as Date),
            }
          : state.job.job,
      },
    });
  };

  const handleChangeDeliveryDeadline = (
    deliveryDeadline: MaterialUiPickersDate
  ) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? {
              ...state.job.job,
              deliveryDeadline: makeDateString(deliveryDeadline as Date),
            }
          : state.job.job,
      },
    });
  };

  const handleChangeOverallDeadline = (
    overallDeadline: MaterialUiPickersDate
  ) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? {
              ...state.job.job,
              deadline: makeDateString(overallDeadline as Date),
            }
          : state.job.job,
      },
    });
  };

  const fetchBoms = async (inputValue: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bomdocuments/search/simple?name=${inputValue}`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Error searching boms " + inputValue;

      const boms = await response.json();
      return boms.map((bom: any) => ({ label: bom?.name, value: bom }));
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const handleSelectBom = (bomDocument: BomDocument | null) => {
    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? {
              ...state.job.job,
              bomName: bomDocument ? bomDocument.name : state.job.job.bomName,
            }
          : state.job.job,
      },
    });
  };

  const fetchFeps = async (inputValue: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fepdocuments/search/simple?name=${inputValue}`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Error searching fep " + inputValue;

      const feps = await response.json();
      return feps.map((fep: any) => ({ label: fep?.name, value: fep }));
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const JobPriorityView = (props: {
    priorityState: JobPriority;
    priority: JobPriority;
  }) => {
    const JobPriorityButton = () => (
      <Button
        onClick={() => setState({ ...state, jobPriority: props.priority })}
        color="primary"
        variant={
          props.priorityState === props.priority ? "contained" : "outlined"
        }
      >
        {props.priority}
      </Button>
    );

    const priorityButtonColor = (() => {
      switch (props.priority) {
        case JobPriority.Low:
          return greenTheme;

        case JobPriority.Medium:
          return yellowTheme;

        case JobPriority.High:
          return redTheme;
      }
    })();

    return (
      <ThemeProvider theme={priorityButtonColor}>
        <JobPriorityButton />
      </ThemeProvider>
    );
  };

  const handleSelectFep = async (fepDocument: FepDocument | null) => {
    console.log("Selected: ", fepDocument);

    const qty = await (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/fepdocuments/search/name?name=${fepDocument?.name}`,
          {
            headers: {
              authorization: apiKey ? apiKey : "",
            },
          }
        );

        const latestFep: FepDocument | null | undefined = await response.json();

        if (response.status === 200 && latestFep) {
          return latestFep.unitQty;
        } else {
          return state.job.job?.qty;
        }
      } catch (e) {
        return state.job.job?.qty;
      }
    })();

    setState({
      ...state,
      job: {
        ...state.job,
        job: state.job.job
          ? {
              ...state.job.job,
              fepName: fepDocument ? fepDocument.name : "",
              qty: qty ?? state.job.job.qty,
            }
          : state.job.job,
      },
    });
  };

  const handleAddPanelTypeToList = (e: any) => {
    e.preventDefault();

    console.log("New job panel type:", state.jobPanelType);

    if (state.job) {
      const newState = { ...state };

      if (newState.job.job?.jobPanelTypes) {
        newState.job.job.jobPanelTypes = [
          ...(state.job.job?.jobPanelTypes ?? []),
          state.jobPanelType,
        ];
      }

      setState(newState);
    }
  };

  const handleAddPanelCode = () => {
    const newState = { ...state };

    const newPanelCodeWiringProcessTypes = state.wiringProcessTypes.map(
      (wiringProcessType) => {
        return {
          ...initialPanelCodeWiringProcessType,
          wiringProcessType: wiringProcessType,
          includes: true,
        };
      }
    );

    if (newState.job.job?.panelCodes) {
      newState.job.job.panelCodes = [
        {
          ...state.panelCode,
          uuid: uuidv4(),
          panelCodeWiringProcessTypes: newPanelCodeWiringProcessTypes,
        },
        ...newState.job.job.panelCodes,
      ];
    }

    setState(newState);
  };

  const handleDeletePanelCode = (panelCodeInput: PanelCode) => {
    const newState = { ...state };

    if (newState.job.job?.panelCodes) {
      newState.job.job.panelCodes = newState.job.job?.panelCodes.filter(
        (panelCode) => panelCode.uuid !== panelCodeInput.uuid
      );
    }

    newState.panelCodeDeleteIds = [
      panelCodeInput.id ?? 0,
      ...newState.panelCodeDeleteIds,
    ];

    setState(newState);
  };

  const handleChangePanelCodeType = (uuid: string, type: string) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCode) => panelCode.uuid === uuid
    );

    if (foundPanelCode) {
      foundPanelCode.type = type;
    }

    setState(newState);
  };

  const handleChangePanelCodeName = (uuid: string, name: string) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCode) => panelCode.uuid === uuid
    );

    if (foundPanelCode) {
      foundPanelCode.name = name;
    }

    setState(newState);
  };

  const handleChangePanelCodeSerialNumber = (
    uuid: string,
    serialNumber: string
  ) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCode) => panelCode.uuid === uuid
    );

    if (foundPanelCode) {
      foundPanelCode.serialNumber = serialNumber;
    }

    setState(newState);
  };

  const handleChangePanelCodeConnections = (
    uuid: string,
    connections: string
  ) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCode) => panelCode.uuid === uuid
    );

    if (foundPanelCode) {
      foundPanelCode.connections = isNaN(parseInt(connections))
        ? 0
        : parseInt(connections);
    }

    setState(newState);
  };

  const handleCheckPanelCodeWiringProcessType = (
    panelCode: PanelCode,
    processName: string
  ) => {
    const newState = { ...state };

    const foundPanelCodeWiringProcessType = newState.job.job?.panelCodes
      ?.find((panelCodeToFind) => panelCodeToFind.uuid === panelCode.uuid)
      ?.panelCodeWiringProcessTypes?.find(
        (panelCodeWiringProcessType) =>
          panelCodeWiringProcessType.wiringProcessType?.name === processName
      );

    if (foundPanelCodeWiringProcessType) {
      foundPanelCodeWiringProcessType.includes =
        !foundPanelCodeWiringProcessType.includes;

      // console.log("Foudn wiring process type for", panelCode, processName, foundPanelCodeWiringProcessType, foundPanelCodeWiringProcessType?.includes, !foundPanelCodeWiringProcessType?.includes);
    }

    console.log("job:", newState.job);
    setState(newState);
  };

  const handleCheckUncheckProcessGroup = (
    processGroup: string,
    panelCode: PanelCode
  ) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCodeToFind) => panelCodeToFind.uuid === panelCode.uuid
    );

    if (foundPanelCode) {
      const filteredWiringProcessTypes =
        foundPanelCode.panelCodeWiringProcessTypes
          ?.filter(
            (panelCodeWiringProcessType) =>
              panelCodeWiringProcessType.wiringProcessType
                ?.wiringProcessTypeGroup?.name === processGroup
          )
          .filter(
            (panelCodeWiringProcessType) => panelCodeWiringProcessType.includes
          );

      if ((filteredWiringProcessTypes?.length ?? 0) > 0) {
        foundPanelCode.panelCodeWiringProcessTypes
          ?.filter(
            (panelCodeWiringProcessType) =>
              panelCodeWiringProcessType.wiringProcessType
                ?.wiringProcessTypeGroup?.name === processGroup
          )
          .forEach((panelCodeWiringProcessType) => {
            panelCodeWiringProcessType.includes = false;
          });
      } else {
        foundPanelCode.panelCodeWiringProcessTypes
          ?.filter(
            (panelCodeWiringProcessType) =>
              panelCodeWiringProcessType.wiringProcessType
                ?.wiringProcessTypeGroup?.name === processGroup
          )
          .forEach((panelCodeWiringProcessType) => {
            panelCodeWiringProcessType.includes = true;
          });
      }
    }

    setState(newState);
  };

  const checkProcessGroup = (
    processGroup: string,
    panelCode: PanelCode
  ): boolean => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCodeToFind) => panelCodeToFind.uuid === panelCode.uuid
    );

    if (foundPanelCode) {
      return (
        (foundPanelCode.panelCodeWiringProcessTypes
          ?.filter(
            (panelCodeWiringProcessType) =>
              panelCodeWiringProcessType.wiringProcessType
                ?.wiringProcessTypeGroup?.name === processGroup
          )
          .filter(
            (panelCodeWiringProcessType) => panelCodeWiringProcessType.includes
          ).length ?? 0) > 0
      );
    } else {
      return false;
    }
  };

  const handleChangeNewPanelCodeDeadline = (e: any) =>
    setState({
      ...state,
      panelCode: {
        ...state.panelCode,
        deadline: e.target.value,
      },
    });

  const handleChangePanelCodeDeadline = (i: number, e: any) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (panelCode, index) => index === i
    );

    if (foundPanelCode) {
      foundPanelCode.deadline = e.target.value;
    }

    setState(newState);
  };

  const handleGenerateTemplate = async (
    pc?: PanelCode | null,
    config?: { restoreOnly: boolean }
  ) => {
    const departmentTemplates = filterGenerateDepartmentTemplate;

    const foundPreset = pc?.extJdpPresetId
      ? jdpPresets.presets.find(
          (p) => `${p.masterJavaBaseModel?.id}` === `${pc?.extJdpPresetId}`
        )
      : null;

    const filteredDeptTemplatesWithJdp = (
      foundPreset
        ? departmentTemplates.filter((t) =>
            t.departmentTemplateItems.find((i) =>
              foundPreset.items.find(
                (ix) =>
                  `${ix.departmentTemplateItemId}` ===
                  `${i.departmentTemplateItem?.id}`
              )
            )
          )
        : departmentTemplates
    ).filter(
      (dt) =>
        dt &&
        dt.departmentTemplate?.processDays &&
        dt.departmentTemplate.processDays > 0
    );

    console.log(
      `filtered dept templates with jdp ${filteredDeptTemplatesWithJdp}`
    );

    if (state.job.job?.jobDate) {
      let datePointer = new Date(`${state.job.job.jobDate}T00:00:00Z`);

      const mappedDepartmentTemplateItems = filteredDeptTemplatesWithJdp // if preset exists

        .map((dt) => {
          // If jdp exists and item does not, exclude

          const result = dt.departmentTemplateItems.map((dti, i) => {
            const startDateIncrement = 0;

            let endDateIncrement =
              Math.ceil(
                ((i + 1) / (dt.departmentTemplateItems.length ?? 0)) *
                  (dt.departmentTemplate?.processDays ?? 0)
              ) -
              Math.ceil(
                (i / (dt.departmentTemplateItems.length ?? 0)) *
                  (dt.departmentTemplate?.processDays ?? 0)
              );

            while (
              // sunday
              new Date(
                datePointer.getTime() + 86400000 * (endDateIncrement ?? 0)
              ).getDay() == 0 ||
              // saturday
              new Date(
                datePointer.getTime() + 86400000 * (endDateIncrement ?? 0)
              ).getDay() == 6
            ) {
              endDateIncrement++;
            }

            const newPCDTI = {
              ...initialPanelCodeDepartmentTemplateItem,
              departmentTemplateItem: dti.departmentTemplateItem,
              uuid: uuidv4(),
              startDate: datePointer.toISOString(),
              endDate: new Date(
                datePointer.getTime() + endDateIncrement * 86400000
              ).toISOString(),
              excluded:
                foundPreset &&
                !foundPreset.items.find(
                  (ix) =>
                    `${ix.departmentTemplateItemId}` ===
                    `${dti.departmentTemplateItem?.id}`
                )
                  ? true
                  : false,
            } as PanelCodeDepartmentTemplateItem;

            datePointer = new Date(
              datePointer.getTime() + 86400000 * (endDateIncrement ?? 0)
            );

            return newPCDTI;
          });

          return result;
        })
        .flat();
      console.log("[MAPPED]", mappedDepartmentTemplateItems);

      setState((state) => ({
        ...state,
        job: state.job
          ? {
              ...state.job,
              job: state.job.job
                ? {
                    ...state.job.job,
                    panelCodes: state.job.job.panelCodes
                      ? [
                          ...state.job.job.panelCodes.map((pcx) =>
                            pcx.uuid === pc?.uuid && pcx
                              ? {
                                  ...pcx,

                                  panelCodeDepartmentTemplates: [
                                    ...filteredDeptTemplatesWithJdp.map(
                                      (dt) =>
                                        ({
                                          ...initialPanelCodeDepartmentTemplate,
                                          departmentTemplate: {
                                            ...dt.departmentTemplate,
                                          },
                                          uuid: uuidv4(),
                                        } as PanelCodeDepartmentTemplate)
                                    ),
                                  ],
                                  panelCodeDepartmentTemplateItems:
                                    config?.restoreOnly
                                      ? pcx.panelCodeDepartmentTemplateItems
                                      : mappedDepartmentTemplateItems,
                                }
                              : pcx
                          ),
                        ]
                      : state.job.job.panelCodes,
                  }
                : state.job.job,
            }
          : state.job,
      }));

      // Set template delete id
      setDepartmentTemplateDeleteIds([
        ...departmentTemplateDeleteIds,
        ...(pc?.panelCodeDepartmentTemplates?.map((dt) => dt.id ?? 0) ?? []),
      ]);

      setDepartmentTemplateDetailDeleteIds([
        ...departmentTemplateDetailDeleteIds,
        ...(pc?.panelCodeDepartmentTemplateItems?.map((dti) => dti.id ?? 0) ??
          []),
      ]);
    }
  };

  const handleForwardDate = (props: {
    panelCode?: PanelCode | null;
    dti?: DepartmentTemplateItemView | null;
    date?: string | null;
    originalDate?: string | null;

    mode: "FROM" | "TO" | "COMPLETE" | "TOGGLE_HIDDEN";
  }) => {
    const newState = { ...state };

    const foundPanelCode = newState.job.job?.panelCodes?.find(
      (pcx) => pcx.uuid === props.panelCode?.uuid
    );

    if (props.mode === "TOGGLE_HIDDEN") {
      const foundDti = foundPanelCode?.panelCodeDepartmentTemplateItems?.find(
        (dtix) =>
          dtix.departmentTemplateItem?.id ===
          props.dti?.departmentTemplateItem?.id
      );

      if (foundDti) {
        foundDti.excluded = !foundDti.excluded;
      }
      setState(newState);
      return;
    }

    if (props.mode === "COMPLETE") {
      const foundDti = foundPanelCode?.panelCodeDepartmentTemplateItems?.find(
        (dtix) =>
          dtix.departmentTemplateItem?.id ===
          props.dti?.departmentTemplateItem?.id
      );

      if (foundDti) {
        if (props.date && props.date !== "") {
          foundDti.completedDate = `${props.date}T00:00:00Z`;
        } else {
          foundDti.completedDate = null;
        }

        setState(newState);
      }

      return;
    }

    const daysGap = Math.round(
      (new Date(props.date ?? "").getTime() -
        new Date(props.originalDate ?? "").getTime()) /
        86400000
    );

    // if (daysGap < 0) {
    //   alert("Cannot move date backwards.");
    //   return;
    // }

    if (foundPanelCode) {
      const foundDti = foundPanelCode.panelCodeDepartmentTemplateItems?.find(
        (dtix) =>
          dtix?.departmentTemplateItem?.id ===
          props.dti?.departmentTemplateItem?.id
      );

      if (foundDti && props.date) {
        switch (props.mode) {
          case "FROM":
            // todo
            foundDti.startDate = props.date;

            if (foundDti.endDate) {
              foundDti.endDate = new Date(
                new Date(foundDti.endDate).getTime() + 86400000 * daysGap
              ).toISOString();
            }

            break;
          case "TO":
            // foundDti.startDate = props.date;
            foundDti.endDate = props.date;
            break;
          default:
          // pass
        }
      }

      if (parallelSequentialMode === "Sequential") {
        if (
          !window.confirm(
            `Really move ${
              props.dti?.departmentTemplateItem?.name
            } date?\n- Original: ${(() => {
              try {
                return makeReadableDateStringIntlUtc(
                  new Date(props.originalDate ?? "")
                );
              } catch (e) {
                return "";
              }
            })()}\n- Modified: ${(() => {
              try {
                return makeReadableDateStringIntlUtc(
                  new Date(`${props.date}T00:00:00Z`)
                );
              } catch (e) {
                return "";
              }
            })()} (gap: ${daysGap})\nNote that this will also modify ${
              foundPanelCode.panelCodeDepartmentTemplateItems?.length ?? 0
            } successor dates.`
          )
        ) {
          return;
        }

        const departmentTemplateItemsFlattened = departmentTemplates
          .filter((dt) =>
            foundPanelCode.panelCodeDepartmentTemplates?.find(
              (dtx) => dtx.departmentTemplate?.id === dt.departmentTemplate?.id
            )
          )
          .map(
            (dt) =>
              dt.departmentTemplateItems.map(
                (dti) => dti.departmentTemplateItem
              ) ?? []
          )
          .flat();

        const departmentTemplateItemsSorted =
          departmentTemplateItemsFlattened?.filter(
            (_, i) =>
              i >
              departmentTemplateItemsFlattened.findIndex(
                (dtix) => dtix?.id === props.dti?.departmentTemplateItem?.id
              )
          );

        foundPanelCode.panelCodeDepartmentTemplateItems
          ?.filter((dti) =>
            departmentTemplateItemsSorted?.find(
              (dtix) => dtix?.id === dti.departmentTemplateItem?.id
            )
          )
          .forEach((dti) => {
            if (dti.startDate) {
              dti.startDate = new Date(
                Math.round(
                  new Date(dti.startDate).getTime() + daysGap * 86400000
                )
              ).toISOString();
            }

            if (dti.endDate) {
              dti.endDate = new Date(
                Math.round(new Date(dti.endDate).getTime() + daysGap * 86400000)
              ).toISOString();
            }
          });
      }

      setState(newState);
    }
  };

  const deptTasks = [
    {
      category: taskListDepartmentCategoryToJSON(
        TaskListDepartmentCategory.PPC
      ),
      tasks: ["Shortage List", "Resource Plan", "Job List"],
    },
    {
      category: taskListDepartmentCategoryToJSON(
        TaskListDepartmentCategory.SALES
      ),
      tasks: ["Requirements", "Documents", "Materials"],
    },
    {
      category: taskListDepartmentCategoryToJSON(
        TaskListDepartmentCategory.PROJECT
      ),
      tasks: [
        "Requirements",
        "Approvals",
        "Documents",
        "Materials",
        "Permits",
        "Forwarders",
        "Subcon",
      ],
    },
  ];

  const TreeAutoAssignPreview = (props: { type: "Product" | "Program" }) => {
    return (
      <>
        <AutoAssignIntervals />

        {props.type === "Product" ? (
          <>
            {" "}
            <div>
              <Select
                options={ctx?.extDepartments}
                placeholder="Select Department..."
                getOptionLabel={(d) => `${d.name}`}
                onChange={(v) => {
                  const val = v as ExtDepartment;

                  setProductTreeExtDepartment(val);
                  setProductTreeExtUser(null);
                  setDataProductTreeGot(false);
                }}
              />
            </div>{" "}
            <div>
              <Select
                options={ctx?.extUsers}
                placeholder="Select user..."
                getOptionLabel={(d) => `${d.username}`}
                onChange={(v) => {
                  const val = v as ExtUser;

                  setProductTreeExtDepartment(null);
                  setProductTreeExtUser(val);
                  setDataProductTreeGot(false);
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="d-flex">
          {autoAssignFilters.intervalFrom && autoAssignFilters.intervalTo ? (
            <>
              {" "}
              <button
                className="btn btn-sm btn-success"
                onClick={async () => {
                  await handleGetAutoAssignV2Data();
                  setDataProductTreeGot(true);
                }}
              >
                Get Data
              </button>{" "}
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ height: "65vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "Name", ...datesIntervalFrom].map((h) => {
                return (
                  <th
                    className="bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                );
              })}
            </tr>
            {ctx?.extUsers
              .filter((u) => {
                if (props.type === "Product") {
                  return productTreeExtDepartment
                    ? productTreeExtDepartment &&
                        `${productTreeExtDepartment?.id}` ===
                          `${u.departmentId}`
                    : productTreeExtUser
                    ? `${productTreeExtUser.id}` === `${u.id}`
                    : false;
                } else if (props.type === "Program") {
                  return [
                    ...new Set(
                      processTypes?.processTypes
                        .map((t) => t.pics.map((p) => p.extUserId))
                        .flat()
                    ),
                  ].find((p) => `${p}` === `${u.id}`);
                } else {
                  return false;
                }
              })
              .map((u, i) => {
                return (
                  <>
                    <tr>
                      {" "}
                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">{u.username}</td>
                      {datesIntervalFrom.map((d) => {
                        const wos = filterWorkOrderByDate(
                          autoAssignWoChecker,
                          d,
                          u
                        );
                        const wosTotalTime =
                          wos?.reduce(
                            (acc, tL) =>
                              acc +
                              (getWorkOrderTimeMinutes(
                                tL.taskList,
                                rcemDepartments,
                                integrationModules,
                                machineProgramsProto,
                                departmentTemplatesProto,
                                panelCodeDepartmentTemplateItemsProto.current,
                                u.id,
                                d
                                // true
                              ).totalHours ?? 0),
                            0.0
                          ) ?? 0;

                        return (
                          <th
                            className="border border-dark"
                            style={{
                              backgroundColor: chroma
                                .scale([
                                  "lightsalmon",
                                  "yellow",
                                  "lightgreen",
                                  "lightblue",
                                ])(wosTotalTime / 8)
                                .hex(),
                            }}
                          >
                            {wos?.length} ({wosTotalTime.toFixed(1)})
                          </th>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      </>
    );
  };

  const handleSendWO = async () => {
    try {
      const data = MeetingTaskListsView.fromPartial({
        taskLists: deptTasks.map((d) => {
          const deptUsers = ctx?.extUsers.filter((u) =>
            ctx.extDepartments
              .filter((dx) => dx.deptCategory === d.category)
              .find((dx) => dx.id === u.departmentId)
          );

          const foundWoDept = workOrders?.current?.taskLists.find(
            (tL) =>
              `${tL.taskList?.extJobId}` === `${state.job.job?.id}` &&
              tL.taskList?.deptCategory ===
                taskListDepartmentCategoryFromJSON(d.category)
          );

          return foundWoDept
            ? foundWoDept
            : MeetingTaskListView.fromPartial({
                taskList: MeetingTaskList.fromPartial({
                  deptCategory: taskListDepartmentCategoryFromJSON(d.category),
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                  extJobId: state.job.job?.id
                    ? `${state.job.job?.id}`
                    : undefined,
                  meetingTasks: d.tasks.map((t) =>
                    MeetingMeetingTask.fromPartial({
                      description: t,
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      start: makeDateString(new Date()),
                      meetingTaskInCharges: deptUsers?.map((u) =>
                        MeetingMeetingTaskInCharge.fromPartial({
                          extUserId: u.id && u.id !== 0 ? `${u.id}` : undefined,
                          masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                            uuid: v4(),
                          }),
                        })
                      ),
                      meetingTaskTargetDates: [
                        MeetingMeetingTaskTargetDate.fromPartial({
                          date: makeDateString(
                            new Date(new Date().getTime() + 86400000 * 14)
                          ),
                          masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                            uuid: v4(),
                          }),
                        }),
                      ],
                    })
                  ),
                }),
              });
        }),
      });
      console.log("wo to send", data);

      if (window.confirm("Really send WO? This will refresh the page.")) {
        setSendWoLoading(true);
        const resp = await fetch(
          `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-save-bulk`,
          {
            method: "post",
            headers: {
              "content-type": "application/json",
              authorization: ctx?.apiKey ?? "",
            },
            body: JSON.stringify(MeetingTaskListsView.toJSON(data)),
          }
        );
        setSendWoLoading(false);
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const autoAssignV2Executor = async (
    panelCodeDepartmentTemplateItems: PanelCodeDepartmentTemplateItem[],
    usersParam: {
      worker: PpicSkillWorker;
      user: ExtUser | undefined;
    }[]
  ) => {
    if (!state.job.job?.id) {
      alert("Please save job first before auto-assigning dept template.");
      return;
    }

    const newWorkOrdersChecker = MeetingTaskListsView.fromPartial(
      JSON.parse(
        JSON.stringify(MeetingTaskListsView.toJSON(autoAssignWoChecker))
      )
    );
    const newWorkOrdersToAdd = MeetingTaskListsView.fromPartial({
      taskLists: [],
    });

    // alert("dummy add");

    let newWorkOrderCounter = 0;
    let noRcem = 0;
    let noSkill = 0;

    console.log(
      "checking: eligible",
      selectedPanelCodeDepartmentTemplateItems.filter(
        (pcdti) =>
          rcemDepartments?.departments
            ?.map((d) =>
              d.processes
                .map((p) =>
                  p.subProcesses.map((sp) => ({
                    dept: d,
                    process: p,
                    subProcess: sp,
                  }))
                )
                .flat()
            )
            .flat()
            .find(
              (sp) =>
                `${sp.subProcess.masterJavaBaseModel?.id}` ===
                `${pcdti.departmentTemplateItem?.extRcemSubProcessId}`
            )?.subProcess.skillId
      )
    );

    // Make panelcodedepartmenttemplateitem unique
    const panelCodeDepartmentTemplateItemsUniqPre = [
      ...new Set(
        panelCodeDepartmentTemplateItems.map((i) => {
          const foundPanelCode = state.job.job?.panelCodes?.find((c) =>
            c.panelCodeDepartmentTemplateItems?.find(
              (ti) => `${ti.id}` === `${i.id}`
            )
          );

          return `${foundPanelCode?.id}:${i.departmentTemplateItem?.id}`;
        })
      ),
    ];

    console.log(`eligible pre:`, panelCodeDepartmentTemplateItemsUniqPre);

    const panelCodeDepartmentTemplateItemsUniq =
      panelCodeDepartmentTemplateItemsUniqPre
        .map((id) =>
          panelCodeDepartmentTemplateItems.find((ix) => {
            const foundPanelCode = state.job.job?.panelCodes?.find((c) =>
              c.panelCodeDepartmentTemplateItems?.find(
                (ti) => `${ti.id}` === `${ix.id}`
              )
            );
            return (
              `${foundPanelCode?.id}` === id.split(":")[0] &&
              `${ix.departmentTemplateItem?.id}` === id.split(":")[1]
            );
          })
        )
        .filter((pcdti) => pcdti)
        .map((pcdti) => pcdti as PanelCodeDepartmentTemplateItem);

    console.log(`eligible uniq:`, panelCodeDepartmentTemplateItemsUniq);

    panelCodeDepartmentTemplateItemsUniq.forEach((pcdti, i) => {
      console.log(`eligible no ${i + 1}`);
      // If WO task found with panel code dept template item, don't fill
      if (
        autoAssignWoChecker.taskLists.find((tL) =>
          tL.taskList?.meetingTasks.find(
            (mt) =>
              `${mt.extPanelCodeDepartmentTemplateItemId}` === `${pcdti.id}`
          )
        )
      ) {
        return;
      }

      // If WO task (in job) found with panel code dept template item, don't fill
      if (
        workOrders?.current?.taskLists.find((tL) =>
          tL.taskList?.meetingTasks.find(
            (mt) =>
              `${mt.extPanelCodeDepartmentTemplateItemId}` === `${pcdti.id}`
          )
        )
      ) {
        return;
      }

      // Check if eligible

      // Check if has RCEM
      // if (usersParam.length <= 0) {
      if (!pcdti.departmentTemplateItem?.extRcemSubProcessId) {
        noRcem++;
        return;
      }
      // }

      // Check if has skill
      // If users is defined, no need to check skill
      const foundSkillId = rcemDepartments?.departments
        .map((d) =>
          d.processes
            .map((p) =>
              p.subProcesses.map((sp) => ({
                dept: d,
                process: p,
                subProcess: sp,
              }))
            )
            .flat()
        )
        .flat()
        .find(
          (sp) =>
            `${sp.subProcess.masterJavaBaseModel?.id}` ===
            `${pcdti.departmentTemplateItem?.extRcemSubProcessId}`
        )?.subProcess.skillId;

      if (usersParam.length <= 0) {
        if (!foundSkillId) {
          noSkill++;
          return;
        }
      }

      const users =
        usersParam.length > 0
          ? usersParam
          : skills.skills
              .find((s) => `${s.masterJavaBaseModel?.id}` === `${foundSkillId}`)
              ?.skillWorkers.map((w) => ({
                worker: w,
                user: ctx?.extUsers.find((u) => `${u.id}` === `${w.extUserId}`),
              }))
              .filter(
                (d) =>
                  d.user &&
                  (autoAssignFilters.filterOnlyPriority !== null
                    ? autoAssignFilters.filterOnlyPriority
                      ? d.worker.isPriority
                      : !d.worker.isPriority
                    : true)
              ) ?? [];

      // Check from starting date

      if (!autoAssignFilters.intervalFrom) {
        console.log("Date pointer not found.");
        return;
      }

      let datePointer = new Date(autoAssignFilters.intervalFrom as string);

      // Loop user date
      let woSkillMatrixAssigned = false;

      console.log(
        "checking users",
        pcdti.departmentTemplateItem.name,
        users?.map((u) => u.user?.username)
      );

      if ((users?.length ?? 0) === 0) {
        console.log("No users eligible ");
        return;
      }

      // Check with RCEM time
      while (!woSkillMatrixAssigned) {
        // Filter saturday, if not included
        if (
          new Date(datePointer.getTime()).getDay() === 6 &&
          !autoAssignFilters.includeSaturday
        ) {
          datePointer = new Date(datePointer.getTime() + 86400000);
          continue;
        }

        // Filter sunday
        if (new Date(datePointer.getTime()).getDay() === 0) {
          datePointer = new Date(datePointer.getTime() + 86400000);
          continue;
        }

        for (let u of users ?? []) {
          const filteredWos = filterWorkOrderByDate(
            newWorkOrdersChecker,
            makeDateString(datePointer),
            u.user
          );
          const woTimeCheckedRaw =
            filteredWos?.map((tL) => ({
              taskList: tL,
              time: getWorkOrderTimeMinutes(
                tL.taskList,
                rcemDepartments,
                integrationModules,
                machineProgramsProto,
                departmentTemplatesProto,
                panelCodeDepartmentTemplateItemsProto.current,
                u.user?.id,
                makeDateString(datePointer)
                // true
              ),
            })) ?? [];

          console.log("checking: wos", woTimeCheckedRaw);

          const woTimeChecked = woTimeCheckedRaw.reduce(
            (acc, d) => acc + d.time.totalHours,
            0.0
          );

          console.log(
            "checking",
            u.user?.username,
            makeDateString(datePointer),
            pcdti.id
          );

          if (woTimeChecked < 8) {
            console.log(
              "Checking: Got WO time:",
              woTimeChecked,
              u.user?.username,
              pcdti.id
            );

            const foundPanelCode = state.job.job?.panelCodes?.find((c) =>
              c.panelCodeDepartmentTemplateItems?.find(
                (pcdtix) => `${pcdtix.id}` === `${pcdti.id}`
              )
            );

            const deptTemplate = departmentTemplates.find((t) =>
              t.departmentTemplateItems.find(
                (i) =>
                  `${i.departmentTemplateItem?.id}` ===
                  `${pcdti.departmentTemplateItem?.id}`
              )
            );

            const newWorkOrderToAdd = MeetingTaskListView.fromPartial({
              taskList: MeetingTaskList.fromPartial({
                extInChargeId: deptTemplate?.departmentTemplate?.extHeadId
                  ? `${deptTemplate?.departmentTemplate?.extHeadId}`
                  : undefined,
                extJobId: state.job.job?.id
                  ? `${state.job.job?.id}`
                  : undefined,
                extPanelCodeId: foundPanelCode
                  ? `${foundPanelCode.id}`
                  : undefined,
                meetingTasks: [
                  MeetingMeetingTask.fromPartial({
                    meetingTaskInCharges: [
                      MeetingMeetingTaskInCharge.fromPartial({
                        extUserId: u.user?.id ? `${u.user.id}` : undefined,
                        masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                          uuid: v4(),
                        }),
                      }),
                    ],
                    meetingTaskTargetDates: [
                      MeetingMeetingTaskTargetDate.fromPartial({
                        date: makeDateString(datePointer),
                        masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                          uuid: v4(),
                        }),
                      }),
                    ],
                    start: makeDateString(datePointer),
                    extPanelCodeDepartmentTemplateItemId: pcdti.id
                      ? `${pcdti.id}`
                      : undefined,
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: v4(),
                    }),
                  }),
                ],
                masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                  uuid: v4(),
                }),
              }),
            });

            newWorkOrdersChecker.taskLists.push(
              JSON.parse(JSON.stringify(newWorkOrderToAdd))
            );
            newWorkOrdersToAdd.taskLists.push(
              JSON.parse(JSON.stringify(newWorkOrderToAdd))
            );

            newWorkOrderCounter++;
            woSkillMatrixAssigned = true;
            return;
          } else {
            console.log(
              "Checking: Unsatisfied",
              u.user?.username,
              makeDateString(datePointer),
              pcdti.id,
              woTimeChecked
            );
          }
        }

        datePointer = new Date(datePointer.getTime() + 86400000);
        console.log("checking: looping");
      }
    });

    setAutoAssignWoChecker(newWorkOrdersChecker);

    if (workOrders) {
      workOrders.current.taskLists = [
        ...workOrders.current.taskLists,
        ...newWorkOrdersToAdd.taskLists,
      ];
      // setWorkOrders({
      //   ...workOrders,
      //   taskLists: [...workOrders.taskLists, ...newWorkOrdersToAdd.taskLists],
      // });
      render();

      setWorkOrdersNewAdded([...newWorkOrdersToAdd.taskLists]);
    }
    alert(`Stats:\n
    - New WO: ${newWorkOrderCounter}\n
    - No RCEM: ${noRcem}\n
    - No skill: ${noSkill}
    `);
  };
  const autoAssignProgramTreeExecutor = async (pc: PanelCode) => {
    // if (!productTreeExtDepartment) {
    //   alert("Please choose department first before auto assigning.");
    //   return;
    // }

    // const users = ctx?.extUsers.filter(
    //   (u) => `${u.departmentId}` === `${productTreeExtDepartment.id}`
    // );

    window.alert(`Executing program tree tree auto assign...`);
    console.log(pc);
    console.log(pc.panelCodeMachinePrograms);
    console.log(pc.machinePrograms);

    const newAutoAssignWoChecker = autoAssignWoChecker.taskLists;
    const newWorkOrders: MeetingTaskListView[] = [];

    if (!autoAssignFilters.intervalFrom) {
      window.alert("Please select interval first.");
      return;
    }

    if (autoAssignFilters.intervalFrom) {
      let datePointer = new Date(autoAssignFilters.intervalFrom);

      pc.machinePrograms?.forEach((p) => {
        console.log(processTypes?.processTypes);
        processTypes?.processTypes.forEach((t) => {
          const users = [...new Set(t.pics.map((p) => p.extUserId))]
            .map((id) => ctx?.extUsers.find((u) => `${u.id}` === `${id}`))
            .filter((u) => u)
            .map((u) => u as ExtUser);

          const newTaskList = MeetingTaskListView.fromPartial({
            taskList: MeetingTaskList.fromPartial({
              masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                uuid: v4(),
              }),
              extProgramId: p?.id ? `${p?.id}` : undefined,
              extProgramRunQty: p.qtyRun ?? undefined,
              extProcessTypeId: t.masterJavaBaseModel?.id,

              extJobId: state.job.job?.id ? `${state.job.job?.id}` : undefined,
              extPanelCodeId: pc.id ? `${pc.id}` : undefined,

              // extMachineId:
              //   foundMachineProgramMachine?.id,
              extInChargeId: t.extSpvId ? `${t.extSpvId}` : undefined,

              meetingTasks: [
                MeetingMeetingTask.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                  meetingTaskInCharges: [
                    MeetingTaskListInCharge.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      extUserId: users?.[0]?.id
                        ? `${users?.[0]?.id}`
                        : undefined,
                    }),
                  ],
                  start: makeDateString(datePointer),
                  meetingTaskTargetDates: [
                    MeetingMeetingTaskTargetDate.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      date: makeDateString(datePointer),
                    }),
                  ],
                }),
              ],
            }),
          });

          console.log(newTaskList);

          newWorkOrders.push(newTaskList);
          newAutoAssignWoChecker.push(newTaskList);
        });
      });

      setAutoAssignWoChecker({
        ...autoAssignWoChecker,
        taskLists: newAutoAssignWoChecker,
      });

      if (workOrders) {
        workOrders.current.taskLists = [
          ...workOrders.current.taskLists,
          ...newWorkOrders,
        ];
        render();
        // setWorkOrders({
        //   ...workOrders,
        //   taskLists: [...workOrders.taskLists, ...newWorkOrders],
        // });
      }
    }
  };
  const autoAssignProductTreeExecutor = async (
    integrationModulesParam: IntegrationModuleView[],
    panelCode: PanelCode,
    iL: JobIntegrationListView
  ) => {
    if (!productTreeExtDepartment && !productTreeExtUser) {
      alert("Please choose department or user first before auto assigning.");
      return;
    }

    const users = ctx?.extUsers.filter((u) =>
      productTreeExtDepartment
        ? `${u.departmentId}` === `${productTreeExtDepartment?.id}`
        : productTreeExtUser
        ? `${u.id}` === `${productTreeExtUser.id}`
        : false
    );

    window.alert(
      `Executing product tree auto assign...\nUsers: ${users
        ?.map((u) => u.username)
        .join(",")}\n\nOrders:\n${integrationModulesParam
        .map(
          (m) =>
            ` -${m.integrationModule?.type}:${m.integrationModule?.name}:x${m.integrationModule?.qty}:${m.integrationModule?.timeProcessMins} min`
        )
        .join("\n")}`
    );

    if (autoAssignFilters.intervalFrom) {
      let datePointer = new Date(autoAssignFilters.intervalFrom);

      const newAutoAssignWoCheckers = autoAssignWoChecker.taskLists ?? [];
      const newWorkOrders: MeetingTaskListView[] = [];

      integrationModulesParam.forEach((m, i) => {
        // If module & panel not included, return
        if (
          !includedModules.find(
            (mx) =>
              `${m.integrationModule?.id}` ===
                `${mx.module.integrationModule?.id}` &&
              `${mx.panel.id}` === `${panelCode.id}`
          )
        ) {
          return;
        }

        const foundModulesQty =
          workOrders?.current?.taskLists
            ?.filter(
              (tL) =>
                // If has set, module, submodule
                (`${tL.taskList?.extSetId}` === `${m.integrationModule?.id}` ||
                  `${tL.taskList?.extModuleId}` ===
                    `${m.integrationModule?.id}` ||
                  `${tL.taskList?.extSubModuleId}` ===
                    `${m.integrationModule?.id}`) &&
                // If has same panel code id
                tL.taskList?.extPanelCodeId &&
                tL.taskList?.extPanelCodeId !== "0" &&
                tL.taskList?.extPanelCodeId !== "" &&
                `${tL.taskList?.extPanelCodeId}` === `${panelCode.id}`
            )
            .reduce(
              (acc, tL) => acc + (tL.taskList?.extIntegrationModuleQty ?? 0),
              0
            ) ?? 0;

        const totalNeededQty =
          (m.integrationModule?.qty ?? 0) * (panelCode.qty ?? 0);

        let remainingQty = totalNeededQty - foundModulesQty;

        console.log(
          "module:",
          m.integrationModule?.type,
          m.integrationModule?.name,
          m.integrationModule?.qty,
          "needed:",
          totalNeededQty,
          "remaining:",
          remainingQty
        );

        // Get custom remaining qty
        const foundRemainingQty = customQtyPanelIds.find(
          (c) =>
            c.panelCodeId &&
            c.jobIntegrationListId &&
            `${c.panelCodeId}` === `${panelCode.id}` &&
            `${c.jobIntegrationListId}` === `${iL.jobIntegrationList?.id}`
        );

        if (foundRemainingQty?.qty) {
          remainingQty = foundRemainingQty.qty;
        }

        // If module already exists in Job WO and no more needed, return
        if (remainingQty <= 0) {
          return;
        }

        // If module time is more than 8h, return
        console.log(
          m.integrationModule?.name,
          (m.integrationModule?.timeProcessMins ?? 0) * (remainingQty ?? 0),
          "vs",
          8 * 60
        );
        if (
          (m.integrationModule?.timeProcessMins ?? 0) * (remainingQty ?? 0) >
          8 * 60
        ) {
          return;
        }

        let assigned = false;

        while (!assigned) {
          // If sunday & monday
          if (datePointer.getDay() === 0 || datePointer.getDay() === 6) {
            datePointer = new Date(datePointer.getTime() + 86400000);
            continue;
          }

          for (const u of users ?? []) {
            const wos = filterWorkOrderByDate(
              MeetingTaskListsView.fromPartial({
                taskLists: newAutoAssignWoCheckers,
              }),
              makeDateString(datePointer),
              u
            );
            const wosTotalTime =
              // Time + module time

              // Time
              (wos?.reduce(
                (acc, tL) =>
                  acc +
                  (getWorkOrderTimeMinutes(
                    tL.taskList,
                    rcemDepartments,
                    integrationModules,
                    machineProgramsProto,
                    departmentTemplatesProto,
                    panelCodeDepartmentTemplateItemsProto.current,
                    u.id,
                    makeDateString(datePointer)
                    // true
                  ).totalHours ?? 0),
                0.0
              ) ?? 0) +
              // Module time
              ((m.integrationModule?.timeProcessMins ?? 0) * remainingQty) / 60;
            // if time less than 8
            if (wosTotalTime <= 8) {
              const spvId = m.integrationModule
                ? findIntegration(m)?.integration?.extDefaultAutoAssignSpvId
                : null;
              console.log("spv to add", spvId);

              const newTaskList = MeetingTaskListView.fromPartial({
                taskList: MeetingTaskList.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                  extSetId:
                    m.integrationModule?.type === "SET"
                      ? `${m.integrationModule.id}`
                      : undefined,
                  extModuleId:
                    m.integrationModule?.type === "MODULE"
                      ? `${m.integrationModule.id}`
                      : undefined,
                  extSubModuleId:
                    m.integrationModule?.type === "SUBMODULE"
                      ? `${m.integrationModule.id}`
                      : undefined,
                  extIntegrationModuleQty: remainingQty,
                  extPanelCodeId: panelCode.id ? `${panelCode.id}` : undefined,
                  extInChargeId: spvId ? `${spvId}` : undefined,
                  extJobId: state.job.job?.id
                    ? `${state.job.job?.id}`
                    : undefined,
                  meetingTasks: [
                    MeetingMeetingTask.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                      description: `Integrate ${m.integrationModule?.type}: ${m.integrationModule?.name} x${m.integrationModule?.qty} for ${state.job.job?.name}`,
                      meetingTaskInCharges: [
                        MeetingMeetingTaskInCharge.fromPartial({
                          masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                            uuid: v4(),
                          }),
                          extUserId: u.id ? `${u.id}` : undefined,
                        }),

                        ...(panelCode.extDefaultQcId &&
                        m.integrationModule?.type === "SET"
                          ? [
                              MeetingMeetingTaskInCharge.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                                extUserId: panelCode.extDefaultQcId
                                  ? `${panelCode.extDefaultQcId}`
                                  : undefined,
                                isQc: true,
                              }),
                            ]
                          : []),
                        ...(panelCode.extDefaultQaId &&
                        m.integrationModule?.type === "SET"
                          ? [
                              MeetingMeetingTaskInCharge.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                                extUserId: panelCode.extDefaultQaId
                                  ? `${panelCode.extDefaultQaId}`
                                  : undefined,
                                isQa: true,
                              }),
                            ]
                          : []),
                      ],
                      start: makeDateString(datePointer),
                      meetingTaskTargetDates: [
                        MeetingMeetingTaskTargetDate.fromPartial({
                          masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                            uuid: v4(),
                          }),
                          date: makeDateString(datePointer),
                        }),
                      ],
                    }),
                  ],
                }),
              });
              newAutoAssignWoCheckers.push(newTaskList);
              newWorkOrders.push(newTaskList);
              assigned = true;
              break;
            }
          }

          if (!assigned) {
            datePointer = new Date(datePointer.getTime() + 86400000);
          }
        }
      });

      setAutoAssignWoChecker({
        ...autoAssignWoChecker,
        taskLists: newAutoAssignWoCheckers,
      });

      if (workOrders) {
        workOrders.current.taskLists = [
          ...workOrders.current.taskLists,
          ...newWorkOrders,
        ];
        render();
        // setWorkOrders({
        //   ...workOrders,
        //   taskLists: [...workOrders.taskLists, ...newWorkOrders],
        // });
      }
    }
  };

  const handleAutoAssignV2 = async () => {
    autoAssignV2Executor(selectedPanelCodeDepartmentTemplateItems, []);
  };

  const handleAutoAssignManualInput = (
    panelCodeDepartmentTemplateItems: PanelCodeDepartmentTemplateItem[],
    usersParam: {
      worker: PpicSkillWorker;
      user: ExtUser | undefined;
    }[]
  ) => {
    autoAssignV2Executor(panelCodeDepartmentTemplateItems, usersParam);
  };

  const handleGetAutoAssignV2Data = async () => {
    try {
      const wos = await fetchWorkOrdersProtoMongo({
        from: autoAssignFilters.intervalFrom,
        to: autoAssignFilters.intervalTo,
        // extUserIds: (
        //   skills.skills.find(
        //     (s) =>
        //       `${autoAssignFilters.skill?.masterJavaBaseModel?.id}` ===
        //       `${s.masterJavaBaseModel?.id}`
        //   )?.skillWorkers ?? []
        // ).map((w) => `${w.extUserId}`),
      });

      setAutoAssignWoChecker(wos);
      setDataGot(true);
    } catch (e) {}
  };
  useEffect(() => {
    if (departmentTemplatesAll) {
      const deptTemplateByCategory = departmentTemplatesAll.filter(
        (d) =>
          d.departmentTemplate?.category?.id ===
          state.job.job?.departmentTemplateCategory?.id
      );

      setDepartmentTemplates(deptTemplateByCategory);

      setFilterGenerateDepartmentTemplate(deptTemplateByCategory);
    }
  }, [state.job.job?.departmentTemplateCategory, departmentTemplatesAll]);

  const AutoAssignIntervals = () => {
    return (
      <div className="d-flex my-1">
        <strong>Interval:</strong>
        <div>From:</div>
        <div>
          <input
            type="date"
            className="form-control form-control-sm"
            // value={autoAssignFilters.intervalFrom ?? undefined}
            onBlur={(e) => {
              if (e.target.value !== "") {
                setAutoAssignFilters({
                  ...autoAssignFilters,
                  intervalFrom: e.target.value,
                });
              }
            }}
          />
        </div>
        <div>To:</div>
        <div>
          <input
            type="date"
            className="form-control form-control-sm"
            // defaultValue={autoAssignFilters.intervalTo ?? undefined}
            onBlur={(e) => {
              if (e.target.value !== "") {
                setAutoAssignFilters({
                  ...autoAssignFilters,
                  intervalTo: e.target.value,
                });
              }
            }}
          />
        </div>
      </div>
    );
  };

  const findIntegration = (integrationModule: IntegrationModuleView) => {
    return integrations.find((i) =>
      i.integrationModules?.find((m) =>
        m.integrationModule
          ? findIntegrationRecursive(m, integrationModule.integrationModule?.id)
            ? true
            : false
          : false
      )
    );
  };

  const findIntegrationRecursive = (
    integrationModule: IntegrationModuleView,
    id: any
  ): boolean => {
    console.log(
      "Matching",
      integrationModule.integrationModule?.id,
      integrationModule.integrationModule?.name,
      "with",
      id
    );
    if (integrationModule.integrationModule?.id === id) {
      console.log(integrationModule.integrationModule?.name, "found");
      return true;
    }

    if ((integrationModule.integrationModules?.length ?? 0) > 0) {
      return integrationModule.integrationModules?.find((m) =>
        findIntegrationRecursive(m, id)
      )
        ? true
        : false;
    } else {
      return false;
    }
  };

  const datePointerIntervalFrom = autoAssignFilters.intervalFrom
    ? makeDateString(new Date(autoAssignFilters.intervalFrom))
    : null;

  const daysLengthIntervalFrom =
    datePointerIntervalFrom && autoAssignFilters.intervalTo
      ? Math.round(
          (new Date(
            makeDateString(new Date(autoAssignFilters.intervalTo))
          ).getTime() -
            new Date(datePointerIntervalFrom).getTime()) /
            86400000
        )
      : 0;

  const datesIntervalFrom = datePointerIntervalFrom
    ? [...Array(daysLengthIntervalFrom)].map((_, i) =>
        makeDateString(
          new Date(new Date(datePointerIntervalFrom).getTime() + 86400000 * i)
        )
      )
    : [];

  const includeModule = (pc: PanelCode, m: IntegrationModuleView) => {
    const flattenedModules = flattenModules(null, null, [m]);

    if (
      !includedModules.find(
        (mx) =>
          flattenedModules.find(
            (mxx) =>
              `${mxx.module.integrationModule?.id}` ===
              `${mx.module.integrationModule?.id}`
          ) && mx.panel.id === pc.id
      )
    ) {
      console.log("newincludedmodules", [
        ...includedModules,
        ...flattenedModules.map((m) => ({ panel: pc, module: m.module })),
      ]);
      setIncludedModules([
        ...includedModules,
        ...flattenedModules.map((m) => ({ panel: pc, module: m.module })),
      ]);
    } else {
      setIncludedModules(
        includedModules.filter(
          (mx) =>
            !flattenedModules.find(
              (mxx) =>
                `${mxx.module.integrationModule?.id}` ===
                `${mx.module.integrationModule?.id}`
            )
        )
      );
    }
  };

  return (
    <ThemeProvider theme={createMuiTheme()}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="mx-1">
          <div className="d-flex align-items-center my-2">
            <div className="mx-1">
              <Link to="/jobs">
                <Button style={{ color: blue[500] }}>
                  {" "}
                  <ChevronLeftIcon /> Back
                </Button>
              </Link>
            </div>
            {state.requestStatus === RequestStatus.Loading ? (
              <></>
            ) : (
              <>
                <div className="d-flex">
                  <div className="mx-1">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSave({ withWo: true, parallelWo: false });
                      }}
                    >
                      Save
                    </Button>
                  </div>

                  <div className="mx-1">
                    {!woLoaded ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={async () => {
                          const workOrdersProtoData =
                            await fetchTaskListsProtoData();

                          if (workOrdersProtoData) {
                            workOrders.current =
                              structuredClone(workOrdersProtoData);
                            render();

                            workOrdersFormer.current =
                              structuredClone(workOrdersProtoData);
                          }

                          setWoLoaded(true);
                        }}
                      >
                        {" "}
                        Load WO
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
            )}

            <CheckRequestStatus requestStatus={state.requestStatus} />
          </div>
          <div className="d-flex my-3">
            <div className="mx-3">
              <button
                onClick={() => {
                  setViewType("GeneralInfo");
                }}
                className={`btn ${
                  viewType === "GeneralInfo"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
              >
                General Info & Products
              </button>
            </div>
            <div className="mx-3">
              <button
                onClick={() => {
                  setViewType("ProductBatch");
                }}
                className={`btn ${
                  viewType === "ProductBatch"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
              >
                Product Batch
              </button>
            </div>
            {true ? (
              <>
                <div className="mx-3">
                  <button
                    onClick={() => {
                      setViewType("DeptTemplate");
                    }}
                    className={`btn ${
                      viewType === "DeptTemplate"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                  >
                    Dept Template
                  </button>
                </div>
                {woLoaded ? (
                  <>
                    {" "}
                    <div className="mx-3">
                      <button
                        onClick={() => {
                          setViewType("IntegrationList");
                        }}
                        className={`btn ${
                          viewType === "IntegrationList"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        Product Tree
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="mx-3">
                  <button
                    onClick={() => {
                      setViewType("IntegrationListFlattened");
                    }}
                    className={`btn ${
                      viewType === "IntegrationListFlattened"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                  >
                    Product Tree Flat
                  </button>
                </div>

                <div className="mx-3">
                  <button
                    onClick={() => {
                      setViewType("IntegrationListFlattened");
                    }}
                    className={`btn ${
                      viewType === "IntegrationListFlattened"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                  >
                    AB Costing
                  </button>
                </div>

                {woLoaded ? (
                  <>
                    {" "}
                    <div className="mx-3">
                      <button
                        onClick={() => {
                          setViewType("ProgramTree");
                        }}
                        className={`btn ${
                          viewType === "ProgramTree"
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        Program Tree
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* <div className="mx-3">
                  <button
                    onClick={() => {
                      setViewType("Comments");
                    }}
                    className={`btn ${
                      viewType === "Comments"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                  >
                    Comments
                  </button>
                </div>
                <div className="mx-3">
                  <button
                    onClick={() => {
                      setViewType("SN Progress");
                    }}
                    className={`btn ${
                      viewType === "SN Progress"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                  >
                    SN Progress
                  </button>
                </div> */}
              </>
            ) : (
              <></>
            )}

            {/* <div className="mx-3">
              <button
                onClick={() => {
                  setViewType("EmptyTest");
                }}
                className={`btn ${
                  viewType === "EmptyTest"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
              >
                EmptyTest
              </button>
            </div>

            <div className="mx-3">
              <button
                onClick={() => {
                  setViewType("EmptyTest2");
                }}
                className={`btn ${
                  viewType === "EmptyTest2"
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
              >
                EmptyTest2
              </button>
            </div> */}

            <div
              className="d-flex align-items-end"
              style={{ cursor: "pointer" }}
            >
              <div>
                Closing Date:{" "}
                {state.job.job?.closingDate ? (
                  (() => {
                    try {
                      return `${makeReadableDateStringIntlDateOnlyUtc(
                        new Date(`${state.job.job.closingDate}T00:00:00Z`)
                      )}`;
                    } catch (e) {
                      return "invalid";
                    }
                  })()
                ) : (
                  <></>
                )}
              </div>

              {state.job.job?.closingDate ? (
                <>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={(e) => {
                      setState({
                        ...state,
                        job: state.job
                          ? {
                              ...state.job,
                              job: state.job.job
                                ? {
                                    ...state.job.job,
                                    closingDate: null,
                                  }
                                : state.job.job,
                            }
                          : state.job,
                      });
                    }}
                  >
                    Clear Closing Date
                  </button>
                </>
              ) : (
                <>
                  {" "}
                  <input
                    type="date"
                    onBlur={(e) => {
                      setState({
                        ...state,
                        job: state.job
                          ? {
                              ...state.job,
                              job: state.job.job
                                ? {
                                    ...state.job.job,
                                    closingDate: e.target.value,
                                  }
                                : state.job.job,
                            }
                          : state.job,
                      });
                    }}
                    defaultValue={state.job.job?.closingDate ?? undefined}
                    className="form-control form-control-sm"
                  />
                </>
              )}
            </div>
          </div>
          {viewType === "GeneralInfo" ? (
            <>
              <h3>General Information</h3>
              <hr className="border border-dark" />

              <div className="d-flex">
                <div className="flex-grow-1">
                  <div>
                    <div>
                      <div>
                        <strong>Project Name</strong>
                      </div>
                      <Select
                        value={ctx?.projectsIdName.find(
                          (p) => `${p.id}` === `${state.job.job?.extProjectId}`
                        )}
                        options={ctx?.projectsIdName}
                        getOptionLabel={(p) => `${p.name}`}
                        onChange={async (v) => {
                          const val = v as {
                            id: number | null;
                            name: string | null;
                          };

                          const p = await fetchProject({
                            id: val.id,
                            apiKey: ctx?.apiKey ?? "",
                          });

                          if (!val.id) {
                            return;
                          }

                          // const foundJobPo =
                          //   state.job.job?.jobPurchaseOrders?.find(
                          //     (jpo) =>
                          //       `${jpo.extPurchaseOrderId}` ===
                          //       `${p?.extPurchaseOrderId}`
                          //   );

                          const jobPoIds =
                            state.job.job?.jobPurchaseOrders?.map(
                              (jpo) => jpo.id ?? 0
                            ) ?? [];

                          jobPurchaseOrderDeleteIds.current = [
                            ...jobPurchaseOrderDeleteIds.current,
                            ...jobPoIds,
                          ];

                          render();

                          // alert(`${p?.extPurchaseOrderId}`)

                          setState({
                            ...state,
                            job: {
                              ...state.job,
                              job: state.job.job
                                ? ({
                                    ...state.job.job,
                                    extProjectId: val.id,
                                    name: val.name ?? "",
                                    jobDate:
                                      p?.start?.split("T")?.[0] ??
                                      state.job.job.jobDate,
                                    productionDeadline:
                                      p?.deadline?.split("T")?.[0] ??
                                      state.job.job.productionDeadline,
                                    deadline:
                                      p?.deadline?.split("T")?.[0] ??
                                      state.job.job.deadline,
                                    deliveryDeadline:
                                      p?.deadline?.split("T")?.[0] ??
                                      state.job.job.deliveryDeadline,
                                    jobPurchaseOrders:
                                      p?.projectPurchaseOrders?.map((p) => ({
                                        ...initialJobPurchaseOrder,
                                        uuid: v4(),
                                        extPurchaseOrderId:
                                          p.extPurchaseOrderId,
                                      })) ?? [],
                                    jobBPOs:
                                      p?.projectBPOs?.map((p) => ({
                                        ...initialJobBPO,
                                        uuid: v4(),
                                        extBPOId: p.extBPOId,
                                      })) ?? [],
                                  } as Job)
                                : state.job.job,
                            },
                          });
                        }}
                        placeholder="Project..."
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => {
                          setShowProjectDetail(!showProjectDetail);
                        }}
                      >
                        Toggle Project Details
                      </button>
                    </div>
                    {showProjectDetail ? (
                      <>
                        {" "}
                        <div>
                          <div>
                            <small>
                              <strong>Scope of Work</strong>
                            </small>
                          </div>
                        </div>
                        <div>
                          <div className="border border-dark">
                            <pre>{selectedJobProjectDetail?.scope}</pre>
                          </div>
                        </div>
                        {(project?.projectRisks?.length ?? 0) > 0 ? (
                          <>
                            {" "}
                            <div>
                              <strong>Risks:</strong>
                              <div>
                                <ol>
                                  {project?.projectRisks?.map((r) => {
                                    return (
                                      <>
                                        <li>
                                          <div className="d-flex">
                                            <div
                                              className="px-1"
                                              style={{
                                                backgroundColor: (() => {
                                                  switch (r.riskSpecification) {
                                                    case "LOW":
                                                      return "darkgreen";
                                                    case "MEDIUM":
                                                      return "darkgoldenrod";
                                                    case "HIGH":
                                                      return "darkred";
                                                    case "SEVERE":
                                                      return "black";
                                                    default:
                                                      return "";
                                                  }
                                                })(),
                                              }}
                                            >
                                              <strong className="text-light">
                                                {r.riskSpecification}
                                              </strong>
                                            </div>
                                            <div className="mx-1">
                                              {r.description}
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ol>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {/* <div className="flex-grow-1">
                  <div>
                    <div>
                      {" "}
                      <div>
                        <strong>DJO </strong>
                      </div>
                    </div>
                    <Select
                      value={crmDjos.djos
                        .map((d) => ({
                          label: `${d.text}: ${d.accountName}`,
                          value: d.value,
                        }))
                        .find(
                          (d) =>
                            `${d.value}` === `${state.job.job?.extCrmDjoId}`
                        )}
                      options={crmDjos.djos.map((d) => ({
                        label: `${d.text}: ${d.accountName}`,
                        value: d.value,
                      }))}
                      onChange={(v) => {
                        const val = v as {
                          label: string | undefined;
                          value: string | undefined;
                        };

                        setState({
                          ...state,
                          job: {
                            ...state.job,
                            job: state.job.job
                              ? {
                                  ...state.job.job,
                                  extCrmDjoId: isNaN(parseInt(val.value ?? ""))
                                    ? state.job.job.extCrmDjoId
                                    : parseInt(val.value ?? ""),
                                }
                              : state.job.job,
                          },
                        });
                      }}
                      placeholder="DJO..."
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div> */}
                {/* <div className="flex-grow-1">
                  <div>
                    <div>
                      {" "}
                      <div>
                        <strong>Preset </strong>
                      </div>
                    </div>
                    <Select
                      value={(() => {
                        const foundPreset = jdpPresets.presets.find(
                          (p) =>
                            `${p.masterJavaBaseModel?.id}` ===
                            `${state.job.job?.extJdpPresetId}`
                        );

                        if (!foundPreset) {
                          return null;
                        }

                        return {
                          label: foundPreset.name ?? "",
                          value: foundPreset,
                        };
                      })()}
                      options={jdpPresets.presets.map((p) => ({
                        label: `${p.name}`,
                        value: p,
                      }))}
                      onChange={(v) => {
                        const val = v as {
                          value: PpicPanelCodeDepartmentTemplatePreset;
                        };

                        setState({
                          ...state,
                          job: {
                            ...state.job,
                            job: state.job.job
                              ? {
                                  ...state.job.job,
                                  extJdpPresetId: val.value.masterJavaBaseModel
                                    ?.id
                                    ? isNaN(
                                        parseInt(
                                          `${val.value.masterJavaBaseModel?.id}`
                                        )
                                      )
                                      ? null
                                      : parseInt(
                                          `${val.value.masterJavaBaseModel?.id}`
                                        )
                                    : null,
                                }
                              : state.job.job,
                          },
                        });
                      }}
                      placeholder="DJO..."
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div> */}
              </div>
              <hr className="border border-dark" />

              <div className="d-flex align-items-center my-2">
                <div className="d-flex flex-grow-1">
                  <div className="w-100">
                    <div>
                      <small>
                        <strong>Job Name</strong>
                      </small>
                    </div>
                    <input
                      className="form-control "
                      placeholder="Job Name..."
                      defaultValue={state.job.job?.name ?? ""}
                      onBlur={(event: any) =>
                        handleChangeOutstandingJobName(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex flex-grow-1">
                  <div className="w-100">
                    <div>
                      <small>
                        <strong>Short Name</strong>
                      </small>
                    </div>
                    <input
                      className="form-control "
                      placeholder="Short Name..."
                      defaultValue={state.job.job?.shortName ?? ""}
                      onBlur={(e) => {
                        setState({
                          ...state,
                          job: {
                            ...state.job,
                            job: state.job.job
                              ? { ...state.job.job, shortName: e.target.value }
                              : state.job.job,
                          },
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="flex-grow-1">
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/postatuses/search`}
                    label="PO Status"
                    value={state.job.job?.poStatus}
                    onChange={(value: any) =>
                      handleChangeOutstandingJobPoStatus(value)
                    }
                    param="name"
                  />
                </div>
                <div className="flex-grow-1">
                  <DatePicker
                    autoOk
                    variant="inline"
                    // inputVariant="outlined"
                    label="Job Date"
                    size="small"
                    format="dd/MM/yyyy"
                    value={
                      state.job.job?.jobDate
                        ? new Date(state.job.job.jobDate)
                        : new Date()
                    }
                    // InputAdornmentProps={{
                    //   position: "start"
                    // }}
                    onChange={(value: MaterialUiPickersDate) =>
                      handleChangeJobDate(value)
                    }
                  />
                </div>
                <div className="flex-grow-1">
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/roles/byname/Production%20Manager/users`}
                    label="Assign to"
                    value={state.job.job?.assignedTo ?? null}
                    onChange={(value: any) => handleChangeAssignedTo(value)}
                    param="name"
                  />
                </div>
              </div>
              <div className="d-flex my-2">
                <div className="d-flex align-items-center flex-grow-1">
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/individualentities/search`}
                    label="Customer"
                    value={state.job.job?.individualEntity ?? null}
                    // onChange={(value: any) => setOutstandingJob({...outstandingJob, individualEntity: value})}
                    onChange={(value: any) =>
                      handleChangeOutstandingJobIndividualEntity(value)
                    }
                    param="name"
                  />
                  <a target="_blank" href="/#/individualentities/new">
                    <Button size="small" color="primary" variant="outlined">
                      Add
                    </Button>
                  </a>
                </div>
                <div className="d-flex align-items-center flex-grow-1">
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/items/search`}
                    label="Item"
                    value={state.job.job?.item}
                    // onChange={(value) => setOutstandingJob({...outstandingJob, item: value})}
                    onChange={(value: any) =>
                      handleChangeOutstandingJobItem(value)
                    }
                    param="partName"
                  />
                  <a target="_blank" href="/#/items/new">
                    <Button size="small" color="primary" variant="outlined">
                      Add
                    </Button>
                  </a>
                </div>
                <div className="ml-2" style={{ width: 50 }}>
                  <strong>
                    Qty:{" "}
                    {state.job.job?.panelCodes?.reduce(
                      (acc, pc) => acc + (pc.qty ?? 0),
                      0
                    )}
                  </strong>
                </div>
                <div className="flex-grow-1">
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/uoms/search`}
                    label="UM"
                    value={state.job.job?.uom}
                    // onChange={(value) => setOutstandingJob({...outstandingJob, uom: value})}
                    onChange={(value: any) =>
                      handleChangeOutstandingJobUom(value)
                    }
                    param="name"
                  />
                </div>
                <div className="flex-grow-1">
                  <div>Type:</div>
                  <div className="d-flex flex-wrap">
                    {jobDeptCategories.map((c) => {
                      return (
                        <>
                          <div>
                            <button
                              onClick={() => {
                                setState({
                                  ...state,
                                  job: {
                                    ...state.job,
                                    job: state.job.job
                                      ? {
                                          ...state.job.job,
                                          jobDeptCategory: c.value,
                                        }
                                      : state.job.job,
                                  },
                                });
                              }}
                              className={`btn ${
                                state.job.job?.jobDeptCategory === c.value
                                  ? `btn-primary`
                                  : `btn-outline-primary`
                              } `}
                            >
                              {c.label}
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <div>
                <pre>{JSON.stringify(purchaseOrders)}</pre>
              </div> */}
              {/* <div>
                <pre>
                  <small>{JSON.stringify(partNumberMatches)}</small>
                </pre>
              </div> */}
              {purchaseOrders ? (
                <>
                  <div>
                    <strong>
                      Purchase Orders: (
                      {state.job.job?.jobPurchaseOrders?.length})
                    </strong>
                  </div>
                  <div>
                    <Select
                      options={purchaseOrders?.purchaseOrders?.map((po) => ({
                        label: `${po.purchaseOrderNumber}: ${
                          po?.account?.name
                        }:${po.products.length ?? 0} item(s)`,
                        value: po,
                      }))}
                      onChange={(v) => {
                        const val = v as unknown as { value: CrmPurchaseOrder };

                        console.log(val.value?.id);

                        if (
                          !state.job.job?.jobPurchaseOrders?.find(
                            (o) => `${o.id}` === `${val.value.id}`
                          )
                        ) {
                          const newPo: JobPO = {
                            ...initialJobPurchaseOrder,
                            uuid: v4(),
                            extPurchaseOrderId: val.value.id
                              ? isNaN(parseInt(val.value.id))
                                ? null
                                : parseInt(val.value.id)
                              : null,
                          };

                          setState({
                            ...state,
                            job: {
                              ...state.job,
                              job: state.job.job
                                ? {
                                    ...state.job.job,
                                    jobPurchaseOrders: state.job.job
                                      .jobPurchaseOrders
                                      ? [
                                          ...state.job.job.jobPurchaseOrders,
                                          newPo,
                                        ]
                                      : [newPo],
                                  }
                                : state.job.job,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <ol>
                      {state.job.job?.jobPurchaseOrders?.map((p, i) => {
                        const foundPo = purchaseOrders.purchaseOrders.find(
                          (o) => `${o.id}` === `${p.extPurchaseOrderId}`
                        );

                        return (
                          <>
                            <li style={{ cursor: "pointer" }}>
                              <div>
                                <div className="d-flex">
                                  <div
                                    onClick={() => {
                                      jobPurchaseOrderDeleteIds.current.push(
                                        p.id ?? 0
                                      );
                                      render();
                                      setState({
                                        ...state,
                                        job: state.job
                                          ? {
                                              ...state.job,
                                              job: state.job.job
                                                ? {
                                                    ...state.job.job,
                                                    jobPurchaseOrders:
                                                      state.job.job.jobPurchaseOrders?.filter(
                                                        (_, ix) => ix !== i
                                                      ) ?? [],
                                                  }
                                                : state.job.job,
                                            }
                                          : state.job,
                                      });
                                    }}
                                  >
                                    {foundPo?.purchaseOrderNumber}:{" "}
                                    {foundPo?.account?.name}:{" "}
                                    {foundPo?.products.length ?? 0} item(s)
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-sm btn-outline-primary px-1 py-0"
                                      onClick={() => {
                                        if (
                                          expandedPOTable.current.find(
                                            (px) =>
                                              `${px}` ===
                                              `${p.extPurchaseOrderId}`
                                          )
                                        ) {
                                          expandedPOTable.current =
                                            expandedPOTable.current.filter(
                                              (px) =>
                                                `${px}` !==
                                                `${p.extPurchaseOrderId}`
                                            );
                                          render();
                                        } else {
                                          expandedPOTable.current.push(
                                            p.extPurchaseOrderId ?? 0
                                          );
                                          render();
                                        }
                                      }}
                                    >
                                      Expand/Collapse
                                    </button>
                                  </div>
                                </div>
                                {/* {JSON.stringify(expandedPOTable.current)} */}
                                {expandedPOTable.current.find(
                                  (px) => `${px}` === `${p.extPurchaseOrderId}`
                                ) ? (
                                  <>
                                    <div className="w-100 d-flex justify-content-end">
                                      <div>
                                        <button
                                          className="btn btn-sm btn-primary"
                                          onClick={() => {
                                            if (
                                              !window.confirm(
                                                "Convert to product?"
                                              )
                                            ) {
                                              return;
                                            }

                                            const foundMatchesAll =
                                              foundPo?.products.map((p, i) => {
                                                const foundMatches =
                                                  partNumberMatches?.matches.filter(
                                                    (m) =>
                                                      `${m.source}` ===
                                                        `${p?.productId}` &&
                                                      `${m.extPurchaseOrderId}` ===
                                                        `${foundPo.id}`
                                                  );

                                                return {
                                                  product: p,
                                                  matches: foundMatches,
                                                };
                                              });

                                            // Filter unique match
                                            const uniqueMatch = [
                                              ...new Set(
                                                foundMatchesAll
                                                  ?.flatMap((m) => m.matches)
                                                  .map(
                                                    (m) =>
                                                      m?.masterJavaBaseModel?.id
                                                  )
                                              ),
                                            ]
                                              .filter((m) => m)
                                              .map((m) => m as string)
                                              .map((id) => {
                                                const foundMatchData =
                                                  foundMatchesAll
                                                    // ?.flatMap((m) => m.matches)
                                                    ?.find((mx) =>
                                                      mx.matches?.find(
                                                        (mxx) =>
                                                          mxx
                                                            .masterJavaBaseModel
                                                            ?.id === id
                                                      )
                                                    );
                                                const foundMatch =
                                                  foundMatchData?.matches?.find(
                                                    (mx) =>
                                                      mx.masterJavaBaseModel
                                                        ?.id === id
                                                  );

                                                return {
                                                  data: foundMatchData,
                                                  match: foundMatch,
                                                };
                                                // return null;
                                              });

                                            const matchProductUniq = [
                                              ...new Set(
                                                uniqueMatch.map(
                                                  (m) => m.match?.destination
                                                )
                                              ),
                                            ];

                                            const pCodes = matchProductUniq
                                              .map((p) => {
                                                const foundUniqueMatch =
                                                  uniqueMatch.find(
                                                    (m) =>
                                                      `${m.match?.destination}` ===
                                                      `${p}`
                                                  );

                                                return foundUniqueMatch;
                                              })
                                              .filter((u) => u)
                                              .map(
                                                (u) =>
                                                  u as {
                                                    data:
                                                      | {
                                                          product: CrmProduct;
                                                          matches:
                                                            | PpicPartNumberMatch[]
                                                            | undefined;
                                                        }
                                                      | undefined;
                                                    match:
                                                      | PpicPartNumberMatch
                                                      | undefined;
                                                  }
                                              )
                                              .map((m) => {
                                                const foundItem =
                                                  items?.items.find(
                                                    (i) =>
                                                      `${i.id}` ===
                                                      `${m.match?.destination}`
                                                  );

                                                const parsedProductId = m.data
                                                  ?.product.productId
                                                  ? !isNaN(
                                                      parseInt(
                                                        m.data?.product
                                                          .productId ?? ""
                                                      )
                                                    )
                                                    ? parseInt(
                                                        m.data?.product
                                                          .productId ?? ""
                                                      )
                                                    : null
                                                  : null;
                                                return {
                                                  ...initialPanelCode,
                                                  uuid: v4(),
                                                  extCrmPurchaseOrderItemId:
                                                    parsedProductId,
                                                  type: m.data?.product
                                                    .partName,
                                                  name: `${m.data?.product.partDesc}:${foundItem?.partNum}`,
                                                  extItemId: parsedProductId,
                                                } as PanelCode;
                                              });

                                            console.log("codes", pCodes);

                                            setState({
                                              ...state,
                                              job: state.job
                                                ? {
                                                    ...state.job,
                                                    job: state.job.job
                                                      ? {
                                                          ...state.job.job,
                                                          panelCodes: [
                                                            ...(state.job.job
                                                              .panelCodes ??
                                                              []),
                                                            ...pCodes.filter(
                                                              (c) =>
                                                                !state.job.job?.panelCodes?.find(
                                                                  (cx) =>
                                                                    `${c.extCrmPurchaseOrderItemId}` ===
                                                                    `${cx.extCrmPurchaseOrderItemId}`
                                                                )
                                                            ),
                                                          ],
                                                        }
                                                      : state.job.job,
                                                  }
                                                : state.job,
                                            });
                                          }}
                                        >
                                          Convert to Products
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className="overflow-auto border border-dark"
                                      style={{
                                        height: "20vh",
                                        resize: "vertical",
                                      }}
                                    >
                                      <table
                                        className="table table-sm"
                                        style={{ borderCollapse: "separate" }}
                                      >
                                        <tr>
                                          {[
                                            "#",
                                            "ID Prod",
                                            "CustPN",
                                            "Product Name",
                                            "Product Desc",
                                            "Qty",
                                            "GSPE PN",
                                            "GSPE PartName",
                                            "GSPE PartDesc",
                                          ].map((h) => (
                                            <>
                                              <th className="bg-dark text-light p-0 m-0">
                                                {h}
                                              </th>
                                            </>
                                          ))}
                                        </tr>
                                        {foundPo?.products.map((p, i) => {
                                          // const foundMatch =
                                          //   partNumberMatches?.matches.find(
                                          //     (m) =>
                                          //       `${m.source}` ===
                                          //         `${p?.productId}` &&
                                          //       `${m.extPurchaseOrderId}` ===
                                          //         `${foundPo.id}`
                                          //   );

                                          const foundMatches =
                                            partNumberMatches?.matches.filter(
                                              (m) =>
                                                `${m.source}` ===
                                                  `${p?.productId}` &&
                                                `${m.extPurchaseOrderId}` ===
                                                  `${foundPo.id}`
                                            );

                                          return (foundMatches?.length ?? 0) ===
                                            0 ? (
                                            <>
                                              <tr>
                                                <td className="border border-dark p-0 m-0">
                                                  {i + 1}
                                                </td>
                                                <td className="border border-dark p-0 m-0">
                                                  {p.productId}
                                                </td>
                                                <td className="border border-dark p-0 m-0">
                                                  {p.partNumber}{" "}
                                                </td>
                                                <td className="border border-dark p-0 m-0">
                                                  {p.partName}{" "}
                                                </td>
                                                <td className="border border-dark p-0 m-0">
                                                  {p.partDesc}{" "}
                                                </td>
                                                <td className="border border-dark p-0 m-0">
                                                  {p.qty}{" "}
                                                </td>
                                                <td
                                                  className={`bg-danger`}
                                                ></td>
                                                <td
                                                  className={`bg-danger`}
                                                ></td>
                                                <td
                                                  className={`bg-danger`}
                                                ></td>
                                              </tr>
                                            </>
                                          ) : (
                                            foundMatches?.map((foundMatch) => {
                                              const foundItem =
                                                items?.items.find(
                                                  (i) =>
                                                    `${i.id}` ===
                                                    `${foundMatch?.destination}`
                                                );

                                              return (
                                                <>
                                                  <tr>
                                                    <td className="border border-dark p-0 m-0">
                                                      {i + 1}
                                                    </td>
                                                    <td className="border border-dark p-0 m-0">
                                                      {p.productId}
                                                    </td>
                                                    <td className="border border-dark p-0 m-0">
                                                      {p.partNumber}{" "}
                                                    </td>
                                                    <td className="border border-dark p-0 m-0">
                                                      {p.partName}{" "}
                                                    </td>
                                                    <td className="border border-dark p-0 m-0">
                                                      {p.partDesc}{" "}
                                                    </td>
                                                    <td className="border border-dark p-0 m-0">
                                                      {p.qty}{" "}
                                                    </td>
                                                    <td
                                                      className={`text-light border border-dark ${
                                                        p.productId
                                                          ? "bg-success"
                                                          : foundItem
                                                          ? `bg-success`
                                                          : `bg-warning`
                                                      } p-0 m-0`}
                                                    >
                                                      {!(
                                                        p.productId &&
                                                        `${p.productId}` !== "0"
                                                      ) ? (
                                                        <>
                                                          DEFAULT:{" "}
                                                          {p.partNumber}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {foundItem?.partNum}{" "}
                                                          {foundMatch?.gspePartNumber &&
                                                          itemsLoading ? (
                                                            <Spinner animation="border" />
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={`text-light border border-dark ${
                                                        p.productId
                                                          ? "bg-success"
                                                          : foundItem
                                                          ? `bg-success`
                                                          : `bg-warning`
                                                      } p-0 m-0`}
                                                    >
                                                      {!(
                                                        p.productId &&
                                                        `${p.productId}` !== "0"
                                                      ) ? (
                                                        <>
                                                          DEFAULT: {p.partName}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {foundItem?.partName}{" "}
                                                          {foundMatch?.gspePartNumber &&
                                                          itemsLoading ? (
                                                            <Spinner animation="border" />
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      )}
                                                    </td>
                                                    <td
                                                      className={`text-light border border-dark ${
                                                        p.productId
                                                          ? "bg-success"
                                                          : foundItem
                                                          ? `bg-success`
                                                          : `bg-warning`
                                                      } p-0 m-0`}
                                                    >
                                                      {!(
                                                        p.productId &&
                                                        `${p.productId}` !== "0"
                                                      ) ? (
                                                        <>
                                                          DEFAULT: {p.partDesc}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {foundItem?.partDesc}{" "}
                                                          {foundMatch?.gspePartNumber &&
                                                          itemsLoading ? (
                                                            <Spinner animation="border" />
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </>
                                                      )}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })
                                          );
                                        })}
                                      </table>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>
                </>
              ) : (
                <></>
              )}

              {bpos ? (
                <>
                  <div>
                    <strong>BPOs: ({state.job.job?.jobBPOs?.length})</strong>
                  </div>
                  <div>
                    <Select
                      options={bpos?.purchaseOrders?.map((po) => ({
                        label: `${po.bpoNumber}: ${po?.account?.name}:${
                          po.products.length ?? 0
                        } item(s)`,
                        value: po,
                      }))}
                      onChange={(v) => {
                        const val = v as unknown as { value: CrmPurchaseOrder };

                        console.log(val.value?.id);

                        if (
                          !state.job.job?.jobPurchaseOrders?.find(
                            (o) => `${o.id}` === `${val.value.id}`
                          )
                        ) {
                          const newPo: JobBPO = {
                            ...initialJobBPO,
                            uuid: v4(),
                            extBPOId: val.value.id
                              ? isNaN(parseInt(val.value.id))
                                ? null
                                : parseInt(val.value.id)
                              : null,
                          };

                          setState({
                            ...state,
                            job: {
                              ...state.job,
                              job: state.job.job
                                ? {
                                    ...state.job.job,
                                    jobBPOs: state.job.job.jobBPOs
                                      ? [...state.job.job.jobBPOs, newPo]
                                      : [newPo],
                                  }
                                : state.job.job,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <ol>
                      {state.job.job?.jobBPOs?.map((p, i) => {
                        const foundBPO = bpos?.purchaseOrders.find(
                          (o) => `${o.id}` === `${p.extBPOId}`
                        );

                        return (
                          <>
                            <li style={{ cursor: "pointer" }}>
                              <div>
                                <div className="d-flex">
                                  <div
                                    onClick={() => {
                                      jobBPODeleteIds.current.push(p.id ?? 0);
                                      render();
                                      setState({
                                        ...state,
                                        job: state.job
                                          ? {
                                              ...state.job,
                                              job: state.job.job
                                                ? {
                                                    ...state.job.job,
                                                    jobBPOs:
                                                      state.job.job.jobBPOs?.filter(
                                                        (_, ix) => ix !== i
                                                      ) ?? [],
                                                  }
                                                : state.job.job,
                                            }
                                          : state.job,
                                      });
                                    }}
                                  >
                                    {foundBPO?.bpoNumber}:{" "}
                                    {foundBPO?.account?.name}:{" "}
                                    {foundBPO?.products.length ?? 0} item(s)
                                  </div>
                                  {/* <div>
                                    <button
                                      className="btn btn-sm btn-outline-primary px-1 py-0"
                                      onClick={() => {
                                        if (
                                          expandedPOTable.current.find(
                                            (px) =>
                                              `${px}` ===
                                              `${p.bpo}`
                                          )
                                        ) {
                                          expandedPOTable.current =
                                            expandedPOTable.current.filter(
                                              (px) =>
                                                `${px}` !==
                                                `${p.extPurchaseOrderId}`
                                            );
                                          render();
                                        } else {
                                          expandedPOTable.current.push(
                                            p.extPurchaseOrderId ?? 0
                                          );
                                          render();
                                        }
                                      }}
                                    >
                                      Expand/Collapse
                                    </button>
                                  </div> */}
                                </div>
                                {/* {JSON.stringify(expandedPOTable.current)} */}
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* <div>
                <div>
                  <strong>DJOs</strong>
                </div>

                <div>
                  <div>
                    <Select
                      value={crmDjos.djos
                        .map((d) => ({
                          label: `${d.text}: ${d.accountName}`,
                          value: d.value,
                        }))
                        .find(
                          (d) =>
                            `${d.value}` === `${state.job.job?.extCrmDjoId}`
                        )}
                      options={crmDjos.djos.map((d) => ({
                        label: `${d.text}: ${d.accountName}`,
                        value: d.value,
                      }))}
                      onChange={(v) => {
                        const val = v as {
                          label: string | undefined;
                          value: string | undefined;
                        };

                        const newJobDjo: JobDJO = {
                          ...initialJobDJO,
                          uuid: v4(),
                          extDjoId: val.value
                            ? isNaN(parseInt(val.value))
                              ? 0
                              : parseInt(val.value)
                            : null,
                        };

                        setState({
                          ...state,
                          job: {
                            ...state.job,
                            job: state.job.job
                              ? {
                                  ...state.job.job,
                                  jobDjos: state.job.job.jobDjos
                                    ? [...state.job.job.jobDjos, newJobDjo]
                                    : [newJobDjo],
                                }
                              : state.job.job,
                          },
                        });
                      }}
                      placeholder="DJO..."
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                </div>

                <div>
                  <ol>
                    {state.job.job?.jobDjos?.map((d) => {
                      return (
                        <>
                          <li>
                            <div>
                              {
                                crmDjos.djos.find(
                                  (dx) => `${dx.value}` === `${d.extDjoId}`
                                )?.text
                              }
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ol>
                </div>
              </div> */}

              <div className="d-flex my-1 align-items-center">
                <div className="d-flex align-items-center my-1 mx-1">
                  <h4>Priority:</h4>
                  <div className="ml-2">
                    <ThemeProvider theme={greenTheme}>
                      {[
                        JobPriority.Low,
                        JobPriority.Medium,
                        JobPriority.High,
                      ].map((priority) => (
                        <JobPriorityView
                          priorityState={state.jobPriority}
                          priority={priority}
                        />
                      ))}
                    </ThemeProvider>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center my-2">
                <h4>Job type:</h4>
                <ThemeProvider theme={greyTheme}>
                  <div className="ml-2">
                    <Button
                      variant={
                        state.jobTypeSelections.includes(
                          JobTypeSelection.Cabinet
                        )
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                      onClick={() =>
                        handleChangeJobTypeSelection(JobTypeSelection.Cabinet)
                      }
                      size="large"
                    >
                      <div className="d-flex flex-column align-items-center">
                        <MeetingRoomIcon />
                        Cabinet
                      </div>
                    </Button>
                  </div>
                  <div className="ml-2">
                    <Button
                      variant={
                        state.jobTypeSelections.includes(
                          JobTypeSelection.Wiring
                        )
                          ? "contained"
                          : "outlined"
                      }
                      color="primary"
                      onClick={() =>
                        handleChangeJobTypeSelection(JobTypeSelection.Wiring)
                      }
                      size="large"
                    >
                      <div className="d-flex flex-column align-items-center">
                        <PowerIcon />
                        Wiring
                      </div>
                    </Button>
                  </div>
                </ThemeProvider>
                <div className="ml-2">
                  {!state.jobTypeSelections.includes(JobTypeSelection.Wiring) &&
                  !state.jobTypeSelections.includes(
                    JobTypeSelection.Cabinet
                  ) ? (
                    <h4 style={{ color: red[400] }}>
                      Please select at least one job type.
                    </h4>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center my-2">
                <AsyncAutocomplete
                  url={`${process.env.REACT_APP_BASE_URL}/rals/search`}
                  label="RAL"
                  value={state.job.job?.ral ?? null}
                  onChange={(value: any) =>
                    handleChangeOutstandingJobRal(value)
                  }
                  param="name"
                />
                <div className="ml-2">
                  <a target="_blank" href="/#/rals/new">
                    <Button size="small" color="primary" variant="outlined">
                      Add
                    </Button>
                  </a>
                </div>
              </div>

              <div className="d-flex mt-2">
                <div className="d-flex align-items-center">
                  <AsyncAutocomplete
                    url={`${process.env.REACT_APP_BASE_URL}/roles/byname/Sales/users`}
                    label="Sales"
                    value={state.job.job?.sales ?? ""}
                    onChange={(value: any) =>
                      handleChangeOutstandingJobSales(value)
                    }
                    param="name"
                  />
                  <div className="ml-2">
                    <a target="_blank" href="/#/users/new">
                      <Button size="small" color="primary" variant="outlined">
                        Add
                      </Button>
                    </a>
                  </div>
                </div>
                <div className="ml-2">
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="Production Deadline"
                    size="small"
                    format="dd/MM/yyyy"
                    value={
                      state.job.job?.productionDeadline
                        ? new Date(state.job.job?.productionDeadline)
                        : new Date()
                    }
                    onChange={(value: MaterialUiPickersDate) =>
                      handleChangeProductionDeadline(value)
                    }
                  />
                </div>
                <div className="ml-2">
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="QC Deadline"
                    size="small"
                    format="dd/MM/yyyy"
                    value={
                      state.job.job?.deadline
                        ? new Date(state.job.job?.deadline)
                        : new Date()
                    }
                    onChange={(value: MaterialUiPickersDate) =>
                      handleChangeOverallDeadline(value)
                    }
                  />
                </div>
                <div className="ml-2">
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="Delivery Deadline"
                    size="small"
                    format="dd/MM/yyyy"
                    value={
                      state.job.job?.deliveryDeadline
                        ? new Date(state.job.job?.deliveryDeadline)
                        : new Date()
                    }
                    onChange={(value: MaterialUiPickersDate) =>
                      handleChangeDeliveryDeadline(value)
                    }
                  />
                </div>
              </div>

              <div className="my-4">
                <Divider />

                <h4>Engineering BOM</h4>

                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div>Select BoMs</div>

                    <div>
                      <Autocomplete
                        fullWidth
                        onChange={(_, b) => {
                          if (
                            b
                            // &&
                            // !state.job.jobBomLeveleds
                            //   ?.filter((b) => !b.jobBomLeveled?.hidden)
                            //   ?.find(
                            //     (bx) =>
                            //       bx.jobBomLeveled?.bomLeveled?.id ===
                            //       b.bomLeveled?.id
                            //   )
                          ) {
                            const foundApproval = bomApprovals.current.find(
                              (a) =>
                                `${a?.extBomLeveledId}` ===
                                `${b.bomLeveled?.id}`
                            );
                            const appr = `(Approved ${
                              foundApproval?.pics?.filter(
                                (p: any) =>
                                  !p?.deletedAt &&
                                  p?.status === 1 &&
                                  p?.approvalDate
                              )?.length
                            }/${
                              foundApproval?.pics?.filter(
                                (p: any) => !p?.deletedAt
                              )?.length
                            })`;

                            const total =
                              foundApproval?.pics?.filter(
                                (p: any) => !p?.deletedAt
                              )?.length ?? 0;

                            const approved =
                              foundApproval?.pics?.filter(
                                (p: any) =>
                                  !p?.deletedAt &&
                                  p?.status === 1 &&
                                  p?.approvalDate
                              )?.length ?? 0;

                            if (total === 0) {
                              alert(`No PIC Approval in BOM list: ${b.bomLeveled?.name}.`);
                              return;
                            }

                            if (approved < total) {
                              alert(`PIC Approval incomplete: ${b.bomLeveled?.name}.`);
                              return;
                            }

                            setState({
                              ...state,
                              job: state.job
                                ? {
                                    ...state.job,
                                    jobBomLeveleds: state.job.jobBomLeveleds
                                      ? [
                                          ...state.job.jobBomLeveleds,
                                          {
                                            ...defaultJobBomLeveledView,
                                            jobBomLeveled: {
                                              ...defaultJobBomLeveled,
                                              bomLeveled: b.bomLeveled,
                                            },
                                          },
                                        ]
                                      : state.job.jobBomLeveleds,
                                  }
                                : state.job,
                            });
                          } else {
                            alert("That BOM is already selected.");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            placeholder="Select BOM"
                            {...params}
                            size="small"
                          />
                        )}
                        getOptionLabel={(b) => {
                          const foundApproval = bomApprovals.current.find(
                            (a) =>
                              `${a?.extBomLeveledId}` === `${b.bomLeveled?.id}`
                          );
                          return `${b.bomLeveled?.name ?? ""} ${
                            foundApproval
                              ? `(Approved ${
                                  foundApproval?.pics?.filter(
                                    (p: any) =>
                                      !p?.deletedAt &&
                                      p?.status === 1 &&
                                      p?.approvalDate
                                  )?.length
                                }/${
                                  foundApproval?.pics?.filter(
                                    (p: any) => !p?.deletedAt
                                  )?.length
                                })`
                              : `(No Approval)`
                          }`;
                        }}
                        options={bomLeveleds}
                      />
                    </div>

                    <table className="table table-sm">
                      <tr>
                        {[
                          "#",
                          "Qty",
                          "PanelCode",
                          "BOM",
                          "Approvals",
                          "Link date",
                        ].map((h) => {
                          return (
                            <>
                              <th
                                style={{ position: "sticky", top: 0 }}
                                className="bg-dark text-light p-0 m-0"
                              >
                                {h}
                              </th>
                            </>
                          );
                        })}
                      </tr>
                      {state.job.jobBomLeveleds
                        ?.filter((b) => !b.jobBomLeveled?.hidden)
                        ?.map((b, i) => {
                          return (
                            <tr key={i} className="border border-dark p-0 m-0">
                              {/* Ordering */}
                              <td className="border border-dark p-0 m-0">
                                {i + 1}
                              </td>

                              {/* Quantity Input */}
                              <td className="border border-dark p-0 m-0">
                                <input
                                  onBlur={(e) => {
                                    setState({
                                      ...state,
                                      job: state.job
                                        ? {
                                            ...state.job,
                                            jobBomLeveleds: state.job
                                              .jobBomLeveleds
                                              ? state.job.jobBomLeveleds.map(
                                                  (bx) =>
                                                    bx &&
                                                    bx.jobBomLeveled?.bomLeveled
                                                      ?.id ===
                                                      b?.jobBomLeveled
                                                        ?.bomLeveled?.id
                                                      ? {
                                                          ...bx,
                                                          jobBomLeveled:
                                                            bx.jobBomLeveled
                                                              ? {
                                                                  ...bx.jobBomLeveled,
                                                                  qty: isNaN(
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  )
                                                                    ? bx
                                                                        .jobBomLeveled
                                                                        .qty
                                                                    : parseInt(
                                                                        e.target
                                                                          .value
                                                                      ),
                                                                }
                                                              : bx.jobBomLeveled,
                                                        }
                                                      : bx
                                                )
                                              : state.job.jobBomLeveleds,
                                          }
                                        : state.job,
                                    });
                                  }}
                                  style={{ width: 75 }}
                                  className="form-control mx-1"
                                  defaultValue={`${b?.jobBomLeveled?.qty}`}
                                />
                              </td>

                              {/* Select Panel Code */}
                              <td className="border border-dark p-0 m-0">
                                <div style={{ minWidth: 200 }}>
                                  <Select
                                    placeholder="Select panel code..."
                                    getOptionLabel={(c) =>
                                      `${c.type}:${c.name}:${c.qty} unit(s)`
                                    }
                                    value={state.job.job?.panelCodes?.find(
                                      (c) =>
                                        `${c.id}` ===
                                        `${b.jobBomLeveled?.extPanelCodeId}`
                                    )}
                                    options={state.job.job?.panelCodes?.filter(
                                      (c) => c.id
                                    )}
                                    onChange={(v) => {
                                      const val = v as PanelCode;

                                      if (
                                        state.job.jobBomLeveleds
                                          ?.filter(
                                            (b) => !b.jobBomLeveled?.hidden
                                          )
                                          ?.find(
                                            (bx) =>
                                              val.id &&
                                              bx.jobBomLeveled
                                                ?.extPanelCodeId &&
                                              bx.jobBomLeveled
                                                ?.extPanelCodeId === val.id
                                          )
                                      ) {
                                        alert(
                                          "That panel code is already selected."
                                        );
                                        return;
                                      }

                                      setState({
                                        ...state,
                                        job: state.job
                                          ? {
                                              ...state.job,
                                              jobBomLeveleds:
                                                state.job.jobBomLeveleds
                                                  ?.filter(
                                                    (b) =>
                                                      !b.jobBomLeveled?.hidden
                                                  )
                                                  .map((bx, ix) =>
                                                    bx && ix === i
                                                      ? {
                                                          ...bx,
                                                          jobBomLeveled:
                                                            bx.jobBomLeveled
                                                              ? {
                                                                  ...bx.jobBomLeveled,
                                                                  extPanelCodeId:
                                                                    val.id,
                                                                }
                                                              : bx.jobBomLeveled,
                                                        }
                                                      : bx
                                                  ) ?? [],
                                            }
                                          : state.job,
                                      });
                                    }}
                                  />
                                </div>
                              </td>

                              {/* Job Bom Leveled Name */}
                              <td
                                className="border border-dark p-0 m-0"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setState({
                                    ...state,
                                    job: state.job
                                      ? {
                                          ...state.job,
                                          jobBomLeveleds:
                                            state.job.jobBomLeveleds?.map(
                                              (bx) =>
                                                bx &&
                                                bx.jobBomLeveled?.bomLeveled
                                                  ?.id ===
                                                  b?.jobBomLeveled?.bomLeveled
                                                    ?.id
                                                  ? {
                                                      ...bx,
                                                      jobBomLeveled:
                                                        bx.jobBomLeveled
                                                          ? {
                                                              ...bx.jobBomLeveled,
                                                              hidden: true,
                                                            }
                                                          : bx.jobBomLeveled,
                                                    }
                                                  : bx
                                            ) ?? [],
                                        }
                                      : state.job,
                                  });
                                }}
                              >
                                {b.jobBomLeveled?.bomLeveled?.name &&
                                b.jobBomLeveled?.bomLeveled?.name !== "" ? (
                                  b.jobBomLeveled?.bomLeveled?.name
                                ) : (
                                  <strong className="text-danger">
                                    No name
                                  </strong>
                                )}
                              </td>

                              {/* Need Approval */}
                              <td className="border border-dark p-0 m-0">
                                <div className="d-flex">
                                  <div className="mx-2">Need approval:</div>
                                  {b.jobBomLeveled?.bomLeveled?.approvals?.map(
                                    (a) => {
                                      const foundUser = ctx?.extUsers.find(
                                        (u) => `${u.id}` === `${a.extUserId}`
                                      );

                                      const wo = jobBomLeveledWos.current.find(
                                        (tL) =>
                                          `${tL.taskList?.extBomLeveledId}` ===
                                          `${b.jobBomLeveled?.bomLeveled?.id}`
                                      );

                                      const mt =
                                        wo?.taskList?.meetingTasks?.find((mt) =>
                                          mt.meetingTaskInCharges.find(
                                            (c) =>
                                              `${c.extUserId}` ===
                                              `${a.extUserId}`
                                          )
                                        );

                                      return (
                                        <div
                                          key={a.extUserId}
                                          className={`${
                                            mt?.status === "COMPLETED"
                                              ? `text-success`
                                              : `text-danger`
                                          }`}
                                        >
                                          {foundUser?.name}
                                          {mt?.status}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </td>

                              <td className="border border-dark p-0 m-0">
                                {intlFormat({
                                  date: b.jobBomLeveled?.createdAt,
                                  dateStyle: "medium",
                                  timeStyle: "short",
                                })}
                              </td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>

                  {/* <div className="flex-grow-1">
                    <div>Select FEPs</div>

                    <div>
                      <Autocomplete
                        fullWidth
                        onChange={(_, f) => {
                          if (
                            f &&
                            !state.job.jobFepLeveleds?.find(
                              (fx) => fx.jobFepLeveled?.fepLeveled?.id === f.id
                            )
                          ) {
                            setState({
                              ...state,
                              job: state.job
                                ? {
                                    ...state.job,
                                    jobFepLeveleds: state.job.jobFepLeveleds
                                      ? [
                                          ...state.job.jobFepLeveleds,
                                          {
                                            ...defaultJobFepLeveledView,
                                            jobFepLeveled: {
                                              ...defaultJobFepLeveled,
                                              fepLeveled: f,
                                            },
                                          },
                                        ]
                                      : state.job.jobFepLeveleds,
                                  }
                                : state.job,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            placeholder="Select FEP"
                            {...params}
                            size="small"
                          />
                        )}
                        getOptionLabel={(f) => f.name ?? ""}
                        options={fepLeveleds}
                      />
                    </div>

                    <div>
                      <ol>
                        {state.job.jobFepLeveleds?.map((f) => {
                          return (
                            <li>
                              <div>{f.jobFepLeveled?.fepLeveled?.name}</div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  </div> */}
                </div>

                <Divider className="border border-dark" />
              </div>

              {/* <div>
                <h4>FEP & BOM original</h4>
              </div>

              <div>
                {" "}
                Multi BOM:{" "}
                <Switch
                  checked={multiBom}
                  onClick={() => {
                    setMultiBom(!multiBom);
                  }}
                />
              </div>

              <div className="mt-2" style={{ width: 600 }}>
                <AsyncSelectWrapper<BomDocument>
                  label="BOM"
                  placeholder="Select BOM..."
                  url={`${process.env.REACT_APP_BASE_URL}/bomdocuments/search`}
                  param="name"
                  value={
                    state.job.job?.bomName === ""
                      ? null
                      : {
                          ...initialBomDocument,
                          name: state.job.job?.bomName ?? "",
                        }
                  }
                  onChange={handleSelectBom}
                />
              </div> */}

              {/* <div>
            {" "}
            Multi FEP:{" "}
            <Switch
              checked={multiFep}
              onClick={() => {
                setMultiFep(!multiFep);
              }}
            />
          </div> */}

              {multiFep ? (
                <>
                  <div>
                    <Autocomplete
                      options={fepDocuments}
                      getOptionLabel={(f) => `${f.name} ${f.unitQty} unit(s)`}
                      size="small"
                      onChange={(_, f) => {
                        setState({
                          ...state,
                          job: {
                            ...state.job,
                            fepDocuments: state.job.fepDocuments
                              ? [
                                  ...state.job.fepDocuments,
                                  { ...defaultJobFep, fepDocument: f },
                                ]
                              : state.job.fepDocuments,
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </div>

                  <div>
                    <ol>
                      {state.job.fepDocuments?.map((f, i) => (
                        <li>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setjobFepDeleteIds([
                                ...jobFepDeleteIds,
                                f.id ?? 0,
                              ]);
                              setState({
                                ...state,
                                job: state.job
                                  ? {
                                      ...state.job,
                                      fepDocuments: state.job.fepDocuments
                                        ? state.job.fepDocuments.filter(
                                            (_, ix) => i !== ix
                                          )
                                        : state.job.fepDocuments,
                                    }
                                  : state.job,
                              });
                            }}
                          >{`${f.fepDocument?.name} ${f.fepDocument?.unitQty} unit(s)`}</div>
                        </li>
                      ))}
                    </ol>
                  </div>
                </>
              ) : (
                <div className="mt-2" style={{ width: 600 }}>
                  <AsyncSelectWrapper<FepDocument>
                    label="FEP"
                    placeholder="Select FEP..."
                    url={`${process.env.REACT_APP_BASE_URL}/fepdocuments/search`}
                    param="name"
                    value={
                      state.job.job?.fepName === ""
                        ? null
                        : {
                            ...initialFepDocument,
                            name: state.job.job?.fepName ?? "",
                          }
                    }
                    onChange={handleSelectFep}
                  />
                </div>
              )}

              <div>
                <h3 style={{ color: green[500] }}>
                  {state.job.job?.qty} Unit(s)
                </h3>
              </div>

              <div className="my-1">
                <div className="d-flex">
                  <form>
                    <h3>
                      Panel codes/Products{": "}
                      <strong>
                        {state.job.job?.panelCodes?.reduce(
                          (acc, pc) => acc + (pc.qty ?? 0),
                          0
                        )}{" "}
                        unit(s)
                      </strong>
                    </h3>
                    <div className="d-flex align-items-center">
                      <div>
                        <TextField
                          label="Panel Type"
                          defaultValue={state.panelCode.type}
                          onBlur={(e) =>
                            setState({
                              ...state,
                              panelCode: {
                                ...state.panelCode,
                                type: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="ml-1">
                        <TextField
                          label="Panel Code"
                          defaultValue={state.panelCode.name}
                          onBlur={(e) =>
                            setState({
                              ...state,
                              panelCode: {
                                ...state.panelCode,
                                name: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      {/* <div className="ml-1">
                        <TextField
                          label="Serial Number"
                          value={state.panelCode.serialNumber}
                          onChange={(e) =>
                            setState({
                              ...state,
                              panelCode: {
                                ...state.panelCode,
                                serialNumber: e.target.value,
                              },
                            })
                          }
                        />
                      </div> */}
                      <div className="ml-1">
                        <TextField
                          label="Connections"
                          type="number"
                          value={state.panelCode.connections}
                          onBlur={(e) =>
                            setState({
                              ...state,
                              panelCode: {
                                ...state.panelCode,
                                connections: isNaN(parseInt(e.target.value))
                                  ? 0
                                  : parseInt(e.target.value),
                              },
                            })
                          }
                        />
                      </div>
                      <div className="ml-1">
                        Deadline
                        <Form.Control
                          onChange={handleChangeNewPanelCodeDeadline}
                          value={
                            state.panelCode.deadline
                              ? state.panelCode.deadline
                              : makeDateString(new Date())
                          }
                          type="date"
                        />
                      </div>
                      <div className="ml-1">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleAddPanelCode}
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
                <div>
                  Show table processes?{" "}
                  <input
                    type="checkbox"
                    checked={showTableProcesses ?? false}
                    onClick={() => {
                      setShowTableProcesses(!showTableProcesses);
                    }}
                  />
                </div>
                <div>
                  Show closed products?{" "}
                  <input
                    type="checkbox"
                    checked={showClosedProducts ?? false}
                    onClick={() => {
                      setShowClosedProducts(!showClosedProducts);
                    }}
                  />
                </div>
                {/* <div className="d-flex">
                  Show warehouse item?{" "}
                  <input
                    type="checkbox"
                    checked={showWarehouseItem ?? false}
                    onClick={() => {
                      setShowWarehouseItem(!showWarehouseItem);
                    }}
                  />
                  {(items?.items.length ?? 0) > 0 ? (
                    <></>
                  ) : (
                    <>
                      <div>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            fetchItemsData();
                          }}
                        >
                          Get Warehouse Item
                        </button>
                      </div>
                    </>
                  )}
                </div> */}

                <div
                  style={{ marginTop: 15, height: "65vh", resize: "vertical" }}
                  className="overflow-auto border border-dark shadow shadow-md"
                >
                  <table
                    className="table table-sm "
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {[
                        "#",
                        "Type",
                        "Name",
                        "Category",
                        "SN",
                        "SN Manual",
                        // "SN Rect",
                        "Connection",
                        "Qty",
                        "Deadline",
                        "JDP Preset",
                        `Prog Tree`,
                        "Product Tree",
                        // "Default QC/QA",
                        "BOM",

                        ...(showWarehouseItem ? ["Warehouse Item"] : []),
                        "Go to BOM",
                        "MI Item",
                        "Closed?",
                        "Action",
                        "PO Item",
                        "Remarks",
                      ].map((h) => {
                        return (
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        );
                      })}
                    </tr>

                    {state.job.job?.panelCodes
                      ?.filter((c) => (showClosedProducts ? true : !c.closed))
                      ?.map((panelCode, i) => (
                        <tr>
                          <td className="border border-dark">{i + 1}</td>

                          <td className="border border-dark">
                            <div style={{ width: 300 }}>
                              <input
                                key={`pcode-type-${panelCode.uuid}`}
                                placeholder="Type..."
                                defaultValue={panelCode.type ?? ""}
                                className="form-control form-control-sm"
                                onBlur={(e) => {
                                  handleChangePanelCodeType(
                                    panelCode.uuid,
                                    e.target.value
                                  );
                                }}
                              />
                              {/* {panelCode.type} */}
                            </div>
                          </td>

                          <td className="border border-dark">
                            <div style={{ width: 200 }}>
                              <input
                                key={`pcode-name-${panelCode.uuid}`}
                                placeholder="Name..."
                                defaultValue={panelCode.name}
                                className="form-control form-control-sm"
                                onBlur={(e) => {
                                  handleChangePanelCodeName(
                                    panelCode.uuid,
                                    e.target.value
                                  );
                                }}
                              />
                              {/* {panelCode.name} */}
                            </div>
                          </td>

                          <td
                            className="border border-dark"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <form>
                              {(
                                [
                                  "CabinetOnly",
                                  "WiringOnly",
                                  "CabinetWiring",
                                  "Other",
                                ] as PanelCodeType[]
                              ).map((pct) => (
                                <>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setState({
                                        ...state,
                                        job: state.job
                                          ? {
                                              ...state.job,
                                              job: state.job.job
                                                ? {
                                                    ...state.job.job,
                                                    panelCodes: state.job.job
                                                      .panelCodes
                                                      ? state.job.job.panelCodes.map(
                                                          (pcx) =>
                                                            pcx &&
                                                            pcx.uuid ===
                                                              panelCode.uuid
                                                              ? {
                                                                  ...pcx,
                                                                  panelCodeType:
                                                                    pct,
                                                                }
                                                              : pcx
                                                        )
                                                      : state.job.job
                                                          .panelCodes,
                                                  }
                                                : state.job.job,
                                            }
                                          : state.job,
                                      });
                                    }}
                                  >
                                    <input
                                      style={{ cursor: "pointer" }}
                                      checked={pct === panelCode.panelCodeType}
                                      type="radio"
                                    ></input>
                                    <label style={{ cursor: "pointer" }}>
                                      {pct}
                                    </label>
                                  </div>
                                </>
                              ))}
                            </form>
                          </td>

                          <td className="border border-dark">
                            <div style={{ width: 200 }}>
                              <button
                                onClick={() => {
                                  if (!panelCode.id) {
                                    alert("Please save panel code first.");
                                    return;
                                  }

                                  if (
                                    (panelCode.panelCodeSerialNumbers?.length ??
                                      0) >= (panelCode.qty ?? 0)
                                  ) {
                                    alert(
                                      "Cannot add more serial numbers. Please void first."
                                    );
                                    return;
                                  }

                                  const newPanelCodeSerialNumbers = [
                                    ...Array(
                                      (panelCode.qty ?? 0) -
                                        (panelCode.panelCodeSerialNumbers
                                          ?.length ?? 0)
                                    ),
                                  ].map((_, i) => {
                                    return {
                                      ...defaultPanelCodeSerialNumber,
                                      uuid: uuidv4(),
                                    } as PanelCodeSerialNumber;
                                  });

                                  setState({
                                    ...state,
                                    job: state.job
                                      ? {
                                          ...state.job,
                                          job: state.job.job
                                            ? {
                                                ...state.job.job,
                                                panelCodes: state.job.job
                                                  .panelCodes
                                                  ? state.job.job.panelCodes.map(
                                                      (pcx) =>
                                                        pcx &&
                                                        pcx.uuid ===
                                                          panelCode.uuid
                                                          ? {
                                                              ...pcx,
                                                              panelCodeSerialNumbers:
                                                                pcx.panelCodeSerialNumbers
                                                                  ? [
                                                                      ...pcx.panelCodeSerialNumbers,
                                                                      ...newPanelCodeSerialNumbers,
                                                                    ]
                                                                  : [
                                                                      ...newPanelCodeSerialNumbers,
                                                                    ],
                                                            }
                                                          : pcx
                                                    )
                                                  : state.job.job.panelCodes,
                                              }
                                            : state.job.job,
                                        }
                                      : state.job,
                                  });
                                }}
                                className="btn btn-sm btn-primary"
                              >
                                <small>
                                  <Add />
                                  SN{" "}
                                </small>
                              </button>
                              <ol>
                                {panelCode.panelCodeSerialNumbers?.map(
                                  (pcsn) => {
                                    const idIncremented =
                                      getPanelCodeCompleteSerialNumber(
                                        panelCode,
                                        pcsn
                                      );
                                    return (
                                      <>
                                        <li>
                                          <div className="d-flex">
                                            <div
                                              onClick={() => {
                                                setState({
                                                  ...state,
                                                  job: state.job
                                                    ? {
                                                        ...state.job,
                                                        job: state.job.job
                                                          ? {
                                                              ...state.job.job,
                                                              panelCodes: state
                                                                .job.job
                                                                .panelCodes
                                                                ? state.job.job.panelCodes.map(
                                                                    (pcx) =>
                                                                      pcx &&
                                                                      pcx.uuid ===
                                                                        panelCode.uuid
                                                                        ? {
                                                                            ...pcx,
                                                                            panelCodeSerialNumbers:
                                                                              pcx.panelCodeSerialNumbers
                                                                                ? pcx.panelCodeSerialNumbers.filter(
                                                                                    (
                                                                                      pcsnx
                                                                                    ) =>
                                                                                      `${pcsnx.uuid}` !==
                                                                                      `${pcsn.uuid}`
                                                                                  )
                                                                                : pcx.panelCodeSerialNumbers,
                                                                          }
                                                                        : pcx
                                                                  )
                                                                : state.job.job
                                                                    .panelCodes,
                                                            }
                                                          : state.job.job,
                                                      }
                                                    : state.job,
                                                });
                                                setpanelCodeSerialNumberDeleteIds(
                                                  [
                                                    ...panelCodeSerialNumberDeleteIds,
                                                    `${pcsn.id}`,
                                                  ]
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {pcsn.id ? (
                                                <>
                                                  <span
                                                    className={
                                                      state.job.job?.jobPanelCodeSerialNumberDones?.find(
                                                        (d) =>
                                                          d
                                                            .panelCodeSerialNumber
                                                            ?.id === pcsn.id
                                                      )
                                                        ? `text-success`
                                                        : `text-danger`
                                                    }
                                                  >
                                                    <strong>
                                                      {idIncremented} (
                                                      {
                                                        panelCode.panelCodeSerialNumberRects?.filter(
                                                          (r) =>
                                                            `${r.extPanelCodeSerialNumberId}` ===
                                                            `${pcsn.id}`
                                                        ).length
                                                      }
                                                      )
                                                    </strong>
                                                  </span>{" "}
                                                </>
                                              ) : (
                                                <div className="text-danger">
                                                  <small>Unsaved</small>
                                                </div>
                                              )}
                                            </div>
                                            <div>
                                              <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                  if (!pcsn.id) {
                                                    window.alert(
                                                      "Please save serial number first."
                                                    );
                                                    return;
                                                  }

                                                  setOpenSNRectModal(true);
                                                  setSelectedPanelCode(
                                                    panelCode
                                                  );
                                                  setSelectedRectSerialNumber(
                                                    pcsn
                                                  );
                                                  setSelectedRectSerialNumberManual(
                                                    null
                                                  );
                                                }}
                                              >
                                                <small>
                                                  <Add />
                                                  Rect
                                                </small>
                                              </button>
                                            </div>
                                          </div>

                                          <div>
                                            {panelCode.panelCodeSerialNumberRects
                                              ?.filter(
                                                (r) =>
                                                  `${r.extPanelCodeSerialNumberId}` ===
                                                  `${pcsn.id}`
                                              )
                                              .map((r) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <small>
                                                        <div>{r.name}</div>
                                                      </small>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                          </div>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </ol>
                            </div>
                          </td>

                          <td className="border border-dark">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                if (!panelCode.id) {
                                  alert("Please save panel code first.");
                                  return;
                                }

                                setState({
                                  ...state,
                                  job: state.job
                                    ? {
                                        ...state.job,
                                        job: state.job.job
                                          ? {
                                              ...state.job.job,
                                              panelCodes: state.job.job
                                                .panelCodes
                                                ? state.job.job.panelCodes?.map(
                                                    (pcx) =>
                                                      pcx.uuid ===
                                                      panelCode.uuid
                                                        ? {
                                                            ...pcx,
                                                            panelCodeSerialNumberManuals:
                                                              pcx.panelCodeSerialNumberManuals
                                                                ? [
                                                                    ...pcx.panelCodeSerialNumberManuals,
                                                                    {
                                                                      ...initialPanelCodeSerialNumberManual,
                                                                      uuid: v4(),
                                                                    },
                                                                  ]
                                                                : [
                                                                    {
                                                                      ...initialPanelCodeSerialNumberManual,
                                                                      uuid: v4(),
                                                                    },
                                                                  ],
                                                          }
                                                        : pcx
                                                  )
                                                : state.job.job.panelCodes,
                                            }
                                          : state.job.job,
                                      }
                                    : state.job,
                                });
                              }}
                            >
                              <small>
                                <Add />
                                SN
                              </small>
                            </button>
                            <div>
                              <ol>
                                {panelCode.panelCodeSerialNumberManuals?.map(
                                  (m) => {
                                    return (
                                      <>
                                        <li>
                                          <div className="d-flex">
                                            <div
                                              style={{
                                                minWidth: 100,
                                              }}
                                            >
                                              <input
                                                key={`pcsnm-${panelCode.uuid}-${m.uuid}`}
                                                placeholder="SN Name..."
                                                className="form-control form-control-sm"
                                                defaultValue={
                                                  m.serialNumber ?? ""
                                                }
                                                onBlur={(e) => {
                                                  setState({
                                                    ...state,
                                                    job: state.job
                                                      ? {
                                                          ...state.job,
                                                          job: state.job.job
                                                            ? {
                                                                ...state.job
                                                                  .job,
                                                                panelCodes:
                                                                  state.job.job
                                                                    .panelCodes
                                                                    ? state.job.job.panelCodes?.map(
                                                                        (pcx) =>
                                                                          pcx.uuid ===
                                                                          panelCode.uuid
                                                                            ? {
                                                                                ...pcx,
                                                                                panelCodeSerialNumberManuals:
                                                                                  pcx.panelCodeSerialNumberManuals?.map(
                                                                                    (
                                                                                      mx
                                                                                    ) =>
                                                                                      mx.uuid ===
                                                                                      m.uuid
                                                                                        ? {
                                                                                            ...mx,
                                                                                            serialNumber:
                                                                                              e
                                                                                                .target
                                                                                                .value,
                                                                                          }
                                                                                        : mx
                                                                                  ) ??
                                                                                  [],
                                                                              }
                                                                            : pcx
                                                                      )
                                                                    : state.job
                                                                        .job
                                                                        .panelCodes,
                                                              }
                                                            : state.job.job,
                                                        }
                                                      : state.job,
                                                  });
                                                }}
                                              />
                                            </div>
                                            <div>
                                              <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => {
                                                  const confirmed =
                                                    window.confirm(
                                                      "Really delete?"
                                                    );

                                                  if (!confirmed) {
                                                    return;
                                                  }

                                                  setpanelCodeSerialNumberManualDeleteIds(
                                                    [
                                                      ...panelCodeSerialNumberManualDeleteIds,
                                                      m.id ?? 0,
                                                    ]
                                                  );

                                                  setState({
                                                    ...state,
                                                    job: state.job
                                                      ? {
                                                          ...state.job,
                                                          job: state.job.job
                                                            ? {
                                                                ...state.job
                                                                  .job,
                                                                panelCodes:
                                                                  state.job.job
                                                                    .panelCodes
                                                                    ? state.job.job.panelCodes?.map(
                                                                        (pcx) =>
                                                                          pcx.uuid ===
                                                                          panelCode.uuid
                                                                            ? {
                                                                                ...pcx,
                                                                                panelCodeSerialNumberManuals:
                                                                                  pcx.panelCodeSerialNumberManuals?.filter(
                                                                                    (
                                                                                      mx
                                                                                    ) =>
                                                                                      mx.uuid !==
                                                                                      m.uuid
                                                                                  ) ??
                                                                                  pcx.panelCodeSerialNumberManuals,
                                                                              }
                                                                            : pcx
                                                                      )
                                                                    : state.job
                                                                        .job
                                                                        .panelCodes,
                                                              }
                                                            : state.job.job,
                                                        }
                                                      : state.job,
                                                  });
                                                }}
                                              >
                                                <Delete />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div>
                                              <button
                                                onClick={() => {
                                                  if (!m.id) {
                                                    window.alert(
                                                      "Please save serial number first."
                                                    );
                                                    return;
                                                  }

                                                  setOpenSNRectModal(true);
                                                  setSelectedPanelCode(
                                                    panelCode
                                                  );
                                                  setSelectedRectSerialNumberManual(
                                                    m
                                                  );
                                                  setSelectedRectSerialNumber(
                                                    null
                                                  );
                                                }}
                                                className="btn btn-sm btn-primary"
                                              >
                                                <small>
                                                  <Add />
                                                  Rect
                                                </small>
                                              </button>
                                            </div>
                                            <div>
                                              <small>
                                                {m.serialNumber} (
                                                {
                                                  panelCode.panelCodeSerialNumberRects?.filter(
                                                    (r) =>
                                                      `${r.extPanelCodeSerialNumberManualId}` ===
                                                      `${m.id}`
                                                  ).length
                                                }
                                                )
                                              </small>
                                            </div>
                                          </div>
                                          <div>
                                            <ol>
                                              {panelCode.panelCodeSerialNumberRects
                                                ?.filter(
                                                  (r) =>
                                                    `${r.extPanelCodeSerialNumberManualId}` ===
                                                    `${m.id}`
                                                )
                                                .map((r) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <small>
                                                          <div>{r.name}</div>
                                                        </small>
                                                      </li>
                                                    </>
                                                  );
                                                })}
                                            </ol>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </ol>
                            </div>
                          </td>
                          {/* <td className="border border-dark">
                            <div>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    job: state.job
                                      ? {
                                          ...state.job,
                                          job: state.job.job
                                            ? {
                                                ...state.job.job,
                                                panelCodes: state.job.job
                                                  .panelCodes
                                                  ? state.job.job.panelCodes?.map(
                                                      (pcx) =>
                                                        pcx.uuid ===
                                                        panelCode.uuid
                                                          ? {
                                                              ...pcx,
                                                              panelCodeSerialNumberRects:
                                                                pcx.panelCodeSerialNumberRects
                                                                  ? [
                                                                      ...pcx.panelCodeSerialNumberRects,
                                                                      {
                                                                        ...defaultPanelCodeSerialNumberRect,
                                                                        uuid: v4(),
                                                                      },
                                                                    ]
                                                                  : [
                                                                      {
                                                                        ...defaultPanelCodeSerialNumberRect,
                                                                        uuid: v4(),
                                                                      },
                                                                    ],
                                                            }
                                                          : pcx
                                                    )
                                                  : state.job.job.panelCodes,
                                              }
                                            : state.job.job,
                                        }
                                      : state.job,
                                  });
                                }}
                              >
                                <small>
                                  <Add />
                                  SN
                                </small>
                              </button>
                            </div>
                            <div>
                              <ol>
                                {panelCode.panelCodeSerialNumberRects?.map(
                                  (r) => {
                                    return (
                                      <>
                                        <li>
                                          <div className="d-flex">
                                            <input
                                              key={r.uuid}
                                              style={{ minWidth: 100 }}
                                              defaultValue={r.name ?? ""}
                                              onBlur={(e) => {
                                                setState({
                                                  ...state,
                                                  job: state.job
                                                    ? {
                                                        ...state.job,
                                                        job: state.job.job
                                                          ? {
                                                              ...state.job.job,
                                                              panelCodes: state
                                                                .job.job
                                                                .panelCodes
                                                                ? state.job.job.panelCodes?.map(
                                                                    (pcx) =>
                                                                      pcx.uuid ===
                                                                      panelCode.uuid
                                                                        ? {
                                                                            ...pcx,
                                                                            panelCodeSerialNumberRects:
                                                                              pcx.panelCodeSerialNumberRects?.map(
                                                                                (
                                                                                  mx
                                                                                ) =>
                                                                                  mx.uuid ===
                                                                                  r.uuid
                                                                                    ? {
                                                                                        ...mx,
                                                                                        name: e
                                                                                          .target
                                                                                          .value,
                                                                                      }
                                                                                    : mx
                                                                              ) ??
                                                                              [],
                                                                          }
                                                                        : pcx
                                                                  )
                                                                : state.job.job
                                                                    .panelCodes,
                                                            }
                                                          : state.job.job,
                                                      }
                                                    : state.job,
                                                });
                                              }}
                                              className="form-control form-control-sm"
                                              placeholder="SN..."
                                            />
                                            <div>
                                              <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => {
                                                  const confirmed =
                                                    window.confirm(
                                                      "Really delete?"
                                                    );

                                                  if (!confirmed) {
                                                    return;
                                                  }

                                                  setpanelCodeSerialNumberRectDeleteIds(
                                                    [
                                                      ...panelCodeSerialNumberRectDeleteIds,
                                                      r.id ?? 0,
                                                    ]
                                                  );

                                                  setState({
                                                    ...state,
                                                    job: state.job
                                                      ? {
                                                          ...state.job,
                                                          job: state.job.job
                                                            ? {
                                                                ...state.job
                                                                  .job,
                                                                panelCodes:
                                                                  state.job.job
                                                                    .panelCodes
                                                                    ? state.job.job.panelCodes?.map(
                                                                        (pcx) =>
                                                                          pcx.uuid ===
                                                                          panelCode.uuid
                                                                            ? {
                                                                                ...pcx,
                                                                                panelCodeSerialNumberRects:
                                                                                  pcx.panelCodeSerialNumberRects?.filter(
                                                                                    (
                                                                                      mx
                                                                                    ) =>
                                                                                      mx.uuid !==
                                                                                      r.uuid
                                                                                  ) ??
                                                                                  pcx.panelCodeSerialNumberRects,
                                                                              }
                                                                            : pcx
                                                                      )
                                                                    : state.job
                                                                        .job
                                                                        .panelCodes,
                                                              }
                                                            : state.job.job,
                                                        }
                                                      : state.job,
                                                  });
                                                }}
                                              >
                                                <Delete />
                                              </button>
                                            </div>
                                          </div>
                                          <small>{r.name}</small>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                              </ol>
                            </div>
                          </td> */}
                          <td className="border border-dark">
                            <div style={{ width: 75 }}>
                              <TextField
                                variant="outlined"
                                label="Connection"
                                size="small"
                                value={panelCode.connections}
                                onChange={(e) =>
                                  handleChangePanelCodeConnections(
                                    panelCode.uuid,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </td>

                          <td className="border border-dark">
                            <div style={{ width: 75 }}>
                              <TextField
                                variant="outlined"
                                label="Qty"
                                size="small"
                                defaultValue={panelCode.qty}
                                key={
                                  panelCode.uuid && panelCode.uuid !== ""
                                    ? panelCode.uuid
                                    : uuidv4()
                                }
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    job: state.job
                                      ? {
                                          ...state.job,
                                          job: state.job.job
                                            ? {
                                                ...state.job.job,
                                                panelCodes: state.job.job
                                                  .panelCodes
                                                  ? state.job.job.panelCodes.map(
                                                      (pcx) =>
                                                        pcx &&
                                                        pcx.uuid ===
                                                          panelCode.uuid
                                                          ? {
                                                              ...pcx,
                                                              qty: isNaN(
                                                                parseInt(
                                                                  e.target.value
                                                                )
                                                              )
                                                                ? pcx.qty
                                                                : parseInt(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                            }
                                                          : pcx
                                                    )
                                                  : state.job.job.panelCodes,
                                              }
                                            : state.job.job,
                                        }
                                      : state.job,
                                  })
                                }
                              />
                            </div>
                          </td>

                          <td className="border border-dark">
                            Deadline
                            <input
                              className="form-control form-control-sm"
                              type="date"
                              key={`pc-dealine-${panelCode.uuid}`}
                              onBlur={(e) =>
                                // handleChangePanelCodeDeadline(i, e)
                                setState({
                                  ...state,
                                  job: {
                                    ...state.job,
                                    job: state.job.job
                                      ? {
                                          ...state.job.job,
                                          panelCodes: state.job.job.panelCodes
                                            ? state.job.job.panelCodes.map(
                                                (c) =>
                                                  `${c.uuid}` ===
                                                  `${panelCode.uuid}`
                                                    ? {
                                                        ...c,
                                                        deadline:
                                                          e.target.value,
                                                      }
                                                    : c
                                              )
                                            : state.job.job.panelCodes,
                                        }
                                      : state.job.job,
                                  },
                                })
                              }
                              defaultValue={
                                panelCode.deadline
                                  ? panelCode.deadline
                                  : undefined
                              }
                            />
                            {/* {panelCode.deadline} */}
                          </td>

                          <td className="border border-dark">
                            <div style={{ width: 250 }}>
                              <Select
                                placeholder="Select JDP Preste..."
                                options={jdpPresets.presets.map((p) => ({
                                  label: p.name ?? "",
                                  value: p,
                                }))}
                                onChange={(v) => {
                                  const val = v as {
                                    value: PpicPanelCodeDepartmentTemplatePreset;
                                  };

                                  setState({
                                    ...state,
                                    job: state.job
                                      ? {
                                          ...state.job,
                                          job: state.job.job
                                            ? {
                                                ...state.job.job,
                                                panelCodes: state.job.job
                                                  .panelCodes
                                                  ? state.job.job.panelCodes.map(
                                                      (pcx) =>
                                                        pcx &&
                                                        pcx.uuid ===
                                                          panelCode.uuid
                                                          ? {
                                                              ...pcx,
                                                              extJdpPresetId:
                                                                val.value
                                                                  .masterJavaBaseModel
                                                                  ?.id &&
                                                                val.value
                                                                  .masterJavaBaseModel
                                                                  ?.id !== "" &&
                                                                val.value
                                                                  .masterJavaBaseModel
                                                                  ?.id !== "0"
                                                                  ? parseInt(
                                                                      val.value
                                                                        .masterJavaBaseModel
                                                                        ?.id
                                                                    )
                                                                  : pcx.extJdpPresetId,
                                                            }
                                                          : pcx
                                                    )
                                                  : state.job.job.panelCodes,
                                              }
                                            : state.job.job,
                                        }
                                      : state.job,
                                  });
                                }}
                              />
                            </div>

                            <small
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setState({
                                  ...state,
                                  job: state.job
                                    ? {
                                        ...state.job,
                                        job: state.job.job
                                          ? {
                                              ...state.job.job,
                                              panelCodes: state.job.job
                                                .panelCodes
                                                ? state.job.job.panelCodes.map(
                                                    (pcx) =>
                                                      pcx &&
                                                      pcx.uuid ===
                                                        panelCode.uuid
                                                        ? {
                                                            ...pcx,
                                                            extJdpPresetId:
                                                              null,
                                                          }
                                                        : pcx
                                                  )
                                                : state.job.job.panelCodes,
                                            }
                                          : state.job.job,
                                      }
                                    : state.job,
                                });
                              }}
                            >
                              <strong>
                                {
                                  jdpPresets.presets.find(
                                    (p) =>
                                      `${p.masterJavaBaseModel?.id}` ===
                                      `${panelCode.extJdpPresetId}`
                                  )?.name
                                }
                              </strong>
                            </small>

                            {jdpPresets.presets.find(
                              (p) =>
                                `${p.masterJavaBaseModel?.id}` ===
                                `${panelCode.extJdpPresetId}`
                            ) ? (
                              <>
                                <div>
                                  <button
                                    className="btn btn-sm btn-danger px-1 py-0"
                                    onClick={() => {
                                      panelCode.extJdpPresetId = null;
                                      render();
                                    }}
                                  >
                                    Clear
                                  </button>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </td>

                          <td
                            className="border border-dark"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <div className="d-flex">
                              {simplifiedProcessTypes.map((t) => {
                                return (
                                  <>
                                    <div>
                                      <div>
                                        <button
                                          className={`btn btn-sm ${
                                            panelCode.panelCodeMachineProgramExclusions?.find(
                                              (e) =>
                                                `${e.extSimplifiedProcessTypeId}` ===
                                                `${t.id}`
                                            )?.excluded
                                              ? `btn-outline-primary`
                                              : `btn-primary`
                                          }`}
                                          onClick={() => {
                                            if (!panelCode.id) {
                                              alert(
                                                "Please save panel code first."
                                              );
                                              return;
                                            }
                                            const foundExclusion =
                                              state.job.job?.panelCodes
                                                ?.flatMap(
                                                  (c) =>
                                                    c.panelCodeMachineProgramExclusions
                                                )
                                                ?.find(
                                                  (e) =>
                                                    // `${e?.extMachineProgramId}` ===
                                                    //   `${mp.id}` &&
                                                    `${e?.extSimplifiedProcessTypeId}` ===
                                                    `${t.id}`
                                                );

                                            const newExclusion = {
                                              ...initialMachineProgramExclusion,
                                              uuid: v4(),
                                              // extMachineProgramId: mp.id,
                                              extSimplifiedProcessTypeId: t.id,
                                              excluded: true,
                                            } as MachineProgramExclusion;

                                            setState({
                                              ...state,
                                              job: state.job
                                                ? {
                                                    ...state.job,
                                                    job: state.job.job
                                                      ? {
                                                          ...state.job.job,
                                                          panelCodes: state.job
                                                            .job.panelCodes
                                                            ? state.job.job.panelCodes.map(
                                                                (pcx) =>
                                                                  pcx &&
                                                                  pcx.uuid ===
                                                                    panelCode.uuid
                                                                    ? {
                                                                        ...pcx,
                                                                        panelCodeMachineProgramExclusions:
                                                                          foundExclusion
                                                                            ? pcx.panelCodeMachineProgramExclusions?.map(
                                                                                (
                                                                                  e
                                                                                ) =>
                                                                                  e.uuid ===
                                                                                  foundExclusion.uuid
                                                                                    ? {
                                                                                        ...e,
                                                                                        excluded:
                                                                                          !e.excluded,
                                                                                      }
                                                                                    : e
                                                                              ) ??
                                                                              []
                                                                            : [
                                                                                ...(pcx.panelCodeMachineProgramExclusions ??
                                                                                  []),
                                                                                {
                                                                                  ...newExclusion,
                                                                                },
                                                                              ],
                                                                      }
                                                                    : pcx
                                                              )
                                                            : state.job.job
                                                                .panelCodes,
                                                        }
                                                      : state.job.job,
                                                  }
                                                : state.job,
                                            });
                                          }}
                                        >
                                          {t.name?.split("")?.[0]}
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </td>

                          {(() => {
                            const jobIntegrations =
                              state.job.jobIntegrationList?.filter(
                                (jIL) =>
                                  `${jIL.jobIntegrationList?.panelCode?.id}` ===
                                  `${panelCode.id}`
                              );

                            return (
                              <>
                                {" "}
                                <td
                                  className="border border-dark"
                                  style={{
                                    backgroundColor:
                                      (jobIntegrations?.length ?? 0) > 0
                                        ? ``
                                        : `lightsalmon`,
                                  }}
                                >
                                  <div style={{ width: 300 }}>
                                    <Select
                                      placeholder="Select Product Tree..."
                                      options={integrations.map((i) => ({
                                        label: i.integration?.name ?? "",
                                        value: i,
                                      }))}
                                      getOptionLabel={(i) => `${i.label}`}
                                      onChange={(v) => {
                                        const val = (
                                          v as unknown as {
                                            label: string;
                                            value: IntegrationView;
                                          }
                                        ).value;

                                        if (!panelCode.id) {
                                          window.alert(
                                            `Selected: ${val.integration?.name} Please save panel code first.`
                                          );
                                          return;
                                        }

                                        if (
                                          !state.job.jobIntegrationList?.find(
                                            (jIL) =>
                                              `${jIL.jobIntegrationList?.panelCode?.id}` ===
                                                `${panelCode.id}` &&
                                              `${jIL.jobIntegrationList?.integration?.id}` ===
                                                `${val.integration?.id}`
                                          )
                                        ) {
                                          setState({
                                            ...state,
                                            job: {
                                              ...state.job,
                                              jobIntegrationList: state.job
                                                .jobIntegrationList
                                                ? [
                                                    ...state.job
                                                      .jobIntegrationList,
                                                    {
                                                      ...defaultJobIntegrationListView,
                                                      jobIntegrationList: {
                                                        ...defaultJobIntegrationList,
                                                        uuid: uuidv4(),
                                                        integration:
                                                          val.integration,
                                                        panelCode: {
                                                          ...initialPanelCode,
                                                          id: panelCode.id,
                                                        },
                                                      } as JobIntegrationList,
                                                    },
                                                  ]
                                                : [
                                                    {
                                                      ...defaultJobIntegrationListView,
                                                      jobIntegrationList: {
                                                        ...defaultJobIntegrationList,
                                                        uuid: uuidv4(),
                                                        integration:
                                                          val.integration,
                                                        panelCode: {
                                                          ...initialPanelCode,
                                                          id: panelCode.id,
                                                        },
                                                      } as JobIntegrationList,
                                                    },
                                                  ],
                                            },
                                          });
                                        }
                                      }}
                                      onBlur={(e) => {}}
                                    />
                                  </div>
                                  <ol>
                                    {(() => {
                                      return jobIntegrations?.map((jIL) => {
                                        return (
                                          <>
                                            <li>
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  setState({
                                                    ...state,
                                                    job: {
                                                      ...state.job,
                                                      jobIntegrationList: state
                                                        .job.jobIntegrationList
                                                        ? state.job.jobIntegrationList.filter(
                                                            (jILx, ix) =>
                                                              jILx
                                                                .jobIntegrationList
                                                                ?.uuid !==
                                                              jIL
                                                                .jobIntegrationList
                                                                ?.uuid
                                                          )
                                                        : state.job
                                                            .jobIntegrationList,
                                                    },
                                                  });
                                                  setjobIntegrationListDeleteIds(
                                                    [
                                                      ...jobIntegrationListDeleteIds,
                                                      jIL.jobIntegrationList
                                                        ?.id ?? 0,
                                                    ]
                                                  );
                                                }}
                                              >
                                                <div>
                                                  {
                                                    jIL.jobIntegrationList
                                                      ?.integration?.name
                                                  }
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      });
                                    })()}
                                  </ol>
                                </td>
                              </>
                            );
                          })()}
                          {/* <td className="border border-dark">
                            <div style={{ width: 200 }}>
                              <div className="d-flex">
                                QC:{" "}
                                <Select
                                  className="flex-grow-1"
                                  options={ctx?.extUsers}
                                  getOptionLabel={(u) => `${u.username}`}
                                  value={ctx?.extUsers.find(
                                    (u) => u.id === panelCode.extDefaultQcId
                                  )}
                                  onChange={(v) => {
                                    const val = v as ExtUser;

                                    setState({
                                      ...state,
                                      job: state.job
                                        ? {
                                            ...state.job,
                                            job: state.job.job
                                              ? {
                                                  ...state.job.job,
                                                  panelCodes: state.job.job
                                                    .panelCodes
                                                    ? state.job.job.panelCodes.map(
                                                        (pcx) =>
                                                          pcx &&
                                                          pcx.uuid ===
                                                            panelCode.uuid
                                                            ? {
                                                                ...pcx,
                                                                extDefaultQcId:
                                                                  val.id,
                                                              }
                                                            : pcx
                                                      )
                                                    : state.job.job.panelCodes,
                                                }
                                              : state.job.job,
                                          }
                                        : state.job,
                                    });
                                  }}
                                />
                              </div>
                              <div className="d-flex">
                                QA:{" "}
                                <Select
                                  className="flex-grow-1"
                                  options={ctx?.extUsers}
                                  getOptionLabel={(u) => `${u.username}`}
                                  value={ctx?.extUsers.find(
                                    (u) => u.id === panelCode.extDefaultQaId
                                  )}
                                  onChange={(v) => {
                                    const val = v as ExtUser;

                                    setState({
                                      ...state,
                                      job: state.job
                                        ? {
                                            ...state.job,
                                            job: state.job.job
                                              ? {
                                                  ...state.job.job,
                                                  panelCodes: state.job.job
                                                    .panelCodes
                                                    ? state.job.job.panelCodes.map(
                                                        (pcx) =>
                                                          pcx &&
                                                          pcx.uuid ===
                                                            panelCode.uuid
                                                            ? {
                                                                ...pcx,
                                                                extDefaultQaId:
                                                                  val.id,
                                                              }
                                                            : pcx
                                                      )
                                                    : state.job.job.panelCodes,
                                                }
                                              : state.job.job,
                                          }
                                        : state.job,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </td> */}
                          <td className="border border-dark">
                            <ol>
                              {state.job.jobBomLeveleds
                                ?.filter(
                                  (l) =>
                                    l.jobBomLeveled?.extPanelCodeId ===
                                      panelCode.id && !l.jobBomLeveled.hidden
                                )
                                .map((l) => {
                                  return (
                                    <>
                                      <li>
                                        <div>
                                          <div>
                                            <small>
                                              {
                                                l.jobBomLeveled?.bomLeveled
                                                  ?.name
                                              }{" "}
                                              x{l.jobBomLeveled?.qty}
                                            </small>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                            </ol>
                          </td>
                          {showWarehouseItem ? (
                            <>
                              {" "}
                              <td
                                className={`border border-dark ${
                                  panelCode.extItemId
                                    ? `bg-success`
                                    : `bg-warning`
                                }`}
                              >
                                {itemsLoading ? (
                                  <>
                                    <Spinner animation="border" />
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <div>
                                        <button
                                          className="btn btn-primary btn-sm"
                                          onClick={() => {
                                            setshowWarehouseItemDialog(true);
                                            setItemSelectMode("Warehouse");
                                            setSelectedPanelCodeWarehouseItem(
                                              panelCode
                                            );
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                      <div></div>
                                      {panelCode.extItemId
                                        ? (() => {
                                            const fItem = items?.items.find(
                                              (ix) =>
                                                `${ix.id}` ===
                                                `${panelCode.extItemId}`
                                            );

                                            return `${fItem?.partNum}`;
                                          })()
                                        : "No product selected"}
                                    </div>
                                  </>
                                )}
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td className="border border-dark">
                            <a
                              target="_blank"
                              href={`?panelCodeId=${
                                panelCode.id ?? "new"
                              }#/bomdocumentshierarchy/new`}
                            >
                              <button className="btn btn-sm btn-primary">
                                Go to BOM
                              </button>
                            </a>
                          </td>

                          <td
                            className={`border border-dark ${
                              panelCode.extMaterialInstallationItemId
                                ? `bg-success`
                                : `bg-warning`
                            }`}
                          >
                            {itemsLoading ? (
                              <>
                                <Spinner animation="border" />
                              </>
                            ) : (
                              <>
                                <div>
                                  <div>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        setshowWarehouseItemDialog(true);
                                        setItemSelectMode("MI");
                                        setSelectedPanelCodeMIItem(panelCode);
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                  <div></div>
                                  {panelCode.extMaterialInstallationItemId
                                    ? (() => {
                                        const fItem = items?.items.find(
                                          (ix) =>
                                            `${ix.id}` ===
                                            `${panelCode.extMaterialInstallationItemId}`
                                        );

                                        return `${fItem?.partNum}`;
                                      })()
                                    : "No MI product selected"}
                                </div>
                              </>
                            )}
                          </td>

                          <td
                            className="border border-dark"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setState({
                                ...state,
                                job: state.job
                                  ? {
                                      ...state.job,
                                      job: state.job.job
                                        ? {
                                            ...state.job.job,
                                            panelCodes: state.job.job.panelCodes
                                              ? state.job.job.panelCodes.map(
                                                  (pcx) =>
                                                    pcx.uuid === panelCode.uuid
                                                      ? {
                                                          ...pcx,
                                                          closed: !pcx.closed,
                                                        }
                                                      : pcx
                                                )
                                              : state.job.job.panelCodes,
                                          }
                                        : state.job.job,
                                    }
                                  : state.job,
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ cursor: "pointer" }}
                              checked={panelCode.closed ?? false}
                            />
                            {panelCode.closed}
                          </td>

                          <td className="border border-dark">
                            <Button
                              color="secondary"
                              variant="contained"
                              size="small"
                              onClick={() => handleDeletePanelCode(panelCode)}
                            >
                              Delete
                            </Button>
                          </td>
                          <td
                            className={`border border-dark font-weight-bold ${
                              panelCode.extCrmPurchaseOrderItemId &&
                              panelCode.extCrmPurchaseOrderItemId !== 0
                                ? `bg-success`
                                : `bg-warning`
                            }`}
                          >
                            <div className="d-flex" style={{ width: 300 }}>
                              <div className="flex-grow-1">
                                <Select
                                  placeholder="PO Item..."
                                  options={state.job.job?.jobPurchaseOrders
                                    ?.map((p) => p.extPurchaseOrderId)
                                    .flatMap(
                                      (p) =>
                                        purchaseOrders?.purchaseOrders.find(
                                          (px) => `${px.id}` === `${p}`
                                        )?.products ?? []
                                    )
                                    .map((p) => p as CrmProduct)}
                                  getOptionLabel={(p) => {
                                    const foundItem = items?.items.find(
                                      (i) => `${i.id}` === `${p.productId}`
                                    );

                                    return `${foundItem?.partName}: ${foundItem?.partDesc}: ${p.qty} unit(s)`;
                                  }}
                                  onChange={(v) => {
                                    const val = v as unknown as CrmProduct;

                                    setState({
                                      ...state,
                                      job: state.job
                                        ? {
                                            ...state.job,
                                            job: state.job.job
                                              ? {
                                                  ...state.job.job,
                                                  panelCodes: state.job.job
                                                    .panelCodes
                                                    ? state.job.job.panelCodes.map(
                                                        (pcx) =>
                                                          pcx.uuid ===
                                                          panelCode.uuid
                                                            ? {
                                                                ...pcx,
                                                                extCrmPurchaseOrderItemId:
                                                                  val.productId
                                                                    ? isNaN(
                                                                        parseInt(
                                                                          val.productId
                                                                        )
                                                                      )
                                                                      ? null
                                                                      : parseInt(
                                                                          val.productId
                                                                        )
                                                                    : null,
                                                              }
                                                            : pcx
                                                      )
                                                    : state.job.job.panelCodes,
                                                }
                                              : state.job.job,
                                          }
                                        : state.job,
                                    });
                                  }}
                                />
                                {(() => {
                                  const foundPoProduct =
                                    purchaseOrders?.purchaseOrders
                                      .flatMap((o) => o.products)
                                      .find(
                                        (p) =>
                                          `${p.productId}` ===
                                          `${panelCode.extCrmPurchaseOrderItemId}`
                                      );

                                  if (!foundPoProduct) {
                                    return "No PO product found.";
                                  }

                                  return `${foundPoProduct.partName ?? ""}:${
                                    foundPoProduct.partDesc ?? ""
                                  } (#${foundPoProduct.productId})`;
                                })()}
                              </div>
                            </div>
                          </td>
                          <td className="border border-dark">
                            <div>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  setSelectedPanelCodeForRemark(panelCode);
                                  setShowPanelCodeRemarkModal(true);
                                }}
                              >
                                <small>
                                  <Add />
                                  Remark{" "}
                                </small>
                              </button>
                            </div>
                          </td>
                          <div style={{ marginTop: 10 }}>
                            {showTableProcesses ? (
                              <>
                                {" "}
                                <TableContainer>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCellBordered
                                          style={{ cursor: "pointer" }}
                                          padding="checkbox"
                                          colSpan={2}
                                          onClick={() =>
                                            handleCheckUncheckProcessGroup(
                                              "Preparation Cable",
                                              panelCode
                                            )
                                          }
                                        >
                                          Preparation Cable{" "}
                                          <Checkbox
                                            checked={checkProcessGroup(
                                              "Preparation Cable",
                                              panelCode
                                            )}
                                          />
                                        </TableCellBordered>
                                        <TableCellBordered
                                          style={{ cursor: "pointer" }}
                                          padding="checkbox"
                                          colSpan={7}
                                          onClick={() =>
                                            handleCheckUncheckProcessGroup(
                                              "Preparation Panel",
                                              panelCode
                                            )
                                          }
                                        >
                                          Preparation Panel{" "}
                                          <Checkbox
                                            checked={checkProcessGroup(
                                              "Preparation Panel",
                                              panelCode
                                            )}
                                          />
                                        </TableCellBordered>
                                        <TableCellBordered
                                          style={{ cursor: "pointer" }}
                                          padding="checkbox"
                                          colSpan={6}
                                          onClick={() =>
                                            handleCheckUncheckProcessGroup(
                                              "Busbar",
                                              panelCode
                                            )
                                          }
                                        >
                                          Busbar{" "}
                                          <Checkbox
                                            checked={checkProcessGroup(
                                              "Busbar",
                                              panelCode
                                            )}
                                          />
                                        </TableCellBordered>
                                        <TableCellBordered
                                          style={{ cursor: "pointer" }}
                                          padding="checkbox"
                                          colSpan={4}
                                          onClick={() =>
                                            handleCheckUncheckProcessGroup(
                                              "Interkoneksi",
                                              panelCode
                                            )
                                          }
                                        >
                                          Interkoneksi{" "}
                                          <Checkbox
                                            checked={checkProcessGroup(
                                              "Interkoneksi",
                                              panelCode
                                            )}
                                          />
                                        </TableCellBordered>
                                        <TableCellBordered
                                          style={{ cursor: "pointer" }}
                                          padding="checkbox"
                                          colSpan={6}
                                          onClick={() =>
                                            handleCheckUncheckProcessGroup(
                                              "Panel Mechanical",
                                              panelCode
                                            )
                                          }
                                        >
                                          Panel Mechanical{" "}
                                          <Checkbox
                                            checked={checkProcessGroup(
                                              "Panel Mechanical",
                                              panelCode
                                            )}
                                          />
                                        </TableCellBordered>
                                      </TableRow>
                                      <TableRow>
                                        {[
                                          "Grouping",
                                          "Tubing/Crimping",
                                          "Dinrail",
                                          "Cable/Support Duct",
                                          "Pasang Terminal",
                                          "Cutout Panel",
                                          "Component",
                                          "Labeling",
                                          "Mimik/Nameplate",
                                          "Potong",
                                          "Bending",
                                          "Lubang",
                                          "Baut",
                                          "Heatshrink",
                                          "Pasang",
                                          "WK",
                                          "LR",
                                          "Interkoneksi",
                                          "Closing",
                                          "Shear",
                                          "Punch",
                                          "Bend",
                                          "Weld",
                                          "Coat",
                                          "Assembly",
                                        ].map((process) => {
                                          return (
                                            <TableCellBordered
                                              style={{ cursor: "pointer" }}
                                              padding="checkbox"
                                            >
                                              {process}&nbsp;
                                              <Checkbox
                                                checked={
                                                  panelCode.panelCodeWiringProcessTypes?.find(
                                                    (
                                                      panelCodeWiringProcessType
                                                    ) =>
                                                      panelCodeWiringProcessType
                                                        .wiringProcessType
                                                        ?.name === process
                                                  )?.includes
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  handleCheckPanelCodeWiringProcessType(
                                                    panelCode,
                                                    process
                                                  )
                                                }
                                              />
                                            </TableCellBordered>
                                          );
                                        })}
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </TableContainer>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </tr>
                      ))}
                  </table>
                </div>
              </div>

              <div className="d-flex align-items-center mt-2">
                <div>
                  <SyncAutocomplete
                    label="Reference type"
                    value={state.job.job?.referenceType}
                    onChange={(value: any) =>
                      handleChangeOutstandingJobReferenceType(value)
                    }
                    options={["PO", "BPO/Forecast", "Internal"]}
                  />
                </div>
                <div className="ml-2">
                  {state.job.job?.referenceType !== "" ? (
                    <TextField
                      size="small"
                      label={`${state.job.job?.referenceType} number`}
                      value={state.job.job?.poNumber}
                      onChange={(event: any) =>
                        handleChangeOutstandingJobPoNumber(event.target.value)
                      }
                      fullWidth
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {viewType === "ProductBatch" ? (
            <>
              <div>
                <div className="d-flex align-items-end">
                  <div>No.of batch:</div>{" "}
                  <input
                    style={{ width: 75 }}
                    placeholder="Qty..."
                    className="form-control form-control-sm mx-2"
                    defaultValue={state.job.job?.batchCount ?? ""}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        return;
                      }
                      setState({
                        ...state,
                        job: {
                          ...state.job,
                          job: state.job.job
                            ? {
                                ...state.job.job,
                                batchCount: isNaN(parseInt(e.target.value))
                                  ? state.job.job.batchCount
                                  : parseInt(e.target.value),
                              }
                            : state.job.job,
                        },
                      });
                    }}
                  />
                </div>
                <div>
                  <hr className="border border-dark" />
                </div>
                <div>
                  Products unassigned to batch:{" "}
                  {state.job.job?.panelCodes?.filter((c) => !c.batch).length}
                </div>
                <div className="d-flex">
                  {[...Array(state.job.job?.batchCount ?? 0)]
                    .map((_, i) => i + 1)
                    .map((n) => {
                      return (
                        <>
                          <div className="mx-1">
                            <button
                              onClick={() => {
                                selectedBatch.current = n;
                                render();
                              }}
                              className={`btn btn-sm ${
                                selectedBatch.current === n
                                  ? `btn-primary`
                                  : `btn-outline-primary`
                              }`}
                            >
                              {n} (
                              {
                                state.job.job?.panelCodes?.filter(
                                  (c) => `${c.batch}` === `${n}`
                                ).length
                              }
                              )
                            </button>
                          </div>
                        </>
                      );
                    })}
                </div>
                {selectedBatch.current ? (
                  <>
                    <div>
                      <div>Selected batch: {selectedBatch.current}</div>
                      <div>
                        <Select
                          options={state.job.job?.panelCodes
                            ?.filter((c) => c.id)
                            .map((c) => {
                              return { label: `${c.type}:${c.name}`, value: c };
                            })}
                          onChange={(v) => {
                            const val = v as { value: PanelCode };

                            setState({
                              ...state,
                              job: {
                                ...state.job,
                                job: state.job.job
                                  ? {
                                      ...state.job.job,
                                      panelCodes:
                                        state.job.job.panelCodes?.map((cx) =>
                                          cx.id === val.value.id
                                            ? {
                                                ...cx,
                                                batch: selectedBatch.current,
                                              }
                                            : cx
                                        ) ?? state.job.job.panelCodes,
                                    }
                                  : state.job.job,
                              },
                            });
                          }}
                        />
                      </div>

                      <div>
                        <ol>
                          {state.job.job?.panelCodes
                            ?.filter(
                              (c) => `${c.batch}` === `${selectedBatch.current}`
                            )
                            .map((c) => {
                              return (
                                <>
                                  <li>
                                    <div>
                                      <div>
                                        {c.type}:{c.name}:{c.qty} unit(s)
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ol>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {viewType === "DeptTemplate" ? (
            <>
              <div className="m-3">
                <h4>Job Dept Template: {state.job.job?.name}</h4>
              </div>
              <div className="d-flex my-2 p-1 border border-dark">
                <strong>Selected dept template Category:</strong>
                <div className="d-flex mx-2">
                  <div className="mx-2">
                    <button
                      onClick={() => {
                        if (
                          !window.confirm(
                            `Change dept template category to Default?`
                          )
                        ) {
                          return;
                        }

                        if (state.job.job) {
                          state.job.job.departmentTemplateCategory = null;
                        }
                        render();
                      }}
                      className={`btn btn-sm ${
                        !state.job.job?.departmentTemplateCategory
                          ? `btn-success`
                          : `btn-outline-success`
                      }`}
                    >
                      Default
                    </button>
                  </div>
                  {templateCategories.map((c) => {
                    return (
                      <>
                        <div className="mx-2">
                          <button
                            onClick={() => {
                              if (
                                !window.confirm(
                                  `Change dept template category to ${c.name}`
                                )
                              ) {
                                return;
                              }

                              if (state.job.job) {
                                state.job.job.departmentTemplateCategory = c;
                              }
                              render();
                            }}
                            className={`btn btn-sm ${
                              `${c.id}` ===
                              `${state.job.job?.departmentTemplateCategory?.id}`
                                ? `btn-success`
                                : `btn-outline-success`
                            }`}
                          >
                            {c.name}
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex">
                {(
                  [
                    ...["Manual Assign"],
                    ...(woLoaded
                      ? ["Auto Assign", "Auto Assign 2", "Auto Assign 3"]
                      : []),
                  ] as (
                    | "Manual Assign"
                    | "Auto Assign"
                    | "Auto Assign 2"
                    | "Auto Assign 3"
                  )[]
                ).map((h) => (
                  <>
                    <div className="mx-2">
                      <button
                        onClick={() => {
                          if (h === "Auto Assign") {
                            fetchTaskListsProtoDataAllFrom();
                          }
                          setDeptTemplateViewType(h);
                        }}
                        className={`btn btn-sm ${
                          deptTemplateViewType === h
                            ? `btn-primary`
                            : `btn-outline-primary`
                        }`}
                      >
                        {h}
                      </button>
                    </div>
                  </>
                ))}
              </div>
              <div>
                <hr className="border border-dark" />
              </div>
              {deptTemplateViewType === "Manual Assign" ? (
                <>
                  <div>
                    Show closed products?{" "}
                    <input
                      type="checkbox"
                      checked={showClosedProducts ?? false}
                      onClick={() => {
                        setShowClosedProducts(!showClosedProducts);
                      }}
                    />
                  </div>
                  <div className="d-flex">
                    <div>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          if (parallelSequentialMode === "Parallel") {
                            setParallelSequentialMode("Sequential");
                          }

                          if (parallelSequentialMode === "Sequential") {
                            setParallelSequentialMode("Parallel");
                          }
                        }}
                      >
                        <small>Toggle Mode</small>
                      </button>
                    </div>
                    <div>
                      Mode: <strong>{parallelSequentialMode}</strong>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => {
                        if (
                          !window.confirm(
                            `Confirm generate all panel with JDP & unassigned template (${
                              state.job.job?.panelCodes?.filter((c) => {
                                console.log(
                                  c.panelCodeDepartmentTemplates?.length,
                                  c.name,
                                  c.type
                                );
                                return (
                                  c.panelCodeDepartmentTemplates?.length ===
                                    0 &&
                                  c.extJdpPresetId &&
                                  c.extJdpPresetId !== 0
                                );
                              }).length
                            } panels).`
                          )
                        ) {
                          return null;
                        }

                        state.job.job?.panelCodes
                          ?.filter((c) => {
                            console.log(
                              c.panelCodeDepartmentTemplates?.length,
                              c.name,
                              c.type
                            );
                            return (
                              c.panelCodeDepartmentTemplates?.length === 0 &&
                              c.extJdpPresetId &&
                              c.extJdpPresetId !== 0
                            );
                          })
                          .forEach((c, i) => {
                            console.log(i + 1, ".", c);
                            handleGenerateTemplate(c);
                          });
                      }}
                    >
                      Generate All with JDP
                    </button>
                  </div>

                  <div>
                    <hr className="border border-dark" />
                  </div>
                  <div className="d-flex my-2">
                    <div>
                      <strong>JDP View mode:</strong>
                    </div>
                    <div>
                      {jdpViewModeTypes.map((t) => {
                        return (
                          <>
                            <button
                              className={`mx-2 btn btn-sm px-1 py-0 ${
                                t === selectedJdpViewModeType.current
                                  ? `btn-primary`
                                  : `btn-outline-primary`
                              }`}
                              onClick={() => {
                                selectedJdpViewModeType.current = t;
                                render();
                              }}
                            >
                              {t}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    <hr className="border border-dark" />
                  </div>

                  {selectedJdpViewModeType.current === "Block" ? (
                    <>
                      {" "}
                      <div>
                        <ol>
                          {state.job?.job?.panelCodes
                            ?.filter((c) =>
                              showClosedProducts ? true : !c.closed
                            )
                            ?.map((pc) => {
                              return (
                                <>
                                  <li>
                                    <div className="d-flex">
                                      <div className="">
                                        {pc.name} | {pc?.type} |{" "}
                                        {pc.serialNumber}
                                      </div>
                                      <div className="mx-2">
                                        <button
                                          className="btn btn-sm btn-outline-primary"
                                          onClick={() => {
                                            if (
                                              expandedPanelCodeDeptTemplates.find(
                                                (pcUuid) => pcUuid === pc.uuid
                                              )
                                            ) {
                                              setExpandedPanelCodeDeptTemplates(
                                                expandedPanelCodeDeptTemplates.filter(
                                                  (pcUuid) => pcUuid !== pc.uuid
                                                )
                                              );
                                            } else {
                                              setExpandedPanelCodeDeptTemplates(
                                                [
                                                  ...expandedPanelCodeDeptTemplates,
                                                  pc.uuid ?? "",
                                                ]
                                              );
                                            }
                                          }}
                                        >
                                          Expand/Collapse
                                        </button>
                                      </div>
                                    </div>

                                    {expandedPanelCodeDeptTemplates.find(
                                      (pcUuid) => pcUuid === pc.uuid
                                    ) ? (
                                      <>
                                        <div>
                                          <div className="d-flex">
                                            <div>
                                              (Start{" "}
                                              {state.job.job?.jobDate != null
                                                ? makeReadableDateStringIntlUtc(
                                                    new Date(
                                                      state.job.job.jobDate
                                                    )
                                                  )
                                                : null}
                                              )
                                            </div>
                                            <div>
                                              <button
                                                className="btn btn-success"
                                                onClick={() => {
                                                  if (!pc.id) {
                                                    alert(
                                                      "Please save panel code first before generating template."
                                                    );
                                                    return;
                                                  }
                                                  if (
                                                    window.confirm(
                                                      `Are you sure you want to generate template with:\n[${
                                                        pc.extJdpPresetId
                                                          ? `Preset ${
                                                              jdpPresets.presets.find(
                                                                (p) =>
                                                                  `${p.masterJavaBaseModel?.id}` ===
                                                                  `${pc.extJdpPresetId}`
                                                              )?.name ?? ""
                                                            }`
                                                          : filterGenerateDepartmentTemplate
                                                              .map(
                                                                (f) =>
                                                                  f
                                                                    .departmentTemplate
                                                                    ?.name
                                                              )
                                                              .join(", ")
                                                      }]?\nThis will delete existing dept template (${
                                                        (pc
                                                          .panelCodeDepartmentTemplates
                                                          ?.length ?? 0) > 0
                                                          ? pc
                                                              .panelCodeDepartmentTemplates
                                                              ?.length
                                                          : "No"
                                                      } dept templates currently found).`
                                                    )
                                                  )
                                                    handleGenerateTemplate(pc);
                                                }}
                                              >
                                                Generate Template
                                              </button>
                                              <button
                                                className="btn btn-success mx-2"
                                                onClick={() => {
                                                  if (!pc.id) {
                                                    alert(
                                                      "Please save panel code first before generating template."
                                                    );
                                                    return;
                                                  }
                                                  if (
                                                    window.confirm(
                                                      `Are you sure you want to restore dept template? Existing progress won't be lost.`
                                                    )
                                                  )
                                                    handleGenerateTemplate(pc, {
                                                      restoreOnly: true,
                                                    });
                                                }}
                                              >
                                                Restore Templates
                                              </button>
                                            </div>
                                            <div
                                              className="mx-2"
                                              style={{ width: 300 }}
                                            >
                                              <strong>
                                                {
                                                  jdpPresets.presets.find(
                                                    (p) =>
                                                      `${p.masterJavaBaseModel?.id}` ===
                                                      `${pc.extJdpPresetId}`
                                                  )?.name
                                                }
                                              </strong>

                                              {/* <Select
                                            placeholder="JDP Preset..."
                                            options={
                                              jdpPresets.presets.map((p) => ({
                                                label: p.name ?? "",
                                                value: p,
                                              })) ?? []
                                            }
                                            onChange={(v) => {
                                              if (!pc.id) {
                                                alert(
                                                  "Please save panel code first."
                                                );
                                                return;
                                              }
                                              const val = v as {
                                                value: PpicPanelCodeDepartmentTemplatePreset;
                                              };

                                              if (
                                                selectedPanelCodeJDPPresets.find(
                                                  (p) =>
                                                    p.panelCode?.id === pc.id
                                                )
                                              ) {
                                                setSelectedPanelCodeJDPPresets(
                                                  (ps) =>
                                                    ps.map((psx) =>
                                                      psx?.panelCode?.id ===
                                                      pc.id
                                                        ? {
                                                            ...psx,
                                                            jdpPreset:
                                                              val.value,
                                                          }
                                                        : psx
                                                    )
                                                );
                                              } else {
                                                setSelectedPanelCodeJDPPresets(
                                                  (ps) => [
                                                    ...ps,
                                                    {
                                                      panelCode: pc,
                                                      jdpPreset: val.value,
                                                    },
                                                  ]
                                                );
                                              }
                                            }}
                                          /> */}
                                            </div>
                                            <div>
                                              <small>
                                                <strong>
                                                  {" "}
                                                  {
                                                    selectedPanelCodeJDPPresets.find(
                                                      (p) =>
                                                        `${p.panelCode.id}` ===
                                                        `${pc.id}`
                                                    )?.jdpPreset.name
                                                  }
                                                </strong>
                                              </small>
                                            </div>
                                          </div>

                                          <div className="d-flex flex-wrap align-items-center">
                                            To generate:
                                            {departmentTemplates.map((dt) => {
                                              return (
                                                <div
                                                  onClick={() => {
                                                    if (
                                                      filterGenerateDepartmentTemplate.find(
                                                        (dtx) =>
                                                          dtx.departmentTemplate
                                                            ?.id ===
                                                          dt.departmentTemplate
                                                            ?.id
                                                      )
                                                    ) {
                                                      setFilterGenerateDepartmentTemplate(
                                                        filterGenerateDepartmentTemplate.filter(
                                                          (dtx) =>
                                                            dtx
                                                              .departmentTemplate
                                                              ?.id !==
                                                            dt
                                                              .departmentTemplate
                                                              ?.id
                                                        )
                                                      );
                                                    } else {
                                                      setFilterGenerateDepartmentTemplate(
                                                        [
                                                          ...filterGenerateDepartmentTemplate,
                                                          dt,
                                                        ]
                                                      );
                                                    }
                                                  }}
                                                  className="d-flex align-items-center p-1 border border-dark"
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {" "}
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      filterGenerateDepartmentTemplate.find(
                                                        (f) =>
                                                          f.departmentTemplate
                                                            ?.id ===
                                                          dt.departmentTemplate
                                                            ?.id
                                                      )
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                  <div
                                                    className={`${
                                                      !dt.departmentTemplate
                                                        ?.processDays
                                                        ? `text-danger`
                                                        : ``
                                                    }`}
                                                  >
                                                    {
                                                      dt.departmentTemplate
                                                        ?.name
                                                    }{" "}
                                                    (
                                                    {
                                                      dt.departmentTemplate
                                                        ?.processDays
                                                    }
                                                    d)
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>

                                          <div>
                                            <table
                                              className="table table-sm table-bordered table-hover table-striped"
                                              style={{
                                                borderCollapse: "separate",
                                              }}
                                            >
                                              {filterNotHiddenAndSort(
                                                pc.panelCodeDepartmentTemplates
                                              ).map((dt) => {
                                                const mappedPCDTIs =
                                                  departmentTemplates
                                                    .flatMap(
                                                      (dt) =>
                                                        dt.departmentTemplateItems
                                                    )
                                                    .filter(
                                                      (dti) =>
                                                        dti
                                                          .departmentTemplateItem
                                                          ?.departmentTemplate
                                                          ?.id ===
                                                        dt.departmentTemplate
                                                          ?.id
                                                    )
                                                    .map((dti) => {
                                                      const found =
                                                        pc.panelCodeDepartmentTemplateItems?.find(
                                                          (dtix) =>
                                                            dtix
                                                              .departmentTemplateItem
                                                              ?.id ===
                                                            dti
                                                              .departmentTemplateItem
                                                              ?.id
                                                        );

                                                      return found;
                                                    });

                                                const foundTaskList =
                                                  workOrders?.current?.taskLists
                                                    .flatMap(
                                                      (tL) =>
                                                        tL.taskList
                                                          ?.meetingTasks
                                                    )
                                                    .find((mt) =>
                                                      mappedPCDTIs.find(
                                                        (pcdti) =>
                                                          `${pcdti?.id}` ===
                                                          `${mt?.extPanelCodeDepartmentTemplateItemId}`
                                                      )
                                                    );

                                                return (
                                                  <>
                                                    <tr>
                                                      <td className="border border-dark">
                                                        <div>
                                                          <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => {
                                                              setState({
                                                                ...state,
                                                                job: state.job
                                                                  ? {
                                                                      ...state.job,
                                                                      job: state
                                                                        .job.job
                                                                        ? {
                                                                            ...state
                                                                              .job
                                                                              .job,
                                                                            panelCodes:
                                                                              state
                                                                                .job
                                                                                .job
                                                                                .panelCodes
                                                                                ? [
                                                                                    ...state.job.job.panelCodes.map(
                                                                                      (
                                                                                        pcx
                                                                                      ) =>
                                                                                        pcx.uuid ===
                                                                                          pc.uuid &&
                                                                                        pcx
                                                                                          ? {
                                                                                              ...pcx,
                                                                                              panelCodeDepartmentTemplates:
                                                                                                pcx.panelCodeDepartmentTemplates
                                                                                                  ? pcx.panelCodeDepartmentTemplates.filter(
                                                                                                      (
                                                                                                        pd
                                                                                                      ) =>
                                                                                                        pd
                                                                                                          .departmentTemplate
                                                                                                          ?.id !==
                                                                                                        dt
                                                                                                          .departmentTemplate
                                                                                                          ?.id
                                                                                                    )
                                                                                                  : pcx.panelCodeDepartmentTemplates,
                                                                                            }
                                                                                          : pcx
                                                                                    ),
                                                                                  ]
                                                                                : state
                                                                                    .job
                                                                                    .job
                                                                                    .panelCodes,
                                                                          }
                                                                        : state
                                                                            .job
                                                                            .job,
                                                                    }
                                                                  : state.job,
                                                              });
                                                              setDepartmentTemplateDeleteIds(
                                                                [
                                                                  ...departmentTemplateDeleteIds,
                                                                  dt.id ?? 0,
                                                                ]
                                                              );
                                                            }}
                                                          >
                                                            Delete
                                                          </button>
                                                        </div>
                                                      </td>
                                                      {/* <td className="border border-dark">
                                          <input type="checkbox" />
                                        </td> */}
                                                      <td className="border border-dark">
                                                        <div>
                                                          {woLoaded ? (
                                                            <>
                                                              <div>
                                                                <div>
                                                                  <div className="d-flex justify-content-between">
                                                                    <div>
                                                                      <strong>
                                                                        {
                                                                          dt
                                                                            .departmentTemplate
                                                                            ?.name
                                                                        }
                                                                      </strong>
                                                                    </div>
                                                                  </div>
                                                                  <div>
                                                                    {dt
                                                                      .departmentTemplate
                                                                      ?.processDays ??
                                                                      0}{" "}
                                                                    days,{" "}
                                                                    {dt
                                                                      .departmentTemplate
                                                                      ?.variable
                                                                      ? "Variable to Qty"
                                                                      : "Static"}
                                                                  </div>
                                                                </div>
                                                                {/* {dt.extTaskListId} */}
                                                                <button
                                                                  className="btn btn-primary btn-sm"
                                                                  onClick={async () => {
                                                                    if (
                                                                      !state.job
                                                                        .job?.id
                                                                    ) {
                                                                      alert(
                                                                        "Cannot create WO if job is unsaved. Please create job first!"
                                                                      );
                                                                      return;
                                                                    }

                                                                    if (
                                                                      !pc.id
                                                                    ) {
                                                                      alert(
                                                                        "Cannot create WO if panel code is unsaved. Please save panel code (by saving job) first!"
                                                                      );
                                                                      return;
                                                                    }

                                                                    if (
                                                                      !dt.id
                                                                    ) {
                                                                      alert(
                                                                        "Please save the dept template first (by saving job) before creating WO!"
                                                                      );
                                                                      return;
                                                                    }
                                                                    setShowWorkOrderOpen(
                                                                      true
                                                                    );
                                                                    setSelectedPanelCode(
                                                                      pc
                                                                    );
                                                                    setSelectedPanelCodeDepartmentTemplate(
                                                                      dt
                                                                    );

                                                                    if (
                                                                      dt.extTaskListId
                                                                    ) {
                                                                      // task list exists, fetch
                                                                      try {
                                                                        setNewSaveStatus(
                                                                          RequestStatus.Loading
                                                                        );

                                                                        const resp =
                                                                          await fetch(
                                                                            `${process.env.REACT_APP_MEETING_URL}/tasklists-proto/${dt.extTaskListId}`,
                                                                            {
                                                                              headers:
                                                                                {
                                                                                  authorization:
                                                                                    ctx?.apiKey ??
                                                                                    "",
                                                                                },
                                                                            }
                                                                          );
                                                                        if (
                                                                          resp.status !==
                                                                          200
                                                                        ) {
                                                                          throw await resp.text();
                                                                        }
                                                                        const wo =
                                                                          MeetingTaskListView.fromPartial(
                                                                            await resp.json()
                                                                          );

                                                                        if (
                                                                          wo
                                                                        ) {
                                                                          setSelectedWorkOrder(
                                                                            wo
                                                                          );
                                                                        }
                                                                      } catch (e) {
                                                                        alert(
                                                                          "Error getting WO"
                                                                        );
                                                                      } finally {
                                                                        setNewSaveStatus(
                                                                          RequestStatus.Success
                                                                        );
                                                                      }
                                                                    } else {
                                                                      setSelectedWorkOrder(
                                                                        MeetingTaskListView.fromPartial(
                                                                          {
                                                                            taskList:
                                                                              MeetingTaskList.fromPartial(
                                                                                {}
                                                                              ),
                                                                          }
                                                                        )
                                                                      );
                                                                    }
                                                                  }}
                                                                >
                                                                  WO
                                                                </button>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}

                                                          <div
                                                            className={`font-weight-bold ${
                                                              foundTaskList
                                                                ? `text-success`
                                                                : `text-danger`
                                                            }`}
                                                          >
                                                            <small>
                                                              <strong>
                                                                {foundTaskList
                                                                  ? "Mapped"
                                                                  : "Not Mapped"}
                                                              </strong>
                                                            </small>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      {departmentTemplates
                                                        .flatMap(
                                                          (dt) =>
                                                            dt.departmentTemplateItems
                                                        )
                                                        .filter(
                                                          (dti) =>
                                                            dti
                                                              .departmentTemplateItem
                                                              ?.departmentTemplate
                                                              ?.id ===
                                                            dt
                                                              .departmentTemplate
                                                              ?.id
                                                        )
                                                        .map((dti) => {
                                                          const found =
                                                            pc.panelCodeDepartmentTemplateItems?.find(
                                                              (dtix) =>
                                                                dtix
                                                                  .departmentTemplateItem
                                                                  ?.id ===
                                                                dti
                                                                  .departmentTemplateItem
                                                                  ?.id
                                                            );

                                                          return (
                                                            <td
                                                              className="border border-dark"
                                                              style={{
                                                                backgroundColor:
                                                                  found?.excluded
                                                                    ? "grey"
                                                                    : undefined,
                                                              }}
                                                            >
                                                              <div
                                                                className="d-flex justify-content-between"
                                                                style={{
                                                                  cursor: found
                                                                    ? ""
                                                                    : "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  if (!found) {
                                                                    window.confirm(
                                                                      "Add process?"
                                                                    );
                                                                  }
                                                                  {
                                                                    const flattenedTemplateItems =
                                                                      pc.panelCodeDepartmentTemplates
                                                                        ?.map(
                                                                          (
                                                                            t
                                                                          ) => {
                                                                            const ti =
                                                                              departmentTemplates.find(
                                                                                (
                                                                                  tx
                                                                                ) =>
                                                                                  `${tx.departmentTemplate?.id}` ===
                                                                                  `${t.departmentTemplate?.id}`
                                                                              )?.departmentTemplateItems;

                                                                            ti?.sort(
                                                                              (
                                                                                a,
                                                                                b
                                                                              ) =>
                                                                                (a
                                                                                  .departmentTemplateItem
                                                                                  ?.ordering ??
                                                                                  0) -
                                                                                (b
                                                                                  .departmentTemplateItem
                                                                                  ?.ordering ??
                                                                                  0)
                                                                            );

                                                                            return ti;
                                                                          }
                                                                        )
                                                                        .flat();

                                                                    console.log(
                                                                      "flattened template items",
                                                                      flattenedTemplateItems?.map(
                                                                        (i) =>
                                                                          i
                                                                            ?.departmentTemplateItem
                                                                            ?.name
                                                                      ),
                                                                      pc.panelCodeDepartmentTemplates?.map(
                                                                        (t) =>
                                                                          t
                                                                            .departmentTemplate
                                                                            ?.id
                                                                      )
                                                                    );

                                                                    const dtiIndex =
                                                                      flattenedTemplateItems?.findIndex(
                                                                        (
                                                                          dtix
                                                                        ) =>
                                                                          `${dtix?.departmentTemplateItem?.id}` ===
                                                                          `${dti.departmentTemplateItem?.id}`
                                                                      );

                                                                    const firstChunk =
                                                                      flattenedTemplateItems?.slice(
                                                                        0,
                                                                        dtiIndex
                                                                      ) ?? [];

                                                                    const secondChunk =
                                                                      flattenedTemplateItems?.slice(
                                                                        (dtiIndex ??
                                                                          0) +
                                                                          1,
                                                                        flattenedTemplateItems.length
                                                                      ) ?? [];

                                                                    const combinedChunk =
                                                                      [
                                                                        ...(() => {
                                                                          const c =
                                                                            [
                                                                              ...firstChunk,
                                                                            ];

                                                                          c.reverse();

                                                                          return c;
                                                                        })(),
                                                                        ...secondChunk,
                                                                      ];

                                                                    console.log(
                                                                      "combined chunk",
                                                                      combinedChunk
                                                                    );

                                                                    const foundDTINeighbor =
                                                                      combinedChunk
                                                                        .map(
                                                                          (c) =>
                                                                            pc.panelCodeDepartmentTemplateItems?.find(
                                                                              (
                                                                                dtix
                                                                              ) =>
                                                                                `${dtix.departmentTemplateItem?.id}` ===
                                                                                `${c?.departmentTemplateItem?.id}`
                                                                            ) as PanelCodeDepartmentTemplateItem
                                                                        )
                                                                        .filter(
                                                                          (d) =>
                                                                            d
                                                                        )
                                                                        .find(
                                                                          (d) =>
                                                                            d.startDate &&
                                                                            d.endDate
                                                                        );

                                                                    console.log(
                                                                      "chunk neighbor",
                                                                      foundDTINeighbor
                                                                    );

                                                                    setState({
                                                                      ...state,
                                                                      job: state.job
                                                                        ? {
                                                                            ...state.job,
                                                                            job: state
                                                                              .job
                                                                              .job
                                                                              ? {
                                                                                  ...state
                                                                                    .job
                                                                                    .job,
                                                                                  panelCodes:
                                                                                    state
                                                                                      .job
                                                                                      .job
                                                                                      .panelCodes
                                                                                      ? state.job.job.panelCodes.map(
                                                                                          (
                                                                                            pcx,
                                                                                            ix
                                                                                          ) => {
                                                                                            return pcx.uuid ===
                                                                                              pc.uuid
                                                                                              ? {
                                                                                                  ...pcx,
                                                                                                  panelCodeDepartmentTemplateItems:
                                                                                                    pcx.panelCodeDepartmentTemplateItems
                                                                                                      ? [
                                                                                                          ...pcx.panelCodeDepartmentTemplateItems,
                                                                                                          {
                                                                                                            ...initialPanelCodeDepartmentTemplateItem,
                                                                                                            uuid: v4(),
                                                                                                            departmentTemplateItem:
                                                                                                              dti.departmentTemplateItem,
                                                                                                            startDate:
                                                                                                              foundDTINeighbor?.startDate ??
                                                                                                              null,
                                                                                                            endDate:
                                                                                                              foundDTINeighbor?.endDate ??
                                                                                                              null,
                                                                                                          } as PanelCodeDepartmentTemplateItem,
                                                                                                        ]
                                                                                                      : pcx.panelCodeDepartmentTemplateItems,
                                                                                                }
                                                                                              : pcx;
                                                                                          }
                                                                                        )
                                                                                      : state
                                                                                          .job
                                                                                          .job
                                                                                          .panelCodes,
                                                                                }
                                                                              : state
                                                                                  .job
                                                                                  .job,
                                                                          }
                                                                        : state.job,
                                                                    });
                                                                  }
                                                                }}
                                                              >
                                                                <div
                                                                  className={`${
                                                                    !found
                                                                      ? `text-danger`
                                                                      : ""
                                                                  }`}
                                                                >
                                                                  {
                                                                    dti
                                                                      .departmentTemplateItem
                                                                      ?.name
                                                                  }
                                                                </div>
                                                                <div>
                                                                  <div>
                                                                    <div>
                                                                      <small>
                                                                        Hidden?{" "}
                                                                      </small>
                                                                      <input
                                                                        type="checkbox"
                                                                        checked={
                                                                          found?.excluded ??
                                                                          undefined
                                                                        }
                                                                        onClick={() => {
                                                                          handleForwardDate(
                                                                            {
                                                                              panelCode:
                                                                                pc,
                                                                              dti: dti,
                                                                              mode: "TOGGLE_HIDDEN",
                                                                            }
                                                                          );
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </td>
                                                          );
                                                        })}
                                                    </tr>
                                                    <tr>
                                                      {[...Array(2)].map(
                                                        (_, i) => (
                                                          <td></td>
                                                        )
                                                      )}
                                                      {departmentTemplates
                                                        .flatMap(
                                                          (dt) =>
                                                            dt.departmentTemplateItems
                                                        )
                                                        .filter(
                                                          (dti) =>
                                                            dti
                                                              .departmentTemplateItem
                                                              ?.departmentTemplate
                                                              ?.id ===
                                                            dt
                                                              .departmentTemplate
                                                              ?.id
                                                        )
                                                        .map((dti) => {
                                                          const found =
                                                            pc.panelCodeDepartmentTemplateItems?.find(
                                                              (dtix) =>
                                                                dtix
                                                                  .departmentTemplateItem
                                                                  ?.id ===
                                                                dti
                                                                  .departmentTemplateItem
                                                                  ?.id
                                                            );

                                                          const pcdtiEndDateStr =
                                                            found?.endDate;
                                                          const dateDeadlineStr =
                                                            state.job.job
                                                              ?.deliveryDeadline;

                                                          const dateExceedsDeadline =
                                                            (() => {
                                                              try {
                                                                const pcdtiEndDate =
                                                                  new Date(
                                                                    pcdtiEndDateStr?.split(
                                                                      "T"
                                                                    )?.[0] ?? ""
                                                                  );
                                                                const dateDeadline =
                                                                  new Date(
                                                                    state.job
                                                                      .job
                                                                      ?.deliveryDeadline ??
                                                                      ""
                                                                  );

                                                                return (
                                                                  pcdtiEndDate.getTime() >=
                                                                  dateDeadline.getTime()
                                                                );
                                                              } catch (e) {
                                                                return false;
                                                              }
                                                            })();

                                                          return (
                                                            <td
                                                              className="border border-dark"
                                                              style={{
                                                                backgroundColor:
                                                                  found?.completedDate
                                                                    ? "lightgreen"
                                                                    : dateExceedsDeadline
                                                                    ? `lightsalmon`
                                                                    : ``,
                                                              }}
                                                            >
                                                              <div className="d-flex"></div>
                                                              <div>
                                                                <small>
                                                                  From:{" "}
                                                                  {(() => {
                                                                    return found ? (
                                                                      <>
                                                                        {dateExceedsDeadline ? (
                                                                          <>
                                                                            <strong className="bg-danger text-light">
                                                                              OVD
                                                                            </strong>
                                                                          </>
                                                                        ) : (
                                                                          <></>
                                                                        )}
                                                                        {/* {JSON.stringify(found)} */}
                                                                        <strong>
                                                                          (
                                                                          {(() => {
                                                                            try {
                                                                              return makeReadableDateStringIntlUtc(
                                                                                new Date(
                                                                                  found.startDate ??
                                                                                    ""
                                                                                )
                                                                              );
                                                                              // return found.startDate;
                                                                            } catch (e) {
                                                                              return found.startDate;
                                                                            }
                                                                          })()}
                                                                          )
                                                                        </strong>
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {/* {JSON.stringify(found)} */}
                                                                      </>
                                                                    );
                                                                  })()}
                                                                </small>
                                                                <input
                                                                  className="form-control form-control-sm"
                                                                  type="date"
                                                                  placeholder="From"
                                                                  onBlur={(
                                                                    e
                                                                  ) => {
                                                                    if (
                                                                      e.target
                                                                        .value !==
                                                                      ""
                                                                    ) {
                                                                      handleForwardDate(
                                                                        {
                                                                          panelCode:
                                                                            pc,
                                                                          dti: dti,
                                                                          date: e
                                                                            .target
                                                                            .value,
                                                                          originalDate:
                                                                            found?.startDate,
                                                                          mode: "FROM",
                                                                        }
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                                <small>
                                                                  To:{" "}
                                                                  {(() => {
                                                                    return found ? (
                                                                      <>
                                                                        <strong>
                                                                          (
                                                                          {(() => {
                                                                            try {
                                                                              return makeReadableDateStringIntlUtc(
                                                                                new Date(
                                                                                  found.endDate ??
                                                                                    ""
                                                                                )
                                                                              );
                                                                              // return found.endDate;
                                                                            } catch (e) {
                                                                              return found.endDate;
                                                                            }
                                                                          })()}
                                                                          )
                                                                        </strong>
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    );
                                                                  })()}
                                                                </small>
                                                                <input
                                                                  className="form-control form-control-sm"
                                                                  type="date"
                                                                  placeholder="To"
                                                                  onBlur={(
                                                                    e
                                                                  ) => {
                                                                    if (
                                                                      e.target
                                                                        .value !==
                                                                      ""
                                                                    ) {
                                                                      handleForwardDate(
                                                                        {
                                                                          panelCode:
                                                                            pc,
                                                                          dti: dti,
                                                                          originalDate:
                                                                            found?.endDate,
                                                                          date: e
                                                                            .target
                                                                            .value,
                                                                          mode: "TO",
                                                                        }
                                                                      );
                                                                    }
                                                                  }}
                                                                />
                                                                <div
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() => {
                                                                    handleForwardDate(
                                                                      {
                                                                        panelCode:
                                                                          pc,
                                                                        dti: dti,
                                                                        date: null,
                                                                        mode: "COMPLETE",
                                                                      }
                                                                    );
                                                                  }}
                                                                >
                                                                  <small>
                                                                    Done:{" "}
                                                                    <strong>
                                                                      {" "}
                                                                      (
                                                                      {(() => {
                                                                        try {
                                                                          return makeReadableDateStringIntlUtc(
                                                                            new Date(
                                                                              found?.completedDate ??
                                                                                ""
                                                                            )
                                                                          );
                                                                          // return found.endDate;
                                                                        } catch (e) {
                                                                          return found?.completedDate;
                                                                        }
                                                                      })()}
                                                                      )
                                                                    </strong>{" "}
                                                                  </small>
                                                                </div>
                                                                <div>
                                                                  <input
                                                                    type="date"
                                                                    className="form-control form-control-sm"
                                                                    onBlur={(
                                                                      e
                                                                    ) => {
                                                                      if (
                                                                        e.target
                                                                          .value !==
                                                                        ""
                                                                      ) {
                                                                        handleForwardDate(
                                                                          {
                                                                            panelCode:
                                                                              pc,
                                                                            dti: dti,
                                                                            date: e
                                                                              .target
                                                                              .value,
                                                                            mode: "COMPLETE",
                                                                          }
                                                                        );
                                                                      }
                                                                    }}
                                                                  />
                                                                </div>
                                                                {/* <div>
                                                    <button
                                                      className="btn btn-primary btn-sm"
                                                      onClick={() => {
                                                        setSelectedPanelCodeDepartmentTemplateItem(
                                                          found
                                                        );
                                                        setSelectedPanelCode(
                                                          pc
                                                        );

                                                        setShowWorkOrderOpen(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      WO
                                                    </button>
                                                  </div> */}
                                                              </div>
                                                            </td>
                                                          );
                                                        })}
                                                    </tr>
                                                  </>
                                                );
                                              })}
                                            </table>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </li>
                                </>
                              );
                            })}
                        </ol>
                        {/* <div>
                  <pre>
                    <small>
                      {JSON.stringify(departmentTemplates, null, 2)}
                    </small>
                  </pre>
                </div> */}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {selectedJdpViewModeType.current === "Table" ? (
                    <>
                      <div className="d-flex">
                        <button
                          className={`mx-1 btn btn-sm ${
                            selectedJdpEditMode.current === "View"
                              ? `btn-primary`
                              : `btn-outline-primary`
                          }`}
                          onClick={() => {
                            selectedJdpEditMode.current = "View";
                            render();
                          }}
                        >
                          View
                        </button>

                        <button
                          className={`mx-1 btn btn-sm ${
                            selectedJdpEditMode.current === "Edit"
                              ? `btn-primary`
                              : `btn-outline-primary`
                          }`}
                          onClick={() => {
                            selectedJdpEditMode.current = "Edit";
                            render();
                          }}
                        >
                          Edit
                        </button>
                      </div>
                      <div
                        className="overflow-auto border border-dark shadow shadow-md"
                        style={{ height: "75vh", resize: "vertical" }}
                      >
                        <table
                          className="table table-sm"
                          style={{
                            borderCollapse: "separate",
                          }}
                        >
                          <tr>
                            {[
                              "#",
                              "Type:Name",
                              "Preset",
                              "Generate",
                              "Restore",
                              "Progress",
                            ].map((h, i) => {
                              return (
                                <>
                                  <th
                                    style={{ position: "sticky", top: 0 }}
                                    className="bg-dark text-light p-0 m-0"
                                  >
                                    {h}
                                  </th>
                                </>
                              );
                            })}
                            {sortedDeptTemplates
                              .flatMap((t) =>
                                t.departmentTemplateItems.map((i) => ({
                                  template: t,
                                  item: i,
                                }))
                              )
                              .map((d) => {
                                return (
                                  <>
                                    <th
                                      style={{ position: "sticky", top: 0 }}
                                      className="border border-dark p-0 m-0"
                                    >
                                      <div>
                                        <div
                                          style={{
                                            backgroundColor: new ColorHash({
                                              lightness: [0.8],
                                            }).hex(
                                              d.template.departmentTemplate
                                                ?.name ?? ""
                                            ),
                                          }}
                                        >
                                          {d.template.departmentTemplate?.name}
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: new ColorHash({
                                              lightness: [0.8],
                                            }).hex(
                                              d.item.departmentTemplateItem
                                                ?.name ?? ""
                                            ),
                                          }}
                                        >
                                          <small>
                                            {
                                              d.item.departmentTemplateItem
                                                ?.name
                                            }
                                          </small>
                                        </div>
                                      </div>
                                    </th>
                                  </>
                                );
                              })}
                          </tr>
                          {state.job.job?.panelCodes?.map((c, i) => {
                            const foundPCDTIs = sortedDeptTemplates
                              .flatMap((t) =>
                                t.departmentTemplateItems.map((i) => ({
                                  template: t,
                                  item: i,
                                }))
                              )
                              .map((d) => {
                                const foundPCDTI =
                                  c.panelCodeDepartmentTemplateItems?.find(
                                    (pcdti) =>
                                      `${pcdti.departmentTemplateItem?.id}` ===
                                      `${d.item.departmentTemplateItem?.id}`
                                  );

                                return foundPCDTI;
                              })
                              .filter(
                                (pcdti) =>
                                  pcdti && !pcdti.excluded && !pcdti.hidden
                              );

                            console.log("pcdtis", c.type, c.name, foundPCDTIs);

                            const completedPCDTIs = foundPCDTIs.filter(
                              (pcdti) => pcdti?.completedDate
                            );

                            const percentage =
                              ((completedPCDTIs.length ?? 0) /
                                (foundPCDTIs.length ?? 1)) *
                              100;

                            return (
                              <>
                                <tr>
                                  <td className="border border-dark p-0 m-0">
                                    {i + 1}
                                  </td>
                                  <td
                                    className="border border-dark p-0 m-0 bg-light"
                                    style={{ position: "sticky", left: 0 }}
                                  >
                                    {c.type}:{c.name}
                                  </td>

                                  <td
                                    className="border border-dark p-0 m-0"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {
                                      jdpPresets.presets.find(
                                        (p) =>
                                          `${p.masterJavaBaseModel?.id}` ===
                                          `${c.extJdpPresetId}`
                                      )?.name
                                    }
                                  </td>
                                  <td className="border border-dark p-0 m-0"></td>
                                  <td className="border border-dark p-0 m-0"></td>
                                  <td
                                    className="border border-dark p-0 m-0"
                                    style={{
                                      whiteSpace: "nowrap",
                                      backgroundColor: chroma
                                        .scale([
                                          "lightsalmon",
                                          "yellow",
                                          "lightgreen",
                                        ])(
                                          (completedPCDTIs.length ?? 0) /
                                            (foundPCDTIs.length ?? 1)
                                        )
                                        .hex(),
                                    }}
                                  >
                                    {percentage.toFixed(0)}% (
                                    {completedPCDTIs.length}/
                                    {foundPCDTIs.length})
                                  </td>

                                  {sortedDeptTemplates
                                    .flatMap((t) =>
                                      t.departmentTemplateItems.map((i) => ({
                                        template: t,
                                        item: i,
                                      }))
                                    )
                                    .map((d) => {
                                      const foundPCDTI =
                                        c.panelCodeDepartmentTemplateItems?.find(
                                          (pcdti) =>
                                            `${pcdti.departmentTemplateItem?.id}` ===
                                            `${d.item.departmentTemplateItem?.id}`
                                        );
                                      const start = foundPCDTI?.startDate
                                        ? (() => {
                                            try {
                                              return formatDateTimeIntl({
                                                date: foundPCDTI.startDate,
                                                dateStyle: "medium",
                                              });
                                            } catch (e) {
                                              return null;
                                            }
                                          })()
                                        : null;

                                      const end = foundPCDTI?.endDate
                                        ? (() => {
                                            try {
                                              return formatDateTimeIntl({
                                                date: foundPCDTI.endDate,
                                                dateStyle: "medium",
                                              });
                                            } catch (e) {
                                              return null;
                                            }
                                          })()
                                        : null;

                                      const completed =
                                        foundPCDTI?.completedDate
                                          ? (() => {
                                              try {
                                                return formatDateTimeIntl({
                                                  date: foundPCDTI.completedDate,
                                                  dateStyle: "medium",
                                                });
                                              } catch (e) {
                                                return null;
                                              }
                                            })()
                                          : null;

                                      return (
                                        <>
                                          <td
                                            className="border border-dark p-0 m-0"
                                            style={{
                                              whiteSpace: "nowrap",
                                              backgroundColor: foundPCDTI
                                                ? foundPCDTI.excluded
                                                  ? `grey`
                                                  : foundPCDTI.completedDate
                                                  ? `lightgreen`
                                                  : ``
                                                : `lightsalmon`,
                                            }}
                                          >
                                            {selectedJdpEditMode.current ===
                                            "View" ? (
                                              <>
                                                {" "}
                                                <div>
                                                  <div>
                                                    {start} - {end}
                                                  </div>

                                                  {foundPCDTI?.completedDate ? (
                                                    <>
                                                      {" "}
                                                      <div>{completed}</div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                            {selectedJdpEditMode.current ===
                                            "Edit" ? (
                                              <>
                                                <div>
                                                  <div className="d-flex">
                                                    <input
                                                      type="date"
                                                      className="form-control form-control-sm"
                                                      key={`pcdti-table-edit-from-${foundPCDTI?.uuid}`}
                                                      defaultValue={
                                                        foundPCDTI?.startDate?.split(
                                                          "T"
                                                        )?.[0] ?? undefined
                                                      }
                                                      onBlur={(e) => {
                                                        if (
                                                          e.target.value !== ""
                                                        ) {
                                                          handleForwardDate({
                                                            panelCode: c,
                                                            dti: d.item,
                                                            date: e.target
                                                              .value,
                                                            originalDate:
                                                              foundPCDTI?.startDate,
                                                            mode: "FROM",
                                                          });
                                                        }
                                                      }}
                                                    />{" "}
                                                    -{" "}
                                                    <input
                                                      type="date"
                                                      className="form-control form-control-sm"
                                                      key={`pcdti-table-edit-to-${foundPCDTI?.uuid}`}
                                                      defaultValue={
                                                        foundPCDTI?.endDate?.split(
                                                          "T"
                                                        )?.[0] ?? undefined
                                                      }
                                                      onBlur={(e) => {
                                                        if (
                                                          e.target.value !== ""
                                                        ) {
                                                          handleForwardDate({
                                                            panelCode: c,
                                                            dti: d.item,
                                                            date: e.target
                                                              .value,
                                                            originalDate:
                                                              foundPCDTI?.endDate,
                                                            mode: "TO",
                                                          });
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div>
                                                  <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    key={`pcdti-table-edit-complete-${foundPCDTI?.uuid}`}
                                                    defaultValue={
                                                      foundPCDTI?.completedDate?.split(
                                                        "T"
                                                      )?.[0] ?? undefined
                                                    }
                                                    onBlur={(e) => {
                                                      if (
                                                        e.target.value !== ""
                                                      ) {
                                                        handleForwardDate({
                                                          panelCode: c,
                                                          dti: d.item,
                                                          date: e.target.value,
                                                          mode: "COMPLETE",
                                                        });
                                                      }
                                                    }}
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </td>
                                        </>
                                      );
                                    })}
                                </tr>
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              {deptTemplateViewType === "Auto Assign" ? (
                (() => {
                  // const totalHours =
                  const filteredUsers = ctx?.extUsers.filter((u) =>
                    // Filter by dept id
                    autoAssignDeptTemplateFilterParams.deptId !== null
                      ? `${u.departmentId}` ===
                        `${autoAssignDeptTemplateFilterParams.deptId}`
                      : true
                  );
                  console.log("[wo from]", workOrdersFrom);

                  return (
                    <>
                      <div>
                        <div>
                          <strong>Generation Params</strong>
                        </div>
                        {workOrdersFromLoading ? (
                          <>
                            <Spinner animation="border" />
                          </>
                        ) : (
                          <></>
                        )}
                        <div>
                          <small>
                            <strong>Included Panels:</strong>
                          </small>
                        </div>
                        <div>
                          <ol>
                            {state.job.job?.panelCodes?.map((pc) => {
                              return (
                                <>
                                  <li>
                                    <div>
                                      <div className="d-flex">
                                        <div>
                                          {pc.type} - {pc.name}
                                        </div>
                                        <div>
                                          <button
                                            className="btn btn-sm btn-outline-primary"
                                            onClick={() => {
                                              if (
                                                includedPanelCodeDepartmentTemplates.find(
                                                  (pcdtx) =>
                                                    pc.panelCodeDepartmentTemplates?.find(
                                                      (pcdt) =>
                                                        `${pcdtx.panelCode.id}` ===
                                                          `${pc.id}` &&
                                                        `${pcdt.departmentTemplate?.id}` ===
                                                          `${pcdtx.dt.departmentTemplate?.id}`
                                                    )
                                                )
                                              ) {
                                                setIncludedPanelCodeDepartmentTemplates(
                                                  includedPanelCodeDepartmentTemplates.filter(
                                                    (pcdt) =>
                                                      !pc.panelCodeDepartmentTemplates?.find(
                                                        (pcdtx) =>
                                                          `${pcdt.panelCode.id}` ===
                                                            `${pc.id}` &&
                                                          `${pcdt.dt.departmentTemplate?.id}` ===
                                                            `${pcdtx.departmentTemplate?.id}`
                                                      )
                                                  )
                                                );
                                              } else {
                                                setIncludedPanelCodeDepartmentTemplates(
                                                  [
                                                    ...includedPanelCodeDepartmentTemplates,
                                                    ...(pc.panelCodeDepartmentTemplates?.map(
                                                      (pcdt) => ({
                                                        dt: pcdt,
                                                        panelCode: pc,
                                                      })
                                                    ) ?? []),
                                                  ]
                                                );
                                              }
                                            }}
                                          >
                                            De/Select all
                                          </button>
                                        </div>
                                      </div>

                                      <div>
                                        <small>
                                          <strong>Dept templates:</strong>
                                        </small>
                                      </div>
                                      <div>
                                        <ol>
                                          {pc.panelCodeDepartmentTemplates?.map(
                                            (pcdt) => {
                                              const includes =
                                                includedPanelCodeDepartmentTemplates.find(
                                                  (pcdtx) =>
                                                    `${pcdtx.dt?.id}` ===
                                                    `${pcdt.id}`
                                                );
                                              return (
                                                <>
                                                  <li>
                                                    <div
                                                      style={{
                                                        cursor: "pointer",
                                                        backgroundColor:
                                                          includes
                                                            ? `lightgreen`
                                                            : "",
                                                      }}
                                                      onClick={() => {
                                                        if (includes) {
                                                          setIncludedPanelCodeDepartmentTemplates(
                                                            includedPanelCodeDepartmentTemplates.filter(
                                                              (pcdtx) =>
                                                                `${pcdtx.dt?.id}` !==
                                                                `${pcdt.id}`
                                                            )
                                                          );
                                                        } else {
                                                          setIncludedPanelCodeDepartmentTemplates(
                                                            [
                                                              ...includedPanelCodeDepartmentTemplates,
                                                              {
                                                                dt: pcdt,
                                                                panelCode: pc,
                                                              },
                                                            ]
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <small>
                                                        <strong>
                                                          {pcdt
                                                            .departmentTemplate
                                                            ?.name ?? ""}
                                                        </strong>
                                                        :{" "}
                                                        {departmentTemplates
                                                          .filter(
                                                            (dt) =>
                                                              `${dt.departmentTemplate?.id}` ===
                                                              `${pcdt.departmentTemplate?.id}`
                                                          )
                                                          .map(
                                                            (dt) =>
                                                              dt.departmentTemplateItems ??
                                                              []
                                                          )
                                                          .flat()
                                                          ?.map((dti) => {
                                                            const foundPCDTI =
                                                              pc.panelCodeDepartmentTemplateItems?.find(
                                                                (pcdtix) =>
                                                                  `${pcdtix.departmentTemplateItem?.id}` ===
                                                                  `${dti.departmentTemplateItem?.id}`
                                                              );
                                                            return (
                                                              <>
                                                                <span
                                                                  className={`${
                                                                    foundPCDTI?.excluded
                                                                      ? `text-danger`
                                                                      : ``
                                                                  }`}
                                                                >
                                                                  {dti
                                                                    .departmentTemplateItem
                                                                    ?.name ??
                                                                    ""}
                                                                  {", "}
                                                                </span>
                                                              </>
                                                            );
                                                          })}
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              );
                                            }
                                          )}
                                        </ol>
                                      </div>
                                    </div>
                                  </li>{" "}
                                </>
                              );
                            })}
                          </ol>
                        </div>
                        <hr></hr>
                        <div>
                          <strong>Estimated hours total: </strong>
                        </div>
                        <div>
                          <strong>Simulation</strong>
                        </div>
                        <div className="d-flex">
                          <div>
                            <strong>Filter parameters:</strong>
                          </div>
                          <div className="flex-grow-1 ">
                            <Select
                              placeholder="Department..."
                              options={ctx?.extDepartments ?? []}
                              getOptionLabel={(d) => `${d.name}`}
                              onChange={(v) => {
                                const val = v as unknown as ExtDepartment;

                                setAutoAssignDeptTemplateFilterParams({
                                  ...autoAssignDeptTemplateFilterParams,
                                  deptId: val.id,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div>
                          {workOrdersFromLoading ? (
                            <>
                              <Spinner animation="border" />
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={async () => {
                                  const newWorkOrdersFrom = {
                                    ...workOrdersFrom.current,
                                  } as MeetingTaskListsView;

                                  console.log(
                                    "[newworkordersfrom] before",
                                    newWorkOrdersFrom
                                  );

                                  let datePointer = new Date(
                                    new Date(
                                      `${makeDateString(new Date())}T00:00:00Z`
                                    )
                                  );

                                  for (let pcdt of includedPanelCodeDepartmentTemplates) {
                                    for (let dti of departmentTemplates
                                      .filter(
                                        (dt) =>
                                          `${dt.departmentTemplate?.id}` ===
                                          `${pcdt.dt.departmentTemplate?.id}`
                                      )
                                      .map(
                                        (dt) => dt.departmentTemplateItems ?? []
                                      )
                                      .flat()) {
                                      const foundPanelCode =
                                        state.job.job?.panelCodes?.find(
                                          (pcx) =>
                                            `${pcx.id}` ===
                                            `${pcdt.panelCode?.id}`
                                        );

                                      const foundPanelCodeDepartmentTemplateItem =
                                        foundPanelCode?.panelCodeDepartmentTemplateItems?.find(
                                          (dtix) =>
                                            `${dtix.departmentTemplateItem?.id}` ===
                                              `${dti.departmentTemplateItem?.id}` &&
                                            // Filter not excluded
                                            !dtix.excluded
                                        );

                                      if (
                                        !foundPanelCodeDepartmentTemplateItem
                                      ) {
                                        continue;
                                      }

                                      // TODO: Check if the task has been assigned before assigning
                                      // By panel code & ext sub process id

                                      // search local first
                                      const foundLocalTasks =
                                        workOrdersFrom?.current?.taskLists.find(
                                          (tL) =>
                                            `${tL.taskList?.extPanelCodeId}` ===
                                              `${foundPanelCode?.id}` &&
                                            tL.taskList?.meetingTasks.find(
                                              (mtx) =>
                                                `${mtx.extSubProcessId}` ===
                                                  `${foundPanelCodeDepartmentTemplateItem.departmentTemplateItem?.id}` &&
                                                mtx.meetingTaskInCharges
                                                  .length > 0
                                            )
                                        );
                                      if (foundLocalTasks) {
                                        console.log(
                                          "[found local task]",
                                          foundLocalTasks,
                                          pcdt.panelCode.id,
                                          dti.departmentTemplateItem?.id,
                                          dti.departmentTemplateItem?.name
                                        );
                                        continue;
                                      }
                                      // search backend for existing taskList
                                      let newTaskList =
                                        MeetingTaskListView.fromPartial({
                                          taskList: MeetingTaskList.fromPartial(
                                            {}
                                          ),
                                        });
                                      let foundTaskList = await (async () => {
                                        try {
                                          const resp = await fetch(
                                            `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-check?extPanelCodeId=${foundPanelCode?.id}&extSubProcessId=${foundPanelCodeDepartmentTemplateItem.departmentTemplateItem?.id}`,
                                            {
                                              headers: {
                                                authorization:
                                                  ctx?.apiKey ?? "",
                                              },
                                            }
                                          );
                                          if (resp.status !== 200)
                                            throw await resp.text();
                                          return MeetingTaskList.fromPartial(
                                            await resp.json()
                                          );
                                        } catch (e) {
                                          // Nothing to do. continue
                                          return null;
                                        }
                                      })();

                                      // If task list is found, but has in charge in it, deny.
                                      if (
                                        foundTaskList != null &&
                                        (foundTaskList.meetingTasks?.find(
                                          (mtx) =>
                                            `${mtx.extSubProcessId}` ===
                                            `${foundPanelCodeDepartmentTemplateItem.departmentTemplateItem?.id}`
                                        )?.meetingTaskInCharges.length ?? 0) > 0
                                      ) {
                                        // Cancel, because in charge is not empty.
                                        continue;
                                      }

                                      // If task list is null, return. We only want to add user to the taskList.
                                      if (foundTaskList === null) {
                                        continue;
                                      }

                                      newTaskList.taskList = foundTaskList;

                                      // Check each filtered users if time exceeds in date pointer.
                                      // If no users are available, send to the next day
                                      let userToAdd = undefined as
                                        | ExtUser
                                        | undefined;

                                      while (!userToAdd) {
                                        userToAdd = filteredUsers?.find((u) => {
                                          const userWorkOrders = (
                                            workOrdersFrom?.current?.taskLists.filter(
                                              (tL) =>
                                                tL.taskList?.meetingTasks.find(
                                                  (mt) =>
                                                    mt.meetingTaskInCharges.find(
                                                      (mticx) =>
                                                        `${mticx.extUserId}` ===
                                                          `${u.id}` ||
                                                        `${tL.taskList?.extInChargeId}` ===
                                                          `${u.id}`
                                                    )
                                                )
                                            ) ?? []
                                          ).flat();

                                          const totalHours =
                                            filterWorkOrderByDate(
                                              MeetingTaskListsView.fromPartial({
                                                taskLists: userWorkOrders,
                                              }),
                                              makeDateString(datePointer),
                                              u
                                            )?.reduce(
                                              (acc, tL) =>
                                                acc +
                                                getWorkOrderTimeMinutes(
                                                  tL.taskList,
                                                  rcemDepartments,
                                                  integrationModules,
                                                  machineProgramsProto,
                                                  departmentTemplatesProto,
                                                  panelCodeDepartmentTemplateItemsProto.current,

                                                  u.id,
                                                  makeDateString(datePointer),
                                                  true
                                                ).totalHours,
                                              0
                                            ) ?? 0;
                                          // console.log(
                                          //   `[user ${u.username} ${u.username} ${u.id}] hrs ${totalHours}`
                                          // );

                                          return totalHours < 8;
                                        });

                                        if (!userToAdd) {
                                          datePointer = new Date(
                                            new Date(datePointer).getTime() +
                                              86400000
                                          );
                                        }
                                      }

                                      if (userToAdd) {
                                        console.log("[user ok]", newTaskList);

                                        // Create task
                                        const foundMeetingTask =
                                          newTaskList.taskList?.meetingTasks.find(
                                            (mtx) =>
                                              `${mtx.extSubProcessId}` ===
                                              `${foundPanelCodeDepartmentTemplateItem.departmentTemplateItem?.id}`
                                          );

                                        if (foundMeetingTask) {
                                          // Override start date
                                          foundMeetingTask.start =
                                            makeDateString(datePointer);

                                          // Override end date
                                          if (
                                            foundMeetingTask
                                              .meetingTaskTargetDates.length > 0
                                          ) {
                                            foundMeetingTask.meetingTaskTargetDates =
                                              foundMeetingTask.meetingTaskTargetDates.map(
                                                (mttd) => ({
                                                  ...mttd,
                                                  date: makeDateString(
                                                    datePointer
                                                  ),
                                                })
                                              );
                                          } else {
                                            foundMeetingTask.meetingTaskTargetDates =
                                              [
                                                MeetingMeetingTaskTargetDate.fromPartial(
                                                  {
                                                    date: makeDateString(
                                                      datePointer
                                                    ),
                                                    masterJavaBaseModel:
                                                      MasterJavaBaseModel.fromPartial(
                                                        { uuid: v4() }
                                                      ),
                                                  }
                                                ),
                                              ];
                                          }

                                          // Override meetingTaskInCharges
                                          if (
                                            foundMeetingTask.meetingTaskInCharges
                                          ) {
                                            foundMeetingTask.meetingTaskInCharges =
                                              [
                                                ...foundMeetingTask.meetingTaskInCharges,
                                                MeetingMeetingTaskInCharge.fromPartial(
                                                  {
                                                    extUserId:
                                                      userToAdd.id !== null &&
                                                      userToAdd.id !== undefined
                                                        ? `${userToAdd.id}`
                                                        : undefined,
                                                    masterJavaBaseModel:
                                                      MasterJavaBaseModel.fromPartial(
                                                        { uuid: v4() }
                                                      ),
                                                  }
                                                ),
                                              ];
                                          } else {
                                            foundMeetingTask.meetingTaskInCharges =
                                              [
                                                MeetingMeetingTaskInCharge.fromPartial(
                                                  {
                                                    extUserId:
                                                      userToAdd.id !== null &&
                                                      userToAdd.id !== undefined
                                                        ? `${userToAdd.id}`
                                                        : undefined,
                                                    masterJavaBaseModel:
                                                      MasterJavaBaseModel.fromPartial(
                                                        { uuid: v4() }
                                                      ),
                                                  }
                                                ),
                                              ];
                                          }
                                        }

                                        console.log(
                                          "[NEW TASK LIST]",
                                          newTaskList
                                        );

                                        newWorkOrdersFrom.taskLists.push(
                                          newTaskList
                                        );

                                        console.log(
                                          "[newworkordersfrom] after",
                                          newWorkOrdersFrom
                                        );
                                      }
                                    }
                                  }
                                  // includedPanelCodeDepartmentTemplates.forEach(
                                  //   (pcdt) =>
                                  // );

                                  console.log(
                                    "[newworkordersfrom] final",
                                    newWorkOrdersFrom
                                  );
                                  if (newWorkOrdersFrom) {
                                    workOrdersFrom.current = newWorkOrdersFrom;
                                    render();
                                  }
                                }}
                              >
                                Generate Work Orders
                              </button>
                            </>
                          )}
                        </div>
                        <div
                          className="overflow-auto border border-dark"
                          style={{ height: "65vh", resize: "vertical" }}
                        >
                          <table
                            className="table table-sm"
                            style={{ borderCollapse: "separate" }}
                          >
                            <tr>
                              {["#", "Manpower"].map((h) => (
                                <th
                                  className="border border-dark bg-dark text-light"
                                  style={{ position: "sticky", top: 0 }}
                                >
                                  {h}
                                </th>
                              ))}
                              {[...Array(30)].map((_, i) => {
                                const date = new Date(
                                  new Date(
                                    `${makeDateString(new Date())}T00:00:00Z`
                                  ).getTime() +
                                    i * 86400000
                                );
                                return (
                                  <>
                                    <th
                                      className="bg-dark text-light"
                                      style={{ position: "sticky", top: 0 }}
                                    >
                                      {(() => {
                                        try {
                                          return (
                                            <>
                                              {Intl.DateTimeFormat("en-US", {
                                                dateStyle: "short",
                                              }).format(date)}
                                            </>
                                          );
                                        } catch (e) {
                                          return <></>;
                                        }
                                      })()}
                                    </th>
                                  </>
                                );
                              })}
                            </tr>
                            {(() => {
                              return filteredUsers?.map((u, i) => {
                                const hasWorkOrders =
                                  workOrdersFrom?.current?.taskLists.find(
                                    (tL) =>
                                      tL.taskList?.meetingTasks.find((mt) =>
                                        mt.meetingTaskInCharges.find(
                                          (mticx) =>
                                            `${mticx.extUserId}` ===
                                              `${u.id}` ||
                                            `${tL.taskList?.extInChargeId}` ===
                                              `${u.id}`
                                        )
                                      )
                                  );

                                return (
                                  <>
                                    <tr>
                                      <td className="border border-dark">
                                        {i + 1}
                                      </td>
                                      <td
                                        className="border border-dark"
                                        style={{
                                          position: "sticky",
                                          left: 0,
                                          backgroundColor: hasWorkOrders
                                            ? "lightgreen"
                                            : "",
                                        }}
                                      >
                                        {u.name} ({u.departmentName})
                                      </td>
                                      {[...Array(30)].map((_, i) => {
                                        const date = new Date(
                                          new Date(
                                            `${makeDateString(
                                              new Date()
                                            )}T00:00:00Z`
                                          ).getTime() +
                                            i * 86400000
                                        );
                                        const curDate = date
                                          .toISOString()
                                          .split("T")[0];
                                        const foundWos = filterWorkOrderByDate(
                                          workOrdersFrom.current,
                                          curDate,
                                          u
                                        );

                                        console.log(
                                          "[filter wo from]",
                                          workOrdersFrom
                                        );

                                        const workOrderWithLoads =
                                          foundWos?.map((tL) => ({
                                            taskList: tL,
                                            load: getWorkOrderTimeMinutes(
                                              tL.taskList,
                                              rcemDepartments,
                                              integrationModules,
                                              machineProgramsProto,
                                              departmentTemplatesProto,
                                              panelCodeDepartmentTemplateItemsProto.current,

                                              u.id,
                                              curDate,
                                              true
                                            ),
                                          }));

                                        const workOrderLoadsTotal =
                                          workOrderWithLoads?.reduce(
                                            (acc, tL) =>
                                              acc + (tL.load.totalHours ?? 0),
                                            0
                                          ) ?? 0;
                                        console.log(
                                          `[wo with load] ${u.id}`,
                                          workOrdersFrom,
                                          curDate,
                                          foundWos,
                                          workOrderWithLoads,
                                          workOrderLoadsTotal
                                        );

                                        return (
                                          <>
                                            <td
                                              className="border border-dark"
                                              style={{
                                                backgroundColor:
                                                  checkLoadColorDay(
                                                    workOrderLoadsTotal
                                                  ),
                                              }}
                                            >
                                              <div>
                                                {/* <div>{curDate}</div> */}
                                                <div>
                                                  WOs: {foundWos?.length} (
                                                  {workOrderLoadsTotal.toFixed(
                                                    1
                                                  )}{" "}
                                                  hrs)
                                                </div>
                                              </div>
                                            </td>
                                          </>
                                        );
                                      })}
                                    </tr>
                                  </>
                                );
                              });
                            })()}
                          </table>
                        </div>
                      </div>
                    </>
                  );
                })()
              ) : (
                <></>
              )}

              {deptTemplateViewType === "Auto Assign 2" ? (
                <>
                  <div>
                    <div className="d-flex">
                      <h4>Auto Assign v2</h4>
                      {panelCodeDepartmentTemplateItemsLoading ? (
                        <>
                          <Spinner animation="border" />
                        </>
                      ) : (
                        <></>
                      )}
                      <div>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            if (
                              selectedPanelCodeDepartmentTemplateItems.length >
                              0
                            ) {
                              setSelectedPanelCodeDepartmentTemplateItems([]);
                            } else {
                              setSelectedPanelCodeDepartmentTemplateItems(
                                state.job.job?.panelCodes
                                  ?.map(
                                    (c) =>
                                      c.panelCodeDepartmentTemplateItems ?? []
                                  )
                                  .flat() ?? []
                              );
                            }
                          }}
                        >
                          Add All
                        </button>
                      </div>
                      <div className="d-flex">
                        Detailed?{" "}
                        <input
                          type="checkbox"
                          checked={detailedView}
                          onClick={() => {
                            setDetailedView(!detailedView);
                          }}
                        />
                      </div>
                    </div>
                    <hr className="border border-dark" />

                    {/* <div className="d-flex">
                      <strong>Dept:</strong>
                      <div className="flex-grow-1">
                        <Select
                          options={ctx?.extDepartments}
                          getOptionLabel={(d) => `${d.name}`}
                          placeholder="Department..."
                          onChange={(v) => {
                            const val = v as unknown as ExtDepartment;

                            setAutoAssignFilters({
                              ...autoAssignFilters,
                              department: val,
                            });
                          }}
                        />
                      </div>
                    </div> */}

                    <div
                      className="overflow-auto border border-dark shadow shadow-md"
                      style={{ resize: "vertical", height: "45vh" }}
                    >
                      <ol>
                        {state.job.job?.panelCodes?.map((c, i) => {
                          return (
                            <>
                              <div>
                                <div>
                                  <small>
                                    {i + 1}. {c.type} | {c.name}
                                  </small>
                                </div>
                                <div className="d-flex">
                                  {c.panelCodeDepartmentTemplates?.map(
                                    (t, j) => {
                                      const departmentTemplateItems =
                                        departmentTemplates.find(
                                          (tx) =>
                                            `${tx.departmentTemplate?.id}` ===
                                            `${t.departmentTemplate?.id}`
                                        )?.departmentTemplateItems ?? [];

                                      const departmentTemplateItemsIncluded =
                                        departmentTemplates
                                          .find(
                                            (tx) =>
                                              `${tx.departmentTemplate?.id}` ===
                                              `${t.departmentTemplate?.id}`
                                          )
                                          ?.departmentTemplateItems.filter(
                                            (it) => {
                                              const foundPCDTI =
                                                c.panelCodeDepartmentTemplateItems?.find(
                                                  (pcdti) =>
                                                    `${pcdti.departmentTemplateItem?.id}` ===
                                                    `${it.departmentTemplateItem?.id}`
                                                );

                                              return !foundPCDTI?.excluded;
                                            }
                                          ) ?? [];

                                      const satisfiedDepartmentTemplateItems =
                                        departmentTemplateItemsIncluded?.filter(
                                          (it) => {
                                            const foundPCDTI =
                                              c.panelCodeDepartmentTemplateItems?.find(
                                                (pcdti) =>
                                                  `${pcdti.departmentTemplateItem?.id}` ===
                                                  `${it.departmentTemplateItem?.id}`
                                              );

                                            return selectedPanelCodeDepartmentTemplateItems.find(
                                              (pcdti) =>
                                                `${pcdti.id}` ===
                                                `${foundPCDTI?.id}`
                                            );
                                          }
                                        );

                                      return (
                                        <>
                                          <div
                                            className="px-1 border border-dark rounded rounded-md shadow shadow-md"
                                            style={{
                                              backgroundColor:
                                                departmentTemplateItems?.length !==
                                                  0 &&
                                                satisfiedDepartmentTemplateItems >=
                                                  departmentTemplateItemsIncluded
                                                  ? `lightsalmon`
                                                  : `white`,
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            <div className="d-flex">
                                              <small>
                                                {i + 1}.{j + 1}.{" "}
                                                {t.departmentTemplate?.name}{" "}
                                                <span
                                                  className={`mx-1 font-weight-bold ${
                                                    t.departmentTemplate
                                                      ?.extDepartmentId
                                                      ? `text-success`
                                                      : `text-danger`
                                                  }`}
                                                >
                                                  {t.departmentTemplate
                                                    ?.extDepartmentId &&
                                                  t.departmentTemplate
                                                    .extDepartmentId !== 0
                                                    ? ctx?.extDepartments.find(
                                                        (d) =>
                                                          `${d.id}` ===
                                                          `${t.departmentTemplate?.extDepartmentId}`
                                                      )?.name
                                                    : ""}
                                                </span>{" "}
                                                (
                                                {
                                                  satisfiedDepartmentTemplateItems.length
                                                }
                                                /
                                                {
                                                  departmentTemplateItemsIncluded.length
                                                }
                                                )
                                              </small>
                                              <button
                                                className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                  if (
                                                    selectedPanelCodeDepartmentTemplateItems.find(
                                                      (pcdti) =>
                                                        departmentTemplateItems
                                                          ?.map((it) =>
                                                            c.panelCodeDepartmentTemplateItems?.find(
                                                              (pcdti) =>
                                                                `${pcdti.departmentTemplateItem?.id}` ===
                                                                `${it.departmentTemplateItem?.id}`
                                                            )
                                                          )
                                                          .find(
                                                            (pcdtix) =>
                                                              `${pcdtix?.id}` ===
                                                              `${pcdti.id}`
                                                          )
                                                    )
                                                  ) {
                                                    console.log("pcdti found");
                                                    setSelectedPanelCodeDepartmentTemplateItems(
                                                      selectedPanelCodeDepartmentTemplateItems.filter(
                                                        (pcdti) =>
                                                          !departmentTemplateItems
                                                            ?.map((it) =>
                                                              c.panelCodeDepartmentTemplateItems?.find(
                                                                (pcdti) =>
                                                                  `${pcdti.departmentTemplateItem?.id}` ===
                                                                  `${it.departmentTemplateItem?.id}`
                                                              )
                                                            )
                                                            .find(
                                                              (pcdtix) =>
                                                                `${pcdtix?.id}` ===
                                                                `${pcdti.id}`
                                                            )
                                                      )
                                                    );
                                                  } else {
                                                    setSelectedPanelCodeDepartmentTemplateItems(
                                                      [
                                                        ...selectedPanelCodeDepartmentTemplateItems,
                                                        ...(departmentTemplateItems
                                                          ?.map((it) =>
                                                            c.panelCodeDepartmentTemplateItems?.find(
                                                              (pcdti) =>
                                                                `${pcdti.departmentTemplateItem?.id}` ===
                                                                `${it.departmentTemplateItem?.id}`
                                                            )
                                                          )
                                                          .filter(
                                                            (pcdti) => pcdti
                                                          )
                                                          .map(
                                                            (pcdti) =>
                                                              pcdti as PanelCodeDepartmentTemplateItem
                                                          ) ?? []),
                                                      ]
                                                    );
                                                  }
                                                }}
                                              >
                                                <small>Toggle</small>
                                              </button>
                                            </div>

                                            <div className="d-flex">
                                              {departmentTemplates
                                                .map((t) =>
                                                  t.departmentTemplateItems.map(
                                                    (i) => ({
                                                      template: t,
                                                      item: i,
                                                    })
                                                  )
                                                )
                                                .flat()
                                                .filter(
                                                  (it) =>
                                                    `${it.template.departmentTemplate?.id}` ===
                                                    `${t.departmentTemplate?.id}`
                                                )
                                                .map((it, k) => {
                                                  const foundPCDTI =
                                                    c.panelCodeDepartmentTemplateItems?.find(
                                                      (pcdti) =>
                                                        pcdti
                                                          .departmentTemplateItem
                                                          ?.id &&
                                                        pcdti
                                                          .departmentTemplateItem
                                                          ?.id !== 0 &&
                                                        `${pcdti.departmentTemplateItem?.id}` ===
                                                          `${it.item.departmentTemplateItem?.id}`
                                                    );

                                                  const foundWorkOrder = [
                                                    ...(workOrders?.current
                                                      ?.taskLists ?? []),
                                                    ...autoAssignWoChecker.taskLists,
                                                  ].find((tL) =>
                                                    tL.taskList?.meetingTasks.find(
                                                      (mt) =>
                                                        mt.extPanelCodeDepartmentTemplateItemId &&
                                                        mt.extPanelCodeDepartmentTemplateItemId !==
                                                          "" &&
                                                        mt.extPanelCodeDepartmentTemplateItemId !==
                                                          "0" &&
                                                        `${mt.extPanelCodeDepartmentTemplateItemId}` ===
                                                          `${foundPCDTI?.id}`
                                                    )
                                                  );

                                                  return (
                                                    <>
                                                      <div
                                                        // onClick={() => {
                                                        //   if (
                                                        //     !foundPCDTI?.excluded
                                                        //   ) {
                                                        //     if (
                                                        //       !selectedPanelCodeDepartmentTemplateItems.find(
                                                        //         (itx, kx) =>
                                                        //           `${itx.id}` ===
                                                        //           `${foundPCDTI?.id}`
                                                        //       ) &&
                                                        //       foundPCDTI
                                                        //     ) {
                                                        //       setSelectedPanelCodeDepartmentTemplateItems(
                                                        //         [
                                                        //           ...selectedPanelCodeDepartmentTemplateItems,
                                                        //           foundPCDTI,
                                                        //         ]
                                                        //       );
                                                        //     } else {
                                                        //       setSelectedPanelCodeDepartmentTemplateItems(
                                                        //         selectedPanelCodeDepartmentTemplateItems.filter(
                                                        //           (itx) =>
                                                        //             `${itx.id}` !==
                                                        //             `${foundPCDTI?.id}`
                                                        //         )
                                                        //       );
                                                        //     }
                                                        //   }
                                                        // }}
                                                        className="px-1 mx-1 border border-dark rounded rounded-md shadow shadow-md"
                                                        style={{
                                                          whiteSpace: "nowrap",
                                                          backgroundColor:
                                                            foundPCDTI?.excluded
                                                              ? `gray`
                                                              : foundWorkOrder
                                                              ? `lightgreen`
                                                              : selectedPanelCodeDepartmentTemplateItems.find(
                                                                  (itx, kx) =>
                                                                    `${itx.id}` ===
                                                                    `${foundPCDTI?.id}`
                                                                )
                                                              ? `lightblue`
                                                              : `white`,
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <div>
                                                          {foundWorkOrder ? (
                                                            <>
                                                              <small>
                                                                <strong>
                                                                  WO: #
                                                                  {
                                                                    foundWorkOrder
                                                                      .taskList
                                                                      ?.masterJavaBaseModel
                                                                      ?.id
                                                                  }
                                                                </strong>
                                                              </small>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <button
                                                                className="btn btn-sm btn-outline-primary"
                                                                onClick={() => {
                                                                  setOpenManualDeptTemplateWoAssignmentModal(
                                                                    true
                                                                  );
                                                                  if (
                                                                    foundPCDTI
                                                                  ) {
                                                                    setSelectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem(
                                                                      foundPCDTI
                                                                    );
                                                                    setSelectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItemPanelCode(
                                                                      c
                                                                    );
                                                                  }
                                                                }}
                                                              >
                                                                <small>
                                                                  <Add />
                                                                </small>
                                                              </button>
                                                            </>
                                                          )}
                                                          <div>
                                                            {" "}
                                                            <small>
                                                              {i + 1}.{j + 1}.
                                                              {k + 1}.{" "}
                                                              {
                                                                it.item
                                                                  .departmentTemplateItem
                                                                  ?.name
                                                              }
                                                            </small>
                                                          </div>
                                                          <div>
                                                            {(() => {
                                                              const foundRcemSubProcess =
                                                                rcemDepartments?.departments
                                                                  .map((d) =>
                                                                    d.processes
                                                                      .map(
                                                                        (p) =>
                                                                          p.subProcesses.map(
                                                                            (
                                                                              s
                                                                            ) => ({
                                                                              dept: d,
                                                                              process:
                                                                                p,
                                                                              subProcess:
                                                                                s,
                                                                            })
                                                                          )
                                                                      )
                                                                      .flat()
                                                                  )
                                                                  .flat()
                                                                  .find(
                                                                    (d) =>
                                                                      `${d.subProcess.masterJavaBaseModel?.id}` ===
                                                                      `${it.item.departmentTemplateItem?.extRcemSubProcessId}`
                                                                  );

                                                              return foundRcemSubProcess ? (
                                                                <>
                                                                  <div>
                                                                    <div>
                                                                      <small>
                                                                        {" "}
                                                                        {/* {
                                                                      foundRcemSubProcess
                                                                        .dept
                                                                        .name
                                                                    }
                                                                    :{" "}
                                                                    {
                                                                      foundRcemSubProcess
                                                                        .process
                                                                        .name
                                                                    }
                                                                    :{" "} */}
                                                                        {
                                                                          foundRcemSubProcess
                                                                            .subProcess
                                                                            .name
                                                                        }
                                                                        :{" "}
                                                                        {
                                                                          foundRcemSubProcess
                                                                            .subProcess
                                                                            .timeProcess
                                                                        }{" "}
                                                                        h
                                                                      </small>
                                                                    </div>
                                                                    <div
                                                                      className={`${
                                                                        foundRcemSubProcess
                                                                          .subProcess
                                                                          .skillId &&
                                                                        foundRcemSubProcess
                                                                          .subProcess
                                                                          .skillId !==
                                                                          "0"
                                                                          ? ``
                                                                          : `text-danger`
                                                                      }`}
                                                                    >
                                                                      <small className="font-weight-bold">
                                                                        {foundRcemSubProcess
                                                                          .subProcess
                                                                          .skillId &&
                                                                        foundRcemSubProcess
                                                                          .subProcess
                                                                          .skillId !==
                                                                          "0"
                                                                          ? skills.skills.find(
                                                                              (
                                                                                s
                                                                              ) =>
                                                                                `${s.masterJavaBaseModel?.id}` ===
                                                                                `${foundRcemSubProcess.subProcess.skillId}`
                                                                            )
                                                                              ?.name
                                                                          : "No skill"}
                                                                      </small>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <div className="text-danger">
                                                                    <small className="font-weight-bold">
                                                                      No RCEM
                                                                    </small>
                                                                  </div>{" "}
                                                                </>
                                                              );
                                                            })()}
                                                          </div>
                                                        </div>
                                                        {detailedView ? (
                                                          <>
                                                            <div>
                                                              <small>
                                                                {
                                                                  foundWorkOrder
                                                                    ?.taskList
                                                                    ?.taskStart
                                                                }
                                                                -
                                                                {
                                                                  foundWorkOrder
                                                                    ?.taskList
                                                                    ?.taskEnd
                                                                }
                                                                ,
                                                                {[
                                                                  ...new Set(
                                                                    foundWorkOrder?.taskList?.meetingTasks
                                                                      .flatMap(
                                                                        (mt) =>
                                                                          mt.meetingTaskInCharges
                                                                      )
                                                                      .map(
                                                                        (
                                                                          mtic
                                                                        ) =>
                                                                          mtic.extUserId
                                                                      )
                                                                  ),
                                                                ]
                                                                  .map(
                                                                    (uid) =>
                                                                      ctx?.extUsers.find(
                                                                        (u) =>
                                                                          `${u.id}` ===
                                                                          `${uid}`
                                                                      )
                                                                        ?.username
                                                                  )
                                                                  .join(",")}
                                                              </small>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </div>
                                                    </>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </ol>
                    </div>

                    <div className="d-flex">
                      <strong>By Skillset:</strong>
                      <div className="flex-grow-1">
                        <Select
                          options={skills.skills}
                          getOptionLabel={(s) => `${s.name}`}
                          placeholder="Skillset..."
                          onChange={(v) => {
                            const val = v as unknown as PpicSkill;

                            setAutoAssignFilters({
                              ...autoAssignFilters,
                              department: null,
                              skill: val,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex">
                      <strong>By Dept:</strong>
                      <div className="flex-grow-1">
                        <Select
                          options={ctx?.extDepartments}
                          getOptionLabel={(d) => `${d.name}`}
                          placeholder="Department..."
                          onChange={(v) => {
                            const val = v as unknown as ExtDepartment;

                            setAutoAssignFilters({
                              ...autoAssignFilters,
                              department: val,
                              skill: null,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex">
                      <strong>Holiday Template:</strong>
                      <div className="flex-grow-1">
                        <Select
                          options={[
                            ...new Set(
                              holidays.holidays.map(
                                (h) => `${h.templateName?.toLowerCase()}`
                              )
                            ),
                          ].map((n) => ({ label: n, value: n }))}
                          getOptionLabel={(h) => `${h.label}`}
                          placeholder="Holiday template..."
                          onChange={(v) => {
                            const val = v as unknown as {
                              label: string;
                              value: string;
                            };

                            setAutoAssignFilters({
                              ...autoAssignFilters,
                              holidayTemplateStr: val.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <small>
                        Filtered holidays: {holidaysFiltered.length}
                      </small>{" "}
                    </div>

                    <div className="d-flex my-1">
                      <strong>Include Saturday?</strong>
                      <div className="flex-grow-1">
                        <input
                          type="checkbox"
                          checked={autoAssignFilters.includeSaturday}
                          onClick={() => {
                            setAutoAssignFilters({
                              ...autoAssignFilters,
                              includeSaturday:
                                !autoAssignFilters.includeSaturday,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex my-1">
                      <strong>Skill only priority?</strong>
                      <div className="flex-grow-1">
                        <Select
                          options={
                            [
                              { value: true, label: "Priority" },
                              { value: false, label: "Non-Priority" },
                              { value: null, label: "All" },
                            ] as { label: string; value: boolean | null }[]
                          }
                          onChange={(v) => {
                            const val = v as unknown as {
                              label: string;
                              value: boolean | null;
                            };

                            setAutoAssignFilters({
                              ...autoAssignFilters,
                              filterOnlyPriority: val.value,
                            });
                          }}
                        />{" "}
                      </div>
                    </div>

                    <AutoAssignIntervals />

                    {autoAssignFilters.intervalFrom &&
                    autoAssignFilters.intervalTo &&
                    (autoAssignFilters.skill ||
                      autoAssignFilters.department) ? (
                      <>
                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              handleGetAutoAssignV2Data();
                            }}
                          >
                            Get Data
                          </button>
                          {dataGot ? (
                            <>
                              <button
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  handleAutoAssignV2();
                                }}
                              >
                                Auto Assign
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {(() => {
                      return (
                        <>
                          <div
                            className="overflow-auto border border-dark shadow shadow-md"
                            style={{ height: "40vh", resize: "vertical" }}
                          >
                            <table
                              className="table table-sm table-hover"
                              style={{ borderCollapse: "separate" }}
                            >
                              <tr>
                                {["#", "Name", "Priority"].map((h) => (
                                  <>
                                    <th
                                      className="bg-dark text-light"
                                      style={{
                                        position: "sticky",
                                        top: 0,
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {h}
                                    </th>
                                  </>
                                ))}
                                {datesIntervalFrom.map((d) => {
                                  const isHoliday =
                                    new Date(d).getDay() === 0 ||
                                    (autoAssignFilters.includeSaturday
                                      ? false
                                      : new Date(d).getDay() === 6) ||
                                    holidaysFiltered.find((h) => h.date === d);

                                  return (
                                    <>
                                      {" "}
                                      <th
                                        className="text-light"
                                        style={{
                                          position: "sticky",
                                          top: 0,
                                          whiteSpace: "nowrap",
                                          backgroundColor: isHoliday
                                            ? `red`
                                            : `black`,
                                        }}
                                      >
                                        {Intl.DateTimeFormat("en-US", {
                                          dateStyle: "short",
                                        }).format(new Date(d))}
                                      </th>
                                    </>
                                  );
                                })}
                              </tr>
                              {(() => {
                                const d = (() => {
                                  if (autoAssignFilters.skill) {
                                    return autoAssignFilters.skill.skillWorkers
                                      .filter((w) =>
                                        autoAssignFilters.filterOnlyPriority !==
                                        null
                                          ? autoAssignFilters.filterOnlyPriority
                                            ? w.isPriority
                                            : !w.isPriority
                                          : true
                                      )
                                      .map((w) => ({
                                        user:
                                          ctx?.extUsers.find(
                                            (u) =>
                                              `${u.id}` === `${w.extUserId}`
                                          ) ?? null,
                                        worker: w as PpicSkillWorker | null,
                                      }));
                                  } else if (autoAssignFilters.department) {
                                    return (
                                      ctx?.extUsers.filter(
                                        (u) =>
                                          `${u.departmentId}` ===
                                          `${autoAssignFilters.department?.id}`
                                      ) ?? []
                                    ).map((u) => ({
                                      worker: null as PpicSkillWorker | null,
                                      user: u,
                                    }));
                                  } else {
                                    return [];
                                  }
                                })();

                                d?.sort((a, b) =>
                                  a.worker?.skillLevel && b.worker?.skillLevel
                                    ? ppicSkillWorker_SkillLevelToNumber(
                                        b.worker.skillLevel
                                      ) -
                                      ppicSkillWorker_SkillLevelToNumber(
                                        a.worker.skillLevel
                                      )
                                    : 0
                                );

                                return d;
                              })()
                                .filter((ds) => ds.user)
                                .map((ds, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="border border-dark">
                                          {i + 1}
                                        </td>
                                        <td
                                          className="border border-dark bg-light"
                                          style={{
                                            position: "sticky",
                                            left: 0,
                                          }}
                                        >
                                          <small>
                                            <strong>{ds.user?.username}</strong>
                                          </small>
                                        </td>
                                        <td
                                          className={`border border-dark ${
                                            autoAssignFilters.department
                                              ? `bg-dark`
                                              : `bg-light`
                                          }`}
                                        >
                                          {ds?.worker?.isPriority ? (
                                            <input type="checkbox" checked />
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        {datesIntervalFrom.map((d) => {
                                          const wos = filterWorkOrderByDate(
                                            autoAssignWoChecker,
                                            makeDateString(new Date(d)),
                                            ds.user
                                          );

                                          const wosTotalTime =
                                            wos?.reduce(
                                              (acc, tL) =>
                                                acc +
                                                (getWorkOrderTimeMinutes(
                                                  tL.taskList,
                                                  rcemDepartments,
                                                  integrationModules,
                                                  machineProgramsProto,
                                                  departmentTemplatesProto,
                                                  panelCodeDepartmentTemplateItemsProto.current,

                                                  ds.user?.id,
                                                  d,
                                                  true
                                                ).totalHours ?? 0),
                                              0.0
                                            ) ?? 0;

                                          return (
                                            <>
                                              <td
                                                className="border border-dark"
                                                style={{
                                                  backgroundColor: chroma
                                                    .scale([
                                                      "lightsalmon",
                                                      "yellow",
                                                      "lightgreen",
                                                      "lightblue",
                                                    ])(wosTotalTime / 8)
                                                    .hex(),
                                                }}
                                              >
                                                <small>
                                                  <strong>
                                                    {wosTotalTime.toFixed(1)}h (
                                                    {wos?.length ?? 0})
                                                  </strong>
                                                </small>
                                              </td>
                                            </>
                                          );
                                        })}
                                      </tr>
                                    </>
                                  );
                                })}
                            </table>
                          </div>

                          <div>New WOs</div>

                          <div
                            className="overflow-auto border border-dark shadow shadow-md"
                            style={{ height: "45vh", resize: "vertical" }}
                          >
                            <table
                              className="table table-sm"
                              style={{ borderCollapse: "separate" }}
                            >
                              {[
                                "#",
                                "SPV",
                                "PIC",
                                "Date",
                                "Dept Template",
                                "RCEM",
                              ].map((h) => (
                                <>
                                  <th
                                    className="bg-dark text-light"
                                    style={{ position: "sticky", top: 0 }}
                                  >
                                    {h}
                                  </th>
                                </>
                              ))}
                              {workOrdersNewAdded.map((tL, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td className="border border-dark">
                                        {i + 1}
                                      </td>
                                      <td className="border border-dark">
                                        {
                                          ctx?.extUsers.find(
                                            (u) =>
                                              `${u.id}` ===
                                              `${tL.taskList?.extInChargeId}`
                                          )?.username
                                        }
                                      </td>
                                      <td className="border border-dark">
                                        {tL.taskList?.meetingTasks
                                          .map((mt) => mt.meetingTaskInCharges)
                                          .flat()
                                          .map(
                                            (c) =>
                                              ctx?.extUsers.find(
                                                (u) =>
                                                  `${u.id}` === `${c.extUserId}`
                                              )?.username
                                          )
                                          .join(",")}
                                      </td>
                                      <td className="border border-dark">
                                        {tL.taskList?.meetingTasks?.[0]?.start}
                                      </td>
                                      <td className="border border-dark">
                                        {(() => {
                                          const foundPCDTI =
                                            panelCodeDepartmentTemplateItemsProto.current.departmentTemplateItems.find(
                                              (i) =>
                                                `${i.masterJavaBaseModel?.id}` ===
                                                `${tL.taskList?.meetingTasks?.[0]?.extPanelCodeDepartmentTemplateItemId}`
                                            );

                                          const foundDTI =
                                            departmentTemplatesProto.templates
                                              .map((t) => t.items)
                                              .flat()
                                              .find(
                                                (i) =>
                                                  `${i.masterJavaBaseModel?.id}` ===
                                                  `${foundPCDTI?.departmentTemplateItem?.masterJavaBaseModel?.id}`
                                              );
                                          const foundDT =
                                            departmentTemplatesProto.templates.find(
                                              (t) =>
                                                t.items.find(
                                                  (i) =>
                                                    `${i.masterJavaBaseModel?.id}` ===
                                                    `${foundPCDTI?.departmentTemplateItem?.masterJavaBaseModel?.id}`
                                                )
                                            );

                                          return `${foundDT?.name}:${foundDTI?.name}`;
                                        })()}
                                      </td>
                                      <td className="border border-dark">
                                        {(() => {
                                          const foundPCDTI =
                                            panelCodeDepartmentTemplateItemsProto.current.departmentTemplateItems.find(
                                              (i) =>
                                                `${i.masterJavaBaseModel?.id}` ===
                                                `${tL.taskList?.meetingTasks?.[0]?.extPanelCodeDepartmentTemplateItemId}`
                                            );

                                          const foundRcemSubProcess =
                                            rcemDepartments?.departments
                                              .map((d) => d.processes)
                                              .flat()
                                              .map((p) => p.subProcesses)
                                              .flat()
                                              .find(
                                                (s) =>
                                                  `${s.masterJavaBaseModel?.id}` ===
                                                  `${foundPCDTI?.departmentTemplateItem?.extRcemSubProcessId}`
                                              );

                                          const foundRcemProcess =
                                            rcemDepartments?.departments
                                              .map((d) => d.processes)
                                              .flat()
                                              .find((p) =>
                                                p.subProcesses.find(
                                                  (s) =>
                                                    `${s.masterJavaBaseModel?.id}` ===
                                                    `${foundPCDTI?.departmentTemplateItem?.extRcemSubProcessId}`
                                                )
                                              );

                                          const foundRcemDept =
                                            rcemDepartments?.departments.find(
                                              (d) =>
                                                d.processes.find((p) =>
                                                  p.subProcesses.find(
                                                    (s) =>
                                                      `${s.masterJavaBaseModel?.id}` ===
                                                      `${foundPCDTI?.departmentTemplateItem?.extRcemSubProcessId}`
                                                  )
                                                )
                                            );

                                          return `${foundRcemDept?.name}:${foundRcemProcess?.name}:${foundRcemSubProcess?.name}`;
                                        })()}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </table>
                          </div>
                        </>
                      );
                    })()}
                  </div>
                </>
              ) : (
                <></>
              )}

              {deptTemplateViewType === "Auto Assign 3" ? (
                <>
                  <div>
                    <div>AutoAssign v3</div>

                    {state.job.job?.id ? (
                      <>
                        <div
                          className="border border-dark shadow shadow-md overflow-auto"
                          style={{ resize: "vertical", height: "35vh" }}
                        >
                          <ol>
                            {state.job.job?.panelCodes?.map((c, i) => {
                              const checkDTIAssignment = (
                                dti: DepartmentTemplateItem
                              ) => {
                                const foundPCDTI = state.job.job?.panelCodes
                                  ?.find((cx) => `${cx.id}` === `${c.id}`)
                                  ?.panelCodeDepartmentTemplateItems?.find(
                                    (pcdti) => {
                                      return (
                                        `${pcdti?.departmentTemplateItem?.id}` ===
                                        `${dti?.id}`
                                      );
                                    }
                                  );

                                if (foundPCDTI) {
                                  // Check if PCDTI already exists in wo V3 list or job WOs. If exists, can't edit
                                  if (
                                    taskListsAutoAssignV3.current.taskLists
                                      .flatMap(
                                        (tL) => tL.taskList?.meetingTasks
                                      )
                                      .find(
                                        (mt) =>
                                          `${mt?.extPanelCodeDepartmentTemplateItemId}` ===
                                          `${foundPCDTI.id}`
                                      ) ||
                                    workOrders?.current?.taskLists
                                      .flatMap(
                                        (tL) => tL.taskList?.meetingTasks
                                      )
                                      .find(
                                        (mt) =>
                                          `${mt?.extPanelCodeDepartmentTemplateItemId}` ===
                                          `${foundPCDTI.id}`
                                      )
                                  ) {
                                    return;
                                  }

                                  if (
                                    selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current.find(
                                      (pcdtix) =>
                                        `${pcdtix.id}` === `${foundPCDTI?.id}`
                                    )
                                  ) {
                                    selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current =
                                      selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current.filter(
                                        (pcdtix) =>
                                          `${pcdtix.id}` !== `${foundPCDTI?.id}`
                                      );
                                  } else {
                                    selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current.push(
                                      {
                                        ...foundPCDTI,
                                        panelCode: {
                                          ...initialPanelCode,
                                          id: c.id,
                                        },
                                      }
                                    );
                                  }
                                }
                              };

                              return (
                                <>
                                  <li>
                                    <div className="d-flex">
                                      <small>
                                        <strong>
                                          {c.type}: {c.name}: {c.qty} unit(s)
                                        </strong>
                                      </small>

                                      <button
                                        className="btn btn-sm btn-primary px-1 py-0"
                                        onClick={() => {
                                          const deptTemplateItemsTotal =
                                            state.job.job?.panelCodes
                                              ?.find(
                                                (cx) => `${cx.id}` === `${c.id}`
                                              )
                                              ?.panelCodeDepartmentTemplateItems?.filter(
                                                (pcdti) => {
                                                  return !pcdti?.excluded;
                                                }
                                              );

                                          deptTemplateItemsTotal?.forEach(
                                            (dti) => {
                                              if (dti?.departmentTemplateItem) {
                                                checkDTIAssignment(
                                                  dti?.departmentTemplateItem
                                                );
                                              }
                                            }
                                          );

                                          render();
                                        }}
                                      >
                                        Toggle Dept
                                      </button>
                                    </div>
                                    <div>
                                      <div className="d-flex">
                                        {(() => {
                                          return sortedDeptTemplates.map(
                                            (t) => {
                                              const sortedTemplateItems =
                                                (() => {
                                                  const departmentTemplateItems =
                                                    [
                                                      ...t.departmentTemplateItems,
                                                    ].filter(
                                                      (i) =>
                                                        !i
                                                          .departmentTemplateItem
                                                          ?.hidden
                                                    );

                                                  departmentTemplateItems.sort(
                                                    (a, b) => {
                                                      if (
                                                        a.departmentTemplateItem
                                                          ?.ordering &&
                                                        b.departmentTemplateItem
                                                          ?.ordering
                                                      ) {
                                                        return (
                                                          a
                                                            .departmentTemplateItem
                                                            .ordering -
                                                          b
                                                            .departmentTemplateItem
                                                            .ordering
                                                        );
                                                      } else {
                                                        return 0;
                                                      }
                                                    }
                                                  );

                                                  return departmentTemplateItems;
                                                })();

                                              const deptTemplateItemsTotal =
                                                sortedTemplateItems.filter(
                                                  (i) => {
                                                    const foundPCDTI =
                                                      state.job.job?.panelCodes
                                                        ?.find(
                                                          (cx) =>
                                                            `${cx.id}` ===
                                                            `${c.id}`
                                                        )
                                                        ?.panelCodeDepartmentTemplateItems?.find(
                                                          (pcdti) =>
                                                            `${pcdti?.departmentTemplateItem?.id}` ===
                                                            `${i.departmentTemplateItem?.id}`
                                                        );

                                                    return !foundPCDTI?.excluded;
                                                  }
                                                );

                                              const selectedV3Assign =
                                                deptTemplateItemsTotal.filter(
                                                  (dti) => {
                                                    const foundPCDTI =
                                                      state.job.job?.panelCodes
                                                        ?.flatMap(
                                                          (c) =>
                                                            c.panelCodeDepartmentTemplateItems
                                                        )
                                                        .find((pcdti) => {
                                                          return (
                                                            `${pcdti?.departmentTemplateItem?.id}` ===
                                                            `${dti.departmentTemplateItem?.id}`
                                                          );
                                                        });

                                                    return selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current.find(
                                                      (pcdtix) =>
                                                        `${pcdtix.id}` ===
                                                        `${foundPCDTI?.id}`
                                                    );
                                                  }
                                                );

                                              return (
                                                <>
                                                  <div className="border border-dark shadow shadow-md rounded rounded-md">
                                                    <div className="d-flex">
                                                      <div>
                                                        {
                                                          t.departmentTemplate
                                                            ?.name
                                                        }{" "}
                                                        (
                                                        {
                                                          selectedV3Assign.length
                                                        }
                                                        /
                                                        {
                                                          deptTemplateItemsTotal.length
                                                        }
                                                        )
                                                      </div>
                                                      <button
                                                        className="btn btn-sm btn-primary px-1 py-0"
                                                        onClick={() => {
                                                          deptTemplateItemsTotal.forEach(
                                                            (dti) => {
                                                              if (
                                                                dti.departmentTemplateItem
                                                              ) {
                                                                checkDTIAssignment(
                                                                  dti.departmentTemplateItem
                                                                );
                                                              }
                                                            }
                                                          );

                                                          render();
                                                        }}
                                                      >
                                                        Toggle Item
                                                      </button>
                                                    </div>
                                                    <div className="d-flex">
                                                      {sortedTemplateItems.map(
                                                        (i) => {
                                                          const foundPCDTI =
                                                            state.job.job?.panelCodes
                                                              ?.find(
                                                                (cx) =>
                                                                  `${cx.id}` ===
                                                                  `${c.id}`
                                                              )
                                                              ?.panelCodeDepartmentTemplateItems?.find(
                                                                (pcdti) =>
                                                                  `${pcdti?.departmentTemplateItem?.id}` ===
                                                                  `${i.departmentTemplateItem?.id}`
                                                              );

                                                          const foundRcemSubProcess =
                                                            rcemDepartments?.departments
                                                              .map((d) =>
                                                                d.processes
                                                                  .map((p) =>
                                                                    p.subProcesses.map(
                                                                      (s) => ({
                                                                        dept: d,
                                                                        process:
                                                                          p,
                                                                        subProcess:
                                                                          s,
                                                                      })
                                                                    )
                                                                  )
                                                                  .flat()
                                                              )
                                                              .flat()
                                                              .find(
                                                                (d) =>
                                                                  `${d.subProcess.masterJavaBaseModel?.id}` ===
                                                                  `${i.departmentTemplateItem?.extRcemSubProcessId}`
                                                              );

                                                          const foundV3Selected =
                                                            selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current.find(
                                                              (pcdtix) =>
                                                                `${pcdtix.id}` ===
                                                                `${foundPCDTI?.id}`
                                                            );

                                                          const foundTask =
                                                            taskListsAutoAssignV3.current.taskLists
                                                              .flatMap(
                                                                (tL) =>
                                                                  tL.taskList
                                                                    ?.meetingTasks
                                                              )
                                                              .find(
                                                                (mt) =>
                                                                  `${mt?.extPanelCodeDepartmentTemplateItemId}` ===
                                                                  `${foundPCDTI?.id}`
                                                              ) ||
                                                            workOrders?.current?.taskLists
                                                              .flatMap(
                                                                (tL) =>
                                                                  tL.taskList
                                                                    ?.meetingTasks
                                                              )
                                                              .find(
                                                                (mt) =>
                                                                  `${mt?.extPanelCodeDepartmentTemplateItemId}` ===
                                                                  `${foundPCDTI?.id}`
                                                              );

                                                          return (
                                                            <>
                                                              <div
                                                                className="overflow-auto mx-2 border border-dark px-1 py-0 rounded rounded-lg"
                                                                style={{
                                                                  width: 150,
                                                                  maxWidth: 150,
                                                                  minWidth: 150,
                                                                  cursor:
                                                                    "pointer",
                                                                  backgroundColor:
                                                                    foundPCDTI?.excluded
                                                                      ? `grey`
                                                                      : foundTask
                                                                      ? `lightblue`
                                                                      : ``,
                                                                }}
                                                              >
                                                                <div className="d-flex">
                                                                  {
                                                                    foundPCDTI?.id
                                                                  }
                                                                  {/* {foundTask ? (
                                                              <>
                                                                {" "}
                                                                <div>
                                                                  <div className="bg-success text-light">
                                                                    <small>
                                                                      <strong>
                                                                        OK
                                                                      </strong>
                                                                    </small>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )} */}

                                                                  <div
                                                                    style={{
                                                                      backgroundColor:
                                                                        foundV3Selected
                                                                          ? `lightgreen`
                                                                          : ``,
                                                                    }}
                                                                  >
                                                                    {i.departmentTemplateItem?.name?.substring(
                                                                      0,
                                                                      15
                                                                    )}
                                                                    {(i
                                                                      .departmentTemplateItem
                                                                      ?.name
                                                                      ?.length ??
                                                                      0) > 15
                                                                      ? `...`
                                                                      : ``}
                                                                  </div>
                                                                </div>

                                                                {foundRcemSubProcess?.subProcess ? (
                                                                  <>
                                                                    <small>
                                                                      <strong>
                                                                        {foundRcemSubProcess.subProcess.name?.substring(
                                                                          0,
                                                                          15
                                                                        )}
                                                                        {(foundRcemSubProcess
                                                                          .subProcess
                                                                          .name
                                                                          ?.length ??
                                                                          0) >
                                                                        15
                                                                          ? `...`
                                                                          : ``}
                                                                        :{" "}
                                                                        {
                                                                          foundRcemSubProcess
                                                                            .subProcess
                                                                            .timeProcess
                                                                        }
                                                                        h{" "}
                                                                        {(() => {
                                                                          const foundSkill =
                                                                            skills.skills.find(
                                                                              (
                                                                                s
                                                                              ) =>
                                                                                `${s.masterJavaBaseModel?.id}` ===
                                                                                `${foundRcemSubProcess.subProcess.skillId}`
                                                                            );

                                                                          if (
                                                                            !foundSkill
                                                                          ) {
                                                                            return "";
                                                                          }

                                                                          return `[Skill: ${foundSkill.name} (${foundSkill.skillWorkers.length}) pers.]`;
                                                                        })()}
                                                                      </strong>
                                                                    </small>
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <small>
                                                                      <strong className="text-danger">
                                                                        No RCEM
                                                                      </strong>
                                                                    </small>
                                                                  </>
                                                                )}
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          );
                                        })()}
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </ol>
                        </div>

                        <div className="d-flex">
                          <div>
                            <div>
                              <small>
                                <strong>From</strong>
                              </small>
                            </div>
                            <div>
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                onBlur={(e) => {
                                  autoAssignV3From.current = e.target.value;
                                  render();
                                }}
                              />
                            </div>
                          </div>

                          <div>
                            <div>
                              <small>
                                <strong>To</strong>
                              </small>
                            </div>
                            <div>
                              <input
                                className="form-control form-control-sm"
                                type="date"
                                onBlur={(e) => {
                                  autoAssignV3To.current = e.target.value;
                                  render();
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex-grow-1">
                            <div>
                              <small>
                                <strong>Department</strong>
                              </small>
                            </div>
                            <div>
                              <Select
                                options={ctx?.extDepartments.map((d) => ({
                                  label: d.name,
                                  value: d,
                                }))}
                                value={ctx?.extDepartments
                                  .map((d) => ({
                                    label: d.name,
                                    value: d,
                                  }))
                                  .find(
                                    (d) =>
                                      `${d.value.id}` ===
                                      `${autoAssignV3SelectedDept.current?.id}`
                                  )}
                                onChange={(d) => {
                                  const val = d as { value: ExtDepartment };

                                  autoAssignV3SelectedDept.current = val.value;

                                  render();
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {selectedPanelCodeDepartmentTemplateItemsAutoAssignV3
                          .current.length > 0 ? (
                          <>
                            <div className="d-flex">
                              {!woDataGotV3.current ? (
                                <> </>
                              ) : (
                                <>
                                  {autoAssignLoadingV3.current ? (
                                    <>
                                      {" "}
                                      <Spinner animation="border" size="sm" />
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={async (e) => {
                                          try {
                                            autoAssignLoadingV3.current = true;
                                            render();

                                            const resp = await fetch(
                                              `${process.env.REACT_APP_MEETING_URL}/auto-assign`,
                                              {
                                                method: "post",
                                                headers: {
                                                  "content-type":
                                                    "application/json",
                                                  authorization:
                                                    ctx?.apiKey ?? "",
                                                },
                                                body: JSON.stringify(
                                                  MeetingAutoAssignParams.fromPartial(
                                                    {
                                                      from:
                                                        autoAssignV3From.current ??
                                                        "",
                                                      to:
                                                        autoAssignV3To.current ??
                                                        "",
                                                      jobId: state.job.job?.id
                                                        ? `${state.job.job?.id}`
                                                        : undefined,

                                                      pcdti:
                                                        selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current.map(
                                                          (p) =>
                                                            PpicPanelCodeDepartmentTemplateItem.fromPartial(
                                                              {
                                                                panelCodeId: p
                                                                  .panelCode?.id
                                                                  ? `${p.panelCode.id}`
                                                                  : undefined,
                                                                departmentTemplateItemId:
                                                                  p
                                                                    .departmentTemplateItem
                                                                    ?.id
                                                                    ? `${p.departmentTemplateItem?.id}`
                                                                    : undefined,
                                                                masterJavaBaseModel:
                                                                  MasterJavaBaseModel.fromPartial(
                                                                    {
                                                                      id: p.id
                                                                        ? `${p.id}`
                                                                        : undefined,
                                                                    }
                                                                  ),
                                                              }
                                                            )
                                                        ) ?? [],
                                                    }
                                                  ) as MeetingAutoAssignParams
                                                ),
                                              }
                                            );

                                            if (resp.status !== 200) {
                                              throw await resp.text();
                                            }

                                            const wos =
                                              MeetingTaskListsView.fromPartial(
                                                await resp.json()
                                              );

                                            taskListsAutoAssignV3.current = wos;

                                            const newWosToAdd =
                                              wos.taskLists.filter((tL) => {
                                                const pcdtiID =
                                                  tL.taskList?.meetingTasks?.[0]
                                                    ?.extPanelCodeDepartmentTemplateItemId;

                                                const foundWorkOrdersPCDTI =
                                                  workOrders?.current?.taskLists
                                                    .flatMap(
                                                      (tL) =>
                                                        tL.taskList
                                                          ?.meetingTasks
                                                    )
                                                    .find(
                                                      (mt) =>
                                                        `${mt?.extPanelCodeDepartmentTemplateItemId}` ===
                                                        `${pcdtiID}`
                                                    );

                                                return (
                                                  !foundWorkOrdersPCDTI &&
                                                  !tL.taskList
                                                    ?.masterJavaBaseModel?.id
                                                );
                                              });

                                            workOrders.current.taskLists =
                                              newWosToAdd;

                                            // setWorkOrders({
                                            //   ...workOrders,
                                            //   taskLists: newWosToAdd,
                                            // });

                                            render();
                                          } catch (e) {
                                            console.error(e);
                                          } finally {
                                            selectedPanelCodeDepartmentTemplateItemsAutoAssignV3.current =
                                              [];
                                            woDataGotV3.current = false;
                                            woDataLoadingV3.current = false;
                                            autoAssignLoadingV3.current = false;
                                            render();
                                          }
                                        }}
                                      >
                                        Auto Assign
                                      </button>
                                    </>
                                  )}{" "}
                                </>
                              )}

                              {woDataLoadingV3.current ? (
                                <>
                                  <Spinner animation="border" size="sm" />
                                </>
                              ) : (
                                <>
                                  <button
                                    className="mx-2 btn btn-sm btn-primary"
                                    onClick={async (e) => {
                                      try {
                                        woDataLoadingV3.current = true;
                                        render();

                                        const d =
                                          await fetchWorkOrdersProtoMongo({
                                            from: autoAssignV3From.current,
                                            to: autoAssignV3To.current,
                                          });

                                        if (d) {
                                          taskListsAutoAssignV3.current = d;
                                          taskListsAutoAssignV3Former.current =
                                            d;

                                          woDataGotV3.current = true;
                                          render();
                                        }
                                      } catch (e) {
                                      } finally {
                                        woDataLoadingV3.current = false;
                                        render();
                                      }
                                    }}
                                  >
                                    Get WO Data
                                  </button>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div
                          className="border border-dark shadow shadow-md overflow-auto"
                          style={{ resize: "vertical", height: "35vh" }}
                        >
                          {(() => {
                            const dateRange =
                              autoAssignV3From.current && autoAssignV3To.current
                                ? [
                                    ...Array(
                                      Math.floor(
                                        (new Date(
                                          autoAssignV3To.current
                                        ).getTime() -
                                          new Date(
                                            autoAssignV3From.current
                                          ).getTime()) /
                                          86400000
                                      ) + 1
                                    ),
                                  ]
                                    .map((_, i) => i)
                                    .map((n) =>
                                      makeDateString(
                                        new Date(
                                          new Date(
                                            autoAssignV3From.current ?? ""
                                          ).getTime() +
                                            86400000 * n
                                        )
                                      )
                                    )
                                : [];

                            return (
                              <table
                                className="table table-sm"
                                style={{ borderCollapse: "separate" }}
                              >
                                <tr>
                                  {[
                                    "#",
                                    "Username",
                                    "Dept",
                                    ...dateRange.map((d) => {
                                      return Intl.DateTimeFormat("en-US", {
                                        dateStyle: "short",
                                      } as any).format(new Date(`${d}`));
                                    }),
                                  ].map((h) => (
                                    <>
                                      <th
                                        className="bg-dark text-light p-0 m-0"
                                        style={{
                                          position: "sticky",
                                          top: 0,
                                          left: h === "Username" ? 0 : ``,
                                        }}
                                      >
                                        {h}
                                      </th>
                                    </>
                                  ))}
                                </tr>
                                {ctx?.extUsers
                                  .filter((u) =>
                                    autoAssignV3SelectedDept.current
                                      ? `${u.departmentId}` ===
                                        `${autoAssignV3SelectedDept.current?.id}`
                                      : true
                                  )
                                  .map((u, i) => {
                                    return (
                                      <>
                                        <tr>
                                          <td className="border border-dark p-0 m-0">
                                            {i + 1}
                                          </td>
                                          <td className="border border-dark p-0 m-0">
                                            {u.username}
                                          </td>
                                          <td className="border border-dark p-0 m-0">
                                            {
                                              ctx.extDepartments.find(
                                                (d) =>
                                                  `${d.id}` ===
                                                  `${u.departmentId}`
                                              )?.name
                                            }
                                          </td>
                                          {dateRange.map((d) => {
                                            const wos =
                                              filterWorkOrderByDateRange(
                                                taskListsAutoAssignV3.current,
                                                d,
                                                d,
                                                u
                                              );

                                            const time = wos?.reduce(
                                              (acc, tL) =>
                                                acc +
                                                (tL.taskList?.meetingTasks.reduce(
                                                  (acc, mt) =>
                                                    acc +
                                                    (mt.meetingTaskInCharges.find(
                                                      (c) =>
                                                        `${c.extUserId}` ===
                                                        `${u.id}`
                                                    )?.totalTimeHoursTask ?? 0),
                                                  0
                                                ) ?? 0),
                                              0
                                            );

                                            return (
                                              <>
                                                <td
                                                  className="border border-dark p-0 m-0"
                                                  style={{
                                                    backgroundColor: chroma
                                                      .scale([
                                                        "lightsalmon",
                                                        "yellow",
                                                        "lightgreen",
                                                      ])((time ?? 0) / 8)
                                                      .alpha(0.9)
                                                      .hex(),
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    v3TaskListsDialogDetail.current =
                                                      MeetingTaskListsView.fromPartial(
                                                        { taskLists: wos }
                                                      );

                                                    render();
                                                  }}
                                                >
                                                  <strong>
                                                    {time?.toFixed(1)}
                                                  </strong>
                                                </td>
                                              </>
                                            );
                                          })}
                                        </tr>
                                      </>
                                    );
                                  })}
                              </table>
                            );
                          })()}
                        </div>
                      </>
                    ) : (
                      <>
                        <strong className="text-danger">
                          Please save job first.
                        </strong>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {viewType === "IntegrationList" ? (
            <>
              <div className="m-3">
                <div className="d-flex">
                  <h4>Product Tree</h4>
                </div>

                <hr className="border border-dark" />

                <div className="d-flex">
                  <div className="flex-grow-1">
                    <div>
                      <Select
                        placeholder="Select Program Tree..."
                        options={integrations.map((i) => ({
                          label: i.integration?.name ?? "",
                          value: i,
                        }))}
                        getOptionLabel={(i) => `${i.label}`}
                        onChange={(i) => {
                          console.log(i);

                          setState({
                            ...state,
                            job: {
                              ...state.job,
                              jobIntegrationList: state.job.jobIntegrationList
                                ? [
                                    ...state.job.jobIntegrationList,
                                    {
                                      ...defaultJobIntegrationListView,
                                      jobIntegrationList: {
                                        ...defaultJobIntegrationList,
                                        uuid: uuidv4(),
                                        integration: (
                                          i as { value: IntegrationView }
                                        ).value.integration,
                                      } as JobIntegrationList,
                                    },
                                  ]
                                : [
                                    {
                                      ...defaultJobIntegrationListView,
                                      jobIntegrationList: {
                                        ...defaultJobIntegrationList,
                                        uuid: uuidv4(),
                                        integration: (
                                          i as { value: IntegrationView }
                                        ).value.integration,
                                      } as JobIntegrationList,
                                    },
                                  ],
                            },
                          });
                        }}
                        value={undefined}
                      />
                    </div>

                    <small>
                      <strong>Filter tree category</strong>
                    </small>

                    <div>
                      <div>
                        <Select
                          options={(
                            [
                              PpicIntegrationModuleType.TREE_ASSY,
                              PpicIntegrationModuleType.TREE_WIRING,
                            ] as PpicIntegrationModuleType[]
                          ).map((h) => ({
                            label: ppicIntegrationModuleTypeToJSON(h),
                            value: h,
                          }))}
                          placeholder="Filter Tree Category..."
                          onChange={(v) => {
                            const val = v as {
                              value: PpicIntegrationModuleType;
                            };

                            treeFilter.current = val.value;
                            render();
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      {/* <ol>
                        {state.job.jobIntegrationList?.map((iL, i) => {
                          return (
                            <>
                              <li>
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      job: {
                                        ...state.job,
                                        jobIntegrationList: state.job
                                          .jobIntegrationList
                                          ? state.job.jobIntegrationList.filter(
                                              (iLx, ix) =>
                                                iL.jobIntegrationList?.uuid !==
                                                iLx.jobIntegrationList?.uuid
                                            )
                                          : state.job.jobIntegrationList,
                                      },
                                    });
                                    setjobIntegrationListDeleteIds([
                                      ...jobIntegrationListDeleteIds,
                                      iL.jobIntegrationList?.id ?? 0,
                                    ]);
                                  }}
                                >
                                  {iL.jobIntegrationList?.integration?.name}
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ol> */}

                      <ol>
                        {state.job.job?.panelCodes?.map((pc) => {
                          const jobListPanelCodes =
                            state.job.jobIntegrationList?.filter(
                              (jIL) =>
                                `${jIL.jobIntegrationList?.panelCode?.id}` ===
                                `${pc.id}`
                            );
                          return (
                            <>
                              <li>
                                <div>
                                  <div>
                                    {pc.type}: {pc.name}: {pc.qty} unit(s):{" "}
                                    {jobListPanelCodes?.length} tree(s)
                                  </div>
                                  <ol>
                                    {jobListPanelCodes
                                      ?.filter((c) =>
                                        treeFilter.current
                                          ? ppicIntegrationModuleTypeFromJSON(
                                              c.integrationRecursive
                                                ?.integration?.category
                                            ) ===
                                            ppicIntegrationModuleTypeFromJSON(
                                              treeFilter.current
                                            )
                                          : true
                                      )
                                      ?.map((iL) => {
                                        // const mapIntegration: Integration

                                        return (
                                          <>
                                            <li>
                                              <div className="d-flex align-items-end">
                                                <div>
                                                  <strong>
                                                    {
                                                      iL.integrationRecursive
                                                        ?.integration?.name
                                                    }
                                                  </strong>
                                                  <strong>
                                                    {iL.integrationRecursive
                                                      ?.integration
                                                      ?.category ? (
                                                      <>
                                                        Category:{" "}
                                                        {ppicIntegrationModuleTypeToJSON(
                                                          ppicIntegrationModuleTypeFromJSON(
                                                            iL
                                                              .integrationRecursive
                                                              ?.integration
                                                              ?.category
                                                          )
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <span className="text-danger">
                                                          No Category
                                                        </span>
                                                      </>
                                                    )}
                                                  </strong>
                                                </div>
                                                {/* <div
                                                className="mx-2"
                                                style={{ width: 250 }}
                                              >
                                                <Select
                                                  options={
                                                    state.job.job?.panelCodes?.map(
                                                      (pc) => ({
                                                        label: `${pc.type}-${pc.name} - ${pc.qty} unit(s)`,
                                                        value: pc,
                                                      })
                                                    ) ?? []
                                                  }
                                                  onChange={(v) => {
                                                    const val = (
                                                      v as unknown as { value: PanelCode }
                                                    ).value;

                                                    setState({
                                                      ...state,
                                                      job: state.job
                                                        ? {
                                                            ...state.job,
                                                            jobIntegrationList:
                                                              state.job
                                                                .jobIntegrationList
                                                                ? state.job.jobIntegrationList.map(
                                                                    (iLx) =>
                                                                      iLx
                                                                        .jobIntegrationList
                                                                        ?.uuid ===
                                                                      iL
                                                                        .jobIntegrationList
                                                                        ?.uuid
                                                                        ? {
                                                                            ...iLx,
                                                                            jobIntegrationList:
                                                                              iLx.jobIntegrationList
                                                                                ? {
                                                                                    ...iLx.jobIntegrationList,
                                                                                    panelCode:
                                                                                      val,
                                                                                  }
                                                                                : iLx.jobIntegrationList,
                                                                          }
                                                                        : iLx
                                                                  )
                                                                : state.job
                                                                    .jobIntegrationList,
                                                          }
                                                        : state.job,
                                                    });
                                                  }}
                                                />
                                              </div> */}
                                                <div>
                                                  {dataProductTreeGot ? (
                                                    <>
                                                      <div className="my-1">
                                                        <button
                                                          onClick={() => {
                                                            // Traverse tree

                                                            const traverseTree =
                                                              (
                                                                m: IntegrationModuleView
                                                              ): IntegrationModuleView[] => {
                                                                return [
                                                                  ...(
                                                                    m.integrationModules?.map(
                                                                      (mx) =>
                                                                        traverseTree(
                                                                          mx
                                                                        )
                                                                    ) ?? []
                                                                  ).flat(),
                                                                  m,
                                                                ];
                                                              };

                                                            const flattenedTree =
                                                              iL.integrationRecursive?.integrationModules
                                                                ?.map((m) =>
                                                                  traverseTree(
                                                                    m
                                                                  )
                                                                )
                                                                .flat() ?? [];

                                                            console.log(
                                                              flattenedTree
                                                            );

                                                            autoAssignProductTreeExecutor(
                                                              flattenedTree,
                                                              pc,
                                                              iL
                                                            );
                                                          }}
                                                          className="btn btn-primary btn-sm"
                                                        >
                                                          Auto Assign
                                                        </button>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  <div className="my-1">
                                                    <button
                                                      onClick={() => {
                                                        if (
                                                          state.job.job?.id ===
                                                            null ||
                                                          state.job.job?.id ===
                                                            undefined ||
                                                          state.job.job.id === 0
                                                        ) {
                                                          alert(
                                                            "Please save job first before creating WO."
                                                          );
                                                          return;
                                                        }

                                                        setShowCreateWoBulkDialog(
                                                          true
                                                        );
                                                        setCreateWoBulkIntegrationList(
                                                          iL
                                                        );
                                                        setSelectedPanelCode(
                                                          pc
                                                        );

                                                        if (state.job.job) {
                                                          setCreateWoBulkWorkOrders(
                                                            mapIntegrationModulesToWorkOrders(
                                                              iL,
                                                              iL
                                                                .integrationRecursive
                                                                ?.integrationModules ??
                                                                [],
                                                              state.job.job,
                                                              pc
                                                            )
                                                          );
                                                        }
                                                      }}
                                                      className="btn btn-primary btn-sm"
                                                    >
                                                      Create WO Bulk
                                                    </button>
                                                  </div>
                                                  <div className="my-1">
                                                    <button
                                                      className="btn btn-success btn-sm"
                                                      onClick={async () => {
                                                        if (workOrders) {
                                                          workOrders.current.taskLists =
                                                            workOrders.current.taskLists.map(
                                                              (tL) =>
                                                                tL &&
                                                                `${tL.taskList?.extPanelCodeId}` ===
                                                                  `${pc.id}` &&
                                                                flattenModules(
                                                                  [iL],
                                                                  null
                                                                ).find(
                                                                  (m) =>
                                                                    `${m.module.integrationModule?.id}` ===
                                                                      `${tL.taskList?.extSetId}` ||
                                                                    `${m.module.integrationModule?.id}` ===
                                                                      `${tL.taskList?.extModuleId}` ||
                                                                    `${m.module.integrationModule?.id}` ===
                                                                      `${tL.taskList?.extSubModuleId}`
                                                                )
                                                                  ? {
                                                                      ...tL,
                                                                      taskList:
                                                                        tL.taskList
                                                                          ? {
                                                                              ...tL.taskList,
                                                                              extIntegrationModuleQtyActual:
                                                                                tL
                                                                                  .taskList
                                                                                  .extIntegrationModuleQty ??
                                                                                tL
                                                                                  .taskList
                                                                                  .extIntegrationModuleQtyActual,
                                                                              meetingTasks:
                                                                                tL
                                                                                  .taskList
                                                                                  .meetingTasks
                                                                                  ? tL.taskList.meetingTasks.map(
                                                                                      (
                                                                                        mt
                                                                                      ) =>
                                                                                        mt
                                                                                          ? {
                                                                                              ...mt,
                                                                                              status:
                                                                                                "COMPLETED",
                                                                                              completedDate:
                                                                                                makeDateString(
                                                                                                  new Date()
                                                                                                ),
                                                                                            }
                                                                                          : mt
                                                                                    )
                                                                                  : tL
                                                                                      .taskList
                                                                                      .meetingTasks,
                                                                            }
                                                                          : tL.taskList,
                                                                    }
                                                                  : tL
                                                            );

                                                          render();
                                                          // setWorkOrders({
                                                          //   ...workOrders,
                                                          //   taskLists:
                                                          //     workOrders.taskLists.map(
                                                          //       (tL) =>
                                                          //         tL &&
                                                          //         `${tL.taskList?.extPanelCodeId}` ===
                                                          //           `${pc.id}` &&
                                                          //         flattenModules(
                                                          //           [iL],
                                                          //           null
                                                          //         ).find(
                                                          //           (m) =>
                                                          //             `${m.module.integrationModule?.id}` ===
                                                          //               `${tL.taskList?.extSetId}` ||
                                                          //             `${m.module.integrationModule?.id}` ===
                                                          //               `${tL.taskList?.extModuleId}` ||
                                                          //             `${m.module.integrationModule?.id}` ===
                                                          //               `${tL.taskList?.extSubModuleId}`
                                                          //         )
                                                          //           ? {
                                                          //               ...tL,
                                                          //               taskList:
                                                          //                 tL.taskList
                                                          //                   ? {
                                                          //                       ...tL.taskList,
                                                          //                       extIntegrationModuleQtyActual:
                                                          //                         tL
                                                          //                           .taskList
                                                          //                           .extIntegrationModuleQty ??
                                                          //                         tL
                                                          //                           .taskList
                                                          //                           .extIntegrationModuleQtyActual,
                                                          //                       meetingTasks:
                                                          //                         tL
                                                          //                           .taskList
                                                          //                           .meetingTasks
                                                          //                           ? tL.taskList.meetingTasks.map(
                                                          //                               (
                                                          //                                 mt
                                                          //                               ) =>
                                                          //                                 mt
                                                          //                                   ? {
                                                          //                                       ...mt,
                                                          //                                       status:
                                                          //                                         "COMPLETED",
                                                          //                                       completedDate:
                                                          //                                         makeDateString(
                                                          //                                           new Date()
                                                          //                                         ),
                                                          //                                     }
                                                          //                                   : mt
                                                          //                             )
                                                          //                           : tL
                                                          //                               .taskList
                                                          //                               .meetingTasks,
                                                          //                     }
                                                          //                   : tL.taskList,
                                                          //             }
                                                          //           : tL
                                                          //     ),
                                                          // });
                                                        }
                                                      }}
                                                    >
                                                      Complete All
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div>
                                                <button
                                                  className="btn btn-outline-primary btn-sm"
                                                  onClick={() => {
                                                    if (
                                                      expandJobIntegrationListUuids.find(
                                                        (exp) =>
                                                          exp ===
                                                          iL.jobIntegrationList
                                                            ?.uuid
                                                      )
                                                    ) {
                                                      setExpandJobIntegrationListUuids(
                                                        expandJobIntegrationListUuids.filter(
                                                          (expx) =>
                                                            expx !==
                                                            iL
                                                              .jobIntegrationList
                                                              ?.uuid
                                                        )
                                                      );
                                                    } else {
                                                      setExpandJobIntegrationListUuids(
                                                        [
                                                          ...expandJobIntegrationListUuids,
                                                          iL.jobIntegrationList
                                                            ?.uuid ?? "",
                                                        ]
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Expand/Collapse
                                                </button>
                                              </div>
                                              {pc.extDefaultQcId ? (
                                                <>
                                                  QC:{" "}
                                                  {
                                                    ctx?.extUsers.find(
                                                      (u) =>
                                                        `${u.id}` ===
                                                        `${pc.extDefaultQcId}`
                                                    )?.username
                                                  }
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {pc.extDefaultQaId !== 0 ? (
                                                <>
                                                  {" "}
                                                  QA:{" "}
                                                  {
                                                    ctx?.extUsers.find(
                                                      (u) =>
                                                        `${u.id}` ===
                                                        `${pc.extDefaultQaId}`
                                                    )?.username
                                                  }
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              , Auto SPV:{" "}
                                              {iL.integrationRecursive
                                                ?.integration
                                                ?.extDefaultAutoAssignSpvId ? (
                                                <>
                                                  {
                                                    ctx?.extUsers.find(
                                                      (u) =>
                                                        `${u.id}` ===
                                                        `${iL.integrationRecursive?.integration?.extDefaultAutoAssignSpvId}`
                                                    )?.username
                                                  }
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {iL.jobIntegrationList
                                                ?.panelCode ? (
                                                <>
                                                  <div>
                                                    <div>
                                                      {
                                                        iL.jobIntegrationList
                                                          .panelCode.type
                                                      }{" "}
                                                      -
                                                      {
                                                        iL.jobIntegrationList
                                                          .panelCode.name
                                                      }{" "}
                                                      -{" "}
                                                      {
                                                        iL.jobIntegrationList
                                                          .panelCode.qty
                                                      }{" "}
                                                      unit(s){" "}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <div className="d-flex">
                                                Custom qty:{" "}
                                                <input
                                                  className="form-control form-control-sm"
                                                  placeholder="Qty..."
                                                  onBlur={(e) => {
                                                    if (!pc.id) {
                                                      return;
                                                    }

                                                    if (
                                                      !iL.jobIntegrationList?.id
                                                    ) {
                                                      return;
                                                    }

                                                    if (
                                                      isNaN(
                                                        parseInt(e.target.value)
                                                      )
                                                    ) {
                                                      return;
                                                    }

                                                    if (
                                                      customQtyPanelIds.find(
                                                        (c) =>
                                                          c.panelCodeId ===
                                                            pc.id &&
                                                          iL.jobIntegrationList
                                                            ?.id &&
                                                          iL.jobIntegrationList
                                                            .id ===
                                                            c.jobIntegrationListId
                                                      )
                                                    ) {
                                                      setCustomQtyPanelIds(
                                                        customQtyPanelIds.map(
                                                          (c) =>
                                                            c.panelCodeId ===
                                                              pc.id &&
                                                            iL
                                                              .jobIntegrationList
                                                              ?.id &&
                                                            iL
                                                              .jobIntegrationList
                                                              .id ===
                                                              c.jobIntegrationListId
                                                              ? {
                                                                  ...c,
                                                                  qty: parseInt(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                                }
                                                              : c
                                                        )
                                                      );
                                                    } else {
                                                      setCustomQtyPanelIds([
                                                        ...customQtyPanelIds,
                                                        {
                                                          panelCodeId:
                                                            pc.id ?? 0,
                                                          jobIntegrationListId:
                                                            iL
                                                              .jobIntegrationList
                                                              .id,
                                                          qty: parseInt(
                                                            e.target.value
                                                          ),
                                                        },
                                                      ]);
                                                    }
                                                  }}
                                                  style={{ width: 100 }}
                                                />
                                                {
                                                  customQtyPanelIds.find(
                                                    (c) =>
                                                      c.panelCodeId === pc.id &&
                                                      iL.jobIntegrationList
                                                        ?.id &&
                                                      iL.jobIntegrationList
                                                        .id ===
                                                        c.jobIntegrationListId
                                                  )?.qty
                                                }
                                              </div>
                                              {expandJobIntegrationListUuids.find(
                                                (exp) =>
                                                  exp ===
                                                  iL.jobIntegrationList?.uuid
                                              ) ? (
                                                <>
                                                  <TreeView
                                                    expanded={[
                                                      ...(
                                                        iL.integrationRecursive?.integrationModules?.map(
                                                          (s) => [
                                                            s.integrationModule
                                                              ?.uuid ?? "",
                                                            ...(
                                                              s.integrationModules?.map(
                                                                (m) => [
                                                                  m
                                                                    .integrationModule
                                                                    ?.uuid ??
                                                                    "",
                                                                ]
                                                              ) ?? []
                                                            ).flat(),
                                                          ]
                                                        ) ?? []
                                                      ).flat(),
                                                    ]}
                                                  >
                                                    {iL.integrationRecursive?.integrationModules?.map(
                                                      (s) => {
                                                        const sQty =
                                                          (s.integrationModule
                                                            ?.qty ?? 0) *
                                                          (iL.jobIntegrationList
                                                            ?.panelCode?.qty ??
                                                            0);

                                                        const woSets =
                                                          workOrders?.current?.taskLists
                                                            ?.map(
                                                              (tL) =>
                                                                tL.taskList ??
                                                                []
                                                            )
                                                            .flat()
                                                            .filter(
                                                              (tL) =>
                                                                tL.extSetId ===
                                                                  `${s.integrationModule?.id}` &&
                                                                `${tL.extPanelCodeId}` ===
                                                                  `${iL.jobIntegrationList?.panelCode?.id}`
                                                            ) ?? [];

                                                        const woSetQtysAssigned =
                                                          woSets.reduce(
                                                            (acc, tL) =>
                                                              acc +
                                                              (tL.extIntegrationModuleQty ??
                                                                0),
                                                            0
                                                          );
                                                        const woSetQtysDone =
                                                          woSets
                                                            .filter((tL) =>
                                                              tL.meetingTasks.find(
                                                                (mt) =>
                                                                  mt.completedDate &&
                                                                  mt.status ===
                                                                    "COMPLETED"
                                                              )
                                                            )
                                                            .reduce(
                                                              (acc, tL) =>
                                                                acc +
                                                                (tL.extIntegrationModuleQtyActual ??
                                                                  0),
                                                              0
                                                            );
                                                        return (
                                                          <>
                                                            <TreeItem
                                                              style={{}}
                                                              expandIcon={`+`}
                                                              collapseIcon={`-`}
                                                              nodeId={
                                                                s
                                                                  .integrationModule
                                                                  ?.uuid ??
                                                                uuidv4()
                                                              }
                                                              label={
                                                                <div
                                                                  className="d-flex justify-content-between p-1"
                                                                  style={{
                                                                    borderLeft:
                                                                      "2px dashed gray",
                                                                    borderBottom:
                                                                      "2px dashed gray",
                                                                    backgroundColor:
                                                                      includedModules.find(
                                                                        (mx) =>
                                                                          `${mx.module.integrationModule?.id}` ===
                                                                            `${s.integrationModule?.id}` &&
                                                                          `${mx.panel.id}` ===
                                                                            `${pc.id}`
                                                                      )
                                                                        ? `lightblue`
                                                                        : undefined,
                                                                  }}
                                                                >
                                                                  <div className="d-flex">
                                                                    <div className="p-1 bg-info text-light mx-2">
                                                                      <small>
                                                                        <strong>
                                                                          SET
                                                                        </strong>
                                                                      </small>
                                                                    </div>{" "}
                                                                    <strong className="mx-2">
                                                                      {s
                                                                        .integrationModule
                                                                        ?.qty ??
                                                                        0}
                                                                      x
                                                                    </strong>{" "}
                                                                    {
                                                                      s
                                                                        .integrationModule
                                                                        ?.name
                                                                    }{" "}
                                                                    (
                                                                    {
                                                                      s
                                                                        .integrationModule
                                                                        ?.timeProcessMins
                                                                    }{" "}
                                                                    min(s))
                                                                    [TOTAL:{" "}
                                                                    <strong>
                                                                      {sQty},{" "}
                                                                      {sQty *
                                                                        (s
                                                                          .integrationModule
                                                                          ?.timeProcessMins ??
                                                                          0)}{" "}
                                                                      min(s)
                                                                    </strong>{" "}
                                                                    ]
                                                                    <strong
                                                                      className={`mx-2 ${
                                                                        woSetQtysAssigned >=
                                                                        sQty
                                                                          ? woSetQtysDone >=
                                                                            woSetQtysAssigned
                                                                            ? `text-success`
                                                                            : `text-info`
                                                                          : `text-danger`
                                                                      }`}
                                                                    >
                                                                      Done/Assig/Qty:{" "}
                                                                      {
                                                                        woSetQtysDone
                                                                      }
                                                                      /
                                                                      {
                                                                        woSetQtysAssigned
                                                                      }
                                                                      /{sQty}
                                                                    </strong>
                                                                  </div>

                                                                  <div className="d-flex">
                                                                    <button
                                                                      onClick={() => {
                                                                        if (
                                                                          (state
                                                                            .job
                                                                            .job
                                                                            ?.id ??
                                                                            0) ===
                                                                          0
                                                                        ) {
                                                                          alert(
                                                                            `Please save job first before adding WO.`
                                                                          );
                                                                          return;
                                                                        }

                                                                        // alert(
                                                                        //   `Set ID: ${s.integrationModule?.id}`
                                                                        // );

                                                                        workOrders.current.taskLists =
                                                                          [
                                                                            ...(workOrders
                                                                              ?.current
                                                                              ?.taskLists ??
                                                                              []),
                                                                            MeetingTaskListView.fromPartial(
                                                                              {
                                                                                taskList:
                                                                                  MeetingTaskList.fromPartial(
                                                                                    {
                                                                                      extPanelCodeId:
                                                                                        iL
                                                                                          .jobIntegrationList
                                                                                          ?.panelCode
                                                                                          ?.id
                                                                                          ? `${iL.jobIntegrationList?.panelCode?.id}`
                                                                                          : undefined,
                                                                                      masterJavaBaseModel:
                                                                                        MasterJavaBaseModel.fromPartial(
                                                                                          {
                                                                                            uuid: uuidv4(),
                                                                                          }
                                                                                        ),
                                                                                      extJobId:
                                                                                        state
                                                                                          .job
                                                                                          .job
                                                                                          ?.id
                                                                                          ? `${state.job.job?.id}`
                                                                                          : undefined,
                                                                                      extSetId: `${s.integrationModule?.id}`,
                                                                                      extIntegrationModuleQty: 0,
                                                                                      meetingTasks:
                                                                                        [
                                                                                          MeetingMeetingTask.fromPartial(
                                                                                            {
                                                                                              masterJavaBaseModel:
                                                                                                MasterJavaBaseModel.fromPartial(
                                                                                                  {
                                                                                                    uuid: uuidv4(),
                                                                                                  }
                                                                                                ),
                                                                                              description: `Integrate set: ${s.integrationModule?.name} for ${state.job.job?.name}`,
                                                                                              meetingTaskInCharges:
                                                                                                [
                                                                                                  ...(pc.extDefaultQcId
                                                                                                    ? [
                                                                                                        MeetingMeetingTaskInCharge.fromPartial(
                                                                                                          {
                                                                                                            masterJavaBaseModel:
                                                                                                              MasterJavaBaseModel.fromPartial(
                                                                                                                {
                                                                                                                  uuid: v4(),
                                                                                                                }
                                                                                                              ),
                                                                                                            extUserId: `${pc.extDefaultQcId}`,
                                                                                                            isQc: true,
                                                                                                          }
                                                                                                        ),
                                                                                                      ]
                                                                                                    : []),
                                                                                                  ...(pc.extDefaultQaId !==
                                                                                                  0
                                                                                                    ? [
                                                                                                        MeetingMeetingTaskInCharge.fromPartial(
                                                                                                          {
                                                                                                            masterJavaBaseModel:
                                                                                                              MasterJavaBaseModel.fromPartial(
                                                                                                                {
                                                                                                                  uuid: v4(),
                                                                                                                }
                                                                                                              ),
                                                                                                            extUserId: `${pc.extDefaultQaId}`,
                                                                                                            isQa: true,
                                                                                                          }
                                                                                                        ),
                                                                                                      ]
                                                                                                    : []),
                                                                                                ],
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                    }
                                                                                  ),
                                                                              }
                                                                            ),
                                                                          ];

                                                                        render();
                                                                        // setWorkOrders(
                                                                        //   {
                                                                        //     ...workOrders,
                                                                        //     taskLists:
                                                                        //       [
                                                                        //         ...(workOrders
                                                                        //           ?.current
                                                                        //           ?.taskLists ??
                                                                        //           []),
                                                                        //         MeetingTaskListView.fromPartial(
                                                                        //           {
                                                                        //             taskList:
                                                                        //               MeetingTaskList.fromPartial(
                                                                        //                 {
                                                                        //                   extPanelCodeId:
                                                                        //                     iL
                                                                        //                       .jobIntegrationList
                                                                        //                       ?.panelCode
                                                                        //                       ?.id
                                                                        //                       ? `${iL.jobIntegrationList?.panelCode?.id}`
                                                                        //                       : undefined,
                                                                        //                   masterJavaBaseModel:
                                                                        //                     MasterJavaBaseModel.fromPartial(
                                                                        //                       {
                                                                        //                         uuid: uuidv4(),
                                                                        //                       }
                                                                        //                     ),
                                                                        //                   extJobId:
                                                                        //                     state
                                                                        //                       .job
                                                                        //                       .job
                                                                        //                       ?.id
                                                                        //                       ? `${state.job.job?.id}`
                                                                        //                       : undefined,
                                                                        //                   extSetId: `${s.integrationModule?.id}`,
                                                                        //                   extIntegrationModuleQty: 0,
                                                                        //                   meetingTasks:
                                                                        //                     [
                                                                        //                       MeetingMeetingTask.fromPartial(
                                                                        //                         {
                                                                        //                           masterJavaBaseModel:
                                                                        //                             MasterJavaBaseModel.fromPartial(
                                                                        //                               {
                                                                        //                                 uuid: uuidv4(),
                                                                        //                               }
                                                                        //                             ),
                                                                        //                           description: `Integrate set: ${s.integrationModule?.name} for ${state.job.job?.name}`,
                                                                        //                           meetingTaskInCharges:
                                                                        //                             [
                                                                        //                               ...(pc.extDefaultQcId
                                                                        //                                 ? [
                                                                        //                                     MeetingMeetingTaskInCharge.fromPartial(
                                                                        //                                       {
                                                                        //                                         masterJavaBaseModel:
                                                                        //                                           MasterJavaBaseModel.fromPartial(
                                                                        //                                             {
                                                                        //                                               uuid: v4(),
                                                                        //                                             }
                                                                        //                                           ),
                                                                        //                                         extUserId: `${pc.extDefaultQcId}`,
                                                                        //                                         isQc: true,
                                                                        //                                       }
                                                                        //                                     ),
                                                                        //                                   ]
                                                                        //                                 : []),
                                                                        //                               ...(pc.extDefaultQaId !==
                                                                        //                               0
                                                                        //                                 ? [
                                                                        //                                     MeetingMeetingTaskInCharge.fromPartial(
                                                                        //                                       {
                                                                        //                                         masterJavaBaseModel:
                                                                        //                                           MasterJavaBaseModel.fromPartial(
                                                                        //                                             {
                                                                        //                                               uuid: v4(),
                                                                        //                                             }
                                                                        //                                           ),
                                                                        //                                         extUserId: `${pc.extDefaultQaId}`,
                                                                        //                                         isQa: true,
                                                                        //                                       }
                                                                        //                                     ),
                                                                        //                                   ]
                                                                        //                                 : []),
                                                                        //                             ],
                                                                        //                         }
                                                                        //                       ),
                                                                        //                     ],
                                                                        //                 }
                                                                        //               ),
                                                                        //           }
                                                                        //         ),
                                                                        //       ],
                                                                        //   }
                                                                        // );
                                                                      }}
                                                                      className="btn btn-primary btn-sm mx-2"
                                                                    >
                                                                      {`Assign WO Set`}
                                                                    </button>
                                                                    <button
                                                                      className="btn btn-sm btn-outline-success"
                                                                      onClick={() => {
                                                                        includeModule(
                                                                          pc,
                                                                          s
                                                                        );
                                                                      }}
                                                                    >
                                                                      Include
                                                                    </button>
                                                                  </div>
                                                                </div>
                                                              }
                                                            >
                                                              {s.integrationModules?.map(
                                                                (m) => {
                                                                  const mQty =
                                                                    (s
                                                                      .integrationModule
                                                                      ?.qty ??
                                                                      0) *
                                                                    (m
                                                                      .integrationModule
                                                                      ?.qty ??
                                                                      0) *
                                                                    (iL
                                                                      .jobIntegrationList
                                                                      ?.panelCode
                                                                      ?.qty ??
                                                                      0);

                                                                  const woModules =
                                                                    workOrders?.current?.taskLists
                                                                      ?.map(
                                                                        (tL) =>
                                                                          tL.taskList ??
                                                                          []
                                                                      )
                                                                      .flat()
                                                                      .filter(
                                                                        (tL) =>
                                                                          tL.extModuleId ===
                                                                            `${m.integrationModule?.id}` &&
                                                                          `${tL.extPanelCodeId}` ===
                                                                            `${iL.jobIntegrationList?.panelCode?.id}`
                                                                      ) ?? [];
                                                                  const woModuleQtysAssigned =
                                                                    woModules.reduce(
                                                                      (
                                                                        acc,
                                                                        tL
                                                                      ) =>
                                                                        acc +
                                                                        (tL.extIntegrationModuleQty ??
                                                                          0),
                                                                      0
                                                                    );
                                                                  const woModuleQtysDone =
                                                                    woModules
                                                                      .filter(
                                                                        (tL) =>
                                                                          tL.meetingTasks.find(
                                                                            (
                                                                              mt
                                                                            ) =>
                                                                              mt.completedDate &&
                                                                              mt.status ===
                                                                                "COMPLETED"
                                                                          )
                                                                      )
                                                                      .reduce(
                                                                        (
                                                                          acc,
                                                                          tL
                                                                        ) =>
                                                                          acc +
                                                                          (tL.extIntegrationModuleQtyActual ??
                                                                            0),
                                                                        0
                                                                      );

                                                                  return (
                                                                    <>
                                                                      <TreeItem
                                                                        style={{}}
                                                                        expandIcon={`+`}
                                                                        collapseIcon={`-`}
                                                                        nodeId={
                                                                          m
                                                                            .integrationModule
                                                                            ?.uuid ??
                                                                          uuidv4()
                                                                        }
                                                                        label={
                                                                          <div
                                                                            className="d-flex justify-content-between p-1"
                                                                            style={{
                                                                              borderLeft:
                                                                                "2px dashed gray",
                                                                              borderBottom:
                                                                                "2px dashed gray",
                                                                              backgroundColor:
                                                                                includedModules.find(
                                                                                  (
                                                                                    mx
                                                                                  ) =>
                                                                                    `${mx.module.integrationModule?.id}` ===
                                                                                      `${m.integrationModule?.id}` &&
                                                                                    `${mx.panel.id}` ===
                                                                                      `${pc.id}`
                                                                                )
                                                                                  ? `lightblue`
                                                                                  : undefined,
                                                                            }}
                                                                          >
                                                                            <div className="d-flex">
                                                                              <div className="p-1 bg-success text-light mx-2">
                                                                                <small>
                                                                                  <strong>
                                                                                    MODULE
                                                                                  </strong>
                                                                                </small>
                                                                              </div>{" "}
                                                                              <strong className="mx-2">
                                                                                {m
                                                                                  .integrationModule
                                                                                  ?.qty ??
                                                                                  0}

                                                                                x
                                                                              </strong>{" "}
                                                                              {
                                                                                m
                                                                                  .integrationModule
                                                                                  ?.name
                                                                              }{" "}
                                                                              (
                                                                              {m
                                                                                .integrationModule
                                                                                ?.timeProcessMins ??
                                                                                0}{" "}
                                                                              min(s))
                                                                              [TOTAL{" "}
                                                                              <strong className="mx-1">
                                                                                {
                                                                                  mQty
                                                                                }

                                                                                ,{" "}
                                                                                {mQty *
                                                                                  (m
                                                                                    .integrationModule
                                                                                    ?.timeProcessMins ??
                                                                                    0)}{" "}
                                                                                min(s)
                                                                              </strong>{" "}
                                                                              ]
                                                                              <strong
                                                                                className={`mx-2 ${
                                                                                  woModuleQtysAssigned >=
                                                                                  mQty
                                                                                    ? woModuleQtysDone >=
                                                                                      woModuleQtysAssigned
                                                                                      ? `text-success`
                                                                                      : `text-info`
                                                                                    : `text-danger`
                                                                                }`}
                                                                              >
                                                                                Done/Assig/Qty:{" "}
                                                                                {
                                                                                  woModuleQtysDone
                                                                                }

                                                                                /
                                                                                {
                                                                                  woModuleQtysAssigned
                                                                                }

                                                                                /
                                                                                {
                                                                                  mQty
                                                                                }
                                                                              </strong>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                              <button
                                                                                onClick={() => {
                                                                                  if (
                                                                                    (state
                                                                                      .job
                                                                                      .job
                                                                                      ?.id ??
                                                                                      0) ===
                                                                                    0
                                                                                  ) {
                                                                                    alert(
                                                                                      `Please save job first before adding WO.`
                                                                                    );
                                                                                    return;
                                                                                  }

                                                                                  // alert(
                                                                                  //   `Mod ID: ${m.integrationModule?.id}`
                                                                                  // );
                                                                                  workOrders.current.taskLists =
                                                                                    [
                                                                                      ...(workOrders
                                                                                        ?.current
                                                                                        ?.taskLists ??
                                                                                        []),
                                                                                      MeetingTaskListView.fromPartial(
                                                                                        {
                                                                                          taskList:
                                                                                            MeetingTaskList.fromPartial(
                                                                                              {
                                                                                                extPanelCodeId:
                                                                                                  iL
                                                                                                    .jobIntegrationList
                                                                                                    ?.panelCode
                                                                                                    ?.id
                                                                                                    ? `${iL.jobIntegrationList?.panelCode?.id}`
                                                                                                    : undefined,

                                                                                                masterJavaBaseModel:
                                                                                                  MasterJavaBaseModel.fromPartial(
                                                                                                    {
                                                                                                      uuid: uuidv4(),
                                                                                                    }
                                                                                                  ),
                                                                                                extJobId:
                                                                                                  state
                                                                                                    .job
                                                                                                    .job
                                                                                                    ?.id
                                                                                                    ? `${state.job.job?.id}`
                                                                                                    : undefined,
                                                                                                extModuleId: `${m.integrationModule?.id}`,
                                                                                                extIntegrationModuleQty: 0,
                                                                                                meetingTasks:
                                                                                                  [
                                                                                                    MeetingMeetingTask.fromPartial(
                                                                                                      {
                                                                                                        masterJavaBaseModel:
                                                                                                          MasterJavaBaseModel.fromPartial(
                                                                                                            {
                                                                                                              uuid: uuidv4(),
                                                                                                            }
                                                                                                          ),
                                                                                                        description: `Integrate module: ${m.integrationModule?.name} for ${state.job.job?.name}`,
                                                                                                        meetingTaskInCharges:
                                                                                                          [
                                                                                                            // qc qa only for set
                                                                                                            // ...(pc.extDefaultQcId
                                                                                                            //   ? [
                                                                                                            //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                                            //         {
                                                                                                            //           masterJavaBaseModel:
                                                                                                            //             MasterJavaBaseModel.fromPartial(
                                                                                                            //               {
                                                                                                            //                 uuid: v4(),
                                                                                                            //               }
                                                                                                            //             ),
                                                                                                            //           extUserId: `${pc.extDefaultQcId}`,
                                                                                                            //           isQc: true,
                                                                                                            //         }
                                                                                                            //       ),
                                                                                                            //     ]
                                                                                                            //   : []),
                                                                                                            // ...(pc.extDefaultQaId
                                                                                                            //   ? [
                                                                                                            //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                                            //         {
                                                                                                            //           masterJavaBaseModel:
                                                                                                            //             MasterJavaBaseModel.fromPartial(
                                                                                                            //               {
                                                                                                            //                 uuid: v4(),
                                                                                                            //               }
                                                                                                            //             ),
                                                                                                            //           extUserId: `${pc.extDefaultQaId}`,
                                                                                                            //           isQa: true,
                                                                                                            //         }
                                                                                                            //       ),
                                                                                                            //     ]
                                                                                                            //   : []),
                                                                                                          ],
                                                                                                      }
                                                                                                    ),
                                                                                                  ],
                                                                                              }
                                                                                            ),
                                                                                        }
                                                                                      ),
                                                                                    ];
                                                                                  render();
                                                                                  // setWorkOrders(
                                                                                  //   {
                                                                                  //     ...workOrders,
                                                                                  //     taskLists:
                                                                                  //       [
                                                                                  //         ...(workOrders
                                                                                  //           ?.current
                                                                                  //           ?.taskLists ??
                                                                                  //           []),
                                                                                  //         MeetingTaskListView.fromPartial(
                                                                                  //           {
                                                                                  //             taskList:
                                                                                  //               MeetingTaskList.fromPartial(
                                                                                  //                 {
                                                                                  //                   extPanelCodeId:
                                                                                  //                     iL
                                                                                  //                       .jobIntegrationList
                                                                                  //                       ?.panelCode
                                                                                  //                       ?.id
                                                                                  //                       ? `${iL.jobIntegrationList?.panelCode?.id}`
                                                                                  //                       : undefined,

                                                                                  //                   masterJavaBaseModel:
                                                                                  //                     MasterJavaBaseModel.fromPartial(
                                                                                  //                       {
                                                                                  //                         uuid: uuidv4(),
                                                                                  //                       }
                                                                                  //                     ),
                                                                                  //                   extJobId:
                                                                                  //                     state
                                                                                  //                       .job
                                                                                  //                       .job
                                                                                  //                       ?.id
                                                                                  //                       ? `${state.job.job?.id}`
                                                                                  //                       : undefined,
                                                                                  //                   extModuleId: `${m.integrationModule?.id}`,
                                                                                  //                   extIntegrationModuleQty: 0,
                                                                                  //                   meetingTasks:
                                                                                  //                     [
                                                                                  //                       MeetingMeetingTask.fromPartial(
                                                                                  //                         {
                                                                                  //                           masterJavaBaseModel:
                                                                                  //                             MasterJavaBaseModel.fromPartial(
                                                                                  //                               {
                                                                                  //                                 uuid: uuidv4(),
                                                                                  //                               }
                                                                                  //                             ),
                                                                                  //                           description: `Integrate module: ${m.integrationModule?.name} for ${state.job.job?.name}`,
                                                                                  //                           meetingTaskInCharges:
                                                                                  //                             [
                                                                                  //                               // qc qa only for set
                                                                                  //                               // ...(pc.extDefaultQcId
                                                                                  //                               //   ? [
                                                                                  //                               //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                  //                               //         {
                                                                                  //                               //           masterJavaBaseModel:
                                                                                  //                               //             MasterJavaBaseModel.fromPartial(
                                                                                  //                               //               {
                                                                                  //                               //                 uuid: v4(),
                                                                                  //                               //               }
                                                                                  //                               //             ),
                                                                                  //                               //           extUserId: `${pc.extDefaultQcId}`,
                                                                                  //                               //           isQc: true,
                                                                                  //                               //         }
                                                                                  //                               //       ),
                                                                                  //                               //     ]
                                                                                  //                               //   : []),
                                                                                  //                               // ...(pc.extDefaultQaId
                                                                                  //                               //   ? [
                                                                                  //                               //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                  //                               //         {
                                                                                  //                               //           masterJavaBaseModel:
                                                                                  //                               //             MasterJavaBaseModel.fromPartial(
                                                                                  //                               //               {
                                                                                  //                               //                 uuid: v4(),
                                                                                  //                               //               }
                                                                                  //                               //             ),
                                                                                  //                               //           extUserId: `${pc.extDefaultQaId}`,
                                                                                  //                               //           isQa: true,
                                                                                  //                               //         }
                                                                                  //                               //       ),
                                                                                  //                               //     ]
                                                                                  //                               //   : []),
                                                                                  //                             ],
                                                                                  //                         }
                                                                                  //                       ),
                                                                                  //                     ],
                                                                                  //                 }
                                                                                  //               ),
                                                                                  //           }
                                                                                  //         ),
                                                                                  //       ],
                                                                                  //   }
                                                                                  // );
                                                                                }}
                                                                                className="btn btn-primary btn-sm mx-2"
                                                                              >
                                                                                {`Assign WO Module`}
                                                                              </button>
                                                                              <button
                                                                                className="btn btn-sm btn-outline-success"
                                                                                onClick={() => {
                                                                                  includeModule(
                                                                                    pc,
                                                                                    m
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Include
                                                                              </button>
                                                                            </div>
                                                                          </div>
                                                                        }
                                                                      >
                                                                        {m.integrationModules?.map(
                                                                          (
                                                                            sm
                                                                          ) => {
                                                                            const smQty =
                                                                              (s
                                                                                .integrationModule
                                                                                ?.qty ??
                                                                                0) *
                                                                              (m
                                                                                .integrationModule
                                                                                ?.qty ??
                                                                                0) *
                                                                              (sm
                                                                                .integrationModule
                                                                                ?.qty ??
                                                                                0) *
                                                                              (iL
                                                                                .jobIntegrationList
                                                                                ?.panelCode
                                                                                ?.qty ??
                                                                                0);

                                                                            const woSubModules =
                                                                              workOrders?.current?.taskLists
                                                                                ?.map(
                                                                                  (
                                                                                    tL
                                                                                  ) =>
                                                                                    tL.taskList ??
                                                                                    []
                                                                                )
                                                                                .flat()
                                                                                .filter(
                                                                                  (
                                                                                    tL
                                                                                  ) =>
                                                                                    tL.extSubModuleId ===
                                                                                      `${sm.integrationModule?.id}` &&
                                                                                    `${tL.extPanelCodeId}` ===
                                                                                      `${iL.jobIntegrationList?.panelCode?.id}`
                                                                                ) ??
                                                                              [];

                                                                            const woSubModuleQtysAssigned =
                                                                              woSubModules.reduce(
                                                                                (
                                                                                  acc,
                                                                                  tL
                                                                                ) =>
                                                                                  acc +
                                                                                  (tL.extIntegrationModuleQty ??
                                                                                    0),
                                                                                0
                                                                              );
                                                                            const woSubModuleQtysDone =
                                                                              woSubModules
                                                                                .filter(
                                                                                  (
                                                                                    tL
                                                                                  ) =>
                                                                                    tL.meetingTasks.find(
                                                                                      (
                                                                                        mt
                                                                                      ) =>
                                                                                        mt.completedDate &&
                                                                                        mt.status ===
                                                                                          "COMPLETED"
                                                                                    )
                                                                                )
                                                                                .reduce(
                                                                                  (
                                                                                    acc,
                                                                                    tL
                                                                                  ) =>
                                                                                    acc +
                                                                                    (tL.extIntegrationModuleQtyActual ??
                                                                                      0),
                                                                                  0
                                                                                );

                                                                            return (
                                                                              <>
                                                                                <TreeItem
                                                                                  style={{}}
                                                                                  expandIcon={`+`}
                                                                                  collapseIcon={`-`}
                                                                                  nodeId={
                                                                                    sm
                                                                                      .integrationModule
                                                                                      ?.uuid ??
                                                                                    uuidv4()
                                                                                  }
                                                                                  label={
                                                                                    <div
                                                                                      className="d-flex justify-content-between p-1"
                                                                                      style={{
                                                                                        borderLeft:
                                                                                          "2px dashed gray",
                                                                                        borderBottom:
                                                                                          "2px dashed gray",
                                                                                        backgroundColor:
                                                                                          includedModules.find(
                                                                                            (
                                                                                              mx
                                                                                            ) =>
                                                                                              `${mx.module.integrationModule?.id}` ===
                                                                                                `${sm.integrationModule?.id}` &&
                                                                                              `${mx.panel.id}` ===
                                                                                                `${pc.id}`
                                                                                          )
                                                                                            ? `lightblue`
                                                                                            : undefined,
                                                                                      }}
                                                                                    >
                                                                                      <div className="d-flex">
                                                                                        <div className="p-1 bg-warning mx-2">
                                                                                          <small>
                                                                                            <strong>
                                                                                              SUBMODULE
                                                                                            </strong>
                                                                                          </small>
                                                                                        </div>{" "}
                                                                                        <strong className="mx-2">
                                                                                          {sm
                                                                                            .integrationModule
                                                                                            ?.qty ??
                                                                                            0}

                                                                                          x
                                                                                        </strong>{" "}
                                                                                        {
                                                                                          sm
                                                                                            .integrationModule
                                                                                            ?.name
                                                                                        }{" "}
                                                                                        (
                                                                                        {sm
                                                                                          .integrationModule
                                                                                          ?.timeProcessMins ??
                                                                                          0}{" "}
                                                                                        min(s)
                                                                                        )
                                                                                        (total{" "}
                                                                                        <strong className="mx-1">
                                                                                          {
                                                                                            smQty
                                                                                          }

                                                                                          ,{" "}
                                                                                          {smQty *
                                                                                            (sm
                                                                                              .integrationModule
                                                                                              ?.timeProcessMins ??
                                                                                              0)}{" "}
                                                                                          min(s)
                                                                                        </strong>

                                                                                        ]{" "}
                                                                                        <strong
                                                                                          className={`mx-2 ${
                                                                                            woSubModuleQtysAssigned >=
                                                                                            smQty
                                                                                              ? woSubModuleQtysDone >=
                                                                                                woSubModuleQtysAssigned
                                                                                                ? `text-success`
                                                                                                : `text-info`
                                                                                              : `text-danger`
                                                                                          }`}
                                                                                        >
                                                                                          Done/Assig/Qty:
                                                                                          {
                                                                                            woSubModuleQtysDone
                                                                                          }

                                                                                          /
                                                                                          {
                                                                                            woSubModuleQtysAssigned
                                                                                          }

                                                                                          /
                                                                                          {
                                                                                            smQty
                                                                                          }
                                                                                        </strong>
                                                                                      </div>

                                                                                      <div className="d-flex">
                                                                                        <button
                                                                                          onClick={() => {
                                                                                            if (
                                                                                              (state
                                                                                                .job
                                                                                                .job
                                                                                                ?.id ??
                                                                                                0) ===
                                                                                              0
                                                                                            ) {
                                                                                              alert(
                                                                                                `Please save job first before adding WO.`
                                                                                              );
                                                                                              return;
                                                                                            }

                                                                                            // alert(
                                                                                            //   `Submodule ID: ${sm.integrationModule?.id}`
                                                                                            // );
                                                                                            workOrders.current.taskLists =
                                                                                              [
                                                                                                ...(workOrders
                                                                                                  ?.current
                                                                                                  ?.taskLists ??
                                                                                                  []),
                                                                                                MeetingTaskListView.fromPartial(
                                                                                                  {
                                                                                                    taskList:
                                                                                                      MeetingTaskList.fromPartial(
                                                                                                        {
                                                                                                          extPanelCodeId:
                                                                                                            iL
                                                                                                              .jobIntegrationList
                                                                                                              ?.panelCode
                                                                                                              ?.id
                                                                                                              ? `${iL.jobIntegrationList?.panelCode?.id}`
                                                                                                              : undefined,

                                                                                                          masterJavaBaseModel:
                                                                                                            MasterJavaBaseModel.fromPartial(
                                                                                                              {
                                                                                                                uuid: uuidv4(),
                                                                                                              }
                                                                                                            ),
                                                                                                          extJobId:
                                                                                                            state
                                                                                                              .job
                                                                                                              .job
                                                                                                              ?.id
                                                                                                              ? `${state.job.job?.id}`
                                                                                                              : undefined,

                                                                                                          extSubModuleId: `${sm.integrationModule?.id}`,
                                                                                                          extIntegrationModuleQty: 0,
                                                                                                          extIntegrationModuleQtyActual: 0,
                                                                                                          meetingTasks:
                                                                                                            [
                                                                                                              MeetingMeetingTask.fromPartial(
                                                                                                                {
                                                                                                                  masterJavaBaseModel:
                                                                                                                    MasterJavaBaseModel.fromPartial(
                                                                                                                      {
                                                                                                                        uuid: uuidv4(),
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  description: `Integrate submodule: ${sm.integrationModule?.name} for ${state.job.job?.name}`,
                                                                                                                  meetingTaskInCharges:
                                                                                                                    [
                                                                                                                      // Qc qa only for set
                                                                                                                      // ...(pc.extDefaultQcId
                                                                                                                      //   ? [
                                                                                                                      //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                                                      //         {
                                                                                                                      //           masterJavaBaseModel:
                                                                                                                      //             MasterJavaBaseModel.fromPartial(
                                                                                                                      //               {
                                                                                                                      //                 uuid: v4(),
                                                                                                                      //               }
                                                                                                                      //             ),
                                                                                                                      //           extUserId: `${pc.extDefaultQcId}`,
                                                                                                                      //           isQc: true,
                                                                                                                      //         }
                                                                                                                      //       ),
                                                                                                                      //     ]
                                                                                                                      //   : []),
                                                                                                                      // ...(pc.extDefaultQaId
                                                                                                                      //   ? [
                                                                                                                      //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                                                      //         {
                                                                                                                      //           masterJavaBaseModel:
                                                                                                                      //             MasterJavaBaseModel.fromPartial(
                                                                                                                      //               {
                                                                                                                      //                 uuid: v4(),
                                                                                                                      //               }
                                                                                                                      //             ),
                                                                                                                      //           extUserId: `${pc.extDefaultQaId}`,
                                                                                                                      //           isQa: true,
                                                                                                                      //         }
                                                                                                                      //       ),
                                                                                                                      //     ]
                                                                                                                      //   : []),
                                                                                                                    ],
                                                                                                                }
                                                                                                              ),
                                                                                                            ],
                                                                                                        }
                                                                                                      ),
                                                                                                  }
                                                                                                ),
                                                                                              ];

                                                                                            render();
                                                                                            // setWorkOrders(
                                                                                            //   {
                                                                                            //     ...workOrders,
                                                                                            //     taskLists:
                                                                                            //       [
                                                                                            //         ...(workOrders
                                                                                            //           ?.current
                                                                                            //           ?.taskLists ??
                                                                                            //           []),
                                                                                            //         MeetingTaskListView.fromPartial(
                                                                                            //           {
                                                                                            //             taskList:
                                                                                            //               MeetingTaskList.fromPartial(
                                                                                            //                 {
                                                                                            //                   extPanelCodeId:
                                                                                            //                     iL
                                                                                            //                       .jobIntegrationList
                                                                                            //                       ?.panelCode
                                                                                            //                       ?.id
                                                                                            //                       ? `${iL.jobIntegrationList?.panelCode?.id}`
                                                                                            //                       : undefined,

                                                                                            //                   masterJavaBaseModel:
                                                                                            //                     MasterJavaBaseModel.fromPartial(
                                                                                            //                       {
                                                                                            //                         uuid: uuidv4(),
                                                                                            //                       }
                                                                                            //                     ),
                                                                                            //                   extJobId:
                                                                                            //                     state
                                                                                            //                       .job
                                                                                            //                       .job
                                                                                            //                       ?.id
                                                                                            //                       ? `${state.job.job?.id}`
                                                                                            //                       : undefined,

                                                                                            //                   extSubModuleId: `${sm.integrationModule?.id}`,
                                                                                            //                   extIntegrationModuleQty: 0,
                                                                                            //                   extIntegrationModuleQtyActual: 0,
                                                                                            //                   meetingTasks:
                                                                                            //                     [
                                                                                            //                       MeetingMeetingTask.fromPartial(
                                                                                            //                         {
                                                                                            //                           masterJavaBaseModel:
                                                                                            //                             MasterJavaBaseModel.fromPartial(
                                                                                            //                               {
                                                                                            //                                 uuid: uuidv4(),
                                                                                            //                               }
                                                                                            //                             ),
                                                                                            //                           description: `Integrate submodule: ${sm.integrationModule?.name} for ${state.job.job?.name}`,
                                                                                            //                           meetingTaskInCharges:
                                                                                            //                             [
                                                                                            //                               // Qc qa only for set
                                                                                            //                               // ...(pc.extDefaultQcId
                                                                                            //                               //   ? [
                                                                                            //                               //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                            //                               //         {
                                                                                            //                               //           masterJavaBaseModel:
                                                                                            //                               //             MasterJavaBaseModel.fromPartial(
                                                                                            //                               //               {
                                                                                            //                               //                 uuid: v4(),
                                                                                            //                               //               }
                                                                                            //                               //             ),
                                                                                            //                               //           extUserId: `${pc.extDefaultQcId}`,
                                                                                            //                               //           isQc: true,
                                                                                            //                               //         }
                                                                                            //                               //       ),
                                                                                            //                               //     ]
                                                                                            //                               //   : []),
                                                                                            //                               // ...(pc.extDefaultQaId
                                                                                            //                               //   ? [
                                                                                            //                               //       MeetingMeetingTaskInCharge.fromPartial(
                                                                                            //                               //         {
                                                                                            //                               //           masterJavaBaseModel:
                                                                                            //                               //             MasterJavaBaseModel.fromPartial(
                                                                                            //                               //               {
                                                                                            //                               //                 uuid: v4(),
                                                                                            //                               //               }
                                                                                            //                               //             ),
                                                                                            //                               //           extUserId: `${pc.extDefaultQaId}`,
                                                                                            //                               //           isQa: true,
                                                                                            //                               //         }
                                                                                            //                               //       ),
                                                                                            //                               //     ]
                                                                                            //                               //   : []),
                                                                                            //                             ],
                                                                                            //                         }
                                                                                            //                       ),
                                                                                            //                     ],
                                                                                            //                 }
                                                                                            //               ),
                                                                                            //           }
                                                                                            //         ),
                                                                                            //       ],
                                                                                            //   }
                                                                                            // );
                                                                                          }}
                                                                                          className="btn btn-primary btn-sm mx-2"
                                                                                        >
                                                                                          {`Assign WO Submodule`}
                                                                                        </button>
                                                                                        <button
                                                                                          className="btn btn-sm btn-outline-success"
                                                                                          onClick={() => {
                                                                                            includeModule(
                                                                                              pc,
                                                                                              sm
                                                                                            );
                                                                                          }}
                                                                                        >
                                                                                          Include
                                                                                        </button>
                                                                                      </div>
                                                                                    </div>
                                                                                  }
                                                                                ></TreeItem>
                                                                              </>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </TreeItem>
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                            </TreeItem>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </TreeView>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </li>
                                          </>
                                        );
                                      })}
                                  </ol>
                                </div>
                                <div className="my-2">
                                  <hr className="border border-dark" />
                                </div>
                              </li>
                            </>
                          );
                        })}
                      </ol>
                    </div>
                  </div>

                  <div className="flex-grow-1 m-3">
                    {(() => {
                      let totalMins = 0;

                      const addTotalMins = (
                        im?: IntegrationModuleView | null
                      ) => {
                        totalMins +=
                          (im?.integrationModule?.qty ?? 0) *
                          (im?.integrationModule?.timeProcessMins ?? 0);

                        im?.integrationModules?.forEach((im) => {
                          addTotalMins(im);
                        });
                      };

                      state.job.jobIntegrationList?.forEach((jL) => {
                        jL.integrationRecursive?.integrationModules?.forEach(
                          (im) => {
                            addTotalMins(im);
                          }
                        );
                      });

                      return (
                        <>
                          <div>
                            <strong>Summary</strong>
                          </div>
                          <div>
                            Total mins:{" "}
                            <strong>
                              {Intl.NumberFormat("en-US").format(totalMins)}
                            </strong>{" "}
                            mins
                          </div>
                          <div>
                            Total hours needed:{" "}
                            <strong>
                              {Intl.NumberFormat("en-US").format(
                                totalMins / 60
                              )}{" "}
                              hours
                            </strong>
                          </div>
                          <div>
                            Total days (per 8 hours) needed for 1 worker:{" "}
                            <strong>
                              {Intl.NumberFormat("en-US").format(
                                totalMins / 480
                              )}{" "}
                              days
                            </strong>
                          </div>
                          <div className="d-flex">
                            Total days (per 8 hours) needed for{" "}
                            <input
                              className="form-control form-control-sm mx-1"
                              style={{ maxWidth: 50 }}
                              defaultValue={workerSim}
                              onChange={(e) => {
                                setWorkerSim(
                                  isNaN(parseInt(e.target.value))
                                    ? workerSim
                                    : parseInt(e.target.value)
                                );
                              }}
                            />{" "}
                            workers:{" "}
                            <strong>
                              {Intl.NumberFormat("en-US").format(
                                totalMins / 480 / (workerSim ?? 1)
                              )}{" "}
                              days
                            </strong>
                          </div>
                          <hr />
                        </>
                      );
                    })()}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <strong>Work Orders</strong>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1 mx-1">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={[
                          PpicIntegrationModuleType.TREE_ASSY,
                          PpicIntegrationModuleType.TREE_WIRING,
                        ].map((h) => ({
                          label: ppicIntegrationModuleTypeToJSON(h),
                          value: h,
                        }))}
                        placeholder="Filter tree type..."
                        onChange={(v) => {
                          const val = v as { value: PpicIntegrationModuleType };
                          setFilters({ ...filters, treeCategory: val.value });
                        }}
                      />
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={
                          (() => {
                            const lst = state.job.job?.panelCodes;

                            lst?.sort(
                              (a, b) =>
                                a.type?.localeCompare(b.type ?? "") ||
                                a.name.localeCompare(b.name ?? "")
                            );

                            return lst;
                          })()?.filter((pc) => pc.id) ?? []
                        }
                        getOptionLabel={(p) => `${p.type}: ${p.name}`}
                        placeholder="Filter panel code..."
                        onChange={(v) => {
                          const val = v as unknown as PanelCode;
                          setFilters({ ...filters, panelCode: val });
                        }}
                      />
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={flattenModules(state.job.jobIntegrationList)}
                        getOptionLabel={(m) =>
                          `${m.integration?.name}: ${m.module.integrationModule?.name}`
                        }
                        placeholder="Filter component..."
                        onChange={(m) => {
                          const val = m as {
                            integration: Integration | null;
                            module: IntegrationModuleView;
                          };
                          setFilters({
                            ...filters,
                            component: `${val.module.integrationModule?.id}`,
                          });
                        }}
                      />
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={[
                          { label: "All", value: null },
                          { label: "Outstanding", value: "OUTSTANDING" },
                          { label: "Completed", value: "COMPLETED" },
                        ]}
                        placeholder="Filter status PIC..."
                      />
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <Select
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        options={[
                          { label: "All", value: null },
                          { label: "Outstanding", value: "OUTSTANDING" },
                          { label: "Completed", value: "COMPLETED" },
                        ]}
                        placeholder="Filter status SPV..."
                      />
                    </div>
                    <div className="flex-grow-1 mx-1">
                      <input
                        className="form-control"
                        placeholder="Date..."
                        type="date"
                        value={filters.date ?? undefined}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            setFilters({ ...filters, date: e.target.value });
                          }
                        }}
                      />
                    </div>
                    {filters.date ? (
                      <>
                        {" "}
                        <div>
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setFilters({ ...filters, date: null });
                            }}
                          >
                            Reset date filter
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    className="shadow shadow-md border border-dark rounded rounded-md overflow-auto"
                    style={{ height: "75vh", resize: "vertical" }}
                  >
                    <table
                      className="table table-sm table-hover table-striped"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          "Action",
                          "#",
                          "WO ID:",
                          "Task",
                          "Item Type",
                          "Item",
                          "Panel",
                          "SN",
                          "SPV",
                          "Amount",
                          "Actual",
                          "In charge",
                          "Start",
                          "End",
                          "Complete by PIC",
                          "Complete",
                          "Remark",
                          "RCEM Subproc",
                        ].map((h) => (
                          <th
                            style={{ position: "sticky", top: 0, zIndex: 1 }}
                            className="bg-dark text-light font-weight-bold"
                          >
                            {h}
                          </th>
                        ))}
                      </tr>
                      {workOrders?.current?.taskLists
                        .filter(
                          (tL) =>
                            ((tL.taskList?.extSetId &&
                              tL.taskList.extSetId !== "0") ||
                              (tL.taskList?.extModuleId &&
                                tL.taskList.extModuleId !== "0") ||
                              (tL.taskList?.extSubModuleId &&
                                tL.taskList.extSubModuleId !== "0")) &&
                            // Filters list
                            // By component
                            (filters.component
                              ? `${tL.taskList.extSetId}` ===
                                  `${filters.component}` ||
                                `${tL.taskList.extModuleId}` ===
                                  `${filters.component}` ||
                                `${tL.taskList.extSubModuleId}` ===
                                  `${filters.component}`
                              : true) &&
                            // By panel code
                            (filters.panelCode
                              ? `${filters.panelCode?.id}` ===
                                `${tL.taskList?.extPanelCodeId}`
                              : true) &&
                            // By date
                            (filters.date
                              ? (() => {
                                  try {
                                    return (
                                      new Date(filters.date).getTime() >=
                                        new Date(
                                          tL.taskList.meetingTasks?.[0]
                                            ?.start ?? ""
                                        ).getTime() &&
                                      new Date(filters.date).getTime() <=
                                        new Date(
                                          extractProtoMeetingTaskTargetDate(
                                            tL?.taskList?.meetingTasks?.[0]
                                          )?.date ?? ""
                                        ).getTime()
                                    );
                                  } catch (e) {
                                    return false;
                                  }
                                })()
                              : true) &&
                            (filters.treeCategory
                              ? (() => {
                                  const type = (() => {
                                    if (
                                      tL.taskList?.extSetId !== undefined &&
                                      tL.taskList?.extSetId !== null &&
                                      tL.taskList?.extSetId !== "0"
                                    ) {
                                      return "Set";
                                    } else if (
                                      tL.taskList?.extModuleId !== undefined &&
                                      tL.taskList?.extModuleId !== null &&
                                      tL.taskList?.extModuleId !== "0"
                                    ) {
                                      return "Module";
                                    } else if (
                                      tL.taskList?.extSubModuleId !==
                                        undefined &&
                                      tL.taskList?.extSubModuleId !== null &&
                                      tL.taskList?.extSubModuleId !== "0"
                                    ) {
                                      return "Submodule";
                                    } else {
                                      return "None";
                                    }
                                  })();

                                  const foundModule = modulesFlattened.find(
                                    (mx) => {
                                      if (type === "Set") {
                                        return (
                                          mx.module.integrationModule?.type ===
                                            "SET" &&
                                          `${mx.module.integrationModule.id}` ===
                                            tL.taskList?.extSetId
                                        );
                                      } else if (type === "Module") {
                                        return (
                                          mx.module.integrationModule?.type ===
                                            "MODULE" &&
                                          `${mx.module.integrationModule.id}` ===
                                            tL.taskList?.extModuleId
                                        );
                                      } else if (type === "Submodule") {
                                        return (
                                          mx.module.integrationModule?.type ===
                                            "SUBMODULE" &&
                                          `${mx.module.integrationModule.id}` ===
                                            tL.taskList?.extSubModuleId
                                        );
                                      } else {
                                        return undefined;
                                      }
                                    }
                                  );

                                  if (foundModule) {
                                    return (
                                      ppicIntegrationModuleTypeFromJSON(
                                        foundModule.integration?.category
                                      ) ===
                                      ppicIntegrationModuleTypeFromJSON(
                                        filters.treeCategory
                                      )
                                    );
                                  }

                                  return false;
                                })()
                              : true)
                        )
                        ?.map((tL, i) => {
                          const type = (() => {
                            if (
                              tL.taskList?.extSetId !== undefined &&
                              tL.taskList?.extSetId !== null &&
                              tL.taskList?.extSetId !== "0"
                            ) {
                              return "Set";
                            } else if (
                              tL.taskList?.extModuleId !== undefined &&
                              tL.taskList?.extModuleId !== null &&
                              tL.taskList?.extModuleId !== "0"
                            ) {
                              return "Module";
                            } else if (
                              tL.taskList?.extSubModuleId !== undefined &&
                              tL.taskList?.extSubModuleId !== null &&
                              tL.taskList?.extSubModuleId !== "0"
                            ) {
                              return "Submodule";
                            } else {
                              return "None";
                            }
                          })();

                          const foundModule = modulesFlattened.find((mx) => {
                            if (type === "Set") {
                              return (
                                mx.module.integrationModule?.type === "SET" &&
                                `${mx.module.integrationModule.id}` ===
                                  tL.taskList?.extSetId
                              );
                            } else if (type === "Module") {
                              return (
                                mx.module.integrationModule?.type ===
                                  "MODULE" &&
                                `${mx.module.integrationModule.id}` ===
                                  tL.taskList?.extModuleId
                              );
                            } else if (type === "Submodule") {
                              return (
                                mx.module.integrationModule?.type ===
                                  "SUBMODULE" &&
                                `${mx.module.integrationModule.id}` ===
                                  tL.taskList?.extSubModuleId
                              );
                            } else {
                              return undefined;
                            }
                          });

                          console.log("looping task list", tL);

                          return (
                            <>
                              <tr>
                                <td className="border border-dark">
                                  {" "}
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Are you sure you want to delete the WO? This action cannot be undone."
                                        )
                                      ) {
                                        if (workOrders) {
                                          setWorkOrderDeleteIds([
                                            ...workOrderDeleteIds,
                                            tL.taskList?.masterJavaBaseModel
                                              ?.id ?? "0",
                                          ]);

                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists?.filter(
                                                  (tLx) =>
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid !==
                                                    tL.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.taskLists
                                          //     ? workOrders.taskLists?.filter(
                                          //         (tLx) =>
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid !==
                                          //           tL.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid
                                          //       )
                                          //     : workOrders.taskLists,
                                          // });
                                        }
                                      }
                                    }}
                                  >
                                    Delete
                                  </button>
                                </td>
                                <td className="border border-dark">{i + 1}</td>
                                <td className="border border-dark">
                                  {(tL.taskList?.masterJavaBaseModel?.id ??
                                    0) === 0 ? (
                                    <small className="font-weight-bold text-danger">
                                      Unsaved
                                    </small>
                                  ) : (
                                    <>{tL.taskList?.masterJavaBaseModel?.id}</>
                                  )}
                                </td>
                                <td className="border border-dark">
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <small>{mt.description}</small>
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td className="border border-dark">
                                  {" "}
                                  <div>
                                    <div>{type}</div>
                                  </div>
                                </td>
                                <td
                                  className="border border-dark"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {" "}
                                  {foundModule?.module.integrationModule?.name}
                                </td>
                                <td
                                  className="border border-dark"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <div
                                    className="overflow-auto"
                                    style={{ width: 200 }}
                                  >
                                    {(() => {
                                      const foundPc =
                                        state.job.job?.panelCodes?.find(
                                          (pc) =>
                                            `${pc.id}` ===
                                            tL.taskList?.extPanelCodeId
                                        );

                                      return foundPc
                                        ? `${foundPc?.type}: ${foundPc?.name} - ${foundPc?.qty} unit(s)`
                                        : "No panel found";
                                    })()}
                                  </div>
                                </td>
                                <td
                                  className="border border-dark"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {(() => {
                                    const foundPc =
                                      state.job.job?.panelCodes?.find((pc) =>
                                        tL.taskList
                                          ?.extPanelCodeSerialNumberId &&
                                        tL.taskList
                                          .extPanelCodeSerialNumberId !== "0" &&
                                        tL.taskList
                                          .extPanelCodeSerialNumberId !== ""
                                          ? pc.panelCodeSerialNumbers?.find(
                                              (sn) =>
                                                `${sn.id}` ===
                                                `${tL.taskList?.extPanelCodeSerialNumberId}`
                                            )
                                          : `${pc.id}` ===
                                            `${tL.taskList?.extPanelCodeId}`
                                      );

                                    console.log("found pc", foundPc);

                                    return (
                                      <div>
                                        <div
                                          className="overflow-auto"
                                          style={{ width: 200 }}
                                        >
                                          {(() => {
                                            const foundSN =
                                              state.job.job?.panelCodes
                                                ?.map(
                                                  (pc) =>
                                                    pc.panelCodeSerialNumbers
                                                )
                                                .flat()
                                                .find(
                                                  (sn) =>
                                                    `${sn?.id}` ===
                                                    `${tL.taskList?.extPanelCodeSerialNumberId}`
                                                );

                                            return (
                                              <>
                                                <div>
                                                  {foundSN ? (
                                                    `${getPanelCodeCompleteSerialNumber(
                                                      foundPc,
                                                      foundSN
                                                    )}`
                                                  ) : (
                                                    <>
                                                      <div className="text-danger font-weight-bold">
                                                        No SN found
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </>
                                            );
                                          })()}
                                        </div>
                                        <div>
                                          <Select
                                            placeholder="Select SN..."
                                            options={
                                              foundPc?.panelCodeSerialNumbers ??
                                              []
                                            }
                                            getOptionLabel={(sn) =>
                                              getPanelCodeCompleteSerialNumber(
                                                foundPc,
                                                sn
                                              )
                                            }
                                            onChange={(v) => {
                                              const val =
                                                v as unknown as PanelCodeSerialNumber;

                                              workOrders.current.taskLists =
                                                workOrders.current.taskLists
                                                  ? workOrders.current.taskLists?.map(
                                                      (tLx) =>
                                                        tLx &&
                                                        tLx.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid ===
                                                          tL.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...tLx,
                                                              taskList:
                                                                tLx.taskList
                                                                  ? {
                                                                      ...tLx.taskList,
                                                                      extPanelCodeSerialNumberId:
                                                                        val.id
                                                                          ? `${val.id}`
                                                                          : undefined,
                                                                    }
                                                                  : tLx.taskList,
                                                            }
                                                          : tLx
                                                    )
                                                  : workOrders.current
                                                      .taskLists;
                                              render();
                                              // setWorkOrders({
                                              //   ...workOrders,
                                              //   taskLists: workOrders.taskLists
                                              //     ? workOrders.taskLists?.map(
                                              //         (tLx) =>
                                              //           tLx &&
                                              //           tLx.taskList
                                              //             ?.masterJavaBaseModel
                                              //             ?.uuid ===
                                              //             tL.taskList
                                              //               ?.masterJavaBaseModel
                                              //               ?.uuid
                                              //             ? {
                                              //                 ...tLx,
                                              //                 taskList:
                                              //                   tLx.taskList
                                              //                     ? {
                                              //                         ...tLx.taskList,
                                              //                         extPanelCodeSerialNumberId:
                                              //                           val.id
                                              //                             ? `${val.id}`
                                              //                             : undefined,
                                              //                       }
                                              //                     : tLx.taskList,
                                              //               }
                                              //             : tLx
                                              //       )
                                              //     : workOrders.taskLists,
                                              // });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })()}
                                </td>
                                <td
                                  className="border border-dark"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <div style={{ width: 300 }}>
                                    <Select
                                      placeholder="Select SPV..."
                                      options={ctx?.extUsers.map((u) => ({
                                        label: `${u.name} (${u.departmentName})`,
                                        value: u,
                                      }))}
                                      onChange={(v) => {
                                        const user = (
                                          v as unknown as {
                                            value?: ExtUser | null;
                                          }
                                        ).value;

                                        // if (
                                        //   mt.meetingTaskInCharges?.find(
                                        //     (mtic) =>
                                        //       mtic.extUserId === `${user?.id}`
                                        //   )
                                        // ) {
                                        //   alert("User already selected.");
                                        //   return;
                                        // }

                                        if (user && workOrders) {
                                          const newMeetingTaskInCharge =
                                            MeetingMeetingTaskInCharge.fromPartial(
                                              {
                                                masterJavaBaseModel:
                                                  MasterJavaBaseModel.fromPartial(
                                                    { uuid: uuidv4() }
                                                  ),
                                                extUserId: `${user?.id}`,
                                              }
                                            );

                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists?.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                extInChargeId:
                                                                  user.id
                                                                    ? `${user.id}`
                                                                    : undefined,
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.taskLists
                                          //     ? workOrders.taskLists?.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       extInChargeId:
                                          //                         user.id
                                          //                           ? `${user.id}`
                                          //                           : undefined,
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.taskLists,
                                          // });
                                        }
                                      }}
                                    />
                                    <div className="overflow-auto">
                                      {(() => {
                                        const foundUser = ctx?.extUsers.find(
                                          (u) =>
                                            `${u.id}` ===
                                            tL.taskList?.extInChargeId
                                        );

                                        return foundUser
                                          ? `${foundUser.name} (${foundUser.departmentName})`
                                          : "None";
                                      })()}
                                    </div>
                                  </div>
                                </td>
                                <td className="border border-dark">
                                  {" "}
                                  <div>
                                    {tL.taskList?.extIntegrationModuleQty}
                                  </div>
                                  <div>
                                    <input
                                      className="form-control form-control-sm"
                                      placeholder="Qty..."
                                      onBlur={(e) => {
                                        if (!isNaN(parseInt(e.target.value))) {
                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists?.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                extIntegrationModuleQty:
                                                                  parseInt(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.taskLists
                                          //     ? workOrders.taskLists?.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       extIntegrationModuleQty:
                                          //                         parseInt(
                                          //                           e.target
                                          //                             .value
                                          //                         ),
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.taskLists,
                                          // });
                                        }
                                      }}
                                      style={{ maxWidth: 75 }}
                                    />
                                  </div>
                                </td>
                                <td className="border border-dark">
                                  <div>
                                    {tL.taskList?.extIntegrationModuleQtyActual}
                                  </div>
                                  <div>
                                    <input
                                      className="form-control form-control-sm"
                                      placeholder="Qty..."
                                      onBlur={(e) => {
                                        if (!isNaN(parseInt(e.target.value))) {
                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists?.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                extIntegrationModuleQtyActual:
                                                                  parseInt(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.taskLists
                                          //     ? workOrders.taskLists?.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       extIntegrationModuleQtyActual:
                                          //                         parseInt(
                                          //                           e.target
                                          //                             .value
                                          //                         ),
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.taskLists,
                                          // });
                                        }
                                      }}
                                      style={{ maxWidth: 75 }}
                                    />
                                  </div>
                                </td>
                                <td className="border border-dark">
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <div style={{ width: 300 }}>
                                            <Select
                                              placeholder="Select PIC..."
                                              options={ctx?.extUsers.map(
                                                (u) => ({
                                                  label: `${u.name} (${u.departmentName})`,
                                                  value: u,
                                                })
                                              )}
                                              onChange={(v) => {
                                                const user = (
                                                  v as unknown as {
                                                    value?: ExtUser | null;
                                                  }
                                                ).value;

                                                if (
                                                  mt.meetingTaskInCharges?.find(
                                                    (mtic) =>
                                                      mtic.extUserId ===
                                                      `${user?.id}`
                                                  )
                                                ) {
                                                  alert(
                                                    "User already selected."
                                                  );
                                                  return;
                                                }

                                                if (user && workOrders) {
                                                  const newMeetingTaskInCharge =
                                                    MeetingMeetingTaskInCharge.fromPartial(
                                                      {
                                                        masterJavaBaseModel:
                                                          MasterJavaBaseModel.fromPartial(
                                                            { uuid: uuidv4() }
                                                          ),
                                                        extUserId: `${user?.id}`,
                                                      }
                                                    );

                                                  workOrders.current.taskLists =
                                                    workOrders.current.taskLists
                                                      ? workOrders.current.taskLists?.map(
                                                          (tLx) =>
                                                            tLx &&
                                                            tLx.taskList
                                                              ?.masterJavaBaseModel
                                                              ?.uuid ===
                                                              tL.taskList
                                                                ?.masterJavaBaseModel
                                                                ?.uuid
                                                              ? {
                                                                  ...tLx,
                                                                  taskList:
                                                                    tLx.taskList
                                                                      ? {
                                                                          ...tLx.taskList,
                                                                          meetingTasks:
                                                                            tLx
                                                                              .taskList
                                                                              .meetingTasks
                                                                              ? tLx.taskList.meetingTasks.map(
                                                                                  (
                                                                                    mtx
                                                                                  ) =>
                                                                                    mtx
                                                                                      .masterJavaBaseModel
                                                                                      ?.uuid ===
                                                                                      mt
                                                                                        .masterJavaBaseModel
                                                                                        ?.uuid &&
                                                                                    mtx
                                                                                      ? {
                                                                                          ...mtx,
                                                                                          meetingTaskInCharges:
                                                                                            mtx.meetingTaskInCharges
                                                                                              ? [
                                                                                                  ...mtx.meetingTaskInCharges,
                                                                                                  newMeetingTaskInCharge,
                                                                                                ]
                                                                                              : [
                                                                                                  newMeetingTaskInCharge,
                                                                                                ],
                                                                                        }
                                                                                      : mtx
                                                                                )
                                                                              : tLx
                                                                                  .taskList
                                                                                  .meetingTasks,
                                                                        }
                                                                      : tLx.taskList,
                                                                }
                                                              : tLx
                                                        )
                                                      : workOrders.current
                                                          .taskLists;

                                                  render();
                                                  // setWorkOrders({
                                                  //   ...workOrders,
                                                  //   taskLists:
                                                  //     workOrders.taskLists
                                                  //       ? workOrders.taskLists?.map(
                                                  //           (tLx) =>
                                                  //             tLx &&
                                                  //             tLx.taskList
                                                  //               ?.masterJavaBaseModel
                                                  //               ?.uuid ===
                                                  //               tL.taskList
                                                  //                 ?.masterJavaBaseModel
                                                  //                 ?.uuid
                                                  //               ? {
                                                  //                   ...tLx,
                                                  //                   taskList:
                                                  //                     tLx.taskList
                                                  //                       ? {
                                                  //                           ...tLx.taskList,
                                                  //                           meetingTasks:
                                                  //                             tLx
                                                  //                               .taskList
                                                  //                               .meetingTasks
                                                  //                               ? tLx.taskList.meetingTasks.map(
                                                  //                                   (
                                                  //                                     mtx
                                                  //                                   ) =>
                                                  //                                     mtx
                                                  //                                       .masterJavaBaseModel
                                                  //                                       ?.uuid ===
                                                  //                                       mt
                                                  //                                         .masterJavaBaseModel
                                                  //                                         ?.uuid &&
                                                  //                                     mtx
                                                  //                                       ? {
                                                  //                                           ...mtx,
                                                  //                                           meetingTaskInCharges:
                                                  //                                             mtx.meetingTaskInCharges
                                                  //                                               ? [
                                                  //                                                   ...mtx.meetingTaskInCharges,
                                                  //                                                   newMeetingTaskInCharge,
                                                  //                                                 ]
                                                  //                                               : [
                                                  //                                                   newMeetingTaskInCharge,
                                                  //                                                 ],
                                                  //                                         }
                                                  //                                       : mtx
                                                  //                                 )
                                                  //                               : tLx
                                                  //                                   .taskList
                                                  //                                   .meetingTasks,
                                                  //                         }
                                                  //                       : tLx.taskList,
                                                  //                 }
                                                  //               : tLx
                                                  //         )
                                                  //       : workOrders.taskLists,
                                                  // });
                                                }
                                              }}
                                            />
                                          </div>

                                          <ol>
                                            {mt.meetingTaskInCharges?.map(
                                              (mtic) => {
                                                const foundUser =
                                                  ctx?.extUsers?.find(
                                                    (u) =>
                                                      `${u.id}` ===
                                                      mtic.extUserId
                                                  );
                                                return (
                                                  <>
                                                    <li>
                                                      <div
                                                        onClick={() => {
                                                          setMeetingTaskInChargeDeleteIds(
                                                            [
                                                              ...meetingTaskInChargeDeleteIds,
                                                              mtic
                                                                .masterJavaBaseModel
                                                                ?.id ?? "",
                                                            ]
                                                          );

                                                          workOrders.current.taskLists =
                                                            workOrders.current
                                                              .taskLists
                                                              ? workOrders.current.taskLists?.map(
                                                                  (tLx) =>
                                                                    tLx &&
                                                                    tLx.taskList
                                                                      ?.masterJavaBaseModel
                                                                      ?.uuid ===
                                                                      tL
                                                                        .taskList
                                                                        ?.masterJavaBaseModel
                                                                        ?.uuid
                                                                      ? {
                                                                          ...tLx,
                                                                          taskList:
                                                                            tLx.taskList
                                                                              ? {
                                                                                  ...tLx.taskList,
                                                                                  meetingTasks:
                                                                                    tLx
                                                                                      .taskList
                                                                                      .meetingTasks
                                                                                      ? tLx.taskList.meetingTasks.map(
                                                                                          (
                                                                                            mtx
                                                                                          ) =>
                                                                                            mtx
                                                                                              .masterJavaBaseModel
                                                                                              ?.uuid ===
                                                                                              mt
                                                                                                .masterJavaBaseModel
                                                                                                ?.uuid &&
                                                                                            mtx
                                                                                              ? {
                                                                                                  ...mtx,
                                                                                                  meetingTaskInCharges:
                                                                                                    mtx.meetingTaskInCharges
                                                                                                      ? mtx.meetingTaskInCharges.filter(
                                                                                                          (
                                                                                                            mticx
                                                                                                          ) =>
                                                                                                            mticx.extUserId !==
                                                                                                            mtic.extUserId
                                                                                                        )
                                                                                                      : mtx.meetingTaskInCharges,
                                                                                                }
                                                                                              : mtx
                                                                                        )
                                                                                      : tLx
                                                                                          .taskList
                                                                                          .meetingTasks,
                                                                                }
                                                                              : tLx.taskList,
                                                                        }
                                                                      : tLx
                                                                )
                                                              : workOrders
                                                                  .current
                                                                  .taskLists;
                                                          render();
                                                          // setWorkOrders({
                                                          //   ...workOrders,
                                                          //   taskLists:
                                                          //     workOrders.taskLists
                                                          //       ? workOrders.taskLists?.map(
                                                          //           (tLx) =>
                                                          //             tLx &&
                                                          //             tLx
                                                          //               .taskList
                                                          //               ?.masterJavaBaseModel
                                                          //               ?.uuid ===
                                                          //               tL
                                                          //                 .taskList
                                                          //                 ?.masterJavaBaseModel
                                                          //                 ?.uuid
                                                          //               ? {
                                                          //                   ...tLx,
                                                          //                   taskList:
                                                          //                     tLx.taskList
                                                          //                       ? {
                                                          //                           ...tLx.taskList,
                                                          //                           meetingTasks:
                                                          //                             tLx
                                                          //                               .taskList
                                                          //                               .meetingTasks
                                                          //                               ? tLx.taskList.meetingTasks.map(
                                                          //                                   (
                                                          //                                     mtx
                                                          //                                   ) =>
                                                          //                                     mtx
                                                          //                                       .masterJavaBaseModel
                                                          //                                       ?.uuid ===
                                                          //                                       mt
                                                          //                                         .masterJavaBaseModel
                                                          //                                         ?.uuid &&
                                                          //                                     mtx
                                                          //                                       ? {
                                                          //                                           ...mtx,
                                                          //                                           meetingTaskInCharges:
                                                          //                                             mtx.meetingTaskInCharges
                                                          //                                               ? mtx.meetingTaskInCharges.filter(
                                                          //                                                   (
                                                          //                                                     mticx
                                                          //                                                   ) =>
                                                          //                                                     mticx.extUserId !==
                                                          //                                                     mtic.extUserId
                                                          //                                                 )
                                                          //                                               : mtx.meetingTaskInCharges,
                                                          //                                         }
                                                          //                                       : mtx
                                                          //                                 )
                                                          //                               : tLx
                                                          //                                   .taskList
                                                          //                                   .meetingTasks,
                                                          //                         }
                                                          //                       : tLx.taskList,
                                                          //                 }
                                                          //               : tLx
                                                          //         )
                                                          //       : workOrders.taskLists,
                                                          // });
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        <div className="d-flex flex-wrap">
                                                          <div>
                                                            {
                                                              foundUser?.username
                                                            }
                                                          </div>
                                                          {mtic.isQc ? (
                                                            <>
                                                              <div className="border border-dark rounded rounded-md">
                                                                <small>
                                                                  <strong>
                                                                    QC
                                                                  </strong>
                                                                </small>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}
                                                          {mtic.isQa ? (
                                                            <>
                                                              <div className="border border-dark rounded rounded-md">
                                                                <small>
                                                                  <strong>
                                                                    QA
                                                                  </strong>
                                                                </small>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )}
                                          </ol>
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td className="border border-dark">
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <input
                                            type="date"
                                            className="form-control form-control-sm"
                                            placeholder="Start..."
                                            onBlur={(e) => {
                                              if (e.target.value !== "") {
                                                workOrders.current.taskLists =
                                                  workOrders.current.taskLists
                                                    ? workOrders.current.taskLists?.map(
                                                        (tLx) =>
                                                          tLx &&
                                                          tLx.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid ===
                                                            tL.taskList
                                                              ?.masterJavaBaseModel
                                                              ?.uuid
                                                            ? {
                                                                ...tLx,
                                                                taskList:
                                                                  tLx.taskList
                                                                    ? {
                                                                        ...tLx.taskList,
                                                                        meetingTasks:
                                                                          tLx
                                                                            .taskList
                                                                            .meetingTasks
                                                                            ? tLx.taskList.meetingTasks.map(
                                                                                (
                                                                                  mtx
                                                                                ) =>
                                                                                  mtx
                                                                                    .masterJavaBaseModel
                                                                                    ?.uuid ===
                                                                                    mt
                                                                                      .masterJavaBaseModel
                                                                                      ?.uuid &&
                                                                                  mtx
                                                                                    ? {
                                                                                        ...mtx,
                                                                                        start:
                                                                                          e
                                                                                            .target
                                                                                            .value,
                                                                                      }
                                                                                    : mtx
                                                                              )
                                                                            : tLx
                                                                                .taskList
                                                                                .meetingTasks,
                                                                      }
                                                                    : tLx.taskList,
                                                              }
                                                            : tLx
                                                      )
                                                    : workOrders.current
                                                        .taskLists;

                                                render();
                                                // setWorkOrders({
                                                //   ...workOrders,
                                                //   taskLists:
                                                //     workOrders.taskLists
                                                //       ? workOrders.taskLists?.map(
                                                //           (tLx) =>
                                                //             tLx &&
                                                //             tLx.taskList
                                                //               ?.masterJavaBaseModel
                                                //               ?.uuid ===
                                                //               tL.taskList
                                                //                 ?.masterJavaBaseModel
                                                //                 ?.uuid
                                                //               ? {
                                                //                   ...tLx,
                                                //                   taskList:
                                                //                     tLx.taskList
                                                //                       ? {
                                                //                           ...tLx.taskList,
                                                //                           meetingTasks:
                                                //                             tLx
                                                //                               .taskList
                                                //                               .meetingTasks
                                                //                               ? tLx.taskList.meetingTasks.map(
                                                //                                   (
                                                //                                     mtx
                                                //                                   ) =>
                                                //                                     mtx
                                                //                                       .masterJavaBaseModel
                                                //                                       ?.uuid ===
                                                //                                       mt
                                                //                                         .masterJavaBaseModel
                                                //                                         ?.uuid &&
                                                //                                     mtx
                                                //                                       ? {
                                                //                                           ...mtx,
                                                //                                           start:
                                                //                                             e
                                                //                                               .target
                                                //                                               .value,
                                                //                                         }
                                                //                                       : mtx
                                                //                                 )
                                                //                               : tLx
                                                //                                   .taskList
                                                //                                   .meetingTasks,
                                                //                         }
                                                //                       : tLx.taskList,
                                                //                 }
                                                //               : tLx
                                                //         )
                                                //       : workOrders.taskLists,
                                                // });
                                              }
                                            }}
                                            // style={{ maxWidth: 75 }}
                                          />
                                          {/* {mt.start} */}
                                          {(() => {
                                            try {
                                              return (
                                                <>
                                                  {makeReadableDateStringIntlDateOnlyUtc(
                                                    new Date(
                                                      `${mt.start}T00:00:00Z`
                                                    )
                                                  )}
                                                </>
                                              );
                                            } catch (e) {
                                              return <>Date invalid</>;
                                            }
                                          })()}
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td className="border border-dark">
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          {" "}
                                          <input
                                            type="date"
                                            className="form-control form-control-sm"
                                            placeholder="Start..."
                                            onBlur={(e) => {
                                              if (e.target.value !== "") {
                                                const newMeetingTaskTargetDate =
                                                  MeetingMeetingTaskTargetDate.fromPartial(
                                                    {
                                                      masterJavaBaseModel:
                                                        MasterJavaBaseModel.fromPartial(
                                                          { uuid: uuidv4() }
                                                        ),
                                                      date: e.target.value,
                                                    }
                                                  );

                                                workOrders.current.taskLists =
                                                  workOrders.current.taskLists
                                                    ? workOrders.current.taskLists?.map(
                                                        (tLx) =>
                                                          tLx &&
                                                          tLx.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid ===
                                                            tL.taskList
                                                              ?.masterJavaBaseModel
                                                              ?.uuid
                                                            ? {
                                                                ...tLx,
                                                                taskList:
                                                                  tLx.taskList
                                                                    ? {
                                                                        ...tLx.taskList,
                                                                        meetingTasks:
                                                                          tLx
                                                                            .taskList
                                                                            .meetingTasks
                                                                            ? tLx.taskList.meetingTasks.map(
                                                                                (
                                                                                  mtx
                                                                                ) =>
                                                                                  mtx
                                                                                    .masterJavaBaseModel
                                                                                    ?.uuid ===
                                                                                    mt
                                                                                      .masterJavaBaseModel
                                                                                      ?.uuid &&
                                                                                  mtx
                                                                                    ? {
                                                                                        ...mtx,
                                                                                        meetingTaskTargetDates:
                                                                                          mtx.meetingTaskTargetDates
                                                                                            ? [
                                                                                                ...mtx.meetingTaskTargetDates,
                                                                                                newMeetingTaskTargetDate,
                                                                                              ]
                                                                                            : [
                                                                                                newMeetingTaskTargetDate,
                                                                                              ],
                                                                                      }
                                                                                    : mtx
                                                                              )
                                                                            : tLx
                                                                                .taskList
                                                                                .meetingTasks,
                                                                      }
                                                                    : tLx.taskList,
                                                              }
                                                            : tLx
                                                      )
                                                    : workOrders.current
                                                        .taskLists;

                                                render();

                                                // setWorkOrders({
                                                //   ...workOrders,
                                                //   taskLists:
                                                //     workOrders.taskLists
                                                //       ? workOrders.taskLists?.map(
                                                //           (tLx) =>
                                                //             tLx &&
                                                //             tLx.taskList
                                                //               ?.masterJavaBaseModel
                                                //               ?.uuid ===
                                                //               tL.taskList
                                                //                 ?.masterJavaBaseModel
                                                //                 ?.uuid
                                                //               ? {
                                                //                   ...tLx,
                                                //                   taskList:
                                                //                     tLx.taskList
                                                //                       ? {
                                                //                           ...tLx.taskList,
                                                //                           meetingTasks:
                                                //                             tLx
                                                //                               .taskList
                                                //                               .meetingTasks
                                                //                               ? tLx.taskList.meetingTasks.map(
                                                //                                   (
                                                //                                     mtx
                                                //                                   ) =>
                                                //                                     mtx
                                                //                                       .masterJavaBaseModel
                                                //                                       ?.uuid ===
                                                //                                       mt
                                                //                                         .masterJavaBaseModel
                                                //                                         ?.uuid &&
                                                //                                     mtx
                                                //                                       ? {
                                                //                                           ...mtx,
                                                //                                           meetingTaskTargetDates:
                                                //                                             mtx.meetingTaskTargetDates
                                                //                                               ? [
                                                //                                                   ...mtx.meetingTaskTargetDates,
                                                //                                                   newMeetingTaskTargetDate,
                                                //                                                 ]
                                                //                                               : [
                                                //                                                   newMeetingTaskTargetDate,
                                                //                                                 ],
                                                //                                         }
                                                //                                       : mtx
                                                //                                 )
                                                //                               : tLx
                                                //                                   .taskList
                                                //                                   .meetingTasks,
                                                //                         }
                                                //                       : tLx.taskList,
                                                //                 }
                                                //               : tLx
                                                //         )
                                                //       : workOrders.taskLists,
                                                // });
                                              }
                                            }}
                                            // style={{ maxWidth: 75 }}
                                          />
                                          {(() => {
                                            try {
                                              return (
                                                <>
                                                  {makeReadableDateStringIntlDateOnlyUtc(
                                                    new Date(
                                                      `${
                                                        extractProtoMeetingTaskTargetDate(
                                                          mt
                                                        )?.date
                                                      }T00:00:00Z`
                                                    )
                                                  )}
                                                </>
                                              );
                                            } catch (e) {
                                              return <>Date invalid</>;
                                            }
                                          })()}
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td
                                  className="border border-dark"
                                  style={{
                                    backgroundColor: tL.taskList
                                      ?.extWoInChargeCompletedDate
                                      ? "lightgreen"
                                      : "lightsalmon",
                                  }}
                                >
                                  {" "}
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <div>
                                            <div>
                                              <input
                                                type="date"
                                                className="form-control form-control-sm"
                                                onBlur={(e) => {
                                                  if (e.target.value !== "") {
                                                    if (
                                                      window.confirm(
                                                        "Really complete task?"
                                                      )
                                                    ) {
                                                      workOrders.current.taskLists =
                                                        workOrders.current
                                                          .taskLists
                                                          ? workOrders.current.taskLists?.map(
                                                              (tLx) =>
                                                                tLx &&
                                                                tLx.taskList
                                                                  ?.masterJavaBaseModel
                                                                  ?.uuid ===
                                                                  tL.taskList
                                                                    ?.masterJavaBaseModel
                                                                    ?.uuid
                                                                  ? {
                                                                      ...tLx,
                                                                      taskList:
                                                                        tLx.taskList
                                                                          ? {
                                                                              ...tLx.taskList,
                                                                              status:
                                                                                "COMPLETED",
                                                                              extWoInChargeCompletedDate: `${e.target.value}T00:00:00Z`,
                                                                            }
                                                                          : tLx.taskList,
                                                                    }
                                                                  : tLx
                                                            )
                                                          : workOrders.current
                                                              .taskLists;

                                                      render();
                                                      // setWorkOrders({
                                                      //   ...workOrders,
                                                      //   taskLists:
                                                      //     workOrders.taskLists
                                                      //       ? workOrders.taskLists?.map(
                                                      //           (tLx) =>
                                                      //             tLx &&
                                                      //             tLx.taskList
                                                      //               ?.masterJavaBaseModel
                                                      //               ?.uuid ===
                                                      //               tL.taskList
                                                      //                 ?.masterJavaBaseModel
                                                      //                 ?.uuid
                                                      //               ? {
                                                      //                   ...tLx,
                                                      //                   taskList:
                                                      //                     tLx.taskList
                                                      //                       ? {
                                                      //                           ...tLx.taskList,
                                                      //                           status:
                                                      //                             "COMPLETED",
                                                      //                           extWoInChargeCompletedDate: `${e.target.value}T00:00:00Z`,
                                                      //                         }
                                                      //                       : tLx.taskList,
                                                      //                 }
                                                      //               : tLx
                                                      //         )
                                                      //       : workOrders.taskLists,
                                                      // });
                                                    }
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div>
                                              ({tL.taskList?.status}){" "}
                                              {(() => {
                                                try {
                                                  return makeReadableDateStringIntlDateOnlyUtc(
                                                    new Date(
                                                      tL.taskList
                                                        ?.extWoInChargeCompletedDate ??
                                                        ""
                                                    )
                                                  );
                                                } catch (e) {
                                                  return "None";
                                                }
                                              })()}
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td
                                  className="border border-dark"
                                  style={{
                                    backgroundColor:
                                      tL.taskList?.meetingTasks.find(
                                        (mt) =>
                                          mt.status === "COMPLETED" &&
                                          mt.completedDate
                                      )
                                        ? "lightgreen"
                                        : "lightsalmon",
                                  }}
                                >
                                  {" "}
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <div>
                                            <div>
                                              <input
                                                type="date"
                                                className="form-control form-control-sm"
                                                onBlur={(e) => {
                                                  if (e.target.value !== "") {
                                                    if (
                                                      window.confirm(
                                                        "Really complete task?"
                                                      )
                                                    ) {
                                                      workOrders.current.taskLists =
                                                        workOrders.current
                                                          .taskLists
                                                          ? workOrders.current.taskLists?.map(
                                                              (tLx) =>
                                                                tLx &&
                                                                tLx.taskList
                                                                  ?.masterJavaBaseModel
                                                                  ?.uuid ===
                                                                  tL.taskList
                                                                    ?.masterJavaBaseModel
                                                                    ?.uuid
                                                                  ? {
                                                                      ...tLx,
                                                                      taskList:
                                                                        tLx.taskList
                                                                          ? {
                                                                              ...tLx.taskList,

                                                                              meetingTasks:
                                                                                tLx
                                                                                  .taskList
                                                                                  .meetingTasks
                                                                                  ? tLx.taskList.meetingTasks.map(
                                                                                      (
                                                                                        mtx
                                                                                      ) =>
                                                                                        mtx
                                                                                          .masterJavaBaseModel
                                                                                          ?.uuid ===
                                                                                          mt
                                                                                            .masterJavaBaseModel
                                                                                            ?.uuid &&
                                                                                        mtx
                                                                                          ? {
                                                                                              ...mtx,
                                                                                              status:
                                                                                                "COMPLETED",
                                                                                              completedDate:
                                                                                                e
                                                                                                  .target
                                                                                                  .value,
                                                                                            }
                                                                                          : mtx
                                                                                    )
                                                                                  : tLx
                                                                                      .taskList
                                                                                      .meetingTasks,
                                                                            }
                                                                          : tLx.taskList,
                                                                    }
                                                                  : tLx
                                                            )
                                                          : workOrders.current
                                                              .taskLists;

                                                      render();
                                                      // setWorkOrders({
                                                      //   ...workOrders,
                                                      //   taskLists:
                                                      //     workOrders.current.taskLists
                                                      //       ? workOrders.current.taskLists?.map(
                                                      //           (tLx) =>
                                                      //             tLx &&
                                                      //             tLx.taskList
                                                      //               ?.masterJavaBaseModel
                                                      //               ?.uuid ===
                                                      //               tL.taskList
                                                      //                 ?.masterJavaBaseModel
                                                      //                 ?.uuid
                                                      //               ? {
                                                      //                   ...tLx,
                                                      //                   taskList:
                                                      //                     tLx.taskList
                                                      //                       ? {
                                                      //                           ...tLx.taskList,

                                                      //                           meetingTasks:
                                                      //                             tLx
                                                      //                               .taskList
                                                      //                               .meetingTasks
                                                      //                               ? tLx.taskList.meetingTasks.map(
                                                      //                                   (
                                                      //                                     mtx
                                                      //                                   ) =>
                                                      //                                     mtx
                                                      //                                       .masterJavaBaseModel
                                                      //                                       ?.uuid ===
                                                      //                                       mt
                                                      //                                         .masterJavaBaseModel
                                                      //                                         ?.uuid &&
                                                      //                                     mtx
                                                      //                                       ? {
                                                      //                                           ...mtx,
                                                      //                                           status:
                                                      //                                             "COMPLETED",
                                                      //                                           completedDate:
                                                      //                                             e
                                                      //                                               .target
                                                      //                                               .value,
                                                      //                                         }
                                                      //                                       : mtx
                                                      //                                 )
                                                      //                               : tLx
                                                      //                                   .taskList
                                                      //                                   .meetingTasks,
                                                      //                         }
                                                      //                       : tLx.taskList,
                                                      //                 }
                                                      //               : tLx
                                                      //         )
                                                      //       : workOrders.current.taskLists,
                                                      // });
                                                    }
                                                  }
                                                }}
                                              />
                                            </div>
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                if (
                                                  !window.confirm(
                                                    "Really undo?"
                                                  )
                                                ) {
                                                  return;
                                                }

                                                workOrders.current.taskLists =
                                                  workOrders.current.taskLists
                                                    ? workOrders.current.taskLists?.map(
                                                        (tLx) =>
                                                          tLx &&
                                                          tLx.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid ===
                                                            tL.taskList
                                                              ?.masterJavaBaseModel
                                                              ?.uuid
                                                            ? {
                                                                ...tLx,
                                                                taskList:
                                                                  tLx.taskList
                                                                    ? {
                                                                        ...tLx.taskList,

                                                                        meetingTasks:
                                                                          tLx
                                                                            .taskList
                                                                            .meetingTasks
                                                                            ? tLx.taskList.meetingTasks.map(
                                                                                (
                                                                                  mtx
                                                                                ) =>
                                                                                  mtx
                                                                                    .masterJavaBaseModel
                                                                                    ?.uuid ===
                                                                                    mt
                                                                                      .masterJavaBaseModel
                                                                                      ?.uuid &&
                                                                                  mtx
                                                                                    ? {
                                                                                        ...mtx,
                                                                                        status:
                                                                                          "OUTSTANDING",
                                                                                      }
                                                                                    : mtx
                                                                              )
                                                                            : tLx
                                                                                .taskList
                                                                                .meetingTasks,
                                                                      }
                                                                    : tLx.taskList,
                                                              }
                                                            : tLx
                                                      )
                                                    : workOrders.current
                                                        .taskLists;

                                                render();
                                                // setWorkOrders({
                                                //   ...workOrders,
                                                //   taskLists:
                                                //     workOrders.taskLists
                                                //       ? workOrders.taskLists?.map(
                                                //           (tLx) =>
                                                //             tLx &&
                                                //             tLx.taskList
                                                //               ?.masterJavaBaseModel
                                                //               ?.uuid ===
                                                //               tL.taskList
                                                //                 ?.masterJavaBaseModel
                                                //                 ?.uuid
                                                //               ? {
                                                //                   ...tLx,
                                                //                   taskList:
                                                //                     tLx.taskList
                                                //                       ? {
                                                //                           ...tLx.taskList,

                                                //                           meetingTasks:
                                                //                             tLx
                                                //                               .taskList
                                                //                               .meetingTasks
                                                //                               ? tLx.taskList.meetingTasks.map(
                                                //                                   (
                                                //                                     mtx
                                                //                                   ) =>
                                                //                                     mtx
                                                //                                       .masterJavaBaseModel
                                                //                                       ?.uuid ===
                                                //                                       mt
                                                //                                         .masterJavaBaseModel
                                                //                                         ?.uuid &&
                                                //                                     mtx
                                                //                                       ? {
                                                //                                           ...mtx,
                                                //                                           status:
                                                //                                             "OUTSTANDING",
                                                //                                         }
                                                //                                       : mtx
                                                //                                 )
                                                //                               : tLx
                                                //                                   .taskList
                                                //                                   .meetingTasks,
                                                //                         }
                                                //                       : tLx.taskList,
                                                //                 }
                                                //               : tLx
                                                //         )
                                                //       : workOrders.taskLists,
                                                // });
                                              }}
                                            >
                                              ({mt.status}){" "}
                                              {(() => {
                                                try {
                                                  return makeReadableDateStringIntlDateOnlyUtc(
                                                    new Date(
                                                      `${mt.completedDate}T00:00:00Z`
                                                    )
                                                  );
                                                } catch (e) {
                                                  return "Invalid";
                                                }
                                              })()}
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td className="border border-dark">
                                  {" "}
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <div style={{ width: 200 }}>
                                            <small>
                                              <button
                                                onClick={() => {
                                                  const promptComment =
                                                    prompt(
                                                      "Enter new comment:"
                                                    );

                                                  if (
                                                    promptComment === null ||
                                                    promptComment ===
                                                      undefined ||
                                                    promptComment === ""
                                                  ) {
                                                    alert(
                                                      "Comment must not be null."
                                                    );
                                                    return;
                                                  }

                                                  workOrders.current.taskLists =
                                                    workOrders.current.taskLists
                                                      ? workOrders.current.taskLists?.map(
                                                          (tLx) =>
                                                            tLx &&
                                                            tLx.taskList
                                                              ?.masterJavaBaseModel
                                                              ?.uuid ===
                                                              tL.taskList
                                                                ?.masterJavaBaseModel
                                                                ?.uuid
                                                              ? {
                                                                  ...tLx,
                                                                  taskList:
                                                                    tLx.taskList
                                                                      ? {
                                                                          ...tLx.taskList,

                                                                          meetingTasks:
                                                                            tLx
                                                                              .taskList
                                                                              .meetingTasks
                                                                              ? tLx.taskList.meetingTasks.map(
                                                                                  (
                                                                                    mtx
                                                                                  ) =>
                                                                                    mtx
                                                                                      .masterJavaBaseModel
                                                                                      ?.uuid ===
                                                                                      mt
                                                                                        .masterJavaBaseModel
                                                                                        ?.uuid &&
                                                                                    mtx
                                                                                      ? {
                                                                                          ...mtx,
                                                                                          meetingTaskComments:
                                                                                            mtx.meetingTaskComments
                                                                                              ? [
                                                                                                  ...mtx.meetingTaskComments,
                                                                                                  MeetingMeetingTaskComment.fromPartial(
                                                                                                    {
                                                                                                      // extUserId: }`
                                                                                                      masterJavaBaseModel:
                                                                                                        MasterJavaBaseModel.fromPartial(
                                                                                                          {
                                                                                                            uuid: uuidv4(),
                                                                                                          }
                                                                                                        ),
                                                                                                      comment:
                                                                                                        promptComment,
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              : mtx.meetingTaskComments,
                                                                                        }
                                                                                      : mtx
                                                                                )
                                                                              : tLx
                                                                                  .taskList
                                                                                  .meetingTasks,
                                                                        }
                                                                      : tLx.taskList,
                                                                }
                                                              : tLx
                                                        )
                                                      : workOrders.current
                                                          .taskLists;

                                                  render();
                                                  // setWorkOrders({
                                                  //   ...workOrders,
                                                  //   taskLists:
                                                  //     workOrders.current.taskLists
                                                  //       ? workOrders.current.taskLists?.map(
                                                  //           (tLx) =>
                                                  //             tLx &&
                                                  //             tLx.taskList
                                                  //               ?.masterJavaBaseModel
                                                  //               ?.uuid ===
                                                  //               tL.taskList
                                                  //                 ?.masterJavaBaseModel
                                                  //                 ?.uuid
                                                  //               ? {
                                                  //                   ...tLx,
                                                  //                   taskList:
                                                  //                     tLx.taskList
                                                  //                       ? {
                                                  //                           ...tLx.taskList,

                                                  //                           meetingTasks:
                                                  //                             tLx
                                                  //                               .taskList
                                                  //                               .meetingTasks
                                                  //                               ? tLx.taskList.meetingTasks.map(
                                                  //                                   (
                                                  //                                     mtx
                                                  //                                   ) =>
                                                  //                                     mtx
                                                  //                                       .masterJavaBaseModel
                                                  //                                       ?.uuid ===
                                                  //                                       mt
                                                  //                                         .masterJavaBaseModel
                                                  //                                         ?.uuid &&
                                                  //                                     mtx
                                                  //                                       ? {
                                                  //                                           ...mtx,
                                                  //                                           meetingTaskComments:
                                                  //                                             mtx.meetingTaskComments
                                                  //                                               ? [
                                                  //                                                   ...mtx.meetingTaskComments,
                                                  //                                                   MeetingMeetingTaskComment.fromPartial(
                                                  //                                                     {
                                                  //                                                       // extUserId: }`
                                                  //                                                       masterJavaBaseModel:
                                                  //                                                         MasterJavaBaseModel.fromPartial(
                                                  //                                                           {
                                                  //                                                             uuid: uuidv4(),
                                                  //                                                           }
                                                  //                                                         ),
                                                  //                                                       comment:
                                                  //                                                         promptComment,
                                                  //                                                     }
                                                  //                                                   ),
                                                  //                                                 ]
                                                  //                                               : mtx.meetingTaskComments,
                                                  //                                         }
                                                  //                                       : mtx
                                                  //                                 )
                                                  //                               : tLx
                                                  //                                   .taskList
                                                  //                                   .meetingTasks,
                                                  //                         }
                                                  //                       : tLx.taskList,
                                                  //                 }
                                                  //               : tLx
                                                  //         )
                                                  //       : workOrders.current.taskLists,
                                                  // });
                                                }}
                                                className="btn btn-outline-primary"
                                              >
                                                <small>
                                                  <Add /> Add
                                                </small>
                                              </button>
                                            </small>
                                            <ol>
                                              {mt.meetingTaskComments.map(
                                                (c) => {
                                                  return (
                                                    <>
                                                      <li>
                                                        <div>
                                                          <div>
                                                            <small>
                                                              <strong>
                                                                {(() => {
                                                                  const fUser =
                                                                    ctx?.extUsers.find(
                                                                      (u) =>
                                                                        `${u.id}` ===
                                                                        `${c.extUserId}`
                                                                    );

                                                                  return `${
                                                                    fUser?.name ??
                                                                    "Admin"
                                                                  } (${
                                                                    fUser?.departmentName ??
                                                                    "Admin"
                                                                  }) ${(() => {
                                                                    try {
                                                                      return Intl.DateTimeFormat(
                                                                        navigator.language ??
                                                                          "",
                                                                        {
                                                                          dateStyle:
                                                                            "short",
                                                                          timeStyle:
                                                                            "short",
                                                                          hourCycle:
                                                                            "h23",
                                                                        } as any
                                                                      ).format(
                                                                        new Date(
                                                                          c
                                                                            .masterJavaBaseModel
                                                                            ?.createdAt ??
                                                                            ""
                                                                        )
                                                                      );
                                                                    } catch (e) {
                                                                      return "";
                                                                    }
                                                                  })()}`;
                                                                })()}
                                                              </strong>
                                                            </small>
                                                          </div>
                                                          <div>
                                                            <small>
                                                              <pre>
                                                                {c.comment}
                                                              </pre>
                                                            </small>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </ol>
                                            {/* <textarea
                                          placeholder="Note..."
                                          key={`task-list-remark-${
                                            tL.taskList?.masterJavaBaseModel
                                              ?.uuid ?? uuidv4()
                                          }`}
                                          className="form-control form-control-sm"
                                          defaultValue={mt.note ?? ""}
                                          onBlur={(e) => {
                                            setWorkOrders({
                                              ...workOrders,
                                              taskLists: workOrders.taskLists
                                                ? workOrders.taskLists?.map(
                                                    (tLx) =>
                                                      tLx &&
                                                      tLx.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid ===
                                                        tL.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...tLx,
                                                            taskList: tLx.taskList
                                                              ? {
                                                                  ...tLx.taskList,
  
                                                                  meetingTasks:
                                                                    tLx.taskList
                                                                      .meetingTasks
                                                                      ? tLx.taskList.meetingTasks.map(
                                                                          (mtx) =>
                                                                            mtx
                                                                              .masterJavaBaseModel
                                                                              ?.uuid ===
                                                                              mt
                                                                                .masterJavaBaseModel
                                                                                ?.uuid &&
                                                                            mtx
                                                                              ? {
                                                                                  ...mtx,
                                                                                  note: e
                                                                                    .target
                                                                                    .value,
                                                                                }
                                                                              : mtx
                                                                        )
                                                                      : tLx
                                                                          .taskList
                                                                          .meetingTasks,
                                                                }
                                                              : tLx.taskList,
                                                          }
                                                        : tLx
                                                  )
                                                : workOrders.taskLists,
                                            });
                                          }}
                                        /> */}
                                          </div>
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                                <td className="border border-dark">
                                  {" "}
                                  <ol>
                                    {tL.taskList?.meetingTasks.map((mt) => (
                                      <>
                                        <li>
                                          <div style={{ width: 300 }}>
                                            {/* RCEMSUBPROC */}
                                            {tL.taskList?.extRcemSubProcessId &&
                                            tL.taskList.extRcemSubProcessId !==
                                              "0" ? (
                                              <>
                                                <div>
                                                  {(() => {
                                                    const foundExtSubProcess =
                                                      flattenedSubProcesses?.find(
                                                        (sp) =>
                                                          sp.subProcess
                                                            .masterJavaBaseModel
                                                            ?.id ===
                                                          tL.taskList
                                                            ?.extRcemSubProcessId
                                                      );

                                                    return (
                                                      <>
                                                        {
                                                          foundExtSubProcess
                                                            ?.dept.name
                                                        }
                                                        :{" "}
                                                        {
                                                          foundExtSubProcess
                                                            ?.process.name
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                          foundExtSubProcess
                                                            ?.subProcess.name
                                                        }
                                                      </>
                                                    );
                                                  })()}
                                                </div>
                                                <button
                                                  className="btn btn-sm btn-outline-danger"
                                                  onClick={() => {
                                                    workOrders.current.taskLists =
                                                      workOrders.current
                                                        .taskLists
                                                        ? workOrders.current.taskLists?.map(
                                                            (tLx) =>
                                                              tLx &&
                                                              tLx.taskList
                                                                ?.masterJavaBaseModel
                                                                ?.uuid ===
                                                                tL.taskList
                                                                  ?.masterJavaBaseModel
                                                                  ?.uuid
                                                                ? {
                                                                    ...tLx,
                                                                    taskList:
                                                                      tLx.taskList
                                                                        ? {
                                                                            ...tLx.taskList,
                                                                            extRcemSubProcessId:
                                                                              undefined,
                                                                          }
                                                                        : tLx.taskList,
                                                                  }
                                                                : tLx
                                                          )
                                                        : workOrders.current
                                                            .taskLists;
                                                    render();
                                                    // setWorkOrders({
                                                    //   ...workOrders,
                                                    //   taskLists:
                                                    //     workOrders.current.taskLists
                                                    //       ? workOrders.current.taskLists?.map(
                                                    //           (tLx) =>
                                                    //             tLx &&
                                                    //             tLx.taskList
                                                    //               ?.masterJavaBaseModel
                                                    //               ?.uuid ===
                                                    //               tL.taskList
                                                    //                 ?.masterJavaBaseModel
                                                    //                 ?.uuid
                                                    //               ? {
                                                    //                   ...tLx,
                                                    //                   taskList:
                                                    //                     tLx.taskList
                                                    //                       ? {
                                                    //                           ...tLx.taskList,
                                                    //                           extRcemSubProcessId:
                                                    //                             undefined,
                                                    //                         }
                                                    //                       : tLx.taskList,
                                                    //                 }
                                                    //               : tLx
                                                    //         )
                                                    //       : workOrders.current.taskLists,
                                                    // });
                                                  }}
                                                >
                                                  Clear
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <Select
                                                  options={flattenedSubProcesses?.map(
                                                    (sp) => ({
                                                      label: `${sp.dept.name}: ${sp.process.name} - ${sp.subProcess.name}`,
                                                      value: sp,
                                                    })
                                                  )}
                                                  onChange={(v) => {
                                                    const val = (
                                                      v as unknown as {
                                                        value?: {
                                                          subProcess?: PpicRcemSubProcess;
                                                        };
                                                      }
                                                    )?.value;

                                                    workOrders.current.taskLists =
                                                      workOrders.current
                                                        .taskLists
                                                        ? workOrders.current.taskLists?.map(
                                                            (tLx) =>
                                                              tLx &&
                                                              tLx.taskList
                                                                ?.masterJavaBaseModel
                                                                ?.uuid ===
                                                                tL.taskList
                                                                  ?.masterJavaBaseModel
                                                                  ?.uuid
                                                                ? {
                                                                    ...tLx,
                                                                    taskList:
                                                                      tLx.taskList
                                                                        ? {
                                                                            ...tLx.taskList,
                                                                            extRcemSubProcessId:
                                                                              val
                                                                                ?.subProcess
                                                                                ?.masterJavaBaseModel
                                                                                ?.id,
                                                                          }
                                                                        : tLx.taskList,
                                                                  }
                                                                : tLx
                                                          )
                                                        : workOrders.current
                                                            .taskLists;

                                                    render();

                                                    // setWorkOrders({
                                                    //   ...workOrders,
                                                    //   taskLists:
                                                    //     workOrders.current.taskLists
                                                    //       ? workOrders.current.taskLists?.map(
                                                    //           (tLx) =>
                                                    //             tLx &&
                                                    //             tLx.taskList
                                                    //               ?.masterJavaBaseModel
                                                    //               ?.uuid ===
                                                    //               tL.taskList
                                                    //                 ?.masterJavaBaseModel
                                                    //                 ?.uuid
                                                    //               ? {
                                                    //                   ...tLx,
                                                    //                   taskList:
                                                    //                     tLx.taskList
                                                    //                       ? {
                                                    //                           ...tLx.taskList,
                                                    //                           extRcemSubProcessId:
                                                    //                             val
                                                    //                               ?.subProcess
                                                    //                               ?.masterJavaBaseModel
                                                    //                               ?.id,
                                                    //                         }
                                                    //                       : tLx.taskList,
                                                    //                 }
                                                    //               : tLx
                                                    //         )
                                                    //       : workOrders.current.taskLists,
                                                    // });
                                                  }}
                                                />
                                              </>
                                            )}
                                          </div>
                                        </li>
                                      </>
                                    ))}
                                  </ol>
                                </td>
                              </tr>
                            </>
                          );
                        })
                        .flat()}
                    </table>
                  </div>
                </div>

                <hr className="border border-dark" />

                <div>
                  <strong>Product Tree Auto Assign Result</strong>
                </div>

                <div>
                  <TreeAutoAssignPreview type="Product" />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {viewType === "IntegrationListFlattened" ? (
            <>
              <div>
                <ol>
                  {(() => {
                    const mods = [
                      ...new Set(
                        modulesFlattened.map(
                          (m) => m.module.integrationModule?.id
                        ) ?? 0
                      ),
                    ]
                      .map((integrationModuleId) =>
                        modulesFlattened.find(
                          (m) =>
                            m.module.integrationModule?.id ===
                            integrationModuleId
                        )
                      )
                      .filter((m) => m)
                      .map(
                        (m) =>
                          m as {
                            integration: Integration | null;
                            module: IntegrationModuleView;
                          }
                      );

                    const integrationModuleTypeToNumber = (
                      i?: IntegrationModuleType | null
                    ) => {
                      switch (i) {
                        case "SET":
                          return 0;
                        case "MODULE":
                          return 1;
                        case "SUBMODULE":
                          return 2;
                        default:
                          return 0;
                      }
                    };

                    mods.sort((a, b) => {
                      return (
                        integrationModuleTypeToNumber(
                          a.module.integrationModule?.type
                        ) -
                        integrationModuleTypeToNumber(
                          b.module.integrationModule?.type
                        )
                      );
                    });

                    return mods;
                  })().map((m, i) => (
                    <>
                      <li>
                        <div>
                          {m.module.integrationModule?.id}
                          {": "}
                          {m.module.integrationModule?.integration?.name}
                          {": "}
                          {m.module.integrationModule?.type}
                          {": "}
                          {m.module.integrationModule?.name}
                        </div>
                      </li>
                    </>
                  ))}
                </ol>
              </div>
            </>
          ) : (
            <></>
          )}

          {viewType === "ProgramTree" ? (
            <>
              <div>
                <div className="d-flex">
                  <h4>Program Tree</h4>
                </div>
                <hr />
                <div className="d-flex flex-column">
                  <div
                    className="overflow-auto flex-grow-1 p-1 border border-dark"
                    style={{
                      // width: "50vw",
                      height: "65vh",
                      resize: "vertical",
                    }}
                  >
                    {state.job.job?.panelCodes?.map((pc, i) => {
                      return (
                        <>
                          <div>
                            <div className="d-flex">
                              <div>
                                {i + 1}. {pc.type} {pc.name} {pc.serialNumber}
                              </div>
                            </div>

                            <div
                              className="overflow-auto"
                              // style={{ height: "30vh" }}
                            >
                              <table
                                className="table table-sm"
                                style={{ borderCollapse: "separate" }}
                              >
                                <tr>
                                  {[
                                    "#",
                                    "Program",
                                    // "Time (mins)",
                                    "x run",
                                    // "Total time",
                                    ...simplifiedProcessTypes.map((pt) => (
                                      <>
                                        <div>
                                          <div>{pt.name ?? ""}</div>
                                          <div>
                                            <small>
                                              SPV:{" "}
                                              {
                                                ctx?.extUsers.find(
                                                  (u) =>
                                                    `${u.id}` ===
                                                    `${pt.extSpvId}`
                                                )?.name
                                              }
                                            </small>
                                          </div>
                                          {/* <div>
                                            <small>
                                              PIC:{" "}
                                              <div>
                                                <ol>
                                                  {pt.simplifiedProcessTypePics?.map(
                                                    (sptp) => {
                                                      const u =
                                                        ctx?.extUsers.find(
                                                          (u) =>
                                                            `${u.id}` ===
                                                            `${sptp.extUserId}`
                                                        );
                                                      return (
                                                        <>
                                                          <li>{`${
                                                            u?.name ?? "No name"
                                                          }`}</li>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </ol>
                                              </div>
                                            </small>
                                          </div> */}
                                        </div>
                                      </>
                                    )),
                                  ].map((h) => (
                                    <>
                                      <th
                                        className="bg-dark text-light"
                                        style={{ position: "sticky", top: 0 }}
                                      >
                                        {h}
                                      </th>
                                    </>
                                  ))}
                                </tr>
                                {pc.machinePrograms?.map((mp, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="border border-dark">
                                          {i + 1}
                                        </td>
                                        <td className="border border-dark">
                                          <div>
                                            <div>{mp?.name}</div>
                                            <div className="d-flex">
                                              <div>
                                                <button
                                                  className="btn btn-outline-primary btn-sm"
                                                  onClick={() => {
                                                    const newTaskLists =
                                                      simplifiedProcessTypes.map(
                                                        (pt) => {
                                                          const foundMachineProgramMachine =
                                                            machines?.machines.find(
                                                              (m) =>
                                                                `${m.id}` ===
                                                                `${
                                                                  machineProgramMachines.machineProgramMachines.find(
                                                                    (mpm) =>
                                                                      `${mpm.machineProgramId}` ===
                                                                        `${mp.id}` &&
                                                                      `${mpm.simplifiedProcessTypeId}` ===
                                                                        `${pt.id}`
                                                                  )?.machineId
                                                                }`
                                                            );

                                                          return MeetingTaskListView.fromPartial(
                                                            {
                                                              taskList:
                                                                MeetingTaskList.fromPartial(
                                                                  {
                                                                    extProcessTypeId:
                                                                      pt.id
                                                                        ? `${pt.id}`
                                                                        : undefined,
                                                                    extJobId:
                                                                      state.job
                                                                        .job?.id
                                                                        ? `${state.job.job?.id}`
                                                                        : undefined,
                                                                    extPanelCodeId:
                                                                      pc.id
                                                                        ? `${pc.id}`
                                                                        : undefined,
                                                                    extProgramRunQty:
                                                                      mp.qtyRun ??
                                                                      0,
                                                                    extProgramId:
                                                                      mp?.id
                                                                        ? `${mp?.id}`
                                                                        : undefined,
                                                                    extMachineId:
                                                                      foundMachineProgramMachine?.id,
                                                                    extInChargeId:
                                                                      pt.extSpvId
                                                                        ? `${pt.extSpvId}`
                                                                        : undefined,
                                                                    meetingTasks:
                                                                      [
                                                                        MeetingMeetingTask.fromPartial(
                                                                          {
                                                                            description: `Program ${mp.name} for panel ${pc.type}:${pc.name}`,
                                                                            meetingTaskInCharges:
                                                                              foundMachineProgramMachine?.pics?.map(
                                                                                (
                                                                                  p
                                                                                ) => {
                                                                                  return MeetingMeetingTaskInCharge.fromPartial(
                                                                                    {
                                                                                      extUserId:
                                                                                        p.extUserId
                                                                                          ? `${p.extUserId}`
                                                                                          : undefined,

                                                                                      masterJavaBaseModel:
                                                                                        MasterJavaBaseModel.fromPartial(
                                                                                          {
                                                                                            uuid: v4(),
                                                                                          }
                                                                                        ),
                                                                                    }
                                                                                  );
                                                                                }
                                                                              ),
                                                                            masterJavaBaseModel:
                                                                              MasterJavaBaseModel.fromPartial(
                                                                                {
                                                                                  uuid: uuidv4(),
                                                                                }
                                                                              ),
                                                                          }
                                                                        ),
                                                                      ],
                                                                    masterJavaBaseModel:
                                                                      MasterJavaBaseModel.fromPartial(
                                                                        {
                                                                          uuid: uuidv4(),
                                                                        }
                                                                      ),
                                                                  }
                                                                ),
                                                            }
                                                          );
                                                        }
                                                      );
                                                    if (workOrders) {
                                                      workOrders.current.taskLists =
                                                        workOrders.current
                                                          .taskLists
                                                          ? [
                                                              ...workOrders
                                                                .current
                                                                .taskLists,
                                                              ...newTaskLists,
                                                            ]
                                                          : [...newTaskLists];

                                                      render();
                                                      // setWorkOrders({
                                                      //   ...workOrders,
                                                      //   taskLists:
                                                      //     workOrders.current.taskLists
                                                      //       ? [
                                                      //           ...workOrders.current.taskLists,
                                                      //           ...newTaskLists,
                                                      //         ]
                                                      //       : [...newTaskLists],
                                                      // });
                                                    }
                                                  }}
                                                >
                                                  Auto Map
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border border-dark">
                                          {mp.qtyRun}
                                        </td>

                                        {simplifiedProcessTypes.map((pt) => {
                                          const foundMachineProgramMachine =
                                            machines?.machines.find(
                                              (m) =>
                                                `${m.id}` ===
                                                `${
                                                  machineProgramMachines.machineProgramMachines.find(
                                                    (mpm) =>
                                                      `${mpm.machineProgramId}` ===
                                                        `${mp.id}` &&
                                                      `${mpm.simplifiedProcessTypeId}` ===
                                                        `${pt.id}`
                                                  )?.machineId
                                                }`
                                            );

                                          const filteredWos =
                                            workOrders?.current?.taskLists?.filter(
                                              (tL) =>
                                                tL.taskList?.extJobId ===
                                                  `${state.job.job?.id}` &&
                                                tL.taskList?.extPanelCodeId ===
                                                  `${pc.id}` &&
                                                tL.taskList?.extProgramId ===
                                                  `${mp?.id}` &&
                                                tL.taskList
                                                  ?.extProcessTypeId ===
                                                  `${pt.id}`
                                            );
                                          const wos =
                                            filteredWos?.reduce(
                                              (acc, tL) =>
                                                acc +
                                                (tL.taskList
                                                  ?.extProgramRunQty ?? 0),
                                              0
                                            ) ?? 0;
                                          const wosActual =
                                            filteredWos?.reduce(
                                              (acc, tL) =>
                                                acc +
                                                (tL.taskList
                                                  ?.extProgramRunQtyActual ??
                                                  0),
                                              0
                                            ) ?? 0;

                                          const color = (() => {
                                            if (
                                              pc.panelCodeMachineProgramExclusions?.find(
                                                (e) =>
                                                  // `${e.extMachineProgramId}` ===
                                                  //   `${mp.id}` &&
                                                  `${e.extSimplifiedProcessTypeId}` ===
                                                  `${pt.id}`
                                              )?.excluded
                                            ) {
                                              return "grey";
                                            }

                                            if (wos <= 0 && wosActual <= 0) {
                                              return "lightsalmon";
                                            } else if (
                                              wosActual >= (mp.qtyRun ?? 0)
                                            ) {
                                              return "lightgreen";
                                            } else if (wos < (mp.qtyRun ?? 0)) {
                                              return "yellow";
                                            } else {
                                              return "lightblue";
                                            }
                                          })();

                                          return (
                                            <>
                                              <td
                                                className="border border-dark"
                                                style={{
                                                  backgroundColor: color,
                                                }}
                                              >
                                                <div>
                                                  <div className="d-flex justify-content-between">
                                                    <div>
                                                      {wosActual}/{wos}/
                                                      {mp.qtyRun}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="d-flex">
                                                      <div>
                                                        <button
                                                          onClick={() => {
                                                            if (
                                                              !state.job.job?.id
                                                            ) {
                                                              alert(
                                                                "Please save job first before adding WO program."
                                                              );
                                                              return;
                                                            }

                                                            if (workOrders) {
                                                              console.log({
                                                                pt: pt.id,
                                                                jobid:
                                                                  state.job.job
                                                                    .id,
                                                                panelcode:
                                                                  pc.id,
                                                                mp: mp.id,
                                                              });
                                                              const foundMachineProgramProto =
                                                                machineProgramsProto.machinePrograms.find(
                                                                  (mpx) =>
                                                                    `${mpx.masterJavaBaseModel?.id}` ===
                                                                    `${mp.id}`
                                                                );
                                                              const newTaskList =
                                                                MeetingTaskListView.fromPartial(
                                                                  {
                                                                    taskList:
                                                                      MeetingTaskList.fromPartial(
                                                                        {
                                                                          extProcessTypeId:
                                                                            pt.id
                                                                              ? `${pt.id}`
                                                                              : undefined,
                                                                          extJobId:
                                                                            state
                                                                              .job
                                                                              .job
                                                                              ?.id
                                                                              ? `${state.job.job?.id}`
                                                                              : undefined,
                                                                          extPanelCodeId:
                                                                            pc.id
                                                                              ? `${pc.id}`
                                                                              : undefined,
                                                                          extProgramId:
                                                                            mp?.id
                                                                              ? `${mp?.id}`
                                                                              : undefined,
                                                                          extProgramRunQty:
                                                                            mp.qtyRun ??
                                                                            0,
                                                                          extMachineId:
                                                                            foundMachineProgramMachine?.id,
                                                                          extInChargeId:
                                                                            pt.extSpvId
                                                                              ? `${pt.extSpvId}`
                                                                              : undefined,
                                                                          meetingTasks:
                                                                            [
                                                                              MeetingMeetingTask.fromPartial(
                                                                                {
                                                                                  description: `Program ${mp.name} for panel ${pc.type}:${pc.name}`,
                                                                                  meetingTaskInCharges:
                                                                                    foundMachineProgramMachine?.pics?.map(
                                                                                      (
                                                                                        p
                                                                                      ) => {
                                                                                        return MeetingMeetingTaskInCharge.fromPartial(
                                                                                          {
                                                                                            extUserId:
                                                                                              p.extUserId
                                                                                                ? `${p.extUserId}`
                                                                                                : undefined,
                                                                                            masterJavaBaseModel:
                                                                                              MasterJavaBaseModel.fromPartial(
                                                                                                {
                                                                                                  uuid: v4(),
                                                                                                }
                                                                                              ),
                                                                                          }
                                                                                        );
                                                                                      }
                                                                                    ),
                                                                                  masterJavaBaseModel:
                                                                                    MasterJavaBaseModel.fromPartial(
                                                                                      {
                                                                                        uuid: uuidv4(),
                                                                                      }
                                                                                    ),
                                                                                }
                                                                              ),
                                                                            ],
                                                                          masterJavaBaseModel:
                                                                            MasterJavaBaseModel.fromPartial(
                                                                              {
                                                                                uuid: uuidv4(),
                                                                              }
                                                                            ),
                                                                        }
                                                                      ),
                                                                  }
                                                                );
                                                              workOrders.current.taskLists =
                                                                workOrders
                                                                  .current
                                                                  .taskLists
                                                                  ? [
                                                                      ...workOrders
                                                                        .current
                                                                        .taskLists,
                                                                      newTaskList,
                                                                    ]
                                                                  : [
                                                                      newTaskList,
                                                                    ];

                                                              render();
                                                              // setWorkOrders({
                                                              //   ...workOrders,
                                                              //   taskLists:
                                                              //     workOrders.taskLists
                                                              //       ? [
                                                              //           ...workOrders.taskLists,
                                                              //           newTaskList,
                                                              //         ]
                                                              //       : [newTaskList],
                                                              // });
                                                            }
                                                          }}
                                                          className="btn btn-outline-primary btn-sm"
                                                        >
                                                          <small>
                                                            <Add /> WO
                                                          </small>
                                                        </button>
                                                      </div>

                                                      {/* {JSON.stringify(
                                                        pc.panelCodeMachineProgramExclusions
                                                      )} */}
                                                      <div>
                                                        <button
                                                          onClick={() => {
                                                            const foundExclusion =
                                                              state.job.job?.panelCodes
                                                                ?.filter(
                                                                  (c) =>
                                                                    `${c.uuid}` ===
                                                                    `${pc.uuid}`
                                                                )
                                                                ?.flatMap(
                                                                  (c) =>
                                                                    c.panelCodeMachineProgramExclusions
                                                                )
                                                                ?.find(
                                                                  (e) =>
                                                                    // `${e?.extMachineProgramId}` ===
                                                                    //   `${mp.id}` &&
                                                                    `${e?.extSimplifiedProcessTypeId}` ===
                                                                    `${pt.id}`
                                                                );

                                                            const newExclusion =
                                                              {
                                                                ...initialMachineProgramExclusion,
                                                                uuid: v4(),
                                                                // extMachineProgramId:
                                                                //   mp.id,
                                                                extSimplifiedProcessTypeId:
                                                                  pt.id,
                                                                excluded: true,
                                                              } as MachineProgramExclusion;

                                                            setState({
                                                              ...state,
                                                              job: state.job
                                                                ? {
                                                                    ...state.job,
                                                                    job: state
                                                                      .job.job
                                                                      ? {
                                                                          ...state
                                                                            .job
                                                                            .job,
                                                                          panelCodes:
                                                                            state
                                                                              .job
                                                                              .job
                                                                              .panelCodes
                                                                              ? state.job.job.panelCodes.map(
                                                                                  (
                                                                                    pcx
                                                                                  ) =>
                                                                                    pcx &&
                                                                                    pcx.uuid ===
                                                                                      pc.uuid
                                                                                      ? {
                                                                                          ...pcx,
                                                                                          panelCodeMachineProgramExclusions:
                                                                                            foundExclusion
                                                                                              ? pcx.panelCodeMachineProgramExclusions?.map(
                                                                                                  (
                                                                                                    e
                                                                                                  ) =>
                                                                                                    e.uuid ===
                                                                                                    foundExclusion.uuid
                                                                                                      ? {
                                                                                                          ...e,
                                                                                                          excluded:
                                                                                                            !e.excluded,
                                                                                                        }
                                                                                                      : e
                                                                                                ) ??
                                                                                                []
                                                                                              : [
                                                                                                  ...(pcx.panelCodeMachineProgramExclusions ??
                                                                                                    []),
                                                                                                  {
                                                                                                    ...newExclusion,
                                                                                                  },
                                                                                                ],
                                                                                        }
                                                                                      : pcx
                                                                                )
                                                                              : state
                                                                                  .job
                                                                                  .job
                                                                                  .panelCodes,
                                                                        }
                                                                      : state
                                                                          .job
                                                                          .job,
                                                                  }
                                                                : state.job,
                                                            });
                                                          }}
                                                          className="btn btn-sm btn-secondary"
                                                        >
                                                          Toggle
                                                        </button>
                                                      </div>
                                                    </div>

                                                    <div>
                                                      <small>
                                                        <strong
                                                          className={`${
                                                            foundMachineProgramMachine
                                                              ? `text-info`
                                                              : `text-danger`
                                                          }`}
                                                        >
                                                          {foundMachineProgramMachine?.name
                                                            ? foundMachineProgramMachine?.name
                                                            : "No machine"}
                                                        </strong>
                                                      </small>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </>
                                          );
                                        })}
                                      </tr>
                                    </>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <div>
                      <h4>Punch {`->`} Coat WOs</h4>
                    </div>
                    <div
                      className="p-2 flex-grow-1 overflow-auto border border-dark"
                      style={{
                        // width: "50vw",
                        height: "65vh",
                        resize: "vertical",
                      }}
                    >
                      <table
                        className="table table-sm"
                        style={{ borderCollapse: "separate" }}
                      >
                        <tr>
                          {[
                            "Action",
                            "#",
                            "Program",
                            "Main Part",
                            "Product/panel",
                            "Process Type",
                            "Qty",
                            "Qty Actual",
                            "Machine",
                            "SPV",
                            "In charge",
                            "From",
                            "To",
                            "Complete",
                            "Completed Date",
                          ].map((h) => {
                            return (
                              <th
                                className="border border-dark bg-dark text-light"
                                style={{ position: "sticky", top: 0 }}
                              >
                                {h}
                              </th>
                            );
                          })}
                        </tr>
                        {workOrders?.current?.taskLists
                          ?.filter(
                            (tL, i) =>
                              tL.taskList?.extProcessTypeId !== null &&
                              tL.taskList?.extProcessTypeId !== undefined &&
                              tL.taskList?.extProcessTypeId !== "0"
                          )
                          ?.map((tL, i) => {
                            const foundPanel = state.job?.job?.panelCodes?.find(
                              (pc) => `${pc.id}` === tL.taskList?.extPanelCodeId
                            );
                            const fProgram = machinePrograms.find(
                              (mp) => `${mp.id}` === tL.taskList?.extProgramId
                            );
                            return tL.taskList?.meetingTasks?.map((mt, j) => {
                              return (
                                <tr>
                                  <td className="border border-dark">
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Are you sure you want to delete the WO? This action cannot be undone."
                                          )
                                        ) {
                                          if (workOrders) {
                                            setWorkOrderDeleteIds([
                                              ...workOrderDeleteIds,
                                              tL.taskList?.masterJavaBaseModel
                                                ?.id ?? "0",
                                            ]);

                                            workOrders.current.taskLists =
                                              workOrders.current.taskLists
                                                ? workOrders.current.taskLists?.filter(
                                                    (tLx) =>
                                                      tLx.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid !==
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                  )
                                                : workOrders.current.taskLists;

                                            render();
                                            // setWorkOrders({
                                            //   ...workOrders,
                                            //   taskLists: workOrders.current.taskLists
                                            //     ? workOrders.current.taskLists?.filter(
                                            //         (tLx) =>
                                            //           tLx.taskList
                                            //             ?.masterJavaBaseModel
                                            //             ?.uuid !==
                                            //           tL.taskList
                                            //             ?.masterJavaBaseModel
                                            //             ?.uuid
                                            //       )
                                            //     : workOrders.current.taskLists,
                                            // });
                                          }
                                        }
                                      }}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                  <td className="border border-dark">
                                    {i + 1}{" "}
                                    {/* {tL.taskList?.meetingTasks
                                      .map((mt) => mt.description)
                                      .join(",")} */}
                                  </td>

                                  <td
                                    className="bg-light border border-dark"
                                    style={{
                                      left: 0,
                                      position: "sticky",
                                      zIndex: 1,
                                    }}
                                  >
                                    <div>{fProgram?.name}</div>
                                    <div>
                                      {secondsToHms(
                                        (fProgram?.timeProcessMins ?? 0) * 60
                                      )}
                                    </div>
                                    <div>
                                      x {tL.taskList?.extProgramRunQty ?? 0} ={" "}
                                      {secondsToHms(
                                        (fProgram?.timeProcessMins ?? 0) *
                                          (tL.taskList?.extProgramRunQty ?? 0) *
                                          60
                                      )}
                                    </div>
                                  </td>
                                  <td className="border border-dark">
                                    {fProgram?.integrationModule?.name}
                                  </td>
                                  <td className="border border-dark">
                                    {foundPanel?.type} - {foundPanel?.name} -{" "}
                                    {foundPanel?.serialNumber}
                                  </td>
                                  <td className="border border-dark">
                                    {
                                      simplifiedProcessTypes.find(
                                        (ptx) =>
                                          `${ptx.id}` ===
                                          tL.taskList?.extProcessTypeId
                                      )?.name
                                    }
                                  </td>
                                  <td className="border border-dark">
                                    <input
                                      style={{ width: 50 }}
                                      className="form-control form-control-sm"
                                      defaultValue={
                                        tL.taskList?.extProgramRunQty
                                      }
                                      onBlur={(e) => {
                                        if (workOrders) {
                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                extProgramRunQty:
                                                                  isNaN(
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  )
                                                                    ? 0
                                                                    : parseInt(
                                                                        e.target
                                                                          .value
                                                                      ),
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.current.taskLists
                                          //     ? workOrders.current.taskLists.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       extProgramRunQty:
                                          //                         isNaN(
                                          //                           parseInt(
                                          //                             e.target
                                          //                               .value
                                          //                           )
                                          //                         )
                                          //                           ? 0
                                          //                           : parseInt(
                                          //                               e.target
                                          //                                 .value
                                          //                             ),
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.current.taskLists,
                                          // });
                                        }
                                      }}
                                    />{" "}
                                  </td>
                                  <td className="border border-dark">
                                    <input
                                      style={{ width: 50 }}
                                      className="form-control form-control-sm"
                                      defaultValue={
                                        tL.taskList?.extProgramRunQtyActual
                                      }
                                      onBlur={(e) => {
                                        if (workOrders) {
                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                extProgramRunQtyActual:
                                                                  isNaN(
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    )
                                                                  )
                                                                    ? 0
                                                                    : parseInt(
                                                                        e.target
                                                                          .value
                                                                      ),
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.current.taskLists
                                          //     ? workOrders.current.taskLists.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       extProgramRunQtyActual:
                                          //                         isNaN(
                                          //                           parseInt(
                                          //                             e.target
                                          //                               .value
                                          //                           )
                                          //                         )
                                          //                           ? 0
                                          //                           : parseInt(
                                          //                               e.target
                                          //                                 .value
                                          //                             ),
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.current.taskLists,
                                          // });
                                        }
                                      }}
                                    />{" "}
                                  </td>
                                  <td className="border border-dark">
                                    <div style={{ width: 150 }}>
                                      <div>
                                        <strong>
                                          {
                                            machines?.machines?.find(
                                              (m) =>
                                                m.id ===
                                                tL.taskList?.extMachineId
                                            )?.name
                                          }
                                        </strong>
                                      </div>
                                      <div>
                                        <Select
                                          placeholder="Select machine..."
                                          options={
                                            machines?.machines?.map((m) => ({
                                              label: m.name,
                                              value: m,
                                            })) ?? []
                                          }
                                          onChange={(v) => {
                                            const val = (
                                              v as unknown as {
                                                value: PpicMachine;
                                              }
                                            ).value;
                                            console.log(val);

                                            if (workOrders) {
                                              workOrders.current.taskLists =
                                                workOrders.current.taskLists
                                                  ? workOrders.current.taskLists.map(
                                                      (tLx) =>
                                                        tLx &&
                                                        tLx.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid ===
                                                          tL.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...tLx,
                                                              taskList:
                                                                tLx.taskList
                                                                  ? {
                                                                      ...tLx.taskList,
                                                                      extMachineId:
                                                                        val.id,
                                                                    }
                                                                  : tLx.taskList,
                                                            }
                                                          : tLx
                                                    )
                                                  : workOrders.current
                                                      .taskLists;

                                              render();
                                              // setWorkOrders({
                                              //   ...workOrders,
                                              //   taskLists: workOrders.current.taskLists
                                              //     ? workOrders.current.taskLists.map(
                                              //         (tLx) =>
                                              //           tLx &&
                                              //           tLx.taskList
                                              //             ?.masterJavaBaseModel
                                              //             ?.uuid ===
                                              //             tL.taskList
                                              //               ?.masterJavaBaseModel
                                              //               ?.uuid
                                              //             ? {
                                              //                 ...tLx,
                                              //                 taskList:
                                              //                   tLx.taskList
                                              //                     ? {
                                              //                         ...tLx.taskList,
                                              //                         extMachineId:
                                              //                           val.id,
                                              //                       }
                                              //                     : tLx.taskList,
                                              //               }
                                              //             : tLx
                                              //       )
                                              //     : workOrders.current.taskLists,
                                              // });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </td>

                                  <td className="border border-dark">
                                    <div style={{ width: 250 }}>
                                      <div>
                                        <Select
                                          placeholder="Select spv..."
                                          options={
                                            ctx?.extUsers?.map((u) => ({
                                              label: `${u.name} (${u.departmentName})`,
                                              value: u,
                                            })) ?? []
                                          }
                                          onChange={(v) => {
                                            const val = (
                                              v as unknown as { value: ExtUser }
                                            ).value;
                                            console.log(val);

                                            if (workOrders) {
                                              workOrders.current.taskLists =
                                                workOrders.current.taskLists
                                                  ? workOrders.current.taskLists.map(
                                                      (tLx) =>
                                                        tLx &&
                                                        tLx.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid ===
                                                          tL.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...tLx,
                                                              taskList:
                                                                tLx.taskList
                                                                  ? {
                                                                      ...tLx.taskList,
                                                                      extInChargeId: `${val.id}`,
                                                                    }
                                                                  : tLx.taskList,
                                                            }
                                                          : tLx
                                                    )
                                                  : workOrders.current
                                                      .taskLists;

                                              render();
                                              // setWorkOrders({
                                              //   ...workOrders,
                                              //   taskLists: workOrders.current.taskLists
                                              //     ? workOrders.current.taskLists.map(
                                              //         (tLx) =>
                                              //           tLx &&
                                              //           tLx.taskList
                                              //             ?.masterJavaBaseModel
                                              //             ?.uuid ===
                                              //             tL.taskList
                                              //               ?.masterJavaBaseModel
                                              //               ?.uuid
                                              //             ? {
                                              //                 ...tLx,
                                              //                 taskList:
                                              //                   tLx.taskList
                                              //                     ? {
                                              //                         ...tLx.taskList,
                                              //                         extInChargeId: `${val.id}`,
                                              //                       }
                                              //                     : tLx.taskList,
                                              //               }
                                              //             : tLx
                                              //       )
                                              //     : workOrders.current.taskLists,
                                              // });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div>
                                        {(() => {
                                          const foundUser = ctx?.extUsers.find(
                                            (u) =>
                                              `${u?.id}` ===
                                              `${tL?.taskList?.extInChargeId}`
                                          );
                                          return foundUser
                                            ? `${foundUser?.name} (${foundUser?.departmentName})`
                                            : "None";
                                        })()}
                                      </div>
                                    </div>
                                  </td>

                                  <td className="border border-dark">
                                    <div style={{ width: 250 }}>
                                      <div>
                                        <Select
                                          placeholder="Select PIC..."
                                          options={
                                            ctx?.extUsers?.map((u) => ({
                                              label: `${u.name} (${u.departmentName})`,
                                              value: u,
                                            })) ?? []
                                          }
                                          onChange={(v) => {
                                            const val = (
                                              v as unknown as { value: ExtUser }
                                            ).value;
                                            console.log(val);
                                            if (
                                              mt.meetingTaskInCharges.find(
                                                (mtic) =>
                                                  mtic.extUserId === `${val.id}`
                                              )
                                            ) {
                                              alert("User already selected");
                                              return;
                                            }
                                            const newMeetingTaskInCharge =
                                              MeetingMeetingTaskInCharge.fromPartial(
                                                {
                                                  extUserId: val.id
                                                    ? `${val.id}`
                                                    : undefined,
                                                  masterJavaBaseModel:
                                                    MasterJavaBaseModel.fromPartial(
                                                      { uuid: uuidv4() }
                                                    ),
                                                }
                                              );

                                            if (workOrders) {
                                              workOrders.current.taskLists =
                                                workOrders.current.taskLists
                                                  ? workOrders.current.taskLists.map(
                                                      (tLx) =>
                                                        tLx &&
                                                        tLx.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid ===
                                                          tL.taskList
                                                            ?.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...tLx,
                                                              taskList:
                                                                tLx.taskList
                                                                  ? {
                                                                      ...tLx.taskList,
                                                                      meetingTasks:
                                                                        tLx
                                                                          .taskList
                                                                          .meetingTasks
                                                                          ? tLx.taskList.meetingTasks.map(
                                                                              (
                                                                                mt
                                                                              ) =>
                                                                                mt
                                                                                  ? {
                                                                                      ...mt,
                                                                                      meetingTaskInCharges:
                                                                                        mt.meetingTaskInCharges
                                                                                          ? [
                                                                                              ...mt.meetingTaskInCharges,
                                                                                              newMeetingTaskInCharge,
                                                                                            ]
                                                                                          : [
                                                                                              newMeetingTaskInCharge,
                                                                                            ],
                                                                                    }
                                                                                  : mt
                                                                            )
                                                                          : tLx
                                                                              .taskList
                                                                              .meetingTasks,
                                                                    }
                                                                  : tLx.taskList,
                                                            }
                                                          : tLx
                                                    )
                                                  : workOrders.current
                                                      .taskLists;

                                              render();
                                              // setWorkOrders({
                                              //   ...workOrders,
                                              //   taskLists: workOrders.current.taskLists
                                              //     ? workOrders.current.taskLists.map(
                                              //         (tLx) =>
                                              //           tLx &&
                                              //           tLx.taskList
                                              //             ?.masterJavaBaseModel
                                              //             ?.uuid ===
                                              //             tL.taskList
                                              //               ?.masterJavaBaseModel
                                              //               ?.uuid
                                              //             ? {
                                              //                 ...tLx,
                                              //                 taskList:
                                              //                   tLx.taskList
                                              //                     ? {
                                              //                         ...tLx.taskList,
                                              //                         meetingTasks:
                                              //                           tLx
                                              //                             .taskList
                                              //                             .meetingTasks
                                              //                             ? tLx.taskList.meetingTasks.map(
                                              //                                 (
                                              //                                   mt
                                              //                                 ) =>
                                              //                                   mt
                                              //                                     ? {
                                              //                                         ...mt,
                                              //                                         meetingTaskInCharges:
                                              //                                           mt.meetingTaskInCharges
                                              //                                             ? [
                                              //                                                 ...mt.meetingTaskInCharges,
                                              //                                                 newMeetingTaskInCharge,
                                              //                                               ]
                                              //                                             : [
                                              //                                                 newMeetingTaskInCharge,
                                              //                                               ],
                                              //                                       }
                                              //                                     : mt
                                              //                               )
                                              //                             : tLx
                                              //                                 .taskList
                                              //                                 .meetingTasks,
                                              //                       }
                                              //                     : tLx.taskList,
                                              //               }
                                              //             : tLx
                                              //       )
                                              //     : workOrders.current.taskLists,
                                              // });
                                            }
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <ol>
                                          {tL.taskList?.meetingTasks
                                            ?.map((mt) => {
                                              return mt.meetingTaskInCharges?.map(
                                                (mtic) => {
                                                  const fu = ctx?.extUsers.find(
                                                    (u) =>
                                                      `${u.id}` ===
                                                      mtic.extUserId
                                                  );
                                                  return (
                                                    <li>
                                                      <div
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                          setMeetingTaskInChargeDeleteIds(
                                                            [
                                                              ...meetingTaskInChargeDeleteIds,
                                                              mtic
                                                                .masterJavaBaseModel
                                                                ?.id ?? "",
                                                            ]
                                                          );

                                                          workOrders.current.taskLists =
                                                            workOrders.current
                                                              .taskLists
                                                              ? workOrders.current.taskLists.map(
                                                                  (tLx) =>
                                                                    tLx &&
                                                                    tLx.taskList
                                                                      ?.masterJavaBaseModel
                                                                      ?.uuid ===
                                                                      tL
                                                                        .taskList
                                                                        ?.masterJavaBaseModel
                                                                        ?.uuid
                                                                      ? {
                                                                          ...tLx,
                                                                          taskList:
                                                                            tLx.taskList
                                                                              ? {
                                                                                  ...tLx.taskList,
                                                                                  meetingTasks:
                                                                                    tLx
                                                                                      .taskList
                                                                                      .meetingTasks
                                                                                      ? tLx.taskList.meetingTasks.map(
                                                                                          (
                                                                                            mt
                                                                                          ) =>
                                                                                            mt
                                                                                              ? {
                                                                                                  ...mt,
                                                                                                  meetingTaskInCharges:
                                                                                                    mt.meetingTaskInCharges
                                                                                                      ? mt.meetingTaskInCharges.filter(
                                                                                                          (
                                                                                                            mticx
                                                                                                          ) =>
                                                                                                            mticx.extUserId !==
                                                                                                            mtic.extUserId
                                                                                                        )
                                                                                                      : mt.meetingTaskInCharges,
                                                                                                }
                                                                                              : mt
                                                                                        )
                                                                                      : tLx
                                                                                          .taskList
                                                                                          .meetingTasks,
                                                                                }
                                                                              : tLx.taskList,
                                                                        }
                                                                      : tLx
                                                                )
                                                              : workOrders
                                                                  .current
                                                                  .taskLists;

                                                          render();
                                                          // setWorkOrders({
                                                          //   ...workOrders,
                                                          //   taskLists:
                                                          //     workOrders.current.taskLists
                                                          //       ? workOrders.current.taskLists.map(
                                                          //           (tLx) =>
                                                          //             tLx &&
                                                          //             tLx
                                                          //               .taskList
                                                          //               ?.masterJavaBaseModel
                                                          //               ?.uuid ===
                                                          //               tL
                                                          //                 .taskList
                                                          //                 ?.masterJavaBaseModel
                                                          //                 ?.uuid
                                                          //               ? {
                                                          //                   ...tLx,
                                                          //                   taskList:
                                                          //                     tLx.taskList
                                                          //                       ? {
                                                          //                           ...tLx.taskList,
                                                          //                           meetingTasks:
                                                          //                             tLx
                                                          //                               .taskList
                                                          //                               .meetingTasks
                                                          //                               ? tLx.taskList.meetingTasks.map(
                                                          //                                   (
                                                          //                                     mt
                                                          //                                   ) =>
                                                          //                                     mt
                                                          //                                       ? {
                                                          //                                           ...mt,
                                                          //                                           meetingTaskInCharges:
                                                          //                                             mt.meetingTaskInCharges
                                                          //                                               ? mt.meetingTaskInCharges.filter(
                                                          //                                                   (
                                                          //                                                     mticx
                                                          //                                                   ) =>
                                                          //                                                     mticx.extUserId !==
                                                          //                                                     mtic.extUserId
                                                          //                                                 )
                                                          //                                               : mt.meetingTaskInCharges,
                                                          //                                         }
                                                          //                                       : mt
                                                          //                                 )
                                                          //                               : tLx
                                                          //                                   .taskList
                                                          //                                   .meetingTasks,
                                                          //                         }
                                                          //                       : tLx.taskList,
                                                          //                 }
                                                          //               : tLx
                                                          //         )
                                                          //       : workOrders.current.taskLists,
                                                          // });
                                                        }}
                                                      >
                                                        {fu?.name} (
                                                        {fu?.departmentName})
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                              );
                                            })
                                            .flat()}
                                        </ol>{" "}
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    className="border border-dark"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {(() => {
                                      try {
                                        return makeReadableDateStringIntlDateOnlyUtc(
                                          new Date(
                                            `${makeDateString(
                                              new Date(mt.start ?? "")
                                            )}T00:00:00Z`
                                          )
                                        );
                                      } catch (e) {
                                        return "Invalid";
                                      }
                                    })()}
                                    <input
                                      className="form-control form-control-sm"
                                      type="date"
                                      onBlur={(e) => {
                                        if (e.target.value != "") {
                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                meetingTasks:
                                                                  tLx.taskList
                                                                    .meetingTasks
                                                                    ? tLx.taskList.meetingTasks.map(
                                                                        (mt) =>
                                                                          mt
                                                                            ? {
                                                                                ...mt,
                                                                                start:
                                                                                  e
                                                                                    .target
                                                                                    .value,
                                                                              }
                                                                            : mt
                                                                      )
                                                                    : tLx
                                                                        .taskList
                                                                        .meetingTasks,
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.current.taskLists
                                          //     ? workOrders.current.taskLists.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       meetingTasks:
                                          //                         tLx.taskList
                                          //                           .meetingTasks
                                          //                           ? tLx.taskList.meetingTasks.map(
                                          //                               (mt) =>
                                          //                                 mt
                                          //                                   ? {
                                          //                                       ...mt,
                                          //                                       start:
                                          //                                         e
                                          //                                           .target
                                          //                                           .value,
                                          //                                     }
                                          //                                   : mt
                                          //                             )
                                          //                           : tLx
                                          //                               .taskList
                                          //                               .meetingTasks,
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.current.taskLists,
                                          // });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td
                                    className="border border-dark"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {(() => {
                                      try {
                                        return makeReadableDateStringIntlDateOnlyUtc(
                                          new Date(
                                            `${makeDateString(
                                              new Date(
                                                extractProtoMeetingTaskTargetDate(
                                                  mt
                                                )?.date ?? ""
                                              )
                                            )}T00:00:00Z`
                                          )
                                        );
                                      } catch (e) {
                                        return "Invalid";
                                      }
                                    })()}
                                    <input
                                      className="form-control form-control-sm"
                                      type="date"
                                      onBlur={(e) => {
                                        if (e.target.value != "") {
                                          const newMeetingTaskTargetDate =
                                            MeetingMeetingTaskTargetDate.fromPartial(
                                              {
                                                date: e.target.value,
                                                masterJavaBaseModel:
                                                  MasterJavaBaseModel.fromPartial(
                                                    { uuid: uuidv4() }
                                                  ),
                                              }
                                            );
                                          workOrders.current.taskLists =
                                            workOrders.current.taskLists
                                              ? workOrders.current.taskLists.map(
                                                  (tLx) =>
                                                    tLx &&
                                                    tLx.taskList
                                                      ?.masterJavaBaseModel
                                                      ?.uuid ===
                                                      tL.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid
                                                      ? {
                                                          ...tLx,
                                                          taskList: tLx.taskList
                                                            ? {
                                                                ...tLx.taskList,
                                                                meetingTasks:
                                                                  tLx.taskList
                                                                    .meetingTasks
                                                                    ? tLx.taskList.meetingTasks.map(
                                                                        (mt) =>
                                                                          mt
                                                                            ? {
                                                                                ...mt,
                                                                                meetingTaskTargetDates:
                                                                                  mt.meetingTaskTargetDates
                                                                                    ? [
                                                                                        ...mt.meetingTaskTargetDates,
                                                                                        newMeetingTaskTargetDate,
                                                                                      ]
                                                                                    : [
                                                                                        newMeetingTaskTargetDate,
                                                                                      ],
                                                                              }
                                                                            : mt
                                                                      )
                                                                    : tLx
                                                                        .taskList
                                                                        .meetingTasks,
                                                              }
                                                            : tLx.taskList,
                                                        }
                                                      : tLx
                                                )
                                              : workOrders.current.taskLists;

                                          render();
                                          // setWorkOrders({
                                          //   ...workOrders,
                                          //   taskLists: workOrders.current.taskLists
                                          //     ? workOrders.current.taskLists.map(
                                          //         (tLx) =>
                                          //           tLx &&
                                          //           tLx.taskList
                                          //             ?.masterJavaBaseModel
                                          //             ?.uuid ===
                                          //             tL.taskList
                                          //               ?.masterJavaBaseModel
                                          //               ?.uuid
                                          //             ? {
                                          //                 ...tLx,
                                          //                 taskList: tLx.taskList
                                          //                   ? {
                                          //                       ...tLx.taskList,
                                          //                       meetingTasks:
                                          //                         tLx.taskList
                                          //                           .meetingTasks
                                          //                           ? tLx.taskList.meetingTasks.map(
                                          //                               (mt) =>
                                          //                                 mt
                                          //                                   ? {
                                          //                                       ...mt,
                                          //                                       meetingTaskTargetDates:
                                          //                                         mt.meetingTaskTargetDates
                                          //                                           ? [
                                          //                                               ...mt.meetingTaskTargetDates,
                                          //                                               newMeetingTaskTargetDate,
                                          //                                             ]
                                          //                                           : [
                                          //                                               newMeetingTaskTargetDate,
                                          //                                             ],
                                          //                                     }
                                          //                                   : mt
                                          //                             )
                                          //                           : tLx
                                          //                               .taskList
                                          //                               .meetingTasks,
                                          //                     }
                                          //                   : tLx.taskList,
                                          //               }
                                          //             : tLx
                                          //       )
                                          //     : workOrders.current.taskLists,
                                          // });
                                        }
                                      }}
                                    />
                                  </td>
                                  <td className="border border-dark">
                                    {tL.taskList?.meetingTasks?.[0]?.status ===
                                    "OUTSTANDING" ? (
                                      <>
                                        <button
                                          className="btn btn-sm btn-success"
                                          onClick={() => {
                                            workOrders.current.taskLists =
                                              workOrders.current.taskLists
                                                ? workOrders.current.taskLists.map(
                                                    (tLx) =>
                                                      tLx &&
                                                      tLx.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid ===
                                                        tL.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...tLx,
                                                            taskList:
                                                              tLx.taskList
                                                                ? {
                                                                    ...tLx.taskList,
                                                                    meetingTasks:
                                                                      tLx.taskList.meetingTasks.map(
                                                                        (
                                                                          mt
                                                                        ) => ({
                                                                          ...mt,
                                                                          status:
                                                                            "COMPLETED",
                                                                        })
                                                                      ),
                                                                  }
                                                                : tLx.taskList,
                                                          }
                                                        : tLx
                                                  )
                                                : workOrders.current.taskLists;

                                            render();
                                          }}
                                        >
                                          Complete
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="btn btn-sm btn-secondary"
                                          onClick={() => {
                                            workOrders.current.taskLists =
                                              workOrders.current.taskLists
                                                ? workOrders.current.taskLists.map(
                                                    (tLx) =>
                                                      tLx &&
                                                      tLx.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid ===
                                                        tL.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...tLx,
                                                            taskList:
                                                              tLx.taskList
                                                                ? {
                                                                    ...tLx.taskList,
                                                                    meetingTasks:
                                                                      tLx.taskList.meetingTasks.map(
                                                                        (
                                                                          mt
                                                                        ) => ({
                                                                          ...mt,
                                                                          status:
                                                                            "OUTSTANDING",
                                                                        })
                                                                      ),
                                                                  }
                                                                : tLx.taskList,
                                                          }
                                                        : tLx
                                                  )
                                                : workOrders.current.taskLists;

                                            render();
                                          }}
                                        >
                                          Undo{" "}
                                        </button>
                                      </>
                                    )}
                                  </td>
                                  <td className="border border-dark">
                                    {mt.status === "COMPLETED" ? (
                                      <>
                                        {" "}
                                        <input
                                          type="date"
                                          className="form-control form-control-sm"
                                          defaultValue={mt.completedDate}
                                          onBlur={(e) => {
                                            workOrders.current.taskLists =
                                              workOrders.current.taskLists
                                                ? workOrders.current.taskLists.map(
                                                    (tLx) =>
                                                      tLx &&
                                                      tLx.taskList
                                                        ?.masterJavaBaseModel
                                                        ?.uuid ===
                                                        tL.taskList
                                                          ?.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...tLx,
                                                            taskList:
                                                              tLx.taskList
                                                                ? {
                                                                    ...tLx.taskList,
                                                                    meetingTasks:
                                                                      tLx.taskList.meetingTasks.map(
                                                                        (
                                                                          mt
                                                                        ) => ({
                                                                          ...mt,
                                                                          completedDate:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        })
                                                                      ),
                                                                  }
                                                                : tLx.taskList,
                                                          }
                                                        : tLx
                                                  )
                                                : workOrders.current.taskLists;

                                            render();
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              );
                            });
                          })
                          .flat()}
                      </table>
                    </div>
                  </div>
                  <div>
                    <strong>Program Tree Auto Assign Result</strong>
                  </div>
                  <div>
                    <TreeAutoAssignPreview type="Program" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {viewType === "Comments" ? (
            <>
              <ol>
                {state.job.job?.panelCodes?.map((pc) => {
                  const comments = workOrders?.current?.taskLists
                    .map(
                      (tL) =>
                        tL.taskList?.meetingTasks
                          .map(
                            (mt) =>
                              mt.meetingTaskComments.map((mtc) => ({
                                meetingTask: mt,
                                taskList: tL,
                                comment: mtc,
                              })) ?? []
                          )
                          .flat() ?? []
                    )
                    .flat()
                    .filter(
                      (c) =>
                        `${c.taskList.taskList?.extPanelCodeId}` === `${pc.id}`
                    );

                  comments?.sort(
                    (a, b) =>
                      b.comment.masterJavaBaseModel?.createdAt?.localeCompare(
                        a.comment.masterJavaBaseModel?.createdAt ?? ""
                      ) ?? 0
                  );

                  return (
                    <>
                      <div>
                        <div className="d-flex ">
                          <div className="d-flex">
                            <div>
                              {pc.type}: {pc.name}: {pc.qty ?? 0} unit(s)
                            </div>
                            <div
                              className={`font-weight-bold border border-dark rounded rounded-lg px-2 py-1 mx-1 ${
                                (comments?.length ?? 0) > 0 ? "text-info" : ""
                              }`}
                            >
                              {comments?.length ?? 0} comment(s)
                            </div>{" "}
                            <div>
                              <div className="mx-2">
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={() => {
                                    if (
                                      expandedPanelCodeComments.find(
                                        (c) => `${c}` === `${pc.uuid}`
                                      )
                                    ) {
                                      setexpandedPanelCodeComments(
                                        expandedPanelCodeComments.filter(
                                          (c) => `${c}` !== `${pc.uuid}`
                                        )
                                      );
                                    } else {
                                      setexpandedPanelCodeComments([
                                        ...expandedPanelCodeComments,
                                        pc.uuid ?? "",
                                      ]);
                                    }
                                  }}
                                >
                                  Expand/Collapse
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {expandedPanelCodeComments.find(
                          (c) => `${c}` === `${pc.uuid}`
                        ) ? (
                          <>
                            {(() => {
                              return (comments?.length ?? 0) > 0 ? (
                                <>
                                  <div
                                    className="border border-dark overflow-auto"
                                    style={{
                                      height: "30vh",
                                      resize: "vertical",
                                    }}
                                  >
                                    <table
                                      className="table table-sm"
                                      style={{ borderCollapse: "separate" }}
                                    >
                                      <tr>
                                        {[
                                          "#",
                                          "Date/Time",
                                          "Panel Code",
                                          "User",
                                          "Comment",
                                          "Additional Info",
                                        ].map((h) => (
                                          <th className="bg-dark text-light">
                                            {h}
                                          </th>
                                        ))}
                                      </tr>
                                      {comments?.map((mtc, i) => {
                                        const foundUser = ctx?.extUsers.find(
                                          (u) =>
                                            `${u.id}` ===
                                            `${mtc.comment.extUserId}`
                                        );

                                        const foundPanelCode =
                                          state.job.job?.panelCodes?.find(
                                            (pc) =>
                                              `${pc.id}` ===
                                              `${mtc.taskList.taskList?.extPanelCodeId}`
                                          );

                                        console.log(
                                          "[commend]",
                                          mtc.taskList.taskList
                                        );

                                        return (
                                          <>
                                            <tr>
                                              <td className="border border-dark">
                                                {i + 1}
                                              </td>
                                              <td className="border border-dark">
                                                {" "}
                                                {(() => {
                                                  try {
                                                    return (
                                                      <>
                                                        <div>
                                                          {Intl.DateTimeFormat(
                                                            navigator.language ??
                                                              "en-US",
                                                            {
                                                              dateStyle:
                                                                "short",
                                                              timeStyle:
                                                                "medium",
                                                              hourCycle: "h23",
                                                            } as any
                                                          ).format(
                                                            new Date(
                                                              `${mtc.comment.masterJavaBaseModel?.createdAt}`
                                                            )
                                                          )}
                                                        </div>
                                                      </>
                                                    );
                                                  } catch (e) {
                                                    return <>Invalid Date</>;
                                                  }
                                                })()}
                                              </td>
                                              <td className="border border-dark">
                                                <div>
                                                  {foundPanelCode ? (
                                                    <>
                                                      <div>
                                                        {foundPanelCode.type}:{" "}
                                                        {foundPanelCode.name} (
                                                        {foundPanelCode.qty}{" "}
                                                        unit(s))
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </td>
                                              <td className="border border-dark">
                                                <div>
                                                  {foundUser?.name} (
                                                  {foundUser?.departmentName})
                                                </div>
                                              </td>
                                              <td className="border border-dark">
                                                <div style={{ width: "45vw" }}>
                                                  <pre>
                                                    <strong>
                                                      {mtc.comment.comment}
                                                    </strong>
                                                  </pre>
                                                </div>
                                              </td>
                                              <td className="border border-dark">
                                                <div>
                                                  {mtc.meetingTask
                                                    .extSubProcessId &&
                                                  mtc.meetingTask
                                                    .extSubProcessId !== "0" ? (
                                                    <>
                                                      {(() => {
                                                        const foundDt =
                                                          departmentTemplates.find(
                                                            (dt) =>
                                                              dt.departmentTemplateItems.find(
                                                                (dti) =>
                                                                  `${dti.departmentTemplateItem?.id}` ===
                                                                  `${mtc.meetingTask.extSubProcessId}`
                                                              )
                                                          );
                                                        const foundDti =
                                                          departmentTemplates
                                                            .map(
                                                              (dt) =>
                                                                dt.departmentTemplateItems ??
                                                                []
                                                            )
                                                            .flat()
                                                            .find(
                                                              (dti) =>
                                                                `${dti.departmentTemplateItem?.id}` ===
                                                                `${mtc.meetingTask.extSubProcessId}`
                                                            );

                                                        return (
                                                          <>
                                                            {foundDt
                                                              ?.departmentTemplate
                                                              ?.name ?? ""}
                                                            :{" "}
                                                            {foundDti
                                                              ?.departmentTemplateItem
                                                              ?.name ?? ""}
                                                            {/* (
                                                                {
                                                                  mtc.taskList.taskList
                                                                    ?.extSubProcessId
                                                                }
                                                                ) */}
                                                          </>
                                                        );
                                                      })()}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {mtc.taskList.taskList
                                                    ?.extSetId &&
                                                  mtc.taskList.taskList
                                                    ?.extSetId !== "0" ? (
                                                    <>
                                                      <div>
                                                        <div>
                                                          Set:{" "}
                                                          {
                                                            integrationModules?.modules.find(
                                                              (m) =>
                                                                `${m.masterJavaBaseModel?.id}` ===
                                                                mtc.taskList
                                                                  .taskList
                                                                  ?.extSetId
                                                            )?.name
                                                          }{" "}
                                                          (
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extIntegrationModuleQtyActual
                                                          }
                                                          /
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extIntegrationModuleQty
                                                          }
                                                          )
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {mtc.taskList.taskList
                                                    ?.extModuleId &&
                                                  mtc.taskList.taskList
                                                    ?.extModuleId !== "0" ? (
                                                    <>
                                                      <div>
                                                        <div>
                                                          Module:{" "}
                                                          {
                                                            integrationModules?.modules.find(
                                                              (m) =>
                                                                `${m.masterJavaBaseModel?.id}` ===
                                                                mtc.taskList
                                                                  .taskList
                                                                  ?.extModuleId
                                                            )?.name
                                                          }{" "}
                                                          (
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extIntegrationModuleQtyActual
                                                          }
                                                          /
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extIntegrationModuleQty
                                                          }
                                                          )
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {mtc.taskList.taskList
                                                    ?.extSubModuleId &&
                                                  mtc.taskList.taskList
                                                    ?.extSubModuleId !== "0" ? (
                                                    <>
                                                      <div>
                                                        <div>
                                                          Set:{" "}
                                                          {
                                                            integrationModules?.modules.find(
                                                              (m) =>
                                                                `${m.masterJavaBaseModel?.id}` ===
                                                                mtc.taskList
                                                                  .taskList
                                                                  ?.extSubModuleId
                                                            )?.name
                                                          }{" "}
                                                          (
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extIntegrationModuleQtyActual
                                                          }
                                                          /
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extIntegrationModuleQty
                                                          }
                                                          )
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {mtc.taskList.taskList
                                                    ?.extProgramId &&
                                                  mtc.taskList.taskList
                                                    ?.extProgramId !== "0" ? (
                                                    <>
                                                      <div>
                                                        <div>
                                                          Program:{" "}
                                                          {
                                                            machinePrograms.find(
                                                              (p) =>
                                                                `${p.id}` ===
                                                                `${mtc.taskList.taskList?.extProgramId}`
                                                            )?.name
                                                          }{" "}
                                                          (
                                                          {
                                                            mtc.taskList
                                                              .taskList
                                                              .extProgramRunQty
                                                          }
                                                          )
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </table>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <strong>No comments yet.</strong>
                                </>
                              );
                            })()}
                          </>
                        ) : (
                          <></>
                        )}

                        <hr className="border border-dark" />
                      </div>
                    </>
                  );
                })}
              </ol>
            </>
          ) : (
            <></>
          )}

          {viewType === "SN Progress" ? (
            <>
              <div>
                <div>
                  <div>
                    <h4>SN Progress</h4>
                  </div>
                </div>
                <hr />
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <Select
                      options={
                        state.job.job?.panelCodes
                          ?.map(
                            (pc) =>
                              pc.panelCodeSerialNumbers
                                ?.filter((sn) => sn.id)
                                ?.map((sn) => ({
                                  panelCode: pc,
                                  serialNumber: sn,
                                })) ?? []
                          )
                          .flat() ?? []
                      }
                      getOptionLabel={(sn) =>
                        `${sn.panelCode.type} - ${sn.panelCode.name} - ${
                          sn.panelCode.qty
                        } unit(s): ${getPanelCodeCompleteSerialNumber(
                          sn.panelCode,
                          sn.serialNumber
                        )}`
                      }
                      onChange={(v) => {
                        const val = v as unknown as {
                          panelCode: PanelCode;
                          serialNumber: PanelCodeSerialNumber;
                        };

                        setState({
                          ...state,
                          job: state.job
                            ? {
                                ...state.job,
                                job: state.job.job
                                  ? {
                                      ...state.job.job,
                                      jobPanelCodeSerialNumberDones: state.job
                                        .job.jobPanelCodeSerialNumberDones
                                        ? [
                                            ...state.job.job
                                              .jobPanelCodeSerialNumberDones,
                                            {
                                              ...defaultJobPanelCodeSerialNumberDone,
                                              panelCodeSerialNumber:
                                                val.serialNumber,
                                              uuid: v4(),
                                            },
                                          ]
                                        : state.job.job
                                            .jobPanelCodeSerialNumberDones,
                                    }
                                  : state.job.job,
                              }
                            : state.job,
                        });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className="overflow-auto"
                    style={{ height: "75vh", resize: "vertical" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          "Delete",
                          "#",
                          "Panel code",
                          "SN",
                          "Completed date",
                        ].map((h) => (
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        ))}
                      </tr>
                      {state.job.job?.jobPanelCodeSerialNumberDones?.map(
                        (d, i) => {
                          const foundPanelCode =
                            state.job.job?.panelCodes?.find((pc) =>
                              pc.panelCodeSerialNumbers?.find(
                                (sn) => sn.id === d.panelCodeSerialNumber?.id
                              )
                            );
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={async () => {
                                      setJobPanelCodeSerialNumberDoneDeleteIds([
                                        ...jobPanelCodeSerialNumberDoneDeleteIds,
                                        d.id ?? 0,
                                      ]);
                                      setState({
                                        ...state,
                                        job: state.job
                                          ? {
                                              ...state.job,
                                              job: state.job.job
                                                ? {
                                                    ...state.job.job,
                                                    jobPanelCodeSerialNumberDones:
                                                      state.job.job
                                                        .jobPanelCodeSerialNumberDones
                                                        ? state.job.job.jobPanelCodeSerialNumberDones.filter(
                                                            (_, ix) => i !== ix
                                                          )
                                                        : state.job.job
                                                            .jobPanelCodeSerialNumberDones,
                                                  }
                                                : state.job.job,
                                            }
                                          : state.job,
                                      });
                                    }}
                                  >
                                    <Delete />
                                  </button>
                                </td>

                                <td className="border border-dark">{i + 1}</td>
                                <td className="border border-dark">
                                  {foundPanelCode?.type} -{" "}
                                  {foundPanelCode?.name} - {foundPanelCode?.qty}{" "}
                                  unit(s):{" "}
                                </td>
                                <td className="border border-dark">
                                  {getPanelCodeCompleteSerialNumber(
                                    foundPanelCode,
                                    d.panelCodeSerialNumber
                                  )}
                                </td>
                                <td className="border border-dark">
                                  <input
                                    type="date"
                                    defaultValue={
                                      d.completedDate
                                        ? makeDateString(
                                            new Date(d.completedDate)
                                          )
                                        : d.completedDate ?? ""
                                    }
                                    className="form-control form-control-sm"
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setState({
                                          ...state,
                                          job: state.job
                                            ? {
                                                ...state.job,
                                                job: state.job.job
                                                  ? {
                                                      ...state.job.job,
                                                      jobPanelCodeSerialNumberDones:
                                                        state.job.job
                                                          .jobPanelCodeSerialNumberDones
                                                          ? state.job.job.jobPanelCodeSerialNumberDones.map(
                                                              (dx, ix) =>
                                                                ix === i && dx
                                                                  ? {
                                                                      ...dx,
                                                                      completedDate: `${e.target.value}T00:00:00Z`,
                                                                    }
                                                                  : dx
                                                            )
                                                          : state.job.job
                                                              .jobPanelCodeSerialNumberDones,
                                                    }
                                                  : state.job.job,
                                              }
                                            : state.job,
                                        });
                                      }
                                    }}
                                    // style={{ width: 75 }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <Modal
          show={v3TaskListsDialogDetail.current.taskLists.length > 0}
          onHide={() => {
            v3TaskListsDialogDetail.current = MeetingTaskListsView.fromPartial(
              {}
            );
            render();
          }}
          size="xl"
        >
          <ModalHeader>
            <h5>Detail</h5>
          </ModalHeader>
          <ModalBody>
            <div>
              <ol>
                {v3TaskListsDialogDetail.current.taskLists.map((tL) => {
                  return (
                    <>
                      {/* <div
                        className="border border-dark overflow-auto"
                        style={{ height: "30vh", resize: "vertical" }}
                      >
                        {JSON.stringify(tL, null, 2)}
                      </div> */}

                      <TaskListInfo
                        customers={ctx?.extCustomers}
                        crmCustomers={ctx?.extCrmCustomers}
                        tL={tL}
                        extUsers={ctx?.extUsers ?? []}
                        jobsProto={
                          jobsProtoSimple ?? PpicJobs.fromPartial({ jobs: [] })
                        }
                        machines={machinesDb}
                        processTypes={
                          processTypes ??
                          PpicSimplifiedProcessTypes.fromPartial({
                            processTypes: [],
                          })
                        }
                        rcemDepartments={rcemDepartments}
                        integrationModules={integrationModules}
                        machinePrograms={machineProgramsProto}
                        departmentTemplates={departmentTemplatesProto}
                        panelCodeDepartmentTemplateItems={
                          panelCodeDepartmentTemplateItemsProto.current
                        }
                      />
                    </>
                  );
                })}
              </ol>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>

        <Modal
          show={showCreateWoBulkDialog}
          onHide={() => {
            setShowCreateWoBulkDialog(false);
          }}
          size="xl"
        >
          {(() => {
            return (
              <>
                {" "}
                <Modal.Header closeButton>
                  <h4>Create Bulk Work Order</h4>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="w-100 overflow-auto"
                    style={{ height: "50vh", resize: "vertical" }}
                  >
                    WOs: {createWoBulkWorkOrders.length}
                    <div>
                      <Select
                        placeholder="Select PiC..."
                        options={ctx?.extUsers.map((u) => ({
                          label: `${u.name} (${u.departmentName})`,
                          value: u,
                        }))}
                        onChange={(v) => {
                          const user = (
                            v as unknown as {
                              value?: ExtUser | null;
                            }
                          ).value;

                          if (createWoBulkWorkOrders) {
                            const newMeetingTaskInCharge =
                              MeetingMeetingTaskInCharge.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: uuidv4(),
                                  }),
                                extUserId: user?.id ? `${user.id}` : undefined,
                              });

                            setCreateWoBulkWorkOrders(
                              createWoBulkWorkOrders.map((tL) =>
                                tL
                                  ? {
                                      ...tL,
                                      meetingTasks: tL?.meetingTasks
                                        ? tL.meetingTasks.map((mt) =>
                                            mt
                                              ? {
                                                  ...mt,
                                                  meetingTaskInCharges:
                                                    mt.meetingTaskInCharges
                                                      ? [
                                                          ...mt.meetingTaskInCharges,
                                                          newMeetingTaskInCharge,
                                                        ]
                                                      : [
                                                          newMeetingTaskInCharge,
                                                        ],
                                                }
                                              : mt
                                          )
                                        : tL?.meetingTasks,
                                    }
                                  : tL
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Select
                        placeholder="Needs skill..."
                        options={skills.skills ?? []}
                        getOptionLabel={(s) => `${s.name}`}
                        onChange={(v) => {
                          const val = v as unknown as PpicSkill;

                          if (createWoBulkWorkOrders) {
                            const newMeetingTaskInCharges =
                              val.skillWorkers.map((sw) => {
                                return MeetingMeetingTaskInCharge.fromPartial({
                                  masterJavaBaseModel:
                                    MasterJavaBaseModel.fromPartial({
                                      uuid: uuidv4(),
                                    }),
                                  extUserId: sw.extUserId,
                                });
                              });
                            setCreateWoBulkWorkOrders(
                              createWoBulkWorkOrders.map((tL) =>
                                tL
                                  ? {
                                      ...tL,
                                      meetingTasks: tL?.meetingTasks
                                        ? tL.meetingTasks.map((mt) =>
                                            mt
                                              ? {
                                                  ...mt,
                                                  meetingTaskInCharges:
                                                    mt.meetingTaskInCharges
                                                      ? [
                                                          ...mt.meetingTaskInCharges,
                                                          ...newMeetingTaskInCharges,
                                                        ]
                                                      : [
                                                          ...newMeetingTaskInCharges,
                                                        ],
                                                }
                                              : mt
                                          )
                                        : tL?.meetingTasks,
                                    }
                                  : tL
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Select
                        placeholder="Select SPV..."
                        options={ctx?.extUsers}
                        getOptionLabel={(u) =>
                          `${u.name ?? "No name"} (${
                            u.departmentName ?? "No dept"
                          })`
                        }
                        onChange={(v) => {
                          const val = v as unknown as ExtUser;

                          if (createWoBulkWorkOrders) {
                            setCreateWoBulkWorkOrders(
                              createWoBulkWorkOrders.map((tLx, ix) =>
                                tLx
                                  ? {
                                      ...tLx,
                                      extInChargeId:
                                        val.id && val.id !== 0
                                          ? `${val.id}`
                                          : tLx.extInChargeId,
                                    }
                                  : tLx
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <Select
                        placeholder="Select tree module..."
                        options={
                          createWoBulkIntegrationList
                            ? flattenModules([createWoBulkIntegrationList])
                            : []
                        }
                        getOptionLabel={(m) =>
                          `${m.module.integrationModule?.type}: ${m.module.integrationModule?.name}`
                        }
                        onChange={(v) => {
                          const val = (
                            v as unknown as {
                              integration: Integration | null;
                              module: IntegrationModuleView;
                            }
                          ).module;
                          setSelectedIntegrationModule(val);

                          if (state.job.job) {
                            setCreateWoBulkWorkOrders(
                              mapIntegrationModulesToWorkOrders(
                                createWoBulkIntegrationList ?? null,
                                (val.integrationModules?.length ?? 0) === 0
                                  ? [val]
                                  : val.integrationModules ?? [],
                                state.job.job,
                                selectedPanelCode
                              )
                            );
                          }
                        }}
                      />
                    </div>
                    <div>
                      <div>
                        <input
                          type="date"
                          placeholder="WO Date..."
                          className="form-control form-control-sm"
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              setCreateWoBulkWorkOrders(
                                createWoBulkWorkOrders.map((tL) =>
                                  tL
                                    ? {
                                        ...tL,
                                        meetingTasks: tL?.meetingTasks
                                          ? tL.meetingTasks.map((mt) =>
                                              mt
                                                ? {
                                                    ...mt,
                                                    start: e.target.value,
                                                    meetingTaskTargetDates: [
                                                      ...mt.meetingTaskTargetDates,
                                                      MeetingMeetingTaskTargetDate.fromPartial(
                                                        {
                                                          date: e.target.value,
                                                          masterJavaBaseModel:
                                                            MasterJavaBaseModel.fromPartial(
                                                              { uuid: v4() }
                                                            ),
                                                        }
                                                      ),
                                                    ],
                                                  }
                                                : mt
                                            )
                                          : tL?.meetingTasks,
                                      }
                                    : tL
                                )
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                    <table
                      className="table table-sm table-hover table-bordered"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          <>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (excludedTaskListModuleIds.length > 0) {
                                  setExcludedTaskListModuleIds([]);
                                } else {
                                  setExcludedTaskListModuleIds(
                                    createWoBulkWorkOrders.map((tL) => {
                                      if (tL.extSetId && tL.extSetId !== "0") {
                                        return tL.extSetId;
                                      }
                                      if (
                                        tL.extModuleId &&
                                        tL.extModuleId !== "0"
                                      ) {
                                        return tL.extModuleId;
                                      }
                                      if (
                                        tL.extSubModuleId &&
                                        tL.extSubModuleId !== "0"
                                      ) {
                                        return tL.extSubModuleId;
                                      }

                                      return "";
                                    })
                                  );
                                }
                              }}
                            >
                              Included{" "}
                              <input
                                type="checkbox"
                                checked={
                                  !excludedTaskListModuleIds.find((id) =>
                                    createWoBulkWorkOrders.find(
                                      (tL) =>
                                        `${tL.extSetId}` === `${id}` ||
                                        `${tL.extModuleId}` === `${id}` ||
                                        `${tL.extSubModuleId}` === `${id}`
                                    )
                                  )
                                }
                              />
                            </div>
                          </>,
                          "#",
                          "WO",
                          "SPV",
                          "PIC",
                          "Module",
                          "WO Date",
                          "Qty",
                        ].map((h) => (
                          <th className="border border-dark">{h}</th>
                        ))}
                      </tr>
                      {createWoBulkWorkOrders.map((tL, i) => {
                        const foundSpv = ctx?.extUsers.find(
                          (u) => `${u.id}` === `${tL.extInChargeId}`
                        );
                        return (
                          <>
                            <tr>
                              <td
                                className="border border-dark"
                                onClick={() => {
                                  const idToSend = (() => {
                                    if (tL.extSetId && tL.extSetId !== "0") {
                                      return tL.extSetId;
                                    }

                                    if (
                                      tL.extModuleId &&
                                      tL.extModuleId !== "0"
                                    ) {
                                      return tL.extModuleId;
                                    }

                                    if (
                                      tL.extSubModuleId &&
                                      tL.extSubModuleId !== "0"
                                    ) {
                                      return tL.extSubModuleId;
                                    }

                                    return "";
                                  })();

                                  if (
                                    excludedTaskListModuleIds.find(
                                      (id) => id === idToSend
                                    )
                                  ) {
                                    setExcludedTaskListModuleIds(
                                      excludedTaskListModuleIds.filter(
                                        (id) => id !== idToSend
                                      )
                                    );
                                  } else {
                                    setExcludedTaskListModuleIds([
                                      ...excludedTaskListModuleIds,
                                      idToSend ?? "",
                                    ]);
                                  }
                                }}
                              >
                                <div>
                                  <input
                                    type="checkbox"
                                    checked={
                                      !excludedTaskListModuleIds.find(
                                        (id) =>
                                          `${tL.extSetId}` === `${id}` ||
                                          `${tL.extModuleId}` === `${id}` ||
                                          `${tL.extSubModuleId}` === `${id}`
                                      )
                                    }
                                  />
                                </div>
                              </td>

                              <td className="border border-dark">{i + 1}</td>
                              <td className="border border-dark">
                                {tL?.meetingTasks
                                  ?.map((mt) => mt.description)
                                  .flat()
                                  .join(", ")}
                              </td>
                              <td className="border border-dark">
                                <div>
                                  <div>
                                    {" "}
                                    <small>{foundSpv?.username}</small>
                                  </div>
                                  <div>
                                    <small>{foundSpv?.departmentName}</small>{" "}
                                  </div>
                                </div>
                              </td>
                              <td className="border border-dark">
                                <ol>
                                  {tL.meetingTasks
                                    .map((mt) => mt?.meetingTaskInCharges ?? [])
                                    .flat()
                                    .map((mtic) => {
                                      const foundUser = ctx?.extUsers.find(
                                        (u) => `${u.id}` === mtic.extUserId
                                      );
                                      return (
                                        <>
                                          <li>
                                            <div className="d-flex">
                                              {foundUser?.username} (
                                              {foundUser?.departmentName})
                                              {mtic.isQc ? (
                                                <>
                                                  <div className="border border-dark rounded rounded-md">
                                                    <small>
                                                      <strong>QC</strong>
                                                    </small>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {mtic.isQa ? (
                                                <>
                                                  <div className="border border-dark rounded rounded-md">
                                                    <small>
                                                      <strong>QA</strong>
                                                    </small>
                                                  </div>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                </ol>
                              </td>
                              <td className="border border-dark">
                                {(() => {
                                  if (tL.extSetId && tL.extSetId !== "0") {
                                    return (
                                      <div>
                                        SET:{" "}
                                        {
                                          modulesFlattened.find(
                                            (m) =>
                                              `${m.module.integrationModule?.id}` ===
                                              tL.extSetId
                                          )?.module.integrationModule?.name
                                        }
                                      </div>
                                    );
                                  } else if (
                                    tL.extModuleId &&
                                    tL.extModuleId !== "0"
                                  ) {
                                    return (
                                      <div>
                                        MODULE:{" "}
                                        {
                                          modulesFlattened.find(
                                            (m) =>
                                              `${m.module.integrationModule?.id}` ===
                                              tL.extModuleId
                                          )?.module?.integrationModule?.name
                                        }
                                      </div>
                                    );
                                  } else if (
                                    tL.extSubModuleId &&
                                    tL.extSubModuleId !== "0"
                                  ) {
                                    return (
                                      <div>
                                        SUBMODULE:{" "}
                                        {
                                          modulesFlattened.find(
                                            (m) =>
                                              `${m.module.integrationModule?.id}` ===
                                              tL.extSubModuleId
                                          )?.module.integrationModule?.name
                                        }
                                      </div>
                                    );
                                  } else {
                                  }
                                })()}
                              </td>
                              <td className="border border-dark">
                                {/* {tL.meetingTasks?.[0]?.start} */}
                                <input
                                  type="date"
                                  className="form-control form-control-sm"
                                  defaultValue={tL.meetingTasks?.[0]?.start}
                                  key={`extIntegrationModuleQtyDate-${i}-${tL.masterJavaBaseModel?.uuid}`}
                                  onBlur={(e) => {
                                    if (
                                      createWoBulkWorkOrders &&
                                      e.target.value !== ""
                                    ) {
                                      setCreateWoBulkWorkOrders(
                                        createWoBulkWorkOrders.map((tLx, ix) =>
                                          tLx && ix === i
                                            ? {
                                                ...tLx,
                                                meetingTasks: tLx.meetingTasks
                                                  ? tLx.meetingTasks.map((mt) =>
                                                      mt
                                                        ? {
                                                            ...mt,
                                                            start:
                                                              e.target.value,
                                                            meetingTaskTargetDates:
                                                              mt.meetingTaskTargetDates
                                                                ? [
                                                                    ...mt.meetingTaskTargetDates,
                                                                    MeetingMeetingTaskTargetDate.fromPartial(
                                                                      {
                                                                        date: e
                                                                          .target
                                                                          .value,
                                                                        masterJavaBaseModel:
                                                                          MasterJavaBaseModel.fromPartial(
                                                                            {
                                                                              uuid: v4(),
                                                                            }
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ]
                                                                : mt.meetingTaskTargetDates,
                                                          }
                                                        : mt
                                                    )
                                                  : tLx.meetingTasks,
                                              }
                                            : tLx
                                        )
                                      );
                                    }
                                  }}
                                />
                              </td>
                              <td className="border border-dark">
                                <input
                                  placeholder="Qty..."
                                  className="form-control form-control-sm"
                                  style={{ maxWidth: 50 }}
                                  defaultValue={tL.extIntegrationModuleQty}
                                  key={`extIntegrationModuleQty-${i}-${tL.masterJavaBaseModel?.uuid}`}
                                  onBlur={(e) => {
                                    if (createWoBulkWorkOrders) {
                                      setCreateWoBulkWorkOrders(
                                        createWoBulkWorkOrders.map((tLx, ix) =>
                                          tLx && ix === i
                                            ? {
                                                ...tLx,
                                                extIntegrationModuleQty: isNaN(
                                                  parseInt(e.target.value)
                                                )
                                                  ? tLx.extIntegrationModuleQty
                                                  : parseInt(e.target.value),
                                              }
                                            : tLx
                                        )
                                      );
                                    }
                                  }}
                                />
                                {/* {tL.extIntegrationModuleQty} */}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setShowCreateWoBulkDialog(false);
                        if (workOrders) {
                          const newWorkOrders = createWoBulkWorkOrders
                            .filter(
                              (tL) =>
                                // Filter task list by module
                                !excludedTaskListModuleIds.find(
                                  (id) =>
                                    tL.extSetId === id ||
                                    tL.extModuleId === id ||
                                    tL.extSubModuleId === id
                                )
                            )
                            .map((tL) =>
                              MeetingTaskListView.fromPartial({
                                taskList: tL,
                              })
                            );

                          workOrders.current.taskLists = workOrders.current
                            .taskLists
                            ? [
                                ...workOrders.current.taskLists,
                                ...newWorkOrders,
                              ]
                            : newWorkOrders;

                          render();
                          // setWorkOrders({
                          //   ...workOrders,
                          //   taskLists: workOrders.current.taskLists
                          //     ? [...workOrders.current.taskLists, ...newWorkOrders]
                          //     : newWorkOrders,
                          // });
                        }
                      }}
                    >
                      Create WO
                    </button>
                  </div>
                </Modal.Footer>
              </>
            );
          })()}
        </Modal>

        <Modal
          show={showWorkOrderOpen}
          onHide={() => {
            setShowWorkOrderOpen(false);
          }}
          size="xl"
        >
          {(() => {
            const filteredPanelCodeDtis = state.job.job?.panelCodes
              ?.find((pc) => pc.id === selectedPanelCode?.id)
              ?.panelCodeDepartmentTemplateItems?.filter((dti) =>
                departmentTemplates
                  .find(
                    (dt) =>
                      dt.departmentTemplate?.id ===
                      selectedPanelCodeDepartmentTemplate?.departmentTemplate
                        ?.id
                  )
                  ?.departmentTemplateItems?.find(
                    (dtix) =>
                      dtix.departmentTemplateItem?.id ===
                      dti.departmentTemplateItem?.id
                  )
              );
            // .flat();
            //     ?.map((dti) => [dti.startDate, dti.endDate])
            //     .flat()
            //     .filter((dti) => dti)
            //     .map((dti) => dti as string) ?? [];

            // filteredPanelCodeDtis?.sort(
            //   (a, b) =>
            //     (() => {
            //       try {
            //         return new Date(a).getTime();
            //       } catch (e) {
            //         return 0;
            //       }
            //     })() -
            //     (() => {
            //       try {
            //         return new Date(b).getTime();
            //       } catch (e) {
            //         return 0;
            //       }
            //     })()
            // );

            // const startDateStr = (() => {
            //   try {
            //     return (filteredPanelCodeDtis?.length ?? 0) > 0
            //       ? makeReadableDateStringIntlUtc(
            //           new Date(filteredPanelCodeDtis[0] ?? "")
            //         )
            //       : undefined;
            //   } catch (e) {
            //     return undefined;
            //   }
            // })();

            // const endDateStr = (() => {
            //   try {
            //     return (filteredPanelCodeDtis?.length ?? 0) > 0
            //       ? makeReadableDateStringIntlUtc(
            //           new Date(
            //             filteredPanelCodeDtis[
            //               filteredPanelCodeDtis.length - 1
            //             ] ?? ""
            //           )
            //         )
            //       : undefined;
            //   } catch (e) {
            //     return undefined;
            //   }
            // })();

            let selectedWorkOrder = workOrders?.current?.taskLists.find((tL) =>
              tL.taskList?.meetingTasks.find((mt) =>
                filteredPanelCodeDtis?.find(
                  (pcdti) =>
                    `${pcdti.id}` ===
                    `${mt.extPanelCodeDepartmentTemplateItemId}`
                )
              )
            );

            return (
              <>
                {" "}
                <Modal.Header closeButton>
                  <h4>
                    Work Order for{" "}
                    {
                      selectedPanelCodeDepartmentTemplate?.departmentTemplate
                        ?.name
                    }
                    , {selectedPanelCode?.name} - {selectedPanelCode?.type}
                  </h4>
                </Modal.Header>
                <Modal.Body>
                  {/* <div>
              For sub process:{" "}
              {
                selectedPanelCodeDepartmentTemplateItem?.departmentTemplateItem
                  ?.name
              }
            </div> */}
                  <div>For job: {state.job.job?.name}</div>
                  {/* {(() => {
                    if (filteredPanelCodeDtis) {
                      return (
                        <>
                          <div>
                            Start:{" "}
                            {(() => {
                              try {
                                return (filteredPanelCodeDtis?.length ?? 0) > 0
                                  ? makeReadableDateStringIntlUtc(
                                      new Date(filteredPanelCodeDtis[0] ?? "")
                                    )
                                  : "";
                              } catch (e) {
                                return <></>;
                              }
                            })()}
                          </div>
                          <div>
                            End:{" "}
                            {(() => {
                              try {
                                return (filteredPanelCodeDtis?.length ?? 0) > 0
                                  ? makeReadableDateStringIntlUtc(
                                      new Date(
                                        filteredPanelCodeDtis[
                                          filteredPanelCodeDtis.length - 1
                                        ] ?? ""
                                      )
                                    )
                                  : "";
                              } catch (e) {
                                return <></>;
                              }
                            })()}
                          </div>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  })()} */}

                  {/* <div>RCEM Sub Process:</div> */}
                  {/* <div className="flex-grow-1 mx-2" style={{ width: 300 }}>
                    {selectedWorkOrder?.taskList?.extRcemSubProcessId ? (
                      <>
                        {(() => {
                          const foundRcemSubProcess =
                            flattenedSubProcesses?.find(
                              (sp) =>
                                sp.subProcess.masterJavaBaseModel?.id ===
                                selectedWorkOrder.taskList?.extRcemSubProcessId
                            );

                          return (
                            <>
                              <div>
                                <strong>
                                  {foundRcemSubProcess
                                    ? `${foundRcemSubProcess.dept.name}: ${foundRcemSubProcess.process.name} - ${foundRcemSubProcess.subProcess.name}`
                                    : "No RCEM Sub Process"}
                                </strong>
                              </div>
                              <div>
                                <button
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => {
                                    if (selectedWorkOrder) {
                                      setSelectedWorkOrder({
                                        ...selectedWorkOrder,
                                        taskList: selectedWorkOrder.taskList
                                          ? {
                                              ...selectedWorkOrder.taskList,
                                              extRcemSubProcessId: undefined,
                                            }
                                          : selectedWorkOrder.taskList,
                                      });
                                    }
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          );
                        })()}
                      </>
                    ) : (
                      <>
                        <Select
                          options={flattenedSubProcesses?.map((sp) => ({
                            label: `${sp.dept.name}: ${sp.process.name} - ${sp.subProcess.name}`,
                            value: sp,
                          }))}
                          onChange={(v) => {
                            const val = (
                              v as unknown as {
                                value?: {
                                  subProcess?: PpicRcemSubProcess;
                                };
                              }
                            )?.value;

                            if (selectedWorkOrder) {
                              setSelectedWorkOrder({
                                ...selectedWorkOrder,
                                taskList: selectedWorkOrder.taskList
                                  ? {
                                      ...selectedWorkOrder.taskList,
                                      extRcemSubProcessId:
                                        val?.subProcess?.masterJavaBaseModel
                                          ?.id,
                                    }
                                  : selectedWorkOrder.taskList,
                              });
                            }
                          }}
                        />
                      </>
                    )}
                  </div> */}
                  <div>
                    Panel: {selectedPanelCode?.type}: {selectedPanelCode?.name}{" "}
                    - {selectedPanelCode?.qty} Unit(s)
                  </div>
                  <div>Job: {state.job?.job?.name}</div>
                  <div>
                    {selectedWorkOrder?.taskList?.meetingTasks.find(
                      (mt) => `${mt.status}` === "COMPLETED"
                    ) ? (
                      <>
                        <div className="font-weight-bold text-success">
                          Completed
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="font-weight-bold text-danger">
                          Incomplete
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        if (!selectedWorkOrder) {
                          selectedWorkOrder = MeetingTaskListView.fromPartial({
                            taskList: MeetingTaskList.fromPartial({
                              extInChargeId: selectedPanelCodeDepartmentTemplate
                                ?.departmentTemplate?.extHeadId
                                ? `${selectedPanelCodeDepartmentTemplate?.departmentTemplate?.extHeadId}`
                                : undefined,
                              extJobId: state.job.job?.id
                                ? `${state.job.job?.id}`
                                : undefined,
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({ uuid: v4() }),
                            }),
                          });
                        }

                        filteredPanelCodeDtis?.forEach((pcdti) => {
                          if (
                            !selectedWorkOrder?.taskList?.meetingTasks.find(
                              (mt) =>
                                `${mt.extPanelCodeDepartmentTemplateItemId}` ===
                                `${pcdti.id}`
                            )
                          ) {
                            selectedWorkOrder?.taskList?.meetingTasks.push(
                              MeetingMeetingTask.fromPartial({
                                // description:
                                description:
                                  pcdti.departmentTemplateItem?.name ?? "",
                                extPanelCodeDepartmentTemplateItemId: pcdti.id
                                  ? `${pcdti.id}`
                                  : undefined,
                                start:
                                  pcdti.startDate?.split("T")?.[0] ?? undefined,
                                meetingTaskTargetDates: [
                                  MeetingMeetingTaskTargetDate.fromPartial({
                                    date:
                                      pcdti.endDate?.split("T")?.[0] ??
                                      undefined,
                                    masterJavaBaseModel:
                                      MasterJavaBaseModel.fromPartial({
                                        uuid: v4(),
                                      }),
                                  }),
                                ],
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                              })
                            );
                          }
                        });

                        if (
                          workOrders?.current?.taskLists.find(
                            (tL) =>
                              tL.taskList?.masterJavaBaseModel?.uuid ===
                              selectedWorkOrder?.taskList?.masterJavaBaseModel
                                ?.uuid
                          )
                        ) {
                          workOrders.current.taskLists =
                            workOrders.current.taskLists.map((tL) =>
                              tL.taskList?.masterJavaBaseModel?.uuid ===
                              selectedWorkOrder?.taskList?.masterJavaBaseModel
                                ?.uuid
                                ? selectedWorkOrder
                                  ? selectedWorkOrder
                                  : MeetingTaskListView.fromPartial({
                                      taskList: MeetingTaskList.fromPartial({
                                        masterJavaBaseModel:
                                          MasterJavaBaseModel.fromPartial({
                                            uuid: v4(),
                                          }),
                                      }),
                                    })
                                : tL
                            );

                          render();
                          // setWorkOrders({
                          //   ...workOrders,
                          //   taskLists: workOrders.current.taskLists.map((tL) =>
                          //     tL.taskList?.masterJavaBaseModel?.uuid ===
                          //     selectedWorkOrder?.taskList?.masterJavaBaseModel
                          //       ?.uuid
                          //       ? selectedWorkOrder
                          //         ? selectedWorkOrder
                          //         : MeetingTaskListView.fromPartial({
                          //             taskList: MeetingTaskList.fromPartial({
                          //               masterJavaBaseModel:
                          //                 MasterJavaBaseModel.fromPartial({
                          //                   uuid: v4(),
                          //                 }),
                          //             }),
                          //           })
                          //       : tL
                          //   ),
                          // });
                        } else {
                          workOrders.current.taskLists = [
                            ...(workOrders?.current?.taskLists ?? []),
                            selectedWorkOrder,
                          ];

                          render();
                          // setWorkOrders({
                          //   ...workOrders,
                          //   taskLists: [
                          //     ...(workOrders?.current?.taskLists ?? []),
                          //     selectedWorkOrder,
                          //   ],
                          // });
                        }

                        // if (
                        //   (selectedWorkOrder?.taskList?.meetingTasks?.length ??
                        //     0) > 0
                        // ) {
                        //   alert("WO tasks already exist! Please add manually.");
                        //   return;
                        // }
                        // setWorkOrders(
                        //   workOrders
                        //     ? {
                        //         ...workOrders,
                        //         taskLists:
                        //         taskList: selectedWorkOrder.taskList
                        //           ? {
                        //               ...selectedWorkOrder.taskList,
                        //               start: startDateStr,
                        //               deadline: endDateStr,
                        //               extJobId: state.job.job?.id
                        //                 ? `${state.job.job?.id}`
                        //                 : undefined,
                        //               masterJavaBaseModel: {
                        //                 uuid: selectedWorkOrder.taskList
                        //                   .masterJavaBaseModel?.uuid
                        //                   ? selectedWorkOrder.taskList
                        //                       .masterJavaBaseModel?.uuid
                        //                   : uuidv4(),
                        //               },
                        //               meetingTasks:
                        //                 departmentTemplates
                        //                   .find(
                        //                     (dt) =>
                        //                       dt.departmentTemplate?.id ===
                        //                       selectedPanelCodeDepartmentTemplate
                        //                         ?.departmentTemplate?.id
                        //                   )
                        //                   ?.departmentTemplateItems.map(
                        //                     (dti) => {
                        //                       const foundPanelCodeDti =
                        //                         state.job.job?.panelCodes
                        //                           ?.find(
                        //                             (pc) =>
                        //                               pc.id ===
                        //                               selectedPanelCode?.id
                        //                           )
                        //                           ?.panelCodeDepartmentTemplateItems?.find(
                        //                             (dtix) =>
                        //                               dtix
                        //                                 .departmentTemplateItem
                        //                                 ?.id ===
                        //                               dti.departmentTemplateItem
                        //                                 ?.id
                        //                           );
                        //                       const startDate = (() => {
                        //                         try {
                        //                           return foundPanelCodeDti?.startDate?.split(
                        //                             "T"
                        //                           )[0];
                        //                         } catch (e) {
                        //                           return undefined;
                        //                         }
                        //                       })();
                        //                       const endDate = (() => {
                        //                         try {
                        //                           return foundPanelCodeDti?.endDate?.split(
                        //                             "T"
                        //                           )[0];
                        //                         } catch (e) {
                        //                           return undefined;
                        //                         }
                        //                       })();
                        //                       return MeetingMeetingTask.fromPartial(
                        //                         {
                        //                           extSubProcessCommonId:
                        //                             dti.departmentTemplateItem
                        //                               ?.commonId ?? undefined,
                        //                           extSubProcessId: dti
                        //                             .departmentTemplateItem?.id
                        //                             ? `${dti.departmentTemplateItem.id}`
                        //                             : undefined,
                        //                           description: dti
                        //                             .departmentTemplateItem
                        //                             ?.name
                        //                             ? dti.departmentTemplateItem
                        //                                 ?.name
                        //                             : undefined,
                        //                           masterJavaBaseModel:
                        //                             MasterJavaBaseModel.fromPartial(
                        //                               {
                        //                                 uuid: uuidv4(),
                        //                               }
                        //                             ),
                        //                           start: startDate,
                        //                           meetingTaskTargetDates: [
                        //                             MeetingMeetingTaskTargetDate.fromPartial(
                        //                               {
                        //                                 masterJavaBaseModel:
                        //                                   MasterJavaBaseModel.fromPartial(
                        //                                     { uuid: uuidv4() }
                        //                                   ),
                        //                                 date: endDate,
                        //                               }
                        //                             ),
                        //                           ],
                        //                         }
                        //                       );
                        //                     }
                        //                   ) ?? [],
                        //             }
                        //           : selectedWorkOrder.taskList,
                        //       }
                        //     : selectedWorkOrder
                        // );
                      }}
                    >
                      Generate WO
                    </button>
                  </div>

                  <div className="my-2">
                    <div>
                      <Select
                        options={ctx?.extUsers.map((u) => ({
                          label: `${u.name} (${u.departmentName})`,
                          value: u,
                        }))}
                        onChange={(u) => {
                          // setSelectedWorkOrder(
                          //   selectedWorkOrder
                          //     ? {
                          //         ...selectedWorkOrder,
                          //         taskList: selectedWorkOrder.taskList
                          //           ? {
                          //               ...selectedWorkOrder.taskList,
                          //               extInChargeId: (
                          //                 u as {
                          //                   value: ExtUser;
                          //                 }
                          //               ).value.id
                          //                 ? `${
                          //                     (
                          //                       u as {
                          //                         value: ExtUser;
                          //                       }
                          //                     ).value.id
                          //                   }`
                          //                 : undefined,
                          //               masterJavaBaseModel: selectedWorkOrder
                          //                 .taskList.masterJavaBaseModel
                          //                 ? {
                          //                     ...selectedWorkOrder.taskList
                          //                       .masterJavaBaseModel,
                          //                     uuid: selectedWorkOrder.taskList
                          //                       .masterJavaBaseModel?.uuid
                          //                       ? selectedWorkOrder.taskList
                          //                           .masterJavaBaseModel?.uuid
                          //                       : uuidv4(),
                          //                   }
                          //                 : selectedWorkOrder.taskList
                          //                     .masterJavaBaseModel,
                          //             }
                          //           : selectedWorkOrder.taskList,
                          //       }
                          //     : selectedWorkOrder
                          // );
                        }}
                        placeholder="Select SPV..."
                      />
                    </div>
                    {selectedWorkOrder?.taskList?.extInChargeId ? (
                      <>
                        <div>
                          {
                            ctx?.extUsers.find(
                              (u) =>
                                `${u.id}` ===
                                selectedWorkOrder?.taskList?.extInChargeId
                            )?.name
                          }
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div>
              PICs:
              <div>
                <ul>
                  {ctx?.extUsers
                    .filter((u, i) =>
                      u.departmentName?.toLowerCase().includes("product")
                    )
                    .filter((_, i) => i < 10)
                    .map((u) => {
                      return (
                        <>
                          <li>
                            <div>
                              {u.name} ({u.username},{" "}
                              {u.roles.map((r) => r.name).join(", ")})
                            </div>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div> */}
                  <ol>
                    {selectedWorkOrder?.taskList?.meetingTasks?.map((mt, i) => {
                      return (
                        <>
                          <li>
                            <div className="d-flex align-items-end justify-content-between">
                              <div className="d-flex align-items-end">
                                {/* <div className="flex-grow-1">
                                  Process:{" "}
                                  {(() => {
                                    const foundDti = departmentTemplates
                                      .map(
                                        (dt) => dt.departmentTemplateItems ?? []
                                      )
                                      .flat()
                                      .find(
                                        (dti) =>
                                          `${dti.departmentTemplateItem?.id}` ===
                                          mt.extSubProcessId
                                      );
                                    const foundDt = departmentTemplates.find(
                                      (dt) =>
                                        dt.departmentTemplateItems.find(
                                          (dti) =>
                                            `${dti.departmentTemplateItem?.id}` ===
                                            `${mt.extSubProcessId}`
                                        )
                                    );

                                    const foundRcem = rcemDepts?.departments
                                      .map((d) => d.processes ?? [])
                                      .flat()
                                      .map((p) => p.subProcesses ?? [])
                                      .flat()
                                      .find(
                                        (sp) =>
                                          `${sp.masterJavaBaseModel?.id}` ===
                                          `${foundDti?.departmentTemplateItem?.extRcemSubProcessId}`
                                      );

                                    const calculatedDtDayFirstUnchecked =
                                      Math.ceil(
                                        (i /
                                          (foundDt?.departmentTemplateItems
                                            ?.length ?? 1)) *
                                          (foundDt?.departmentTemplate
                                            ?.processDays ?? 0)
                                      );
                                    const calculatedDtDayFirst =
                                      calculatedDtDayFirstUnchecked === 0
                                        ? 1
                                        : calculatedDtDayFirstUnchecked;

                                    const calculatedDtDayEnd = Math.ceil(
                                      ((i + 1) /
                                        (foundDt?.departmentTemplateItems
                                          ?.length ?? 1)) *
                                        (foundDt?.departmentTemplate
                                          ?.processDays ?? 0)
                                    );

                                    return (
                                      <>
                                        <strong>
                                          {
                                            foundDti?.departmentTemplateItem
                                              ?.name
                                          }{" "}
                                          (day {calculatedDtDayFirst} -{" "}
                                          {calculatedDtDayEnd})
                                        </strong>{" "}
                                        ({mt.description})
                                        <strong
                                          className={`${
                                            foundRcem
                                              ? `text-success`
                                              : `text-danger`
                                          }`}
                                        >
                                          {foundRcem
                                            ? `${foundRcem.name} (${foundRcem.timeProcess} hrs)`
                                            : `RCEM Process Unmapped`}
                                        </strong>
                                      </>
                                    );
                                  })()}
                                </div> */}
                                {mt.description}
                              </div>

                              <div className="d-flex">
                                <div>
                                  From:{" "}
                                  {(() => {
                                    try {
                                      return makeReadableDateStringIntlDateOnlyUtc(
                                        new Date(`${mt.start}T00:00:00Z`)
                                      );
                                    } catch (e) {
                                      return "date invalid";
                                    }
                                  })()}
                                  <input
                                    className="form-control form-control-sm"
                                    placeholder="From..."
                                    type="date"
                                    onBlur={(e) => {
                                      if (
                                        selectedWorkOrder &&
                                        e.target.value !== ""
                                      ) {
                                        setSelectedWorkOrder(
                                          selectedWorkOrder
                                            ? {
                                                ...selectedWorkOrder,
                                                taskList:
                                                  selectedWorkOrder.taskList
                                                    ? {
                                                        ...selectedWorkOrder.taskList,
                                                        masterJavaBaseModel: {
                                                          ...selectedWorkOrder
                                                            .taskList
                                                            .masterJavaBaseModel,

                                                          uuid: selectedWorkOrder
                                                            .taskList
                                                            .masterJavaBaseModel
                                                            ?.uuid
                                                            ? selectedWorkOrder
                                                                .taskList
                                                                .masterJavaBaseModel
                                                                ?.uuid
                                                            : uuidv4(),
                                                        },
                                                        meetingTasks:
                                                          selectedWorkOrder.taskList.meetingTasks.map(
                                                            (mtx) =>
                                                              mtx &&
                                                              mtx
                                                                .masterJavaBaseModel
                                                                ?.uuid ===
                                                                mt
                                                                  .masterJavaBaseModel
                                                                  ?.uuid
                                                                ? {
                                                                    ...mtx,
                                                                    start:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                : mtx
                                                          ),
                                                      }
                                                    : selectedWorkOrder.taskList,
                                              }
                                            : selectedWorkOrder
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  <div className="mx-2">
                                    To:{" "}
                                    {(() => {
                                      try {
                                        return makeReadableDateStringIntlDateOnlyUtc(
                                          new Date(
                                            `${
                                              extractProtoMeetingTaskTargetDate(
                                                mt
                                              )?.date
                                            }T00:00:00Z`
                                          )
                                        );
                                      } catch (e) {
                                        return "date invalid";
                                      }
                                    })()}
                                  </div>

                                  <input
                                    className=" mx-2 form-control form-control-sm"
                                    placeholder="To..."
                                    type="date"
                                    onBlur={(e) => {
                                      if (
                                        selectedWorkOrder &&
                                        e.target.value !== ""
                                      ) {
                                        const newMeetingTaskTargetDate =
                                          MeetingMeetingTaskTargetDate.fromPartial(
                                            {
                                              date: e.target.value,
                                              masterJavaBaseModel:
                                                MasterJavaBaseModel.fromPartial(
                                                  { uuid: uuidv4() }
                                                ),
                                            }
                                          );

                                        setSelectedWorkOrder(
                                          selectedWorkOrder
                                            ? {
                                                ...selectedWorkOrder,
                                                taskList:
                                                  selectedWorkOrder.taskList
                                                    ? {
                                                        ...selectedWorkOrder.taskList,
                                                        masterJavaBaseModel: {
                                                          ...selectedWorkOrder
                                                            .taskList
                                                            .masterJavaBaseModel,

                                                          uuid: selectedWorkOrder
                                                            .taskList
                                                            .masterJavaBaseModel
                                                            ?.uuid
                                                            ? selectedWorkOrder
                                                                .taskList
                                                                .masterJavaBaseModel
                                                                ?.uuid
                                                            : uuidv4(),
                                                        },
                                                        meetingTasks:
                                                          selectedWorkOrder.taskList.meetingTasks.map(
                                                            (mtx) =>
                                                              mtx &&
                                                              mtx
                                                                .masterJavaBaseModel
                                                                ?.uuid ===
                                                                mt
                                                                  .masterJavaBaseModel
                                                                  ?.uuid
                                                                ? {
                                                                    ...mtx,
                                                                    meetingTaskTargetDates:
                                                                      mtx.meetingTaskTargetDates
                                                                        ? [
                                                                            ...mtx.meetingTaskTargetDates,
                                                                            newMeetingTaskTargetDate,
                                                                          ]
                                                                        : [
                                                                            newMeetingTaskTargetDate,
                                                                          ],
                                                                  }
                                                                : mtx
                                                          ),
                                                      }
                                                    : selectedWorkOrder.taskList,
                                              }
                                            : selectedWorkOrder
                                        );
                                      }
                                    }}
                                  />
                                </div>
                                <div>
                                  <div className="mx-2">
                                    Completed:{" "}
                                    {mt.status === "OUTSTANDING" ? (
                                      <>
                                        <div className="text-danger">
                                          Incomplete
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {(() => {
                                          try {
                                            return makeReadableDateStringIntlDateOnlyUtc(
                                              new Date(
                                                `${mt.completedDate}T00:00:00Z`
                                              )
                                            );
                                          } catch (e) {
                                            return "date invalid";
                                          }
                                        })()}
                                      </>
                                    )}{" "}
                                  </div>

                                  <input
                                    className=" mx-2 form-control form-control-sm"
                                    placeholder="To..."
                                    type="date"
                                    onBlur={(e) => {
                                      if (
                                        selectedWorkOrder &&
                                        e.target.value !== ""
                                      ) {
                                        setSelectedWorkOrder(
                                          selectedWorkOrder
                                            ? {
                                                ...selectedWorkOrder,
                                                taskList:
                                                  selectedWorkOrder.taskList
                                                    ? {
                                                        ...selectedWorkOrder.taskList,
                                                        masterJavaBaseModel: {
                                                          ...selectedWorkOrder
                                                            .taskList
                                                            .masterJavaBaseModel,
                                                          uuid: selectedWorkOrder
                                                            .taskList
                                                            .masterJavaBaseModel
                                                            ?.uuid
                                                            ? selectedWorkOrder
                                                                .taskList
                                                                .masterJavaBaseModel
                                                                ?.uuid
                                                            : uuidv4(),
                                                        },
                                                        meetingTasks:
                                                          selectedWorkOrder.taskList.meetingTasks.map(
                                                            (mtx) =>
                                                              mtx &&
                                                              mtx
                                                                .masterJavaBaseModel
                                                                ?.uuid ===
                                                                mt
                                                                  .masterJavaBaseModel
                                                                  ?.uuid
                                                                ? {
                                                                    ...mtx,
                                                                    status:
                                                                      "COMPLETED",
                                                                    completedDate:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                : mtx
                                                          ),
                                                      }
                                                    : selectedWorkOrder.taskList,
                                              }
                                            : selectedWorkOrder
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <ol>
                                {mt.meetingTaskInCharges?.map((ic) => {
                                  const foundUser = ctx?.extUsers.find(
                                    (u) => `${u.id}` === ic.extUserId
                                  );
                                  return (
                                    <>
                                      <li>
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (selectedWorkOrder) {
                                              console.log(
                                                "[delete id in charge]",
                                                [
                                                  ...selectedWorkOrder.meetingTaskInChargeDeleteIds,
                                                  ic.masterJavaBaseModel?.id ??
                                                    "",
                                                ]
                                              );
                                              setSelectedWorkOrder({
                                                ...selectedWorkOrder,
                                                meetingTaskInChargeDeleteIds:
                                                  selectedWorkOrder.meetingTaskInChargeDeleteIds
                                                    ? [
                                                        ...selectedWorkOrder.meetingTaskInChargeDeleteIds,
                                                        ic.masterJavaBaseModel
                                                          ?.id ?? "",
                                                      ]
                                                    : selectedWorkOrder.meetingTaskInChargeDeleteIds,
                                                taskList:
                                                  selectedWorkOrder.taskList
                                                    ? {
                                                        ...selectedWorkOrder.taskList,
                                                        meetingTasks:
                                                          selectedWorkOrder
                                                            .taskList
                                                            .meetingTasks
                                                            ? selectedWorkOrder.taskList.meetingTasks.map(
                                                                (mtx) =>
                                                                  mtx &&
                                                                  mtx
                                                                    .masterJavaBaseModel
                                                                    ?.uuid ===
                                                                    mt
                                                                      .masterJavaBaseModel
                                                                      ?.uuid
                                                                    ? {
                                                                        ...mtx,
                                                                        meetingTaskInCharges:
                                                                          mtx.meetingTaskInCharges
                                                                            ? mtx.meetingTaskInCharges.filter(
                                                                                (
                                                                                  mticx
                                                                                ) =>
                                                                                  mticx.extUserId !==
                                                                                  ic.extUserId
                                                                              )
                                                                            : mtx.meetingTaskInCharges,
                                                                      }
                                                                    : mtx
                                                              )
                                                            : selectedWorkOrder
                                                                .taskList
                                                                .meetingTasks,
                                                      }
                                                    : selectedWorkOrder.taskList,
                                              });
                                            }
                                          }}
                                        >
                                          {foundUser?.name} (
                                          {foundUser?.departmentName})
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ol>
                            </div>

                            <div>
                              <div>
                                <Select
                                  options={ctx?.extUsers.map((u) => ({
                                    label: `${u.name} (${u.departmentName})`,
                                    value: u,
                                  }))}
                                  onChange={(u) => {
                                    if (
                                      selectedWorkOrder?.taskList?.meetingTasks
                                        .find(
                                          (mtx) =>
                                            mtx.masterJavaBaseModel?.uuid ===
                                            mt.masterJavaBaseModel?.uuid
                                        )
                                        ?.meetingTaskInCharges?.find(
                                          (ic) =>
                                            ic.extUserId ===
                                            `${
                                              (
                                                u as {
                                                  value: ExtUser;
                                                }
                                              ).value.id
                                            }`
                                        )
                                    ) {
                                      alert(
                                        "User already selected in task. Please select another one."
                                      );
                                      return;
                                    }
                                    const newMeetingTaskInCharge =
                                      MeetingMeetingTaskInCharge.fromPartial({
                                        masterJavaBaseModel:
                                          MasterJavaBaseModel.fromPartial({
                                            uuid: uuidv4(),
                                          }),
                                        extUserId: (
                                          u as {
                                            value: ExtUser;
                                          }
                                        ).value.id
                                          ? `${
                                              (
                                                u as {
                                                  value: ExtUser;
                                                }
                                              ).value.id
                                            }`
                                          : undefined,
                                      });
                                    // setSelectedWorkOrder(
                                    //   selectedWorkOrder
                                    //     ? {
                                    //         ...selectedWorkOrder,
                                    //         taskList: selectedWorkOrder.taskList
                                    //           ? {
                                    //               ...selectedWorkOrder.taskList,
                                    //               masterJavaBaseModel:
                                    //                 selectedWorkOrder?.taskList
                                    //                   ?.masterJavaBaseModel
                                    //                   ? {
                                    //                       ...selectedWorkOrder
                                    //                         ?.taskList
                                    //                         ?.masterJavaBaseModel,
                                    //                       uuid: selectedWorkOrder
                                    //                         .taskList
                                    //                         .masterJavaBaseModel
                                    //                         ?.uuid
                                    //                         ? selectedWorkOrder
                                    //                             .taskList
                                    //                             .masterJavaBaseModel
                                    //                             ?.uuid
                                    //                         : uuidv4(),
                                    //                     }
                                    //                   : selectedWorkOrder
                                    //                       ?.taskList
                                    //                       ?.masterJavaBaseModel,
                                    //               meetingTasks:
                                    //                 selectedWorkOrder.taskList.meetingTasks.map(
                                    //                   (mtx) =>
                                    //                     mtx &&
                                    //                     mtx.masterJavaBaseModel
                                    //                       ?.uuid ===
                                    //                       mt.masterJavaBaseModel
                                    //                         ?.uuid
                                    //                       ? {
                                    //                           ...mtx,
                                    //                           meetingTaskInCharges:
                                    //                             mtx.meetingTaskInCharges
                                    //                               ? [
                                    //                                   ...mtx.meetingTaskInCharges,
                                    //                                   newMeetingTaskInCharge,
                                    //                                 ]
                                    //                               : [
                                    //                                   newMeetingTaskInCharge,
                                    //                                 ],
                                    //                         }
                                    //                       : mtx
                                    //                 ),
                                    //             }
                                    //           : selectedWorkOrder.taskList,
                                    //       }
                                    //     : selectedWorkOrder
                                    // );
                                  }}
                                  placeholder="Select PiC..."
                                />
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ol>
                </Modal.Body>
                <Modal.Footer>
                  {/* <div className="d-flex justify-content-between">
                    {newSaveStatus === RequestStatus.Loading ? (
                      <>
                        <Spinner animation="border" />
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary"
                          onClick={async () => {
                            if (!state.job.job?.id) {
                              alert(
                                "Cannot create WO if job is unsaved. Please create job first."
                              );
                              return;
                            }

                            if (selectedWorkOrder) {
                              console.log(
                                MeetingTaskListView.toJSON(selectedWorkOrder)
                              );

                              try {
                                setNewSaveStatus(RequestStatus.Loading);

                                const foundDepartmentTemplateExtHeadId =
                                  departmentTemplates.find(
                                    (dt) =>
                                      `${dt.departmentTemplate?.id}` ===
                                      `${selectedPanelCodeDepartmentTemplate?.departmentTemplate?.id}`
                                  )?.departmentTemplate?.extHeadId;

                                const resp = await fetch(
                                  `${process.env.REACT_APP_MEETING_URL}/tasklists-proto-save`,
                                  {
                                    method: "POST",
                                    headers: {
                                      "content-type": "application/json",
                                      authorization: ctx?.apiKey ?? "",
                                    },
                                    body: JSON.stringify(
                                      MeetingTaskListView.toJSON({
                                        ...selectedWorkOrder,
                                        taskList: selectedWorkOrder.taskList
                                          ? {
                                              ...selectedWorkOrder.taskList,
                                              // extInChargeId:
                                              //   foundDepartmentTemplateExtHeadId
                                              //     ? `${foundDepartmentTemplateExtHeadId}`
                                              //     : selectedWorkOrder.taskList
                                              //         .extInChargeId,
                                              extPanelCodeId:
                                                selectedPanelCode?.id
                                                  ? `${selectedPanelCode?.id}`
                                                  : selectedWorkOrder.taskList
                                                      .extPanelCodeId,
                                              extJobId: state.job?.job?.id
                                                ? `${state.job?.job?.id}`
                                                : selectedWorkOrder.taskList
                                                    .extJobId,
                                              meetingTasks:
                                                selectedWorkOrder.taskList.meetingTasks.map(
                                                  (mt) => {
                                                    const foundPanelCodeDepartmentTemplateItem =
                                                      selectedPanelCode?.panelCodeDepartmentTemplateItems?.find(
                                                        (pcdti) =>
                                                          `${pcdti.departmentTemplateItem?.id}` ===
                                                          `${mt?.extSubProcessId}`
                                                      );

                                                    return {
                                                      ...mt,
                                                      start:
                                                        foundPanelCodeDepartmentTemplateItem &&
                                                        mt.start !==
                                                          foundPanelCodeDepartmentTemplateItem?.startDate
                                                          ? foundPanelCodeDepartmentTemplateItem?.startDate?.split(
                                                              "T"
                                                            )[0] ?? ""
                                                          : mt.start,
                                                      meetingTaskTargetDates:
                                                        foundPanelCodeDepartmentTemplateItem &&
                                                        extractProtoMeetingTaskTargetDate(
                                                          mt
                                                        )?.date !==
                                                          foundPanelCodeDepartmentTemplateItem?.endDate
                                                          ? [
                                                              ...mt.meetingTaskTargetDates,
                                                              MeetingMeetingTaskTargetDate.fromPartial(
                                                                {
                                                                  masterJavaBaseModel:
                                                                    MasterJavaBaseModel.fromPartial(
                                                                      {
                                                                        uuid: v4(),
                                                                      }
                                                                    ),
                                                                  date: foundPanelCodeDepartmentTemplateItem?.endDate?.split(
                                                                    "T"
                                                                  )[0],
                                                                }
                                                              ),
                                                            ]
                                                          : mt.meetingTaskTargetDates,
                                                    };
                                                  }
                                                ),
                                            }
                                          : selectedWorkOrder.taskList,
                                      })
                                    ),
                                  }
                                );

                                if (resp.status !== 200)
                                  throw await resp.text();
                                const taskList = MeetingTaskList.fromPartial(
                                  await resp.json()
                                );

                                // Save panelcodedepartmenttemplate
                                if (
                                  taskList.masterJavaBaseModel?.id &&
                                  taskList.masterJavaBaseModel.id !== `0`
                                ) {
                                  setState({
                                    ...state,
                                    job: {
                                      ...state.job,
                                      job: state.job.job
                                        ? {
                                            ...state.job.job,
                                            panelCodes: state.job.job.panelCodes
                                              ? state.job.job.panelCodes.map(
                                                  (pcx) =>
                                                    pcx.uuid ===
                                                    selectedPanelCode?.uuid
                                                      ? {
                                                          ...pcx,
                                                          panelCodeDepartmentTemplates:
                                                            pcx.panelCodeDepartmentTemplates
                                                              ? pcx.panelCodeDepartmentTemplates.map(
                                                                  (dtx) =>
                                                                    dtx
                                                                      .departmentTemplate
                                                                      ?.id ===
                                                                    selectedPanelCodeDepartmentTemplate
                                                                      ?.departmentTemplate
                                                                      ?.id
                                                                      ? {
                                                                          ...dtx,
                                                                          extTaskListId:
                                                                            taskList
                                                                              .masterJavaBaseModel
                                                                              ?.id &&
                                                                            taskList
                                                                              .masterJavaBaseModel
                                                                              .id !==
                                                                              `0`
                                                                              ? isNaN(
                                                                                  parseInt(
                                                                                    taskList
                                                                                      .masterJavaBaseModel
                                                                                      .id
                                                                                  )
                                                                                )
                                                                                ? null
                                                                                : parseInt(
                                                                                    taskList
                                                                                      .masterJavaBaseModel
                                                                                      .id
                                                                                  )
                                                                              : null,
                                                                        }
                                                                      : dtx
                                                                )
                                                              : pcx.panelCodeDepartmentTemplates,
                                                        }
                                                      : pcx
                                                )
                                              : state.job.job.panelCodes,
                                          }
                                        : state.job.job,
                                    },
                                  });
                                }

                                if (selectedPanelCodeDepartmentTemplate) {
                                  try {
                                    const respDt = await fetch(
                                      `${process.env.REACT_APP_BASE_URL}/panelcodedepartmenttemplates`,
                                      {
                                        method: "POST",
                                        headers: {
                                          "content-type": "application/json",
                                          authorization: ctx?.apiKey ?? "",
                                        },
                                        body: JSON.stringify({
                                          ...selectedPanelCodeDepartmentTemplate,
                                          panelCode: selectedPanelCode,
                                          extTaskListId:
                                            taskList.masterJavaBaseModel?.id &&
                                            taskList.masterJavaBaseModel.id !==
                                              `0`
                                              ? isNaN(
                                                  parseInt(
                                                    taskList.masterJavaBaseModel
                                                      .id
                                                  )
                                                )
                                                ? null
                                                : parseInt(
                                                    taskList.masterJavaBaseModel
                                                      .id
                                                  )
                                              : null,
                                        } as PanelCodeDepartmentTemplate),
                                      }
                                    );

                                    if (respDt.status !== 200)
                                      throw await resp.text();
                                  } catch (e) {
                                    console.error(e);
                                    alert(
                                      "Saving work order to dept template error."
                                    );
                                    return;
                                  }
                                }

                                alert(
                                  `Work order save OK: ${taskList.masterJavaBaseModel?.id}.`
                                );
                                setShowWorkOrderOpen(false);
                                handleInit();
                              } catch (e) {
                                console.error(e);
                                alert("Error saving work order.");
                              } finally {
                                setNewSaveStatus(RequestStatus.NotAsked);
                              }
                            }
                          }}
                        >
                          Save WO
                        </button>
                      </>
                    )}
                  </div> */}
                </Modal.Footer>
              </>
            );
          })()}
        </Modal>
        <Modal
          show={showWarehouseItemDialog}
          onHide={() => {
            setshowWarehouseItemDialog(false);
          }}
          size="xl"
        >
          <ModalHeader>
            <div>
              <h5>Search Item</h5>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <input
                className="form-control form-control-sm"
                placeholder="Search items by PN, name, desc..."
                onBlur={(e) => {
                  setSearchItemString(e.target.value);
                }}
              />
            </div>
            {itemsLoading ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                {" "}
                <div
                  className="overflow-auto border border-dark shadow shadow-md"
                  style={{ height: "65vh", resize: "vertical" }}
                >
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {["Select", "ID", "#", "P/N", "Name"].map((h) => (
                        <>
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        </>
                      ))}
                    </tr>
                    {searchItems(items, searchItemString).map((i, i_) => (
                      <tr>
                        <td className="border border-dark">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={(e) => {
                              const uuidSel = (() => {
                                switch (itemSelectMode) {
                                  case "Warehouse":
                                    return selectedPanelCodeWarehouseItem?.uuid;
                                  case "MI":
                                    return selectedPanelCodeMIItem?.uuid;
                                  default:
                                    return null;
                                }
                              })();

                              if (state) {
                                setshowWarehouseItemDialog(false);
                                setState({
                                  ...state,
                                  job: state.job
                                    ? {
                                        ...state.job,
                                        job: state.job.job
                                          ? {
                                              ...state.job.job,
                                              panelCodes: state.job.job
                                                .panelCodes
                                                ? state.job.job.panelCodes.map(
                                                    (pcx) =>
                                                      `${pcx.uuid}` ===
                                                      `${uuidSel}`
                                                        ? {
                                                            ...pcx,
                                                            extItemId:
                                                              itemSelectMode ===
                                                              "Warehouse"
                                                                ? isNaN(
                                                                    parseInt(
                                                                      i.id ?? ""
                                                                    )
                                                                  )
                                                                  ? pcx.extItemId
                                                                  : parseInt(
                                                                      i.id ?? ""
                                                                    )
                                                                : pcx.extItemId,
                                                            extMaterialInstallationItemId:
                                                              itemSelectMode ===
                                                              "MI"
                                                                ? isNaN(
                                                                    parseInt(
                                                                      i.id ?? ""
                                                                    )
                                                                  )
                                                                  ? pcx.extMaterialInstallationItemId
                                                                  : parseInt(
                                                                      i.id ?? ""
                                                                    )
                                                                : pcx.extMaterialInstallationItemId,
                                                          }
                                                        : pcx
                                                  )
                                                : state.job.job.panelCodes,
                                            }
                                          : state.job.job,
                                      }
                                    : state.job,
                                });
                              }
                            }}
                          >
                            Select
                          </button>
                        </td>
                        <td className="border border-dark">{i.id}</td>
                        <td className="border border-dark">{i_ + 1}</td>

                        <td className="border border-dark">{i.partNum}</td>
                        <td className="border border-dark">
                          <div>
                            <div>{i.partName}</div>
                            <div>
                              <small>{i.partDesc}</small>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </>
            )}
          </ModalBody>
          {/* <ModalFooter>
            <button className="btn btn-sm btn-primary">Insert</button>
          </ModalFooter> */}
        </Modal>
      </MuiPickersUtilsProvider>

      <Modal
        size="xl"
        show={handleSaveJobWoSpecificDeptsShowModal}
        onHide={() => {
          sethandleSaveJobWoSpecificDeptsShowModal(false);
        }}
      >
        <ModalHeader>
          <h3>Send WO Depts</h3>
        </ModalHeader>
        <ModalBody>
          <div>
            For departments:{" "}
            {[
              taskListDepartmentCategoryToJSON(TaskListDepartmentCategory.PPC),
              taskListDepartmentCategoryToJSON(
                TaskListDepartmentCategory.SALES
              ),
              taskListDepartmentCategoryToJSON(
                TaskListDepartmentCategory.PROJECT
              ),
            ].join(", ")}
          </div>

          <hr />

          {deptTasks.map((d) => {
            const deptUsers = ctx?.extUsers.filter((u) =>
              ctx.extDepartments
                .filter((dx) => dx.deptCategory === d.category)
                .find((dx) => dx.id === u.departmentId)
            );

            const foundWo = workOrders?.current?.taskLists.find(
              (tL) =>
                `${tL.taskList?.extJobId}` === `${state.job.job?.id}` &&
                tL.taskList?.deptCategory ===
                  taskListDepartmentCategoryFromJSON(d.category)
            );

            return (
              <>
                <div>
                  <div>
                    {d.category}: {deptUsers?.length}
                  </div>
                  <div>
                    <small>
                      {deptUsers?.map((d) => d.username).join(", ")}
                    </small>
                  </div>
                  <div>
                    <div>
                      <strong>
                        #{foundWo?.taskList?.masterJavaBaseModel?.id} Tasks:{" "}
                        {foundWo ? (
                          <>
                            <strong className="text-success">Found</strong>
                          </>
                        ) : (
                          <>
                            {" "}
                            <strong className="text-danger">Not Found</strong>
                          </>
                        )}
                      </strong>
                    </div>
                    <div>
                      {(foundWo?.taskList?.meetingTasks.filter(
                        (mt) => mt.status === "COMPLETED"
                      ).length ?? 0) ===
                      (foundWo?.taskList?.meetingTasks.length ?? 0) ? (
                        <>
                          <strong className="text-success">Completed</strong>
                        </>
                      ) : (
                        <>
                          <strong className="text-danger">Not Completed</strong>
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <ol>
                      {d.tasks.map((t) => {
                        const foundTask = foundWo?.taskList?.meetingTasks.find(
                          (mt) => mt.description === t
                        );
                        return (
                          <>
                            <li>
                              <div className="d-flex justify-content-between">
                                <div>{t}</div>
                                <div>
                                  <div>
                                    {foundTask?.status}: {foundTask?.start} -{" "}
                                    {
                                      extractProtoMeetingTaskTargetDate(
                                        foundTask
                                      )?.date
                                    }
                                  </div>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ol>{" "}
                  </div>
                </div>
                <hr className="border border-dark" />
              </>
            );
          })}
        </ModalBody>
        <ModalFooter>
          {/* {state.job.job?.id ? (
            <>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  handleSendWO();
                }}
              >
                {sendWoLoading ? (
                  <>
                    <Spinner animation="border" />
                  </>
                ) : (
                  <>Send WO</>
                )}
              </button>
            </>
          ) : (
            <>Save job before saving WO!</>
          )} */}
        </ModalFooter>
      </Modal>

      <Modal
        size="xl"
        show={openManualDeptTemplateWoAssignmentModal}
        onHide={() => {
          setOpenManualDeptTemplateWoAssignmentModal(false);
        }}
      >
        <ModalHeader>
          <h3>Manual Dept Template Assignment</h3>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>Manpower</div>
            <div>
              Assigning for:{" "}
              {
                departmentTemplates.find((t) =>
                  t.departmentTemplateItems.find(
                    (i) =>
                      `${i.departmentTemplateItem?.id}` ===
                      `${selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem?.departmentTemplateItem?.id}`
                  )
                )?.departmentTemplate?.name
              }
              :{" "}
              {
                selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
                  ?.departmentTemplateItem?.name
              }
              : [
              {
                selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItemPanelCode?.type
              }
              -
              {
                selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItemPanelCode?.name
              }
              - qty
              {
                selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItemPanelCode?.qty
              }
              ]
            </div>
            <hr className="border border-dark" />
            <div>
              <div>
                <Select
                  options={ctx?.extUsers}
                  getOptionLabel={(u) =>
                    `${u.username}: ${u.name}: ${u.departmentName}`
                  }
                  onChange={(v) => {
                    const val = v as unknown as ExtUser;

                    setExtUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem(
                      val
                    );
                  }}
                />
              </div>
              <div>
                {
                  extUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem?.username
                }
                :{" "}
                {
                  extUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem?.name
                }
                :{" "}
                {
                  extUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem?.departmentName
                }
              </div>
            </div>
            <hr className="border border-dark" />
            <div className="d-flex">
              <div>Auto-assign date?</div>
              <div>
                <input
                  type="checkbox"
                  checked={
                    autoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
                  }
                  onClick={() => {
                    setAutoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem(
                      !autoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
                    );
                  }}
                />
              </div>
            </div>
          </div>
          {autoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem ? (
            <></>
          ) : (
            <>
              <div>
                <input
                  className="form-control form-control-sm"
                  type="date"
                  onBlur={(e) => {
                    if (e.target.value !== "") {
                      setAssignedDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem(
                        e.target.value
                      );
                    }
                  }}
                />
                {
                  assignedDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
                }
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-sm btn-primary"
            disabled={
              !extUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
            }
            onClick={() => {
              if (
                selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
              ) {
                if (
                  autoAssignDateManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem
                ) {
                  handleAutoAssignManualInput(
                    [
                      selectedManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem,
                    ],
                    [
                      {
                        worker: PpicSkillWorker.fromPartial({}),
                        user:
                          extUserManualDeptTemplateWoAssignmentPanelCodeDepartmentTemplateItem ??
                          undefined,
                      },
                    ]
                  );
                }
              }
            }}
          >
            Assign
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        size="xl"
        show={showWorkOrderCleanupDetailModal}
        onHide={() => {
          setShowWorkOrderCleanupDetailModal(false);
        }}
      >
        <ModalHeader>
          <h3>WO Detail</h3>
        </ModalHeader>
        <ModalBody>
          <div>
            <TaskListInfo
              customers={ctx?.extCustomers}
              crmCustomers={ctx?.extCrmCustomers}
              tL={workOrderCleanupDetail}
              extUsers={ctx?.extUsers ?? []}
              jobsProto={jobsProtoSimple ?? PpicJobs.fromPartial({ jobs: [] })}
              machines={machinesDb}
              processTypes={
                processTypes ??
                PpicSimplifiedProcessTypes.fromPartial({ processTypes: [] })
              }
              rcemDepartments={rcemDepartments}
              integrationModules={integrationModules}
              machinePrograms={machineProgramsProto}
              departmentTemplates={departmentTemplatesProto}
              panelCodeDepartmentTemplateItems={
                panelCodeDepartmentTemplateItemsProto.current
              }
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        show={showWorkOrderCleanupModal}
        onHide={() => {
          setShowWorkOrderCleanupModal(false);
        }}
      >
        <ModalHeader>
          <div>
            <div></div>
            <h3>WO Cleanup</h3>
            <div>
              <small>
                Automatic cleanup select: No product tree, no program tree, no
                dept template, no product tree, time process 0
              </small>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div
              className="overflow-auto shadow shadow-md border border-dark"
              style={{ height: "75vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                {[
                  "#",
                  "ID",
                  "Product Tree",
                  "Program Tree",
                  "Dept Template",
                  "Job",
                  "Product",
                  "Time process",
                  "Manpower",
                  "Date",
                  "Details",
                ].map((h) => (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
                {workOrders?.current?.taskLists.map((tL, i) => {
                  return (
                    <>
                      <tr
                        style={{
                          backgroundColor: extWoCleanupIds.find(
                            (id) =>
                              `${id}` ===
                              `${tL.taskList?.masterJavaBaseModel?.id}`
                          )
                            ? "lightsalmon"
                            : "white",
                        }}
                      >
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          {tL.taskList?.masterJavaBaseModel?.id}
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              (tL.taskList?.extSetId &&
                                tL.taskList.extSetId !== "0" &&
                                tL.taskList.extSetId !== "") ||
                              (tL.taskList?.extModuleId &&
                                tL.taskList.extModuleId !== "0" &&
                                tL.taskList.extModuleId !== "") ||
                              (tL.taskList?.extSubModuleId &&
                                tL.taskList.extSubModuleId !== "0" &&
                                tL.taskList.extSubModuleId !== "")
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.extProgramId &&
                              tL.taskList.extProgramId !== "0" &&
                              tL.taskList.extProgramId !== ""
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.meetingTasks.find(
                                (mt) =>
                                  mt.extPanelCodeDepartmentTemplateItemId &&
                                  mt.extPanelCodeDepartmentTemplateItemId !==
                                    "0" &&
                                  mt.extPanelCodeDepartmentTemplateItemId !== ""
                              )
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.meetingTasks.find((mt) =>
                                tL.taskList?.extJobId &&
                                tL.taskList.extJobId !== "0" &&
                                tL.taskList.extJobId !== ""
                                  ? true
                                  : false
                              )
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={
                              tL.taskList?.meetingTasks.find((mt) =>
                                tL.taskList?.extPanelCodeId &&
                                tL.taskList.extPanelCodeId !== "0" &&
                                tL.taskList.extPanelCodeId !== ""
                                  ? true
                                  : false
                              )
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td className="border border-dark">
                          {getWorkOrderTimeMinutes(
                            tL.taskList,
                            rcemDepartments,
                            integrationModules,
                            machineProgramsProto,
                            departmentTemplatesProto,
                            panelCodeDepartmentTemplateItemsProto.current,
                            null,
                            null,
                            true
                          ).totalHours.toFixed(1)}{" "}
                          h
                        </td>
                        <td className="border border-dark">
                          <small>
                            {ctx?.extUsers.find(
                              (u) =>
                                `${u.id}` === `${tL.taskList?.extInChargeId}`
                            )?.username ??
                              `no spv ${tL.taskList?.extInChargeId ?? ""}`}{" "}
                            |{" "}
                            {[
                              ...new Set(
                                tL.taskList?.meetingTasks
                                  .map((mt) =>
                                    mt.meetingTaskInCharges.map(
                                      (c) => c.extUserId
                                    )
                                  )
                                  .flat()
                              ),
                            ]
                              .map(
                                (i) =>
                                  ctx?.extUsers.find(
                                    (u) => `${u.id}` === `${i}`
                                  )?.username ?? `no name (${i ?? ""})`
                              )
                              .join(",")}
                          </small>
                        </td>
                        <td className="border border-dark">
                          {tL.taskList?.taskStart} to {tL.taskList?.taskEnd}
                        </td>
                        <td className="border border-dark">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              setShowWorkOrderCleanupDetailModal(true);
                              if (tL.taskList) {
                                setWorkOrderCleanupDetail(tL);
                              }
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="xl"
        show={showPanelCodeRemarkModal}
        onHide={() => {
          setShowPanelCodeRemarkModal(false);
        }}
      >
        <ModalHeader>
          Remarks for panel code: {selectedPanelCodeForRemark?.type}:
          {selectedPanelCodeForRemark?.name}:{selectedPanelCodeForRemark?.qty}{" "}
          unit(s)
        </ModalHeader>
        <ModalBody>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                if (!selectedPanelCodeForRemark?.id) {
                  alert("Please save panel code first.");
                  return;
                }
                setState({
                  ...state,
                  job: state.job
                    ? {
                        ...state.job,
                        job: state.job.job
                          ? {
                              ...state.job.job,
                              panelCodes: state.job.job.panelCodes
                                ? state.job.job.panelCodes.map((cx) =>
                                    `${cx.uuid}` ===
                                    `${selectedPanelCodeForRemark?.uuid}`
                                      ? {
                                          ...cx,
                                          panelCodeRemarks: [
                                            ...(cx.panelCodeRemarks ?? []),
                                            {
                                              ...initialPanelCodeRemark,
                                              uuid: v4(),
                                            },
                                          ],
                                        }
                                      : cx
                                  )
                                : state.job.job.panelCodes,
                            }
                          : state.job.job,
                      }
                    : state.job,
                });
              }}
            >
              <small>
                <Add />
                Remark
              </small>
            </button>
          </div>
          <div
            className="overflow-auto border border-dark shadow shadow-md"
            style={{ height: "50vh", resize: "vertical" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["#", "Remark", "Done", "Created"].map((h) => (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
              </tr>
              {state.job.job?.panelCodes
                ?.find((c) => `${c.id}` === `${selectedPanelCodeForRemark?.id}`)
                ?.panelCodeRemarks?.map((r, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          <textarea
                            className="form-control form-control-sm"
                            key={`rmk-${r.remark}`}
                            defaultValue={r.remark ?? ""}
                            placeholder="Product remark..."
                            onBlur={(e) => {
                              setState({
                                ...state,
                                job: state.job
                                  ? {
                                      ...state.job,
                                      job: state.job.job
                                        ? {
                                            ...state.job.job,
                                            panelCodes: state.job.job.panelCodes
                                              ? state.job.job.panelCodes.map(
                                                  (cx) =>
                                                    `${cx.uuid}` ===
                                                    `${selectedPanelCodeForRemark?.uuid}`
                                                      ? {
                                                          ...cx,
                                                          panelCodeRemarks:
                                                            cx.panelCodeRemarks
                                                              ? cx.panelCodeRemarks.map(
                                                                  (rx) =>
                                                                    rx.uuid ===
                                                                    r.uuid
                                                                      ? {
                                                                          ...rx,
                                                                          remark:
                                                                            e
                                                                              .target
                                                                              .value,
                                                                        }
                                                                      : rx
                                                                )
                                                              : cx.panelCodeRemarks,
                                                        }
                                                      : cx
                                                )
                                              : state.job.job.panelCodes,
                                          }
                                        : state.job.job,
                                    }
                                  : state.job,
                              });
                            }}
                          />
                          {/* {r.remark} */}
                        </td>
                        <td className="border border-dark">
                          <input
                            type="checkbox"
                            checked={r.done ?? false}
                            onClick={() => {
                              setState({
                                ...state,
                                job: state.job
                                  ? {
                                      ...state.job,
                                      job: state.job.job
                                        ? {
                                            ...state.job.job,
                                            panelCodes: state.job.job.panelCodes
                                              ? state.job.job.panelCodes.map(
                                                  (cx) =>
                                                    `${cx.uuid}` ===
                                                    `${selectedPanelCodeForRemark?.uuid}`
                                                      ? {
                                                          ...cx,
                                                          panelCodeRemarks:
                                                            cx.panelCodeRemarks
                                                              ? cx.panelCodeRemarks.map(
                                                                  (rx) =>
                                                                    rx.uuid ===
                                                                    r.uuid
                                                                      ? {
                                                                          ...rx,
                                                                          done: !rx.done,
                                                                        }
                                                                      : rx
                                                                )
                                                              : cx.panelCodeRemarks,
                                                        }
                                                      : cx
                                                )
                                              : state.job.job.panelCodes,
                                          }
                                        : state.job.job,
                                    }
                                  : state.job,
                              });
                            }}
                          />
                        </td>

                        <td className="border border-dark">{r.createdAt}</td>
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      <Modal
        size="xl"
        show={openSNRectModal}
        onHide={() => {
          setOpenSNRectModal(false);
          setSelectedPanelCode(null);
        }}
      >
        <ModalHeader>
          SN Rect :{" "}
          {selectedRectSerialNumber ? (
            <>
              {getPanelCodeCompleteSerialNumber(
                selectedPanelCode,
                selectedRectSerialNumber
              )}
            </>
          ) : (
            <></>
          )}
          {selectedRectSerialNumberManual ? (
            <>{selectedRectSerialNumberManual.serialNumber}</>
          ) : (
            <></>
          )}
        </ModalHeader>
        <hr className="border border-dark" />
        <ModalBody>
          <div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  const newPanelCodeSerialNumberRect = {
                    ...defaultPanelCodeSerialNumberRect,
                    uuid: v4(),
                    extPanelCodeSerialNumberId:
                      selectedRectSerialNumber?.id ?? null,
                    extPanelCodeSerialNumberManualId:
                      selectedRectSerialNumberManual?.id ?? null,
                  } as PanelCodeSerialNumberRect;

                  setState({
                    ...state,
                    job: {
                      ...state.job,
                      job: state.job.job
                        ? {
                            ...state.job.job,
                            panelCodes:
                              state.job.job.panelCodes?.map((c) =>
                                `${c.id}` === `${selectedPanelCode?.id}`
                                  ? {
                                      ...c,
                                      panelCodeSerialNumberRects:
                                        c.panelCodeSerialNumberRects
                                          ? [
                                              ...c.panelCodeSerialNumberRects,
                                              newPanelCodeSerialNumberRect,
                                            ]
                                          : [newPanelCodeSerialNumberRect],
                                    }
                                  : c
                              ) ?? [],
                          }
                        : state.job.job,
                    },
                  });
                }}
              >
                Add
              </button>
            </div>
            <ol>
              {state.job.job?.panelCodes
                ?.find((c) => `${c.id}` === `${selectedPanelCode?.id}`)
                ?.panelCodeSerialNumberRects?.filter((r) =>
                  selectedRectSerialNumber
                    ? `${r.extPanelCodeSerialNumberId}` ===
                      `${selectedRectSerialNumber.id}`
                    : selectedRectSerialNumberManual
                    ? `${r.extPanelCodeSerialNumberManualId}` ===
                      `${selectedRectSerialNumberManual.id}`
                    : null
                )
                ?.map((r) => {
                  return (
                    <>
                      <li>
                        <div>
                          <div>
                            <input
                              className="form-control form-control-sm"
                              placeholder="SN Rect..."
                              defaultValue={r.name ?? ""}
                              key={r.uuid}
                              onBlur={(e) => {
                                setState({
                                  ...state,
                                  job: {
                                    ...state.job,
                                    job: state.job.job
                                      ? {
                                          ...state.job.job,
                                          panelCodes:
                                            state.job.job.panelCodes?.map((c) =>
                                              `${c.id}` ===
                                              `${selectedPanelCode?.id}`
                                                ? {
                                                    ...c,
                                                    panelCodeSerialNumberRects:
                                                      c.panelCodeSerialNumberRects?.map(
                                                        (rx) =>
                                                          `${rx.uuid}` ===
                                                          `${r.uuid}`
                                                            ? {
                                                                ...rx,
                                                                name: e.target
                                                                  .value,
                                                              }
                                                            : rx
                                                      ) ?? [],
                                                  }
                                                : c
                                            ) ?? [],
                                        }
                                      : state.job.job,
                                  },
                                });
                              }}
                            />
                            <small>{r.name}</small>
                            {/* {JSON.stringify({
                              auto: r.extPanelCodeSerialNumberId,
                              manual: r.extPanelCodeSerialNumberManualId,
                            })} */}
                          </div>
                        </div>
                      </li>
                    </>
                  );
                })}
            </ol>
          </div>
        </ModalBody>
      </Modal>
    </ThemeProvider>
  );
};

export default OutstandingJobForm;
