import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { IndividualEntity } from "../../../models/model";
import { initialIndividualEntity } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import SyncAutocomplete from "../../mini-components/CrudTable/components/SyncAutocomplete";

const CustomerFormPage = () => {
  const { id } = useParams() as any;
  const history = useHistory();

  // Main state
  const [customer, setCustomer] = useState<IndividualEntity>({
    ...initialIndividualEntity,
  });
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  useEffect(() => {
    if (id !== undefined) {
      if (!isNaN(parseInt(id))) {
        fetchCustomerData(parseInt(id));
      }
    }
  }, []);

  const fetchCustomerData = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/individualentities/${id}`
      );
      if (response.status !== 200) throw "Fetch customer error";

      setCustomer(await response.json());
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleSave = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/individualentities`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customer),
        }
      );

      if (response.status !== 201) throw "Error saving customer";
      setRequestStatus(RequestStatus.Success);
      history.push("/individualentities");
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleChangeCustomerName = (name: string) =>
    setCustomer({ ...customer, name: name });
  const handleChangeCustomerType = (type: string) =>
    setCustomer({ ...customer, type: type });

  return (
    <>
      <Box display="flex" alignItems="center">
        <Link to="/individualentities">
          <Button color="primary">
            <ChevronLeftIcon /> Back
          </Button>
        </Link>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
        <Box mx={1}>
          {requestStatus === RequestStatus.Loading ? (
            <CircularProgress disableShrink />
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <Box m={2}>
        <h3>Customer Data</h3>
        <Box display="flex">
          <Box>
            <TextField
              size="small"
              label="name"
              value={customer.name}
              onChange={(evt: any) =>
                handleChangeCustomerName(evt.target.value)
              }
            />
          </Box>
          <Box ml={2}>
            <SyncAutocomplete
              label="Customer Type"
              value={customer.type}
              options={["customer", "internal"]}
              onChange={(value: string) => handleChangeCustomerType(value)}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomerFormPage;
