import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  TextField,
} from "@material-ui/core";
import { blue, green, red } from "@material-ui/core/colors";
import { Print } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../App";
import { makeReadableDateStringIntl, secondsToHms } from "../../../helpers";
import {
  ExtUser,
  ProcessType,
  WorkOrderActualReject,
  WorkOrderActualRev,
  WorkOrderRev,
  WorkOrderRevWorker,
} from "../../../models/model";
import {
  initialWorkOrderActualReject,
  initialWorkOrderActualRev,
  initialWorkOrderRev,
  initialWorkOrderRevWorker,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import { ChevronLeftIcon } from "../../mini-components/Icons";

interface ActualInput {
  uuid: string; // Work order UUID
  qty: number;
  rejects: ActualInput[];
}

enum WoType {
  PartToPart = "PartToPart",
  FepMulti = "FepMulti",
}

const initialActualInput: ActualInput = { uuid: "", qty: 0, rejects: [] };

const WorkOrderRevActual = () => {
  const ctx = useContext(AppContext);
  const { date } = useParams() as { date: string };

  const [view, setView] = useState<"WorkOrder" | "Print">("WorkOrder");
  const [extendSummary, setExtendSummary] = useState(false);
  const [workOrders, setWorkOrders] = useState<WorkOrderRev[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [checkedUuids, setCheckedUuids] = useState<string[]>([]);
  const [actualInputs, setActualInputs] = useState<ActualInput[]>([]);
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [workOrderRevWorkerDeleteIds, setWorkOrderRevWorkerDeleteIds] =
    useState<number[]>([]);

  const [expandAll, setExpandAll] = useState(false);

  // Selection
  const [selectedProcessType, setSelectedProcessType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  useEffect(() => {
    if (requestStatus === RequestStatus.NotAsked && date !== undefined) {
      fetchWorkOrders(date);
      // fetchProcessTypes();
    }
  });

  const fetchWorkOrders = async (date: string) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/workorderrevs/bydate?date=${date}`
      );

      if (response.status !== 200)
        throw "Error fetching work order for " + date;

      const workOrdersJson: WorkOrderRev[] = await response.json();
      setWorkOrders(workOrdersJson);

      const newActualInputs: ActualInput[] = [...workOrdersJson].map(
        (workOrder: WorkOrderRev) => {
          if (workOrder.fepMulti !== null) {
            const foundFepDetail = workOrder.fepMulti.fepDetails.find(
              (fepDetail) =>
                fepDetail.processType?.name ===
                workOrder.machine?.processType?.name
            );

            return foundFepDetail
              ? {
                  uuid: workOrder.uuid,
                  qty: 0,
                  rejects: [{ uuid: foundFepDetail.uuid, qty: 0, rejects: [] }],
                }
              : initialActualInput;
          } else if (workOrder.partToPart !== null) {
            return {
              uuid: workOrder.uuid,
              qty: 0,
              rejects: workOrder.partToPart.partToPartDetails.map(
                (partToPartDetail) => ({
                  uuid: partToPartDetail.uuid,
                  qty: 0,
                  rejects: [],
                })
              ),
            };
          } else {
            return initialActualInput;
          }
        }
      );

      console.log(newActualInputs);

      setActualInputs([...newActualInputs]);
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  // const fetchProcessTypes = async () => {
  //   try {
  //     setRequestStatus(RequestStatus.Loading);

  //     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/processtypes`);
  //     if(response.status !== 200) throw "Error fetching process types.";

  //     const processTypes: ProcessType[] | null | undefined = await response.json();

  //     if(processTypes) {
  //       setProcessTypes({...processTypes});
  //     }

  //     setRequestStatus(RequestStatus.Success);
  //   } catch(e) {
  //     setRequestStatus(RequestStatus.Error);
  //   }
  // }

  const handleChangeActualDoneQty = (workOrderUuid: string, qty: string) => {
    // console.log('work order uuid:', workOrderUuid, 'qty:', qty)
    const newActualInputs = [...actualInputs];
    const foundActualInput = newActualInputs.find(
      (actualInput) => actualInput.uuid === workOrderUuid
    );

    const foundWorkOrder = workOrders.find(
      (workOrder) => workOrder.uuid === workOrderUuid
    );

    const needed = foundWorkOrder?.amount ? foundWorkOrder.amount : 0;
    const producedWrapped = foundWorkOrder?.workOrderActualRevs.reduce(
      (acc, workOrderActual) => acc + workOrderActual.amount,
      0
    );
    const produced = producedWrapped ? producedWrapped : 0;

    if (foundActualInput) {
      const parsedQty = isNaN(parseInt(qty)) ? 0 : parseInt(qty);

      // if (produced + parsedQty > needed) {
      //   foundActualInput.qty = 0;
      // } else {
      foundActualInput.qty = parsedQty;
      // }

      setActualInputs(newActualInputs);
    }
  };

  const handleChangeReject = (
    workOrderUuid: string,
    typeUuid: string,
    qty: string,
    type: WoType
  ) => {
    const newActualInputs = [...actualInputs];
    const foundActualInput = newActualInputs.find(
      (actualInput) => actualInput.uuid === workOrderUuid
    );
    const foundReject = foundActualInput?.rejects.find(
      (reject) => reject.uuid === typeUuid
    );

    console.log("found actual input:", foundActualInput);
    console.log("found reject: " + typeUuid, foundReject);

    if (foundReject !== undefined && !isNaN(parseInt(qty))) {
      foundReject.qty = parseInt(qty);
      console.log("new actual inputs:", newActualInputs);
      setActualInputs(newActualInputs);
    }
  };

  const handleAddActual = (workOrderUuid: string) => {
    const newWorkOrders = [...workOrders];
    const foundActualInput = actualInputs.find(
      (actualInput) => actualInput.uuid === workOrderUuid
    );
    const foundWorkOrder = newWorkOrders.find(
      (workOrder) => workOrder.uuid === workOrderUuid
    );
    const workOrderProcessType = foundWorkOrder?.machine?.processType?.name;

    console.log(
      "Adding actual:",
      foundActualInput,
      "Work order process type:",
      workOrderProcessType
    );

    const rejects: WorkOrderActualReject[] = foundActualInput
      ? foundActualInput.rejects.map((reject) => {
          const foundItem = (() => {
            if (
              foundWorkOrder?.machine?.processType?.name === "Punch" ||
              foundWorkOrder?.machine?.processType?.name === "LVD" ||
              foundWorkOrder?.machine?.processType?.name === "Trumpf"
            ) {
              const foundPartToPartDetail =
                foundWorkOrder?.partToPart?.partToPartDetails.find(
                  (partToPartDetail) => partToPartDetail.uuid === reject.uuid
                );
              return foundPartToPartDetail ? foundPartToPartDetail.item : null;
            } else {
              const foundFepMultiItem = foundWorkOrder?.fepMulti?.item;
              return foundFepMultiItem ? foundFepMultiItem : null;
            }
          })();

          return {
            ...initialWorkOrderActualReject,
            item: foundItem ? foundItem : null,
            qty: reject.qty,
            uuid: uuidv4(),
            workOrderActual: null,
          };
        })
      : [];

    const newWorkOrderActual: WorkOrderActualRev = {
      amount: foundActualInput ? foundActualInput.qty : 0,
      dateTime: new Date().toISOString(),
      id: 0,
      remark: "",
      uuid: uuidv4(),
      workOrder: null,
      workOrderActualRejects: rejects,
      workOrderRev: {
        ...initialWorkOrderRev,
        id: foundWorkOrder ? foundWorkOrder.id : 0,
      },
      createdAt: null,
      updatedAt: null,
      createdBy: null,
    };

    console.log("new work order actual: ", newWorkOrderActual);
    // console.log('rejects', rejects)

    if (workOrderProcessType === "Punch") {
    } else {
    }

    if (foundWorkOrder !== undefined) {
      foundWorkOrder.workOrderActualRevs = [
        newWorkOrderActual,
        ...foundWorkOrder.workOrderActualRevs,
      ];
      setWorkOrders(newWorkOrders);
    }
  };

  const handleSave = async () => {
    // For each work orders,
    const responses = await Promise.all(
      workOrders.map(async (workOrder) => {
        // save the work order remark first
        await fetch(
          `${ctx?.baseUrl}/workorderrevs/${workOrder.id}/save-remark`,
          {
            method: "post",
            headers: {
              authorization: ctx?.apiKey ?? "",
              "content-type": "application/json",
            },
            body: JSON.stringify({ remark: workOrder.remark }),
          }
        );

        // Post work order actuals, and each work order actuals:
        return await Promise.all([
          ...workOrder.workOrderActualRevs.map(async (workOrderActual) => {
            try {
              const actualResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL}/workorderactualrevs`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    ...workOrderActual,
                    workOrderRev: { ...initialWorkOrderRev, id: workOrder.id },
                  }),
                }
              );

              const savedActualResponse: WorkOrderActualRev =
                await actualResponse.json();

              // Post rejects:
              const rejectResponses = await Promise.all(
                workOrderActual.workOrderActualRejects.map(
                  async (actualReject) => {
                    const rejectResponse = await fetch(
                      `${process.env.REACT_APP_BASE_URL}/workorderactualrejects`,
                      {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          ...actualReject,
                          workOrderActualRev: {
                            ...initialWorkOrderActualRev,
                            id: savedActualResponse.id,
                          },
                        }),
                      }
                    );

                    return rejectResponse;
                  }
                )
              );

              return actualResponse;
            } catch (e) {
              console.log("Posting work order actual error!");
            }
          }),
          ...(workOrder.workOrderRevWorkers?.map(async (workOrderRevWorker) => {
            return await fetch(`${ctx?.baseUrl}/workorderrevworkers-save`, {
              method: "post",
              headers: {
                authorization: ctx?.apiKey ?? "",
                "content-type": "application/json",
              },
              body: JSON.stringify({
                ...workOrderRevWorker,
                workOrderRev: {
                  ...initialWorkOrderRev,
                  id: workOrder.id,
                },
              } as WorkOrderRevWorker),
            });
          }) ?? []),
        ]);
      })
    );

    // Delets
    await Promise.all([
      ...workOrderRevWorkerDeleteIds.map(async (id) => {
        return await fetch(`${ctx?.baseUrl}/workorderrevworkers/${id}/empty`, {
          method: "delete",
          headers: { authorization: ctx?.apiKey ?? "" },
        });
      }),
    ]);

    window.location.reload();
  };

  const ProcessView = (props: { workOrder: WorkOrderRev }) => {
    if (props.workOrder.fepMulti !== null) {
      const foundActualInput = actualInputs.find(
        (actualInput) => actualInput.uuid === props.workOrder.uuid
      );
      const amount = foundActualInput ? foundActualInput.qty : 0;

      const foundFepDetail = props.workOrder.fepMulti.fepDetails.find(
        (fepDetail) =>
          fepDetail.processType?.name ===
          props.workOrder.machine?.processType?.name
      );
      const foundFepDetailUuid = foundFepDetail ? foundFepDetail.uuid : "";
      const foundReject = foundActualInput?.rejects.find(
        (reject) => reject.uuid === foundFepDetailUuid
      );
      const rejectAmount = foundReject ? foundReject.qty : 0;

      return (
        <Box>
          <Box m={2} display="flex" alignItems="center">
            {props.workOrder.fepMulti.item?.partName ?? "No Name"}:&nbsp;
            <strong className="mx-2" style={{ color: blue[600] }}>
              {" "}
              (ID: #{props.workOrder.fepMulti.item?.id}){" "}
            </strong>
            <strong className="mx-2" style={{ color: green[600] }}>
              {props.workOrder.machine?.processType?.name === "Coat" ? (
                <>RAL: {props.workOrder.fepMulti.ral?.name ?? "No RAL"}</>
              ) : (
                <></>
              )}
            </strong>
            {props.workOrder.amount}&nbsp;|&nbsp; To insert:&nbsp;
            <strong>
              {/* {props.workOrder.fepMulti.qty * amount - rejectAmount} */}
              {amount - rejectAmount}
            </strong>
            &nbsp;|&nbsp; Rejects:&nbsp;
            <Box width={75}>
              <TextField
                size="small"
                variant="outlined"
                label="Qty"
                value={rejectAmount}
                onChange={(evt) =>
                  handleChangeReject(
                    props.workOrder.uuid,
                    foundFepDetailUuid,
                    evt.target.value,
                    WoType.FepMulti
                  )
                }
              />
            </Box>
            <Box>
              <span style={{ fontSize: 18 }}>
                Complete: 0/
                {props.workOrder.fepMulti.qty * props.workOrder.amount}
              </span>
            </Box>
          </Box>

          {props.workOrder.machine?.processType?.name === "Weld" ||
          props.workOrder.machine?.processType?.name === "Assembly" ? (
            <Box ml={3}>
              <Box>
                <strong>Building blocks:</strong>:
              </Box>
              <ul>
                {props.workOrder.fepMulti.children.map((fepMulti) => (
                  <li>
                    <Box>
                      {fepMulti.item?.partName} x{fepMulti.qty}
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      );
    } else if (props.workOrder.partToPart !== null) {
      return (
        <List>
          {props.workOrder.partToPart.partToPartDetails.map(
            (partToPartDetail) => {
              const foundActualInput = actualInputs.find(
                (actualInput) => actualInput.uuid === props.workOrder.uuid
              );
              const amount = foundActualInput ? foundActualInput.qty : 0;

              const foundReject = foundActualInput?.rejects.find(
                (reject) => reject.uuid === partToPartDetail.uuid
              );
              const rejectAmount = foundReject ? foundReject.qty : 0;

              // console.log('found reject:', foundReject)
              const completedAmount =
                props.workOrder.workOrderActualRevs.reduce(
                  (acc, workOrderActual) => {
                    const rejectAmountWrapped =
                      workOrderActual.workOrderActualRejects.find(
                        (reject) =>
                          reject.item?.partName ===
                          partToPartDetail.item?.partName
                      )?.qty;

                    const rejectAmount = rejectAmountWrapped
                      ? rejectAmountWrapped
                      : 0;

                    return (
                      acc +
                      workOrderActual.amount * partToPartDetail.qtyProduced -
                      rejectAmount
                    );
                  },
                  0
                );
              const assignedAmount =
                partToPartDetail.qtyProduced * props.workOrder.amount;

              return (
                <ListItem>
                  <strong>
                    {partToPartDetail.materialType?.name} (
                    {props.workOrder.partToPart?.length} x{" "}
                    {props.workOrder.partToPart?.width} x{" "}
                    {props.workOrder.partToPart?.height}){" : "}
                  </strong>{" "}
                  {partToPartDetail.item?.partName}{" "}
                  <strong className="mx-2" style={{ color: blue[600] }}>
                    {" "}
                    (ID: #{partToPartDetail.item?.id}){" "}
                  </strong>
                  {partToPartDetail.qtyProduced} x {props.workOrder.amount}{" "}
                  =&nbsp;
                  {assignedAmount}&nbsp;|&nbsp; To insert:&nbsp;
                  <strong>
                    {partToPartDetail.qtyProduced * amount - rejectAmount}
                  </strong>
                  &nbsp;|&nbsp; Rejects:&nbsp;
                  <Box width={75}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Qty"
                      value={rejectAmount}
                      onChange={(evt) =>
                        handleChangeReject(
                          props.workOrder.uuid,
                          partToPartDetail.uuid,
                          evt.target.value,
                          WoType.FepMulti
                        )
                      }
                    />
                  </Box>
                  <Box mx={2}>
                    <strong style={{ fontSize: 18 }}>
                      Complete: {completedAmount}/{assignedAmount}
                    </strong>
                  </Box>
                </ListItem>
              );
            }
          )}
        </List>
      );
    } else {
      return <></>;
    }
  };

  const handleDeleteWorkOrderActual = (
    workOrderUuid: string,
    workOrderActualUuid: string
  ) => {
    const newWorkOrders = [...workOrders];
    const foundWorkOrder = newWorkOrders.find(
      (workOrder) => workOrder.uuid === workOrderUuid
    );

    if (foundWorkOrder) {
      foundWorkOrder.workOrderActualRevs =
        foundWorkOrder.workOrderActualRevs.filter(
          (workOrderActual) => workOrderActual.uuid !== workOrderActualUuid
        );
    }

    setWorkOrders([...newWorkOrders]);
  };

  return (
    <Box>
      <Box display="flex" my={2}>
        <Box>
          <Button
            variant={view === "WorkOrder" ? "contained" : "outlined"}
            color="primary"
            onClick={(e) => {
              setView("WorkOrder");
            }}
          >
            Interactive View
          </Button>
        </Box>
        <Box>
          <Button
            variant={view === "Print" ? "contained" : "outlined"}
            color="primary"
            onClick={(e) => {
              setView("Print");
            }}
          >
            Print View
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" my={3}>
        <h3>
          Work Order Actuals for{" "}
          {date ? makeReadableDateStringIntl(new Date(date)) : <></>}
          {" | "}
          Total time:{" "}
          {(() => {
            const totalTime =
              workOrders
                .filter((workOrder) =>
                  selectedProcessType
                    ? workOrder.machine?.processType?.name ===
                      selectedProcessType?.label
                    : true
                )
                ?.reduce((acc, workOrder) => {
                  if (workOrder.fepMulti) {
                    return (
                      acc +
                      (workOrder.fepMulti.fepDetails.find(
                        (fepDetail) =>
                          fepDetail.processType?.name ===
                          workOrder?.machine?.processType?.name
                      )?.time ?? 0)
                    );
                  } else if (workOrder.partToPart) {
                    return acc + (workOrder.partToPart.timePerUnit ?? 0);
                  } else {
                    return acc;
                  }
                }, 0) ?? 0;

            return secondsToHms(totalTime);
          })()}
        </h3>
        <Box mx={2} style={{ width: "300px" }}>
          <Select
            placeholder="Filter process type..."
            options={[
              ...new Set(
                workOrders.map(
                  (workOrder) => workOrder.machine?.processType?.name
                )
              ),
            ]
              .filter(
                (processTypeName): processTypeName is string =>
                  processTypeName !== null && processTypeName !== undefined
              )
              .map((processTypeName) => ({
                label: processTypeName,
                value: processTypeName,
              }))}
            value={selectedProcessType}
            onChange={(val) => {
              setSelectedProcessType({
                label: (val as { label: string; value: string }).label,
                value: (val as { label: string; value: string }).value,
              });
            }}
          />
        </Box>
      </Box>

      <Box>
        <strong>Total WOs: {workOrders.length ?? 0}</strong>
      </Box>

      <Box display="flex">
        Part to Part Plate Summary{" "}
        <Switch
          value={extendSummary}
          onClick={() => {
            setExtendSummary(!extendSummary);
          }}
        />
        <Box mx={3}>
          Expand all
          <Switch
            onClick={(e) => {
              if (expandAll) {
                setExpandAll(false);
                setCheckedUuids([]);
              } else {
                setExpandAll(true);
                setCheckedUuids(workOrders.map((workOrder) => workOrder.uuid));
              }
            }}
            value={expandAll}
          />
        </Box>
      </Box>

      {extendSummary ? (
        <>
          <ul>
            {workOrders
              .filter((workOrder) =>
                ["HSG", "Punch", "Trumpf", "LVD"].includes(
                  workOrder?.machine?.processType?.name ?? ""
                )
              )
              .map((workOrder) => workOrder.partToPart?.partToPartDetails)
              .flat()
              .map((partToPartDetail) => partToPartDetail?.materialType)
              .filter(
                (value, index, self) =>
                  value &&
                  self.findIndex(
                    (materialType) => materialType?.id === value?.id
                  ) === index
              )
              .map((materialType) => {
                return (
                  <li>
                    <Box>
                      <Box>
                        <strong>{materialType?.name}</strong>
                      </Box>

                      {(() => {
                        const filteredWorkOrdersByMaterialType = workOrders
                          .filter(
                            (workOrder) =>
                              workOrder.partToPart?.partToPartDetails[0]
                                ?.materialType?.id === materialType?.id
                          )
                          .sort(
                            (a, b) =>
                              (a.partToPart?.height ?? 0) -
                              (b.partToPart?.height ?? 0)
                          );

                        return (
                          <Box>
                            <Box>
                              <ul>
                                {[
                                  ...new Set(
                                    filteredWorkOrdersByMaterialType.map(
                                      (workOrder) =>
                                        workOrder.partToPart?.height
                                    )
                                  ),
                                ].map((partToPartHeight) => {
                                  return (
                                    <li>
                                      <strong>{partToPartHeight}</strong>:{" "}
                                      {
                                        filteredWorkOrdersByMaterialType.filter(
                                          (workOrder) =>
                                            workOrder.partToPart?.height ===
                                            partToPartHeight
                                        )?.length
                                      }
                                      {" WOs"}
                                    </li>
                                  );
                                })}
                              </ul>
                            </Box>

                            <Box my={1}>
                              <hr style={{ backgroundColor: "black" }} />
                            </Box>

                            <ol>
                              {filteredWorkOrdersByMaterialType.map(
                                (workOrder) => {
                                  return (
                                    <li>
                                      <Box>
                                        {
                                          workOrder.partToPart
                                            ?.partToPartDetails[0]?.materialType
                                            ?.name
                                        }{" "}
                                        {workOrder.partToPart?.height}
                                        {" - "}
                                        {workOrder.partToPart?.name}
                                      </Box>
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          </Box>
                        );
                      })()}
                    </Box>
                  </li>
                );
              })}
          </ul>
        </>
      ) : (
        <></>
      )}

      {(() => {
        switch (view) {
          case "WorkOrder":
            return (
              <>
                <Box display="flex" flexDirection="row">
                  <Link to="/workordersrev">
                    <Button color="primary">
                      <ChevronLeftIcon /> Back
                    </Button>
                  </Link>
                  <Box mx={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>

                <Box my={1}>
                  {requestStatus === RequestStatus.Loading ? (
                    <CircularProgress disableShrink />
                  ) : (
                    <></>
                  )}
                </Box>

                <Box mt={1}>
                  {workOrders
                    // Sort by name
                    .sort((a, b) => {
                      const extractName = (workOrderRev: WorkOrderRev) =>
                        workOrderRev.partToPart
                          ? workOrderRev.partToPart.name
                          : workOrderRev.fepMulti?.item?.partName;

                      return (
                        extractName(a)?.toLowerCase() ?? ""
                      ).localeCompare(extractName(b)?.toLowerCase() ?? "");
                    })
                    .filter((workOrder) =>
                      selectedProcessType
                        ? workOrder.machine?.processType?.name ===
                          selectedProcessType?.label
                        : true
                    )
                    .map((workOrder, i) => {
                      const workOrderProcessType =
                        workOrder.machine?.processType?.name;
                      const completedAmount =
                        workOrder.workOrderActualRevs.reduce(
                          (acc, workOrderActual) =>
                            acc + workOrderActual.amount,
                          0
                        );

                      const color = (() => {
                        if (completedAmount < workOrder.amount) {
                          return red[100];
                        } else {
                          return green[100];
                        }
                      })();

                      return (
                        <Box my={1}>
                          <Paper elevation={2}>
                            <Box
                              onClick={() => {
                                if (!checkedUuids.includes(workOrder.uuid)) {
                                  setCheckedUuids([
                                    workOrder.uuid,
                                    ...checkedUuids,
                                  ]);
                                } else {
                                  const newCheckedUuids = [...checkedUuids];
                                  newCheckedUuids.splice(
                                    checkedUuids.findIndex(
                                      (uuid) => uuid === workOrder.uuid
                                    ),
                                    1
                                  );
                                  setCheckedUuids(newCheckedUuids);
                                }
                              }}
                              display="flex"
                              alignItems="center"
                              style={{
                                backgroundColor: color,
                                cursor: "pointer",
                              }}
                            >
                              <Box mx={1}>
                                <strong>{i + 1}</strong>
                              </Box>

                              <Box>
                                <Switch
                                  checked={checkedUuids.includes(
                                    workOrder.uuid
                                  )}
                                />
                                &nbsp;
                                <strong style={{ fontSize: "18px" }}>
                                  {workOrder.jobName}:&nbsp;
                                  {workOrder.fepMulti
                                    ? `${
                                        workOrder.fepMulti.name
                                      } (${secondsToHms(
                                        workOrder.fepMulti.fepDetails.find(
                                          (fepDetail) =>
                                            fepDetail.processType?.name ===
                                            workOrder.machine?.processType?.name
                                        )?.time ?? 0
                                      )})`
                                    : `${
                                        workOrder.partToPart?.name
                                      } (${secondsToHms(
                                        workOrder.partToPart?.timePerUnit ?? 0
                                      )})`}{" "}
                                  {workOrder.partToPart ? (
                                    <>
                                      (
                                      {
                                        workOrder.partToPart
                                          .partToPartDetails[0]?.materialType
                                          ?.name
                                      }{" "}
                                      {workOrder.partToPart.length} x{" "}
                                      {workOrder.partToPart.width} x{" "}
                                      {workOrder.partToPart.height})
                                    </>
                                  ) : (
                                    <></>
                                  )}{" "}
                                  &nbsp;|&nbsp; Complete:{" "}
                                  {workOrder.workOrderActualRevs.reduce(
                                    (acc, workOrderActual) =>
                                      acc + workOrderActual.amount,
                                    0
                                  )}
                                  /{workOrder.amount}
                                </strong>{" "}
                                | {workOrder.machine?.processType?.name} |
                                worker(s):{" "}
                                {workOrder.workOrderRevWorkers
                                  ?.map((worker) => {
                                    const foundUser = ctx?.extUsers.find(
                                      (extUser) =>
                                        extUser.id === worker.extUserId
                                    );

                                    return `${foundUser?.name ?? "No Name"} (${
                                      foundUser?.departmentName ?? "No Dept"
                                    })`;
                                  })
                                  ?.join(", ")}
                              </Box>
                            </Box>

                            <Collapse
                              in={checkedUuids.includes(workOrder.uuid)}
                            >
                              <Box display="flex" my={2} mx={3}>
                                <Box
                                  width={400}
                                  // style={{ backgroundColor: "red" }}
                                >
                                  <Autocomplete
                                    options={ctx?.extUsers ?? []}
                                    size="small"
                                    onChange={(
                                      _: any,
                                      user: ExtUser | null
                                    ) => {
                                      if (
                                        user &&
                                        !workOrder.workOrderRevWorkers?.find(
                                          (workOrderRevWorker) =>
                                            workOrderRevWorker.extUserId ===
                                            user.id
                                        )
                                      ) {
                                        setWorkOrders(
                                          workOrders.map((workOrderX, ix) =>
                                            workOrderX.uuid === workOrder.uuid
                                              ? {
                                                  ...workOrderX,
                                                  workOrderRevWorkers:
                                                    workOrderX.workOrderRevWorkers
                                                      ? [
                                                          ...workOrderX.workOrderRevWorkers,
                                                          {
                                                            ...initialWorkOrderRevWorker,
                                                            uuid: uuidv4(),
                                                            extUserId:
                                                              user?.id ?? null,
                                                          },
                                                        ]
                                                      : workOrderX.workOrderRevWorkers,
                                                }
                                              : workOrderX
                                          )
                                        );
                                      }
                                    }}
                                    getOptionLabel={(user) =>
                                      `${user.name} (${user.departmentName})`
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        // fullWidth
                                        variant="outlined"
                                        label="Select Worker"
                                        {...params}
                                      />
                                    )}
                                  />
                                </Box>
                                <Box display="flex" alignItems="center">
                                  <Box mx={2}>Actual time: </Box>
                                  <Box mx={1} width={50}>
                                    <TextField
                                      value={workOrder.actualH ?? 0}
                                      onChange={(e) => {
                                        setWorkOrders(
                                          workOrders.map((workOrderX, ix) =>
                                            workOrderX.uuid === workOrder.uuid
                                              ? {
                                                  ...workOrderX,
                                                  actualH: !isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                    ? parseInt(e.target.value)
                                                    : workOrderX.actualH,
                                                }
                                              : workOrderX
                                          )
                                        );
                                      }}
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                    />
                                  </Box>
                                  <Box>h</Box>
                                  <Box mx={1} width={50}>
                                    <TextField
                                      value={workOrder.actualM ?? 0}
                                      onChange={(e) => {
                                        setWorkOrders(
                                          workOrders.map((workOrderX, ix) =>
                                            workOrderX.uuid === workOrder.uuid
                                              ? {
                                                  ...workOrderX,
                                                  actualM: !isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                    ? parseInt(e.target.value)
                                                    : workOrderX.actualM,
                                                }
                                              : workOrderX
                                          )
                                        );
                                      }}
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                    />
                                  </Box>
                                  <Box>m</Box>
                                  <Box mx={1} width={50}>
                                    <TextField
                                      value={workOrder.actualS ?? 0}
                                      onChange={(e) => {
                                        setWorkOrders(
                                          workOrders.map((workOrderX, ix) =>
                                            workOrderX.uuid === workOrder.uuid
                                              ? {
                                                  ...workOrderX,
                                                  actualS: !isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                    ? parseInt(e.target.value)
                                                    : workOrderX.actualS,
                                                }
                                              : workOrderX
                                          )
                                        );
                                      }}
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                    />
                                  </Box>
                                  <Box>s</Box>
                                </Box>
                              </Box>
                              <Box display="flex" m={2} flexWrap="wrap">
                                {workOrder.workOrderRevWorkers?.map(
                                  (workOrderRevWorker) => {
                                    return (
                                      <Box
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setWorkOrders(
                                            workOrders.map((workOrderX, ix) =>
                                              workOrderX.uuid === workOrder.uuid
                                                ? {
                                                    ...workOrderX,
                                                    workOrderRevWorkers:
                                                      workOrderX.workOrderRevWorkers
                                                        ? workOrderX.workOrderRevWorkers.filter(
                                                            (
                                                              workOrderRevWorkerX
                                                            ) =>
                                                              workOrderRevWorker.uuid !==
                                                              workOrderRevWorkerX.uuid
                                                          )
                                                        : workOrderX.workOrderRevWorkers,
                                                  }
                                                : workOrderX
                                            )
                                          );

                                          setWorkOrderRevWorkerDeleteIds([
                                            ...workOrderRevWorkerDeleteIds,
                                            workOrderRevWorker.id ?? 0,
                                          ]);
                                        }}
                                        className="border-2 border-dark rounded px-2 py-1 mx-2"
                                      >
                                        {(() => {
                                          const foundUser = ctx?.extUsers.find(
                                            (extUser) =>
                                              extUser.id ===
                                              workOrderRevWorker.extUserId
                                          );

                                          return `${foundUser?.name} (${foundUser?.departmentName})`;
                                        })()}
                                      </Box>
                                    );
                                  }
                                )}
                              </Box>
                              <Divider />

                              <Box m={1}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  multiline
                                  label="Remark"
                                  value={workOrder?.remark}
                                  onChange={(e) => {
                                    setWorkOrders(
                                      workOrders.map((workOrderX, ix) =>
                                        ix === i
                                          ? {
                                              ...workOrderX,
                                              remark: e.target.value,
                                            }
                                          : workOrderX
                                      )
                                    );
                                  }}
                                />
                              </Box>

                              <Divider />
                              <Box display="flex" m={2} alignItems="flex-end">
                                <Box mx={2}>Done amount:</Box>
                                <Box width={75}>
                                  <TextField
                                    size="small"
                                    variant="outlined"
                                    label="Qty"
                                    value={
                                      actualInputs.find(
                                        (actualInput) =>
                                          actualInput.uuid === workOrder.uuid
                                      )?.qty
                                    }
                                    onChange={(evt) =>
                                      handleChangeActualDoneQty(
                                        workOrder.uuid,
                                        evt.target.value
                                      )
                                    }
                                  />
                                </Box>
                              </Box>
                              <ProcessView workOrder={workOrder} />
                              <Box m={1}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleAddActual(workOrder.uuid)
                                  }
                                >
                                  Add Actual
                                </Button>
                              </Box>
                              <Divider />
                              <Box m={2}>
                                Actual History:
                                <Grid container direction="row">
                                  {workOrder.workOrderActualRevs.map(
                                    (workOrderActual) => (
                                      <Box
                                        p={2}
                                        m={1}
                                        style={{
                                          border: "1px solid black",
                                          display: "inline",
                                        }}
                                      >
                                        <strong style={{ fontSize: "20px" }}>
                                          {workOrderActual.amount}x
                                        </strong>{" "}
                                        {workOrderActual.dateTime
                                          ? Intl.DateTimeFormat(
                                              navigator.language ?? "en-US",
                                              {
                                                dateStyle: "medium",
                                                timeStyle: "long",
                                              } as any
                                            ).format(
                                              new Date(workOrderActual.dateTime)
                                            )
                                          : "No Date"}
                                        <Box>
                                          <Button
                                            size="small"
                                            color="secondary"
                                            variant="contained"
                                            onClick={() =>
                                              handleDeleteWorkOrderActual(
                                                workOrder.uuid,
                                                workOrderActual.uuid
                                              )
                                            }
                                          >
                                            Delete
                                          </Button>
                                          <List>
                                            {(() => {
                                              if (
                                                workOrder.machine?.processType
                                                  ?.name === "Punch" ||
                                                workOrder.machine?.processType
                                                  ?.name === "LVD" ||
                                                workOrder.machine?.processType
                                                  ?.name === "Trumpf"
                                              ) {
                                                return workOrder.partToPart?.partToPartDetails.map(
                                                  (partToPartDetail) => (
                                                    <ListItem>
                                                      {
                                                        partToPartDetail.item
                                                          ?.partName
                                                      }{" "}
                                                      | reject:&nbsp;
                                                      {
                                                        workOrderActual.workOrderActualRejects.find(
                                                          (reject) =>
                                                            reject.item
                                                              ?.partName ===
                                                            partToPartDetail
                                                              .item?.partName
                                                        )?.qty
                                                      }
                                                    </ListItem>
                                                  )
                                                );
                                              } else {
                                                return (
                                                  <ListItem>
                                                    {
                                                      workOrder.fepMulti?.item
                                                        ?.partName
                                                    }{" "}
                                                    | reject:&nbsp;
                                                    {
                                                      workOrderActual.workOrderActualRejects.find(
                                                        (reject) =>
                                                          reject.item
                                                            ?.partName ===
                                                          workOrder.fepMulti
                                                            ?.item?.partName
                                                      )?.qty
                                                    }
                                                  </ListItem>
                                                );
                                              }
                                            })()}
                                          </List>
                                        </Box>
                                      </Box>
                                    )
                                  )}
                                </Grid>
                              </Box>
                            </Collapse>
                          </Paper>
                        </Box>
                      );
                    })}
                </Box>
              </>
            );

          case "Print":
            return (
              <>
                <Box>
                  <Button
                    onClick={() => {
                      window.print();
                    }}
                    variant="outlined"
                    size="small"
                  >
                    <Print fontSize="small" /> Print
                  </Button>
                </Box>
                <Table size="sm" className=" shadow-sm">
                  <thead>
                    <tr>
                      {[
                        "#",
                        "Project",
                        "Process Type",
                        "Machine",
                        "Program",
                        "Program Run",
                        "Plate Type",
                        "RAL",
                        "Part Name",
                        "Qty",
                        "Length",
                        "Width",
                        "Height",
                        "PICs",
                      ].map((head) => (
                        <th className="border border-dark">{head}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {workOrders
                      .map((workOrder, i) => {
                        if (workOrder.fepMulti) {
                          return [
                            {
                              projectName: workOrder.jobName,
                              processName: workOrder.machine?.processType?.name,
                              machine: workOrder.machine?.name,
                              program: null,
                              partName: workOrder.fepMulti.item?.partName,
                              programRun: workOrder.amount,
                              qty: null,
                              length: workOrder.fepMulti.length,
                              width: workOrder.fepMulti.width,
                              height: null,
                              materialType: null,
                              ral: workOrder.fepMulti.ral,
                              workers: workOrder.workOrderRevWorkers?.map(
                                (worker) => {
                                  const foundUser = ctx?.extUsers.find(
                                    (extUser) => extUser.id === worker.extUserId
                                  );

                                  return `${foundUser?.name ?? "No Name"} (${
                                    foundUser?.departmentName ?? "No Dept"
                                  })`;
                                }
                              ),
                            },
                          ];
                        } else if (workOrder.partToPart) {
                          return (
                            workOrder.partToPart.partToPartDetails.map(
                              (partToPartDetail) => ({
                                projectName: workOrder.jobName,
                                processName:
                                  workOrder.machine?.processType?.name,
                                machine: workOrder.machine?.name,
                                program: workOrder.partToPart?.name,
                                partName: partToPartDetail.item?.partName,
                                programRun: workOrder.amount,

                                qty: null,

                                length: partToPartDetail.length,
                                width: partToPartDetail.width,
                                height: null,
                                materialType: `${partToPartDetail.materialType?.name} (${workOrder.partToPart?.length} x ${workOrder.partToPart?.width} x ${workOrder.partToPart?.height})`,
                                ral: null,
                                workers: workOrder.workOrderRevWorkers?.map(
                                  (worker) => {
                                    const foundUser = ctx?.extUsers.find(
                                      (extUser) =>
                                        extUser.id === worker.extUserId
                                    );

                                    return `${foundUser?.name ?? "No Name"} (${
                                      foundUser?.departmentName ?? "No Dept"
                                    })`;
                                  }
                                ),
                              })
                            ) ?? []
                          );
                        } else {
                          return null;
                        }
                      })
                      .flat()
                      .map((ob, i) => {
                        return (
                          <tr>
                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark">
                              {ob?.projectName}
                            </td>
                            <td className="border border-dark">
                              {ob?.processName}
                            </td>
                            <td className="border border-dark">
                              {ob?.machine}
                            </td>
                            <td className="border border-dark">
                              {ob?.program}
                            </td>
                            <td className="border border-dark">
                              {["Trumpf", "LVD", "HSG", "Punch"].includes(
                                ob?.processName ?? ""
                              )
                                ? ob?.programRun
                                : ""}
                            </td>

                            <td className="border border-dark">
                              {ob?.materialType}
                            </td>
                            <td className="border border-dark">
                              {ob?.processName === "Coat" ? ob?.ral?.name : ""}
                            </td>
                            <td className="border border-dark">
                              {ob?.partName}
                            </td>
                            <td className="border border-dark">
                              {!["Trumpf", "LVD", "HSG", "Punch"].includes(
                                ob?.processName ?? ""
                              )
                                ? ob?.programRun
                                : ""}
                            </td>
                            <td className="border border-dark">{ob?.length}</td>
                            <td className="border border-dark">{ob?.width}</td>
                            <td className="border border-dark">{ob?.height}</td>
                            <td className="border border-dark">
                              <ul>
                                {ob?.workers?.map((worker) => (
                                  <li>{worker}</li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </>
            );
        }
      })()}
    </Box>
  );
};

export default WorkOrderRevActual;
