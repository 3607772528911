import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Uom } from '../../../models/model';
import { initialUom } from '../../../models/modelinitials';
import { RequestStatus } from '../../../models/RequestStatus';

const UomForm = () => {
  const { id } = useParams() as any;
  const history = useHistory();

  const [uom, setUom] = useState<Uom>({...initialUom})
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NotAsked);

  useEffect(() => {
    if(id && !isNaN(parseInt(id)))
      fetchUom(parseInt(id));
  }, [])

  const fetchUom = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const apiKey = localStorage.getItem("apiKey");
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/uoms/${id}`, {
        headers: { authorization : apiKey ? apiKey : "" }
      });
      if(response.status !== 200) throw "Error fetching";
      setUom({...await response.json()});
      setRequestStatus(RequestStatus.Success);
    } catch(e) {
      setRequestStatus(RequestStatus.Error);
      console.log(e);
    }
  }

  const saveUom = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const apiKey = localStorage.getItem("apiKey");
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/uoms`, {
        method: "POST",
        headers: { "content-type": "application/json", "authorization": apiKey ? apiKey : "" },
        body: JSON.stringify({...uom})
      })
      if(response.status !== 201) throw "Saving uom error";

      setRequestStatus(RequestStatus.Success);
      history.push("/uoms")
    } catch(e) {
      setRequestStatus(RequestStatus.Error);
      console.log(e);
    }
  }

  return (
    <Box>
      <Box display="flex">
        <Button 
          color="primary"
        >
          <ChevronLeftIcon /> Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={saveUom}
        >
          Save
        </Button>
        { requestStatus === RequestStatus.Loading ? <CircularProgress disableShrink /> : <></> }
      </Box>

      <h2>Unit of Measurement</h2>
      
      <TextField 
        label="Name"
        value={uom.name}
        onChange={evt => setUom({ ...uom, name: evt.target.value })}
      />
    </Box>
  )
}

export default UomForm;