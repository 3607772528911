import React from "react";

const ProblemCatalogPage = () => {
  return (
    <>
      <div>
        <div>
          <div>
            <h5>Problem Catalog</h5>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <div className=""></div> 
        </div>
      </div>
    </>
  );
};

export default ProblemCatalogPage;
