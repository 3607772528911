import React from "react";

const ProjectDocumentIntegrationPage = () => {
  return (
    <>
      <div className="m-3">
        <div>
          <h4>Project Document Integration</h4>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div style={{ height: "75vh", width: "95vw" }}>
          <iframe
            style={{ height: "75vh", width: "100vw" }}
            src={`${process.env.REACT_APP_MEETING_UI_URL}/?app=PPIC#/projectdocumentintegration`}
          />
        </div>{" "}
      </div>
    </>
  );
};

export default ProjectDocumentIntegrationPage;
