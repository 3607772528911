import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Switch,
  TextField,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CheckRequestStatus } from "../../../helpers";
import { Role, User } from "../../../models/model";
import { initialUser } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";

const UserForm = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const apiKey = localStorage.getItem("apiKey");

  const [user, setUser] = useState<User>({ ...initialUser });
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  // Selections
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");

  useEffect(() => {
    if (id !== undefined) {
      if (!isNaN(parseInt(id))) {
        fetchUserData(parseInt(id));
      }
    }
  }, []);

  const fetchUserData = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/${id}`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Error";
      setUser(await response.json());
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleSave = async () => {
    // props.saveUserData(props.userData.data)
    const passwordMatches = user.password === passwordConfirm;

    if (changePassword) {
      if (passwordMatches) {
        saveUser();
      } else {
        alert("Passwords do not match.");
      }
    } else {
      saveUser();
    }
  };

  const saveUser = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/users/save`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: apiKey ? apiKey : "",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.status !== 201) throw "Error";

      setRequestStatus(RequestStatus.Success);
      history.push("/users");
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleChangeSelectedRole = (role: Role) => setSelectedRole(role);
  const handleDeleteRole = (roleToDelete: Role) => {
    const newRoles = [...user.roles];
    newRoles.splice(
      newRoles.findIndex((role) => role.name === roleToDelete.name),
      1
    );
    setUser({ ...user, roles: newRoles });
  };
  const handleAddSelectedRole = () => {
    const newRoles = [...user.roles];

    if (
      selectedRole !== null &&
      user.roles.find((role) => role.name === selectedRole.name) === undefined
    ) {
      newRoles.unshift(selectedRole);
      setUser({ ...user, roles: newRoles });
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box>
          <Link to="/users">
            <Button color="primary">
              <ChevronLeftIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
        <Box mx={1}>
          <CheckRequestStatus requestStatus={requestStatus} />
        </Box>
      </Box>
      <Box ml={3}>
        <h3>User Information</h3>
        <Box mt={3} display="flex">
          <Box>
            <TextField
              value={user.username}
              onChange={(e) => setUser({ ...user, username: e.target.value })}
              size="small"
              label="Username"
            />
          </Box>
          <Box ml={3}>
            <TextField
              value={user.name}
              onChange={(e) => setUser({ ...user, name: e.target.value })}
              size="small"
              label="Name"
            />
          </Box>
        </Box>
        <Box mt={3}>
          <h3>Roles</h3>
          <Box display="flex" alignItems="end">
            <Box width={300}>
              <AsyncSelectWrapper
                label="Role"
                url={`${process.env.REACT_APP_BASE_URL}/roles/search`}
                param="name"
                value={selectedRole}
                onChange={(selected) => setSelectedRole(selected)}
              />
            </Box>
            <Box mx={2}>
              <Button
                onClick={() => handleAddSelectedRole()}
                size="small"
                variant="outlined"
                color="primary"
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box style={{ width: "300px" }}>
            <List>
              {user.roles.map((role) => {
                return (
                  <>
                    <ListItem button onClick={() => handleDeleteRole(role)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      {role === null ? "" : role.name}
                    </ListItem>
                  </>
                );
              })}
            </List>
          </Box>
        </Box>
        <div className="d-flex">
          <div>Job entry allowed?</div>
          <div>
            <input
              type="checkbox"
              checked={user.jobEntryAllowed ? true : false}
              onClick={() => {
                setUser({
                  ...user,
                  jobEntryAllowed: user.jobEntryAllowed ? false : true,
                });
              }}
            />
          </div>
        </div>
        <hr className="border border-dark" />
        <Box mt={3}>
          {isNaN(parseInt(id ? id : "NaN")) ? (
            <h3>Password</h3>
          ) : (
            <Box display="flex" alignItems="center">
              <h3>Change Password?</h3>
              <Box ml={2}>
                <Switch
                  checked={changePassword}
                  onClick={() => {
                    setChangePassword(true);
                    setUser({ ...user, password: "" });
                  }}
                />
              </Box>
            </Box>
          )}
          {changePassword || isNaN(parseInt(id ? id : "NaN")) ? (
            <Box display="flex">
              <Box>
                <TextField
                  label="Password"
                  type="password"
                  value={user.password}
                  onChange={(e) =>
                    setUser({ ...user, password: e.target.value })
                  }
                ></TextField>
              </Box>
              <Box ml={2}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                ></TextField>
              </Box>
            </Box>
          ) : (
            <></>
          )}
          {changePassword && user.password !== passwordConfirm ? (
            <strong style={{ color: red[500] }}>Passwords do not match!</strong>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default UserForm;
