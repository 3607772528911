import React from 'react'
import { Machine } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const MachinePage = (props: any) => {
  const mapperFunc = (machine: Machine) => {
    return {
      id: machine.id,
      content: [ machine.name, (machine.processType ? machine.processType.name: ''), machine.purchasedDate, machine.description ]
    }
  }

  return (
    <StatefulCrudTable<Machine>  
      url="machines"
      head={['Name', 'Process Type', 'Purchased Date', 'Description']}
      mapper={mapperFunc}
    />
  )
}

export default MachinePage