import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchHolidaysProto } from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicHoliday,
  PpicHolidays,
} from "../../../masterbigsystem";

const HolidayCalendarPage = () => {
  const ctx = useContext(AppContext);
  const [holidays, setHolidays] = useState(
    PpicHolidays.fromPartial({ holidays: [] })
  );

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    handleFetchHolidaysData();
  };

  const handleFetchHolidaysData = async () => {
    setHolidays(await fetchHolidaysProto({ apiKey: ctx?.apiKey ?? "" }));
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/holidays`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: ctx?.apiKey ?? "",
        },
        body: JSON.stringify(PpicHolidays.toJSON(holidays)),
      });
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>Holiday Calendar</h4>
          </div>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={async () => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <button
            onClick={() => {
              setHolidays({
                ...holidays,
                holidays: [
                  ...holidays.holidays,
                  PpicHoliday.fromPartial({
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: v4(),
                    }),
                  }),
                ],
              });
            }}
            className="btn btn-sm btn-primary"
          >
            <Add />
            Add
          </button>
        </div>
        <div
          className="border border-dark shadow shadow-md overflow-auto"
          style={{
            height: "75vh",
          }}
        >
          <table
            className="table table-sm table-bordered"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "Template Name", "Name", "Date"].map((h) => (
                <>
                  <th
                    className="bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              ))}
            </tr>
            {(() => {
              const d = holidays.holidays;

              d.sort((a, b) => b.date?.localeCompare(a.date ?? "") ?? 0);

              return d;
            })().map((h, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <input
                        className="form-control form-control-sm"
                        placeholder="Template Name..."
                        defaultValue={h.templateName}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            setHolidays({
                              ...holidays,
                              holidays: holidays.holidays.map((hx, ix) =>
                                ix === i
                                  ? { ...hx, templateName: e.target.value }
                                  : hx
                              ),
                            });
                          }
                        }}
                      />
                      {h.templateName}
                    </td>
                    <td className="border border-dark">
                      <input
                        className="form-control form-control-sm"
                        placeholder="Name..."
                        defaultValue={h.name}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            setHolidays({
                              ...holidays,
                              holidays: holidays.holidays.map((hx, ix) =>
                                ix === i ? { ...hx, name: e.target.value } : hx
                              ),
                            });
                          }
                        }}
                      />
                      {h.name}
                    </td>
                    <td className="border border-dark">
                      <input
                        className="form-control form-control-sm"
                        type="date"
                        placeholder="Date..."
                        defaultValue={h.date}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            setHolidays({
                              ...holidays,
                              holidays: holidays.holidays.map((hx, ix) =>
                                ix === i ? { ...hx, date: e.target.value } : hx
                              ),
                            });
                          }
                        }}
                      />
                      {(() => {
                        try {
                          return Intl.DateTimeFormat("id-ID", {
                            dateStyle: "long",
                          }).format(new Date(h.date ?? ""));
                        } catch (e) {
                          return "";
                        }
                      })()}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default HolidayCalendarPage;
