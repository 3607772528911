import { Box } from "@material-ui/core";
import { rawListeners } from "process";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AppContext } from "../../../App";
import {
  CheckRequestStatus,
  days,
  DoneStatusCheck,
  makeDateString,
  makeReadableDateString,
} from "../../../helpers";
import { User, UserWiringView } from "../../../models/model";
import { RequestStatus } from "../../../models/RequestStatus";
import { ChevronLeftIcon, ChevronRightIcon } from "../../mini-components/Icons";

type MoveMonth = "Next" | "Prev";

const WiringManpowerAssignmentPage = () => {
  const apiKey = localStorage.getItem("apiKey");

  const ctx = useContext(AppContext);

  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [state, setState] = useState({
    selectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    requestStatus: RequestStatus.NotAsked,
    users: [] as Array<UserWiringView>,
    searchInput: "",
    unassignedWorkerInfo: {
      date: "",
      dialogOpen: false,
    },
  });

  const lastDate = new Date(
    state.selectedDate.getFullYear(),
    state.selectedDate.getMonth() + 1,
    0
  );

  const start = makeDateString(
    new Date(
      state.selectedDate.getFullYear(),
      state.selectedDate.getMonth(),
      state.selectedDate.getDate() - 1
    )
  );
  const end = makeDateString(
    new Date(
      lastDate.getFullYear(),
      lastDate.getMonth(),
      lastDate.getDate() + 1
    )
  );

  const dateNowString = makeDateString(new Date());

  useEffect(() => {
    if (state.requestStatus === RequestStatus.NotAsked) {
      fetchUsers();
    }
  });

  const fetchUsers = async () => {
    try {
      (async () => {
        setState({ ...state, requestStatus: RequestStatus.Loading });

        const response = await fetch(
          encodeURI(
            `${process.env.REACT_APP_BASE_URL}/roles/byname/Production Worker/users/wiringworkorder?start=${start}&end=${end}`
          ),
          {
            headers: { authorization: apiKey ? apiKey : "" },
          }
        );

        const usersResponse = await response.json();

        setState({
          ...state,
          users: usersResponse,
          requestStatus: RequestStatus.Success,
        });
      })();

      const [usersAll] = await Promise.all([fetchAllUsers()]);

      setAllUsers(usersAll);
    } catch (e) {
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  const fetchAllUsers = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/users`, {
        headers: {
          authorization: ctx?.apiKey ?? "",
        },
      });

      if (resp.status !== 200) throw await resp.text();

      return (await resp.json()) as User[];
    } catch (e) {
      return [];
    }
  };

  const handleMoveMonth = (move: MoveMonth) => {
    switch (move) {
      case "Next":
        setState({
          ...state,
          requestStatus: RequestStatus.NotAsked,
          selectedDate: new Date(
            state.selectedDate.getFullYear(),
            state.selectedDate.getMonth() + 1,
            state.selectedDate.getDate()
          ),
        });
        break;

      case "Prev":
        setState({
          ...state,
          requestStatus: RequestStatus.NotAsked,
          selectedDate: new Date(
            state.selectedDate.getFullYear(),
            state.selectedDate.getMonth() - 1,
            state.selectedDate.getDate()
          ),
        });
        break;
    }
  };

  return (
    <Box>
      Wiring manpower assignment page.{" "}
      {makeReadableDateString(state.selectedDate)} to{" "}
      {makeReadableDateString(lastDate)}
      <div className="d-flex flex-wrap flex-lg-nowrap my-2 align-items-center">
        <div>
          <Button
            className="text-nowrap"
            size="sm"
            variant="outline-info"
            color="primary"
            onClick={() => handleMoveMonth("Prev")}
          >
            <ChevronLeftIcon /> Prev Month
          </Button>
        </div>
        <div>
          <Button
            className="text-nowrap"
            size="sm"
            variant="outline-info"
            color="primary"
            onClick={() => handleMoveMonth("Next")}
          >
            Next Month <ChevronRightIcon />
          </Button>
        </div>
        <Form.Control
          className="ml-2"
          size="sm"
          placeholder="Filter by name..."
          onChange={(e) =>
            setState({
              ...state,
              searchInput: e.target.value,
            })
          }
        />
        <div className="ml-2">
          <CheckRequestStatus requestStatus={state.requestStatus} />
        </div>
      </div>
      <div
        style={{
          height: "75vh",
          resize: "vertical",
          overflow: "auto",
          border: "2px solid gray",
        }}
      >
        <Table size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "darkblue",
                zIndex: 1,
              }}
            >
              <th
                className="text-light text-center align-middle"
                style={{
                  position: "sticky",
                  top: 0,
                  left: 0,
                  backgroundColor: "darkblue",
                  border: "2px solid white",
                }}
              >
                Name
              </th>
              {[...Array(lastDate.getDate()).keys()]
                .map((date) => date + 1)
                .map((date) => {
                  const dateString = makeDateString(
                    new Date(
                      state.selectedDate.getFullYear(),
                      state.selectedDate.getMonth(),
                      date
                    )
                  );

                  return (
                    <th>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          backgroundColor:
                            dateString === dateNowString ? "lightblue" : "",
                        }}
                      >
                        <div>
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() =>
                              setState({
                                ...state,
                                unassignedWorkerInfo: {
                                  ...state.unassignedWorkerInfo,
                                  date: dateString,
                                  dialogOpen: true,
                                },
                              })
                            }
                          >
                            Check
                          </Button>
                        </div>
                        <div className="text-light">
                          {days[new Date(dateString).getDay()]}
                        </div>
                        <div className="text-light">{date}</div>
                      </div>
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {state.users
              .filter((user) =>
                user.name
                  .toLowerCase()
                  .includes(state.searchInput.toLowerCase())
              )
              .map((user) => {
                return (
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "whitesmoke",
                        border: "1px solid black",
                      }}
                      className="text-center align-middle"
                    >
                      <strong>{user.name}</strong>
                    </td>
                    {[...Array(lastDate.getDate()).keys()]
                      .map((date) => date + 1)
                      .map((date) => {
                        const dateString = makeDateString(
                          new Date(
                            state.selectedDate.getFullYear(),
                            state.selectedDate.getMonth(),
                            date
                          )
                        );

                        const filteredWiringWorkOrderByDate =
                          user.wiringWorkOrders.filter(
                            (wiringWorkOrder) =>
                              wiringWorkOrder.wiringWorkOrder?.date ===
                              dateString
                          );

                        return (
                          <td
                            style={{
                              whiteSpace: "nowrap",
                              border: "1px solid black",
                            }}
                          >
                            <ul>
                              {filteredWiringWorkOrderByDate.map(
                                (wiringWorkOrder) => (
                                  <li>
                                    <div>
                                      <div className="d-flex">
                                        <strong>
                                          {wiringWorkOrder.job?.name}
                                        </strong>
                                      </div>
                                      <div>
                                        <strong>
                                          {wiringWorkOrder.panelCode?.type} -{" "}
                                          {wiringWorkOrder.panelCode?.name}:{" "}
                                          {
                                            wiringWorkOrder.panelCode
                                              ?.serialNumber
                                          }
                                        </strong>
                                      </div>
                                      <div>
                                        {
                                          wiringWorkOrder.wiringWorkOrder
                                            ?.wiringProcessType
                                        }
                                        :{" "}
                                        {
                                          wiringWorkOrder.wiringWorkOrder
                                            ?.wiringDescription
                                        }{" "}
                                        <DoneStatusCheck
                                          date={dateString}
                                          doneStatus={
                                            wiringWorkOrder.wiringWorkOrder
                                              ?.doneStatus
                                          }
                                        />
                                      </div>
                                      <div>
                                        {wiringWorkOrder.wiringWorkOrder
                                          ?.wiringRemark !== "" ? (
                                          <div className="ml-1">
                                            (
                                            {
                                              wiringWorkOrder.wiringWorkOrder
                                                ?.wiringRemark
                                            }
                                            )
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <Modal
        show={state.unassignedWorkerInfo.dialogOpen}
        onHide={() =>
          setState({
            ...state,
            unassignedWorkerInfo: {
              ...state.unassignedWorkerInfo,
              dialogOpen: false,
            },
          })
        }
      >
        <Modal.Header>
          <Modal.Title>
            Unassigned Workers for{" "}
            {makeReadableDateString(new Date(state.unassignedWorkerInfo.date))}{" "}
            ({days[new Date(state.unassignedWorkerInfo.date).getDay()]})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: "1em" }}>
            <ol>
              {state.users.map((user) => {
                return user.wiringWorkOrders.find(
                  (wiringWorkOrder) =>
                    wiringWorkOrder.wiringWorkOrder?.date ===
                    state.unassignedWorkerInfo.date
                ) ? (
                  <></>
                ) : (
                  <li>
                    {user.name}{" "}
                    {(() => {
                      const foundUser = allUsers.find(
                        (ux) => ux.id === user.id
                      );

                      return foundUser?.roles.find((r) =>
                        r.name.toLowerCase().includes("mechanical")
                      ) ||
                        foundUser?.roles.find((r) =>
                          r.name.toLowerCase().includes("electrical")
                        ) ? (
                        <strong>
                          (
                          {foundUser.roles
                            .filter(
                              (r) =>
                                r.name.toLowerCase().includes("mechanical") ||
                                r.name.toLowerCase().includes("electrical")
                            )
                            .map((r) => r.name)
                            .join(", ")}
                          )
                        </strong>
                      ) : (
                        <strong className="text-danger">(Role unmapped)</strong>
                      );
                    })()}
                  </li>
                );
              })}
            </ol>
          </div>
          <hr />
          <div className="d-flex justify-content-between m-1">
            <div className="">
              <div>Mechanical</div>
              <ol>
                {state.users
                  .filter((u) =>
                    allUsers
                      .find((ux) => ux.id === u.id)
                      ?.roles?.map((r) => r.name.toLowerCase())
                      .find((rn) => rn.includes("mechanical"))
                  )
                  .map((user) => {
                    return user.wiringWorkOrders.find(
                      (wiringWorkOrder) =>
                        wiringWorkOrder.wiringWorkOrder?.date ===
                        state.unassignedWorkerInfo.date
                    ) ? (
                      <></>
                    ) : (
                      <li>{user.name}</li>
                    );
                  })}
              </ol>
            </div>
            <div className="">
              <div>Electrical</div>
              <ol>
                {state.users
                  .filter((u) =>
                    allUsers
                      .find((ux) => ux.id === u.id)
                      ?.roles?.map((r) => r.name.toLowerCase())
                      .find((rn) => rn.includes("electrical"))
                  )
                  .map((user) => {
                    return user.wiringWorkOrders.find(
                      (wiringWorkOrder) =>
                        wiringWorkOrder.wiringWorkOrder?.date ===
                        state.unassignedWorkerInfo.date
                    ) ? (
                      <></>
                    ) : (
                      <li>{user.name}</li>
                    );
                  })}
              </ol>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* <pre>
        {JSON.stringify(users, null, 2)}
      </pre> */}
    </Box>
  );
};

export default WiringManpowerAssignmentPage;
