import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  PpicBomLeveled,
  PpicBomLeveledGroup,
  PpicBomLeveledGroupItem,
  PpicBomLeveledGroups,
  PpicBomLeveleds,
} from "../../../masterbigsystem";
import {
  fetchBomLeveledGroupProto,
  fetchBomLeveledGroupsProto,
  fetchBomLeveledsProtoSimple,
  fetchExtCrmPurchaseOrdersProto,
} from "../../../helpers";
import { AppContext } from "../../../App";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

const BomGroupDetail = () => {
  const { id } = useParams() as { id: string };
  const ctx = useContext(AppContext);
  const history = useHistory();
  const bomGroup = useRef(PpicBomLeveledGroup.fromPartial({ items: [] }));
  const bomLeveleds = useRef(PpicBomLeveleds.fromPartial({}));
  const pos = useRef(CrmPurchaseOrders.fromPartial({}));

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const handleFetchBomGroupData = async () => {
    if (!isNaN(parseInt(id))) {
      const d = await fetchBomLeveledGroupProto({
        apiKey: ctx?.apiKey ?? "",
        id: id,
      });

      if (d) {
        bomGroup.current = d;
        render();
      }
    }
  };

  const handleFetchCrmPosData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
      render();
    }
  };

  const handleFetchBomLeveledsProtoSimpleData = async () => {
    const d = await fetchBomLeveledsProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      bomLeveleds.current = d;
      render();
    }
  };
  const handleInit = async () => {
    handleFetchBomGroupData();
    handleFetchBomLeveledsProtoSimpleData();
    handleFetchCrmPosData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bomleveledgroups-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(
            PpicBomLeveledGroups.fromPartial({ groups: [bomGroup.current] })
          ),
        }
      );

      if (resp.status !== 200) {
        throw await resp.text();
      }

      history.push("/bomgroups");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>BOM Group Detail</h4>
          </div>

          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>

          <div>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => {
                if (!window.confirm("Really delete?")) {
                  return;
                }

                if (bomGroup.current.masterJavaBaseModel) {
                  bomGroup.current.masterJavaBaseModel.hidden = true;
                }

                handleSave();
              }}
            >
              Delete
            </button>
          </div>
        </div>

        <div>
          <strong>Name</strong>
        </div>
        <div>
          <input
            className="form-control form-control-sm"
            placeholder="BOM Group name..."
            defaultValue={bomGroup.current.name}
            onBlur={(e) => {
              bomGroup.current.name = e.target.value;
              render();
            }}
          />
        </div>

        <div>
          <strong>Quote</strong>
        </div>
        <div>
          <Select
            placeholder="PO..."
            options={pos.current.purchaseOrders
              .filter((p) => p.quoteId && p.quoteId !== "" && p.quoteId !== "0")
              .map((p) => ({
                label: p.quoteNumber,
                value: p,
              }))}
            value={pos.current.purchaseOrders
              .filter((p) => p.quoteId && p.quoteId !== "" && p.quoteId !== "0")
              .map((p) => ({
                label: p.quoteNumber,
                value: p,
              }))
              .find(
                (p) => `${p.value.quoteId}` === `${bomGroup.current.quoteId}`
              )}
            onChange={(v) => {
              const val = v as { value: CrmPurchaseOrder };

              bomGroup.current.quoteId = val.value.quoteId;
              render();
            }}
          />
        </div>

        <div>
          <strong>Versions</strong>
        </div>

        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              const newBomGroupItem = PpicBomLeveledGroupItem.fromPartial({});

              if (bomGroup.current.items) {
                bomGroup.current.items.push(newBomGroupItem);
              } else {
                bomGroup.current.items = [newBomGroupItem];
              }

              render();
            }}
          >
            Add
          </button>
        </div>
        <div
          className="overflow-auto border border-dark"
          style={{ height: `65vh` }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "BOM"].map((h) => {
                return (
                  <>
                    <th className="bg-dark text-light">{h}</th>
                  </>
                );
              })}
            </tr>
            {bomGroup.current.items.map((i, i_) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i_ + 1}</td>
                    <td className="border border-dark">
                      <Select
                        placeholder="BOM.."
                        options={bomLeveleds.current.bomLeveleds.map((b) => ({
                          label: b.name,
                          value: b,
                        }))}
                        value={bomLeveleds.current.bomLeveleds
                          .map((b) => ({
                            label: b.name,
                            value: b,
                          }))
                          .find(
                            (b) =>
                              `${b.value.masterJavaBaseModel?.id}` ===
                              `${i.bomLeveledId}`
                          )}
                        onChange={(v) => {
                          const val = v as { value: PpicBomLeveled };

                          i.bomLeveledId = val.value.masterJavaBaseModel?.id;

                          render();
                        }}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default BomGroupDetail;
