import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import { checkMrQtyAvailColor, CheckRequestStatus } from "../../../helpers";
import {
  BomDocument,
  BomMulti,
  ExtInventory,
  ExtItem,
  ItemBuffer,
  Job,
  MaterialRequest,
  MaterialRequestItem,
} from "../../../models/model";
import {
  initialJob,
  initialMaterialRequest,
  initialMaterialRequestItem,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";

const MaterialRequestAdd = () => {
  const appState = useContext(AppContext);
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const [state, setState] = useState({
    requestStatus: RequestStatus.NotAsked,
    items: [] as Array<ExtItem>,
    inventoryList: [] as Array<ExtInventory>,
    materialRequest: { ...initialMaterialRequest },
    job: null as Job | null,
    partNameSearch: "",
    partNumberSearch: "",
    bomDocument: null as BomDocument | null,
    qtyInput: 0,
    extItemIdInput: 0,
    showIndependentMr: true,
    bufferList: [] as Array<{ extItemId: number; qty: number }>,
  });

  useEffect(() => {
    (async () => {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const parsedId = parseInt(id ? id : "");

      const materialRequest = await (async () => {
        if (!isNaN(parsedId)) {
          return await fetchMaterialRequest(parsedId);
        } else {
          return null;
        }
      })();

      // const fetchBomDocument = async (): Promise<BomDocument | null> => {
      //   if(materialRequest && materialRequest.jobData?.bomName !== "") {
      //     return await fetchLatestBom(materialRequest.jobData?.bomName);
      //   } else {
      //     return null;
      //   }
      // };

      const [items, inventoryList] = await Promise.all([
        // fetchBomDocument(),
        fetchItems(),
        fetchInventory(),
      ]);

      setState({
        ...state,
        inventoryList: inventoryList ? inventoryList : state.inventoryList,
        materialRequest: materialRequest
          ? materialRequest
          : state.materialRequest,
        bufferList: items?.bufferList ? items.bufferList : state.bufferList,
        // bomDocument: bomDocument,
        items: items?.items ? items.items : [],
      });
    })();
  }, []);

  const fetchInventory = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ext-inventory`,
        {
          headers: {
            authorization: appState?.apiKey ?? "",
          },
        }
      );

      if (response.status !== 200) throw "Error fetching inventory.";

      return (await response.json()) as Array<ExtInventory>;
    } catch (e) {
      return null;
    }
  };

  const fetchMaterialRequest = async (id: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/materialrequests/${id}/full`,
        {
          headers: {
            authorization: appState?.apiKey ?? "",
          },
        }
      );

      return await response.json();
    } catch (e) {
      return null;
    }
  };

  const fetchItems = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ext-items`,
        {
          headers: {
            authorization: appState?.apiKey ?? "",
          },
        }
      );
      if (response.status !== 200) throw "Failed fetching items";

      const itemsData = (await response.json()) as Array<ExtItem>;

      // Check buffer
      const bufferList = await Promise.all(
        itemsData.map(async (item) => {
          const buffer = await (async () => {
            try {
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/materialrequests/buffer?extItemId=${item.id}`,
                {
                  headers: {
                    authorization: appState?.apiKey ?? "",
                  },
                }
              );
              return (await response.json()) as ItemBuffer;
            } catch (e) {
              return null;
            }
          })();

          return {
            extItemId: item.id ? item.id : 0,
            qty: buffer ? buffer.qty : 0,
          };
        })
      );

      // console.log("Buffer list:", bufferList);

      return {
        items: itemsData,
        bufferList: bufferList,
      };
    } catch (e) {
      return null;
    }
  };

  const fetchLatestBom = async (name: string | null | undefined) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bomdocuments/search/name?name=${name}`,
        {
          headers: {
            authorization: appState?.apiKey ?? "",
          },
        }
      );

      if (response.status !== 200) throw "Error fetching BOM Document";
      return (await response.json()) as BomDocument;
    } catch (e) {
      return null;
    }
  };

  const handleSave = async () => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/materialrequests/save`,
        {
          method: "POST",
          headers: {
            authorization: appState?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...state.materialRequest,
            name: `MR_${new Date().toString()}`,
            materialRequestItems: state.materialRequest.materialRequestItems
              ? [...state.materialRequest.materialRequestItems].map(
                  (materialRequestItem) => {
                    return {
                      ...materialRequestItem,
                      job: {
                        ...initialJob,
                        id: materialRequestItem.jobId,
                      },
                    };
                  }
                )
              : state.materialRequest.materialRequestItems,
            // job: {...state.materialRequest.jobData}
          } as MaterialRequest),
        }
      );

      if (response.status !== 201) throw "Fetching material request error";

      setState({ ...state, requestStatus: RequestStatus.Success });
      history.push("/materialrequests");
    } catch (e) {
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  const handleChangeJob = async (job: Job | null) => {
    setState({
      ...state,
      bomDocument:
        job?.bomName !== "" ? await fetchLatestBom(job?.bomName) : null,
      job: job,
      materialRequest: {
        ...state.materialRequest,
        jobData: job,
        job: {
          ...initialJob,
          id: job ? job.id : 0,
        },
      },
    });
  };

  const flattenedBomMultis = [] as Array<{
    order: Array<number>;
    bomMulti: BomMulti;
  }>;

  const flattenBomMultis = (bomMulti: BomMulti, order: Array<number>) => {
    // console.log(bomMulti);
    flattenedBomMultis.push({ order: order, bomMulti: bomMulti });

    bomMulti.children.forEach((bomMulti, i) => {
      flattenBomMultis(bomMulti, [...order, i + 1]);
    });
  };

  state.bomDocument?.bomMultis.forEach((bomMulti, i) =>
    flattenBomMultis(bomMulti, [i + 1])
  );

  const handleInsertMaterialRequest = (e: any) => {
    e.preventDefault();

    const newState = { ...state };

    const newMaterialRequestItem = {
      ...initialMaterialRequestItem,
      name: `MRI_${new Date().toString()}`,
      jobName: newState.job?.name,
      jobId: newState.job?.id,
      extItemId: newState.extItemIdInput,
      job: {
        ...initialJob,
        id: newState.job?.id,
        name: newState.job ? newState.job.name : "",
      },
      qty: newState.qtyInput,
    } as MaterialRequestItem;

    newState.materialRequest.materialRequestItems = [
      newMaterialRequestItem,
      ...(newState.materialRequest.materialRequestItems ?? []),
    ];

    setState(newState);
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <h3>Material Request </h3>
        <div className="ml-2">
          <Button onClick={() => handleSave()}>Save</Button>
        </div>
        <div className="ml-2">
          <CheckRequestStatus requestStatus={state.requestStatus} />
        </div>
      </div>
      <div className="d-flex flex-wrap flex-lg-nowrap align-items-center">
        <div className="flex-grow-1">
          <AsyncSelectWrapper
            isClearable
            label="Job"
            url={`${process.env.REACT_APP_BASE_URL}/jobs/search/mr`}
            param="name"
            value={state.job}
            placeholder="Job..."
            onChange={(selected) => handleChangeJob(selected)}
          />
        </div>
        <div>
          <Form.Control
            disabled
            value={
              state.materialRequest.id && state.materialRequest.id !== 0
                ? `MR-${state.materialRequest.id}`
                : ``
            }
          />
        </div>
      </div>
      <div className="my-1">
        <Form.Control
          as="textarea"
          value={state.materialRequest.remark ?? ""}
          placeholder="Remark..."
          onChange={(e) =>
            setState({
              ...state,
              materialRequest: {
                ...state.materialRequest,
                remark: e.target.value,
              },
            })
          }
        />
      </div>
      {state.job ? (
        <>
          <div className="font-weight-bold">
            MR By Job (BOM:{" "}
            {state.bomDocument ? (
              `${state.bomDocument.name} version ${state.bomDocument.version}`
            ) : (
              <span className="text-danger">No BOM</span>
            )}
            ) for qty {state.job?.qty}
          </div>
          <div
            className="my-2"
            style={{
              height: "30vh",
              border: "1px solid gray",
              resize: "vertical",
              overflow: "auto",
            }}
          >
            <Table size="sm" style={{ borderCollapse: "separate" }}>
              <thead>
                <tr
                  style={{
                    backgroundColor: "darkblue",
                    top: 0,
                    position: "sticky",
                  }}
                >
                  <th className="text-center text-white">Order</th>
                  <th className="text-center text-white">Manufacturer</th>
                  <th className="text-center text-white">Part Number</th>
                  <th className="text-center text-white">Part Name</th>
                  <th className="text-center text-white">Part Desc</th>
                  <th className="text-center text-white">Qty Need/Unit</th>
                  <th className="text-center text-white">Qty Need</th>
                  <th className="text-center text-white">Qty Avail</th>
                  <th className="text-center text-white">Qty Requested</th>
                  <th className="text-center text-white">Qty to Request</th>
                  <th className="text-center text-white">UM</th>
                </tr>
              </thead>
              <tbody>
                {flattenedBomMultis.map((bomMulti) => {
                  const foundItem = state.items.find(
                    (item) => item.id === bomMulti.bomMulti.extItemId
                  );

                  const inventoryQtyWrapped = state.inventoryList.find(
                    (inventory) =>
                      inventory.products?.partNum === foundItem?.partNum
                  )?.qty;
                  const inventoryQty = inventoryQtyWrapped
                    ? inventoryQtyWrapped
                    : 0;

                  const qtyNeeded =
                    bomMulti.bomMulti.qty *
                    (state.materialRequest.jobData
                      ? state.materialRequest.jobData.qty
                      : 1);

                  const qtyRequestedWrapped = state.job?.materialRequestItems
                    ?.filter(
                      (materialRequestItem) =>
                        materialRequestItem.extItemId === foundItem?.id
                    )
                    .reduce(
                      (acc, materialRequestItem) =>
                        acc + materialRequestItem.qty,
                      0
                    );
                  const qtyRequested = qtyRequestedWrapped
                    ? qtyRequestedWrapped
                    : 0;

                  const inBuffer = state.bufferList.find(
                    (buffer) => buffer.extItemId === bomMulti.bomMulti.extItemId
                  );
                  const qtyInBuffer = inBuffer?.qty ? inBuffer.qty : 0;
                  const qtyAvail = inventoryQty - qtyInBuffer;

                  return (
                    <tr>
                      <td
                        style={{ border: "1px solid black" }}
                        className="font-weight-bold"
                      >
                        {bomMulti.order.join(".")}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {foundItem?.mfr}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {foundItem?.partNum}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {foundItem?.partName}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {foundItem?.partDesc}
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {bomMulti.bomMulti.qty}
                      </td>
                      <td style={{ border: "1px solid black" }}>{qtyNeeded}</td>
                      <td
                        className={`${checkMrQtyAvailColor(
                          inventoryQtyWrapped,
                          qtyAvail
                        )} text-center align-middle font-weight-bold`}
                        style={{
                          border: "1px solid black",
                        }}
                      >
                        {qtyAvail}
                      </td>
                      <td
                        className={`${
                          qtyRequested < qtyNeeded ? "bg-danger" : "bg-info"
                        } text-white font-weight-bold text-center align-middle`}
                        style={{ border: "1px solid black" }}
                      >
                        {qtyRequested}/{qtyNeeded}
                      </td>
                      <td className="align-middle">
                        <div>
                          <form>
                            <Form.Control
                              type="number"
                              placeholder="Qty..."
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  qtyInput: isNaN(parseInt(e.target.value))
                                    ? 0
                                    : parseInt(e.target.value),
                                  extItemIdInput: bomMulti.bomMulti.extItemId,
                                })
                              }
                            />
                            <Button
                              className="d-none"
                              type="submit"
                              onClick={handleInsertMaterialRequest}
                            />
                          </form>
                        </div>
                      </td>
                      <td style={{ border: "1px solid black" }}>
                        {foundItem?.defaultUm}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <></>
      )}
      <hr style={{ border: "2px dashed gray" }}></hr>
      <div className="d-flex flex-wrap flex-lg-nowrap font-weight-bold align-items-center mt-2">
        <div>Independent MR</div>
        <div className="ml-2">
          <Form.Control
            placeholder="Search by Part Number..."
            onChange={(e) =>
              setState({ ...state, partNumberSearch: e.target.value })
            }
          />
        </div>
        <div className="ml-2">
          <Form.Control
            placeholder="Search by Part Name..."
            onChange={(e) =>
              setState({ ...state, partNameSearch: e.target.value })
            }
          />
        </div>
        <div className="d-flex align-items-center ml-2">
          Show independent MR?{" "}
          <div>
            <Form.Control
              className="ml-2"
              type="checkbox"
              checked={state.showIndependentMr}
              onClick={() =>
                setState({
                  ...state,
                  showIndependentMr: !state.showIndependentMr,
                })
              }
            />
          </div>
        </div>
      </div>
      <div
        className={`my-2 ${state.showIndependentMr ? "d-block" : "d-none"}`}
        style={{
          height: "30vh",
          border: "1px solid gray",
          resize: "vertical",
          overflow: "auto",
        }}
      >
        <Table size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr
              style={{
                backgroundColor: "darkblue",
                top: 0,
                position: "sticky",
              }}
            >
              <th className="text-center text-white">Manufacturer</th>
              <th className="text-center text-white">Part Number</th>
              <th className="text-center text-white">Part Name</th>
              <th className="text-center text-white">Part Desc</th>
              <th className="text-center text-white">Qty Avail</th>
              {/* <th className="text-center text-white">Qty Requested</th> */}
              <th className="text-center text-white">Qty to Request</th>
              <th className="text-center text-white">UM</th>
            </tr>
          </thead>
          <tbody>
            {state.inventoryList
              .filter(
                (inventory) =>
                  inventory.products?.partName
                    ?.toLowerCase()
                    .includes(state.partNameSearch.toLowerCase()) &&
                  inventory.products.partNum
                    ?.toLowerCase()
                    .includes(state.partNumberSearch.toLowerCase())
              )
              .map((inventory) => {
                const inBuffer = state.bufferList.find(
                  (buffer) => buffer.extItemId === inventory.products?.id
                );
                const qtyInBuffer = inBuffer?.qty ? inBuffer.qty : 0;

                const inventoryQty = inventory.qty ? inventory.qty : 0;

                const qtyAvail = inventoryQty - qtyInBuffer;

                return (
                  <tr>
                    <td style={{ border: "1px solid black" }}>
                      {inventory.products?.mfr}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {inventory.products?.partNum}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {inventory.products?.partName}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {inventory.products?.partDesc}
                    </td>
                    <td
                      style={{ border: "1px solid black" }}
                      className={`text-center align-middle font-weight-bold text-white ${checkMrQtyAvailColor(
                        inventory.qty,
                        qtyAvail
                      )}`}
                    >
                      {qtyAvail}
                    </td>
                    {/* <td style={{ border: "1px solid black" }} className="bg-danger text-center align-middle font-weight-bold text-white">0</td> */}
                    <td
                      style={{ border: "1px solid black" }}
                      className="align-middle"
                    >
                      <form>
                        <Form.Control
                          type="number"
                          placeholder="Qty..."
                          onChange={(e) =>
                            setState({
                              ...state,
                              qtyInput: isNaN(parseInt(e.target.value))
                                ? 0
                                : parseInt(e.target.value),
                              extItemIdInput: inventory.products?.id
                                ? inventory.products.id
                                : 0,
                              job: null,
                            })
                          }
                        />
                        <Button
                          type="submit"
                          className="d-none"
                          onClick={handleInsertMaterialRequest}
                        />
                      </form>
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {inventory.products?.defaultUm}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <hr style={{ border: "2px dashed gray" }}></hr>
      <div className="font-weight-bold">Selected Items</div>
      <div
        style={{
          height: "30vh",
          resize: "vertical",
          overflow: "auto",
          border: "1px solid gray",
        }}
      >
        <Table size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr
              style={{
                backgroundColor: "darkblue",
                position: "sticky",
                top: 0,
              }}
            >
              <th className="text-white">Job</th>
              <th className="text-white">Manufacturer</th>
              <th className="text-white">Part Number</th>
              <th className="text-white">Part Name</th>
              <th className="text-white">Part Description</th>
              <th className="text-white">Qty</th>
              <th className="text-white">UM</th>
            </tr>
          </thead>
          <tbody>
            {state.materialRequest.materialRequestItems?.map(
              (materialRequestItem) => {
                const foundItem = state.items.find(
                  (item) => item.id === materialRequestItem.extItemId
                );

                return (
                  <tr>
                    <td style={{ border: "1px solid black" }}>
                      {materialRequestItem.jobName}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {foundItem?.mfr}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {foundItem?.partNum}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {foundItem?.partName}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {foundItem?.partDesc}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {materialRequestItem.qty}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {foundItem?.defaultUm}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
      </div>
      {/* <div>
        <pre>
          {JSON.stringify(state.inventoryList, null, 2)}
        </pre>
      </div> */}
      {/* <div>
        <pre>
          {JSON.stringify(state.bufferList, null, 2)}
        </pre>
      </div> */}
    </div>
  );
};

export default MaterialRequestAdd;
