import React from 'react'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'
import { Warehouse } from '../../../models/model'

const WarehousePage = (props: any) => {
  const mapperFunc = (warehouse: Warehouse) => {
    return {
      id: warehouse.id,
      content: [ warehouse.name ]
    }
  }

  return (
    <StatefulCrudTable<Warehouse>  
      url="warehouses"
      head={['Name']}
      mapper={mapperFunc}
    />
  )
}

export default WarehousePage