import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../App";
import {
  days,
  DoneStatusCheck,
  filterNotHiddenAndSort,
  makeDateString,
  makeReadableDateString,
  planNameColor,
} from "../../../helpers";
import {
  MasterJavaBaseModel,
  MeetingMeetingTaskPostBody,
} from "../../../masterbigsystem";
import {
  Job,
  PanelCode,
  PanelCodePlan,
  User,
  WiringWorkOrder,
  WiringWorkOrderBundleBody,
  WiringWorkOrderDoneStatus,
} from "../../../models/model";
import {
  initialPanelCode,
  initialPanelCodePlan,
  initialWiringWorkOrder,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";

const ManpowerPlanning = () => {
  const apiKey = localStorage.getItem("apiKey");
  const productionAndWiringProcess = [
    { label: "None", value: null },

    { label: "Plan - Punching", value: "P" },
    { label: "Plan - Bending", value: "B" },
    { label: "Plan - Welding", value: "W" },
    { label: "Plan - Grinding", value: "G" },
    { label: "Plan - Coating", value: "C" },
    { label: "Plan - Assembling", value: "A" },

    { label: "Panel Mechanical - Punching", value: "Punch" },
    { label: "Panel Mechanical - Bending", value: "Bend" },
    { label: "Panel Mechanical - Welding", value: "Weld" },
    { label: "Panel Mechanical - Grinding", value: "Grind" },
    { label: "Panel Mechanical - Coating", value: "Coat" },
    { label: "Panel Mechanical - Assembling", value: "Assembly" },

    { label: "Preparation Cable - Grouping", value: "Grouping" },
    { label: "Preparation Cable - Tubing/Crimping", value: "Tubing/Crimping" },
    {
      label: "Preparation panel - Ducting dan Pasang",
      value: "Tubing/Crimping",
    },
    { label: "Preparation Panel - Dinrail", value: "Dinrail" },
    { label: "Preparation panel - Ducting dan Pasang", value: "Tubing/Crimping" },

    {
      label: "Preparation Panel - Cable/Support Duct",
      value: "Cable/Support Duct",
    },
    { label: "Preparation Panel - Pasang Terminal", value: "Pasang Terminal" },
    { label: "Preparation Panel - Cutout Panel", value: "Cutout Panel" },
    { label: "Preparation Panel - Component", value: "Component" },
    { label: "Preparation Panel - Labeling", value: "Labeling" },
    { label: "Preparation Panel - Mimik/Nameplate", value: "Mimik/Nameplate" },
    { label: "Busbar - Potong", value: "Potong" },
    { label: "Busbar - Bending", value: "Bending" },
    { label: "Busbar - Lubang", value: "Lubang" },
    { label: "Busbar - Baut", value: "Baut" },
    { label: "Busbar - Heatshrink", value: "Heatshrink" },
    { label: "Busbar - Pasang", value: "Pasang" },
    { label: "Interkoneksi - WK", value: "WK" },
    { label: "Interkoneksi - LR", value: "LR" },
    { label: "Interkoneksi - Interkoneksi", value: "Interkoneksi" },
    { label: "Interkoneksi - Closing", value: "Closing" },
    { label: "QC - Panel", value: "Panel" },
    { label: "QC - Point to Point", value: "Point to Point" },
    { label: "QC - Test On", value: "Test On" },
    { label: "QC - NC Mechanical", value: "NC Mechanical" },
    { label: "QC - NC Electrical", value: "NC Electrical" },
    { label: "QC - Validasi", value: "Validasi" },
  ];

  const documents = [
    { label: "Mechanical Document - Drawing", value: "Drawing" },
    { label: "Mechanical Document - Program", value: "Program" },
    { label: "Mechanical Document - BOM", value: "BOM" },
    { label: "Electrical Document - Layout", value: "Layout" },
    { label: "Electrical Document - SLD", value: "SLD" },
    { label: "Electrical Document - Zplant", value: "Zplant" },
    { label: "Electrical Document - FW/LO", value: "FW/LO" },
    { label: "Electrical Document - Schema", value: "Schema" },
    { label: "Electrical Document - TL", value: "TL" },
    { label: "Electrical Document - WL", value: "WL" },
    { label: "Electrical Document - BOM Elec", value: "BOM Elec" },
    { label: "Electrical Document - Cutout", value: "Cutout" },
    { label: "Electrical Document - Nameplate", value: "Nameplate" },
    { label: "Electrical Document - Mimik", value: "Mimik" },
  ];

  const deadlineNames = [...documents, ...productionAndWiringProcess];

  const ctx = useContext(AppContext);

  const initialState = {
    apiKey: apiKey ? apiKey : "",
    searchInput: "",
    requestStatus: RequestStatus.NotAsked,
    jobs: [] as Array<Job>,
    selectedDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dialogShow: false,
    dialogInfo: {
      type: "Plan" as "Plan" | "Mapping" | "ProcessAssignment",
      jobId: 0,
      panelCode: null as PanelCode | null,
      panelCodePlan: { ...initialPanelCodePlan },
      worker: null as User | null,
      isCustom: false,
      wiringProcesstype: "",
      wiringDescription: "",
      customWiringWorkOrder: "",
      date: "",
      line: "",
      zone: "",
    },
    planIdsToDelete: [] as Array<number>,
    mappingIdsToDelete: [] as Array<number>,
    selectedWiringProcessType: null as string | null,
  };

  const [changesUnsaved, setChangesUnsaved] = useState(false);
  const [state, setState] = useState(initialState);

  const [processesForDateDialog, setProcessesForDateDialog] = useState(false);
  const [processesForDateDialogDate, setProcessesForDateDialogDate] = useState<
    string | null | undefined
  >(null);
  const [simpleView, setSimpleView] = useState(false);

  useEffect(() => {
    if (state.requestStatus === RequestStatus.NotAsked) fetchWiringWorkOrders();
  });

  const startDate = makeDateString(
    new Date(state.selectedDate.getFullYear(), state.selectedDate.getMonth(), 1)
  );
  const endDate = makeDateString(
    new Date(
      state.selectedDate.getFullYear(),
      state.selectedDate.getMonth() + 1,
      0
    )
  );

  const lastDate = new Date(endDate).getDate();

  const start = makeDateString(
    new Date(state.selectedDate.getFullYear(), state.selectedDate.getMonth(), 0)
  );
  const end = makeDateString(
    new Date(
      state.selectedDate.getFullYear(),
      state.selectedDate.getMonth(),
      lastDate + 1
    )
  );

  const dateNowString = makeDateString(new Date());

  const fetchWiringWorkOrders = async () => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/wiringmanpower?start=${start}&end=${end}`,
        {
          headers: { authorization: state.apiKey },
        }
      );
      if (response.status !== 200) throw "Error fetching work orders";

      const jobsData: Job[] | null | undefined = await response.json();

      console.log("Jobs data:", jobsData);

      if (jobsData) {
        setState({
          ...state,
          jobs: jobsData,
          requestStatus: RequestStatus.Success,
        });
      }
    } catch (e) {
      console.error(e);
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  const ChevronLeftIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-chevron-left"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  );

  const ChevronRightIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      className="bi bi-chevron-right"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );

  const strafeMonth = (type: "Prev" | "Next") => {
    switch (type) {
      case "Prev":
        setState({
          ...state,
          selectedDate: new Date(
            state.selectedDate.getFullYear(),
            state.selectedDate.getMonth() - 1,
            1
          ),
          requestStatus: RequestStatus.NotAsked,
        });
        break;

      case "Next":
        setState({
          ...state,
          selectedDate: new Date(
            state.selectedDate.getFullYear(),
            state.selectedDate.getMonth() + 1,
            1
          ),
          requestStatus: RequestStatus.NotAsked,
        });
        break;
    }
  };

  const handleAddPanelCodePlan = (panelCodePlanName: string) => {
    setChangesUnsaved(true);
    const newState = { ...state };

    const foundPanelCode = newState?.jobs
      ?.find((job) => job.id === state.dialogInfo.jobId)
      ?.panelCodes?.find(
        (panelCode) => panelCode.id === state.dialogInfo.panelCode?.id
      );

    if (foundPanelCode) {
      const newPanelCodePlan = {
        ...initialPanelCodePlan,
        date: state.dialogInfo.date,
        uuid: uuidv4(),
        name: panelCodePlanName,
        panelCode: {
          ...initialPanelCode,
          id: foundPanelCode.id,
        },
      } as PanelCodePlan;

      foundPanelCode.panelCodePlans = [
        ...foundPanelCode.panelCodePlans,
        newPanelCodePlan,
      ];
    }

    setState(newState);
  };

  const handleAddPanelCodeMapping = () => {
    setChangesUnsaved(true);

    const newState = { ...state };

    const newWiringWorkOrder = {
      ...initialWiringWorkOrder,
      date: state.dialogInfo.date,
      panelCode: { ...initialPanelCode, id: newState.dialogInfo.panelCode?.id },
      worker: newState.dialogInfo.worker,
      wiringProcessType: newState.dialogInfo.isCustom
        ? newState.dialogInfo.customWiringWorkOrder
        : newState.dialogInfo.wiringProcesstype,
      isCustom: newState.dialogInfo.isCustom,
      wiringDescription: newState.dialogInfo.wiringDescription,
      uuid: uuidv4(),
      line: state.dialogInfo?.line ?? "",
      zone: state.dialogInfo?.zone ?? "",
    } as WiringWorkOrder;

    const foundPanelCode = newState.jobs
      ?.find((job) => job.id === newState.dialogInfo.jobId)
      ?.panelCodes?.find(
        (panelCodeToFind) =>
          panelCodeToFind.id === newState.dialogInfo.panelCode?.id
      );

    foundPanelCode?.wiringWorkOrders.push(newWiringWorkOrder);
    newState.dialogInfo.wiringProcesstype = "";
    newState.dialogInfo.wiringDescription = "";

    setState(newState);
  };

  const handleDeletePanelCodePlan = (
    jobId: number,
    panelCodeId: number,
    panelCodePlan: PanelCodePlan
  ) => {
    console.log(
      "Job id",
      jobId,
      "Panel code id",
      panelCodeId,
      "Panel code plan",
      panelCodePlan
    );
    const newState = { ...state };

    const foundPanelCode = state.jobs
      ?.find((job) => job.id === jobId)
      ?.panelCodes?.find((panelCode) => panelCode.id === panelCodeId);

    let panelCodePlanId = 0;

    // console.log("Foudn panel code: ", foundPanelCode);

    if (foundPanelCode) {
      const foundPanelCodePlanIndex = foundPanelCode?.panelCodePlans.findIndex(
        (panelCodePlanToFind) => panelCodePlanToFind.uuid === panelCodePlan.uuid
      );

      panelCodePlanId =
        foundPanelCode.panelCodePlans[foundPanelCodePlanIndex].id;

      // console.log("Panel code plan:", foundPanelCode.panelCodePlans[foundPanelCodePlanIndex], "index", )

      foundPanelCode.panelCodePlans.splice(foundPanelCodePlanIndex, 1);
    }

    newState.planIdsToDelete = [...newState.planIdsToDelete, panelCodePlanId];

    setState(newState);
  };

  const handleSave = async () => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      await fetch(`${process.env.REACT_APP_BASE_URL}/wiringworkorders-bundle`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "content-encoding": "gzip",
          "accept-encoding": "gzip",
        },
        body: JSON.stringify({
          panelCodePlans: state.jobs
            .map((j) => j.panelCodes)
            .flat()
            .map((pc) =>
              pc?.panelCodePlans.map((pcp) => ({
                ...pcp,
                panelCode: {
                  ...initialPanelCode,
                  id: pc?.id ?? null,
                },
              }))
            )
            .flat(),
          panelCodePlanDeleteIds: state.planIdsToDelete,
          wiringWorkOrders: state.jobs
            .map((j) => j.panelCodes)
            .flat()
            .map((pc) =>
              pc?.wiringWorkOrders.map((wwo) => ({
                ...wwo,
                panelCode: { ...initialPanelCode, id: pc.id ?? null },
              }))
            )
            .flat(),
          wiringWorkOrderDeleteIds: state.mappingIdsToDelete,
        } as WiringWorkOrderBundleBody),
      });

      setState(initialState);
    } catch (e) {
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  const handleSaveFragment = async (body: WiringWorkOrderBundleBody) => {
    try {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      await fetch(`${process.env.REACT_APP_BASE_URL}/wiringworkorders-bundle`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "content-encoding": "gzip",
          "accept-encoding": "gzip",
        },
        body: JSON.stringify(body),
      });

      setState(initialState);
    } catch (e) {
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  const handleShowDialog = (
    type: "Plan" | "Mapping",
    dateString: string,
    jobId: number,
    panelCode: PanelCode
  ) => {
    setState({
      ...state,
      dialogShow: true,
      dialogInfo: {
        ...state.dialogInfo,
        type: type,
        date: dateString,
        jobId: jobId,
        panelCode: panelCode,
        panelCodePlan: {
          ...state.dialogInfo.panelCodePlan,
          date: dateString,
        },
      },
    });
  };

  const handleChangeWiringWorkOrderDoneStatus = (
    wiringWorkOrderUuid: string,
    doneStatus: WiringWorkOrderDoneStatus
  ) => {
    const newState = { ...state };

    const foundWiringWorkOrder = newState.jobs
      .find((job) => job.id === newState.dialogInfo.jobId)
      ?.panelCodes?.find(
        (panelCode) => panelCode.id === newState.dialogInfo.panelCode?.id
      )
      ?.wiringWorkOrders.find(
        (wiringWorkOrder) => wiringWorkOrder.uuid === wiringWorkOrderUuid
      );

    if (foundWiringWorkOrder) {
      foundWiringWorkOrder.doneStatus = doneStatus;
    }

    setState(newState);
  };

  const handleChangeWiringWorkOrderRemark = (
    wiringWorkOrderUuid: string,
    remark: string
  ) => {
    const newState = { ...state };

    const foundWiringWorkOrder = newState.jobs
      .find((job) => job.id === newState.dialogInfo.jobId)
      ?.panelCodes?.find(
        (panelCode) => panelCode.id === newState.dialogInfo.panelCode?.id
      )
      ?.wiringWorkOrders.find(
        (wiringWorkOrder) => wiringWorkOrder.uuid === wiringWorkOrderUuid
      );

    if (foundWiringWorkOrder) {
      foundWiringWorkOrder.wiringRemark = remark;
    }

    setState(newState);
  };

  const handleDeleteWiringWorkOrder = (wiringWorkOrderUuid: string) => {
    const newState = { ...state };

    const foundPanelCode = newState.jobs
      .find((job) => job.id === newState.dialogInfo.jobId)
      ?.panelCodes?.find(
        (panelCode) => panelCode.id === newState.dialogInfo.panelCode?.id
      );

    const foundWiringWorkOrder = foundPanelCode?.wiringWorkOrders.find(
      (wiringWorkOrder) => wiringWorkOrder.uuid === wiringWorkOrderUuid
    );

    if (foundPanelCode && foundWiringWorkOrder) {
      newState.mappingIdsToDelete.push(foundWiringWorkOrder?.id);

      foundPanelCode.wiringWorkOrders.splice(
        foundPanelCode.wiringWorkOrders.findIndex(
          (wiringWorkOrder) => wiringWorkOrder.uuid === wiringWorkOrderUuid
        ),
        1
      );
    }

    setState(newState);
  };

  const handleChangeFilterByProcessType = (selected: any) =>
    setState({
      ...state,
      selectedWiringProcessType: (selected as { label: string; value: string })
        .value,
    });

  return (
    <div>
      <div className="d-flex">
        <h3>
          Manpower Planning {makeReadableDateString(state.selectedDate)}
          {" - "}
          {makeReadableDateString(
            new Date(
              state.selectedDate.getFullYear(),
              state.selectedDate.getMonth() + 1,
              0
            )
          )}
        </h3>
        <div className="mx-2">
          <Button variant="outline-info" onClick={() => strafeMonth("Prev")}>
            <ChevronLeftIcon /> Prev
          </Button>
          <Button variant="outline-info" onClick={() => strafeMonth("Next")}>
            Next <ChevronRightIcon />
          </Button>
        </div>
        {/* <div>
          <Button onClick={() => handleSave()}>Save</Button>
        </div> */}
        <div className="mx-1">
          {state.requestStatus === RequestStatus.Loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="d-flex">
        <div style={{ width: 300 }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              document.getElementById("job-name-filter-search")?.blur();
            }}
          >
            <Form.Control
              id="job-name-filter-search"
              placeholder="Filter by job name..."
              onBlur={(e: any) =>
                setState({ ...state, searchInput: e.target.value })
              }
            />
            <button type="submit" style={{ display: "none" }} />
          </form>
        </div>
        <div style={{ width: 300, zIndex: 10 }}>
          {/* <Form.Control
            placeholder="Filter by job name..."
            onChange={e => setState({ ...state, searchInput: e.target.value })}
          /> */}
          <Select
            placeholder="Filter By Process Type..."
            options={productionAndWiringProcess}
            onChange={handleChangeFilterByProcessType}
          />
        </div>
        <div>
          {simpleView ? (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setSimpleView(!simpleView);
              }}
            >
              Simple View
            </button>
          ) : (
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setSimpleView(!simpleView);
              }}
            >
              Normal View
            </button>
          )}
        </div>
      </div>

      <div
        className="my-2"
        style={{
          height: "75vh",
          overflow: "auto",
          border: "2px solid gray",
          resize: "vertical",
        }}
      >
        {(() => {
          const filteredJobs = state.jobs
            .filter((job) =>
              state.selectedWiringProcessType
                ? (job.panelCodes
                    ?.flatMap((panelCode) => panelCode.wiringWorkOrders)
                    .filter(
                      (wiringWorkOrder) =>
                        wiringWorkOrder.wiringProcessType ===
                        state.selectedWiringProcessType
                    ).length ?? 0) > 0 ||
                  (job.panelCodes
                    ?.map((panelCode) => panelCode.panelCodePlans)
                    .flat()
                    .filter(
                      (panelCodePlan) =>
                        panelCodePlan?.name === state.selectedWiringProcessType
                    ).length ?? 0) > 0
                : true
            )
            .filter((job) =>
              job.name.toLowerCase().includes(state.searchInput.toLowerCase())
            );
          return simpleView ? (
            <>
              <Table bordered size="sm" style={{ borderCollapse: "separate" }}>
                <thead>
                  <tr>
                    <th
                      className="bg-dark text-light sticky-top top-0"
                      style={{ position: "sticky", left: 0, zIndex: 2 }}
                    >
                      Job Name
                    </th>
                    <th
                      className="bg-dark text-light sticky-top top-0"
                      style={{ position: "sticky", left: 214, zIndex: 2 }}
                    >
                      Panel Code
                    </th>
                    {[...Array(lastDate).keys()]
                      .map((date) => date + 1)
                      .map((date) => {
                        const dateString = makeDateString(
                          new Date(
                            state.selectedDate.getFullYear(),
                            state.selectedDate.getMonth(),
                            date
                          )
                        );

                        return (
                          <td
                            className={`text-light sticky-top top-0 align-center text-center ${
                              makeDateString(new Date()) === dateString
                                ? "bg-primary"
                                : new Date(dateString).getDay() === 6 ||
                                  new Date(dateString).getDay() === 0
                                ? "bg-danger"
                                : "bg-dark"
                            }`}
                            style={{ zIndex: 1 }}
                          >
                            <div>
                              {Intl.DateTimeFormat(
                                navigator.language ?? "en-US",
                                { day: "numeric" } as any
                              ).format(new Date(dateString))}
                            </div>
                            <div>
                              {Intl.DateTimeFormat(
                                navigator.language ?? "en-US",
                                { weekday: "short" } as any
                              ).format(new Date(dateString))}
                            </div>
                            <div>
                              <button
                                className="btn btn-light btn-sm"
                                onClick={() => {
                                  setProcessesForDateDialog(true);
                                  setProcessesForDateDialogDate(dateString);
                                }}
                              >
                                Check
                              </button>
                            </div>
                          </td>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {filteredJobs.map((j, i) => {
                    return (
                      <>
                        <tr>
                          <td
                            className="bg-light"
                            style={{ position: "sticky", left: 0 }}
                            rowSpan={(j.panelCodes?.length ?? 0) + 1}
                          >
                            <div
                              className="d-flex flex-wrap bg-light"
                              style={{ width: 200 }}
                            >
                              {j.name}
                            </div>{" "}
                          </td>
                        </tr>
                        {j.panelCodes?.map((pc, j_) => {
                          return (
                            <tr>
                              <td
                                style={{
                                  position: "sticky",
                                  left: 214,
                                }}
                              >
                                <div
                                  className="d-flex flex-wrap  bg-light"
                                  style={{
                                    width: 200,
                                  }}
                                >
                                  {pc.type}
                                  {" - "}
                                  {pc.name}
                                  {" | "}
                                  {pc.serialNumber && pc.serialNumber !== "" ? (
                                    <strong>SN {pc.serialNumber}</strong>
                                  ) : (
                                    <strong className="text-danger">
                                      No SN
                                    </strong>
                                  )}{" "}
                                </div>
                              </td>
                              {[...Array(lastDate).keys()]
                                .map((date) => date + 1)
                                .map((date) => {
                                  const dateString = makeDateString(
                                    new Date(
                                      state.selectedDate.getFullYear(),
                                      state.selectedDate.getMonth(),
                                      date
                                    )
                                  );

                                  return (
                                    <td>
                                      {pc.panelCodePlans
                                        .filter(
                                          (panelCodePlan) =>
                                            panelCodePlan.date === dateString
                                        )
                                        .map((panelCodePlan) => (
                                          <li>
                                            <div
                                              style={{
                                                backgroundColor: planNameColor(
                                                  panelCodePlan.name
                                                ),
                                              }}
                                            >
                                              <small className="text-white font-weight-bold p-1 m-1">
                                                {panelCodePlan.name}
                                              </small>
                                            </div>
                                          </li>
                                        ))}
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <Table bordered size="sm" style={{ borderCollapse: "separate" }}>
                <thead
                  style={{
                    backgroundColor: "darkblue",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                  }}
                >
                  <tr className="text-center align-middle" style={{}}>
                    <th
                      className="text-light text-center align-middle"
                      style={{
                        position: "sticky",
                        left: 0,
                        width: 450,
                        backgroundColor: "darkblue",
                      }}
                    >
                      Job
                    </th>
                    <th
                      className="text-light text-center align-middle"
                      style={{
                        position: "sticky",
                        left: 464,
                        backgroundColor: "darkblue",
                      }}
                    >
                      Panel Code
                    </th>
                    {[...Array(lastDate).keys()]
                      .map((date) => date + 1)
                      .map((date) => {
                        const dateString = makeDateString(
                          new Date(
                            state.selectedDate.getFullYear(),
                            state.selectedDate.getMonth(),
                            date
                          )
                        );

                        return (
                          <th
                            colSpan={2}
                            className="text-light text-center"
                            style={{
                              backgroundColor:
                                dateString === dateNowString ? "green" : "",
                            }}
                          >
                            <div>
                              <div>{date}</div>
                              <div>{days[new Date(dateString).getDay()]}</div>
                              <div className="d-flex justify-content-between">
                                <Button
                                  variant="light"
                                  size="sm"
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      dialogShow: true,
                                      dialogInfo: {
                                        ...state.dialogInfo,
                                        date: dateString,
                                        type: "ProcessAssignment",
                                      },
                                    })
                                  }
                                >
                                  Check
                                </Button>
                                <a
                                  href={`/#/print/${dateString}`}
                                  target="_blank"
                                >
                                  <Button size="sm">Print</Button>
                                </a>
                              </div>
                            </div>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {filteredJobs.map((job) => {
                    return (
                      <>
                        <tr>
                          <td
                            className="text-center align-middle"
                            rowSpan={(job.panelCodes?.length ?? 0) + 1}
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                              border: "1px solid black",
                            }}
                          >
                            <div style={{ width: 450 }}>
                              <div className="d-flex align-items-center justify-content-center">
                                <strong>{job.name}</strong>
                                <div className="mx-2">
                                  <Link to={`/jobs/${job.id}`}>
                                    <Button size="sm" variant="info">
                                      View
                                    </Button>
                                  </Link>
                                </div>
                              </div>
                              <div>
                                <strong>
                                  (
                                  {job.individualEntity?.name ? (
                                    job.individualEntity.name
                                  ) : (
                                    <span className="text-danger">
                                      No Customer Selected
                                    </span>
                                  )}
                                  )
                                </strong>
                              </div>
                              <div>
                                Prod Deadline:{" "}
                                {makeReadableDateString(
                                  new Date(job.productionDeadline)
                                )}
                              </div>
                              <div>
                                Deliv Deadline:{" "}
                                {makeReadableDateString(
                                  new Date(job.deliveryDeadline)
                                )}
                              </div>
                              <div>
                                Done:{" "}
                                {
                                  filterNotHiddenAndSort(job.panelCodes).filter(
                                    (pc) => pc.closed
                                  ).length
                                }
                                /{filterNotHiddenAndSort(job.panelCodes).length}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {job.panelCodes
                          // .filter(panelCode =>
                          //   panelCode.wiringWorkOrders.filter(wiringWorkOrder =>
                          //     state.selectedWiringProcessType
                          //       ? wiringWorkOrder.wiringProcessType === state.selectedWiringProcessType
                          //       : true
                          //   ).length > 0
                          // )
                          ?.map((panelCode) => {
                            return (
                              <tr>
                                <td
                                  className={`text-center align-middle`}
                                  style={{
                                    position: "sticky",
                                    left: 464,
                                    backgroundColor: panelCode.closed
                                      ? `lightgreen`
                                      : `white`,
                                    border: "1px solid black",
                                  }}
                                >
                                  <div
                                    className="p-1"
                                    style={{ width: "25vw" }}
                                  >
                                    {panelCode.type}
                                    {" - "}
                                    {panelCode.name}
                                    {" | "}
                                    {panelCode.serialNumber &&
                                    panelCode.serialNumber !== "" ? (
                                      <strong>
                                        SN {panelCode.serialNumber}
                                      </strong>
                                    ) : (
                                      <strong className="text-danger">
                                        No SN
                                      </strong>
                                    )}
                                  </div>
                                  <div>
                                    Deadline:{" "}
                                    {panelCode.deadline
                                      ? makeReadableDateString(
                                          new Date(panelCode.deadline)
                                        )
                                      : ""}
                                  </div>
                                </td>
                                {[...Array(lastDate).keys()]
                                  .map((date) => date + 1)
                                  .map((date) => {
                                    const dateString = makeDateString(
                                      new Date(
                                        state.selectedDate.getFullYear(),
                                        state.selectedDate.getMonth(),
                                        date
                                      )
                                    );
                                    const dayIndex = new Date(
                                      dateString
                                    ).getDay();
                                    const isWeekend =
                                      dayIndex === 6 || dayIndex === 0;
                                    const isSunday = dayIndex === 0;

                                    return (
                                      <>
                                        <td
                                          style={{
                                            borderLeft: "2px solid black",
                                            borderRight: "1px solid gray",
                                            whiteSpace: "nowrap",
                                            backgroundColor: isWeekend
                                              ? "red"
                                              : "white",
                                          }}
                                        >
                                          {isSunday ? (
                                            <></>
                                          ) : (
                                            <div className="d-flex flex-column align-items-center">
                                              <div>
                                                <Button
                                                  size="sm"
                                                  variant="outline-secondary"
                                                  onClick={() =>
                                                    handleShowDialog(
                                                      "Plan",
                                                      dateString,
                                                      job.id ?? 0,
                                                      panelCode
                                                    )
                                                  }
                                                >
                                                  + Plan
                                                </Button>
                                              </div>
                                              <div>
                                                <ol>
                                                  {panelCode.panelCodePlans
                                                    .filter(
                                                      (panelCodePlan) =>
                                                        panelCodePlan.date ===
                                                        dateString
                                                    )
                                                    .map((panelCodePlan) => (
                                                      <li>
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              planNameColor(
                                                                panelCodePlan.name
                                                              ),
                                                          }}
                                                        >
                                                          <small className="text-white font-weight-bold p-1 m-1">
                                                            {panelCodePlan.name}
                                                          </small>
                                                        </div>
                                                      </li>
                                                    ))}
                                                </ol>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            borderRight: "2px solid black",
                                            borderLeft: "1px solid gray",
                                            whiteSpace: "nowrap",
                                            backgroundColor: isWeekend
                                              ? "red"
                                              : "white",
                                          }}
                                        >
                                          {isSunday ? (
                                            <></>
                                          ) : (
                                            <div className="d-flex flex-column align-items-center">
                                              <div>
                                                <Button
                                                  size="sm"
                                                  variant="outline-primary"
                                                  onClick={() =>
                                                    handleShowDialog(
                                                      "Mapping",
                                                      dateString,
                                                      job.id ?? 0,
                                                      panelCode
                                                    )
                                                  }
                                                >
                                                  + Mapping
                                                </Button>
                                              </div>
                                              <div>
                                                <ol>
                                                  {panelCode.wiringWorkOrders
                                                    .filter(
                                                      (wiringWorkOrder) =>
                                                        wiringWorkOrder.date ===
                                                        dateString
                                                    )
                                                    .map((wiringWorkOrder) => {
                                                      return (
                                                        <li>
                                                          <div>
                                                            <div>
                                                              {
                                                                wiringWorkOrder
                                                                  .worker?.name
                                                              }{" "}
                                                              -
                                                              <small
                                                                className="p-1 font-weight-bold text-white"
                                                                style={{
                                                                  backgroundColor:
                                                                    planNameColor(
                                                                      wiringWorkOrder.wiringProcessType
                                                                    ),
                                                                }}
                                                              >
                                                                {/* {wiringWorkOrder.wiringProcessType === ""
                                                              ? wiringWorkOrder.wiringProcessType
                                                              : wiringWorkOrder.cu
                                                            } */}
                                                                {
                                                                  wiringWorkOrder.wiringProcessType
                                                                }
                                                              </small>
                                                              :{" "}
                                                              {
                                                                wiringWorkOrder.wiringDescription
                                                              }
                                                            </div>
                                                            <div className="d-flex">
                                                              <div className="d-flex justify-content-between w-100">
                                                                <div>
                                                                  <DoneStatusCheck
                                                                    date={
                                                                      dateString
                                                                    }
                                                                    doneStatus={
                                                                      wiringWorkOrder.doneStatus
                                                                    }
                                                                  />
                                                                </div>

                                                                <div className="d-flex">
                                                                  L:{" "}
                                                                  {wiringWorkOrder?.line &&
                                                                  wiringWorkOrder.line !==
                                                                    ""
                                                                    ? wiringWorkOrder.line
                                                                    : "No"}
                                                                  , Z:{" "}
                                                                  {wiringWorkOrder?.zone &&
                                                                  wiringWorkOrder.zone !==
                                                                    ""
                                                                    ? wiringWorkOrder.zone
                                                                    : "No"}
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div>
                                                              <pre>
                                                                {wiringWorkOrder.wiringRemark !==
                                                                "" ? (
                                                                  wiringWorkOrder?.wiringRemark
                                                                ) : (
                                                                  <></>
                                                                )}
                                                              </pre>
                                                            </div>
                                                          </div>
                                                          <hr className="border border-secondary" />
                                                        </li>
                                                      );
                                                    })}
                                                </ol>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                      </>
                                    );
                                  })}
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </>
          );
        })()}
      </div>
      <Modal
        show={state.dialogShow}
        onHide={async () => {
          if (changesUnsaved) {
            if (
              window.confirm(
                "Changes unsaved. Are you sure you want to quit editing?"
              )
            ) {
              setState({ ...state, dialogShow: false });
            }
          } else {
            setState({ ...state, dialogShow: false });
          }
          // setState({ ...state, dialogShow: false });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {state.dialogInfo.type} for {state.dialogInfo.date}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(() => {
            switch (state.dialogInfo.type) {
              case "Plan":
                return (
                  <div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            setState({ ...state, dialogShow: false });
                            handleSaveFragment({
                              panelCodePlans: (() => {
                                const foundPanelCode = state.jobs
                                  ?.find(
                                    (job) => job.id === state.dialogInfo.jobId
                                  )
                                  ?.panelCodes?.find(
                                    (panelCode) =>
                                      panelCode.id ===
                                      state.dialogInfo.panelCode?.id
                                  );

                                return (
                                  foundPanelCode?.panelCodePlans
                                    .filter(
                                      (panelCodePlan) =>
                                        panelCodePlan.date ===
                                        state.dialogInfo.date
                                    )
                                    .map((pcp) => ({
                                      ...pcp,
                                      panelCode: {
                                        ...initialPanelCode,
                                        id: foundPanelCode?.id ?? null,
                                      },
                                    })) ?? []
                                );
                              })(),

                              panelCodePlanDeleteIds: state.planIdsToDelete,
                              wiringWorkOrders: [],

                              wiringWorkOrderDeleteIds: [],
                            });
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                    <div>
                      <Select
                        placeholder="Process type..."
                        options={deadlineNames}
                        onChange={(selected) =>
                          handleAddPanelCodePlan(
                            (selected as { label: string; value: string }).value
                          )
                        }
                      />
                    </div>
                    <div className="my-2">
                      <ul>
                        {state.jobs
                          ?.find((job) => job.id === state.dialogInfo.jobId)
                          ?.panelCodes?.find(
                            (panelCode) =>
                              panelCode.id === state.dialogInfo.panelCode?.id
                          )
                          ?.panelCodePlans.filter(
                            (panelCodePlan) =>
                              panelCodePlan.date === state.dialogInfo.date
                          )
                          .map((panelCodePlan) => {
                            const panelCodeId = state.dialogInfo.panelCode?.id;

                            return (
                              <li>
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      onClick={() =>
                                        handleDeletePanelCodePlan(
                                          state.dialogInfo.jobId,
                                          panelCodeId ? panelCodeId : 0,
                                          panelCodePlan
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  <div className="mx-1">
                                    {panelCodePlan.name}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                );

              case "Mapping":
                return (
                  <div>
                    <div className="d-flex justify-content-end">
                      <div>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            setState({ ...state, dialogShow: false });
                            handleSaveFragment({
                              panelCodePlans: [],
                              panelCodePlanDeleteIds: [],
                              wiringWorkOrders: (() => {
                                const foundPanelcode = state.jobs
                                  ?.find(
                                    (job) => job.id === state.dialogInfo.jobId
                                  )
                                  ?.panelCodes?.find(
                                    (panelCode) =>
                                      panelCode.id ===
                                      state.dialogInfo.panelCode?.id
                                  );

                                return (
                                  foundPanelcode?.wiringWorkOrders
                                    .filter(
                                      (wiringWorkOrder) =>
                                        wiringWorkOrder.date ===
                                        state.dialogInfo.date
                                    )
                                    ?.map((wwo) => ({
                                      ...wwo,
                                      panelCode: {
                                        ...initialPanelCode,
                                        id: foundPanelcode?.id ?? 0,
                                      },
                                    })) ?? []
                                );
                              })(),

                              wiringWorkOrderDeleteIds:
                                state.mappingIdsToDelete,
                            });
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      Is custom?{" "}
                      <Form.Check
                        className="mx-1"
                        checked={state.dialogInfo.isCustom}
                        onClick={() =>
                          setState({
                            ...state,
                            dialogInfo: {
                              ...state.dialogInfo,
                              isCustom: !state.dialogInfo.isCustom,
                              wiringProcesstype: "",
                            },
                          })
                        }
                      />
                    </div>
                    <div className="d-flex align-items-end">
                      <div className="flex-grow-1" style={{ width: 200 }}>
                        <div>
                          <AsyncSelectWrapper
                            placeholder="Worker.."
                            label="Worker"
                            url={encodeURI(
                              `${process.env.REACT_APP_BASE_URL}/roles/byname/Production Worker/users`
                            )}
                            param="name"
                            value={state.dialogInfo.worker}
                            onChange={(selected) =>
                              setState({
                                ...state,
                                dialogInfo: {
                                  ...state.dialogInfo,
                                  worker: selected,
                                },
                              })
                            }
                          />
                          {state.dialogInfo.worker ? (
                            <>
                              <div>
                                <button
                                  className="btn btn-outline-primary btn-sm"
                                  onClick={(e) => {
                                    setState({
                                      ...state,
                                      dialogInfo: {
                                        ...state.dialogInfo,
                                        worker: null,
                                      },
                                    });
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <label>Line...</label>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();

                            document.getElementById("d-info-line")?.blur();
                          }}
                        >
                          <input
                            className="form-control w-100"
                            placeholder="Line..."
                            defaultValue={state.dialogInfo.line}
                            id="d-info-line"
                            onBlur={(e) => {
                              setState({
                                ...state,
                                dialogInfo: {
                                  ...state.dialogInfo,
                                  line: e.target?.value ?? "",
                                },
                              });
                            }}
                          ></input>
                        </form>
                      </div>
                      <div className="flex-grow-1">
                        <label>Zone...</label>

                        <form
                          onSubmit={(e) => {
                            e.preventDefault();

                            document.getElementById("d-info-zone")?.blur();
                          }}
                        >
                          <input
                            className="form-control w-100"
                            placeholder="Zone..."
                            id="d-info-zone"
                            onBlur={(e) => {
                              setState({
                                ...state,
                                dialogInfo: {
                                  ...state.dialogInfo,
                                  zone: e.target?.value ?? "",
                                },
                              });
                            }}
                          ></input>
                        </form>
                      </div>
                    </div>
                    {state.dialogInfo.isCustom ? (
                      <div className="my-2">
                        <Form.Control
                          placeholder="Task name..."
                          value={state.dialogInfo.customWiringWorkOrder}
                          onChange={(e) =>
                            setState({
                              ...state,
                              dialogInfo: {
                                ...state.dialogInfo,
                                customWiringWorkOrder: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    ) : (
                      <div className="my-2">
                        <Select
                          className="my-2"
                          placeholder="Process type..."
                          options={state.dialogInfo.panelCode?.panelCodeWiringProcessTypes
                            ?.filter(
                              (wiringProcessType) => wiringProcessType.includes
                            )
                            // PROCESS TYPE DUPLICATE FILTER
                            .filter(
                              (value, index, self) =>
                                self.findIndex(
                                  (w) =>
                                    w.wiringProcessType?.name ===
                                    value.wiringProcessType?.name
                                ) === index
                            )
                            // PROCESS TYPE DUPLICATE FILTER END

                            .map((wiringProcessType) => ({
                              label: `${wiringProcessType.wiringProcessType?.wiringProcessTypeGroup?.name} - ${wiringProcessType.wiringProcessType?.name}`,
                              value: wiringProcessType.wiringProcessType?.name,
                            }))}
                          onChange={(selected) =>
                            setState({
                              ...state,
                              dialogInfo: {
                                ...state.dialogInfo,
                                wiringProcesstype: (
                                  selected as { label: string; value: string }
                                ).value,
                              },
                            })
                          }
                          // options={deadlineNames}
                          // onChange={selected => setState({
                          //   ...state,
                          //   dialogInfo: {
                          //     ...state.dialogInfo,
                          //     wiringProcesstype: (selected as { label: string, value: string }).value
                          //   }
                          // })}
                        />
                        {state.dialogInfo.wiringProcesstype ? (
                          <>
                            <div>
                              <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={(e) => {
                                  setState({
                                    ...state,
                                    dialogInfo: {
                                      ...state.dialogInfo,
                                      wiringProcesstype: "",
                                    },
                                  });
                                }}
                              >
                                Clear
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <Form.Control
                          className="my-2"
                          placeholder="Description..."
                          onBlur={(e: any) =>
                            setState({
                              ...state,
                              dialogInfo: {
                                ...state.dialogInfo,
                                wiringDescription: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    )}
                    <div>
                      <Button
                        variant="outline-primary"
                        onClick={() => handleAddPanelCodeMapping()}
                      >
                        Add
                      </Button>
                    </div>
                    <div>
                      <ul>
                        {state.jobs
                          .find((job) => job.id === state.dialogInfo.jobId)
                          ?.panelCodes?.find(
                            (panelCode) =>
                              panelCode.id === state.dialogInfo.panelCode?.id
                          )
                          ?.wiringWorkOrders.filter(
                            (wiringWorkOrder) =>
                              wiringWorkOrder.date === state.dialogInfo.date
                          )
                          .map((wiringWorkOrder) => (
                            <li className="my-2">
                              <div className="d-flex align-items-center">
                                <div>
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => {
                                      setChangesUnsaved(true);
                                      handleDeleteWiringWorkOrder(
                                        wiringWorkOrder.uuid
                                      );
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                                <div className="d-flex mx-2">
                                  {wiringWorkOrder.worker?.name} -{" "}
                                  {wiringWorkOrder.wiringProcessType}:{" "}
                                  {wiringWorkOrder.wiringDescription}
                                </div>
                              </div>

                              <div className="d-inline">
                                <strong>
                                  <DoneStatusCheck
                                    date={state.dialogInfo.date}
                                    doneStatus={wiringWorkOrder.doneStatus}
                                  />
                                </strong>{" "}
                                <div className="d-flex">
                                  <select
                                    onChange={(e) => {
                                      setChangesUnsaved(true);

                                      handleChangeWiringWorkOrderDoneStatus(
                                        wiringWorkOrder.uuid,
                                        e.target
                                          .value as WiringWorkOrderDoneStatus
                                      );
                                    }}
                                  >
                                    <option value="ON_PROGRESS">
                                      On Progress
                                    </option>
                                    <option value="FINISHED">Finished</option>
                                    {/* <option
                                    onClick={e => handleChangeWiringWorkOrderDoneStatus(wiringWorkOrder.uuid, "UNFINISHED")}
                                  >
                                    Unfinished
                                  </option> */}
                                    <option value="CANCELLED">Cancelled</option>
                                  </select>
                                </div>
                                <div>
                                  {wiringWorkOrder.extMeetingTaskId !== null &&
                                  wiringWorkOrder.extMeetingTaskId !==
                                    undefined ? (
                                    <div className="text-success font-weight-bold">
                                      Task Mapped
                                    </div>
                                  ) : (
                                    <div className="text-danger d-flex font-weight-bold">
                                      <div>Task Unmapped</div>

                                      <div>
                                        <button
                                          onClick={async () => {
                                            try {
                                              const resp = await fetch(
                                                `${process.env.REACT_APP_BASE_URL}/ext-meetingtasks-proto-save`,
                                                {
                                                  method: "post",
                                                  headers: {
                                                    "content-type":
                                                      "application/json",
                                                    authorization:
                                                      ctx?.apiKey ?? "",
                                                  },
                                                  body: JSON.stringify(
                                                    MeetingMeetingTaskPostBody.fromJSON(
                                                      {
                                                        description: `${wiringWorkOrder.wiringProcessType} ${wiringWorkOrder.wiringDescription}`,
                                                        date:
                                                          wiringWorkOrder.date !==
                                                            null &&
                                                          wiringWorkOrder.date !==
                                                            undefined &&
                                                          wiringWorkOrder.date !==
                                                            ""
                                                            ? wiringWorkOrder.date
                                                            : null,
                                                        remark:
                                                          wiringWorkOrder.wiringRemark ??
                                                          "",
                                                        ppicWorkOrderRevId: `${
                                                          wiringWorkOrder.id ??
                                                          0
                                                        }`,
                                                      } as MeetingMeetingTaskPostBody
                                                    )
                                                  ) as string,
                                                }
                                              );
                                            } catch (e) {
                                              console.error(e);
                                            }
                                          }}
                                          className="btn btn-sm btn-outline-primary"
                                        >
                                          Map Task
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex align-items-end">
                                  <div className="flex-grow-1">
                                    <label>Line...</label>
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();

                                        document
                                          .getElementById(
                                            `d-info-line-${wiringWorkOrder.uuid}`
                                          )
                                          ?.blur();
                                      }}
                                    >
                                      <input
                                        className="form-control form-control-sm  w-100"
                                        placeholder="Line..."
                                        defaultValue={
                                          wiringWorkOrder.line ?? ""
                                        }
                                        id={`d-info-line-${wiringWorkOrder.uuid}`}
                                        onBlur={(e) => {
                                          setChangesUnsaved(true);

                                          setState({
                                            ...state,
                                            jobs: state.jobs.map((jx) =>
                                              jx &&
                                              jx.id === state.dialogInfo.jobId
                                                ? {
                                                    ...jx,
                                                    panelCodes: jx.panelCodes
                                                      ? jx.panelCodes.map(
                                                          (pcx) =>
                                                            pcx &&
                                                            pcx.id ===
                                                              state.dialogInfo
                                                                .panelCode?.id
                                                              ? {
                                                                  ...pcx,
                                                                  wiringWorkOrders:
                                                                    pcx.wiringWorkOrders
                                                                      ? pcx.wiringWorkOrders.map(
                                                                          (
                                                                            wx
                                                                          ) =>
                                                                            wx &&
                                                                            wx.uuid ===
                                                                              wiringWorkOrder.uuid
                                                                              ? {
                                                                                  ...wx,
                                                                                  line:
                                                                                    e
                                                                                      .target
                                                                                      ?.value ??
                                                                                    "",
                                                                                }
                                                                              : wx
                                                                        )
                                                                      : pcx.wiringWorkOrders,
                                                                }
                                                              : pcx
                                                        )
                                                      : jx.panelCodes,
                                                  }
                                                : jx
                                            ),
                                          });
                                        }}
                                      ></input>
                                    </form>
                                  </div>
                                  <div className="flex-grow-1">
                                    <label>Zone...</label>

                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();

                                        document
                                          .getElementById(
                                            `d-info-zone-${wiringWorkOrder.uuid}`
                                          )
                                          ?.blur();
                                      }}
                                    >
                                      <input
                                        className="form-control form-control-sm w-100"
                                        placeholder="Zone..."
                                        defaultValue={
                                          wiringWorkOrder.zone ?? ""
                                        }
                                        id={`d-info-zone-${wiringWorkOrder.uuid}`}
                                        onBlur={(e) => {
                                          setChangesUnsaved(true);

                                          setState({
                                            ...state,
                                            jobs: state.jobs.map((jx) =>
                                              jx &&
                                              jx.id === state.dialogInfo.jobId
                                                ? {
                                                    ...jx,
                                                    panelCodes: jx.panelCodes
                                                      ? jx.panelCodes.map(
                                                          (pcx) =>
                                                            pcx &&
                                                            pcx.id ===
                                                              state.dialogInfo
                                                                .panelCode?.id
                                                              ? {
                                                                  ...pcx,
                                                                  wiringWorkOrders:
                                                                    pcx.wiringWorkOrders
                                                                      ? pcx.wiringWorkOrders.map(
                                                                          (
                                                                            wx
                                                                          ) =>
                                                                            wx &&
                                                                            wx.uuid ===
                                                                              wiringWorkOrder.uuid
                                                                              ? {
                                                                                  ...wx,
                                                                                  zone:
                                                                                    e
                                                                                      .target
                                                                                      ?.value ??
                                                                                    "",
                                                                                }
                                                                              : wx
                                                                        )
                                                                      : pcx.wiringWorkOrders,
                                                                }
                                                              : pcx
                                                        )
                                                      : jx.panelCodes,
                                                  }
                                                : jx
                                            ),
                                          });
                                        }}
                                      ></input>
                                    </form>
                                  </div>
                                </div>
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();

                                    document
                                      .getElementById(
                                        `text-area-remark-${wiringWorkOrder.uuid}`
                                      )
                                      ?.blur();
                                  }}
                                >
                                  <textarea
                                    id={`text-area-remark-${wiringWorkOrder.uuid}`}
                                    className="form-control form-control-sm"
                                    placeholder="Remark..."
                                    defaultValue={
                                      wiringWorkOrder.wiringRemark
                                        ? wiringWorkOrder.wiringRemark
                                        : ""
                                    }
                                    onBlur={(e) => {
                                      setChangesUnsaved(true);

                                      handleChangeWiringWorkOrderRemark(
                                        wiringWorkOrder.uuid,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </form>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                );

              case "ProcessAssignment":
                return (
                  <div>
                    {[
                      { label: "Punching", value: "P", includes: ["P"] },
                      { label: "Bending", value: "B", includes: ["B"] },
                      { label: "Welding", value: "W", includes: ["W"] },
                      { label: "Grinding", value: "G", includes: ["G"] },
                      { label: "Coating", value: "C", includes: ["C"] },
                      { label: "Assembling", value: "A", includes: ["A"] },
                      {
                        label: "Preparation Cable",
                        value: "Grouping",
                        includes: ["Grouping", "Tubing/Crimping"],
                      },
                      {
                        label: "Preparation Panel",
                        value: "Dinrail",
                        includes: [
                          "Dinrail",
                          "Cable/Support Duct",
                          "Pasang Terminal",
                          "Cutout Panel",
                          "Component",
                          "Labeling",
                          "Mimik/Nameplate",
                        ],
                      },
                      {
                        label: "Busbar",
                        value: "Potong",
                        includes: [
                          "Potong",
                          "Bending",
                          "Lubang",
                          "Baut",
                          "Heatshrink",
                          "Pasang",
                        ],
                      },
                      {
                        label: "Interkoneksi",
                        value: "WK",
                        includes: ["WK", "LR", "Interkoneksi", "Closing"],
                      },
                      {
                        label: "QC",
                        value: "Point to Point",
                        includes: [
                          "Panel",
                          "Point to Point",
                          "Test On",
                          "NC Mechanical",
                          "NC Electrical",
                          "Validasi",
                        ],
                      },
                    ].map((process) => {
                      return (
                        <div
                          className="d-flex justify-content-between p-1 text-white font-weight-bold"
                          style={{
                            backgroundColor: planNameColor(process.value),
                          }}
                        >
                          <div>{process.label}</div>
                          <div>
                            {
                              state.jobs
                                .map((job) => job.panelCodes)
                                .flat()
                                .map((panelCode) => panelCode?.panelCodePlans)
                                .flat()
                                .filter(
                                  (panelCodePlan) =>
                                    panelCodePlan?.date ===
                                    state.dialogInfo.date
                                )
                                .filter((panelCodePlan) =>
                                  process.includes.includes(
                                    panelCodePlan?.name ?? ""
                                  )
                                ).length
                            }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
            }
          })()}
        </Modal.Body>
      </Modal>
      <Modal
        show={processesForDateDialog}
        onHide={() => setProcessesForDateDialog(false)}
      >
        <Modal.Header>
          <Modal.Title>
            Plans for Date{" "}
            {processesForDateDialogDate
              ? Intl.DateTimeFormat(navigator.language ?? "en-US", {
                  dateStyle: "full",
                } as any).format(new Date(processesForDateDialogDate))
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(() => {
            const datesMapped = state.jobs.flatMap((j) =>
              j.panelCodes?.flatMap((pc) =>
                pc.panelCodePlans
                  .filter((pcp) => pcp.date === processesForDateDialogDate)
                  .flatMap((pcp) => ({
                    job: j,
                    panelCode: pc,
                    plan: pcp,
                  }))
              )
            );

            const processNames = [
              ...new Set(datesMapped.map((d) => d?.plan.name)),
            ];

            return (
              <div>
                <ol>
                  {processNames.map((pN) => {
                    const processRelevantDates = datesMapped.filter(
                      (d) => d?.plan.name === pN
                    );

                    return (
                      <li>
                        <div>
                          <div>
                            <strong>
                              {pN} (
                              {
                                deadlineNames.find((dN) => dN.value == pN)
                                  ?.label
                              }
                              )
                            </strong>
                            : {processRelevantDates?.length}
                          </div>
                          <div>
                            <ol>
                              {processRelevantDates.map((pRD) => (
                                <li>
                                  <div>
                                    <strong>{pRD?.job?.name}</strong>:{" "}
                                    {pRD?.panelCode?.type}{" "}
                                    {pRD?.panelCode?.name}{" "}
                                    {pRD?.panelCode?.serialNumber}
                                  </div>
                                </li>
                              ))}
                            </ol>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ol>
              </div>
            );
          })()}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManpowerPlanning;
