import React from 'react'
import { IndividualEntity } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const CustomerPage = (props: any) => {
  const mapperFunc = (customer: IndividualEntity) => {
    return {
      id: customer.id,
      content: [ customer.name, customer.type ]
    }
  }

  return (
    <StatefulCrudTable<IndividualEntity>  
      url="individualentities"
      head={['Name', 'Type']}
      mapper={mapperFunc}
    />
  )
}

export default CustomerPage