import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import { useHistory, useParams } from "react-router-dom";
import { fetchToolInventories, fetchToolInventory } from "../../../helpers";

const ToolInputPage = () => {
  const ctx = useContext(AppContext);

  const params = useParams() as { id: any };
  const history = useHistory();
  const [toolData, setToolData] = useState({
    purchaseDate: "",
    toolName: "",
    category: "",
    description: "",
    serialNumber: "",
    specification: "",
    brand: "",
    type: "",
    dateCalibration: "",
    dateCalibrationExpiration: "",

    calibrationNumber: "",
    storageLocation: "",
    status: "",
  });

  const fetchToolInventoryData = async () => {
    const d = await fetchToolInventory({
      apiKey: ctx?.apiKey ?? "",
      id: params.id,
    });

    if (d) {
      setToolData(d);
      // render();
    }
  };

  const handleChange = (field: string, value: string) => {
    setToolData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/toolinventories-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify([toolData]),
        }
      );

      // window.location.reload();
      history.push("/tools");
    } catch (e) {
      console.error(e);
    }
  };

  const handleInit = async () => {
    fetchToolInventoryData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>Add New Tool</h4>
        <div>
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>

      <hr className="border border-dark" />

      <form className="card p-4">
        <div className="row g-3">
          <div className="col-md-6">
            <label className="form-label">Purchase Date</label>
            <input
              type="date"
              className="form-control"
              value={toolData.purchaseDate}
              onChange={(e) => handleChange("purchaseDate", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Tool Name</label>
            <input
              type="text"
              className="form-control"
              value={toolData.toolName}
              onChange={(e) => handleChange("toolName", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Category</label>
            <input
              type="text"
              className="form-control"
              value={toolData.category}
              onChange={(e) => handleChange("category", e.target.value)}
            />
          </div>

          <div className="col-12">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              value={toolData.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Serial Number</label>
            <input
              type="text"
              className="form-control"
              value={toolData.serialNumber}
              onChange={(e) => handleChange("serialNumber", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Specification</label>
            <input
              type="text"
              className="form-control"
              value={toolData.specification}
              onChange={(e) => handleChange("specification", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Brand</label>
            <input
              type="text"
              className="form-control"
              value={toolData.brand}
              onChange={(e) => handleChange("brand", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Type</label>
            <input
              type="text"
              className="form-control"
              value={toolData.type}
              onChange={(e) => handleChange("type", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Calibration Date</label>
            <input
              type="date"
              className="form-control"
              value={toolData.dateCalibration}
              onChange={(e) => handleChange("dateCalibration", e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label className="form-label">Calibration Date Expiration</label>
            <input
              type="date"
              className="form-control"
              value={toolData.dateCalibrationExpiration}
              onChange={(e) =>
                handleChange("dateCalibrationExpiration", e.target.value)
              }
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Calibration Number</label>
            <input
              type="text"
              className="form-control"
              value={toolData.calibrationNumber}
              onChange={(e) =>
                handleChange("calibrationNumber", e.target.value)
              }
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Storage Location</label>
            <input
              type="text"
              className="form-control"
              value={toolData.storageLocation}
              onChange={(e) => handleChange("storageLocation", e.target.value)}
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Status</label>
            <input
              type="text"
              className="form-control"
              value={toolData.status}
              onChange={(e) => handleChange("status", e.target.value)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ToolInputPage;
