import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  ExtTsCrmPurchaseOrder,
  fetchExtTsCrmPurchaseOrders,
} from "../../../helpers";
import {
  ExtPurchaseOrder,
  JobSummary,
  JobSummaryView,
  JobView,
} from "../../../models/model";

const JobSummaryPage = () => {
  const [jobSummaries, setJobSummaries] = useState<JobSummaryView[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<ExtTsCrmPurchaseOrder[]>(
    []
  );
  const ctx = useContext(AppContext);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobsummaries-view?compose=true`,
        {
          headers: {
            authorization: ctx?.apiKey ?? "",
          },
        }
      );

      if (resp.status !== 200) throw await resp.text();

      const jobSummaries = await resp.json();

      const [purchaseOrdersData] = await Promise.all([
        fetchExtTsCrmPurchaseOrders(),
      ]);

      setJobSummaries(jobSummaries);
      setPurchaseOrders(purchaseOrdersData);
    } catch (e) {
      console.log("[error init]", e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <div>
            <h5>Job Summaries</h5>
          </div>
          <div className="mx-2">
            <Link to={`/jobsummary/new`}>
              <button className="btn btn-primary btn-sm">Add</button>
            </Link>
          </div>
        </div>

        <div>
          <hr className="border border-dark" />
        </div>
        <div
          className="overflow-auto shadow shadow-md border border-secondary"
          style={{
            height: "65vh",
            resize: "vertical",
          }}
        >
          <table
            style={{ borderCollapse: "separate" }}
            className="table table-sm table-bordered table-hover table-striped"
          >
            <thead>
              {[
                "#",
                "Job Name",
                "Issued Date",
                "Deadline",
                "Expected Days",
                "No. of jobs",
                "Punch",
                "Bend",
                "WeldGrind",
                "Coat",

                "PO",
                "Actions",
              ].map((h) => (
                <th
                  className="border border-dark text-light bg-dark sticky-top"
                  style={{ zIndex: 1 }}
                >
                  {h}
                </th>
              ))}
            </thead>
            <tbody>
              {jobSummaries.map((j, i) => {
                return (
                  <>
                    <tr>
                      <td className="border-secondary">{i + 1}</td>
                      <td
                        className="border-secondary"
                        style={{ maxWidth: 300 }}
                      >
                        {j.jobSummary?.name}
                      </td>
                      <td
                        className={`border-secondary ${
                          j.jobSummary?.dateIssued ? "" : "bg-warning"
                        }`}
                      >
                        {j.jobSummary?.dateIssued
                          ? Intl.DateTimeFormat(navigator.language ?? "en-US", {
                              dateStyle: "medium",
                              timeZone: "utc",
                              hourCycle: "h23",
                              // timeStyle: "long",
                            } as any).format(
                              new Date(j.jobSummary.dateIssued ?? "")
                            )
                          : "None"}
                      </td>
                      <td
                        className={`border-secondary ${
                          j.jobSummary?.deadline
                            ? new Date(
                                j.jobSummary.deadline.split("T")[0]
                              ).getTime() <
                              new Date(
                                new Date().toISOString().split("T")[0]
                              ).getTime()
                              ? "bg-danger text-light"
                              : ""
                            : "bg-warning"
                        }`}
                      >
                        {" "}
                        {j.jobSummary?.deadline
                          ? Intl.DateTimeFormat(navigator.language ?? "en-US", {
                              dateStyle: "medium",
                              timeZone: "utc",
                              hourCycle: "h23",
                              // timeStyle: "long",
                            } as any).format(
                              new Date(j.jobSummary.deadline ?? "")
                            )
                          : "None"}
                      </td>
                      <td className="border-secondary">
                        {j.jobSummary?.expectedDays}
                      </td>
                      <td className="border-secondary">
                        {j.jobsMapped?.length ?? 0}
                      </td>
                      <td className="border-secondary">
                        {j.jobsMapped?.reduce(
                          (acc, j) =>
                            acc +
                            (j.jobFepLeveleds?.reduce(
                              (acc, f) =>
                                acc +
                                (f.fepLeveledFepLeveledPrograms?.reduce(
                                  (acc, p) =>
                                    acc +
                                    (p.fepLeveledFepLeveledProgram
                                      ?.fepLeveledProgram?.runs ?? 0),
                                  0
                                ) ?? 0),
                              0
                            ) ?? 0),
                          0
                        )}
                      </td>
                      <td className="bg-secondary"></td>
                      <td className="bg-secondary"></td>
                      <td className="bg-secondary"></td>

                      <td className="border-secondary">
                        <ol>
                          {j.jobSummaryExtPurchaseOrders?.map((p) => {
                            return (
                              <li>
                                <div>
                                  <div>
                                    {(() => {
                                      const foundPurchaseOrder =
                                        purchaseOrders.find(
                                          (px) =>
                                            px.id ===
                                            p.jobSummaryExtPurchaseOrder
                                              ?.extPurchaseOrder
                                              ?.extPurchaseOrderId
                                        );

                                      return (
                                        <>
                                          {
                                            foundPurchaseOrder?.purchaseOrderNumber
                                          }{" "}
                                          ({foundPurchaseOrder?.account?.name})
                                        </>
                                      );
                                    })()}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ol>
                      </td>
                      <td className="border-secondary">
                        <div className="d-flex">
                          <Link to={`/jobsummary/${j.jobSummary?.id}`}>
                            <button className="btn btn-sm btn-primary">
                              Edit
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default JobSummaryPage;
