import { TreeItem, TreeView } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  ExtUser,
  Integration,
  IntegrationModule,
  IntegrationModuleView,
} from "../../../models/model";
import {
  defaultIntegration,
  defaultIntegrationModule,
} from "../../../models/modelinitials";
import { v4 as uuidv4 } from "uuid";
import { fetchIntegration } from "../../../helpers";
import { FileCopy } from "@material-ui/icons";
import Select from "react-select";
import {
  PpicIntegrationModuleType,
  ppicIntegrationModuleTypeFromJSON,
  ppicIntegrationModuleTypeToJSON,
} from "../../../masterbigsystem";

const IntegrationDetailPage = () => {
  const { id } = useParams() as { id?: string | null };
  const ctx = useContext(AppContext);
  const history = useHistory();

  const [integrationDetailDocument, setIntegrationDetailDocument] =
    useState<Integration>({ ...defaultIntegration });
  const [integrationModuleDeleteIds, setIntegrationModuleDeleteIds] = useState<
    number[]
  >([]);

  const handleAddIntegrationSet = () => {
    setIntegrationDetailDocument({
      ...integrationDetailDocument,
      integrationModules: integrationDetailDocument.integrationModules
        ? [
            ...integrationDetailDocument.integrationModules,
            { ...defaultIntegrationModule, uuid: uuidv4(), type: "SET" },
          ]
        : [{ ...defaultIntegrationModule, uuid: uuidv4(), type: "SET" }],
    });
  };

  const handleAddModule = (set?: IntegrationModule | null) => {
    setIntegrationDetailDocument({
      ...integrationDetailDocument,
      integrationModules: integrationDetailDocument.integrationModules
        ? integrationDetailDocument.integrationModules.map((sx, _) =>
            sx && sx.uuid === set?.uuid
              ? {
                  ...sx,
                  integrationModules: sx.integrationModules
                    ? [
                        ...sx.integrationModules,
                        {
                          ...defaultIntegrationModule,
                          type: "MODULE",
                          uuid: uuidv4(),
                        },
                      ]
                    : [
                        {
                          ...defaultIntegrationModule,
                          type: "MODULE",
                          uuid: uuidv4(),
                        },
                      ],
                }
              : sx
          )
        : integrationDetailDocument.integrationModules,
    });
  };

  const handleAddSubmodule = (module?: IntegrationModule | null) => {
    setIntegrationDetailDocument({
      ...integrationDetailDocument,
      integrationModules: integrationDetailDocument.integrationModules
        ? integrationDetailDocument.integrationModules.map((sx, _) => ({
            ...sx,
            integrationModules:
              sx.integrationModules?.map((m, _) =>
                m && m.uuid === module?.uuid
                  ? {
                      ...m,
                      integrationModules: m.integrationModules
                        ? [
                            ...m.integrationModules,
                            {
                              ...defaultIntegrationModule,
                              type: "SUBMODULE",
                              uuid: uuidv4(),
                            },
                          ]
                        : [
                            {
                              ...defaultIntegrationModule,
                              type: "SUBMODULE",
                              uuid: uuidv4(),
                            },
                          ],
                    }
                  : m
              ) ?? [],
          }))
        : integrationDetailDocument.integrationModules,
    });
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSave = async () => {
    try {
      console.log(integrationDetailDocument);
      // alert("Saving");

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/integrations-save`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(integrationDetailDocument),
        }
      );

      if (resp.status !== 200) throw await resp.text();

      // Delete integrationmodules
      await Promise.all([
        ...integrationModuleDeleteIds.map(async (id) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/integrationmodules/${id}/empty`,
              {
                method: "delete",
                headers: { authorization: ctx?.apiKey ?? "" },
              }
            );
          } catch (e) {
            console.error(e);
          }
        }),
      ]);

      history.push("/integrationlist");
    } catch (e) {
      console.error(e);
    }
  };

  const handleCopy = async (
    name: string | null,
    copySettings?: { weldCoatOnly: boolean }
  ) => {
    try {
      console.log(integrationDetailDocument);
      // alert("Saving");

      const newIntegrationDocument = {
        ...JSON.parse(JSON.stringify(integrationDetailDocument)),
        name: name,
        id: null,
      } as Integration;

      const copyIntegrationModulesRecursive = (m: IntegrationModule) => {
        m.id = null;

        if (copySettings?.weldCoatOnly) {
          // Duplicate, delete submodule
          m.integrationModules =
            m.integrationModules?.filter((m) => m.type !== "SUBMODULE") ?? [];
        }

        m.integrationModules?.forEach((m) => {
          copyIntegrationModulesRecursive(m);
        });
      };

      newIntegrationDocument.integrationModules?.forEach((m) => {
        copyIntegrationModulesRecursive(m);
      });

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/integrations-save`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(newIntegrationDocument),
        }
      );

      if (resp.status !== 200) throw await resp.text();

      history.push("/integrationlist");
    } catch (e) {
      console.error(e);
    }
  };

  const handleInit = async () => {
    if (!isNaN(parseInt(id ?? ""))) {
      const foundIntegration = await fetchIntegration({
        apiKey: ctx?.apiKey ?? "",
        id: id,
      });

      if (foundIntegration && foundIntegration.integration) {
        const mapIntegration = (
          integrationModule: IntegrationModuleView | null
        ): IntegrationModule | null =>
          integrationModule && integrationModule.integrationModule
            ? {
                ...integrationModule.integrationModule,
                integrationModules:
                  integrationModule.integrationModules
                    ?.filter((i) => i)
                    .map((i) => mapIntegration(i) as IntegrationModule) ?? [],
              }
            : null;

        setIntegrationDetailDocument({
          ...foundIntegration.integration,
          integrationModules:
            foundIntegration.integrationModules
              ?.filter((i) => i)
              .map((i) => mapIntegration(i) as IntegrationModule) ?? [],
        });
      }
    }
  };

  const handleDeleteIntegrationModule = (uuid?: string | null) => {
    let foundIntegrationModule: IntegrationModule | null | undefined;
    const newIntegrationDetailDocument = { ...integrationDetailDocument };

    const deleteIntegrationModule = (
      ims: IntegrationModule[] | null | undefined
    ) => {
      const found = ims?.find((im) => im.uuid === uuid);

      if (found) {
        foundIntegrationModule = found;
      }

      if ((ims?.findIndex((im) => im.uuid === uuid) ?? -1) > -1) {
        ims?.splice(
          ims.findIndex((im) => im.uuid === uuid),
          1
        );
      }

      ims?.forEach((im) => {
        deleteIntegrationModule(im.integrationModules);
      });
    };

    deleteIntegrationModule(newIntegrationDetailDocument.integrationModules);
    setIntegrationDetailDocument(newIntegrationDetailDocument);

    setIntegrationModuleDeleteIds([
      ...integrationModuleDeleteIds,
      foundIntegrationModule?.id ?? 0,
    ]);
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-end">
          <div className="d-flex align-items-end">
            <h4>Product Tree Detail</h4>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </button>
            </div>
          </div>

          <input
            className="form-control form-control-sm"
            placeholder="Integration document name..."
            defaultValue={integrationDetailDocument.name ?? ""}
            onBlur={(e) => {
              setIntegrationDetailDocument({
                ...integrationDetailDocument,
                name:
                  e.target.value !== ""
                    ? e.target.value
                    : integrationDetailDocument.name,
              });
            }}
          />
        </div>

        <div className="d-flex">
          <div className="flex-grow-1">
            <small>
              <strong>Default QC</strong>
            </small>
            <Select
              options={ctx?.extUsers ?? []}
              value={ctx?.extUsers.find(
                (u) =>
                  `${u.id}` === `${integrationDetailDocument.extDefaultQcId}`
              )}
              getOptionLabel={(u) =>
                `${u.username}: ${u.name}: ${u.departmentName}`
              }
              onChange={(v) => {
                const val = v as unknown as unknown as ExtUser;

                setIntegrationDetailDocument({
                  ...integrationDetailDocument,
                  extDefaultQcId: val.id,
                });
              }}
            />
          </div>
          <div className="flex-grow-1">
            <small>
              <strong>Default QA</strong>
            </small>
            <Select
              options={ctx?.extUsers ?? []}
              value={ctx?.extUsers.find(
                (u) =>
                  `${u.id}` === `${integrationDetailDocument.extDefaultQaId}`
              )}
              getOptionLabel={(u) =>
                `${u.username}: ${u.name}: ${u.departmentName}`
              }
              onChange={(v) => {
                const val = v as unknown as unknown as ExtUser;

                setIntegrationDetailDocument({
                  ...integrationDetailDocument,
                  extDefaultQaId: val.id,
                });
              }}
            />
          </div>
          <div className="flex-grow-1">
            <small>
              <strong>Default AutoAssign SPV</strong>
            </small>
            <Select
              options={ctx?.extUsers ?? []}
              value={ctx?.extUsers.find(
                (u) =>
                  `${u.id}` ===
                  `${integrationDetailDocument.extDefaultAutoAssignSpvId}`
              )}
              getOptionLabel={(u) =>
                `${u.username}: ${u.name}: ${u.departmentName}`
              }
              onChange={(v) => {
                const val = v as unknown as unknown as ExtUser;

                setIntegrationDetailDocument({
                  ...integrationDetailDocument,
                  extDefaultAutoAssignSpvId: val.id,
                });
              }}
            />
          </div>

          <div className="flex-grow-1">
            <small>
              <strong>Category</strong>
            </small>
            <Select
              options={(
                [
                  PpicIntegrationModuleType.TREE_ASSY,
                  PpicIntegrationModuleType.TREE_WIRING,
                ] as PpicIntegrationModuleType[]
              ).map((h) => ({
                label: ppicIntegrationModuleTypeToJSON(h),
                value: h,
              }))}
              value={(
                [
                  PpicIntegrationModuleType.TREE_ASSY,
                  PpicIntegrationModuleType.TREE_WIRING,
                ] as PpicIntegrationModuleType[]
              )
                .map((h) => ({
                  label: ppicIntegrationModuleTypeToJSON(h),
                  value: h,
                }))
                .find(
                  (h) =>
                    ppicIntegrationModuleTypeFromJSON(h.value) ===
                    ppicIntegrationModuleTypeFromJSON(
                      integrationDetailDocument.category
                    )
                )}
              onChange={(v) => {
                const val = v as { value: PpicIntegrationModuleType };

                setIntegrationDetailDocument({
                  ...integrationDetailDocument,
                  category: val.value,
                });
              }}
            />
          </div>
        </div>

        <hr className="border border-dark" />

        <div className="d-flex align-items-center">
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleAddIntegrationSet();
              }}
            >
              Add Set
            </button>
          </div>

          <div className="mx-2">
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                const name = prompt(
                  "Copy for weld & coat name:",
                  `${integrationDetailDocument.name} weld & coat process`
                );

                handleCopy(name, { weldCoatOnly: true });
              }}
            >
              <FileCopy /> Copy Weld & Coat
            </button>
          </div>

          <div className="mx-2">
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => {
                const name = prompt(
                  "Copy for weld & coat name:",
                  `${integrationDetailDocument.name} copy`
                );

                handleCopy(name, { weldCoatOnly: false });
              }}
            >
              <FileCopy /> Copy All
            </button>
          </div>
        </div>

        <div className="my-3">
          <TreeView
            expanded={(integrationDetailDocument.integrationModules ?? [])
              .map((s) => [
                s.uuid ?? "",
                ...(s.integrationModules ?? [])
                  .map((m) => [m.uuid ?? ""])
                  .flat(),
              ])
              .flat()}
            // expanded={[`1`]}
          >
            {(integrationDetailDocument.integrationModules ?? []).map(
              (i, i_) => {
                return (
                  <TreeItem
                    expandIcon={`+`}
                    collapseIcon={`-`}
                    style={{
                      backgroundColor: "lightsteelblue",
                      borderLeft: `2px dashed gray`,
                      borderBottom: `2px dashed gray`,
                    }}
                    nodeId={i.uuid}
                    label={
                      <div className="d-flex">
                        <div className="bg-info p-1 my-1 font-weight-bold text-light">
                          SET
                        </div>
                        <div className="m-2 font-weight-bold">
                          {i.qty}x {i.name} ({i.timeProcessMins} mins) [TOTAL:{" "}
                          {i.qty}, {(i.timeProcessMins ?? 0) * (i.qty ?? 0)}{" "}
                          min(s)]
                        </div>
                      </div>
                    }
                  >
                    <TreeItem
                      nodeId={`addmodule-${i.uuid}`}
                      label={
                        <div className="d-flex">
                          <button
                            className="btn btn-secondary btn-sm"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={() => {
                              handleAddModule(i);
                            }}
                          >
                            Add Module
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            style={{ whiteSpace: "nowrap" }}
                            onClick={() => {
                              handleDeleteIntegrationModule(i.uuid);
                            }}
                          >
                            Delete Set
                          </button>

                          <input
                            className="form-control form-control-sm mx-2"
                            placeholder="Qty..."
                            style={{ maxWidth: 100 }}
                            onBlur={(e) => {
                              if (e.target.value !== "") {
                                setIntegrationDetailDocument({
                                  ...integrationDetailDocument,
                                  integrationModules:
                                    integrationDetailDocument.integrationModules
                                      ? integrationDetailDocument.integrationModules.map(
                                          (ix) =>
                                            ix && ix.uuid === i.uuid
                                              ? {
                                                  ...ix,
                                                  qty: isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                    ? ix.qty
                                                    : parseInt(e.target.value),
                                                }
                                              : ix
                                        )
                                      : integrationDetailDocument.integrationModules,
                                });
                              }
                            }}
                          />
                          <input
                            className="form-control form-control-sm mx-2"
                            placeholder="t. proc..."
                            style={{ maxWidth: 100 }}
                            onBlur={(e) => {
                              if (e.target.value !== "") {
                                setIntegrationDetailDocument({
                                  ...integrationDetailDocument,
                                  integrationModules:
                                    integrationDetailDocument.integrationModules
                                      ? integrationDetailDocument.integrationModules.map(
                                          (ix) =>
                                            ix && ix.uuid === i.uuid
                                              ? {
                                                  ...ix,
                                                  timeProcessMins: isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                    ? ix.qty
                                                    : parseInt(e.target.value),
                                                }
                                              : ix
                                        )
                                      : integrationDetailDocument.integrationModules,
                                });
                              }
                            }}
                          />
                          <input
                            className="form-control form-control-sm mx-2"
                            placeholder="Set name..."
                            onBlur={(e) => {
                              if (e.target.value !== "") {
                                setIntegrationDetailDocument({
                                  ...integrationDetailDocument,
                                  integrationModules:
                                    integrationDetailDocument.integrationModules
                                      ? integrationDetailDocument.integrationModules.map(
                                          (ix) =>
                                            ix && ix.uuid === i.uuid
                                              ? { ...ix, name: e.target.value }
                                              : ix
                                        )
                                      : integrationDetailDocument.integrationModules,
                                });
                              }
                            }}
                          />
                        </div>
                      }
                    />
                    {(i.integrationModules ?? []).map((m, j) => {
                      return (
                        <>
                          {/* {JSON.stringify(m.uuid)} */}

                          <TreeItem
                            expandIcon={`+`}
                            collapseIcon={`-`}
                            style={{
                              backgroundColor: "lightyellow",
                              borderLeft: `2px dashed gray`,
                              borderBottom: `2px dashed gray`,
                            }}
                            nodeId={m.uuid}
                            label={
                              <div className="d-flex">
                                <div className="bg-success p-1 my-1 font-weight-bold text-light">
                                  MODULE
                                </div>
                                <div className="m-2 font-weight-bold">
                                  {m.qty}x {m.name} ({m.timeProcessMins} min(s))
                                  [TOTAL: {(m.qty ?? 0) * (i.qty ?? 0)},{" "}
                                  {(m.qty ?? 0) *
                                    (i.qty ?? 0) *
                                    (m.timeProcessMins ?? 0)}{" "}
                                  mins(s)]
                                </div>
                              </div>
                            }
                          >
                            <TreeItem
                              style={{
                                backgroundColor: "lightyellow",
                                borderLeft: `2px dashed gray`,
                                borderBottom: `2px dashed gray`,
                              }}
                              nodeId={`addsubmodule-${m.uuid}`}
                              label={
                                <div className="d-flex">
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => {
                                      handleAddSubmodule(m);
                                    }}
                                  >
                                    Add Submodule
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => {
                                      handleDeleteIntegrationModule(m.uuid);
                                    }}
                                  >
                                    Delete Module
                                  </button>

                                  <input
                                    className="form-control form-control-sm mx-2"
                                    placeholder="Qty..."
                                    style={{ maxWidth: 100 }}
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setIntegrationDetailDocument({
                                          ...integrationDetailDocument,
                                          integrationModules:
                                            integrationDetailDocument.integrationModules
                                              ? integrationDetailDocument.integrationModules.map(
                                                  (ix) =>
                                                    ix
                                                      ? {
                                                          ...ix,
                                                          integrationModules:
                                                            ix.integrationModules
                                                              ? ix.integrationModules.map(
                                                                  (mx) =>
                                                                    mx &&
                                                                    mx.uuid ===
                                                                      m.uuid
                                                                      ? {
                                                                          ...mx,
                                                                          qty: isNaN(
                                                                            parseInt(
                                                                              e
                                                                                .target
                                                                                .value
                                                                            )
                                                                          )
                                                                            ? mx.qty
                                                                            : parseInt(
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              ),
                                                                        }
                                                                      : mx
                                                                )
                                                              : ix.integrationModules,
                                                        }
                                                      : ix
                                                )
                                              : integrationDetailDocument.integrationModules,
                                        });
                                      }
                                    }}
                                  />
                                  <input
                                    className="form-control form-control-sm mx-2"
                                    placeholder="t. proc..."
                                    style={{ maxWidth: 100 }}
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setIntegrationDetailDocument({
                                          ...integrationDetailDocument,
                                          integrationModules:
                                            integrationDetailDocument.integrationModules
                                              ? integrationDetailDocument.integrationModules.map(
                                                  (ix) =>
                                                    ix
                                                      ? {
                                                          ...ix,
                                                          integrationModules:
                                                            ix.integrationModules
                                                              ? ix.integrationModules.map(
                                                                  (mx) =>
                                                                    mx &&
                                                                    mx.uuid ===
                                                                      m.uuid
                                                                      ? {
                                                                          ...mx,
                                                                          timeProcessMins:
                                                                            isNaN(
                                                                              parseInt(
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            )
                                                                              ? mx.qty
                                                                              : parseInt(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                ),
                                                                        }
                                                                      : mx
                                                                )
                                                              : ix.integrationModules,
                                                        }
                                                      : ix
                                                )
                                              : integrationDetailDocument.integrationModules,
                                        });
                                      }
                                    }}
                                  />

                                  <input
                                    className="form-control form-control-sm mx-2"
                                    placeholder="Module name..."
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setIntegrationDetailDocument({
                                          ...integrationDetailDocument,
                                          integrationModules:
                                            integrationDetailDocument.integrationModules
                                              ? integrationDetailDocument.integrationModules.map(
                                                  (ix) =>
                                                    ix
                                                      ? {
                                                          ...ix,
                                                          integrationModules:
                                                            ix.integrationModules
                                                              ? ix.integrationModules.map(
                                                                  (mx) =>
                                                                    mx &&
                                                                    mx.uuid ===
                                                                      m.uuid
                                                                      ? {
                                                                          ...mx,
                                                                          name: e
                                                                            .target
                                                                            .value,
                                                                        }
                                                                      : mx
                                                                )
                                                              : ix.integrationModules,
                                                        }
                                                      : ix
                                                )
                                              : integrationDetailDocument.integrationModules,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              }
                            />
                            {m.integrationModules?.map((sm) => {
                              return (
                                <>
                                  <TreeItem
                                    style={{
                                      backgroundColor: "lightgray",
                                      borderLeft: `2px dashed gray`,
                                      borderBottom: `2px dashed gray`,
                                    }}
                                    nodeId={sm.uuid}
                                    label={
                                      <>
                                        <div className="d-flex">
                                          <div className="bg-warning p-1 my-1 font-weight-bold">
                                            SUBMODULE
                                          </div>
                                          <div className="m-2 font-weight-bold">
                                            {sm.qty}x {sm.name} (
                                            {sm.timeProcessMins} mins) [TOTAL:{" "}
                                            {(sm.qty ?? 0) *
                                              (m.qty ?? 0) *
                                              (i.qty ?? 0)}
                                            ,{" "}
                                            {(sm.qty ?? 0) *
                                              (m.qty ?? 0) *
                                              (i.qty ?? 0) *
                                              (sm.timeProcessMins ?? 0)}{" "}
                                            min(s) ]
                                          </div>
                                        </div>

                                        <div className="d-flex">
                                          <button
                                            className="btn btn-danger btn-sm"
                                            style={{ whiteSpace: "nowrap" }}
                                            onClick={() => {
                                              handleDeleteIntegrationModule(
                                                sm.uuid
                                              );
                                            }}
                                          >
                                            Delete Submodule
                                          </button>
                                          <input
                                            className="form-control form-control-sm mx-2"
                                            placeholder="Qty..."
                                            style={{ maxWidth: 100 }}
                                            onBlur={(e) => {
                                              if (e.target.value !== "") {
                                                setIntegrationDetailDocument({
                                                  ...integrationDetailDocument,
                                                  integrationModules:
                                                    integrationDetailDocument.integrationModules
                                                      ? integrationDetailDocument.integrationModules.map(
                                                          (ix) =>
                                                            ix
                                                              ? {
                                                                  ...ix,
                                                                  integrationModules:
                                                                    ix.integrationModules
                                                                      ? ix.integrationModules.map(
                                                                          (
                                                                            mx
                                                                          ) =>
                                                                            mx
                                                                              ? {
                                                                                  ...mx,
                                                                                  integrationModules:
                                                                                    mx.integrationModules
                                                                                      ? mx.integrationModules.map(
                                                                                          (
                                                                                            smx
                                                                                          ) =>
                                                                                            smx &&
                                                                                            smx.uuid ===
                                                                                              sm.uuid
                                                                                              ? {
                                                                                                  ...smx,
                                                                                                  qty: isNaN(
                                                                                                    parseInt(
                                                                                                      e
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  )
                                                                                                    ? smx.qty
                                                                                                    : parseInt(
                                                                                                        e
                                                                                                          .target
                                                                                                          .value
                                                                                                      ),
                                                                                                }
                                                                                              : smx
                                                                                        )
                                                                                      : mx.integrationModules,
                                                                                }
                                                                              : mx
                                                                        )
                                                                      : ix.integrationModules,
                                                                }
                                                              : ix
                                                        )
                                                      : integrationDetailDocument.integrationModules,
                                                });
                                              }
                                            }}
                                          />
                                          <input
                                            className="form-control form-control-sm mx-2"
                                            placeholder="t. proc..."
                                            style={{ maxWidth: 100 }}
                                            onBlur={(e) => {
                                              if (e.target.value !== "") {
                                                setIntegrationDetailDocument({
                                                  ...integrationDetailDocument,
                                                  integrationModules:
                                                    integrationDetailDocument.integrationModules
                                                      ? integrationDetailDocument.integrationModules.map(
                                                          (ix) =>
                                                            ix
                                                              ? {
                                                                  ...ix,
                                                                  integrationModules:
                                                                    ix.integrationModules
                                                                      ? ix.integrationModules.map(
                                                                          (
                                                                            mx
                                                                          ) =>
                                                                            mx
                                                                              ? {
                                                                                  ...mx,
                                                                                  integrationModules:
                                                                                    mx.integrationModules
                                                                                      ? mx.integrationModules.map(
                                                                                          (
                                                                                            smx
                                                                                          ) =>
                                                                                            smx &&
                                                                                            smx.uuid ===
                                                                                              sm.uuid
                                                                                              ? {
                                                                                                  ...smx,
                                                                                                  timeProcessMins:
                                                                                                    isNaN(
                                                                                                      parseInt(
                                                                                                        e
                                                                                                          .target
                                                                                                          .value
                                                                                                      )
                                                                                                    )
                                                                                                      ? smx.qty
                                                                                                      : parseInt(
                                                                                                          e
                                                                                                            .target
                                                                                                            .value
                                                                                                        ),
                                                                                                }
                                                                                              : smx
                                                                                        )
                                                                                      : mx.integrationModules,
                                                                                }
                                                                              : mx
                                                                        )
                                                                      : ix.integrationModules,
                                                                }
                                                              : ix
                                                        )
                                                      : integrationDetailDocument.integrationModules,
                                                });
                                              }
                                            }}
                                          />

                                          <input
                                            className="form-control form-control-sm mx-2"
                                            placeholder="Submodule name..."
                                            onBlur={(e) => {
                                              if (e.target.value !== "") {
                                                setIntegrationDetailDocument({
                                                  ...integrationDetailDocument,
                                                  integrationModules:
                                                    integrationDetailDocument.integrationModules
                                                      ? integrationDetailDocument.integrationModules.map(
                                                          (ix) =>
                                                            ix
                                                              ? {
                                                                  ...ix,
                                                                  integrationModules:
                                                                    ix.integrationModules
                                                                      ? ix.integrationModules.map(
                                                                          (
                                                                            mx
                                                                          ) =>
                                                                            mx
                                                                              ? {
                                                                                  ...mx,
                                                                                  integrationModules:
                                                                                    mx.integrationModules
                                                                                      ? mx.integrationModules.map(
                                                                                          (
                                                                                            smx
                                                                                          ) =>
                                                                                            smx &&
                                                                                            smx.uuid ===
                                                                                              sm.uuid
                                                                                              ? {
                                                                                                  ...smx,
                                                                                                  name: e
                                                                                                    .target
                                                                                                    .value,
                                                                                                }
                                                                                              : smx
                                                                                        )
                                                                                      : mx.integrationModules,
                                                                                }
                                                                              : mx
                                                                        )
                                                                      : ix.integrationModules,
                                                                }
                                                              : ix
                                                        )
                                                      : integrationDetailDocument.integrationModules,
                                                });
                                              }
                                            }}
                                          />
                                        </div>
                                      </>
                                    }
                                  />
                                </>
                              );
                            })}
                          </TreeItem>
                        </>
                      );
                    })}
                  </TreeItem>
                );
              }
            )}
          </TreeView>
        </div>
      </div>
    </>
  );
};

export default IntegrationDetailPage;
