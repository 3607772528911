import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import { Modal, ModalBody, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  fetchKpiWoWeights,
  fetchProblemCatalogs,
  fetchWorkOrdersProtoMongo,
  getChromaColor,
  makeDateString,
} from "../../../helpers";
import chroma from "chroma-js";
import { ExtUser, KpiWoWeight } from "../../../models/model";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { TodayTwoTone } from "@material-ui/icons";
import {
  MeetingProblemCatalogs,
  MeetingTaskListsView,
  MeetingTaskListView,
} from "../../../masterbigsystem";
import { initialKpiWoWeight } from "../../../models/modelinitials";

const KpiWOPage = () => {
  const ctx = useContext(AppContext);

  const selectedUser = useRef(null as ExtUser | null);
  const [, refresh] = useState(false);
  const problemCatalogs = useRef(MeetingProblemCatalogs.fromPartial({}));
  const fetchMeetingTaskProblemCatalogsData = async () => {
    problemCatalogs.current = await fetchProblemCatalogs({
      apiKey: ctx?.apiKey ?? "",
    });

    render();
  };
  const usersRandomVal = useRef(
    [] as {
      user: ExtUser;

      capacity: number;
      capacityScore?: number;

      onTime: number;
      onTimeScore?: number;

      quality: number;
      qualityScore?: number;

      cost: number;
      costScore?: number;

      totalScore?: number;
    }[]
  );

  const isCapacitySelected = useRef(true);
  const isOnTimeSelected = useRef(false);
  const isQualitySelected = useRef(false);
  const isCostSelected = useRef(false);
  const fromDate = useRef(makeDateString(new Date()));
  const toDate = useRef(makeDateString(new Date()));
  const kpiWoWeights = useRef([] as KpiWoWeight[]);

  const totalWeightSum =
    [
      isCapacitySelected.current
        ? kpiWoWeights.current.find((w) => `${w.name}` === `capacity`)?.weight
        : null,
      isOnTimeSelected.current
        ? kpiWoWeights.current.find((w) => `${w.name}` === `ontime`)?.weight
        : null,
      isQualitySelected.current
        ? kpiWoWeights.current.find((w) => `${w.name}` === `quality`)?.weight
        : null,
      isCostSelected.current
        ? kpiWoWeights.current.find((w) => `${w.name}` === `cost`)?.weight
        : null,
    ]
      .filter((num) => num)
      .reduce((acc, w) => (acc ?? 0) + (w ?? 0), 0) ?? 1;

  const capacityContribution = isCapacitySelected.current
    ? ((kpiWoWeights.current.find((w) => `${w.name}` === `capacity`)?.weight ??
        0) /
        (totalWeightSum ?? 1)) *
      100
    : 0;

  const ontimeContribution = isOnTimeSelected.current
    ? ((kpiWoWeights.current.find((w) => `${w.name}` === `ontime`)?.weight ??
        0) /
        (totalWeightSum ?? 1)) *
      100
    : 0;

  const qualityContribution = isQualitySelected.current
    ? ((kpiWoWeights.current.find((w) => `${w.name}` === `quality`)?.weight ??
        0) /
        (totalWeightSum ?? 1)) *
      100
    : 0;

  const costContribution = isCostSelected.current
    ? ((kpiWoWeights.current.find((w) => `${w.name}` === `cost`)?.weight ?? 0) /
        (totalWeightSum ?? 1)) *
      100
    : 0;

  const fetchKpiWoWeightsData = async () => {
    try {
      const d = await fetchKpiWoWeights({ apiKey: ctx?.apiKey ?? "" });

      if (d) {
        kpiWoWeights.current = d;
        render();
      }
    } catch (e) {}
  };

  const render = () => {
    refresh((n) => !n);
  };

  const foundSelectedUser = usersRandomVal.current.find(
    (r) => `${r.user.id}` === `${selectedUser.current?.id}`
  );

  const wos = useRef(MeetingTaskListsView.fromPartial({}));

  useEffect(() => {
    usersRandomVal.current =
      ctx?.extUsers.map((u) => {
        const userWos = wos.current.taskLists.filter((tL) =>
          tL.taskList?.meetingTasks.find((mt) =>
            mt.meetingTaskInCharges.find(
              (ic) => u?.id && `${ic.extUserId}` === `${u?.id}`
            )
          )
        );

        const numOfDaysFromTo = Math.ceil(
          (new Date(`${toDate.current}T23:59:59`).getTime() -
            new Date(`${fromDate.current}T00:00:00`).getTime()) /
            86400000
        );

        const userWosFilteredUserId = userWos
          // .flatMap((tL) => tL.taskList?.meetingTasks)
          .filter((tL) =>
            tL.taskList?.meetingTasks.find((mt) =>
              mt.meetingTaskInCharges.find(
                (ic) => `${ic.extUserId}` === `${u.id}`
              )
            )
          );

        const userWosFilteredUserIdTasks = userWos
          .flatMap((tL) => tL.taskList?.meetingTasks)
          .filter((mt) =>
            mt?.meetingTaskInCharges.find(
              (ic) => `${ic.extUserId}` === `${u.id}`
            )
          );

        const hrsAllDays = userWosFilteredUserId.reduce(
          (acc, tL) => acc + (tL.taskList?.totalTimeHours ?? 0),
          0
        );

        const qualityAllDaysNum = userWosFilteredUserIdTasks.filter(
          (mt) =>
            !mt?.meetingTaskProblemCatalogs.find(
              (c) =>
                problemCatalogs.current.catalogs.find(
                  (cx) =>
                    `${cx.masterJavaBaseModel?.id}` === `${c.problemCatalogId}`
                )?.isQuality
            )
        );

        const qualityAllDays =
          qualityAllDaysNum.reduce(
            (acc, mt) => acc + (mt?.durationMins ?? 0),
            0
          ) / 60;

        const costAllDaysNum = userWosFilteredUserIdTasks.filter(
          (mt) =>
            !mt?.meetingTaskProblemCatalogs.find(
              (c) =>
                problemCatalogs.current.catalogs.find(
                  (cx) =>
                    `${cx.masterJavaBaseModel?.id}` === `${c.problemCatalogId}`
                )?.isCost
            )
        );
        const costAllDays =
          costAllDaysNum.reduce((acc, mt) => acc + (mt?.durationMins ?? 0), 0) /
          60;

        console.log(u.name, "quality", qualityAllDays);

        const processHours = (hr: number) =>
          numOfDaysFromTo > 5
            ? hr / ((5 / 7) * numOfDaysFromTo)
            : hr / numOfDaysFromTo;

        const hrs =
          numOfDaysFromTo > 5
            ? hrsAllDays / ((5 / 7) * numOfDaysFromTo)
            : hrsAllDays / numOfDaysFromTo;
        const hrsQuality =
          numOfDaysFromTo > 5
            ? qualityAllDays / ((5 / 7) * numOfDaysFromTo)
            : qualityAllDays / numOfDaysFromTo;
        const hrsCost =
          numOfDaysFromTo > 5
            ? costAllDays / ((5 / 7) * numOfDaysFromTo)
            : costAllDays / numOfDaysFromTo;

        const tasksCompletedOnTime = userWos.flatMap((tL) =>
          tL.taskList?.meetingTasks.filter((mt) => {
            return (
              mt.meetingTaskTargetDates.slice(-1)?.[0]?.date &&
              mt.completedDate &&
              new Date(mt.completedDate ?? "").getTime() <=
                new Date(
                  mt.meetingTaskTargetDates.slice(-1)?.[0]?.date ?? ""
                ).getTime()
            );
          })
        );

        if (userWos.length > 0) {
          console.log(u.name, hrs, userWos, tasksCompletedOnTime);
        }
        const capacityScore = (hrs / 8) * 100;

        const data = {
          user: u,

          capacity: hrs,
          capacityScore: capacityScore,

          onTime: tasksCompletedOnTime.length,
          onTimeScore:
            userWos.flatMap((tL) => tL.taskList?.meetingTasks).length > 0
              ? (tasksCompletedOnTime.length /
                  userWos.flatMap((tL) => tL.taskList?.meetingTasks).length) *
                capacityScore
              : 0,

          quality: qualityAllDaysNum.length,
          qualityScore: (hrsQuality / 8) * 100,

          cost: costAllDaysNum.length,
          costScore: (hrsCost / 8) * 100,

          totalScore: 0,
        };

        const totalScoreRaw = [
          isCapacitySelected.current
            ? data.capacityScore * (capacityContribution / 100)
            : null,
          isOnTimeSelected.current
            ? data.onTimeScore * (ontimeContribution / 100)
            : null,
          isQualitySelected.current
            ? data.qualityScore * (qualityContribution / 100)
            : null,
          isCostSelected.current
            ? data.costScore * (costContribution / 100)
            : null,
        ].filter((d) => d !== null && !isNaN(d));

        const totalScore =
          totalScoreRaw.reduce((acc, t) => (acc ?? 0) + (t ?? 0), 0) ?? 0;

        console.log(u.name, totalScoreRaw, totalScore);

        data.totalScore = totalScore ?? 0;

        return data;
      }) ?? [];
    render();
  }, [
    ctx?.extUsers,
    wos.current,
    isCapacitySelected.current,
    isOnTimeSelected.current,
    isQualitySelected.current,
    isCostSelected.current,
    problemCatalogs.current,
  ]);

  const handleSaveWeight = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/kpiwoweights-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(kpiWoWeights.current),
        }
      );

      window.location.reload();
    } catch (e) {}
  };

  const fetchWOData = async () => {
    const d = await fetchWorkOrdersProtoMongo({
      apiKey: ctx?.apiKey ?? "",
      from: fromDate.current,
      to: toDate.current,
    });

    if (d) {
      wos.current = d;
      render();
    }
  };

  const userWos = wos.current.taskLists.filter((tL) =>
    tL.taskList?.meetingTasks.find((mt) =>
      mt.meetingTaskInCharges.find(
        (ic) =>
          selectedUser.current &&
          `${ic.extUserId}` === `${selectedUser.current?.id}`
      )
    )
  );

  useEffect(() => {
    fetchWOData();
    fetchMeetingTaskProblemCatalogsData();
    fetchKpiWoWeightsData();
  }, []);

  return (
    <>
      <Modal
        size="xl"
        show={selectedUser.current ? true : false}
        onClose={() => {
          selectedUser.current = null;
          render();
        }}
        onHide={() => {
          selectedUser.current = null;
          render();
        }}
      >
        <ModalHeader>
          <div>
            <h4>KPI Detail</h4>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>User: {selectedUser.current?.name}</div>

          <div>No. of WOs: {userWos.length}</div>

          <div>
            Total hours:{" "}
            {Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(foundSelectedUser?.capacity ?? 0)}
          </div>

          <div>
            Total tasks:{" "}
            {Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(
              userWos.flatMap((tl) => tl.taskList?.meetingTasks).length ?? 0
            )}
          </div>

          <div>
            Total tasks completed on time:{" "}
            {Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(foundSelectedUser?.onTime ?? 0)}
          </div>

          <div>
            Total tasks with quality problem:{" "}
            {Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(
              (userWos.flatMap((tl) => tl.taskList?.meetingTasks).length ?? 0) -
                (foundSelectedUser?.quality ?? 0)
            )}
          </div>

          <div>
            Total tasks with cost problem:{" "}
            {Intl.NumberFormat("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(
              (userWos.flatMap((tl) => tl.taskList?.meetingTasks).length ?? 0) -
                (foundSelectedUser?.cost ?? 0)
            )}
          </div>

          <div>
            <strong>
              Total score:{" "}
              {Intl.NumberFormat("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }).format(foundSelectedUser?.totalScore ?? 0)}
            </strong>
          </div>

          <div>
            {" "}
            <hr className="border border-dark" />
          </div>

          <div className={`${isCapacitySelected.current ? `` : `text-danger`}`}>
            Capacity: {Math.round(foundSelectedUser?.capacityScore ?? 0)}/100 |
            Weight:{" "}
            {
              kpiWoWeights.current.find((w) => `${w.name}` === `capacity`)
                ?.weight
            }{" "}
            | Contribution:{" "}
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(capacityContribution)}
            % | final score:{" "}
            {(capacityContribution / 100) *
              (foundSelectedUser?.capacityScore ?? 0)}
          </div>
          <div className={`${isOnTimeSelected.current ? `` : `text-danger`}`}>
            On Time: {Math.round(foundSelectedUser?.onTimeScore ?? 0)}/100 |
            Weight:{" "}
            {kpiWoWeights.current.find((w) => `${w.name}` === `ontime`)?.weight}{" "}
            | Contribution:{" "}
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(ontimeContribution)}
            % | final score:{" "}
            {(ontimeContribution / 100) * (foundSelectedUser?.onTimeScore ?? 0)}
          </div>
          <div className={`${isQualitySelected.current ? `` : `text-danger`}`}>
            Quality: {Math.round(foundSelectedUser?.qualityScore ?? 0)}/100 |
            Weight:{" "}
            {
              kpiWoWeights.current.find((w) => `${w.name}` === `quality`)
                ?.weight
            }{" "}
            | Contribution:{" "}
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(qualityContribution)}
            % | final score:{" "}
            {(qualityContribution / 100) *
              (foundSelectedUser?.qualityScore ?? 0)}
          </div>
          <div className={`${isCostSelected.current ? `` : `text-danger`}`}>
            Cost: {Math.round(foundSelectedUser?.costScore ?? 0)}/100 | Weight:{" "}
            {kpiWoWeights.current.find((w) => `${w.name}` === `cost`)?.weight} |
            Contribution:{" "}
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(costContribution)}
            % | final score:{" "}
            {(costContribution / 100) * (foundSelectedUser?.costScore ?? 0)}
          </div>
        </ModalBody>
      </Modal>

      <div>
        <div>KPI WO</div>
        <hr className="border border-dark" />

        <div className="d-flex">
          <div>
            <div>
              <strong>From</strong>
            </div>
            <input
              defaultValue={fromDate.current}
              type="date"
              onBlur={(e) => {
                fromDate.current = e.target.value;
                render();
                fetchWOData();
              }}
              className="form-control form-control-sm"
            />
          </div>

          <div>
            <div>
              <strong>To</strong>
            </div>
            <input
              defaultValue={toDate.current}
              type="date"
              onBlur={(e) => {
                toDate.current = e.target.value;
                render();
                fetchWOData();
              }}
              className="form-control form-control-sm"
            />
          </div>

          <div className="mx-2">
            <div>
              <strong>Capacity?</strong>
            </div>
            <div>
              <input
                type="checkbox"
                checked={isCapacitySelected.current}
                onClick={() => {
                  isCapacitySelected.current = !isCapacitySelected.current;
                  render();
                }}
              />
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                style={{ width: 75 }}
                placeholder="Weight.."
                key={`inp-${
                  kpiWoWeights.current.find((w) => `${w.name}` === `capacity`)
                    ?.name
                }`}
                defaultValue={
                  kpiWoWeights.current.find((w) => `${w.name}` === `capacity`)
                    ?.weight ?? 0
                }
                onBlur={(e) => {
                  const foundKpi = kpiWoWeights.current.find(
                    (w) => `${w.name}` === `capacity`
                  );
                  if (foundKpi) {
                    foundKpi.name = "capacity";
                    foundKpi.weight = isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value);
                    return;
                  }
                  kpiWoWeights.current = [
                    ...kpiWoWeights.current,
                    {
                      ...initialKpiWoWeight,
                      name: "capacity",
                      weight: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  ];
                  render();
                }}
              />
            </div>
          </div>

          <div className="mx-2">
            <div>
              <strong>On Time?</strong>
            </div>
            <div>
              <input
                type="checkbox"
                checked={isOnTimeSelected.current}
                onClick={() => {
                  isOnTimeSelected.current = !isOnTimeSelected.current;
                  render();
                }}
              />
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                style={{ width: 75 }}
                placeholder="Weight.."
                key={`inp-${
                  kpiWoWeights.current.find((w) => `${w.name}` === `ontime`)
                    ?.name
                }`}
                defaultValue={
                  kpiWoWeights.current.find((w) => `${w.name}` === `ontime`)
                    ?.weight ?? 0
                }
                onBlur={(e) => {
                  const foundKpi = kpiWoWeights.current.find(
                    (w) => `${w.name}` === `ontime`
                  );
                  if (foundKpi) {
                    foundKpi.name = "ontime";
                    foundKpi.weight = isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value);
                    return;
                  }

                  kpiWoWeights.current = [
                    ...kpiWoWeights.current,
                    {
                      ...initialKpiWoWeight,
                      name: "ontime",
                      weight: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  ];
                  render();
                }}
              />
            </div>
          </div>

          <div className="mx-2">
            <div>
              <strong>Quality?</strong>
            </div>
            <div>
              <input
                type="checkbox"
                checked={isQualitySelected.current}
                onClick={() => {
                  isQualitySelected.current = !isQualitySelected.current;
                  render();
                }}
              />
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                style={{ width: 75 }}
                placeholder="Weight.."
                key={`inp-${
                  kpiWoWeights.current.find((w) => `${w.name}` === `quality`)
                    ?.name
                }`}
                defaultValue={
                  kpiWoWeights.current.find((w) => `${w.name}` === `quality`)
                    ?.weight ?? 0
                }
                onBlur={(e) => {
                  const foundKpi = kpiWoWeights.current.find(
                    (w) => `${w.name}` === `quality`
                  );
                  if (foundKpi) {
                    foundKpi.name = "quality";
                    foundKpi.weight = isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value);
                    return;
                  }

                  kpiWoWeights.current = [
                    ...kpiWoWeights.current,
                    {
                      ...initialKpiWoWeight,
                      name: "quality",
                      weight: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  ];
                  render();
                }}
              />
            </div>
          </div>
          <div className="mx-2">
            <div>
              <strong>Cost?</strong>
            </div>
            <div>
              <input
                type="checkbox"
                checked={isCostSelected.current}
                onClick={() => {
                  isCostSelected.current = !isCostSelected.current;
                  render();
                }}
              />
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                style={{ width: 75 }}
                placeholder="Weight.."
                key={`inp-${
                  kpiWoWeights.current.find((w) => `${w.name}` === `cost`)?.name
                }`}
                defaultValue={
                  kpiWoWeights.current.find((w) => `${w.name}` === `cost`)
                    ?.weight ?? 0
                }
                onBlur={(e) => {
                  const foundKpi = kpiWoWeights.current.find(
                    (w) => `${w.name}` === `cost`
                  );
                  if (foundKpi) {
                    foundKpi.name = "cost";
                    foundKpi.weight = isNaN(parseInt(e.target.value))
                      ? 0
                      : parseInt(e.target.value);
                    return;
                  }
                  kpiWoWeights.current = [
                    ...kpiWoWeights.current,
                    {
                      ...initialKpiWoWeight,
                      name: "cost",
                      weight: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  ];
                  render();
                }}
              />
            </div>
          </div>

          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSaveWeight();
              }}
            >
              Save Weights
            </button>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center align-items-stretch">
          {ctx?.extDepartments
            .filter((d) => {
              const deptUsers = ctx.extUsers.filter(
                (u) => u.departmentId === d.id
              );
              return d.name && d.name !== "" && (deptUsers?.length ?? 0) > 0;
            })
            .map((d) => {
              const deptUsers = ctx.extUsers.filter(
                (u) => u.departmentId === d.id && u.name && u.name !== ""
              );
              return (
                <>
                  <div
                    className="d-flex flex-column justify-content-between bg-light shadow shadow-md border border-dark rounded rounded-md p-2 m-2"
                    style={{ width: "15vw" }} // Adjust width and ensure full height
                  >
                    <div className="w-100 flex-grow-1">
                      {" "}
                      {/* Added flexGrow */}
                      <div>
                        <strong>{d.name}</strong>
                      </div>
                      <hr className="border border-secondary" />
                      <div className="d-flex flex-wrap justify-content-center">
                        {deptUsers.map((u, i) => {
                          const userRandomVal = usersRandomVal.current.find(
                            (ux) => `${ux.user.id}` === `${u?.id}`
                          );
                          const first =
                            (u?.name?.split(" ")?.length ?? 0) > 1
                              ? u.name?.slice(0, 2) ?? ""
                              : u.name?.slice(0, 3) ?? "";
                          const second = u.name?.split(" ")?.[1]?.[0] ?? "";
                          const combinedName = `${first}${second}`;

                          const tooltip = (
                            <Tooltip id="tooltip">
                              <strong>{u?.name}</strong>
                            </Tooltip>
                          );

                          return (
                            <>
                              <div
                                style={{ width: "45%" }}
                                className="d-flex justify-content-center my-1"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip}
                                >
                                  <button
                                    style={{
                                      width: 50,
                                      height: 50,
                                      backgroundColor: chroma
                                        .scale(["red", "yellow", "green"])(
                                          (usersRandomVal.current.find(
                                            (ux) =>
                                              `${ux.user.id}` === `${u?.id}`
                                          )?.totalScore ?? 0) / 100
                                        )
                                        .alpha(0.8)
                                        .hex(),
                                    }}
                                    onClick={() => {
                                      selectedUser.current = u;
                                      render();
                                    }}
                                    className="d-flex justify-content-center align-items-center rounded-circle"
                                  >
                                    <strong>
                                      {combinedName.toUpperCase()}
                                    </strong>
                                  </button>
                                </OverlayTrigger>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default KpiWOPage;
