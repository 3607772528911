import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  createMuiTheme,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TreeItem, TreeView } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { makeDateString } from "../../../helpers";
import { FepMulti, Job, PanelDeadline } from "../../../models/model";
import {
  initialDeadline,
  initialPanelDeadline,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const greenTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[600],
    },
  },
});

interface DeadlineMapped {
  id: number;
  plan: Date | null;
  actual: Date | null;
}

enum DeadlineType {
  Plan = "Plan",
  Actual = "Actual",
}

const MpsRevDetail = () => {
  const { id } = useParams() as { id: string };

  // Fetch
  const [job, setJob] = useState<Job | null>(null);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  // Selection
  const [showPartToPart, setShowPartToPart] = useState<boolean>(true);
  const [panelDeadline, setPanelDeadline] = useState<Date | null>(null);
  const [panelQty, setPanelQty] = useState<string>("");
  const [customCloseDate, setCustomCloseDate] = useState<boolean>(false);
  const [closeDate, setCloseDate] = useState<Date | null>(null);

  // Maps
  const [expandedPartToParts, setExpandedPartToParts] = useState<string[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [deadlines, setDeadlines] = useState<DeadlineMapped[]>([]);

  useEffect(() => {
    const parsedId = parseInt(id ? id : "");

    if (!isNaN(parsedId)) {
      if (requestStatus === RequestStatus.NotAsked) fetchJob(parsedId);
    }
  });

  const fetchJob = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/${id}/full`
      );

      if (response.status !== 200) throw "Fetching job MPS failed";

      const jobJson: Job | null | undefined = await response.json();

      // Make default nodes expanded
      const newExpandedNodes: string[] = [...expandedNodes];
      const deadlines = jobJson?.deadlines;
      const newDeadlines: DeadlineMapped[] = deadlines
        ? [...deadlines]
            .map((deadline) =>
              deadline.deadlineDetails.map((deadlineDetail) => ({
                id: deadlineDetail.id,
                plan: deadlineDetail.plan
                  ? new Date(deadlineDetail.plan)
                  : null,
                actual: deadlineDetail.actual
                  ? new Date(deadlineDetail.actual)
                  : null,
              }))
            )
            .flat()
        : [];

      const extractUuidRecursive = (fepMulti: FepMulti) => {
        newExpandedNodes.unshift(fepMulti.uuid);
        fepMulti.children.forEach((childFepMulti) =>
          extractUuidRecursive(childFepMulti)
        );
      };
      jobJson?.fepDocument?.fepMultis.forEach((fepMulti) =>
        extractUuidRecursive(fepMulti)
      );

      console.log("new deadlines:", newDeadlines);
      const parsedCloseDate =
        job && job.closingDate !== null
          ? new Date(job.closingDate)
          : new Date();

      setJob(jobJson ? jobJson : null);
      setDeadlines(newDeadlines);
      setExpandedNodes(newExpandedNodes);
      setCloseDate(parsedCloseDate);

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleAddPanelDeadline = () => {
    console.log(panelDeadline);

    if (panelDeadline !== null && !isNaN(parseInt(panelQty))) {
      if (job !== null) {
        const newPanelDeadline: PanelDeadline = {
          ...initialPanelDeadline,
          uuid: uuidv4(),
          date: makeDateString(panelDeadline),
          qty: parseInt(panelQty),
          jobId: job?.id ?? 0,
        };

        setJob({
          ...job,
          panelDeadlines: [newPanelDeadline, ...(job.panelDeadlines ?? [])],
        });
      }
    }
  };

  const handleChangeDeadlineDate = (
    type: DeadlineType,
    deadlineId: number,
    deadlineDetailId: number,
    date: Date | null
  ) => {
    if (job !== null) {
      console.log(type, date);
      const dateString = date ? makeDateString(date) : null;
      const newDeadlines = [...deadlines];
      const newJob = { ...job };

      const foundDeadlineMapped = newDeadlines.find(
        (deadline) => deadline.id === deadlineDetailId
      );

      if (foundDeadlineMapped !== undefined) {
        switch (type) {
          case DeadlineType.Plan:
            foundDeadlineMapped.plan = date;
            break;
          case DeadlineType.Actual:
            foundDeadlineMapped.actual = date;
            break;
        }
      }

      const foundDeadlineDetail = newJob?.deadlines
        ?.find((deadline) => deadline.id === deadlineId)
        ?.deadlineDetails.find(
          (deadlineDetail) => deadlineDetail.id === deadlineDetailId
        );

      if (foundDeadlineDetail !== undefined) {
        switch (type) {
          case DeadlineType.Plan:
            foundDeadlineDetail.plan = dateString;
            break;
          case DeadlineType.Actual:
            foundDeadlineDetail.actual = dateString;
            break;
        }
      }

      setDeadlines(newDeadlines);
      setJob(newJob);
    }
  };

  const handleSave = async () => {
    if (job !== null) {
      try {
        setRequestStatus(RequestStatus.Loading);

        const jobResponses = await Promise.all(
          job.panelDeadlines?.map(async (deadline) => {
            console.log("deadline:", deadline);

            return await fetch(
              `${process.env.REACT_APP_BASE_URL}/paneldeadlines/save`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(deadline),
              }
            );
          }) ?? []
        );

        const deadlineDetailResponses = await Promise.all(
          job.deadlines?.map(async (deadline) => {
            return await Promise.all(
              deadline.deadlineDetails.map(async (deadlineDetail) => {
                return await fetch(
                  `${process.env.REACT_APP_BASE_URL}/deadlinedetails`,
                  {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      ...deadlineDetail,
                      deadline: { ...initialDeadline, id: deadline.id },
                    }),
                  }
                );
              })
            );
          }) ?? []
        );

        setRequestStatus(RequestStatus.Success);
      } catch (e) {
        console.log(e);
        setRequestStatus(RequestStatus.Error);
      }
    }
  };

  const closeJob = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/jobs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...job,
          closed: true,
          closingDate: customCloseDate
            ? makeDateString(closeDate ? closeDate : new Date())
            : makeDateString(new Date()),
        }),
      });

      if (response.status !== 201) throw "Error closing job";

      setRequestStatus(RequestStatus.Success);
      window.location.reload();
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
    }
  };

  const reopenJob = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/jobs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...job,
          closed: false,
          closingDate: null,
        }),
      });

      if (response.status !== 201) throw "Error saving.";
      setRequestStatus(RequestStatus.Success);
      window.location.reload();
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
    }
  };

  // TREE VIEW

  const handleSelectNode = (event: any, nodeId: string) => {
    console.log("node ids:", nodeId);

    const newExpandedNodes = [...expandedNodes];

    if (newExpandedNodes.includes(nodeId)) {
      newExpandedNodes.splice(
        newExpandedNodes.findIndex(
          (expandedNodeId) => expandedNodeId === nodeId
        ),
        1
      );
    } else {
      newExpandedNodes.unshift(nodeId);
    }

    setExpandedNodes(newExpandedNodes);
  };

  const handleCollapseAll = () => {
    setExpandedNodes([]);
  };

  const handleExpandAll = () => {
    const newExpandedNodes: string[] = [...expandedNodes];

    const extractUuidRecursive = (fepMulti: FepMulti) => {
      newExpandedNodes.unshift(fepMulti.uuid);
      fepMulti.children.forEach((childFepMulti) =>
        extractUuidRecursive(childFepMulti)
      );
    };
    job?.fepDocument?.fepMultis.forEach((fepMulti) =>
      extractUuidRecursive(fepMulti)
    );

    setExpandedNodes(newExpandedNodes);
  };

  const handleClickPartToPartExpansion = (uuid: string) => {
    if (expandedPartToParts) {
      if (expandedPartToParts?.includes(uuid)) {
        const newExpandedParttoParts = [...expandedPartToParts];
        newExpandedParttoParts.splice(
          newExpandedParttoParts.findIndex(
            (partToPartUuid) => partToPartUuid === uuid
          ),
          1
        );
        setExpandedPartToParts(newExpandedParttoParts);
        console.log("new", newExpandedParttoParts);
      } else {
        const newExpandedParttoParts = [uuid, ...expandedPartToParts];
        setExpandedPartToParts(newExpandedParttoParts);
      }
    }
  };

  const handleExpandPartToPart = () => {
    const uuids = job?.fepDocument?.partToParts.map(
      (partToPart) => partToPart.uuid
    );

    if (uuids) {
      setExpandedPartToParts(uuids);
    }
  };

  const handleCollapsePartToPart = () => {
    setExpandedPartToParts([]);
  };

  const RecursiveFepMulti = (props: {
    fepMulti: FepMulti;
    level: number[];
  }) => {
    // If there's bend and weld, filter bend only
    const mappedFepDetailNames = props.fepMulti.fepDetails.map(
      (fepDetail) => fepDetail.processType?.name
    );

    const filteredFepDetails = (() => {
      if (
        mappedFepDetailNames.includes("Bend") &&
        mappedFepDetailNames.includes("Weld")
      ) {
        const bend = props.fepMulti.fepDetails.find(
          (fepDetail) => fepDetail.processType?.name === "Bend"
        );

        return bend ? [bend] : [];
      } else {
        return props.fepMulti.fepDetails;
      }
    })();

    return (
      <TreeItem
        nodeId={props.fepMulti.uuid}
        label={
          <Box
            p={1}
            display="flex"
            alignItems="center"
            style={{
              fontSize: 18,
              border: "1px black solid",
              // backgroundColor: workOrdersProducedQtyTotalUnwrapped < qtyNeededTotal ? red[100] : green[100]
            }}
          >
            <strong>{props.level.join(".")}</strong>&nbsp;
            {props.fepMulti.name}&nbsp;
            {filteredFepDetails.map((fepDetail) => {
              const qtyNeededTotal = props.fepMulti.qty * (job ? job.qty : 0);

              const producedTotalWrapped = job?.workOrderRevs
                ?.filter(
                  (workOrder) => workOrder.fepMulti?.id === props.fepMulti.id
                )
                .filter(
                  (workOrder) =>
                    workOrder.machine?.processType?.name ===
                    fepDetail.processType?.name
                )
                .map((workOrder) => {
                  return workOrder.workOrderActualRevs
                    .map((workOrderActual) => {
                      const produced = workOrderActual.amount;
                      const rejectedWrapped =
                        workOrderActual.workOrderActualRejects.find(
                          (reject) =>
                            reject.item?.partName ===
                            props.fepMulti.item?.partName
                        );
                      const rejected = rejectedWrapped
                        ? rejectedWrapped.qty
                        : 0;

                      return produced - rejected;
                    })
                    .reduce((acc, actualProduced) => acc + actualProduced, 0);
                })
                .reduce((acc, totalProduced) => acc + totalProduced, 0);

              const producedTotal = producedTotalWrapped
                ? producedTotalWrapped
                : 0;
              const processTypeName = (() => {
                switch (fepDetail.processType?.name) {
                  case "Assembly":
                    return props.fepMulti.topLevel
                      ? "Assembly"
                      : "Sub Assembly";

                  default:
                    return fepDetail.processType?.name;
                }
              })();

              return (
                <Box
                  p={1}
                  mx={1}
                  display="flex"
                  style={{
                    backgroundColor:
                      producedTotal < qtyNeededTotal ? red[100] : green[100],
                  }}
                >
                  <strong>
                    {processTypeName} Done: {producedTotal}/{qtyNeededTotal}
                  </strong>
                </Box>
              );
            })}
          </Box>
        }
      >
        {props.fepMulti.children.map((childFepMulti, i) => (
          <RecursiveFepMulti
            fepMulti={childFepMulti}
            level={[...props.level, i + 1]}
          />
        ))}
      </TreeItem>
    );
  };

  const FepView = () => {
    return (
      <TreeView
        defaultExpandIcon={<ChevronRightIcon />}
        defaultCollapseIcon={<ExpandMoreIcon />}
        expanded={expandedNodes}
        onNodeSelect={(event: any, nodeId: any) =>
          handleSelectNode(event, nodeId)
        }
        selected={[]}
      >
        {job?.fepDocument?.fepMultis.map((fepMulti, i) => (
          <RecursiveFepMulti fepMulti={fepMulti} level={[i + 1]} />
        ))}
      </TreeView>
    );
  };

  const PartToPartView = () => {
    return (
      <Box>
        {/* {job?.fepDocument?.partToParts.map(partToPart => {
          return (
            <Paper elevation={2}>
              <Box display="flex" alignItems="center">
                <Switch 
                  checked={expandedPartToParts?.includes(partToPart.uuid)} 
                  onClick={() => handleClickPartToPartExpansion(partToPart.uuid)}
                />
                <span style={{fontSize: 18}}>
                  {partToPart.name}
                </span>
              </Box>
              <Collapse in={expandedPartToParts?.includes(partToPart.uuid)}>
                <Box m={1}>
                  <List>
                    {partToPart.partToPartDetails.map(partToPartDetail => {
                      const partToPartNeeded = job.fepDocument?.partToPartNeededs.find(needed => needed.item?.partName === partToPartDetail.item?.partName)
                      const partToPartNeededQty = partToPartNeeded ? partToPartNeeded.qty : 0

                      const needed = partToPartNeededQty * job.qty
                      const produced = job.workOrderRevs
                        .filter(workOrder => {
                          workOrder.partToPart?.partToPartDetails
                            .map(detail => detail.item ? detail.item.partName : '')
                            .includes(partToPartDetail.item ? partToPartDetail.item.partName : '')
                        })
                        .reduce((acc, workOrder) => {
                          const produced = workOrder.amount * partToPartDetail.qtyProduced
                          return acc + produced
                        }, 0)

                      console.log('item:', partToPartDetail.item?.partName, 'qty:', produced)


                      return (
                        <ListItem>
                          <strong>{partToPartDetail.material}:&nbsp;</strong>
                          {partToPartDetail.name}&nbsp;|&nbsp;
                          <span style={{fontSize: 18, fontWeight: 'bold'}}>
                            Completed: {produced}/{needed}
                          </span>
                        </ListItem>
                      )})
                    }
                  </List>
                </Box>
              </Collapse>
            </Paper>
          )
        })} */}
        <List>
          {job?.fepDocument?.partToPartNeededs.map((partToPartNeeded) => {
            const produced = job.workOrderRevs
              ?.filter((workOrder) => {
                return workOrder.partToPart?.partToPartDetails
                  .map((partToPartDetail) => partToPartDetail.item?.partName)
                  .includes(partToPartNeeded.item?.partName);
              })
              .reduce((acc, workOrder) => {
                const qtyToProduce =
                  workOrder.partToPart?.partToPartDetails.find(
                    (partToPartDetail) =>
                      partToPartDetail.item?.partName ===
                      partToPartNeeded.item?.partName
                  )?.qtyProduced;
                const qtyToProduceUnwrapped = qtyToProduce ? qtyToProduce : 0;
                const actuals = workOrder.workOrderActualRevs.reduce(
                  (acc, actual) => {
                    const rejectAmountWrapped =
                      actual.workOrderActualRejects.find(
                        (reject) =>
                          reject.item?.partName ===
                          partToPartNeeded.item?.partName
                      )?.qty;
                    const rejectAmount = rejectAmountWrapped
                      ? rejectAmountWrapped
                      : 0;

                    return (
                      acc + actual.amount * qtyToProduceUnwrapped - rejectAmount
                    );
                  },
                  0
                );

                return acc + actuals;
              }, 0);

            const needed = job.qty * partToPartNeeded.qty;

            return (
              <ListItem>
                <Box
                  style={{
                    backgroundColor:
                      (produced ?? 0) < needed ? red[100] : green[100],
                  }}
                  p={1}
                >
                  <span style={{ fontSize: 16 }}>
                    {partToPartNeeded.item?.partName}
                  </span>
                  &nbsp;|&nbsp;
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Completed: {produced}/{needed}
                  </span>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={createMuiTheme()}>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link to="/mpsdetailed">
            <Button color="primary">
              <ChevronLeftIcon />
              Back
            </Button>
          </Link>
          {!job?.closed ? (
            <Box display="flex">
              <Box mx={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
              <Box mx={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={closeJob}
                >
                  Close Job
                </Button>
              </Box>
              <Box mx={1}>
                Custom close date?
                <Checkbox
                  onClick={() => setCustomCloseDate(!customCloseDate)}
                />
              </Box>
              <Box mx={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {customCloseDate ? (
                    <DatePicker
                      value={closeDate}
                      autoOk
                      clearable
                      format="dd/MM/yyyy"
                      label="Select Close Date..."
                      onChange={(closeDate) => setCloseDate(closeDate)}
                    />
                  ) : (
                    <></>
                  )}
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
          ) : (
            <ThemeProvider theme={greenTheme}>
              <Box display="flex" alignItems="center">
                <h3 style={{ color: red[500] }}>CLOSED {job.closingDate}</h3>
                <Box mx={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={reopenJob}
                  >
                    Reopen
                  </Button>
                </Box>
              </Box>
            </ThemeProvider>
          )}
        </Box>
        <Box>
          {requestStatus === RequestStatus.Loading ? (
            <CircularProgress disableShrink />
          ) : (
            <></>
          )}
        </Box>
        {/* <Box>{id}</Box> */}
        <Box my={1}>
          <h2>
            MPS Detail: {job?.name} for {job?.qty} unit(s)
          </h2>
        </Box>

        <Box>
          {job?.deadlines?.map((deadline) => (
            <Box display="flex" flexDirection="column">
              <Box display="flex" px={1}>
                <Box style={{ backgroundColor: "yellow" }} px={2}>
                  <h3>{deadline.name}</h3>
                </Box>
              </Box>

              <Grid container direction="row">
                {deadline.deadlineDetails.map((deadlineDetail) => (
                  <Box m={1}>
                    <Paper style={{ padding: 10 }} elevation={2}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box display="flex" flexDirection="column">
                          <strong>{deadlineDetail.name}</strong>
                          <DatePicker
                            autoOk
                            clearable
                            format="dd/MM/yyyy"
                            label="Plan"
                            value={
                              deadlines.find(
                                (deadline) => deadline.id === deadlineDetail.id
                              )?.plan
                            }
                            onChange={(date) =>
                              handleChangeDeadlineDate(
                                DeadlineType.Plan,
                                deadline.id,
                                deadlineDetail.id,
                                date
                              )
                            }
                          />
                          <DatePicker
                            autoOk
                            clearable
                            format="dd/MM/yyyy"
                            label="Actual"
                            value={
                              deadlines.find(
                                (deadline) => deadline.id === deadlineDetail.id
                              )?.actual
                            }
                            onChange={(date) =>
                              handleChangeDeadlineDate(
                                DeadlineType.Actual,
                                deadline.id,
                                deadlineDetail.id,
                                date
                              )
                            }
                          />
                        </Box>
                      </MuiPickersUtilsProvider>
                    </Paper>
                  </Box>
                ))}
              </Grid>
              <Box my={1}>
                <Divider />
              </Box>
            </Box>
          ))}
        </Box>

        <Box>
          <Box display="flex">
            <Box style={{ backgroundColor: "yellow" }} px={2}>
              <h3>Panel Deadline Progress</h3>
            </Box>
          </Box>

          <Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Deadline"
                  value={panelDeadline}
                  variant="inline"
                  autoOk
                  onChange={(date) => setPanelDeadline(date)}
                />
              </MuiPickersUtilsProvider>
              <Box width={75} mx={1}>
                <TextField
                  label="qty"
                  type="number"
                  value={panelQty}
                  fullWidth
                  onChange={(evt) => setPanelQty(evt.target.value)}
                />
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddPanelDeadline}
                >
                  Add to List
                </Button>
              </Box>
            </Box>

            <Box style={{ border: "1px solid black" }} p={1} my={1} width={400}>
              <List>
                {job?.panelDeadlines?.map((deadline) => (
                  <ListItem button style={{ width: 300 }}>
                    <h3>{deadline.qty} units</h3>
                    <Box mx={2}>{deadline.date}</Box>
                  </ListItem>
                ))}
                {job?.panelDeadlines?.length === 0 ? (
                  <>No deadlines set</>
                ) : (
                  <></>
                )}
              </List>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box display="flex">
            <Box style={{ backgroundColor: "yellow" }} px={2}>
              <h3>Part to Part Progress</h3>
            </Box>
          </Box>
          Show:{" "}
          <Switch
            checked={showPartToPart}
            onClick={() => setShowPartToPart(!showPartToPart)}
          />
          {showPartToPart ? <PartToPartView /> : <></>}
        </Box>

        <Box my={2}>
          <Box display="flex">
            <Box style={{ backgroundColor: "yellow" }} px={2}>
              <h3>FEP Detail Progress</h3>
            </Box>
          </Box>

          <Box display="flex" my={1}>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCollapseAll}
              >
                Collapse All
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleExpandAll}
              >
                Expand All
              </Button>
            </Box>
          </Box>
          <FepView />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default MpsRevDetail;
