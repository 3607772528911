import React from 'react'
import { PanelType } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const PanelTypePage = (props: any) => {
  const mapperFunc = (panelType: PanelType) => {
    return {
      id: panelType.id,
      content: [ panelType.name, panelType.code ]
    }
  }

  return (
    <StatefulCrudTable<PanelType>  
      url="paneltypes"
      head={['Name', 'Code']}
      mapper={mapperFunc}
    />
  )
}

export default PanelTypePage