import React from "react";

const WhatsappPage = () => {
  return (
    <>
      <div>
        <div>
          <h5>WhatsApp panel</h5>
        </div>
        <hr className="border border-dark" />
      </div>
    </>
  );
};

export default WhatsappPage;
