import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  DepartmentTemplateCategory,
  DepartmentTemplateGroup,
  DepartmentTemplateGroupItem,
  Job,
  JobMapped,
  PanelCodeDepartmentTemplateItem,
} from "../../../models/model";
import {
  fetchDepartmentTemplateCategories,
  fetchDepartmentTemplateGroups,
  fetchDepartmentTemplatesProto,
  fetchJobFull,
  JobPriority,
  JobTypeSelection,
  makeDateString,
} from "../../../helpers";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { PpicDepartmentTemplates } from "../../../masterbigsystem";
import { lastDayOfDecade } from "date-fns";

type ZoomLevelType = "Job" | "Product";
const zoomLevelTypes = ["Job", "Product"] as ZoomLevelType[];

type ExpandCollapseOutputType = "Collapse" | "Expand";
const expandCollapseOutputs = [
  "Collapse",
  "Expand",
] as ExpandCollapseOutputType[];

const JobGantt = () => {
  const ctx = useContext(AppContext);

  const selectedZoomLevel = useRef("Job" as ZoomLevelType);
  const selectedExpandCollapse = useRef("Collapse" as ExpandCollapseOutputType);
  const selectedYear = useRef(new Date().getFullYear());
  const job = useRef(
    null as {
      jobPriority: JobPriority;
      jobTypeSelections: JobTypeSelection[] | undefined;
      job: JobMapped;
    } | null
  );
  const departmentTemplates = useRef(PpicDepartmentTemplates.fromPartial({}));
  const templateGroups = useRef([] as DepartmentTemplateGroup[]);
  const templateCategories = useRef([] as DepartmentTemplateCategory[]);

  const fetchDepartmentTemplateGroupsData = async () => {
    const d = await fetchDepartmentTemplateGroups({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      templateGroups.current = d;
      render();
    }
  };

  const fetchDepartmentTemplateCategoryData = async () => {
    const d = await fetchDepartmentTemplateCategories({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      templateCategories.current = d;
      render();
    }
  };

  const fetchDepartmentTemplatesData = async () => {
    const d = await fetchDepartmentTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      departmentTemplates.current = d;
      render();
    }
  };

  const isDateInRange = (
    group: string,
    template: string,
    year: number,
    monthIndex: number
  ) => {
    const range = dummyTemplateRange.find(
      (r) => r.group === group && r.template === template
    );
    if (!range) return false;

    const cellDate = new Date(year, monthIndex);
    const fromDate = new Date(range.from);
    const toDate = new Date(range.to);

    return cellDate >= fromDate && cellDate <= toDate;
  };

  const dummyTemplateRange = [
    {
      group: "Pre-production",
      template: "Sales",
      from: "2023-01-01",
      to: "2023-03-15",
    },
    {
      group: "Pre-production",
      template: "CRO",
      from: "2023-03-16",
      to: "2023-05-15",
    },
    {
      group: "Pre-production",
      template: "Project",
      from: "2023-02-16",
      to: "2023-07-15",
    },
    {
      group: "Pre-production",
      template: "Purchasing",
      from: "2023-07-16",
      to: "2023-09-15",
    },
    {
      group: "Production",
      template: "PPC",
      from: "2023-09-16",
      to: "2023-11-15",
    },
    {
      group: "Production",
      template: "Mechanical",
      from: "2023-11-16",
      to: "2024-01-15",
    },
    {
      group: "Production",
      template: "Elec_LV System",
      from: "2024-01-16",
      to: "2024-03-15",
    },
    {
      group: "Production",
      template: "Elec_HV System",
      from: "2024-03-16",
      to: "2024-05-15",
    },
    {
      group: "Production",
      template: "Production & QC Procces",
      from: "2024-01-16",
      to: "2024-07-15",
    },
    {
      group: "Post-Production",
      template: "WH Loading",
      from: "2024-07-16",
      to: "2024-09-15",
    },
    {
      group: "Post-Production",
      template: "Finance",
      from: "2024-09-16",
      to: "2024-11-15",
    },
    {
      group: "Post-Production",
      template: "Finance",
      from: "2024-11-16",
      to: "2024-12-31",
    },
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const years = [
    ...[...Array(10)].map((_, n) => new Date().getFullYear() - (10 - n)),
    ...[...Array(10)].map((_, n) => new Date().getFullYear() + n),
  ];

  const { id } = useParams() as { id: any };
  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const fetchJobFullData = async () => {
    const d = await fetchJobFull({ apiKey: ctx?.apiKey ?? "", id: id });

    if (d) {
      job.current = d;
      render();
    }
  };

  const handleInit = async () => {
    fetchJobFullData();
    fetchDepartmentTemplatesData();
    fetchDepartmentTemplateGroupsData();
    fetchDepartmentTemplateCategoryData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <>
      <div>
        <div>
          <div>
            <h4>
              Job Gantt: {job.current?.job.job?.name} | Template:{" "}
              {job.current?.job.job?.departmentTemplateCategory?.name ??
                "Default"}
            </h4>
          </div>
        </div>
        <hr className="border border-dark" />
        <div className="d-flex">
          <div>
            <div>
              <strong>Year</strong>
            </div>
            <div style={{ width: 200 }}>
              <Select
                options={years.map((y) => ({ label: `${y}`, value: y }))}
                value={years
                  .map((y) => ({ label: `${y}`, value: y }))
                  .find((d) => d.value === selectedYear.current)}
                onChange={(v) => {
                  const val = v as { value: number };
                  selectedYear.current = val.value;
                  render();
                }}
              />
            </div>
          </div>

          <div>
            <div>
              <strong>Zoom Level</strong>
            </div>
            <div style={{ width: 200 }}>
              <Select
                options={zoomLevelTypes.map((y) => ({
                  label: `${y}`,
                  value: y,
                }))}
                value={zoomLevelTypes
                  .map((y) => ({ label: `${y}`, value: y }))
                  .find((d) => d.value === selectedZoomLevel.current)}
                onChange={(v) => {
                  const val = v as { value: ZoomLevelType };

                  if (val.value) {
                    selectedZoomLevel.current = val.value;
                    render();
                  }
                }}
              />
            </div>
          </div>

          <div>
            <div>
              <strong>Expand/Collapse Outputs</strong>
            </div>
            <div style={{ width: 200 }}>
              <Select
                options={expandCollapseOutputs.map((y) => ({
                  label: `${y}`,
                  value: y,
                }))}
                value={expandCollapseOutputs
                  .map((y) => ({ label: `${y}`, value: y }))
                  .find((d) => d.value === selectedExpandCollapse.current)}
                onChange={(v) => {
                  const val = v as { value: ExpandCollapseOutputType };

                  if (val.value) {
                    selectedExpandCollapse.current = val.value;
                    render();
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <strong>Legend</strong>
          </div>

          <div className="mx-2 border border-dark px-2 py-1 d-flex">
            <div style={{ backgroundColor: "orange", color: "orange" }}>
              {"||||"}
            </div>
            <div className="mx-2">Plan</div>
          </div>

          <div className="mx-2 border border-dark px-2 py-1 d-flex">
            <div style={{ backgroundColor: "green", color: "green" }}>
              {"||||"}
            </div>
            <div className="mx-2">Actual</div>
          </div>

          <div className="mx-2 border border-dark px-2 py-1 d-flex">
            <div style={{ backgroundColor: "cyan", color: "cyan" }}>
              {"||||"}
            </div>
            <div className="mx-2">Plan & Actual Overlap</div>
          </div>
        </div>

        <div>
          <hr className="border border-dark" />
        </div>

        {(selectedZoomLevel.current === "Job"
          ? [null]
          : job.current?.job.job?.panelCodes
        )?.map((c) => {
          return (
            <>
              <div></div>
              <div>
                <div>Product: {c ? `${c.type}: ${c.name}` : "All"}</div>
                <div
                  className="border border-dark overflow-auto"
                  style={{ height: "30vh", resize: "vertical" }}
                >
                  <table
                    className="table table-sm"
                    // style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {["#", "Group", "Template", "Completed"].map((h) => {
                        return (
                          <>
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                              rowSpan={3}
                            >
                              {h}
                            </th>
                          </>
                        );
                      })}
                    </tr>

                    <tr>
                      <th className="bg-dark text-light" colSpan={12}>
                        {selectedYear.current - 1}
                      </th>
                      <th className="bg-dark text-light" colSpan={12}>
                        {selectedYear.current}
                      </th>
                    </tr>

                    <tr>
                      {[...Array(2)].map((h) => {
                        return (
                          <>
                            {[
                              ...months.map((m) => {
                                return `${m}`;
                              }),
                            ].map((h) => {
                              return (
                                <>
                                  <th
                                    className="bg-dark text-light"
                                    style={{ position: "sticky", top: 0 }}
                                  >
                                    {h}
                                  </th>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </tr>

                    {templateGroups.current
                      .flatMap((g) =>
                        g.departmentTemplateGroupItems

                          ?.filter(
                            (i) =>
                              !i.hidden &&
                              (job.current?.job.job?.departmentTemplateCategory
                                ?.id
                                ? `${job.current?.job.job?.departmentTemplateCategory?.id}` ===
                                  `${g.category?.id}`
                                : !job.current?.job.job
                                    ?.departmentTemplateCategory?.id)
                          )
                          ?.flatMap((i) => {
                            let groups = [] as {
                              group: DepartmentTemplateGroup;
                              item: DepartmentTemplateGroupItem;
                              panelCodeDepartmentTemplateItem?: PanelCodeDepartmentTemplateItem;
                            }[];

                            if (selectedExpandCollapse.current === "Collapse") {
                              groups = [
                                {
                                  group: g,
                                  item: i,
                                },
                              ];
                            } else if (
                              selectedExpandCollapse.current === "Expand"
                            ) {
                              groups =
                                c?.panelCodeDepartmentTemplateItems
                                  ?.filter((iy) => {
                                    const foundDeptTemplate =
                                      departmentTemplates.current.templates.find(
                                        (t) =>
                                          t.items.find(
                                            (ix) =>
                                              `${ix.masterJavaBaseModel?.id}` ===
                                              `${iy?.departmentTemplateItem?.id}`
                                          )
                                      );

                                    return (
                                      !iy.excluded &&
                                      `${foundDeptTemplate?.masterJavaBaseModel?.id}` ===
                                        `${i.departmentTemplate?.id}`
                                    );
                                  })
                                  .map((pcdti) => {
                                    return {
                                      group: g,
                                      item: i,
                                      panelCodeDepartmentTemplateItem: pcdti,
                                    };
                                  }) ?? [];
                            }

                            return groups;
                          })
                      )
                      .map((d, i) => {
                        const rawPcdtis = (
                          c
                            ? c.panelCodeDepartmentTemplateItems
                            : job.current?.job.job?.panelCodes?.flatMap(
                                (c) => c.panelCodeDepartmentTemplateItems
                              )
                        )?.filter(
                          (pcdti) => !pcdti?.excluded && !pcdti?.hidden
                        );

                        const pcdtis =
                          rawPcdtis
                            ?.filter((pcdti) => {
                              const foundDeptTemplate =
                                departmentTemplates.current.templates.find(
                                  (t) =>
                                    t.items.find(
                                      (i) =>
                                        `${i.masterJavaBaseModel?.id}` ===
                                        `${pcdti?.departmentTemplateItem?.id}`
                                    )
                                );

                              return (
                                `${foundDeptTemplate?.masterJavaBaseModel?.id}` ===
                                `${d?.item.departmentTemplate?.id}`
                              );
                            })
                            .filter((p) => p?.startDate && p.endDate) ?? [];

                        const pcdtiStarts = [...pcdtis];
                        pcdtiStarts.sort(
                          (a, b) =>
                            new Date(a?.startDate ?? "").getTime() -
                            new Date(b?.startDate ?? "").getTime()
                        );

                        const pcdtiEnds = [...pcdtis];
                        pcdtiEnds.sort(
                          (a, b) =>
                            new Date(a?.endDate ?? "").getTime() -
                            new Date(b?.endDate ?? "").getTime()
                        );

                        const pcdtiCompletes = [...pcdtis].filter(
                          (p) => p?.completedDate
                        );
                        pcdtiCompletes.sort(
                          (a, b) =>
                            new Date(a?.completedDate ?? "").getTime() -
                            new Date(b?.completedDate ?? "").getTime()
                        );

                        const start =
                          pcdtiStarts?.[0]?.startDate?.split("T")?.[0] ?? "";

                        const end =
                          pcdtiEnds.slice(-1)?.[0]?.endDate?.split("T")?.[0] ??
                          "";

                        const complete =
                          pcdtiCompletes
                            .slice(-1)?.[0]
                            ?.completedDate?.split("T")?.[0] ?? undefined;

                        return (
                          <>
                            <tr>
                              <td className="border border-dark p-0 m-0">
                                {i + 1}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {d?.group.name}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {d?.item.departmentTemplate?.name}
                                {d?.panelCodeDepartmentTemplateItem
                                  ? ` - ${d.panelCodeDepartmentTemplateItem.departmentTemplateItem?.name}`
                                  : ``}
                              </td>
                              <td className="border border-dark p-0 m-0">
                                {pcdtis.filter((p) => p?.completedDate).length}/
                                {pcdtis.length}
                              </td>
                              {[...Array(2)].map((_, yearOffset) =>
                                months.map((_, monthIndex) => {
                                  const year =
                                    selectedYear.current - 1 + yearOffset;

                                  const firstMonthDate = makeDateString(
                                    new Date(year, monthIndex, 1)
                                  );
                                  const lastMonthDate = makeDateString(
                                    new Date(year, monthIndex + 1, 0)
                                  );

                                  const planOverlap =
                                    firstMonthDate <= end &&
                                    lastMonthDate >= start;

                                  const actualOverlap =
                                    complete &&
                                    firstMonthDate <= complete &&
                                    lastMonthDate >= complete;

                                  const backgroundColor = planOverlap
                                    ? actualOverlap
                                      ? `cyan`
                                      : `orange`
                                    : actualOverlap
                                    ? `green`
                                    : `transparent`;

                                  return (
                                    <td
                                      key={`${year}-${monthIndex}`}
                                      className=" p-0 m-0"
                                      style={{
                                        backgroundColor,
                                        borderRight: "1px solid black",
                                      }}
                                    >
                                      {/* {complete} */}
                                      {/* {pcdtis?.length} ({start} - {end}) */}
                                    </td>
                                  );
                                })
                              )}
                            </tr>
                          </>
                        );
                      })}
                  </table>
                </div>

                <div>
                  <hr />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default JobGantt;
