import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import { PanelTemplate } from "../../models/model";
import { RequestStatus } from "../../models/RequestStatus";

const PanelTemplatePage = () => {
  const state = useContext(AppContext);
  const [templateName, setTemplateName] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateGroup, setTemplateGroup] = useState("");

  const [panelTemplates, setPanelTemplates] = useState<PanelTemplate[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const resp = await fetch(
        `${state?.baseUrl}/paneltemplates-dynamicsearch?templateName=${templateName}&templateType=${templateType}&templateGroup=${templateGroup}`,
        {
          headers: {
            authorization: state?.apiKey ?? "",
          },
        }
      );

      if (resp.status !== 200) throw await resp.text();
      setPanelTemplates(await resp.json());
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log("[fetchData] error", e);

      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center my-2 ">
          <div>
            <h4>Panel Templates</h4>
          </div>

          <div className="mx-2">
            <Link to="/paneltemplates/new">
              <Button size="sm" variant="primary">
                <Add /> Add
              </Button>
            </Link>
          </div>

          <div>
            <div className="mx-2">
              {requestStatus === RequestStatus.Loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="my-2">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              fetchData();
            }}
          >
            <div className="d-flex">
              <div className="flex-grow-1">
                <FormControl
                  size="sm"
                  placeholder="Template name..."
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>
              <div className="flex-grow-1">
                <FormControl
                  size="sm"
                  placeholder="Template Type..."
                  onChange={(e) => setTemplateType(e.target.value)}
                />
              </div>
              <div className="flex-grow-1">
                <FormControl
                  size="sm"
                  placeholder="Template Group..."
                  onChange={(e) => setTemplateGroup(e.target.value)}
                />
              </div>
              <Button size="sm" type="submit">
                Search
              </Button>
            </div>
          </form>
        </div>

        <div
          className="border border-secondary overflow-auto"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <Table size="sm" style={{ borderCollapse: "separate" }}>
            <thead>
              <tr>
                {["Name", "Type", "Group"].map((head) => (
                  <th
                    className="bg-secondary text-light align-middle text-center border border-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {panelTemplates.map((panelTemplate, i) => {
                return (
                  <tr>
                    <td className="border border-dark">
                      <Link to={`/paneltemplates/${panelTemplate?.id}`}>
                        {panelTemplate.name && panelTemplate.name !== ""
                          ? panelTemplate.name
                          : "No Name"}
                      </Link>
                    </td>
                    <td className="border border-dark">
                      {panelTemplate.type && panelTemplate.type !== ""
                        ? panelTemplate.type
                        : "No Type"}
                    </td>
                    <td className="border border-dark">
                      {panelTemplate.panelGroup &&
                      panelTemplate.panelGroup !== ""
                        ? panelTemplate.panelGroup
                        : "No Group"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default PanelTemplatePage;
