import { green } from "@material-ui/core/colors";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { AppContext } from "../../../App";
import { fetchMachines, fetchWorkOrderRevs } from "../../../helpers";
import { Machine, WorkOrderRev } from "../../../models/model";
import { makeDateString } from "../../../models/modelinitials";

const ResourcePlanningPage = () => {
  const ctx = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [workOrderRevs, setWorkOrderRevs] = useState<WorkOrderRev[]>([]);

  useEffect(() => {
    handleInit();
  }, [year]);

  const handleInit = async () => {
    try {
      setLoading(true);

      const [machinesData, workOrderRevData] = await Promise.all([
        fetchMachines({ apiKey: ctx?.apiKey }),
        fetchWorkOrderRevs({ year: year, apiKey: ctx?.apiKey }),
      ]);

      setMachines(machinesData);
      setWorkOrderRevs(workOrderRevData);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const dates = [...Array(365)]
    .map((_, i) => i)
    .map((i) => {
      return new Date(new Date(`${year}-01-01`).getTime() + 86400000 * i);
    });

  const filteredDates = dates.filter((d) =>
    workOrderRevs.find((w) => w.date === makeDateString(d))
  );

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <div>
            <h4>Resource Planning {year}</h4>{" "}
          </div>{" "}
          <div className="mx-1">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setYear(year - 1);
              }}
            >
              Prev Year
            </button>
          </div>
          <div className="mx-1">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setYear(year + 1);
              }}
            >
              Next Year
            </button>
          </div>
          <div className="mx-3">
            {loading ? <Spinner animation="border" /> : <></>}
          </div>{" "}
        </div>

        <hr className="border border-dark" />

        <div
          className="border border-dark shadow shadow-md overflow-auto"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm table-bordered table-striped table-hover"
            style={{ borderCollapse: "separate" }}
          >
            <tr className="sticky-top top-0" style={{ zIndex: 1 }}>
              {["#", "Machine"].map((h) => (
                <th
                  className="border border-dark bg-dark text-light"
                  style={{
                    left: h === "Machine" ? 0 : undefined,
                    position: "sticky",
                  }}
                >
                  {h}
                </th>
              ))}
              {filteredDates.map((d) => {
                return (
                  <td
                    className="border border-dark text-light bg-dark font-weight-bold"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {Intl.DateTimeFormat(navigator.language ?? "en-US", {
                      dateStyle: "medium",
                    } as any).format(d)}
                  </td>
                );
              })}
            </tr>
            {(() => {
              const machinesClone = [...machines];

              machinesClone.sort((a, b) =>
                (a.name ?? "").localeCompare(b?.name ?? "")
              );

              return machinesClone;
            })().map((m, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>

                    <td
                      className="border border-dark bg-light"
                      style={{
                        left: 0,
                        whiteSpace: "nowrap",
                        position: "sticky",
                      }}
                    >
                      {m?.name}
                    </td>
                    {/* Dates */}
                    {filteredDates.map((d) => {
                      const woRevs = workOrderRevs.filter(
                        (w) =>
                          w.date === makeDateString(d) &&
                          w.machine?.processType?.id === m.processType?.id
                      );
                      const woRevsTime = woRevs.reduce(
                        (acc, w) => acc + (w.endTime ?? 0) - (w.startTime ?? 0),
                        0
                      );
                      const woRevsTimeHr = woRevsTime / 86400;

                      return (
                        <>
                          <td
                            className="border border-dark"
                            style={{
                              backgroundColor:
                                (woRevs?.length ?? 0) > 0 ? green[200] : "",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {(woRevs?.length ?? 0) > 0 ? (
                              <>
                                {woRevs?.length} WOs{" "}
                                <strong>({woRevsTimeHr.toFixed(2)} hr)</strong>
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        </>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default ResourcePlanningPage;
