import React from 'react'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'
import { Ral } from '../../../models/model'

const RalPage = (props: any) => {
  const mapperFunc = (ral: Ral) => {
    return {
      id: ral.id,
      content: [ ral.name ]
    }
  }

  return (
    <StatefulCrudTable<Ral>  
      url="rals"
      head={['Name']}
      mapper={mapperFunc}
    />
  )
}

export default RalPage