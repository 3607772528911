import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchPrePr,
  fetchPrePrs,
  fetchWarehouseItemsProto,
  makeDateString,
} from "../../helpers";
import { AppContext } from "../../App";
import {
  PrePr,
  PrePr_PrePrStatus,
  prePr_PrePrStatusFromJSON,
  PrePrItem,
  PrePrs,
  WarehouseItems,
} from "../../masterbigsystem";
import { SwapHorizOutlined } from "@material-ui/icons";
import { Dialog } from "@material-ui/core";
import {
  initialExtPurchaseRequestPostBodyItemJson,
  initialExtPurchaseRequestPostBodyJson,
} from "../../models/modelinitials";
import { ExtPurchaseRequestPostBodyJson } from "../../models/model";

const PrePRPage = () => {
  const { id } = useParams() as { id: any };
  const ctx = useContext(AppContext);

  const prePr = useRef(PrePr.fromPartial({}));

  const [, refresh] = useState(false);
  const items = useRef(WarehouseItems.fromPartial({}));
  const render = () => {
    refresh((n) => !n);
  };

  const searchItemFilter = useRef("");
  const handleFetchWarehouseItems = async () => {
    const d = await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      items.current = d;
      render();
    }
  };
  const selectItemModal = useRef(false);
  const selectedPrePrItem = useRef(PrePrItem.fromPartial({}));

  const handleFetchPrePr = async () => {
    if (!isNaN(parseInt(id))) {
      const d = await fetchPrePr({ apiKey: ctx?.apiKey ?? "", id: id });

      if (d) {
        prePr.current = d;
        render();
      }
    }
  };

  const handleInit = async () => {
    handleFetchPrePr();
    handleFetchWarehouseItems();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSave = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/preprs`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: ctx?.apiKey ?? "",
        },
        body: JSON.stringify(PrePrs.fromPartial({ prePrs: [prePr.current] })),
      });

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const handleConvertPR = async () => {
    if (
      !window.confirm(
        `Confirm create PR:\n${prePr.current.items
          .filter((i) => i.extItemId)
          .map((i, i_) => {
            const foundItem = items.current.items.find(
              (ix) => `${ix.id}` === `${i.extItemId}`
            );
            return `${i_ + 1}. ${foundItem?.partNum}: ${foundItem?.partName}`;
          })
          .join("\n")}`
      )
    ) {
      return;
    }

    const created = ctx?.extUsers.find(
      (u) =>
        `${u.id}` === `${prePr.current.masterJavaBaseModel?.extCreatedById}`
    );

    const approved = ctx?.extUsers.find(
      (u) => `${u.id}` === `${prePr.current.approvedById}`
    );

    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/ext-purchase-requests-save-json`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...initialExtPurchaseRequestPostBodyJson,
          createdBy: created?.name ?? "",
          requestFrom: created?.name ?? "",
          purposeRemark: `PREPR/${new Date(
            prePr.current.masterJavaBaseModel?.createdAt ?? new Date()
          ).getFullYear()}/${prePr.current.masterJavaBaseModel?.id}`,
          purpose: "INTERNAL",
          prDate: makeDateString(new Date()),
          extPurchaseRequestPostBodyItemsJson: prePr.current.items
            .filter((i) => i.extItemId)
            .map((pi) => {
              return {
                ...initialExtPurchaseRequestPostBodyItemJson,
                productId: pi.extItemId ?? 0,
                qtyPr: pi.qty ?? 0,
              };
            }),
        } as ExtPurchaseRequestPostBodyJson),
      }
    );
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>
            Pre-PR: PREPR/
            {new Date(
              prePr.current.masterJavaBaseModel?.createdAt ?? new Date()
            ).getFullYear()}
            /{id}
          </h4>

          <div className="mx-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>

          <div className="mx-2">
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                handleConvertPR();
              }}
            >
              <SwapHorizOutlined /> Convert to PR
            </button>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          Requested by:{" "}
          <strong>
            {
              ctx?.extUsers.find(
                (u) =>
                  `${u.id}` ===
                  `${prePr.current.masterJavaBaseModel?.extCreatedById}`
              )?.name
            }
          </strong>
        </div>

        <div>
          Approved by:{" "}
          <strong>
            {
              ctx?.extUsers.find(
                (u) => `${u.id}` === `${prePr.current.approvedById}`
              )?.name
            }
          </strong>
        </div>

        <div className="d-flex">
          Status:
          {[
            PrePr_PrePrStatus.PREPR_OUTSTANDING,
            PrePr_PrePrStatus.PREPR_APPROVED,
            PrePr_PrePrStatus.PREPR_CANCELLED,
          ].map((s) => {
            return (
              <>
                <div className="mx-2">
                  <button
                    onClick={() => {
                      prePr.current.prePrStatus = s;

                      if (ctx?.gspeAppUser?.id) {
                        prePr.current.approvedById = ctx.gspeAppUser.id
                          ? `${ctx.gspeAppUser.id}`
                          : undefined;
                      } else {
                        alert("GSPE App user not detected. Please re-login");
                      }

                      render();
                    }}
                    className={`btn btn-sm ${
                      prePr_PrePrStatusFromJSON(prePr.current.prePrStatus) === s
                        ? `btn-primary`
                        : `btn-outline-primary`
                    }`}
                  >
                    {s}
                  </button>
                </div>
              </>
            );
          })}
        </div>

        <hr className="border border-dark" />
        <strong>Items</strong>
        <div
          className="overflow-auto shadow shadow-md border border-dark"
          style={{ resize: "vertical", height: "65vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "#",
                "Description",
                "Select PN",
                "MFR",
                "PN",
                "Part Name",
                "Part Desc",
                "Qty",
                "UM",
              ].map((h) => (
                <>
                  <th
                    className="bg-dark text-light p-0 m-0"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              ))}
            </tr>
            {prePr.current.items.map((i, i_) => {
              const foundItem = items.current.items.find(
                (ix) => `${ix.id}` === `${i.extItemId}`
              );
              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i_ + 1}</td>
                    <td className="border border-dark p-0 m-0">
                      {i.description}
                    </td>
                    <td className={`border border-dark p-0 m-0`}>
                      <button
                        className="btn btn-sm btn-primary px-1 py-0"
                        onClick={() => {
                          selectItemModal.current = true;
                          selectedPrePrItem.current = i;
                          render();
                        }}
                      >
                        Select Item
                      </button>
                    </td>
                    <td
                      className={`border border-dark p-0 m-0 ${
                        foundItem
                          ? `bg-success text-light`
                          : items.current.items.length === 0
                          ? ``
                          : `bg-warning`
                      }`}
                    >
                      {foundItem?.mfr}
                    </td>
                    <td
                      className={`border border-dark p-0 m-0 ${
                        foundItem
                          ? `bg-success text-light`
                          : items.current.items.length === 0
                          ? ``
                          : `bg-warning`
                      }`}
                    >
                      {foundItem?.partNum}
                    </td>
                    <td
                      className={`border border-dark p-0 m-0 ${
                        foundItem
                          ? `bg-success text-light`
                          : items.current.items.length === 0
                          ? ``
                          : `bg-warning`
                      }`}
                    >
                      {foundItem?.partName}
                    </td>
                    <td
                      className={`border border-dark p-0 m-0 ${
                        foundItem
                          ? `bg-success text-light`
                          : items.current.items.length === 0
                          ? ``
                          : `bg-warning`
                      }`}
                    >
                      {foundItem?.partDesc}
                    </td>
                    <td className={`border border-dark p-0`}>
                      <input
                        style={{ maxWidth: 75 }}
                        defaultValue={i.qty}
                        className="form-control form-control-sm"
                        key={`prepr-item-qty-${i.masterJavaBaseModel?.uuid}`}
                        onBlur={(e) => {
                          if (!isNaN(parseFloat(e.target.value))) {
                            i.qty = parseFloat(e.target.value);
                          }

                          render();
                        }}
                      />
                    </td>
                    <td
                      className={`border border-dark p-0 m-0 ${
                        foundItem
                          ? `bg-success text-light`
                          : items.current.items.length === 0
                          ? ``
                          : `bg-warning`
                      }`}
                    >
                      {foundItem?.defaultUm}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>

      <Dialog
        open={selectItemModal.current}
        onClose={() => {
          selectItemModal.current = false;
          render();
          // setSelectBomItemModalOpen(false);
        }}
      >
        <div className="m-3">
          {items.current.items.length} items detected.
          <div className="d-flex">
            <input
              placeholder="Search by mfr, part desc, part name, part num..."
              className="form-control form-control-sm"
              onBlur={(e) => {
                searchItemFilter.current = e.target.value;
                render();
                // setSearchItemFilter(e.target.value);
              }}
            />
            <button className="btn btn-dark btn-sm">Search</button>
          </div>
          {items.current.items
            .filter(
              (i) =>
                searchItemFilter.current !== "" &&
                `${i.mfr}${i.partDesc}${i.partName}${i.partNum}`
                  .toLowerCase()
                  .includes(searchItemFilter.current.toLowerCase())
            )
            .map((i, i_) => {
              return (
                <div
                  onClick={() => {
                    selectItemModal.current = false;
                    selectedPrePrItem.current.extItemId = i.id;
                    render();
                  }}
                  className="p-2 my-1 border border-dark"
                  style={{ cursor: "pointer" }}
                >
                  {`#${i_ + 1} (ID: #${i.id}) (${i.partNum} ${i.mfr}) ${
                    i.partName
                  } - ${i.partDesc}`}
                </div>
              );
            })}
        </div>
      </Dialog>
    </>
  );
};
export default PrePRPage;
