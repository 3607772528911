import { DetailsTwoTone } from "@material-ui/icons";
import chroma from "chroma-js";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "../../App";
import {
  fetchAttendanceNotes,
  fetchAttendanceNoteTypes,
  fetchDepartmentTemplatesProto,
  fetchKpiApiProto,
  fetchPanelCodeDepartmentTemplateItemsProto,
  fetchRcemDepartmentsProto,
  fetchRcemTasklistMongoKpi,
  makeDateString,
} from "../../helpers";
import {
  MeetingKpi,
  MeetingTaskListsView,
  PpicDepartmentTemplates,
  PpicPanelCodeDepartmentTemplateItems,
  PpicRcemDepartments,
} from "../../masterbigsystem";
import { AttendanceNote, AttendanceNoteType } from "../../models/meeting";
import { ExtDepartment, ExtUser } from "../../models/model";

export type KpiRange =
  | -365
  | -84
  | -28
  | -7
  | -1
  | 0
  | 1
  | 7
  | 28
  | 84
  | 365
  | 10
  | 11
  | 12
  | 13
  | 14;
export const kpiRanges: KpiRange[] = [
  0, 1, 7, 28, 84, 365, -1, -7, -28, -84, -365,
];

export interface AliasDetail {
  range: KpiRange;
  alias: string;
  startDate?: string;
  endDate?: string;
  daysCount?: number;
}

export const aliases: AliasDetail[] = [
  { range: -84, alias: "LQ" },
  { range: -28, alias: "LM" },
  { range: -7, alias: "LW" },
  {
    range: -1,
    alias: "LD",
    ...(() => {
      const lastDay = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 1
      );

      return {
        startDate: makeDateString(lastDay),
        endDate: makeDateString(lastDay),
        daysCount: Math.round(
          (lastDay.getTime() - lastDay.getTime()) / 86400000
        ),
      };
    })(),
  },
  { range: 0, alias: "TO" },
  { range: 1, alias: "ND" },
  { range: 7, alias: "NW" },
  {
    range: 28,
    alias: "NM",
    ...(() => {
      const startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      );
      const endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 2,
        0
      );

      return {
        startDate: makeDateString(startDate),
        endDate: makeDateString(endDate),
        daysCount: Math.round(
          (endDate.getTime() - startDate.getTime()) / 86400000
        ),
      };
    })(),
  },
  { range: 84, alias: "NQ" },
  {
    range: 10,
    alias: "CW",
    ...(() => {
      const startDate = new Date(
        new Date().getTime() - 86400000 * new Date().getDay() + 86400000
      );

      return {
        startDate: makeDateString(startDate),
        endDate: makeDateString(new Date(startDate.getTime() + 86400000 * 6)),
        daysCount: 7,
      };
    })(),
  },
  {
    range: 11,
    alias: "CM",
    ...(() => {
      return {
        startDate: makeDateString(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        endDate: makeDateString(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, -1)
        ),
        daysCount:
          new Date(
            new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              -1
            ).getTime() -
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                1
              ).getTime()
          ).getTime() / 86400000,
      };
    })(),
  },
  {
    range: 12,
    alias: "LCW",
    ...(() => {
      const endDate = new Date(
        new Date().getTime() - 86400000 * new Date().getDay() + 86400000
      );

      return {
        startDate: makeDateString(new Date(endDate.getTime() - 86400000 * 7)),
        endDate: makeDateString(endDate),
      };
    })(),
  },
  {
    range: 13,
    alias: "LCM",
    ...(() => {
      return {
        startDate: makeDateString(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        ),
        endDate: makeDateString(
          new Date(new Date().getFullYear(), new Date().getMonth(), -1)
        ),
      };
    })(),
  },
  {
    range: 14,
    alias: "NM+1",
    ...(() => {
      const startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 2,
        1
      );
      const endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 3,
        0
      );

      return {
        startDate: makeDateString(startDate),
        endDate: makeDateString(endDate),
        daysCount: Math.round(
          (endDate.getTime() - startDate.getTime()) / 86400000
        ),
      };
    })(),
  },
];
export const kpiRangesMonth: KpiRange[] = [
  0, 1, 7, 12, 28, 13, 14, -1, -7, -28, -84, 10, 11,
];
export const kpiRangesPositive: KpiRange[] = [0, 1, 7, 28, 14, 365];
export const kpiRangesPositiveMonth: KpiRange[] = [0, 1, 10, 7, 11, 28, 14];

export const kpiRangesNegative: KpiRange[] = [0, -1, -7, -28, -84, -365];
export const kpiRangesNegativeMonth: KpiRange[] = [0, -1, -7, -28, -84];

const RcemKpiPage = () => {
  const ctx = useContext(AppContext);

  const [rcemDepartments, setRcemDepartments] = useState<PpicRcemDepartments>(
    PpicRcemDepartments.fromPartial({})
  );
  const [departmentTemplates, setDepartmentTemplates] =
    useState<PpicDepartmentTemplates>(PpicDepartmentTemplates.fromPartial({}));

  const [workOrders, setWorkOrders] = useState<MeetingTaskListsView>(
    MeetingTaskListsView.fromPartial({ taskLists: [] })
  );
  const [attendanceNoteTypes, setAttendanceNoteTypes] = useState<
    AttendanceNoteType[]
  >([]);
  const [attendanceNotes, setAttendanceNotes] = useState<AttendanceNote[]>([]);
  const [selectedDept, setSelectedDept] = useState<ExtDepartment | null>(null);
  const [selectedUser, setSelectedUser] = useState<ExtUser | null>(null);

  const [kpiRangesData, setKpiRangesData] = useState<
    {
      range: KpiRange;
      kpi: MeetingKpi;
    }[]
  >([]);

  const [kpiRangesLoading, setKpiRangesLoading] = useState<
    { range: KpiRange; loading: boolean }[]
  >([]);

  const [
    panelCodeDepartmentTemplateItems,
    setPanelCodeDepartmentTemplateItems,
  ] = useState<PpicPanelCodeDepartmentTemplateItems>(
    PpicPanelCodeDepartmentTemplateItems.fromPartial({
      departmentTemplateItems: [],
    })
  );

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    handleFetchRcemDepartments();
    handleFetchKpiWorkOrders();
    handleFetchDepartmentTemplatesProto();
    handleFetchPpicPanelCodeDepartmentTemplateItems();
    handleFetchAttendanceNoteTypes();
    handleFetchAttendanceNotesData();
  };

  const handleFetchRcemDepartments = async () => {
    const d = await fetchRcemDepartmentsProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setRcemDepartments(d);
    }
  };
  const handleFetchAttendanceNoteTypes = async () => {
    const d = await fetchAttendanceNoteTypes({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setAttendanceNoteTypes(d);
    }
  };
  const handleFetchAttendanceNotesData = async () => {
    const d = await fetchAttendanceNotes({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setAttendanceNotes(d);
    }
  };

  const handleFetchKpiWorkOrders = async () => {
    const d = await fetchRcemTasklistMongoKpi({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setWorkOrders(d);
    }
  };
  const handleFetchDepartmentTemplatesProto = async () => {
    const d = await fetchDepartmentTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      setDepartmentTemplates(d);
    }
  };
  const handleFetchPpicPanelCodeDepartmentTemplateItems = async () => {
    const d = await fetchPanelCodeDepartmentTemplateItemsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      setPanelCodeDepartmentTemplateItems(d);
    }
  };

  const filteredUsers = selectedDept
    ? ctx?.extUsers.filter(
        (u) => `${u.departmentId}` === `${selectedDept.id}`
      ) ?? []
    : selectedUser
    ? [selectedUser]
    : [];

  useEffect(() => {
    if (selectedDept || selectedUser) {
      fetchKpiRange(0);
      fetchKpiRange(-1);
      fetchKpiRange(-7);
      fetchKpiRange(-28);
    }
  }, [selectedDept, selectedUser]);

  const fetchKpiRange = async (n: KpiRange) => {
    try {
      console.log("Filteered users: ", filteredUsers);
      const kpiRangeLoading = kpiRangesLoading.find((l) => l.range === n);

      if (kpiRangeLoading) {
        setKpiRangesLoading((loadings) =>
          loadings.map((l) => (l.range === n ? { ...l, loading: true } : l))
        );
      } else {
        setKpiRangesLoading((loadings) => [
          ...loadings,
          { range: n, loading: true },
        ]);
      }

      const fromDate = `${makeDateString(new Date())}T00:00:00Z`;
      const toDate = `${makeDateString(
        new Date(new Date().getTime() + 86400000 * n)
      )}T00:00:00Z`;

      const dateA = fromDate.localeCompare(toDate) - 1 ? fromDate : toDate;
      const dateB = fromDate.localeCompare(toDate) - 1 ? toDate : fromDate;

      const d = await fetchKpiApiProto({
        apiKey: ctx?.apiKey ?? "",
        from: dateA,
        to: dateB,
        wo: false,
        userIds: filteredUsers
          .map((u) => u.id)
          .filter((i) => i)
          .map((i) => i as number),
      });

      const foundData = kpiRangesData.find((r) => r.range === n);

      if (foundData) {
        setKpiRangesData((kpiRangesData) =>
          kpiRangesData.map((r) => (r.range === n ? { ...r, kpi: d } : r))
        );
      } else {
        setKpiRangesData((kpiRangesData) => [
          ...kpiRangesData,
          { range: n, kpi: d },
        ]);
      }
    } catch (e) {
    } finally {
      setKpiRangesLoading(
        kpiRangesLoading.map((lx) =>
          lx.range === n ? { ...lx, loading: false } : lx
        )
      );
    }
  };

  return (
    <>
      <div>
        <div>
          <h4>RCEM KPI</h4>
        </div>
        <div className="border border-dark my-2"></div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <Select
              placeholder="Search by user..."
              options={ctx?.extUsers.map((u) => ({
                label: `${u.username}:${u.name}:${
                  ctx?.extDepartments.find(
                    (d) => `${d.id}` === `${u.departmentId}`
                  )?.name
                }`,
                value: u,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtUser };

                setSelectedDept(null);
                setSelectedUser(val.value);
              }}
            />
          </div>
          <div className="flex-grow-1">
            <Select
              placeholder="Search by dept..."
              options={ctx?.extDepartments.map((d) => ({
                label: `${d.name}: ${
                  ctx.extUsers.filter((u) => `${u.departmentId}` === `${d.id}`)
                    .length
                } person(s)`,
                value: d,
              }))}
              onChange={(v) => {
                const val = v as { value: ExtDepartment };

                setSelectedDept(val.value);
                setSelectedUser(null);
              }}
            />
          </div>
        </div>
        <div className="border border-dark my-2"></div>
        <div>
          {filteredUsers
            .map(
              (u) =>
                ctx?.extUsers.find((ux) => `${ux.id}` === `${u.id}`)?.username
            )
            .join(",")}
        </div>
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ height: "30vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "Manpower",
                ...kpiRanges.map((n) => `${n}d`),
                // "Last Updated",
              ].map((h) => (
                <>
                  <th
                    className="border border-dark bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              ))}
            </tr>
          </table>
        </div>
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ height: "30vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["Manpower", ...kpiRangesNegative.map((n) => `${n}d`)].map(
                (h) => (
                  <>
                    <th
                      className="border border-dark bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                )
              )}
            </tr>
            <tr>
              <td className="border border-dark">Check ins</td>
              {kpiRangesNegative.map((n) => {
                return (
                  <td className="border border-dark">
                    {kpiRangesLoading.find(
                      (l) => l.loading && l.range === n
                    ) ? (
                      <>
                        <Spinner animation="border" />
                      </>
                    ) : (
                      <>
                        {(selectedDept || selectedUser) &&
                        (kpiRangesData.find((r) => r.range === n)?.kpi.users
                          .length ?? 0) === 0 ? (
                          <>
                            {" "}
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={async () => {
                                fetchKpiRange(n);
                              }}
                            >
                              Load {n}d
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {(() => {
                      return kpiRangesData
                        .find((rx) => rx.range === n)
                        ?.kpi.users.filter((u) =>
                          filteredUsers.find(
                            (ux) => `${u.user?.gormModel?.id}` === `${ux.id}`
                          )
                        )
                        ?.reduce(
                          (acc, k) => acc + (k.attendance?.checkIns ?? 0),
                          0
                        );
                    })()}

                    {/* {filteredUsers.map((u) => u.id).join(",")} */}
                  </td>
                );
              })}
            </tr>
            {attendanceNoteTypes.map((t) => {
              const filteredAttendanceNotes = attendanceNotes.filter(
                (a) =>
                  a.attendanceNoteType?.id === t.id &&
                  (selectedUser
                    ? `${a.extUserId}` === `${selectedUser.id}`
                    : selectedDept
                    ? `${
                        ctx?.extUsers.find(
                          (u) => `${u.id}` === `${a.extUserId}`
                        )?.departmentId
                      }` === `${selectedDept.id}`
                    : false)
              );

              const todayNotes = filteredAttendanceNotes.filter(
                (a) => a.date?.split("T")?.[0] === makeDateString(new Date())
              );
              const yesterdayNotes = filteredAttendanceNotes.filter(
                (a) =>
                  (a.date?.split("T")?.[0] ?? "") >=
                  makeDateString(new Date(new Date().getTime() - 8640000 * 1))
              );

              const last7dNotes = filteredAttendanceNotes.filter(
                (a) =>
                  (a.date?.split("T")?.[0] ?? "") >=
                  makeDateString(new Date(new Date().getTime() - 8640000 * 7))
              );

              const last28dNotes = filteredAttendanceNotes.filter(
                (a) =>
                  (a.date?.split("T")?.[0] ?? "") >=
                  makeDateString(new Date(new Date().getTime() - 8640000 * 28))
              );

              const last365dNotes = filteredAttendanceNotes.filter(
                (a) =>
                  (a.date?.split("T")?.[0] ?? "") >=
                  makeDateString(new Date(new Date().getTime() - 8640000 * 365))
              );
              return (
                <>
                  <tr>
                    <td className={`border border-dark`}>{t.name}</td>
                    <td
                      className="border border-dark"
                      style={{
                        backgroundColor:
                          last365dNotes.length > 0 ? `gold` : undefined,
                      }}
                    >
                      {last365dNotes.length}
                    </td>
                    <td
                      className={`border border-dark`}
                      style={{
                        backgroundColor:
                          last28dNotes.length > 0 ? `gold` : undefined,
                      }}
                    >
                      {last28dNotes.length}
                    </td>{" "}
                    <td
                      className={`border border-dark`}
                      style={{
                        backgroundColor:
                          last7dNotes.length > 0 ? `gold` : undefined,
                      }}
                    >
                      {last7dNotes.length}
                    </td>
                    <td
                      className={`border border-dark`}
                      style={{
                        backgroundColor:
                          yesterdayNotes.length > 0 ? `gold` : undefined,
                      }}
                    >
                      {yesterdayNotes.length}
                    </td>
                    <td
                      className={`border border-dark`}
                      style={{
                        backgroundColor:
                          todayNotes.length > 0 ? `gold` : undefined,
                      }}
                    >
                      {todayNotes.length}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
          {/* <div>
            <pre>
              <small>
                {JSON.stringify(
                  attendanceNotes.map(
                    (a) =>
                      `${a.date?.split("T")?.[0] ?? ""} vs ${makeDateString(
                        new Date(new Date().getTime() - 8640000 * 365)
                      )} = ${
                        (a.date?.split("T")?.[0] ?? "") >=
                        makeDateString(
                          new Date(new Date().getTime() - 8640000 * 365)
                        )
                      }`
                  ),
                  null,
                  2
                )}
              </small>
            </pre>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default RcemKpiPage;
