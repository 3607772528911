import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  fetchExtCrmPurchaseOrdersProto,
  fetchPartNumberCustomerMatchesProto,
  fetchPartNumberMatchesProto,
  fetchWarehouseItemsProto,
} from "../../../helpers";
import {
  CrmAccount,
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  PpicPartNumberCustomerMatches,
  PpicPartNumberMatches,
  WarehouseItem,
  WarehouseItems,
} from "../../../masterbigsystem";

const PartNumbersV2Page = () => {
  const ctx = useContext(AppContext);

  const [pos, setPos] = useState<CrmPurchaseOrders | null>(null);
  const [selectedCrmAccount, setSelectedCrmAccount] =
    useState<CrmAccount | null>(null);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<CrmPurchaseOrder | null>(null);
  const [loadingPos, setLoadingPos] = useState(false);
  const [items, setItems] = useState<WarehouseItems | null>(null);
  const [loadingPartNumberMatches, setLoadingPartNumberMatches] =
    useState(false);
  const [
    loadingPartNumberCustomerMatches,
    setLoadingPartNumberCustomerMatches,
  ] = useState(false);

  const [partNumberMatches, setPartNumberMatches] =
    useState<PpicPartNumberMatches | null>(null);
  const [partNumberCustomerMatches, setPartNumberCustomerMatches] =
    useState<PpicPartNumberCustomerMatches | null>(null);

  const [openItemModal, setOpenItemModel] = useState(false);
  const [itemSearch, setItemSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState<WarehouseItem | null>(null);
  const [itemsLoading, setItemsLoading] = useState(false);

  const [partNumberMatchesDeleteIds, setPartNumberMatchesDeleteIds] = useState<
    string[]
  >([]);
  const [filters, setFilters] = useState({
    date: null as string | null,
    searchPo: "" as string,
  });
  const [selectedPartNumberMatch, setSelectedPartNumberMatch] = useState<
    string | null
  >(null);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchPosData();
    fetchItemsData();
    fetchPartNumberMatchesData();
    fetchPartNumberMatchesCustomerData();
  };

  const fetchPosData = async () => {
    setPos(await fetchExtCrmPurchaseOrdersProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchItemsData = async () => {
    setItemsLoading(true);

    const itemsData = await fetchWarehouseItemsProto({
      apiKey: ctx?.apiKey ?? "",
    });
    console.log("[items]", itemsData);
    setItems(itemsData);
    setItemsLoading(false);
  };
  const fetchPartNumberMatchesData = async () => {
    setLoadingPartNumberMatches(true);
    setPartNumberMatches(
      await fetchPartNumberMatchesProto({ apiKey: ctx?.apiKey ?? "" })
    );
    setLoadingPartNumberMatches(false);
  };
  const fetchPartNumberMatchesCustomerData = async () => {
    setLoadingPartNumberCustomerMatches(true);
    setPartNumberMatches(
      await fetchPartNumberCustomerMatchesProto({ apiKey: ctx?.apiKey ?? "" })
    );
    setLoadingPartNumberCustomerMatches(false);
  };

  const customers = [
    ...new Set(
      pos?.purchaseOrders
        .map((p) => p.account?.id)
        .filter((p) => p)
        .map((p) => p as string)
    ),
  ]
    .map((i) =>
      pos?.purchaseOrders.map((p) => p.account).find((a) => a?.id === i)
    )
    .filter((a) => a)
    .map((a) => a as CrmAccount);

  const posFiltered = pos?.purchaseOrders.filter(
    (p) => `${p.account?.id}` === `${selectedCrmAccount?.id}`
  );

  return (
    <>
      <div className="m-3">
        <div className="d-flex">
          {itemsLoading ? (
            <>
              <div>
                <div>
                  <Spinner animation="border" />
                </div>
                <div>
                  <small>Items loading...</small>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {loadingPos ? (
            <>
              <div>
                <div>
                  <Spinner animation="border" />
                </div>
                <div>
                  <small>POs loading...</small>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div>
          <h4>Part Numbers</h4>
        </div>
        <hr className="border border-dark" />
        <div>
          <small>
            <strong>Customer</strong>
          </small>
        </div>
        <div>
          <Select
            options={customers}
            onChange={(v) => {
              const val = v as unknown as CrmAccount;
              setSelectedCrmAccount(val);
            }}
            getOptionLabel={(c) => `${c.name}`}
            placeholder="Customer..."
          />
        </div>
        {selectedCrmAccount ? (
          <>
            {" "}
            <div>
              <small>
                <strong>PO</strong>
              </small>
            </div>
            <div>
              <Select
                options={posFiltered}
                onChange={(v) => {
                  const val = v as unknown as CrmPurchaseOrder;
                  setSelectedPurchaseOrder(val);
                }}
                getOptionLabel={(p) => `${p.purchaseOrderNumber}`}
                placeholder="PO Number..."
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {selectedPurchaseOrder ? (
          <>
            <div
              className="overflow-auto border border-dark shadow shadow-md"
              style={{ height: "40vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                {["#", "Check", "Item"].map((h) => (
                  <>
                    <th
                      className="bg-dark text-light "
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
                {(() => {
                  const foundPo = pos?.purchaseOrders.find(
                    (p) => `${p.id}` === `${selectedPurchaseOrder.id}`
                  );

                  return (
                    <>
                      {foundPo?.products.map((p, i_) => {
                        const foundItem = items?.items.find(
                          (i) => `${i.id}` === `${p.productId}`
                        );

                        return (
                          <>
                            <tr>
                              <td className="border border-dark">{i_ + 1}</td>
                              <td className="border border-dark">
                                <input type="checkbox" />
                              </td>
                              <td className="border border-dark">
                                <div>
                                  <div>
                                    {foundItem?.partNum}: {foundItem?.partName}
                                  </div>
                                  <div>
                                    <small>{foundItem?.partDesc}</small>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </>
                  );
                })()}
              </table>
            </div>
            <div>
              <small>
                <strong></strong>
              </small>
            </div>
            <div>
              <button className="btn btn-sm btn-primary">Add to List</button>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PartNumbersV2Page;
