import { initialPageable, Pageable } from "./Pageable";
import { initialSort, Sort } from "./Sort";

export interface PageAny extends PageNoContent {
  content: any
}

export interface Page<T> extends PageNoContent {
  content: T[]
}

export interface PageNoContent {
  pageable: Pageable,
  totalPages: number,
  totalElements: number,
  last: boolean,
  first: boolean,
  sort: Sort,
  numberOfElements: number,
  size: number,
  number: number,
  empty: boolean
}

export const initialPageNoContent: PageNoContent = {
  pageable: {...initialPageable},
  totalPages: 1,
  totalElements: 0,
  last: true,
  first: true,
  sort: {...initialSort},
  numberOfElements: 1,
  size: 10,
  number: 0,
  empty: false
}

export const initialPage: PageAny = {
  ...initialPageNoContent,
  content: []
}

