import React from 'react';
import AsyncSelect from "react-select/async";

const AsyncSelectWrapper = <T extends {}>(props: { 
  label?: string,
  isClearable?: boolean,
  url: string,
  param: string,
  value: T | null | undefined,
  placeholder?: string,
  onChange: (selected: T | null) => void
}) => {
  const apiKey = localStorage.getItem("apiKey");

  const loadOptions = async (inputValue: string, callback: any) => {
    try {
      const response = await fetch(`${props.url}?${props.param}=${inputValue}`, {
        headers: {
          "authorization": apiKey ? apiKey : ""
        }
      });
      const contents: T[] | null | undefined = await response.json();

      return contents
        ? contents.map(content => ({ label: (content as any)[props.param], value: content }))
        : [];
    } catch(e) {
      return [];
    }
  }

  return (
    <AsyncSelect
      isClearable={props.isClearable}
      value={props.value ? ({ label: (props.value as any)[props.param], value: props.value }) : null } 
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      placeholder={props.placeholder}
      onChange={selected => props.onChange(selected ? (selected as { label: string, value: any }).value : null)}
    />
  )
};

export default AsyncSelectWrapper;