import React from 'react'
import { Item } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const ItemPage = (props: any) => {
  const mapperFunc = (item: Item) => {
    return {
      id: item.id,
      content: [ item.partName, item.companyPartNumber, item.partNumber, item.um, item.materialType, item.partDescription ]
    }
  }

  return (
    <StatefulCrudTable<Item>  
      url="items"
      head={['Part Name', 'Company Part Number', 'Part Number', 'UoM', 'Material Type', 'Description']}
      mapper={mapperFunc}
    />
  )
}

export default ItemPage