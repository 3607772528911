
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KitchenIcon from '@material-ui/icons/Kitchen';
import ReceiptIcon from '@material-ui/icons/Receipt';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import { Item, Manufacturer, MaterialType, Uom } from '../../../models/model';
import { initialItem } from '../../../models/modelinitials';
import { RequestStatus } from '../../../models/RequestStatus';
import AsyncSelectWrapper from '../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper';

const ItemForm = () => {
  const { id } = useParams() as any
  const history = useHistory()
  const apiKey = localStorage.getItem("apiKey");

  const [item, setItem] = useState<Item>({...initialItem});
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NotAsked);

  useEffect(() => {
    if(id !== undefined) {
      if(!isNaN(parseInt(id))) {
        fetchItemData(parseInt(id));
      }
    }
  }, []);
  
  const fetchItemData = async (id: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/items/${id}`);
      if(response.status !== 200) throw "Error.";
      const item: Item | null | undefined = await response.json();
      
      if(item) {
        setItem({...item});
      }
    } catch(e) {
      console.log(e);
    }
  }

  const handleChangePartName = (partName: string) => setItem({...item, partName: partName});
  const handleChangeCompanyPartNumber = (companyPartNumber: string) => setItem({...item, companyPartNumber: companyPartNumber});
  const handleChangePartNumber = (partNumber: string) => setItem({...item, partNumber: partNumber});
  const handleChangePartDescription = (partDescription: string) => setItem({...item, partDescription: partDescription});
  const handleChangeMaterialType = (materialType: string) => setItem({...item, materialType: materialType})
  const handleChangeSelectedUom = (uom: Uom) => setItem({...item, um: uom.name});
  const handleChangeUom = (uom: string) => setItem({...item, um: uom})
  const handleChangeSelectedMaterialType = (materialType: MaterialType) => setItem({...item, materialType: materialType.name});
  const handleChangeManufacturer = (manufacturer: Manufacturer) => setItem({...item, manufacturer: manufacturer});

  const handleSave = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(item)
      });
      if(response.status !== 201) throw "Save error";
      setRequestStatus(RequestStatus.Success);     
      history.push("/items");
    } catch(e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  }

  console.log("item:", item);
  
  return (
    <Box mx={1}>
      <Box display="flex" alignItems="center">
        <Link to="/items">
          <Button
            color="primary"
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Link>
        <Box mx={1}>
          <Button
           onClick={() => handleSave()}
           color="primary" 
           variant="contained"
          >
            Save
          </Button>
        </Box>
        {requestStatus === RequestStatus.Loading
          ? <CircularProgress disableShrink />
          : <></>
        }
      </Box>

      <Box>
        <h3>Item Information</h3>
        <Box display="flex">
          <Box style={{width: '400px'}}>
            <TextField 
              label="Part Name" 
              onChange={(evt: any) => handleChangePartName(evt.target.value)} 
              value={item.partName} 
              fullWidth 
            />
          </Box>
          <Box ml={2} style={{width: '350px'}}>
            <TextField 
              label="GSPE Part Number" 
              onChange={(evt: any) => handleChangeCompanyPartNumber(evt.target.value)} 
              value={item.companyPartNumber} 
              fullWidth 
            />
          </Box>
          <Box ml={2} style={{width: '350px'}}>
            <TextField 
              label="Part Number" 
              onChange={(evt: any) => handleChangePartNumber(evt.target.value)} 
              value={item.partNumber} 
              fullWidth 
            />
          </Box>
        </Box>
        <Box mt={2}>
          <Box style={{width: '1000px'}}>
            <TextField 
              label="Part Description" 
              onChange={(evt: any) =>  handleChangePartDescription(evt.target.value)} 
              value={item.partDescription} 
              fullWidth 
            />
          </Box>
        </Box>
        <Box my={5}>
          <h3>Material Type</h3>
          <Box display="flex">
            {
              [
                {name: 'Raw Material', value: 'raw_material', icon: <ReceiptIcon fontSize="large" />},
                {name: 'Processed Item', value: 'processed_item', icon: <BuildIcon fontSize="large" />},
                {name: 'Finished Goods', value: 'finished_goods', icon: <KitchenIcon fontSize="large" />},
              ]
              .map((selection, index) => {
                return (
                  <Box key={`MaterialType_${index}`} ml={3}>
                    <Button 
                      onClick={() => handleChangeMaterialType(selection.value)}
                      color="primary" 
                      variant={item.materialType === selection.value ? 'contained' : 'outlined'}
                    >
                      <Box my={3} display="flex" flexDirection="column" alignItems="center">
                        {selection.icon}
                        <strong>{selection.name}</strong>
                      </Box>
                    </Button>
                  </Box>
                )
              })
            }
          </Box>
        </Box>
        <Box display="flex" mt={2}>
          <Box display="flex" alignItems="center" mx={1}>
            <Box width={400} mx={1}>
              <AsyncSelectWrapper<Uom>
                label="Unit of Measurement"
                url={`${process.env.REACT_APP_BASE_URL}/uoms/search`}
                param="name"
                value={item.uom}
                onChange={selected => setItem({ ...item, uom: selected })}
              />
            </Box>
            <a href="/#/uoms/add" target="_blank">
              <Button variant="outlined" color="primary">Add</Button>
            </a>
          </Box>
          <Box display="flex" mx={1} alignItems="center">
            <Box width={300} mx={1}>
              <AsyncSelectWrapper<Manufacturer>
                label="Manufacturer"
                url={`${process.env.REACT_APP_BASE_URL}/manufacturers/search`}
                param="name"
                value={item.manufacturer}
                onChange={selected => setItem({ ...item, manufacturer: selected })}
              />
            </Box>
            <a href="/#/manufacturers/add" target="_blank">
              <Button variant="outlined" color="primary">Add</Button>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ItemForm