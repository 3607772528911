import React from 'react'
import { ProblemType } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const ProblemTypePage = (props: any) => {
  const mapperFunc = (problemType: ProblemType) => {
    return {
      id: problemType.id,
      content: [ problemType.name ]
    }
  }

  return (
    <StatefulCrudTable<ProblemType>  
      url="problemtypes"
      head={['Name']}
      mapper={mapperFunc}
    />
  )
}

export default ProblemTypePage