import { Checkbox } from "@material-ui/core";
import { RSA_NO_PADDING } from "constants";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  checkBomDocumentTypeColor,
  checkMrQtyAvailColor,
  CheckRequestStatus,
  createBomListFlattened,
  fetchExtInventory,
  fetchItems,
  mapExtItemString,
} from "../../../helpers";
import {
  BomDocument,
  BomLeveledRecursive,
  BomMulti,
  ExtInventory,
  ExtItem,
  ExtReserveList,
  ItemBuffer,
  Job,
  JobBomLeveled,
  JobBomLeveledView,
  JobMapped,
  MaterialRequest,
  MaterialRequestItem,
  MaterialRequestView,
} from "../../../models/model";
import {
  defaultMaterialRequestItemView,
  defaultMaterialRequestMaterialRequestItem,
  defaultMaterialRequestMaterialRequestItemView,
  defaultMaterialRequestView,
  initialJob,
  initialMaterialRequest,
  initialMaterialRequestItem,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";

const JobMaterialRequest = () => {
  const { id } = useParams() as { id: string };
  const ctx = useContext(AppContext);

  const [loading, setLoading] = useState(RequestStatus.NotAsked);
  const [jobsMapped, setJobsMapped] = useState<JobMapped | null>(null);
  const [items, setItems] = useState<ExtItem[]>([]);
  const [inventory, setInventory] = useState<ExtInventory[]>([]);
  const [jobReserveList, setJobReserveList] = useState<ExtReserveList[]>([]);
  const [reservationListToMrs, setReservationListToMrs] = useState<
    ExtReserveList[]
  >([]);

  const [showReservationDialog, setShowReservationDialog] = useState(false);
  const [openMrAddDialog, setOpenMrAddDialog] = useState(false);
  const [newMaterialRequest, setNewMaterialRequest] =
    useState<MaterialRequestView>({
      ...defaultMaterialRequestView,
    });

  const [showPrDialog, setShowPrDialog] = useState(false);
  const [mrQtys, setMrQtys] = useState<
    {
      extItemId: number;
      mrValue: number;
      inStock: ExtInventory | null | undefined;
    }[]
  >([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      setLoading(RequestStatus.Loading);

      const [itemsData, inventoryData, jobReserveListData] = await Promise.all([
        fetchItems(process.env.REACT_APP_BASE_URL ?? "", ctx?.apiKey ?? ""),
        fetchExtInventory({
          apiKey: ctx?.apiKey ?? "",
        }),
        (async () => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/ext-reserve-list`
            );

            if (resp.status !== 200) throw await resp.text();

            return await resp.json();
          } catch (e) {
            return [];
          }
        })(),
      ]);

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/${id}/full?compose=true`,
        {
          headers: { authorization: ctx?.apiKey ?? "" },
        }
      );

      if (resp.status !== 200) throw await resp.text();

      const jobsMapped = (await resp.json()) as JobMapped;

      const bomListFlattened = createBomListFlattened(
        jobsMapped.jobBomLeveleds ?? [],
        jobsMapped.jobBomLeveledsMapped ?? []
      );

      setMrQtys(
        [
          ...new Set(
            bomListFlattened
              .filter((b) => b.bom.bom?.bomLeveled?.extItemId)
              .map((b) => b.bom.bom?.bomLeveled?.extItemId)
          ),
        ]
          .filter(
            (ix) =>
              itemsData?.find((i) => i.id === ix) &&
              (() => {
                const inStock = inventoryData.find(
                  (ix_) => ix_.productId === ix
                );
                const mrValue =
                  bomListFlattened
                    .filter((bx) => bx?.bom.bom?.bomLeveled?.extItemId === ix)
                    .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0) -
                  (inStock?.qtyReserve ?? 0);

                return (inStock?.qtyBalance ?? 0) > 0 && mrValue > 0;
              })()
          )
          .map((extItemId) => {
            const inStock = inventoryData.find(
              (ix) => ix.productId === extItemId
            );

            const mrValue =
              bomListFlattened
                .filter(
                  (bx) => bx?.bom.bom?.bomLeveled?.extItemId === extItemId
                )
                .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0) -
              (inStock?.qtyReserve ?? 0);

            return {
              extItemId: extItemId ?? 0,
              inStock: inStock,
              mrValue: mrValue,
            };
          })
      );

      setInventory(inventoryData);
      setJobsMapped(jobsMapped);
      setJobReserveList(jobReserveListData);
      setItems(itemsData ?? []);
      setNewMaterialRequest({
        ...defaultMaterialRequestView,
        materialRequest: {
          ...initialMaterialRequest,
          name: `Material Request [${jobsMapped?.job?.jobSummary?.name ?? ""} ${
            jobsMapped?.job?.name
          }] ${Intl.DateTimeFormat(navigator.language ?? "en-US", {
            dateStyle: "full",
            timeStyle: "long",
            hourCycle: "h23",
          } as any).format(new Date())}`,
        },
      });
    } catch (e) {
      console.log("[error handle init]", e);
    } finally {
      setLoading(RequestStatus.NotAsked);
    }
  };

  const bomListFlattened = createBomListFlattened(
    jobsMapped?.jobBomLeveleds ?? [],
    jobsMapped?.jobBomLeveledsMapped ?? []
  );

  return (
    <div>
      <div className="d-flex align-items-center align-items-end">
        <h3>
          Material Request for job [{jobsMapped?.job?.jobSummary?.name ?? ""}{" "}
          {jobsMapped?.job?.name}] (PO: {jobsMapped?.job?.poNumber})
        </h3>
        <div>
          {loading === RequestStatus.Loading ? (
            <Spinner animation="border" />
          ) : (
            <></>
          )}
        </div>
      </div>
      <hr className="border border-dark" />
      <div>BOMs:</div>
      <div>
        <ol>
          {jobsMapped?.jobBomLeveleds?.map((b) => {
            return (
              <>
                <li>
                  {b.jobBomLeveled?.qty}x {b.jobBomLeveled?.bomLeveled?.name}
                </li>
              </>
            );
          })}
        </ol>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-sm btn-primary mx-1"
          onClick={() => {
            setShowReservationDialog(true);
          }}
        >
          Create reservation
        </button>
        {/* <button
          className="btn btn-sm btn-primary mx-1"
          onClick={() => {
            setShowPrDialog(true);
          }}
        >
          Create MR
        </button> */}
      </div>
      <div
        className="border overflow-auto border-dark shadow shadow-lg"
        style={{ height: "65vh", resize: "vertical" }}
      >
        <table
          className="table table-sm table-bordered table-striped table-hover"
          style={{ borderCollapse: "separate" }}
        >
          <thead>
            {[
              "#",
              "BOM",
              "Type",
              "Name",
              "Item name",
              "Qty",
              "Qty multiplied",
              "Qty multiplied total (MR needed)",
              "MR approved",
              "MR assigned",
              "Rsv. for job",
              "Qty reserved",
              "In stock",

              "Free stock",
              "MR left",
              "UoM",
            ].map((h) => (
              <th
                className="sticky-top text-light bg-dark"
                style={{ zIndex: 1 }}
              >
                {h}
              </th>
            ))}
          </thead>
          <tbody>
            {bomListFlattened.map((b, i) => {
              const foundItem = items.find(
                (i) => i.id === b?.bom.bom?.bomLeveled?.extItemId
              );
              const itemInvalidBg =
                b.bom.bom?.bomLeveled?.type === "Component"
                  ? !foundItem
                    ? "bg-danger text-light"
                    : bomListFlattened.filter(
                        (bx) =>
                          bx?.bom.bom?.bomLeveled?.extItemId ===
                          b?.bom.bom?.bomLeveled?.extItemId
                      ).length > 1
                    ? "bg-warning"
                    : ""
                  : "";

              const inStock = inventory.find(
                (ix) => ix.productId === b.bom.bom?.bomLeveled?.extItemId
              );
              const flattenedNeededQty = bomListFlattened
                .filter(
                  (bx) =>
                    bx?.bom.bom?.bomLeveled?.extItemId &&
                    bx?.bom.bom?.bomLeveled?.extItemId ===
                      b?.bom.bom?.bomLeveled?.extItemId
                )
                .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0);

              console.log(
                `[flattened needed qty for ${b.bom.bom?.bomLeveled?.name} ${b.bom.bom}]`,
                flattenedNeededQty
              );

              const mrReserved = inStock?.qtyReserve ?? 0;
              const mrNeeded = flattenedNeededQty - mrReserved;

              return (
                <tr
                  style={{
                    backgroundColor: !b.bom.bom?.bomLeveled?.type
                      ? "hotpink"
                      : checkBomDocumentTypeColor(b.bom.bom?.bomLeveled?.type),
                  }}
                >
                  <td className="border border-dark">{i + 1}</td>
                  <td className="border border-dark">{b.parent?.name}</td>
                  <td className="border border-dark">
                    {!b.bom.bom?.bomLeveled?.type ? (
                      <strong>BOM</strong>
                    ) : (
                      b.bom.bom?.bomLeveled?.type
                    )}
                  </td>
                  <td className="border border-dark">
                    {b.bom?.bom?.bomLeveled?.name}
                  </td>
                  <td className={`border border-dark ${itemInvalidBg}`}>
                    {b.bom?.bom?.bomLeveled?.type === "Component"
                      ? !foundItem &&
                        b.bom.bom?.bomLeveled?.type === "Component"
                        ? "Selected item invalid"
                        : `(${foundItem?.partNum ?? "[NO PARTNUM]"} - ${
                            foundItem?.mfr ?? "[NO MFR]"
                          }) - ${foundItem?.partName ?? "[NO PART NAME]"}: ${
                            foundItem?.partDesc ?? "[NO PART NAME]"
                          } (0.0 x 0.0 x 0.0 mm)`
                      : ""}
                  </td>
                  <td className={`border border-dark ${itemInvalidBg}`}>
                    {b.bom.bom?.bomLeveled?.qty}
                  </td>
                  <td className={`border border-dark ${itemInvalidBg}`}>
                    {b.bom.multiplier}
                  </td>
                  <td className={`border border-dark ${itemInvalidBg}`}>
                    {b.bom.bom?.bomLeveled?.type === "Component" ? (
                      flattenedNeededQty
                    ) : (
                      <></>
                    )}
                  </td>
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : ""
                    }`}
                  >
                    {b.bom.bom?.bomLeveled?.type === "Component" ? 0 : <>0</>}
                  </td>
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : itemInvalidBg
                    }`}
                  >
                    {b.bom.bom?.bomLeveled?.type === "Component" ? (
                      <>
                        {" "}
                        {jobReserveList
                          .filter(
                            (r) =>
                              r.jobId === jobsMapped?.job?.id &&
                              r.products?.id ===
                                b?.bom.bom?.bomLeveled?.extItemId &&
                              r.transactionType === "mr"
                          )
                          .reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0)}
                      </>
                    ) : (
                      <>0</>
                    )}
                  </td>{" "}
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : (inStock?.qty ?? 0) < mrNeeded
                        ? "bg-danger text-light"
                        : itemInvalidBg
                    }`}
                  >
                    {jobReserveList
                      .filter(
                        (r) =>
                          r.jobId === jobsMapped?.job?.id &&
                          r.products?.id ===
                            b?.bom.bom?.bomLeveled?.extItemId &&
                          r.transactionType === "rsv"
                      )
                      .reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0)}
                  </td>
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : (inStock?.qty ?? 0) < mrNeeded
                        ? "bg-danger text-light"
                        : itemInvalidBg
                    }`}
                  >
                    {jobReserveList
                      .filter(
                        (r) =>
                          r.transactionType === "rsv" &&
                          r.products?.id === b?.bom.bom?.bomLeveled?.extItemId
                      )
                      .reduce((acc, r) => acc + (r.qtyReserve ?? 0), 0)}
                  </td>
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : (inStock?.qty ?? 0) < mrNeeded
                        ? "bg-danger text-light"
                        : itemInvalidBg
                    }`}
                  >
                    {inStock?.qty}
                  </td>
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : (inStock?.qtyBalance ?? 0) < mrNeeded
                        ? "bg-danger text-light"
                        : itemInvalidBg
                    }`}
                  >
                    <div>{inStock?.qtyBalance} </div>

                    {(inStock?.qtyBalance ?? 0) < mrNeeded ? (
                      <div>
                        {`(Stock not enough, ${mrNeeded} ${inStock?.products?.defaultUm} needed)`}{" "}
                        <Link to={`/jobs/${id}/bomlistsimulation`}>
                          <button className="btn btn-sm btn-outline-secondary">
                            PR
                          </button>
                        </Link>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td
                    className={`border border-dark ${
                      !(b.bom.bom?.bomLeveled?.type === "Component")
                        ? "bg-dark"
                        : ""
                    }`}
                  >
                    {mrNeeded.toFixed(2)}
                  </td>
                  <td className="border border-dark">{foundItem?.defaultUm}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <hr className="border border-dark" />
      </div>

      <div>
        <h5>Reservation list</h5>
      </div>

      {/* {JSON.stringify(reservationListToMrs)} */}

      <div className="d-flex justify-content-end">
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              setOpenMrAddDialog(true);
            }}
          >
            Create MR
          </button>
        </div>
      </div>
      <div
        className="overflow-auto shadow-md border border-dark"
        style={{ height: "65vh", resize: "vertical" }}
      >
        <table className="table table-sm table-bordered table-hover">
          <thead>
            <tr>
              {[
                "#",
                "Name",
                "Reserved",
                <div className="d-flex">
                  to MR?{" "}
                  <input
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setReservationListToMrs(
                        reservationListToMrs.length > 0
                          ? []
                          : jobReserveList.filter(
                              (r) => r.transactionType === "rsv"
                            )
                      );
                    }}
                  />
                </div>,
                "Type",
                "Action",
              ].map((h) => (
                <th className="bg-dark text-light">{h}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {jobReserveList
              // .filter((r) => r.transactionType == "rsv")
              .map((r, i) => {
                const foundItem = items.find((i) => i.id === r.products?.id);
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{mapExtItemString(foundItem)}</td>
                    <td>
                      {r.qtyReserve} {foundItem?.defaultUm ?? "[NO UM]"}
                    </td>

                    {r.transactionType === "rsv" ? (
                      <>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setReservationListToMrs(
                              reservationListToMrs.find((rx) => rx.id === r.id)
                                ? reservationListToMrs.filter(
                                    (rx) => rx.id !== r.id
                                  )
                                : [...reservationListToMrs, { ...r }]
                            );
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              reservationListToMrs.find((rx) => rx.id === r.id)
                                ? true
                                : false
                            }
                          />
                        </td>
                      </>
                    ) : (
                      <td></td>
                    )}

                    <td>{r.transactionType}</td>

                    <td>
                      {r.transactionType === "rsv" ? (
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={async () => {
                            const confirmation = window.confirm(
                              `Really release reservation for ${mapExtItemString(
                                foundItem
                              )}? This action cannot be undone.`
                            );

                            if (confirmation) {
                              try {
                                const resp = await fetch(
                                  `${process.env.REACT_APP_BASE_URL}/ext-material-reservation-delete/${r.id}`
                                );

                                window.location.reload();
                              } catch (e) {
                                console.error(e);
                              }
                            }
                          }}
                        >
                          Release
                        </button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <Modal
        show={showPrDialog}
        onHide={() => {
          setShowPrDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Automatic Create MR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            {mrQtys.map((m) => {
              const foundItem = items.find((i) => i.id === m.extItemId);

              const inStock = inventory.find(
                (ix) => ix.productId === m.extItemId
              );
              const flattenedNeededQty = bomListFlattened
                .filter(
                  (bx) => bx?.bom.bom?.bomLeveled?.extItemId === m.extItemId
                )
                .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0);

              const mrAssigned = 0;
              const mrNeeded = flattenedNeededQty - mrAssigned;
              return (
                <li>
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        ({foundItem?.partNum}: {foundItem?.mfr}) -{" "}
                        {foundItem?.partName} : {foundItem?.partDesc}
                      </div>
                      <div>
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 75 }}
                          onChange={(e) => {
                            setMrQtys(
                              mrQtys.map((mx) =>
                                mx.extItemId === m.extItemId
                                  ? {
                                      ...mx,
                                      mrValue: isNaN(parseFloat(e.target.value))
                                        ? 0
                                        : parseFloat(e.target.value),
                                    }
                                  : mx
                              )
                            );
                          }}
                          defaultValue={
                            m.mrValue <= 0
                              ? 0
                              : (inStock?.qtyBalance ?? 0) >= m.mrValue
                              ? m.mrValue
                              : inStock?.qtyBalance ?? 0
                          }
                        />
                      </div>
                      {m.mrValue}
                    </div>
                    <div>
                      <hr className="border border-dark" />
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-end">
            <button className="btn btn-sm btn-primary">Create</button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReservationDialog}
        onHide={() => {
          setShowReservationDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Automatic Create Reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            {mrQtys.map((m) => {
              const foundItem = items.find((i) => i.id === m.extItemId);

              const inStock = inventory.find(
                (ix) => ix.productId === m.extItemId
              );
              const flattenedNeededQty = bomListFlattened
                .filter(
                  (bx) => bx?.bom.bom?.bomLeveled?.extItemId === m.extItemId
                )
                .reduce((acc, b) => (b.bom.multiplier ?? 0) + acc, 0);

              const mrReserved = inStock?.qtyReserve ?? 0;
              const mrNeeded = flattenedNeededQty - mrReserved;
              return (
                <li>
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        ({foundItem?.partNum}: {foundItem?.mfr}) -{" "}
                        {foundItem?.partName} : {foundItem?.partDesc}
                      </div>
                      <div>
                        <input
                          className="form-control form-control-sm"
                          style={{ width: 75 }}
                          onChange={(e) => {
                            setMrQtys(
                              mrQtys.map((mx) =>
                                mx.extItemId === m.extItemId
                                  ? {
                                      ...mx,
                                      mrValue: isNaN(parseFloat(e.target.value))
                                        ? 0
                                        : parseFloat(e.target.value),
                                    }
                                  : mx
                              )
                            );
                          }}
                          defaultValue={
                            m.mrValue <= 0
                              ? 0
                              : (inStock?.qtyBalance ?? 0) >= mrNeeded
                              ? mrNeeded
                              : inStock?.qtyBalance ?? 0
                          }
                        />
                      </div>
                      {/* {m.mrValue} */}
                    </div>
                    <div>
                      <hr className="border border-dark" />
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-sm btn-primary"
              onClick={async () => {
                const dataMapped = {
                  jobId: jobsMapped?.job?.id ?? null,
                  items: mrQtys
                    .filter((m) => m.mrValue !== 0)
                    .map((m) => ({
                      id: null,
                      transactionType: "rsv",
                      productId: m.extItemId ?? null,
                      qtyReserve:
                        m.mrValue <= 0
                          ? 0
                          : (m.inStock?.qtyBalance ?? 0) >= m.mrValue
                          ? m.mrValue
                          : m.inStock?.qtyBalance ?? 0,
                      // qtyReserve: 1,
                    })),
                };

                console.log("[data mr mapped]", dataMapped);

                try {
                  console.log(process.env.REACT_APP_WAREHOUSE_URL);
                  const resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/ext-material-requests`,
                    {
                      method: "post",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify(dataMapped),
                    }
                  );

                  if (resp.status !== 201) throw await resp.text();

                  window.location.reload();
                } catch (e) {
                  console.error(e);
                }
                console.log("[reservation list]", {
                  job: jobsMapped?.job,
                  reservations: mrQtys.map((m) => ({
                    ...m,
                    item: items.find((i) => i.id === m.extItemId),
                  })),
                });
              }}
            >
              Reserve
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openMrAddDialog}
        onHide={() => {
          setOpenMrAddDialog(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create MR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-label">MR Description</div>
            <input
              className="form-control"
              defaultValue={newMaterialRequest.materialRequest?.name ?? ""}
              onBlur={(e) => {
                setNewMaterialRequest({
                  ...newMaterialRequest,
                  materialRequest: newMaterialRequest.materialRequest
                    ? {
                        ...newMaterialRequest.materialRequest,
                        name: e.target?.value,
                      }
                    : newMaterialRequest.materialRequest,
                });
              }}
            />
          </div>

          <hr />

          <h5>Items to MR from reservation list</h5>
          <div>
            <ol>
              {reservationListToMrs.map((m) => {
                const foundItem = items.find((i) => i.id === m.products?.id);

                return (
                  <>
                    <div className="d-flex w-100 justify-content-between">
                      <div className="flex-grow-1">
                        {mapExtItemString(foundItem)}
                      </div>
                      <div className="d-flex font-weight-bold flex-grow-1 justify-content-end align-items-end text-end">
                        <div className="d-flex justify-content-end">
                          {m.qtyReserve}{" "}
                        </div>
                        <div className="d-flex mx-1 justify-content-end">
                          {foundItem?.defaultUm && foundItem.defaultUm !== ""
                            ? foundItem.defaultUm
                            : "[NO UM]"}
                        </div>
                      </div>
                    </div>

                    <hr className="border border-secondary my-2" />
                  </>
                );
              })}
            </ol>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-sm btn-primary"
              onClick={async () => {
                const materialRequestViewToSend: MaterialRequestView = {
                  ...newMaterialRequest,
                  materialRequest: newMaterialRequest.materialRequest
                    ? ({
                        ...newMaterialRequest.materialRequest,
                        job: jobsMapped?.job ?? null,
                      } as MaterialRequest)
                    : newMaterialRequest.materialRequest,
                  reserveList: reservationListToMrs,
                  materialRequestItems: reservationListToMrs.map((r) => ({
                    ...defaultMaterialRequestItemView,
                    materialRequestItem: {
                      ...initialMaterialRequestItem,
                      extItemId: r.products?.id ?? 0,
                      qty: r.qtyReserve ?? 0,
                      extReserveListId: r.id,
                    },
                  })),
                };

                const confirmed = window.confirm(
                  [
                    `Confirm MR creation for ${materialRequestViewToSend.materialRequestItems?.length} items`,
                    `${materialRequestViewToSend.materialRequest?.name}`,
                    "\n",
                    ...(materialRequestViewToSend.materialRequestItems?.map(
                      (i, i_) => {
                        const foundItem = items.find(
                          (ix) => ix.id === i.materialRequestItem?.extItemId
                        );
                        return `${i_ + 1}. ${i.materialRequestItem?.qty}x ${
                          foundItem?.defaultUm
                        } ${mapExtItemString(foundItem)}\n`;
                      }
                    ) ?? []),
                  ].join("\n")
                );

                if (confirmed) {
                  try {
                    const resp = await fetch(
                      `${process.env.REACT_APP_BASE_URL}/material-requests-view-save`,
                      {
                        method: "post",
                        headers: {
                          "content-type": "application/json",
                          authorization: ctx?.apiKey ?? "",
                        },
                        body: JSON.stringify(materialRequestViewToSend),
                      }
                    );

                    window.location.reload();
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
            >
              Create MR
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobMaterialRequest;
