import { ChevronLeft, Delete } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchSkillProto, fetchSkillsProto } from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicSkill,
  PpicSkills,
  PpicSkillWorker,
  PpicSkillWorker_SkillLevel,
  ppicSkillWorker_SkillLevelToJSON,
} from "../../../masterbigsystem";
import { ExtDepartment, ExtUser } from "../../../models/model";

const SkillCatalogDetailPage = () => {
  const { id } = useParams() as { id?: string };
  const history = useHistory();
  const ctx = useContext(AppContext);
  const [skill, setSkill] = useState<PpicSkill | null>(
    PpicSkill.fromPartial({})
  );
  const [skillWorkerIdsToDelete, setskillWorkerIdsToDelete] = useState<
    string[]
  >([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchSkillData();
  };

  const fetchSkillData = async () => {
    const fetchedSkill = await fetchSkillProto({
      apiKey: ctx?.apiKey ?? "",
      id: id,
    });

    if (fetchedSkill) {
      setSkill(fetchedSkill);
    }
  };

  const handleSave = async () => {
    try {
      if (skill) {
        console.log("skill now", skill);
        const resp = await fetch(`${process.env.REACT_APP_BASE_URL}/skills`, {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(PpicSkill.toJSON(skill)),
        });

        // Delete skill workers
        await Promise.all(
          skillWorkerIdsToDelete.map(async (id) => {
            try {
              await fetch(
                `${process.env.REACT_APP_BASE_URL}/skillworkers/${id}/empty`,
                {
                  method: "delete",
                  headers: {
                    "content-type": "application/json",
                    authorization: ctx?.apiKey ?? "",
                  },
                }
              );
            } catch (e) {
              return null;
            }
          })
        );

        history.push("/skillcatalog");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div className="mr-2">
            <Link to="/skillcatalog">
              <button className="btn btn-outline-primary">
                <ChevronLeft /> Back{" "}
              </button>
            </Link>
          </div>
          <h4>Skill Catalog Detail</h4>
          <div>
            <button
              onClick={() => {
                handleSave();
              }}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <div>
            <small>
              <strong>Name</strong>
            </small>
            <input
              className="form-control form-control-sm"
              placeholder="Name..."
              defaultValue={skill?.name ?? ""}
              onBlur={(e) => {
                if (skill) {
                  setSkill({ ...skill, name: e.target.value });
                }
              }}
            />
          </div>
        </div>
        <hr className="border border-dark" />

                <small>
          <strong>Department</strong>
        </small>
        <div>
          {" "}
          <Select
            options={ctx?.extDepartments}
            getOptionLabel={(d) => `${d.name}`}
            value={ctx?.extDepartments.find(
              (d) => `${d.id}` === `${skill?.extDepartmentId}`
            )}
            onChange={(v) => {
              const val = v as unknown as ExtDepartment;

              if (skill) {
                setSkill({
                  ...skill,
                  extDepartmentId: val.id ? `${val.id}` : undefined,
                });
              }
            }}
          />
        </div>
        <hr className="border border-dark" />

        <div>
          <small>
            <strong>Workers</strong>
          </small>
        </div>
        <div>
          <Select
            options={ctx?.extUsers ?? []}
            getOptionLabel={(u) =>
              `${u.username}: ${u.name}: ${u.departmentName}`
            }
            onChange={(v) => {
              const val = v as unknown as ExtUser;

              if (
                skill?.skillWorkers.find(
                  (w) => `${w.extUserId}` === `${val.id}`
                )
              ) {
                setSkill({
                  ...skill,
                  skillWorkers: skill.skillWorkers.filter(
                    (w) => `${w.extUserId}` !== `${val.id}`
                  ),
                });
              } else {
                setSkill({
                  ...skill,
                  skillWorkers: [
                    ...(skill?.skillWorkers ?? []),
                    PpicSkillWorker.fromPartial({
                      extUserId:
                        val.id !== null && val.id !== undefined
                          ? `${val.id}`
                          : undefined,
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                    }),
                  ],
                });
              }
            }}
          />
        </div>
        <div>
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <thead>
              {[
                "Action",
                "#",
                "Name",
                // "Skill Level",
                "Priority",
              ].map((h) => (
                <th className="border border-dark bg-dark text-light">{h}</th>
              ))}
            </thead>
            <tbody>
              {skill?.skillWorkers.map((w, i) => {
                const fUser = ctx?.extUsers.find(
                  (u) => `${u.id}` === `${w.extUserId}`
                );
                return (
                  <>
                    <tr>
                      <td
                        className="border border-dark"
                        onClick={() => {
                          setskillWorkerIdsToDelete([
                            ...skillWorkerIdsToDelete,
                            w.masterJavaBaseModel?.id ?? "",
                          ]);
                          setSkill({
                            ...skill,
                            skillWorkers: skill.skillWorkers.filter(
                              (wx) =>
                                `${wx.masterJavaBaseModel?.uuid}` !==
                                `${w.masterJavaBaseModel?.uuid}`
                            ),
                          });
                        }}
                      >
                        <button className="btn btn-danger btn-sm">
                          <Delete />{" "}
                        </button>
                      </td>

                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">
                        <div>
                          <div>
                            {(() => {
                              return (
                                <>
                                  {fUser?.username}: {fUser?.name}:{" "}
                                  {fUser?.departmentName}
                                </>
                              );
                            })()}
                          </div>
                        </div>
                      </td>
                      {/* <td className="border border-dark">
                        <div className="d-flex">
                          {(
                            [
                              PpicSkillWorker_SkillLevel.BASIC,
                              PpicSkillWorker_SkillLevel.INTERMEDIATE,
                              PpicSkillWorker_SkillLevel.ADVANCED,
                              PpicSkillWorker_SkillLevel.EXPERT,
                            ] as PpicSkillWorker_SkillLevel[]
                          ).map((sl) => {
                            return (
                              <>
                                <div>
                                  <button
                                    onClick={() => {
                                      setSkill({
                                        ...skill,
                                        skillWorkers: skill.skillWorkers.map(
                                          (wx) =>
                                            wx &&
                                            `${wx.extUserId}` ===
                                              `${w.extUserId}`
                                              ? { ...wx, skillLevel: sl }
                                              : wx
                                        ),
                                      });
                                    }}
                                    className={`btn ${(() => {
                                      switch (sl) {
                                        case PpicSkillWorker_SkillLevel.BASIC:
                                          return w.skillLevel ===
                                            PpicSkillWorker_SkillLevel.BASIC
                                            ? `btn-primary`
                                            : `btn-outline-primary`;
                                        case PpicSkillWorker_SkillLevel.INTERMEDIATE:
                                          return w.skillLevel ===
                                            PpicSkillWorker_SkillLevel.INTERMEDIATE
                                            ? `btn-warning`
                                            : `btn-outline-warning`;
                                        case PpicSkillWorker_SkillLevel.ADVANCED:
                                          return w.skillLevel ===
                                            PpicSkillWorker_SkillLevel.ADVANCED
                                            ? `btn-danger`
                                            : `btn-outline-danger`;
                                        case PpicSkillWorker_SkillLevel.EXPERT:
                                          return w.skillLevel ===
                                            PpicSkillWorker_SkillLevel.EXPERT
                                            ? `btn-secondary`
                                            : `btn-outline-secondary`;

                                        default:
                                          return "";
                                      }
                                    })()}`}
                                  >
                                    {ppicSkillWorker_SkillLevelToJSON(sl)}
                                  </button>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </td> */}
                      <td className="border border-dark">
                        <input
                          type="checkbox"
                          checked={w.isPriority}
                          onClick={() => {
                            setSkill({
                              ...skill,
                              skillWorkers: skill.skillWorkers.map((wx) =>
                                wx && `${wx.extUserId}` === `${w.extUserId}`
                                  ? { ...wx, isPriority: !wx.isPriority }
                                  : wx
                              ),
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SkillCatalogDetailPage;
