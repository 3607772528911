import { Save } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { secondsToHms } from "../../helpers";
import { AdminSetup } from "../../models/model";

const AdminSetupPage = () => {
  const ctx = useContext(AppContext);

  const [adminSetup, setAdminSetup] = useState<AdminSetup | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await fetch(`${ctx?.baseUrl ?? ""}/adminsetup`, {
        headers: { authorization: ctx?.apiKey ?? "" },
      });

      if (resp.status !== 200) throw await resp.text();

      setAdminSetup(await resp.json());
    } catch (e) {
      console.error(e);
    }
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(`${ctx?.baseUrl}/adminsetup-save`, {
        method: "post",
        headers: {
          authorization: ctx?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify(adminSetup),
      });

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  const TimeSecsEditor = (props: {
    secs: number;
    label: string;
    onInput: (secs: number) => void;
  }) => {
    const [h, setH] = useState(Math.floor(props.secs / 3600));
    const [m, setM] = useState(Math.floor((props.secs % 3600) / 60));
    const [s, setS] = useState(props.secs % 60);

    return (
      <div>
        <div>{props.label}</div>
        <form
          onSubmit={() => {
            props.onInput(h * 3600 + m * 60 + s);
          }}
        >
          <div className="d-flex align-items-center">
            <div style={{ width: 50 }}>
              <input
                className="form-control"
                value={h}
                onInput={(e) => {
                  if (!isNaN(parseInt((e.target as any)?.value))) {
                    const h = parseInt((e.target as any)?.value);
                    setH(h);
                  }
                }}
              ></input>
            </div>
            {" : "}
            <div style={{ width: 50 }}>
              <input
                className="form-control"
                value={m}
                onInput={(e) => {
                  if (!isNaN(parseInt((e.target as any)?.value))) {
                    const m = parseInt((e.target as any)?.value);
                    setM(m);
                  }
                }}
              ></input>
            </div>
            {" : "}
            <div style={{ width: 50 }}>
              <input
                className="form-control"
                value={s}
                onInput={(e) => {
                  if (!isNaN(parseInt((e.target as any)?.value))) {
                    const s = parseInt((e.target as any)?.value);
                    setS(s);
                  }
                }}
              ></input>
            </div>
            <button type="submit" style={{ display: "none" }} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        <div className="d-flex align-items-center">
          <h4>Admin Setup</h4>
          <div className="ml-2">
            <button
              onClick={() => {
                handleSave();
              }}
              className="btn btn-sm btn-primary"
            >
              <Save /> Save
            </button>
          </div>
        </div>

        <div className="my-1">
          <hr />
        </div>

        <div>
          <strong>Press enter to change hours</strong>
        </div>

        <div className="d-flex align-items-center justify-content-around flex-wrap">
          <TimeSecsEditor
            label={`Check In Time (${secondsToHms(
              adminSetup?.checkInTime ?? 0
            )})`}
            secs={adminSetup?.checkInTime ?? 0}
            onInput={(secs) => {
              if (adminSetup)
                setAdminSetup({ ...adminSetup, checkInTime: secs });
            }}
          />

          <TimeSecsEditor
            label={`Check Out Time (${secondsToHms(
              adminSetup?.checkOutTime ?? 0
            )})`}
            secs={adminSetup?.checkOutTime ?? 0}
            onInput={(secs) => {
              if (adminSetup)
                setAdminSetup({ ...adminSetup, checkOutTime: secs });
            }}
          />

          <TimeSecsEditor
            label={`Break Start Time (${secondsToHms(
              adminSetup?.breakStartTime ?? 0
            )})`}
            secs={adminSetup?.breakStartTime ?? 0}
            onInput={(secs) => {
              if (adminSetup)
                setAdminSetup({ ...adminSetup, breakStartTime: secs });
            }}
          />

          <TimeSecsEditor
            label={`Check In Time (${secondsToHms(
              adminSetup?.breakEndTime ?? 0
            )})`}
            secs={adminSetup?.breakEndTime ?? 0}
            onInput={(secs) => {
              if (adminSetup)
                setAdminSetup({ ...adminSetup, breakEndTime: secs });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default AdminSetupPage;
