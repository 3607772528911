import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Ral } from "../../../models/model";
import { initialRal } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const RalForm = () => {
  const { id } = useParams() as any;
  const history = useHistory();

  const [ral, setRal] = useState<Ral>({ ...initialRal });
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  useEffect(() => {
    if (id !== undefined) {
      if (!isNaN(parseInt(id))) {
        fetchRalData(parseInt(id));
      }
    }
  }, []);

  const fetchRalData = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/rals/${id}`
      );
      if (response.status !== 200) throw "Erorr";
      setRal(await response.json());
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleChangeRalName = (name: string) => setRal({ ...ral, name: name });

  const handleSaveRal = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/rals`, {
        method : "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(ral)
      })
      if(response.status !== 201) throw "Error";
      setRequestStatus(RequestStatus.Success);
      history.push("/rals");
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <Box mx={1}>
      <Box display="flex" alignItems="center">
        <Link to="/rals">
          <Button color="primary">
            <ChevronLeftIcon /> Back
          </Button>
        </Link>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSaveRal()}
          >
            Save
          </Button>
        </Box>

        <Box mx={1}>
          {(() => {
            switch (requestStatus) {
              case RequestStatus.NotAsked:
                return <></>;

              case RequestStatus.Loading:
                return <CircularProgress disableShrink />;

              case RequestStatus.Success:
                return <h4 style={{ color: green[500] }}>Success!</h4>;

              case RequestStatus.Error:
                return <h4 style={{ color: red[500] }}>Error!</h4>;
            }
          })()}
        </Box>
      </Box>

      <Box my={2}>
        <TextField
          label="RAL Name"
          value={ral.name}
          onChange={(event: any) => handleChangeRalName(event.target.value)}
        />
      </Box>
    </Box>
  );
};

export default RalForm;
