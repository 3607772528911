import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PanelType } from '../../../models/model';
import { initialPanelType } from '../../../models/modelinitials';
import { RequestStatus } from '../../../models/RequestStatus';

const PanelTypeForm = () => {
  const apiKey = localStorage.getItem("apiKey");
  const { id } = useParams() as any;
  const history = useHistory();
  
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NotAsked);
  const [panelType, setPanelType] = useState<PanelType>({ ...initialPanelType });

  useEffect(() => {
    if(id && !isNaN(parseInt(id))) {
      fetchPanelType(parseInt(id));
    }
  }, []);

  const fetchPanelType = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/paneltypes/${id}`, {
        headers: { "authorization": apiKey ? apiKey : "" }
      });

      if(response.status !== 200) throw "Error fetching panel type";

      const panelType = await response.json();
      setPanelType({ ...panelType });

      setRequestStatus(RequestStatus.Success);
    } catch(e) {
      setRequestStatus(RequestStatus.Error);
    }
  }

  const handleSave = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/paneltypes`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "authorization": apiKey ? apiKey : ""
        },
        body: JSON.stringify(panelType)
      });
      if(response.status !== 201) throw "Saving error";

      setRequestStatus(RequestStatus.Success);
      history.push("/paneltypes")
    } catch(e) {
      setRequestStatus(RequestStatus.Error);
    }
  }
  
  return (
    <Box>
      <Box display="flex">
        <Box>
          <Link to="/paneltypes">
            <Button
              color="primary"
            >
              <ChevronLeftIcon /> Back
            </Button>
          </Link>
        </Box>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        {requestStatus === RequestStatus.Loading ? <CircularProgress disableShrink /> : <></>}
      </Box>
      <h3>Panel Type</h3>
      <Box display="flex">
        <Box width={300}>
          <TextField 
            fullWidth
            label="Name"
            value={panelType.name}
            onChange={e => setPanelType({ ...panelType, name: e.target.value })}
          />
        </Box>
        <Box mx={1}>
          <TextField
            fullWidth
            label="Code"
            value={panelType.code}
            onChange={e => setPanelType({ ...panelType, code: e.target.value })}
          />
        </Box>
      </Box>
    </Box>
  )
} 

export default PanelTypeForm;