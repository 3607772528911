import { Box, Fab, Table, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core'
import { blue, grey, orange, yellow } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const WorkOrder = () => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [requestStatus, setRequestStatus] = useState<string>('notAsked')
  const [jobsByDateArray, setJobsByDateArray] = useState<any>([])

  useEffect(() => {
    (async () => {
      if(requestStatus === 'notAsked') {
        setRequestStatus('loading')
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` :  currentDate.getMonth() + 1
  
        const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate()
  
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/workorders/bydatebetween/jobs?start=${year}-${month}-01&end=${year}-${month}-${daysInCurrentMonth}`)
        const responseJson = await response.json()
        
        console.log(responseJson)
        setJobsByDateArray(responseJson)
        setRequestStatus('success')  
      }
    })()
  })

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const currentMonth = currentDate.getMonth()
  console.log('current month:', currentMonth)

  const daysInCurrentMonth = new Date(currentDate.getFullYear(), currentMonth + 1, 0).getDate()

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const dates  = [...Array(daysInCurrentMonth).keys()]
  dates.shift()
  dates.push(dates.length + 1)

  const datesWithDays = dates.map(date => {
    const dayIndex = new Date(currentDate.getFullYear(), currentMonth, date).getDay()
    return {date: date, day: days[dayIndex], dayIndex: dayIndex}
  })

  let newShiftedDate

  if(datesWithDays[0].dayIndex > 0) {
    newShiftedDate = Array(datesWithDays[0].dayIndex).fill(null).concat(datesWithDays)
  }
  else {
    newShiftedDate = datesWithDays
  }
  
  let newSplicedDate = []

  while(newShiftedDate.length > 0) {
    newSplicedDate.push(newShiftedDate.splice(0, 7))
  }

  // console.log(newShiftedDate)

  const shiftDate = (type: string) => {
    const newDate = new Date(currentDate.toString())

    console.log('move type:', type)
    switch(type) {
      case 'up':
        newDate.setMonth(currentDate.getMonth() + 1)
        break
        
      case 'down':
        newDate.setMonth(currentDate.getMonth() - 1)
        break

      default:
        console.log('not a valid command')
    }

    console.log('new date:', newDate)
    setCurrentDate(newDate)
    setRequestStatus('notAsked')
  }


  return (
    < >
      <Box style={{width: '100%', overflowX: 'auto'}} display="flex" flexDirection="column" alignItems="center">
        <h2>Work Orders</h2>
        <Box>
          No. of days in current Month : {daysInCurrentMonth}, month: {months[currentMonth]}&nbsp;|&nbsp;
          {currentDate.toString()}
        </Box>
        
        <TableContainer style={{width: '1000px'}}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box mt={2}>
                <h2>{months[currentMonth]} {currentDate.getFullYear()}</h2>
              </Box>
              <Box ml={2}>
                <Fab size="small" color="secondary" onClick={() => shiftDate('down')}>
                  <NavigateBeforeIcon />
                </Fab>
              </Box>
              <Box ml={2}>
                <Fab size="small" color="secondary" onClick={() => shiftDate('up')}>
                  <NavigateNextIcon />
                </Fab>
              </Box>
            </Box>
          </Box>
          <Box border={2}>
            <Table>
              <TableHead>
                <TableRow style={{backgroundColor: orange[100]}}>
                  {
                    days.map(day => {
                      return (
                        <TableCell>
                          <Box display="flex" justifyContent="center"><h3>{day}</h3></Box>
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
                {
                  newSplicedDate.map(dateChunk => {
                    return (
                      <TableRow>
                        {
                          dateChunk.map(date => {
                            const selectedDate = date === null
                            ? ''
                            : new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${date.date}`).toJSON().slice(0, 10)

                            const year = currentDate.getFullYear()
                            const month = currentDate.getMonth() + 1 < 10 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth()
                            const dateOrDefaults = date === null ? 1 : date.date
                            const dateInBlock = dateOrDefaults < 10 ? `0${dateOrDefaults}` : dateOrDefaults

                            const formattedDateInBlock = `${year}-${month}-${dateInBlock}` 

                            // console.log('date:', dateInBlock)
                            // return JSON.stringify(jobsByDateArray.find(jobWrapper => jobWrapper.date === formattedDateInBlock))

                            const foundJobWrapperByDate = jobsByDateArray.find((jobWrapper: any) => jobWrapper.date === formattedDateInBlock)
                            const unwrappedJobs = foundJobWrapperByDate === undefined ? [] : foundJobWrapperByDate.jobs

                            // console.log('current date:', formattedDateInBlock)
                            const timeNow = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
                            const timeToCompare = new Date(formattedDateInBlock).getTime()

                            console.log('date now:', )

                            return (
                              date === null 
                              ? <TableCell /> 
                              : <TableCell>
                                  <Tooltip 
                                    placement="right"
                                    title={
                                      < >
                                        <Box display="flex" flexDirection="column">
                                          <h1>Jobs:</h1>
                                          <h2>
                                            { 
                                              unwrappedJobs.length === 0 
                                              ? 'No Jobs' 
                                              : <Box>
                                                  { unwrappedJobs.map((job: any) => <Box my={1}>- {job}</Box>) }
                                                </Box> 
                                            }
                                          </h2>
                                        </Box>
                                      </>
                                    }
                                  >
                                    <Box 
                                      style={{
                                        backgroundColor: currentMonth === new Date().getMonth() && date.date === new Date().getDate()
                                        ? yellow[200]
                                        : grey[200]
                                      }}
                                      borderRadius={10}
                                      p={1}
                                    >
                                      <Box display="flex" flexDirection="column">
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                          {/* {
                                            timeNow < timeToCompare
                                            ?  */}
                                              <Link to={`/workorders/add?date=${selectedDate}`}>
                                                <Fab size="small" color="primary">
                                                  <AddIcon />
                                                </Fab>
                                              </Link>
                                            {/* : <></>
                                          } */}
                                          <Box ml={2}>
                                            <h1>{date.date}</h1>
                                          </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="column" mx={1} alignSelf="flex-end">
                                          { 
                                            unwrappedJobs.length === 0 
                                            ? <h3>No Jobs</h3> 
                                            : <Box display="flex" flexDirection="column">
                                                <div>{unwrappedJobs.length} Jobs</div>
                                                <div>
                                                  <Link to={`/workorders/actualinput?date=${selectedDate}`}>
                                                    <strong style={{color: blue[500], cursor: 'pointer', textDecoration: 'underline'}}>
                                                      Actual Input
                                                    </strong>
                                                  </Link>
                                                </div>
                                              </Box> 
                                          } 
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Tooltip>
                                </TableCell>
                            ) 
                          })
                        }
                      </TableRow>
                    )
                  })
                }
              </TableHead>
            </Table>
          </Box> 
        </TableContainer>
      </Box>
    </>
  )
}

export default WorkOrder