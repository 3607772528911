import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import { fetchDepartmentTemplatesProto } from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicDepartmentTemplate,
  PpicDepartmentTemplateItem,
  PpicDepartmentTemplateRule,
  PpicDepartmentTemplateRules,
  PpicDepartmentTemplateRule_PpicDepartmentRuleType,
  ppicDepartmentTemplateRule_PpicDepartmentRuleTypeToJSON,
  PpicDepartmentTemplates,
} from "../../../masterbigsystem";

const DepartmentRulePage = () => {
  const ctx = useContext(AppContext);
  const [departmentRules, setDepartmentRules] = useState(
    PpicDepartmentTemplateRules.fromPartial({ rules: [] })
  );
  const [departmentTemplates, setDepartmentTemplates] = useState(
    PpicDepartmentTemplates.fromPartial({ templates: [] })
  );
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchDeptTemplatesData();
  };

  const fetchDeptTemplatesData = async () => {
    const deptTemplateData = await fetchDepartmentTemplatesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (deptTemplateData) {
      setDepartmentTemplates(deptTemplateData);
    }
  };

  const modifyDeptTemplateRecursive = (
    uuid?: string | null,
    r?: PpicDepartmentTemplateRule,
    f?: (_: PpicDepartmentTemplateRule) => void
  ) => {
    console.log("[finding rule]", r?.masterJavaBaseModel?.uuid, uuid);

    // If found, stop
    if (r && r?.masterJavaBaseModel?.uuid === uuid) {
      console.log("[found rule]", uuid);
      f?.(r);
      return;
    }

    r?.departmentTemplateRules?.forEach((rx) => {
      modifyDeptTemplateRecursive(uuid, rx, f);
    });
  };

  const DepartmentTemplateRuleView = (props: {
    rule: PpicDepartmentTemplateRule;
    level: number;
  }) => {
    return (
      <>
        <div style={{ marginLeft: `${props.level}em` }}>
          <div className="m-1 border-dark border-left border-bottom p-2 rounded rounded-md">
            <div className="d-flex">
              <div>Predicate:</div>
              <div className="d-flex">
                <div>
                  <button
                    className={`btn btn-sm ${
                      props.rule.type ===
                      PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND
                        ? `btn-primary`
                        : `btn-outline-primary`
                    }`}
                    onClick={() => {
                      const newDeptRules = { ...departmentRules };

                      newDeptRules.rules.forEach((r) => {
                        modifyDeptTemplateRecursive(
                          props.rule.masterJavaBaseModel?.uuid,
                          r,
                          (r) => {
                            r.type =
                              PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND;
                          }
                        );
                      });

                      setDepartmentRules(newDeptRules);
                    }}
                  >
                    And
                  </button>
                </div>
                <div>
                  <button
                    className={`btn btn-sm ${
                      props.rule.type ===
                      PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR
                        ? `btn-primary`
                        : `btn-outline-primary`
                    }`}
                    onClick={() => {
                      const newDeptRules = { ...departmentRules };

                      newDeptRules.rules.forEach((r) => {
                        modifyDeptTemplateRecursive(
                          props.rule.masterJavaBaseModel?.uuid,
                          r,
                          (r) => {
                            r.type =
                              PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR;
                          }
                        );
                      });

                      setDepartmentRules(newDeptRules);
                    }}
                  >
                    Or
                  </button>
                </div>
                <div>
                  <button
                    className={`btn btn-sm ${
                      !(
                        props.rule.type ===
                        PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND
                      ) &&
                      !(
                        props.rule.type ===
                        PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR
                      )
                        ? `btn-primary`
                        : `btn-outline-primary`
                    }`}
                    onClick={() => {
                      const newDeptRules = { ...departmentRules };

                      newDeptRules.rules.forEach((r) => {
                        modifyDeptTemplateRecursive(
                          props.rule.masterJavaBaseModel?.uuid,
                          r,
                          (r) => {
                            r.type = undefined;
                          }
                        );
                      });

                      setDepartmentRules(newDeptRules);
                    }}
                  >
                    Single
                  </button>
                </div>
              </div>
              {!(
                props.rule.type ===
                PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND
              ) &&
              !(
                props.rule.type ===
                PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR
              ) ? (
                <>
                  <div className="flex-grow-1 ">
                    <div>
                      <Select
                        options={[
                          {
                            item: PpicDepartmentTemplateItem.fromPartial({
                              name: "None",
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({}),
                            }),
                            template: null,
                          },
                          ...departmentTemplates.templates
                            .map(
                              (dt) =>
                                dt.items.map((dti) => ({
                                  item: dti,
                                  template: dt,
                                })) ?? []
                            )
                            .flat(),
                        ]}
                        getOptionLabel={(i) =>
                          `${i.template?.name ?? ""}: ${i?.item?.name ?? ""}`
                        }
                        onChange={(v) => {
                          const val = v as unknown as
                            | {
                                item: PpicDepartmentTemplateItem;
                                template: PpicDepartmentTemplate;
                              }
                            | {
                                item: PpicDepartmentTemplateItem;
                                template: null;
                              };

                          console.log(val, val.item.masterJavaBaseModel?.id);

                          if (val.item.masterJavaBaseModel?.id) {
                            const newDeptRules = { ...departmentRules };

                            newDeptRules.rules.forEach((r) => {
                              modifyDeptTemplateRecursive(
                                props.rule.masterJavaBaseModel?.uuid,
                                r,
                                (r) => {
                                  r.departmentTemplateItemId =
                                    val.item.masterJavaBaseModel?.id;
                                }
                              );
                            });

                            setDepartmentRules(newDeptRules);
                          } else {
                          }
                        }}
                      />
                    </div>
                    {(() => {
                      const foundDti = departmentTemplates.templates
                        .map(
                          (dt) =>
                            dt.items.map((dti) => ({
                              item: dti,
                              template: dt,
                            })) ?? []
                        )
                        .flat()
                        .find(
                          (dti) =>
                            dti.item.masterJavaBaseModel?.id ===
                            props.rule.departmentTemplateItemId
                        );

                      return foundDti ? (
                        <>
                          <div>
                            {foundDti.template.name}: {foundDti.item.name}
                          </div>
                        </>
                      ) : (
                        <></>
                      );
                    })()}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {!(
              props.rule.type ===
              PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND
            ) &&
            !(
              props.rule.type ===
              PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR
            ) ? (
              <></>
            ) : (
              <>
                {" "}
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      const newDeptRules = { ...departmentRules };

                      newDeptRules.rules.forEach((r) => {
                        modifyDeptTemplateRecursive(
                          props.rule.masterJavaBaseModel?.uuid,
                          r,
                          (r) => {
                            r.departmentTemplateRules = [
                              ...r.departmentTemplateRules,
                              PpicDepartmentTemplateRule.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                              }),
                            ];
                          }
                        );
                      });

                      setDepartmentRules(newDeptRules);
                    }}
                  >
                    <Add /> Add
                  </button>
                </div>
              </>
            )}
          </div>

          {props.rule.departmentTemplateRules.map((r) => {
            return (
              <DepartmentTemplateRuleView rule={r} level={props.level + 1} />
            );
          })}
        </div>
      </>
    );
  };

  const DepartmentTemplateRuleViewer = (props: {
    rule: PpicDepartmentTemplateRule;
    level: number;
  }) => {
    const foundDti = departmentTemplates.templates
      .map((dt) => dt.items.map((dti) => ({ item: dti, template: dt })) ?? [])
      .flat()
      .find(
        (dti) =>
          `${dti.item.masterJavaBaseModel?.id}` ===
          `${props.rule.departmentTemplateItemId}`
      );
    return (
      <>
        <div
          className="border-dark border-left border-bottom"
          style={{ marginLeft: `${props.level}em` }}
        >
          <div className="border-dark border-left border-bottom">
            {props.rule.type ===
              PpicDepartmentTemplateRule_PpicDepartmentRuleType.AND ||
            props.rule.type ===
              PpicDepartmentTemplateRule_PpicDepartmentRuleType.OR ? (
              <>
                <div>
                  {ppicDepartmentTemplateRule_PpicDepartmentRuleTypeToJSON(
                    props.rule.type
                  )}
                </div>
              </>
            ) : (
              <>
                <div>
                  {foundDti?.template.name}: {foundDti?.item.name}
                </div>
              </>
            )}
          </div>
          <div>
            {props.rule.departmentTemplateRules.map((r) => {
              return (
                <DepartmentTemplateRuleViewer
                  rule={r}
                  level={props.level + 1}
                />
              );
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div>
        <div>
          <div>
            <h4>Department Rule</h4>
          </div>
        </div>
        <hr className="border border-dark" />
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setDepartmentRules({
                ...departmentRules,
                rules: [
                  ...departmentRules.rules,
                  PpicDepartmentTemplateRule.fromPartial({
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: v4(),
                    }),
                  }),
                ],
              });
            }}
          >
            {" "}
            Add
          </button>
        </div>
        {departmentRules.rules.map((r, i) => {
          return (
            <>
              <div className="border border-dark m-2 rounded rounded-md d-flex">
                <div className="flex-grow-1">
                  <div>
                    <input
                      defaultValue={r.name}
                      className="form-control form-control-sm"
                      placeholder="Name..."
                      onBlur={(e) => {
                        const newDeptRules = { ...departmentRules };

                        newDeptRules.rules.forEach((rx) => {
                          modifyDeptTemplateRecursive(
                            r.masterJavaBaseModel?.uuid,
                            rx,
                            (rx) => {
                              rx.name = e.target.value;
                            }
                          );
                        });

                        setDepartmentRules(newDeptRules);
                      }}
                    />
                  </div>
                  <DepartmentTemplateRuleView rule={r} level={0} />
                </div>
                <div className="flex-grow-1 p-2 border border-left ">
                  <div>
                    <h5>Viewer: {r.name}</h5>
                  </div>
                  <hr className="border border-dark" />
                  <DepartmentTemplateRuleViewer rule={r} level={0} />
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
export default DepartmentRulePage;
