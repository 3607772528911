import { CompareTwoTone, MoveToInbox } from "@material-ui/icons";
import chroma from "chroma-js";
import React, { useContext, useEffect, useState } from "react";
import { useInterval } from "react-interval-hook";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  checkLoadColorDay,
  extractProtoMeetingTaskTargetDate,
  fetchJobsProtoSimple,
  fetchLastLocationData,
  fetchWorkOrdersProto,
  haversine,
  makeDateString,
  progressColor,
  UserLocation,
} from "../../../helpers";
import { MeetingTaskListsView, PpicJobs } from "../../../masterbigsystem";
import { ExtDepartment } from "../../../models/model";

type ViewMode = "Installer" | "Installer_Timeline" | "Regular";
type DeptMode = "Generic" | "Installer" | "Production";
type DetailMode = "Summary" | "Detailed";
type MonitoringRequestStatus = "Waiting" | "Loading" | "Completed";

const MonitoringPage = () => {
  const ctx = useContext(AppContext);

  const [viewMode, setViewMode] = useState<ViewMode>("Installer");
  const [selectedDept, setSelectedDept] = useState<ExtDepartment | null>(null);
  const [filterHasCoordinateOnly, setFilterHasCoordinateOnly] = useState(true);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [lastLocationUsers, setLastLocationUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [detailedMode, setDetailedMode] = useState<DetailMode>("Summary");
  const [requestStatus, setRequestStatus] =
    useState<MonitoringRequestStatus>("Waiting");
  const [userLocations, setUserLocations] = useState<UserLocation[]>([]);
  const [selectedDeptMode, setselectedDeptMode] = useState<DeptMode>("Generic");
  const [taskLists, setTaskLists] = useState(
    MeetingTaskListsView.fromPartial({})
  );
  const [expandedWO, setExpandedWO] = useState<string[]>([]);
  const [jobs, setJobs] = useState<PpicJobs>(
    PpicJobs.fromPartial({ jobs: [] })
  );

  useEffect(() => {
    handleFetchOnceData();
  }, []);

  const handleFetchOnceData = async () => {
    fetchJobsData();
  };

  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      setJobs(d);
    }
  };

  useEffect(() => {
    console.log("reqstatus", requestStatus);

    if (requestStatus === "Waiting") {
      console.log("Waiting detected! Fetching...");
      handleFetcher();
    }
  }, [requestStatus]);

  // Make 10 secs timer
  useInterval(() => {
    if (requestStatus === "Completed") {
      setRequestStatus("Waiting");
    }
  }, 60000 * 15);

  const handleFetcher = async () => {
    try {
      fetchUserPositionsData();

      setRequestStatus("Loading");
      setLoading(true);
      await fetchTaskListsProtoData();

      setRequestStatus("Completed");
      setLoading(false);

      setLastUpdate(new Date());
    } catch (e) {
      console.error(`err ${e}`);
    }
  };

  const fetchUserPositionsData = async () => {
    const d = await fetchLastLocationData({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      setUserLocations(d);
    }
  };

  const fetchTaskListsProtoData = async () => {
    console.log("cond", filterHasCoordinateOnly);
    const wosProto = await fetchWorkOrdersProto({
      // outstanding: true,
      from: makeDateString(lastUpdate),
      to: makeDateString(lastUpdate),
      hasCoords: filterHasCoordinateOnly,
    });
    setTaskLists(wosProto);
  };

  const filteredTaskLists = taskLists.taskLists.filter((tL) =>
    // Filter by coordinate only
    // (filterHasCoordinateOnly
    //   ? tL.taskList?.meetingTasks.find((mt) =>
    //       mt.journeys.find((mt) => mt)
    //     )
    //   : true) &&
    // Fitler by dept
    selectedDept?.id
      ? tL.taskList?.meetingTasks.find((mt) =>
          mt.meetingTaskInCharges.find(
            (mtic) =>
              `${
                ctx?.extUsers.find((ux) => `${ux.id}` === `${mtic.extUserId}`)
                  ?.departmentId
              }` === `${selectedDept?.id}`
          )
        )
      : true
  );

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>
              Monitoring last update:{" "}
              {Intl.DateTimeFormat(navigator.language ?? "en-US", {
                dateStyle: "long",
                timeStyle: "long",
                hourCycle: "h23",
              } as any).format(lastUpdate)}
            </h5>
          </div>
          <div>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={() => {
                ctx?.setAppBarVisible(!ctx.appBarVisible);
              }}
            >
              Toggle Navbar
            </button>
          </div>
          {loading ? <>Retrieving data...</> : <></>}
        </div>
        {/* <div>{requestStatus}</div> */}
        <div>
          <hr />
        </div>
        <div className="d-flex">
          {(["Detailed", "Summary"] as DetailMode[]).map((h) => (
            <>
              <div>
                <button
                  className={`btn btn-sm ${
                    detailedMode === h ? `btn-primary` : `btn-outline-primary`
                  }`}
                  onClick={() => {
                    setDetailedMode(h);
                  }}
                >
                  {h}
                </button>
              </div>
            </>
          ))}

          {/* <div>
            <button
              className={`btn btn-sm  ${
                viewMode === "Regular" ? `btn-primary` : `btn-outline-primary`
              }`}
              onClick={() => {
                setViewMode("Regular");
              }}
            >
              WO
            </button>
          </div> */}
          <div className="flex-grow-1">
            <Select
              placeholder="Department..."
              options={ctx?.extDepartments ?? []}
              getOptionLabel={(d) => `${d.name}`}
              onChange={(v) => {
                const val = v as unknown as ExtDepartment;

                setSelectedDept(val);
              }}
            />
          </div>
        </div>
        <div>
          <div className="d-flex">
            {(["Generic", "Installer", "Production"] as DeptMode[]).map((n) => (
              <>
                <button
                  className={`btn btn-sm ${
                    selectedDeptMode === n
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                  onClick={() => {
                    setselectedDeptMode(n);
                  }}
                >
                  {n}
                </button>
              </>
            ))}
          </div>
        </div>
        <div className="d-flex">
          Has coordinate only?{" "}
          <div>
            <input
              type="checkbox"
              checked={filterHasCoordinateOnly}
              onClick={() => {
                setFilterHasCoordinateOnly(!filterHasCoordinateOnly);

                if (requestStatus === "Completed") {
                  setRequestStatus("Waiting");
                }
              }}
            />
          </div>
          {JSON.stringify(filterHasCoordinateOnly)}
        </div>

        {["Installer", "Installer_Timeline"].includes(viewMode) ? (
          <>
            <div>
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    {
                      name: `WO# (${filteredTaskLists.length ?? 0})`,
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },
                    {
                      name: "WO name",
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },

                    {
                      name: (
                        <>
                          {(() => {
                            const completedTasks =
                              filteredTaskLists
                                .flatMap(
                                  (tL) => tL.taskList?.meetingTasks ?? []
                                )
                                .filter((mt) => mt.status === "COMPLETED")
                                .length ?? 0;

                            const totalTasks =
                              filteredTaskLists.flatMap(
                                (tL) => tL.taskList?.meetingTasks ?? []
                              ).length ?? 1;

                            return (
                              <>
                                Tasks{" "}
                                <span
                                  className="font-weight-bold"
                                  style={{
                                    backgroundColor: chroma
                                      .scale([
                                        "lightsalmon",
                                        "yellow",
                                        "lightgreen",
                                      ])(completedTasks / totalTasks)
                                      .hex(),
                                  }}
                                >
                                  {(
                                    (completedTasks /
                                      (totalTasks === 0 ? 1 : totalTasks)) *
                                    100
                                  ).toFixed(1)}
                                  %
                                </span>
                              </>
                            );
                          })()}{" "}
                        </>
                      ),
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },
                    {
                      name: "Job",
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },
                    {
                      name: "PIC",
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },
                    {
                      name: "Status",
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },
                    {
                      name: "Date",
                      modes: [
                        "Generic",
                        "Installer",
                        "Production",
                      ] as DeptMode[],
                    },
                    // {
                    //   name: "Time",
                    //   modes: [
                    //     "Generic",
                    //     "Installer",
                    //     "Production",
                    //   ] as DeptMode[],
                    // },
                    {
                      name: "Coordinate",
                      modes: ["Installer"] as DeptMode[],
                    },
                    {
                      name: "Current Coord.",
                      modes: ["Installer"] as DeptMode[],
                    },
                    {
                      name: "Est. Distance",
                      modes: ["Installer"] as DeptMode[],
                    },
                    {
                      name: "ETA",
                      modes: ["Installer"] as DeptMode[],
                    },

                    {
                      name: "Qty",
                      modes: ["Production"] as DeptMode[],
                    },
                  ]
                    .filter((h) => h.modes.includes(selectedDeptMode))
                    .map((h) => (
                      <>
                        <th className="bg-dark text-light">{h.name}</th>
                      </>
                    ))}
                </tr>

                {filteredTaskLists.map((tL, j) => {
                  switch (detailedMode) {
                    case "Detailed":
                      return tL.taskList?.meetingTasks.map((mt, i) => {
                        return (
                          <>
                            <tr>
                              {[
                                {
                                  name: "WO#",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      <small>
                                        {/* {j + 1}.{" "}
                                        {tL.taskList?.masterJavaBaseModel?.id} */}
                                      </small>
                                    </td>
                                  ),
                                },
                                {
                                  name: "WO name",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      <small>
                                        {tL.taskList?.name
                                          ?.split(" ")
                                          .map((s) => s.substring(0, 5))
                                          .join(" ")}
                                      </small>
                                    </td>
                                  ),
                                },
                                {
                                  name: "Task",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      <small>
                                        {mt.description
                                          ?.split(" ")
                                          .map((s) => s.substring(0, 4))
                                          .join(" ")}
                                      </small>
                                    </td>
                                  ),
                                },
                                {
                                  name: "Job",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      <small>
                                        {jobs.jobs
                                          .find(
                                            (j) =>
                                              `${j.masterJavaBaseModel?.id}` ===
                                              `${tL.taskList?.extJobId}`
                                          )
                                          ?.name?.split(" ")
                                          .map((s) => s.substring(0, 5))
                                          .join(" ")}
                                      </small>
                                    </td>
                                  ),
                                },
                                {
                                  name: "PIC",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      <small>
                                        {mt.meetingTaskInCharges
                                          .filter(
                                            (mtic) =>
                                              mtic.extUserId &&
                                              mtic.extUserId !== "0"
                                          )
                                          .map((mtic) => {
                                            const fUser = ctx?.extUsers.find(
                                              (ux) =>
                                                `${ux.id}` ===
                                                `${mtic.extUserId}`
                                            );
                                            return fUser?.username;
                                          })
                                          .join(", ")}
                                      </small>
                                    </td>
                                  ),
                                },

                                {
                                  name: "Status",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td
                                      className="border border-dark"
                                      style={{
                                        backgroundColor:
                                          mt.status === "COMPLETED"
                                            ? "lightgreen"
                                            : "",
                                      }}
                                    >
                                      {mt.status}
                                    </td>
                                  ),
                                },
                                {
                                  name: "Date",
                                  modes: [
                                    "Generic",
                                    "Installer",
                                    "Production",
                                  ] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      {(() => {
                                        try {
                                          return Intl.DateTimeFormat(
                                            navigator.language ?? "en-US",
                                            { dateStyle: "short" }
                                          ).format(new Date(mt.start ?? ""));
                                        } catch (e) {
                                          return "none";
                                        }
                                      })()}{" "}
                                      to{" "}
                                      {(() => {
                                        try {
                                          return Intl.DateTimeFormat(
                                            navigator.language ?? "en-US",
                                            { dateStyle: "short" }
                                          ).format(
                                            new Date(
                                              extractProtoMeetingTaskTargetDate(
                                                mt
                                              )?.date ?? ""
                                            )
                                          );
                                        } catch (e) {
                                          return "none";
                                        }
                                      })()}
                                    </td>
                                  ),
                                },
                                // {
                                //   name: "Time",
                                //   modes: [
                                //     "Generic",
                                //     "Installer",
                                //     "Production",
                                //   ] as DeptMode[],
                                //   node: (
                                //     <td className="border border-dark">
                                //       {mt.fromHour ?? "0"}:{mt.fromMins ?? "0"}-
                                //       {mt.toHour ?? "0"}:{mt.toMins ?? "0"}
                                //     </td>
                                //   ),
                                // },
                                {
                                  name: "Coordinate",
                                  modes: ["Installer"] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      {mt.journeys.map((j) => {
                                        return (
                                          <>
                                            <div>
                                              <small>
                                                {j.lat?.toFixed(4)},{" "}
                                                {j.lon?.toFixed(4)}
                                              </small>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </td>
                                  ),
                                },
                                {
                                  name: "Current Coord.",
                                  modes: ["Installer"] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      {tL.taskList?.meetingTasks.findIndex(
                                        (mt) => mt.status === "OUTSTANDING"
                                      ) === i ? (
                                        <>
                                          <ol>
                                            {mt.meetingTaskInCharges
                                              .filter(
                                                (mtic) =>
                                                  mtic.extUserId &&
                                                  mtic.extUserId !== "0"
                                              )
                                              .map((mtic) => {
                                                const foundUserCoord =
                                                  userLocations.find(
                                                    (u) =>
                                                      `${u.extUserId}` ===
                                                      `${mtic.extUserId}`
                                                  );

                                                return (
                                                  <>
                                                    {foundUserCoord ? (
                                                      <>
                                                        <li>
                                                          <small>
                                                            {foundUserCoord?.lat?.toFixed(
                                                              4
                                                            )}
                                                            ,{" "}
                                                            {foundUserCoord?.lon?.toFixed(
                                                              4
                                                            )}{" "}
                                                            (
                                                            {foundUserCoord.createdAt ? (
                                                              (() => {
                                                                try {
                                                                  return (
                                                                    <>
                                                                      {Intl.DateTimeFormat(
                                                                        navigator.language ??
                                                                          "en-US",
                                                                        {
                                                                          timeStyle:
                                                                            "short",
                                                                          dateStyle:
                                                                            "short",
                                                                          hourCycle:
                                                                            "h23",
                                                                        } as any
                                                                      ).format(
                                                                        new Date(
                                                                          foundUserCoord.createdAt
                                                                        )
                                                                      )}
                                                                    </>
                                                                  );
                                                                } catch (e) {
                                                                  return <></>;
                                                                }
                                                              })()
                                                            ) : (
                                                              <></>
                                                            )}
                                                            )
                                                          </small>
                                                        </li>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <li>
                                                          <small>
                                                            No coord
                                                          </small>
                                                        </li>
                                                      </>
                                                    )}
                                                  </>
                                                );
                                              })}
                                          </ol>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  ),
                                },
                                {
                                  name: "Est. Distance",
                                  modes: ["Installer"] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      {tL.taskList?.meetingTasks.findIndex(
                                        (mt) => mt.status === "OUTSTANDING"
                                      ) === i ? (
                                        <>
                                          <ol>
                                            {mt.meetingTaskInCharges
                                              .filter(
                                                (mtic) =>
                                                  mtic.extUserId &&
                                                  mtic.extUserId !== "0"
                                              )
                                              .map((mtic) => {
                                                const fUser =
                                                  ctx?.extUsers.find(
                                                    (ux) =>
                                                      `${ux.id}` ===
                                                      `${mtic.extUserId}`
                                                  );
                                                const latDest =
                                                  mt.journeys.find(
                                                    (j) => j
                                                  )?.lat;
                                                const lonDest =
                                                  mt.journeys.find(
                                                    (j) => j
                                                  )?.lon;
                                                const foundUserCoord =
                                                  userLocations.find(
                                                    (u) =>
                                                      `${u.extUserId}` ===
                                                      `${mtic.extUserId}`
                                                  );

                                                return (
                                                  <>
                                                    <li>
                                                      <div>
                                                        <small>
                                                          <strong>
                                                            {fUser?.username}:{" "}
                                                            {latDest &&
                                                            lonDest &&
                                                            foundUserCoord?.lat &&
                                                            foundUserCoord.lon ? (
                                                              (() => {
                                                                const calc =
                                                                  haversine(
                                                                    latDest,
                                                                    lonDest,
                                                                    foundUserCoord.lat,
                                                                    foundUserCoord.lon
                                                                  );

                                                                return (
                                                                  <>
                                                                    {(
                                                                      calc /
                                                                      1000
                                                                    ).toFixed(
                                                                      2
                                                                    )}{" "}
                                                                  </>
                                                                );
                                                              })()
                                                            ) : (
                                                              <>No data</>
                                                            )}{" "}
                                                            km
                                                          </strong>
                                                        </small>
                                                      </div>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                          </ol>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  ),
                                },
                                {
                                  name: "ETA",
                                  modes: ["Installer"] as DeptMode[],
                                  node: (
                                    <td className="border border-dark">
                                      {tL.taskList?.meetingTasks.findIndex(
                                        (mt) => mt.status === "OUTSTANDING"
                                      ) === i ? (
                                        <>
                                          <ol>
                                            {mt.meetingTaskInCharges
                                              .filter(
                                                (mtic) =>
                                                  mtic.extUserId &&
                                                  mtic.extUserId !== "0"
                                              )
                                              .map((mtic) => {
                                                const fUser =
                                                  ctx?.extUsers.find(
                                                    (ux) =>
                                                      `${ux.id}` ===
                                                      `${mtic.extUserId}`
                                                  );
                                                const latDest =
                                                  mt.journeys.find(
                                                    (j) => j
                                                  )?.lat;
                                                const lonDest =
                                                  mt.journeys.find(
                                                    (j) => j
                                                  )?.lon;
                                                const foundUserCoord =
                                                  userLocations.find(
                                                    (u) =>
                                                      `${u.extUserId}` ===
                                                      `${mtic.extUserId}`
                                                  );

                                                return (
                                                  <>
                                                    <li>
                                                      <div>
                                                        <small>
                                                          <strong>
                                                            {fUser?.username}:{" "}
                                                            {latDest &&
                                                            lonDest &&
                                                            foundUserCoord?.lat &&
                                                            foundUserCoord.lon ? (
                                                              (() => {
                                                                const calc =
                                                                  haversine(
                                                                    latDest,
                                                                    lonDest,
                                                                    foundUserCoord.lat,
                                                                    foundUserCoord.lon
                                                                  );

                                                                return (
                                                                  <>
                                                                    {/* Divide by 30 km/h */}
                                                                    {(
                                                                      calc /
                                                                      1000 /
                                                                      30
                                                                    ).toFixed(
                                                                      2
                                                                    )}{" "}
                                                                    h
                                                                  </>
                                                                );
                                                              })()
                                                            ) : (
                                                              <>No data</>
                                                            )}
                                                          </strong>
                                                        </small>
                                                      </div>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                          </ol>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  ),
                                },
                                {
                                  name: "Qty",
                                  modes: ["Production"] as DeptMode[],
                                  node: (
                                    <>
                                      <td className="border border-dark">
                                        <small>
                                          <strong>
                                            {(() => {
                                              if (
                                                tL.taskList?.extProgramRunQty &&
                                                tL.taskList
                                                  ?.extProgramRunQty !== 0
                                              ) {
                                                return (
                                                  <>
                                                    {tL.taskList
                                                      ?.extProgramRunQtyActual ??
                                                      0}
                                                    /
                                                    {tL.taskList
                                                      ?.extProgramRunQty ?? 0}
                                                  </>
                                                );
                                              } else if (
                                                tL.taskList
                                                  ?.extIntegrationModuleQty &&
                                                tL.taskList
                                                  ?.extIntegrationModuleQty !==
                                                  0
                                              ) {
                                                return (
                                                  <>
                                                    {tL.taskList
                                                      ?.extIntegrationModuleQtyActual ??
                                                      0}
                                                    /
                                                    {tL.taskList
                                                      ?.extIntegrationModuleQty ??
                                                      0}
                                                  </>
                                                );
                                              } else {
                                                return "";
                                              }
                                            })()}
                                          </strong>
                                        </small>
                                      </td>
                                    </>
                                  ),
                                },
                              ]
                                .filter((b) =>
                                  b.modes.includes(selectedDeptMode)
                                )
                                .map((b) => {
                                  return <>{b.node}</>;
                                })}
                            </tr>
                          </>
                        );
                      });
                    case "Summary":
                      return (
                        <>
                          <tr>
                            {[
                              {
                                name: "WO#",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (
                                  <td
                                    className="border border-dark"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <small>
                                      {j + 1}.{" "}
                                      {tL.taskList?.masterJavaBaseModel?.id}
                                    </small>
                                  </td>
                                ),
                              },
                              {
                                name: "WO name",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (
                                  <td className="border border-dark">
                                    <small>
                                      {tL.taskList?.name
                                        ?.split(" ")
                                        .map((s) => s.substring(0, 5))
                                        .join(" ")}
                                    </small>
                                  </td>
                                ),
                              },
                              {
                                name: "Task",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (() => {
                                  const completedTasks =
                                    tL.taskList?.meetingTasks.filter(
                                      (mt) => mt.status === "COMPLETED"
                                    ).length ?? 0;
                                  const totalTasks =
                                    tL.taskList?.meetingTasks.length ?? 0;

                                  return (
                                    <>
                                      <td
                                        onClick={() => {
                                          if (
                                            expandedWO.find(
                                              (id) =>
                                                id ===
                                                tL.taskList?.masterJavaBaseModel
                                                  ?.id
                                            )
                                          ) {
                                            setExpandedWO(
                                              expandedWO.filter(
                                                (id) =>
                                                  id !==
                                                  tL.taskList
                                                    ?.masterJavaBaseModel?.id
                                              )
                                            );
                                            return;
                                          }

                                          setExpandedWO([
                                            ...expandedWO,
                                            tL.taskList?.masterJavaBaseModel
                                              ?.id ?? "",
                                          ]);
                                        }}
                                        className="border border-dark"
                                        style={{
                                          backgroundColor: chroma
                                            .scale([
                                              "lightsalmon",
                                              "yellow",
                                              "lightgreen",
                                            ])(completedTasks / totalTasks)
                                            .hex(),

                                          cursor: "pointer",
                                        }}
                                      >
                                        <small>
                                          <strong>
                                            {" "}
                                            {completedTasks}/{totalTasks} (
                                            {(
                                              (completedTasks / totalTasks) *
                                              100
                                            ).toFixed(0)}
                                            % )
                                          </strong>
                                        </small>
                                      </td>
                                    </>
                                  );
                                })(),
                              },
                              {
                                name: "Job",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (
                                  <td className="border border-dark">
                                    <small>
                                      {jobs.jobs
                                        .find(
                                          (j) =>
                                            `${j.masterJavaBaseModel?.id}` ===
                                            `${tL.taskList?.extJobId}`
                                        )
                                        ?.name?.split(" ")
                                        .map((s) => s.substring(0, 5))
                                        .join(" ")}
                                    </small>
                                  </td>
                                ),
                              },
                              {
                                name: "PIC",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (
                                  <td className="border border-dark">
                                    <small>
                                      {[
                                        ...new Set(
                                          tL.taskList?.meetingTasks
                                            ?.flatMap(
                                              (mt) =>
                                                mt.meetingTaskInCharges ?? []
                                            )
                                            .map((mtic) => mtic.extUserId)
                                            .filter((id) => id && id !== "0")
                                            .map((id) => id as string) ?? []
                                        ),
                                      ]
                                        .map((id) => {
                                          const fUser = ctx?.extUsers.find(
                                            (ux) => `${ux.id}` === `${id}`
                                          );

                                          return `${fUser?.username ?? "none"}`;
                                        })
                                        .join(", ")}
                                    </small>
                                  </td>
                                ),
                              },

                              {
                                name: "Status",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (() => {
                                  const completedTasks =
                                    tL.taskList?.meetingTasks.filter(
                                      (mt) => mt.status === "COMPLETED"
                                    ).length ?? 0;
                                  const totalTasks =
                                    tL.taskList?.meetingTasks.length ?? 0;
                                  return (
                                    <td className="border border-dark">
                                      <small>
                                        <strong>
                                          {(() => {
                                            if (completedTasks === 0) {
                                              return "OUTS";
                                            } else if (
                                              completedTasks < totalTasks
                                            ) {
                                              return "Partial";
                                            } else {
                                              return "COMPL";
                                            }
                                          })()}
                                        </strong>
                                      </small>
                                    </td>
                                  );
                                })(),
                              },
                              {
                                name: "Date",
                                modes: [
                                  "Generic",
                                  "Installer",
                                  "Production",
                                ] as DeptMode[],
                                node: (() => {
                                  const starts = [
                                    ...(tL.taskList?.meetingTasks ?? []),
                                  ];
                                  starts.sort(
                                    (a, b) =>
                                      a.start?.localeCompare(b.start ?? "") ?? 0
                                  );

                                  const ends = [
                                    ...(tL.taskList?.meetingTasks.map((mt) =>
                                      extractProtoMeetingTaskTargetDate(mt)
                                    ) ?? []),
                                  ];
                                  ends.sort(
                                    (a, b) =>
                                      b?.date?.localeCompare(a?.date ?? "") ?? 0
                                  );

                                  return (
                                    <td className="border border-dark">
                                      <small>
                                        {(() => {
                                          try {
                                            return Intl.DateTimeFormat(
                                              navigator.language ?? "en-US",
                                              { dateStyle: "short" }
                                            ).format(
                                              new Date(starts?.[0].start ?? "")
                                            );
                                          } catch (e) {
                                            return "none";
                                          }
                                        })()}{" "}
                                        to{" "}
                                        {(() => {
                                          try {
                                            return Intl.DateTimeFormat(
                                              navigator.language ?? "en-US",
                                              { dateStyle: "short" }
                                            ).format(
                                              new Date(ends?.[0]?.date ?? "")
                                            );
                                          } catch (e) {
                                            return "none";
                                          }
                                        })()}
                                      </small>
                                    </td>
                                  );
                                })(),
                              },
                              // {
                              //   name: "Time",
                              //   modes: [
                              //     "Generic",
                              //     "Installer",
                              //     "Production",
                              //   ] as DeptMode[],
                              //   node: (() => {
                              //     const starts = [
                              //       ...(tL.taskList?.meetingTasks ?? []),
                              //     ];
                              //     starts.sort(
                              //       (a, b) =>
                              //         `${a.fromHour}:${a.fromMins}`?.localeCompare(
                              //           `${b.fromHour}:${b.fromMins}`
                              //         ) ?? 0
                              //     );

                              //     const ends = [
                              //       ...(tL.taskList?.meetingTasks ?? []),
                              //     ];
                              //     ends.sort(
                              //       (a, b) =>
                              //         `${b.fromHour}:${b.fromMins}`?.localeCompare(
                              //           `${a.fromHour}:${a.fromMins}`
                              //         ) ?? 0
                              //     );
                              //     return (
                              //       <td className="border border-dark">
                              //         <small>
                              //           {starts?.[0].fromHour ?? 0}:
                              //           {starts?.[0].fromMins ?? 0} to{" "}
                              //           {ends?.[0].fromHour ?? 0}:
                              //           {ends?.[0].fromMins ?? 0}
                              //         </small>
                              //       </td>
                              //     );
                              //   })(),
                              // },
                              {
                                name: "Coordinate",
                                modes: ["Installer"] as DeptMode[],
                                node: (
                                  <td className="border border-dark">
                                    {tL.taskList?.meetingTasks
                                      .find(
                                        (mt) =>
                                          mt.status !== "COMPLETED" &&
                                          mt.journeys.length > 0
                                      )
                                      ?.journeys.map((j) => {
                                        return (
                                          <>
                                            <div>
                                              <small>
                                                {j.lat?.toFixed(4)},{" "}
                                                {j.lon?.toFixed(4)}
                                              </small>
                                            </div>
                                          </>
                                        );
                                      })}
                                  </td>
                                ),
                              },
                              {
                                name: "Current Coord.",
                                modes: ["Installer"] as DeptMode[],
                                node: (() => {
                                  const mt = tL.taskList?.meetingTasks.find(
                                    (mt) => mt.status === "OUTSTANDING"
                                  );

                                  return (
                                    <td className="border border-dark">
                                      {
                                        <>
                                          <ol>
                                            {mt?.meetingTaskInCharges
                                              .filter(
                                                (mtic) =>
                                                  mtic.extUserId &&
                                                  mtic.extUserId !== "0"
                                              )
                                              .map((mtic) => {
                                                const foundUserCoord =
                                                  userLocations.find(
                                                    (u) =>
                                                      `${u.extUserId}` ===
                                                      `${mtic.extUserId}`
                                                  );

                                                return (
                                                  <>
                                                    {foundUserCoord ? (
                                                      <>
                                                        <li>
                                                          <small>
                                                            {foundUserCoord?.lat?.toFixed(
                                                              4
                                                            )}
                                                            ,{" "}
                                                            {foundUserCoord?.lon?.toFixed(
                                                              4
                                                            )}{" "}
                                                            (
                                                            {foundUserCoord.createdAt ? (
                                                              (() => {
                                                                try {
                                                                  return (
                                                                    <>
                                                                      {Intl.DateTimeFormat(
                                                                        navigator.language ??
                                                                          "en-US",
                                                                        {
                                                                          timeStyle:
                                                                            "short",
                                                                          dateStyle:
                                                                            "short",
                                                                          hourCycle:
                                                                            "h23",
                                                                        } as any
                                                                      ).format(
                                                                        new Date(
                                                                          foundUserCoord.createdAt
                                                                        )
                                                                      )}
                                                                    </>
                                                                  );
                                                                } catch (e) {
                                                                  return <></>;
                                                                }
                                                              })()
                                                            ) : (
                                                              <></>
                                                            )}
                                                            )
                                                          </small>
                                                        </li>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <li>
                                                          <small>
                                                            No coord
                                                          </small>
                                                        </li>
                                                      </>
                                                    )}
                                                  </>
                                                );
                                              })}
                                          </ol>
                                        </>
                                      }
                                    </td>
                                  );
                                })(),
                              },
                              {
                                name: "Est. Distance",
                                modes: ["Installer"] as DeptMode[],
                                node: (() => {
                                  const mt = tL.taskList?.meetingTasks.find(
                                    (mt) => mt.status === "OUTSTANDING"
                                  );
                                  return (
                                    <td className="border border-dark">
                                      <>
                                        <ol>
                                          {mt?.meetingTaskInCharges
                                            .filter(
                                              (mtic) =>
                                                mtic.extUserId &&
                                                mtic.extUserId !== "0"
                                            )
                                            .map((mtic) => {
                                              const fUser = ctx?.extUsers.find(
                                                (ux) =>
                                                  `${ux.id}` ===
                                                  `${mtic.extUserId}`
                                              );
                                              const latDest = mt.journeys.find(
                                                (j) => j
                                              )?.lat;
                                              const lonDest = mt.journeys.find(
                                                (j) => j
                                              )?.lon;
                                              const foundUserCoord =
                                                userLocations.find(
                                                  (u) =>
                                                    `${u.extUserId}` ===
                                                    `${mtic.extUserId}`
                                                );

                                              return (
                                                <>
                                                  <li>
                                                    <div>
                                                      <small>
                                                        <strong>
                                                          {fUser?.username}:{" "}
                                                          {latDest &&
                                                          lonDest &&
                                                          foundUserCoord?.lat &&
                                                          foundUserCoord.lon ? (
                                                            (() => {
                                                              const calc =
                                                                haversine(
                                                                  latDest,
                                                                  lonDest,
                                                                  foundUserCoord.lat,
                                                                  foundUserCoord.lon
                                                                );

                                                              return (
                                                                <>
                                                                  {/* Divide by 30 km/h */}
                                                                  {(
                                                                    calc / 1000
                                                                  ).toFixed(
                                                                    2
                                                                  )}{" "}
                                                                  km
                                                                </>
                                                              );
                                                            })()
                                                          ) : (
                                                            <>No data</>
                                                          )}
                                                        </strong>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              );
                                            })}
                                        </ol>
                                      </>
                                    </td>
                                  );
                                })(),
                              },
                              {
                                name: "ETA",
                                modes: ["Installer"] as DeptMode[],
                                node: (() => {
                                  const mt = tL.taskList?.meetingTasks.find(
                                    (mt) => mt.status === "OUTSTANDING"
                                  );
                                  return (
                                    <td className="border border-dark">
                                      <>
                                        <ol>
                                          {mt?.meetingTaskInCharges
                                            .filter(
                                              (mtic) =>
                                                mtic.extUserId &&
                                                mtic.extUserId !== "0"
                                            )
                                            .map((mtic) => {
                                              const fUser = ctx?.extUsers.find(
                                                (ux) =>
                                                  `${ux.id}` ===
                                                  `${mtic.extUserId}`
                                              );
                                              const latDest = mt.journeys.find(
                                                (j) => j
                                              )?.lat;
                                              const lonDest = mt.journeys.find(
                                                (j) => j
                                              )?.lon;
                                              const foundUserCoord =
                                                userLocations.find(
                                                  (u) =>
                                                    `${u.extUserId}` ===
                                                    `${mtic.extUserId}`
                                                );

                                              return (
                                                <>
                                                  <li>
                                                    <div>
                                                      <small>
                                                        <strong>
                                                          {fUser?.username}:{" "}
                                                          {latDest &&
                                                          lonDest &&
                                                          foundUserCoord?.lat &&
                                                          foundUserCoord.lon ? (
                                                            (() => {
                                                              const calc =
                                                                haversine(
                                                                  latDest,
                                                                  lonDest,
                                                                  foundUserCoord.lat,
                                                                  foundUserCoord.lon
                                                                );

                                                              return (
                                                                <>
                                                                  {/* Divide by 30 km/h */}
                                                                  {(
                                                                    calc /
                                                                    1000 /
                                                                    30
                                                                  ).toFixed(
                                                                    2
                                                                  )}{" "}
                                                                  h
                                                                </>
                                                              );
                                                            })()
                                                          ) : (
                                                            <>No data</>
                                                          )}
                                                        </strong>
                                                      </small>
                                                    </div>
                                                  </li>
                                                </>
                                              );
                                            })}
                                        </ol>
                                      </>
                                    </td>
                                  );
                                })(),
                              },
                              {
                                name: "Qty",
                                modes: ["Production"] as DeptMode[],
                                node: (
                                  <>
                                    <td className="border border-dark">
                                      <small>
                                        <strong>
                                          {(() => {
                                            if (
                                              tL.taskList?.extProgramRunQty &&
                                              tL.taskList?.extProgramRunQty !==
                                                0
                                            ) {
                                              return (
                                                <>
                                                  {tL.taskList
                                                    ?.extProgramRunQtyActual ??
                                                    0}
                                                  /
                                                  {tL.taskList
                                                    ?.extProgramRunQty ?? 0}
                                                </>
                                              );
                                            } else if (
                                              tL.taskList
                                                ?.extIntegrationModuleQty &&
                                              tL.taskList
                                                ?.extIntegrationModuleQty !== 0
                                            ) {
                                              return (
                                                <>
                                                  {tL.taskList
                                                    ?.extIntegrationModuleQtyActual ??
                                                    0}
                                                  /
                                                  {tL.taskList
                                                    ?.extIntegrationModuleQty ??
                                                    0}
                                                </>
                                              );
                                            } else {
                                              return "";
                                            }
                                          })()}
                                        </strong>
                                      </small>
                                    </td>
                                  </>
                                ),
                              },
                            ]
                              .filter((b) => b.modes.includes(selectedDeptMode))
                              .map((b) => {
                                return <>{b.node}</>;
                              })}
                          </tr>
                          {expandedWO.find(
                            (id) => id === tL.taskList?.masterJavaBaseModel?.id
                          ) ? (
                            <>
                              {tL.taskList?.meetingTasks.map((mt) => {
                                return (
                                  <>
                                    <tr>
                                      {[
                                        {
                                          name: "WO#",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (
                                            <td className="border border-dark"></td>
                                          ),
                                        },
                                        {
                                          name: "WO name",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (
                                            <td className="border border-dark"></td>
                                          ),
                                        },
                                        {
                                          name: "Task",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (() => {
                                            return (
                                              <>
                                                <td className="border border-dark">
                                                  <small
                                                    className={`font-weight-bold ${
                                                      mt.status === "COMPLETED"
                                                        ? `text-success`
                                                        : `text-danger`
                                                    }`}
                                                  >
                                                    {mt.description &&
                                                    mt.description !== ""
                                                      ? mt.description
                                                          ?.split(" ")
                                                          .map((s) =>
                                                            s.substring(0, 4)
                                                          )
                                                          .join(" ")
                                                      : "No desc"}
                                                  </small>
                                                </td>
                                              </>
                                            );
                                          })(),
                                        },
                                        {
                                          name: "Job",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (
                                            <td className="border border-dark"></td>
                                          ),
                                        },
                                        {
                                          name: "PIC",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (
                                            <td className="border border-dark"></td>
                                          ),
                                        },

                                        {
                                          name: "Status",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (() => {
                                            const completedTasks =
                                              tL.taskList?.meetingTasks.filter(
                                                (mt) =>
                                                  mt.status === "COMPLETED"
                                              ).length ?? 0;
                                            const totalTasks =
                                              tL.taskList?.meetingTasks
                                                .length ?? 0;
                                            return (
                                              <td className="border border-dark"></td>
                                            );
                                          })(),
                                        },
                                        {
                                          name: "Date",
                                          modes: [
                                            "Generic",
                                            "Installer",
                                            "Production",
                                          ] as DeptMode[],
                                          node: (() => {
                                            return (
                                              <td className="border border-dark"></td>
                                            );
                                          })(),
                                        },
                                        // {
                                        //   name: "Time",
                                        //   modes: [
                                        //     "Generic",
                                        //     "Installer",
                                        //     "Production",
                                        //   ] as DeptMode[],
                                        //   node: (() => {
                                        //     return (
                                        //       <td className="border border-dark"></td>
                                        //     );
                                        //   })(),
                                        // },
                                        {
                                          name: "Coordinate",
                                          modes: ["Installer"] as DeptMode[],
                                          node: (
                                            <td className="border border-dark"></td>
                                          ),
                                        },
                                        {
                                          name: "Current Coord.",
                                          modes: ["Installer"] as DeptMode[],
                                          node: (() => {
                                            const mt =
                                              tL.taskList?.meetingTasks.find(
                                                (mt) =>
                                                  mt.status === "OUTSTANDING"
                                              );

                                            return (
                                              <td className="border border-dark"></td>
                                            );
                                          })(),
                                        },
                                        {
                                          name: "Est. Distance",
                                          modes: ["Installer"] as DeptMode[],
                                          node: (() => {
                                            const mt =
                                              tL.taskList?.meetingTasks.find(
                                                (mt) =>
                                                  mt.status === "OUTSTANDING"
                                              );
                                            return (
                                              <td className="border border-dark"></td>
                                            );
                                          })(),
                                        },
                                        {
                                          name: "ETA",
                                          modes: ["Installer"] as DeptMode[],
                                          node: (() => {
                                            const mt =
                                              tL.taskList?.meetingTasks.find(
                                                (mt) =>
                                                  mt.status === "OUTSTANDING"
                                              );
                                            return (
                                              <td className="border border-dark"></td>
                                            );
                                          })(),
                                        },
                                        {
                                          name: "Qty",
                                          modes: ["Production"] as DeptMode[],
                                          node: (
                                            <>
                                              <td className="border border-dark"></td>
                                            </>
                                          ),
                                        },
                                      ]
                                        .filter((b) =>
                                          b.modes.includes(selectedDeptMode)
                                        )
                                        .map((b) => {
                                          return <>{b.node}</>;
                                        })}
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                  }
                })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}

        {/* {["Installer", "Installer_Timeline"].includes(viewMode) ? (
          <>
            <div>
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "WO #",
                    "PIC",
                    "WO name",
                    "Task",
                    "Status",
                    ...(viewMode === "Installer"
                      ? [
                          "Date",
                          "Time",
                          "Coordinate",
                          "Current",
                          "Est. Distance & ETA",
                        ]
                      : []),
                    ...(viewMode === "Installer_Timeline"
                      ? [...Array(24)].flatMap((_, i) => {
                          return [`${i}`];
                        })
                      : []),
                  ].map((h) => (
                    <>
                      <th className="bg-dark text-light">{h}</th>
                    </>
                  ))}
                </tr>

                {taskLists.taskLists
                  .filter(
                    (tL) =>
                      // Filter by coordinate only
                      (filterHasCoordinateOnly
                        ? tL.taskList?.meetingTasks.find((mt) =>
                            mt.journeys.find((mt) => mt)
                          )
                        : true) &&
                      // Fitler by dept
                      (selectedDept?.id
                        ? tL.taskList?.meetingTasks.find((mt) =>
                            mt.meetingTaskInCharges.find(
                              (mtic) =>
                                `${
                                  ctx?.extUsers.find(
                                    (ux) => `${ux.id}` === `${mtic.extUserId}`
                                  )?.departmentId
                                }` === `${selectedDept?.id}`
                            )
                          )
                        : true)
                  )
                  .map((tL) => {
                    return tL.taskList?.meetingTasks.map((mt, i) => {
                      return (
                        <>
                          <tr>
                            <td className="border border-dark">
                              {tL.taskList?.masterJavaBaseModel?.id}
                            </td>
                            <td className="border border-dark">
                              <small>
                                {mt.meetingTaskInCharges
                                  .map((mtic) => {
                                    const fUser = ctx?.extUsers.find(
                                      (ux) => `${ux.id}` === `${mtic.extUserId}`
                                    );
                                    return fUser?.username;
                                  })
                                  .join(", ")}
                              </small>
                            </td>
                            <td className="border border-dark">
                              {tL.taskList?.name}
                            </td>
                            <td className="border border-dark">
                              {mt.description}
                            </td>
                            <td
                              className="border border-dark"
                              style={{
                                backgroundColor:
                                  mt.status === "COMPLETED" ? "lightgreen" : "",
                              }}
                            >
                              {mt.status}
                            </td>
                            {viewMode === "Installer" ? (
                              <>
                                {" "}
                                <td className="border border-dark">
                                  {mt.start}
                                </td>
                                <td className="border border-dark">
                                  {mt.fromHour ?? "0"}:{mt.fromMins ?? "0"}-
                                  {mt.toHour ?? "0"}:{mt.toMins ?? "0"}
                                </td>
                                <td className="border border-dark">
                                  {mt.journeys.map((j) => {
                                    return (
                                      <>
                                        <div>
                                          <small>
                                            {j.lat?.toFixed(4)},{" "}
                                            {j.lon?.toFixed(4)}
                                          </small>
                                        </div>
                                      </>
                                    );
                                  })}
                                </td>
                                <td className="border border-dark">
                                  {tL.taskList?.meetingTasks.findIndex(
                                    (mt) => mt.status === "OUTSTANDING"
                                  ) === i ? (
                                    <>
                                      <ol>
                                        {mt.meetingTaskInCharges.map((mtic) => {
                                          const foundUserCoord =
                                            userLocations.find(
                                              (u) =>
                                                `${u.extUserId}` ===
                                                `${mtic.extUserId}`
                                            );

                                          return (
                                            <>
                                              {foundUserCoord ? (
                                                <>
                                                  <li>
                                                    <small>
                                                      {foundUserCoord?.lat?.toFixed(
                                                        4
                                                      )}
                                                      ,{" "}
                                                      {foundUserCoord?.lon?.toFixed(
                                                        4
                                                      )}{" "}
                                                      (
                                                      {foundUserCoord.createdAt ? (
                                                        (() => {
                                                          try {
                                                            return (
                                                              <>
                                                                {Intl.DateTimeFormat(
                                                                  navigator.language ??
                                                                    "en-US",
                                                                  {
                                                                    timeStyle:
                                                                      "short",
                                                                    dateStyle:
                                                                      "short",
                                                                    hourCycle:
                                                                      "h23",
                                                                  } as any
                                                                ).format(
                                                                  new Date(
                                                                    foundUserCoord.createdAt
                                                                  )
                                                                )}
                                                              </>
                                                            );
                                                          } catch (e) {
                                                            return <></>;
                                                          }
                                                        })()
                                                      ) : (
                                                        <></>
                                                      )}
                                                      )
                                                    </small>
                                                  </li>
                                                </>
                                              ) : (
                                                <>
                                                  <li>
                                                    <small>No coord</small>
                                                  </li>
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </ol>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                                <td className="border border-dark">
                                  {tL.taskList?.meetingTasks.findIndex(
                                    (mt) => mt.status === "OUTSTANDING"
                                  ) === i ? (
                                    <>
                                      <ol>
                                        {mt.meetingTaskInCharges.map((mtic) => {
                                          const fUser = ctx?.extUsers.find(
                                            (ux) =>
                                              `${ux.id}` === `${mtic.extUserId}`
                                          );
                                          const latDest = mt.journeys.find(
                                            (j) => j
                                          )?.lat;
                                          const lonDest = mt.journeys.find(
                                            (j) => j
                                          )?.lon;
                                          const foundUserCoord =
                                            userLocations.find(
                                              (u) =>
                                                `${u.extUserId}` ===
                                                `${mtic.extUserId}`
                                            );

                                          return (
                                            <>
                                              <li>
                                                <div>
                                                  <small>
                                                    <strong>
                                                      {fUser?.username}:{" "}
                                                      {latDest &&
                                                      lonDest &&
                                                      foundUserCoord?.lat &&
                                                      foundUserCoord.lon ? (
                                                        (() => {
                                                          const calc =
                                                            haversine(
                                                              latDest,
                                                              lonDest,
                                                              foundUserCoord.lat,
                                                              foundUserCoord.lon
                                                            );

                                                          return (
                                                            <>
                                                              {(
                                                                calc / 1000
                                                              ).toFixed(2)}{" "}
                                                              km (
                                                              {(
                                                                calc /
                                                                1000 /
                                                                30
                                                              ).toFixed(2)}{" "}
                                                              h)
                                                            </>
                                                          );
                                                        })()
                                                      ) : (
                                                        <>No data</>
                                                      )}
                                                    </strong>
                                                  </small>
                                                </div>
                                              </li>
                                            </>
                                          );
                                        })}
                                      </ol>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </>
                            ) : (
                              <></>
                            )}
                            {viewMode === "Installer_Timeline" ? (
                              <>
                                {[...Array(24)].map((_, i) => {
                                  const targetMinsFrom =
                                    (mt.fromHour ?? 0) * 60 +
                                    (mt.fromMins ?? 0);
                                  const targetMinsTo =
                                    (mt.toHour ?? 0) * 60 + (mt.toMins ?? 0);

                                  const curMinsFrom = i * 60;
                                  const curMinsTo = i * 60 + 60;

                                  return (
                                    <>
                                      <td
                                        style={{
                                          backgroundColor:
                                            curMinsFrom < targetMinsTo &&
                                            curMinsTo > targetMinsFrom
                                              ? "blue"
                                              : "",
                                        }}
                                      ></td>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </tr>
                        </>
                      );
                    });
                  })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
};
export default MonitoringPage;
