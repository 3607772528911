import React, { useContext, useEffect, useState } from "react";
import { useInterval } from "react-interval-hook";
import { AppContext } from "../../../App";
import {
  fetchAttendanceData,
  fetchAttendanceNotes,
  makeDateString,
} from "../../../helpers";
import { AttendanceNote, UserFirstCheckIn } from "../../../models/meeting";

const AttendancePage = () => {
  const ctx = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [userFirstCheckIns, setuserFirstCheckIns] = useState<
    UserFirstCheckIn[]
  >([]);
  const [attendanceNotes, setAttendanceNotes] = useState<AttendanceNote[]>([]);

  useEffect(() => {
    handleInit();
  }, []);

  useInterval(() => {
    fetchAttendance();
  }, 60000 * 15);

  const handleInit = async () => {
    fetchAttendanceNotesData();
    fetchAttendance();
  };

  const fetchAttendance = async () => {
    setLoading(true);
    setuserFirstCheckIns(await fetchAttendanceData({ date: new Date() }));
    setLoading(false);
    setLastUpdate(new Date());
  };

  const fetchAttendanceNotesData = async () => {
    setAttendanceNotes(
      await fetchAttendanceNotes({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  return (
    <>
      <div className="m-3">
        <div>
          <div>
            Attendance{" "}
            {loading
              ? " Loading..."
              : ` | last update: ${Intl.DateTimeFormat(
                  navigator.language ?? "en-US",
                  {
                    dateStyle: "medium",
                    timeStyle: "long",
                  } as any
                ).format(new Date(lastUpdate))}`}
          </div>
        </div>
        <hr />
        <div>Present</div>
        <div>
          <div
            className="border border-dark shadow shadow-md overflow-auto"
            style={{ height: "25vh" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              {["#", "Name", "Check in", "Check out"].map((h) => (
                <>
                  <th
                    className="border border-dark bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              ))}
              {(() => {
                const filteredUsers = userFirstCheckIns.filter(
                  (u) => u.firstLocation
                );

                return filteredUsers.map((u, i) => {
                  const foundAttendanceNote = attendanceNotes.find(
                    (n) =>
                      `${makeDateString(new Date(n.date ?? ""))}` ===
                        `${makeDateString(new Date())}` &&
                      n.extUserId === u.user?.id
                  );
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <small>
                            <strong> {filteredUsers.length - i}</strong>
                          </small>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>
                              {" "}
                              {u.user?.name} ({u.user?.username})
                            </strong>
                          </small>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>
                              {" "}
                              {foundAttendanceNote
                                ? foundAttendanceNote?.note
                                : u.firstLocation?.createdAt ?? ""}
                            </strong>
                          </small>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>{u.firstCheckOut?.createdAt ?? ""}</strong>
                          </small>
                        </td>
                      </tr>
                    </>
                  );
                });
              })()}
            </table>
          </div>
        </div>
        <hr />
        <div>Late </div>
        <div>
          <div
            className="border border-dark shadow shadow-md overflow-auto"
            style={{ height: "25vh" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["#", "Name", "Check in", "Check out"].map((h) => (
                  <>
                    <th
                      className="border border-dark bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
              </tr>
            </table>
          </div>
        </div>
        <hr />
        <div>Absent </div>
        <div>
          <div
            className="border border-dark shadow shadow-md overflow-auto"
            style={{ height: "25vh" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <tr>
                {["#", "Name", "Check in", "Check out"].map((h) => (
                  <>
                    <th
                      className="border border-dark bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                ))}
              </tr>
              {(() => {
                const filteredUsers = userFirstCheckIns.filter(
                  (u) => !u.firstLocation
                );

                return filteredUsers.map((u, i) => {
                  const foundAttendanceNote = attendanceNotes.find(
                    (n) =>
                      `${makeDateString(new Date(n.date ?? ""))}` ===
                        `${makeDateString(new Date())}` &&
                      n.extUserId === u.user?.id
                  );
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <small>
                            <strong>{filteredUsers.length - i}</strong>
                          </small>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>
                              {u.user?.name} ({u.user?.username})
                            </strong>
                          </small>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>
                              {foundAttendanceNote
                                ? foundAttendanceNote?.note
                                : u.firstLocation?.createdAt ?? ""}
                            </strong>
                          </small>
                        </td>
                        <td className="border border-dark">
                          <small>
                            <strong>{u.firstCheckOut?.createdAt}</strong>
                          </small>
                        </td>
                      </tr>
                    </>
                  );
                });
              })()}
            </table>
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default AttendancePage;
