import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, Spinner, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { AppContext } from "../../App";
import {
    fetchExtProject,
    fetchExtProjectProductPanelTemplatesByExtProjectId,
    fetchPanelTemplates
} from "../../helpers";
import {
    ExtProjectProductPanelTemplate,
    ExtProjectSimple,
    PanelTemplate
} from "../../models/model";
import { initialExtProjectProductPanelTemplate } from "../../models/modelinitials";
import { RequestStatus } from "../../models/RequestStatus";

const ProjectDetailPage = () => {
  const { id } = useParams() as { id: string };
  const state = useContext(AppContext);
  const [project, setProject] = useState<ExtProjectSimple | null>(null);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [panelTemplates, setPanelTemplates] = useState<PanelTemplate[]>([]);
  const [panelTemplateSearch, setPanelTemplateSearch] = useState("");
  const [
    extProjectProductPanelTemplates,
    setExtProjectProductPanelTemplates,
  ] = useState<ExtProjectProductPanelTemplate[]>([]);

  const [
    extProjectProductPanelTemplateDeleteIds,
    setExtProjectProductPanelTemplateDeleteIds,
  ] = useState<number[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const [
        project,
        panelTemplates,
        extProjectProductPanelTemplates,
      ] = await Promise.all([
        fetchExtProject(state?.baseUrl ?? "", state?.apiKey ?? "", id),
        fetchPanelTemplates(state?.baseUrl ?? "", state?.apiKey ?? ""),
        fetchExtProjectProductPanelTemplatesByExtProjectId(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
      ]);

      setProject(project);
      setPanelTemplates(panelTemplates);
      setExtProjectProductPanelTemplates(extProjectProductPanelTemplates);

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log("Error", e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const handleSave = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      // Save
      await Promise.all(
        extProjectProductPanelTemplates.map(
          async (extProjectProductPanelTemplate) => {
            return await fetch(
              `${state?.baseUrl}/extprojectproductpaneltemplates`,
              {
                method: "post",
                headers: {
                  authorization: state?.apiKey ?? "",
                  "content-type": "application/json",
                },
                body: JSON.stringify(extProjectProductPanelTemplate),
              }
            );
          }
        )
      );

      // Delete
      await Promise.all([
        ...extProjectProductPanelTemplateDeleteIds.map(async (id) => {
          return await fetch(
            `${state?.baseUrl}/extprojectproductpaneltemplates/${id}/empty`,
            {
              method: "delete",
              headers: {
                authorization: state?.apiKey ?? "",
              },
            }
          );
        }),
      ]);

      setRequestStatus(RequestStatus.Success);
      window.location.reload();
    } catch (e) {
      console.log("[handleSave] error");
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <h4>Project Detail: {project?.name}</h4>
          <div className="mx-2">
            {requestStatus === RequestStatus.Loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <></>
            )}
          </div>
          <div className="mx-2">
            <Button size="sm" onClick={() => handleSave()}>
              Save
            </Button>
          </div>
        </div>

        <div
          className="my-2 overflow-auto border border-secondary shadow shadow-md bg-light"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <Table size="sm" style={{ borderCollapse: "separate" }}>
            <thead>
              {[
                "Order",
                "Name",
                "Product #",
                "Qty",
                "PO #",
                "PO Type",
                "Site",
                "Template",
              ].map((head) => (
                <th
                  className="border border-secondary text-center align-middle bg-dark text-light"
                  style={{ position: "sticky", top: 0 }}
                >
                  {head}
                </th>
              ))}
            </thead>
            <tbody>
              {project?.projectProducts.map((projectProduct, i) => {
                const foundExtProjectProductPanelTemplate = extProjectProductPanelTemplates.find(
                  (extProjectProductPanelTemplate) =>
                    extProjectProductPanelTemplate.extProjectProductId ===
                    projectProduct.id
                );

                const foundExtProjectProductPanelTemplateIndex = extProjectProductPanelTemplates.findIndex(
                  (extProjectProductPanelTemplate) =>
                    extProjectProductPanelTemplate.extProjectProductId ===
                    projectProduct.id
                );

                return (
                  <tr>
                    <td className="border border-secondary text-center align-middle">
                      {i + 1}
                    </td>

                    <td className="border border-secondary text-center align-middle">
                      {projectProduct.name}
                    </td>

                    <td className="border border-secondary text-center align-middle">
                      {projectProduct.productNumber}
                    </td>
                    <td className="border border-secondary text-center align-middle">
                      {projectProduct.qty}
                    </td>
                    <td className="border border-secondary text-center align-middle">
                      {projectProduct.projectPo?.number}
                    </td>
                    <td className="border border-secondary text-center align-middle">
                      {projectProduct.projectPo?.projectPoType?.name}
                    </td>
                    <td className="border border-secondary text-center align-middle">
                      {projectProduct.projectPo?.site?.name}
                    </td>
                    <td
                      className={`border border-secondary text-center align-middle ${
                        foundExtProjectProductPanelTemplate ? "bg-success" : ""
                      }`}
                    >
                      <FormControl
                        size="sm"
                        placeholder="Search..."
                        onChange={(e) => setPanelTemplateSearch(e.target.value)}
                      />
                      <div className="d-flex">
                        <select
                          onInput={(e: any) => {
                            console.log(
                              extProjectProductPanelTemplates,
                              projectProduct.id,
                              foundExtProjectProductPanelTemplate,
                              e.target.value
                            );

                            if (foundExtProjectProductPanelTemplate) {
                              setExtProjectProductPanelTemplates(
                                extProjectProductPanelTemplates.map(
                                  (extProjectProductPanelTemplate, ix) =>
                                    ix ===
                                    foundExtProjectProductPanelTemplateIndex
                                      ? {
                                          ...extProjectProductPanelTemplate,
                                          panelTemplate:
                                            panelTemplates.find(
                                              (panelTemplate) =>
                                                `${panelTemplate.id}` ===
                                                e.target.value
                                            ) ?? null,
                                        }
                                      : extProjectProductPanelTemplate
                                )
                              );
                            } else {
                              setExtProjectProductPanelTemplates([
                                ...extProjectProductPanelTemplates,
                                {
                                  ...initialExtProjectProductPanelTemplate,
                                  extProjectId: parseInt(id),
                                  extProjectProductId: projectProduct.id,
                                  panelTemplate:
                                    panelTemplates.find(
                                      (panelTemplate) =>
                                        `${panelTemplate.id}` === e.target.value
                                    ) ?? null,
                                },
                              ]);
                            }
                          }}
                        >
                          <option disabled selected>
                            {foundExtProjectProductPanelTemplate
                              ? foundExtProjectProductPanelTemplate
                                  .panelTemplate?.name
                              : "Select Template"}
                          </option>
                          {panelTemplates
                            .filter((panelTemplate) =>
                              panelTemplate.name
                                ?.toLowerCase()
                                .includes(panelTemplateSearch.toLowerCase())
                            )
                            .map((panelTemplate) => (
                              <option value={panelTemplate.id ?? 0}>
                                {panelTemplate.name}
                              </option>
                            ))}
                        </select>
                        {foundExtProjectProductPanelTemplate &&
                        foundExtProjectProductPanelTemplateIndex > -1 ? (
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() => {
                              setExtProjectProductPanelTemplateDeleteIds([
                                ...extProjectProductPanelTemplateDeleteIds,
                                foundExtProjectProductPanelTemplate.id ?? 0,
                              ]);

                              setExtProjectProductPanelTemplates(
                                extProjectProductPanelTemplates.filter(
                                  (_, i) =>
                                    i !==
                                    foundExtProjectProductPanelTemplateIndex
                                )
                              );
                            }}
                          >
                            Delete
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        {/* <div className="my-3">
          <div>{JSON.stringify(project)}</div>
        </div> */}
      </div>
    </>
  );
};
export default ProjectDetailPage;
