import {
    Add, Delete,
    List
} from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, FormControl } from "react-bootstrap";
import { AppContext } from "../../App";
import {
    ProcessType, ProcessTypeGeneralView
} from "../../models/model";
import {
    initialProcessType,
    initialProcessTypeGeneral,
    initialProcessTypeGeneralItem,
    initialProcessTypeGeneralItemSubProcess,
    initialProcessTypeGeneralItemSubProcessView,
    initialProcessTypeGeneralItemView,
    initialProcessTypeGeneralView
} from "../../models/modelinitials";
import GenericOrderableTable from "../GenericOrderableTable.tsx/GenericOrderableTable";

const MechanicalProcessTypePage = () => {
  const state = useContext(AppContext);

  const [processTypeDeleteIds, setProcessTypeDeleteIds] = useState<number[]>(
    []
  );

  const [
    processTypeGeneralDeleteIds,
    setProcessTypeGeneralDeleteIds,
  ] = useState<number[]>([]);

  const [
    processTypeGeneralItemDeleteIds,
    setProcessTypeGeneralItemDeleteIds,
  ] = useState<number[]>([]);

  const [
    processTypeGeneralItemSubProcessDeleteIds,
    setProcessTypeGeneralItemSubProcessDeleteIds,
  ] = useState<number[]>([]);

  const [processTypeGenerals, setProcessTypeGenerals] = useState<
    ProcessTypeGeneralView[]
  >([]);

  const [processTypeGeneralMove, setProcessTypeGeneralMove] = useState(false);
  const [
    processTypeGeneralMoveIndex,
    setProcessTypeGeneralMoveIndex,
  ] = useState<number | null>(null);

  const [processTypeGeneralItemMove, setProcessTypeGeneralItemMove] = useState(
    false
  );
  const [
    processTypeGeneralItemMoveIndex,
    setProcessTypeGeneralItemMoveIndex,
  ] = useState<number | null>(null);

  const [
    processTypeGeneralItemSubProcessMove,
    setProcessTypeGeneralItemSubProcessMove,
  ] = useState(false);
  const [
    processTypeGeneralItemSubProcessMoveIndex,
    setProcessTypeGeneralItemSubProcessMoveIndex,
  ] = useState<number | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await fetch(`${state?.baseUrl}/processtypegenerals-view`, {
        headers: { authorization: state?.apiKey ?? "" },
      });

      if (resp.status !== 200) throw await resp.text();

      const newProcessTypeGenerals = (await resp.json()) as ProcessTypeGeneralView[];

      // Sort
      newProcessTypeGenerals.sort(
        (a, b) =>
          (a.processTypeGeneral?.ordering ?? 0) -
          (b.processTypeGeneral?.ordering ?? 0)
      );

      newProcessTypeGenerals.forEach((processTypeGeneralView) => {
        processTypeGeneralView.processTypeGeneralItems.sort(
          (a, b) =>
            (a.processTypeGeneralItem?.ordering ?? 0) -
            (b.processTypeGeneralItem?.ordering ?? 0)
        );
      });

      newProcessTypeGenerals.forEach((processTypeGeneralView) =>
        processTypeGeneralView.processTypeGeneralItems.forEach(
          (processTypeGeneralItemView) => {
            processTypeGeneralItemView.processTypeGeneralItemSubProcesses.sort(
              (a, b) =>
                (a.processTypeGeneralItemSubProcess?.ordering ?? 0) -
                (b.processTypeGeneralItemSubProcess?.ordering ?? 0)
            );
          }
        )
      );

      setProcessTypeGenerals(newProcessTypeGenerals);
    } catch (e) {
      console.log("[fetchData mechanical] error", e);
    }
  };

  const handleSave = async () => {
    try {
      console.log(state?.processTypes);

      // Save
      await Promise.all([
        await fetch(`${state?.baseUrl}/processtypes-save-batch`, {
          method: "post",
          headers: {
            authorization: state?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(state?.processTypes ?? []),
        }),
        await fetch(`${state?.baseUrl}/processtypegenerals-view-save-batch`, {
          method: "post",
          headers: {
            authorization: state?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(processTypeGenerals),
        }),
      ]);

      // Delete
      await Promise.all([
        ...processTypeDeleteIds.map(async (id) => {
          return await fetch(`${state?.baseUrl}/processtypes/${id}/hide`, {
            method: "delete",
            headers: {
              authorization: state?.apiKey ?? "",
            },
          });
        }),
        ...processTypeGeneralDeleteIds.map(async (id) => {
          return await fetch(
            `${state?.baseUrl}/processtypegenerals/${id}/empty`,
            {
              method: "delete",
              headers: {
                authorization: state?.apiKey ?? "",
              },
            }
          );
        }),
        ...processTypeGeneralItemDeleteIds.map(async (id) => {
          return await fetch(
            `${state?.baseUrl}/processtypegeneralitems/${id}/empty`,
            {
              method: "delete",
              headers: {
                authorization: state?.apiKey ?? "",
              },
            }
          );
        }),
        ...processTypeGeneralItemSubProcessDeleteIds.map(async (id) => {
          return await fetch(
            `${state?.baseUrl}/processtypegeneralitemsubprocesses/${id}/empty`,
            {
              method: "delete",
              headers: {
                authorization: state?.apiKey ?? "",
              },
            }
          );
        }),
      ]);

      window.location.reload();
    } catch (e) {
      console.log("[handleSave] error", e);
    }
  };

  const handleAdd = () => {
    try {
      if (state?.processTypes)
        state.setProcessTypes([
          ...state.processTypes,
          { ...initialProcessType },
        ]);
    } catch (e) {
      console.log("[handleAdd] error", e);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center ">
          <div>
            <h4>Mechanical Process Types</h4>
          </div>
          <div className="mx-2">
            <Button size="sm" variant="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
          <div className="mx-2">
            <Button size="sm" variant="primary" onClick={handleAdd}>
              <Add fontSize="inherit" /> Add
            </Button>
          </div>
        </div>

        <div
          className="overflow-auto border border-secondary"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <GenericOrderableTable<ProcessType>
            head={["Name"]}
            body={
              state?.processTypes.filter(
                (processType) => !processType.hidden
              ) ?? []
            }
            onDelete={(processType, i) => {
              setProcessTypeDeleteIds([
                ...processTypeDeleteIds,
                processType?.id,
              ]);
              state?.processTypes.splice(i, 1);
            }}
            mapper={(item, i) => {
              return (
                <>
                  <td className="border border-secondary">
                    <FormControl
                      size="sm"
                      value={item.name}
                      onChange={(e) => {
                        if (state?.processTypes) {
                          state.setProcessTypes(
                            state.processTypes.map((processTypeX, ix) =>
                              i === ix
                                ? { ...processTypeX, name: e.target.value }
                                : { ...processTypeX }
                            )
                          );
                        }
                      }}
                    />{" "}
                  </td>
                </>
              );
            }}
          />
        </div>

        <div className="d-flex align-items-center my-3">
          <div className="d-flex">
            <div>
              <h4>Process Type General</h4>
            </div>
            <div className="ml-2">
              <Button
                size="sm"
                onClick={() => {
                  setProcessTypeGenerals([
                    ...processTypeGenerals,
                    {
                      ...initialProcessTypeGeneralView,
                      processTypeGeneral: { ...initialProcessTypeGeneral },
                      processTypeGeneralItems: [],
                    },
                  ]);
                }}
              >
                <Add fontSize="inherit" /> Add
              </Button>
            </div>
          </div>
        </div>
        <div>
          <Container>
            <div>
              {processTypeGenerals.map((processTypeGeneralView, i) => {
                return (
                  <>
                    <div>
                      <div className="d-flex align-items-center">
                        {i + 1}.{" "}
                        <Button
                          size="sm"
                          className="mx-1"
                          onClick={() => {
                            setProcessTypeGenerals(
                              processTypeGenerals.map(
                                (processTypeGeneralViewX, ix) =>
                                  i === ix
                                    ? {
                                        ...processTypeGeneralViewX,
                                        processTypeGeneralItems: [
                                          ...processTypeGeneralViewX.processTypeGeneralItems,
                                          {
                                            ...initialProcessTypeGeneralItemView,
                                            processTypeGeneralItem: {
                                              ...initialProcessTypeGeneralItem,
                                            },
                                          },
                                        ],
                                      }
                                    : processTypeGeneralViewX
                              )
                            );
                          }}
                        >
                          <Add fontSize="inherit" />
                        </Button>
                        <Button
                          disabled={
                            processTypeGeneralMove &&
                            processTypeGeneralMoveIndex === i
                          }
                          onClick={() => {
                            console.log(
                              processTypeGeneralMove,
                              i,
                              processTypeGeneralMoveIndex
                            );

                            if (processTypeGeneralMove) {
                              if (processTypeGeneralMoveIndex !== null) {
                                const a = processTypeGenerals[i];
                                const b =
                                  processTypeGenerals[
                                    processTypeGeneralMoveIndex
                                  ];
                                processTypeGenerals[
                                  processTypeGeneralMoveIndex
                                ] = a;
                                processTypeGenerals[i] = b;
                                setProcessTypeGeneralMove(false);
                                setProcessTypeGeneralMoveIndex(null);
                              }
                            } else {
                              setProcessTypeGeneralMove(true);
                              setProcessTypeGeneralMoveIndex(i);
                            }
                          }}
                          variant="success"
                          size="sm"
                          className="mx-1"
                        >
                          <List fontSize="inherit" />
                        </Button>
                        <Button
                          onClick={() => {
                            setProcessTypeGenerals(
                              processTypeGenerals.filter((_, ix) => ix !== i)
                            );
                            setProcessTypeGeneralDeleteIds([
                              ...processTypeGeneralDeleteIds,
                              processTypeGeneralView.processTypeGeneral?.id ??
                                0,
                            ]);
                          }}
                          variant="danger"
                          size="sm"
                          className="mx-1"
                        >
                          <Delete fontSize="inherit" />
                        </Button>
                        <FormControl
                          value={
                            processTypeGeneralView.processTypeGeneral?.name ??
                            ""
                          }
                          onChange={(e) => {
                            setProcessTypeGenerals(
                              processTypeGenerals.map(
                                (processTypeGeneralViewX, ix) =>
                                  ix === i
                                    ? {
                                        ...processTypeGeneralViewX,
                                        processTypeGeneral: processTypeGeneralViewX.processTypeGeneral
                                          ? {
                                              ...processTypeGeneralViewX.processTypeGeneral,
                                              name: e.target.value,
                                            }
                                          : processTypeGeneralViewX.processTypeGeneral,
                                      }
                                    : processTypeGeneralViewX
                              )
                            );
                          }}
                          size="sm"
                        />
                      </div>
                      <div>
                        <strong>Items</strong>
                      </div>
                      {processTypeGeneralView.processTypeGeneralItems.map(
                        (processTypeGeneralItemView, j) => {
                          return (
                            <>
                              <div className="d-flex ml-2 my-2">
                                {i + 1}.{j + 1}
                                <Button
                                  onClick={() => {
                                    setProcessTypeGenerals(
                                      processTypeGenerals.map(
                                        (processTypeGeneralViewX, ix) =>
                                          i === ix
                                            ? {
                                                ...processTypeGeneralViewX,
                                                processTypeGeneralItems: processTypeGeneralViewX.processTypeGeneralItems.map(
                                                  (
                                                    processTypeGeneralItemViewX,
                                                    jx
                                                  ) =>
                                                    j === jx
                                                      ? {
                                                          ...processTypeGeneralItemViewX,
                                                          processTypeGeneralItemSubProcesses: [
                                                            ...processTypeGeneralItemViewX.processTypeGeneralItemSubProcesses,
                                                            {
                                                              ...initialProcessTypeGeneralItemSubProcessView,
                                                              processTypeGeneralItemSubProcess: {
                                                                ...initialProcessTypeGeneralItemSubProcess,
                                                              },
                                                            },
                                                          ],
                                                        }
                                                      : processTypeGeneralItemViewX
                                                ),
                                              }
                                            : processTypeGeneralViewX
                                      )
                                    );
                                  }}
                                  size="sm"
                                  className="mx-1"
                                >
                                  <Add fontSize="inherit" />
                                </Button>
                                <Button
                                  variant="success"
                                  size="sm"
                                  className="mx-1"
                                  disabled={
                                    processTypeGeneralItemMove &&
                                    processTypeGeneralItemMoveIndex === j
                                  }
                                  onClick={() => {
                                    console.log(
                                      processTypeGeneralItemMove,
                                      i,
                                      processTypeGeneralItemMoveIndex
                                    );

                                    if (processTypeGeneralItemMove) {
                                      if (
                                        processTypeGeneralMoveIndex !== null &&
                                        processTypeGeneralItemMoveIndex !== null
                                      ) {
                                        const a =
                                          processTypeGenerals[
                                            processTypeGeneralMoveIndex
                                          ].processTypeGeneralItems[j];
                                        const b =
                                          processTypeGenerals[
                                            processTypeGeneralMoveIndex
                                          ].processTypeGeneralItems[
                                            processTypeGeneralItemMoveIndex
                                          ];

                                        processTypeGenerals[
                                          processTypeGeneralMoveIndex
                                        ].processTypeGeneralItems[j] = b;

                                        processTypeGenerals[
                                          processTypeGeneralMoveIndex
                                        ].processTypeGeneralItems[
                                          processTypeGeneralItemMoveIndex
                                        ] = a;

                                        setProcessTypeGeneralItemMove(false);
                                        setProcessTypeGeneralMoveIndex(null);
                                        setProcessTypeGeneralItemMoveIndex(
                                          null
                                        );
                                      }
                                    } else {
                                      setProcessTypeGeneralItemMove(true);
                                      setProcessTypeGeneralMoveIndex(i);
                                      setProcessTypeGeneralItemMoveIndex(j);
                                    }
                                  }}
                                >
                                  <List fontSize="inherit" />
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="mx-1"
                                  onClick={() => {
                                    setProcessTypeGenerals(
                                      processTypeGenerals.map(
                                        (processTypeGeneral, ix) =>
                                          ix === i
                                            ? {
                                                ...processTypeGeneral,
                                                processTypeGeneralItems: processTypeGeneral.processTypeGeneralItems.filter(
                                                  (_, jx) => j !== jx
                                                ),
                                              }
                                            : processTypeGeneral
                                      )
                                    );
                                    setProcessTypeGeneralItemDeleteIds([
                                      ...processTypeGeneralItemDeleteIds,
                                      processTypeGeneralItemView
                                        .processTypeGeneralItem?.id ?? 0,
                                    ]);
                                  }}
                                >
                                  <Delete fontSize="inherit" />
                                </Button>
                                <FormControl
                                  size="sm"
                                  value={
                                    processTypeGeneralItemView
                                      .processTypeGeneralItem?.name ?? ""
                                  }
                                  onChange={(e) => {
                                    setProcessTypeGenerals(
                                      processTypeGenerals.map(
                                        (processTypeGeneralViewX, ix) =>
                                          i === ix
                                            ? {
                                                ...processTypeGeneralViewX,
                                                processTypeGeneralItems: processTypeGeneralViewX.processTypeGeneralItems.map(
                                                  (
                                                    processTypeGeneralItemViewX,
                                                    jx
                                                  ) =>
                                                    j === jx
                                                      ? {
                                                          ...processTypeGeneralItemViewX,
                                                          processTypeGeneralItem: processTypeGeneralItemViewX.processTypeGeneralItem
                                                            ? {
                                                                ...processTypeGeneralItemViewX.processTypeGeneralItem,
                                                                name:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : processTypeGeneralItemViewX.processTypeGeneralItem,
                                                        }
                                                      : processTypeGeneralItemViewX
                                                ),
                                              }
                                            : processTypeGeneralViewX
                                      )
                                    );
                                  }}
                                />
                              </div>

                              <div className="my-1 ml-4">
                                <strong>Processes</strong>
                              </div>

                              {processTypeGeneralItemView.processTypeGeneralItemSubProcesses.map(
                                (processTypeGeneralItemSubProcess, k) => {
                                  return (
                                    <>
                                      <div className="d-flex align-items-center my-1 ml-5">
                                        {i + 1}.{j + 1}.{k + 1}{" "}
                                        <Button
                                          variant="success"
                                          size="sm"
                                          className="mx-1"
                                          disabled={
                                            processTypeGeneralItemSubProcessMove &&
                                            processTypeGeneralItemSubProcessMoveIndex ===
                                              k
                                          }
                                          onClick={() => {
                                            console.log(
                                              processTypeGeneralItemSubProcessMove,
                                              i,
                                              processTypeGeneralItemSubProcessMoveIndex
                                            );

                                            if (
                                              processTypeGeneralItemSubProcessMove
                                            ) {
                                              if (
                                                processTypeGeneralMoveIndex !==
                                                  null &&
                                                processTypeGeneralItemMoveIndex !==
                                                  null &&
                                                processTypeGeneralItemSubProcessMoveIndex !==
                                                  null
                                              ) {
                                                const a =
                                                  processTypeGenerals[
                                                    processTypeGeneralMoveIndex
                                                  ].processTypeGeneralItems[
                                                    processTypeGeneralItemMoveIndex
                                                  ]
                                                    .processTypeGeneralItemSubProcesses[
                                                    k
                                                  ];
                                                const b =
                                                  processTypeGenerals[
                                                    processTypeGeneralMoveIndex
                                                  ].processTypeGeneralItems[
                                                    processTypeGeneralItemMoveIndex
                                                  ]
                                                    .processTypeGeneralItemSubProcesses[
                                                    processTypeGeneralItemSubProcessMoveIndex
                                                  ];

                                                processTypeGenerals[
                                                  processTypeGeneralMoveIndex
                                                ].processTypeGeneralItems[
                                                  processTypeGeneralItemMoveIndex
                                                ].processTypeGeneralItemSubProcesses[
                                                  processTypeGeneralItemSubProcessMoveIndex
                                                ] = a;

                                                processTypeGenerals[
                                                  processTypeGeneralMoveIndex
                                                ].processTypeGeneralItems[
                                                  processTypeGeneralItemMoveIndex
                                                ].processTypeGeneralItemSubProcesses[
                                                  k
                                                ] = b;

                                                setProcessTypeGeneralItemSubProcessMove(
                                                  false
                                                );
                                                setProcessTypeGeneralMoveIndex(
                                                  null
                                                );
                                                setProcessTypeGeneralItemMoveIndex(
                                                  null
                                                );
                                                setProcessTypeGeneralItemSubProcessMoveIndex(
                                                  null
                                                );
                                              }
                                            } else {
                                              setProcessTypeGeneralItemSubProcessMove(
                                                true
                                              );
                                              setProcessTypeGeneralMoveIndex(i);
                                              setProcessTypeGeneralItemMoveIndex(
                                                j
                                              );
                                              setProcessTypeGeneralItemSubProcessMoveIndex(
                                                k
                                              );
                                            }
                                          }}
                                        >
                                          <List fontSize="inherit" />
                                        </Button>
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          className="mx-1"
                                          onClick={() => {
                                            setProcessTypeGenerals(
                                              processTypeGenerals.map(
                                                (processTypeGeneral, ix) =>
                                                  ix === i
                                                    ? {
                                                        ...processTypeGeneral,
                                                        processTypeGeneralItems: processTypeGeneral.processTypeGeneralItems.map(
                                                          (
                                                            processTypeGeneralItem,
                                                            jx
                                                          ) =>
                                                            jx === j
                                                              ? {
                                                                  ...processTypeGeneralItem,
                                                                  processTypeGeneralItemSubProcesses: processTypeGeneralItem.processTypeGeneralItemSubProcesses.filter(
                                                                    (_, kx) =>
                                                                      kx !== k
                                                                  ),
                                                                }
                                                              : processTypeGeneralItem
                                                        ),
                                                      }
                                                    : processTypeGeneral
                                              )
                                            );
                                            setProcessTypeGeneralItemSubProcessDeleteIds(
                                              [
                                                ...processTypeGeneralItemSubProcessDeleteIds,
                                                processTypeGeneralItemSubProcess
                                                  .processTypeGeneralItemSubProcess
                                                  ?.id ?? 0,
                                              ]
                                            );
                                          }}
                                        >
                                          <Delete fontSize="inherit" />
                                        </Button>
                                        <FormControl
                                          size="sm"
                                          value={
                                            processTypeGeneralItemSubProcess
                                              .processTypeGeneralItemSubProcess
                                              ?.name ?? ""
                                          }
                                          onChange={(e) => {
                                            setProcessTypeGenerals(
                                              processTypeGenerals.map(
                                                (processTypeGeneralViewX, ix) =>
                                                  i === ix
                                                    ? {
                                                        ...processTypeGeneralViewX,
                                                        processTypeGeneralItems: processTypeGeneralViewX.processTypeGeneralItems.map(
                                                          (
                                                            processTypeGeneralItemViewX,
                                                            jx
                                                          ) =>
                                                            j === jx
                                                              ? {
                                                                  ...processTypeGeneralItemViewX,
                                                                  processTypeGeneralItemSubProcesses: processTypeGeneralItemViewX.processTypeGeneralItemSubProcesses.map(
                                                                    (
                                                                      processTypeGeneralItemSubProcessX,
                                                                      kx
                                                                    ) =>
                                                                      k === kx
                                                                        ? {
                                                                            ...processTypeGeneralItemSubProcessX,
                                                                            processTypeGeneralItemSubProcess: processTypeGeneralItemSubProcessX.processTypeGeneralItemSubProcess
                                                                              ? {
                                                                                  ...processTypeGeneralItemSubProcessX.processTypeGeneralItemSubProcess,
                                                                                  name:
                                                                                    e
                                                                                      .target
                                                                                      .value,
                                                                                }
                                                                              : processTypeGeneralItemSubProcessX.processTypeGeneralItemSubProcess,
                                                                          }
                                                                        : processTypeGeneralItemSubProcessX
                                                                  ),
                                                                }
                                                              : processTypeGeneralItemViewX
                                                        ),
                                                      }
                                                    : processTypeGeneralViewX
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </>
                          );
                        }
                      )}
                    </div>
                    <hr className="border border-dark" />
                  </>
                );
              })}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default MechanicalProcessTypePage;
