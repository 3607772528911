import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Form, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  fetchDepartmentTemplates,
  makeReadableDateString,
  makeReadableDateStringShortIntl,
  PriorityMap,
} from "../../../helpers";
import { initialCrudInfoNoData } from "../../../models/CrudInfo";
import { IndividualEntity } from "../../../models/IndividualEntity";
import {
  DeadlineTemplateGroup,
  DeadlineTemplateGroupView,
  Job,
  PanelCode,
  PanelCodePlan,
} from "../../../models/model";
import {
  initialJob,
  initialPanelCode,
  initialPanelCodePlan,
} from "../../../models/modelinitials";
import { initialPageNoContent } from "../../../models/Page";
import { RequestStatus } from "../../../models/RequestStatus";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";
import Search from "../../mini-components/Search";
import { v4 as uuidv4 } from "uuid";
import { initialJobSimple } from "../../../models/JobSimple";
import { get, set } from "idb-keyval";

const MpsDetailed = () => {
  const appState = useContext(AppContext);
  const apiKey = appState?.apiKey ? appState.apiKey : "";
  const baseUrl = appState?.baseUrl ? appState.baseUrl : "";

  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    test: "",
    jobs: [] as Job[],
    requestStatus: RequestStatus.NotAsked,
    crudState: {
      ...initialCrudInfoNoData,
      rowsPerPage: 50,
      data: {
        ...initialPageNoContent,
        content: [] as Job[],
      },
    },
    customers: [] as Array<IndividualEntity>,
    customer: null as IndividualEntity | null,
    status: "false",
    priority: "",
  });

  const [deadlineStrNamesIdb, setDeadlineStrNamesIdb] = useState<string[]>([]);
  const [deadlineTemplateGroups, setDeadlineTemplateGroups] = useState<
    DeadlineTemplateGroupView[]
  >([]);

  const [selectedPanelCode, setSelectedPanelCode] = useState<PanelCode | null>(
    null
  );

  useEffect(() => {
    if (state.crudState.requestStatus === RequestStatus.NotAsked) {
      handleInitData(state);
    }
    fetchDeadlineStrNamesIdb();
  }, [state.crudState.requestStatus]);

  const fetchDeadlineStrNamesIdb = async () => {
    const deadlineNames = (await get("filter-names")) as string[];

    if (!deadlineNames) {
      await set("filter-names", []);
      setDeadlineStrNamesIdb([]);
    } else {
      setDeadlineStrNamesIdb(deadlineNames);
    }
  };

  const handleInitData = async (state: any) => {
    fetchJobMps(state);
    fetchDeadlineTemplateGroups();
  };

  const fetchDeadlineTemplateGroups = async () => {
    try {
      const resp = await fetch(
        `${appState?.baseUrl}/deadlinetemplategroups-view`,
        { headers: { authorization: appState?.apiKey ?? "" } }
      );

      if (resp.status !== 200) throw await resp.text();

      setDeadlineTemplateGroups(await resp.json());
    } catch (e) {
      console.error(e);
    }
  };

  const fetchJobMps = async (state: any) => {
    try {
      const url = `
        ${process.env.REACT_APP_BASE_URL}/jobs/search/paged/mps
        ?name=${encodeURI(state.crudState.searchInput)}
        &individualentityname=${encodeURI(
          state.customer ? state.customer.name : ""
        )}
        &page=${state.crudState.currentPage}
        &perPage=${state.crudState.rowsPerPage}
        &closed=${state.status}
        &priority=${state.priority}
      `
        .split(" ")
        .join("");

      const response = await fetch(url, {
        headers: { authorization: state.apiKey },
      });

      if (response.status !== 200) throw "Error fetching jobs";

      const jobsDataPage = await response.json();

      setState({
        ...state,
        crudState: {
          ...state.crudState,
          data: jobsDataPage,
        },
      });
    } finally {
    }
  };

  const handleSelectRowsPerPage = (rowsPerPage: number) => {
    const newState = {
      ...state,
      crudState: {
        ...state.crudState,
        currentPage: 0,
        rowsPerPage: rowsPerPage,
        requestStatus: RequestStatus.NotAsked,
      },
    };

    setState(newState);
    handleInitData(newState);
  };

  const deadlineNames = [
    "Drawing",
    "Program",
    "BOM",
    "Layout",
    "SLD",
    "Zplant",
    "FW/LO",
    "Schema",
    "TL",
    "WL",
    "BOM Elec",
    "Cutout",
    "Nameplate",
    "Mimik",
    "P",
    "B",
    "W",
    "G",
    "C",
    "A",
    "Grouping",
    "Tubing/Crimping",
    "Dinrail",
    "Cable/Support Duct",
    "Pasang Terminal",
    "Cutout Panel",
    "Component",
    "Labeling",
    "Mimik/Nameplate",
    "Potong",
    "Bending",
    "Lubang",
    "Baut",
    "Heatshrink",
    "Pasang",
    "WK",
    "LR",
    "Interkoneksi",
    "Closing",
    "Panel",
    "Point to Point",
    "Test On",
    "NC Mechanical",
    "NC Electrical",
    "Validasi",
  ];

  const ChevronLeftIcon = () => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-chevron-left"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
        />
      </svg>
    );
  };

  const ChevronRightIcon = () => {
    return (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        className="bi bi-chevron-right"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
        />
      </svg>
    );
  };

  const handleStrafePage = (page: "Prev" | "Next") => {
    switch (page) {
      case "Prev":
        const prevPage =
          state.crudState.currentPage - 1 < 0
            ? 0
            : state.crudState.currentPage - 1;

        setState({
          ...state,
          crudState: {
            ...state.crudState,
            currentPage: prevPage,
            requestStatus: RequestStatus.NotAsked,
          },
        });
        break;

      case "Next":
        const nextPage =
          state.crudState.currentPage + 1 > state.crudState.data.totalPages - 1
            ? state.crudState.data.totalPages - 1
            : state.crudState.currentPage + 1;

        setState({
          ...state,
          crudState: {
            ...state.crudState,
            currentPage: nextPage,
            requestStatus: RequestStatus.NotAsked,
          },
        });
        break;
    }
  };

  const ProcessGroup = (props: { colSpan: number; label: string }) => {
    return (
      <th
        className="text-light text-center"
        colSpan={props.colSpan}
        style={{ whiteSpace: "nowrap" }}
      >
        <div className="p-1" style={{}}>
          {props.label}
        </div>
      </th>
    );
  };

  const handleClosePanelCode = async (jobId: number, panelCode: PanelCode) => {
    // const confirmation = window.confirm(`Are you sure you want to close panel ${panelCode.type} - ${panelCode.name} | SN   ${panelCode.serialNumber}?`);

    // if (confirmation) {
    try {
      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Loading,
        },
      });

      const response = await fetch(`${baseUrl}/panelcodes`, {
        method: "POST",
        headers: {
          authorization: apiKey,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...panelCode,
          closed: true,
          job: {
            ...initialJob,
            id: jobId,
          },
        } as PanelCode),
      });

      if (response.status !== 201) throw "Error saving panelcode closed status";
      fetchJobMps(state);
    } catch (e) {
      console.error(e);
    }
    // }
  };

  const handleChangeActual = async (
    panelCode: PanelCode,
    panelCodePlan: PanelCodePlan,
    date: string
  ) => {
    if (panelCodePlan) {
      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Loading,
        },
      });

      try {
        console.log("Panel code plan:", panelCodePlan);

        const response = await fetch(`${appState?.baseUrl}/panelcodeplans`, {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: apiKey,
          },
          body: JSON.stringify({
            ...panelCodePlan,
            finishedDate: date,
            panelCode: panelCode,
          }),
        });

        console.log("[SETTING STATE]");

        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.NotAsked,
          },
        });
      } catch (e) {
        console.error(e);
        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.Error,
          },
        });
      }
    }
  };

  const handleUndoActual = async (
    panelCode: PanelCode,
    panelCodePlan: PanelCodePlan | null | undefined
  ) => {
    if (panelCodePlan) {
      try {
        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.Loading,
          },
        });

        console.log("Panel code plan:", panelCodePlan);

        const response = await fetch(`${appState?.baseUrl}/panelcodeplans`, {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: apiKey,
          },
          body: JSON.stringify({
            ...panelCodePlan,
            finishedDate: null,
            panelCode: panelCode,
          }),
        });

        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.NotAsked,
          },
        });
      } catch (e) {
        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.Loading,
          },
        });
      }
    }
  };

  const handleUndoPanelCodeClose = async (job: Job, panelCode: PanelCode) => {
    if (panelCode) {
      try {
        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.Loading,
          },
        });

        const response = await fetch(`${appState?.baseUrl}/panelcodes`, {
          method: "post",
          headers: {
            authorization: apiKey,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            ...panelCode,
            closed: false,
            job: { ...initialJob, id: job.id },
          }),
        });

        setState({
          ...state,
          crudState: {
            ...state.crudState,
            requestStatus: RequestStatus.NotAsked,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  const savePanelCodePlan = async (
    panelCode: PanelCode,
    deadlineName: string,
    plan: string
  ) => {
    try {
      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Loading,
        },
      });

      const resp = await fetch(`${appState?.baseUrl}/panelcodeplans`, {
        method: "post",
        headers: {
          authorization: appState?.apiKey ?? "",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          ...initialPanelCodePlan,
          uuid: uuidv4(),
          panelCode: panelCode,
          name: deadlineName,
          date: plan,
        }),
      });

      if (resp.status !== 201) throw await resp.text();

      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.NotAsked,
        },
      });
    } catch (e) {
      console.error(e);

      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Error,
        },
      });
    }
  };

  const handleEmptyPanelCodePlan = async (panelCodePlan: PanelCodePlan) => {
    try {
      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Loading,
        },
      });

      const resp = await fetch(
        `${appState?.baseUrl}/panelcodeplans/${panelCodePlan.id}/empty`,
        {
          method: "delete",
          headers: {
            authorization: appState?.apiKey ?? "",
          },
        }
      );

      if (resp.status !== 200) throw await resp.text();

      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.NotAsked,
        },
      });
    } catch (e) {
      console.error(e);

      setState({
        ...state,
        crudState: {
          ...state.crudState,
          requestStatus: RequestStatus.Error,
        },
      });
    }
  };

  return (
    <div>
      {/* <div className="d-flex">
        <h3>MPS Detailed</h3>
        {state.requestStatus === RequestStatus.Loading ? <Spinner animation="border" variant="primary" /> : <></>}
      </div> */}
      <div className="d-flex flex-wrap align-items-center">
        <div>
          {state.crudState.requestStatus === RequestStatus.Loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <></>
          )}
        </div>
        {/* <div className="flex-grow-1">
          
        </div> */}
        <div className="my-2 mr-2 flex-grow-1">
          <Search
            onPressEnter={(text) => {
              const newState = {
                ...state,
                crudState: {
                  ...state.crudState,
                  searchInput: text,
                  requestStatus: RequestStatus.NotAsked,
                },
              };
              setState(newState);
              handleInitData(newState);
            }}
          />
          {/* <Form.Control
            type="text" 
            placeholder="Filter by job name..."
            value={state.crudState.searchInput}
            onChange={e => setState({ 
              ...state, 
              crudState: { 
                ...state.crudState, 
                searchInput: e.target.value,
                requestStatus: RequestStatus.NotAsked
              } 
            })}
          /> */}
        </div>
        <div className="mx-2 flex-grow-1" style={{ zIndex: 3 }}>
          {/* <Select
            options={[]}
            placeholder="Filter by customer..."
          /> */}
          <AsyncSelectWrapper
            label="Customer"
            url={`${process.env.REACT_APP_BASE_URL}/individualentities/search`}
            param="name"
            value={state.customer}
            placeholder="Filter by customer..."
            onChange={(selected) => {
              const newState = {
                ...state,
                customer: selected,
                crudState: {
                  ...state.crudState,
                  requestStatus: RequestStatus.NotAsked,
                },
              };
              setState(newState);
              handleInitData(newState);
            }}
          />
        </div>
        <div className="mx-2 flex-grow-1" style={{ zIndex: 3 }}>
          <Select
            options={[
              { label: "All", value: "" },
              { label: "Closed", value: "true" },
              { label: "Outstanding", value: "false" },
            ]}
            placeholder="Filter by status..."
            onChange={(selected) => {
              const newState = {
                ...state,
                status: (selected as { label: string; value: string }).value,
                crudState: {
                  ...state.crudState,
                  requestStatus: RequestStatus.NotAsked,
                },
              };
              setState(newState);
              handleInitData(newState);
            }}
          />
        </div>
        <div className="mx-2 flex-grow-1" style={{ zIndex: 3 }}>
          <Select
            options={[
              { label: "All", value: "" },
              { label: "High", value: "High" },
              { label: "Medium", value: "Medium" },
              { label: "Low", value: "Low" },
            ]}
            placeholder="Filter by priority..."
            onChange={(selected) =>
              setState({
                ...state,
                priority: (selected as { label: string; value: string }).value,
                crudState: {
                  ...state.crudState,
                  requestStatus: RequestStatus.NotAsked,
                },
              })
            }
          />
        </div>
      </div>
      <div className="d-flex flex-wrap">
        {/* <pre>{JSON.stringify(deadlineStrNamesIdb)}</pre> */}
        {deadlineStrNamesIdb.map((d) => (
          <div
            className="p-1 border border-dark"
            style={{ cursor: "pointer" }}
            onClick={async () => {
              const newDeadlineStrNamesIdb = deadlineStrNamesIdb.filter(
                (dx) => dx !== d
              );

              await set("filter-names", newDeadlineStrNamesIdb);
              setDeadlineStrNamesIdb(newDeadlineStrNamesIdb);
            }}
          >
            <strong>{d}</strong>
          </div>
        ))}
      </div>
      <div
        style={{ overflow: "auto", height: "75vh", resize: "vertical" }}
        className="border border-dark"
      >
        <Table bordered size="sm" style={{ borderCollapse: "separate" }}>
          <thead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "darkblue",
            }}
          >
            <tr>
              <th
                rowSpan={2}
                className="text-light text-center align-middle"
                style={{
                  position: "sticky",
                  left: 0,
                  backgroundColor: "darkblue",
                  zIndex: 2,
                }}
              >
                <div className="p-1" style={{ width: 300 }}>
                  Job
                </div>
              </th>
              <th
                rowSpan={2}
                className="text-light text-center align-middle"
                style={{
                  position: "sticky",
                  left: 313,
                  backgroundColor: "darkblue",
                  zIndex: 2,
                }}
              >
                <div className="p-1" style={{ width: 200 }}>
                  Panel Info
                </div>
              </th>

              {/* {
                // [
                //   { label: "Engineering Mechanical Document", colSpan: 3 },
                //   { label: "Engineering Electrical Document", colSpan: 11 },
                //   { label: "Production Process", colSpan: 6 },
                //   { label: "Preparation Cable", colSpan: 2 },
                //   { label: "Preparation Panel", colSpan: 7 },
                //   { label: "Busbar", colSpan: 6 },
                //   { label: "Interkoneksi", colSpan: 4 },
                //   { label: "QC", colSpan: 6 },
                // ]
                deadlineTemplateGroups
                  .map((deadlineTemplateGroup) => ({
                    label:
                      deadlineTemplateGroup.deadlineTemplateGroup?.name ?? "",
                    colSpan:
                      deadlineTemplateGroup.deadlineTemplates.filter((d) =>
                        deadlineStrNamesIdb.filter(
                          (dx) => dx !== d.deadlineTemplate?.name
                        )
                      ).length ?? 0,
                  }))
                  .map((processGroupHeader) => (
                    <ProcessGroup
                      label={processGroupHeader.label}
                      colSpan={processGroupHeader.colSpan}
                    />
                  ))
              } */}
            </tr>
            <tr>
              {deadlineTemplateGroups
                .map((deadlineTemplateGroup) => {
                  const filteredDeadlineTemplates =
                    deadlineTemplateGroup.deadlineTemplates.filter(
                      (dT) =>
                        !deadlineStrNamesIdb.find(
                          (dTX) => dTX === dT.deadlineTemplate?.name
                        )
                    );

                  return filteredDeadlineTemplates.map((dT) => {
                    return (
                      <th
                        className="text-light text-center"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <div>
                          <div>
                            <small>
                              {deadlineTemplateGroup?.deadlineTemplateGroup?.name
                                ?.split(" ")
                                .map((s) => s.substring(0, 3))
                                .join(" ")}
                            </small>
                          </div>
                          <div
                            className="p-1"
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              if (
                                !deadlineStrNamesIdb.find(
                                  (n) => n === dT.deadlineTemplate?.name
                                )
                              ) {
                                const newDeadlineStrNamesIdb = [
                                  ...deadlineStrNamesIdb,
                                  dT.deadlineTemplate?.name ?? "",
                                ];

                                await set(
                                  "filter-names",
                                  newDeadlineStrNamesIdb
                                );
                                setDeadlineStrNamesIdb(newDeadlineStrNamesIdb);
                              }
                            }}
                          >
                            {dT.deadlineTemplate?.name?.substring(0, 5)}
                          </div>
                        </div>
                      </th>
                    );
                  });
                })
                .flat()}
            </tr>
          </thead>
          <tbody>
            {state.crudState.data.content.map((job, i) => {
              return (
                <>
                  <tr>
                    <td
                      className="align-middle"
                      rowSpan={
                        (job.panelCodes?.filter((pc) => !pc.closed)?.length ??
                          0) + 1
                      }
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "white",
                        border: "1px solid black",
                      }}
                    >
                      <div
                        className="overflow-auto"
                        style={{
                          backgroundColor: "white",
                          margin: 0,
                          padding: 5,
                          width: 300,
                        }}
                      >
                        <div className="d-flex">
                          <div style={{ whiteSpace: "nowrap" }}>
                            <Link to={`/mpsrev/${job.id}`}>{job.name}</Link>
                          </div>
                          <div>
                            <strong>{job.individualEntity?.name}</strong>
                          </div>
                        </div>

                        <div className="d-flex align-items-center">
                          <div style={{ whiteSpace: "nowrap" }}>
                            Done 0/{job.qty} (
                            {job.deliveryDeadline
                              ? makeReadableDateStringShortIntl(
                                  new Date(job.deliveryDeadline)
                                )
                              : ""}
                            )
                          </div>
                          <div>
                            <PriorityMap priority={job.priority} />
                          </div>
                          <Link to={`/jobs/${job.id}/remarks`}>
                            <Button
                              variant={
                                (job.jobRemarks?.length ?? 0) > 0
                                  ? "danger"
                                  : "primary"
                              }
                              size="sm"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Remarks ({job.jobRemarks?.length})
                            </Button>
                          </Link>
                          <Link to={`/jobs/${job.id}/nc`}>
                            <Button variant="outline-primary" size="sm">
                              NC
                            </Button>
                          </Link>
                        </div>

                        <div>
                          <ol>
                            {job.panelCodes
                              ?.filter((pc) => pc.closed)
                              .map((pc) => {
                                return (
                                  <>
                                    <li>
                                      <div
                                        style={{ cursor: "pointer" }}
                                        className="d-flex my-1"
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              `Really undo ${pc.type}, ${pc.name}, ${pc.serialNumber}?`
                                            )
                                          ) {
                                            handleUndoPanelCodeClose(job, pc);
                                          }
                                        }}
                                      >
                                        <small>
                                          {pc.type}, {pc.name},{" "}
                                          {pc.serialNumber}
                                        </small>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                          </ol>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {job.panelCodes
                    ?.filter((pc) => !pc.closed)
                    ?.map((panelCode, j) => (
                      <tr>
                        <td
                          className={`align-middle ${
                            panelCode.closed
                              ? "bg-success text-white"
                              : "bg-light"
                          }`}
                          style={{
                            position: "sticky",
                            left: 313,
                            border: "1px solid black",
                          }}
                        >
                          <div
                            className={`overflow-auto d-flex ${
                              panelCode.closed ? "bg-success text-white" : ""
                            }`}
                            style={{ width: 200 }}
                          >
                            <div
                              style={{ whiteSpace: "nowrap" }}
                              // style={{ backgroundColor: "white" }}
                            >
                              {panelCode.name}
                              {" - "}
                              {panelCode.type}

                              {" | "}
                              <strong>
                                {panelCode.serialNumber &&
                                panelCode.serialNumber !== "" ? (
                                  `SN ${panelCode.serialNumber}`
                                ) : (
                                  <span style={{ color: "red" }}>No SN</span>
                                )}
                              </strong>
                            </div>

                            <div className="d-flex">
                              <div>
                                <div>
                                  {panelCode.closed ? (
                                    <></>
                                  ) : (
                                    <div>
                                      <Button
                                        className="btn-sm"
                                        variant="outline-primary"
                                        onClick={() =>
                                          handleClosePanelCode(
                                            job.id ?? 0,
                                            panelCode
                                          )
                                        }
                                      >
                                        Close
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex justify-content-center">
                                  {panelCode.closed ? (
                                    <Button
                                      size="sm"
                                      onClick={() => {
                                        if (window.confirm("Really undo?"))
                                          handleUndoPanelCodeClose(
                                            job,
                                            panelCode
                                          );
                                      }}
                                    >
                                      Undo
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>

                              <div className="mx-2">
                                <Button
                                  disabled={
                                    selectedPanelCode &&
                                    selectedPanelCode.id === panelCode.id
                                      ? true
                                      : false
                                  }
                                  onClick={async () => {
                                    if (selectedPanelCode) {
                                      // Empty current panel codes first
                                      await Promise.all(
                                        panelCode.panelCodePlans.map(
                                          async (panelCodePlan) => {
                                            return await fetch(
                                              `${appState?.baseUrl}/panelcodeplans/${panelCodePlan.id}/empty`,
                                              {
                                                method: "delete",
                                                headers: {
                                                  authorization:
                                                    appState?.apiKey ?? "",
                                                },
                                              }
                                            );
                                          }
                                        )
                                      );

                                      // Copy panel code
                                      await Promise.all(
                                        selectedPanelCode.panelCodePlans.map(
                                          async (panelCodePlan) => {
                                            return await fetch(
                                              `${appState?.baseUrl}/panelcodeplans`,
                                              {
                                                method: "post",
                                                headers: {
                                                  authorization:
                                                    appState?.apiKey ?? "",
                                                  "content-type":
                                                    "application/json",
                                                },
                                                body: JSON.stringify({
                                                  ...panelCodePlan,
                                                  id: 0,
                                                  uuid: uuidv4(),
                                                  panelCode: {
                                                    ...initialPanelCode,
                                                    id: panelCode.id,
                                                  },
                                                }),
                                              }
                                            );
                                          }
                                        )
                                      );

                                      // Set reqstatus to notasked to repeat
                                      setState({
                                        ...state,
                                        crudState: {
                                          ...state.crudState,
                                          requestStatus: RequestStatus.NotAsked,
                                        },
                                      });

                                      setSelectedPanelCode(null);
                                    } else {
                                      setSelectedPanelCode(panelCode);
                                    }
                                  }}
                                  variant="outline-success"
                                  size="sm"
                                >
                                  {selectedPanelCode ? "Here" : "Copy"}
                                </Button>
                              </div>
                            </div>
                          </div>

                          {/* <div>
                            <strong>
                              Planned: {panelCode.panelCodePlans.length}/
                              {deadlineNames.length}
                            </strong>
                          </div>
                          <div>
                            <strong
                              className={`${
                                (panelCode.panelCodePlans.filter(
                                  (panelCodePlan) => panelCodePlan.finishedDate
                                ).length ?? 0) <
                                  (panelCode.panelCodePlans.length ?? 0) ||
                                panelCode.panelCodePlans.length === 0
                                  ? "text-danger"
                                  : "text-success"
                              } `}
                            >
                              Done:{" "}
                              {
                                panelCode.panelCodePlans.filter(
                                  (panelCodePlan) => panelCodePlan.finishedDate
                                ).length
                              }
                              /{panelCode.panelCodePlans.length}
                            </strong>
                          </div> */}
                        </td>
                        {deadlineNames
                          .filter(
                            (d) => !deadlineStrNamesIdb.find((dx) => dx === d)
                          )
                          .map((deadlineName) => {
                            const foundDeadline = job.deadlines
                              ?.map((deadline) => deadline.deadlineDetails)
                              .flat()
                              .find(
                                (deadlineDetail) =>
                                  deadlineDetail?.name === deadlineName
                              );

                            const newPanelCodePlans = [
                              ...panelCode.panelCodePlans,
                            ];
                            newPanelCodePlans.reverse();
                            const foundPlan = newPanelCodePlans.find(
                              (panelCodePlan) =>
                                panelCodePlan.name === deadlineName
                            );

                            const foundActual = foundPlan?.finishedDate;

                            return (
                              <td
                                style={{
                                  whiteSpace: "nowrap",
                                  border: "2px solid black",
                                  backgroundColor:
                                    foundPlan?.date || foundActual
                                      ? foundActual && foundPlan?.date
                                        ? new Date(foundActual).getTime() >
                                          new Date(foundPlan.date).getTime()
                                          ? "lightcoral"
                                          : "palegreen"
                                        : "white"
                                      : "black",
                                }}
                                className="text-center align-middle p-0"
                              >
                                <div className="d-flex align-items-center p-2">
                                  P -{" "}
                                  {foundPlan?.date ? (
                                    <div
                                      className="d-flex"
                                      onClick={() => {
                                        if (!foundActual) {
                                          if (window.confirm("Really undo?")) {
                                            handleEmptyPanelCodePlan(foundPlan);
                                          }
                                        }
                                      }}
                                      style={{
                                        cursor: foundActual
                                          ? undefined
                                          : "pointer",
                                      }}
                                    >
                                        <strong>
                                          {makeReadableDateStringShortIntl(
                                            new Date(foundPlan.date)
                                          )}
                                        </strong>
                                    </div>
                                  ) : (
                                    <>
                                      <input
                                        style={{ maxWidth: 100 }}
                                        className="form-control form-control-sm"
                                        placeholder="Date"
                                        type="date"
                                        onBlur={(e) => {
                                          // console.log('Date', date)

                                          savePanelCodePlan(
                                            panelCode,
                                            deadlineName,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                                <hr style={{ border: "1px solid lightblue" }} />
                                <div className="d-flex align-items-center p-2">
                                  A -{" "}
                                  {foundActual ? (
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (window.confirm("Really undo?")) {
                                          handleUndoActual(
                                            panelCode,
                                            foundPlan
                                          );
                                        }
                                      }}
                                    >
                                        <strong>
                                          {makeReadableDateStringShortIntl(
                                            new Date(foundActual)
                                          )}
                                        </strong>
                                    </div>
                                  ) : foundPlan ? (
                                    <input
                                      style={{ maxWidth: 100 }}
                                      type="date"
                                      className="form-control form-control-sm"
                                      onBlur={(e: any) =>
                                        handleChangeActual(
                                          panelCode,
                                          foundPlan,
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            );
                          })}
                      </tr>
                    ))}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex">
        <div className="d-flex align-items-center">
          <div style={{ marginRight: 5 }}>Show per page:</div>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              {state.crudState.rowsPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[10, 25, 50, 100].map((rowsPerPage) => (
                <Dropdown.Item
                  onClick={() => handleSelectRowsPerPage(rowsPerPage)}
                >
                  {rowsPerPage}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="mx-1">
            <strong>
              {state.crudState.currentPage + 1}/
              {state.crudState.data.totalPages}
            </strong>
          </div>
          <div className="mx-1">
            <Button onClick={() => handleStrafePage("Prev")}>
              <ChevronLeftIcon />
            </Button>
            <Button onClick={() => handleStrafePage("Next")}>
              <ChevronRightIcon />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MpsDetailed;
