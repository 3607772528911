import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import {
  MasterJavaBaseModel,
  PpicSimplifiedProcessTypeMultiplier,
  PpicSimplifiedProcessTypeMultipliers,
  PpicSimplifiedProcessTypes,
} from "../../masterbigsystem";
import {
  fetchSimplifiedProcessTypeMultipliersProto,
  fetchSimplifiedProcessTypesProto,
} from "../../helpers";
import { v4 } from "uuid";

const ProgramTreeMultiplierPage = () => {
  const ctx = useContext(AppContext);

  const [, refresh] = useState(false);

  const simplifiedProcessTypes = useRef(
    PpicSimplifiedProcessTypes.fromPartial({})
  );
  const multipliers = useRef(
    PpicSimplifiedProcessTypeMultipliers.fromPartial({ multipliers: [] })
  );

  const render = () => {
    refresh((n) => !n);
  };

  const fetchSimplifiedProcessTypesData = async () => {
    const d = await fetchSimplifiedProcessTypesProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      simplifiedProcessTypes.current = d;
      render();
    }
  };

  const fetchSimplifiedProcessTypeMultipliersData = async () => {
    const d = await fetchSimplifiedProcessTypeMultipliersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      multipliers.current = d;
      render();
    }
  };

  const handleInit = async () => {
    fetchSimplifiedProcessTypesData();
    fetchSimplifiedProcessTypeMultipliersData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/simplifiedprocesstypemultipliers-proto-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(multipliers.current),
        }
      );

      if (resp.status !== 200) {
        const t = await resp.text();

        alert("Save failed");

        return await t;
      }

        window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <h4>Program Tree Multiplier</h4>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div
          className="border border-dark overflow-auto"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "Process Type", "Multiplier"].map((h) => {
                return (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                );
              })}
            </tr>
            {simplifiedProcessTypes.current.processTypes.map((p, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">{p.name}</td>
                    <td className="border border-dark">
                      <input
                        value={
                          multipliers.current.multipliers.find(
                            (m) =>
                              `${m.simplifiedProcessTypeId}` ===
                              `${p.masterJavaBaseModel?.id}`
                          )?.multiplier
                        }
                        className="form-control form-control-sm"
                        onBlur={(e) => {
                          if (!isNaN(parseFloat(e.target.value))) {
                            const foundMultiplier =
                              multipliers.current.multipliers.find(
                                (m) =>
                                  `${m.simplifiedProcessTypeId}` ===
                                  `${p.masterJavaBaseModel?.id}`
                              );

                            if (foundMultiplier) {
                              foundMultiplier.multiplier = parseFloat(
                                e.target.value
                              );
                              render();
                              return;
                            }

                            multipliers.current.multipliers.push(
                              PpicSimplifiedProcessTypeMultiplier.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: v4(),
                                  }),
                                simplifiedProcessTypeId:
                                  p.masterJavaBaseModel?.id,
                                multiplier: parseFloat(e.target.value),
                              })
                            );

                            render();
                          }
                        }}
                        placeholder="Multiplier..."
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default ProgramTreeMultiplierPage;
