import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  categoryPartNums,
  fetchExtInventory,
  fetchExtPNDouble,
  fetchItems,
  fetchWarehouseItemsProto,
} from "../../../helpers";
import { ExtInventory } from "../../../models/model";
import { WarehouseItems } from "../../../masterbigsystem";

const DoublePNReportPage = () => {
  const ctx = useContext(AppContext);

  const doublePNs = useRef([] as any[]);
  const doublePNsLoading = useRef(false);
  const [, refresh] = useState(false);

  const inventory = useRef([] as ExtInventory[]);
  const items = useRef(WarehouseItems.fromPartial({ items: [] }));

  const doublePNsMapped = doublePNs.current.map((d) => {
    const itemsFiltered = items.current.items.filter(
      (i) => `${i.partNum}` === `${d?.pn}`
    );

    const inventoryFiltered = inventory.current.filter((inv) =>
      itemsFiltered.find((ix) => `${ix.id}` === `${inv.productId}`)
    );

    return {
      pn: d,
      itemsFiltered: itemsFiltered,
      inventoryFiltered: inventoryFiltered,
    };
  });

  const render = () => {
    refresh((n) => !n);
  };

  const fetchDoublePNsData = async () => {
    doublePNsLoading.current = true;
    render();

    const d = await fetchExtPNDouble({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      doublePNs.current = d;
    }

    doublePNsLoading.current = false;

    render();
  };
  const fetchInventoryData = async () => {
    const d = await fetchExtInventory({ apiKey: ctx?.apiKey ?? "", all: true });

    if (d) {
      inventory.current = d;
    }

    render();
  };
  const fetchItemsData = async () => {
    const d = await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      items.current = d;
    }

    render();
  };

  const filterPN = useRef("");

  const handleInit = async () => {
    fetchInventoryData();
    fetchItemsData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  return (
    <>
      <div>
        <div>
          <div>
            <h4>PN Duplicate</h4>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          {doublePNsLoading.current ? (
            <>
              <div className="d-flex">
                <div className="spinner-border spinner-border-sm"></div>
                <div>Loading...</div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="d-flex">
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                fetchDoublePNsData();
              }}
            >
              Get Data
            </button>
          </div>

          <div className="mx-2">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/ext-pn-double?excel=true`}
            >
              <button className="btn btn-sm btn-success">Get Excel</button>
            </a>
          </div>
        </div>

        <div>
          <strong>
            {
              doublePNsMapped.filter(
                (d) =>
                  // Filter where not hidden (hidden != 1) is still more than one
                  d.itemsFiltered.filter((i) => i.hidden !== 1).length > 1
              ).length
            }
          </strong>{" "}
          item(s) have duplicate PN.
        </div>

        <div>
          <hr className="border border-dark" />
        </div>

        <div>
          <input
            className="form-control form-control-sm"
            placeholder="Filter PN..."
            onBlur={(e) => {
              filterPN.current = e.target.value;
              render();
            }}
          />
        </div>

        <ol>
          {doublePNsMapped
            .filter((d) => {
              if (filterPN.current === "") {
                return true;
              }

              return filterPN.current === d?.pn?.pn;
            })
            .map((d) => {
              return (
                <>
                  <li>
                    <div>
                      <div>
                        <strong
                          className={`font-weight-bold text-light ${
                            // Filter where not hidden (hidden != 1) is still more than one
                            d.itemsFiltered.filter((i) => i.hidden !== 1)
                              .length > 1
                              ? `text-danger`
                              : `text-success`
                          }`}
                        >
                          {d?.pn?.pn}: {d?.pn?.amount} duplicates.
                        </strong>
                      </div>
                      <div>
                        <div>
                          <strong>Item</strong>
                        </div>
                        <div>
                          <table
                            className="table table-sm"
                            style={{ borderCollapse: "separate" }}
                          >
                            <tr>
                              {[
                                "#",
                                "ID",
                                "MFR",
                                "PN",
                                "PartName",
                                "Desc",
                                "Category PartNum",
                                "Hidden",
                              ].map((h) => {
                                return (
                                  <>
                                    <th className="bg-dark text-light p-0 m-0">
                                      {h}
                                    </th>
                                  </>
                                );
                              })}
                            </tr>
                            {d?.itemsFiltered.map((i, i_) => {
                              return (
                                <>
                                  <tr>
                                    <td className="border border-dark p-0 m-0">
                                      {i_ + 1}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.id}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.mfr}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.partNum}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.partName}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.partDesc}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {
                                        categoryPartNums.find(
                                          (p) =>
                                            `${p.value}` ===
                                            `${i.categoryPartNum}`
                                        )?.label
                                      }
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={
                                            `${i?.hidden}` === `${1}`
                                              ? true
                                              : false
                                          }
                                        />
                                        h{i.hidden}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                      <div>
                        <div>
                          <strong>Inventory</strong>
                        </div>
                        <div>
                          <table
                            className="table table-sm"
                            style={{ borderCollapse: "separate" }}
                          >
                            <tr>
                              {[
                                "#",
                                "ID",
                                "PN",
                                "MFR",
                                "PartName",
                                "Desc",
                                "WH",
                                "Qty",
                                "FreeStock",
                                "Category PartNum",
                              ].map((h) => {
                                return (
                                  <>
                                    <th className="bg-dark text-light p-0 m-0">
                                      {h}
                                    </th>
                                  </>
                                );
                              })}
                            </tr>
                            {d?.inventoryFiltered.map((i, i_) => {
                              return (
                                <>
                                  <tr>
                                    <td className="border border-dark p-0 m-0">
                                      {i_ + 1}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.productId}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.products?.mfr}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.products?.partNum}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.products?.partName}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.products?.partDesc}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.warehouse?.warehouse?.name}:
                                      {i.warehouse?.warehouse?.name}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.qty}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {i.balanceRsv}
                                    </td>
                                    <td className="border border-dark p-0 m-0">
                                      {
                                        categoryPartNums.find(
                                          (p) =>
                                            `${p.value}` ===
                                            `${i.products?.categoryPartNum}`
                                        )?.label
                                      }
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </table>
                        </div>
                      </div>

                      <div>
                        <hr className="border border-dark" />
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
        </ol>
      </div>
    </>
  );
};

export default DoublePNReportPage;
