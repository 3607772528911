import {
  Box,
  List,
  ListItem,
  styled,
  TableBody,
  TableCell,
  TextField,
} from "@material-ui/core";
import { lightBlue, yellow } from "@material-ui/core/colors";
import { Add, Delete } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import InfoIcon from "@material-ui/icons/Info";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../App";
import { secondsToHms } from "../../../helpers";
import {
  FepDetail,
  FepDocument,
  FepMulti,
  FepMultiType,
  Item,
  MaterialType,
  PartToPart,
  PartToPartDetail,
  ProcessType,
  Ral,
} from "../../../models/model";
import {
  initialFepDetail,
  initialFepDocument,
  initialFepMulti,
  initialPartToPartDetail,
  intialPartToPart,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const FepMultiForm = () => {
  const { id } = useParams() as { id: string };
  const appState = useContext(AppContext);
  const apiKey = appState?.apiKey ? appState.apiKey : "";

  const [state, setState] = useState({
    hoverUuid: "",
    oldName: "",
    nameChanged: false,
    partToPartModalShow: false,
    fepMultiModalShow: false,
    cutoutModalShow: false,
    newFepMulti: { ...initialFepMulti } as FepMulti,
    newPartToPart: { ...intialPartToPart } as PartToPart,
    newPartToPartDetail: { ...initialPartToPartDetail } as PartToPartDetail,
    newFepMultiItemPartNameToCheck: "",
    newPartToPartDetailItemPartNameToCheck: "",
    selectedFepMultiParent: null as FepMulti | null,
  });
  const [fep, setFep] = useState<FepDocument | null>(null);
  const [fepDebug, setFepDebug] = useState<string>("");
  const [treeView, setTreeView] = useState<boolean>(false);

  const [timeHour, setTimeHour] = useState(0);
  const [timeMins, setTimeMins] = useState(0);
  const [timeSecs, setTimeSecs] = useState(0);

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [openNote, setOpenNote] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Extension
  const [extend, setExtend] = React.useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const parsedId = parseInt(id ? id : "new");

    if (isNaN(parsedId)) {
      console.log("new form");
    } else {
      console.log("edit form");
      fetchFep(parsedId);
    }
  }, []);

  const fetchFep = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fepdocuments/${id}/full`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) {
        setRequestStatus(RequestStatus.Error);
        throw "Failed fetchin FEP multi";
      }

      const fep = (await response.json()) as FepDocument;
      setFep(fep);
      setFepDebug(JSON.stringify(fep, undefined, 2));
      setRequestStatus(RequestStatus.Success);
      setState({
        ...state,
        oldName: fep.name,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleImport = async (evt: any) => {
    const formData = new FormData();
    formData.append("file", evt.target.files[0]);

    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fepdocuments/import`,
        {
          method: "POST",
          headers: {
            authorization: apiKey ? apiKey : "",
          },
          body: formData,
        }
      );

      if (response.status !== 200) {
        setRequestStatus(RequestStatus.Error);
        const errorMsg: FepDocument | null | undefined = await (async () => {
          try {
            return await response.json();
          } catch (e) {
            return null;
          }
        })();

        throw errorMsg
          ? errorMsg.name
          : "Import failed. Error unknown. Please try to log out and log back in again.";
      }

      const responseJson = await response.json();

      setFep(responseJson);
      setFepDebug(JSON.stringify(responseJson, undefined, 2));
      console.log("response json:", responseJson);

      setRequestStatus(RequestStatus.Success);
      setErrorMessage("");
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
      setErrorMessage(`${e}`);
      console.log(e);
    }
  };

  const handleExtendFep = async (evt: any) => {
    // Match FepDocumentExtension type:
    // {
    //   file: MultipartFile,
    //   parentId: Long,
    //   fepDocument: FepDocument
    // }
    if (fep) {
      const formData = new FormData();
      formData.append("file", evt.target.files[0]);

      // post extension to server
      try {
        setRequestStatus(RequestStatus.Loading);

        const response = await fetch(
          `${
            process.env.REACT_APP_BASE_URL
          }/fepdocuments/importextension?parentId=${fep.id.toString()}&fepDocumentId=${fep.id.toString()}`,
          {
            method: "POST",
            headers: {
              authorization: apiKey ? apiKey : "",
            },
            body: formData,
          }
        );

        if (response.status !== 200) throw "Error adding extension";

        const fepDocumentJson = await response.json();
        setFep({ ...fepDocumentJson });
        setRequestStatus(RequestStatus.Success);
      } catch (e) {
        console.error(e);
        setRequestStatus(RequestStatus.Error);
      }

      setExtend(true);
    }
  };

  const FepMultiView = (props: {
    fepMulti: FepMulti;
    parentIndex: number[];
  }) => {
    return (
      <div className="my-1 ml-2">
        <div>
          &gt;&nbsp;<strong>{JSON.stringify(props.parentIndex)}</strong>&nbsp;
          {props.fepMulti.name}
        </div>
        {props.fepMulti.children.map((fepMulti, i) => (
          <FepMultiView
            key={fepMulti.uuid}
            fepMulti={fepMulti}
            parentIndex={[...props.parentIndex, i + 1]}
          />
        ))}
      </div>
    );
  };

  const checkCoating = (
    coat: FepDetail | undefined,
    weld: FepDetail | undefined,
    childrenLength: number
  ) => {
    if (coat) {
      if (coat && weld) {
        if (childrenLength > 0) {
          return "";
        } else {
          return "darkslategray";
        }
      } else {
        return "";
      }
    } else {
      return "darkslategray";
    }
  };

  const checkAssy = (fepMulti: FepMulti) =>
    !fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Bend"
    ) &&
    !fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Weld"
    ) &&
    !fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Grind"
    ) &&
    !fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Coat"
    ) &&
    fepMulti.children.length > 0;

  const checkCoatingBool = (fepMulti: FepMulti) =>
    fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Coat"
    ) &&
    (fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Weld"
    )
      ? fepMulti.children.length > 0
      : true);

  const checkGrind = (fepMulti: FepMulti): boolean =>
    fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Grind"
    ) &&
    fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Weld"
    ) &&
    fepMulti.children.length > 0
      ? true
      : false;

  const checkWeld = (fepMulti: FepMulti): boolean =>
    fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Weld"
    ) && fepMulti.children.length > 0
      ? true
      : false;

  const checkBend = (fepMulti: FepMulti): boolean =>
    fepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Bend"
    )
      ? true
      : false;

  const findFepMulti = (root: FepDocument, uuid: string) => {
    let foundFepMulti = null as FepMulti | null;

    const findFepMultiRecursive = (fepMulti: FepMulti) => {
      if (fepMulti.uuid === uuid) {
        foundFepMulti = fepMulti;
      }
      fepMulti.children.forEach((fepMulti) => {
        findFepMultiRecursive(fepMulti);
      });
    };

    root.fepMultis.forEach((fepMulti) => {
      findFepMultiRecursive(fepMulti);
    });

    return foundFepMulti;
  };

  const handleChangeFepMultiQty = (fepMultiUuid: string, qtyString: string) => {
    if (fep) {
      const newFep = { ...fep };
      let foundFepMulti = findFepMulti(newFep, fepMultiUuid);

      if (foundFepMulti) {
        foundFepMulti.qty = isNaN(parseInt(qtyString))
          ? 0
          : parseInt(qtyString);
        setFep(newFep);
      }
    }
  };

  const handleChangeFepMultiRal = (fepMultiUuid: string, ral: Ral) => {
    if (fep) {
      const newFep = { ...fep };
      let foundFepMulti = findFepMulti(newFep, fepMultiUuid);

      if (foundFepMulti) {
        foundFepMulti.ral = ral;
        setFep(newFep);
      }
    }
  };

  const handleChangeFepMultiName = (fepMultiUuid: string, name: string) => {
    if (fep) {
      const newFep = { ...fep };
      let foundFepMulti = findFepMulti(newFep, fepMultiUuid);

      if (foundFepMulti?.item?.partName) {
        foundFepMulti.item.partName = name;
        setFep(newFep);
      }
    }
  };

  const handleCheckNewPartToPartItem = async (e: any) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${appState?.baseUrl}/itemscheck?itemName=${state.newPartToPartDetailItemPartNameToCheck}`,
        {
          headers: { authorization: apiKey },
        }
      );

      if (response.status !== 200) throw await response.text();

      setState({
        ...state,
        newPartToPartDetail: {
          ...state.newPartToPartDetail,
          item: (await response.json()) as Item,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCheckNewPartToPartItemReturn = async (partName: string) => {
    try {
      const response = await fetch(
        `${appState?.baseUrl}/itemscheck?itemName=${partName}`,
        {
          headers: { authorization: apiKey },
        }
      );

      if (response.status !== 200) throw await response.text();
      return (await response.json()) as Item;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleAddNewPartToPartDetail = () => {
    const newState = { ...state };

    newState.newPartToPart.partToPartDetails = [
      ...newState.newPartToPart.partToPartDetails,
      {
        ...newState.newPartToPartDetail,
        uuid: uuidv4(),
      },
    ];

    setState(newState);
  };

  const handleAddNewPartToPart = () => {
    let newFep = (() => {
      if (fep) {
        return { ...fep } as FepDocument;
      } else {
        return { ...initialFepDocument } as FepDocument;
      }
    })();

    newFep.partToParts = [
      ...newFep.partToParts,
      {
        ...state.newPartToPart,
        timePerUnit: timeHour * 3600 + timeMins * 60 + timeSecs * 60,
        timeNest: timeHour * 3600 + timeMins * 60 + timeSecs * 60,
        timeRun: timeHour * 3600 + timeMins * 60 + timeSecs * 60,
        uuid: uuidv4(),
      },
    ];

    setState({
      ...state,
      partToPartModalShow: false,
      newPartToPart: {
        ...state.newPartToPart,
        uuid: uuidv4(),
        partToPartDetails: [],
      },
    });
    setFep(newFep);
  };

  const partToPartProcessTypes = appState?.processTypes
    ? appState.processTypes.filter(
        (processType) =>
          processType.name === "Trumpf" ||
          processType.name === "LVD" ||
          processType.name === "HSG"
      )
    : [];

  // Recursive func
  const FepTableView = (props: {
    fepMulti: FepMulti;
    parentIndex: number[];
  }) => {
    const [bend, weld, grind, coat, assy] = [
      "Bend",
      "Weld",
      "Grind",
      "Coat",
      "Assembly",
    ].map((processName) =>
      props.fepMulti.fepDetails.find(
        (fepDetail) => fepDetail.processType?.name === processName
      )
    );

    return (
      <>
        <tr
          // onMouseOver={() => setState({ ...state, hoverUuid: props.fepMulti.uuid })}
          style={{
            cursor: "pointer",
            backgroundColor: (() => {
              // if (props.fepMulti.uuid === state.hoverUuid) return "lightgray"
              if (props.parentIndex.length === 1) return "orchid";
              // Main assy
              else if (checkAssy(props.fepMulti)) return "orange";
              // Sub assy
              else if (props.fepMulti.children.length !== 0) return "yellow";
              else return "";
            })(),
          }}
        >
          <td className={`border border-dark `}>
            <strong>{props.parentIndex.join(".")}</strong>
          </td>
          <td
            className={`border border-dark ${
              fepMultiPartIdMultipleCheck(
                props.fepMulti.item?.id ? props.fepMulti.item.id : 0
              )
                ? "text-danger font-weight-bold"
                : ""
            }`}
          >
            {props.fepMulti.item?.id}
          </td>
          <td className="border border-dark">
            <input
              className="form-control"
              placeholder="Part Name..."
              defaultValue={props.fepMulti.item?.partName ?? ""}
              onBlur={(e) => {
                handleChangeFepMultiName(props.fepMulti.uuid, e.target.value);
              }}
            />
          </td>
          <td className="border border-dark">{props.fepMulti.length}</td>
          <td className="border border-dark">{props.fepMulti.width}</td>
          <td className="d-flex align-items-center border border-dark">
            <TextField
              variant="outlined"
              style={{ width: 50 }}
              defaultValue={props.fepMulti.qty}
              onBlur={(e) =>
                handleChangeFepMultiQty(props.fepMulti.uuid, e.target.value)
              }
            />
          </td>
          <td
            className="border border-dark"
            style={{
              // Check if child has welding.
              // If ral is null but has welding children, go red
              backgroundColor:
                !props.fepMulti.ral &&
                props.fepMulti.children
                  .map((fepMulti) =>
                    fepMulti.fepDetails.map(
                      (fepDetail) => fepDetail.processType?.name
                    )
                  )
                  .flat()
                  .includes("Weld") &&
                !checkAssy(props.fepMulti)
                  ? // !props.fepMulti.fepDetails.map(fepDetail => fepDetail.processType?.name).includes("Assembly")
                    "red"
                  : props.fepMulti.children.length > 0 &&
                    !checkAssy(props.fepMulti)
                  ? "yellow"
                  : "",
            }}
          >
            {/* {JSON.stringify(props.fepMulti.ral)} */}
            <Autocomplete
              options={appState?.rals ?? []}
              getOptionLabel={(ral) => ral?.name ?? "no name"}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              onChange={(_, ral) => {
                if (ral) {
                  handleChangeFepMultiRal(props.fepMulti.uuid, ral);
                }
              }}
              value={props.fepMulti.ral}
            />

            <small>
              <strong>{props.fepMulti.ral?.name}</strong>
            </small>
          </td>
          <td className="border border-dark">{props.fepMulti.material}</td>
          {/* B */}
          <td className="border border-dark">
            <Form.Check checked={checkBend(props.fepMulti)} />
          </td>
          {/* W */}
          <td className="border border-dark">
            <Form.Check checked={checkWeld(props.fepMulti)} />
          </td>
          {/* G */}
          <td className="border border-dark">
            <Form.Check checked={checkGrind(props.fepMulti)} />
          </td>
          {/* C */}
          <td className="border border-dark">
            <Form.Check checked={checkCoatingBool(props.fepMulti)} />
          </td>
          {/* A */}
          <td className="border border-dark">
            <Form.Check checked={checkAssy(props.fepMulti)} />
          </td>

          <td
            className={`border border-dark ${
              props.fepMulti.fepDetails.find(
                (fepDetail) => fepDetail.processType?.name === "Bend"
              )?.bendStrokes
                ? ""
                : "bg-dark"
            }`}
          >
            {
              props.fepMulti.fepDetails.find(
                (fepDetail) => fepDetail.processType?.name === "Bend"
              )?.bendStrokes
            }
          </td>
          <td className={`border border-dark ${bend ? "" : "bg-dark"}`}>
            {secondsToHms(bend ? bend.time : 0)}
          </td>
          <td
            className={`border border-dark ${
              // If welding child found & no weld, turn red
              props.fepMulti.children.length > 0 &&
              props.fepMulti.children.filter((child) =>
                child.fepDetails.find(
                  (fepDetail) => fepDetail.processType?.name === "Weld"
                )
              ).length > 0 &&
              // props.fepMulti.fepDetails.\find(fepDetail => fepDetail.processType?.name === "Weld")?.weldValue === 0
              !props.fepMulti.fepDetails.find(
                (fepDetail) => fepDetail.processType?.name === "Weld"
              ) &&
              !checkAssy(props.fepMulti)
                ? "bg-danger"
                : ""
            }`}
          >
            {
              props.fepMulti.fepDetails.find(
                (fepDetail) => fepDetail.processType?.name === "Weld"
              )?.weldValue
            }
          </td>
          <td
            className="border border-dark"
            style={{
              backgroundColor:
                weld && props.fepMulti.children.length > 0
                  ? ""
                  : "darkslategray",
            }}
          >
            {secondsToHms(weld ? weld.time : 0)}
          </td>
          <td
            className="border border-dark"
            style={{
              backgroundColor:
                grind && props.fepMulti.children.length > 0
                  ? ""
                  : "darkslategray",
            }}
          >
            {secondsToHms(grind ? grind.time : 0)}
          </td>
          <td
            className="border border-dark"
            style={{
              backgroundColor: checkCoating(
                coat,
                weld,
                props.fepMulti.children.length
              ),
            }}
          >
            {secondsToHms(coat ? coat.time : 0)}
          </td>
          <td
            className="border border-dark"
            style={{
              backgroundColor: assy ? "" : "darkslategray",
            }}
          >
            {secondsToHms(assy ? assy.time : 0)}
          </td>
        </tr>
        {props.fepMulti.children.map((fepMulti, i) => {
          return (
            <FepTableView
              key={fepMulti.uuid}
              fepMulti={fepMulti}
              parentIndex={[...props.parentIndex, i + 1]}
            />
          );
        })}
      </>
    );
  };

  const TableCellBordered = styled(TableCell)({
    border: 2,
    borderStyle: "solid",
  });

  const handleChangeProgramRun = (index: number, programRunString: string) => {
    if (fep) {
      const newFep = { ...fep };
      const foundPartToPart = newFep.partToParts.find(
        (partToPart, i) => i === index
      );

      if (foundPartToPart) {
        foundPartToPart.programRun = isNaN(parseInt(programRunString))
          ? 0
          : parseInt(programRunString);
      }

      setFep(newFep);
    }
  };

  const FormComponent = (props: {
    value: any;
    onSubmit: (val: string) => void;
  }) => {
    const [state, setState] = useState({
      value: props.value,
    });

    const handleSubmit = (e: any) => {
      e.preventDefault();
      props.onSubmit(state.value);
    };

    return (
      <div>
        <Form.Control
          style={{ width: 75 }}
          className="border border-secondary"
          value={state.value}
          onChange={(e) => setState({ ...state, value: e.target.value })}
          onBlur={handleSubmit}
        />
      </div>
    );
  };

  const fepMultiPartIdMultipleCheck = (partId: number) => {
    let count = 0;

    const dupeCheck = (fepMulti: FepMulti) => {
      if (fepMulti.item?.id === partId) {
        // console.log("Found dupe", fepMulti.item.partName)
        count++;
      }
      fepMulti.children.forEach((fepMulti) => {
        dupeCheck(fepMulti);
      });
    };

    fep?.fepMultis.forEach((fepMulti) => {
      dupeCheck(fepMulti);
    });

    return count > 1;
  };

  const partToPartPartIdMultipleCheck = (partId: number) => {
    let count = 0;

    fep?.partToParts
      .map((partToPart) => partToPart.partToPartDetails)
      .flat()
      .forEach((partToPartDetail) => {
        if (partToPartDetail?.item?.id === partId) {
          count++;
        }
      });

    // if (count > 1) {
    //   console.log("Part ID found", count);
    // }

    return count > 1;
  };

  const handleCheckFepAssy = () => {
    const newState = { ...state };

    if (
      !newState.newFepMulti.fepDetails.find(
        (fepDetail) => fepDetail.processType?.name === "Assembly"
      )
    ) {
      newState.newFepMulti.fepDetails = [
        // ...newState.newFepMulti.fepDetails,
        {
          ...initialFepDetail,
          processType: appState?.processTypes.find(
            (processType) => processType.name === "Assembly"
          ),
        } as FepDetail,
      ];
    } else {
      newState.newFepMulti.fepDetails.splice(
        newState.newFepMulti.fepDetails.findIndex(
          (fepDetail) => fepDetail.processType?.name === "Assembly"
        ),
        1
      );
    }

    setState(newState);
  };

  const handleCheckFepCutout = () => {
    if (state.newFepMulti.type === "CUTOUT") {
      setState({
        ...state,
        newFepMulti: {
          ...state.newFepMulti,
          type: "FEP",
        },
      });
    } else {
      setState({
        ...state,
        newFepMulti: {
          ...state.newFepMulti,
          type: "CUTOUT",
        },
      });
    }
  };

  const handleCheckFepBend = () => {
    const newState = { ...state };

    if (
      state.newFepMulti.fepDetails.find(
        (fepDetail) => fepDetail.processType?.name === "Bend"
      )
    ) {
      newState.newFepMulti.fepDetails.splice(
        state.newFepMulti.fepDetails.findIndex(
          (fepDetail) => fepDetail.processType?.name === "Bend"
        ),
        1
      );
    } else {
      newState.newFepMulti.fepDetails = [
        ...state.newFepMulti.fepDetails,
        {
          ...initialFepDetail,
          processType: appState?.processTypes.find(
            (processType) => processType.name === "Bend"
          ),
        } as FepDetail,
      ];
    }

    setState(newState);
  };

  const handleCheckFepWeldGrind = () => {
    const newState = { ...state };

    if (
      newState.newFepMulti.fepDetails.find(
        (fepDetail) => fepDetail.processType?.name === "Weld"
      ) &&
      newState.newFepMulti.fepDetails.find(
        (fepDetail) => fepDetail.processType?.name === "Grind"
      )
    ) {
      newState.newFepMulti.fepDetails.splice(
        newState.newFepMulti.fepDetails.findIndex(
          (fepDetail) => fepDetail.processType?.name === "Weld"
        ),
        1
      );

      newState.newFepMulti.fepDetails.splice(
        newState.newFepMulti.fepDetails.findIndex(
          (fepDetail) => fepDetail.processType?.name === "Grind"
        ),
        1
      );
    } else {
      newState.newFepMulti.fepDetails = [
        ...newState.newFepMulti.fepDetails,
        {
          ...initialFepDetail,
          processType: appState?.processTypes.find(
            (processType) => processType.name === "Weld"
          ),
        } as FepDetail,
        {
          ...initialFepDetail,
          processType: appState?.processTypes.find(
            (processType) => processType.name === "Grind"
          ),
        } as FepDetail,
      ];
    }

    setState(newState);
  };

  const handleCheckFepCoat = () => {
    const newState = { ...state };

    if (
      state.newFepMulti.fepDetails.find(
        (fepDetail) => fepDetail.processType?.name === "Coat"
      )
    ) {
      newState.newFepMulti.fepDetails.splice(
        state.newFepMulti.fepDetails.findIndex(
          (fepDetail) => fepDetail.processType?.name === "Coat"
        ),
        1
      );
    } else {
      newState.newFepMulti.fepDetails = [
        ...state.newFepMulti.fepDetails,
        {
          ...initialFepDetail,
          processType: appState?.processTypes.find(
            (processType) => processType.name === "Coat"
          ),
        } as FepDetail,
      ];
    }

    setState(newState);
  };

  const handleChangeNewFepMultiBendStroke = (e: any) => {
    const newState = { ...state };

    const foundBendDetail = newState.newFepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Bend"
    );

    if (foundBendDetail) {
      const bendStrokes = isNaN(parseInt(e.target.value))
        ? 0
        : parseInt(e.target.value);
      foundBendDetail.bendStrokes = bendStrokes;
      foundBendDetail.time = Math.round(
        (bendStrokes * 2 - 1) * 5 * newState.newFepMulti.qty
      );
      console.log("Bend detail time:", foundBendDetail);
    }

    setState(newState);
  };

  const handleChangeNewFepMultiWeldValue = (e: any) => {
    const newState = { ...state };

    const foundWeldDetail = newState.newFepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Weld"
    );

    if (foundWeldDetail) {
      foundWeldDetail.weldValue = isNaN(parseFloat(e.target.value))
        ? 0
        : parseFloat(e.target.value);
    }

    setState(newState);
  };

  const handleChangeFepMultiItemPartName = (e: any) =>
    setState({
      ...state,
      newFepMultiItemPartNameToCheck: e.target.value,
    });

  const handleCheckFepMultiItemPartName = async (e: any) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${appState?.baseUrl}/itemscheck?itemName=${state.newFepMultiItemPartNameToCheck}`,
        {
          headers: { authorization: apiKey },
        }
      );

      if (response.status !== 200) throw await response.text();

      setState({
        ...state,
        newFepMulti: {
          ...state.newFepMulti,
          item: (await response.json()) as Item,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleChangeFepMultiCoatingRal = (selected: {
    label: string;
    value: Ral;
  }) => {
    const newState = { ...state };

    console.log("Selected ral:", selected.value);

    const foundCoatDetail = newState.newFepMulti.fepDetails.find(
      (fepDetail) => fepDetail.processType?.name === "Coat"
    );

    if (foundCoatDetail) {
      newState.newFepMulti.ral = selected.value;
      foundCoatDetail.time = Math.round(
        ((newState.newFepMulti.length *
          newState.newFepMulti.width *
          2 *
          newState.newFepMulti.qty) /
          1000000 /
          0.8) *
          60
      );
    }

    setState(newState);
  };

  const handleAddNewFepMulti = () => {
    console.log("Adding new FEP Multi", state.newFepMulti);

    const newFep = (() => {
      if (fep) {
        return { ...fep };
      } else {
        return { ...initialFepDocument };
      }
    })();

    if (state.selectedFepMultiParent) {
      const searchFepMultis = (fepMulti: FepMulti) => {
        if (fepMulti.uuid === state.selectedFepMultiParent?.uuid) {
          fepMulti.children = [
            ...fepMulti.children,
            {
              ...state.newFepMulti,
              // fepMulti: state.selectedFepMultiParent ?? null,
              uuid: uuidv4(),
            },
          ];
        }
      };

      fep?.fepMultis.forEach((fepMulti) => {
        searchFepMultis(fepMulti);
      });
    } else {
      newFep.fepMultis = [
        ...newFep.fepMultis,
        {
          ...state.newFepMulti,
          // fepMulti: state.selectedFepMultiParent ?? null,
          uuid: uuidv4(),
        },
      ];
    }

    setFep(newFep);
    setState({
      ...state,
      selectedFepMultiParent: null,
      fepMultiModalShow: false,
    });
  };

  const handleChangeFepMultiParent = (selected: {
    label: string;
    value: FepMulti;
  }) =>
    setState({
      ...state,
      selectedFepMultiParent: selected.value,
    });

  const PartToPartTableView = (props: { partToParts: PartToPart[] }) => {
    return props.partToParts.length > 0 ? (
      <Table size="sm" style={{ borderCollapse: "separate" }}>
        <thead>
          <tr
            style={{
              border: 2,
              borderStyle: "solid",
              position: "sticky",
              top: 0,
            }}
          >
            {[
              "Program",
              "Length",
              "Width",
              "Height",
              "Part ID",
              "Part Name",
              "P Length",
              "P Width",
              "Material",
              "Qty need/unit",
              "Qty/program",
              "Qty need total",
              "Qty prod total",
              "Qty prod x program",
              "Balance",
              "Time",
              "Program run",
              "Machine Type",
            ].map((head) => {
              return (
                <th
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "yellow",
                  }}
                  className="text-center align-middle border border-dark"
                >
                  {head}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props.partToParts?.map((partToPart, i) => {
            const partToPartClone = [...partToPart.partToPartDetails];
            partToPartClone.splice(0, 1);

            const PartToPartRest = () => {
              return (
                <>
                  {partToPartClone.map((partToPartDetail, j) => {
                    const totalQtyNeeded =
                      partToPartDetail.qtyNeeded * (fep ? fep.unitQty : 0);
                    const totalQtyProduced = fep
                      ? fep.partToParts
                          .map((partToPartFind) => {
                            const programRun = partToPartFind.programRun;

                            const programProduced =
                              partToPartFind.partToPartDetails
                                .filter(
                                  (partToPartDetailToFind) =>
                                    partToPartDetail?.item?.id ===
                                    partToPartDetailToFind.item?.id
                                )
                                .reduce(
                                  (acc, partToPartDetail) =>
                                    acc +
                                    (partToPartDetail?.qtyProduced
                                      ? partToPartDetail.qtyProduced
                                      : 0) *
                                      programRun,
                                  0
                                );

                            // console.log(partToPartDetail.item?.partName, partToPart.name, programProduced, programRun);

                            return programProduced;
                          })
                          .reduce(
                            (acc, programProduced) => acc + programProduced,
                            0
                          )
                      : 0;

                    const balance = totalQtyProduced - totalQtyNeeded;

                    return (
                      <tr key={partToPartDetail.uuid}>
                        <td
                          className={`border border-dark ${
                            partToPartPartIdMultipleCheck(
                              partToPartDetail.item?.id
                                ? partToPartDetail.item?.id
                                : 0
                            )
                              ? "text-danger font-weight-bold"
                              : ""
                          }`}
                          style={{ border: 2, borderStyle: "solid" }}
                        >
                          {partToPartDetail.item?.id}
                        </td>
                        <td
                          className="d-flex border border-dark"
                          style={{ border: 2, borderStyle: "solid" }}
                        >
                          <Box mr={1}>
                            <Button
                              onClick={() => {
                                if (fep)
                                  setFep({
                                    ...fep,
                                    partToParts: fep?.partToParts
                                      ? fep.partToParts.map((partToPartX) =>
                                          partToPartX.uuid === partToPart.uuid
                                            ? {
                                                ...partToPartX,
                                                partToPartDetails:
                                                  partToPartX.partToPartDetails
                                                    ? partToPartX.partToPartDetails.filter(
                                                        (partToPartDetailX) =>
                                                          partToPartDetailX.uuid !==
                                                          partToPartDetail.uuid
                                                      )
                                                    : partToPartX.partToPartDetails,
                                              }
                                            : partToPartX
                                        )
                                      : fep.partToParts,
                                  });
                              }}
                              size="sm"
                              className="btn-danger"
                            >
                              <Delete />
                            </Button>
                          </Box>

                          {partToPartDetail.item?.partName}
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor:
                              partToPartDetail.length !== 0 ? "white" : "red",
                          }}
                        >
                          {partToPartDetail.length}
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor:
                              partToPartDetail.width !== 0 ? "white" : "red",
                          }}
                        >
                          {partToPartDetail.width}
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor: partToPartDetail.materialType
                              ? "white"
                              : "red",
                          }}
                        >
                          <Autocomplete
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                              />
                            )}
                            options={appState?.materialTypes ?? []}
                            getOptionLabel={(materialType) =>
                              materialType?.name
                            }
                            value={partToPartDetail.materialType ?? null}
                            onChange={(_: any, v: MaterialType | null) => {
                              if (fep) {
                                const newFep = { ...fep };

                                const foundPartToPartDetail =
                                  newFep.partToParts[i].partToPartDetails.find(
                                    (partToPartDetailX) =>
                                      partToPartDetailX.uuid ===
                                      partToPartDetail.uuid
                                  );

                                if (foundPartToPartDetail && v) {
                                  foundPartToPartDetail.materialType = v;
                                }

                                setFep(newFep);
                              }
                            }}
                          />
                          <small>
                            <strong>
                              {partToPartDetail.materialType?.name}
                            </strong>
                          </small>
                        </td>
                        <td
                          className="border border-dark"
                          style={{
                            backgroundColor:
                              partToPartDetail.qtyNeeded !== 0
                                ? "white"
                                : "red",
                          }}
                        >
                          {(() => {
                            const QtyNeededPerUnit = () => {
                              const [qtyNeeded, setQtyNeeded] = useState(
                                partToPartDetail.qtyNeeded
                              );

                              return (
                                <Form.Control
                                  style={{ width: 75 }}
                                  className="border border-secondary"
                                  value={qtyNeeded}
                                  onChange={(e) => {
                                    setQtyNeeded(
                                      isNaN(parseInt(e.target.value))
                                        ? qtyNeeded
                                        : parseInt(e.target.value)
                                    );
                                  }}
                                  onBlur={() => {
                                    if (fep) {
                                      const newFep = { ...fep };

                                      const foundPartToPartDetail =
                                        newFep.partToParts[
                                          i
                                        ]?.partToPartDetails?.find(
                                          (partToPartDetailX) =>
                                            partToPartDetailX.uuid ===
                                            partToPartDetail.uuid
                                        );

                                      if (foundPartToPartDetail) {
                                        foundPartToPartDetail.qtyNeeded =
                                          qtyNeeded;

                                        setFep(newFep);
                                      }
                                    }
                                  }}
                                />
                              );
                            };

                            return <QtyNeededPerUnit />;
                          })()}
                        </td>
                        <td className="border border-dark">
                          {(() => {
                            const QtyProducedPerUnit = () => {
                              const [qtyProduced, setQtyProduced] = useState(
                                partToPartDetail.qtyProduced
                              );

                              return (
                                <Form.Control
                                  style={{ width: 75 }}
                                  className="border border-secondary"
                                  value={qtyProduced}
                                  onChange={(e) => {
                                    setQtyProduced(
                                      isNaN(parseInt(e.target.value))
                                        ? qtyProduced
                                        : parseInt(e.target.value)
                                    );
                                  }}
                                  onBlur={() => {
                                    if (fep) {
                                      const newFep = { ...fep };

                                      const foundPartToPartDetail =
                                        newFep.partToParts[
                                          i
                                        ]?.partToPartDetails.find(
                                          (partToPartDetailX) =>
                                            partToPartDetailX.item?.id ===
                                            partToPartDetail?.item?.id
                                        );

                                      if (foundPartToPartDetail) {
                                        foundPartToPartDetail.qtyProduced =
                                          qtyProduced;

                                        setFep(newFep);
                                      }
                                    }
                                  }}
                                />
                              );
                            };

                            return <QtyProducedPerUnit />;
                          })()}{" "}
                        </td>
                        <td className="border border-dark">{totalQtyNeeded}</td>
                        <td className="border border-dark">
                          {totalQtyProduced}
                        </td>
                        <td className={`border border-dark`}>
                          {partToPartDetail.qtyProduced * partToPart.programRun}
                        </td>
                        <td
                          className={`border border-dark ${(() => {
                            if (balance > 0) return "bg-warning";
                            else if (balance < 0) return "bg-danger text-white";
                            else return "";
                          })()}`}
                        >
                          {/* Balance */}
                          {balance > 0 ? "+" : ""}
                          {balance}
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            };

            const totalQtyNeeded =
              (partToPart.partToPartDetails[0]?.qtyNeeded ?? 0) *
              (fep ? fep.unitQty : 0);
            const totalQtyProduced = fep
              ? fep.partToParts
                  .map((partToPartFind) => {
                    const programRun = partToPartFind.programRun;

                    const programProduced = partToPartFind.partToPartDetails
                      .filter(
                        (partToPartDetailToFind) =>
                          partToPart.partToPartDetails[0]?.item?.id ===
                          partToPartDetailToFind.item?.id
                      )
                      .reduce(
                        (acc, partToPartDetail) =>
                          acc +
                          (partToPartDetail?.qtyProduced
                            ? partToPartDetail.qtyProduced
                            : 0) *
                            programRun,
                        0
                      );

                    // console.log(partToPart.partToPartDetails[0]?.item?.partName, partToPart.name, programProduced, programRun);

                    return programProduced;
                  })
                  .reduce((acc, programProduced) => acc + programProduced, 0)
              : 0;

            // console.log("Total qty produced: ", totalQtyProduced);

            const balance = totalQtyProduced - totalQtyNeeded;

            return (
              <>
                <tr>
                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor: partToPart.name !== "" ? "white" : "red",
                    }}
                  >
                    {/* {JSON.stringify(fep?.partToParts
                        .map(partToPart => partToPart.partToPartDetails)
                        .flat()
                        .filter(partToPartDetailToFind => partToPartDetailToFind?.item?.id === partToPart.partToPartDetails[0].item?.id))} */}
                    <textarea
                      className="form-control"
                      defaultValue={partToPart.name}
                      onBlur={(e) => {
                        if (fep) {
                          setFep({
                            ...fep,
                            partToParts: fep.partToParts.map(
                              (partToPartX, ix) =>
                                ix === i
                                  ? {
                                      ...partToPartX,
                                      name: (e.target as any)?.value ?? "",
                                    }
                                  : partToPartX
                            ),
                          });
                        }
                      }}
                    />

                    <Box>
                      <Button
                        onClick={async () => {
                          const partName = prompt("Enter part name:");

                          if (partName && partName !== "") {
                            const newItem =
                              await handleCheckNewPartToPartItemReturn(
                                partName
                              );

                            const newPartToPartDetail = {
                              ...initialPartToPartDetail,
                              item: newItem,
                            } as PartToPartDetail;

                            if (fep)
                              setFep({
                                ...fep,
                                partToParts: fep.partToParts
                                  ? fep.partToParts.map((partToPartX, ix) =>
                                      ix === i
                                        ? {
                                            ...partToPartX,
                                            partToPartDetails:
                                              partToPartX.partToPartDetails
                                                ? [
                                                    ...partToPartX.partToPartDetails,
                                                    newPartToPartDetail,
                                                  ]
                                                : partToPartX.partToPartDetails,
                                          }
                                        : partToPartX
                                    )
                                  : fep.partToParts,
                              });
                          }
                        }}
                        size="sm"
                      >
                        <Add />
                        Add Part
                      </Button>
                    </Box>
                  </td>
                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor:
                        partToPart.length !== 0 ? "white" : "red",
                    }}
                  >
                    {partToPart.length}
                  </td>
                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor: partToPart.width !== 0 ? "white" : "red",
                    }}
                  >
                    {partToPart.width}
                  </td>
                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor:
                        partToPart.height !== 0 ? "white" : "red",
                    }}
                  >
                    {partToPart.height}
                  </td>
                  <td
                    className={`border border-dark ${
                      partToPartPartIdMultipleCheck(
                        partToPart.partToPartDetails[0]?.item?.id
                          ? partToPart.partToPartDetails[0]?.item?.id
                          : 0
                      )
                        ? "text-danger font-weight-bold"
                        : ""
                    }`}
                  >
                    {partToPart.partToPartDetails[0]?.item?.id}
                  </td>
                  <td className="d-flex border border-dark">
                    <Box mr={1}>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() => {
                          if (fep)
                            setFep({
                              ...fep,
                              partToParts: fep?.partToParts
                                ? fep.partToParts.map((partToPartX) =>
                                    partToPartX.uuid === partToPart.uuid
                                      ? {
                                          ...partToPartX,
                                          partToPartDetails:
                                            partToPartX.partToPartDetails
                                              ? partToPartX.partToPartDetails.filter(
                                                  (partToPartDetailX) =>
                                                    partToPartDetailX.uuid !==
                                                    partToPart
                                                      .partToPartDetails[0]
                                                      ?.uuid
                                                )
                                              : partToPartX.partToPartDetails,
                                        }
                                      : partToPartX
                                  )
                                : fep.partToParts,
                            });
                        }}
                      >
                        <Delete />
                      </Button>
                    </Box>
                    {partToPart.partToPartDetails[0]?.item?.partName}
                  </td>
                  <td
                    className="border border-dark"
                    style={{
                      backgroundColor:
                        partToPart.partToPartDetails[0] &&
                        partToPart.partToPartDetails[0].length !== 0
                          ? "white"
                          : "red",
                    }}
                  >
                    {partToPart.partToPartDetails[0]?.length}
                  </td>
                  <td
                    className="border border-dark"
                    style={{
                      backgroundColor:
                        partToPart.partToPartDetails[0] &&
                        partToPart.partToPartDetails[0].width !== 0
                          ? "white"
                          : "red",
                    }}
                  >
                    {partToPart.partToPartDetails[0]?.width}
                  </td>
                  <td
                    className="border border-dark"
                    style={{
                      backgroundColor:
                        partToPart.partToPartDetails[0] &&
                        partToPart.partToPartDetails[0].materialType
                          ? "white"
                          : "red",
                    }}
                  >
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      options={appState?.materialTypes ?? []}
                      getOptionLabel={(materialType) => materialType?.name}
                      value={
                        partToPart.partToPartDetails[0]?.materialType ?? null
                      }
                      onChange={(_: any, v: MaterialType | null) => {
                        if (fep) {
                          const newFep = { ...fep };

                          const foundPartToPartDetail = newFep.partToParts[
                            i
                          ].partToPartDetails.find(
                            (partToPartDetailX) =>
                              partToPartDetailX.uuid ===
                              partToPart.partToPartDetails[0]?.uuid
                          );

                          if (foundPartToPartDetail && v) {
                            foundPartToPartDetail.materialType = v;
                          }

                          setFep(newFep);
                        }
                      }}
                    />

                    <small>
                      <strong>
                        {partToPart.partToPartDetails[0]?.materialType?.name}
                      </strong>
                    </small>
                  </td>
                  <td
                    className="border border-dark"
                    style={{
                      backgroundColor:
                        partToPart.partToPartDetails[0] &&
                        partToPart.partToPartDetails[0].qtyNeeded !== 0
                          ? "white"
                          : "red",
                    }}
                  >
                    {(() => {
                      const QtyNeededPerUnit = () => {
                        const [qtyNeeded, setQtyNeeded] = useState(
                          partToPart.partToPartDetails[0]?.qtyNeeded ?? 0
                        );

                        return (
                          <Form.Control
                            style={{ width: 75 }}
                            className="border border-secondary"
                            value={qtyNeeded}
                            onChange={(e) => {
                              setQtyNeeded(
                                isNaN(parseInt(e.target.value))
                                  ? qtyNeeded
                                  : parseInt(e.target.value)
                              );
                            }}
                            onBlur={() => {
                              if (fep) {
                                const newFep = { ...fep };

                                const foundPartToPartDetails = (
                                  newFep.partToParts.map(
                                    (partToPart) =>
                                      partToPart.partToPartDetails ?? []
                                  ) ?? []
                                )
                                  .flat()
                                  ?.filter(
                                    (partToPartDetailX) =>
                                      partToPartDetailX.item?.id ===
                                      partToPart.partToPartDetails[0]?.item?.id
                                  );

                                if (foundPartToPartDetails) {
                                  foundPartToPartDetails.forEach(
                                    (partToPartDetail) => {
                                      partToPartDetail.qtyNeeded = qtyNeeded;
                                    }
                                  );

                                  setFep(newFep);
                                }
                              }
                            }}
                          />
                        );
                      };

                      return <QtyNeededPerUnit />;
                    })()}{" "}
                  </td>
                  <td className="border border-dark">
                    {(() => {
                      const QtyProducedPerUnit = () => {
                        const [qtyProduced, setQtyProduced] = useState(
                          partToPart.partToPartDetails[0]?.qtyProduced ?? 0
                        );

                        return (
                          <Form.Control
                            style={{ width: 75 }}
                            className="border border-secondary"
                            value={qtyProduced}
                            onChange={(e) => {
                              setQtyProduced(
                                isNaN(parseInt(e.target.value))
                                  ? qtyProduced
                                  : parseInt(e.target.value)
                              );
                            }}
                            onBlur={() => {
                              if (fep) {
                                const newFep = { ...fep };

                                const foundPartToPartDetail =
                                  newFep.partToParts[i]?.partToPartDetails.find(
                                    (partToPartDetailX) =>
                                      partToPartDetailX.item?.id ===
                                      partToPart.partToPartDetails[0]?.item?.id
                                  );

                                if (foundPartToPartDetail) {
                                  foundPartToPartDetail.qtyProduced =
                                    qtyProduced;

                                  setFep(newFep);
                                }
                              }
                            }}
                          />
                        );
                      };

                      return <QtyProducedPerUnit />;
                    })()}
                  </td>
                  <td className="border border-dark">{totalQtyNeeded}</td>
                  <td className={`border border-dark`}>{totalQtyProduced}</td>
                  <td className={`border border-dark`}>
                    {(partToPart.partToPartDetails[0]?.qtyProduced ?? 0) *
                      partToPart.programRun}
                  </td>
                  <td
                    className={`border border-dark ${(() => {
                      if (balance > 0) return "bg-warning";
                      else if (balance < 0) return "bg-danger text-white";
                      else return "";
                    })()}`}
                  >
                    {balance > 0 ? "+" : ""}
                    {balance}
                  </td>

                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor:
                        partToPart.timePerUnit !== 0 ? "white" : "red",
                    }}
                  >
                    {secondsToHms(partToPart.timePerUnit)}
                  </td>
                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor:
                        partToPart.programRun !== 0 ? "white" : "red",
                    }}
                  >
                    <div>
                      <TextField
                        style={{ width: 75 }}
                        variant="outlined"
                        defaultValue={partToPart.programRun ?? 0}
                        onBlur={(e) =>
                          handleChangeProgramRun(i, e.target.value)
                        }
                      />
                    </div>
                    {/* <Form.Control 
                        value={partToPart.programRun} 
                        onChange={e => handleChangeProgramRun(i, e.target.value)}
                      /> */}
                  </td>
                  <td
                    className="border border-dark"
                    rowSpan={partToPart.partToPartDetails.length}
                    style={{
                      backgroundColor:
                        partToPart.processType &&
                        partToPart.processType.name !== ""
                          ? "white"
                          : "red",
                    }}
                  >
                    <Autocomplete
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                      value={partToPart?.processType ?? null}
                      onChange={(_: any, v: ProcessType | null) => {
                        if (fep) {
                          setFep({
                            ...fep,
                            partToParts: fep.partToParts.map(
                              (partToPartX, ix) =>
                                ix === i
                                  ? { ...partToPartX, processType: v }
                                  : partToPartX
                            ),
                          });
                        }
                      }}
                      getOptionLabel={(processType) =>
                        processType?.name ?? "No name"
                      }
                      options={
                        appState?.processTypes.filter((processType) =>
                          ["HSG", "Trumpf", "LVD"].includes(processType.name)
                        ) ?? []
                      }
                    />
                    <small>
                      <strong>{partToPart.processType?.name}</strong>
                    </small>
                  </td>
                </tr>
                <PartToPartRest />
              </>
            );
          })}
        </tbody>
      </Table>
    ) : (
      <></>
    );
  };

  const handleSaveFep = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      console.log(fep);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fepdocuments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: apiKey ? apiKey : "",
          },
          body: JSON.stringify(fep),
        }
      );

      if (response.status !== 201) throw await response.text();

      const fepDocument = await response.json();

      if (fep !== null) {
        const partToPartNeededResponse = await Promise.all(
          fep.partToPartNeededs.map(async (partToPartNeeded) => {
            return await fetch(
              `${process.env.REACT_APP_BASE_URL}/parttopartneededs`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  authorization: apiKey ? apiKey : "",
                },
                body: JSON.stringify({
                  ...partToPartNeeded,
                  uuid: uuidv4(),
                  fepDocument: { ...initialFepDocument, id: fepDocument.id },
                }),
              }
            );
          })
        );
      }

      setRequestStatus(RequestStatus.Success);
      history.push("/fepdocuments");
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
      alert(e);
      console.log(e);
    }
  };

  const handleSaveFepCopy = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${appState?.baseUrl}/fepdocuments/duplicate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: apiKey ? apiKey : "",
          },
          body: JSON.stringify(fep),
        }
      );

      if (response.status !== 201) throw await response.text();

      const fepDocument = await response.json();

      setRequestStatus(RequestStatus.Success);
      history.push("/fepdocuments");
    } catch (e) {
      console.error(e);
      setRequestStatus(RequestStatus.Error);
      alert(JSON.stringify(e));
    }
  };

  const FepView = (props: { type: FepMultiType }) => {
    if (fep !== null) {
      if (treeView) {
        return (
          <>
            {fep.fepMultis
              .filter((fepMulti) => fepMulti.type === props.type)
              .map((fepMulti, i) => {
                return (
                  <FepMultiView
                    key={fepMulti.uuid}
                    fepMulti={fepMulti}
                    parentIndex={[i + 1]}
                  />
                );
              })}
          </>
        );
      } else {
        return (
          <Table size="sm" style={{ borderCollapse: "separate" }}>
            <thead
              style={{
                backgroundColor: lightBlue[100],
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <tr>
                {[
                  "Ord",
                  "Part ID",
                  "Part Name",
                  "Len",
                  "Wid",
                  "Qty",
                  "RAL",
                  "Material",
                  "B",
                  "W",
                  "G",
                  "C",
                  "A",
                  "Bend Stroke",
                  "Bend t",
                  "Weld Const",
                  "Weld t",
                  "Grind t",
                  "Coat t",
                  "Assy t",
                ].map((head) => {
                  return (
                    <th
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "yellow",
                      }}
                      className="border border-dark text-center align-middle"
                    >
                      {head}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <TableBody>
              {fep.fepMultis
                .filter((fepMulti) => fepMulti.type === props.type)
                .map((fepMulti, i) => {
                  return (
                    <FepTableView
                      key={fepMulti.uuid}
                      fepMulti={fepMulti}
                      parentIndex={[i + 1]}
                    />
                  );
                })}
            </TableBody>
          </Table>
        );
      }
    } else {
      return <></>;
    }
  };

  const handleSaveExtension = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fepdocuments/saveextension`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: apiKey ? apiKey : "",
          },
          body: JSON.stringify(fep),
        }
      );

      if (response.status !== 201) throw "Error saving FEP extension.";
      setRequestStatus(RequestStatus.Success);

      history.push("/fepdocuments");
    } catch (e) {
      console.error(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="mx-1">
          <Link to="/fepdocuments">
            <Button color="primary">
              <ChevronLeftIcon />
              Back
            </Button>
          </Link>
        </div>

        {/* {fep ? (
          fep.id !== 0 ? (
            extend ? (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveExtension}
                >
                  Save Extension
                </Button>
              </div>
            ) : (
              <div>
                Extend FEP
                <input type="file" onChange={handleExtendFep} />
              </div>
            )
          ) : (
            <div className="mx-1">
              <Button variant="primary" onClick={() => handleSaveFep()}>
                Save
              </Button>
            </div>
          )
        ) : (
          <></>
        )} */}

        {fep ? (
          <>
            <div className="mx-1">
              <Button
                disabled={state.oldName !== fep.name && state.oldName !== ""}
                variant="primary"
                onClick={() => handleSaveFep()}
              >
                Save
              </Button>
            </div>
            {fep.id !== 0 ? (
              <div className="mx-1">
                <Button variant="primary" onClick={() => handleSaveFepCopy()}>
                  Save Copy
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {!fep ? (
          <div className="mx-1">
            Import FEP&nbsp;
            <input type="file" onChange={(evt: any) => handleImport(evt)} />
          </div>
        ) : (
          <></>
        )}

        <div className="mx-1">
          <Button variant="outline-primary" onClick={() => setOpenNote(true)}>
            Import Notes
          </Button>
        </div>

        {/* <div className="mx-1">
          <a href={`${process.env.REACT_APP_BASE_URL}/fepdocuments/example`} target="_blank">
            <Button variant="contained" color="primary">Download Example</Button>
          </a>
        </div> */}

        <div className="mx-1">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/examples/fep1`}
            target="_blank"
          >
            <Button>Download Example</Button>
          </a>
        </div>

        <div className="mx-1">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/examples/fep2`}
            target="_blank"
          >
            <Button>Download Example 2</Button>
          </a>
        </div>

        <div className="mx-1">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/examples/blank`}
            target="_blank"
          >
            <Button variant="secondary">Download Blank</Button>
          </a>
        </div>

        <Modal onHide={() => setOpenNote(false)} show={openNote}>
          <Modal.Header>
            <Modal.Title>FEP Import notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List>
              <div className="d-flex my-1 justify-content-center align-items-center">
                <strong>General</strong>
              </div>

              <ListItem>
                <InfoIcon />
                &nbsp;Format file dalam bentuk .xls
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Nama worksheet: Part to Part dan FEP dari Inventor
                (perhatikan huruf kapital)
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Tidak boleh ada baris yg benar-benar kosong
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Tidak boleh menggunakan vlookup & hlookup untuk pengisian
                panjang/lebar maupun kolom lainnya
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Angka desimal menggunakan titik, tidak boleh menggunakan
                koma
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Tidak ada isi kolom lagi setelah tabel. (kecuali penanda
                EOF atau FINISH)
              </ListItem>

              <div className="d-flex my-1 justify-content-center align-items-center">
                <strong>FEP</strong>
              </div>

              <ListItem>
                <InfoIcon />
                &nbsp;FEP dari Inventor dimulai dari baris 5
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Penomoran untuk FEP dimulai dari 1 (main assembly) ke
                bawah
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Penomoran untuk FEP memakai format text (tidak boleh
                number)
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Konstanta total pada welding tidak boleh 0 (nol) atau
                kosong
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Kolom RAL hanya untuk proses yang membutuhkan coating
                saja.
              </ListItem>

              <div className="d-flex my-1 justify-content-center align-items-center">
                <strong>Part to Part</strong>
              </div>

              <ListItem>
                <InfoIcon />
                &nbsp;Part to Part dimulai dari baris 4
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Material yg diperbolehkan{" "}
                {appState?.materialTypes
                  .map((materialType) => materialType.name)
                  .join(", ")}
                .
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Kolom E pada Part to Part dikosongkan
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Harus ditambahkan EOF (End of File) atau FINISH di akhir
                worksheet
              </ListItem>
              <ListItem>
                <InfoIcon />
                &nbsp;Kolom mesin pembeda Trumpf, LVD, & HSG disesuaikan (kolom
                U)
              </ListItem>
            </List>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        {requestStatus === RequestStatus.Loading ? (
          <Spinner animation="border" />
        ) : (
          <></>
        )}
      </div>
      <div className="d-flex align-items-center my-1">
        FEP Name:{" "}
        {/* {fep?.id === 0
          ? <Form.Control
              value={fep ? fep.name : ""}
              onChange={(e) => {
                if (fep) {
                  setFep({ ...fep, name: e.target.value });
                }
              }}
              placeholder="FEP Name..."
            />
          : <div>
              {fep?.name}
            </div>
        } */}
        {fep ? (
          <Form.Control
            value={fep ? fep.name : ""}
            onChange={(e) => {
              if (fep) {
                setFep({ ...fep, name: e.target.value });
                setState({
                  ...state,
                  nameChanged: true,
                });
              }
            }}
            placeholder="FEP Name..."
          />
        ) : (
          <></>
        )}
        {/* <div>{fep ? `Version ${fep.version}` : ""}</div> */}
      </div>
      {/* <div>
        Tree view:
        <Switch value={treeView} onClick={() => setTreeView(!treeView)} />
      </div> */}

      <h3 style={{ color: yellow[900] }}>{errorMessage}</h3>

      {fep ? (
        <div className="d-flex my-1">
          <h3>
            FEP for qty:
            {/* {fep.unitQty} */}
          </h3>
          <FormComponent
            value={fep.unitQty}
            onSubmit={(val) =>
              setFep({
                ...fep,
                unitQty: isNaN(parseInt(val)) ? 0 : parseInt(val),
              })
            }
          />
        </div>
      ) : (
        <></>
      )}

      <div>
        <div className="d-flex align-items-center my-2">
          <h3>Part to Part</h3>
          <Button
            onClick={(e) => {
              setState({
                ...state,
                partToPartModalShow: true,
              });
            }}
          >
            Add
          </Button>
        </div>
        Total parts:{" "}
        {JSON.stringify(
          [
            ...new Set(
              fep?.partToParts
                .map((partToPart) => partToPart.partToPartDetails)
                .flat()
                .map((partToPartDetail) => partToPartDetail?.item?.id)
            ),
          ].length
        )}
        , Total programs: {JSON.stringify(fep?.partToParts.length)}
        <div
          className="overflow-auto border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <PartToPartTableView partToParts={fep ? fep.partToParts : []} />
        </div>
        <div className="d-flex align-items-center my-2">
          <h3>FEP</h3>
          {/* {JSON.stringify(appState?.processTypes)} */}
          <Button
            onClick={(e) => {
              setState({
                ...state,
                fepMultiModalShow: true,
                newFepMulti: {
                  ...state.newFepMulti,
                  type: "FEP",
                },
              });
            }}
          >
            Add
          </Button>
        </div>
        <div
          className="overflow-auto border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <FepView type="FEP" />
        </div>
        <div className="d-flex align-items-center my-2">
          <h3>Cutout / Additional Part</h3>
          <Button
            onClick={(e) => {
              setState({
                ...state,
                fepMultiModalShow: true,
                newFepMulti: {
                  ...state.newFepMulti,
                  type: "CUTOUT",
                },
              });
            }}
          >
            {" "}
            Add
          </Button>
        </div>
        <div
          className="overflow-auto border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <FepView type="CUTOUT" />
        </div>
        {/* <pre>
          {fepDebug}
        </pre> */}
      </div>

      {/* Part to Part Modal */}
      <Modal
        className="overflow-auto"
        show={state.partToPartModalShow}
        onHide={() => setState({ ...state, partToPartModalShow: false })}
      >
        <Modal.Header>
          <Modal.Title>Part to Part</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div>
              Program Name
              <Form.Control
                value={state.newPartToPart.name}
                onChange={(e) => {
                  setState({
                    ...state,
                    newPartToPart: {
                      ...state.newPartToPart,
                      name: e.target.value,
                    },
                  });
                }}
                placeholder="Program name..."
              />
            </div>
            <div>
              x Program Run
              <Form.Control
                value={state.newPartToPart.programRun}
                onChange={(e) => {
                  setState({
                    ...state,
                    newPartToPart: {
                      ...state.newPartToPart,
                      programRun: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  });
                }}
                placeholder="Program name..."
              />
            </div>
          </div>
          <div>
            <strong>Machine Type</strong>
            <Select
              onChange={(selected) =>
                setState({
                  ...state,
                  newPartToPart: {
                    ...state.newPartToPart,
                    processType: (
                      selected as {
                        label: string;
                        value: ProcessType;
                      }
                    ).value,
                  },
                })
              }
              options={partToPartProcessTypes?.map((processType) => ({
                label: processType.name,
                value: processType,
              }))}
            />
          </div>
          <div>
            <strong>Time</strong>
            <div className="d-flex">
              <div className="flex-grow-1">
                Hour
                <FormControl
                  value={timeHour}
                  onChange={(e) =>
                    setTimeHour(
                      isNaN(parseInt(e.target.value))
                        ? timeHour
                        : parseInt(e.target.value)
                    )
                  }
                  placeholder="Hour..."
                />
              </div>
              <div className="flex-grow-1">
                Minute
                <FormControl
                  value={timeMins}
                  onChange={(e) =>
                    setTimeMins(
                      isNaN(parseInt(e.target.value)) ||
                        parseInt(e.target.value) > 59
                        ? timeMins
                        : parseInt(e.target.value)
                    )
                  }
                  placeholder="Min..."
                />
              </div>
              <div className="flex-grow-1">
                Second
                <FormControl
                  value={timeSecs}
                  onChange={(e) =>
                    setTimeSecs(
                      isNaN(parseInt(e.target.value)) ||
                        parseInt(e.target.value) > 59
                        ? timeSecs
                        : parseInt(e.target.value)
                    )
                  }
                  placeholder="Secs..."
                />
              </div>
            </div>
          </div>
          Total time: {timeHour < 10 ? `0${timeHour}` : `${timeHour}`}:
          {timeMins < 10 ? `0${timeMins}` : `${timeMins}`}:
          {timeSecs < 10 ? `0${timeSecs}` : `${timeSecs}`}{" "}
          <strong>({timeHour * 3600 + timeMins * 60 + timeSecs} secs)</strong>
          <hr />
          <div className="d-flex">
            <strong>Plate Dimensions</strong>
            <div>
              Length
              <Form.Control
                value={state.newPartToPart.length}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPart: {
                      ...state.newPartToPart,
                      length: isNaN(parseFloat(e.target.value))
                        ? 0
                        : parseFloat(e.target.value),
                    },
                  })
                }
                placeholder="Length..."
              />
            </div>
            <div>
              Width
              <Form.Control
                value={state.newPartToPart.width}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPart: {
                      ...state.newPartToPart,
                      width: isNaN(parseFloat(e.target.value))
                        ? 0
                        : parseFloat(e.target.value),
                    },
                  })
                }
                placeholder="Width..."
              />
            </div>
            <div>
              Height
              <Form.Control
                value={state.newPartToPart.height}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPart: {
                      ...state.newPartToPart,
                      height: isNaN(parseFloat(e.target.value))
                        ? 0
                        : parseFloat(e.target.value),
                    },
                  })
                }
                placeholder="Height..."
              />
            </div>
          </div>
          <hr />
          <div>
            <strong>Parts produced:</strong>
          </div>
          <form>
            <div className="d-flex">
              <Form.Control
                value={state.newPartToPartDetailItemPartNameToCheck}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPartDetailItemPartNameToCheck: e.target.value,
                  })
                }
                placeholder="Part Name..."
              />
              <Button type="submit" onClick={handleCheckNewPartToPartItem}>
                Check
              </Button>
            </div>
          </form>
          {state.newPartToPartDetail.item ? (
            <div className="my-2">
              <strong>Part ID: {state.newPartToPartDetail.item.id}</strong> -{" "}
              {state.newPartToPartDetail.item.partName}
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex">
            <strong>Part Dimensions</strong>
            <div>
              Length
              <Form.Control
                value={state.newPartToPartDetail.length}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPartDetail: {
                      ...state.newPartToPartDetail,
                      length: isNaN(parseFloat(e.target.value))
                        ? 0
                        : parseFloat(e.target.value),
                    },
                  })
                }
                placeholder="Length..."
              />
            </div>
            <div>
              Width
              <Form.Control
                value={state.newPartToPartDetail.width}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPartDetail: {
                      ...state.newPartToPartDetail,
                      width: isNaN(parseFloat(e.target.value))
                        ? 0
                        : parseFloat(e.target.value),
                    },
                  })
                }
                placeholder="Width..."
              />
            </div>
          </div>
          <div className="d-flex my-2">
            <div>
              Qty Needed / Unit
              <Form.Control
                value={state.newPartToPartDetail.qtyNeeded}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPartDetail: {
                      ...state.newPartToPartDetail,
                      qtyNeeded: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  })
                }
                placeholder="Qty needed..."
              />
            </div>
            <div>
              Qty Produced / Program
              <Form.Control
                value={state.newPartToPartDetail.qtyProduced}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPartToPartDetail: {
                      ...state.newPartToPartDetail,
                      qtyProduced: isNaN(parseInt(e.target.value))
                        ? 0
                        : parseInt(e.target.value),
                    },
                  })
                }
                placeholder="Qty produced..."
              />
            </div>
          </div>
          <div>
            <strong>Material Type</strong>
            <Form.Control
              as="select"
              // value={state.newPartToPartDetail.material}
              onChange={(e) =>
                setState({
                  ...state,
                  newPartToPartDetail: {
                    ...state.newPartToPartDetail,
                    material: e.target.value,
                    materialType:
                      appState?.materialTypes.find(
                        (materialType) =>
                          `${materialType.id}` === e.target.value
                      ) ?? null,
                  },
                })
              }
              placeholder="Material Type..."
            >
              <option selected disabled>
                {state.newPartToPartDetail.materialType?.name ?? "None"}
              </option>
              {(appState?.materialTypes ?? []).map((materialType) => {
                return (
                  <option value={materialType.id ?? undefined}>
                    {materialType.name}
                  </option>
                );
              })}
            </Form.Control>
          </div>
          <div className="d-flex my-2">
            <Button
              onClick={handleAddNewPartToPartDetail}
              disabled={
                !state.newPartToPartDetail.item ||
                state.newPartToPartDetail.length === 0 ||
                state.newPartToPartDetail.width === 0 ||
                state.newPartToPartDetail.qtyNeeded === 0 ||
                state.newPartToPartDetail.qtyProduced === 0 ||
                state.newPartToPart.partToPartDetails.filter(
                  (partToPartDetail) =>
                    partToPartDetail.item?.id ===
                    state.newPartToPartDetail.item?.id
                ).length > 0
                // state.newPartToPartDetail.material === ""
              }
              className="flex-grow-1"
            >
              Insert
            </Button>
          </div>
          <Table size="sm">
            <thead>
              <th>Part ID</th>
              <th>Part Name</th>
              <th>Length</th>
              <th>Width</th>
              <th>Qty Needed</th>
              <th>Qty Produced</th>
              <th>Material</th>
            </thead>
            <tbody>
              {state.newPartToPart.partToPartDetails.map((partToPartDetail) => {
                return (
                  <tr>
                    <td>{partToPartDetail.item?.id}</td>
                    <td>{partToPartDetail.item?.partName}</td>
                    <td>{partToPartDetail.length}</td>
                    <td>{partToPartDetail.width}</td>
                    <td>{partToPartDetail.qtyNeeded}</td>
                    <td>{partToPartDetail.qtyProduced}</td>
                    <td>{partToPartDetail.materialType?.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="my-2">
            <Button
              disabled={
                state.newPartToPart.length === 0 ||
                state.newPartToPart.programRun === 0 ||
                state.newPartToPart.processType === null ||
                state.newPartToPart.width === 0 ||
                state.newPartToPart.height === 0 ||
                state.newPartToPart.partToPartDetails.length === 0
              }
              onClick={handleAddNewPartToPart}
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* FEP Modal */}
      <Modal
        show={state.fepMultiModalShow}
        onHide={() => setState({ ...state, fepMultiModalShow: false })}
      >
        <Modal.Header closeButton>
          <Modal.Title>FEP ({state.newFepMulti.type})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            Part Name
            <form>
              <div className="d-flex flex-grow-1 ml-2">
                <TextField
                  variant="outlined"
                  onBlur={(e) => {
                    handleChangeFepMultiItemPartName(e);
                  }}
                  placeholder="Part name..."
                />
                <Button type="submit" onClick={handleCheckFepMultiItemPartName}>
                  Check
                </Button>
              </div>
            </form>
          </div>
          <div>
            {state.newFepMulti.item ? (
              <>
                <strong>Selected item:</strong> ID: {state.newFepMulti.item.id}{" "}
                - {state.newFepMulti.item.partName}
              </>
            ) : (
              <></>
            )}
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <div>
              Qty
              <div className="d-flex flex-grow-1 ml-2">
                <Form.Control
                  value={state.newFepMulti.qty}
                  onChange={(e) =>
                    setState({
                      ...state,
                      newFepMulti: {
                        ...state.newFepMulti,
                        qty: isNaN(parseInt(e.target.value))
                          ? 0
                          : parseInt(e.target.value),
                      },
                    })
                  }
                  placeholder="Qty..."
                />
              </div>
            </div>
            <div>
              Length
              <div className="d-flex flex-grow-1 ml-2">
                <Form.Control
                  value={state.newFepMulti.length}
                  placeholder="Length..."
                  onChange={(e) =>
                    setState({
                      ...state,
                      newFepMulti: {
                        ...state.newFepMulti,
                        length: isNaN(parseFloat(e.target.value))
                          ? 0
                          : parseFloat(e.target.value),
                      },
                    })
                  }
                />
              </div>
            </div>
            <div>
              Width
              <div className="d-flex flex-grow-1 ml-2">
                <Form.Control
                  value={state.newFepMulti.width}
                  placeholder="Width..."
                  onChange={(e) =>
                    setState({
                      ...state,
                      newFepMulti: {
                        ...state.newFepMulti,
                        width: isNaN(parseFloat(e.target.value))
                          ? 0
                          : parseFloat(e.target.value),
                      },
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-1">
            {state.newFepMulti.type === "FEP" ? (
              <div className="d-flex">
                Assy?
                <Form.Check
                  checked={
                    state.newFepMulti.fepDetails.find(
                      (fepDetail) => fepDetail.processType?.name === "Assembly"
                    )
                      ? true
                      : false
                  }
                  onClick={handleCheckFepAssy}
                />
              </div>
            ) : (
              <></>
            )}
            {/* {state.newFepMulti.fepDetails.find(fepDetail => fepDetail.processType?.name === "Assembly")
              ? <div></div>
              : <div> 
                  <div className="d-flex">
                    Cutout?
                    <Form.Check checked={state.newFepMulti.type === "CUTOUT"} onClick={handleCheckFepCutout} />
                  </div>
                </div>
            } */}
          </div>
          {state.newFepMulti.fepDetails.find(
            (fepDetail) => fepDetail.processType?.name === "Assembly"
          ) ? (
            <div></div>
          ) : (
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                B?
                <Form.Check
                  checked={
                    state.newFepMulti.fepDetails.find(
                      (fepDetail) => fepDetail.processType?.name === "Bend"
                    )
                      ? true
                      : false
                  }
                  onClick={handleCheckFepBend}
                />
              </div>
              {state.newFepMulti.type === "FEP" ? (
                <div className="d-flex">
                  W&G?
                  <Form.Check
                    checked={
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Weld"
                      )
                        ? true
                        : false
                    }
                    onClick={handleCheckFepWeldGrind}
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex">
                C?
                <Form.Check
                  checked={
                    state.newFepMulti.fepDetails.find(
                      (fepDetail) => fepDetail.processType?.name === "Coat"
                    )
                      ? true
                      : false
                  }
                  onClick={handleCheckFepCoat}
                />
              </div>
            </div>
          )}
          {state.newFepMulti.type === "FEP" ? (
            <div className="d-flex align-items-center">
              Parent
              <Select
                onChange={(selected) =>
                  handleChangeFepMultiParent(
                    selected as { label: string; value: FepMulti }
                  )
                }
                className="flex-grow-1 ml-2"
                options={
                  fep
                    ? (() => {
                        const flattenedFepMultis = [] as {
                          order: number[];
                          fepMulti: FepMulti;
                        }[];

                        const appendFepMulti = (
                          fepMulti: FepMulti,
                          order: number[]
                        ) => {
                          flattenedFepMultis.push({
                            order: order,
                            fepMulti: fepMulti,
                          });

                          fepMulti.children.forEach((fepMulti, i) => {
                            appendFepMulti(fepMulti, [...order, i + 1]);
                          });
                        };

                        fep.fepMultis
                          .filter(
                            (fepMulti) =>
                              fepMulti.type === state.newFepMulti.type
                          )
                          .forEach((fepMulti, i) => {
                            appendFepMulti(fepMulti, [i + 1]);
                          });

                        return flattenedFepMultis;
                      })().map((fepMulti) => {
                        return {
                          label: `${fepMulti.order.join(".")} - ${
                            fepMulti.fepMulti.item?.partName
                          }`,
                          value: fepMulti.fepMulti,
                        };
                      })
                    : []
                }
                placeholder="Parent..."
              />
            </div>
          ) : (
            <></>
          )}
          {state.newFepMulti.fepDetails.find(
            (fepDetail) => fepDetail.processType?.name === "Bend"
          ) ? (
            <>
              <hr />
              <div className="d-flex align-items-center">
                Bend Strokes
                <div>
                  <Form.Control
                    value={
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Bend"
                      )?.bendStrokes
                        ? state.newFepMulti.fepDetails.find(
                            (fepDetail) =>
                              fepDetail.processType?.name === "Bend"
                          )?.bendStrokes
                        : 0
                    }
                    onChange={handleChangeNewFepMultiBendStroke}
                    type="number"
                    placeholder="Bend strokes..."
                    className="flex-grow-1 ml-2"
                  />
                  <strong className="ml-2">
                    Bend time: (
                    {
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Bend"
                      )?.bendStrokes
                    }
                    ) (
                    {secondsToHms(
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Bend"
                      )?.time
                    )}
                    )
                  </strong>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {state.newFepMulti.fepDetails.find(
            (fepDetail) => fepDetail.processType?.name === "Weld"
          ) &&
          state.newFepMulti.fepDetails.find(
            (fepDetail) => fepDetail.processType?.name === "Grind"
          ) ? (
            <>
              <hr />
              <div>
                <div className="d-flex align-items-center">
                  Weld Value
                  <div>
                    <Form.Control
                      value={
                        state.newFepMulti.fepDetails.find(
                          (fepDetail) => fepDetail.processType?.name === "Weld"
                        )?.weldValue
                          ? state.newFepMulti.fepDetails.find(
                              (fepDetail) =>
                                fepDetail.processType?.name === "Weld"
                            )?.weldValue
                          : 0
                      }
                      onChange={handleChangeNewFepMultiWeldValue}
                      type="number"
                      placeholder="Weld value..."
                      className="flex-grow-1 ml-2"
                    />
                    {/* <strong className="ml-2">Weld time:</strong>
                        <strong className="ml-2">Grind time:</strong> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {state.newFepMulti.fepDetails.find(
            (fepDetail) => fepDetail.processType?.name === "Coat"
          ) ? (
            <>
              <hr />
              <div className="d-flex">
                RAL:
                <div className="d-flex flex-column w-100">
                  <Select
                    className="ml-2 flex-grow-1"
                    options={appState?.rals.map((ral) => ({
                      label: ral.name,
                      value: ral,
                    }))}
                    onChange={(selected) =>
                      handleChangeFepMultiCoatingRal(
                        selected as { label: string; value: Ral }
                      )
                    }
                  />
                  <strong className="ml-2">
                    Coat time: (
                    {secondsToHms(
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Coat"
                      )?.time
                    )}
                    ){" "}
                  </strong>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="my-2 flex-grow-1">
            <Button
              onClick={handleAddNewFepMulti}
              disabled={
                !state.newFepMulti.item ||
                state.newFepMulti.qty === 0 ||
                (!state.newFepMulti.fepDetails.find(
                  (fepDetail) => fepDetail.processType?.name === "Assembly"
                ) &&
                  [
                    state.newFepMulti.fepDetails.find(
                      (fepDetail) => fepDetail.processType?.name === "Bend"
                    ) ||
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Weld"
                      ) ||
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Grind"
                      ) ||
                      state.newFepMulti.fepDetails.find(
                        (fepDetail) => fepDetail.processType?.name === "Coat"
                      ),
                  ].filter((fepMulti) => fepMulti).length === 0)
              }
            >
              Add
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FepMultiForm;
