import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  fetchBomLeveledGroupsProto,
  fetchBomLeveledsProtoSimple,
  fetchExtCrmPurchaseOrdersProto,
  fetchJobsProtoSimple,
  intlFormat,
} from "../../../helpers";
import {
  CrmPurchaseOrders,
  PpicBomLeveledGroups,
  PpicBomLeveleds,
  PpicJobs,
} from "../../../masterbigsystem";
import { Link } from "react-router-dom";
import { Edit } from "@material-ui/icons";

type ViewMode = "Table" | "Report Perhitungan";
const viewModes: ViewMode[] = ["Table", "Report Perhitungan"];

const BomGroup = () => {
  const ctx = useContext(AppContext);
  const bomGroups = useRef(PpicBomLeveledGroups.fromPartial({}));
  const bomLeveleds = useRef(PpicBomLeveleds.fromPartial({}));
  const [, refresh] = useState(false);
  const selectedViewMode = useRef("Table" as ViewMode);
  const jobs = useRef(PpicJobs.fromPartial({}));
  const pos = useRef(CrmPurchaseOrders.fromPartial({}));
  const posLoading = useRef(false);
  const render = () => {
    refresh((n) => !n);
  };

  const handleFetchBomGroupsData = async () => {
    const d = await fetchBomLeveledGroupsProto({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      bomGroups.current = d;
      render();
    }
  };

  const handleFetchCrmPurchaseOrders = async () => {
    posLoading.current = true;
    render();
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
      // withProducts: true,
    });

    if (d) {
      pos.current = d;
    }

    posLoading.current = false;
    render();
  };

  const handleFetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
      withProducts: true,
      withPurchaseOrders: true,
      all: true,
    });

    if (d) {
      jobs.current = d;
      render();
    }
  };

  const handleFetchBomLeveledsProtoSimpleData = async () => {
    const d = await fetchBomLeveledsProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      bomLeveleds.current = d;
      render();
    }
  };

  const handleInit = async () => {
    handleFetchBomGroupsData();
    handleFetchBomLeveledsProtoSimpleData();
    handleFetchJobsData();
    handleFetchCrmPurchaseOrders();
  };

  useEffect(() => {
    handleInit();
  }, []);
  return (
    <>
      <div>
        <div>
          <div className="d-flex">
            <h4>BOM Group</h4>
            <div>
              <Link to={`/bomgroups/new`}>
                <button className="btn btn-sm btn-primary">Add</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="d-flex">
          {viewModes.map((m) => {
            return (
              <>
                <button
                  className={`btn ${
                    selectedViewMode.current === m
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                  onClick={() => {
                    selectedViewMode.current = m;
                    render();
                  }}
                >
                  {m}
                </button>
              </>
            );
          })}
        </div>
        {selectedViewMode.current === "Table" ? (
          <>
            <div
              className="overflow-auto border border-dark"
              style={{ height: `65vh` }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["#", "Name", "Quote", "BOMs", "Edit"].map((h) => {
                    return (
                      <>
                        <th className="bg-dark text-light p-0 m-0">{h}</th>
                      </>
                    );
                  })}
                </tr>
                {bomGroups.current.groups
                  .filter((g) => !g.masterJavaBaseModel?.hidden)
                  .map((g, i) => {
                    const foundPO = pos.current.purchaseOrders.find(
                      (p) => `${p.quoteId}` === `${g.quoteId}`
                    );

                    return (
                      <>
                        <tr>
                          <td className="border border-dark p-0 m-0">
                            {i + 1}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {g.name && g.name !== "" ? g.name : ""}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {posLoading.current
                              ? `Loading...`
                              : foundPO?.quoteNumber}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            <ol>
                              {g.items.map((i) => {
                                return (
                                  <>
                                    <li>
                                      <div>
                                        <small>
                                          {
                                            bomLeveleds.current.bomLeveleds.find(
                                              (b) =>
                                                `${b.masterJavaBaseModel?.id}` ===
                                                `${i.bomLeveledId}`
                                            )?.name
                                          }

                                          {/* {i.bomLeveledId} */}
                                        </small>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </td>
                          <td className="border border-dark p-0 m-0">
                            <Link
                              to={`/bomgroups/${g.masterJavaBaseModel?.id}`}
                            >
                              <button className="btn btn-sm btn-primary">
                                <Edit />
                              </button>
                            </Link>{" "}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
        {selectedViewMode.current === "Report Perhitungan" ? (
          <>
            <div
              className="overflow-auto border border-dark"
              style={{ height: `65vh` }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "BOM Group",
                    "Cust Name",
                    "PO No",
                    "PO Date",
                    "Prod Name",
                    "Qty",
                    "Unit Price",
                    "Quotation ID",
                    "Qty",
                    "Unit Price",
                    "BOM v0",
                    "BOM Akhir -1",
                    "BOM Akhir",
                    "Δ1 (PO-QUO)",
                    "Δ2 (PO-BOMV0)",
                    "Δ3 (QUO-BOMV0)",
                    "Δ4 (PO-BOMVFINAL)",
                    "Δ5 (QUO-BOMVFINAL)",
                    "Δ6 (PO-MR)",
                    "Δ7 (BOMVFINAL-MR)",
                    "Δ8 (Quo < 15%)",
                    "Δ9 (MR-BOMVFINAL)",
                  ].map((h) => {
                    return (
                      <>
                        <th className="bg-dark text-light p-0 m-0">{h}</th>
                      </>
                    );
                  })}
                </tr>
                {bomGroups.current.groups
                  .filter((g) => !g.masterJavaBaseModel?.hidden)
                  .map((g, i) => {
                    const foundJobs = jobs.current.jobs.filter((j) =>
                      j.jobBomLeveleds.find((l) =>
                        g.items.find(
                          (gx) => `${gx.bomLeveledId}` === `${l.bomLeveledId}`
                        )
                      )
                    );

                    const foundPOs = pos.current.purchaseOrders.filter((p) =>
                      foundJobs
                        .flatMap((j) => j.jobPurchaseOrders)
                        .find((px) => `${px.extPurchaseOrderId}` === `${p.id}`)
                    );

                    const totalPOAmount = foundPOs.reduce(
                      (acc, p) =>
                        acc +
                        p.products.reduce(
                          (accx, px) =>
                            accx + (px.qty ?? 0) * (px.unitPrice ?? 0),
                          0.0
                        ),
                      0.0
                    );

                    const bomV0 = bomLeveleds.current.bomLeveleds.find(
                      (l) =>
                        `${l.masterJavaBaseModel?.id}` ===
                        `${g.items?.[0]?.bomLeveledId}`
                    );

                    const bomV1Minus1 =
                      (g.items.length ?? 0) > 2
                        ? bomLeveleds.current.bomLeveleds.find(
                            (l) =>
                              `${l.masterJavaBaseModel?.id}` ===
                              `${g.items?.slice(-2)?.[0]?.bomLeveledId}`
                          )
                        : null;

                    const bomV1 =
                      (g.items.length ?? 0) > 1
                        ? bomLeveleds.current.bomLeveleds.find(
                            (l) =>
                              `${l.masterJavaBaseModel?.id}` ===
                              `${g.items?.slice(-1)?.[0]?.bomLeveledId}`
                          )
                        : null;

                    const delta1 = bomV1Minus1
                      ? (bomV1Minus1?.snapshotPriceIdr ?? 0) -
                        (bomV0?.snapshotPriceIdr ?? 0)
                      : 0;

                    const delta2 = bomV1
                      ? (bomV1?.snapshotPriceIdr ?? 0) -
                        (bomV0?.snapshotPriceIdr ?? 0)
                      : 0;

                    const foundPO = pos.current.purchaseOrders.find(
                      (p) => `${p.quoteId}` === `${g.quoteId}`
                    );

                    return (
                      <>
                        <tr>
                          <td className="border border-dark p-0 m-0">
                            {i + 1}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {g.name}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {posLoading.current
                              ? `Loading...`
                              : foundPOs.map((p) => p.account?.name).join(", ")}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {posLoading.current
                              ? `Loading...`
                              : foundPOs
                                  .map((p) => p.purchaseOrderNumber)
                                  .join(", ")}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {posLoading.current
                              ? `Loading...`
                              : foundPOs
                                  .map((p) =>
                                    intlFormat({
                                      date: p.date,
                                      dateStyle: "medium",
                                    })
                                  )
                                  .join(", ")}
                          </td>

                          <td className="border border-dark p-0 m-0">
                            {foundJobs.map((j) => j.name)}
                          </td>
                          <td className="border border-dark p-0 m-0">1</td>
                          <td className="border border-dark p-0 m-0">
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                              }).format(totalPOAmount)}
                            </strong>
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {posLoading.current
                              ? `Loading...`
                              : foundPO?.quoteNumber}
                          </td>
                          <td className="border border-dark p-0 m-0">1</td>
                          <td className="border border-dark p-0 m-0">
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                              }).format(foundPO?.quoteAmount ?? 0)}
                            </strong>
                          </td>
                          <td className="border border-dark p-0 m-0">
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(bomV0?.snapshotPriceIdr ?? 0)}
                            </strong>
                          </td>
                          <td
                            className={`border border-dark p-0 m-0 ${
                              !bomV1Minus1 ||
                              (bomV1Minus1?.snapshotPriceIdr ?? 0) >
                                (bomV0?.snapshotPriceIdr ?? 0)
                                ? `text-danger`
                                : `text-success`
                            }`}
                          >
                            <strong>
                              {bomV1Minus1 ? (
                                <>
                                  {" "}
                                  IDR{" "}
                                  {Intl.NumberFormat("id-ID", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(bomV1Minus1?.snapshotPriceIdr ?? 0)}
                                </>
                              ) : (
                                <>None</>
                              )}
                            </strong>
                          </td>
                          <td
                            className={`border border-dark p-0 m-0 ${
                              !bomV1 ||
                              (bomV1?.snapshotPriceIdr ?? 0) >
                                (bomV0?.snapshotPriceIdr ?? 0)
                                ? `text-danger`
                                : `text-success`
                            }`}
                          >
                            <strong>
                              {bomV1 ? (
                                <>
                                  {" "}
                                  IDR{" "}
                                  {Intl.NumberFormat("id-ID", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(bomV1?.snapshotPriceIdr ?? 0)}
                                </>
                              ) : (
                                <>None</>
                              )}
                            </strong>
                          </td>

                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                totalPOAmount - (foundPO?.quoteAmount ?? 0)
                              )}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                totalPOAmount - (bomV0?.snapshotPriceIdr ?? 0)
                              )}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                (foundPO?.quoteAmount ?? 0) -
                                  (bomV0?.snapshotPriceIdr ?? 0)
                              )}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(totalPOAmount - (bomV1?.snapshotPriceIdr??0))}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format( (foundPO?.quoteAmount ?? 0) - (bomV1?.snapshotPriceIdr??0))}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(0)}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(0)}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(0)}
                            </strong>
                          </td>
                          <td className={`border border-dark p-0 m-0`}>
                            <strong>
                              IDR{" "}
                              {Intl.NumberFormat("id-ID", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(0)}
                            </strong>
                          </td>

                          {/* <td
                            className={`border border-dark p-0 m-0 ${
                              (bomV1?.snapshotPriceIdr ?? 0) >
                              (bomV0?.snapshotPriceIdr ?? 0)
                                ? `text-danger`
                                : `text-success`
                            }`}
                          >
                            <strong>
                              {bomV1 ? (
                                <>
                                  {" "}
                                  IDR{" "}
                                  {Intl.NumberFormat("id-ID", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(bomV1?.snapshotPriceIdr ?? 0)}
                                </>
                              ) : (
                                <>None</>
                              )}
                            </strong>
                          </td>

                          <td
                            className={`border border-dark p-0 m-0 ${
                              delta1 < 0 ? `text-success` : `text-danger`
                            }`}
                          >
                            <strong>
                              {bomV1Minus1 ? (
                                <>
                                  IDR {delta1 >= 0 ? `+` : ``}
                                  {Intl.NumberFormat("id-ID", {
                                    minimumFractionDigits: 2,
                                  }).format(delta1 ?? 0)}{" "}
                                  (
                                  {Intl.NumberFormat("id-ID", {
                                    maximumFractionDigits: 1,
                                  }).format(
                                    ((delta1 ?? 0) /
                                      ((bomV0?.snapshotPriceIdr ?? 0) !== 0
                                        ? bomV0?.snapshotPriceIdr ?? 0
                                        : 1)) *
                                      100
                                  )}
                                  %)
                                </>
                              ) : (
                                <>None</>
                              )}
                            </strong>
                          </td>

                          <td
                            className={`border border-dark p-0 m-0 ${
                              delta2 < 0 ? `text-success` : `text-danger`
                            }`}
                          >
                            <strong>
                              {bomV1 ? (
                                <>
                                  IDR {delta2 >= 0 ? `+` : ``}
                                  {Intl.NumberFormat("id-ID", {
                                    minimumFractionDigits: 2,
                                  }).format(delta2 ?? 0)}{" "}
                                  (
                                  {Intl.NumberFormat("id-ID", {
                                    maximumFractionDigits: 1,
                                  }).format(
                                    ((delta2 ?? 0) /
                                      ((bomV0?.snapshotPriceIdr ?? 0) !== 0
                                        ? bomV0?.snapshotPriceIdr ?? 0
                                        : 1)) *
                                      100
                                  )}
                                  %)
                                </>
                              ) : (
                                <>None</>
                              )}
                            </strong>
                          </td> */}

                          {/* <td className="border border-dark p-0 m-0">1</td>
                          <td className="border border-dark p-0 m-0">
                            {" "}
                            IDR{" "}
                            {Intl.NumberFormat("id-ID", {
                              minimumFractionDigits: 2,
                            }).format(0)}
                          </td>
                          <td className="border border-dark p-0 m-0"></td>
                          <td className="border border-dark p-0 m-0">1</td>
                          <td className="border border-dark p-0 m-0">
                            {" "}
                            IDR{" "}
                            {Intl.NumberFormat("id-ID", {
                              minimumFractionDigits: 2,
                            }).format(0)}
                          </td> */}
                        </tr>
                      </>
                    );
                  })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default BomGroup;
