import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  fetchSimplifiedProcessTypes,
  fetchSimplifiedProcessTypesProto,
} from "../../../helpers";
import {
  PpicSimplifiedProcessType,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import { ExtUser, Machine, ProcessType } from "../../../models/model";
import {
  defaultMachinePic,
  defaultSimplifiedProcessType,
  initialMachine,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

interface SelectValue<T> {
  label: string;
  value: T;
}

const MachineFormPage = () => {
  const ctx = useContext(AppContext);
  // Fetches
  const [machine, setMachine] = useState<Machine>({ ...initialMachine });
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  // Selection
  const [selectedProcessType, setSelectedProcessType] =
    useState<SelectValue<Machine> | null>(null);

  const { id } = useParams() as { id: string };
  const [simplifiedProcessTypes, setSimplifiedProcessTypes] = useState(
    PpicSimplifiedProcessTypes.fromPartial({})
  );
  const [picDeleteIds, setPicDeleteIds] = useState<number[]>([]);
  const history = useHistory();

  useEffect(() => {
    console.log("test");
    if (id !== undefined) {
      const parsedId = parseInt(id);

      if (!isNaN(parsedId)) {
        fetchMachineData(parsedId);
      }
      fetchProcessTypes();
      fetchSimplifiedProcessTypesData();
    }
  }, []);

  const fetchSimplifiedProcessTypesData = async () => {
    const d = await fetchSimplifiedProcessTypesProto({ apiKey: ctx?.apiKey });

    if (d) {
      setSimplifiedProcessTypes(d);
    }
  };

  const fetchMachineData = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/machines/${id}`
      );
      if (response.status !== 200) throw "Error fetching machine data";

      const machine = await response.json();
      setMachine(machine);
      setSelectedProcessType({
        label: machine?.processType?.name,
        value: { ...machine?.processType },
      });

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  const fetchProcessTypes = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/processtypes`
      );
      if (response.status !== 200) throw "Error fetching process types";

      setProcessTypes(await response.json());

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
      console.log(e);
      return [];
    }
  };

  const handleSave = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/machines`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(machine),
        }
      );
      if (response.status !== 201) throw "Saving machine error ";
      setRequestStatus(RequestStatus.Success);

      // Emty
      await Promise.all(
        picDeleteIds.map(async (id) => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_BASE_URL}/machinepics/${id}/empty`,
              {
                method: "delete",
              }
            );
          } catch (e) {}
        })
      );

      history.push("/machines");
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
      console.log(e);
    }
  };

  return (
    <Box>
      <Box my={1} display="flex">
        <Link to="/machines">
          <Button color="primary">
            <ChevronLeftIcon />
            Back
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
        >
          Save
        </Button>
        {requestStatus === RequestStatus.Loading ? (
          <Box mx={1}>
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Box display="flex">
        <TextField
          label="Machine"
          value={machine?.name}
          onChange={(evt) => setMachine({ ...machine, name: evt.target.value })}
        />
        <Box width={500} mx={1}>
          <TextField
            label="Description"
            fullWidth
            value={machine?.description}
            onChange={(evt) =>
              setMachine({ ...machine, description: evt.target.value })
            }
          />
        </Box>
      </Box>

      <div className="d-flex" style={{ width: "100%" }}>
        <strong>Regular (old) process type: </strong>
        <div className="flex-grow-1">
          <Select
            options={processTypes.map((processType) => ({
              label: processType.name,
              value: processType,
            }))}
            value={{
              label: machine.processType?.name,
              value: machine.processType,
            }}
            onChange={(selected) =>
              setMachine({
                ...machine,
                processType: {
                  ...(selected as { label: string; value: ProcessType }).value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="d-flex" style={{ width: "100%" }}>
        <strong>Simplified (new) process type: </strong>
        <div className="flex-grow-1">
          <Select
            options={simplifiedProcessTypes.processTypes}
            value={simplifiedProcessTypes.processTypes.find(
              (pt) =>
                `${pt.masterJavaBaseModel?.id}` ===
                `${machine.simpleProcessType?.id}`
            )}
            getOptionLabel={(pt) => pt.name ?? "No name"}
            onChange={(v) => {
              const val = v as unknown as PpicSimplifiedProcessType;
              const foundProcessType = simplifiedProcessTypes.processTypes.find(
                (pt) =>
                  `${pt.masterJavaBaseModel?.id}` ===
                  `${val.masterJavaBaseModel?.id}`
              );

              console.log(
                val,
                simplifiedProcessTypes.processTypes,
                foundProcessType
              );

              if (foundProcessType) {
                setMachine({
                  ...machine,
                  simpleProcessType: {
                    ...defaultSimplifiedProcessType,
                    id: isNaN(parseInt(val.masterJavaBaseModel?.id ?? ""))
                      ? machine.simpleProcessType?.id ?? null
                      : parseInt(val.masterJavaBaseModel?.id ?? ""),
                  },
                });
              }
            }}
          />
        </div>
      </div>
      <hr />
      <div>
        <strong>PICs:</strong>
      </div>
      <div>
        <Select
          options={ctx?.extUsers}
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          onChange={(v) => {
            const val = v as unknown as ExtUser;

            if (!machine.pics?.find((p) => `${p.extUserId}` === `${val.id}`)) {
              setMachine({
                ...machine,
                pics: machine.pics
                  ? [
                      ...machine.pics,
                      { ...defaultMachinePic, extUserId: val.id, uuid: v4() },
                    ]
                  : machine.pics,
              });
            }
          }}
        />
      </div>
      <div>
        <ol>
          {machine.pics?.map((p) => {
            const fUser = ctx?.extUsers.find(
              (u) => `${u.id}` === `${p.extUserId}`
            );
            return (
              <>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setPicDeleteIds([...picDeleteIds, p.id ?? 0]);
                      setMachine({
                        ...machine,
                        pics: machine.pics
                          ? machine.pics.filter(
                              (px) => `${px.extUserId}` !== `${p.extUserId}`
                            )
                          : machine.pics,
                      });
                    }}
                  >
                    <div>
                      {fUser?.name} ({fUser?.departmentName})
                    </div>
                  </div>
                </li>
              </>
            );
          })}
        </ol>
      </div>

      {/* <SyncAutocompleteName 
        options={processTypes}
        value={null}
        name="name"
        label="Process Type"
        width={300}
        onChange={(value) => setSelectedProcessType(value)}
      /> */}

      {/* <Box mt={1}>
        Active: <Switch checked={machine.status === 'active' ? true : false} />
      </Box>

      <Box my={1}>
        Logs:
        <Box my={1}>
          <List>
            {machine.machineLogs.map(machineLog => (
              <ListItem>{machineLog.dateTime} {machineLog.remark}</ListItem>
            ))}
          </List>
        </Box>
      </Box> */}
    </Box>
  );
};

export default MachineFormPage;
