import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Paper,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { blue, green, grey, lightBlue, red } from "@material-ui/core/colors";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  compareDateColor,
  compareDeadlineInterval,
  getUserFromApiKey,
  makeDateString,
  makeReadableDateString,
} from "../../../helpers";
import { CrudInfo, initialCrudInfoNoData } from "../../../models/CrudInfo";
import {
  Deadline,
  DeadlineDetail,
  IndividualEntity,
  Job,
} from "../../../models/model";
import { initialDeadline } from "../../../models/modelinitials";
import { initialPageNoContent } from "../../../models/Page";
import { RequestStatus } from "../../../models/RequestStatus";

const MpsRev = () => {
  const apiKey = localStorage.getItem("apiKey");
  const user = getUserFromApiKey(apiKey ? apiKey : "");

  const [crudState, setCrudState] = useState<CrudInfo<Job>>({
    ...initialCrudInfoNoData,
    data: { ...initialPageNoContent, content: [] },
  });
  const [filterClosed, setFilterClosed] = useState<{
    label: string;
    value: string;
  }>({
    label: "Outstanding",
    value: "false",
  });

  // Summary/detailed
  const [detailed, setDetailed] = useState<boolean>(false);

  // Misc & filters
  const [customers, setCustomers] = useState<IndividualEntity[]>([]);
  const [customer, setCustomer] = useState<IndividualEntity | null>(null);

  // Priority
  const [priority, setPriority] = useState<string>("");

  const [deadlineInfoOpen, setDeadlineInfoOpen] = useState<boolean>(false);
  const [deadlineInfo, setDeadlineInfo] = useState<{
    deadline: Deadline | null;
    deadlineDetail: DeadlineDetail | null;
    jobId: number;
    type: "plan" | "actual";
    clear: boolean;
  }>({
    deadline: null,
    deadlineDetail: null,
    jobId: 0,
    type: "plan",
    clear: false,
  });

  const currentDate = new Date();

  useEffect(() => {
    if (crudState.requestStatus === RequestStatus.NotAsked) {
      fetchJobs(filterClosed.value);
    }

    fetchCustomers();
  }, [crudState.requestStatus]);

  const fetchCustomers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/individualentities`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Error fetching customers.";
      setCustomers(await response.json());
    } catch (e) {
      console.error(e);
    }
  };

  const fetchJobs = async (closedFilter: string) => {
    if (crudState.requestStatus === RequestStatus.NotAsked) {
      try {
        setCrudState({ ...crudState, requestStatus: RequestStatus.Loading });

        const url = `
          ${process.env.REACT_APP_BASE_URL}/jobs/search/paged/mps
          ?name=${crudState.searchInput}
          &individualentityname=${encodeURI(customer ? customer.name : "")}
          &page=${crudState.currentPage}
          &perPage=${crudState.rowsPerPage}&closed=${closedFilter}
          &priority=${priority}
        `
          .split(" ")
          .join("");

        console.log("Result url: ", url);

        const response = await fetch(url);

        if (response.status !== 200) throw "Failed fetching jobs MPS";

        const jobsJson = await response.json();

        setCrudState({
          ...crudState,
          requestStatus: RequestStatus.Success,
          data: jobsJson,
        });
      } catch (e) {
        console.log(e);
        setCrudState({ ...crudState, requestStatus: RequestStatus.Error });
      }
    }
  };

  const TableCellBordered = styled(TableCell)({
    border: "1px solid black",
  });

  const CenteredTableCellBordered = (props: {
    colSpan?: number;
    rowSpan?: number;
    content: string;
    textColor?: string;
    backgroundColor?: string;
  }) => {
    return (
      <TableCellBordered
        colSpan={props.colSpan ? props.colSpan : 1}
        rowSpan={props.rowSpan ? props.rowSpan : 1}
        style={{
          color: props.textColor ? props.textColor : "black",
          whiteSpace: "nowrap",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: props.backgroundColor
              ? props.backgroundColor
              : "none",
            padding: 5,
          }}
        >
          {props.content}
        </div>
      </TableCellBordered>
    );
  };

  const handleChangeFilterClosed = (selected: {
    label: string;
    value: string;
  }) => {
    setFilterClosed({ ...selected });
    setCrudState({ ...crudState, requestStatus: RequestStatus.NotAsked });
  };

  const LastProcess = (props: { jobId: number }) => {
    // Find job based on job ID
    const job = crudState.data.content.find((job) => job.id === props.jobId);

    const names = [
      "Drawing",
      "Program",
      "BOM",
      "Layout",
      "SLD",
      "Zplant",
      "FW/LO",
      "Schema",
      "TL",
      "WL",
      "BOM Elec",
      "Cutout",
      "Nameplate",
      "Mimik",
      "Preparation Cable",
      "Preparation Panel",
      "Interconnection",
      "Busbar",
      "Panel",
      "Point to Point",
      "Test On",
      "NC Mechanical",
      "NC Electrical",
      "Validasi",
    ];

    const lastFoundDeadlineRev = names
      .filter((name) =>
        job?.deadlines
          ?.map((deadline) => deadline.deadlineDetails)
          .flat()
          .find(
            (deadlineDetail) =>
              deadlineDetail?.name === name && deadlineDetail.actual !== null
          )
      )
      .reverse();

    // const lastFoundDeadline = job?.deadlines
    //   .map(deadline => deadline.deadlineDetails)
    //   .flat()
    //   .reverse()
    //   .find(deadlineDetail => deadlineDetail?.actual !== null);

    return (
      <>
        Last:{" "}
        {lastFoundDeadlineRev && lastFoundDeadlineRev.length > 0 ? (
          lastFoundDeadlineRev[0]
        ) : (
          <span style={{ color: "red" }}>None</span>
        )}
      </>
    );
  };

  const PriorityComponent = (props: { priority: string }) => {
    switch (props.priority) {
      case "High":
        return (
          <div style={{ padding: 5, backgroundColor: "crimson" }}>
            <strong style={{ color: "white" }}>High</strong>
          </div>
        );

      case "Medium":
        return (
          <div style={{ padding: 5, backgroundColor: "goldenrod" }}>
            <strong style={{ color: "white" }}>Medium</strong>
          </div>
        );

      case "Low":
        return (
          <div style={{ padding: 5, backgroundColor: "seagreen" }}>
            <strong style={{ color: "white" }}>Low</strong>
          </div>
        );

      default:
        return <>None</>;
    }
  };

  const DeadlineDetailCell = (props: {
    jobId: number;
    deadline: Deadline | undefined;
    deadlineDetail: DeadlineDetail | undefined;
  }) => {
    if (props.deadline) {
      return (
        <TableCellBordered
          style={{
            backgroundColor: compareDateColor(new Date(), props.deadlineDetail),
            border:
              compareDateColor(new Date(), props.deadlineDetail) === "black"
                ? "1px solid white"
                : "1px solid black",
            whiteSpace: "nowrap",
            margin: 0,
            padding: 0,
          }}
        >
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {props.deadlineDetail?.plan ? (
              <>
                <strong>
                  {makeReadableDateString(new Date(props.deadlineDetail.plan))}{" "}
                  (P)
                </strong>
                <button>C</button>
              </>
            ) : (
              <button
                onClick={(e) => {
                  setDeadlineInfo({
                    ...deadlineInfo,
                    deadline: props.deadline ? props.deadline : null,
                    deadlineDetail: props.deadlineDetail
                      ? props.deadlineDetail
                      : null,
                    jobId: props.jobId,
                    type: "plan",
                  });
                  setDeadlineInfoOpen(true);
                }}
              >
                Add Plan
              </button>
            )}
          </div>
          <hr />
          <div
            style={{
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            {props.deadlineDetail?.actual ? (
              <>
                <strong>
                  {makeReadableDateString(
                    new Date(props.deadlineDetail.actual)
                  )}{" "}
                  (A)
                </strong>
                <button>C</button>
              </>
            ) : props.deadlineDetail?.plan ? (
              <button
                onClick={(e) => {
                  setDeadlineInfo({
                    ...deadlineInfo,
                    deadline: props.deadline ? props.deadline : null,
                    deadlineDetail: props.deadlineDetail
                      ? props.deadlineDetail
                      : null,
                    jobId: props.jobId,
                    type: "actual",
                  });
                  setDeadlineInfoOpen(true);
                }}
              >
                Add Actual
              </button>
            ) : (
              <></>
            )}
          </div>
        </TableCellBordered>
      );
    } else {
      return <></>;
    }
  };

  const handleSelectDeadlineDetail = (date: MaterialUiPickersDate) => {
    const newCrudState = { ...crudState };
    const foundDeadlineDetail = newCrudState.data.content
      .find((job) => job.id === deadlineInfo.jobId)
      ?.deadlines?.find((deadline) => deadline.id === deadlineInfo.deadline?.id)
      ?.deadlineDetails.find(
        (deadlineDetail) =>
          deadlineDetail.id === deadlineInfo.deadlineDetail?.id
      );

    if (foundDeadlineDetail) {
      if (deadlineInfo.type === "plan")
        foundDeadlineDetail.plan = makeDateString(date as Date);
      else foundDeadlineDetail.actual = makeDateString(date as Date);
    }

    setDeadlineInfoOpen(false);
  };

  const handleSave = async () => {
    setCrudState({ ...crudState, requestStatus: RequestStatus.Loading });

    try {
      await Promise.all(
        crudState.data.content
          .map((job) => job.deadlines)
          .flat()
          .map(async (deadline) => {
            return await Promise.all(
              deadline?.deadlineDetails.map(async (deadlineDetail) => {
                return await fetch(
                  `${process.env.REACT_APP_BASE_URL}/deadlinedetails`,
                  {
                    method: "POST",
                    headers: {
                      authorization: apiKey ? apiKey : "",
                      "content-type": "application/json",
                    },
                    body: JSON.stringify({
                      ...deadlineDetail,
                      deadline: { ...initialDeadline, id: deadline?.id ?? 0 },
                    }),
                  }
                );
              }) ?? []
            );
          })
      );

      window.location.reload();
    } catch (e) {
      setCrudState({ ...crudState, requestStatus: RequestStatus.Error });
    }
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>Master Production Schedule (Summary)</h3>
          <Button color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
          {crudState.requestStatus === RequestStatus.Loading ? (
            <CircularProgress disableShrink />
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Box>
            <TextField
              label="Job Name..."
              onChange={(evt) =>
                setCrudState({
                  ...crudState,
                  searchInput: evt.target.value,
                  requestStatus: RequestStatus.NotAsked,
                })
              }
            />
          </Box>
          {/* <Box mx={1}>
            <TextField 
              label="Customer Name..."
              onChange={evt => {
                setCustomerName(evt.target.value);
                setCrudState({...crudState, requestStatus: RequestStatus.NotAsked})
              }}
            />
          </Box> */}
          <div
            style={{ width: 300, marginRight: 10, marginLeft: 10, zIndex: 2 }}
          >
            <Select
              placeholder="Priority"
              options={["", "High", "Medium", "Low"].map((prio) => ({
                label: prio === "" ? "All" : prio,
                value: prio,
              }))}
              onChange={(selected) => {
                setPriority(
                  (selected as { label: string; value: string }).value
                );
                setCrudState({
                  ...crudState,
                  requestStatus: RequestStatus.NotAsked,
                });
              }}
            />
          </div>
          <Box width={300} style={{ zIndex: 2 }}>
            <Select
              placeholder="Customer..."
              options={[
                { label: "All", value: "" },
                ...customers.map((customer) => ({
                  label: customer.name,
                  value: customer,
                })),
              ]}
              onChange={(selected) => {
                setCustomer(
                  (selected as { label: string; value: IndividualEntity }).value
                );
                setCrudState({
                  ...crudState,
                  requestStatus: RequestStatus.NotAsked,
                });
              }}
            />
          </Box>
          <Box width={250} mx={2} style={{ zIndex: 2 }}>
            <Select
              placeholder=""
              options={[
                { label: "All", value: "all" },
                { label: "Outstanding", value: "false" },
                { label: "Finished", value: "true" },
              ]}
              value={filterClosed}
              onChange={(selected) =>
                handleChangeFilterClosed(
                  selected as { label: string; value: string }
                )
              }
            />
          </Box>
          <Box p={1} border={1}>
            Summary
            <Switch
              checked={detailed}
              onClick={(e) => setDetailed(!detailed)}
            />
            Detailed
          </Box>
        </div>
        <TableContainer
          component={Paper}
          style={{ height: "70vh", resize: "vertical" }}
        >
          <Table size="small">
            <TableHead
              style={{
                backgroundColor: blue[900],
                position: "sticky",
                top: 0,
                zIndex: 1,
              }}
            >
              <TableRow>
                <TableCellBordered
                  rowSpan={3}
                  style={{
                    backgroundColor: lightBlue[50],
                    whiteSpace: "nowrap",
                    position: "sticky",
                    left: 0,
                  }}
                >
                  Job/Project Name
                </TableCellBordered>
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Sales"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Customer"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Need Qty"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Actual Qty"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="FEP"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="BOM"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Deadline Prod"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Deadline QC"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Deadline Deliv"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={3}
                  textColor="white"
                  content="Closing Date"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  colSpan={14}
                  textColor="white"
                  content="Engineering Document"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={2}
                  colSpan={6}
                  textColor="white"
                  content="Cabinet Process"
                />
                {detailed ? (
                  <>
                    <CenteredTableCellBordered
                      backgroundColor="darkblue"
                      rowSpan={2}
                      colSpan={2}
                      textColor="white"
                      content="Preparation Cable"
                    />
                    <CenteredTableCellBordered
                      backgroundColor="darkblue"
                      rowSpan={2}
                      colSpan={7}
                      textColor="white"
                      content="Preparation Panel"
                    />
                    <CenteredTableCellBordered
                      backgroundColor="darkblue"
                      rowSpan={2}
                      colSpan={6}
                      textColor="white"
                      content="Busbar"
                    />
                    <CenteredTableCellBordered
                      backgroundColor="darkblue"
                      rowSpan={2}
                      colSpan={4}
                      textColor="white"
                      content="Interkoneksi"
                    />
                    {/* <TableCellBordered colSpan={2} rowSpan={2}>Preparation Cable</TableCellBordered>
                        <TableCellBordered colSpan={6} rowSpan={2}>Preparation Panel</TableCellBordered>
                        <TableCellBordered colSpan={5} rowSpan={2}>Busbar</TableCellBordered>
                        <TableCellBordered colSpan={4} rowSpan={2}>Interkoneksi</TableCellBordered> */}
                  </>
                ) : (
                  <CenteredTableCellBordered
                    backgroundColor="darkblue"
                    rowSpan={2}
                    colSpan={4}
                    textColor="white"
                    content="Wiring"
                  />
                )}

                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  rowSpan={2}
                  colSpan={6}
                  textColor="white"
                  content="QC"
                />
              </TableRow>
              <TableRow style={{ backgroundColor: blue[900] }}>
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  textColor="white"
                  colSpan={3}
                  content="Mechanical Document"
                />
                <CenteredTableCellBordered
                  backgroundColor="darkblue"
                  textColor="white"
                  colSpan={11}
                  content="Electrical Document"
                />
              </TableRow>
              <TableRow style={{ backgroundColor: blue[900] }}>
                {/* Mechanical Doc */}
                {["Drawing", "Program", "BOM"].map((proc) => (
                  <CenteredTableCellBordered
                    backgroundColor="darkblue"
                    textColor="white"
                    content={proc}
                  />
                ))}

                {/* Electrical Doc */}
                {[
                  "Layout",
                  "SLD",
                  "Zplant",
                  "FW/LO",
                  "Schema",
                  "TL",
                  "WL",
                  "BOM Elec",
                  "Cutout",
                  "Nameplate",
                  "Mimik",
                ].map((proc) => (
                  <CenteredTableCellBordered
                    backgroundColor="darkblue"
                    textColor="white"
                    content={proc}
                  />
                ))}

                {/* Prod. process */}
                {["P", "B", "W", "G", "C", "A"].map((proc) => (
                  <CenteredTableCellBordered
                    backgroundColor="darkblue"
                    textColor="white"
                    content={proc}
                  />
                ))}

                {/* Wiring process */}
                {detailed
                  ? [
                      "Grouping",
                      "Tubing/Crimping",
                      "Dinrail",
                      "Cable/Support Duct",
                      "Pasang Terminal",
                      "Cutout",
                      "Component",
                      "Labeling",
                      "Mimik/Nameplate",
                      "Potong",
                      "Bending",
                      "Lubang",
                      "Baut",
                      "Heatshrink",
                      "Pasang",
                      "WK",
                      "LR",
                      "Interkoneksi",
                      "Closing",
                    ].map((proc) => (
                      <CenteredTableCellBordered
                        backgroundColor="darkblue"
                        textColor="white"
                        content={proc}
                      />
                    ))
                  : [
                      "Preparation Cable",
                      "Preparation Panel",
                      "Interconnection",
                      "Busbar",
                    ].map((proc) => (
                      <CenteredTableCellBordered
                        backgroundColor="darkblue"
                        textColor="white"
                        content={proc}
                      />
                    ))}

                {/* QC */}
                {[
                  "Panel",
                  "Point to Point",
                  "Test On",
                  "NC Mechanical",
                  "NC Electrical",
                  "Validasi",
                ].map((proc) => (
                  <CenteredTableCellBordered
                    backgroundColor="darkblue"
                    textColor="white"
                    content={proc}
                  />
                ))}

                {/* <TableCellBordered>Panel</TableCellBordered>
                <TableCellBordered>Point to Point</TableCellBordered> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {crudState?.data?.content.map((job) => {
                const mechanicalDoc = job.deadlines?.find(
                  (deadline) =>
                    deadline.name === "Engineering Mechanical Document"
                );
                const electricalDoc = job.deadlines?.find(
                  (deadline) =>
                    deadline.name === "Engineering Electrical Document"
                );
                const productionProcess = job.deadlines?.find(
                  (deadline) => deadline.name === "Production Process"
                );
                const wiringProcess = job.deadlines?.find(
                  (deadline) => deadline.name === "Wiring"
                );
                const qcProcess = job.deadlines?.find(
                  (deadline) => deadline.name === "QC"
                );
                const preparationCable = job.deadlines?.find(
                  (deadline) => deadline.name === "Preparation Cable"
                );
                const preparationPanel = job.deadlines?.find(
                  (deadline) => deadline.name === "Preparation Panel"
                );
                const interconnection = job.deadlines?.find(
                  (deadline) => deadline.name === "Interkoneksi"
                );
                const busbar = job.deadlines?.find(
                  (deadline) => deadline.name === "Busbar"
                );

                const mechanicalDocDeadlines =
                  // ['Punching', 'Bending', 'Welding', 'Assembling', 'Cabinet']
                  ["Drawing", "Program", "BOM"].map((docName) =>
                    mechanicalDoc?.deadlineDetails.find(
                      (detail) => detail.name === docName
                    )
                  );

                const electricalDocDeadlines = [
                  "Layout",
                  "SLD",
                  "Zplant",
                  "FW/LO",
                  "Schema",
                  "TL",
                  "WL",
                  "BOM Elec",
                  "Cutout",
                  "Nameplate",
                  "Mimik",
                ].map((docName) =>
                  electricalDoc?.deadlineDetails.find(
                    (detail) => detail.name === docName
                  )
                );

                const productionProcessDeadlines = [
                  "Punch",
                  "Bend",
                  "Grind",
                  "Coat",
                  "Weld",
                  "Assembly",
                ].map((processName) =>
                  productionProcess?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                const wiringProcessDeadlines = [
                  "Preparation Cable",
                  "Preparation Panel",
                  "Interconnection",
                  "Busbar",
                ].map((processName) =>
                  wiringProcess?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                const qcProcessDeadlines = [
                  "Panel",
                  "Point to Point",
                  "Test On",
                  "NC Mechanical",
                  "NC Electrical",
                  "Validasi",
                ].map((processName) =>
                  qcProcess?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                // MPS Detailed (Wiring)
                const preparationCableDeadlines = [
                  "Grouping",
                  "Tubing/Crimping",
                ].map((processName) =>
                  preparationCable?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                const preparationPanelDeadlines = [
                  "Dinrail",
                  "Cable/Support Duct",
                  "Pasang Terminal",
                  "Cutout",
                  "Component",
                  "Labeling",
                  "Mimik/Nameplate",
                ].map((processName) =>
                  preparationPanel?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                const interconnectionDeadlines = [
                  "WK",
                  "LR",
                  "Interkoneksi",
                  "Closing",
                ].map((processName) =>
                  interconnection?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                const busbarDeadlines = [
                  "Potong",
                  "Bending",
                  "Lubang",
                  "Baut",
                  "Heatshrink",
                  "Pasang",
                ].map((processName) =>
                  busbar?.deadlineDetails.find(
                    (detail) => detail.name === processName
                  )
                );

                return (
                  <TableRow>
                    <TableCellBordered
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "lightgrey",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: job.closed ? green[100] : grey[100],
                          padding: 10,
                        }}
                      >
                        <Link to={`/mpsrev/${job.id}`}>
                          {job.name === "" ? "(No name)" : job.name}
                        </Link>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div>
                            <Link to={`/jobs/${job.id}/remarks`}>
                              <Button
                                size="small"
                                variant="contained"
                                color={
                                  (job.jobRemarks?.length ?? 0) > 0
                                    ? "secondary"
                                    : "primary"
                                }
                              >
                                Remarks ({job.jobRemarks?.length})
                              </Button>
                            </Link>
                          </div>
                          <div>
                            <Button
                              size="small"
                              color="primary"
                              variant="outlined"
                            >
                              NC (0)
                            </Button>
                          </div>
                          <div style={{ marginLeft: 5, marginRight: 5 }}>
                            <PriorityComponent priority={job.priority} />
                          </div>
                          <div>
                            <strong>
                              (
                              {makeReadableDateString(
                                new Date(job.deliveryDeadline)
                              )}
                              )
                            </strong>
                          </div>
                        </div>
                        <div>
                          <LastProcess jobId={job.id ?? 0} />
                        </div>
                      </div>
                    </TableCellBordered>
                    <TableCellBordered>{job.sales?.name}</TableCellBordered>
                    <TableCellBordered>
                      {job.individualEntity?.name}
                    </TableCellBordered>
                    <TableCellBordered>{job.qty}</TableCellBordered>
                    <TableCellBordered style={{ backgroundColor: red[100] }}>
                      {0}
                    </TableCellBordered>
                    <TableCellBordered
                      style={{
                        backgroundColor: job.fepDocument
                          ? green[100]
                          : red[100],
                      }}
                    >
                      {job.fepDocument ? `v${job.fepDocument.version}` : ``}
                    </TableCellBordered>
                    <TableCellBordered
                      style={{
                        backgroundColor: job.bomDocument
                          ? green[100]
                          : red[100],
                      }}
                    >
                      {job.bomDocument ? `v${job.bomDocument.version}` : ``}
                    </TableCellBordered>
                    <TableCellBordered
                      style={{
                        backgroundColor: compareDeadlineInterval(
                          new Date(),
                          job.productionDeadline
                        ),
                      }}
                    >
                      {makeReadableDateString(new Date(job.productionDeadline))}
                    </TableCellBordered>
                    <TableCellBordered
                      style={{
                        backgroundColor: compareDeadlineInterval(
                          new Date(),
                          job.deadline
                        ),
                      }}
                    >
                      {makeReadableDateString(new Date(job.deadline))}
                    </TableCellBordered>
                    <TableCellBordered
                      style={{
                        backgroundColor: compareDeadlineInterval(
                          new Date(),
                          job.deliveryDeadline
                        ),
                      }}
                    >
                      {makeReadableDateString(new Date(job.deliveryDeadline))}
                    </TableCellBordered>
                    <TableCellBordered
                      style={{
                        backgroundColor: job.closed ? green[100] : "black",
                        border: "1px solid white",
                      }}
                    >
                      {job.closingDate}
                    </TableCellBordered>

                    {mechanicalDocDeadlines.map((deadline) => (
                      <DeadlineDetailCell
                        jobId={job.id ?? 0}
                        deadline={mechanicalDoc}
                        deadlineDetail={deadline}
                      />
                    ))}
                    {electricalDocDeadlines.map((deadline) => (
                      <DeadlineDetailCell
                        jobId={job.id ?? 0}
                        deadline={electricalDoc}
                        deadlineDetail={deadline}
                      />
                    ))}
                    {productionProcessDeadlines.map((deadline) => (
                      <DeadlineDetailCell
                        jobId={job.id ?? 0}
                        deadline={productionProcess}
                        deadlineDetail={deadline}
                      />
                    ))}

                    {detailed ? (
                      <>
                        {preparationCableDeadlines.map((deadline) => (
                          <DeadlineDetailCell
                            jobId={job.id ?? 0}
                            deadline={preparationCable}
                            deadlineDetail={deadline}
                          />
                        ))}
                        {preparationPanelDeadlines.map((deadline) => (
                          <DeadlineDetailCell
                            jobId={job.id ?? 0}
                            deadline={preparationPanel}
                            deadlineDetail={deadline}
                          />
                        ))}
                        {busbarDeadlines.map((deadline) => (
                          <DeadlineDetailCell
                            jobId={job.id ?? 0}
                            deadline={busbar}
                            deadlineDetail={deadline}
                          />
                        ))}
                        {interconnectionDeadlines.map((deadline) => (
                          <DeadlineDetailCell
                            jobId={job.id ?? 0}
                            deadline={interconnection}
                            deadlineDetail={deadline}
                          />
                        ))}
                      </>
                    ) : (
                      wiringProcessDeadlines.map((deadline) => (
                        <DeadlineDetailCell
                          jobId={job.id ?? 0}
                          deadline={wiringProcess}
                          deadlineDetail={deadline}
                        />
                      ))
                    )}

                    {qcProcessDeadlines.map((deadline) => (
                      <DeadlineDetailCell
                        jobId={job.id ?? 0}
                        deadline={qcProcess}
                        deadlineDetail={deadline}
                      />
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={crudState.data.totalElements}
            rowsPerPage={crudState.rowsPerPage}
            page={crudState.currentPage}
            onPageChange={(evt, newPage) =>
              setCrudState({
                ...crudState,
                currentPage: newPage,
                requestStatus: RequestStatus.NotAsked,
              })
            }
            onChangeRowsPerPage={(evt) =>
              setCrudState({
                ...crudState,
                currentPage: 0,
                rowsPerPage: parseInt(evt.target.value),
                requestStatus: RequestStatus.NotAsked,
              })
            }
          />
        </div>
      </div>
      <Dialog
        open={deadlineInfoOpen}
        onClose={(e) => setDeadlineInfoOpen(false)}
      >
        <div style={{ margin: 20 }}>
          <h3>
            Job:{" "}
            {
              crudState.data.content.find(
                (job) => job.id === deadlineInfo.jobId
              )?.name
            }
          </h3>
          <div style={{ display: "flex" }}>
            <div>
              <h3 style={{ display: "inline" }}>
                Deadline: {deadlineInfo.deadline?.name}{" "}
                {deadlineInfo.deadlineDetail?.name} ({deadlineInfo.type})
              </h3>
            </div>
            <div style={{ marginLeft: 15, marginRight: 15 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Deadline Date"
                  value={null}
                  variant="inline"
                  autoOk
                  clearable
                  onChange={(date) => handleSelectDeadlineDetail(date)}
                />
              </MuiPickersUtilsProvider>
            </div>
            {/* <input type="date" 
            
            /> */}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MpsRev;
