import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../../App";
import {
  fetchExtInventory,
  fetchExtReserveLists,
  fetchJobsProtoSimple,
  fetchMaterialRequestsSimple,
  fetchWarehouseItemsProto,
  intlFormat,
} from "../../../helpers";
import {
  ExtInventory,
  ExtMaterialRequestItem,
  ExtReserveList,
} from "../../../models/model";
import {
  PpicJobs,
  PpicMaterialRequestsSimple,
  WarehouseItem,
  WarehouseItems,
} from "../../../masterbigsystem";
import { types } from "util";
import { IfFulfilled } from "react-async";

export type ReserveListType = "Reserve" | "Minus Stock";
export const reserveListTypes = ["Reserve", "Minus Stock"] as ReserveListType[];

const ReserveListPage = () => {
  const ctx = useContext(AppContext);
  const [reserveList, setReserveList] = useState<ExtReserveList[]>([]);
  const items = useRef(WarehouseItems.fromPartial({}));
  const materialRequestsSimple = useRef(
    PpicMaterialRequestsSimple.fromPartial({})
  );

  const itemLoading = useRef(false);

  const inventory = useRef([] as ExtInventory[]);
  const inventoryLoading = useRef(false);

  const selectedReserveListType = useRef("Reserve" as ReserveListType);
  const jobs = useRef(PpicJobs.fromPartial({}));
  const reserveLoading = useRef(false);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchReserveListData();
    fetchJobsProtoSimpleData();
    fetchWarehouseItemsData();
    fetchInventoryData();
    fetchMaterialRequestsSimpleData();
  };

  const fetchReserveListData = async () => {
    reserveLoading.current = true;
    render();

    setReserveList(await fetchExtReserveLists({ apiKey: ctx?.apiKey ?? "" }));

    reserveLoading.current = false;
    render();
  };

  const fetchWarehouseItemsData = async () => {
    fetchItemsData();
  };

  const fetchJobsProtoSimpleData = async () => {
    const d = await fetchJobsProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      jobs.current = d;
      render();
    }
  };

  const fetchMaterialRequestsSimpleData = async () => {
    const d = await fetchMaterialRequestsSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      materialRequestsSimple.current = d;
      render();
    }
  };

  const fetchInventoryData = async () => {
    inventoryLoading.current = true;
    render();
    const d = await fetchExtInventory({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      inventory.current = d;
      render();
    }

    inventoryLoading.current = false;
    render();
  };

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const fetchItemsData = async () => {
    itemLoading.current = true;
    render();
    const d = await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      items.current = d;
      render();
    }

    itemLoading.current = false;
    render();
  };

  const cleanApproveLoading = useRef(false);

  const reserveListRev = (() => {
    const rRev = [...reserveList];

    rRev.reverse();
    return rRev;
  })();

  const handleCleanApprovedRsv = async () => {
    cleanApproveLoading.current = true;
    render();

    await fetchExtReserveLists({ apiKey: ctx?.apiKey ?? "", delete: true });

    cleanApproveLoading.current = false;
    render();

    window.location.reload();
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h5>Reserve List</h5>
          {itemLoading.current ? (
            <>
              <div className="spinner-border spinner-border-sm"></div>
              Items Loading...
            </>
          ) : (
            <></>
          )}

          {reserveLoading.current ? (
            <>
              <div className="spinner-border spinner-border-sm"></div>
              Reserve Loading...
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="d-flex">
          {reserveListTypes.map((h) => {
            return (
              <>
                <button
                  onClick={() => {
                    selectedReserveListType.current = h;

                    render();
                  }}
                  className={`mx-2 btn btn-sm ${
                    selectedReserveListType.current === h
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                >
                  {h}
                </button>
              </>
            );
          })}

          {cleanApproveLoading.current ? (
            <>
              <div className="spinner-border spinner-border-sm"></div>
            </>
          ) : (
            <>
              {" "}
              <div>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    if (
                      !window.confirm(
                        "Really clean? This will delete all approved MRs' reservation."
                      )
                    ) {
                      return;
                    }

                    handleCleanApprovedRsv();
                  }}
                >
                  Clean Approved RSV
                </button>
              </div>
            </>
          )}
        </div>
        <div>
          <hr />
        </div>

        {selectedReserveListType.current === "Reserve" ? (
          <>
            {" "}
            <div>
              <div
                className="border border-dark overflow-auto"
                style={{ height: "80vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "#",
                      "ID",
                      "PN",
                      "Part Name",
                      "Part Desc",
                      "Job",
                      "Qty",
                      "MR",
                      "Status",
                      "Date",
                      "Delete",
                    ].map((h) => (
                      <th
                        className="bg-dark text-light"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    ))}
                  </tr>

                  {reserveListRev.map((r, i) => {
                    const foundItem = items.current.items.find(
                      (ix) => `${ix.id}` === `${r.products?.id}`
                    );

                    // const foundMR =
                    //   materialRequestsSimple.current.materialRequests
                    //     .flatMap((m) =>
                    //       m.items.map((i) => ({ mr: m, item: i }))
                    //     )
                    //     .find(
                    //       (d) => `${d.item.extReserveListId}` === `${r.id}`
                    //     );

                    return (
                      <>
                        <tr>
                          <td className="border border-dark p-0 m-0">
                            {i + 1}
                          </td>
                          <td className="border border-dark p-0 m-0">{r.id}</td>

                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partNum}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partName}
                          </td>

                          <td className="border border-dark p-0 m-0">
                            {foundItem?.partDesc}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {
                              jobs.current.jobs.find(
                                (j) =>
                                  `${j.masterJavaBaseModel?.id}` ===
                                  `${r.jobId}`
                              )?.name
                            }
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {" "}
                            {r.qtyReserve}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {r.mrName}
                          </td>
                          <td
                            className={`border border-dark p-0 m-0 ${(() => {
                              switch (r.mrStatus) {
                                case 0:
                                  return `bg-danger`;
                                case 1:
                                  return `bg-success`;
                                case 2:
                                  return `bg-info`;
                                default:
                                  return ``;
                              }
                            })()}`}
                          >
                            {(() => {
                              switch (r.mrStatus) {
                                case 0:
                                  return `Not Approved`;
                                case 1:
                                  return `Approved`;
                                case 2:
                                  return `CutStock`;
                                default:
                                  return ``;
                              }
                            })()}
                          </td>

                          <td
                            className="border border-dark p-0 m-0"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {intlFormat({
                              date: r.createdAt,
                              dateStyle: "medium",
                              timeStyle: "short",
                            })}
                          </td>

                          <td className="border border-dark p-0 m-0">
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={async () => {
                                try {
                                  if (
                                    !window.confirm(
                                      `Are you sure to remove ${foundItem?.partNum}:${foundItem?.partName}, qty ${r.qtyReserve} ?`
                                    )
                                  ) {
                                    return;
                                  }

                                  reserveLoading.current = true;
                                  render();
                                  const resp = await fetch(
                                    `${process.env.REACT_APP_BASE_URL}/ext-material-reservation-delete/${r.id}`
                                  );

                                  fetchReserveListData();
                                } catch (e) {
                                  console.error(e);
                                }
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {selectedReserveListType.current === "Minus Stock" ? (
          <>
            <div
              className="border border-dark overflow-auto"
              style={{ height: "80vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "#",
                    "PN",
                    "Part Name",
                    "Part Desc",
                    // "Job",
                    "Warehouse",
                    "Qty",
                  ].map((h) => (
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  ))}
                </tr>
                {(() => {
                  const balanceMinuses = inventory.current.filter(
                    (i, i_) => (i.balanceRsv ?? 0) < 0
                  );

                  return balanceMinuses.map((i, i_) => {
                    // const foundInventory = inventory.current.find(
                    //   (ix) => `${ix.id}` === `${i.id}`
                    // );
                    return (
                      <>
                        <tr>
                          <td className="border border-dark p-0 m-0">
                            {i_ + 1}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {i.products?.partNum}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {i.products?.partName}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {i.products?.partDesc}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {i.warehouse?.warehouse?.name}:{i.warehouse?.whName}
                          </td>
                          <td className="border border-dark p-0 m-0">
                            {i.balanceRsv}
                          </td>
                        </tr>
                      </>
                    );
                  });
                })()}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ReserveListPage;
