import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'


const SyncAutocompleteName = (props: {
  options: any, 
  value: any, 
  name: string, 
  label: string,
  width: number | null,
  onChange: (value: any) => void}
) => {
  return (
    <Autocomplete 
      id="crud-table-combobox-filter"
      options={props.options}
      value={props.value}
      onChange={(event: any, value: any) => props.onChange(value)}
      getOptionLabel={option => option[props.name]}
      style={{width: props.width ? props.width : 300}}
      renderInput={params => (
        <TextField {...params} label={props.label} fullWidth size="small" />
      )}
    />
  )
}

export default SyncAutocompleteName