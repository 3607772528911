
import React from 'react'
import { WiringFepDocument } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const WiringFepDocumentPage = (props: any) => {
  const mapperFunc = (wiringFepDocument: WiringFepDocument) => {
    return {
      id: wiringFepDocument.id,
      content: [ wiringFepDocument.name ]
    }
  }

  return (
    <StatefulCrudTable<WiringFepDocument>  
      url="wiringfepdocuments"
      head={['Name']}
      mapper={mapperFunc}
    />
  )
}

export default WiringFepDocumentPage;