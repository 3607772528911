import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  MasterJavaBaseModel,
  PpicJobs,
  PpicMachineProgram,
  PpicMachineProgramPic,
  PpicMachinePrograms,
  PpicPanelCode,
  PpicPanelCodes,
} from "../../../masterbigsystem";
import { v4 as uuidv4 } from "uuid";
import { ChevronLeft } from "@material-ui/icons";
import { Spinner } from "react-bootstrap";
import {
  fetchJobsProtoSimple,
  fetchPanelCodesProtoSimple,
} from "../../../helpers";
import Select from "react-select";
import { ExtUser, PanelCode } from "../../../models/model";

const MachineProgramDetailPage = () => {
  const history = useHistory();
  const { id } = useParams() as { id: string };
  const ctx = useContext(AppContext);
  const [machineProgram, setMachineProgram] = useState<PpicMachineProgram>(
    PpicMachineProgram.fromPartial({
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: uuidv4() }),
    })
  );
  const [jobs, setJobs] = useState<PpicJobs | null | undefined>(null);
  const [panelCodes, setPanelCodes] = useState<
    PpicPanelCodes | null | undefined
  >(null);

  const [loading, setLoading] = useState(false);
  const [panelCodesLoading, setPanelCodesLoading] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [machineProgramPicDeleteIds, setMachineProgramPicDeleteIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    setLoading(true);

    handleFetchData();
    handleFetchPanelCodesSimple();
    handleFetchJobsProtoSimple();

    setLoading(false);
  };

  const handleFetchData = async () => {
    if (!isNaN(parseInt(id))) {
      try {
        const resp = await fetch(
          `${process.env.REACT_APP_BASE_URL}/machineprograms-proto/${id}`,
          { headers: { authorization: ctx?.apiKey ?? "" } }
        );
        if (resp.status !== 200) throw await resp.text();

        setMachineProgram(PpicMachineProgram.fromPartial(await resp.json()));
      } catch (e) {
        console.error(e);
      } finally {
      }
    }
  };
  const handleFetchPanelCodesSimple = async () => {
    setPanelCodesLoading(true);
    setPanelCodes(
      await fetchPanelCodesProtoSimple({ apiKey: ctx?.apiKey ?? "" })
    );
    setPanelCodesLoading(false);
  };
  const handleFetchJobsProtoSimple = async () => {
    setJobsLoading(true);
    setJobs(await fetchJobsProtoSimple({ apiKey: ctx?.apiKey ?? "" }));
    setJobsLoading(false);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/machineprograms-proto`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorizatin: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(PpicMachineProgram.toJSON(machineProgram)),
        }
      );

      await Promise.all(
        machineProgramPicDeleteIds.map(async (id) => {
          try {
            const resp = await fetch(
              `${process.env.REACT_APP_BASE_URL}/machineprogrampics-proto/${id}/empty`,
              {
                method: "delete",
                headers: { authorization: ctx?.apiKey ?? "" },
              }
            );
          } catch (e) {
            console.error(e);
          }
        })
      );

      history.push("/machineprograms");
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <Link to="/machineprograms">
            <button className="btn btn-outline-primary">
              <ChevronLeft /> Back{" "}
            </button>
          </Link>

          <h4>Machine Program Detail</h4>
          <div className="mx-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleSave();
              }}
            >
              {" "}
              Save
            </button>
          </div>
          {loading ? <Spinner animation="border" /> : <></>}
        </div>
        <hr />

        <div className="d-flex">
          <div className="flex-grow-1">
            <div>Program Name ({machineProgram.name})</div>
            <div>
              <input
                defaultValue={machineProgram.name ?? ""}
                onBlur={(e) => {
                  setMachineProgram({
                    ...machineProgram,
                    name: e.target.value,
                  });
                }}
                className="form-control"
                placeholder="Program name..."
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <div>Time process mins: ({machineProgram.timeProcessMins})</div>
            <div>
              <input
                onBlur={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setMachineProgram({
                      ...machineProgram,
                      timeProcessMins: parseInt(e.target.value),
                    });
                  }
                }}
                className="form-control"
                placeholder="Time process..."
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <div>Qty run ({machineProgram.qtyRun})</div>
            <div>
              <input
                onBlur={(e) => {
                  if (!isNaN(parseInt(e.target.value))) {
                    setMachineProgram({
                      ...machineProgram,
                      qtyRun: parseInt(e.target.value),
                    });
                  }
                }}
                className="form-control"
                placeholder="Qty..."
              />
            </div>
          </div>
        </div>
        {panelCodesLoading ? (
          <>
            <Spinner animation="border" />
          </>
        ) : (
          <>
            {(() => {
              const foundPc = panelCodes?.panelCodes.find(
                (pcx) =>
                  pcx.masterJavaBaseModel?.id === machineProgram.panelCodeId
              );
              const pcString = `${
                jobsLoading
                  ? "(Loading jobs...)"
                  : jobs?.jobs.find(
                      (j) => j.masterJavaBaseModel?.id === foundPc?.jobId
                    )?.name
              } ${foundPc?.type} - ${foundPc?.name} - ${
                foundPc?.qty ?? "No"
              } unit(s)`;

              return (
                <div>
                  <div>
                    {machineProgram.panelCodeId ? (
                      <strong>{pcString}</strong>
                    ) : (
                      <></>
                    )}
                  </div>

                  <Select
                    placeholder="Select product..."
                    onChange={(v) => {
                      const val = (v as unknown as { value: PpicPanelCode }).value;

                      setMachineProgram({
                        ...machineProgram,
                        panelCodeId: val.masterJavaBaseModel?.id,
                      });
                    }}
                    options={
                      panelCodes?.panelCodes.map((pc) => ({
                        label: `${
                          jobsLoading
                            ? "(Loading jobs...)"
                            : jobs?.jobs.find(
                                (j) => j.masterJavaBaseModel?.id === pc.jobId
                              )?.name
                        } ${pc.type} - ${pc?.name} - ${pc.qty ?? "No"} unit(s)`,
                        value: pc,
                      })) ?? []
                    }
                  />
                </div>
              );
            })()}
          </>
        )}
      </div>
      <hr />
      <div>
        <strong>PICs:</strong>
      </div>
      <div>
        <Select
          placeholder="Select PICs..."
          onChange={(v) => {
            const val = v as unknown as ExtUser;

            if (
              !machineProgram.machineProgramPics.find(
                (p) => `${p.extUserId}` === `${val.id}`
              )
            ) {
              console.log(machineProgram.machineProgramPics);
              setMachineProgram({
                ...machineProgram,
                machineProgramPics: [
                  ...machineProgram.machineProgramPics,
                  PpicMachineProgramPic.fromPartial({
                    extUserId: val.id ? `${val.id}` : undefined,
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: uuidv4(),
                    }),
                  }),
                ],
              });
            }
          }}
          getOptionLabel={(u) => `${u.name} (${u.departmentName})`}
          options={ctx?.extUsers}
        />
      </div>
      <div>
        <ol>
          {machineProgram.machineProgramPics.map((p) => {
            const fUser = ctx?.extUsers.find(
              (u) => `${u.id}` === `${p.extUserId}`
            );
            return (
              <>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMachineProgramPicDeleteIds([
                        ...machineProgramPicDeleteIds,
                        p.masterJavaBaseModel?.id ?? "",
                      ]);
                      setMachineProgram({
                        ...machineProgram,
                        machineProgramPics:
                          machineProgram.machineProgramPics.filter(
                            (px) => `${px.extUserId}` !== `${p.extUserId}`
                          ),
                      });
                    }}
                  >
                    <div>
                      {fUser?.name} ({fUser?.departmentName})
                    </div>
                  </div>
                </li>
              </>
            );
          })}
        </ol>
      </div>
    </>
  );
};

export default MachineProgramDetailPage;
