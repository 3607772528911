import React, { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  fetchJobOverviewHiddenCols,
  jobOverviewHeaders,
} from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicExtDepartmentHiddenJobOverviewCol,
  PpicExtDepartmentHiddenJobOverviewCols,
} from "../../../masterbigsystem";

const JobOverviewColPage = () => {
  const ctx = useContext(AppContext);

  const [jobOverviewHiddenCols, setjobOverviewHiddenCols] =
    useState<PpicExtDepartmentHiddenJobOverviewCols>(
      PpicExtDepartmentHiddenJobOverviewCols.fromPartial({ hiddenCols: [] })
    );

  const [jobOverviewHiddenColDeleteIds, setjobOverviewHiddenColDeleteIds] =
    useState<string[]>([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchJobOverviewHiddenColsData();
  };

  const fetchJobOverviewHiddenColsData = async () => {
    setjobOverviewHiddenCols(
      await fetchJobOverviewHiddenCols({ apiKey: ctx?.apiKey ?? "" })
    );
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>Job Overview Custom Column</h5>
          </div>
          <div className="mx-2">
            <button
              className="btn btn-primary btn-sm"
              onClick={async () => {
                try {
                  const resp = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/extdepartmenthiddenjoboverviewcols`,
                    {
                      method: "post",
                      headers: {
                        "content-type": "application/json",
                        authorization: ctx?.apiKey ?? "",
                      },
                      body: JSON.stringify(
                        PpicExtDepartmentHiddenJobOverviewCols.toJSON(
                          jobOverviewHiddenCols
                        )
                      ),
                    }
                  );

                  // Empty all
                  await Promise.all([
                    ...jobOverviewHiddenColDeleteIds.map(async (id) => {
                      try {
                        const resp = await fetch(
                          `${process.env.REACT_APP_BASE_URL}/extdepartmenthiddenjoboverviewcols/${id}/empty`,
                          {
                            method: "delete",
                            headers: {
                              authorization: ctx?.apiKey ?? "",
                            },
                          }
                        );
                      } catch (e) {}
                    }),
                  ]);

                  window.location.reload();
                } catch (e) {}
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <hr />
        </div>
        <div>
          <ol>
            {ctx?.extDepartments.map((d, i) => {
              return (
                <>
                  <li>
                    <div>
                      <div>{d.name ?? "No name"}</div>
                    </div>
                    <div className="d-flex flex-wrap">
                      Hidden:{" "}
                      {jobOverviewHiddenCols.hiddenCols
                        .filter((c) => `${c.extDepartmentId}` === `${d.id}`)
                        .map((c) => {
                          return (
                            <>
                              <div
                                className="p-2 border border-dark rounded rounded-lg"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setjobOverviewHiddenColDeleteIds([
                                    ...jobOverviewHiddenColDeleteIds,
                                    c.masterJavaBaseModel?.id ?? "0",
                                  ]);
                                  setjobOverviewHiddenCols({
                                    ...jobOverviewHiddenCols,
                                    hiddenCols:
                                      jobOverviewHiddenCols.hiddenCols.filter(
                                        (cx) =>
                                          !(
                                            `${cx.extDepartmentId}` ===
                                              `${d.id}` &&
                                            `${cx.name}` === `${c.name}`
                                          )
                                      ),
                                  });
                                }}
                              >
                                {c.name} {c.extDepartmentId}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div>
                      <table
                        className="table table-sm"
                        style={{ borderCollapse: "separate" }}
                      >
                        <tr>
                          {jobOverviewHeaders
                            .filter(
                              (h) =>
                                !jobOverviewHiddenCols.hiddenCols.find(
                                  (hc) =>
                                    `${hc.name}` === h.name &&
                                    `${hc.extDepartmentId}` === `${d.id}`
                                )
                            )
                            .map((h) => (
                              <th className="bg-dark text-light ">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setjobOverviewHiddenCols({
                                      ...jobOverviewHiddenCols,
                                      hiddenCols: [
                                        ...jobOverviewHiddenCols.hiddenCols,
                                        PpicExtDepartmentHiddenJobOverviewCol.fromPartial(
                                          {
                                            extDepartmentId: d.id
                                              ? `${d.id}`
                                              : undefined,
                                            name: h.name,
                                            masterJavaBaseModel:
                                              MasterJavaBaseModel.fromPartial({
                                                uuid: v4(),
                                              }),
                                          }
                                        ),
                                      ],
                                    });
                                  }}
                                >
                                  {h.name}
                                </div>
                              </th>
                            ))}
                        </tr>
                      </table>
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>
      </div>
    </>
  );
};

export default JobOverviewColPage;
