import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchDeliveryNote,
  fetchExtCrmPurchaseOrdersProto,
  makeDateString,
} from "../../../helpers";
import { CrmPurchaseOrders, PpicDeliveryNote } from "../../../masterbigsystem";
import { AppContext } from "../../../App";

const PrintDNPage = () => {
  const { id } = useParams() as { id: string };

  const ctx = useContext(AppContext);
  const deliveryNote = useRef(PpicDeliveryNote.fromPartial({}));
  const purchaseOrders = useRef(
    CrmPurchaseOrders.fromPartial({ purchaseOrders: [] })
  );

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const fetchPOsData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      purchaseOrders.current = d;
      render();
    }
  };

  const handleFetchDeliveryNote = async () => {
    const d = await fetchDeliveryNote({ apiKey: ctx?.apiKey ?? "", id: id });

    if (d) {
      deliveryNote.current = d;
      render();
    }
  };
  const handleInit = async () => {
    handleFetchDeliveryNote();
    fetchPOsData();
  };

  useEffect(() => {
    handleInit();
  }, []);

  const foundPO = purchaseOrders.current.purchaseOrders.find(
    (p) => `${p.id}` === `${deliveryNote.current.extPurchaseOrderId}`
  );

  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <div>
              <img src="/gspe-manufacturing.png" style={{ height: 100 }} />
            </div>
            <h4>
              <strong>Delivery Note</strong>
            </h4>
          </div>

          <div>
            <table className="table table-sm">
              <tr>
                <td className="border border-dark">No. Revisi</td>
                <td className="border border-dark">0</td>
              </tr>
              <tr>
                <td className="border border-dark">Tanggal Efektif</td>
                <td className="border border-dark">
                  {(() => {
                    try {
                      return Intl.DateTimeFormat("en-US", {
                        dateStyle: "medium",
                      } as any).format(
                        new Date(deliveryNote.current.effectiveDate ?? "")
                      );
                    } catch (e) {
                      return "";
                    }
                  })()}
                </td>
              </tr>
              <tr>
                <td className="border border-dark">
                  <div style={{ minHeight: 25 }}></div>
                </td>
                <td className="border border-dark"></td>
              </tr>
              <tr>
                <td className="border border-dark">Halaman</td>
                <td className="border border-dark">1</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <div className="border border-dark p-2">
            <div>
              <strong>GRAHA SUMBER PRIMA ELEKTRONIK, PT</strong>
            </div>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {deliveryNote.current.address}
            </div>
          </div>

          <div className="border border-dark p-2">
            <div>
              DN#:{' '}
              {/* {deliveryNote.current.dnNumber} */}
              {deliveryNote.current.masterJavaBaseModel?.id}
            </div>
            <div>
              Date:{" "}
              {(() => {
                try {
                  return Intl.DateTimeFormat("en-US", {
                    dateStyle: "medium",
                  } as any).format(new Date(deliveryNote.current.date ?? ""));
                } catch (e) {
                  return "";
                }
              })()}
            </div>
            <div>
              PO#: {foundPO?.purchaseOrderNumber} ({foundPO?.account?.name})
            </div>
            <div>Purpose: {deliveryNote.current.purpose}</div>
          </div>
        </div>

        <div className="my-2">
          <strong>Items</strong>
        </div>

        <div
        //   className="border border-dark overflow-auto"
        //   style={{ resize: "vertical", height: "75vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate", position: "sticky", top: 0 }}
          >
            <tr>
              {["#", "Item", "Qty"].map((h) => (
                <>
                  <th className="bg-dark text-light p-0 m-0">{h}</th>
                </>
              ))}
            </tr>
            {deliveryNote.current.deliveryNoteItems.map((i, i_) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i_ + 1}</td>
                    <td className="border border-dark p-0 m-0">
                      {i.description}
                    </td>
                    <td className="border border-dark p-0 m-0">{i.qty}</td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default PrintDNPage;
