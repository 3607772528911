import React from 'react'
import { Manufacturer } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const ManufacturerPage = (props: any) => {
  const mapperFunc = (manufacturer: Manufacturer) => {
    return {
      id: manufacturer.id,
      content: [ manufacturer.name ]
    }
  }

  return (
    <StatefulCrudTable<Manufacturer>  
      url="manufacturers"
      head={['Manufacturer Name']}
      mapper={mapperFunc}
    />
  )
}

export default ManufacturerPage