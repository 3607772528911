import React, { useContext, useEffect, useState } from "react";
import { Link, Route } from "react-router-dom";
import { AppContext } from "../../../App";
import { fetchSsoUsers, fetchUsers } from "../../../helpers";
import { SsoUserEntitiesData } from "../../../masterbigsystem";
import { User } from "../../../models/model";
import StatefulCrudTable from "../../mini-components/StatefulCrudTable/StatefulCrudTable";

const UserPage = (props: any) => {
  const ctx = useContext(AppContext);

  const [ssoUsers, setSsoUsers] = useState(SsoUserEntitiesData.fromPartial({}));
  const [users, setUsers] = useState([] as User[]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchSsoUsersData();
    fetchUsersData();
  };

  const fetchSsoUsersData = async () => {
    setSsoUsers(await fetchSsoUsers({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchUsersData = async () => {
    setUsers(await fetchUsers({ apiKey: ctx?.apiKey ?? "" }));
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>Users</h4>

          <Link to={`/users/new`}>
            <button className="btn btn-sm btn-primary">Add </button>
          </Link>
        </div>
        <div>
          <hr />
        </div>
        {ctx?.user?.roles.find(
          (r) => r.name.toLowerCase() === "administrator"
        ) ? (
          <>
            <div
              className="border border-dark overflow-auto"
              style={{ height: "75vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {[
                    "Action",
                    "#",
                    "Name",
                    "Username",
                    "Roles",
                    "SSO link (GSPE App)",
                  ].map((h) => (
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  ))}
                </tr>
                {users.map((u, i) => {
                  return (
                    <tr>
                      <td className="border border-dark">
                        <Link to={`/users/${u.id}`}>
                          <button className="btn btn-sm btn-primary">
                            Details
                          </button>
                        </Link>
                      </td>

                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">{u.name}</td>
                      <td className="border border-dark">{u.username}</td>
                      <td className="border border-dark">
                        <ol>
                          {u.roles.map((r) => {
                            return (
                              <li>
                                <div>{r.name}</div>
                              </li>
                            );
                          })}
                        </ol>
                      </td>
                      {(() => {
                        const foundSsoUser = ssoUsers.ssoUserEntities
                          .find((ue) =>
                            ue.ssoUserAppEntity.find(
                              (uae) =>
                                `${uae.ssoAppEntity?.name}` === "ppic" &&
                                `${uae.extUserId}` === `${u.id}`
                            )
                          )
                          ?.ssoUserAppEntity.find(
                            (uae) => `${uae.ssoAppEntity?.name}` === "gspe"
                          );

                        const foundExtUser = ctx?.extUsers.find(
                          (ux) => `${ux.id}` === `${foundSsoUser?.extUserId}`
                        );

                        return (
                          <>
                            <td
                              className={`border border-dark font-weight-bold text-light ${
                                foundSsoUser ? `bg-success` : `bg-danger`
                              }`}
                            >
                              {foundSsoUser ? (
                                <>
                                  <small>
                                    <strong>
                                      <div>{foundSsoUser?.extUserId}</div>
                                      <div>
                                        {foundExtUser?.name} (
                                        {foundExtUser?.username})
                                      </div>
                                      <div>{foundExtUser?.departmentName}</div>
                                    </strong>
                                  </small>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </>
                        );
                      })()}
                    </tr>
                  );
                })}
              </table>
            </div>
          </>
        ) : (
          <>You are not an administrator. Cannot edit user</>
        )}
      </div>
    </>
  );
};

export default UserPage;
