import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Select from "react-select";
import {
  CheckRequestStatus,
  fetchItemsReq,
  makeLocaleDateTimeString,
} from "../../../helpers";
import { ExtItemReq } from "../../../models/model";
import { initialItemReq } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const RequestItemPage = () => {
  const apiKey = localStorage.getItem("apiKey");

  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    requestStatus: RequestStatus.NotAsked,
    itemsReq: [] as Array<ExtItemReq>,
    extItemReq: { ...initialItemReq } as ExtItemReq,
    filterItems: null as number | null,
    showModal: false,
  });

  useEffect(() => {
    (async () => {
      setState({ ...state, requestStatus: RequestStatus.Loading });
      const itemsReq = await fetchItemsReq();

      setState({
        ...state,
        requestStatus: RequestStatus.Success,
        itemsReq: itemsReq ? itemsReq : state.itemsReq,
      });
    })();
  }, []);

  const handleAddNewItem = async (e: any) => {
    e.preventDefault();

    setState({ ...state, requestStatus: RequestStatus.Loading });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/ext-items-req`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: state.apiKey,
          },
          body: JSON.stringify(state.extItemReq),
        }
      );

      if (response.status !== 201) throw "Error adding item req.";

      const newItemsReq = await fetchItemsReq();

      setState({
        ...state,
        requestStatus: RequestStatus.Success,
        itemsReq: newItemsReq ? newItemsReq : state.itemsReq,
        showModal: false,
      });
    } catch (e) {
      setState({ ...state, requestStatus: RequestStatus.Error });
    }
  };

  console.log("Status: ", state.filterItems);

  return (
    <div>
      <div className="d-flex">
        <h3>Request New Item</h3>
        <div className="ml-2">
          <Button
            variant="primary"
            onClick={() => setState({ ...state, showModal: true })}
          >
            Add
          </Button>
        </div>
        <div className="ml-3" style={{ width: 300 }}>
          <Select
            placeholder="Filter.. "
            options={[
              { label: "All", value: null },
              { label: "Waiting for Approval", value: 0 },
              { label: "Approved", value: 1 },
              { label: "Rejected", value: 2 },
            ]}
            onChange={(selected) =>
              setState({
                ...state,
                filterItems: (selected as { label: string; value: number })
                  .value,
              })
            }
          />
        </div>
        <div className="ml-2">
          <CheckRequestStatus requestStatus={state.requestStatus} />
        </div>
      </div>
      <div
        className="border border-dark"
        style={{
          overflow: "auto",
          height: "80vh",
          resize: "vertical",
          border: "2px solid lightgray",
        }}
      >
        <table
          className="table table-sm "
          style={{ borderCollapse: "separate" }}
        >
          <thead>
            <tr>
              {[
                "#",
                "ID",
                "Mfr",
                "PN GSPE",
                "Part Name",
                "Part Desc",
                "UM",
                "Status",
                "Created",
                "Updated",
              ].map((h) => (
                <>
                  <th
                    className="bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                </>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.itemsReq
              .filter((itemReq) =>
                state.filterItems !== null
                  ? itemReq.status === state.filterItems
                  : true
              )
              .map((itemReq, i) => {
                return (
                  <tr>
                    <td className="text-nowrap border border-dark">{i + 1}</td>

                    <td className="text-nowrap border border-dark">
                     #{itemReq.id}
                    </td>

                    <td className="text-nowrap border border-dark">
                      {itemReq.mfr}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {itemReq.partNum}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {itemReq.partName}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {itemReq.partDesc}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {itemReq.defaultUm}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {(() => {
                        switch (itemReq.status) {
                          case 0:
                            return (
                              <span className="text-secondary font-weight-bold">
                                Waiting for Approval
                              </span>
                            );

                          case 1:
                            return (
                              <span className="text-success font-weight-bold">
                                Accepted
                              </span>
                            );

                          case 2:
                            return (
                              <span className="text-danger font-weight-bold">
                                Rejected
                              </span>
                            );

                          default:
                            return <>Undefined</>;
                        }
                      })()}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {(() => {
                        try {
                          return Intl.DateTimeFormat("en-US", {
                            dateStyle: "short",
                            timeStyle: "short",
                          } as any).format(
                            new Date(itemReq.createdAt ? itemReq.createdAt : "")
                          );
                        } catch (e) {
                          return;
                        }
                      })()}
                    </td>
                    <td className="text-nowrap border border-dark">
                      {(() => {
                        try {
                          return Intl.DateTimeFormat("en-US", {
                            dateStyle: "short",
                            timeStyle: "short",
                          } as any).format(
                            new Date(itemReq.updatedAt ? itemReq.updatedAt : "")
                          );
                        } catch (e) {
                          return;
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {/* <div>
        <pre>{JSON.stringify(state.itemsReq, null, 2)}</pre>
      </div> */}
      <Modal
        show={state.showModal}
        onHide={() => setState({ ...state, showModal: false })}
      >
        <Modal.Header>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              <Form.Control
                className="my-2"
                placeholder="Item name..."
                onChange={(e) =>
                  setState({
                    ...state,
                    extItemReq: {
                      ...state.extItemReq,
                      partName: e.target.value,
                    },
                  })
                }
              />
              <Form.Control
                className="my-2"
                placeholder="Part Number..."
                onChange={(e) =>
                  setState({
                    ...state,
                    extItemReq: {
                      ...state.extItemReq,
                      partNum: e.target.value,
                    },
                  })
                }
              />
              <Form.Control
                className="my-2"
                placeholder="Manufacturer..."
                onChange={(e) =>
                  setState({
                    ...state,
                    extItemReq: { ...state.extItemReq, mfr: e.target.value },
                  })
                }
              />
              <Form.Control
                className="my-2"
                placeholder="Part Description..."
                onChange={(e) =>
                  setState({
                    ...state,
                    extItemReq: {
                      ...state.extItemReq,
                      partDesc: e.target.value,
                    },
                  })
                }
              />
              <Form.Control
                className="my-2"
                placeholder="UM..."
                onChange={(e) =>
                  setState({
                    ...state,
                    extItemReq: {
                      ...state.extItemReq,
                      defaultUm: e.target.value,
                    },
                  })
                }
              />
              <div className="d-flex align-items-center">
                <Button
                  className="my-2"
                  type="submit"
                  onClick={handleAddNewItem}
                >
                  Add
                </Button>
                <div className="ml-2">
                  <CheckRequestStatus requestStatus={state.requestStatus} />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestItemPage;
