import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  fetchJobsDataProto,
  fetchJobsProtoSimple,
  getPanelCodeCompleteSerialNumberProto,
} from "../../../helpers";
import {
  PpicJob,
  PpicJobs,
  PpicPanelCode,
  PpicPanelCodeSerialNumber,
} from "../../../masterbigsystem";
import { CloudDownload } from "@material-ui/icons";

type ShowTypeType = "Auto" | "Manual" | "Rect" | "All";
const showTypeList: ShowTypeType[] = ["All", "Auto", "Manual", "Rect"];

const SerialNumberReportPage = () => {
  const ctx = useContext(AppContext);

  const [jobsProto, setJobsProto] = useState(
    PpicJobs.fromPartial({ jobs: [] })
  );
  const [selectedJob, setSelectedJob] = useState<PpicJob | null>(null);
  const [selectedPanelCode, setSelectedPanelCode] =
    useState<PpicPanelCode | null>(null);
  const [serialNumbers, setSerialNumbers] =
    useState<PpicPanelCodeSerialNumber | null>(null);
  const [serialNumberManuals, setSerialNumberManuals] =
    useState<PpicPanelCodeSerialNumber | null>(null);
  const [showType, setShowType] = useState<ShowTypeType>("All");

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchJobsData();
  };

  const fetchJobsData = async () => {
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
      withProducts: true,
      all: true,
    });

    if (d) {
      setJobsProto(d);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>Serial Number Report</h4>
          <div>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/serial-number-xlsx`}
              target="_blank"
            >
              <button className="btn btn-success btn-sm">
                <CloudDownload />
                Download{" "}
              </button>
            </a>
          </div>
        </div>
        <hr className="border border-dark" />
        <div className="d-flex">
          {showTypeList.map((t) => {
            return (
              <>
                <button
                  className={`btn btn-sm ${
                    showType === t ? `btn-primary` : `btn-outline-primary`
                  }`}
                  onClick={() => {
                    setShowType(t);
                  }}
                >
                  {t}
                </button>
              </>
            );
          })}
        </div>
        <div>
          <div className="d-flex">
            <div className="flex-grow-1">
              <Select
                placeholder="Job..."
                options={jobsProto.jobs}
                getOptionLabel={(j) => j.name ?? "No name"}
                onChange={(v) => {
                  const val = v as PpicJob;
                  setSelectedJob(val);
                }}
              />
            </div>
            <div className="flex-grow-1">
              <Select
                placeholder="Product..."
                options={jobsProto.jobs
                  .filter((j) =>
                    selectedJob
                      ? `${j.masterJavaBaseModel?.id}` ===
                        `${selectedJob.masterJavaBaseModel?.id}`
                      : true
                  )
                  .map((j) => j.panelCodes)
                  .flat()}
                getOptionLabel={(c) =>
                  `${c.type ?? "No type"}:${c.name ?? "No name"}:${c.qty ?? 0}`
                }
                onChange={(v) => {
                  const val = v as PpicPanelCode;
                  setSelectedPanelCode(val);
                }}
              />
            </div>
          </div>
        </div>
        <hr className="border border-dark" />
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ height: "65vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "Serial Number",
                "Auto/Manual",
                "Job",
                "Panel Code",
                "Panel Type",
              ].map((h) => {
                return (
                  <>
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  </>
                );
              })}
            </tr>

            {/* Auto SN */}
            {showType === "Auto" || showType === "All" ? (
              (() => {
                const d = [...jobsProto.jobs];

                d.sort(
                  (a, b) =>
                    parseInt(b.masterJavaBaseModel?.id ?? "0") -
                    parseInt(a.masterJavaBaseModel?.id ?? "0")
                );

                return d;
              })()
                .filter((j) =>
                  selectedJob
                    ? j.masterJavaBaseModel?.id ===
                      selectedJob.masterJavaBaseModel?.id
                    : true
                )
                .map((j) =>
                  j.panelCodes
                    .filter((c) =>
                      selectedPanelCode
                        ? c.masterJavaBaseModel?.id ===
                          selectedPanelCode.masterJavaBaseModel?.id
                        : true
                    )
                    .map((c) =>
                      c.serialNumbers.map((n) => ({
                        job: j,
                        panelCode: c,
                        serialNumber: n,
                      }))
                    )
                    .flat()
                )
                .flat()
                .map((d) => {
                  const snrs = d.panelCode.serialNumberRects.filter(
                    (r) =>
                      `${r.serialNumberId}` ===
                      `${d.serialNumber.masterJavaBaseModel?.id}`
                  );
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <div className="d-flex">
                            {getPanelCodeCompleteSerialNumberProto(
                              d.panelCode,
                              d.serialNumber
                            )}{" "}
                            |{" "}
                            <div>
                              <ol>
                                {snrs.length > 0 ? (
                                  <>
                                    {snrs.map((r) => {
                                      return (
                                        <>
                                          <div>
                                            <li className="p-0 m-0">
                                              <small>{r.name}</small>
                                            </li>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ol>
                            </div>
                          </div>
                        </td>
                        <td className="border border-dark">Automatic</td>
                        <td className="border border-dark">{d.job.name}</td>
                        <td className="border border-dark">
                          {d.panelCode.type}:{d.panelCode.name}:
                          {d.panelCode.qty} unit(s)
                        </td>
                        <td className="border border-dark">
                          {d.panelCode.panelCodeType}
                        </td>
                      </tr>
                    </>
                  );
                })
            ) : (
              <></>
            )}

            {/* Manual SN */}
            {showType === "Manual" || showType === "All" ? (
              (() => {
                const d = [...jobsProto.jobs];

                d.sort(
                  (a, b) =>
                    parseInt(b.masterJavaBaseModel?.id ?? "0") -
                    parseInt(a.masterJavaBaseModel?.id ?? "0")
                );

                return d;
              })()
                .filter((j) =>
                  selectedJob
                    ? j.masterJavaBaseModel?.id ===
                      selectedJob.masterJavaBaseModel?.id
                    : true
                )
                .map((j) =>
                  j.panelCodes
                    .filter((c) =>
                      selectedPanelCode
                        ? c.masterJavaBaseModel?.id ===
                          selectedPanelCode.masterJavaBaseModel?.id
                        : true
                    )
                    .map((c) =>
                      c.serialNumberManuals.map((n) => ({
                        job: j,
                        panelCode: c,
                        serialNumber: n,
                      }))
                    )
                    .flat()
                )
                .flat()
                .map((d) => {
                  const snrs = d.panelCode.serialNumberRects.filter(
                    (r) =>
                      `${r.serialNumberManualId}` ===
                      `${d.serialNumber.masterJavaBaseModel?.id}`
                  );
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          <div className="d-flex">
                            {d.serialNumber.serialNumber} |{" "}
                            <div>
                              <ol>
                                {snrs.length > 0 ? (
                                  <>
                                    {snrs.map((r) => {
                                      return (
                                        <>
                                          <li className="p-0 m-0">
                                            <div>
                                              <small>{r.name}</small>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ol>
                            </div>
                          </div>
                        </td>
                        <td className="border border-dark">Manual</td>
                        <td className="border border-dark">{d.job.name}</td>
                        <td className="border border-dark">
                          {d.panelCode.type}:{d.panelCode.name}:
                          {d.panelCode.qty} unit(s)
                        </td>
                        <td className="border border-dark">
                          {d.panelCode.panelCodeType}
                        </td>
                      </tr>
                    </>
                  );
                })
            ) : (
              <></>
            )}

            {/* Rect SN */}
            {showType === "Rect" || showType === "All" ? (
              (() => {
                const d = [...jobsProto.jobs];

                d.sort(
                  (a, b) =>
                    parseInt(b.masterJavaBaseModel?.id ?? "0") -
                    parseInt(a.masterJavaBaseModel?.id ?? "0")
                );

                return d;
              })()
                .filter((j) =>
                  selectedJob
                    ? j.masterJavaBaseModel?.id ===
                      selectedJob.masterJavaBaseModel?.id
                    : true
                )
                .map((j) =>
                  j.panelCodes
                    .filter((c) =>
                      selectedPanelCode
                        ? c.masterJavaBaseModel?.id ===
                          selectedPanelCode.masterJavaBaseModel?.id
                        : true
                    )
                    .map((c) =>
                      c.serialNumberRects.map((n) => ({
                        job: j,
                        panelCode: c,
                        serialNumber: n,
                      }))
                    )
                    .flat()
                )
                .flat()
                .map((d) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">
                          {d.serialNumber.name}
                        </td>
                        <td className="border border-dark">Rect</td>
                        <td className="border border-dark">{d.job.name}</td>
                        <td className="border border-dark">
                          {d.panelCode.type}:{d.panelCode.name}:
                          {d.panelCode.qty} unit(s)
                        </td>
                        <td className="border border-dark">
                          {d.panelCode.panelCodeType}
                        </td>
                      </tr>
                    </>
                  );
                })
            ) : (
              <></>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SerialNumberReportPage;
