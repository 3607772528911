import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import { FepLeveledView } from "../../../models/model";

const FepDocumentSimplified = () => {
  const ctx = useContext(AppContext);

  const [fepLeveleds, setFepLeveleds] = useState<FepLeveledView[]>([]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fepleveled-view`,
        {
          headers: { authorization: ctx?.apiKey ?? "" },
        }
      );

      if (resp.status !== 200) throw await resp.text();
      setFepLeveleds(await resp.json());
    } catch (e) {
      console.log("[error init]", e);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <h5>FEP Documents (Simplified)</h5>
          <div>
            <Link to="/fepdocumentssimplifieddetail/new">
              <button className="btn btn-sm btn-primary">Add</button>
            </Link>
          </div>
        </div>
        <hr />
        <div
          className="overflow-auto border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-bordered table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <thead>
              <tr>
                {["#", "Name"].map((h) => (
                  <th
                    className="border border-dark bg-dark text-light"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {fepLeveleds.map((f, i) => {
                return (
                  <>
                    <tr>
                      <td className="border border-dark">{i + 1}</td>
                      <td className="border border-dark">
                        <Link
                          to={`/fepdocumentssimplifieddetail/${f.fepLeveled?.id}`}
                        >
                          {f.fepLeveled?.name && f.fepLeveled.name !== ""
                            ? f.fepLeveled.name
                            : "[NO NAME]"}
                        </Link>{" "}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FepDocumentSimplified;
