import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const Search = (props: {
  onPressEnter: (search: string) => void
}) => {
  const [state, setState] = useState({
    search: ""
  });

  const handleChange = (e: any) => {
    e.preventDefault(); 
    props.onPressEnter(state.search);
  }
  
  return (
    <form>
      <Form.Control 
        type="text"
        placeholder="Search..."
        onChange={e => setState({ ...state, search: e.target.value })}
      />
      <Button onClick={handleChange} style={{ display: "none" }} type="submit" /> 
    </form>
  );
};

export default Search;