import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ProblemType } from '../../../models/model';
import { initialProblemType } from '../../../models/modelinitials';
import { RequestStatus } from '../../../models/RequestStatus';

const ProblemTypeForm = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const apiKey = localStorage.getItem("apiKey");
  
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NotAsked);
  const [problemType, setProblemType] = useState<ProblemType>({...initialProblemType});

  useEffect(() => {
    const parsedId = id ? id : "";

    if(!isNaN(parseInt(parsedId))) {
      fetchProblemType(parseInt(parsedId));
    }
  }, []);

  const fetchProblemType = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/problemtypes/${id}`, {
        headers: { "authorization": apiKey ? apiKey : "" }
      });

      setProblemType({...await response.json()});
      setRequestStatus(RequestStatus.Success);
    } catch(e) {
      console.error(e);
      setRequestStatus(RequestStatus.Error);
    }
  }

  const handleSave = async (e: any) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/problemtypes`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "authorization": apiKey ? apiKey : ""
        },
        body: JSON.stringify(problemType)
      })
      if(response.status !== 201) throw "Error sasving problem type";
      setRequestStatus(RequestStatus.Success);
      history.push("/problemtypes");
    } catch(e) {
      console.error(e);
      setRequestStatus(RequestStatus.Error);
    }
  }

  return (
    <Box>
      <h3>Problem Type</h3>
      <Box display="flex">
        <Box>
          <Link to="/problemtypes">
            <Button
              color="primary"
            >
              <ChevronLeftIcon /> Back
            </Button>
          </Link>
        </Box>
        <Box mx={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        <Box>
          {requestStatus === RequestStatus.Loading
            ? <CircularProgress disableShrink />
            : <></>
          }
        </Box>
      </Box>
      <Box display="flex">
        <Box width={500}>
          <TextField
            fullWidth
            label="Problem name"
            onChange={e => setProblemType({ ...problemType, name: e.target.value })}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ProblemTypeForm;