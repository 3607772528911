import {
    CircularProgress, TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'

const AsyncAutocompleteJobs = (props) => {
  const [open, setOpen] = useState(false)
  const [requestStatus, setRequestStatus] = useState('notAsked')
  const [options, setOptions] = useState([])
  const [searchInput, setSearchInput] = useState(null)
  // const loading = open && options.length === 0
  const loading = requestStatus === 'loading'

  useEffect(() => {
    if(open) {
      (async () => {
        setOptions([])
        try {
          setRequestStatus('loading')
          const url = `${props.url}?${props.param}=${props.paramOverride ? props.paramOverride : searchInput}&perPage=10`
          // console.log('Fetch from:', url)
          const response = await fetch(url)
          const dataJson = await response.json()
    
          console.log(`Achieved data from ${props.url} :`, dataJson)
          
          setOptions(dataJson)
          setRequestStatus('success')
        }
        catch(err) {
          setRequestStatus('error')
        }
      })()
    }
  }, [searchInput])

  useEffect(() => {
    if(!open) {
      setOptions([])
      setSearchInput(null)
    }
  }, [open])

  // Helper functions & variables
  const paramOrDefault = props.param === undefined ? 'title' : props.param
  let valueObject = {}

  if(props.value === undefined || props.value === null) {
    valueObject = null
  }
  else {
    valueObject[paramOrDefault] = props.value[paramOrDefault]
  }

  return (
    <Autocomplete
      id="crud-table-combobox"
      value={valueObject}
      style={{width: props.width === undefined ? 250 : props.width}}
      open={open}
      onOpen={() => {
        // console.log('opened')
        setOpen(true)
        setSearchInput("")
      }}
      onClose={() => {
        console.log('closed')
        setOpen(false)
      }}
      onChange={(event, value) => {
        // console.log('Selected value:', [event, value])
        props.onChange(value)
      }}
      getOptionSelected={(option, value) => option[paramOrDefault] === value[paramOrDefault]}
      getOptionLabel={option => option[paramOrDefault]}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField 
          {...params} 
          label={props.label}
          fullWidth
            InputProps={{
            ...params.InputProps,
            endAdornment: (
              < >
                { loading ? <CircularProgress disableShrink color="inherit" size={20} /> : null }
                { params.InputProps.endAdornment }
              </>
            )
          }}
          value={{searchInput}}
          onInput={event => {
            setOpen(true)
            setSearchInput(event.target.value)
          }}
          size="small" 
        />
      )}
    />
  )
}

export default AsyncAutocompleteJobs