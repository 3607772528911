import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, CircularProgress, Grid, Switch, TextField } from "@material-ui/core";
import { blue, green } from "@material-ui/core/colors";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { secondsToHms } from "../../../helpers";
import { WiringFepDocument, WiringModule, WiringModuleType } from "../../../models/model";
import { initialWiringFepDocument, initialWiringModule } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const WiringFepDocumentForm = () => {
  const { id } = useParams() as any;
  const history = useHistory();

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.NotAsked);
  const [wiringFepDocument, setWiringFepDocument] = useState<WiringFepDocument>({...initialWiringFepDocument});
  const [viewSwitch, setViewSwitch] = useState<{
    cablePrep: boolean, 
    panelPrep: boolean, 
    interconnection: boolean
  }>({
    cablePrep: true, 
    panelPrep: true, 
    interconnection: true
  });

  // Selections and forms
  const [moduleForm, setModuleForm] = useState<{
    name: string,
    connections: number,
    time: number,
    type: string
  }>({ name: "", connections: 0, time: 0, type: "WK" });
  const [moduleIdsToDelete, setModuleIdsToDelete] = useState<number[]>([]);

  useEffect(() => {
    if(id && !isNaN(parseInt(id))) {
      fetchWiringFepDocument(parseInt(id));
    }
  }, []);

  const fetchWiringFepDocument = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const apiKey = localStorage.getItem("apiKey");
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/wiringfepdocuments/${id}`, {
        headers: { "authorization": apiKey ? apiKey : "" }
      });
      if(response.status !== 200) throw "Error fetching wiring fep document";
      setWiringFepDocument(await response.json());
      setRequestStatus(RequestStatus.Success);
    } catch(e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  }

  const save = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const apiKey = localStorage.getItem("apiKey");
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/wiringfepdocuments`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "authorization": apiKey ? apiKey : ""
        },
        body: JSON.stringify(wiringFepDocument)
      });
      if(response.status !== 201) throw "Error adding FEP Wiring Document";

      const wiringFepDocumentJson: WiringFepDocument | null | undefined = await response.json();
    
      if(wiringFepDocumentJson) {
        // Add wiring modules
        await Promise.all(wiringFepDocument.wiringModules.map(async (wiringModule) => {
          return await fetch(`${process.env.REACT_APP_BASE_URL}/wiringmodules`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              "authorization": apiKey ? apiKey : ""
            },
            body: JSON.stringify({
              ...wiringModule,
              wiringFepDocument: {
                ...initialWiringFepDocument,
                id: wiringFepDocumentJson.id
              }
            })
          })
        }));

        // Delete wiring modules
        await Promise.all(moduleIdsToDelete.map(async (id) => {
          return await fetch(`${process.env.REACT_APP_BASE_URL}/wiringmodules/${id}`, {
            method: "DELETE",
            headers: {
              "authorization": apiKey ? apiKey : ""
            }
          })
        }));
      }

      setRequestStatus(RequestStatus.Success);
      history.push("/wiringfepdocuments");
    } catch(e) {
      setRequestStatus(RequestStatus.Error);
    }
  }

  const handleAddModule = () => {
    const newWiringModule: WiringModule = {
      ...initialWiringModule,
      uuid: uuidv4(),
      name: moduleForm.name,
      connections: moduleForm.connections,
      type: moduleForm.type as WiringModuleType
    };
    
    setWiringFepDocument({
      ...wiringFepDocument, 
      wiringModules: [newWiringModule, ...wiringFepDocument.wiringModules] 
    });
  }

  const handleDeleteWiringModule = (uuid: string) => {
    const wiringModuleSlice = [...wiringFepDocument.wiringModules]
    const foundWiringModule = wiringModuleSlice.find(wiringModule => wiringModule.uuid === uuid);

    if(foundWiringModule) {
      console.log("Wiring module found!", foundWiringModule);
      const newWiringModules = wiringModuleSlice.filter(wiringModule => wiringModule.uuid !== foundWiringModule.uuid);
      
      setModuleIdsToDelete([...moduleIdsToDelete, foundWiringModule.id]);
      setWiringFepDocument({
        ...wiringFepDocument,
        wiringModules: [...newWiringModules]
      });
    }
  }

  return (
    <Box>
      FEP Document form page {id}
      <Box display="flex">
        <Link to="/wiringfepdocuments">
          <Button
            color="primary"
          >
            <ChevronLeftIcon /> Back
          </Button>
        </Link>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={save}
          >
            Save
          </Button>
        </Box>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={save}
          >
            Save Copy
          </Button>
        </Box>
        {requestStatus === RequestStatus.Loading
          ? <CircularProgress disableShrink />
          : <></>
        }
      </Box>
      <Grid container>
        <TextField
          value={wiringFepDocument.name}
          onChange={evt => setWiringFepDocument({...wiringFepDocument, name: evt.target.value})}
          label="Name"
        />
        <Box mx={1} width={100}>
          <TextField
            fullWidth
            label="Time/Connection(s)"
            value={wiringFepDocument.tubingTime}
            onChange={evt => setWiringFepDocument({...wiringFepDocument, timePerConnection: isNaN(parseInt(evt.target.value)) ? 0 : parseInt(evt.target.value) })}
          />
        </Box>
        <Box mx={2} width={100}>
          <TextField
            fullWidth
            label="Connections"
            value={wiringFepDocument.connections.toString()}
            onChange={evt => setWiringFepDocument({...wiringFepDocument, connections: isNaN(parseInt(evt.target.value)) ? 0 : parseInt(evt.target.value) })}
          />
        </Box>
        <Box>
          <h3>
            Cutting time needed: {secondsToHms(wiringFepDocument.connections * wiringFepDocument.timePerConnection)}
          </h3>
        </Box>
        <Box display="flex">
          <Box border={1} px={1} mx={1}>
            Cable Prep
            <Switch checked={viewSwitch.cablePrep} onClick={() => setViewSwitch({ ...viewSwitch, cablePrep: !viewSwitch.cablePrep })} />
          </Box>
          <Box border={1} px={1} mx={1}>
            Panel Prep
            <Switch checked={viewSwitch.panelPrep} onClick={() => setViewSwitch({ ...viewSwitch, panelPrep: !viewSwitch.panelPrep })} />
          </Box>
          <Box border={1} px={1} mx={1}>
            Interconnection
            <Switch checked={viewSwitch.interconnection} onClick={() => setViewSwitch({ ...viewSwitch, interconnection: !viewSwitch.interconnection })} />
          </Box>Process tim
        </Box>
      </Grid>
      <Box display={viewSwitch.cablePrep ? "block" : "none"}>
        <h2>Cable Preparation</h2>
        <h3>Process time (seconds)</h3>
        <Box display="flex">
          <Box mx={2} width={75}>
            <TextField
              fullWidth
              label="Grouping"
              value={wiringFepDocument.groupingTime}
              onChange={evt => setWiringFepDocument({...wiringFepDocument, groupingTime: isNaN(parseInt(evt.target.value)) ? 0 : parseInt(evt.target.value) })}
            />
          </Box>
          <Box mx={2} width={75}>
            <TextField
              fullWidth
              label="Tubing"
              value={wiringFepDocument.tubingTime}
              onChange={evt => setWiringFepDocument({...wiringFepDocument, tubingTime: isNaN(parseInt(evt.target.value)) ? 0 : parseInt(evt.target.value) })}
            />
          </Box>
          <Box mx={2} width={75}>
            <TextField
              fullWidth
              label="Crimping"
              value={wiringFepDocument.crimpingTime}
              onChange={evt => setWiringFepDocument({...wiringFepDocument, crimpingTime: isNaN(parseInt(evt.target.value)) ? 0 : parseInt(evt.target.value) })}
            />
          </Box>
        </Box>
        <Box my={1}>
          <Box display="flex">
            <h3>Modules</h3>
            <Box mx={1}>
              {(() => {
                const totalConnections = wiringFepDocument.connections;
                const connectionsAssigned = wiringFepDocument.wiringModules.reduce((acc, wiringModule) => acc + wiringModule.connections, 0);
                const connectionsLeft = totalConnections - connectionsAssigned;

                if(connectionsLeft < 0) {
                  return (
                    <h3 style={{color: "red"}}>
                      {`Connections over-assigned: ${totalConnections - connectionsAssigned}/${totalConnections}`}
                    </h3>
                  );
                } else if(connectionsLeft > 0) {
                  return (
                    <h3 style={{color: "red"}}>
                      {`Connection(s) left: ${totalConnections - connectionsAssigned}/${totalConnections}`}
                    </h3>
                  ); 
                } else {
                  return (
                    <h3 style={{color: green[400]}}>
                      {`Connections all assigned: ${totalConnections - connectionsAssigned}/${totalConnections}`}
                    </h3>
                  );
                }
              })()}
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box mx={1}>
              <TextField 
                label="Name"
                value={moduleForm.name}
                onChange={evt => setModuleForm({...moduleForm, name: evt.target.value})}
              />
            </Box>
            <Box mx={1} width={125}>
              <TextField 
                fullWidth
                label="Connections"
                value={moduleForm.connections.toString()}
                onChange={evt => setModuleForm({...moduleForm, connections: isNaN(parseInt(evt.target.value)) ? 0 : parseInt(evt.target.value) })}
              />
            </Box>
            <Box width={125} mx={1}>
              Module type:
              <Select
                placeholder="Type..."
                value={{ label: moduleForm.type, value: moduleForm.type }}
                options={["WK", "LR"].map(moduleType => ({ label: moduleType, value: moduleType }))}
                onChange={selected => setModuleForm({...moduleForm, type: (selected as { value: string, label: string }).value})}
              />
            </Box>
            <Box>
              <Button 
                variant="contained" 
                color="primary"
                type="submit"
                onClick={handleAddModule}
              >
                Add module
              </Button>
            </Box>
          </Box>
        </Box>
        <Grid container>
          {wiringFepDocument.wiringModules.map(wiringModule => (
            <Box border={1} p={1} m={1} width={200}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <h3 style={{color: green[500]}}>{wiringModule.type}</h3>
                <Box>
                  <Button
                    variant="contained" 
                    color="secondary"
                    size="small"
                    onClick={() => handleDeleteWiringModule(wiringModule.uuid)}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
              <h3 style={{color: blue[400]}}>{wiringModule.name}: {wiringModule.connections} conns</h3>
              <h4>Grouping time: {secondsToHms(wiringModule.connections * wiringFepDocument.groupingTime)}</h4>
              <h4>Tubing time: {secondsToHms(wiringModule.connections * wiringFepDocument.tubingTime)}</h4>
              <h4>Crimping time: {secondsToHms(wiringModule.connections * wiringFepDocument.crimpingTime)}</h4>
            </Box>
          ))}
        </Grid>
      </Box>
      <Box display={viewSwitch.panelPrep ? "block" : "none"}>
        <h2>Panel Preparation</h2>
        <Grid container>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box p={1} m={1} border={1}>
              <h4>Dinrail Installment</h4>
              <KeyboardTimePicker 
                ampm={false}
                label="Dinrail Time"
                format="HH:mm:ss"
                views={["hours", "minutes", "seconds"]}
                placeholder="00:00:05"
                mask="__:__:__"
                value={null}
                onChange={() => {}}
              />
            </Box>
            <Box p={1} m={1} border={1}>
              <h4>Cable Duct Installment</h4>
              <KeyboardTimePicker 
                ampm={false}
                label="Cable Duct Time"
                format="HH:mm:ss"
                views={["hours", "minutes", "seconds"]}
                placeholder="00:00:05"
                mask="__:__:__"
                value={null}
                onChange={() => {}}
              />
            </Box>
            <Box p={1} m={1} border={1}>
              <h4>Cutout Installment</h4>
              <KeyboardTimePicker 
                ampm={false}
                label="Cutout Time"
                format="HH:mm:ss"
                views={["hours", "minutes", "seconds"]}
                placeholder="00:00:05"
                mask="__:__:__"
                value={null}
                onChange={() => {}}
              />
            </Box>
            <Box p={1} m={1} border={1}>
              <h4>Component Installment</h4>
              <KeyboardTimePicker 
                ampm={false}
                label="Component Time"
                format="HH:mm:ss"
                views={["hours", "minutes", "seconds"]}
                placeholder="00:00:05"
                mask="__:__:__"
                value={null}
                onChange={() => {}}
              />
            </Box>
            <Box p={1} m={1} border={1}>
              <h4>Labeling</h4>
              <KeyboardTimePicker 
                ampm={false}
                label="Labeling Time"
                format="HH:mm:ss"
                views={["hours", "minutes", "seconds"]}
                placeholder="00:00:05"
                mask="__:__:__"
                value={null}
                onChange={() => {}}
              />
            </Box>
            <Box p={1} m={1} border={1}>
              <h4>Nameplate Installment</h4>
              <KeyboardTimePicker 
                ampm={false}
                label="Nameplate Time"
                format="HH:mm:ss"
                views={["hours", "minutes", "seconds"]}
                placeholder="00:00:05"
                mask="__:__:__"
                value={null}
                onChange={() => {}}
              />
            </Box>
          </MuiPickersUtilsProvider>
        </Grid>
      </Box>
      <Box display={viewSwitch.interconnection ? "block" : "none"}>
        <h2>Interconnection</h2>
        <Box>
          <h3>WK</h3>
          {wiringFepDocument.wiringModules.filter(wiringModule => wiringModule.type == "WK").map(wiringModule => (
            <Box>
              <h3>{wiringModule.name}</h3>
            </Box>
          ))}
        </Box>
        <Box>
          <h3>LR</h3>
          {wiringFepDocument.wiringModules.filter(wiringModule => wiringModule.type == "LR").map(wiringModule => (
            <Box>
              <h3>{wiringModule.name}</h3>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default WiringFepDocumentForm