import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { menuPortalCSS } from "react-select/src/components/Menu";
import { v4 } from "uuid";
import { AppContext } from "../../../App";
import {
  fetchCustomers,
  fetchCustomersProto,
  fetchIntegrationModulesProto,
  fetchIntegrations,
  fetchJobsProtoSimple,
  fetchMachinePrograms,
  fetchMachinesProto,
  fetchPanelCodesProtoSimple,
  fetchSimplifiedProcessTypes,
  fetchSimplifiedProcessTypesProto,
  flattenModules,
} from "../../../helpers";
import {
  MasterJavaBaseModel,
  PpicIndividualEntities,
  PpicIntegrationModules,
  PpicJob,
  PpicJobs,
  PpicMachine,
  PpicMachineProgram,
  PpicMachineProgramMachine,
  PpicMachinePrograms,
  PpicMachines,
  PpicPanelCode,
  PpicPanelCodes,
  PpicSimplifiedProcessType,
  PpicSimplifiedProcessTypes,
} from "../../../masterbigsystem";
import {
  Integration,
  IntegrationModuleView,
  IntegrationView,
} from "../../../models/model";
import murmur from "murmurhash-js";

const MachineProgramPage = () => {
  const history = useHistory();
  const ctx = useContext(AppContext);

  const [machinePrograms, setMachinePrograms] = useState<
    PpicMachinePrograms | undefined
  >(undefined);
  const [machineProgramsFormer, setMachineProgramsFormer] = useState<
    PpicMachinePrograms | undefined
  >(undefined);

  const [jobs, setJobs] = useState<PpicJobs | null | undefined>(null);
  const [panelCodes, setPanelCodes] = useState<
    PpicPanelCodes | null | undefined
  >(null);
  const [panelCodesLoading, setPanelCodesLoading] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [searchProgram, setSearchProgram] = useState("");
  const [programsView, setProgramsView] = useState<"Normal" | "Selection">(
    "Normal"
  );
  const [customers, setCustomers] = useState<PpicIndividualEntities>(
    PpicIndividualEntities.fromPartial({})
  );
  const [simplifiedProcessTypes, setSimplifiedProcessTypes] = useState(
    PpicSimplifiedProcessTypes.fromPartial({})
  );
  const [machines, setMachines] = useState(PpicMachines.fromPartial({}));

  const [filters, setFilters] = useState({
    job: null as PpicJob | null,
    panelCode: null as PpicPanelCode | null,
  });
  const [integrationModules, setIntegrationModules] = useState<
    IntegrationView[]
  >([]);
  const [newMachineProgram, setNewMachineProgram] = useState(
    PpicMachineProgram.fromPartial({
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: v4() }),
    })
  );

  const flattenedIntegrations = flattenModules(null, integrationModules);

  useEffect(() => {
    handleInit();
  }, []);

  const handleFetchPanelCodesSimple = async () => {
    setPanelCodesLoading(true);
    setPanelCodes(
      await fetchPanelCodesProtoSimple({ apiKey: ctx?.apiKey ?? "" })
    );
    setPanelCodesLoading(false);
  };
  const handleFetchJobsProtoSimple = async () => {
    setJobsLoading(true);
    setJobs(
      await fetchJobsProtoSimple({
        apiKey: ctx?.apiKey ?? "",
        withProducts: true,
      })
    );
    setJobsLoading(false);
  };

  const handleInit = async () => {
    fetchMachineProgramsData();
    handleFetchPanelCodesSimple();
    handleFetchJobsProtoSimple();
    fetchIndividualEntitiesData();
    fetchSimplifiedProcessTypesData();
    fetchMachinesData();
    fetchPpicIntegrationModulesData();
  };

  const fetchPpicIntegrationModulesData = async () => {
    setIntegrationModules(
      await fetchIntegrations({ apiKey: ctx?.apiKey ?? "" })
    );
  };
  const fetchMachineProgramsData = async () => {
    const d = await fetchMachinePrograms({ apiKey: ctx?.apiKey });
    setMachinePrograms(structuredClone(d));
    setMachineProgramsFormer(structuredClone(d));
  };
  const fetchIndividualEntitiesData = async () => {
    setCustomers(await fetchCustomersProto({ apiKey: ctx?.apiKey }));
  };
  const fetchMachinesData = async () => {
    const d = await fetchMachinesProto({ apiKey: ctx?.apiKey });

    if (d) {
      setMachines(d);
    }
  };
  const fetchSimplifiedProcessTypesData = async () => {
    const d = await fetchSimplifiedProcessTypesProto({ apiKey: ctx?.apiKey });

    if (d) {
      setSimplifiedProcessTypes(d);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>Machine Programs</h4>
          <div className="mx-2">
            <Link to="/machineprograms/new">
              <button className="btn btn-primary btn-sm"> Add</button>
            </Link>{" "}
          </div>
        </div>
        <hr />
        <div className="d-flex">
          {(["Normal", "Selection"] as ("Normal" | "Selection")[]).map((s) => {
            return (
              <>
                <div>
                  <button
                    onClick={() => {
                      setProgramsView(s);
                    }}
                    className={`btn ${
                      s === programsView ? `btn-primary` : `btn-outline-primary`
                    } btn-sm`}
                  >
                    {s}
                  </button>
                </div>
              </>
            );
          })}
        </div>
        {programsView === "Normal" ? (
          <>
            <div className="d-flex">
              <div className="flex-grow-1">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    document.getElementById("search-program-id")?.blur();
                  }}
                >
                  <input
                    className="form-control form-control-sm"
                    placeholder="Search Program..."
                    id="search-program-id"
                    onBlur={(e) => {
                      setSearchProgram(e.target.value);
                    }}
                  />
                </form>
              </div>
              <div className="flex-grow-1">
                <Select placeholder="Customer..." />
              </div>
              <div className="flex-grow-1">
                <Select
                  placeholder="Job..."
                  options={jobs?.jobs ?? []}
                  getOptionLabel={(j) => `${j.name ?? "No name"}`}
                />
              </div>
              <div className="flex-grow-1">
                <Select placeholder="Product..." />
              </div>
            </div>
            <div
              className="overflow-auto border border-dark"
              style={{ height: "65vh", resize: "vertical" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                {[
                  "#",
                  "Program",
                  "Time (mins)",
                  "Panel Code",
                  "Qty run",
                  "PICs",
                ].map((h) => (
                  <th
                    className="text-light bg-dark"
                    style={{ position: "sticky", top: 0 }}
                  >
                    {h}
                  </th>
                ))}
                {machinePrograms?.machinePrograms
                  ?.filter((mp) =>
                    mp.name?.toLowerCase().includes(searchProgram.toLowerCase())
                  )
                  .map((mp, i) => {
                    return (
                      <>
                        <tr>
                          <td className="border border-dark">{i + 1}</td>
                          <td className="border border-dark">
                            <Link
                              to={`/machineprograms/${mp.masterJavaBaseModel?.id}`}
                            >
                              {mp.name}
                            </Link>{" "}
                          </td>
                          <td className="border border-dark">
                            {mp.timeProcessMins}
                          </td>
                          <td className="border border-dark">
                            {/* {mp.panelCodeId} */}
                            {panelCodesLoading ? (
                              <>
                                <Spinner animation="border" />
                              </>
                            ) : (
                              <>
                                <>
                                  {(() => {
                                    const foundPc = panelCodes?.panelCodes.find(
                                      (pcx) =>
                                        pcx.masterJavaBaseModel?.id ===
                                        mp.panelCodeId
                                    );
                                    const pcString = `${
                                      jobsLoading
                                        ? "(Loading jobs...)"
                                        : jobs?.jobs.find(
                                            (j) =>
                                              j.masterJavaBaseModel?.id ===
                                              foundPc?.jobId
                                          )?.name
                                    } ${foundPc?.type} - ${foundPc?.name} - ${
                                      foundPc?.qty ?? "No"
                                    } unit(s)`;

                                    return (
                                      <div>
                                        <div>
                                          {mp.panelCodeId ? (
                                            <strong>{pcString}</strong>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })()}
                                </>
                              </>
                            )}
                          </td>
                          <td className="border border-dark">{mp.qtyRun}</td>
                          <td className="border border-dark">
                            <ol>
                              {mp.machineProgramPics.map((p) => {
                                const fUser = ctx?.extUsers.find(
                                  (u) => `${u.id}` === `${p.extUserId}`
                                );
                                return (
                                  <>
                                    <li>
                                      <div>
                                        {fUser?.name} ({fUser?.departmentName})
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </ol>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
        {programsView === "Selection" ? (
          <>
            <div>
              <div>
                <strong>Select Job</strong>
              </div>
              <div className="flex-grow-1">
                <Select
                  placeholder="Job..."
                  options={jobs?.jobs ?? []}
                  getOptionLabel={(j) => `${j.name ?? "No name"}`}
                  onChange={(v) => {
                    const val = v as unknown as PpicJob;

                    setFilters({ ...filters, job: val });
                  }}
                />
              </div>

              {filters.job ? (
                <>
                  <div>
                    <strong>Filter product</strong>
                  </div>
                  <div className="flex-grow-1">
                    <Select
                      placeholder="Product..."
                      options={
                        panelCodes?.panelCodes.filter(
                          (pc) =>
                            `${pc.jobId}` ===
                            `${filters.job?.masterJavaBaseModel?.id}`
                        ) ?? []
                      }
                      getOptionLabel={(pc) =>
                        `${pc.type} - ${pc.name}: ${pc.qty} unit(s)`
                      }
                      onChange={(v) => {
                        const val = v as unknown as PpicPanelCode;

                        setFilters({ ...filters, panelCode: val });
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              <hr />

              {filters.panelCode ? (
                <>
                  <div>
                    <h5>Predefined Add</h5>
                    <div>
                      <small>Program name</small>
                      <input
                        style={{ minWidth: 200 }}
                        className="form-control form-control-sm"
                        placeholder="Program name..."
                        onBlur={(e) => {
                          setNewMachineProgram({
                            ...newMachineProgram,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <small>Main part</small>
                      <Select
                        placeholder="Main part..."
                        options={flattenedIntegrations}
                        getOptionLabel={(m) =>
                          `${m.integration?.name}: ${m.module.integrationModule?.type}: ${m.module.integrationModule?.name}`
                        }
                        value={flattenedIntegrations.find(
                          (i) =>
                            `${i.module.integrationModule?.id}` ===
                            `${newMachineProgram.integrationModuleId}`
                        )}
                        onChange={(v) => {
                          const val = (
                            v as unknown as {
                              integration: IntegrationView | null;
                              module: IntegrationModuleView;
                            }
                          ).module;

                          if (val) {
                            setNewMachineProgram({
                              ...newMachineProgram,
                              integrationModuleId: val.integrationModule?.id
                                ? `${val.integrationModule?.id}`
                                : undefined,
                            });
                          }
                        }}
                      />
                    </div>
                    <div>
                      <small>Process time (mins)</small>

                      <input
                        className="form-control form-control-sm"
                        placeholder="Process time (mins)..."
                        onBlur={(e) => {
                          setNewMachineProgram({
                            ...newMachineProgram,
                            timeProcessMins: isNaN(parseInt(e.target.value))
                              ? newMachineProgram.timeProcessMins
                              : parseInt(e.target.value),
                          });
                        }}
                      />
                    </div>
                    <div>
                      <small>Qty</small>

                      <input
                        className="form-control form-control-sm"
                        placeholder="Qty..."
                        onBlur={(e) => {
                          setNewMachineProgram({
                            ...newMachineProgram,
                            qtyRun: isNaN(parseInt(e.target.value))
                              ? newMachineProgram.timeProcessMins
                              : parseInt(e.target.value),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setMachinePrograms({
                        ...machinePrograms,
                        machinePrograms: machinePrograms?.machinePrograms
                          ? [
                              {
                                ...newMachineProgram,
                                panelCodeId:
                                  filters.panelCode?.masterJavaBaseModel?.id,
                              },
                              ...machinePrograms.machinePrograms,
                            ]
                          : machinePrograms?.machinePrograms ?? [],
                      });
                      setNewMachineProgram({
                        ...newMachineProgram,
                        masterJavaBaseModel: {
                          ...newMachineProgram.masterJavaBaseModel,
                          uuid: v4(),
                        },
                      });
                    }}
                    className="btn btn-primary btn-sm"
                  >
                    <Add /> Add From Temp
                  </button>
                  <hr className="border border-dark" />
                  <div>
                    <button
                      onClick={() => {
                        setMachinePrograms({
                          ...machinePrograms,
                          machinePrograms: machinePrograms?.machinePrograms
                            ? [
                                PpicMachineProgram.fromPartial({
                                  panelCodeId:
                                    filters.panelCode?.masterJavaBaseModel?.id,
                                  masterJavaBaseModel:
                                    MasterJavaBaseModel.fromPartial({
                                      uuid: v4(),
                                    }),
                                }),
                                ...machinePrograms.machinePrograms,
                              ]
                            : machinePrograms?.machinePrograms ?? [],
                        });
                      }}
                      className="btn btn-sm btn-primary"
                    >
                      <Add /> Add
                    </button>
                  </div>
                  <div
                    className="overflow-auto  border border-dark shadow shadow-md"
                    style={{ height: "30vh", resize: "vertical" }}
                  >
                    <table
                      className="table table-sm"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          "#",
                          "Name",
                          "Main Part",

                          "Process Time (mins)",
                          "x Run",

                          "Qty set/prog",
                          ...(() => {
                            const spts = [
                              ...simplifiedProcessTypes.processTypes,
                            ];
                            spts.sort(
                              (a, b) =>
                                a.masterJavaBaseModel?.ordering?.localeCompare(
                                  b.masterJavaBaseModel?.ordering ?? ""
                                ) ?? 0
                            );
                            return spts;
                          })().map((spt) => spt.name),
                        ].map((h) => (
                          <>
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          </>
                        ))}
                      </tr>
                      {machinePrograms?.machinePrograms
                        .filter(
                          (mp) =>
                            `${mp.panelCodeId}` ===
                            `${filters.panelCode?.masterJavaBaseModel?.id}`
                        )
                        .map((mp, i) => {
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">{i + 1}</td>
                                <td className="border border-dark">
                                  <input
                                    key={`mp-name-${mp.masterJavaBaseModel?.uuid}`}
                                    className="form-control form-control-sm"
                                    style={{ minWidth: 200 }}

                                    placeholder="Name..."
                                    defaultValue={mp.name}
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setMachinePrograms({
                                          ...machinePrograms,
                                          machinePrograms:
                                            machinePrograms.machinePrograms.map(
                                              (mpx) =>
                                                mpx &&
                                                mpx.masterJavaBaseModel
                                                  ?.uuid ===
                                                  mp.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...mpx,
                                                      name: e.target.value,
                                                    }
                                                  : mpx
                                            ),
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td className="border border-dark">
                                  <div style={{ width: 300 }}>
                                    <Select
                                      placeholder="Main part..."
                                      options={flattenedIntegrations}
                                      getOptionLabel={(m) =>
                                        `${m.integration?.name}: ${m.module.integrationModule?.type}: ${m.module.integrationModule?.name}`
                                      }
                                      value={flattenedIntegrations.find(
                                        (i) =>
                                          `${i.module.integrationModule?.id}` ===
                                          `${mp.integrationModuleId}`
                                      )}
                                      onChange={(v) => {
                                        const val = (
                                          v as unknown as {
                                            integration: IntegrationView | null;
                                            module: IntegrationModuleView;
                                          }
                                        ).module;

                                        if (val) {
                                          setMachinePrograms({
                                            ...machinePrograms,
                                            machinePrograms:
                                              machinePrograms.machinePrograms.map(
                                                (mpx) =>
                                                  mpx &&
                                                  mpx.masterJavaBaseModel
                                                    ?.uuid ===
                                                    mp.masterJavaBaseModel?.uuid
                                                    ? {
                                                        ...mpx,
                                                        integrationModuleId: val
                                                          .integrationModule?.id
                                                          ? `${val.integrationModule?.id}`
                                                          : undefined,
                                                      }
                                                    : mpx
                                              ),
                                          });
                                        }
                                      }}
                                    />
                                    <div>
                                      <small>
                                        {(() => {
                                          const foundIntegration =
                                            flattenedIntegrations.find(
                                              (i) =>
                                                `${i.module.integrationModule?.id}` ===
                                                `${mp.integrationModuleId}`
                                            );

                                          return foundIntegration
                                            ? `${foundIntegration?.integration?.name}: ${foundIntegration?.module.integrationModule?.type}: ${foundIntegration?.module.integrationModule?.name}`
                                            : "None";
                                        })()}
                                      </small>
                                    </div>
                                  </div>
                                </td>
                                <td className="border border-dark">
                                  <input
                                    key={`mp-tpm-${mp.masterJavaBaseModel?.uuid}`}
                                    className="form-control form-control-sm"
                                    placeholder="Time..."
                                    defaultValue={mp.timeProcessMins}
                                    style={{ width: 75 }}
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setMachinePrograms({
                                          ...machinePrograms,
                                          machinePrograms:
                                            machinePrograms.machinePrograms.map(
                                              (mpx) =>
                                                mpx &&
                                                mpx.masterJavaBaseModel
                                                  ?.uuid ===
                                                  mp.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...mpx,
                                                      timeProcessMins: isNaN(
                                                        parseInt(e.target.value)
                                                      )
                                                        ? mpx.timeProcessMins
                                                        : parseInt(
                                                            e.target.value
                                                          ),
                                                    }
                                                  : mpx
                                            ),
                                        });
                                      }
                                    }}
                                  />
                                </td>
                                <td className="border border-dark">
                                  <input
                                    key={`mp-run-${mp.masterJavaBaseModel?.uuid}`}
                                    className="form-control form-control-sm"
                                    placeholder="x run..."
                                    defaultValue={mp.qtyRun}
                                    style={{ width: 75 }}
                                    onBlur={(e) => {
                                      if (e.target.value !== "") {
                                        setMachinePrograms({
                                          ...machinePrograms,
                                          machinePrograms:
                                            machinePrograms.machinePrograms.map(
                                              (mpx) =>
                                                mpx &&
                                                mpx.masterJavaBaseModel
                                                  ?.uuid ===
                                                  mp.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...mpx,
                                                      qtyRun: isNaN(
                                                        parseInt(e.target.value)
                                                      )
                                                        ? mpx.qtyRun
                                                        : parseInt(
                                                            e.target.value
                                                          ),
                                                    }
                                                  : mpx
                                            ),
                                        });
                                      }
                                    }}
                                  />
                                </td>

                                <td className="border border-dark"></td>

                                {(() => {
                                  const spts = [
                                    ...simplifiedProcessTypes.processTypes,
                                  ];
                                  spts.sort(
                                    (a, b) =>
                                      a.masterJavaBaseModel?.ordering?.localeCompare(
                                        b.masterJavaBaseModel?.ordering ?? ""
                                      ) ?? 0
                                  );
                                  return spts.map((pt) => (
                                    <>
                                      {mp.masterJavaBaseModel?.id ? (
                                        <>
                                          {" "}
                                          <td className="border border-dark">
                                            {/* {pt.masterJavaBaseModel?.id}
                                        {machines.machines.map(
                                          (m) => m.simpleProcessTypeId
                                        )} */}
                                            <div style={{ width: 200 }}>
                                              <Select
                                                placeholder="Machine..."
                                                options={machines.machines.filter(
                                                  (m) =>
                                                    `${m.simpleProcessTypeId}` ===
                                                    `${pt.masterJavaBaseModel?.id}`
                                                )}
                                                getOptionLabel={(pt) =>
                                                  pt.name ?? ""
                                                }
                                                onChange={(v) => {
                                                  const val =
                                                    v as unknown as PpicMachine;

                                                  const newMachineProgramMachine =
                                                    PpicMachineProgramMachine.fromPartial(
                                                      {
                                                        machineId: val.id,
                                                        machineProgramId:
                                                          mp.masterJavaBaseModel
                                                            ?.id,
                                                        simplifiedProcessTypeId:
                                                          pt.masterJavaBaseModel
                                                            ?.id,
                                                        masterJavaBaseModel:
                                                          MasterJavaBaseModel.fromPartial(
                                                            { uuid: v4() }
                                                          ),
                                                      }
                                                    );

                                                  setMachinePrograms({
                                                    ...machinePrograms,
                                                    machinePrograms:
                                                      machinePrograms.machinePrograms.map(
                                                        (mpx) =>
                                                          mpx &&
                                                          mpx
                                                            .masterJavaBaseModel
                                                            ?.uuid ===
                                                            mp
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                            ? {
                                                                ...mpx,
                                                                machineProgramMachines:
                                                                  mpx.machineProgramMachines.find(
                                                                    (mpm) =>
                                                                      `${mpm.machineProgramId}` ===
                                                                        `${mp.masterJavaBaseModel?.id}` &&
                                                                      `${mpm.simplifiedProcessTypeId}` ===
                                                                        `${pt.masterJavaBaseModel?.id}`
                                                                  )
                                                                    ? mpx.machineProgramMachines.map(
                                                                        (mpm) =>
                                                                          `${mpm.machineProgramId}` ===
                                                                            `${mp.masterJavaBaseModel?.id}` &&
                                                                          `${mpm.simplifiedProcessTypeId}` ===
                                                                            `${pt.masterJavaBaseModel?.id}`
                                                                            ? {
                                                                                ...mpm,
                                                                                machineId:
                                                                                  val.id,
                                                                                machineProgramId:
                                                                                  mp
                                                                                    .masterJavaBaseModel
                                                                                    ?.id,
                                                                                simplifiedProcessTypeId:
                                                                                  pt
                                                                                    .masterJavaBaseModel
                                                                                    ?.id,
                                                                              }
                                                                            : mpm
                                                                      )
                                                                    : [
                                                                        ...mpx.machineProgramMachines,
                                                                        newMachineProgramMachine,
                                                                      ],
                                                              }
                                                            : mpx
                                                      ),
                                                  });
                                                }}
                                              />
                                            </div>
                                            {/* <div>
                                              {
                                                mp.machineProgramMachines.find(
                                                  (mpm) =>
                                                    `${mpm.machineProgramId}` ===
                                                      `${mp.masterJavaBaseModel?.id}` &&
                                                    `${mpm.simplifiedProcessTypeId}` ===
                                                      `${pt.masterJavaBaseModel?.id}`
                                                )?.machineId
                                              }
                                            </div> */}

                                            {machines.machines.find(
                                              (m) =>
                                                `${m.id}` ===
                                                `${
                                                  mp.machineProgramMachines.find(
                                                    (mpm) =>
                                                      `${mpm.machineProgramId}` ===
                                                        `${mp.masterJavaBaseModel?.id}` &&
                                                      `${mpm.simplifiedProcessTypeId}` ===
                                                        `${pt.masterJavaBaseModel?.id}`
                                                  )?.machineId
                                                }`
                                            )?.name ?? "None"}
                                          </td>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ));
                                })()}
                              </tr>
                            </>
                          );
                        })}
                    </table>
                  </div>
                  <div>
                    {jobsLoading ? (
                      <>
                        <Spinner animation="border" />
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="d-flex">
                          <div>
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={async () => {
                                try {
                                  setJobsLoading(true);
                                  const resp = await fetch(
                                    `${process.env.REACT_APP_BASE_URL}/machineprograms-proto-save-bulk`,
                                    {
                                      method: "post",
                                      headers: {
                                        "content-type": "application/json",
                                        authorization: ctx?.apiKey ?? "",
                                      },
                                      body: JSON.stringify(
                                        machinePrograms
                                          ? PpicMachinePrograms.toJSON({
                                              ...machinePrograms,
                                              machinePrograms:
                                                machinePrograms.machinePrograms.filter(
                                                  (m) => {
                                                    if (
                                                      !m.masterJavaBaseModel?.id
                                                    ) {
                                                      return true;
                                                    }

                                                    const foundMachineProgramFormer =
                                                      machineProgramsFormer?.machinePrograms.find(
                                                        (mx) =>
                                                          `${mx.masterJavaBaseModel?.id}` ===
                                                          `${m.masterJavaBaseModel?.id}`
                                                      );

                                                    if (
                                                      !foundMachineProgramFormer
                                                    ) {
                                                      return false;
                                                    }

                                                    // console.log(
                                                    //   'a:',
                                                    //   JSON.stringify(
                                                    //     foundMachineProgramFormer
                                                    //   ),
                                                    //   'b:',
                                                    //   JSON.stringify(
                                                    //     m
                                                    //   ),
                                                    //   murmur.murmur3(
                                                    //     JSON.stringify(
                                                    //       foundMachineProgramFormer
                                                    //     )
                                                    //   ),
                                                    //   murmur.murmur3(
                                                    //     JSON.stringify(m)
                                                    //   )
                                                    // );

                                                    return (
                                                      murmur.murmur3(
                                                        JSON.stringify(
                                                          foundMachineProgramFormer
                                                        )
                                                      ) !==
                                                      murmur.murmur3(
                                                        JSON.stringify(m)
                                                      )
                                                    );
                                                  }
                                                ),
                                            })
                                          : PpicMachinePrograms.fromPartial({})
                                      ),
                                    }
                                  );

                                  window.location.reload();
                                } catch (e) {
                                  console.error(e);
                                } finally {
                                  setJobsLoading(false);
                                }
                              }}
                            >
                              Save
                            </button>
                          </div>
                          <div className="mx-2">
                            <button className="btn btn-sm btn-primary">
                              Copy to Panel
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default MachineProgramPage;
