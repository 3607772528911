import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { getUserFromApiKey, makeDateTimeString } from "../../../helpers";
import { Job, JobRemark, ProblemType } from "../../../models/model";
import {
  initialJob,
  initialJobRemark,
  initialUser,
} from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const Remark = () => {
  const { id } = useParams() as { id: string };

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [job, setJob] = useState<Job | null>(null);
  const [remark, setRemark] = useState<JobRemark>({ ...initialJobRemark });
  const [problemTypes, setProblemTypes] = useState<ProblemType[]>([]);
  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);

  const apiKey = localStorage.getItem("apiKey");
  const user = getUserFromApiKey(apiKey ? apiKey : "");

  useEffect(() => {
    const parsedId = parseInt(id ? id : "");

    if (!isNaN(parsedId)) {
      fetchRemark(parsedId);
    }
    fetchProblemTypes();
  }, []);

  const fetchProblemTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/problemtypes`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      const problemTypes: ProblemType[] | null | undefined =
        await response.json();

      if (problemTypes) {
        setProblemTypes([...problemTypes]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchRemark = async (jobId: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/${jobId}/jobremarks`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      const job: Job | null | undefined = await response.json();

      if (job) {
        setJob(job);
      }

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      setRequestStatus(RequestStatus.Error);
      console.log(e);
    }
  };

  const handleAddRemark = (e: any) => {
    if (job) {
      setJob({
        ...job,
        jobRemarks: [
          ...(job.jobRemarks ?? []),
          {
            ...remark,
            uuid: uuidv4(),
            createdAt: makeDateTimeString(new Date()),
            createdBy: {
              ...initialUser,
              id: user.id,
              name: user.name,
            },
          },
        ],
      });
    }
  };

  const handleDeleteRemark = (jobRemarkInput: JobRemark) => {
    if (job) {
      const newJobRemarks = [...(job.jobRemarks ?? [])];

      setJob({
        ...job,
        jobRemarks: newJobRemarks.filter(
          (remark) => remark.uuid !== jobRemarkInput.uuid
        ),
      });
      setIdsToDelete([jobRemarkInput.id, ...idsToDelete]);
    }
  };

  const handleSave = async () => {
    if (job) {
      setRequestStatus(RequestStatus.Loading);

      const saveRemarksResponse = await Promise.all(
        job.jobRemarks?.map(async (jobRemark) => {
          try {
            await fetch(`${process.env.REACT_APP_BASE_URL}/jobremarks`, {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: apiKey ? apiKey : "",
              },
              body: JSON.stringify({
                ...jobRemark,
                job: {
                  ...initialJob,
                  id: job.id,
                },
              }),
            });
          } catch (e) {
            console.error(e);
          }
        }) ?? []
      );

      const deleteRemarksResponse = await Promise.all(
        idsToDelete.map(async (id) => {
          try {
            await fetch(`${process.env.REACT_APP_BASE_URL}/jobremarks/${id}`, {
              method: "DELETE",
              headers: { authorization: apiKey ? apiKey : "" },
            });
          } catch (e) {
            console.error(e);
          }
        })
      );

      setRequestStatus(RequestStatus.Success);
      window.location.reload();
    }
  };

  const handleToggleRemarkDoneStatus = (uuid: string) => {
    if (job) {
      const newRemarks = [...(job.jobRemarks ?? [])];
      const foundRemark = newRemarks.find((remark) => remark.uuid === uuid);

      if (foundRemark) foundRemark.done = !foundRemark.done;

      setJob({
        ...job,
        jobRemarks: [...newRemarks],
      });
    }
  };

  return (
    <Box>
      <Box display="flex">
        <Box>
          <Link to="/mpsdetailed">
            <Button color="primary">
              <ChevronLeftIcon />
              Back
            </Button>
          </Link>
        </Box>
        <Box mx={2}>
          <Button color="primary" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
      <h3>Remark ID {id}</h3>
      {requestStatus === RequestStatus.Loading ? (
        <CircularProgress disableShrink />
      ) : (
        <Box>
          {!job ? (
            <></>
          ) : (
            <Box>
              <h3>{job.name} remarks:</h3>
              <Box my={1}>
                <Box display="flex">
                  <Box width={500}>
                    <Select
                      placeholder="Select problem type..."
                      options={problemTypes.map((problemType) => ({
                        label: problemType.name,
                        value: problemType,
                      }))}
                      onChange={(selected) => {
                        setRemark({
                          ...remark,
                          problemType: (
                            selected as { label: string; value: ProblemType }
                          ).value,
                        });
                      }}
                    />
                  </Box>

                  <a href="/#/problemtypes/add" target="_blank">
                    <Button variant="outlined" color="primary">
                      Add New Problem Type
                    </Button>
                  </a>
                </Box>
                <Box display="flex">
                  <TextField
                    fullWidth
                    label="Solution"
                    value={remark?.remark}
                    onChange={(e) =>
                      setRemark({ ...remark, remark: e.target.value })
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddRemark}
                >
                  Add Remark
                </Button>
              </Box>
              <Box>
                <Box>
                  <h3>Logs:</h3>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow style={{ backgroundColor: blue[100] }}>
                          <TableCell>Action</TableCell>
                          <TableCell>Datetime</TableCell>
                          <TableCell>Problem Type</TableCell>
                          <TableCell>Solution</TableCell>
                          <TableCell>PIC</TableCell>
                          <TableCell>Solved</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {job.jobRemarks?.map((remark) => (
                          <TableRow>
                            <TableCell>
                              <Button
                                size="small"
                                color="secondary"
                                variant="outlined"
                                onClick={(e) => handleDeleteRemark(remark)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                            <TableCell>{remark.createdAt}</TableCell>
                            <TableCell>{remark.problemType?.name}</TableCell>
                            <TableCell>{remark.remark}</TableCell>
                            <TableCell>{remark.createdBy?.name}</TableCell>
                            <TableCell>
                              <Switch
                                checked={remark.done}
                                onClick={(e) =>
                                  handleToggleRemarkDoneStatus(remark.uuid)
                                }
                              />
                            </TableCell>
                          </TableRow>
                          // <ListItem>
                          //   <Box display="flex" alignItems="center">
                          //     <Button
                          //       color="secondary"
                          //       variant="outlined"
                          //       onClick={e => handleDeleteRemark(remark)}
                          //     >
                          //       Delete
                          //     </Button>
                          //     <Box mx={1}>
                          //       {remark.createdAt}
                          //     </Box>
                          //     |
                          //     <Box mx={1} display="flex" alignItems="center">
                          //       Problem type:&nbsp;<h3>{remark.problemType?.name}</h3>
                          //     </Box>
                          //     |
                          //     <Box mx={1}>
                          //       Solution: {remark.remark}
                          //     </Box>
                          //     |
                          //     <Box mx={1}>
                          //       PIC: {remark.createdBy?.name}
                          //     </Box>
                          //   </Box>
                          // </ListItem>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Remark;
