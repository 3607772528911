import { Box, Button, Divider, List, ListItem, ListItemIcon, Paper, TextField } from '@material-ui/core'
import { blue, green, orange, red } from '@material-ui/core/colors'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

const WorkOrderActualInput = props => {
  const [workOrdersToView, setWorkOrdersToView] = useState([])
  const [requestStatus, setRequestStatus] = useState('notAsked')
  const [workOrderModifications, setWorkOrderModifications] = useState([])
  const [rejectBuffer, setRejectBuffer] = useState([])

  const query = new URLSearchParams(useLocation().search)
  const dateInfo = query.get('date')

  useEffect(() => {
    (async () => {
      if(requestStatus === 'notAsked') {
        setRequestStatus('loading')
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/workorders/bydate?date=${dateInfo}`)
          const workOrdersJson = await response.json()

          const workOrderWithInputQty = workOrdersJson.map(workOrder => ({...workOrder, inputQty: ''}))
          console.log('work orders json:', workOrderWithInputQty)

          const newRejectBuffer = [...workOrdersJson].map(workOrder => ({
            ...initialRejectWrapper,
            workOrderUuid: workOrder.uuid,
            rejects: workOrder.fepSubProcess.fepSubProcessOutputItem.map(outputItem => ({
              ...initialRejectInfo,
              outputItem: outputItem
            }))
          }))

          console.log('new reject buffer: ', newRejectBuffer)

          setRejectBuffer(newRejectBuffer)
          setWorkOrdersToView(workOrderWithInputQty)
          setRequestStatus('success')
        }
        catch(err) {
          setRequestStatus('failure')
          console.log('fetching work orders error', err)
        }
      }
      
    })()
  })
  
  // Helper functions
  const checkDoneColor = (needed, done) => {
    if(done === 0) return red[400]
    else if(done === needed) return green[400]
    else return orange[700]
  }

  const ViewManpowerCard = props => {
    const manpower = props.data

    if(manpower !== null) {

      if(manpower.individual != null) {
        return (
          <Box my={1}>
            <strong>Worker:</strong>&nbsp;
            {manpower.individual.name}
          </Box>
        )
      }
      else if(manpower.workOrderTeam !== null){
        return (
          <Box my={1}>
            <strong>Team Leader:</strong>&nbsp;{manpower.workOrderTeam.leader.name},&nbsp;
            <strong>Members:</strong>&nbsp;
            {manpower.workOrderTeam.members.map(member => member.name).join(', ')}
          </Box>
        )
      }
    }
    else {
      return ''
    }
  }

  const handleAddWorkOrderActual = (workOrderId, workOrderUuid) => {
    const newWorkOrdersToView = [...workOrdersToView]
    const workOrderIndex = newWorkOrdersToView.findIndex(workOrder => workOrder.id === workOrderId)

    const inputQty = parseInt(newWorkOrdersToView[workOrderIndex].inputQty)
  
    const foundRejectWorkOrder = rejectBuffer.find(buffer => buffer.workOrderUuid === workOrderUuid)
    const filteredRejectWorkOrders = foundRejectWorkOrder
      ? foundRejectWorkOrder.rejects.filter(reject => !isNaN(parseInt(reject.qty)) && parseInt(reject.qty) !== 0)
      : []

    const newWorkOrderActualRejects = filteredRejectWorkOrders.map(reject => {
      return {
        ...initialWorkOrderActualReject,
        uuid: uuidv4(),
        qty: isNaN(parseInt(reject.qty)) ? 0 : parseInt(reject.qty),
        item: reject.outputItem.item
      }
    })

    console.log('found work order:', foundRejectWorkOrder)
    console.log('filtered rejects:', filteredRejectWorkOrders)

    const newWorkOrderActual = {
      ...workOrderActualDefaults,
      // id: workOrderId,
      id: 0,
      programRun: inputQty,
      dateTime: new Date().toISOString(),
      workOrder: {id: workOrdersToView[workOrderIndex].id},
      workOrderActualRejects: newWorkOrderActualRejects
    }

    newWorkOrdersToView[workOrderIndex].workOrderActuals.unshift(newWorkOrderActual)
    newWorkOrdersToView[workOrderIndex].inputQty = ''

    // // Update rejects
    // newWorkOrdersToView[workOrderIndex].workOrderActuals.

    setWorkOrdersToView(newWorkOrdersToView)
    setWorkOrderModifications([...workOrderModifications, {type: 'add', data: newWorkOrderActual}])

    console.log('new work orders:', newWorkOrdersToView)
  }

  const handleInputActualQty = (workOrderId, actualQty) => {
    const newWorkOrdersToView = [...workOrdersToView]
    const workOrderIndex = newWorkOrdersToView.findIndex(workOrder => workOrder.id === workOrderId)
    
    console.log('work order index', workOrderIndex)
    newWorkOrdersToView[workOrderIndex].inputQty = actualQty

    setWorkOrdersToView(newWorkOrdersToView)
    console.log('new work orders:', newWorkOrdersToView)
  }

  const handleChangeRejectBuffer = (workOrderUuid, outputItemUuid, qty) => {
    const newRejectBuffer = [...rejectBuffer]

    const foundRejectBuffer = newRejectBuffer.find(buffer => buffer.workOrderUuid === workOrderUuid)
    const foundRejectInfo = foundRejectBuffer ? foundRejectBuffer.rejects.find(rejectInfo => rejectInfo.outputItem.uuid === outputItemUuid) : null

    if(foundRejectInfo !== null) {
      foundRejectInfo.qty = qty
    }

    console.log('new reject buffer:', newRejectBuffer)

    setRejectBuffer(newRejectBuffer)
  }

  // const handleDeleteActual = (workOrderId, workOrderActualId) => {
  //   const newWorkOrderActual = {
  //     type: 'delete',
  //     data: {
  //       ...workOrderActualDefaults,
  //       id: workOrderActualId,
  //       dateTime: new Date().toISOString()
  //     } 
  //   }
  //   console.log('delete actual', newWorkOrderActual)

  //   const newWorkOrdersToView = [...workOrdersToView]
  //   const workOrderIndex = newWorkOrdersToView.indexOf(newWorkOrdersToView.find(workOrder => workOrder.id === workOrderId))
    
  //   const workOrderActualIndex = 
  //     newWorkOrdersToView[workOrderIndex].workOrderActuals
  //     .indexOf(newWorkOrdersToView[workOrderIndex].workOrderActuals.find(workOrderActual => workOrderActual.id === workOrderActualId))
    
  //   newWorkOrdersToView[workOrderIndex].workOrderActuals.splice(workOrderActualIndex, 1)

  //   setWorkOrdersToView(newWorkOrdersToView)
  //   console.log('new work orders to view:', newWorkOrdersToView)
  //   setWorkOrderModifications([...workOrderModifications, newWorkOrderActual])
  // }

  const handleSave = async () => {
    console.log('modifications', workOrderModifications)
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/workorderactuals`, {
        method: 'PUT',
        headers: {
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(workOrderModifications)
      })
      const responseText = await response.text()
  
      console.log('modification response:', responseText)
      window.location.reload()
    }
    catch(err) {
      console.log('modification error', err)
    }
    
  }

  return (
    <Box>
      <Box>
        <Button variant="contained" color="primary" onClick={() => handleSave()}>Save</Button>
      </Box>
      <h3>Work Order Actual Input for {dateInfo}</h3>
      <List>
        {
          workOrdersToView.map(workOrder => {
            const workOrderProgramRun = workOrder.programRun
            const workOrderQtyDone = workOrder.workOrderActuals.reduce((acc, wo) => acc + wo.programRun, 0)

            // workOrder.machine.processType.name === 'Punching'

            return (
              <Box my={1}>
                <Paper elevation={3}>
                  <ListItem>
                    <Box>
                      <Box>
                          {/* {
                            (() => {
                              const workOrderActualQty = workOrder.fepSubProcess.fepSubProcessOutputItem
                                .map(outputItem => outputItem.qty * workOrder.programRun)

                              return JSON.stringify(workOrderActualQty)
                            })()
                          } */}
                          {
                            workOrderQtyDone < workOrderProgramRun
                            ? <Box display="flex" alignItems="center">
                                <CancelIcon style={{color: orange[600]}} />
                                <h2 style={{color: orange[600]}}>
                                  Incomplete
                                </h2>
                              </Box>
                              
                            : <Box display="flex" alignItems="center">
                                <CheckCircleIcon style={{color: green[600]}} />
                                <h2 style={{color: green[600]}}>
                                  Complete
                                </h2>
                              </Box>
                            
                              
                          }
                      </Box>
                      <Box display="flex" alignItems="center">
                        <strong>
                          { workOrder.machine === null ? '' : workOrder.machine.name }:&nbsp;
                          {
                            (() => {
                              const machineProcessType = workOrder.machine === null ? '' : workOrder.machine.processType.name

                              if(machineProcessType === 'Punching') {
                                return (
                                  < >
                                    <span>({workOrder.job.name})</span>&nbsp;
                                    <span style={{color: blue[900]}}>{workOrder.fepSubProcess === null ? '' : workOrder.fepSubProcess.programName}</span>
                                    &nbsp;
                                    ( 
                                      {/* <span>{workOrder.fepSubProcess === null ? '' : workOrder.fepSubProcess.programRun * workOrder.job.qty}</span> needed, &nbsp; */}
                                      <span>{workOrder.fepSubProcess === null ? '' : workOrder.fepSubProcess.programRun}</span> needed, &nbsp;
                                      <span style={{color: green[500]}}>{workOrderProgramRun}</span> assigned, &nbsp;
                                      <span style={{color: checkDoneColor(workOrderProgramRun, workOrderQtyDone)}}>
                                        {workOrderQtyDone}
                                      </span> done
                                    )
                                  </>
                                )  
                              }
                              else {
                                return (
                                  < >
                                    <span>({workOrder.job.name})</span>&nbsp;
                                    <span style={{color: blue[900]}}>{workOrder.fepSubProcess === null ? '' : workOrder.fepSubProcess.fepSubProcessOutputItem[0].item.partName}</span>
                                    &nbsp;
                                    ( 
                                      {/* <span>{workOrder.fepSubProcess === null ? '' : workOrder.fepSubProcess.programRun * workOrder.job.qty}</span> needed, &nbsp; */}
                                      <span>{workOrder.fepSubProcess.fepSubProcessOutputItem.length > 0 ? workOrder.fepSubProcess.fepSubProcessOutputItem[0].qty * workOrder.job.qty : 0}</span> needed, &nbsp;
                                      <span style={{color: green[500]}}>{workOrderProgramRun}</span> assigned, &nbsp;
                                      <span style={{color: checkDoneColor(workOrderProgramRun, workOrderQtyDone)}}>
                                        {workOrderQtyDone}
                                      </span> done
                                    )
                                  </>
                                )  
                              }
                            })()
                          }
                        </strong>
                        <Box display="flex" ml={2}>
                        </Box>
                      </Box>
                      <Box>
                        <ViewManpowerCard data={workOrder.manpower} />
                      </Box>
                      <Box>
                        <Box display="flex" alignItems="center">
                          Items produced:&nbsp;
                          <Box width={50} ml={2}>
                            <TextField 
                              placeholder="qty" 
                              value={workOrdersToView.find(wo => wo.id === workOrder.id).inputQty}
                              onInput={(evt) => handleInputActualQty(workOrder.id, evt.target.value)}
                              fullWidth 
                            />
                          </Box>
                        </Box>
                        <List>
                          {
                            workOrder.fepSubProcess.fepSubProcessOutputItem.map(outputItem => (
                              <ListItem>
                                <Box display="flex" alignItems="center">
                                  <Box>
                                    [ {(() => {
                                      const filteredRejects = workOrder.workOrderActuals
                                        .map(woActual => woActual.workOrderActualRejects)
                                        .flat()
                                        .filter(reject => reject.item.partName === outputItem.item.partName)
  
                                      const totalRejectedQty = filteredRejects.reduce((acc, reject) => acc + reject.qty, 0)

                                      console.log('total rejected qty for', outputItem.item.partName, totalRejectedQty)
                                        
                                      return workOrder.machine.processType.name === 'Punching'
                                        ? workOrder.workOrderActuals.reduce((acc, wo) => acc + wo.programRun, 0) * outputItem.qty - totalRejectedQty
                                        : workOrder.workOrderActuals.reduce((acc, wo) => acc + wo.programRun, 0) - totalRejectedQty
                                    })()}
                                    /
                                    {outputItem.qty * workOrder.programRun} ]
                                    &nbsp;
                                    <strong>
                                      {outputItem.item ? outputItem.item.partName : ''}
                                    </strong>&nbsp; 
                                  </Box>

                                  <Box mx={2}>
                                    +
                                  </Box>

                                  <Box display="flex" alignItems="center">
                                    [ {(() => {
                                        const outputItemTotal = workOrder.machine.processType.name === 'Punching'
                                          ? workOrder.inputQty !== '' ? workOrder.inputQty * outputItem.qty : 0
                                          : workOrder.inputQty !== '' ? workOrder.inputQty * outputItem.qty : 0

                                        const foundRejectBuffer = rejectBuffer.find(buffer => buffer.workOrderUuid === workOrder.uuid)
                                        const foundOutputItem = foundRejectBuffer ? foundRejectBuffer.rejects.find(reject => reject.outputItem.uuid === outputItem.uuid) : null
                                        const rejectQty = foundOutputItem ? foundOutputItem.qty : ''

                                        const parsedRejectQty = isNaN(parseInt(rejectQty))
                                          ? 0
                                          : parseInt(rejectQty)

                                        return outputItemTotal - parsedRejectQty
                                      })()
                                      
                                    } ]&nbsp;
                                    {/* <strong>
                                      {outputItem.item ? outputItem.item.partName : ''}
                                    </strong>&nbsp;  */}
                                    <Box mx={3} style={{width: 75}}>
                                      <TextField
                                        // variant="outlined"
                                        label="reject" 
                                        size="small"
                                        value={(() => {
                                          const foundRejectBuffer = rejectBuffer.find(buffer => buffer.workOrderUuid === workOrder.uuid)
                                          const foundRejectInfo = foundRejectBuffer ? foundRejectBuffer.rejects.find(reject => reject.outputItem.uuid === outputItem.uuid) : null
                                          const rejectBufferQty = foundRejectInfo ? foundRejectInfo.qty : 0

                                          return rejectBufferQty
                                        })()}
                                        onChange={(evt) => handleChangeRejectBuffer(workOrder.uuid, outputItem.uuid, evt.target.value)}
                                      />
                                    </Box>
                                  </Box>
                                  
                                </Box>
                                
                              </ListItem>
                            ))
                          }
                        </List>
                      </Box>
                      <Box>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleAddWorkOrderActual(workOrder.id, workOrder.uuid)}
                        >
                          Add
                        </Button>
                      </Box> 
                      <Divider />
                      <Box my={1}>
                        History:
                      </Box>
                      <div>
                        <List>
                          {
                            workOrder.workOrderActuals.length === 0
                            ? < ></>
                            : workOrder.workOrderActuals.map((woActual, index) => {
                                return (
                                  // <ListItem onClick={() => handleDeleteActual(workOrder.id, woActual.id)}>
                                  <ListItem key={`WorkOrderInput_${index}`}>
                                    <ListItemIcon>
                                      <DeleteIcon />
                                    </ListItemIcon>
                                    <Box>
                                      <strong>x{ woActual.programRun }</strong>&nbsp;({ woActual.dateTime.split('T').join(' ') })
                                      {
                                        woActual.workOrderActualRejects.length === 0
                                        ? <></>
                                        : <Box>
                                            Rejects:
                                            <List>
                                              {woActual.workOrderActualRejects.map(reject => (
                                                <ListItem>
                                                  [ {reject.qty} ]&nbsp;<strong>{reject.item ? reject.item.partName : ''}</strong>
                                                </ListItem>
                                              ))}
                                            </List>
                                          </Box>
                                      }
                                    </Box>
                                    
                                  </ListItem>
                                )
                              })
                          }
                          
                        </List>
                      </div>
                    </Box>
                  </ListItem>
                </Paper>
              </Box>
                
            )
          })
        }
      </List>
      
    </Box>
  )
}

export default WorkOrderActualInput

// const workOrderDefaults = {
//   "id": 0,
//   "name": "",
//   "programRun": 0,
//   "startTime": 50000,
//   "endTime": 60000,
//   "date": "2020-02-15",
//   "machine": null,
//   "fepSubProcess": null,
//   "workOrderActuals": []
// }

const workOrderActualDefaults = {
  id: 0,
  programRun: 0,
  dateTime: '2020-01-21T00:00:00',
  workOrder: null,
  remark: ''
}

const initialRejectWrapper = {
  workOrderUuid: '',
  rejects: [] // initialRejectInfo
}

const initialRejectInfo = {
  outputItem: null,
  qty: ''
}

const initialWorkOrderActualReject = {
  id: 0,
  uuid: '',
  qty: 0,
  item: null
}