import React, { useContext } from "react";
import { AppContext } from "../../../App";

const NcrPage = () => {
  const appState = useContext(AppContext);
  const userId = appState?.gspeAppUser?.id;

  return (
    <div style={{ height: "85vh", width: "100%", boxSizing: "border-box" }}>
      <iframe
        src={`${process.env.REACT_APP_QAQC_FRONTEND_URL}/#/ncr?meetingAppUserId=${userId ?? 0}`}
        style={{ border: "2px solid #ccc", borderRadius: "8px", height: "100%", width: "100%" }}
        title="NCR Page"
      />
    </div>
  );
};

export default NcrPage;