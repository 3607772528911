import { LatLngTuple } from "leaflet";
import React, { useContext, useEffect } from "react";
import {
  Circle,
  Marker,
  Polyline,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import { AppContext } from "../../App";
import { haversine, OSRMObject, padNumber } from "../../helpers";
import { MeetingTaskListsView } from "../../masterbigsystem";
import { LocationRecord } from "../../models/meeting";
import { ExtUser } from "../../models/model";
import { DeptWoFilterType } from "./ProductMonitoringPage";

const ProductMonitoringMap = (props: {
  osrmData: OSRMObject | null | undefined;
  osrmDataActual: OSRMObject | null | undefined;
  woJobs: (MeetingTaskListsView | null)[];
  index: number;
  locationRecords: LocationRecord[];
  showAllLocationUsers: boolean;
  users: ExtUser[] | null | undefined;
  deptWoFilter: DeptWoFilterType;
}) => {
  const ctx = useContext(AppContext);
  const map = useMap();

  const userHomes = [
    ...new Set(
      props.woJobs
        .flatMap((tL) => tL?.taskLists)
        .flatMap((tL) => tL?.taskList?.meetingTasks)
        .flatMap((mt) => mt?.meetingTaskInCharges)
        .map((c) => c?.extUserId as string)
        .filter((uid) => uid)
    ),
  ]
    .map((uid) => ctx?.extUsers.find((u) => `${u.id}` === `${uid}`) as ExtUser)
    .filter((u) => u);

  useEffect(() => {
    const osrmDataFlat = props.woJobs
      .map((tL) => tL?.taskLists)
      .flat()
      .map((tL) => tL?.taskList?.meetingTasks)
      .flat()
      .map((mt) => mt?.journeys)
      .flat()
      .filter((j) => j?.lat && j.lon)
      .map((l) => [l?.lat ?? 0, l?.lon ?? 0]);
    // props.osrmData?.routes
    //   ?.map((r) => r.legs)
    //   .flat()
    //   .map((l) => l?.steps)
    //   .flat()
    //   .map((s) => s?.maneuver?.location)
    //   .map((l) => [l?.[1] ?? 0.0, l?.[0] ?? 0.0]) ?? [];

    const osrmDataActualFlat = props.locationRecords
      .filter((l) =>
        [
          ...new Set(
            props.woJobs
              .map((tL) => tL?.taskLists)
              .flat()
              .map((tL) => tL?.taskList?.meetingTasks)
              .flat()
              .map((mt) => mt?.meetingTaskInCharges)
              .flat()
              .map((c) => c?.extUserId)
          ),
        ].find((id) => `${id}` === `${l.extUserId}`)
      )
      .map((l) => [l.lat ?? 0, l.lon ?? 0]);

    const userHomesLatLon = [
      ...userHomes
        .filter((u) => u.lat && u.lon)
        .map((u) => [u.lat ?? 0, u.lon ?? 0]),
    ];

    const bounds = [...osrmDataFlat, ...osrmDataActualFlat, ...userHomesLatLon];

    console.log("bounds:", bounds);

    if (bounds.length > 0) {
      map.fitBounds(bounds as LatLngTuple[]);
    }
  }, [props.osrmData, props.osrmDataActual, props.locationRecords]);

  useEffect(() => {
    console.log("location records:", props.locationRecords);
  }, [props.locationRecords]);

  return (
    <>
      {" "}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={process.env.REACT_APP_OSM_TILE_URL ?? ""}
      />
      {/* Marker for user homes */}
      {userHomes
        .filter((u) => u.lat && u.lon)
        .map((u) => {
          return (
            <>
              <Marker position={[u.lat ?? 0, u.lon ?? 0]}>
                <Tooltip
                  permanent
                  // opacity={1}
                >
                  <div>
                    <div>
                      <div className="font-weight-bold">
                        <strong>{u.username}'s home</strong>
                      </div>
                    </div>
                  </div>
                </Tooltip>
              </Marker>
            </>
          );
        })}
      {/* Marker for journeys */}
      {props.woJobs?.[props.index]?.taskLists.map((tL, i) => {
        return (
          <>
            {tL.taskList?.meetingTasks
              .filter((mt) => mt.journeys.length > 0)
              .map((mt, j) => {
                return (
                  <>
                    {mt.journeys
                      .filter((j) => j.lat && j.lon)
                      .map((j, k) => {
                        console.log(j);
                        const locationInGeofence = props.locationRecords.find(
                          (r) =>
                            r.lat &&
                            r.lon &&
                            j.lat &&
                            j.lon &&
                            haversine(r.lat, r.lon, j.lat, j.lon) <= 300
                        );

                        return (
                          <>
                            <Marker position={[j.lat ?? 0, j.lon ?? 0]}>
                              <Tooltip
                                permanent
                                // opacity={1}
                              >
                                <div>
                                  <div>
                                    <div className="font-weight-bold">
                                      <strong>{j.siteName}</strong>
                                    </div>
                                  </div>
                                </div>
                              </Tooltip>
                            </Marker>
                            {/* Circle for plan */}
                            <Circle
                              center={[j.lat ?? 0, j.lon ?? 0]}
                              fillOpacity={0.5}
                              fill
                              pathOptions={{
                                color: locationInGeofence ? `green` : `red`,
                                fillColor: locationInGeofence ? `green` : `red`,
                              }}
                              radius={300}
                            />
                          </>
                        );
                      })}
                  </>
                );
              })}
          </>
        );
      })}
      {/* Polyline for planned journeys */}
      <Polyline
        pathOptions={{
          color: "red",
          weight: 5,
        }}
        positions={
          props.osrmData?.routes
            ?.map((r) => r.legs)
            .flat()
            .map((l) => l?.steps)
            .flat()
            .map((s) => s?.maneuver)
            .filter((m) => m?.location && m.location?.[0] && m.location?.[1])
            .map(
              (m) =>
                ({
                  lat: m?.location?.[1] ?? 0,
                  lng: m?.location?.[0] ?? 0,
                } as {
                  lat: number;
                  lng: number;
                })
            ) ?? []
        }
      />
      {/* Polyline for actual line */}
      {(props.showAllLocationUsers
        ? props.users
            ?.filter(
              (u) =>
                u.id &&
                (props.deptWoFilter.extUserId
                  ? `${props.deptWoFilter.extUserId}` === `${u.id}`
                  : props.deptWoFilter.deptId
                  ? `${props.deptWoFilter.deptId}` === `${u.departmentId}`
                  : false)
            )
            .map((u) => (u.id ? `${u.id}` : ``)) ?? []
        : [
            ...new Set(
              props.woJobs
                .map((tL) => tL?.taskLists)
                .flat()
                .map((tL) => tL?.taskList?.meetingTasks)
                .flat()
                .map((mt) => mt?.meetingTaskInCharges)
                .flat()
                .map((c) => c?.extUserId)
            ),
          ]
      ).map((id) => {
        const fUser = ctx?.extUsers.find((u) => `${u.id}` === `${id}`);

        return (
          <>
            <Polyline
              pathOptions={{
                color: "green",
                weight: 5,
              }}
              positions={props.locationRecords
                .filter(
                  (r) => r.lat && r.lon && id && `${r.extUserId}` === `${id}`
                )
                .map((r) => ({ lat: r.lat ?? 0, lng: r.lon ?? 0 }))}
            />
            {/* Location tooltip */}
            {props.locationRecords
              .filter(
                (r) => r.lat && r.lon && id && `${r.extUserId}` === `${id}`
              )
              .map((r) => {
                const taskListPopupVisible = props.woJobs
                  .flatMap((tL) => tL?.taskLists)
                  .flatMap((tL) => tL?.taskList)
                  .filter((tL) =>
                    tL?.meetingTasks.find(
                      (mt) =>
                        mt.meetingTaskInCharges.find(
                          (mtic) => `${mtic.extUserId}` === `${fUser?.id}`
                        ) && mt.journeys.length > 0
                    )
                  )
                  .flatMap((tL) => tL?.meetingTasks)
                  .flatMap((mt) => mt?.journeys)
                  .find(
                    (j) =>
                      r.lat &&
                      r.lon &&
                      j?.lat &&
                      j?.lon &&
                      haversine(r.lat, r.lon, j.lat, j.lon) <= 300
                  );
                // const locationInGeofence = props.locationRecords.find(
                //   (r) =>
                //     r.lat &&
                //     r.lon &&
                //     j.lat &&
                //     j.lon &&
                //     haversine(r.lat, r.lon, j.lat, j.lon) <= 300
                // );

                // console.log(r.lat, r.lon);
                return r.createdAt ? (
                  <>
                    <Circle
                      radius={10}
                      center={{ lat: r.lat ?? 0, lng: r.lon ?? 0 }}
                    >
                      <Tooltip
                        permanent
                        // opacity={1}
                      >
                        <div>
                          <div>
                            <div className="font-weight-bold bg-warning">
                              <strong>
                                [{fUser?.username}]{" "}
                                {(() => {
                                  try {
                                    return `${new Date(
                                      r.createdAt
                                    ).getHours()}:${new Date(
                                      r.createdAt
                                    ).getMinutes()}`;
                                  } catch (e) {
                                    return "";
                                  }
                                })()}
                              </strong>
                            </div>
                          </div>

                          {taskListPopupVisible ? (
                            <>
                              <div>
                                <ol>
                                  {props.woJobs.flatMap((tL) =>
                                    tL?.taskLists
                                      .filter((tL) =>
                                        tL.taskList?.meetingTasks
                                          .filter(
                                            (mt) => mt.journeys.length > 0
                                          )
                                          .flatMap(
                                            (mt) => mt.meetingTaskInCharges
                                          )
                                          .find(
                                            (mtic) =>
                                              `${mtic.extUserId}` ===
                                              `${fUser?.id}`
                                          )
                                      )
                                      .map((tL) =>
                                        tL.taskList?.meetingTasks.map((mt) => ({
                                          meetingTask: mt,
                                          taskList: tL,
                                        }))
                                      )
                                      .flat()
                                      .map((d) => (
                                        <>
                                          <li>
                                            <strong>
                                              <div
                                                style={{
                                                  color:
                                                    d?.meetingTask.status ===
                                                    "COMPLETED"
                                                      ? `green`
                                                      : d?.meetingTask
                                                          .extPicCompletedDate
                                                      ? `darkorange`
                                                      : `red`,
                                                }}
                                              >
                                                {d?.meetingTask.description}
                                              </div>
                                            </strong>
                                          </li>
                                        </>
                                      ))
                                  )}
                                </ol>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Tooltip>
                    </Circle>
                  </>
                ) : (
                  <></>
                );
              })}
          </>
        );
      })}
    </>
  );
};

export default ProductMonitoringMap;
