import React, { useContext, useEffect, useState } from "react";
import { Switch, useHistory, useParams } from "react-router";
import {
  BomDocument,
  BomLeveledRecursive,
  ExtUser,
  FepDocument,
  FepLeveled,
  Job,
  JobMapped,
  JobPanelType,
  JobSummaryView,
  JobType,
  JobView,
  PanelCode,
  Uom,
  WiringProcessType,
} from "../../../models/model";
import { v4 as uuidv4 } from "uuid";
import {
  defaultExtPurchaseOrder,
  defaultFepLeveledView,
  defaultJobBomLeveled,
  defaultJobBomLeveledView,
  defaultJobFep,
  defaultJobFepLeveled,
  defaultJobFepLeveledView,
  defaultJobMapped,
  defaultJobSummaryExtPurchaseOrder,
  defaultJobSummaryExtPurchaseOrderView,
  defaultJobSummaryView,
  defaultJobView,
  initialBomDocument,
  initialFepDocument,
  initialJob,
  initialJobPanelType,
  initialPanelCode,
} from "../../../models/modelinitials";
import {
  Button,
  Checkbox,
  createMuiTheme,
  Divider,
  FormControl,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import {
  blue,
  blueGrey,
  green,
  lightGreen,
  orange,
  red,
} from "@material-ui/core/colors";
import { RequestStatus } from "../../../models/RequestStatus";
import {
  CheckRequestStatus,
  ExtTsCrmPurchaseOrder,
  fetchBomLeveleds,
  fetchExtTsCrmPurchaseOrders,
  fetchFepDocumentsSimple,
  fetchFepLeveleds,
  fetchJob,
  fetchJobTypes,
  fetchWiringProcessTypes,
  getUserFromApiKey,
  makeDateString,
  TableCellBordered,
} from "../../../helpers";
import { AppContext } from "../../../App";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import SyncAutocomplete from "../../mini-components/CrudTable/components/SyncAutocomplete";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Form } from "react-bootstrap";
import AsyncSelectWrapper from "../../mini-components/AsyncSelectWrapper/AsyncSelectWrapper";
import AsyncAutocomplete from "../../mini-components/CrudTable/components/AsyncAutocomplete";
import { ChevronLeftIcon } from "../../mini-components/Icons";
import { Link } from "react-router-dom";
import { MeetingRoom, Power } from "@material-ui/icons";
import { min } from "date-fns";
import { Async } from "react-async";

const JobSummaryDetail = () => {
  const history = useHistory();

  const ctx = useContext(AppContext);
  const { id } = useParams() as { id: string };
  const [jobSummary, setJobSummary] = useState<JobSummaryView>({
    ...defaultJobSummaryView,
  });

  const [loading, setLoading] = useState(false);

  const [fepDocuments, setFepDocuments] = useState<FepDocument[]>([]);
  const [multiFep, setMultiFep] = useState(true);
  const [multiBom, setMultiBom] = useState(true);
  const [jobFepDeleteIds, setjobFepDeleteIds] = useState<number[]>([]);
  const [fepLeveleds, setFepLeveleds] = useState<FepLeveled[]>([]);
  const [bomLeveleds, setBomLeveleds] = useState<BomLeveledRecursive[]>([]);
  const [jobExpandIndexes, setJobExpandIndexes] = useState<number[]>([]);
  const [purchaseOrders, setPurchaseOrders] = useState<ExtTsCrmPurchaseOrder[]>(
    []
  );

  enum JobTypeSelection {
    Cabinet = "Cabinet",
    Wiring = "Wiring",
  }

  enum JobPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
  }

  const greyTheme = createMuiTheme({
    palette: { primary: { main: blueGrey[500] } },
  });
  const greenTheme = createMuiTheme({
    palette: { primary: { main: lightGreen[800] } },
  });
  const yellowTheme = createMuiTheme({
    palette: { primary: { main: orange[500] } },
  });
  const redTheme = createMuiTheme({ palette: { primary: { main: red[500] } } });

  const [state, setState] = useState({
    job: {
      job: { ...initialJob } as Job,
      fepDocuments: [],
      jobBomLeveleds: [],
      jobFepLeveleds: [],
      jobBomLeveledsMapped: [],
      jobMaterialRequests: [],
      jobPurchaseRequests: [],
      jobIntegrationList: []
    } as JobMapped,
    jobTypes: [] as Array<JobType>,
    uoms: [] as Array<Uom>,
    requestStatus: RequestStatus.NotAsked,
    jobTypeSelections: [JobTypeSelection.Cabinet],
    jobPriority: JobPriority.Low,
    panelTypeQty: 0,
    selectedPanelType: { ...initialJobPanelType } as JobPanelType,
    jobPanelType: { ...initialJobPanelType } as JobPanelType,
    panelCode: { ...initialPanelCode } as PanelCode,
    panelCodeDeleteIds: [] as Array<number>,
    wiringProcessTypes: [] as Array<WiringProcessType>,
  });

  useEffect(() => {
    handleInit();

    (async () => {
      setState({ ...state, requestStatus: RequestStatus.Loading });

      const parsedId = id ? parseInt(id) : NaN;

      const fetchedJob = await (async () => {
        if (
          !isNaN(parsedId) &&
          state.requestStatus === RequestStatus.NotAsked
        ) {
          return await fetchJob({ id: parsedId, apiKey: ctx?.apiKey ?? "" });
        } else {
          return null;
        }
      })();

      const [
        jobTypes,
        wiringProcessTypes,
        fepDocumentsData,
        fepLeveledsData,
        bomLeveledsData,
        extTsCrmPurchaseOrdersData,
      ] = await Promise.all([
        fetchJobTypes({ apiKey: ctx?.apiKey ?? "" }),
        fetchWiringProcessTypes({ apiKey: ctx?.apiKey ?? "" }),
        fetchFepDocumentsSimple({
          baseUrl: process.env.REACT_APP_BASE_URL ?? "",
          apiKey: ctx?.apiKey ?? "" ?? "",
        }),
        fetchFepLeveleds({ apiKey: ctx?.apiKey ?? "" }),
        fetchBomLeveleds({ apiKey: ctx?.apiKey ?? "" }),
        fetchExtTsCrmPurchaseOrders(),
      ]);

      setPurchaseOrders(extTsCrmPurchaseOrdersData);
      setFepDocuments(fepDocumentsData);

      setFepLeveleds(fepLeveledsData);
      setBomLeveleds(bomLeveledsData);

      setState({
        ...state,
        job: fetchedJob ? fetchedJob.job : state.job,
        jobPriority: fetchedJob ? fetchedJob.jobPriority : state.jobPriority,
        jobTypeSelections: fetchedJob?.jobTypeSelections
          ? fetchedJob.jobTypeSelections
          : state.jobTypeSelections,
        jobTypes: jobTypes ? jobTypes : state.jobTypes,
        wiringProcessTypes: wiringProcessTypes
          ? wiringProcessTypes
          : state.wiringProcessTypes,
        requestStatus: RequestStatus.Success,
      });

      console.log("Job types state: ", wiringProcessTypes, jobTypes);
    })();
  }, []);

  const handleInit = async () => {
    try {
      if (!isNaN(parseInt(id))) {
        const resp = await fetch(
          `${process.env.REACT_APP_BASE_URL}/jobsummaries-view/${id}?compose=true`
        );

        if (resp.status !== 200) throw await resp.text();

        const jobSummaryData = await resp.json();
        setJobSummary(jobSummaryData);
      }
    } catch (e) {
      console.log("[init error]", e);
    }
  };

  const JobPriorityView = (props: {
    priorityState: JobPriority;
    priority: JobPriority;
  }) => {
    const JobPriorityButton = () => (
      <Button
        onClick={() => setState({ ...state, jobPriority: props.priority })}
        color="primary"
        variant={
          props.priorityState === props.priority ? "contained" : "outlined"
        }
      >
        {props.priority}
      </Button>
    );

    const priorityButtonColor = (() => {
      switch (props.priority) {
        case JobPriority.Low:
          return greenTheme;

        case JobPriority.Medium:
          return yellowTheme;

        case JobPriority.High:
          return redTheme;
      }
    })();

    return (
      <ThemeProvider theme={priorityButtonColor}>
        <JobPriorityButton />
      </ThemeProvider>
    );
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobsummaries-save`,
        {
          method: "post",
          headers: {
            authorization: ctx?.apiKey ?? "",
            "content-type": "application/json",
          },
          body: JSON.stringify(jobSummary),
        }
      );
      history.push("/jobsummary");
    } catch (e) {
      console.log("[error saving]", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h5>Job Summary </h5>
          {loading ? (
            <></>
          ) : (
            <div>
              <button className="btn btn-sm btn-primary" onClick={handleSave}>
                Save
              </button>
            </div>
          )}
        </div>
        <div className="my-2">
          <hr className="border border-dark" />
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <label className="form-label">Job name</label>
            <input
              defaultValue={jobSummary.jobSummary?.name ?? ""}
              onBlur={(e) => {
                setJobSummary({
                  ...jobSummary,
                  jobSummary: jobSummary.jobSummary
                    ? { ...jobSummary.jobSummary, name: e.target.value }
                    : jobSummary.jobSummary,
                });
              }}
              className="form-control form-control-sm shadow-sm"
              placeholder="Job summary name..."
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-1">
            <label className="form-label">
              Date issued (
              {jobSummary?.jobSummary?.dateIssued
                ? Intl.DateTimeFormat(navigator.language ?? "en-US", {
                    dateStyle: "medium",
                    hourCycle: "h23",
                    timeZone: "utc",
                  } as any).format(
                    new Date(jobSummary?.jobSummary?.dateIssued ?? "")
                  )
                : "None"}
              )
            </label>
            <input
              type="date"
              defaultValue={
                jobSummary.jobSummary?.dateIssued
                  ? jobSummary.jobSummary.dateIssued.split("T")[0]
                  : undefined
              }
              onBlur={(e) => {
                if (e.target.value && e.target.value !== "") {
                  setJobSummary({
                    ...jobSummary,
                    jobSummary: jobSummary.jobSummary
                      ? {
                          ...jobSummary.jobSummary,
                          dateIssued: `${e.target.value}T00:00:00Z`,
                        }
                      : jobSummary.jobSummary,
                  });
                }
              }}
              className="form-control form-control-sm shadow-sm"
              placeholder="Date issued..."
            />
          </div>
          <div className="flex-grow-1">
            <label className="form-label">
              Deadline (
              {jobSummary?.jobSummary?.deadline
                ? Intl.DateTimeFormat(navigator.language ?? "en-US", {
                    dateStyle: "medium",
                    hourCycle: "h23",
                    timeZone: "utc",
                  } as any).format(
                    new Date(jobSummary?.jobSummary?.deadline ?? "")
                  )
                : "None"}
              )
            </label>
            <input
              type="date"
              defaultValue={
                jobSummary.jobSummary?.deadline
                  ? jobSummary.jobSummary.deadline.split("T")[0]
                  : undefined
              }
              onBlur={(e) => {
                if (e.target.value && e.target.value !== "") {
                  setJobSummary({
                    ...jobSummary,
                    jobSummary: jobSummary.jobSummary
                      ? {
                          ...jobSummary.jobSummary,
                          deadline: `${e.target.value}T00:00:00Z`,
                        }
                      : jobSummary.jobSummary,
                  });
                }
              }}
              className="form-control form-control-sm shadow-sm"
              placeholder="Deadline..."
            />
          </div>
          <div className="flex-grow-1">
            <label className="form-label">
              Expected Days ({jobSummary.jobSummary?.expectedDays})
            </label>
            <input
              className="form-control form-control-sm shadow-sm"
              placeholder="Expected days..."
              defaultValue={jobSummary.jobSummary?.expectedDays ?? 0}
              onBlur={(e) => {
                if (e.target.value && e.target.value !== "") {
                  setJobSummary({
                    ...jobSummary,
                    jobSummary: jobSummary.jobSummary
                      ? {
                          ...jobSummary.jobSummary,
                          expectedDays: isNaN(parseInt(e.target.value))
                            ? jobSummary.jobSummary.expectedDays
                            : parseInt(e.target.value),
                        }
                      : jobSummary.jobSummary,
                  });
                }
              }}
            />
          </div>
          {/* <div className="flex-grow-1">
            <label className="form-label">
              PO Number ({jobSummary.jobSummary?.poNumber})
            </label>
            <input
              className="form-control form-control-sm shadow-sm"
              placeholder="PO Number..."
              onBlur={(e) => {
                if (e.target.value && e.target.value !== "") {
                  setJobSummary({
                    ...jobSummary,
                    jobSummary: jobSummary.jobSummary
                      ? {
                          ...jobSummary.jobSummary,
                          poNumber: e.target.value,
                        }
                      : jobSummary.jobSummary,
                  });
                }
              }}
            />
          </div> */}
        </div>
        <div className="my-2">
          <div className="d-flex align-items-center">
            <div>
              <strong>Select PO:</strong>
            </div>
            <div className="flex-grow-1">
              <Autocomplete
                options={purchaseOrders}
                getOptionLabel={(p) =>
                  `${p.purchaseOrderNumber} (${p.account?.name})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Select purchase order..."
                  />
                )}
                onChange={(_, p) => {
                  if (
                    jobSummary.jobSummaryExtPurchaseOrders?.find(
                      (px) =>
                        p?.id ===
                        px.jobSummaryExtPurchaseOrder?.extPurchaseOrder
                          ?.extPurchaseOrderId
                    )
                  ) {
                    alert("PO already chosen");
                    return;
                  }

                  if (p) {
                    setJobSummary({
                      ...jobSummary,
                      jobSummaryExtPurchaseOrders:
                        jobSummary.jobSummaryExtPurchaseOrders
                          ? [
                              ...jobSummary.jobSummaryExtPurchaseOrders,
                              {
                                ...defaultJobSummaryExtPurchaseOrderView,
                                jobSummaryExtPurchaseOrder: {
                                  ...defaultJobSummaryExtPurchaseOrder,
                                  extPurchaseOrder: {
                                    ...defaultExtPurchaseOrder,
                                    extPurchaseOrderId: p?.id ?? null,
                                  },
                                },
                              },
                            ]
                          : jobSummary.jobSummaryExtPurchaseOrders,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <ol>
            {jobSummary.jobSummaryExtPurchaseOrders?.map((p) => {
              const foundPurchaseOrder = purchaseOrders.find(
                (px) =>
                  px.id ===
                  p.jobSummaryExtPurchaseOrder?.extPurchaseOrder
                    ?.extPurchaseOrderId
              );

              return (
                <li>
                  <div>
                    PO: {foundPurchaseOrder?.purchaseOrderNumber} (
                    {foundPurchaseOrder?.account?.name})
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
        <div className="my-2">
          <hr className="border border-secondary" />
        </div>
        <div className="d-flex align-items-center">
          <h5>Job Detail</h5>
          <div className="mx-2">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                setJobSummary({
                  ...jobSummary,
                  jobsMapped: jobSummary.jobsMapped
                    ? [
                        ...jobSummary.jobsMapped,
                        {
                          ...defaultJobMapped,
                          job: { ...initialJob, uuid: uuidv4() },
                        },
                      ]
                    : jobSummary.jobsMapped,
                });
              }}
            >
              Add
            </button>
          </div>
        </div>
        <div>
          {/* {JSON.stringify(jobExpandIndexes)} */}
          <ol>
            {jobSummary.jobsMapped?.map((j, i) => {
              return (
                <>
                  <li>
                    <div className="my-2">
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            setJobExpandIndexes(
                              jobExpandIndexes.find((ix) => ix === i) !==
                                undefined
                                ? jobExpandIndexes.filter((ix) => ix !== i)
                                : [...jobExpandIndexes, i]
                            );
                          }}
                        >
                          Expand/Collapse
                        </button>
                        <div className="mx-2">
                          Job: {j.job?.name},{" "}
                          <span
                            className={`${
                              (j.jobBomLeveledsMapped?.length ?? 0) > 0
                                ? "text-success font-weight-bold"
                                : "text-danger font-weight-bold"
                            }`}
                          >
                            {j.jobBomLeveledsMapped?.length} BOM(s)
                          </span>
                          {", "}
                          <span
                            className={`${
                              (j.jobFepLeveleds?.length ?? 0) > 0
                                ? "text-success font-weight-bold"
                                : "text-danger font-weight-bold"
                            }`}
                          >
                            {j.jobFepLeveleds?.length} FEP(s)
                          </span>{" "}
                        </div>
                      </div>
                      {jobExpandIndexes.find((ix) => ix === i) !== undefined ? (
                        <>
                          <div>
                            <ThemeProvider theme={createMuiTheme()}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mx-1">
                                  <div className="d-flex align-items-center my-2">
                                    <div
                                      className="d-flex flex-grow-1 mx-1 my-2"
                                      style={{ width: "500px" }}
                                    >
                                      <TextField
                                        variant="outlined"
                                        size="small"
                                        label="Job Name"
                                        defaultValue={j.job?.name}
                                        onBlur={(e) => {
                                          setJobSummary({
                                            ...jobSummary,
                                            jobsMapped: jobSummary.jobsMapped
                                              ? jobSummary.jobsMapped.map(
                                                  (jx, ix) =>
                                                    ix === i
                                                      ? jx
                                                        ? {
                                                            ...jx,
                                                            job: jx.job
                                                              ? {
                                                                  ...jx.job,
                                                                  name: e.target
                                                                    .value,
                                                                }
                                                              : jx.job,
                                                          }
                                                        : jx
                                                      : jx
                                                )
                                              : jobSummary.jobsMapped,
                                          });
                                          // handleChangeOutstandingJobName(
                                          //   event.target.value
                                          // )
                                        }}
                                        fullWidth
                                      />
                                    </div>
                                    <div className="flex-grow-1 mx-1">
                                      <TextField
                                        type="date"
                                        defaultValue={j.job?.jobDate}
                                        onBlur={(e) => {
                                          if (
                                            e.target.value &&
                                            e.target.value !== ""
                                          ) {
                                            setJobSummary({
                                              ...jobSummary,
                                              jobsMapped: jobSummary.jobsMapped
                                                ? jobSummary.jobsMapped.map(
                                                    (jx, ix) =>
                                                      jx.job?.uuid ===
                                                      j.job?.uuid
                                                        ? {
                                                            ...jx,
                                                            job: jx.job
                                                              ? {
                                                                  ...jx.job,
                                                                  jobDate:
                                                                    e.target
                                                                      .value,
                                                                }
                                                              : jx.job,
                                                          }
                                                        : jx
                                                  )
                                                : jobSummary.jobsMapped,
                                            });
                                          }
                                        }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        label="Job Date"
                                      />
                                    </div>
                                  
                                    <div className="flex-grow-1">
                                      <Autocomplete
                                        getOptionLabel={(u) =>
                                          `${u.name} (${u.departmentName})`
                                        }
                                        options={ctx?.extUsers ?? []}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Assign to..."
                                            fullWidth
                                            size="small"
                                          />
                                        )}
                                        value={
                                          ctx?.extUsers.find(
                                            (u) =>
                                              u.id === j.job?.extAssignedToId
                                          ) ?? null
                                        }
                                        onChange={(_, u) => {
                                          setJobSummary({
                                            ...jobSummary,
                                            jobsMapped: jobSummary.jobsMapped
                                              ? jobSummary.jobsMapped.map(
                                                  (jx, ix) =>
                                                    jx.job?.uuid === j.job?.uuid
                                                      ? {
                                                          ...jx,
                                                          job: jx.job
                                                            ? {
                                                                ...jx.job,
                                                                extAssignedToId:
                                                                  u?.id ?? null,
                                                              }
                                                            : jx.job,
                                                        }
                                                      : jx
                                                )
                                              : jobSummary.jobsMapped,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="my-4">
                                    <Divider />

                                    <div className="d-flex align-items-center">
                                      <div>
                                        <h4>FEP & BOM simplified</h4>
                                      </div>
                                      <div className="mx-1">
                                        <Link
                                          to={`/jobs/${j.job?.id}/materialrequests`}
                                        >
                                          <button className="btn btn-sm btn-primary">
                                            {" "}
                                            Material Request
                                          </button>
                                        </Link>
                                      </div>
                                      <div className="mx-1">
                                        <Link
                                          to={`/jobs/${j.job?.id}/bomlistsimulation`}
                                        >
                                          <button className="btn btn-sm btn-primary">
                                            {" "}
                                            Purchase Request
                                          </button>
                                        </Link>
                                      </div>
                                    </div>

                                    <div className="d-flex">
                                      <div className="flex-grow-1">
                                        <div>Select BoMs</div>

                                        <div>
                                          <Autocomplete
                                            fullWidth
                                            onChange={(_, b) => {
                                              if (
                                                b &&
                                                !state.job.jobBomLeveleds?.find(
                                                  (bx) =>
                                                    bx.jobBomLeveled?.bomLeveled
                                                      ?.id === b.bomLeveled?.id
                                                )
                                              ) {
                                                setJobSummary({
                                                  ...jobSummary,
                                                  jobsMapped:
                                                    jobSummary.jobsMapped
                                                      ? jobSummary.jobsMapped.map(
                                                          (jx, ix) =>
                                                            ix === i
                                                              ? {
                                                                  ...jx,
                                                                  jobBomLeveleds:
                                                                    jx.jobBomLeveleds
                                                                      ? [
                                                                          ...jx.jobBomLeveleds,
                                                                          {
                                                                            ...defaultJobBomLeveledView,
                                                                            jobBomLeveled:
                                                                              {
                                                                                ...defaultJobBomLeveled,
                                                                                bomLeveled:
                                                                                  b.bomLeveled,
                                                                              },
                                                                          },
                                                                        ]
                                                                      : jx.jobBomLeveleds,
                                                                }
                                                              : jx
                                                        )
                                                      : jobSummary.jobsMapped,
                                                });
                                              }
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                variant="outlined"
                                                placeholder="Select BOM"
                                                {...params}
                                                size="small"
                                              />
                                            )}
                                            getOptionLabel={(b) =>
                                              b.bomLeveled?.name ?? ""
                                            }
                                            options={bomLeveleds}
                                          />
                                        </div>

                                        <div>
                                          <ol>
                                            {j.jobBomLeveleds?.map((b, j) => {
                                              return (
                                                <li>
                                                  <div className="d-flex align-items-center my-2">
                                                    <div>
                                                      <input
                                                        className="form-control form-control-sm"
                                                        defaultValue={
                                                          b.jobBomLeveled
                                                            ?.qty ?? 0
                                                        }
                                                        onBlur={(e) => {
                                                          setJobSummary({
                                                            ...jobSummary,
                                                            jobsMapped:
                                                              jobSummary.jobsMapped
                                                                ? jobSummary.jobsMapped.map(
                                                                    (jx, ix) =>
                                                                      ix === i
                                                                        ? jx
                                                                          ? {
                                                                              ...jx,
                                                                              jobBomLeveleds:
                                                                                jx.jobBomLeveleds
                                                                                  ? jx.jobBomLeveleds.map(
                                                                                      (
                                                                                        jbx,
                                                                                        jx
                                                                                      ) =>
                                                                                        jx ===
                                                                                        j
                                                                                          ? {
                                                                                              ...jbx,
                                                                                              jobBomLeveled:
                                                                                                jbx.jobBomLeveled
                                                                                                  ? {
                                                                                                      ...jbx.jobBomLeveled,
                                                                                                      qty: isNaN(
                                                                                                        parseInt(
                                                                                                          e
                                                                                                            .target
                                                                                                            .value
                                                                                                        )
                                                                                                      )
                                                                                                        ? jbx
                                                                                                            .jobBomLeveled
                                                                                                            .qty
                                                                                                        : parseInt(
                                                                                                            e
                                                                                                              .target
                                                                                                              .value
                                                                                                          ),
                                                                                                    }
                                                                                                  : jbx.jobBomLeveled,
                                                                                            }
                                                                                          : jbx
                                                                                    )
                                                                                  : jx.jobBomLeveleds,
                                                                            }
                                                                          : jx
                                                                        : jx
                                                                  )
                                                                : jobSummary.jobsMapped,
                                                          });
                                                        }}
                                                        // className="form-control form-control-sm"
                                                        style={{ maxWidth: 75 }}
                                                      />
                                                    </div>

                                                    <div>
                                                      {b.jobBomLeveled?.qty ??
                                                        0}
                                                      {"x "}{" "}
                                                      {
                                                        b.jobBomLeveled
                                                          ?.bomLeveled?.name
                                                      }{" "}
                                                      (UID{" "}
                                                      {
                                                        b.jobBomLeveled
                                                          ?.bomLeveled?.id
                                                      }
                                                      )
                                                    </div>
                                                  </div>
                                                </li>
                                              );
                                            })}
                                          </ol>
                                        </div>
                                      </div>

                                      <div className="flex-grow-1">
                                        <div>Select FEPs</div>

                                        <div>
                                          <Autocomplete
                                            fullWidth
                                            onChange={(_, f) => {
                                              // alert(JSON.stringify(f));
                                              if (
                                                f &&
                                                !state.job.jobFepLeveleds?.find(
                                                  (fx) =>
                                                    fx.jobFepLeveled?.fepLeveled
                                                      ?.id === f.id
                                                )
                                              ) {
                                                setJobSummary({
                                                  ...jobSummary,
                                                  jobsMapped:
                                                    jobSummary.jobsMapped
                                                      ? jobSummary.jobsMapped.map(
                                                          (jx, ix) =>
                                                            ix === i
                                                              ? {
                                                                  ...jx,
                                                                  jobFepLeveleds:
                                                                    jx.jobFepLeveleds
                                                                      ? [
                                                                          ...jx.jobFepLeveleds,
                                                                          {
                                                                            ...defaultFepLeveledView,
                                                                            jobFepLeveled:
                                                                              {
                                                                                ...defaultJobFepLeveled,
                                                                                fepLeveled:
                                                                                  f,
                                                                              },
                                                                          },
                                                                        ]
                                                                      : jx.jobFepLeveleds,
                                                                }
                                                              : jx
                                                        )
                                                      : jobSummary.jobsMapped,
                                                });
                                              }
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                variant="outlined"
                                                placeholder="Select FEP"
                                                {...params}
                                                size="small"
                                              />
                                            )}
                                            getOptionLabel={(f) => f.name ?? ""}
                                            options={fepLeveleds}
                                          />
                                        </div>

                                        <div>
                                          <ol>
                                            {j.jobFepLeveleds?.map((f, k) => {
                                              return (
                                                <li>
                                                  <div
                                                    className="my-2"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      setJobSummary({
                                                        ...jobSummary,
                                                        jobFepDeleteIds:
                                                          jobSummary.jobFepDeleteIds
                                                            ? [
                                                                ...jobSummary.jobFepDeleteIds,
                                                                f.jobFepLeveled
                                                                  ?.id ?? 0,
                                                              ]
                                                            : jobSummary.jobFepDeleteIds,
                                                        jobsMapped:
                                                          jobSummary.jobsMapped
                                                            ? jobSummary.jobsMapped.map(
                                                                (jx, ix) =>
                                                                  jx.job
                                                                    ?.uuid ===
                                                                  j.job?.uuid
                                                                    ? jx
                                                                      ? {
                                                                          ...jx,
                                                                          jobFepLeveleds:
                                                                            jx.jobFepLeveleds
                                                                              ? jx.jobFepLeveleds.filter(
                                                                                  (
                                                                                    fx,
                                                                                    kx
                                                                                  ) =>
                                                                                    kx !==
                                                                                    k
                                                                                )
                                                                              : jx.jobFepLeveleds,
                                                                        }
                                                                      : jx
                                                                    : jx
                                                              )
                                                            : jobSummary.jobsMapped,
                                                      });
                                                    }}
                                                  >
                                                    {
                                                      f.jobFepLeveled
                                                        ?.fepLeveled?.name
                                                    }{" "}
                                                    (UID{" "}
                                                    {
                                                      f.jobFepLeveled
                                                        ?.fepLeveled?.id
                                                    }
                                                    ){" "}
                                                    <span
                                                      className={`font-weight-bold ${
                                                        (f
                                                          .fepLeveledFepLeveledPrograms
                                                          ?.length ?? 0) > 0
                                                          ? "text-success"
                                                          : "text-danger"
                                                      }`}
                                                    >{`${
                                                      f
                                                        .fepLeveledFepLeveledPrograms
                                                        ?.length
                                                    } program, ${f.fepLeveledFepLeveledPrograms?.reduce(
                                                      (acc, p) =>
                                                        acc +
                                                        (p
                                                          .fepLeveledFepLeveledProgram
                                                          ?.fepLeveledProgram
                                                          ?.runs ?? 0),
                                                      0
                                                    )}x runs`}</span>
                                                  </div>
                                                </li>
                                              );
                                            })}
                                          </ol>
                                        </div>
                                      </div>
                                    </div>

                                    <Divider />
                                  </div>

                                  <div className="d-flex mt-2">
                                    <div className="mx-2 flex-grow-1">
                                      <div>
                                        <label>
                                          Production Deadline{" "}
                                          {j.job?.productionDeadline
                                            ? `${Intl.DateTimeFormat(
                                                navigator.language ?? "en-US",
                                                {
                                                  dateStyle: "medium",
                                                  timeZone: "utc",
                                                  hourCycle: "h23",
                                                  // timeStyle: "long",
                                                } as any
                                              ).format(
                                                new Date(
                                                  `${j.job?.productionDeadline}T00:00:00Z`
                                                )
                                              )}`
                                            : ``}
                                        </label>
                                        <input
                                          className="form-control form-control-sm shadow-md"
                                          type="date"
                                          placeholder="Production deadline..."
                                          defaultValue={
                                            j.job?.productionDeadline ??
                                            undefined
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value &&
                                              e.target.value !== ""
                                            ) {
                                              setJobSummary({
                                                ...jobSummary,
                                                jobsMapped:
                                                  jobSummary.jobsMapped
                                                    ? jobSummary.jobsMapped.map(
                                                        (jx, ix) =>
                                                          jx.job?.uuid ===
                                                          j.job?.uuid
                                                            ? {
                                                                ...jx,
                                                                job: jx.job
                                                                  ? {
                                                                      ...jx.job,
                                                                      productionDeadline:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  : jx.job,
                                                              }
                                                            : jx
                                                      )
                                                    : jobSummary.jobsMapped,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="mx-2 flex-grow-1">
                                      <div>
                                        <label>
                                          QC Deadline{" "}
                                          {j.job?.deadline
                                            ? `${Intl.DateTimeFormat(
                                                navigator.language ?? "en-US",
                                                {
                                                  dateStyle: "medium",
                                                  timeZone: "utc",
                                                  hourCycle: "h23",
                                                  // timeStyle: "long",
                                                } as any
                                              ).format(
                                                new Date(
                                                  `${j.job?.deadline}T00:00:00Z`
                                                )
                                              )}`
                                            : ``}
                                        </label>
                                        <input
                                          className="form-control form-control-sm shadow-md"
                                          type="date"
                                          placeholder="QC deadline..."
                                          defaultValue={
                                            j.job?.deadline ?? undefined
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value &&
                                              e.target.value !== ""
                                            ) {
                                              setJobSummary({
                                                ...jobSummary,
                                                jobsMapped:
                                                  jobSummary.jobsMapped
                                                    ? jobSummary.jobsMapped.map(
                                                        (jx, ix) =>
                                                          jx.job?.uuid ===
                                                          j.job?.uuid
                                                            ? {
                                                                ...jx,
                                                                job: jx.job
                                                                  ? {
                                                                      ...jx.job,
                                                                      deadline:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  : jx.job,
                                                              }
                                                            : jx
                                                      )
                                                    : jobSummary.jobsMapped,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="mx-2 flex-grow-1">
                                      <div>
                                        <label>
                                          Delivery Deadline{" "}
                                          {j.job?.deliveryDeadline
                                            ? `${Intl.DateTimeFormat(
                                                navigator.language ?? "en-US",
                                                {
                                                  dateStyle: "medium",
                                                  timeZone: "utc",
                                                  hourCycle: "h23",
                                                  // timeStyle: "long",
                                                } as any
                                              ).format(
                                                new Date(
                                                  `${j.job?.deliveryDeadline}T00:00:00Z`
                                                )
                                              )}`
                                            : ``}
                                        </label>
                                        <input
                                          className="form-control form-control-sm shadow-md"
                                          type="date"
                                          placeholder="Delivery deadline..."
                                          defaultValue={
                                            j.job?.deliveryDeadline ?? undefined
                                          }
                                          onChange={(e) => {
                                            if (
                                              e.target.value &&
                                              e.target.value !== ""
                                            ) {
                                              setJobSummary({
                                                ...jobSummary,
                                                jobsMapped:
                                                  jobSummary.jobsMapped
                                                    ? jobSummary.jobsMapped.map(
                                                        (jx, ix) =>
                                                          jx.job?.uuid ===
                                                          j.job?.uuid
                                                            ? {
                                                                ...jx,
                                                                job: jx.job
                                                                  ? {
                                                                      ...jx.job,
                                                                      deliveryDeadline:
                                                                        e.target
                                                                          .value,
                                                                    }
                                                                  : jx.job,
                                                              }
                                                            : jx
                                                      )
                                                    : jobSummary.jobsMapped,
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </MuiPickersUtilsProvider>
                            </ThemeProvider>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <hr className="border border-secondary" />
                    </div>
                  </li>
                </>
              );
            })}
          </ol>
        </div>
      </div>
    </>
  );
};

export default JobSummaryDetail;
