import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";
import {
    makeReadableDateString,
    makeReadableDateTimeString
} from "../../helpers";
import { ExtProjectSimple } from "../../models/model";
import { RequestStatus } from "../../models/RequestStatus";

const ProjectsPage = () => {
  const state = useContext(AppContext);

  const [projects, setProjects] = useState<ExtProjectSimple[]>([]);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.NotAsked);
  const [projectName, setProjectName] = useState("");
  const [organizationId, setOrganizationId] = useState(0);
  const [customerId, setCustomerId] = useState(0);
  const [projectManagerId, setProjectManagerID] = useState(0);

  const [organizationSearch, setOrganizationSearch] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [projectManagerSearch, setProjectManagerSearch] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const resp = await fetch(
        `${state?.baseUrl}/ext-projects?name=${projectName}&customerId=${customerId}&projectManagerId=${projectManagerId}&organizationId=${organizationId}`,
        {
          headers: { authorization: state?.apiKey ?? "" },
        }
      );

      if (resp.status !== 200) throw await resp.text();

      const newProjects = await resp.json();

      setProjects(newProjects);
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log("[fetchData] error", e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <div>
            <h4>Projects</h4>
          </div>
          <div className="mx-2">
            {requestStatus === RequestStatus.Loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              fetchData();
            }}
          >
            <div className="d-flex my-2 align-items-end">
              <div className="flex-grow-1 mx-1">
                <FormControl
                  placeholder="Filter by Name.."
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </div>
              <div className="flex-grow-1 mx-1">
                <FormControl
                  size="sm"
                  placeholder="Search..."
                  onChange={(e) => setOrganizationSearch(e.target.value)}
                />
                <FormControl
                  as="select"
                  onInput={(e: any) => {
                    console.log(e.target.value);
                    if (e.target.value && e.target.value !== "") {
                      try {
                        setOrganizationId(parseInt(e.target.value));
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      setOrganizationId(0);
                    }
                  }}
                >
                  <option selected disabled>
                    Filter by Organization...
                  </option>

                  <option value={""}>None</option>
                  {state?.extOrganizations
                    ?.filter((extOrganization) =>
                      extOrganization.name
                        ?.toLowerCase()
                        .includes(organizationSearch.toLowerCase())
                    )
                    .map((extOrganization) => (
                      <option value={extOrganization.id ?? undefined}>
                        {extOrganization.name}
                      </option>
                    ))}
                </FormControl>
              </div>
              <div className="flex-grow-1 mx-1">
                <FormControl
                  size="sm"
                  placeholder="Search..."
                  onChange={(e) => setCustomerSearch(e.target.value)}
                />
                <FormControl
                  as="select"
                  onInput={(e: any) => {
                    if (e.target.value && e.target.value !== "") {
                      try {
                        setCustomerId(parseInt(e.target.value));
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      setCustomerId(0);
                    }
                  }}
                >
                  <option selected disabled>
                    Filter by Customer...
                  </option>
                  <option value={""}>None</option>
                  {state?.extCustomers
                    ?.filter((extCustomer) =>
                      extCustomer.name
                        ?.toLowerCase()
                        .includes(customerSearch.toLowerCase())
                    )
                    ?.map((extCustomer) => (
                      <option value={extCustomer.id ?? undefined}>
                        {extCustomer.name}
                      </option>
                    ))}
                </FormControl>
              </div>

              <div className="flex-grow-1 mx-1">
                <FormControl
                  size="sm"
                  placeholder="Search..."
                  onChange={(e) => setProjectManagerSearch(e.target.value)}
                />
                <FormControl
                  as="select"
                  onInput={(e: any) => {
                    if (e.target.value && e.target.value !== "") {
                      try {
                        setProjectManagerID(parseInt(e.target.value));
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      setProjectManagerID(0);
                    }
                  }}
                >
                  <option selected disabled>
                    Filter by PM...
                  </option>
                  <option value={""}>None</option>
                  {state?.extUsers
                    ?.filter((extUser) =>
                      extUser?.name
                        ?.toLowerCase()
                        ?.includes(projectManagerSearch?.toLowerCase())
                    )
                    ?.map((extUser) => (
                      <option value={extUser.id ?? undefined}>
                        {extUser.name}
                      </option>
                    ))}
                </FormControl>
              </div>
              <div>
                <Button size="sm" type="submit">
                  Search
                </Button>
              </div>
            </div>
          </form>

          <div
            className="overflow-auto border border-secondary"
            style={{ height: "75vh", resize: "vertical" }}
          >
            <Table size="sm" style={{ borderCollapse: "separate" }}>
              <thead>
                <tr>
                  {[
                    "Name",
                    "Organization",
                    "Customer",
                    "Template",
                    "PM",
                    "Scope",
                    "Start",
                    "Deadline",
                    "No. of products",
                    "No. of POs",
                    "Created",
                    "Updated",
                  ].map((head) => (
                    <th
                      style={{ position: "sticky", top: 0 }}
                      className="border border-light bg-secondary text-light text-center align-middle"
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => {
                  return (
                    <tr>
                      <td className="border border-secondary">
                        <Link to={`/projects/${project.id}`}>
                          {project.name && project.name !== ""
                            ? project.name
                            : "[NO NAME]"}
                        </Link>
                      </td>
                      <td className="border border-secondary">
                        {project.organization}
                      </td>
                      <td className="border border-secondary">
                        {project.customer}
                      </td>

                      <td className="border border-secondary">
                        {project.templateGroup}
                      </td>

                      <td className="border border-secondary">
                        {project.leader}
                      </td>
                      <td className="border border-secondary">{`${project.scope?.slice(
                        0,
                        20
                      )}....`}</td>
                      <td className="border border-secondary">
                        {project.start
                          ? makeReadableDateString(new Date(project.start))
                          : "None"}
                      </td>
                      <td className="border border-secondary">
                        {project.deadline
                          ? makeReadableDateString(new Date(project.deadline))
                          : "None"}{" "}
                      </td>
                      <td className="border border-secondary">
                        {project.products}
                      </td>
                      <td className="border border-secondary">{project.pos}</td>
                      <td className="border border-secondary">
                        {project.createdAt
                          ? makeReadableDateTimeString(
                              new Date(project.createdAt)
                            )
                          : "None"}{" "}
                      </td>
                      <td className="border border-secondary">
                        {project.updatedAt
                          ? makeReadableDateTimeString(
                              new Date(project.updatedAt)
                            )
                          : "None"}{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsPage;
