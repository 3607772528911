import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'


const SyncAutocomplete = (props: any) => {
  return (
    <Autocomplete
      id="crud-table-combobox-filter"
      options={props.options}
      value={props.value}
      onChange={(event: any, value: any) => props.onChange(value)}
      getOptionLabel={option => option}
      style={{width: props.width ? props.width : 400}}
      renderInput={params => (
        <TextField {...params} label={props.label} fullWidth size="small" />
      )}
    />
  )
}

export default SyncAutocomplete