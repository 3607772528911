import { Box, Button, Checkbox, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { CrudInfo, initialCrudInfoNoData } from '../../../models/CrudInfo'
import { initialPageNoContent } from '../../../models/Page'
import { RequestStatus } from '../../../models/RequestStatus'

const StatefulCrudTable = <T,> (props: {
  url: string,
  head: string[],
  mapper: (arg: T) => {id: number, content: string[]} 
}) => {
  const apiKey = localStorage.getItem("apiKey");

  const [crudState, setCrudState] = useState<CrudInfo<T>>({
    ...initialCrudInfoNoData,
    data: { ...initialPageNoContent, content: [] }
  })

  const mappedValues = crudState.data.content.map(obj => props.mapper(obj))

  useEffect(() => {
    if(crudState.requestStatus === RequestStatus.NotAsked) {
      fetchData()
    }
  })

  const fetchData = async () => {
    try {
      setCrudState({
        ...crudState,
        requestStatus: RequestStatus.Loading
      })
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${props.url}/search/paged?name=${crudState.searchInput}&page=${crudState.currentPage}&perPage=${crudState.rowsPerPage}`, {
        headers: {
          "authorization": apiKey ? apiKey : ""
        }
      })
      setCrudState({
        ...crudState,
        requestStatus: RequestStatus.Success,
        data: await response.json() 
      })
    } catch(e) {
      setCrudState({
        ...crudState,
        requestStatus: RequestStatus.Error
      })

      console.log('Error fetching: ', e)
    }
  }

  const handleCheckCell = (id: number) => {
    if(crudState.idsToDelete.includes(id)) {
      const newCrudState = {...crudState}
      newCrudState.idsToDelete.splice(newCrudState.idsToDelete.findIndex(id => id), 1)
      setCrudState(newCrudState)
    } else {
      const newCrudState = {...crudState}
      newCrudState.idsToDelete = [id, ...newCrudState.idsToDelete]

      setCrudState(newCrudState)
    }
  } 

  const handleClickCheckAll = () => {
    if(crudState.idsToDelete.length === 0) {
      const newCrudState = {
        ...crudState,
        idsToDelete: crudState.data.content.map((val: any) => val.id)
      }
      
      console.log('new crud state', newCrudState.idsToDelete)

      setCrudState(newCrudState)
    } else {
      setCrudState({
        ...crudState,
        idsToDelete: []
      })
    }
  }
   
  const handleDeleteBatch = async () => {
    const response = await Promise.all(crudState.idsToDelete.map(async (id) => {
      return await fetch(`${process.env.REACT_APP_BASE_URL}/${props.url}/${id}`, {
        method: 'DELETE'
      })  
    }))

    setCrudState({
      ...initialCrudInfoNoData,
      data: { ...initialPageNoContent, content: [] }
    })
  }

  const handleChangePage = (event: any, newPage: number) => {
    setCrudState({
      ...crudState,
      requestStatus: RequestStatus.NotAsked,
      currentPage: newPage
    })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setCrudState({
      ...crudState,
      rowsPerPage: parseInt(event.target.value, 10),
      requestStatus: RequestStatus.NotAsked,
      currentPage: 0
    })
  }
    
  const handleChangeSearchInput = (event: any) => {
    setCrudState({
      ...crudState,
      searchInput: event.target.value,
      requestStatus: RequestStatus.NotAsked,
      currentPage: 0
    })
  }
  
  return (
    <Box>
      <Box my={1} display="flex">
        <TextField
          value={crudState.searchInput}
          placeholder="Search"
          onChange={handleChangeSearchInput}
        />
        <Box mx={1}>
          <Link to={`/${props.url}/add`}>
            <Button
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Link>
        </Box>
        {crudState.idsToDelete.length > 0
          ? <Box mx={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteBatch}
              >
                Delete
              </Button>
            </Box>
          : <></>
        }
        {crudState.requestStatus === RequestStatus.Loading ? <CircularProgress disableShrink /> : <></>}
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead style={{backgroundColor: 'honeydew'}}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox 
                  onClick={() => handleClickCheckAll()}
                />
              </TableCell>
              {props.head.map(head => <TableCell>{head}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedValues.map(row => (
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={() => handleCheckCell(row.id)}
                    checked={crudState.idsToDelete.includes(row.id)} 
                  />
                </TableCell>
                {row.content.map((value, i) => (
                  <TableCell>
                    {i === 0
                      ? <Link to={`/${props.url}/${row.id}`}>
                          {value === "" ? "(No name)" : value}
                        </Link>
                      : value
                    }
                  </TableCell>
                ))} 
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={crudState.data.totalElements}
          page={crudState.currentPage}
          rowsPerPage={crudState.rowsPerPage}
          onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        >
        </TablePagination>
      </TableContainer>
    </Box>
  )
}

export default StatefulCrudTable