import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";

export type PlanningReportType =
  | "Dept Summary"
  | "Dept vs Actual"
  | "Manpower Summary"
  | "Manpower vs Actual"
  | "Optimization"
  | "Statistics";

export const planningReportType: PlanningReportType[] = [
  "Dept Summary",
  "Dept vs Actual",
  "Manpower Summary",
  "Manpower vs Actual",
  "Optimization",
  "Statistics",
];

const PlanningReport = () => {
  const ctx = useContext(AppContext);

  const [selectedPlanningReportType, setSelectedPlanningReportType] = useState(
    "Dept Summary" as PlanningReportType
  );

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {};

  return (
    <>
      <div>
        <div>
          <h4>Planning Report</h4>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          {planningReportType.map((t) => {
            return (
              <>
                <button
                  onClick={() => {
                    setSelectedPlanningReportType(t);
                  }}
                  className={`btn btn-sm ${
                    selectedPlanningReportType === t
                      ? `btn-primary`
                      : `btn-outline-primary`
                  }`}
                >
                  {t}
                </button>
              </>
            );
          })}
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <h5>{selectedPlanningReportType}</h5>
        {(() => {
          switch (selectedPlanningReportType) {
            case "Dept Summary":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );
            case "Dept Summary":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );
            case "Dept vs Actual":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );
            case "Manpower Summary":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );
            case "Manpower vs Actual":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );
            case "Optimization":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );
            case "Statistics":
              return (
                <>
                  {" "}
                  <div
                    className="overflow-auto border border-dark shadow shadow-md bg-light"
                    style={{ height: "65vh", resize: "vertical" }}
                  ></div>
                </>
              );

            default:
              return <></>;
          }
        })()}
      </div>
    </>
  );
};

export default PlanningReport;
