import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import {
  MasterJavaBaseModel,
  PpicRcem,
  PpicRcemDepartment,
  PpicRcemDepartments,
  PpicRcemProcess,
  PpicRcemProcesses,
  PpicRcemProcessSkills,
  PpicRcemSubProcess,
  PpicRcemSubProcessSkill,
  PpicRcemSubProcessSkills,
  PpicRcemSubProcess_Impact,
  ppicRcemSubProcess_ImpactFromJSON,
  ppicRcemSubProcess_ImpactToJSON,
  ppicRcemSubProcess_ImpactToNumber,
  PpicRcemSubProcess_Likelihood,
  ppicRcemSubProcess_LikelihoodToJSON,
  ppicRcemSubProcess_LikelihoodToNumber,
  PpicSkill,
  PpicSkills,
} from "../../../masterbigsystem";
import { v4 as uuidv4, v4 } from "uuid";
import { Add } from "@material-ui/icons";
import {
  fetchRcemDepartmentsProto,
  fetchRcemProcessSkillsProto,
  fetchRcemSubProcessSkillsProto,
  fetchSkillsProto,
} from "../../../helpers";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { ExtDepartment } from "../../../models/model";

const RcemPage = () => {
  const ctx = useContext(AppContext);
  const [rcemDepts, setRcemDepts] = useState<PpicRcemDepartments>(
    PpicRcemDepartments.fromPartial({ departments: [] })
  );
  const [selectedRcemDept, setSelectedRcemDept] = useState<
    PpicRcemDepartment | null | undefined
  >(null);
  const [rcemSubProcessSkills, setRcemSubProcessSkills] =
    useState<PpicRcemSubProcessSkills>(
      PpicRcemSubProcessSkills.fromPartial({ rcemSubProcessSkills: [] })
    );
  const [skills, setSkills] = useState<PpicSkills>(
    PpicSkills.fromPartial({ skills: [] })
  );
  const [rcemSubProcessSkillDeleteIds, setRcemSubProcessSkillDeleteIds] =
    useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    handleFetchRcemDepartmentsData();
    handleFetchRcemProcessSkills();
    handleFetchSkills();
  };

  const handleFetchRcemDepartmentsData = async () => {
    setLoading(true);
    const fetched = await fetchRcemDepartmentsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (fetched) {
      setRcemDepts(fetched);
    }

    setLoading(false);
  };
  const handleFetchRcemProcessSkills = async () => {
    const fetched = await fetchRcemSubProcessSkillsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (fetched) {
      setRcemSubProcessSkills(fetched);
    }
  };
  const handleFetchSkills = async () => {
    const fetched = await fetchSkillsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (fetched) {
      setSkills(fetched);
    }
  };

  const handleSave = async () => {
    setLoading(true);

    console.log("deleteid", rcemSubProcessSkillDeleteIds);
    await Promise.all([
      (async () => {
        try {
          const resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/rcemdepartments`,
            {
              method: "post",
              headers: {
                "content-type": "application/json",
                authorization: ctx?.apiKey ?? "",
              },
              body: JSON.stringify(PpicRcemDepartments.toJSON(rcemDepts)),
            }
          );
        } catch (e) {
          console.error(e);
        } finally {
        }
      })(),
      (async () => {
        try {
          const resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/rcemsubprocessskills-bulk`,
            {
              method: "post",
              headers: {
                "content-type": "application/json",
                authorization: ctx?.apiKey ?? "",
              },
              body: JSON.stringify(
                PpicRcemSubProcessSkills.toJSON(rcemSubProcessSkills)
              ),
            }
          );
        } catch (e) {
          console.error(e);
        }
      })(),
      // Empty rcem sub process skills
      rcemSubProcessSkillDeleteIds.map(async (id) => {
        try {
          const resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/rcemsubprocessskills/${id}/empty`,
            {
              method: "delete",
              headers: {
                "content-type": "application/json",
                authorization: ctx?.apiKey ?? "",
              },
              body: JSON.stringify(
                PpicRcemSubProcessSkills.toJSON(rcemSubProcessSkills)
              ),
            }
          );
        } catch (e) {
          console.error(e);
        }
      }),
    ]);
    setLoading(false);
    alert("Save done");
    window.location.reload();
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex">
          <div className="d-flex">
            <h4>RCEM</h4>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Save
                </button>
              </>
            )}
          </div>
        </div>
        <hr />
        <div>
          <div>
            <button
              onClick={() => {
                const newRcemDept = PpicRcemDepartment.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: uuidv4(),
                  }),
                });
                setRcemDepts({
                  ...rcemDepts,
                  departments: rcemDepts.departments
                    ? [...rcemDepts.departments, newRcemDept]
                    : [newRcemDept],
                });
              }}
              className="btn btn-primary btn-sm"
            >
              <Add /> Add Dept
            </button>
          </div>
        </div>
        <div className="d-flex flex-wrap">
          {rcemDepts.departments?.map((d) => {
            return (
              <div className="border border-dark p-1 d-flex">
                <div>
                  <input
                    style={{ width: 100 }}
                    className="form-control form-control-sm"
                    defaultValue={d.name}
                    onBlur={(e) => {
                      setRcemDepts({
                        ...rcemDepts,
                        departments: rcemDepts.departments
                          ? rcemDepts.departments.map((dx) =>
                              dx.masterJavaBaseModel?.uuid ===
                              d.masterJavaBaseModel?.uuid
                                ? { ...dx, name: e.target.value }
                                : dx
                            )
                          : rcemDepts.departments,
                      });
                    }}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => {
                      setSelectedRcemDept(d);
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
            );
          }) ?? []}
        </div>
        <hr />
        {selectedRcemDept ? (
          <>
            <div className="d-flex">
              <div>Selected dept: {selectedRcemDept.name}</div>
              <button
                onClick={() => {
                  const newRcemProcess = PpicRcemProcess.fromPartial({
                    masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                      uuid: uuidv4(),
                    }),
                  });
                  setRcemDepts({
                    ...rcemDepts,
                    departments: rcemDepts.departments
                      ? rcemDepts.departments.map((dx) =>
                          dx &&
                          dx.masterJavaBaseModel?.uuid ===
                            selectedRcemDept?.masterJavaBaseModel?.uuid
                            ? {
                                ...dx,
                                processes: dx.processes
                                  ? [...dx.processes, newRcemProcess]
                                  : [newRcemProcess],
                              }
                            : dx
                        )
                      : rcemDepts.departments,
                  });
                }}
                className="btn btn-primary btn-sm"
              >
                <Add />
                Add Process
              </button>
            </div>
          </>
        ) : (
          <></>
        )}

        <div
          className="border border-dark overflow-auto"
          style={{ height: "60vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {[
                "Process",
                "Subprocess",
                "Risk",
                "Time proc (h)",
                "Impact",
                "Likelihood",
                "Quantitative Risk",
                "Qualitative Risk (1-16)",
                "Control Supplier",
                "Control Input",
                "Control Process",
                "Control Output",
                "Control Responsibility",
                "Frequency",
                "A/M",
                "P/D",
                "R/NR/E",
                "Control Component",
              ].map((h, i) => {
                return (
                  <th
                    className="bg-dark text-light"
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      left: i === 1 ? 0 : undefined,
                    }}
                  >
                    {h}
                  </th>
                );
              })}
            </tr>
            {(() => {
              const foundRcemDept = rcemDepts.departments?.find(
                (dx) =>
                  dx.masterJavaBaseModel?.uuid ===
                  selectedRcemDept?.masterJavaBaseModel?.uuid
              );

              return foundRcemDept?.processes?.map((p, i) => {
                return (
                  <>
                    <tr>
                      <td
                        className="border border-dark"
                        rowSpan={(p.subProcesses?.length ?? 0) + 1}
                      >
                        <button
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => {
                            const newRcemSubProcess =
                              PpicRcemSubProcess.fromPartial({
                                masterJavaBaseModel:
                                  MasterJavaBaseModel.fromPartial({
                                    uuid: uuidv4(),
                                  }),
                              });
                            setRcemDepts({
                              ...rcemDepts,
                              departments: rcemDepts.departments
                                ? rcemDepts.departments.map((dx) =>
                                    dx &&
                                    dx.masterJavaBaseModel?.uuid ===
                                      selectedRcemDept?.masterJavaBaseModel
                                        ?.uuid
                                      ? {
                                          ...dx,
                                          processes: dx.processes
                                            ? dx.processes.map((px) =>
                                                px &&
                                                px.masterJavaBaseModel?.uuid ===
                                                  p.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      subProcesses:
                                                        px.subProcesses
                                                          ? [
                                                              ...px.subProcesses,
                                                              newRcemSubProcess,
                                                            ]
                                                          : [newRcemSubProcess],
                                                    }
                                                  : px
                                              )
                                            : dx.processes,
                                        }
                                      : dx
                                  )
                                : rcemDepts.departments,
                            });
                          }}
                          className="btn btn-primary btn-sm"
                        >
                          <Add />
                          Add Subproc
                        </button>
                        <textarea
                          className="form-control form-control-sm"
                          placeholder="Process Name..."
                          onBlur={(e) => {
                            setRcemDepts({
                              ...rcemDepts,
                              departments: rcemDepts.departments
                                ? rcemDepts.departments.map((dx) =>
                                    dx &&
                                    dx.masterJavaBaseModel?.uuid ===
                                      selectedRcemDept?.masterJavaBaseModel
                                        ?.uuid
                                      ? {
                                          ...dx,
                                          processes: dx.processes
                                            ? dx.processes.map((px) =>
                                                px &&
                                                px.masterJavaBaseModel?.uuid ===
                                                  p.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...px,
                                                      name: e.target.value,
                                                    }
                                                  : px
                                              )
                                            : dx.processes,
                                        }
                                      : dx
                                  )
                                : rcemDepts.departments,
                            });
                          }}
                          defaultValue={p.name}
                        />
                      </td>
                    </tr>
                    {p.subProcesses?.map((sp) => {
                      return (
                        <>
                          <tr>
                            <td
                              className="border border-dark bg-light"
                              style={{ position: "sticky", left: 0, zIndex: 1 }}
                            >
                              <div style={{ minWidth: 250 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Process Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              name: e
                                                                                .target
                                                                                .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.name}
                                />
                                <div>
                                  <small>
                                    <strong>Skills needed:</strong>
                                  </small>
                                  <div>
                                    <Select
                                      options={skills.skills ?? []}
                                      getOptionLabel={(s) =>
                                        s.name ?? "No name"
                                      }
                                      placeholder="Select skill"
                                      onChange={(v) => {
                                        if (!sp.masterJavaBaseModel?.id) {
                                          alert(
                                            "Please save sub process first before adding skill."
                                          );
                                          return;
                                        }
                                        const val = v as unknown as PpicSkill;

                                        setRcemDepts({
                                          ...rcemDepts,
                                          departments: rcemDepts.departments
                                            ? rcemDepts.departments.map((dx) =>
                                                dx &&
                                                dx.masterJavaBaseModel?.uuid ===
                                                  selectedRcemDept
                                                    ?.masterJavaBaseModel?.uuid
                                                  ? {
                                                      ...dx,
                                                      processes: dx.processes
                                                        ? dx.processes.map(
                                                            (px) =>
                                                              px &&
                                                              px
                                                                .masterJavaBaseModel
                                                                ?.uuid ===
                                                                p
                                                                  .masterJavaBaseModel
                                                                  ?.uuid
                                                                ? {
                                                                    ...px,
                                                                    subProcesses:
                                                                      px.subProcesses
                                                                        ? px.subProcesses.map(
                                                                            (
                                                                              spx
                                                                            ) =>
                                                                              spx &&
                                                                              spx
                                                                                .masterJavaBaseModel
                                                                                ?.uuid ===
                                                                                sp
                                                                                  .masterJavaBaseModel
                                                                                  ?.uuid
                                                                                ? {
                                                                                    ...spx,
                                                                                    skillId:
                                                                                      val
                                                                                        .masterJavaBaseModel
                                                                                        ?.id,
                                                                                  }
                                                                                : spx
                                                                          )
                                                                        : px.subProcesses,
                                                                  }
                                                                : px
                                                          )
                                                        : dx.processes,
                                                    }
                                                  : dx
                                              )
                                            : rcemDepts.departments,
                                        });

                                        // if (
                                        //   !rcemSubProcessSkills.rcemSubProcessSkills.find(
                                        //     (spx) =>
                                        //       `${spx.skillId}` ===
                                        //         `${val.masterJavaBaseModel?.id}` &&
                                        //       `${spx.rcemSubProcessId}` ===
                                        //         sp.masterJavaBaseModel?.id
                                        //   )
                                        // ) {
                                        //   setRcemSubProcessSkills({
                                        //     ...rcemSubProcessSkills,
                                        //     rcemSubProcessSkills: [
                                        //       ...rcemSubProcessSkills.rcemSubProcessSkills,
                                        //       PpicRcemSubProcessSkill.fromPartial(
                                        //         {
                                        //           masterJavaBaseModel:
                                        //             MasterJavaBaseModel.fromPartial(
                                        //               { uuid: v4() }
                                        //             ),
                                        //           rcemSubProcessId:
                                        //             sp.masterJavaBaseModel?.id,
                                        //           skillId:
                                        //             val.masterJavaBaseModel?.id,
                                        //         }
                                        //       ),
                                        //     ],
                                        //   });
                                        // }
                                      }}
                                      menuPortalTarget={document.body}
                                      styles={{
                                        menuPortal: (base) => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                      }}
                                    />
                                  </div>
                                  <div>
                                    {
                                      skills.skills.find(
                                        (s) =>
                                          `${s.masterJavaBaseModel?.id}` ===
                                          `${sp.skillId}`
                                      )?.name
                                    }
                                  </div>
                                  {/* <div>
                                    <ol>
                                      {rcemSubProcessSkills.rcemSubProcessSkills
                                        .filter(
                                          (spx) =>
                                            `${spx.rcemSubProcessId}` ===
                                            `${sp.masterJavaBaseModel?.id}`
                                        )
                                        .map((sp) => {
                                          const foundSkill = skills.skills.find(
                                            (sx) =>
                                              `${sx.masterJavaBaseModel?.id}` ===
                                              `${sp.skillId}`
                                          );
                                          return (
                                            <>
                                              <li>
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setRcemSubProcessSkills({
                                                      ...rcemSubProcessSkills,
                                                      rcemSubProcessSkills:
                                                        rcemSubProcessSkills.rcemSubProcessSkills.filter(
                                                          (spx) =>
                                                            `${spx.masterJavaBaseModel?.uuid}` !==
                                                            `${sp.masterJavaBaseModel?.uuid}`
                                                        ),
                                                    });
                                                    setRcemSubProcessSkillDeleteIds(
                                                      [
                                                        ...rcemSubProcessSkillDeleteIds,
                                                        sp.masterJavaBaseModel
                                                          ?.id ?? "",
                                                      ]
                                                    );
                                                  }}
                                                >
                                                  <div>
                                                    <small>
                                                      {foundSkill?.name ??
                                                        "None"}
                                                    </small>
                                                  </div>
                                                </div>
                                              </li>
                                            </>
                                          );
                                        })}
                                    </ol>
                                  </div> */}
                                </div>
                              </div>
                            </td>
                            <td
                              style={{ whiteSpace: "nowrap" }}
                              className="border border-dark"
                            >
                              <div>
                                <button
                                  style={{ whiteSpace: "nowrap" }}
                                  onClick={() => {
                                    const newRcem = PpicRcem.fromPartial({
                                      masterJavaBaseModel:
                                        MasterJavaBaseModel.fromPartial({
                                          uuid: uuidv4(),
                                        }),
                                    });
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              rcems:
                                                                                spx.rcems
                                                                                  ? [
                                                                                      ...spx.rcems,
                                                                                      newRcem,
                                                                                    ]
                                                                                  : [
                                                                                      newRcem,
                                                                                    ],
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  className="btn btn-primary btn-sm"
                                >
                                  <Add />
                                  Add RCEM
                                </button>
                              </div>
                              <ol>
                                {sp.rcems?.map((r, j) => {
                                  return (
                                    <li>
                                      <div style={{ minWidth: 250 }}>
                                        {/* {r.name} */}
                                        <textarea
                                          className="form-control form-control-sm"
                                          placeholder="Risk Desc..."
                                          onBlur={(e) => {
                                            setRcemDepts({
                                              ...rcemDepts,
                                              departments: rcemDepts.departments
                                                ? rcemDepts.departments.map(
                                                    (dx) =>
                                                      dx &&
                                                      dx.masterJavaBaseModel
                                                        ?.uuid ===
                                                        selectedRcemDept
                                                          ?.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...dx,
                                                            processes:
                                                              dx.processes
                                                                ? dx.processes.map(
                                                                    (px) =>
                                                                      px &&
                                                                      px
                                                                        .masterJavaBaseModel
                                                                        ?.uuid ===
                                                                        p
                                                                          .masterJavaBaseModel
                                                                          ?.uuid
                                                                        ? {
                                                                            ...px,
                                                                            subProcesses:
                                                                              px.subProcesses
                                                                                ? px.subProcesses.map(
                                                                                    (
                                                                                      spx
                                                                                    ) =>
                                                                                      spx &&
                                                                                      spx
                                                                                        .masterJavaBaseModel
                                                                                        ?.uuid ===
                                                                                        sp
                                                                                          .masterJavaBaseModel
                                                                                          ?.uuid
                                                                                        ? {
                                                                                            ...spx,
                                                                                            rcems:
                                                                                              spx.rcems
                                                                                                ? spx.rcems.map(
                                                                                                    (
                                                                                                      rx
                                                                                                    ) =>
                                                                                                      rx &&
                                                                                                      rx
                                                                                                        .masterJavaBaseModel
                                                                                                        ?.uuid ===
                                                                                                        r
                                                                                                          .masterJavaBaseModel
                                                                                                          ?.uuid
                                                                                                        ? {
                                                                                                            ...rx,
                                                                                                            name: e
                                                                                                              .target
                                                                                                              .value,
                                                                                                          }
                                                                                                        : rx
                                                                                                  )
                                                                                                : spx.rcems,
                                                                                          }
                                                                                        : spx
                                                                                  )
                                                                                : px.subProcesses,
                                                                          }
                                                                        : px
                                                                  )
                                                                : dx.processes,
                                                          }
                                                        : dx
                                                  )
                                                : rcemDepts.departments,
                                            });
                                          }}
                                          defaultValue={r.name}
                                        />
                                      </div>
                                      <div>
                                        <Select
                                          options={ctx?.extDepartments}
                                          getOptionLabel={(d) => `${d.name}`}
                                          value={ctx?.extDepartments.find(
                                            (d) =>
                                              `${d.id}` ===
                                              `${r.extDepartmentId}`
                                          )}
                                          onChange={(v) => {
                                            const val = v as unknown as ExtDepartment;

                                            setRcemDepts({
                                              ...rcemDepts,
                                              departments: rcemDepts.departments
                                                ? rcemDepts.departments.map(
                                                    (dx) =>
                                                      dx &&
                                                      dx.masterJavaBaseModel
                                                        ?.uuid ===
                                                        selectedRcemDept
                                                          ?.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...dx,
                                                            processes:
                                                              dx.processes
                                                                ? dx.processes.map(
                                                                    (px) =>
                                                                      px &&
                                                                      px
                                                                        .masterJavaBaseModel
                                                                        ?.uuid ===
                                                                        p
                                                                          .masterJavaBaseModel
                                                                          ?.uuid
                                                                        ? {
                                                                            ...px,
                                                                            subProcesses:
                                                                              px.subProcesses
                                                                                ? px.subProcesses.map(
                                                                                    (
                                                                                      spx
                                                                                    ) =>
                                                                                      spx &&
                                                                                      spx
                                                                                        .masterJavaBaseModel
                                                                                        ?.uuid ===
                                                                                        sp
                                                                                          .masterJavaBaseModel
                                                                                          ?.uuid
                                                                                        ? {
                                                                                            ...spx,
                                                                                            rcems:
                                                                                              spx.rcems
                                                                                                ? spx.rcems.map(
                                                                                                    (
                                                                                                      rx
                                                                                                    ) =>
                                                                                                      rx &&
                                                                                                      rx
                                                                                                        .masterJavaBaseModel
                                                                                                        ?.uuid ===
                                                                                                        r
                                                                                                          .masterJavaBaseModel
                                                                                                          ?.uuid
                                                                                                        ? {
                                                                                                            ...rx,
                                                                                                            extDepartmentId:
                                                                                                              val.id
                                                                                                                ? `${val.id}`
                                                                                                                : undefined,
                                                                                                          }
                                                                                                        : rx
                                                                                                  )
                                                                                                : spx.rcems,
                                                                                          }
                                                                                        : spx
                                                                                  )
                                                                                : px.subProcesses,
                                                                          }
                                                                        : px
                                                                  )
                                                                : dx.processes,
                                                          }
                                                        : dx
                                                  )
                                                : rcemDepts.departments,
                                            });
                                          }}
                                        />
                                      </div>
                                      <hr className="border border-dark" />
                                    </li>
                                  );
                                })}
                              </ol>
                            </td>
                            <td className="border border-dark">
                              <input
                                style={{ width: 50 }}
                                className="form-control form-control-sm"
                                placeholder="Hours..."
                                onBlur={(e) => {
                                  setRcemDepts({
                                    ...rcemDepts,
                                    departments: rcemDepts.departments
                                      ? rcemDepts.departments.map((dx) =>
                                          dx &&
                                          dx.masterJavaBaseModel?.uuid ===
                                            selectedRcemDept
                                              ?.masterJavaBaseModel?.uuid
                                            ? {
                                                ...dx,
                                                processes: dx.processes
                                                  ? dx.processes.map((px) =>
                                                      px &&
                                                      px.masterJavaBaseModel
                                                        ?.uuid ===
                                                        p.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...px,
                                                            subProcesses:
                                                              px.subProcesses
                                                                ? px.subProcesses.map(
                                                                    (spx) =>
                                                                      spx &&
                                                                      spx
                                                                        .masterJavaBaseModel
                                                                        ?.uuid ===
                                                                        sp
                                                                          .masterJavaBaseModel
                                                                          ?.uuid
                                                                        ? {
                                                                            ...spx,
                                                                            timeProcess:
                                                                              isNaN(
                                                                                parseInt(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              )
                                                                                ? spx.timeProcess
                                                                                : parseInt(
                                                                                    e
                                                                                      .target
                                                                                      .value
                                                                                  ),
                                                                          }
                                                                        : spx
                                                                  )
                                                                : px.subProcesses,
                                                          }
                                                        : px
                                                    )
                                                  : dx.processes,
                                              }
                                            : dx
                                        )
                                      : rcemDepts.departments,
                                  });
                                }}
                                defaultValue={sp.timeProcess}
                              />
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 150 }}>
                                <strong>
                                  {sp.impact
                                    ? `${ppicRcemSubProcess_ImpactToJSON(
                                        sp.impact
                                      )} (${
                                        ppicRcemSubProcess_ImpactToNumber(
                                          sp.impact
                                        ) + 1
                                      })`
                                    : "None"}
                                </strong>
                                <Select
                                  onChange={(v) => {
                                    const val = (
                                      v as unknown as {
                                        value: PpicRcemSubProcess_Impact;
                                      }
                                    ).value;

                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              impact:
                                                                                val,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  options={(
                                    [
                                      PpicRcemSubProcess_Impact.UNRECOGNIZED,
                                      PpicRcemSubProcess_Impact.INSIGNIFICANT,
                                      PpicRcemSubProcess_Impact.SIGNIFICANT,
                                      PpicRcemSubProcess_Impact.CRITICAL,
                                      PpicRcemSubProcess_Impact.CATASTROPHIC,
                                    ] as PpicRcemSubProcess_Impact[]
                                  ).map((i) => ({
                                    label: ppicRcemSubProcess_ImpactToJSON(i),
                                    value: i,
                                  }))}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 150 }}>
                                <strong>
                                  {sp.likelihood
                                    ? `${ppicRcemSubProcess_LikelihoodToJSON(
                                        sp.likelihood
                                      )} (${
                                        ppicRcemSubProcess_LikelihoodToNumber(
                                          sp.likelihood
                                        ) + 1
                                      })`
                                    : "None"}
                                </strong>
                                <Select
                                  onChange={(v) => {
                                    const val = (
                                      v as unknown as {
                                        value: PpicRcemSubProcess_Likelihood;
                                      }
                                    ).value;

                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              likelihood:
                                                                                val,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  options={(
                                    [
                                      PpicRcemSubProcess_Likelihood.UNRECOGNIZED,
                                      PpicRcemSubProcess_Likelihood.RARE,
                                      PpicRcemSubProcess_Likelihood.OCCASSIONAL,
                                      PpicRcemSubProcess_Likelihood.LIKELY,
                                      PpicRcemSubProcess_Likelihood.FREQUENT,
                                    ] as PpicRcemSubProcess_Likelihood[]
                                  ).map((i) => ({
                                    label:
                                      ppicRcemSubProcess_LikelihoodToJSON(i),
                                    value: i,
                                  }))}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              {sp.impact && sp.likelihood
                                ? (ppicRcemSubProcess_ImpactToNumber(
                                    sp.impact
                                  ) +
                                    1) *
                                  (ppicRcemSubProcess_LikelihoodToNumber(
                                    sp.likelihood
                                  ) +
                                    1)
                                : "None"}
                            </td>
                            <td
                              className="border border-dark"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {sp.qualitativeRisk} (
                              {(() => {
                                if ((sp.qualitativeRisk ?? 0) < 4) {
                                  return "Low";
                                } else if ((sp.qualitativeRisk ?? 0) < 9) {
                                  return "High";
                                } else {
                                  return "Unacceptable";
                                }
                              })()}
                              )
                              <input
                                style={{ width: 50 }}
                                className="form-control form-control-sm"
                                placeholder="Risk..."
                                onBlur={(e) => {
                                  setRcemDepts({
                                    ...rcemDepts,
                                    departments: rcemDepts.departments
                                      ? rcemDepts.departments.map((dx) =>
                                          dx &&
                                          dx.masterJavaBaseModel?.uuid ===
                                            selectedRcemDept
                                              ?.masterJavaBaseModel?.uuid
                                            ? {
                                                ...dx,
                                                processes: dx.processes
                                                  ? dx.processes.map((px) =>
                                                      px &&
                                                      px.masterJavaBaseModel
                                                        ?.uuid ===
                                                        p.masterJavaBaseModel
                                                          ?.uuid
                                                        ? {
                                                            ...px,
                                                            subProcesses:
                                                              px.subProcesses
                                                                ? px.subProcesses.map(
                                                                    (spx) =>
                                                                      spx &&
                                                                      spx
                                                                        .masterJavaBaseModel
                                                                        ?.uuid ===
                                                                        sp
                                                                          .masterJavaBaseModel
                                                                          ?.uuid
                                                                        ? {
                                                                            ...spx,
                                                                            qualitativeRisk:
                                                                              isNaN(
                                                                                parseInt(
                                                                                  e
                                                                                    .target
                                                                                    .value
                                                                                )
                                                                              )
                                                                                ? spx.qualitativeRisk
                                                                                : (() => {
                                                                                    if (
                                                                                      parseInt(
                                                                                        e
                                                                                          .target
                                                                                          .value
                                                                                      ) >
                                                                                      16
                                                                                    ) {
                                                                                      return 16;
                                                                                    } else if (
                                                                                      parseInt(
                                                                                        e
                                                                                          .target
                                                                                          .value
                                                                                      ) <
                                                                                      1
                                                                                    ) {
                                                                                      return 1;
                                                                                    } else {
                                                                                      return parseInt(
                                                                                        e
                                                                                          .target
                                                                                          .value
                                                                                      );
                                                                                    }
                                                                                  })(),
                                                                          }
                                                                        : spx
                                                                  )
                                                                : px.subProcesses,
                                                          }
                                                        : px
                                                    )
                                                  : dx.processes,
                                              }
                                            : dx
                                        )
                                      : rcemDepts.departments,
                                  });
                                }}
                                defaultValue={sp.qualitativeRisk}
                              />
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              supplierStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.supplierStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              inputStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.inputStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              processStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.processStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              outputStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.outputStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              controlResponsibilityStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.controlResponsibilityStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              frequencyOfControlApplicationStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={
                                    sp.frequencyOfControlApplicationStr
                                  }
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              amStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.amStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 300 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              pdStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.pdStr}
                                />
                              </div>
                            </td>

                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              rNrEStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.rNrEStr}
                                />
                              </div>
                            </td>
                            <td className="border border-dark">
                              <div style={{ width: 200 }}>
                                <textarea
                                  className="form-control form-control-sm"
                                  placeholder="Name..."
                                  onBlur={(e) => {
                                    setRcemDepts({
                                      ...rcemDepts,
                                      departments: rcemDepts.departments
                                        ? rcemDepts.departments.map((dx) =>
                                            dx &&
                                            dx.masterJavaBaseModel?.uuid ===
                                              selectedRcemDept
                                                ?.masterJavaBaseModel?.uuid
                                              ? {
                                                  ...dx,
                                                  processes: dx.processes
                                                    ? dx.processes.map((px) =>
                                                        px &&
                                                        px.masterJavaBaseModel
                                                          ?.uuid ===
                                                          p.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...px,
                                                              subProcesses:
                                                                px.subProcesses
                                                                  ? px.subProcesses.map(
                                                                      (spx) =>
                                                                        spx &&
                                                                        spx
                                                                          .masterJavaBaseModel
                                                                          ?.uuid ===
                                                                          sp
                                                                            .masterJavaBaseModel
                                                                            ?.uuid
                                                                          ? {
                                                                              ...spx,
                                                                              controlComponentStr:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          : spx
                                                                    )
                                                                  : px.subProcesses,
                                                            }
                                                          : px
                                                      )
                                                    : dx.processes,
                                                }
                                              : dx
                                          )
                                        : rcemDepts.departments,
                                    });
                                  }}
                                  defaultValue={sp.controlComponentStr}
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                );
              });
            })()}
          </table>
        </div>
      </div>
    </>
  );
};

export default RcemPage;
