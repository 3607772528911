import { Add, Delete } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { FepLeveledView } from "../../../models/model";
import { v4 as uuidv4 } from "uuid";
import {
  defaultFepLeveledFepLeveledProgram,
  defaultFepLeveledFepLeveledProgramView,
  defaultFepLeveledPartView,
  defaultFepLeveledProgram,
  defaultFepLeveledView,
} from "../../../models/modelinitials";
import { secondsToHms } from "../../../helpers";
import { AppContext } from "../../../App";

const FepDocumentSimplifiedDetail = () => {
  const history = useHistory();
  const ctx = useContext(AppContext);
  const { id } = useParams() as { id: string };

  const [fepLeveled, setFepLeveled] = useState<FepLeveledView>({
    ...defaultFepLeveledView,
  });

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    try {
      if (!isNaN(parseInt(id))) {
        const resp = await fetch(
          `${process.env.REACT_APP_BASE_URL}/fepleveled-view/${id}`,
          { headers: { authorization: ctx?.apiKey ?? "" } }
        );

        if (resp.status !== 200) throw await resp.text();

        setFepLeveled(await resp.json());
      }
    } catch (e) {
      console.log("[error]", e);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex">
          <h5>FEP Documents (Simplified) Detail</h5>

          <button
            onClick={async () => {
              try {
                console.log("fep document leveled", fepLeveled);
                const resp = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/fepleveled-save`,
                  {
                    method: "post",
                    headers: {
                      authorization: ctx?.apiKey ?? "",
                      "content-type": "application/json",
                    },
                    body: JSON.stringify(fepLeveled),
                  }
                );

                history.push("/fepdocumentssimplified");
              } catch (e) {
                console.log("[error saving]", e);
              }
            }}
            className="btn btn-sm btn-primary mx-2"
          >
            Save
          </button>
        </div>
        <div className="my-2">
          <input
            defaultValue={fepLeveled.fepLeveled?.name ?? ""}
            className="form-control form-control-sm w-100"
            placeholder="FEP Name..."
            onBlur={(e) => {
              setFepLeveled({
                ...fepLeveled,
                fepLeveled: fepLeveled.fepLeveled
                  ? { ...fepLeveled.fepLeveled, name: e.target.value }
                  : fepLeveled.fepLeveled,
              });
            }}
          />
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <div>
            <button
              onClick={() => {
                setFepLeveled({
                  ...fepLeveled,
                  fepLeveledFepLeveledPrograms:
                    fepLeveled.fepLeveledFepLeveledPrograms
                      ? [
                          ...fepLeveled.fepLeveledFepLeveledPrograms,
                          {
                            ...defaultFepLeveledFepLeveledProgramView,
                            fepLeveledFepLeveledProgram: {
                              ...defaultFepLeveledFepLeveledProgram,
                              fepLeveledProgram: {
                                ...defaultFepLeveledProgram,
                                uuid: uuidv4(),
                              },
                            },
                          },
                        ]
                      : fepLeveled.fepLeveledFepLeveledPrograms,
                  fepLeveledParts: fepLeveled.fepLeveledParts
                    ? [
                        ...fepLeveled.fepLeveledParts,
                        { ...defaultFepLeveledPartView },
                      ]
                    : fepLeveled.fepLeveledParts,
                });
              }}
              className="btn btn-sm btn-primary"
            >
              <Add /> Add program
            </button>
          </div>
        </div>
        <div>
          <div
            className="overflow-auto border border-dark"
            style={{ resize: "vertical", height: "75vh" }}
          >
            <table
              className="table table-sm"
              style={{ borderCollapse: "separate" }}
            >
              <thead>
                <tr>
                  {[
                    `# (total ${fepLeveled.fepLeveledFepLeveledPrograms?.length})`,
                    "Program name",
                    "Area",
                    "Punch",
                    "x run",
                    "Punch total",
                    // "needed/panel",
                    // "needed total",
                    // "Actual Produced",
                    // "Balance",
                    "Bend",
                    "Weld/Grind",
                    "Coat",
                    "Action",
                  ].map((h) => (
                    <th
                      className="bg-dark text-light"
                      style={{ position: "sticky", top: 0 }}
                    >
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fepLeveled.fepLeveledFepLeveledPrograms?.map((p, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark">{i + 1}</td>
                        <td className="border border-dark">
                          <input
                            className="form-control form-control-sm w-100"
                            key={
                              p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.uuid
                            }
                            defaultValue={
                              p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.name ?? ""
                            }
                            onBlur={(e) => {
                              setFepLeveled({
                                ...fepLeveled,
                                fepLeveledFepLeveledPrograms:
                                  fepLeveled.fepLeveledFepLeveledPrograms
                                    ? fepLeveled.fepLeveledFepLeveledPrograms.map(
                                        (px, ix) =>
                                          ix === i
                                            ? {
                                                ...px,
                                                fepLeveledFepLeveledProgram:
                                                  px.fepLeveledFepLeveledProgram
                                                    ? {
                                                        ...px.fepLeveledFepLeveledProgram,
                                                        fepLeveledProgram: px
                                                          .fepLeveledFepLeveledProgram
                                                          .fepLeveledProgram
                                                          ? {
                                                              ...px
                                                                .fepLeveledFepLeveledProgram
                                                                .fepLeveledProgram,
                                                              name: e.target
                                                                .value,
                                                            }
                                                          : px
                                                              .fepLeveledFepLeveledProgram
                                                              .fepLeveledProgram,
                                                      }
                                                    : px.fepLeveledFepLeveledProgram,
                                              }
                                            : px
                                      )
                                    : fepLeveled.fepLeveledFepLeveledPrograms,
                              });
                            }}
                          />
                          {/* {
                            p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                              ?.name
                          } */}
                        </td>{" "}
                        <td className="border border-dark">
                          <div className="d-flex">
                            <input
                              style={{ width: 50 }}
                              className="form-control form-control-sm"
                              defaultValue={
                                p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                  ?.area ?? 0.0
                              }
                              onBlur={(e) => {
                                setFepLeveled({
                                  ...fepLeveled,
                                  fepLeveledFepLeveledPrograms:
                                    fepLeveled.fepLeveledFepLeveledPrograms
                                      ? fepLeveled.fepLeveledFepLeveledPrograms.map(
                                          (px) =>
                                            px.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid ===
                                            p.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid
                                              ? {
                                                  ...px,
                                                  fepLeveledFepLeveledProgram:
                                                    px.fepLeveledFepLeveledProgram
                                                      ? {
                                                          ...px.fepLeveledFepLeveledProgram,
                                                          fepLeveledProgram: px
                                                            .fepLeveledFepLeveledProgram
                                                            .fepLeveledProgram
                                                            ? {
                                                                ...px
                                                                  .fepLeveledFepLeveledProgram
                                                                  .fepLeveledProgram,
                                                                area: isNaN(
                                                                  parseFloat(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                )
                                                                  ? px
                                                                      .fepLeveledFepLeveledProgram
                                                                      .fepLeveledProgram
                                                                      .area
                                                                  : parseFloat(
                                                                      e.target
                                                                        .value
                                                                    ),
                                                              }
                                                            : px
                                                                .fepLeveledFepLeveledProgram
                                                                .fepLeveledProgram,
                                                        }
                                                      : px.fepLeveledFepLeveledProgram,
                                                }
                                              : px
                                        )
                                      : fepLeveled.fepLeveledFepLeveledPrograms,
                                });
                              }}
                            />{" "}
                            <div>m²</div>
                            {/* {p.fepLeveledFepLeveledProgram?.fepLeveledProgram?.area} */}
                          </div>
                        </td>
                        <td className="border border-dark">
                          <div className="d-flex">
                            <input
                              style={{ maxWidth: 50 }}
                              defaultValue={Math.floor(
                                (p.fepLeveledFepLeveledProgram
                                  ?.fepLeveledProgram?.time ?? 0) / 3600
                              )}
                              onBlur={(e) => {
                                setFepLeveled({
                                  ...fepLeveled,
                                  fepLeveledFepLeveledPrograms:
                                    fepLeveled.fepLeveledFepLeveledPrograms
                                      ? fepLeveled.fepLeveledFepLeveledPrograms?.map(
                                          (px) =>
                                            px.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid ===
                                            p.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid
                                              ? {
                                                  ...px,
                                                  fepLeveledFepLeveledProgram:
                                                    px.fepLeveledFepLeveledProgram
                                                      ? {
                                                          ...px.fepLeveledFepLeveledProgram,
                                                          fepLeveledProgram: px
                                                            .fepLeveledFepLeveledProgram
                                                            .fepLeveledProgram
                                                            ? {
                                                                ...px
                                                                  .fepLeveledFepLeveledProgram
                                                                  .fepLeveledProgram,
                                                                time: isNaN(
                                                                  parseInt(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                )
                                                                  ? px
                                                                      .fepLeveledFepLeveledProgram
                                                                      .fepLeveledProgram
                                                                      .time
                                                                  : parseInt(
                                                                      e.target
                                                                        .value
                                                                    ) *
                                                                      3600 +
                                                                    Math.floor(
                                                                      ((p
                                                                        .fepLeveledFepLeveledProgram
                                                                        ?.fepLeveledProgram
                                                                        ?.time ??
                                                                        0) %
                                                                        3600) -
                                                                        (((p
                                                                          .fepLeveledFepLeveledProgram
                                                                          ?.fepLeveledProgram
                                                                          ?.time ??
                                                                          0) %
                                                                          3600) %
                                                                          60)
                                                                    ) +
                                                                    ((p
                                                                      .fepLeveledFepLeveledProgram
                                                                      ?.fepLeveledProgram
                                                                      ?.time ??
                                                                      0) %
                                                                      60),
                                                              }
                                                            : px
                                                                .fepLeveledFepLeveledProgram
                                                                .fepLeveledProgram,
                                                        }
                                                      : px.fepLeveledFepLeveledProgram,
                                                }
                                              : px
                                        )
                                      : fepLeveled.fepLeveledFepLeveledPrograms,
                                });
                              }}
                            />
                            <input
                              style={{ maxWidth: 50 }}
                              defaultValue={Math.floor(
                                ((p.fepLeveledFepLeveledProgram
                                  ?.fepLeveledProgram?.time ?? 0) %
                                  3600) /
                                  60
                              )}
                              onBlur={(e) => {
                                setFepLeveled({
                                  ...fepLeveled,
                                  fepLeveledFepLeveledPrograms:
                                    fepLeveled.fepLeveledFepLeveledPrograms
                                      ? fepLeveled.fepLeveledFepLeveledPrograms?.map(
                                          (px) =>
                                            px.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid ===
                                            p.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid
                                              ? {
                                                  ...px,
                                                  fepLeveledFepLeveledProgram:
                                                    px.fepLeveledFepLeveledProgram
                                                      ? {
                                                          ...px.fepLeveledFepLeveledProgram,
                                                          fepLeveledProgram: px
                                                            .fepLeveledFepLeveledProgram
                                                            .fepLeveledProgram
                                                            ? {
                                                                ...px
                                                                  .fepLeveledFepLeveledProgram
                                                                  .fepLeveledProgram,
                                                                time: isNaN(
                                                                  parseInt(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                )
                                                                  ? px
                                                                      .fepLeveledFepLeveledProgram
                                                                      ?.fepLeveledProgram
                                                                      .time
                                                                  : Math.floor(
                                                                      p
                                                                        .fepLeveledFepLeveledProgram
                                                                        ?.fepLeveledProgram
                                                                        ?.time ??
                                                                        0
                                                                    ) -
                                                                    ((p
                                                                      .fepLeveledFepLeveledProgram
                                                                      ?.fepLeveledProgram
                                                                      ?.time ??
                                                                      0) %
                                                                      3600) +
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    ) *
                                                                      60 +
                                                                    ((p
                                                                      .fepLeveledFepLeveledProgram
                                                                      ?.fepLeveledProgram
                                                                      ?.time ??
                                                                      0) %
                                                                      60),
                                                              }
                                                            : px
                                                                .fepLeveledFepLeveledProgram
                                                                .fepLeveledProgram,
                                                        }
                                                      : px.fepLeveledFepLeveledProgram,
                                                }
                                              : px
                                        )
                                      : fepLeveled.fepLeveledFepLeveledPrograms,
                                });
                              }}
                            />
                            <input
                              style={{ maxWidth: 50 }}
                              defaultValue={Math.floor(
                                (p.fepLeveledFepLeveledProgram
                                  ?.fepLeveledProgram?.time ?? 0) % 60
                              )}
                              onBlur={(e) => {
                                setFepLeveled({
                                  ...fepLeveled,
                                  fepLeveledFepLeveledPrograms:
                                    fepLeveled.fepLeveledFepLeveledPrograms
                                      ? fepLeveled.fepLeveledFepLeveledPrograms?.map(
                                          (px) =>
                                            px.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid ===
                                            p.fepLeveledFepLeveledProgram
                                              ?.fepLeveledProgram?.uuid
                                              ? {
                                                  ...px,
                                                  fepLeveledFepLeveledProgram:
                                                    px.fepLeveledFepLeveledProgram
                                                      ? {
                                                          ...px.fepLeveledFepLeveledProgram,
                                                          fepLeveledProgram: px
                                                            .fepLeveledFepLeveledProgram
                                                            .fepLeveledProgram
                                                            ? {
                                                                ...px
                                                                  .fepLeveledFepLeveledProgram
                                                                  .fepLeveledProgram,
                                                                time: isNaN(
                                                                  parseInt(
                                                                    e.target
                                                                      .value
                                                                  )
                                                                )
                                                                  ? px
                                                                      .fepLeveledFepLeveledProgram
                                                                      ?.fepLeveledProgram
                                                                      .time
                                                                  : Math.floor(
                                                                      p
                                                                        .fepLeveledFepLeveledProgram
                                                                        ?.fepLeveledProgram
                                                                        ?.time ??
                                                                        0
                                                                    ) -
                                                                    ((p
                                                                      .fepLeveledFepLeveledProgram
                                                                      ?.fepLeveledProgram
                                                                      ?.time ??
                                                                      0) %
                                                                      3600) +
                                                                    Math.floor(
                                                                      ((p
                                                                        .fepLeveledFepLeveledProgram
                                                                        ?.fepLeveledProgram
                                                                        ?.time ??
                                                                        0) %
                                                                        3600) -
                                                                        (((p
                                                                          .fepLeveledFepLeveledProgram
                                                                          ?.fepLeveledProgram
                                                                          ?.time ??
                                                                          0) %
                                                                          3600) %
                                                                          60)
                                                                    ) +
                                                                    parseInt(
                                                                      e.target
                                                                        .value
                                                                    ),
                                                              }
                                                            : px
                                                                .fepLeveledFepLeveledProgram
                                                                .fepLeveledProgram,
                                                        }
                                                      : px.fepLeveledFepLeveledProgram,
                                                }
                                              : px
                                        )
                                      : fepLeveled.fepLeveledFepLeveledPrograms,
                                });
                              }}
                            />
                          </div>
                          {/* <small>
                            Seconds:{" "}
                            <strong>
                              {
                                p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                  ?.time
                              }
                            </strong>{" "}
                          </small> */}
                        </td>
                        <td className="border border-dark">
                          <input
                            className="form-control form-control-sm w-100"
                            key={
                              p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.uuid
                            }
                            defaultValue={
                              p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.runs ?? ""
                            }
                            style={{ maxWidth: 50 }}
                            onBlur={(e) => {
                              setFepLeveled({
                                ...fepLeveled,
                                fepLeveledFepLeveledPrograms:
                                  fepLeveled.fepLeveledFepLeveledPrograms
                                    ? fepLeveled.fepLeveledFepLeveledPrograms.map(
                                        (f, ix) =>
                                          ix === i
                                            ? {
                                                ...f,
                                                fepLeveledFepLeveledProgram:
                                                  f.fepLeveledFepLeveledProgram
                                                    ? {
                                                        ...f.fepLeveledFepLeveledProgram,
                                                        fepLeveledProgram: f
                                                          .fepLeveledFepLeveledProgram
                                                          .fepLeveledProgram
                                                          ? {
                                                              ...f
                                                                .fepLeveledFepLeveledProgram
                                                                .fepLeveledProgram,
                                                              runs: isNaN(
                                                                parseInt(
                                                                  e.target.value
                                                                )
                                                              )
                                                                ? f
                                                                    .fepLeveledFepLeveledProgram
                                                                    .fepLeveledProgram
                                                                    .runs
                                                                : parseInt(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                            }
                                                          : f
                                                              .fepLeveledFepLeveledProgram
                                                              .fepLeveledProgram,
                                                      }
                                                    : f.fepLeveledFepLeveledProgram,
                                              }
                                            : f
                                      )
                                    : fepLeveled.fepLeveledFepLeveledPrograms,
                              });
                            }}
                          />
                          {/* {
                            p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                              ?.runs
                          } */}
                        </td>{" "}
                        <td className="border border-dark">
                          {secondsToHms(
                            Math.floor(
                              (p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.time ?? 0) *
                                (p.fepLeveledFepLeveledProgram
                                  ?.fepLeveledProgram?.runs ?? 0)
                            )
                          )}
                        </td>
                        <td className="border border-dark">
                          {secondsToHms(
                            Math.floor(
                              ((p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.time ?? 0) *
                                (p.fepLeveledFepLeveledProgram
                                  ?.fepLeveledProgram?.runs ?? 0)) /
                                2
                            )
                          )}
                        </td>
                        <td className="border border-dark">
                          {secondsToHms(
                            Math.floor(
                              (p.fepLeveledFepLeveledProgram?.fepLeveledProgram
                                ?.time ?? 0) *
                                (p.fepLeveledFepLeveledProgram
                                  ?.fepLeveledProgram?.runs ?? 0) *
                                2
                            )
                          )}
                        </td>
                        <td className="border border-dark">
                          {secondsToHms(
                            Math.floor(5 * 3600) /
                              (fepLeveled.fepLeveledFepLeveledPrograms
                                ?.length ?? 1)
                          )}
                        </td>
                        <td className="border border-dark">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              setFepLeveled({
                                ...fepLeveled,
                                fepLeveledFepLeveledPrograms:
                                  fepLeveled.fepLeveledFepLeveledPrograms
                                    ? fepLeveled.fepLeveledFepLeveledPrograms.filter(
                                        (_, ix) => ix !== i
                                      )
                                    : fepLeveled.fepLeveledFepLeveledPrograms,
                              });
                            }}
                          >
                            <Delete />
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FepDocumentSimplifiedDetail;
