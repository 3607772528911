import React from 'react'
import { FepDocument } from '../../../models/model'
import StatefulCrudTable from '../../mini-components/StatefulCrudTable/StatefulCrudTable'

const FepDocumentPage = (props: any) => {
  const mapperFunc = (fepDocument: FepDocument) => {
    return {
      id: fepDocument.id,
      content: [ 
        fepDocument.name, 
        // `${fepDocument.version}`,
        fepDocument.createdAt ? fepDocument.createdAt : "",
        fepDocument.updatedAt ? fepDocument.updatedAt : "",
        fepDocument.createdBy ? fepDocument.createdBy.name :  ""
      ]
    }
  }

  return (
    <StatefulCrudTable<FepDocument>  
      url="fepdocuments"
      head={['Name', 'Created At', 'Updated At', 'Created By']}
      mapper={mapperFunc}
    />
  )
}

export default FepDocumentPage