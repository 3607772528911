import React, { useRef, useState } from "react";
import { useContext, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, Spinner } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { AppContext } from "../../../App";
import {
  extractItemDesc,
  fetchExtCrmPurchaseOrdersProto,
  fetchPartNumberCustomerMatchesProto,
  fetchPartNumberMatcheGroupsProto,
  fetchPartNumberMatchesProto,
  fetchWarehouseItemsProto,
  getItemFullDescription,
  getItemFullDescriptionProto,
  makeReadableDateString,
} from "../../../helpers";
import { v4 as uuidv4, v4 } from "uuid";
import {
  CrmAccount,
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  MasterJavaBaseModel,
  PpicPartNumberCustomerMatches,
  PpicPartNumberMatch,
  PpicPartNumberMatches,
  WarehouseItem,
  WarehouseItems,
  WarehouseItem_WarehouseItemType,
  warehouseItem_WarehouseItemTypeFromJSON,
  warehouseItem_WarehouseItemTypeToJSON,
  CrmCustomer,
  PpicPartNumberMatchGroup,
  PpicPartNumberMatchGroups,
} from "../../../masterbigsystem";
import { ExtItem, PartNumberMatch } from "../../../models/model";
import Select from "react-select";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  CloudDownload,
  Delete,
  Edit,
} from "@material-ui/icons";
import murmur from "murmurhash-js";

const PartNumbersPage = () => {
  const ctx = useContext(AppContext);

  type PartNumbersMatchFilter =
    | "Outstanding"
    | "Matched"
    | "All"
    | "ManyToMany"
    | "Checklist"
    | "Checklist Result";

  const [pos, setPos] = useState<CrmPurchaseOrders | null>(null);
  const [loadingPos, setLoadingPos] = useState(false);
  const [extPoProductIds, setExtPoProductIds] = useState<string[]>([]);
  const [items, setItems] = useState<WarehouseItems | null>(null);
  const [poFilterActive, setPoFilterActive] = useState(true);
  const [importProcess, setImportProcess] = useState(false);
  const [loadingPartNumberMatches, setLoadingPartNumberMatches] =
    useState(false);

  const matchGroups = useRef(
    PpicPartNumberMatchGroups.fromPartial({ groups: [] })
  );
  const [
    loadingPartNumberCustomerMatches,
    setLoadingPartNumberCustomerMatches,
  ] = useState(false);

  const [partNumberMatches, setPartNumberMatches] =
    useState<PpicPartNumberMatches | null>(null);

  const [partNumberMatchesFormer, setPartNumberMatchesFormer] =
    useState<PpicPartNumberMatches | null>(null);

  const [partNumberCustomerMatches, setPartNumberCustomerMatches] =
    useState<PpicPartNumberCustomerMatches | null>(null);

  const matchGroupDeleteIds = useRef([] as (string | undefined)[]);
  const [checklistResultSearch, setchecklistResultSearch] = useState("");
  const [checklistResultShowAll, setChecklistResultShowAll] = useState(false);
  const [selectedCrmAccount, setSelectedCrmAccount] =
    useState<CrmCustomer | null>(null);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<CrmPurchaseOrder | null>(null);
  const [openItemModal, setOpenItemModel] = useState(false);
  const [itemSearch, setItemSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState<WarehouseItem | null>(null);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [checklistItemModal, setChecklistItemModal] = useState(false);
  const [checklistItem, setChecklistItem] = useState<WarehouseItem | null>(
    null
  );

  const [partNumberMatchesDeleteIds, setPartNumberMatchesDeleteIds] = useState<
    string[]
  >([]);
  const [filters, setFilters] = useState({
    date: null as string | null,
    searchPo: "" as string,
  });
  const [selectedPartNumberMatch, setSelectedPartNumberMatch] =
    useState<PpicPartNumberMatch | null>(null);
  const [selectedPartNumberMatchType, setSelectedPartNumberMatchType] =
    useState<"Source" | "Destination">("Source");

  const [currentPartNumberMatchFilter, setCurrentPartNumberMatchFilter] =
    useState<PartNumbersMatchFilter>("Checklist");

  useEffect(() => {
    handleInit();
  }, []);

  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };

  const expandedPurchaseNumberIds = useRef<string[]>([]);

  const handleInit = async () => {
    fetchPosData();
    fetchItemsData();
    fetchPartNumberMatchesData();
    fetchPartNumberMatchesCustomerData();
    fetchPartNumberMatchGroupsData();
  };

  useEffect(() => {
    console.log("effect pnmatch", partNumberMatches);
  }, [partNumberMatches]);

  const fetchPosData = async () => {
    setPos(await fetchExtCrmPurchaseOrdersProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchItemsData = async () => {
    setItemsLoading(true);

    const itemsData = await fetchWarehouseItemsProto({
      apiKey: ctx?.apiKey ?? "",
    });
    console.log("[items]", itemsData);
    setItems(itemsData);
    setItemsLoading(false);
  };
  const fetchPartNumberMatchesData = async () => {
    setLoadingPartNumberMatches(true);
    const d = await fetchPartNumberMatchesProto({ apiKey: ctx?.apiKey ?? "" });

    console.log("match data", d);
    if ((d?.matches.length ?? 0) > 0) {
      setPartNumberMatches(structuredClone(d));
      setPartNumberMatchesFormer(structuredClone(d));
    }
    setLoadingPartNumberMatches(false);
  };

  const fetchPartNumberMatchesCustomerData = async () => {
    setLoadingPartNumberCustomerMatches(true);
    setPartNumberCustomerMatches(
      await fetchPartNumberCustomerMatchesProto({ apiKey: ctx?.apiKey ?? "" })
    );
    setLoadingPartNumberCustomerMatches(false);
  };

  const fetchPartNumberMatchGroupsData = async () => {
    const d = await fetchPartNumberMatcheGroupsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      matchGroups.current = d;
      render();
    }
  };

  // const customers = [
  //   ...new Set(
  //     pos?.purchaseOrders
  //       .map((p) => p.account?.id)
  //       .filter((p) => p)
  //       .map((p) => p as string)
  //   ),
  // ]
  //   .map((i) =>
  //     pos?.purchaseOrders.map((p) => p.account).find((a) => a?.id === i)
  //   )
  //   .filter((a) => a)
  //   .map((a) => a as CrmAccount);

  const posFiltered = pos?.purchaseOrders.filter(
    (p) => `${p.account?.id}` === `${selectedCrmAccount?.value}`
  );

  return (
    <>
      <div>
        <div className="d-flex align-items-center">
          <div className="d-flex">
            <div>
              <h4>Part Numbers Matching</h4>
            </div>
          </div>

          {itemsLoading ? (
            <>
              <Spinner animation="border" />
            </>
          ) : (
            <>
              <button
                className="btn btn-sm btn-primary"
                onClick={async () => {
                  try {
                    setItemsLoading(true);

                    const diff = partNumberMatches?.matches.filter((m) => {
                      const foundMatch = partNumberMatchesFormer?.matches.find(
                        (mx) =>
                          `${mx.masterJavaBaseModel?.uuid}` ===
                          `${m.masterJavaBaseModel?.uuid}`
                      );

                      return (
                        !foundMatch ||
                        (foundMatch &&
                          murmur.murmur3(JSON.stringify(m)) !==
                            murmur.murmur3(JSON.stringify(foundMatch)))
                      );
                    });

                    const resp = await fetch(
                      `${process.env.REACT_APP_BASE_URL}/partnumbermatches-proto-save`,
                      {
                        method: "post",
                        headers: {
                          "content-type": "application/json",
                          authorization: ctx?.apiKey ?? "",
                        },
                        body: partNumberMatches
                          ? JSON.stringify(
                              PpicPartNumberMatches.fromPartial({
                                matches: diff,
                              })
                            )
                          : null,
                      }
                    );

                    const respGroup = await fetch(
                      `${process.env.REACT_APP_BASE_URL}/partnumbermatchgroups-save-bulk`,
                      {
                        method: "post",
                        headers: {
                          "content-type": "application/json",
                          authorization: ctx?.apiKey ?? "",
                        },
                        body: matchGroups.current
                          ? JSON.stringify(
                              PpicPartNumberMatchGroups.toJSON(
                                matchGroups.current
                              )
                            )
                          : null,
                      }
                    );

                    await Promise.all([
                      ...partNumberMatchesDeleteIds.map(async (id) => {
                        try {
                          const resp = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/partnumbermatches/${id}/empty`,
                            {
                              method: "delete",
                              headers: { authorization: ctx?.apiKey ?? "" },
                            }
                          );
                        } catch (e) {}
                      }),
                    ]);

                    // matchgroup delete
                    await Promise.all([
                      ...matchGroupDeleteIds.current.map(async (id) => {
                        try {
                          const resp = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/partnumbermatchgroups/${id}`,
                            {
                              method: "delete",
                              headers: { authorization: ctx?.apiKey ?? "" },
                            }
                          );
                        } catch (e) {}
                      }),
                    ]);

                    if (resp.status !== 200) throw await resp.text();
                    window.location.reload();
                  } catch (e) {
                    alert("Save failed");
                  } finally {
                    setItemsLoading(false);
                  }
                }}
              >
                Save
              </button>
            </>
          )}

          <div className="ml-3">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/partnumbermatches-export-xlsx`}
              target="_blank"
            >
              <button className="btn btn-sm btn-success">
                <CloudDownload />
                XLSX
              </button>
            </a>
          </div>

          <div className="ml-3">
            {importProcess ? (
              <>Importing...</>
            ) : (
              <>
                {" "}
                <input
                  type="file"
                  onChange={(e) => {
                    const f = e.target.files?.[0];

                    if (f) {
                      const r = new FileReader();

                      r.onload = async (e) => {
                        const dataBase64 = (r.result as string).split(
                          ";base64,"
                        )?.[1];

                        try {
                          setImportProcess(true);
                          const resp = await fetch(
                            `${process.env.REACT_APP_BASE_URL}/partnumbermatches-import-xlsx`,
                            {
                              method: "post",
                              headers: {
                                authorization: ctx?.apiKey ?? "",
                                "content-type": "application/json",
                              },
                              body: JSON.stringify({ binary: dataBase64 }),
                            }
                          );

                          if (resp.status !== 200) throw await resp.text();

                          const d = await resp.json();
                          const pns = PpicPartNumberMatches.fromPartial(d);

                          setPartNumberMatches(pns);
                        } catch (e) {
                          console.error(e);
                        } finally {
                          setImportProcess(false);
                        }
                      };

                      r.onerror = (e) => {
                        console.error(e);
                      };
                      r.readAsDataURL(f);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>

        <hr />
        <div className="d-flex">
          <div className="flex-grow-1 mx-1">
            <div>
              <small>PO Number</small>
            </div>
            <input
              className="form-control form-control-sm"
              placeholder="Search PO..."
              onBlur={(e) => {
                setFilters({ ...filters, searchPo: e.target.value });
              }}
            />
          </div>
          <div className="flex-grow-1 mx-1">
            <div>
              <small>Date</small>
            </div>
            <input
              type="date"
              className="form-control form-control-sm"
              placeholder="Filter date..."
              onBlur={(e) => {
                setFilters({ ...filters, date: e.target.value });
              }}
            />
          </div>
        </div>

        {/* <div>
          <pre>
            <small>{JSON.stringify(partNumberMatches)}</small>
          </pre>
        </div> */}

        <div className="d-flex">
          {(
            [
              "Outstanding",
              "Matched",
              "All",
              // "ManyToMany",
              "Checklist",
              "Checklist Result",
            ] as PartNumbersMatchFilter[]
          ).map((f) => (
            <div>
              <button
                className={`btn btn-sm mx-2 ${
                  currentPartNumberMatchFilter === f
                    ? `btn-primary`
                    : `btn-outline-primary`
                }`}
                onClick={() => {
                  setCurrentPartNumberMatchFilter(f);
                }}
              >
                {f}
              </button>
            </div>
          ))}
        </div>

        {(() => {
          switch (currentPartNumberMatchFilter) {
            case "ManyToMany":
              return (
                <>
                  <div>
                    <div>Many to Many</div>
                    <hr />
                    <div
                      className="overflow-auto"
                      style={{ height: "75vh", resize: "vertical" }}
                    >
                      <table
                        className="table table-sm"
                        style={{ borderCollapse: "separate" }}
                      >
                        <tr>
                          {["#", "Source", "Destination"].map((h) => (
                            <>
                              <th className="bg-dark text-light">{h}</th>
                            </>
                          ))}
                        </tr>
                        {(() => {
                          const lst = [
                            ...new Set(
                              [
                                ...(partNumberMatches?.matches.map(
                                  (m) => m.source
                                ) ?? []),
                                ...(partNumberMatches?.matches.map(
                                  (m) => m.destination
                                ) ?? []),
                              ]
                                .filter((m) => m)
                                .map((m) => m as string) ?? []
                            ),
                          ];

                          lst.sort((a, b) =>
                            a.toLowerCase().localeCompare(b.toLowerCase() ?? "")
                          );

                          return lst;
                        })().map((i, i_) => {
                          const fItem = items?.items.find(
                            (ix) => `${ix.id}` === `${i}`
                          );
                          const twoWayLinks = [
                            ...new Set([
                              ...(
                                partNumberMatches?.matches.filter(
                                  (m) => `${m.source}` === i
                                ) ?? []
                              ).map((m) => m.destination),
                              ...(
                                partNumberMatches?.matches.filter(
                                  (m) => `${m.destination}` === i
                                ) ?? []
                              ).map((m) => m.source),
                            ]),
                          ]
                            .filter((m) => m)
                            .map((m) => m as string);
                          return (
                            <>
                              <tr>
                                <td className="border border-dark">{i_ + 1}</td>
                                <td className="border border-dark">
                                  <div>
                                    <strong>
                                      {fItem?.partNum}:{fItem?.partName}
                                    </strong>
                                  </div>
                                  <div>
                                    <small>{fItem?.partDesc}</small>
                                  </div>
                                </td>
                                <td className="border border-dark">
                                  <ol>
                                    {twoWayLinks.map((j) => {
                                      const fItem = items?.items.find(
                                        (jx) => `${jx.id}` === `${j}`
                                      );

                                      return (
                                        <>
                                          <li>
                                            <div
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                console.log(
                                                  "deleting link:",
                                                  i,
                                                  "vs",
                                                  j
                                                );
                                                const foundMatches =
                                                  partNumberMatches?.matches.filter(
                                                    (m) =>
                                                      (`${m.source}` ===
                                                        `${i}` &&
                                                        `${m.destination}` ===
                                                          `${j}`) ||
                                                      (`${m.destination}` ===
                                                        `${i}` &&
                                                        `${m.source}` ===
                                                          `${j}`)
                                                  );
                                                setPartNumberMatchesDeleteIds([
                                                  ...partNumberMatchesDeleteIds,
                                                  ...(foundMatches?.map(
                                                    (m) =>
                                                      m.masterJavaBaseModel
                                                        ?.id ?? ""
                                                  ) ?? []),
                                                ]);
                                                setPartNumberMatches({
                                                  ...partNumberMatches,
                                                  matches:
                                                    partNumberMatches?.matches.filter(
                                                      (m) =>
                                                        !foundMatches
                                                          ?.map(
                                                            (mx) =>
                                                              mx
                                                                .masterJavaBaseModel
                                                                ?.uuid
                                                          )
                                                          .includes(
                                                            m
                                                              .masterJavaBaseModel
                                                              ?.uuid
                                                          )
                                                    ) ?? [],
                                                });
                                              }}
                                            >
                                              <div>
                                                <strong>
                                                  {fItem?.partNum}:
                                                  {fItem?.partName}
                                                </strong>
                                              </div>
                                              <div>
                                                <small>{fItem?.partDesc}</small>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ol>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </>
              );
            case "Checklist":
              return (
                <>
                  <div className="d-flex">
                    {itemsLoading ? (
                      <>
                        <div>
                          <div>
                            <Spinner animation="border" />
                          </div>
                          <div>
                            <small>Items loading...</small>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {loadingPos ? (
                      <>
                        <div>
                          <div>
                            <Spinner animation="border" />
                          </div>
                          <div>
                            <small>POs loading...</small>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <h4>Part Numbers</h4>
                  </div>
                  <hr className="border border-dark" />
                  <div>
                    <small>
                      <strong>Customer</strong>
                    </small>
                  </div>
                  <div>
                    <Select
                      options={ctx?.extCrmCustomers.customers ?? []}
                      onChange={(v) => {
                        const val = v as unknown as CrmCustomer;
                        setSelectedCrmAccount(val);
                      }}
                      getOptionLabel={(c) => `${c.text}`}
                      placeholder="Customer..."
                    />
                  </div>
                  {selectedCrmAccount ? (
                    <>
                      {" "}
                      <div>
                        <small>
                          <strong>PO</strong>
                        </small>
                      </div>
                      <div>
                        <Select
                          options={posFiltered}
                          onChange={(v) => {
                            const val = v as unknown as CrmPurchaseOrder;
                            setSelectedPurchaseOrder(val);
                          }}
                          getOptionLabel={(p) => `${p.purchaseOrderNumber}`}
                          placeholder="PO Number..."
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <>
                    <div
                      className="overflow-auto border border-dark shadow shadow-md"
                      style={{ height: "40vh", resize: "vertical" }}
                    >
                      <table
                        className="table table-sm table-hover"
                        style={{ borderCollapse: "separate" }}
                      >
                        {["#", "Check", "Item", "Matched"].map((h) => (
                          <>
                            <th
                              className="bg-dark text-light "
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h === "Check" ? (
                                <>
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (extPoProductIds.length === 0) {
                                        setExtPoProductIds(
                                          pos?.purchaseOrders
                                            .find(
                                              (p) =>
                                                `${p.id}` ===
                                                `${selectedPurchaseOrder?.id}`
                                            )
                                            ?.products.map((p) => p.productId)
                                            .filter((i) => i)
                                            .map((i) => i as string) ?? []
                                        );
                                      } else {
                                        setExtPoProductIds([]);
                                      }
                                    }}
                                  >
                                    {h}
                                  </div>
                                </>
                              ) : (
                                <>{h}</>
                              )}
                            </th>
                          </>
                        ))}
                        {(() => {
                          const foundPo = pos?.purchaseOrders.find(
                            (p) => `${p.id}` === `${selectedPurchaseOrder?.id}`
                          );

                          return (
                            <>
                              {foundPo?.products.map((p, i_) => {
                                const foundItem = items?.items.find(
                                  (i) => `${i.id}` === `${p.productId}`
                                );

                                const foundMatch =
                                  partNumberMatches?.matches.find(
                                    (m) =>
                                      `${m.extPurchaseOrderId}` ===
                                        `${foundPo.id}` &&
                                      `${m.source}` === `${p.productId}`
                                  );

                                return (
                                  <>
                                    <tr
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (p.productId) {
                                          if (
                                            extPoProductIds.find(
                                              (i) => `${i}` === `${p.productId}`
                                            )
                                          ) {
                                            setExtPoProductIds(
                                              extPoProductIds.filter(
                                                (i) =>
                                                  `${i}` !== `${p.productId}`
                                              )
                                            );
                                          } else {
                                            setExtPoProductIds([
                                              ...extPoProductIds,
                                              p.productId,
                                            ]);
                                          }
                                        }
                                      }}
                                    >
                                      <td className="border border-dark">
                                        {i_ + 1}
                                      </td>
                                      <td className="border border-dark">
                                        <input
                                          type="checkbox"
                                          checked={
                                            extPoProductIds.find(
                                              (i) => `${i}` === `${p.productId}`
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                      </td>
                                      <td className="border border-dark">
                                        <div>
                                          <div>
                                            (#{foundItem?.id}){" "}
                                            {foundItem?.partNum}:{" "}
                                            {foundItem?.partName}
                                          </div>
                                          <div>
                                            <small>{foundItem?.partDesc}</small>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        className={`border border-dark ${
                                          foundMatch
                                            ? `bg-success`
                                            : `bg-warning`
                                        }`}
                                      ></td>
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          );
                        })()}
                      </table>
                    </div>
                    <div>
                      <small>
                        <strong></strong>
                      </small>
                    </div>
                    <div className="d-flex">
                      <div className="mx-2">
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setChecklistItemModal(true);
                          }}
                        >
                          Select Item
                        </button>
                      </div>
                    </div>

                    <hr className="border border-dark" />

                    {checklistItem ? (
                      <>
                        <div>
                          <div>
                            {getItemFullDescriptionProto(checklistItem)}
                          </div>
                          <div className="mx-2">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                console.log(
                                  "selected po",
                                  selectedPurchaseOrder
                                );
                                const currentPartNumbers = [
                                  ...new Set([
                                    ...(
                                      partNumberMatches?.matches.filter(
                                        (m) =>
                                          `${m.destination}` ===
                                            `${checklistItem.id}` &&
                                          `${m.extPurchaseOrderId}` ===
                                            `${selectedPurchaseOrder?.id}`
                                      ) ?? []
                                    ).map((p) => p.source),
                                  ]),
                                ];

                                console.log(
                                  "current pn",
                                  currentPartNumbers,
                                  "ext po product ids",
                                  extPoProductIds
                                );
                                const newItems = extPoProductIds.filter(
                                  (i) =>
                                    !currentPartNumbers.find(
                                      (n) => `${n}` === `${i}`
                                    )
                                );

                                window.alert(
                                  `Detected new items: ${
                                    newItems?.length
                                  }\n${newItems
                                    .map(
                                      (i, i_) =>
                                        `${
                                          i_ + 1
                                        }. ${getItemFullDescriptionProto(
                                          items?.items.find(
                                            (ix) => `${ix.id}` === `${i}`
                                          )
                                        )}`
                                    )
                                    .join("\n")}`
                                );

                                if (partNumberMatches) {
                                  setPartNumberMatches({
                                    ...partNumberMatches,
                                    matches: [
                                      ...partNumberMatches.matches,
                                      ...newItems.map((i) =>
                                        PpicPartNumberMatch.fromPartial({
                                          destination: checklistItem.id,
                                          source: i,
                                          extPurchaseOrderId:
                                            selectedPurchaseOrder?.id
                                              ? `${selectedPurchaseOrder?.id}`
                                              : undefined,
                                          masterJavaBaseModel:
                                            MasterJavaBaseModel.fromPartial({
                                              uuid: v4(),
                                            }),
                                        })
                                      ),
                                    ],
                                  });
                                }
                              }}
                            >
                              Add to List
                            </button>
                          </div>
                          <div
                            className="overflow-auto"
                            style={{ height: "50vh", resize: "vertical" }}
                          >
                            <table
                              className="table table-sm"
                              style={{ borderCollapse: "separate" }}
                            >
                              <tr>
                                {[
                                  "#",
                                  "Purchase Order",
                                  "Item",
                                  "Qty",
                                  "Group Qty",
                                ].map((h) => (
                                  <th
                                    className="bg-dark text-light"
                                    style={{ position: "sticky", top: 0 }}
                                  >
                                    {h}
                                  </th>
                                ))}
                              </tr>
                              {(
                                partNumberMatches?.matches.filter(
                                  (m) =>
                                    `${m.destination}` === `${checklistItem.id}`
                                ) ?? []
                              ).map((m, i_) => {
                                const foundItem = items?.items.find(
                                  (ix) => `${ix.id}` === `${m.source}`
                                );

                                const foundPo = pos?.purchaseOrders.find(
                                  (p) => `${p.id}` === `${m.extPurchaseOrderId}`
                                );
                                return (
                                  <>
                                    <tr>
                                      <td className="border border-dark">
                                        {i_ + 1}
                                      </td>
                                      <td className="border border-dark">
                                        {foundPo ? (
                                          <>
                                            {foundPo?.purchaseOrderNumber} (
                                            {foundPo?.account?.name})
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className="border border-dark">
                                        {getItemFullDescriptionProto(foundItem)}
                                      </td>

                                      <td className="border border-dark">
                                        <div className="d-flex">
                                          {/* {m.masterJavaBaseModel?.uuid} */}
                                          <input
                                            key={`qty-id-${m.masterJavaBaseModel?.uuid}`}
                                            className="form-control form-control-sm"
                                            placeholder="Qty..."
                                            defaultValue={m?.qty}
                                            style={{ width: 75 }}
                                            onBlur={(e) => {
                                              if (partNumberMatches) {
                                                setPartNumberMatches({
                                                  ...partNumberMatches,
                                                  matches:
                                                    partNumberMatches.matches.map(
                                                      (mx) =>
                                                        mx.masterJavaBaseModel
                                                          ?.uuid &&
                                                        m.masterJavaBaseModel
                                                          ?.uuid &&
                                                        mx.masterJavaBaseModel
                                                          ?.uuid ===
                                                          m.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...mx,
                                                              qty: isNaN(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              )
                                                                ? mx.qty
                                                                : parseFloat(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                            }
                                                          : mx
                                                    ),
                                                });
                                              }
                                            }}
                                          />
                                          <div>{m?.qty}</div>
                                        </div>
                                      </td>

                                      <td className="border border-dark">
                                        <div className="d-flex">
                                          {/* {m.masterJavaBaseModel?.uuid} */}
                                          <input
                                            key={`groupqty-id-${m.masterJavaBaseModel?.uuid}`}
                                            className="form-control form-control-sm"
                                            placeholder="Qty..."
                                            defaultValue={m?.groupQty}
                                            style={{ width: 75 }}
                                            onBlur={(e) => {
                                              if (partNumberMatches) {
                                                setPartNumberMatches({
                                                  ...partNumberMatches,
                                                  matches:
                                                    partNumberMatches.matches.map(
                                                      (mx) =>
                                                        mx.masterJavaBaseModel
                                                          ?.uuid &&
                                                        m.masterJavaBaseModel
                                                          ?.uuid &&
                                                        mx.masterJavaBaseModel
                                                          ?.uuid ===
                                                          m.masterJavaBaseModel
                                                            ?.uuid
                                                          ? {
                                                              ...mx,
                                                              groupQty: isNaN(
                                                                parseFloat(
                                                                  e.target.value
                                                                )
                                                              )
                                                                ? mx.groupQty
                                                                : parseFloat(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                            }
                                                          : mx
                                                    ),
                                                });
                                              }
                                            }}
                                          />
                                          <div>{m?.groupQty}</div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </>
              );
            case "Checklist Result":
              return (
                <>
                  <div>
                    <input
                      className="form-control form-control-sm"
                      placeholder="Checklist result search..."
                      defaultValue={checklistResultSearch}
                      onBlur={(e) => {
                        setchecklistResultSearch(e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-between my-2">
                    <div className="d-flex">
                      <div>
                        <small>
                          Show all? (total {partNumberMatches?.matches.length})
                        </small>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          checked={checklistResultShowAll}
                          onClick={() => {
                            setChecklistResultShowAll(!checklistResultShowAll);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="btn btn-sm btn-primary px-1 py-0"
                        onClick={() => {
                          setSelectedPartNumberMatch(
                            PpicPartNumberMatch.fromPartial({
                              masterJavaBaseModel:
                                MasterJavaBaseModel.fromPartial({ uuid: v4() }),
                            })
                          );
                        }}
                      >
                        <Add /> Add New
                      </button>
                    </div>
                  </div>
                  <div
                    className="border border-dark shadow shadow-md overflow-auto"
                    style={{ height: "65vh", resize: "vertical" }}
                  >
                    <table
                      className="table table-sm table-hover"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {[
                          "Action",
                          "#",
                          "Purchase Order",
                          "Destination (selected item)",
                          "Source (item in PO)",
                          "Qty",
                        ].map((h) => (
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        ))}
                      </tr>
                      {
                        // [
                        //   ...new Set(
                        //     partNumberMatches?.matches.map(
                        //       (m) => m.destination
                        //     ) ?? []
                        //   ),
                        // ]
                        //   .map((d) =>
                        //     partNumberMatches?.matches.filter(
                        //       (m) => `${m.destination}` === `${d}`
                        //     )
                        //   )
                        //   .flat()
                        partNumberMatches?.matches
                          .map((m) => {
                            const getFoundMatchItemSrc = () =>
                              items?.items.find(
                                (i) => `${i.id}` === `${m?.source}`
                              );
                            const getFoundMatchItemDest = () =>
                              items?.items.find(
                                (i) => `${i.id}` === `${m?.destination}`
                              );

                            if (
                              checklistResultSearch === "" &&
                              filters.searchPo === ""
                            ) {
                              return checklistResultShowAll
                                ? {
                                    sourceItem: getFoundMatchItemSrc(),
                                    destItem: getFoundMatchItemDest(),
                                    match: m,
                                  }
                                : null;
                            }

                            const foundMatchItemSrc = getFoundMatchItemSrc();
                            const foundMatchItemDest = getFoundMatchItemDest();

                            const foundPo = m.extPurchaseOrderId
                              ? pos?.purchaseOrders.find(
                                  (p) => `${p.id}` === `${m.extPurchaseOrderId}`
                                )
                              : null;
                            return (checklistResultSearch === ""
                              ? true
                              : checklistResultSearch
                                  .toLowerCase()
                                  .split(" ")
                                  .filter((s) =>
                                    `${extractItemDesc(foundMatchItemSrc, {
                                      skipLowercase: true,
                                    })} ${extractItemDesc(foundMatchItemDest, {
                                      skipLowercase: true,
                                    })}`.includes(s)
                                  ).length ===
                                checklistResultSearch.toLowerCase().split(" ")
                                  .length) &&
                              (filters.searchPo === ""
                                ? true
                                : `${foundPo?.purchaseOrderNumber?.toLowerCase()}`.includes(
                                    filters.searchPo.toLowerCase()
                                  ))
                              ? {
                                  sourceItem: foundMatchItemSrc,
                                  destItem: foundMatchItemDest,
                                  match: m,
                                }
                              : null;
                          })
                          .filter(
                            (d) =>
                              d as {
                                sourceItem: WarehouseItem | undefined;
                                destItem: WarehouseItem | undefined;
                                match: PpicPartNumberMatch;
                              }
                          )
                          .map((m, i) => {
                            const foundPo = pos?.purchaseOrders.find(
                              (p) =>
                                `${p.id}` === `${m?.match?.extPurchaseOrderId}`
                            );

                            return (
                              <>
                                <>
                                  <tr>
                                    <td className="border border-dark">
                                      <div className="d-flex">
                                        <button
                                          className="btn btn-sm btn-danger px-1 py-0"
                                          onClick={() => {
                                            if (partNumberMatches) {
                                              setPartNumberMatches({
                                                ...partNumberMatches,
                                                matches:
                                                  partNumberMatches.matches.filter(
                                                    (mx) =>
                                                      `${mx.masterJavaBaseModel?.id}` !==
                                                      `${m?.match?.masterJavaBaseModel?.id}`
                                                  ),
                                              });
                                            }
                                            setPartNumberMatchesDeleteIds([
                                              ...partNumberMatchesDeleteIds,
                                              m?.match?.masterJavaBaseModel
                                                ?.id ?? "",
                                            ]);
                                          }}
                                        >
                                          <Delete />
                                        </button>

                                        <button
                                          className="btn btn-sm btn-primary px-1 py-0"
                                          onClick={() => {
                                            if (partNumberMatches && m?.match) {
                                              setSelectedPartNumberMatch(
                                                m?.match
                                              );
                                            }
                                          }}
                                        >
                                          <Edit />
                                        </button>
                                      </div>
                                    </td>
                                    <td className="border border-dark">
                                      {i + 1}
                                    </td>
                                    <td className="border border-dark">
                                      {foundPo ? (
                                        <>
                                          {" "}
                                          (#{foundPo.id}){" "}
                                          {foundPo?.purchaseOrderNumber} (
                                          {foundPo?.account?.name})
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className="border border-dark">
                                      {getItemFullDescriptionProto(m?.destItem)}
                                    </td>
                                    <td className="border border-dark">
                                      {" "}
                                      {getItemFullDescriptionProto(
                                        m?.sourceItem
                                      )}
                                    </td>
                                    <td className="border border-dark">
                                      {" "}
                                      {m?.match.qty}
                                    </td>
                                  </tr>
                                </>
                              </>
                            );
                          })

                        // .map((d) => {
                        //   const foundMatch = partNumberMatches?.matches.find(
                        //     (m) => `${m.destination}` === `${d}`
                        //   );
                        //   const foundMatches = partNumberMatches?.matches.filter(
                        //     (m) => `${m.destination}` === `${d}`
                        //   );

                        //   const foundMatchItemDest = items?.items.find(
                        //     (i) => `${i.id}` === `${d}`
                        //   );

                        //   return (
                        //     <>
                        //       {foundMatches?.map((m) => {
                        //         const foundMatchItemSrc = items?.items.find(
                        //           (i) => `${i.id}` === `${m.source}`
                        //         );

                        //         return (
                        //           <>
                        //             <tr>
                        //               <td className="border border-dark">
                        //                 {getItemFullDescriptionProto(
                        //                   foundMatchItemDest
                        //                 )}
                        //               </td>
                        //               <td className="border border-dark">
                        //                 {" "}
                        //                 {getItemFullDescriptionProto(
                        //                   foundMatchItemSrc
                        //                 )}
                        //               </td>
                        //               <td className="border border-dark">
                        //                 {" "}
                        //                 {m.qty}
                        //               </td>
                        //             </tr>
                        //           </>
                        //         );
                        //       })}
                        //     </>
                        //   );
                        // })
                      }
                    </table>
                  </div>
                </>
              );
            default:
              return (
                <>
                  <div className="d-flex">
                    PO filter active?{" "}
                    <input
                      type="checkbox"
                      checked={poFilterActive}
                      onClick={() => {
                        setPoFilterActive(!poFilterActive);
                      }}
                    />
                  </div>
                  <div
                    className="border border-dark shadow shadow-md overflow-auto"
                    style={{ height: "65vh", resize: "vertical" }}
                  >
                    <table
                      className="table table-sm"
                      style={{
                        borderCollapse: "separate",
                      }}
                    >
                      <tr>
                        {[
                          "#",
                          "PN",
                          "Part Name",
                          "Part Desc",
                          "MFR",

                          "Select Item",
                          "Match Items",
                          "Select Item Cust",
                          "Match Items Cust",

                          // "Match MFR",
                          // "Match Part Name",
                          // "Match Part Desc",
                          // "Match UM",
                        ].map((h) => (
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {h}
                          </th>
                        ))}
                      </tr>
                      {pos?.purchaseOrders
                        .filter(
                          (po) =>
                            // By name
                            (filters.searchPo !== ""
                              ? po.purchaseOrderNumber
                                  ?.toLowerCase()
                                  .includes(filters.searchPo.toLowerCase())
                              : true) &&
                            // By date
                            (filters.date ? po.date === filters.date : true) &&
                            (() => {
                              switch (currentPartNumberMatchFilter) {
                                case "All":
                                  return true;
                                case "Outstanding":
                                  return (
                                    (po.products.filter(
                                      (i) =>
                                        i.productId !== null &&
                                        i.productId !== undefined &&
                                        i.productId !== "0" &&
                                        (partNumberMatches?.matches.find(
                                          (pnm) =>
                                            `${pnm.source}` ===
                                              `${i.productId}` &&
                                            (poFilterActive
                                              ? pnm.extPurchaseOrderId &&
                                                `${pnm.extPurchaseOrderId}` ===
                                                  `${po.id}`
                                              : true)
                                        )?.destination ||
                                          partNumberMatches?.matches.find(
                                            (pnm) =>
                                              `${pnm.destination}` ===
                                                `${i.productId}` &&
                                              (poFilterActive
                                                ? pnm.extPurchaseOrderId &&
                                                  `${pnm.extPurchaseOrderId}` ===
                                                    `${po.id}`
                                                : true)
                                          )?.source)
                                    ).length !==
                                      po.products.length) ===
                                      true && po.products.length !== 0
                                  );
                                case "Matched":
                                  return (
                                    (po.products.filter(
                                      (i) =>
                                        i.productId !== null &&
                                        i.productId !== undefined &&
                                        i.productId !== "0" &&
                                        (partNumberMatches?.matches.find(
                                          (pnm) =>
                                            `${pnm.source}` ===
                                              `${i.productId}` &&
                                            (poFilterActive
                                              ? pnm.extPurchaseOrderId &&
                                                `${pnm.extPurchaseOrderId}` ===
                                                  `${po.id}`
                                              : true)
                                        )?.destination ||
                                          partNumberMatches?.matches.find(
                                            (pnm) =>
                                              `${pnm.destination}` ===
                                                `${i.productId}` &&
                                              (poFilterActive
                                                ? pnm.extPurchaseOrderId &&
                                                  `${pnm.extPurchaseOrderId}` ===
                                                    `${po.id}`
                                                : true)
                                          )?.source)
                                    ).length ===
                                      po.products.length) ===
                                      true && po.products.length !== 0
                                  );
                                default:
                                  return true;
                              }
                            })()
                        )
                        .map((po, i) => {
                          const matched = po.products.filter(
                            (i) =>
                              i.productId !== null &&
                              i.productId !== undefined &&
                              i.productId !== "0" &&
                              (partNumberMatches?.matches.find(
                                (pnm) =>
                                  `${pnm.source}` === `${i.productId}` &&
                                  (poFilterActive
                                    ? pnm.extPurchaseOrderId &&
                                      `${pnm.extPurchaseOrderId}` === `${po.id}`
                                    : true)
                              )?.destination ||
                                partNumberMatches?.matches.find(
                                  (pnm) =>
                                    `${pnm.destination}` === `${i.productId}` &&
                                    (poFilterActive
                                      ? pnm.extPurchaseOrderId &&
                                        `${pnm.extPurchaseOrderId}` ===
                                          `${po.id}`
                                      : true)
                                )?.source)
                          ).length;

                          const numProducts = po.products.length;

                          return (
                            <>
                              <tr>
                                <td colSpan={9}>
                                  {" "}
                                  <div className="d-flex">
                                    <strong>
                                      {i + 1}.{" "}
                                      {(() => {
                                        try {
                                          return makeReadableDateString(
                                            new Date(po.date ?? "")
                                          );
                                        } catch (e) {
                                          return "Invalid date";
                                        }
                                      })()}
                                    </strong>{" "}
                                    <div className="mx-2">
                                      {po.purchaseOrderNumber} (
                                      {po.account?.name})
                                    </div>
                                    <div
                                      className={`mx-1 ${
                                        matched < numProducts
                                          ? `text-danger`
                                          : `text-success`
                                      }`}
                                    >
                                      <strong>
                                        {matched}/{numProducts}
                                      </strong>
                                    </div>
                                    <div>
                                      <button
                                        onClick={() => {
                                          if (
                                            expandedPurchaseNumberIds.current.find(
                                              (i) => `${i}` === `${po.id}`
                                            )
                                          ) {
                                            expandedPurchaseNumberIds.current =
                                              expandedPurchaseNumberIds.current.filter(
                                                (i) => `${i}` !== `${po.id}`
                                              );
                                            render();
                                            return;
                                          }

                                          expandedPurchaseNumberIds.current.push(
                                            `${po.id}`
                                          );
                                          render();
                                        }}
                                        className="btn btn-sm btn-outline-primary px-2 py-0"
                                      >
                                        {expandedPurchaseNumberIds.current.find(
                                          (i) => `${i}` === `${po.id}`
                                        ) ? (
                                          <>
                                            <ArrowUpward />
                                          </>
                                        ) : (
                                          <>
                                            <ArrowDownward />
                                          </>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              {!expandedPurchaseNumberIds.current.find(
                                (i) => `${i}` === `${po.id}`
                              ) ? (
                                <></>
                              ) : (
                                po.products.map((p, j) => {
                                  const foundCustItem = items?.items.find(
                                    (i) => `${i.id}` === `${p.productId}`
                                  );

                                  return (
                                    <>
                                      <tr>
                                        <td className="border border-dark">
                                          {j + 1}
                                        </td>
                                        <td className="border border-dark">
                                          <div>
                                            <div>{foundCustItem?.partNum}</div>
                                            <div>
                                              <small>{p.partNumber}</small>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border border-dark">
                                          <div>
                                            <div>{foundCustItem?.partName}</div>
                                            <div>
                                              <small>{p.partName}</small>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border border-dark">
                                          <div>
                                            <div>
                                              (#{foundCustItem?.id}){" "}
                                              {foundCustItem?.partDesc}
                                            </div>
                                            <div>
                                              <small>{p.partDesc}</small>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="border border-dark">
                                          {foundCustItem?.mfr}
                                        </td>

                                        <td className="border border-dark">
                                          <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                              if (foundCustItem) {
                                                setOpenItemModel(true);
                                                setSelectedItem(foundCustItem);
                                                setSelectedPurchaseOrder(po);
                                                return;
                                              }

                                              alert(
                                                "Item not found in database."
                                              );
                                            }}
                                          >
                                            Select
                                          </button>
                                        </td>
                                        {(() => {
                                          const foundPartNumberMatches = [
                                            ...new Set([
                                              ...(partNumberMatches?.matches
                                                .filter(
                                                  (m) =>
                                                    `${m.source}` ===
                                                      `${p.productId}` &&
                                                    (poFilterActive
                                                      ? m.extPurchaseOrderId &&
                                                        `${m.extPurchaseOrderId}` ===
                                                          `${po.id}`
                                                      : true)
                                                )
                                                .map((m) => m.destination) ??
                                                []),
                                              ...(partNumberMatches?.matches
                                                .filter(
                                                  (m) =>
                                                    `${m.destination}` ===
                                                      `${p.productId}` &&
                                                    m.extPurchaseOrderId &&
                                                    (poFilterActive
                                                      ? m.extPurchaseOrderId &&
                                                        `${m.extPurchaseOrderId}` ===
                                                          `${po.id}`
                                                      : true)
                                                )
                                                .map((m) => m.source) ?? []),
                                            ]),
                                          ];

                                          return (
                                            <>
                                              <td
                                                className={`border border-dark ${
                                                  foundPartNumberMatches.length >
                                                  0
                                                    ? `bg-success`
                                                    : `bg-danger`
                                                } text-light`}
                                              >
                                                <div>
                                                  <ol>
                                                    {foundPartNumberMatches.map(
                                                      (id) => {
                                                        const foundItem =
                                                          items?.items.find(
                                                            (i) =>
                                                              `${i.id}` ===
                                                              `${id}`
                                                          );

                                                        const foundMatch =
                                                          partNumberMatches?.matches.find(
                                                            (m) =>
                                                              (`${m.source}` ===
                                                                `${id}` &&
                                                                `${m.destination}` ===
                                                                  `${p.productId}`) ||
                                                              (`${m.destination}` ===
                                                                `${id}` &&
                                                                `${m.source}` ===
                                                                  `${p.productId}`)
                                                          );

                                                        return (
                                                          <>
                                                            <li>
                                                              <div>
                                                                <div className="d-flex">
                                                                  <div
                                                                    className="flex-grow-1"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      <strong>
                                                                        {
                                                                          foundItem?.partNum
                                                                        }
                                                                        :
                                                                        {
                                                                          foundItem?.partName
                                                                        }
                                                                      </strong>
                                                                    </div>
                                                                    <div>
                                                                      <small>
                                                                        {
                                                                          foundItem?.partDesc
                                                                        }
                                                                      </small>
                                                                    </div>
                                                                  </div>
                                                                  <div>
                                                                    <button
                                                                      className="btn btn-sm btn-danger"
                                                                      onClick={() => {
                                                                        if (
                                                                          !window.confirm(
                                                                            "Really delete?"
                                                                          )
                                                                        ) {
                                                                          return;
                                                                        }

                                                                        setPartNumberMatchesDeleteIds(
                                                                          [
                                                                            ...partNumberMatchesDeleteIds,
                                                                            ...(partNumberMatches?.matches
                                                                              .filter(
                                                                                (
                                                                                  m
                                                                                ) =>
                                                                                  (`${m.source}` ===
                                                                                    `${id}` &&
                                                                                    `${m.destination}` ===
                                                                                      `${p.productId}`) ||
                                                                                  (`${m.destination}` ===
                                                                                    `${id}` &&
                                                                                    `${m.source}` ===
                                                                                      `${p.productId}`)
                                                                              )
                                                                              .map(
                                                                                (
                                                                                  m
                                                                                ) =>
                                                                                  m
                                                                                    .masterJavaBaseModel
                                                                                    ?.id ??
                                                                                  ""
                                                                              ) ??
                                                                              []),
                                                                          ]
                                                                        );
                                                                        setPartNumberMatches(
                                                                          {
                                                                            ...partNumberMatches,
                                                                            matches:
                                                                              partNumberMatches?.matches
                                                                                ? partNumberMatches.matches.filter(
                                                                                    (
                                                                                      m
                                                                                    ) =>
                                                                                      !(
                                                                                        (`${m.source}` ===
                                                                                          `${id}` &&
                                                                                          `${m.destination}` ===
                                                                                            `${p.productId}`) ||
                                                                                        (`${m.destination}` ===
                                                                                          `${id}` &&
                                                                                          `${m.source}` ===
                                                                                            `${p.productId}`)
                                                                                      )
                                                                                  )
                                                                                : partNumberMatches?.matches ??
                                                                                  [],
                                                                          }
                                                                        );
                                                                      }}
                                                                    >
                                                                      <Delete />
                                                                    </button>
                                                                  </div>

                                                                  <div className="mx-2">
                                                                    <div>
                                                                      <div>
                                                                        <strong className="text-light">
                                                                          SeqNum
                                                                        </strong>
                                                                      </div>

                                                                      <div className="d-flex">
                                                                        <input
                                                                          className="form-control form-control-sm"
                                                                          placeholder="Seq..."
                                                                          defaultValue={
                                                                            foundMatch?.sequenceNumber
                                                                          }
                                                                          key={`pnm-seq-${foundMatch?.masterJavaBaseModel?.uuid}`}
                                                                          style={{
                                                                            width: 50,
                                                                          }}
                                                                          onBlur={(
                                                                            e
                                                                          ) => {
                                                                            setPartNumberMatches(
                                                                              {
                                                                                ...partNumberMatches,
                                                                                matches:
                                                                                  partNumberMatches?.matches
                                                                                    ? partNumberMatches.matches.map(
                                                                                        (
                                                                                          m
                                                                                        ) =>
                                                                                          (`${m.source}` ===
                                                                                            `${id}` &&
                                                                                            `${m.destination}` ===
                                                                                              `${p.productId}`) ||
                                                                                          (`${m.destination}` ===
                                                                                            `${id}` &&
                                                                                            `${m.source}` ===
                                                                                              `${p.productId}`)
                                                                                            ? {
                                                                                                ...m,
                                                                                                sequenceNumber:
                                                                                                  isNaN(
                                                                                                    parseInt(
                                                                                                      e
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  )
                                                                                                    ? m.qty
                                                                                                    : parseInt(
                                                                                                        e
                                                                                                          .target
                                                                                                          .value
                                                                                                      ),
                                                                                              }
                                                                                            : m
                                                                                      )
                                                                                    : partNumberMatches?.matches ??
                                                                                      [],
                                                                              }
                                                                            );
                                                                          }}
                                                                        />{" "}
                                                                        {/* <div>
                                                                          {
                                                                            foundMatch?.sequenceNumber
                                                                          }
                                                                        </div> */}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {/* <div className="mx-2">
                                                                    {(() => {
                                                                      const foundMatchGroup =
                                                                        matchGroups.current.groups.find(
                                                                          (g) =>
                                                                            `${g.extItemId}` ===
                                                                              `${foundItem?.id}` &&
                                                                            `${g.extPurchaseOrderId}` ===
                                                                              `${po.id}`
                                                                        );

                                                                      return foundMatchGroup ? (
                                                                        <>
                                                                          <button
                                                                            className="btn btn-sm btn-info"
                                                                            onClick={() => {
                                                                              if (
                                                                                !window.confirm(
                                                                                  "Ungroup?"
                                                                                )
                                                                              ) {
                                                                                return;
                                                                              }

                                                                              matchGroupDeleteIds.current.push(
                                                                                foundMatchGroup
                                                                                  .masterJavaBaseModel
                                                                                  ?.id
                                                                              );
                                                                              matchGroups.current.groups =
                                                                                matchGroups.current.groups.filter(
                                                                                  (
                                                                                    g
                                                                                  ) =>
                                                                                    !(
                                                                                      `${g.extItemId}` ===
                                                                                        `${foundItem?.id}` &&
                                                                                      `${g.extPurchaseOrderId}` ===
                                                                                        `${po.id}`
                                                                                    )
                                                                                );

                                                                              render();
                                                                            }}
                                                                          >
                                                                            Group
                                                                          </button>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <button
                                                                            className="btn btn-sm btn-danger"
                                                                            onClick={() => {
                                                                              if (
                                                                                !window.confirm(
                                                                                  "Mark as group?"
                                                                                )
                                                                              ) {
                                                                                return;
                                                                              }
                                                                              matchGroups.current.groups.push(
                                                                                PpicPartNumberMatchGroup.fromPartial(
                                                                                  {
                                                                                    masterJavaBaseModel:
                                                                                      MasterJavaBaseModel.fromPartial(
                                                                                        {
                                                                                          uuid: v4(),
                                                                                        }
                                                                                      ),
                                                                                    extPurchaseOrderId:
                                                                                      po.id,
                                                                                    extItemId:
                                                                                      foundItem?.id,
                                                                                  }
                                                                                )
                                                                              );

                                                                              render();
                                                                            }}
                                                                          >
                                                                            NoGroup
                                                                          </button>
                                                                        </>
                                                                      );
                                                                    })()}
                                                                  </div> */}
                                                                  <div>
                                                                    <div>
                                                                      <strong className="text-light">
                                                                        Qty
                                                                      </strong>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                      <input
                                                                        className="form-control form-control-sm"
                                                                        placeholder="qty..."
                                                                        key={`pnm-qty-${foundMatch?.masterJavaBaseModel?.uuid}`}
                                                                        defaultValue={
                                                                          foundMatch?.qty
                                                                        }
                                                                        style={{
                                                                          width: 50,
                                                                        }}
                                                                        onBlur={(
                                                                          e
                                                                        ) => {
                                                                          setPartNumberMatches(
                                                                            {
                                                                              ...partNumberMatches,
                                                                              matches:
                                                                                partNumberMatches?.matches
                                                                                  ? partNumberMatches.matches.map(
                                                                                      (
                                                                                        m
                                                                                      ) =>
                                                                                        (`${m.source}` ===
                                                                                          `${id}` &&
                                                                                          `${m.destination}` ===
                                                                                            `${p.productId}`) ||
                                                                                        (`${m.destination}` ===
                                                                                          `${id}` &&
                                                                                          `${m.source}` ===
                                                                                            `${p.productId}`)
                                                                                          ? {
                                                                                              ...m,
                                                                                              qty: isNaN(
                                                                                                parseInt(
                                                                                                  e
                                                                                                    .target
                                                                                                    .value
                                                                                                )
                                                                                              )
                                                                                                ? m.qty
                                                                                                : parseInt(
                                                                                                    e
                                                                                                      .target
                                                                                                      .value
                                                                                                  ),
                                                                                            }
                                                                                          : m
                                                                                    )
                                                                                  : partNumberMatches?.matches ??
                                                                                    [],
                                                                            }
                                                                          );
                                                                        }}
                                                                      />{" "}
                                                                      {/* <div>
                                                                      {
                                                                        foundMatch?.qty
                                                                      }
                                                                    </div> */}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </li>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </ol>
                                                </div>
                                              </td>
                                              <td className="border border-dark">
                                                <button className="btn btn-sm btn-primary">
                                                  Select
                                                </button>
                                              </td>
                                              <td className="border border-dark"></td>
                                            </>
                                          );
                                        })()}
                                      </tr>
                                    </>
                                  );
                                })
                              )}
                            </>
                          );
                        })}
                    </table>
                  </div>
                </>
              );
          }
        })()}
      </div>

      <Modal
        show={openItemModal}
        onHide={() => {
          setOpenItemModel(false);
        }}
        size="xl"
      >
        <ModalHeader>Select GSPE PN for:</ModalHeader>
        <ModalBody>
          <div>
            <input
              className="form-control form-control-sm"
              placeholder="Search by PN, part name, part desc..."
              onBlur={(e) => {
                setItemSearch(e.target.value);
              }}
            />
            {itemsLoading ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                <div className="overflow-auto" style={{ height: "75vh" }}>
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {[
                        "Action",
                        "#",
                        "MFR",
                        "PN",
                        "Part Name",
                        "Part Desc",
                        "UM",
                      ].map((h) => (
                        <th
                          className="border border-dark bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      ))}
                    </tr>
                    {items?.items
                      ?.filter(
                        (i) =>
                          itemSearch !== "" &&
                          itemSearch
                            .split(" ")
                            .filter((shard) =>
                              `${i.partNum} ${i.partDesc} ${i.partName}`
                                .toLowerCase()
                                .includes(shard.toLowerCase())
                            ).length === itemSearch.split(" ").length
                      )
                      ?.map((i, i_) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    if (i.id && partNumberMatches) {
                                      const newPartNumberMatches = {
                                        ...partNumberMatches,
                                      };

                                      const foundPnMatch =
                                        newPartNumberMatches?.matches?.find(
                                          (m) =>
                                            `${m.source}` ===
                                              `${selectedItem?.id}` &&
                                            `${m.destination}` === `${i.id}`
                                        ) ||
                                        newPartNumberMatches?.matches?.find(
                                          (m) =>
                                            `${m.destination}` ===
                                              `${selectedItem?.id}` &&
                                            `${m.source}` === `${i.id}`
                                        );

                                      console.log(
                                        "[found selected item]",
                                        foundPnMatch
                                      );
                                      console.log("state", selectedItem);
                                      console.log("clicked", i.id);

                                      if (!foundPnMatch) {
                                        newPartNumberMatches.matches =
                                          newPartNumberMatches.matches
                                            ? [
                                                ...newPartNumberMatches.matches,
                                                PpicPartNumberMatch.fromPartial(
                                                  {
                                                    source: selectedItem?.id
                                                      ? `${selectedItem?.id}`
                                                      : undefined,
                                                    destination: `${i.id}`,
                                                    extPurchaseOrderId:
                                                      selectedPurchaseOrder?.id,
                                                    masterJavaBaseModel:
                                                      MasterJavaBaseModel.fromPartial(
                                                        { uuid: uuidv4() }
                                                      ),
                                                  }
                                                ),
                                              ]
                                            : newPartNumberMatches.matches;
                                      }
                                      setPartNumberMatches(
                                        newPartNumberMatches
                                      );
                                      setOpenItemModel(false);
                                    }
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                              <td>{i_ + 1}</td>
                              <td>{i.mfr}</td>
                              <td>{i.partNum}</td>
                              <td>{i.partName}</td>
                              <td>{i.partDesc}</td>
                            </tr>
                          </>
                        );
                      })}
                  </table>
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={checklistItemModal}
        onHide={() => {
          setChecklistItemModal(false);
        }}
        size="xl"
      >
        <ModalHeader>Select GSPE PN for:</ModalHeader>
        <ModalBody>
          <div>
            <input
              className="form-control form-control-sm"
              placeholder="Search by PN, part name, part desc..."
              onBlur={(e) => {
                setItemSearch(e.target.value);
              }}
            />
            {itemsLoading ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                <div className="overflow-auto" style={{ height: "75vh" }}>
                  <table
                    className="table table-sm"
                    style={{ borderCollapse: "separate" }}
                  >
                    <tr>
                      {[
                        "Action",
                        "#",
                        "ID",
                        "MFR",
                        "PN",
                        "Part Name",
                        "Part Desc",
                        "UM",
                        "Matched",
                      ].map((h) => (
                        <th
                          className="border border-dark bg-dark text-light"
                          style={{ position: "sticky", top: 0 }}
                        >
                          {h}
                        </th>
                      ))}
                    </tr>
                    {items?.items
                      ?.filter(
                        (i) =>
                          itemSearch !== "" &&
                          itemSearch
                            .split(" ")
                            .filter((shard) =>
                              `${i.partNum} ${i.partDesc} ${i.partName}`
                                .toLowerCase()
                                .includes(shard.toLowerCase())
                            ).length === itemSearch.split(" ").length
                      )
                      ?.map((i, i_) => {
                        const matches = partNumberMatches?.matches.filter(
                          (m) => `${m.destination}` === `${i.id}`
                        );

                        return (
                          <>
                            <tr>
                              <td className="border border-dark">
                                <button
                                  className="btn btn-sm btn-primary"
                                  onClick={() => {
                                    if (selectedPartNumberMatch) {
                                      if (
                                        selectedPartNumberMatchType ===
                                        "Destination"
                                      ) {
                                        setSelectedPartNumberMatch({
                                          ...selectedPartNumberMatch,
                                          destination: i.id,
                                        });
                                      }

                                      if (
                                        selectedPartNumberMatchType === "Source"
                                      ) {
                                        setSelectedPartNumberMatch({
                                          ...selectedPartNumberMatch,
                                          source: i.id,
                                        });
                                      }
                                      setChecklistItemModal(false);

                                      return;
                                    }
                                    setChecklistItem(i);
                                    setChecklistItemModal(false);
                                  }}
                                >
                                  Select
                                </button>
                              </td>
                              <td className="border border-dark">{i_ + 1}</td>
                              <td className="border border-dark">#{i.id}</td>

                              <td className="border border-dark">{i.mfr}</td>
                              <td className="border border-dark">
                                {i.partNum}
                              </td>
                              <td className="border border-dark">
                                {i.partName}
                              </td>
                              <td className="border border-dark">
                                {i.defaultUm}
                              </td>
                              <td className="border border-dark">
                                {i.partDesc}
                              </td>

                              <td className="border border-dark">
                                <small>
                                  <ol>
                                    {matches?.map((m) => {
                                      const foundItemMatch = items.items.find(
                                        (i) => `${i.id}` === `${m.source}`
                                      );
                                      return (
                                        <>
                                          <li>
                                            <div>
                                              <div>
                                                {foundItemMatch?.partNum}
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                                  </ol>
                                </small>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </table>
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        show={selectedPartNumberMatch ? true : false}
        onHide={() => {
          setSelectedPartNumberMatch(null);
        }}
        size="xl"
      >
        <ModalHeader>
          <h4>Match Detail</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              <strong>PO</strong>
            </div>
            <div>
              <Select
                options={pos?.purchaseOrders.map((p) => ({
                  label: `${p.purchaseOrderNumber} (${p.account?.name})`,
                  value: p,
                }))}
                placeholder="Select PO..."
                value={pos?.purchaseOrders
                  .map((p) => ({
                    label: `${p.purchaseOrderNumber} (${p.account?.name})`,
                    value: p,
                  }))
                  .find(
                    (p) =>
                      `${p.value.id}` ===
                      `${selectedPartNumberMatch?.extPurchaseOrderId}`
                  )}
                onChange={(v) => {
                  const val = v as { value: CrmPurchaseOrder };

                  if (selectedPartNumberMatch) {
                    setSelectedPartNumberMatch({
                      ...selectedPartNumberMatch,
                      extPurchaseOrderId: val.value.id,
                    });
                  }
                }}
              />
            </div>
            <div>
              <strong>Destination (selected item)</strong>
            </div>
            <div>
              {!selectedPartNumberMatch?.destination
                ? ""
                : extractItemDesc(
                    items?.items.find(
                      (i) =>
                        `${i.id}` === `${selectedPartNumberMatch?.destination}`
                    ),
                    {
                      skipLowercase: true,
                    }
                  )}
            </div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setChecklistItemModal(true);
                  setSelectedPartNumberMatchType("Destination");
                }}
              >
                Select
              </button>{" "}
            </div>
            <div>
              <strong>Source (item in PO)</strong>
            </div>
            <div>
              {" "}
              {!selectedPartNumberMatch?.source
                ? ""
                : extractItemDesc(
                    items?.items.find(
                      (i) => `${i.id}` === `${selectedPartNumberMatch?.source}`
                    ),
                    {
                      skipLowercase: true,
                    }
                  )}
            </div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  setChecklistItemModal(true);
                  setSelectedPartNumberMatchType("Source");
                }}
              >
                Select
              </button>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              const foundPNM = partNumberMatches?.matches.find(
                (mx) =>
                  selectedPartNumberMatch?.masterJavaBaseModel?.uuid &&
                  mx.masterJavaBaseModel?.uuid &&
                  `${selectedPartNumberMatch?.masterJavaBaseModel?.uuid}` ===
                    `${mx.masterJavaBaseModel?.uuid}`
              );

              if (foundPNM && selectedPartNumberMatch) {
                console.log("pnm found");
                setPartNumberMatches({
                  ...partNumberMatches,
                  matches:
                    partNumberMatches?.matches.map((mx) =>
                      selectedPartNumberMatch?.masterJavaBaseModel?.uuid &&
                      mx.masterJavaBaseModel?.uuid &&
                      `${selectedPartNumberMatch?.masterJavaBaseModel?.uuid}` ===
                        `${mx.masterJavaBaseModel?.uuid}`
                        ? selectedPartNumberMatch
                        : mx
                    ) ?? [],
                });

                return;
              }
              console.log("pnm not found");

              if (partNumberMatches && selectedPartNumberMatch) {
                setPartNumberMatches({
                  ...partNumberMatches,
                  matches: [
                    ...partNumberMatches.matches,
                    selectedPartNumberMatch,
                  ],
                });
              }

              setSelectedPartNumberMatch(null);
            }}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default PartNumbersPage;
