import { FileCopy } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { AppContext } from "../../App";
import {
    fetchPanelTemplate,
    fetchPanelTemplateMechanicalProcessTypeBlacklists,
    fetchPanelTemplateMechanicalProcessTypes,
    fetchPanelTemplateProcessTypeGeneralBlacklists,
    fetchPanelTemplateProcessTypeGeneralItemBlacklists,
    fetchPanelTemplateProcessTypeGeneralItems,
    fetchPanelTemplateProcessTypeGeneralItemSubProcessBlacklists,
    fetchPanelTemplateProcessTypeGeneralItemSubProcesses,
    fetchPanelTemplateProcessTypeGenerals,
    fetchProcessTypeGeneralViews
} from "../../helpers";
import {
    PanelTemplate,
    PanelTemplateMechanicalProcessType,
    PanelTemplateMechanicalProcessTypeBlacklist,
    PanelTemplateProcessTypeGeneral,
    PanelTemplateProcessTypeGeneralBlacklist,
    PanelTemplateProcessTypeGeneralItem,
    PanelTemplateProcessTypeGeneralItemBlacklist,
    PanelTemplateProcessTypeGeneralItemSubProcess,
    PanelTemplateProcessTypeGeneralItemSubProcessBlacklist, ProcessTypeGeneralItemSubProcessView,
    ProcessTypeGeneralItemView,
    ProcessTypeGeneralView
} from "../../models/model";
import {
    initialPanelTemplate,
    initialPanelTemplateMechanicalProcessType, initialPanelTemplateProcessTypeGeneral, initialPanelTemplateProcessTypeGeneralItem, initialPanelTemplateProcessTypeGeneralItemSubProcess
} from "../../models/modelinitials";

const PanelTemplateDetail = () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();
  const state = useContext(AppContext);
  const [panelTemplate, setPanelTemplate] = useState<PanelTemplate | null>(
    null
  );
  const [processTypeGenerals, setProcessTypeGenerals] = useState<
    ProcessTypeGeneralView[]
  >([]);

  // Delet IDs
  const [
    panelTemplateMechanicalProcessTypeBlacklistDeleteIds,
    setPanelTemplateMechanicalProcessTypeBlacklistDeleteIds,
  ] = useState<number[]>([]);
  const [
    panelTemplateProcessTypeGeneralBlacklistDeleteIds,
    setPanelTemplateProcessTypeGeneralBlacklistDeleteIds,
  ] = useState<number[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemBlacklistDeleteIds,
    setPanelTemplateProcessTypeGeneralItemBlacklistDeleteIds,
  ] = useState<number[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemSubProcessBlacklistDeleteIds,
    setPanelTemplateProcessTypeGeneralItemSubProcessBlacklistDeleteIds,
  ] = useState<number[]>([]);

  // Non blacklist delete IDs
  const [
    panelTemplateMechanicalProcessTypeDeleteIds,
    setPanelTemplateMechanicalProcessTypeDeleteIds,
  ] = useState<number[]>([]);
  const [
    panelTemplateProcessTypeGeneralDeleteIds,
    setPanelTemplateProcessTypeGeneralDeleteIds,
  ] = useState<number[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemDeleteIds,
    setPanelTemplateProcessTypeGeneralItemDeleteIds,
  ] = useState<number[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemSubProcessDeleteIds,
    setPanelTemplateProcessTypeGeneralItemSubProcessDeleteIds,
  ] = useState<number[]>([]);

  // Blacklists
  const [
    panelTemplateMechanicalProcessTypeBlacklists,
    setPanelTemplateMechanicalProcessTypeBlacklists,
  ] = useState<PanelTemplateMechanicalProcessTypeBlacklist[]>([]);

  const [
    panelTemplateProcessTypeGeneralBlacklists,
    setPanelTemplateProcessTypeGeneralBlacklists,
  ] = useState<PanelTemplateProcessTypeGeneralBlacklist[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemBlacklists,
    setPanelTemplateProcessTypeGeneralItemBlacklists,
  ] = useState<PanelTemplateProcessTypeGeneralItemBlacklist[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemSubProcessBlacklists,
    setPanelTemplateProcessTypeGeneralItemSubProcessBlacklists,
  ] = useState<PanelTemplateProcessTypeGeneralItemSubProcessBlacklist[]>([]);

  // Non Blacklists
  const [
    panelTemplateMechanicalProcessTypes,
    setPanelTemplateMechanicalProcessTypes,
  ] = useState<PanelTemplateMechanicalProcessType[]>([]);

  const [
    panelTemplateProcessTypeGenerals,
    setPanelTemplateProcessTypeGenerals,
  ] = useState<PanelTemplateProcessTypeGeneral[]>([]);

  const [
    panelTemplateProcessTypeGeneralItems,
    setPanelTemplateProcessTypeGeneralItems,
  ] = useState<PanelTemplateProcessTypeGeneralItem[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemSubProcesses,
    setPanelTemplateProcessTypeGeneralItemSubProcesses,
  ] = useState<PanelTemplateProcessTypeGeneralItemSubProcess[]>([]);

  useEffect(() => {
    // Sort
    if (state?.processTypes) {
      state.processTypes.sort((a, b) => (a.ordering ?? 0) - (b.ordering ?? 0));

      if (isNaN(parseInt(id))) {
        setPanelTemplate({ ...initialPanelTemplate });
      } else {
        fetchData();
      }
    }
  }, [state?.processTypes]);

  const togglePanelTemplateProcessTypeGeneral = (
    processTypeGeneralView: ProcessTypeGeneralView | null = null
  ) => {
    const foundProcessTypeGeneral = panelTemplateProcessTypeGenerals.find(
      (panelTemplateProcessTypeGeneralBlacklist) =>
        panelTemplateProcessTypeGeneralBlacklist.processTypeGeneral?.id ===
        processTypeGeneralView?.processTypeGeneral?.id
    );

    const foundProcessTypeGeneralIndex = panelTemplateProcessTypeGenerals.findIndex(
      (panelTemplateProcessTypeGeneralBlacklist) =>
        panelTemplateProcessTypeGeneralBlacklist.processTypeGeneral?.id ===
        processTypeGeneralView?.processTypeGeneral?.id
    );

    if (foundProcessTypeGeneral) {
      setPanelTemplateProcessTypeGeneralDeleteIds([
        ...panelTemplateProcessTypeGeneralDeleteIds,
        foundProcessTypeGeneral.id ?? 0,
      ]);

      const newPanelTemplateProcessTypeGenerals = [
        ...panelTemplateProcessTypeGenerals,
      ];

      newPanelTemplateProcessTypeGenerals.splice(
        foundProcessTypeGeneralIndex,
        1
      );

      setPanelTemplateProcessTypeGenerals(newPanelTemplateProcessTypeGenerals);

      setAllPanelTemplateProcessTypeGeneralItems(processTypeGeneralView, false);

      setAllPanelTemplateProcessTypeGeneralItemSubProcessBlacklistsByGeneral(
        processTypeGeneralView,
        false
      );
    } else {
      if (processTypeGeneralView?.processTypeGeneral) {
        setPanelTemplateProcessTypeGenerals([
          ...panelTemplateProcessTypeGenerals,
          {
            ...initialPanelTemplateProcessTypeGeneral,
            processTypeGeneral: processTypeGeneralView?.processTypeGeneral,
          },
        ]);

        setAllPanelTemplateProcessTypeGeneralItems(
          processTypeGeneralView,
          true
        );

        setAllPanelTemplateProcessTypeGeneralItemSubProcessBlacklistsByGeneral(
          processTypeGeneralView,
          true
        );
      }
    }
  };

  const getPanelTemplateProcessTypeGeneralItemTime = (
    processTypeGeneralItemView: ProcessTypeGeneralItemView,
    panelTemplateProcessTypeGeneralItemSubProcesses: PanelTemplateProcessTypeGeneralItemSubProcess[]
  ) => {
    return processTypeGeneralItemView.processTypeGeneralItemSubProcesses.reduce(
      (acc, processTypeGeneralItemSubProcess) =>
        acc +
        (panelTemplateProcessTypeGeneralItemSubProcesses.find(
          (panelTemplateProcessTypeGeneralItemSubProcessX) =>
            panelTemplateProcessTypeGeneralItemSubProcessX
              .processTypeGeneralItemSubProcess?.id ===
            processTypeGeneralItemSubProcess.processTypeGeneralItemSubProcess
              ?.id
        )?.timeMins ?? 0),
      0
    );
  };

  const getPanelTemplateProcessTypeGeneralTime = (
    processTypeGeneralView: ProcessTypeGeneralView
  ) => {
    return processTypeGeneralView.processTypeGeneralItems.reduce(
      (acc, processTypeGeneralItemView) =>
        acc +
        getPanelTemplateProcessTypeGeneralItemTime(
          processTypeGeneralItemView,
          panelTemplateProcessTypeGeneralItemSubProcesses
        ),
      0
    );
  };

  const getTotalPanelTemplateTime = () => {
    return processTypeGenerals.reduce(
      (acc, panelTemplateProcessTypeGeneralView) =>
        acc +
        getPanelTemplateProcessTypeGeneralTime(
          panelTemplateProcessTypeGeneralView
        ),
      0
    );
  };

  const togglePanelTemplateProcessTypeGeneralItem = (
    processTypeGeneralItemView: ProcessTypeGeneralItemView
  ) => {
    const foundProcessTypeGeneralItem = panelTemplateProcessTypeGeneralItems.find(
      (panelTemplateProcessTypeGeneralItem) =>
        panelTemplateProcessTypeGeneralItem.processTypeGeneralItem?.id ===
        processTypeGeneralItemView?.processTypeGeneralItem?.id
    );

    const foundProcessTypeGeneralItemIndex = panelTemplateProcessTypeGeneralItems.findIndex(
      (panelTemplateProcessTypeGeneralItem) =>
        panelTemplateProcessTypeGeneralItem.processTypeGeneralItem?.id ===
        processTypeGeneralItemView?.processTypeGeneralItem?.id
    );

    if (foundProcessTypeGeneralItem) {
      setPanelTemplateProcessTypeGeneralItemDeleteIds([
        ...panelTemplateProcessTypeGeneralItemDeleteIds,
        foundProcessTypeGeneralItem.id ?? 0,
      ]);

      const newPanelTemplateProcessTypeGeneralItems = [
        ...panelTemplateProcessTypeGeneralItems,
      ];

      newPanelTemplateProcessTypeGeneralItems.splice(
        foundProcessTypeGeneralItemIndex,
        1
      );

      setPanelTemplateProcessTypeGeneralItems(
        newPanelTemplateProcessTypeGeneralItems
      );

      setAllPanelTemplateProcessTypeGeneralItemSubProcesses(
        processTypeGeneralItemView,
        false
      );
    } else {
      if (processTypeGeneralItemView.processTypeGeneralItem) {
        setPanelTemplateProcessTypeGeneralItems([
          ...panelTemplateProcessTypeGeneralItems,
          {
            ...initialPanelTemplateProcessTypeGeneralItem,
            processTypeGeneralItem:
              processTypeGeneralItemView.processTypeGeneralItem,
          },
        ]);

        setAllPanelTemplateProcessTypeGeneralItemSubProcesses(
          processTypeGeneralItemView,
          true
        );
      }
    }
  };

  const setAllPanelTemplateProcessTypeGeneralItems = (
    processTypeGeneralView: ProcessTypeGeneralView | null = null,
    set: boolean = false
  ) => {
    const newPanelTemplateProcessTypeGeneralItems = [
      ...panelTemplateProcessTypeGeneralItems,
    ];

    const filtered = newPanelTemplateProcessTypeGeneralItems.filter(
      (panelTemplateProcessTypeGeneralItem) =>
        panelTemplateProcessTypeGeneralItem.processTypeGeneralItem
          ?.processTypeGeneral?.id !==
        processTypeGeneralView?.processTypeGeneral?.id
    );

    const found = newPanelTemplateProcessTypeGeneralItems.filter(
      (panelTemplateProcessTypeGeneralItem) =>
        panelTemplateProcessTypeGeneralItem.processTypeGeneralItem
          ?.processTypeGeneral?.id ===
        processTypeGeneralView?.processTypeGeneral?.id
    );

    setPanelTemplateProcessTypeGeneralItems(filtered);

    setPanelTemplateProcessTypeGeneralItemDeleteIds([
      ...panelTemplateProcessTypeGeneralItemBlacklistDeleteIds,
      ...found.map((item) => item.id ?? 0),
    ]);

    if (set) {
      setPanelTemplateProcessTypeGeneralItems([
        ...newPanelTemplateProcessTypeGeneralItems,
        ...(processTypeGeneralView?.processTypeGeneralItems.map(
          (processTypeGeneralItemView) => ({
            ...initialPanelTemplateProcessTypeGeneralItem,
            processTypeGeneralItem:
              processTypeGeneralItemView.processTypeGeneralItem,
          })
        ) ?? []),
      ]);
    }
  };

  const togglePanelTemplateProcessTypeGeneralItemSubProcess = (
    processTypeGeneralItemSubProcessView: ProcessTypeGeneralItemSubProcessView
  ) => {
    const foundProcessTypeGeneralItemSubProcess = panelTemplateProcessTypeGeneralItemSubProcesses.find(
      (panelTemplateProcessTypeGeneralItemSubProcess) =>
        panelTemplateProcessTypeGeneralItemSubProcess
          .processTypeGeneralItemSubProcess?.id ===
        processTypeGeneralItemSubProcessView.processTypeGeneralItemSubProcess
          ?.id
    );

    const foundProcessTypeGeneralItemSubProcessIndex = panelTemplateProcessTypeGeneralItemSubProcesses.findIndex(
      (panelTemplateProcessTypeGeneralItemSubProcessBlacklistView) =>
        panelTemplateProcessTypeGeneralItemSubProcessBlacklistView
          .processTypeGeneralItemSubProcess?.id ===
        processTypeGeneralItemSubProcessView.processTypeGeneralItemSubProcess
          ?.id
    );

    if (foundProcessTypeGeneralItemSubProcess) {
      setPanelTemplateProcessTypeGeneralItemSubProcessDeleteIds([
        ...panelTemplateProcessTypeGeneralItemSubProcessDeleteIds,
        foundProcessTypeGeneralItemSubProcess.id ?? 0,
      ]);

      const newPanelTemplateProcessTypeGeneralItemSubProcesses = [
        ...panelTemplateProcessTypeGeneralItemSubProcesses,
      ];

      newPanelTemplateProcessTypeGeneralItemSubProcesses.splice(
        foundProcessTypeGeneralItemSubProcessIndex ?? 0,
        1
      );

      setPanelTemplateProcessTypeGeneralItemSubProcesses(
        newPanelTemplateProcessTypeGeneralItemSubProcesses
      );
    } else {
      if (
        processTypeGeneralItemSubProcessView.processTypeGeneralItemSubProcess
      ) {
        setPanelTemplateProcessTypeGeneralItemSubProcesses([
          ...panelTemplateProcessTypeGeneralItemSubProcesses,
          {
            ...initialPanelTemplateProcessTypeGeneralItemSubProcess,
            processTypeGeneralItemSubProcess:
              processTypeGeneralItemSubProcessView.processTypeGeneralItemSubProcess,
          },
        ]);
      }
    }
  };

  const setAllPanelTemplateProcessTypeGeneralItemSubProcesses = (
    processTypeGeneralItemView: ProcessTypeGeneralItemView | null = null,
    set: boolean = false
  ) => {
    const newPanelTemplateProcessTypeGeneralItemSubProcesses = [
      ...panelTemplateProcessTypeGeneralItemSubProcesses,
    ];

    const filtered = newPanelTemplateProcessTypeGeneralItemSubProcesses.filter(
      (panelTemplateProcessTypeGeneralItemSubProcess) =>
        panelTemplateProcessTypeGeneralItemSubProcess
          .processTypeGeneralItemSubProcess?.processTypeGeneralItem?.id !==
        processTypeGeneralItemView?.processTypeGeneralItem?.id
    );

    const found = newPanelTemplateProcessTypeGeneralItemSubProcesses.filter(
      (panelTemplateProcessTypeGeneralItemSubProcess) =>
        panelTemplateProcessTypeGeneralItemSubProcess
          .processTypeGeneralItemSubProcess?.processTypeGeneralItem?.id ===
        processTypeGeneralItemView?.processTypeGeneralItem?.id
    );

    setPanelTemplateProcessTypeGeneralItemSubProcesses(filtered);

    setPanelTemplateProcessTypeGeneralItemSubProcessDeleteIds([
      ...panelTemplateProcessTypeGeneralItemSubProcessDeleteIds,
      ...found.map((item) => item.id ?? 0),
    ]);

    if (set) {
      console.log(
        "[setAllPanelTemplateProcessTypeGeneralItemSubProcessBlacklists] setting"
      );

      setPanelTemplateProcessTypeGeneralItemSubProcesses([
        ...newPanelTemplateProcessTypeGeneralItemSubProcesses,
        ...(processTypeGeneralItemView?.processTypeGeneralItemSubProcesses.map(
          (processTypeGeneralItemSubProcessView) => ({
            ...initialPanelTemplateProcessTypeGeneralItemSubProcess,
            processTypeGeneralItemSubProcess:
              processTypeGeneralItemSubProcessView.processTypeGeneralItemSubProcess,
          })
        ) ?? []),
      ]);
    }
  };

  const setAllPanelTemplateProcessTypeGeneralItemSubProcessBlacklistsByGeneral = (
    processTypeGeneralView: ProcessTypeGeneralView | null = null,
    set: boolean = false
  ) => {
    const newPanelTemplateProcessTypeGeneralItemSubProcesses = [
      ...panelTemplateProcessTypeGeneralItemSubProcesses,
    ];

    const filtered = newPanelTemplateProcessTypeGeneralItemSubProcesses.filter(
      (panelTemplateProcessTypeGeneralItemSubProcess) =>
        panelTemplateProcessTypeGeneralItemSubProcess
          .processTypeGeneralItemSubProcess?.processTypeGeneralItem
          ?.processTypeGeneral?.id !==
        processTypeGeneralView?.processTypeGeneral?.id
    );

    const found = newPanelTemplateProcessTypeGeneralItemSubProcesses.filter(
      (panelTemplateProcessTypeGeneralItemSubProcess) =>
        panelTemplateProcessTypeGeneralItemSubProcess
          .processTypeGeneralItemSubProcess?.processTypeGeneralItem
          ?.processTypeGeneral?.id ===
        processTypeGeneralView?.processTypeGeneral?.id
    );

    setPanelTemplateProcessTypeGeneralItemSubProcesses(filtered);

    setPanelTemplateProcessTypeGeneralItemSubProcessDeleteIds([
      ...panelTemplateProcessTypeGeneralItemSubProcessDeleteIds,
      ...found.map((item) => item.id ?? 0),
    ]);

    if (set) {
      console.log(
        "[setAllPanelTemplateProcessTypeGeneralItemSubProcessBlacklists] setting"
      );

      const newPanelsToAdd =
        processTypeGeneralView?.processTypeGeneralItems
          ?.map((processTypeGeneralItemView) =>
            processTypeGeneralItemView.processTypeGeneralItemSubProcesses
              .map(
                (processTypeGeneralItemSubProcessView) =>
                  ({
                    ...initialPanelTemplateProcessTypeGeneralItemSubProcess,
                    processTypeGeneralItemSubProcess:
                      processTypeGeneralItemSubProcessView.processTypeGeneralItemSubProcess,
                  } as PanelTemplateProcessTypeGeneralItemSubProcess)
              )
              .flat()
          )
          ?.flat()
          .filter(
            (
              panelTemplateProcessTypeGeneralItemSubProcess
            ): panelTemplateProcessTypeGeneralItemSubProcess is PanelTemplateProcessTypeGeneralItemSubProcess =>
              panelTemplateProcessTypeGeneralItemSubProcess !== null &&
              panelTemplateProcessTypeGeneralItemSubProcess !== undefined
          ) ?? [];

      setPanelTemplateProcessTypeGeneralItemSubProcesses([
        ...newPanelTemplateProcessTypeGeneralItemSubProcesses,
        ...newPanelsToAdd,
      ]);
    }
  };

  const handleSave = async () => {
    try {
      console.log(panelTemplateProcessTypeGeneralBlacklistDeleteIds);
      const resp = await fetch(`${state?.baseUrl}/paneltemplates`, {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: state?.apiKey ?? "",
        },
        body: JSON.stringify(panelTemplate),
      });

      if (resp.status !== 201) throw await resp.text();

      const panelTemplateRes = (await resp.json()) as PanelTemplate;

      // Saves
      await Promise.all([
        ...panelTemplateMechanicalProcessTypeBlacklists.map(
          async (panelTemplateMechanicalProcessTypeBlacklist) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplatemechanicalprocesstypeblacklists`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateMechanicalProcessTypeBlacklist,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateMechanicalProcessTypeBlacklist] error",
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralBlacklists.map(
          async (panelTemplateProcessTypeGeneralBlacklist) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralblacklists`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateProcessTypeGeneralBlacklist,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralBlacklist] error",
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralItemBlacklists.map(
          async (panelTemplateProcessTypeGeneralItemBlacklist) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitemblacklists`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateProcessTypeGeneralItemBlacklist,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemBlacklist] error",
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralItemSubProcessBlacklists.map(
          async (panelTemplateProcessTypeGeneralItemSubProcessBlacklist) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitemsubprocessblacklists`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateProcessTypeGeneralItemSubProcessBlacklist,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemSubProcessBlacklist] error",
                e
              );
            }
          }
        ),
        // Non blacklists
        ...panelTemplateMechanicalProcessTypes.map(
          async (panelTemplateMechanicalProcessType) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplatemechanicalprocesstypes`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateMechanicalProcessType,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateMechanicalProcessTypeBlacklist] error",
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGenerals.map(
          async (panelTemplateProcessTypeGeneral) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegenerals`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateProcessTypeGeneral,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralBlacklist] error",
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralItems.map(
          async (panelTemplateProcessTypeGeneralItem) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitems`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateProcessTypeGeneralItem,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemBlacklist] error",
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralItemSubProcesses.map(
          async (panelTemplateProcessTypeGeneralItemSubProcess) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitemsubprocesses`,
                {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                    authorization: state?.apiKey ?? "",
                  },
                  body: JSON.stringify({
                    ...panelTemplateProcessTypeGeneralItemSubProcess,
                    panelTemplate: {
                      ...initialPanelTemplate,
                      id: panelTemplateRes?.id ?? 0,
                    },
                  }),
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemSubProcessBlacklist] error",
                e
              );
            }
          }
        ),
      ]);

      // Deletes
      await Promise.all([
        ...panelTemplateMechanicalProcessTypeBlacklistDeleteIds.map(
          async (id) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplatemechanicalprocesstypeblacklists/${id}/empty`,
                {
                  method: "delete",
                  headers: {
                    authorization: state?.apiKey ?? "",
                  },
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateMechanicalProcessTypeBlacklistDeleteId] error",
                id,
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralBlacklistDeleteIds.map(async (id) => {
          try {
            return await fetch(
              `${state?.baseUrl}/paneltemplateprocesstypegeneralblacklists/${id}/empty`,
              {
                method: "delete",
                headers: {
                  authorization: state?.apiKey ?? "",
                },
              }
            );
          } catch (e) {
            console.log(
              "[panelTemplateMechanicalProcessTypeBlacklistDeleteId] error",
              id,
              e
            );
          }
        }),
        ...panelTemplateProcessTypeGeneralItemBlacklistDeleteIds.map(
          async (id) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitemblacklists/${id}/empty`,
                {
                  method: "delete",
                  headers: {
                    authorization: state?.apiKey ?? "",
                  },
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemBlacklistDeleteIds] error",
                id,
                e
              );
            }
          }
        ),
        ...panelTemplateProcessTypeGeneralItemSubProcessBlacklistDeleteIds.map(
          async (id) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitemsubprocessblacklists/${id}/empty`,
                {
                  method: "delete",
                  headers: {
                    authorization: state?.apiKey ?? "",
                  },
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemSubProcessBlacklistDeleteIds] error",
                id,
                e
              );
            }
          }
        ),
        // Non blacklists
        ...panelTemplateMechanicalProcessTypeDeleteIds.map(async (id) => {
          try {
            return await fetch(
              `${state?.baseUrl}/paneltemplatemechanicalprocesstypes/${id}/empty`,
              {
                method: "delete",
                headers: {
                  authorization: state?.apiKey ?? "",
                },
              }
            );
          } catch (e) {
            console.log(
              "[panelTemplateMechanicalProcessTypeBlacklistDeleteId] error",
              id,
              e
            );
          }
        }),
        ...panelTemplateProcessTypeGeneralDeleteIds.map(async (id) => {
          try {
            return await fetch(
              `${state?.baseUrl}/paneltemplateprocesstypegenerals/${id}/empty`,
              {
                method: "delete",
                headers: {
                  authorization: state?.apiKey ?? "",
                },
              }
            );
          } catch (e) {
            console.log(
              "[panelTemplateMechanicalProcessTypeBlacklistDeleteId] error",
              id,
              e
            );
          }
        }),
        ...panelTemplateProcessTypeGeneralItemDeleteIds.map(async (id) => {
          try {
            return await fetch(
              `${state?.baseUrl}/paneltemplateprocesstypegeneralitems/${id}/empty`,
              {
                method: "delete",
                headers: {
                  authorization: state?.apiKey ?? "",
                },
              }
            );
          } catch (e) {
            console.log(
              "[panelTemplateProcessTypeGeneralItemBlacklistDeleteIds] error",
              id,
              e
            );
          }
        }),
        ...panelTemplateProcessTypeGeneralItemSubProcessDeleteIds.map(
          async (id) => {
            try {
              return await fetch(
                `${state?.baseUrl}/paneltemplateprocesstypegeneralitemsubprocesses/${id}/empty`,
                {
                  method: "delete",
                  headers: {
                    authorization: state?.apiKey ?? "",
                  },
                }
              );
            } catch (e) {
              console.log(
                "[panelTemplateProcessTypeGeneralItemSubProcessBlacklistDeleteIds] error",
                id,
                e
              );
            }
          }
        ),
      ]);

      // history.push("/paneltemplates");
      window.location.reload();
    } catch (e) {
      console.log("[handleSave] error", e);
    }
  };

  const fetchData = async () => {
    try {
      console.log("fetching");
      const [
        panelTemplateRes,
        processTypeGenerals,
        panelMechanicalBlacklists,
        panelGeneralBlacklists,
        panelGeneralItemBlacklists,
        panelGeneralItemSubProcessBlacklists,
        panelMechanicals,
        panelGenerals,
        panelGeneralItems,
        panelGeneralItemSubProcesses,
        // Non blacklists
      ] = await Promise.all([
        fetchPanelTemplate(state?.baseUrl ?? "", state?.apiKey ?? "", id),
        fetchProcessTypeGeneralViews(state?.baseUrl ?? "", state?.apiKey ?? ""),

        fetchPanelTemplateMechanicalProcessTypeBlacklists(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        fetchPanelTemplateProcessTypeGeneralBlacklists(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        fetchPanelTemplateProcessTypeGeneralItemBlacklists(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        fetchPanelTemplateProcessTypeGeneralItemSubProcessBlacklists(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        // Non blacklistst
        fetchPanelTemplateMechanicalProcessTypes(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        fetchPanelTemplateProcessTypeGenerals(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        fetchPanelTemplateProcessTypeGeneralItems(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
        fetchPanelTemplateProcessTypeGeneralItemSubProcesses(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          id
        ),
      ]);

      console.log("Panle template", panelTemplateRes);

      // Sort the stuff

      processTypeGenerals.sort(
        (a, b) =>
          (a.processTypeGeneral?.ordering ?? 0) -
          (b.processTypeGeneral?.ordering ?? 0)
      );

      processTypeGenerals.forEach((processTypeGeneralView) =>
        processTypeGeneralView.processTypeGeneralItems.sort(
          (a, b) =>
            (a.processTypeGeneralItem?.ordering ?? 0) -
            (b.processTypeGeneralItem?.ordering ?? 0)
        )
      );

      processTypeGenerals.forEach((processTypeGeneralView) =>
        processTypeGeneralView.processTypeGeneralItems.forEach(
          (processTypeGeneralItemView) => {
            processTypeGeneralItemView.processTypeGeneralItemSubProcesses.sort(
              (a, b) =>
                (a.processTypeGeneralItemSubProcess?.ordering ?? 0) -
                (b.processTypeGeneralItemSubProcess?.ordering ?? 0)
            );
          }
        )
      );

      setPanelTemplate(panelTemplateRes);
      setProcessTypeGenerals(processTypeGenerals);

      setPanelTemplateMechanicalProcessTypeBlacklists(
        panelMechanicalBlacklists
      );
      setPanelTemplateProcessTypeGeneralBlacklists(panelGeneralBlacklists);
      setPanelTemplateProcessTypeGeneralItemBlacklists(
        panelGeneralItemBlacklists
      );
      setPanelTemplateProcessTypeGeneralItemSubProcessBlacklists(
        panelGeneralItemSubProcessBlacklists
      );
      // Non blacklists
      setPanelTemplateMechanicalProcessTypes(panelMechanicals);
      setPanelTemplateProcessTypeGenerals(panelGenerals);
      setPanelTemplateProcessTypeGeneralItems(panelGeneralItems);
      setPanelTemplateProcessTypeGeneralItemSubProcesses(
        panelGeneralItemSubProcesses
      );
    } catch (e) {
      console.log("[fetchData] error", e);
    }
  };

  return (
    <>
      <div className="m-3">
        <div className="d-flex align-items-center">
          <div>
            <h4>Panel Template Detail ({id})</h4>
          </div>
          <div className="mx-1">
            <Button size="sm" onClick={handleSave}>
              Save
            </Button>
          </div>
          {panelTemplate?.id && panelTemplate.id !== 0 ? (
            <div className="mx-1">
              <Button
                size="sm"
                onClick={async () => {
                  const name = prompt(
                    "Select name to copy",
                    panelTemplate?.name ? `${panelTemplate.name} copy` : ""
                  );

                  if (name && name !== "") {
                    try {
                      const resp = await fetch(
                        `${state?.baseUrl}/paneltemplates/${panelTemplate?.id}/copy?name=${name}`,
                        {
                          method: "post",
                          headers: {
                            authorization: state?.apiKey ?? "",
                          },
                        }
                      );

                      if (resp.status !== 201) throw await resp.text();
                    } catch (e) {
                      console.log(e);
                    }

                    // window.location.reload();
                    history.push("/paneltemplates");
                  } else {
                    alert("Failed to copy.");
                  }
                }}
              >
                <FileCopy fontSize="inherit" /> Copy
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <hr />

        <div className="d-flex flex-wrap">
          <div className="flex-grow-1">
            <div>
              <small>Template Name</small>
            </div>
            <FormControl
              value={panelTemplate?.name ?? ""}
              onChange={(e) => {
                if (panelTemplate)
                  setPanelTemplate({ ...panelTemplate, name: e.target.value });
              }}
              placeholder="Name..."
            />
          </div>
          <div className="flex-grow-1">
            <div>
              <small>Template Type</small>
            </div>
            <FormControl
              value={panelTemplate?.type ?? ""}
              onChange={(e) => {
                if (panelTemplate)
                  setPanelTemplate({ ...panelTemplate, type: e.target.value });
              }}
              placeholder="Name..."
            />
          </div>
          <div className="flex-grow-1">
            <div>
              <small>Template Group</small>
            </div>
            <FormControl
              value={panelTemplate?.panelGroup ?? ""}
              onChange={(e) => {
                if (panelTemplate)
                  setPanelTemplate({
                    ...panelTemplate,
                    panelGroup: e.target.value,
                  });
              }}
              placeholder="Name..."
            />
          </div>
        </div>

        <hr />

        {panelTemplate?.id === 0 ? (
          <></>
        ) : (
          <>
            <div>
              <h5>Mechanical Process Types</h5>
            </div>

            <div>
              <Table size="sm">
                <thead>
                  <tr>
                    {["Name", "Exists?"].map((head) => (
                      <th className="border border-secondary text-light bg-secondary align-middle text-center">
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {state?.processTypes
                    .filter((processType) => !processType.hidden)
                    .map((processType) => {
                      const foundMechanical = panelTemplateMechanicalProcessTypes.find(
                        (panelTemplateMechanicalProcessType) =>
                          panelTemplateMechanicalProcessType.processType?.id ===
                          processType.id
                      );

                      return (
                        <tr>
                          <td
                            className={`border border-secondary align-middle text-center font-weight-bold ${
                              foundMechanical
                                ? "text-success"
                                : "text-danger bg-dark"
                            }`}
                          >
                            {processType?.name}
                          </td>
                          <td
                            className={`border border-secondary align-middle text-center ${
                              foundMechanical ? "" : "bg-dark"
                            }`}
                          >
                            <Button
                              onClick={() => {
                                console.log(
                                  "Found mechanical blacklist:",
                                  foundMechanical,
                                  panelTemplateMechanicalProcessTypes
                                );

                                if (foundMechanical) {
                                  setPanelTemplateMechanicalProcessTypes(
                                    panelTemplateMechanicalProcessTypes.filter(
                                      (panelTemplateMechanicalProcessType) =>
                                        panelTemplateMechanicalProcessType
                                          .processType?.id !== processType.id
                                    )
                                  );
                                  setPanelTemplateMechanicalProcessTypeDeleteIds(
                                    [
                                      ...panelTemplateMechanicalProcessTypeDeleteIds,
                                      foundMechanical.id ?? 0,
                                    ]
                                  );
                                } else {
                                  setPanelTemplateMechanicalProcessTypes([
                                    ...panelTemplateMechanicalProcessTypes,
                                    {
                                      ...initialPanelTemplateMechanicalProcessType,
                                      processType: processType,
                                    },
                                  ]);
                                }
                              }}
                              variant="outline-secondary"
                              size="sm"
                            >
                              Toggle
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <hr />

            <div>
              <h5>General Process Types</h5>
              <div className="font-weight-bold">
                Total time: {getTotalPanelTemplateTime()}
              </div>
            </div>

            <hr />

            <div>
              {processTypeGenerals.map((processTypeGeneralView) => {
                const foundProcessTypeGeneral = panelTemplateProcessTypeGenerals.find(
                  (panelTemplateProcessTypeGeneral) =>
                    panelTemplateProcessTypeGeneral.processTypeGeneral?.id ===
                    processTypeGeneralView.processTypeGeneral?.id
                );

                return (
                  <div>
                    <div className="d-flex align-items-center">
                      <div
                        className="d-flex p-2 border border-secondary"
                        onClick={() => {
                          // togglePanelTemplateProcessTypeGeneral(
                          //   processTypeGeneralView
                          // );
                          togglePanelTemplateProcessTypeGeneral(
                            processTypeGeneralView
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div>
                          <input
                            type="checkbox"
                            checked={foundProcessTypeGeneral ? true : false}
                          />
                        </div>
                        <div className="mx-2">
                          <strong>
                            {processTypeGeneralView.processTypeGeneral?.name}
                          </strong>
                        </div>
                      </div>
                    </div>

                    <div className="font-weight-bold">
                      Total Time:{" "}
                      {getPanelTemplateProcessTypeGeneralTime(
                        processTypeGeneralView
                      )}{" "}
                      mins
                    </div>

                    <div className="my-2 overflow-auto">
                      <Table size="sm">
                        <tbody>
                          {processTypeGeneralView.processTypeGeneralItems.map(
                            (processTypeGeneralItemView) => {
                              const foundProcessTypeGeneralItem = panelTemplateProcessTypeGeneralItems.find(
                                (panelTemplateProcessTypeGeneralItem) =>
                                  panelTemplateProcessTypeGeneralItem
                                    .processTypeGeneralItem?.id ===
                                  processTypeGeneralItemView
                                    .processTypeGeneralItem?.id
                              );

                              const foundProcessTypeGeneral = panelTemplateProcessTypeGenerals.find(
                                (panelTemplateProcessTypeGeneral) =>
                                  panelTemplateProcessTypeGeneral
                                    .processTypeGeneral?.id ===
                                  processTypeGeneralView.processTypeGeneral?.id
                              );

                              return (
                                <tr>
                                  <td
                                    className={`border border-secondary ${
                                      // foundProcessTypeGeneral ||
                                      foundProcessTypeGeneralItem
                                        ? ""
                                        : "bg-dark text-light"
                                    }`}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (foundProcessTypeGeneral) {
                                          togglePanelTemplateProcessTypeGeneralItem(
                                            processTypeGeneralItemView
                                          );
                                        }
                                      }}
                                    >
                                      <div>
                                        <input
                                          type="checkbox"
                                          checked={
                                            foundProcessTypeGeneralItem
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      <div className="mx-2 font-weight-bold">
                                        {processTypeGeneralItemView
                                          .processTypeGeneralItem?.name ??
                                          "No Name"}
                                      </div>
                                    </div>
                                    <div className="font-weight-bold">
                                      Total Time:{" "}
                                      {getPanelTemplateProcessTypeGeneralItemTime(
                                        processTypeGeneralItemView,
                                        panelTemplateProcessTypeGeneralItemSubProcesses
                                      )}{" "}
                                      mins
                                    </div>
                                  </td>
                                  {processTypeGeneralItemView.processTypeGeneralItemSubProcesses.map(
                                    (processTypeGeneralItemSubProcessView) => {
                                      const foundProcessTypeGeneralItemSubProcess = panelTemplateProcessTypeGeneralItemSubProcesses.find(
                                        (
                                          panelTemplateProcessTypeGeneralItemSubProcess
                                        ) =>
                                          panelTemplateProcessTypeGeneralItemSubProcess
                                            .processTypeGeneralItemSubProcess
                                            ?.id ===
                                          processTypeGeneralItemSubProcessView
                                            .processTypeGeneralItemSubProcess
                                            ?.id
                                      );

                                      const foundProcessTypeGeneralItem = panelTemplateProcessTypeGeneralItems.find(
                                        (panelTemplateProcessTypeGeneralItem) =>
                                          panelTemplateProcessTypeGeneralItem
                                            .processTypeGeneralItem?.id ===
                                          processTypeGeneralItemView
                                            .processTypeGeneralItem?.id
                                      );

                                      return (
                                        <td
                                          className={`border border-secondary ${
                                            // foundProcessTypeGeneralItem ||
                                            foundProcessTypeGeneralItemSubProcess
                                              ? ""
                                              : "bg-dark text-light"
                                          }`}
                                        >
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              if (foundProcessTypeGeneralItem) {
                                                togglePanelTemplateProcessTypeGeneralItemSubProcess(
                                                  processTypeGeneralItemSubProcessView
                                                );
                                              }
                                            }}
                                            className="d-flex align-items-center"
                                          >
                                            <div>
                                              <input
                                                type="checkbox"
                                                checked={
                                                  foundProcessTypeGeneralItemSubProcess
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="mx-2">
                                              {
                                                processTypeGeneralItemSubProcessView
                                                  .processTypeGeneralItemSubProcess
                                                  ?.name
                                              }
                                            </div>
                                          </div>
                                          {foundProcessTypeGeneralItemSubProcess ? (
                                            <div className="d-flex">
                                              Time:{" "}
                                              <FormControl
                                                value={
                                                  foundProcessTypeGeneralItemSubProcess?.timeMins ??
                                                  0
                                                }
                                                size="sm"
                                                onChange={(e) =>
                                                  setPanelTemplateProcessTypeGeneralItemSubProcesses(
                                                    panelTemplateProcessTypeGeneralItemSubProcesses.map(
                                                      (
                                                        panelTemplateProcessTypeGeneralItemSubProcess
                                                      ) =>
                                                        panelTemplateProcessTypeGeneralItemSubProcess
                                                          .processTypeGeneralItemSubProcess
                                                          ?.id ===
                                                        processTypeGeneralItemSubProcessView
                                                          .processTypeGeneralItemSubProcess
                                                          ?.id
                                                          ? {
                                                              ...panelTemplateProcessTypeGeneralItemSubProcess,
                                                              timeMins: isNaN(
                                                                parseInt(
                                                                  e.target.value
                                                                )
                                                              )
                                                                ? 0
                                                                : parseInt(
                                                                    e.target
                                                                      .value
                                                                  ),
                                                            }
                                                          : panelTemplateProcessTypeGeneralItemSubProcess
                                                    )
                                                  )
                                                }
                                                placeholder="Process time (mins)..."
                                              />
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                      );
                                    }
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <hr />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PanelTemplateDetail;
