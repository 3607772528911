import { Delete } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Button, FormControl, Modal, Spinner, Table } from "react-bootstrap";
import { useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../App";
import {
  checkLoadColorDay,
  fetchDateWorkOrders,
  fetchPanelTemplateProcessTypeGeneralItems,
  fetchPanelTemplateProcessTypeGeneralItemSubProcesses,
  fetchPanelTemplateProcessTypeGeneralItemSubProcessWorkers,
  fetchPanelTemplateProcessTypeGenerals,
  fetchProcessTypeGeneralViews,
  getPanelTemplateProcessTypeGeneralItemTime,
  getPanelTemplateProcessTypeGeneralTime,
  makeReadableDateString,
} from "../../helpers";
import {
  ExtProjectProduct,
  ExtProjectProductPanelTemplate,
  ExtProjectSimple,
  ExtProjectWorkOrder,
  PanelTemplateProcessTypeGeneral,
  PanelTemplateProcessTypeGeneralItem,
  PanelTemplateProcessTypeGeneralItemSubProcess,
  PanelTemplateProcessTypeGeneralItemSubProcessWorker,
  ProcessTypeGeneralView,
} from "../../models/model";
import {
  initialExtProjectWorkOrder,
  initialPanelTemplateProcessTypeGeneralItemSubProcessWorker,
} from "../../models/modelinitials";
import { RequestStatus } from "../../models/RequestStatus";

const WorkOrderV2Detail = () => {
  const { date } = useParams() as { date: string };
  const state = useContext(AppContext);

  const [searchUser, setSearchUser] = useState("");
  const [projects, setProjects] = useState<ExtProjectSimple[]>([]);
  const [selectedProject, setSelectedProject] =
    useState<ExtProjectSimple | null>(null);

  const [extProjectWorkOrders, setExtProjectWorkOrders] = useState<
    ExtProjectWorkOrder[]
  >([]);

  const [workOrderDeleteIds, setWorkOrderDeleteIds] = useState<number[]>([]);

  const [dateWorkOrders, setDateWorkOrders] = useState<ExtProjectWorkOrder[]>(
    []
  );

  const [extProjectsIdName, setExtProjectsIdName] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const [selectedProjectProduct, setSelectedProjectProduct] =
    useState<ExtProjectProduct | null>(null);

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  const [projectSearch, setProjectSearch] = useState("");
  const [projectProductSearch, setProjectProductSearch] = useState("");
  const [projectProductPanelTemplates, setProjectProductPanelTemplates] =
    useState<ExtProjectProductPanelTemplate[]>([]);
  const [processTypeGenerals, setProcessTypeGenerals] = useState<
    ProcessTypeGeneralView[]
  >([]);

  const [
    panelTemplateProcessTypeGenerals,
    setPanelTemplateProcessTypeGenerals,
  ] = useState<PanelTemplateProcessTypeGeneral[]>([]);

  const [
    panelTemplateProcessTypeGeneralItems,
    setPanelTemplateProcessTypeGeneralItems,
  ] = useState<PanelTemplateProcessTypeGeneralItem[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemSubProcesses,
    setPanelTemplateProcessTypeGeneralItemSubProcesses,
  ] = useState<PanelTemplateProcessTypeGeneralItemSubProcess[]>([]);

  const [selectedWorkOrderQty, setSelectedWorkOrderQty] = useState(1);
  const [showWorkersDialog, setShowWorkersDialog] = useState(false);
  const [
    selectedPanelTemplateProcessTypeGeneralItemSubProcess,
    setSelectedPanelTemplateProcessTypeGeneralItemSubProcess,
  ] = useState<PanelTemplateProcessTypeGeneralItemSubProcess | null>(null);

  const [tempExtWorkers, setTempExtWorkers] = useState<
    PanelTemplateProcessTypeGeneralItemSubProcessWorker[]
  >([]);
  const [
    panelTemplateProcessTypeGeneralItemSubProcessWorkers,
    setPanelTemplateProcessTypeGeneralItemSubProcessWorkers,
  ] = useState<PanelTemplateProcessTypeGeneralItemSubProcessWorker[]>([]);

  const [
    panelTemplateProcessTypeGeneralItemSubProcessWorkerDeleteIds,
    setPanelTemplateProcessTypeGeneralItemSubProcessWorkerDeleteIds,
  ] = useState<number[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const resp = await fetch(`${state?.baseUrl}/ext-projects`, {
        headers: { authorization: state?.apiKey ?? "" },
      });

      if (resp.status !== 200) throw await resp.text();

      const [
        processTypeGenerals,
        dateWorkOrders,
        panelTemplateProcessTypeGeneralItemSubProcessWorkers,
      ] = await Promise.all([
        fetchProcessTypeGeneralViews(state?.baseUrl ?? "", state?.apiKey ?? ""),
        fetchDateWorkOrders(state?.baseUrl ?? "", state?.apiKey ?? "", date),
        fetchPanelTemplateProcessTypeGeneralItemSubProcessWorkers(
          state?.baseUrl ?? "",
          state?.apiKey ?? "",
          date
        ),
      ]);

      // Sort
      processTypeGenerals.sort(
        (a, b) =>
          (a.processTypeGeneral?.ordering ?? 0) -
          (b.processTypeGeneral?.ordering ?? 0)
      );

      processTypeGenerals.forEach((processTypeGeneralView) =>
        processTypeGeneralView.processTypeGeneralItems.sort(
          (a, b) =>
            (a.processTypeGeneralItem?.ordering ?? 0) -
            (b.processTypeGeneralItem?.ordering ?? 0)
        )
      );

      processTypeGenerals.forEach((processTypeGeneralView) => {
        processTypeGeneralView.processTypeGeneralItems.sort(
          (a, b) =>
            (a.processTypeGeneralItem?.ordering ?? 0) -
            (b.processTypeGeneralItem?.ordering ?? 0)
        );
      });

      processTypeGenerals.forEach((processTypeGeneralView) => {
        processTypeGeneralView.processTypeGeneralItems.forEach(
          (processTypeGeneralItemView) => {
            processTypeGeneralItemView.processTypeGeneralItemSubProcesses.sort(
              (a, b) =>
                (a.processTypeGeneralItemSubProcess?.ordering ?? 0) -
                (b.processTypeGeneralItemSubProcess?.ordering ?? 0)
            );
          }
        );
      });

      // Get projects idname
      const projectsIdNameResp = await fetch(
        `${state?.baseUrl}/ext-projects-idname`,
        { headers: { authorization: state?.apiKey ?? "" } }
      );

      if (projectsIdNameResp.status !== 200)
        throw await projectsIdNameResp.text();

      setExtProjectsIdName(await projectsIdNameResp.json());
      setProjects(await resp.json());
      setProcessTypeGenerals(processTypeGenerals);
      setDateWorkOrders(dateWorkOrders);
      setPanelTemplateProcessTypeGeneralItemSubProcessWorkers(
        panelTemplateProcessTypeGeneralItemSubProcessWorkers
      );
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log("[fetchData] error", e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h5>Work Order Detail for: {makeReadableDateString(new Date(date))}</h5>

        <div>
          <Button
            size="sm"
            onClick={async () => {
              try {
                await Promise.all([
                  ...dateWorkOrders.map(async (dateWorkOrder) => {
                    return await fetch(
                      `${state?.baseUrl}/extprojectworkorders`,
                      {
                        method: "post",
                        headers: {
                          authorization: state?.apiKey ?? "",
                          "content-type": "application/json",
                        },
                        body: JSON.stringify(dateWorkOrder),
                      }
                    );
                  }),
                  ...panelTemplateProcessTypeGeneralItemSubProcessWorkers.map(
                    async (
                      panelTemplateProcessTypeGeneralItemSubProcessWorker
                    ) => {
                      return await fetch(
                        `${state?.baseUrl}/paneltemplateprocesstypegeneralitemsubprocessworkers`,
                        {
                          method: "post",
                          headers: {
                            authorization: state?.apiKey ?? "",
                            "content-type": "application/json",
                          },
                          body: JSON.stringify(
                            panelTemplateProcessTypeGeneralItemSubProcessWorker
                          ),
                        }
                      );
                    }
                  ),
                ]);

                // Delete
                await Promise.all([
                  ...workOrderDeleteIds.map(async (id) => {
                    return await fetch(
                      `${state?.baseUrl}/extprojectworkorders/${id}/empty`,
                      {
                        method: "delete",
                        headers: {
                          authorization: state?.apiKey ?? "",
                        },
                      }
                    );
                  }),
                  ...panelTemplateProcessTypeGeneralItemSubProcessWorkerDeleteIds.map(
                    async (id) => {
                      return await fetch(
                        `${state?.baseUrl}/paneltemplateprocesstypegeneralitemsubprocessworkers/${id}/empty`,
                        {
                          method: "delete",
                          headers: {
                            authorization: state?.apiKey ?? "",
                          },
                        }
                      );
                    }
                  ),
                ]);

                window.location.reload();
              } catch (e) {
                console.log("[work order save] error", e);
              }
            }}
          >
            Save
          </Button>
        </div>

        {requestStatus === RequestStatus.Loading ? (
          <Spinner className="ml-2" animation="border" size="sm" />
        ) : (
          <></>
        )}
      </div>
      <hr />
      {/* <pre>{JSON.stringify(extProjectWorkOrders, null, 2)}</pre>
      <pre>{JSON.stringify(selectedProjectProduct, null, 2)}</pre> */}

      <div>
        <div>
          <div>Select Project:</div>
          <div className="flex-grow-1">
            <FormControl
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
              size="sm"
              placeholder="Search..."
            />
          </div>

          <div className="flex-grow-1">
            <FormControl
              as="select"
              onChange={async (e: any) => {
                console.log("Project:", e.target.value);

                if (e.target.value && e.target.value !== "") {
                  const foundProject =
                    projects.find(
                      (project) => `${project.id}` === e.target.value
                    ) ?? null;

                  setSelectedProject(foundProject);
                  setSelectedProjectProduct(null);

                  try {
                    // Get Project Product template
                    const resp = await fetch(
                      `${state?.baseUrl}/extprojectproductpaneltemplates/byextprojectid?extProjectId=${e.target.value}`
                    );

                    if (resp.status !== 200) throw await resp.text();
                    setProjectProductPanelTemplates(await resp.json());

                    // Get project work orders
                    const projectWorkOrderResp = await fetch(
                      `${state?.baseUrl}/extprojects/${foundProject?.id}/extprojectworkorders`,
                      {
                        headers: { authorization: state?.apiKey ?? "" },
                      }
                    );

                    if (projectWorkOrderResp.status !== 200)
                      throw await projectWorkOrderResp.text();
                    setExtProjectWorkOrders(await projectWorkOrderResp.json());
                  } catch (e) {
                    console.log(
                      "[extprojectproductpaneltemplates/byextprojectid] error ",
                      e
                    );
                  }
                } else {
                  setSelectedProject(null);
                  setSelectedProjectProduct(null);
                  setExtProjectWorkOrders([]);
                }
              }}
            >
              <option selected disabled>
                {selectedProject
                  ? projects.find(
                      (project) => project.id === selectedProject.id
                    )?.name
                  : "None"}
              </option>
              <option>None</option>
              {projects
                .sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""))
                .filter((project) =>
                  project.name
                    ?.toLowerCase()
                    .includes(projectSearch.toLowerCase())
                )
                .map((project) => {
                  return (
                    <option value={project.id ?? 0}>
                      {project.name} ({project.projectProducts.length} products)
                    </option>
                  );
                })}
            </FormControl>
          </div>
        </div>

        {selectedProject ? (
          <div className="font-weight-bold">
            Selected: {selectedProject.name}
          </div>
        ) : (
          <></>
        )}

        {selectedProject ? (
          <div>
            <div>Select Product:</div>
            <div className="flex-grow-1">
              <FormControl
                value={projectProductSearch}
                onChange={(e) => setProjectProductSearch(e.target.value)}
                size="sm"
                placeholder="Search..."
              />
            </div>

            <div className="flex-grow-1">
              <FormControl
                as="select"
                onChange={async (e) => {
                  setSelectedProjectProduct(
                    selectedProject.projectProducts.find(
                      (projectProduct) =>
                        `${projectProduct.id}` === e.target.value
                    ) ?? null
                  );

                  const foundProjectProductPanelTemplate =
                    projectProductPanelTemplates.find(
                      (projectProductPanelTemplate) =>
                        `${projectProductPanelTemplate.extProjectProductId}` ===
                        e.target.value
                    );

                  console.log(
                    "Found project product panel template",
                    foundProjectProductPanelTemplate
                  );

                  // Fetch project product panel template
                  if (foundProjectProductPanelTemplate) {
                    const [
                      panelTemplateProcessTypeGenerals,
                      panelTemplateProcessTypeGeneralItems,
                      panelTemplateProcessTypeGeneralItemSubProcesses,
                    ] = await Promise.all([
                      fetchPanelTemplateProcessTypeGenerals(
                        state?.baseUrl ?? "",
                        state?.apiKey ?? "",
                        foundProjectProductPanelTemplate.panelTemplate?.id
                      ),
                      fetchPanelTemplateProcessTypeGeneralItems(
                        state?.baseUrl ?? "",
                        state?.apiKey ?? "",
                        foundProjectProductPanelTemplate.panelTemplate?.id
                      ),
                      fetchPanelTemplateProcessTypeGeneralItemSubProcesses(
                        state?.baseUrl ?? "",
                        state?.apiKey ?? "",
                        foundProjectProductPanelTemplate.panelTemplate?.id
                      ),
                    ]);

                    setPanelTemplateProcessTypeGenerals(
                      panelTemplateProcessTypeGenerals
                    );
                    setPanelTemplateProcessTypeGeneralItems(
                      panelTemplateProcessTypeGeneralItems
                    );
                    setPanelTemplateProcessTypeGeneralItemSubProcesses(
                      panelTemplateProcessTypeGeneralItemSubProcesses
                    );
                  } else {
                    setPanelTemplateProcessTypeGenerals([]);
                    setPanelTemplateProcessTypeGeneralItems([]);
                    setPanelTemplateProcessTypeGeneralItemSubProcesses([]);
                  }
                }}
              >
                <option selected disabled>
                  {selectedProjectProduct
                    ? selectedProject.projectProducts.find(
                        (projectProduct) =>
                          projectProduct.id === selectedProjectProduct.id
                      )?.name
                    : "None"}
                </option>
                {selectedProject.projectProducts
                  .filter((projectProduct) =>
                    projectProduct.name
                      .toLowerCase()
                      .includes(projectProductSearch.toLowerCase())
                  )
                  .map((projectProduct) => {
                    return (
                      <option value={projectProduct.id ?? 0}>
                        {projectProduct.name}:{projectProduct.qty}
                      </option>
                    );
                  })}
              </FormControl>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {selectedProjectProduct ? (
        <div className="font-weight-bold">
          Selected product: {selectedProjectProduct.name}, Qty: ,{" "}
          {selectedProjectProduct.qty},{" "}
          {(() => {
            const foundProjectProductPanelTemplate =
              projectProductPanelTemplates.find(
                (projectProductPanelTemplate) =>
                  projectProductPanelTemplate.extProjectProductId ===
                  selectedProjectProduct.id
              );

            if (foundProjectProductPanelTemplate) {
              return (
                <span className="font-weight-bold text-success">
                  Template:{" "}
                  {foundProjectProductPanelTemplate.panelTemplate?.name}
                </span>
              );
            } else {
              return (
                <span className="font-weight-bold text-danger">
                  No Template
                </span>
              );
            }
          })()}
        </div>
      ) : (
        <></>
      )}

      <hr />

      {selectedProjectProduct ? (
        <div>
          <h5>Processes</h5>

          <div className="my-2">
            {processTypeGenerals
              .sort(
                (a, b) =>
                  (a.processTypeGeneral?.ordering ?? 0) -
                  (b.processTypeGeneral?.ordering ?? 0)
              )
              .map((processTypeGeneralView) => {
                const foundPanelTemplateProcessTypeGeneral =
                  panelTemplateProcessTypeGenerals.find(
                    (panelTemplateProcessTypeGeneral) =>
                      panelTemplateProcessTypeGeneral.processTypeGeneral?.id ===
                      processTypeGeneralView.processTypeGeneral?.id
                  );

                return (
                  <>
                    <div className="d-flex align-items-center my-2">
                      <div
                        className={`px-2 py-1 border border-secondary ${
                          foundPanelTemplateProcessTypeGeneral
                            ? ""
                            : "bg-dark text-light"
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={
                            foundPanelTemplateProcessTypeGeneral ? true : false
                          }
                        />{" "}
                        {processTypeGeneralView.processTypeGeneral?.name}
                      </div>
                      <div className="ml-2">
                        <strong>
                          {(() => {
                            console.log(
                              "Process type general",
                              processTypeGeneralView.processTypeGeneral?.name
                            );

                            const processTypeGeneralTime =
                              getPanelTemplateProcessTypeGeneralTime(
                                processTypeGeneralView,
                                panelTemplateProcessTypeGeneralItemSubProcesses
                              );

                            return `${processTypeGeneralTime} mins, x${
                              selectedProjectProduct.qty
                            } = ${
                              processTypeGeneralTime *
                              (selectedProjectProduct.qty ?? 0)
                            } mins`;
                          })()}
                        </strong>
                      </div>
                    </div>
                    <div className="my-2">
                      <Table size="sm">
                        {processTypeGeneralView.processTypeGeneralItems.map(
                          (processTypeGeneralItemView) => {
                            const foundPanelTemplateProcessTypeGeneralItem =
                              panelTemplateProcessTypeGeneralItems.find(
                                (panelTemplateProcessTypeGeneralItem) =>
                                  panelTemplateProcessTypeGeneralItem
                                    .processTypeGeneralItem?.id ===
                                  processTypeGeneralItemView
                                    .processTypeGeneralItem?.id
                              );

                            return (
                              <tr>
                                <th
                                  className={`border border-secondary ${
                                    foundPanelTemplateProcessTypeGeneralItem
                                      ? ""
                                      : "bg-dark text-light"
                                  } `}
                                >
                                  <div className="d-flex align-items-center">
                                    <input
                                      type="checkbox"
                                      checked={
                                        foundPanelTemplateProcessTypeGeneralItem
                                          ? true
                                          : false
                                      }
                                    />
                                    <div className="ml-1">
                                      <div>
                                        {
                                          processTypeGeneralItemView
                                            .processTypeGeneralItem?.name
                                        }
                                      </div>
                                      <div>
                                        <strong>
                                          {(() => {
                                            const totalTime =
                                              getPanelTemplateProcessTypeGeneralItemTime(
                                                processTypeGeneralItemView,
                                                panelTemplateProcessTypeGeneralItemSubProcesses
                                              );

                                            return `Total time: ${totalTime} mins, x${
                                              selectedProjectProduct.qty ?? 0
                                            } = ${
                                              totalTime *
                                              (selectedProjectProduct.qty ?? 0)
                                            } mins`;
                                          })()}
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                {processTypeGeneralItemView.processTypeGeneralItemSubProcesses.map(
                                  (processTypeGeneralItemSubProcessView) => {
                                    const foundpanelTemplateProcessTypeGeneralItemSubProcess =
                                      panelTemplateProcessTypeGeneralItemSubProcesses.find(
                                        (
                                          panelTemplateProcessTypeGeneralItemSubProcess
                                        ) =>
                                          panelTemplateProcessTypeGeneralItemSubProcess
                                            .processTypeGeneralItemSubProcess
                                            ?.id ===
                                          processTypeGeneralItemSubProcessView
                                            .processTypeGeneralItemSubProcess
                                            ?.id
                                      );

                                    const mappedWorkOrders =
                                      extProjectWorkOrders
                                        .filter(
                                          (extProjectWorkOrder) =>
                                            extProjectWorkOrder.extProductId ===
                                              selectedProjectProduct.id &&
                                            extProjectWorkOrder
                                              .panelTemplateProcessTypeGeneralItemSubProcess
                                              ?.processTypeGeneralItemSubProcess
                                              ?.id ===
                                              processTypeGeneralItemSubProcessView
                                                .processTypeGeneralItemSubProcess
                                                ?.id
                                        )
                                        .reduce(
                                          (acc, extProjectWorkOrder) =>
                                            acc +
                                            (extProjectWorkOrder.qty ?? 0),
                                          0
                                        );

                                    return (
                                      <td
                                        className={`border border-secondary ${
                                          foundpanelTemplateProcessTypeGeneralItemSubProcess
                                            ? mappedWorkOrders >=
                                              (selectedProjectProduct.qty ?? 0)
                                              ? "bg-success text-light"
                                              : ""
                                            : "bg-dark text-light"
                                        }`}
                                      >
                                        <div>
                                          <div className="d-flex">
                                            <div>
                                              <input
                                                type="checkbox"
                                                checked={
                                                  foundpanelTemplateProcessTypeGeneralItemSubProcess
                                                    ? true
                                                    : false
                                                }
                                              />
                                            </div>
                                            <div className="mx-2">
                                              {
                                                processTypeGeneralItemSubProcessView
                                                  .processTypeGeneralItemSubProcess
                                                  ?.name
                                              }
                                            </div>
                                            <div className="ml-2">
                                              <Button
                                                variant="outline-primary"
                                                size="sm"
                                                onClick={() => {
                                                  if (
                                                    foundpanelTemplateProcessTypeGeneralItemSubProcess
                                                  ) {
                                                    setShowWorkersDialog(true);
                                                    setTempExtWorkers([]);
                                                    setSelectedPanelTemplateProcessTypeGeneralItemSubProcess(
                                                      foundpanelTemplateProcessTypeGeneralItemSubProcess
                                                    );
                                                  }
                                                }}
                                              >
                                                Input
                                              </Button>
                                            </div>
                                          </div>
                                          <div className="font-weight-bold">
                                            {foundpanelTemplateProcessTypeGeneralItemSubProcess?.timeMins ??
                                              0}{" "}
                                            mins
                                          </div>
                                          <div className="font-weight-bold">
                                            x{selectedProjectProduct.qty ?? 0} ={" "}
                                            {(foundpanelTemplateProcessTypeGeneralItemSubProcess?.timeMins ??
                                              0) *
                                              (selectedProjectProduct.qty ??
                                                0)}{" "}
                                            mins
                                          </div>
                                          <div className="font-weight-bold">
                                            Mapped {mappedWorkOrders}/
                                            {selectedProjectProduct.qty ?? 0}
                                          </div>
                                        </div>
                                        {foundpanelTemplateProcessTypeGeneralItemSubProcess ? (
                                          <div className="d-flex">
                                            <FormControl
                                              size="sm"
                                              style={{ width: 50 }}
                                              placeholder="Qty"
                                              onChange={(e) => {
                                                if (
                                                  !isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                ) {
                                                  setSelectedWorkOrderQty(
                                                    parseInt(e.target.value)
                                                  );
                                                }
                                              }}
                                            />
                                            <FormControl
                                              className="flex-grow-1"
                                              onInput={(e: any) => {
                                                if (
                                                  e.target.value &&
                                                  e.target.value !== "" &&
                                                  !isNaN(
                                                    parseInt(e.target.value)
                                                  )
                                                ) {
                                                  console.log(
                                                    "Selected subprocess",
                                                    foundpanelTemplateProcessTypeGeneralItemSubProcess.processTypeGeneralItemSubProcess
                                                  );

                                                  const newWorkOrder = {
                                                    ...initialExtProjectWorkOrder,
                                                    uuid: uuidv4(),
                                                    panelTemplateProcessTypeGeneralItemSubProcess:
                                                      foundpanelTemplateProcessTypeGeneralItemSubProcess,
                                                    extProjectId:
                                                      selectedProject?.id ??
                                                      null,
                                                    extUserId: parseInt(
                                                      e.target.value
                                                    ),
                                                    date: `${date}T00:00:00Z`,
                                                    qty: selectedWorkOrderQty,
                                                    extProductId:
                                                      selectedProjectProduct.id,
                                                  };

                                                  setExtProjectWorkOrders([
                                                    ...extProjectWorkOrders,
                                                    { ...newWorkOrder },
                                                  ]);

                                                  setDateWorkOrders([
                                                    ...dateWorkOrders,
                                                    { ...newWorkOrder },
                                                  ]);

                                                  setSelectedWorkOrderQty(1);
                                                }
                                              }}
                                              as="select"
                                            >
                                              <option disabled selected>
                                                Select Operator
                                              </option>
                                              <option>None</option>
                                              {state?.extUsers
                                                .sort((a, b) =>
                                                  (a.name ?? "")?.localeCompare(
                                                    b.name ?? ""
                                                  )
                                                )
                                                .map((extUser) => (
                                                  <option
                                                    value={
                                                      extUser.id ?? undefined
                                                    }
                                                  >
                                                    {extUser.name} (
                                                    {extUser.departmentName})
                                                  </option>
                                                ))}
                                            </FormControl>
                                            <Button size="sm">Add</Button>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    );
                                  }
                                )}
                              </tr>
                            );
                          }
                        )}
                      </Table>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      ) : (
        <></>
      )}

      <hr />

      <div>
        <h5>Work Orders</h5>
      </div>

      <div
        className="border border-secondary overflow-auto my-2"
        style={{
          height: "50vh",
          resize: "vertical",
        }}
      >
        <Table size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr>
              {[
                "Action",
                "Project",
                "Product",
                "Process",
                "Item",
                "Sub Process",
                "Worker",
                "Workers",
                "Qty",
                "Time (mins)",
                "Time total (mins)",
                // "Workload %",
              ].map((head) => (
                <th
                  className=" bg-secondary text-light"
                  style={{ position: "sticky", top: 0 }}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dateWorkOrders.map((dateWorkOrder) => {
              return (
                <tr>
                  <td className="border border-secondary">
                    <Button
                      onClick={() => {
                        setWorkOrderDeleteIds([
                          ...workOrderDeleteIds,
                          dateWorkOrder.id ?? 0,
                        ]);

                        setDateWorkOrders(
                          dateWorkOrders.filter(
                            (dateWorkOrderX) =>
                              dateWorkOrderX.uuid !== dateWorkOrder.uuid
                          )
                        );

                        setExtProjectWorkOrders(
                          extProjectWorkOrders.filter(
                            (dateWorkOrderX) =>
                              dateWorkOrderX.uuid !== dateWorkOrder.uuid
                          )
                        );

                        const foundPanelTemplateProcessTypeGeneralItemSubProcessWorkers =
                          panelTemplateProcessTypeGeneralItemSubProcessWorkers.filter(
                            (
                              panelTemplateProcessTypeGeneralItemSubProcessWorker
                            ) =>
                              panelTemplateProcessTypeGeneralItemSubProcessWorker.workOrderUuid ===
                              dateWorkOrder.uuid
                          );

                        setPanelTemplateProcessTypeGeneralItemSubProcessWorkers(
                          panelTemplateProcessTypeGeneralItemSubProcessWorkers.filter(
                            (
                              panelTemplateProcessTypeGeneralItemSubProcessWorker
                            ) =>
                              panelTemplateProcessTypeGeneralItemSubProcessWorker.workOrderUuid !==
                              dateWorkOrder.uuid
                          )
                        );

                        setPanelTemplateProcessTypeGeneralItemSubProcessWorkerDeleteIds(
                          [
                            ...panelTemplateProcessTypeGeneralItemSubProcessWorkerDeleteIds,
                            ...foundPanelTemplateProcessTypeGeneralItemSubProcessWorkers.map(
                              (
                                foundPanelTemplateProcessTypeGeneralItemSubProcessWorker
                              ) =>
                                foundPanelTemplateProcessTypeGeneralItemSubProcessWorker.id ??
                                0
                            ),
                          ]
                        );
                      }}
                      size="sm"
                      variant="danger"
                    >
                      <Delete fontSize="inherit" />
                    </Button>
                  </td>
                  <td className="border border-secondary">
                    {
                      state?.projectsIdName.find(
                        (extProjectIdName) =>
                          extProjectIdName.id === dateWorkOrder.extProjectId
                      )?.name
                    }
                  </td>
                  <td className="border border-secondary">
                    {
                      state?.projectProductsIdName.find(
                        (projectProductIdName) =>
                          projectProductIdName.id === dateWorkOrder.extProductId
                      )?.name
                    }
                  </td>
                  <td className="border border-secondary">
                    {
                      dateWorkOrder
                        ?.panelTemplateProcessTypeGeneralItemSubProcess
                        ?.processTypeGeneralItemSubProcess
                        ?.processTypeGeneralItem?.processTypeGeneral?.name
                    }
                  </td>
                  <td className="border border-secondary">
                    {
                      dateWorkOrder
                        ?.panelTemplateProcessTypeGeneralItemSubProcess
                        ?.processTypeGeneralItemSubProcess
                        ?.processTypeGeneralItem?.name
                    }
                  </td>
                  <td className="border border-secondary">
                    {
                      dateWorkOrder
                        ?.panelTemplateProcessTypeGeneralItemSubProcess
                        ?.processTypeGeneralItemSubProcess?.name
                    }
                  </td>
                  <td className="border border-secondary">
                    {
                      state?.extUsers.find(
                        (extUser) => extUser.id === dateWorkOrder.extUserId
                      )?.name
                    }
                  </td>
                  <td className="border border-secondary">
                    {panelTemplateProcessTypeGeneralItemSubProcessWorkers
                      .filter(
                        (panelTemplateProcessTypeGeneralItemSubProcessWorker) =>
                          panelTemplateProcessTypeGeneralItemSubProcessWorker?.workOrderUuid ===
                          dateWorkOrder?.uuid
                      )
                      ?.map(
                        (
                          panelTemplateProcessTypeGeneralItemSubProcessWorker
                        ) => {
                          return state?.extUsers.find(
                            (extUser) =>
                              extUser.id ===
                              panelTemplateProcessTypeGeneralItemSubProcessWorker.extUserId
                          )?.name;
                        }
                      )
                      .join(", ")}
                  </td>

                  <td className="border border-secondary">
                    {dateWorkOrder.qty}
                  </td>
                  <td className="border border-secondary">
                    {dateWorkOrder.panelTemplateProcessTypeGeneralItemSubProcess
                      ?.timeMins ?? 0}
                  </td>
                  <td className="border border-secondary">
                    {(dateWorkOrder
                      .panelTemplateProcessTypeGeneralItemSubProcess
                      ?.timeMins ?? 0) * (dateWorkOrder?.qty ?? 0)}
                  </td>
                  {/* <td className="border border-secondary">
                    {(
                      (((dateWorkOrder
                        .panelTemplateProcessTypeGeneralItemSubProcess
                        ?.timeMins ?? 0) *
                        (dateWorkOrder?.qty ?? 0)) /
                        480) *
                      100
                    ).toFixed(2)}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <hr />
      <div>
        <div className="d-flex">
          <h5>
            Operator Workloads for {makeReadableDateString(new Date(date))}
          </h5>
          <div className="flex-grow-1">
            <FormControl
              value={searchUser}
              onChange={(e) => setSearchUser(e.target.value)}
              size="sm"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
      <div
        className="border border-secondary overflow-auto my-2"
        style={{
          borderCollapse: "separate",
          height: "50vh",
          resize: "vertical",
        }}
      >
        <Table size="sm" style={{ borderCollapse: "separate" }}>
          <thead>
            <tr>
              {["#", "Name", "Department", "Role", "Load"].map((head) => (
                <th
                  className=" bg-secondary text-light"
                  style={{ position: "sticky", top: 0 }}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state?.extUsers
              .filter((extUser) =>
                `${extUser.name?.toLowerCase()}${state?.extDepartments
                  .find(
                    (extDepartment) => extDepartment.id === extUser.departmentId
                  )
                  ?.name?.toLowerCase()}${extUser.roles
                  .map((role) => role.name?.toLowerCase())
                  .join("")}`.includes(searchUser.toLowerCase())
              )
              .map((extUser, i) => {
                const workHours =
                  panelTemplateProcessTypeGeneralItemSubProcessWorkers
                    .filter(
                      (panelTemplateProcessTypeGeneralItemSubProcessWorker) =>
                        panelTemplateProcessTypeGeneralItemSubProcessWorker.extUserId ===
                        extUser.id
                    )
                    .reduce(
                      (
                        acc,
                        panelTemplateProcessTypeGeneralItemSubProcessWorker
                      ) => {
                        return (
                          acc +
                          (panelTemplateProcessTypeGeneralItemSubProcessWorker?.timeMins ??
                            0)
                        );
                      },
                      0
                    );

                return (
                  <tr>
                    <td className="border border-secondary ">{i + 1} </td>
                    <td className="border border-secondary ">{extUser.name}</td>
                    <td className="border border-secondary ">
                      {
                        state.extDepartments.find(
                          (extDepartment) =>
                            extDepartment.id === extUser.departmentId
                        )?.name
                      }
                    </td>
                    <td className="border border-secondary ">
                      {extUser.roles.map((role) => role.name).join(", ")}
                    </td>
                    <td
                      className="border border-secondary font-weight-bold"
                      style={{
                        backgroundColor: checkLoadColorDay(workHours / 60),
                      }}
                    >
                      {((workHours / 480) * 100).toFixed(2)}% (
                      {(workHours / 60).toFixed(2)} hrs)
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {(() => {
        const mappedWorkOrders = extProjectWorkOrders
          .filter(
            (extProjectWorkOrder) =>
              extProjectWorkOrder.extProductId === selectedProjectProduct?.id &&
              extProjectWorkOrder.panelTemplateProcessTypeGeneralItemSubProcess
                ?.processTypeGeneralItemSubProcess?.id ===
                selectedPanelTemplateProcessTypeGeneralItemSubProcess
                  ?.processTypeGeneralItemSubProcess?.id
          )
          .reduce(
            (acc, extProjectWorkOrder) => acc + (extProjectWorkOrder.qty ?? 0),
            0
          );

        const minsMapped = tempExtWorkers.reduce(
          (acc, tempExtWorker) => acc + (tempExtWorker?.timeMins ?? 0),
          0
        );

        const totalMins =
          selectedWorkOrderQty *
          (selectedPanelTemplateProcessTypeGeneralItemSubProcess?.timeMins ??
            0);

        return (
          <Modal
            show={showWorkersDialog}
            onHide={() => {
              setShowWorkersDialog(false);
              setTempExtWorkers([]);
              setSelectedPanelTemplateProcessTypeGeneralItemSubProcess(null);
            }}
          >
            <Modal.Header>
              <Modal.Title>
                Workers for {selectedProjectProduct?.name}:{" "}
                {
                  selectedPanelTemplateProcessTypeGeneralItemSubProcess
                    ?.processTypeGeneralItemSubProcess?.name
                }
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div>
                  Time needed:{" "}
                  <strong>
                    {
                      selectedPanelTemplateProcessTypeGeneralItemSubProcess?.timeMins
                    }{" "}
                    mins
                  </strong>
                </div>
                <div>
                  x{selectedProjectProduct?.qty ?? 1}:{" "}
                  <strong>
                    {(selectedPanelTemplateProcessTypeGeneralItemSubProcess?.timeMins ??
                      0) * (selectedProjectProduct?.qty ?? 1)}{" "}
                    mins
                  </strong>
                </div>
                <div>
                  Mapped{" "}
                  <strong>
                    {mappedWorkOrders + selectedWorkOrderQty}/
                    {selectedProjectProduct?.qty}
                  </strong>
                </div>
                <div>
                  Select workers:{" "}
                  <FormControl
                    as="select"
                    onInput={(e: any) => {
                      if (e.target.value && e.target.value !== "") {
                        const foundExtUser = state?.extUsers.find(
                          (extUser) => `${extUser.id}` === e.target.value
                        );

                        if (
                          foundExtUser &&
                          !tempExtWorkers.find(
                            (tempExtWorker) =>
                              tempExtWorker.extUserId === foundExtUser.id
                          )
                        ) {
                          setTempExtWorkers([
                            ...tempExtWorkers,
                            {
                              ...initialPanelTemplateProcessTypeGeneralItemSubProcessWorker,
                              uuid: uuidv4(),
                              extUserId: foundExtUser.id ?? 0,
                            },
                          ]);
                        } else {
                        }
                      }
                    }}
                  >
                    <option selected disabled>
                      None
                    </option>
                    <option>None</option>

                    {state?.extUsers
                      .filter(
                        (extUser) =>
                          ["prod", "wiring", "eng", "hardware", "instal"]
                            .map((relevantDeptName) =>
                              extUser.departmentName
                                ?.toLowerCase()
                                .includes(relevantDeptName.toLowerCase())
                            )
                            .filter((includes) => includes).length > 0
                      )
                      ?.sort((a, b) =>
                        (a?.name ?? "")
                          .toLowerCase()
                          .localeCompare(b.name?.toLowerCase() ?? "")
                      )
                      .map((extUser) => {
                        return (
                          <option value={extUser.id ?? undefined}>
                            {extUser?.name} ({extUser.departmentName})
                          </option>
                        );
                      })}
                  </FormControl>
                  <div className="d-flex">
                    Qty:
                    <FormControl
                      value={selectedWorkOrderQty}
                      onChange={(e) =>
                        setSelectedWorkOrderQty(
                          isNaN(parseInt(e.target.value))
                            ? 0
                            : parseInt(e.target.value)
                        )
                      }
                      placeholder="Qty..."
                    />
                  </div>
                  <div
                    className={`ml-2 ${
                      minsMapped < totalMins ? "text-danger" : "text-success"
                    }`}
                  >
                    Time mins mapped:{" "}
                    <strong>
                      {minsMapped}/{totalMins}
                    </strong>
                  </div>
                  <div>
                    {tempExtWorkers.map((tempExtWorker) => {
                      const foundUser = state?.extUsers.find(
                        (extUser) => extUser.id === tempExtWorker.extUserId
                      );

                      return (
                        <div className="d-flex align-items-center">
                          <div
                            onClick={() => {
                              setTempExtWorkers(
                                tempExtWorkers.filter(
                                  (tempExtWorkerX) =>
                                    tempExtWorkerX.extUserId !==
                                    tempExtWorker.extUserId
                                )
                              );
                            }}
                            className="flex-grow-1 border border-secondary shadow shadow-md my-2 px-2"
                            style={{ cursor: "pointer" }}
                          >
                            {foundUser?.name} ({foundUser?.departmentName})
                          </div>
                          <div style={{ width: 75 }}>
                            <FormControl
                              placeholder="Time"
                              value={tempExtWorker?.timeMins ?? 0}
                              onChange={(e) => {
                                setTempExtWorkers(
                                  tempExtWorkers.map((tempExtWorkerX) =>
                                    tempExtWorkerX.uuid === tempExtWorker.uuid
                                      ? {
                                          ...tempExtWorkerX,
                                          timeMins: isNaN(
                                            parseInt(e.target.value)
                                          )
                                            ? 0
                                            : parseInt(e.target.value),
                                        }
                                      : tempExtWorkerX
                                  )
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                disabled={minsMapped < totalMins}
                onClick={() => {
                  const newWorkOrder = {
                    ...initialExtProjectWorkOrder,
                    uuid: uuidv4(),
                    panelTemplateProcessTypeGeneralItemSubProcess:
                      selectedPanelTemplateProcessTypeGeneralItemSubProcess,
                    extProjectId: selectedProject?.id ?? null,
                    // extUserId: parseInt(
                    //   e.target.value
                    // ),
                    date: `${date}T00:00:00Z`,
                    qty: selectedWorkOrderQty,
                    extProductId: selectedProjectProduct?.id ?? 0,
                  };

                  const newWorkers = tempExtWorkers.map((tempExtWorker) => ({
                    ...tempExtWorker,
                    extUserId: tempExtWorker.extUserId,
                    workOrderUuid: newWorkOrder.uuid,
                  }));

                  setPanelTemplateProcessTypeGeneralItemSubProcessWorkers([
                    ...panelTemplateProcessTypeGeneralItemSubProcessWorkers,
                    ...newWorkers,
                  ]);

                  setExtProjectWorkOrders([
                    ...extProjectWorkOrders,
                    { ...newWorkOrder },
                  ]);

                  setDateWorkOrders([...dateWorkOrders, { ...newWorkOrder }]);

                  setSelectedWorkOrderQty(1);
                  setTempExtWorkers([]);
                  setShowWorkersDialog(false);
                }}
              >
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        );
      })()}
    </>
  );
};

export default WorkOrderV2Detail;
