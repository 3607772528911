import { Box, Button, CircularProgress, TextField } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Manufacturer } from "../../../models/model";
import { initialManufacturer } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";

const ManufacturerFormPage = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const apiKey = localStorage.getItem("apiKey");

  const [manufacturer, setManufacturer] = useState<Manufacturer>({
    ...initialManufacturer,
  });
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );

  useEffect(() => {
    if (id !== undefined) {
      if (!isNaN(parseInt(id))) {
        fetchManufacturerData(parseInt(id));
      }
    }
  }, []);

  const fetchManufacturerData = async (id: number) => {
    try {
      setRequestStatus(RequestStatus.Loading);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/manufacturers/${id}`
      );
      if (response.status !== 200) throw "Error fetching manufacturer.";
      setManufacturer(await response.json());
      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeManufacturerName = (name: string) =>
    setManufacturer({ ...manufacturer, name: name });

  const saveManufacturerData = async () => {
    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/manufacturers`, {
        method: "POST",
        headers: { "content-type": "application/json", "authorization": apiKey ? apiKey : "" },
        body: JSON.stringify(manufacturer)
      });
      if(response.status !== 201) throw "Error saving Manufacturer data.";

      setRequestStatus(RequestStatus.Success);
      history.push("/manufacturers");
    } catch(e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  }

  return (
    <Box>
      <Box display="flex">
        <Box mx={1}>
          <Link to="/manufacturers">
            <Button color="primary">
              <ChevronLeftIcon /> Back
            </Button>
          </Link>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveManufacturerData()}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box>
        {requestStatus === RequestStatus.Loading ? (
          <CircularProgress />
        ) : (
          <>
            <TextField
              label="Name"
              value={manufacturer.name}
              onChange={(evt) => handleChangeManufacturerName(evt.target.value)}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ManufacturerFormPage;
