import React, { useContext, useEffect, useState } from "react";
import { parseJwt } from "../../../helpers";
import { AppContext } from "../../../App";
import { Apps } from "@material-ui/icons";

const Dashboard = () => {
  const apiKey = localStorage.getItem("apiKey");
  const [state, setState] = useState({
    apiKey: apiKey ? apiKey : "",
    parsedJwt: parseJwt(apiKey ? apiKey : ""),
  });

  const appState = useContext(AppContext);

  useEffect(() => {}, []);

  return (
    <div>
      <div>
        <div>
          <h3>Hello!</h3>
        </div>
        <div>
          <h3>Manufacturing User: {state.parsedJwt?.sub}!</h3>
        </div>
        <div>
          <h3>
            GSPE App User:{" "}
            {appState?.gspeAppUser ? (
              <>
                {" "}
                {appState?.gspeAppUser?.username}:{appState?.gspeAppUser?.name}{" "}
                ({appState?.gspeAppUser?.departmentName},{" "}
                {appState?.gspeAppUser?.id})
              </>
            ) : (
              <>
                <strong className="text-danger">None</strong>
              </>
            )}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
