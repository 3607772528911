import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../App";
import { fetchTaskListTemplates } from "../../../helpers";
import {
  MeetingMeetingTaskListTemplates,
  MeetingMeetingTaskTemplate,
} from "../../../masterbigsystem";

const WorkOrderTemplatePage = () => {
  const ctx = useContext(AppContext);

  const [templates, setTemplates] = useState(
    MeetingMeetingTaskListTemplates.fromPartial({ templates: [] })
  );
  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchMeetingMeetingTaskTemplatesData();
  };
  const fetchMeetingMeetingTaskTemplatesData = async () => {
    setTemplates(await fetchTaskListTemplates({ apiKey: ctx?.apiKey ?? "" }));
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h5>WO Templates</h5>
          </div>
          <div>
            <Link to={`/workordertemplates/new`}>
              <button className="btn btn-primary btn-sm">New</button>
            </Link>
          </div>
        </div>
        <hr />
        <div
          className="overflow-auto border border-dark shadow shadow-md"
          style={{ resize: "vertical", height: "75vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            {["Action", "#", "Name", "Items", "Time mins"].map((h) => (
              <th
                className="bg-dark text-light"
                style={{ position: "sticky", top: 0 }}
              >
                {h}
              </th>
            ))}
            {templates.templates.map((t, i) => {
              return (
                <>
                  <tr>
                    {" "}
                    <td className="border border-dark">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={async () => {
                          if (
                            window.confirm(`Really delete template ${t.name}?`)
                          ) {
                            try {
                              const resp = await fetch(
                                `${process.env.REACT_APP_MEETING_URL}/tasklisttemplates/${t.masterJavaBaseModel?.id}/empty`,
                                {
                                  method: "delete",
                                  headers: { authorization: ctx?.apiKey ?? "" },
                                }
                              );
                              window.location.reload();
                            } catch (e) {}
                          }
                        }}
                      >
                        Delete
                      </button>
                    </td>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <Link
                        to={`/workordertemplates/${t.masterJavaBaseModel?.id}`}
                      >
                        {t.name && t.name !== "" ? t.name : "No name"}
                      </Link>
                    </td>
                    <td className="border border-dark">
                      {t.items.length ?? 0}
                    </td>
                    <td className="border border-dark">
                      {t.items.reduce(
                        (acc, i) => acc + (i.workingMins ?? 0.0),
                        0.0
                      )}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default WorkOrderTemplatePage;
