import { RequestStatus } from "./RequestStatus";
import { Page } from "./Page";

export interface CrudInfo<T> extends CrudInfoWithoutData {
  data: Page<T>
}

export interface CrudInfoWithoutData {
  requestStatus: RequestStatus,
  searchInput: string,
  currentPage: number,
  rowsPerPage: number,
  idsToDelete: number[]
}

export const initialCrudInfoNoData: CrudInfoWithoutData = {
  requestStatus: RequestStatus.NotAsked,
  searchInput: '',
  currentPage: 0,
  rowsPerPage: 10,
  idsToDelete: [],
}