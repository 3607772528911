import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../App";
import {
  MasterJavaBaseModel,
  PpicJob,
  PpicNewJobNotification,
  PpicNewJobNotifications,
} from "../../masterbigsystem";
import Select from "react-select";
import { v4 } from "uuid";
import { ExtUser } from "../../models/model";
import { fetchNewJobNotificationsProto } from "../../helpers";

const NewJobNotificationPage = () => {
  const ctx = useContext(AppContext);

  const newJobNotifications = useRef(
    PpicNewJobNotifications.fromPartial({ notifications: [] })
  );

  useEffect(() => {
    handleInit();
  }, []);

  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const fetchNewJobNotificationsProtoData = async () => {
    const d = await fetchNewJobNotificationsProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      newJobNotifications.current = d;
      render();
    }
  };
  const handleInit = async () => {
    fetchNewJobNotificationsProtoData();
  };

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/newjobnotifications-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(newJobNotifications.current),
        }
      );

      if (resp.status !== 200) {
        throw await resp.text();
      }

      alert("Save success");
      window.location.reload();
    } catch (e) {
      alert(e);
    }
  };
  return (
    <>
      <div>
        <div className="d-flex">
          <div>
            <h4>New Job Notification</h4>
          </div>
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              newJobNotifications.current.notifications.push(
                PpicNewJobNotification.fromPartial({
                  masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                    uuid: v4(),
                  }),
                })
              );

              render();
            }}
          >
            Add
          </button>
        </div>
        <div
          className="overflow-auto border border-dark"
          style={{ height: "75vh", resize: "vertical" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate" }}
          >
            <tr>
              {["#", "PIC"].map((h) => {
                return (
                  <>
                    <th className="bg-dark text-light">{h}</th>
                  </>
                );
              })}
            </tr>
            {newJobNotifications.current.notifications.map((n, i) => {
              return (
                <>
                  <tr>
                    <td className="border border-dark">{i + 1}</td>
                    <td className="border border-dark">
                      <Select
                        placeholder="Select user.."
                        options={ctx?.extUsers.map((u) => ({
                          label: `${u.username}`,
                          value: u,
                        }))}
                        value={ctx?.extUsers
                          .map((u) => ({
                            label: `${u.username}`,
                            value: u,
                          }))
                          .find((u) => `${u.value.id}` === `${n.extUserId}`)}
                        onChange={(v) => {
                          const val = v as { value: ExtUser };

                          n.extUserId = val.value.id
                            ? `${val.value.id}`
                            : n.extUserId;

                          render();
                        }}
                      />
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default NewJobNotificationPage;
