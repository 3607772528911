import React, { useRef, useState } from "react";
import { WarehouseItems } from "../../../masterbigsystem";

const PNRequestPage = () => {
  const items = useRef<WarehouseItems | null>(null);
  const [, refresh] = useState(false);

  const render = () => {
    refresh((n) => !n);
  };
  return (
    <>
      <div>
        <div>
          <h4>PN Requests</h4>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
      </div>
    </>
  );
};

export default PNRequestPage;
