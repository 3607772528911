import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { createTextSpan } from "typescript";
import { AppContext } from "../../../App";
import {
  createBomListFlattened,
  fetchBomLeveled,
  fetchBomLeveledsProtoSimple,
  fetchBomPrice,
  fetchCustomersProto,
  fetchExtCrmPurchaseOrdersProto,
  fetchJobsProtoSimple,
  fetchWarehouseItemsProto,
  getItemFullDescription,
  getItemFullDescriptionProto,
  intlFormat,
} from "../../../helpers";
import {
  CrmPurchaseOrders,
  PpicBomLeveleds,
  PpicIndividualEntities,
  PpicIndividualEntity,
  PpicJobs,
  WarehouseItems,
} from "../../../masterbigsystem";
import {
  BomLeveled,
  BomLeveledRecursive,
  ExtItem,
} from "../../../models/model";
import BomDocumentJobAsync from "./BomDocumentJobAsync";

const BomDocumentHierarchy = () => {
  const ctx = useContext(AppContext);
  const [bomLeveleds, setBomLeveleds] = useState<BomLeveledRecursive[]>([]);
  const bomLeveledsProto = useRef(PpicBomLeveleds.fromPartial({}));
  const [searchByName, setSearchByName] = useState("");
  const [viewType, setViewType] = useState<"List" | "Comparator">("List");
  const [selectedBom1, setSelectedBom1] = useState<BomLeveledRecursive | null>(
    null
  );
  const [selectedBom2, setSelectedBom2] = useState<BomLeveledRecursive | null>(
    null
  );
  const [bomLoading, setBomLoading] = useState(false);
  const [items, setItems] = useState<WarehouseItems>(
    WarehouseItems.fromPartial({ items: [] })
  );
  const pos = useRef(CrmPurchaseOrders.fromPartial({ purchaseOrders: [] }));

  const [itemsLoading, setItemsLoading] = useState(false);
  const [customers, setCustomers] = useState<PpicIndividualEntities>(
    PpicIndividualEntities.fromPartial({ entities: [] })
  );

  const jobs = useRef(PpicJobs.fromPartial({ jobs: [] }));
  const jobsLoading = useRef(false);
  const selectedCustomer = useRef(null as PpicIndividualEntity | null);
  useEffect(() => {
    handleInit();
  }, []);

  const BomPriceView = (props: { id: any }) => {
    const [loading, setLoading] = useState(false);
    const [price, setPrice] = useState(null as any);

    const fetchBomPriceData = async () => {
      setLoading(true);
      const d = await fetchBomPrice({
        apiKey: ctx?.apiKey ?? "",
        id: props.id,
      });

      if (d) {
        setPrice(d);
      }

      setLoading(false);
    };

    useEffect(() => {
      fetchBomPriceData();
    }, []);

    return (
      <>
        <div>
          {loading ? (
            <>
              <div className="spinner-border spinner-border-sm"></div>
            </>
          ) : (
            <>{price}</>
          )}
        </div>
      </>
    );
  };

  const handleInit = async () => {
    try {
      fetchJobsData();
      fetchCustomersData();
      fetchBomLeveledsProtoData();
      fetchItemsData();
      fetchPosData();

      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/bomleveled?recursive=false`,
        { headers: { authorization: ctx?.apiKey ?? "" } }
      );

      if (resp.status !== 200) throw await resp.text();

      setBomLeveleds(await resp.json());
    } catch (e) {
      console.log("[error handle init]", e);
    }
  };

  const fetchItemsData = async () => {
    setItemsLoading(true);
    setItems(await fetchWarehouseItemsProto({ apiKey: ctx?.apiKey ?? "" }));
    setItemsLoading(false);
  };
  const fetchCustomersData = async () => {
    setCustomers(await fetchCustomersProto({ apiKey: ctx?.apiKey ?? "" }));
  };
  const fetchPosData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });

    if (d) {
      pos.current = d;
      render();
    }
  };

  const fetchBomLeveledsProtoData = async () => {
    const d = await fetchBomLeveledsProtoSimple({ apiKey: ctx?.apiKey ?? "" });

    if (d) {
      bomLeveledsProto.current = d;
      render();
    }
  };

  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const fetchJobsData = async () => {
    jobsLoading.current = true;
    render();
    const d = await fetchJobsProtoSimple({
      apiKey: ctx?.apiKey ?? "",
      withProducts: true,
      withPurchaseOrders: true,
      all: true,
    });

    if (d) {
      jobs.current = d;
    }
    jobsLoading.current = false;
    render();
  };

  const selectedBomListFlattened1 = selectedBom1
    ? createBomListFlattened([], [selectedBom1])
    : [];
  const selectedBomListFlattened2 = selectedBom2
    ? createBomListFlattened([], [selectedBom2])
    : [];

  const missingItemsBom2 = selectedBomListFlattened2.filter(
    (b2) =>
      !selectedBomListFlattened1.find(
        (b1) =>
          `${b2.bom.bom?.bomLeveled?.extItemId}` ===
          `${b1.bom.bom?.bomLeveled?.extItemId}`
      )
  );

  const mapSelectedBomListFlattenedToView = (
    b: {
      bom: {
        bom: BomLeveledRecursive | null;
        multiplier?: number | null | undefined;
      };
      parent: BomLeveled | null;
    }[],
    missingItems: boolean
  ) => {
    return (
      <>
        {[
          ...new Set(
            b
              .filter(
                (i) =>
                  i.bom.bom?.bomLeveled?.extItemId !== null &&
                  i.bom.bom?.bomLeveled?.extItemId !== undefined
              )
              .map((l) => l.bom.bom?.bomLeveled?.extItemId)
          ),
        ]
          .map((id) => ({
            item: items.items.find((i) => `${i.id}` === `${id}`),
            value: b
              .filter((bx) => bx.bom.bom?.bomLeveled?.extItemId === id)
              .reduce((acc, b) => acc + (b.bom.multiplier ?? 0), 0),
          }))
          .map((bx, i) => {
            const bom2Details = {
              item:
                selectedBomListFlattened2.find(
                  (bxx) =>
                    `${bxx.bom.bom?.bomLeveled?.extItemId}` === `${bx.item?.id}`
                ) && items.items.find((i) => `${i.id}` === `${bx.item?.id}`),
              value: selectedBomListFlattened2
                .filter(
                  (bxx) =>
                    `${bxx.bom.bom?.bomLeveled?.extItemId}` === `${bx.item?.id}`
                )
                .reduce((acc, b) => acc + (b.bom.multiplier ?? 0), 0),
            };

            return (
              <>
                <tr className={`${missingItems ? `bg-warning` : ``}`}>
                  <td className="border border-dark">{i + 1}</td>
                  <td className="border border-dark">
                    {itemsLoading ? (
                      <>
                        <Spinner animation="border" />
                      </>
                    ) : (
                      <>
                        <div>{bx.item?.partName}</div>
                        <div>
                          <small>{bx.item?.partDesc}</small>
                        </div>
                      </>
                    )}
                  </td>
                  <td className="border border-dark">{bx.item?.partNum}</td>
                  <td className="border border-dark">{bx.value?.toFixed(1)}</td>
                  <td
                    className={`border border-dark ${
                      bom2Details.item
                        ? `${bx.value.toFixed(1)}` ===
                          `${bom2Details.value.toFixed(1)}`
                          ? ``
                          : `bg-warning`
                        : `bg-danger`
                    }`}
                  >
                    {/* {found2?.bom.multiplier?.toFixed(1)} */}
                    {bom2Details.value.toFixed(1)}
                  </td>
                  <td className="border border-dark">{bx.item?.defaultUm}</td>
                </tr>
              </>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div className="m-3">
        <div>
          <h5>BOM Documents Hierarchy</h5>
        </div>
        <hr />
        <div className="d-flex">
          {(["List", "Comparator"] as ("List" | "Comparator")[]).map((h) => {
            return (
              <div className="mx-2">
                <button
                  onClick={() => {
                    setViewType(h);
                  }}
                  className={`btn btn-sm ${
                    viewType === h ? `btn-primary` : `btn-outline-primary`
                  }`}
                >
                  {h}
                </button>
              </div>
            );
          })}
        </div>
        {viewType === "List" ? (
          <>
            {" "}
            <div>
              <div className="my-1  d-flex">
                <Link to={`/bomdocumentshierarchy/new`}>
                  <button className="btn btn-sm btn-primary">
                    <Add /> Add
                  </button>{" "}
                </Link>

                <div className="flex-grow-1">
                  <input
                    className="form-control form-control-sm w-100"
                    placeholder="Search by name...."
                    onChange={(e) => {
                      setSearchByName(e.target.value);
                    }}
                  />
                </div>

                <div className="flex-grow-1">
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    options={
                      customers.entities.map((c) => ({
                        label: c.name,
                        value: c,
                      })) ?? []
                    }
                    getOptionLabel={(c) => `${c.value.name}`}
                    placeholder="Customer..."
                    onChange={(c) => {
                      if (c) {
                        const val = c as { value: PpicIndividualEntity };
                        selectedCustomer.current = val.value;
                        render();
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className="overflow-auto border border-dark shadow-md"
                style={{ height: "65vh", resize: "vertical" }}
              >
                <table
                  className="table-bordered table-hover table table-striped table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {[
                      "#",
                      "Status",
                      "Name",
                      "Customer",
                      "Items",
                      "Revision",
                      "Last Snapshot Price",
                      "Created",
                      "Output Item",
                      "Used in job",

                      "Mech",
                      "Elec",
                    ].map((h) => (
                      <th
                        className="sticky-top bg-dark text-light"
                        style={{ zIndex: 1 }}
                      >
                        {h}
                      </th>
                    ))}
                    {/* <th>#</th>
              <th>Name</th>
              <th>Type</th> */}
                  </tr>
                  {/* {JSON.stringify(selectedCustomer.current)} */}
                  {bomLeveledsProto.current.bomLeveleds
                    .filter(
                      (b) =>
                        `${(b?.name ?? "")?.toLowerCase()}`.includes(
                          searchByName.toLowerCase()
                        ) &&
                        (selectedCustomer.current
                          ? b.entities?.find(
                              (i) =>
                                `${i.individualEntityId}` ===
                                `${selectedCustomer.current?.masterJavaBaseModel?.id}`
                            )
                          : true)
                    )
                    .map((b, i) => {
                      return (
                        <tr>
                          <td className="border-dark">{i + 1}</td>
                          <td
                            className={`border-dark text-light ${
                              b?.deactivationDate ? `bg-danger` : `bg-success`
                            }`}
                          >
                            {b?.deactivationDate
                              ? `Inactive (${intlFormat({
                                  date: b?.deactivationDate,
                                  dateStyle: "medium",
                                })})`
                              : `Active`}
                          </td>
                          <td className="border-dark">
                            <Link
                              to={`/bomdocumentshierarchy/${b?.masterJavaBaseModel?.id}`}
                            >
                              {b.name && b.name !== "" ? b.name : "[NO NAME]"}
                            </Link>
                          </td>
                          <td className="border-dark">
                            <div>
                              <ol>
                                {b.entities?.map((blie) => {
                                  return (
                                    <>
                                      <li>
                                        <div>
                                          {customers.entities.find(
                                            (c) =>
                                              `${c.masterJavaBaseModel?.id}` ===
                                              `${blie.individualEntityId}`
                                          )?.name ?? "No cust"}
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                              </ol>
                            </div>
                          </td>
                          <td className="border-dark">{b?.itemsCount}</td>
                          <td className="border-dark">
                            {b.revision}
                            {/* <BomPriceView id={b.bomLeveled?.id} /> */}
                          </td>
                          <td
                            className={`border-dark ${
                              b?.snapshotPriceLast ? `bg-success` : `bg-danger`
                            }`}
                          >
                            {b?.snapshotPriceLast ? (
                              <>
                                {intlFormat({
                                  date: b.snapshotPriceLast,
                                  dateStyle: "medium",
                                  timeStyle: "short",
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="border-dark">
                            {intlFormat({
                              date: b.masterJavaBaseModel?.createdAt,
                              dateStyle: "medium",
                              timeStyle: "short",
                            })}
                          </td>

                          <td className="border-dark">
                            {b?.extItemToCreateId ? (
                              <>
                                {/* (#{b?.extItemToCreateId}) */}
                                {itemsLoading ? (
                                  <>Loading...</>
                                ) : (
                                  getItemFullDescriptionProto(
                                    items.items.find(
                                      (i) =>
                                        `${i.id}` === `${b?.extItemToCreateId}`
                                    ),
                                    { showId: false }
                                  )
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </td>

                          <td className="border-dark">
                            {/* <BomDocumentJobAsync bomId={b.bomLeveled?.id} /> */}
                            {jobsLoading.current ? (
                              <>Loading...</>
                            ) : (
                              <>
                                <ol>
                                  {jobs.current.jobs
                                    .filter((j) =>
                                      j.jobBomLeveleds.find(
                                        (jbl) =>
                                          `${jbl.bomLeveledId}` ===
                                          `${b?.masterJavaBaseModel?.id}`
                                      )
                                    )
                                    .map((j) => {
                                      return (
                                        <>
                                          <li>
                                            {j.name} (
                                            {j.jobBomLeveleds
                                              .filter(
                                                (jbl) =>
                                                  `${jbl.bomLeveledId}` ===
                                                  `${b?.masterJavaBaseModel?.id}`
                                              )
                                              .map((jbl) => {
                                                const foundPanelCode =
                                                  jobs.current.jobs
                                                    .flatMap(
                                                      (j) => j.panelCodes
                                                    )
                                                    .find(
                                                      (c) =>
                                                        `${c.masterJavaBaseModel?.id}` ===
                                                        `${jbl.extPanelCodeId}`
                                                    );

                                                return `${
                                                  foundPanelCode?.type ?? ""
                                                }:${
                                                  foundPanelCode?.name ?? ""
                                                }`;
                                              })}
                                            ) (
                                            {j.jobPurchaseOrders.map((p) => {
                                              const foundPO =
                                                pos.current.purchaseOrders.find(
                                                  (px) =>
                                                    `${px.id}` ===
                                                    `${p.extPurchaseOrderId}`
                                                );
                                              return `${foundPO?.purchaseOrderNumber} (${foundPO?.account?.name})`;
                                            })}
                                            )
                                          </li>
                                        </>
                                      );
                                    })}
                                </ol>
                              </>
                            )}
                          </td>

                          <td
                            className={`border-dark ${
                              b?.mech ? `bg-success` : `bg-danger`
                            }`}
                          ></td>
                          <td
                            className={`border-dark ${
                              b?.elec ? `bg-success` : `bg-danger`
                            }`}
                          ></td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {viewType === "Comparator" ? (
          <>
            <div>
              <hr />
              <div className="d-flex">
                <div className="flex-grow-1">
                  <Select
                    placeholder="Select BOM 1..."
                    options={bomLeveleds ?? []}
                    getOptionLabel={(b) => b.bomLeveled?.name ?? "No name"}
                    onChange={async (b) => {
                      const val = b as BomLeveledRecursive;

                      setBomLoading(true);
                      setSelectedBom1(
                        await fetchBomLeveled({
                          apiKey: ctx?.apiKey ?? "",
                          id: val.bomLeveled?.id,
                        })
                      );
                      setBomLoading(false);
                    }}
                  />
                </div>
                <div className="flex-grow-1">
                  <Select
                    placeholder="Select BOM 2..."
                    options={bomLeveleds ?? []}
                    getOptionLabel={(b) => b.bomLeveled?.name ?? "No name"}
                    onChange={async (b) => {
                      const val = b as BomLeveledRecursive;

                      setBomLoading(true);
                      setSelectedBom2(
                        await fetchBomLeveled({
                          apiKey: ctx?.apiKey ?? "",
                          id: val.bomLeveled?.id,
                        })
                      );
                      setBomLoading(false);
                    }}
                  />
                </div>
              </div>
              <hr />
              <div>
                {bomLoading ? (
                  <>
                    <Spinner animation="border" />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <table className="table table-sm">
                <tr>
                  {["#", "Part Name", "P/N", "Qty", "vs Qty", "UM"].map((h) => (
                    <th className="bg-dark font-weight-bold text-light">{h}</th>
                  ))}
                </tr>
                {mapSelectedBomListFlattenedToView(
                  selectedBomListFlattened1,
                  false
                )}
                {mapSelectedBomListFlattenedToView(missingItemsBom2, true)}
              </table>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default BomDocumentHierarchy;
