import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  blue,
  green,
  lightBlue,
  purple,
  red,
  yellow,
} from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useContext, useEffect, useState } from "react";
import { Layer, Line, Rect, Stage, Text } from "react-konva";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../App";
import {
  compareAssignedAndNeededQty,
  dateToSecs,
  secondsToHms,
} from "../../../helpers";
import {
  AdminSetup,
  FepDocument,
  FepMulti,
  Job,
  JobMapped,
  Machine,
  PartToPart,
  ProcessType,
  Setting,
  User,
  WorkOrderRev,
} from "../../../models/model";
import { initialWorkOrderRev } from "../../../models/modelinitials";
import { RequestStatus } from "../../../models/RequestStatus";
import SyncAutocompleteName from "../../mini-components/CrudTable/components/SyncAutocompleteName";

type AmountType = "By Unit" | "By Part";

interface ProcessWithAmount {
  id: number;
  amount: number;
}

const WorkOrderRevAdd = () => {
  const ctx = useContext(AppContext);
  const apiKey = localStorage.getItem("apiKey");

  // Fetches
  const [job, setJob] = useState<JobMapped | null>(null);
  const [selectedFepDocument, setselectedFepDocument] =
    useState<FepDocument | null>(null);

  const [workOrders, setWorkOrders] = useState<WorkOrderRev[]>([]);
  const [machines, setMachines] = useState<Machine[]>([]);
  const [workers, setWorkers] = useState<User[]>([]);
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [adminSetup, setAdminSetup] = useState<AdminSetup | null>(null);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [dailyProcessTypeFilter, setDailyProcessTypeFilter] =
    useState<ProcessType | null>(null);

  // Selections
  const [machine, setMachine] = useState<Machine | null>(null);
  const [processType, setProcessType] = useState<ProcessType | null>(null);
  const [worker, setWorker] = useState<User | null>(null);
  const [fepProcess, setFepProcess] = useState<PartToPart | FepMulti | null>(
    null
  );
  const [autofill, setAutofill] = useState<boolean>(true);
  const [autofillTime, setAutofillTime] = useState<boolean>(true);
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [amountType, setAmountType] = useState<AmountType | null>("By Unit");
  const [filterInput, setFilterInput] = useState<string>("");
  const [processWithAmounts, setProcessWithAmounts] = useState<
    ProcessWithAmount[]
  >([]);

  // Preview
  const [fepPreview, setFepPreview] = useState<boolean>(true);
  const [workOrderPreview, setWorkOrderPreview] = useState<boolean>(true);
  const [ganttPreview, setGanttPreview] = useState<boolean>(true);

  // Deletions
  const [woIdsToDelete, setWoIdsToDelete] = useState<number[]>([]);

  // Work order form
  const [workOrder, setWorkOrder] = useState<WorkOrderRev>({
    ...initialWorkOrderRev,
  });

  // Limitations
  const [breakTime, setBreakTime] = useState<{ start: number; end: number }>({
    start: 43200,
    end: 45000,
  });
  const [workingHour, setWorkingHour] = useState<{
    start: number;
    end: number;
  }>({ start: 25200, end: 79200 });

  const [multiFep, setMultiFep] = useState(true);

  const startX = (window.innerHeight * 2) / 10; // Time starting point, takes 2/10 of the screen
  const timeInterval = (window.innerWidth - startX) / 25; // Px interval from 0 to 24
  const timeLetterPadding = 35;

  const machinesFiltered = machines.filter(
    (machine) => machine.processType?.name === processType?.name
  );
  const fepMultisBuffer: FepMulti[] = [];

  const flattenFepMultis = (fepMulti: FepMulti) => {
    fepMultisBuffer.push(fepMulti);
    fepMulti.children.forEach((child) => flattenFepMultis(child));
  };

  selectedFepDocument?.fepMultis.forEach((fepMulti) =>
    flattenFepMultis(fepMulti)
  );
  console.log("fep multi buffer:", fepMultisBuffer);

  const getProcesses = (processType: ProcessType | null) => {
    if (processType) {
      switch (processType.name) {
        case "Punch":
          return selectedFepDocument?.partToParts;
        case "LVD":
          return selectedFepDocument?.partToParts;
        case "Trumpf":
          return selectedFepDocument?.partToParts;
        case "HSG":
          return selectedFepDocument?.partToParts;

        case "Weld":
          return fepMultisBuffer
            .filter((fepMulti) =>
              fepMulti.fepDetails
                .map((fepDetail) => fepDetail.processType?.name)
                .includes("Weld")
            )
            .filter((fepMulti) => fepMulti.children.length !== 0);
        case "Grind":
          return fepMultisBuffer
            .filter((fepMulti) =>
              fepMulti.fepDetails
                .map((fepDetail) => fepDetail.processType?.name)
                .includes("Grind")
            )
            .filter((fepMulti) => fepMulti.children.length !== 0);
        case "Coat":
          return fepMultisBuffer.filter((fepMulti) => {
            const coat = fepMulti.fepDetails
              .map((fepDetail) => fepDetail.processType?.name)
              .includes("Coat");
            const weld = fepMulti.fepDetails
              .map((fepDetail) => fepDetail.processType?.name)
              .includes("Weld");

            if (coat) {
              if (coat && weld) {
                if (fepMulti.children.length > 0) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else {
              return false;
            }
          });
        // .filter(fepMulti => fepMulti.children.length !== 0)
        default:
          console.log("Select Process type:", processType);

          return fepMultisBuffer.filter((fepMulti) =>
            fepMulti.fepDetails
              .map((fepDetail) => fepDetail.processType?.name)
              .includes(processType.name)
          );
      }
    } else return [];
  };

  const processes = getProcesses(processType);

  const { date } = useParams() as { date: string };
  const history = useHistory();

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    setRequestStatus(RequestStatus.Loading);

    await Promise.all([
      ...(date ? [fetchWorkOrders(date)] : []),
      ...[
        fetchMachines(),
        fetchProductionWorkers(),
        fetchAdminSetup(),
        fetchProcessTypes(),
        fetchSetting(),
      ],
    ]);

    setRequestStatus(RequestStatus.Success);
  };

  console.log(startX, startX + timeInterval * 24);

  const mapTimeToCoordPx = (time: number) => {
    const startPx = startX;
    const endPx = startX + timeInterval * 24;
    const normalizedTime = time;
    const pxPerSecs = (endPx - startPx) / 86400;

    return normalizedTime * pxPerSecs + startX;
  };

  const mapWidthToWidthPx = (time: number) => {
    const startPx = startX;
    const endPx = startX + timeInterval * 24;
    const pxPerSecs = (endPx - startPx) / 86400;

    return time * pxPerSecs;
  };

  const workOrderData = [
    {
      startTime: 46000,
      endtime: 49000, // full
      machineId: 1,
    },
    {
      startTime: 50000,
      endtime: 51680, // full
      machineId: 1,
    },
    {
      startTime: 60000,
      endtime: 79200, // full
      machineId: 1,
    },
  ];

  const fetchWorkOrders = async (date: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/workorderrevs/bydate?date=${date}`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) throw "Fetching wo rev failed.";

      const woRevJson = await response.json();
      setWorkOrders(woRevJson);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMachines = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/machines`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );
      if (response.status !== 200) throw "Fetching machine error";

      const machines = await response.json();
      setMachines(machines);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchAdminSetup = async () => {
    try {
      const resp = await fetch(`${ctx?.baseUrl}/adminsetup`, {
        headers: { authorization: ctx?.apiKey ?? "" },
      });

      if (resp.status !== 200) throw await resp.text();

      setAdminSetup(await resp.json());
    } catch (e) {
      console.error(e);

      return null;
    }
  };

  const fetchProductionWorkers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/roles/byname/Production%20Worker/users`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) throw "Error fetching production workers";

      const workers = await response.json();
      setWorkers(workers);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchJobs = async (inputValue: string) => {
    console.log(inputValue);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/jobs/search/simple?name=${inputValue}&compose=true`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) throw await response.text();

      const data = (await response.json()) as JobMapped[];

      console.log(data);

      return data.map((j) => ({
        label: `${j.job?.name} (${j.fepDocuments?.length} fep(s))`,
        value: j,
      }));
    } catch (e) {
      return [];
    }
  };

  const fetchProcessTypes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/processtypes`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) {
        throw "Error fetching process types.";
      }

      const processTypes = await response.json();
      const processTypesMapped = processTypes.map((processType: any) => ({
        label: processType.name,
        value: processType,
      }));

      setProcessTypes(processTypes);

      return processTypesMapped;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  const fetchSetting = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/setting`,
        {
          headers: { authorization: apiKey ? apiKey : "" },
        }
      );

      if (response.status !== 200) throw "Error fetching setting";

      const settingData: Setting | null | undefined = await response.json();

      console.log("fetched setting data: ", settingData);

      if (settingData) {
        setWorkingHour({
          start: settingData.workingHourStartTime,
          end: settingData.workingHourEndTime,
        });
        setBreakTime({
          start: settingData.breakStartTime,
          end: settingData.breakEndTime,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const selectJob = async (id: number | null) => {
    if (id !== null) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/jobs/${id}/full?compose=true`
        );
        if (response.status !== 200) throw "Failed fetching job" + id;

        const job = (await response.json()) as JobMapped;

        setJob(job);
      } catch (e) {
        console.log(e);
      }
    } else {
      setJob(null);
    }
  };

  const handleDeleteWorkOrder = (workOrderInput: WorkOrderRev) => {
    const newWorkOrders = [...workOrders].filter(
      (workOrder) => workOrder.uuid !== workOrderInput.uuid
    );

    setWoIdsToDelete([...woIdsToDelete, workOrderInput.id]);
    setWorkOrders([...newWorkOrders]);

    if (job) {
      const newJob = { ...job };
      const newJobWorkOrderRevs = [...(newJob.job?.workOrderRevs ?? [])].filter(
        (workOrderRev) => workOrderRev.uuid !== workOrderInput.uuid
      );

      setJob({
        ...job,
        job: job.job
          ? { ...job.job, workOrderRevs: [...newJobWorkOrderRevs] }
          : job.job,
      });
    }
  };

  const TableCellBordered = styled(TableCell)({
    border: 1,
    borderStyle: "solid",
  });

  const PunchView = (props: { processName: string }) => {
    return (
      <Table size="small" style={{ borderCollapse: "separate" }}>
        <TableHead>
          <TableRow
            style={{ backgroundColor: yellow[500], position: "sticky", top: 0 }}
          >
            {[
              "Program",
              "Part Name",
              "Qty assigned",
              "Qty actual",
              "Qty/Program",
              "Qty/Program x Total run",
              "Qty Needed / Unit",
              "Qty Needed Total",
            ].map((head) => (
              <TableCellBordered
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "yellow",
                }}
              >
                {head}
              </TableCellBordered>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedFepDocument?.partToParts
            .filter((partToPart) =>
              partToPart.name.toLowerCase().includes(filterInput)
            )
            .filter(
              (partToPart) => partToPart.processType?.name === props.processName
            )
            .map((partToPart) => {
              const filteredWorkOrders = job?.job?.workOrderRevs
                ?.filter(
                  (workOrder) =>
                    workOrder?.machine?.processType?.name === props.processName
                )
                .filter(
                  (workOrder) => workOrder.partToPart?.id === partToPart.id
                );
              const filteredAmount =
                filteredWorkOrders?.reduce(
                  (acc, workOrder) => acc + workOrder.amount,
                  0
                ) ?? 0;

              const filteredActualAmount =
                filteredWorkOrders?.reduce((acc, workOrder) => {
                  return (
                    acc +
                    workOrder.workOrderActualRevs.reduce((acc, actual) => {
                      return acc + actual.amount;
                    }, 0)
                  );
                }, 0) ?? 0;

              console.log("work orders:", workOrders);
              console.log("filtered work orders:", filteredWorkOrders);

              return (
                <>
                  <TableRow style={{ backgroundColor: lightBlue[50] }}>
                    <TableCellBordered colSpan={8}>
                      <Box display="flex" alignItems="center">
                        <h3>
                          {partToPart.name}
                          {` (${partToPart.length}x${partToPart.width}x${partToPart.height})`}
                          <strong>
                            Time: {secondsToHms(partToPart.timePerUnit)}
                          </strong>
                          &nbsp;|&nbsp;
                          <strong>
                            Program run assigned:&nbsp;
                            <span
                              style={{
                                color:
                                  filteredAmount < partToPart.programRun
                                    ? red[500]
                                    : green[500],
                              }}
                            >
                              {filteredAmount}
                            </span>
                          </strong>
                          &nbsp;|&nbsp;
                          <strong>
                            Actual run:&nbsp;
                            <span>{filteredActualAmount}</span>
                          </strong>
                          &nbsp;|&nbsp;
                          <strong>
                            Program run needed: {partToPart.programRun}
                          </strong>
                        </h3>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();

                            const foundProcessWithAmount = [
                              ...processWithAmounts,
                            ].find((process) => process.id === partToPart.id);

                            const newWorkOrder = {
                              ...workOrder,
                              amount: foundProcessWithAmount
                                ? foundProcessWithAmount.amount
                                : 0,
                            };

                            handleAddWorkOrder(partToPart, newWorkOrder);
                          }}
                        >
                          <Box mx={1} style={{ backgroundColor: "white" }}>
                            <TextField
                              style={{ width: 75 }}
                              variant="outlined"
                              // label="Program run.."
                              type="number"
                              size="small"
                              value={
                                processWithAmounts.find(
                                  (process) => process.id === partToPart.id
                                )?.amount
                              }
                              onChange={(e) => {
                                const newProcessWithAmounts = [
                                  ...processWithAmounts,
                                ];
                                const foundProcess = newProcessWithAmounts.find(
                                  (process) => process.id === partToPart.id
                                );

                                console.log(
                                  "Found process:",
                                  newProcessWithAmounts,
                                  foundProcess
                                );

                                if (foundProcess) {
                                  foundProcess.amount = isNaN(
                                    parseInt(e.target.value)
                                  )
                                    ? 0
                                    : parseInt(e.target.value);
                                  setProcessWithAmounts([
                                    ...newProcessWithAmounts,
                                  ]);
                                }
                              }}
                            />
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Add
                            </Button>
                          </Box>
                        </form>
                        {/* <div>
                          {partToPart?.}
                        </div> */}
                      </Box>
                    </TableCellBordered>
                  </TableRow>
                  {partToPart.partToPartDetails.map((partToPartDetail) => {
                    const totalWorkOrderAmount =
                      filteredWorkOrders?.reduce(
                        (acc, workOrder) =>
                          acc + workOrder.amount * partToPartDetail.qtyProduced,
                        0
                      ) ?? 0;
                    const totalQtyNeeded =
                      partToPartDetail.qtyNeeded *
                      (selectedFepDocument ? selectedFepDocument?.unitQty : 0);

                    const totalWorkOrderActualAmount =
                      filteredWorkOrders?.reduce((acc, workOrder) => {
                        return (
                          acc +
                          workOrder.workOrderActualRevs.reduce(
                            (acc, actual) => {
                              const rejects = actual.workOrderActualRejects
                                .filter(
                                  (reject) =>
                                    reject.item?.id ===
                                    partToPartDetail.item?.id
                                )
                                .reduce((acc, reject) => acc + reject.qty, 0);

                              return (
                                acc +
                                actual.amount * partToPartDetail.qtyProduced -
                                rejects
                              );
                            },
                            0
                          )
                        );
                      }, 0) ?? 0;

                    return (
                      <TableRow>
                        <TableCellBordered>{partToPart.name}</TableCellBordered>
                        <TableCellBordered>
                          {partToPartDetail.item?.partName} (
                          {partToPartDetail?.materialType?.name ?? "No Type"})
                        </TableCellBordered>
                        <TableCellBordered
                          style={{
                            backgroundColor: compareAssignedAndNeededQty(
                              totalWorkOrderAmount,
                              partToPartDetail.qtyProduced *
                                partToPart.programRun
                            ),
                          }}
                        >
                          {totalWorkOrderAmount}
                        </TableCellBordered>
                        <TableCellBordered
                          style={{
                            backgroundColor: compareAssignedAndNeededQty(
                              totalWorkOrderActualAmount,
                              partToPartDetail.qtyProduced *
                                partToPart.programRun
                            ),
                          }}
                        >
                          {totalWorkOrderActualAmount}
                        </TableCellBordered>
                        <TableCellBordered>
                          {partToPartDetail.qtyProduced}
                        </TableCellBordered>
                        <TableCellBordered>
                          {partToPartDetail.qtyProduced * partToPart.programRun}
                        </TableCellBordered>
                        <TableCellBordered>
                          {partToPartDetail.qtyNeeded}
                        </TableCellBordered>
                        <TableCellBordered>{totalQtyNeeded}</TableCellBordered>
                      </TableRow>
                    );
                  })}
                </>
              );
            })}
        </TableBody>
      </Table>
    );
  };

  const ProcessTable = () => {
    switch (processType?.name) {
      case "Punch":
        return <PunchView processName="Punch" />;

      case "LVD":
        return <PunchView processName="LVD" />;

      case "Trumpf":
        return <PunchView processName="Trumpf" />;

      case "HSG":
        return <PunchView processName="HSG" />;

      case "Weld":
        return (
          <Table size="small" style={{ borderCollapse: "separate" }}>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: yellow[500],
                  position: "sticky",
                  top: 0,
                }}
              >
                {[
                  "Part Name",
                  "Length",
                  "Width",
                  "Weld Constant",
                  "Qty Needed / Unit",
                  "Qty Needed",
                  "Time",
                ].map((head) => (
                  <TableCellBordered
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "yellow",
                    }}
                  >
                    {head}
                  </TableCellBordered>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fepMultisBuffer
                .filter((fepMulti) =>
                  fepMulti.item?.partName.toLowerCase().includes(filterInput)
                )
                .filter((fepMulti) =>
                  fepMulti.fepDetails
                    .map((fepDetail) => fepDetail.processType?.name)
                    .includes("Weld")
                )
                .filter((fepMulti) => fepMulti.children.length !== 0)
                .map((fepMulti) => {
                  console.log(
                    "fep multi children " + fepMulti.name,
                    fepMulti.children
                  );
                  const weldDetail = fepMulti.fepDetails.find(
                    (fepDetail) => fepDetail.processType?.name === "Weld"
                  );
                  const filteredWorkOrders = job?.job?.workOrderRevs
                    ?.filter(
                      (workOrder) =>
                        workOrder?.machine?.processType?.name === "Weld"
                    )
                    .filter(
                      (workOrder) => workOrder.fepMulti?.id === fepMulti.id
                    );

                  const totalWorkOrderAmount = filteredWorkOrders
                    ? filteredWorkOrders.reduce(
                        (acc, workOrder) => acc + workOrder.amount,
                        0
                      )
                    : 0;

                  const totalWorkOrderActualAmount = filteredWorkOrders
                    ? filteredWorkOrders.reduce((acc, workOrder) => {
                        return (
                          acc +
                          workOrder.workOrderActualRevs.reduce(
                            (acc, actual) => {
                              const rejectAmount =
                                actual.workOrderActualRejects.reduce(
                                  (acc, reject) => acc + reject.qty,
                                  0
                                );
                              return acc + actual.amount - rejectAmount;
                            },
                            0
                          )
                        );
                      }, 0)
                    : 0;

                  const totalQtyNeeded =
                    fepMulti.qty * (selectedFepDocument?.unitQty ?? 0);

                  return (
                    <>
                      <TableRow>
                        <TableCellBordered
                          style={{ backgroundColor: lightBlue[50] }}
                          colSpan={7}
                        >
                          <Box display="flex" alignItems="center">
                            <h3>
                              {fepMulti.name}&nbsp;|&nbsp; Weld constant:{" "}
                              {weldDetail?.weldValue}&nbsp;|&nbsp;
                              <span
                                style={{
                                  backgroundColor: compareAssignedAndNeededQty(
                                    totalWorkOrderAmount,
                                    totalQtyNeeded
                                  ),
                                }}
                              >
                                <span style={{ padding: "5px" }}>
                                  Qty Assigned:&nbsp;
                                  {totalWorkOrderAmount}
                                </span>
                              </span>
                              &nbsp;|&nbsp;
                              <span
                                style={{
                                  backgroundColor: compareAssignedAndNeededQty(
                                    totalWorkOrderActualAmount,
                                    totalQtyNeeded
                                  ),
                                }}
                              >
                                Qty Actual:&nbsp;
                                {totalWorkOrderActualAmount}
                              </span>
                              &nbsp;|&nbsp; Qty Needed: {totalQtyNeeded}
                              &nbsp;|&nbsp; Time:{" "}
                              {secondsToHms(weldDetail ? weldDetail.time : 0)}
                            </h3>
                            <Box mx={1} style={{ backgroundColor: "white" }}>
                              <TextField
                                label="Amount..."
                                type="number"
                                variant="outlined"
                                size="small"
                                value={
                                  processWithAmounts.find(
                                    (process) => process.id === fepMulti.id
                                  )?.amount
                                }
                                onChange={(e) => {
                                  const newProcessWithAmounts = [
                                    ...processWithAmounts,
                                  ];
                                  const foundProcess = processWithAmounts.find(
                                    (process) => process.id === fepMulti.id
                                  );

                                  console.log("Processes:", processWithAmounts);
                                  console.log("Found process:", foundProcess);

                                  if (foundProcess) {
                                    foundProcess.amount = isNaN(
                                      parseInt(e.target.value)
                                    )
                                      ? 0
                                      : parseInt(e.target.value);
                                    setProcessWithAmounts([
                                      ...newProcessWithAmounts,
                                    ]);
                                  }
                                }}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={(e) => {
                                  const foundProcessWithAmount = [
                                    ...processWithAmounts,
                                  ].find(
                                    (process) => process.id === fepMulti.id
                                  );
                                  const newWorkOrder = {
                                    ...workOrder,
                                    amount: foundProcessWithAmount
                                      ? foundProcessWithAmount.amount
                                      : 0,
                                  };

                                  setFepProcess({ ...fepMulti });
                                  setWorkOrder({ ...newWorkOrder });
                                  handleAddWorkOrder(fepMulti, newWorkOrder);
                                }}
                              >
                                Add
                              </Button>
                            </Box>
                          </Box>
                        </TableCellBordered>
                      </TableRow>
                      {fepMulti.children.map((fepMulti) => (
                        <TableRow>
                          <TableCellBordered>{fepMulti.name}</TableCellBordered>
                          <TableCellBordered>
                            {fepMulti.length}
                          </TableCellBordered>
                          <TableCellBordered>
                            {fepMulti.width}
                          </TableCellBordered>
                          <TableCellBordered>
                            {weldDetail?.weldValue}
                          </TableCellBordered>
                          <TableCellBordered>{fepMulti.qty}</TableCellBordered>
                          <TableCellBordered>
                            {fepMulti.qty * (selectedFepDocument?.unitQty ?? 0)}
                          </TableCellBordered>
                          <TableCellBordered>
                            {secondsToHms(weldDetail ? weldDetail.time : 0)}
                          </TableCellBordered>
                        </TableRow>
                      ))}
                    </>
                  );
                })}
            </TableBody>
          </Table>
        );

      default:
        return (
          <Table size="small" style={{ borderCollapse: "separate" }}>
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "yellow",
                  position: "sticky",
                  top: 0,
                }}
              >
                {[
                  "ID",
                  "Part ID",
                  "Part Name",
                  "Length",
                  "Width",
                  "Assigned",
                  "Actual",
                  "Qty needed / Unit",
                  "Qty needed Total",
                  "Qty WO Input",
                  "Time",
                ].map((head) => (
                  <TableCell
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "yellow",
                    }}
                  >
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fepMultisBuffer
                .filter(
                  (fepMulti) =>
                    fepMulti.item?.partName
                      .toLowerCase()
                      .includes(filterInput) &&
                    fepMulti.fepDetails
                      .map((fepDetail) => fepDetail.processType?.name)
                      .includes(processType?.name) &&
                    // If not cutout, has coat or grind & does not have bend (indicates that this is a welding process):
                    // make sure that fep multi has children
                    (fepMulti.type !== "CUTOUT"
                      ? (fepMulti.fepDetails.find(
                          (fepDetail) => fepDetail.processType?.name === "Coat"
                        ) ||
                          fepMulti.fepDetails.find(
                            (fepDetail) =>
                              fepDetail.processType?.name === "Grind"
                          )) &&
                        !fepMulti.fepDetails.find(
                          (fepDetail) => fepDetail.processType?.name === "Bend"
                        )
                        ? fepMulti.children.length > 0
                        : true
                      : true) &&
                    // ensure that bend does not have children.
                    (fepMulti.type !== "CUTOUT"
                      ? fepMulti.fepDetails.find(
                          (fepDetail) => fepDetail.processType?.name === "Bend"
                        )
                        ? fepMulti.children.length === 0
                        : true
                      : true) &&
                    // If process type is coat or grind, but has [bend, weld, grind, coat]
                    // (indicates that this is an atomic process (not parent)),
                    // don't include.
                    (fepMulti.type !== "CUTOUT"
                      ? processType?.name === "Coat" ||
                        processType?.name === "Grind"
                        ? !(
                            fepMulti.fepDetails.find(
                              (fepDetail) =>
                                fepDetail.processType?.name === "Bend"
                            ) &&
                            fepMulti.fepDetails.find(
                              (fepDetail) =>
                                fepDetail.processType?.name === "Weld"
                            ) &&
                            fepMulti.fepDetails.find(
                              (fepDetail) =>
                                fepDetail.processType?.name === "Grind"
                            ) &&
                            fepMulti.fepDetails.find(
                              (fepDetail) =>
                                fepDetail.processType?.name === "Coat"
                            )
                          )
                        : true
                      : true)
                )
                .map((fepMulti) => {
                  const fepDetail = fepMulti.fepDetails.find(
                    (fepDetail) =>
                      fepDetail.processType?.name === processType?.name
                  );
                  const filteredWorkOrders = job?.job?.workOrderRevs
                    ?.filter(
                      (workOrder) =>
                        workOrder?.machine?.processType?.name ===
                        processType?.name
                    )
                    .filter(
                      (workOrder) => workOrder.fepMulti?.id === fepMulti.id
                    );

                  const totalWorkOrderAmount = filteredWorkOrders
                    ? filteredWorkOrders.reduce(
                        (acc, workOrder) => acc + workOrder.amount,
                        0
                      )
                    : 0;

                  const totalWorkOrderActualAmount = filteredWorkOrders
                    ? filteredWorkOrders.reduce((acc, workOrder) => {
                        return (
                          acc +
                          workOrder.workOrderActualRevs.reduce(
                            (acc, actual) => {
                              const rejectAmount =
                                actual.workOrderActualRejects.reduce(
                                  (acc, reject) => acc + reject.qty,
                                  0
                                );
                              return acc + actual.amount - rejectAmount;
                            },
                            0
                          )
                        );
                      }, 0)
                    : 0;

                  const totalQtyNeeded =
                    fepMulti.qty * (selectedFepDocument?.unitQty ?? 0);

                  return (
                    <TableRow>
                      <TableCell>{fepMulti.id}</TableCell>
                      <TableCell>{fepMulti.item?.id}</TableCell>

                      <TableCell>
                        {fepMulti.item?.partName}{" "}
                        {fepMulti.fepDetails
                          .map((fepDetail) => fepDetail.processType?.name)
                          .join(", ")}{" "}
                      </TableCell>
                      <TableCell>{fepMulti.length}</TableCell>
                      <TableCell>{fepMulti.width}</TableCell>
                      <TableCell
                        style={{
                          backgroundColor: compareAssignedAndNeededQty(
                            totalWorkOrderAmount,
                            totalQtyNeeded
                          ),
                        }}
                      >
                        {totalWorkOrderAmount}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: compareAssignedAndNeededQty(
                            totalWorkOrderActualAmount,
                            totalQtyNeeded
                          ),
                        }}
                      >
                        {totalWorkOrderActualAmount}
                      </TableCell>
                      <TableCell>{fepMulti.qty}</TableCell>
                      <TableCell>{totalQtyNeeded}</TableCell>
                      <TableCell>
                        <Box display="flex">
                          <TextField
                            value={
                              processWithAmounts.find(
                                (process) => process.id === fepMulti.id
                              )?.amount
                            }
                            size="small"
                            variant="outlined"
                            label="Amount"
                            onChange={(evt) => {
                              const newProcessWithAmounts = [
                                ...processWithAmounts,
                              ];
                              const foundProcessWithAmount =
                                newProcessWithAmounts.find(
                                  (process) => process.id === fepMulti.id
                                );

                              if (foundProcessWithAmount)
                                foundProcessWithAmount.amount = isNaN(
                                  parseInt(evt.target.value)
                                )
                                  ? 0
                                  : parseInt(evt.target.value);

                              setProcessWithAmounts([...newProcessWithAmounts]);
                            }}
                          />
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={(evt) => {
                              const foundProcessWithAmount =
                                processWithAmounts.find(
                                  (process) => process.id === fepMulti.id
                                );
                              const newWorkOrder = {
                                ...workOrder,
                                amount: foundProcessWithAmount
                                  ? foundProcessWithAmount.amount
                                  : 0,
                              };

                              // console.log("fep process:", fepMulti);
                              setFepProcess({ ...fepMulti });
                              setWorkOrder({ ...newWorkOrder });
                              // console.log("work order: ", workOrder, "new work order", newWorkOrder);
                              handleAddWorkOrder(fepMulti, newWorkOrder);
                            }}
                          >
                            Add
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {secondsToHms(fepDetail ? fepDetail.time : 0)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        );
    }
  };

  const handleAddWorkOrder = (
    fepProcess: PartToPart | FepMulti | null,
    workOrder: WorkOrderRev
  ) => {
    if (
      // By Unit
      processType?.name !== "Punch" &&
      processType?.name !== "Trumpf" &&
      processType?.name !== "LVD" &&
      processType?.name !== "HSG" &&
      amountType === "By Unit"
    ) {
      console.log("Selected: By Unit");
      console.log("Filtered work order processes:", processes);

      let newWorkOrders = [] as WorkOrderRev[];
      let workOrdersBuffer: WorkOrderRev[] = [...workOrders];
      let machineResult: Machine | null = null;
      let startTimeResult = autofillTime
        ? adminSetup?.checkInTime ?? 0
        : // ? workOrders
          //     .filter(workOrder => workOrder.machine?.processType?.id === processType?.id)
          dateToSecs(startTime); // 7AM

      if (processes) {
        (processes as FepMulti[]).forEach((fepMulti) => {
          const foundFepDetail = fepMulti.fepDetails.find(
            (fepDetail) => fepDetail.processType?.name === processType?.name
          );
          const totalProcessTime = foundFepDetail
            ? foundFepDetail.time * workOrder.amount
            : 0;

          machines
            .filter(
              (machine) => machine.processType?.name === processType?.name
            )
            .reverse()
            .forEach((machine) => {
              console.log("machine:", machine);

              const workOrdersByMachine = workOrdersBuffer.filter(
                (workOrder) => workOrder.machine?.id === machine.id
              );

              const timeList = workOrdersByMachine.map(
                (workOrder) => workOrder.endTime
              );
              const max = (() => {
                if (timeList.length === 0)
                  return Math.max(
                    autofillTime
                      ? adminSetup?.checkInTime ?? 0
                      : dateToSecs(startTime)
                  );
                else return Math.max(...timeList);
              })();

              // Peep if overlaps break time
              const startTimeFinal = (() => {
                const startTime = max;
                const endTimeCheck = startTime + totalProcessTime;

                // Check if process time overlaps break time
                const processTimeArr: number[] = [];
                const breakTimeArr: number[] = [];

                for (let i = startTime; i < endTimeCheck; ++i)
                  processTimeArr.push(i);

                for (
                  let i = adminSetup?.breakStartTime ?? 0;
                  i < (adminSetup?.breakEndTime ?? 0);
                  ++i
                )
                  breakTimeArr.push(i);

                return processTimeArr
                  .filter((processSecs) => breakTimeArr.includes(processSecs))
                  .filter((overlap) => overlap).length > 0
                  ? (adminSetup?.breakEndTime ?? 0) + 1
                  : startTime;
              })();

              // if(startTimeFinal + totalProcessTime <= 79200) {
              if (
                startTimeFinal + totalProcessTime <=
                (adminSetup?.checkOutTime ?? 0)
              ) {
                console.log("criteria success!", machine, max);

                machineResult = machine;
                startTimeResult = startTimeFinal;
              }
            });

          const endTimeFinal = startTimeResult + totalProcessTime;

          if (autofill && machineResult === null) {
            alert("Criteria not succeeded.");
          } else {
            console.log(
              "Machine result: ",
              machineResult,
              startTimeResult,
              endTimeFinal
            );
          }

          const newWorkOrder: WorkOrderRev = {
            ...workOrder,
            uuid: uuidv4(),
            machine: autofill ? machineResult : machine,
            // amount: workOrder.amount,
            amount: workOrder.amount * fepMulti.qty,
            fepMulti: fepMulti,
            partToPart: null,
            jobName: job?.job?.name ?? "",
            jobId: job?.job?.id ?? 0,
            date: date ? date : `${new Date().getFullYear()}-01-01`,
            startTime: startTimeResult,
            endTime: endTimeFinal,
          };

          startTimeResult = startTimeResult + totalProcessTime;
          console.log("Start time: ", startTimeResult);
          // workOrdersBuffer = [{...newWorkOrder}, ...workOrdersBuffer];
          workOrdersBuffer.push(newWorkOrder);
          newWorkOrders.unshift(newWorkOrder);

          // if(job !== null) {
          //   setJob({
          //     ...job,
          //     workOrderRevs: [{...newWorkOrder}, ...job.workOrderRevs]});
          // }

          machineResult = null;
        });
      }

      setWorkOrders(workOrdersBuffer);

      if (job !== null) {
        setJob({
          ...job,
          job: job.job
            ? {
                ...job.job,
                workOrderRevs: job.job.workOrderRevs
                  ? [...job.job.workOrderRevs, ...newWorkOrders]
                  : job.job.workOrderRevs,
              }
            : job.job,
        });
      }

      // Normalize
      setFepProcess(null);
      setWorkOrder({ ...workOrder, workers: [], amount: 0, machine: null });
      setWorker(null);
      setMachine(null);

      console.log("Final work orders:", workOrdersBuffer);
    } else {
      // By Part
      console.log("Selected: By Part");
      console.log("start time:", startTime);
      console.log(
        "start time:",
        startTime?.getHours(),
        startTime?.getMinutes(),
        startTime?.getSeconds()
      );
      console.log("FEP Process:", fepProcess);

      const fepProcessTime = (() => {
        if (fepProcess) {
          return (processType?.name === "Punch" ||
            processType?.name === "LVD" ||
            processType?.name === "Trumpf" ||
            processType?.name === "HSG") &&
            fepProcess !== null
            ? (fepProcess as PartToPart).timePerUnit
            : (fepProcess as FepMulti).fepDetails?.find(
                (fepDetail) => fepDetail.processType?.name === processType?.name
              )?.time;
        } else {
          return 0;
        }
      })();

      const totalProcessTime = fepProcessTime
        ? fepProcessTime * workOrder.amount
        : 0;

      const startTimeSecs = dateToSecs(startTime);

      const autofillResult = ((): {
        machine: Machine | null;
        startTime: number;
      } => {
        let machineResult = null;
        let startTimeResult = adminSetup?.checkInTime ?? 0; // Start time at 7AM

        machines
          .filter((machine) => machine.processType?.name === processType?.name)
          .reverse()
          .forEach((machine) => {
            console.log("machine:", machine);
            const workOrdersByMachine = workOrders.filter(
              (workOrder) => workOrder.machine?.id === machine.id
            );

            const timeList = workOrdersByMachine.map(
              (workOrder) => workOrder.endTime
            );
            const max = (() => {
              if (timeList.length === 0)
                return Math.max(adminSetup?.checkInTime ?? 0);
              else return Math.max(...timeList);
            })();

            // if(max + totalProcessTime <= 79200) {
            if (max + totalProcessTime <= (adminSetup?.checkOutTime ?? 0)) {
              console.log("criteria success!", machine, max);

              machineResult = machine;
              startTimeResult = max;
            }
          });

        return { machine: machineResult, startTime: startTimeResult };
      })();

      console.log("autofil result:", autofillResult);

      // If autofill & machineResult is null, alert and return
      if (autofill && autofillResult.machine === null) {
        alert("No results satisfied!");
        throw "No results satisfied";
      }

      const startTimeFinal = (() => {
        const startTime = autofillTime
          ? autofillResult.startTime
          : startTimeSecs;
        const endTimeCheck = startTime + totalProcessTime;

        // Check if process time overlaps break time
        const processTimeArr: number[] = [];
        const breakTimeArr: number[] = [];

        for (let i = startTime; i < endTimeCheck; ++i) processTimeArr.push(i);

        for (
          let i = adminSetup?.breakStartTime ?? 0;
          i < (adminSetup?.breakEndTime ?? 0);
          ++i
        )
          breakTimeArr.push(i);

        return processTimeArr
          .filter((processSecs) => breakTimeArr.includes(processSecs))
          .filter((overlap) => overlap).length > 0
          ? (adminSetup?.breakEndTime ?? 0) + 1
          : startTime;
      })();

      const endTimeFinal = startTimeFinal + totalProcessTime;

      const newWorkOrder: WorkOrderRev = {
        ...workOrder,
        uuid: uuidv4(),
        machine: autofill ? autofillResult.machine : machine,
        amount: isNaN(workOrder.amount) ? 0 : workOrder.amount,
        fepMulti:
          processType?.name === "Punch" ||
          processType?.name === "LVD" ||
          processType?.name === "Trumpf" ||
          processType?.name === "HSG"
            ? null
            : (fepProcess as FepMulti),
        partToPart:
          processType?.name === "Punch" ||
          processType?.name === "LVD" ||
          processType?.name === "Trumpf" ||
          processType?.name === "HSG"
            ? (fepProcess as PartToPart)
            : null,
        jobName: job?.job?.name ?? "",
        jobId: job?.job?.id ?? 0,
        date: date ? date : `${new Date().getFullYear()}-01-01`,
        startTime: startTimeFinal,
        endTime: endTimeFinal,
      };

      setWorkOrders([{ ...newWorkOrder }, ...workOrders]);

      if (job !== null) {
        setJob({
          ...job,
          job: job.job
            ? {
                ...job.job,
                workOrderRevs: job.job.workOrderRevs
                  ? [newWorkOrder, ...job.job.workOrderRevs]
                  : job.job.workOrderRevs,
              }
            : job.job,
        });
      }

      // Normalize
      setFepProcess(null);
      setWorkOrder({ ...workOrder, workers: [], amount: 0, machine: null });
      setWorker(null);
      setMachine(null);

      console.log("new work order:", newWorkOrder);
      console.log("new job:", job);
    }
  };

  const handleSelectProcessType = <T extends PartToPart | FepMulti>(
    processType: ProcessType
  ) => {
    setProcessType(processType);
    console.log("select Process type:", processType);

    const processes = getProcesses(processType);

    if (processes) {
      const newProcesses: ProcessWithAmount[] = (processes as T[]).map(
        (process) => {
          console.log("Process:", process);
          return { id: process.id, amount: 0 };
        }
      );
      setProcessWithAmounts([...newProcesses]);
    }
  };

  const handleSave = async () => {
    const apiKey = localStorage.getItem("apiKey");
    const response = await Promise.all(
      workOrders.map(async (workOrder) => {
        try {
          const workOrderResponse = await fetch(
            `${process.env.REACT_APP_BASE_URL}/workorderrevs/save`,
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: apiKey ? apiKey : "",
              },
              body: JSON.stringify(workOrder),
            }
          );
        } catch (e) {
          console.log(e);
        }
      })
    );

    const woIdsDeleteResponse = await Promise.all(
      woIdsToDelete.map(async (id) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/workorderrevs/${id}`,
            {
              method: "DELETE",
              headers: {
                authorization: apiKey ? apiKey : "",
              },
            }
          );
        } catch (e) {
          console.error(e);
        }
      })
    );

    history.push("/workordersrev");

    // window.location.reload()
  };

  const handleChangeBreakStartTime = () => {};

  const handleChangeBreakEndTime = () => {};

  const handleChangeWorkingHourStartTime = () => {};

  const handleChangeWorkingHourEndTime = () => {};

  console.log("time inteval:", timeInterval);

  return (
    <Box>
      {window.innerWidth}
      <Box my={1} display="flex">
        {requestStatus === RequestStatus.Loading ? (
          <></>
        ) : (
          <>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        )}

        <Box mx={1}>
          <a
            href={`${process.env.REACT_APP_BASE_URL}/workorderrevs/bydate/spk?date=${date}`}
            target="_blank"
          >
            <Button variant="contained" color="primary">
              Export SPK
            </Button>
          </a>
        </Box>

        {requestStatus === RequestStatus.Loading ? (
          <>
            <CircularProgress disableShrink size={24} />{" "}
          </>
        ) : (
          <></>
        )}
      </Box>

      {/* <Box>
        Multi FEP;{" "}
        <Switch
          checked={multiFep}
          onClick={() => {
            setMultiFep(!multiFep);
          }}
        />
      </Box> */}

      {requestStatus === RequestStatus.Loading ? (
        <> </>
      ) : (
        <>
          <Box my={1} style={{ width: 500 }}>
            Job
            <AsyncSelect
              cacheOptions
              defaultOptions
              isClearable={true}
              loadOptions={fetchJobs}
              onChange={(value: any) => {
                console.log(
                  value,
                  value?.value?.fepDocument,
                  value?.fepDocument
                );

                if (value?.value) {
                  selectJob(value.value?.job?.id);
                }
              }}
            />
            {selectedFepDocument ? (
              <Box my={1}>
                <strong style={{ color: green[500] }}>
                  Fep: {selectedFepDocument.name}, version:{" "}
                  {selectedFepDocument.version}
                </strong>
              </Box>
            ) : (
              <Box>
                <strong style={{ color: red[500] }}>No FEP</strong>
              </Box>
            )}
            {job ? <h4>Unit(s) needed: {job?.job?.qty}</h4> : <></>}
          </Box>

          {multiFep && job ? (
            <>
              <Box>
                <Box>
                  <strong>Select FEP</strong>
                </Box>
                <Autocomplete
                  size="small"
                  options={job?.fepDocuments ?? []}
                  getOptionLabel={(f) =>
                    `${f.fepDocument?.name} ${f.fepDocument?.unitQty} unit(s)`
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  onChange={(_, f) => {
                    if (f?.fepDocument) {
                      setselectedFepDocument(f.fepDocument);
                    }
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          {multiFep && job && selectedFepDocument ? (
            <>
              {" "}
              <Box my={1} style={{ width: 300 }}>
                Process Type
                <SyncAutocompleteName
                  options={processTypes}
                  value={processType}
                  name="name"
                  label="Process Type"
                  width={300}
                  onChange={(selected) => {
                    if (
                      selected?.name === "Punch" ||
                      selected?.name === "LVD" ||
                      selected?.name === "Trumpf" ||
                      selected?.name === "HSG"
                    ) {
                      handleSelectProcessType<PartToPart>(selected);
                    } else {
                      handleSelectProcessType<FepMulti>(selected);
                    }

                    // Set by unit / by part
                    if (selected?.name === "Weld") {
                      setAmountType("By Part");
                    } else if (
                      selected?.name === "Bend" ||
                      selected?.name === "Grind" ||
                      selected?.name === "Coat"
                    ) {
                      setAmountType("By Unit");
                    }
                  }}
                />
              </Box>
            </>
          ) : (
            <></>
          )}

          <Box display="flex" my={1} alignItems="center">
            FEP Preview:{" "}
            <Switch
              checked={fepPreview}
              onClick={() => setFepPreview(!fepPreview)}
            />
            Work Order:{" "}
            <Switch
              checked={workOrderPreview}
              onClick={() => setWorkOrderPreview(!workOrderPreview)}
            />
            Gantt:{" "}
            <Switch
              checked={ganttPreview}
              onClick={() => setGanttPreview(!ganttPreview)}
            />
          </Box>

          {/* <Box my={1}>
        {job && processType
          ? <>Job & Process Type OK {processType.name}</>
          : <>None</>
        }
      </Box> */}

          {job && processType ? (
            processType.name === "Punch" ||
            processType.name === "Trumpf" ||
            processType.name === "LVD" ||
            processType.name === "HSG" ? (
              <></>
            ) : (
              <Box>
                <Box
                  my={1}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box display="flex" mx={1} border={1} p={1}>
                    {processType.name !== "Punch" &&
                    processType.name !== "Trumpf" &&
                    processType.name !== "LVD" ? (
                      <Box width={150}>
                        Amount type: <strong>{amountType}</strong>
                        <Select
                          options={["By Unit", "By Part"].map((option) => ({
                            label: option,
                            value: option,
                          }))}
                          value={
                            amountType
                              ? { label: amountType, value: amountType }
                              : null
                          }
                          onChange={(selected) =>
                            setAmountType(
                              (
                                selected as {
                                  label: AmountType;
                                  value: AmountType;
                                }
                              ).label
                            )
                          }
                        />
                      </Box>
                    ) : (
                      <></>
                    )}

                    {processType.name !== "Punch" &&
                    processType.name !== "Trumpf" &&
                    processType.name !== "LVD" &&
                    processType.name !== "HSG" &&
                    amountType === "By Unit" ? (
                      <></>
                    ) : (
                      <Box width={300}>
                        Select part/program
                        <Select
                          placeholder="Part/program name..."
                          value={{
                            label: fepProcess
                              ? `${fepProcess?.id} - ${fepProcess?.name}`
                              : "",
                            value: fepProcess,
                          }}
                          options={
                            processes
                              ? (processes as (PartToPart | FepMulti)[]).map(
                                  (process) => {
                                    return {
                                      label: `${process.item?.partName} - ${process.id}`,
                                      value: process,
                                    };
                                  }
                                )
                              : []
                          }
                          onChange={(selected) =>
                            setFepProcess(
                              (
                                selected as {
                                  label: string;
                                  value: PartToPart | FepMulti;
                                }
                              ).value
                            )
                          }
                        />
                      </Box>
                    )}

                    <Box mx={2} width={100}>
                      <TextField
                        label="Amount"
                        value={workOrder.amount.toString()}
                        type="number"
                        onChange={(evt) =>
                          setWorkOrder({
                            ...workOrder,
                            amount: parseInt(evt.target.value),
                          })
                        }
                      />
                    </Box>
                  </Box>

                  <Box mx={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddWorkOrder(fepProcess, workOrder)}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>

                <Box my={2}>
                  <List>
                    {workOrder.workers.map((worker) => (
                      <ListItem style={{ width: 300 }} button>
                        {worker.name}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            )
          ) : (
            <></>
          )}

          <div
            style={{
              marginTop: 15,
              marginBottom: 15,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 10 }}>
              <TextField
                variant="outlined"
                size="small"
                label="Search Item..."
                onChange={(evt) => setFilterInput(evt.target.value)}
              />
            </div>
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              Break time:{" "}
              <strong>
                {secondsToHms(adminSetup?.breakStartTime ?? 0)} -{" "}
                {secondsToHms(adminSetup?.breakEndTime ?? 0)}
              </strong>
            </div>
            |
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              Total planned:&nbsp;
              <strong>
                {secondsToHms(
                  processType
                    ? workOrders
                        .filter(
                          (workOrder) =>
                            workOrder.machine?.processType?.id ===
                            processType?.id
                        )
                        .reduce(
                          (acc, workOrder) =>
                            acc + (workOrder.endTime - workOrder.startTime),
                          0
                        )
                    : 0
                )}
              </strong>
            </div>
            |
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              Working hours:{" "}
              <strong>
                {secondsToHms(adminSetup?.checkInTime ?? 0)} -{" "}
                {secondsToHms(adminSetup?.checkOutTime ?? 0)} (
                {secondsToHms(
                  (adminSetup?.checkOutTime ?? 0) -
                    (adminSetup?.checkInTime ?? 0)
                )}
                )
              </strong>
            </div>
            |
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              Capacity needed:&nbsp;
              <strong>
                {(() => {
                  const capacityNeeded =
                    workOrders
                      .filter(
                        (workOrder) =>
                          workOrder.machine?.processType?.id === processType?.id
                      )
                      .reduce(
                        (acc, workOrder) =>
                          acc + (workOrder.endTime - workOrder.startTime),
                        0
                      ) /
                    ((adminSetup?.checkOutTime ?? 0) -
                      (adminSetup?.checkInTime ?? 0));

                  return processType
                    ? Math.round(capacityNeeded * 10000) / 10000
                    : 0;
                })()}
              </strong>
            </div>
          </div>

          <Box display="flex" alignItems="center">
            <Box>
              Autofill Machine?{" "}
              <Switch
                checked={autofill}
                onChange={() => setAutofill(!autofill)}
              />
            </Box>

            <Box style={{ display: autofill ? "none" : "inline" }}>
              <SyncAutocompleteName
                label="Machine"
                name="name"
                width={300}
                options={machinesFiltered}
                value={machine}
                onChange={(value) => setMachine(value)}
              />
            </Box>

            <Box ml={3}>
              Autofill Start Time?{" "}
              <Switch
                checked={autofillTime}
                onChange={() => setAutofillTime(!autofillTime)}
              />
            </Box>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box style={{ display: autofillTime ? "none" : "inline" }}>
                <Box mx={2}>
                  <KeyboardTimePicker
                    ampm={false}
                    openTo="hours"
                    views={["hours", "minutes", "seconds"]}
                    format="HH:mm:ss"
                    mask="__:__:__"
                    label="Start Time"
                    value={startTime}
                    onChange={(time) => setStartTime(time)}
                  />
                </Box>
              </Box>
            </MuiPickersUtilsProvider>
          </Box>

          <Box
            my={1}
            style={{
              height: "50vh",
              resize: "vertical",
              overflow: "auto",
              border: 1,
              borderStyle: "solid",
              display: fepPreview ? "block" : "none",
            }}
          >
            {job && processType && selectedFepDocument ? (
              <ProcessTable />
            ) : (
              <></>
            )}
          </Box>

          <Box my={1}>
            <Divider />
          </Box>

          <Box my={1} width={300}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  label="Filter process type"
                />
              )}
              options={processTypes}
              onChange={(_: any, v: ProcessType | null) => {
                setDailyProcessTypeFilter(v);
              }}
              getOptionLabel={(processType) => processType?.name ?? "No Name  "}
            />
          </Box>

          {(() => {
            const filteredWorkOrders = workOrders
              // Filter daily by process type
              .filter((workOrder) =>
                dailyProcessTypeFilter
                  ? workOrder.machine?.processType?.id ===
                    dailyProcessTypeFilter?.id
                  : true
              );

            return (
              <Box
                my={1}
                style={{
                  height: "50vh",
                  resize: "vertical",
                  overflow: "auto",
                  border: 1,
                  borderStyle: "solid",
                  display: workOrderPreview ? "block" : "none",
                }}
              >
                <Table size="small" style={{ borderCollapse: "separate" }}>
                  <TableHead>
                    <TableRow
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: purple[50],
                      }}
                    >
                      {[
                        "#",
                        `Job (${filteredWorkOrders?.length})`,
                        "Machine",
                        "Process Type",
                        "Plate Type",

                        "Process Name",
                        "Amount",
                        "Start Time",
                        "End Time",
                        "Worker(s)",
                        "Action",
                      ].map((head) => (
                        <TableCell>{head}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredWorkOrders
                      .sort((a, b) => (a.startTime ?? 0) - (b.startTime ?? 0))
                      .map((workOrder, i) => (
                        <TableRow>
                          <TableCell>{i + 1}</TableCell>
                          <TableCell>{workOrder.jobName}</TableCell>
                          <TableCell>{workOrder.machine?.name}</TableCell>
                          <TableCell>
                            {workOrder.machine?.processType?.name}
                          </TableCell>
                          <TableCell>
                            {(workOrder.partToPart?.partToPartDetails?.length ??
                              0) > 0
                              ? workOrder.partToPart?.partToPartDetails[0]
                                  ?.materialType?.name
                              : ""}
                          </TableCell>

                          <TableCell>
                            {workOrder.partToPart !== null
                              ? workOrder.partToPart.name
                              : workOrder.fepMulti?.item?.partName}
                          </TableCell>
                          <TableCell>{workOrder.amount}</TableCell>
                          <TableCell>
                            {secondsToHms(workOrder.startTime)}
                          </TableCell>
                          <TableCell>
                            {secondsToHms(workOrder.endTime)}
                          </TableCell>
                          <TableCell>
                            {workOrder.workers
                              .map((worker) => worker.name)
                              .join(", ")}
                          </TableCell>
                          <TableCell>
                            <Button
                              size="small"
                              variant="outlined"
                              color="secondary"
                              onClick={(e) => handleDeleteWorkOrder(workOrder)}
                            >
                              Delete
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            );
          })()}

          <div className="my-3">
            <hr />
          </div>

          <div className="d-flex justify-content-around">
            <div>
              check in time:{" "}
              <strong>{secondsToHms(adminSetup?.checkInTime ?? 0)}</strong>
            </div>

            <div>
              check out time:{" "}
              <strong>{secondsToHms(adminSetup?.checkOutTime ?? 0)}</strong>
            </div>

            <div>
              break start time:{" "}
              <strong>{secondsToHms(adminSetup?.breakStartTime ?? 0)}</strong>
            </div>

            <div>
              break end time:{" "}
              <strong>{secondsToHms(adminSetup?.breakEndTime ?? 0)}</strong>
            </div>
          </div>

          <Box
            overflow="auto"
            style={{ height: "50vh", resize: "vertical" }}
            boxShadow={5}
          >
            <Stage
              style={{
                border: 1,
                borderStyle: "solid",
                display: ganttPreview ? "block" : "none",
              }}
              width={window.innerWidth}
              height={(machines.length ?? 0) * 35 + timeLetterPadding}
            >
              <Layer>
                <Line
                  points={[startX, 0, startX, window.innerHeight]}
                  stroke="black"
                />

                {/* Hour indicator */}
                {[...Array(25).keys()].map((_, time) => {
                  return (
                    <>
                      <Text
                        x={time * timeInterval + 5 + startX}
                        y={0}
                        text={time.toString()}
                        fontSize={16}
                      />
                      <Line
                        stroke="black"
                        points={[
                          time * timeInterval + startX,
                          0,
                          time * timeInterval + startX,
                          (machines.length ?? 0) * 35 + timeLetterPadding,
                        ]}
                      />
                    </>
                  );
                })}

                {/* Bounding box for break time start */}
                <Line
                  points={[
                    mapTimeToCoordPx(adminSetup?.breakStartTime ?? 0),
                    0,
                    mapTimeToCoordPx(adminSetup?.breakStartTime ?? 0),
                    window.innerHeight,
                  ]}
                  stroke="red"
                />

                {/* Bounding box for break time end */}
                <Line
                  points={[
                    mapTimeToCoordPx(adminSetup?.breakEndTime ?? 0),
                    0,
                    mapTimeToCoordPx(adminSetup?.breakEndTime ?? 0),
                    window.innerHeight,
                  ]}
                  stroke="red"
                />

                {/* Bounding box for break time start */}
                <Line
                  points={[
                    mapTimeToCoordPx(adminSetup?.checkInTime ?? 0),
                    0,
                    mapTimeToCoordPx(adminSetup?.checkInTime ?? 0),
                    window.innerHeight,
                  ]}
                  stroke={`${blue[600]}`}
                />

                {/* Bounding box for break time end */}
                <Line
                  points={[
                    mapTimeToCoordPx(adminSetup?.checkOutTime ?? 0),
                    0,
                    mapTimeToCoordPx(adminSetup?.checkOutTime ?? 0),
                    window.innerHeight,
                  ]}
                  stroke={`${blue[600]}`}
                />

                {machines.map((machine, i) => {
                  const filteredWorkOrders = workOrders.filter(
                    (workOrder) => workOrder.machine?.name === machine.name
                  );

                  console.log("machine", machine.name, filteredWorkOrders);

                  return (
                    <>
                      <Text
                        text={machine.name}
                        y={i * 30 + timeLetterPadding}
                        fontSize={16}
                      />
                      {filteredWorkOrders.map((workOrder) => {
                        return (
                          <Rect
                            x={mapTimeToCoordPx(workOrder.startTime)}
                            y={i * 30 + timeLetterPadding}
                            height={20}
                            width={mapWidthToWidthPx(
                              workOrder.endTime - workOrder.startTime
                            )}
                            fill="orange"
                          />
                        );
                      })}
                    </>
                  );
                })}
              </Layer>
            </Stage>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WorkOrderRevAdd;
