import React, { useContext, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import { makeReadableDateString } from "../../../helpers";
import { WiringWorkOrder } from "../../../models/model";

const Print = () => {
  const appState = useContext(AppContext);
  const apiKey = appState?.apiKey ? appState.apiKey : "";
  const { date } = useParams() as { date: string };

  const [state, setState] = useState({
    workOrders: [] as WiringWorkOrder[],
  });

  useEffect(() => {
    appState?.setAppBarVisible(false);

    fetchData();
  }, []);

  const fetchData = async () => {
    const [workOrdersToday] = await Promise.all([fetchWorkOrdersToday()]);

    setState({
      ...state,
      workOrders: workOrdersToday,
    });

    // window.print();
  };

  const fetchWorkOrdersToday = async () => {
    try {
      const response = await fetch(
        `${appState?.baseUrl}/wiringworkorders/bydate/today?date=${date}`,
        {
          headers: { authorization: apiKey },
        }
      );

      return (await response.json()) as WiringWorkOrder[];
    } catch (e) {
      return [];
    }
  };

  return (
    <div>
      <div>
        <h4>Manpower mapping {makeReadableDateString(new Date(date))}</h4>
      </div>
      <div>
        <Table size="sm">
          <thead>
            <tr className="border border-dark">
              <th className="align-middle text-center border border-dark">
                Operator
              </th>
              <th className="align-middle text-center border border-dark">
                Job
              </th>
              <th className="align-middle text-center border border-dark">
                Panel Code
              </th>
              <th className="align-middle text-center border border-dark">
                Type
              </th>
              <th className="align-middle text-center border border-dark">
                Serial Number
              </th>
              <th className="align-middle text-center border border-dark">
                Process Type
              </th>
              <th className="align-middle text-center border border-dark">
                Non Standard
              </th>
              <th className="align-middle text-center border border-dark">
                Remark
              </th>
              <th className="align-middle text-center border border-dark">
                Done
              </th>
            </tr>
          </thead>
          <tbody>
            {state.workOrders.map((workOrder) => {
              return (
                <tr>
                  <td className="border border-dark">
                    {workOrder.worker?.name}
                  </td>
                  <td className="border border-dark">{workOrder.jobName}</td>
                  <td className="border border-dark">
                    {workOrder.panelCodeName}
                  </td>
                  <td className="border border-dark">
                    {workOrder.panelCodeType}
                  </td>
                  <td className="border border-dark">
                    {workOrder.panelCodeSerialNumber}
                  </td>
                  <td className="border border-dark">
                    {workOrder.wiringProcessType}
                  </td>
                  <td className="border border-dark">
                    <Form.Check
                      checked={
                        workOrder.isCustom !== null ? workOrder.isCustom : false
                      }
                    />
                  </td>
                  <td className="border border-dark">
                    {workOrder.wiringRemark}
                  </td>
                  <td className="border border-dark">{workOrder.doneStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* <pre>
          {JSON.stringify(state.workOrders, null, 2)}
        </pre> */}
      </div>
    </div>
  );
};

export default Print;
