import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  CrmPurchaseOrder,
  CrmPurchaseOrders,
  MasterJavaBaseModel,
  PpicDeliveryNote,
  PpicDeliveryNoteItem,
  PpicDeliveryNotes,
} from "../../../masterbigsystem";
import {
  fetchDeliveryNotes,
  fetchExtCrmPurchaseOrdersProto,
} from "../../../helpers";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { V } from "jointjs";
import Select from "react-select";
import { v4 } from "uuid";
import { Edit, Print } from "@material-ui/icons";

const DNManualPage = () => {
  const ctx = useContext(AppContext);

  const dns = useRef(PpicDeliveryNotes.fromPartial({ notes: [] }));
  const dnModalShow = useRef(false);

  const [, refresh] = useState(false);
  const render = () => {
    refresh((n) => !n);
  };

  const purchaseOrders = useRef(
    CrmPurchaseOrders.fromPartial({ purchaseOrders: [] })
  );

  const fetchDeliveryNotesData = async () => {
    const d = await fetchDeliveryNotes({ apiKey: ctx?.apiKey ?? "" });
    if (d) {
      dns.current = d;
      render();
    }
  };

  const fetchPOsData = async () => {
    const d = await fetchExtCrmPurchaseOrdersProto({
      apiKey: ctx?.apiKey ?? "",
    });
    if (d) {
      purchaseOrders.current = d;
      render();
    }
  };

  const newDnManual = useRef(
    PpicDeliveryNote.fromPartial({
      deliveryNoteItems: [],
      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({ uuid: v4() }),
    })
  );

  const handleSave = async () => {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BASE_URL}/deliverynotes-save-bulk`,
        {
          method: "post",
          headers: {
            "content-type": "application/json",
            authorization: ctx?.apiKey ?? "",
          },
          body: JSON.stringify(
            PpicDeliveryNotes.fromPartial({ notes: [newDnManual.current] })
          ),
        }
      );

      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchDeliveryNotesData();
    fetchPOsData();
  }, []);
  return (
    <>
      <Modal
        show={dnModalShow.current ? true : false}
        onHide={() => {
          dnModalShow.current = !dnModalShow.current;
          render();
        }}
        size="xl"
      >
        <ModalHeader>
          <h4>DN Editor</h4>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              <small>
                <strong>Revisi</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.revision = isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value);

                  render();
                }}
                defaultValue={newDnManual.current.revision}
                placeholder="Revisi..."
              />
            </div>
            <div>
              <small>
                <strong>To</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.to = e.target.value;
                  render();
                }}
                defaultValue={newDnManual.current.to}
                placeholder="To..."
              />
            </div>
            <div>
              <small>
                <strong>Address</strong>
              </small>
            </div>
            <div>
              <textarea
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.address = e.target.value;
                  render();
                }}
                defaultValue={newDnManual.current.address}
                placeholder="Address..."
              />
            </div>
            <div>
              <small>
                <strong>Effective Date</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.effectiveDate = e.target.value;
                  render();
                }}
                defaultValue={newDnManual.current.effectiveDate}
                type="date"
                placeholder="Effective date..."
              />
            </div>
            <div>
              <small>
                <strong>Date</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.date = e.target.value;
                  render();
                }}
                defaultValue={newDnManual.current.date}
                type="date"
                placeholder="date..."
              />
            </div>
            <div>
              <small>
                <strong>Purpose</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.purpose = e.target.value;
                  render();
                }}
                defaultValue={newDnManual.current.purpose}
                placeholder="Purpose..."
              />
            </div>
            {/* <div>
              <small>
                <strong>DN Number</strong>
              </small>
            </div>
            <div>
              <input
                className="form-control form-control-sm"
                onBlur={(e) => {
                  newDnManual.current.dnNumber = e.target.value;
                  render();
                }}
                defaultValue={newDnManual.current.dnNumber}
                placeholder="DN Number..."
              />
            </div> */}
            <div>
              <small>
                <strong
                  className={`${
                    newDnManual.current.extPurchaseOrderId ? `text-success` : ``
                  }`}
                >
                  PO{" "}
                  {newDnManual.current.extPurchaseOrderId ? (
                    <>(Selected: {newDnManual.current.extPurchaseOrderId})</>
                  ) : (
                    <></>
                  )}
                </strong>
              </small>
            </div>
            <div>
              <Select
                value={purchaseOrders.current?.purchaseOrders
                  .map((p) => ({
                    label: `${p.purchaseOrderNumber} (${p.account?.name}): ${p.products.length} item(s)`,
                    value: p,
                  }))
                  .find(
                    (o) =>
                      `${o.value.id}` ===
                      `${newDnManual.current.extPurchaseOrderId}`
                  )}
                options={purchaseOrders.current?.purchaseOrders.map((p) => ({
                  label: `${p.purchaseOrderNumber} (${p.account?.name}): ${p.products.length} item(s)`,
                  value: p,
                }))}
                placeholder="Select PO..."
                onChange={(v) => {
                  const val = v as { value: CrmPurchaseOrder };
                  newDnManual.current.extPurchaseOrderId = val.value.id;
                  render();
                }}
              />
            </div>
            <div>
              <small>
                <strong>Items</strong>
              </small>
            </div>
            <div>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => {
                  newDnManual.current.deliveryNoteItems.push(
                    PpicDeliveryNoteItem.fromPartial({
                      masterJavaBaseModel: MasterJavaBaseModel.fromPartial({
                        uuid: v4(),
                      }),
                    })
                  );
                  render();
                }}
              >
                Add
              </button>
            </div>
            <div
              className="border border-dark overflow-auto"
              style={{ resize: "vertical", height: "20vh" }}
            >
              <table
                className="table table-sm"
                style={{ borderCollapse: "separate" }}
              >
                <tr>
                  {["#", "Item Name", "Qty"].map((h) => (
                    <>
                      <th
                        className="bg-dark text-light"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  ))}
                </tr>

                {newDnManual.current.deliveryNoteItems.map((i, i_) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark p-0 m-0">{i_ + 1}</td>
                        <td className="border border-dark p-0 m-0">
                          <input
                            className="form-control form-control-sm"
                            placeholder="Item name..."
                            onBlur={(e) => {
                              i.description = e.target.value;
                              render();
                            }}
                            defaultValue={i.description}
                            key={`i-desc-${i.masterJavaBaseModel?.uuid}`}
                          />
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <input
                            className="form-control form-control-sm"
                            placeholder="Qty..."
                            onBlur={(e) => {
                              i.qty = isNaN(parseFloat(e.target.value))
                                ? 0
                                : parseFloat(e.target.value);
                            }}
                            style={{ width: 75 }}
                            key={`i-q-${i.masterJavaBaseModel?.uuid}`}
                            defaultValue={i.qty}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="w-100 d-flex justify-content-end">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <div>
        <div>
          <h4>DN Manual Page</h4>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              dnModalShow.current = true;
              render();
            }}
          >
            Add
          </button>
        </div>

        <div
          className="border border-dark overflow-auto"
          style={{ resize: "vertical", height: "75vh" }}
        >
          <table
            className="table table-sm"
            style={{ borderCollapse: "separate", position: "sticky", top: 0 }}
          >
            <tr>
              {[
                "#",
                "No dokumen",
                "Rev",
                "To",
                "Address",
                "Tanggal Efektif",
                "DN#",
                "Date",
                "Purpose",
                "PO#",
                "Items",
                "Edit",
                "Print",

                "Created",
                "Updated",
              ].map((h) => (
                <>
                  <th className="bg-dark text-light p-0 m-0">{h}</th>
                </>
              ))}
            </tr>
            {dns.current.notes.map((n, i) => {
              const foundPO = purchaseOrders.current.purchaseOrders.find(
                (p) => `${p.id}` === `${n.extPurchaseOrderId}`
              );
              return (
                <>
                  <tr>
                    <td className="border border-dark p-0 m-0">{i + 1}</td>
                    <td className="border border-dark p-0 m-0">
                      {n.masterJavaBaseModel?.id}
                    </td>
                    <td className="border border-dark p-0 m-0">{n.revision}</td>
                    <td className="border border-dark p-0 m-0">{n.to}</td>
                    <td className="border border-dark p-0 m-0">{n.address}</td>
                    <td className="border border-dark p-0 m-0">
                      {n.effectiveDate}
                    </td>
                    <td className="border border-dark p-0 m-0">{n.dnNumber}</td>
                    <td className="border border-dark p-0 m-0">{n.date}</td>
                    <td className="border border-dark p-0 m-0">{n.purpose}</td>
                    <td className="border border-dark p-0 m-0">
                      {foundPO ? (
                        <>
                          {foundPO?.purchaseOrderNumber} (
                          {foundPO?.account?.name})
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {n.deliveryNoteItems.length}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      <button
                        className="btn btn-sm btn-primary px-1 py-0"
                        onClick={() => {
                          newDnManual.current = n;
                          dnModalShow.current = true;
                          render();
                        }}
                      >
                        <Edit />{" "}
                      </button>
                    </td>
                    <td className="border border-dark p-0 m-0">
                      <a
                        href={`${window.location.origin}/#/printdn/${n.masterJavaBaseModel?.id}`}
                        target="_blank"
                      >
                        <button className="btn btn-sm btn-primary px-1 py-0">
                          <Print />
                        </button>
                      </a>
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {(() => {
                        try {
                          return Intl.DateTimeFormat("en-US", {
                            dateStyle: "medium",
                            timeStyle: "short",
                          } as any).format(
                            new Date(n.masterJavaBaseModel?.createdAt ?? "")
                          );
                        } catch (e) {
                          return "";
                        }
                      })()}
                    </td>
                    <td className="border border-dark p-0 m-0">
                      {" "}
                      {(() => {
                        try {
                          return Intl.DateTimeFormat("en-US", {
                            dateStyle: "medium",
                            timeStyle: "short",
                          } as any).format(
                            new Date(n.masterJavaBaseModel?.updatedAt ?? "")
                          );
                        } catch (e) {
                          return "";
                        }
                      })()}
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </div>
      </div>
    </>
  );
};

export default DNManualPage;
