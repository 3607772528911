import { Print } from "@material-ui/icons";
import { QRCodeSVG } from "qrcode.react";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../App";
import {
  fetchItems,
  fetchMaterialRequest,
  fetchMaterialRequestView,
  parseJwt,
} from "../../../helpers";
import { ExtItem, MaterialRequest } from "../../../models/model";

const MaterialRequestPrint = () => {
  const ctx = useContext(AppContext);
  const { id } = useParams() as { id: string };

  const [loading, setLoading] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [items, setItems] = useState<ExtItem[] | null>([]);
  const [mr, setMr] = useState<MaterialRequest | null | undefined>(null);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    ctx?.setAppBarVisible(false);
    fetchItemsData();
    fetchMaterialRequestsData();
  };

  const fetchItemsData = async () => {
    try {
      setLoadingItems(true);
      setItems(
        await fetchItems(
          process.env.REACT_APP_BASE_URL ?? "",
          ctx?.apiKey ?? ""
        )
      );
    } catch (e) {
    } finally {
      setLoadingItems(false);
    }
  };
  const fetchMaterialRequestsData = async () => {
    try {
      setLoading(true);
      setMr(
        await fetchMaterialRequestView({ apiKey: ctx?.apiKey ?? "", id: id })
      );
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const mrItemsFlattenedIds = [
    ...new Set(mr?.materialRequestItems?.map((i) => i.extItemId)),
  ].map((i) => ({
    itemId: i,
    qty: mr?.materialRequestItems
      ?.filter((ix) => `${ix.extItemId}` === `${i}`)
      .reduce((acc, i) => acc + (i.qty ?? 0.0), 0.0),
  }));
  // .filter((d) => d.qty && d.qty !== 0);

  return (
    <>
      <div className="m-3">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div>
              <div className="d-flex">
                <h4>Material Request</h4>
                <div className="mx-2">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={() => {
                      window?.print();
                    }}
                  >
                    <Print /> Print
                  </button>
                </div>
              </div>
            </div>
            <div>Name: {mr?.name}</div>
            <div>
              MR/PPIC/
              {(() => {
                try {
                  return new Date(mr?.createdAt ?? "").getFullYear();
                } catch (e) {
                  return 0;
                }
              })()}
              /{mr?.id}{" "}
            </div>
            <div>No. MR (Manual): {mr?.materialRequestNumber}</div>

            <div>
              For date:{" "}
              {mr?.neededDate && mr?.neededDate !== ""
                ? (() => {
                    try {
                      return Intl.DateTimeFormat(
                        navigator.language ?? "en-US",
                        {
                          dateStyle: "long",
                        } as any
                      ).format(new Date(mr?.neededDate ?? ""));
                    } catch (e) {
                      return "";
                    }
                  })()
                : ""}
            </div>
            <div>For job: {mr?.jobData?.name}</div>

            <div>
              Printed from PPIC app by{" "}
              {(() => {
                try {
                  return parseJwt(ctx?.apiKey ?? "")?.sub;
                } catch (e) {
                  return "";
                }
              })()}
            </div>

            <hr className="border border-dark" />
            {loadingItems ? (
              <>
                <Spinner animation="border" />
              </>
            ) : (
              <>
                <div>
                  <table className="table table-sm table-bordered table-hover table-striped">
                    <tr>
                      {["#", "P/N", "MFR", "Name", "Desc", "Qty", "UM"].map(
                        (h) => (
                          <th className="border border-dark">{h}</th>
                        )
                      )}
                    </tr>
                    {mrItemsFlattenedIds?.map((mi, i) => {
                      const foundItem = items?.find(
                        (ix) => ix.id === mi.itemId
                      );

                      return (
                        <>
                          <tr>
                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark">
                              {foundItem?.partNum}
                            </td>
                            <td className="border border-dark">
                              {foundItem?.mfr}
                            </td>
                            <td className="border border-dark">
                              {foundItem?.partName}
                            </td>
                            <td className="border border-dark">
                              {foundItem?.partDesc}
                            </td>
                            <td className="border border-dark">
                              {Math.ceil(mi.qty ?? 0)}
                            </td>
                            <td className="border border-dark">
                              {foundItem?.defaultUm}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                  <hr className="border border-dark" />
                  <div className="d-flex justify-content-between">
                    {[
                      "Requested by",
                      "Prepared by",
                      "Approved by",
                      "Received by",
                    ].map((h) => {
                      return (
                        <>
                          <div>
                            <div>{h}</div>
                            <div style={{ marginTop: "4em" }}></div>
                            <hr className="border border-dark" />
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div>
                    <QRCodeSVG value={`0/${mr?.id}`} />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MaterialRequestPrint;
