import React from "react";

const InventoryTransactionReportPage = () => {
  return (
    <>
      <div>
        <div>
          <div>
            <h4>Inventory Transaction Report</h4>
          </div>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div
          className="border border-dark overflow-auto"
          style={{ resize: "vertical", height: "75vh" }}
        >
          <table className="table table-sm" style={{borderCollapse:"separate"}}>
            <tr>
              {[
                "#",
                "PN",
                "PartName",
                "Desc",
                "UM",
                "In/Out",
                "Old Balance",
                "New Balance",
              ].map((h) => {
                return (
                  <>
                    <th
                      className="bg-dark text-light"
                    >
                      {h}
                    </th>
                  </>
                );
              })}
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default InventoryTransactionReportPage;
