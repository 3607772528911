import { Delete } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { AppContext } from "../../../App";
import {
  fetchSkillsProto,
  getSkillLevelIcon,
  getUserDetail,
  mapSkillLevelToColor,
} from "../../../helpers";
import {
  PpicSkill,
  PpicSkills,
  ppicSkillWorker_SkillLevelToJSON,
  ppicSkillWorker_SkillLevelToNumber,
} from "../../../masterbigsystem";
import { ExtDepartment } from "../../../models/model";

const SkillCatalogPage = () => {
  const ctx = useContext(AppContext);
  const [skills, setSkills] = useState<PpicSkills | null>(
    PpicSkills.fromPartial({ skills: [] })
  );
  const [filters, setFilters] = useState({
    selectedDepartment: null as ExtDepartment | null,
  });
  const [selectedSkill, setSelectedSkill] = useState<PpicSkill | null>(null);
  const [skillIdsToDelete, setskillIdsToDelete] = useState<string[]>([]);
  const [view, setView] = useState<
    "List" | "Overview" | "Category" | "Priority"
  >("List");

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchSkillsData();
  };

  const fetchSkillsData = async () => {
    setSkills(await fetchSkillsProto({ apiKey: ctx?.apiKey ?? "" }));
  };

  return (
    <>
      <div>
        <div className="d-flex">
          <h4>Skill Catalog</h4>
          <div className="mx-2">
            <Link to="/skillcatalog/new">
              <button className="btn btn-primary btn-sm">Add</button>
            </Link>
          </div>
        </div>
        <hr className="border border-dark" />
        {(
          ["List", "Overview", "Category", "Priority"] as (
            | "List"
            | "Overview"
            | "Category"
            | "Priority"
          )[]
        ).map((v, i) => {
          return (
            <button
              onClick={() => {
                setView(v);
              }}
              className={`btn btn-sm ${
                view === v ? `btn-primary` : `btn-outline-primary`
              }`}
            >
              {v}
            </button>
          );
        })}
        <div>
          {view === "List" ? (
            <>
              {" "}
              <div className="">
                <Select
                  options={[
                    { id: null, name: "None" },
                    ...(ctx?.extDepartments ?? []),
                  ]}
                  getOptionLabel={(d) => `${d.name}`}
                  placeholder="Department filter..."
                  onChange={(v) => {
                    const val = v as unknown as ExtDepartment;
                    setFilters({ ...filters, selectedDepartment: val });
                  }}
                />
              </div>
              <div
                className="overflow-auto border border-dark"
                style={{ height: "75vh" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {["Action", "#", "Name", "Dept", "Workers"].map((h) => (
                      <th className="text-light font-weight-bold bg-dark">
                        {h}
                      </th>
                    ))}
                  </tr>
                  {skills?.skills
                    .filter((s) =>
                      filters.selectedDepartment &&
                      filters.selectedDepartment?.id
                        ? `${s.extDepartmentId}` ===
                          `${filters.selectedDepartment?.id}`
                        : true
                    )
                    .map((s, i) => {
                      return (
                        <>
                          <tr>
                            <td className="border border-dark">
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={async () => {
                                  if (
                                    window.confirm(`Really delete ${s.name}?`)
                                  ) {
                                    try {
                                      const resp = await fetch(
                                        `${process.env.REACT_APP_BASE_URL}/skills/${s.masterJavaBaseModel?.id}`,
                                        {
                                          method: "delete",
                                          headers: {
                                            authorization: ctx?.apiKey ?? "",
                                          },
                                        }
                                      );
                                      handleInit();
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  }
                                }}
                              >
                                <Delete />
                              </button>
                            </td>
                            <td className="border border-dark">{i + 1}</td>
                            <td className="border border-dark">
                              <Link
                                to={`/skillcatalog/${s.masterJavaBaseModel?.id}`}
                              >
                                {s.name && s.name !== "" ? s.name : "No Name"}
                              </Link>
                            </td>
                            <td className="border border-dark">
                              {
                                ctx?.extDepartments.find(
                                  (d) => `${d.id}` === `${s.extDepartmentId}`
                                )?.name
                              }
                            </td>
                            <td className="border border-dark">
                              {s.pics ?? 0}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </table>
              </div>
            </>
          ) : (
            <></>
          )}

          {view === "Overview" ? (
            <>
              <div
                className="overflow-auto"
                style={{ height: "65vh", resize: "vertical" }}
              >
                <table
                  className="table table-sm"
                  style={{ borderCollapse: "separate" }}
                >
                  <tr>
                    {["#", "Name"].map((h) => (
                      <>
                        <th
                          style={{ position: "sticky", top: 0 }}
                          className="bg-dark text-light"
                        >
                          {h}
                        </th>
                      </>
                    ))}
                    {skills?.skills.map((s) => {
                      return (
                        <>
                          <th
                            className="bg-dark text-light"
                            style={{ position: "sticky", top: 0 }}
                          >
                            {s.name}
                          </th>
                        </>
                      );
                    })}
                  </tr>
                  {ctx?.extUsers
                    .filter((u) =>
                      skills?.skills
                        .map((s) => s.skillWorkers ?? [])
                        .flat()
                        .find((sw) => `${sw.extUserId}` === `${u.id}`)
                    )
                    .map((u, i) => {
                      return (
                        <>
                          <tr>
                            <td className="border border-dark">{i + 1}</td>
                            <td
                              style={{ position: "sticky", left: 0 }}
                              className="border border-dark bg-light"
                            >
                              <div>
                                <div className="font-weight-bold">
                                  {u.username}: {u.name}
                                </div>
                                <div>
                                  <small>{u.departmentName}</small>
                                </div>
                              </div>
                            </td>
                            {skills?.skills.map((s) => {
                              const foundUserSkillLevel = s.skillWorkers.find(
                                (sw) => `${sw.extUserId}` === `${u.id}`
                              );
                              return (
                                <>
                                  <td
                                    className={`border border-dark ${
                                      foundUserSkillLevel
                                        ? foundUserSkillLevel?.isPriority
                                          ? `bg-success`
                                          : `bg-primary`
                                        : ``
                                    }`}
                                  >
                                    {foundUserSkillLevel?.isPriority ? (
                                      <>
                                        <div className="bg-danger text-light">
                                          <strong>PRIORITY</strong>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </>
                              );
                            })}
                          </tr>
                        </>
                      );
                    })}
                </table>
              </div>{" "}
            </>
          ) : (
            <></>
          )}

          {view === "Priority" ? (
            <>
              <Select
                options={skills?.skills}
                getOptionLabel={(s) => `${s.name}`}
                placeholder="Select skill..."
                onChange={(v) => {
                  const val = v as unknown as PpicSkill;

                  setSelectedSkill(val);
                }}
              />

              <hr className="border border-dark" />

              {selectedSkill ? (
                <>
                  <div
                    className="overflow-auto border border-dark shadow shadow-md"
                    style={{ height: "75vh" }}
                  >
                    <table
                      className="table table-sm table-hover"
                      style={{ borderCollapse: "separate" }}
                    >
                      <tr>
                        {["#", "Name", "Skill priority"].map((h) => (
                          <>
                            <th
                              className="bg-dark text-light"
                              style={{ position: "sticky", top: 0 }}
                            >
                              {h}
                            </th>
                          </>
                        ))}
                      </tr>

                      {(() => {
                        const data =
                          skills?.skills.find(
                            (sx) =>
                              `${sx.masterJavaBaseModel?.id}` ===
                              `${selectedSkill.masterJavaBaseModel?.id}`
                          )?.skillWorkers ?? [];

                        data.sort(
                          (a, b) =>
                            (b.skillLevel
                              ? ppicSkillWorker_SkillLevelToNumber(b.skillLevel)
                              : 0) -
                            (a.skillLevel
                              ? ppicSkillWorker_SkillLevelToNumber(a.skillLevel)
                              : 0)
                        );

                        return data;
                      })().map((w, i) => {
                        return (
                          <>
                            <tr>
                              <td className="border border-dark">{i + 1}</td>
                              <td className="border border-dark">
                                {getUserDetail(
                                  ctx?.extUsers.find(
                                    (u) => `${u.id}` === `${w.extUserId}`
                                  )
                                )}
                              </td>

                              <td className="border border-dark">
                                {w.skillLevel
                                  ? getSkillLevelIcon(w.skillLevel)
                                  : "none"}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default SkillCatalogPage;
