import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../App";
import {
  categoryPartNums,
  fetchMaterialRequests,
  intlFormat,
} from "../../../helpers";
import { MaterialRequest } from "../../../models/model";
import { Print } from "@material-ui/icons";

const MRPrintPage = () => {
  const ctx = useContext(AppContext);
  const [, refresh] = useState(false);
  const mrs = useRef([] as MaterialRequest[]);
  const findMR = useRef("");
  const loadingMr = useRef(false);
  const render = () => {
    refresh((n) => !n);
  };

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    fetchMRData();
  };

  const fetchMRData = async () => {
    loadingMr.current = true;
    render();

    const d = await fetchMaterialRequests({
      apiKey: ctx?.apiKey ?? "",
      from: new Date(
        new Date().getFullYear() - 100,
        new Date().getMonth(),
        new Date().getDate()
      ).toISOString(),
      to: new Date(
        new Date().getFullYear() + 100,
        new Date().getMonth(),
        new Date().getDate()
      ).toISOString(),
    });

    if (d) {
      mrs.current = d;
      render();
    }

    loadingMr.current = false;
    render();
  };
  return (
    <>
      <div>
        <div>
          <h4>MR Print {loadingMr.current ? `(Loading...)` : <></>}</h4>
        </div>
        <div>
          <hr className="border border-dark" />
        </div>
        <div>
          <input
            className="form-control form-control-sm"
            placeholder="MR ID search (i.e. 260507).."
            onBlur={(e) => {
              findMR.current = e.target.value;
              render();
            }}
          />
        </div>
        <div>
          <div
            className="overflow-auto"
            style={{ resize: "vertical", height: "75vh" }}
          >
            <table className="table table-sm">
              <tr>
                {[
                  "#",
                  "MR",
                  "Date",
                  "Category PN",
                  "Titipan?",
                  "Status",
                  "Items",
                  "Print",
                ].map((h) => {
                  return (
                    <>
                      <th
                        className="bg-dark text-light p-0 m-0"
                        style={{ position: "sticky", top: 0 }}
                      >
                        {h}
                      </th>
                    </>
                  );
                })}
              </tr>

              {mrs.current
                .filter((m) =>
                  findMR.current && findMR.current !== ""
                    ? `${m.id}` === `${findMR.current}`
                    : true
                )
                .filter((m) => m.status !== 0)
                .map((m, i) => {
                  return (
                    <>
                      <tr>
                        <td className="border border-dark p-0 m-0">{i + 1}</td>
                        <td className="border border-dark p-0 m-0">
                          MR/PPIC/{new Date(m.createdAt ?? "").getFullYear()}/
                          {m.id}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {intlFormat({
                            date: m.neededDate,
                            dateStyle: "long",
                          })}
                        </td>

                        <td className="border border-dark p-0 m-0">
                          {
                            categoryPartNums.find(
                              (c) => `${c.value}` === `${m.partNumCategory}`
                            )?.label
                          }
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <input
                            type="checkbox"
                            checked={m.titipan ? true : false}
                          />
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {(() => {
                            switch (m.status) {
                              case 0:
                                return `Not Approved`;
                              case 1:
                                return `Approved`;
                              case 2:
                                return `CutStock`;
                              default:
                                return ``;
                            }
                          })()}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          {m.materialRequestItems?.length ?? 0}
                        </td>
                        <td className="border border-dark p-0 m-0">
                          <a
                            href={`/#/materialrequestprint/${m?.id}`}
                            target="_blank"
                          >
                            <button className="btn btn-sm btn-primary d-flex">
                              <Print /> Print{" "}
                            </button>
                          </a>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default MRPrintPage;
