import {
  Box,
  Button,
  CircularProgress,
  createMuiTheme,
  Divider,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { blue, green, grey, orange, yellow } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeDateString } from "../../../helpers";
import { RequestStatus } from "../../../models/RequestStatus";
import { WorkOrderRev } from "../../../models/WorkOrderRev";

const orangeTheme = createMuiTheme({
  palette: { primary: { main: orange[500] } },
});

const WorkOrderRevPage = () => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [workOrders, setWorkOrders] = useState<WorkOrderRev[]>([]);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.NotAsked
  );
  const [roleNames, setRoleNames] = useState<string[]>([]);

  const currentMonthLastDate = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth() + 1,
    0
  );

  enum MoveDateType {
    Prev,
    Next,
  }

  const handleMoveDate = (type: MoveDateType) => {
    switch (type) {
      case MoveDateType.Next:
        console.log("next");
        setSelectedDate(
          new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1)
        );
        setRequestStatus(RequestStatus.NotAsked);
        return;

      case MoveDateType.Prev:
        console.log("prev");
        setSelectedDate(
          new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, 1)
        );
        setRequestStatus(RequestStatus.NotAsked);
        return;
    }
  };

  const dates = [...Array(currentMonthLastDate.getDate()).keys()].map(
    (date) => date + 1
  );
  const firstDay = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    1
  ).getDay();
  const pad = [...Array(firstDay).fill(0)];
  const paddedDate = [...pad, ...dates];
  const slicedDate = [
    [0, 7],
    [7, 14],
    [14, 21],
    [21, 28],
    [28, 35],
    [35, 42],
  ].map(([start, end]) => paddedDate.slice(start, end));

  useEffect(() => {
    if (requestStatus === RequestStatus.NotAsked) fetchWorkOrdersBetween();
  });

  useEffect(() => {
    try {
      const splitApiKey = localStorage.getItem("apiKey")?.split(".")[1];

      if (splitApiKey) {
        console.log("Roles: ", JSON.parse(atob(splitApiKey)).roles);
        setRoleNames(JSON.parse(atob(splitApiKey)).roles);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchWorkOrdersBetween = async () => {
    const y = `${selectedDate.getFullYear()}`;
    const m =
      selectedDate.getMonth() + 1 < 10
        ? `0${selectedDate.getMonth() + 1}`
        : `${selectedDate.getMonth() + 1}`;
    const d =
      currentMonthLastDate.getDate() < 10
        ? `0${currentMonthLastDate.getDate()}`
        : `${currentMonthLastDate.getDate()}`;

    const yearMonth = `${y}-${m}-`;
    const startDate = "01";
    const endDate = d;

    const start = `${yearMonth}${startDate}`;
    const end = `${yearMonth}${endDate}`;

    try {
      setRequestStatus(RequestStatus.Loading);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/workorderrevs/bydate/between?start=${start}&end=${end}`
      );

      if (response.status !== 200)
        throw `Fetching date between ${start} ${end} error.`;

      setWorkOrders(await response.json());

      setRequestStatus(RequestStatus.Success);
    } catch (e) {
      console.log(e);
      setRequestStatus(RequestStatus.Error);
    }
  };

  console.log("Current date:", selectedDate);

  return (
    <div>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h3>
            {selectedDate.toString().split(" ")[1]}{" "}
            {selectedDate.toString().split(" ")[3]}
          </h3>
          <div style={{ marginLeft: 15, marginRight: 15 }}>
            <Link
              to={`/monthlysummary?start=${makeDateString(
                new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 0)
              )}&end=${makeDateString(
                new Date(
                  selectedDate.getFullYear(),
                  selectedDate.getMonth() + 1,
                  1
                )
              )}`}
            >
              <Button variant="contained" color="primary">
                Monthly Summary
              </Button>
            </Link>
          </div>
          <div style={{ marginLeft: 15, marginRight: 15 }}>
            {requestStatus === RequestStatus.Loading ? (
              <CircularProgress disableShrink />
            ) : (
              <></>
            )}
          </div>
        </div>
        <h3>Days in current month: {currentMonthLastDate.getDate()}</h3>
        <div style={{ display: "flex" }}>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleMoveDate(MoveDateType.Prev)}
            >
              <ChevronLeftIcon /> Prev
            </Button>
          </div>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleMoveDate(MoveDateType.Next)}
            >
              Next <ChevronRightIcon />
            </Button>
          </div>
        </div>

        <Box my={1}>
          {requestStatus === RequestStatus.NotAsked ? (
            <CircularProgress disableShrink />
          ) : (
            <></>
          )}
        </Box>

        {/* <Box>
          {JSON.stringify(paddedDate)}
        </Box> */}
        <Box
          boxShadow={5}
          overflow="auto"
          style={{ height: "65vh", resize: "vertical" }}
        >
          <Table style={{ borderCollapse: "separate" }}>
            <TableHead>
              <TableRow style={{}}>
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                  (day) => (
                    <TableCell
                      style={{
                        border: 1,
                        borderStyle: "solid",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: yellow[200],
                      }}
                    >
                      <h4>{day}</h4>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedDate.map((weekArray) => {
                return (
                  <TableRow>
                    {weekArray.map((date) => {
                      const y = selectedDate.getFullYear();
                      const m =
                        selectedDate.getMonth() + 1 < 10
                          ? `0${selectedDate.getMonth() + 1}`
                          : `${selectedDate.getMonth() + 1}`;
                      const d = date < 10 ? `0${date}` : `${date}`;

                      const dateString = `${y}-${m}-${d}`;
                      // console.log(dateString)
                      const filteredWorkOrderJobNames = workOrders
                        .filter((workOrder) => workOrder.date === dateString)
                        .map((workOrder) => workOrder.jobName);

                      return (
                        <TableCell
                          style={{
                            border: 1,
                            borderStyle: "solid",
                            backgroundColor:
                              date === new Date().getDate() &&
                              selectedDate.getMonth() === new Date().getMonth()
                                ? "lightblue"
                                : "white",
                          }}
                        >
                          {date === 0 ? (
                            <></>
                          ) : (
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                            >
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                flexDirection="row"
                              >
                                <Box>
                                  <h3>{date}</h3>
                                </Box>
                                {roleNames.includes("Production Worker") ? (
                                  <></>
                                ) : (
                                  <Link to={`/workordersrev/${dateString}`}>
                                    <Fab size="small" color="primary">
                                      <AddIcon />
                                    </Fab>
                                  </Link>
                                )}
                              </Box>
                              <Box display="flex" flexDirection="column">
                                {filteredWorkOrderJobNames.length > 0 ? (
                                  <Link
                                    to={`/workordersrevactual/${dateString}`}
                                  >
                                    <Box style={{ color: `${blue[600]}` }}>
                                      <strong>Actual Input</strong>{" "}
                                    </Box>
                                  </Link>
                                ) : (
                                  <></>
                                )}

                                <Box
                                  style={{
                                    color:
                                      filteredWorkOrderJobNames?.length > 0
                                        ? `${green[700]}`
                                        : "red",
                                  }}
                                >
                                  <strong>
                                    Total WO(s):{" "}
                                    {filteredWorkOrderJobNames?.length ?? 0}
                                  </strong>
                                </Box>

                                <ol>
                                  {[...new Set(filteredWorkOrderJobNames)].map(
                                    (jobName) => (
                                      <li>
                                        <Box>
                                          <strong>
                                            {workOrders
                                              .filter(
                                                (workOrder) =>
                                                  workOrder.jobName ===
                                                    jobName &&
                                                  workOrder.date === dateString
                                              )
                                              .reduce(
                                                (acc, workOrder) => acc + 1,
                                                0
                                              )}{" "}
                                            WO(s):
                                          </strong>{" "}
                                          {jobName}
                                        </Box>
                                        <Divider
                                          style={{
                                            backgroundColor: `${grey[600]}`,
                                          }}
                                        />
                                      </li>
                                    )
                                  )}
                                </ol>
                              </Box>
                            </Box>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </div>
    </div>
  );
};

export default WorkOrderRevPage;
