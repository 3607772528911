import React, { ReactNode, useContext, useEffect } from "react";
import { HashRouter as Router, useLocation } from "react-router-dom";
import { components } from "react-select";
import { AppContext } from "../App";
import { fetchInitialData } from "../helpers";
import { RequestStatus } from "../models/RequestStatus";
import AdminSetupPage from "./AdminSetupPage/AdminSetupPage";
import BomDocumentPage from "./Initialization/BomDocument/BomDocument";
import BomDocumentForm from "./Initialization/BomDocument/BomDocumentForm";
import BomDocumentHierarchy from "./Initialization/BomDocument/BomDocumentHierarchy";
import BomDocumentHierarchyDetail from "./Initialization/BomDocument/BomDocumentHierarchyDetail";
import CustomerFormPage from "./Initialization/Customers/CustomerForm";
import CustomerPage from "./Initialization/Customers/Customers";
import DepartmentRulePage from "./Initialization/DepartmentRule/DepartmentRulePage";
import DepartmentTemplatePage from "./Initialization/DepartmentTemplate/DepartmentTemplatePage";
import FepDocumentPage from "./Initialization/FepDocument/FepDocument";
import FepDocumentSimplified from "./Initialization/FepDocument/FepDocumentSimplified";
import FepDocumentSimplifiedDetail from "./Initialization/FepDocument/FepDocumentSimplifiedDetail";
import FepMultiForm from "./Initialization/FepMulti/FepMultiForm";
import HolidayCalendarPage from "./Initialization/HolidayCalendar/HolidayCalendarPage";
import IntegrationDetailPage from "./Initialization/IntegrationList/IntegrationDetailPage";
import IntegrationListPage from "./Initialization/IntegrationList/IntegrationListPage";
import JobOverviewColPage from "./Initialization/JobOverviewCol/JobOverviewColPage";
import MachinePage from "./Initialization/MachineDetails/Machine";
import MachineFormPage from "./Initialization/MachineDetails/MachineFormPage";
import MachineProgramPage from "./Initialization/MachineProgram/MachineProgram";
import MachineProgramDetailPage from "./Initialization/MachineProgram/MachineProgramDetail";
import ManufacturerPage from "./Initialization/Manufacturer/Manufacturer";
import ManufacturerFormPage from "./Initialization/Manufacturer/ManufacturerForm";
import ItemPage from "./Initialization/MaterialMaster/Item";
import ItemForm from "./Initialization/MaterialMaster/ItemForm";
import MPAssistancePage from "./Initialization/MPAssitancePage/MPAssistancePage";
import PanelTypePage from "./Initialization/PanelType/PanelType";
import PanelTypeForm from "./Initialization/PanelType/PanelTypeForm";
import ProblemTypePage from "./Initialization/ProblemType/ProblemType";
import ProblemTypeForm from "./Initialization/ProblemType/ProblemTypeForm";
import RalPage from "./Initialization/Ral/Ral";
import RalForm from "./Initialization/Ral/RalForm";
import RcemPage from "./Initialization/Rcem/RcemPage";
import RequestItemPage from "./Initialization/RequestItem/RequestItem";
import SkillCatalogDetailPage from "./Initialization/SkillCatalog/SkillCatalogDetail";
import SkillCatalogPage from "./Initialization/SkillCatalog/SkillCatalogPage";
import UomPage from "./Initialization/Uom/Uom";
import UomForm from "./Initialization/Uom/UomForm";
import UserForm from "./Initialization/Users/UserForm";
import UserPage from "./Initialization/Users/Users";
import WarehousePage from "./Initialization/Warehouse/Warehouse";
import WarehouseForm from "./Initialization/Warehouse/WarehouseForm";
import WhatsappPage from "./Initialization/WhatsappPage/WhatsappPage";
import WiringFepDocumentPage from "./Initialization/WiringFepDocument/WiringFepDocument";
import WiringFepDocumentForm from "./Initialization/WiringFepDocument/WiringFepDocumentForm";
import WoProblemCatalogPage from "./Initialization/WoProblemCatalog/WoProblemCatalogPage";
import WorkingHourPage from "./Initialization/WorkingHour/WorkingHourPage";
import WorkOrderTemplateDetailPage from "./Initialization/WorkOrderTemplate/WorkOrderTemplateDetailPage";
import WorkOrderTemplatePage from "./Initialization/WorkOrderTemplate/WorkOrderTemplatePage";
import Login from "./Login/Login";
import MaterialRequestPage from "./Material/MaterialRequest/MaterialRequest";
import MaterialRequestAdd from "./Material/MaterialRequest/MaterialRequestAdd";
import MaterialRequestPrint from "./Material/MaterialRequest/MaterialRequestPrint";
import MaterialRequestSimpleView from "./Material/MaterialRequest/MaterialRequestSimpleView";
import ReserveListPage from "./Material/ReserveList/ReserveListPage";
import WarehouseSummaryPage from "./Material/WarehouseSummary/WarehouseSummary";
import MainAppBar from "./mini-components/MainAppBar/MainAppBar";
import Print from "./mini-components/Print/Print";
import RouteSwitcher from "./mini-components/RouteSwitcher/RouteSwitcher";
import CostReportPage from "./Operations/CostReport/CostReportPage";
import HandoverDetailPage from "./Operations/Handover/HandoverDetailPage";
import HandoverPage from "./Operations/Handover/HandoverPage";
import ManpowerPlanning from "./Operations/ManpowerPlanning/ManpowerPlanning";
import ManualWoPage from "./Operations/ManualWoPage/ManualWoPage";
import MonthlySummary from "./Operations/MonthlySummary/MonthlySummary";
import JobBomListSimulation from "./Operations/OutstandingJob/JobBomListSimulation";
import JobMaterialRequest from "./Operations/OutstandingJob/JobMaterialRequest";
import JobSummaryPage from "./Operations/OutstandingJob/JobSummary";
import JobSummary from "./Operations/OutstandingJob/JobSummary";
import JobSummaryDetail from "./Operations/OutstandingJob/JobSummaryDetail";
import JobPage from "./Operations/OutstandingJob/OutstandingJob";
import OutstandingJobForm from "./Operations/OutstandingJob/OutstandingJobForm";
import PartNumbersPage from "./Operations/PartNumbers/PartNumbersPage";
import PartNumbersV2Page from "./Operations/PartNumbers/PartNumbersV2Page";
import ProblemCatalogPage from "./Operations/ProblemCatalogPage/ProblemCatalogPage";
import ProgramTreeSettingsPage from "./Operations/ProgramTreeSettings/ProgramTreeSettingsPage";
import ProjectNew from "./Operations/ProjectNew/ProjectNew";
import ProjectNewDetail from "./Operations/ProjectNew/ProjectNewDetail";
import ResourcePlanningPage from "./Operations/ResourcePlanning/ResourcePlanning";
import WiringManpowerPage from "./Operations/WiringManpower/WiringManpower";
import WiringManpowerAssignmentPage from "./Operations/WiringManpowerAssignment/WiringManpowerAssignment";
import WorkOrder from "./Operations/WorkOrder/WorkOrder";
import WorkOrderActualInput from "./Operations/WorkOrder/WorkOrderActualInput";
import WorkOrderAdd from "./Operations/WorkOrder/WorkOrderAdd";
import WorkOrderRevPage from "./Operations/WorkOrderRev/WorkOrderRev";
import WorkOrderRevActual from "./Operations/WorkOrderRev/WorkOrderRevActual";
import WorkOrderRevAdd from "./Operations/WorkOrderRev/WorkOrderRevAdd";
import PanelTemplateDetail from "./PanelTemplatePage/PanelTemplateDetail";
import PanelTemplatePage from "./PanelTemplatePage/PanelTemplatePage";
import MechanicalProcessTypePage from "./ProcessTypePage/MechanicalProcessTypePage";
import ProjectDetailPage from "./ProjectsPage/ProjectDetailPage";
import ProjectsPage from "./ProjectsPage/ProjectsPage";
import PurchaseRequestPage from "./PurchaseRequestPage/PurchaseRequestPage";
import RcemKpiPage from "./RcemKpiPage/RcemKpiPage";
import AttendancePage from "./Reports/AttendancePage/AttendancePage";
import Dashboard from "./Reports/Dashboard/Dashboard";
import JobDeptProgressPage from "./Reports/JobDeptProgress/JobDeptProgressPage";
import JobOverviewPage from "./Reports/JobOverview/JobOverviewPage";
import MonitoringPage from "./Reports/Monitoring/Monitoring";
import MpsDetailed from "./Reports/MpsDetailed/MpsDetailed";
import MpsRev from "./Reports/MpsRev/MpsRev";
import MpsRevDetail from "./Reports/MpsRev/MpsRevDetail";
import PlanningReport from "./Reports/PlanningReport/PlanningReport";
import ProductMonitoringPage from "./Reports/ProductMonitoringPage";
import Remark from "./Reports/Remark/Remark";
import RemoteControlPage from "./Reports/RemoteControlPage/RemoteControlPage";
import SerialNumberReportPage from "./Reports/SerialNumberReportPage/SerialNumberReportPage";
import WiringReport from "./Reports/WiringReport/WiringReport";
import WorkOrderV2Detail from "./workOrderv2Page/WorkOrderV2Detail";
import WorkOrderV2Page from "./workOrderv2Page/WorkOrderV2Page";
import WoReportPage from "./Operations/ManualWoPage/WoReportPage";
import JobInfoPage from "./Operations/OutstandingJob/JobInfoPage";
import PNRequestPage from "./Initialization/PNRequestPage/PNRequestPage";
import JobSimpleMode from "./Operations/OutstandingJob/JobSimpleMode";
import JobDeptTemplatesPage from "./Reports/JobDeptTemplatesPage/JobDeptTemplatesPage";
import DNManualPage from "./Operations/DNManualPage/DNManualPage";
import PrintDNPage from "./Operations/DNManualPage/PrintDNPage";
import DeliveryPlanPage from "./DeliveryPlanPage/DeliveryPlanPage";
import ProjectDocumentIntegrationPage from "./ProjectDocumentIntegrationPage/ProjectDocumentIntegrationPage";
import ProgramTreeMultiplierPage from "./ProgramTreeMultiplierPage/ProgramTreeMultiplierPage";
import AutoMRWarehouseFilterPage from "./Material/AutoMRWarehouseFilterPage/AutoMRWarehouseFilterPage";
import InventoryTransactionReportPage from "./Material/InventoryTransactionReportPage/InventoryTransactionReportPage";
import MRReportPage from "./Material/MRReportPage/MRReportPage";
import NewJobNotificationPage from "./NewJobNotificationPage/NewJobNotificationPage";
import PurchaseRequestPrintPage from "./PurchaseRequestPage/PurchaseRequestPrintPage";
import DoublePNReportPage from "./Material/DoublePNReportPage/DoublePNReportPage";
import BomGroup from "./Initialization/BomDocument/BomGroup";
import BomGroupDetail from "./Initialization/BomDocument/BomGroupDetail";
import MRPrintPage from "./Material/MaterialRequest/MRPrint";
import DefaultMRAuthPage from "./Initialization/DefaultMRAuthPage/DefaultMRAuthPage";
import PrePRPage from "./PurchaseRequestPage/PrePRPage";
import HandoverPrintPage from "./Operations/Handover/HandoverPrintPage";
import JobGantt from "./Operations/OutstandingJob/JobGantt";
import KpiWOPage from "./Operations/KpiWOPage/KpiWOPage";
import ToolsPage from "./Initialization/Tools/ToolsPage";
import WoTemplateReportPage from "./Operations/WoTemplateReportPage/WoTemplateReportPage";
import ToolDetailPage from "./Initialization/Tools/ToolsDetailPage";
import NcrPage from "./Reports/NCRPage/NcrPage";

const MainComponent = () => {
  const state = useContext(AppContext);

  useEffect(() => {
    (async () => {
      if (state?.apiKey) {
        await fetchInitialData(state);

        state?.setLoggedIn(true);
        state?.setLoginRequestStatus(RequestStatus.Success);
      } else {
        state?.setLoginRequestStatus(RequestStatus.Success);
      }
    })();
  }, []);

  useEffect(() => {}, []);

  const routes: {
    linkFrom: string;
    component: ReactNode;
    bypassLogin?: boolean;
  }[] = [
    { linkFrom: "/", component: <Dashboard /> },
    { linkFrom: "/bomdocuments", component: <BomDocumentPage /> },
    { linkFrom: "/bomdocuments/:id", component: <BomDocumentForm /> },
    { linkFrom: "/fepdocuments", component: <FepDocumentPage /> },
    {
      linkFrom: "/fepdocumentssimplified",
      component: <FepDocumentSimplified />,
    },
    {
      linkFrom: "/fepdocumentssimplifieddetail/:id",
      component: <FepDocumentSimplifiedDetail />,
    },

    { linkFrom: "/processtypes", component: <MechanicalProcessTypePage /> },

    // { linkFrom: "/fepdocuments", component: <FepDocumentPage /> },
    { linkFrom: "/wiringfepdocuments", component: <WiringFepDocumentPage /> },
    {
      linkFrom: "/wiringfepdocuments/:id",
      component: <WiringFepDocumentForm />,
    },
    { linkFrom: "/fepdocuments/:id", component: <FepMultiForm /> },
    { linkFrom: "/workorders", component: <WorkOrder /> },
    { linkFrom: "/workordersrev", component: <WorkOrderRevPage /> },
    { linkFrom: "/workordersv2", component: <WorkOrderV2Page /> },
    { linkFrom: "/workordersv2/:date", component: <WorkOrderV2Detail /> },

    { linkFrom: "/workordersrev/:date", component: <WorkOrderRevAdd /> },
    { linkFrom: "/resourceplanning", component: <ResourcePlanningPage /> },

    {
      linkFrom: "/workordersrevactual/:date",
      component: <WorkOrderRevActual />,
    },
    {
      linkFrom: "/monthlysummary",
      component: <MonthlySummary />,
    },
    { linkFrom: "/workorders/add", component: <WorkOrderAdd /> },
    {
      linkFrom: "/workorders/actualinput",
      component: <WorkOrderActualInput />,
    },
    {
      linkFrom: "/adminsetup",
      component: <AdminSetupPage />,
    },

    { linkFrom: "/machines", component: <MachinePage /> },
    { linkFrom: "/machines/:id", component: <MachineFormPage /> },
    { linkFrom: "/individualentities", component: <CustomerPage /> },
    { linkFrom: "/individualentities/:id", component: <CustomerFormPage /> },
    { linkFrom: "/manufacturers", component: <ManufacturerPage /> },
    { linkFrom: "/manufacturers/:id", component: <ManufacturerFormPage /> },
    { linkFrom: "/rals", component: <RalPage /> },
    { linkFrom: "/rals/:id", component: <RalForm /> },
    { linkFrom: "/items", component: <ItemPage /> },
    { linkFrom: "/requestitems", component: <RequestItemPage /> },
    { linkFrom: "/items/:id", component: <ItemForm /> },
    { linkFrom: "/users", component: <UserPage /> },
    { linkFrom: "/users/:id", component: <UserForm /> },
    { linkFrom: "/mpsrev", component: <MpsRev /> },
    { linkFrom: "/mpsdetailed", component: <MpsDetailed /> },
    { linkFrom: "/mpsrev/:id", component: <MpsRevDetail /> },
    { linkFrom: "/warehouses", component: <WarehousePage /> },
    { linkFrom: "/warehouses/:id", component: <WarehouseForm /> },
    { linkFrom: "/warehousesummary", component: <WarehouseSummaryPage /> },
    { linkFrom: "/jobs", component: <JobPage /> },
    { linkFrom: "/jobsummary", component: <JobSummaryPage /> },
    { linkFrom: "/jobsummary/:id", component: <JobSummaryDetail /> },
    { linkFrom: "/kpi-wo", component: <KpiWOPage /> },

    {
      linkFrom: "/jobs/:id/bomlistsimulation",
      component: <JobBomListSimulation />,
    },
    {
      linkFrom: "/jobs/:id/gantt",
      component: <JobGantt />,
    },

    {
      linkFrom: "/jobs/:id/jobinfo",
      component: <JobInfoPage />,
    },

    {
      linkFrom: "/jobs/:id/materialrequests",
      component: <JobMaterialRequest />,
    },

    { linkFrom: "/jobs/:id/remarks", component: <Remark /> },
    { linkFrom: "/jobs/:id", component: <OutstandingJobForm /> },
    { linkFrom: "/uoms", component: <UomPage /> },
    { linkFrom: "/uoms/:id", component: <UomForm /> },
    { linkFrom: "/problemtypes", component: <ProblemTypePage /> },
    { linkFrom: "/projects", component: <ProjectsPage /> },
    { linkFrom: "/projects/:id", component: <ProjectDetailPage /> },

    { linkFrom: "/problemtypes/:id", component: <ProblemTypeForm /> },
    { linkFrom: "/manpowerplanning", component: <ManpowerPlanning /> },
    { linkFrom: "/wiringmanpower", component: <WiringManpowerPage /> },
    {
      linkFrom: "/wiringmanpowerassignment",
      component: <WiringManpowerAssignmentPage />,
    },
    { linkFrom: "/paneltypes", component: <PanelTypePage /> },
    { linkFrom: "/paneltemplates", component: <PanelTemplatePage /> },
    { linkFrom: "/paneltemplates/:id", component: <PanelTemplateDetail /> },

    { linkFrom: "/paneltypes/:id", component: <PanelTypeForm /> },
    { linkFrom: "/wiringreport", component: <WiringReport /> },
    { linkFrom: "/materialrequests", component: <MaterialRequestPage /> },
    {
      linkFrom: "/materialrequestprint/:id",
      component: <MaterialRequestPrint />,
    },

    {
      linkFrom: "/materialrequestssimpleview",
      component: <MaterialRequestSimpleView />,
    },
    { linkFrom: "/purchaserequests", component: <PurchaseRequestPage /> },
    {
      linkFrom: "/purchaserequests/print/:id",
      component: <PurchaseRequestPrintPage />,
    },

    { linkFrom: "/materialrequests/:id", component: <MaterialRequestAdd /> },
    { linkFrom: "/print/:date", component: <Print /> },
    { linkFrom: "/projectsnew", component: <ProjectNew /> },
    { linkFrom: "/projectsnew/:id", component: <ProjectNewDetail /> },
    { linkFrom: "/bomdocumentshierarchy", component: <BomDocumentHierarchy /> },
    { linkFrom: "/bomgroups", component: <BomGroup /> },
    { linkFrom: "/bomgroups/:id", component: <BomGroupDetail /> },

    { linkFrom: "/pnrequest", component: <PNRequestPage /> },

    {
      linkFrom: "/bomdocumentshierarchy/:id",
      component: <BomDocumentHierarchyDetail />,
    },
    {
      linkFrom: "/jobs/:id/simplemode",
      component: <JobSimpleMode />,
    },

    {
      linkFrom: "/departmenttemplate",
      component: <DepartmentTemplatePage />,
    },
    {
      linkFrom: "/jobdeptprogress",
      component: <JobDeptProgressPage />,
    },
    {
      linkFrom: "/integrationlist",
      component: <IntegrationListPage />,
    },
    {
      linkFrom: "/integrationlist/:id",
      component: <IntegrationDetailPage />,
    },
    {
      linkFrom: "/machineprograms",
      component: <MachineProgramPage />,
    },
    {
      linkFrom: "/machineprograms/:id",
      component: <MachineProgramDetailPage />,
    },
    {
      linkFrom: "/rcem",
      component: <RcemPage />,
    },
    {
      linkFrom: "/partnumbers",
      component: <PartNumbersPage />,
    },
    {
      linkFrom: "/partnumbersv2",
      component: <PartNumbersV2Page />,
    },

    {
      linkFrom: "/joboverview",
      component: <JobOverviewPage />,
    },
    {
      linkFrom: "/skillcatalog",
      component: <SkillCatalogPage />,
    },
    {
      linkFrom: "/skillcatalog/:id",
      component: <SkillCatalogDetailPage />,
    },
    {
      linkFrom: "/departmentrule",
      component: <DepartmentRulePage />,
    },
    {
      linkFrom: "/workinghour",
      component: <WorkingHourPage />,
    },
    {
      linkFrom: "/manualwo",
      component: <ManualWoPage />,
    },
    {
      linkFrom: "/woreportpage",
      component: <WoReportPage />,
    },

    {
      linkFrom: "/reservelist",
      component: <ReserveListPage />,
    },
    {
      linkFrom: "/joboverviewcol",
      component: <JobOverviewColPage />,
    },
    {
      linkFrom: "/problemcatalog",
      component: <ProblemCatalogPage />,
    },
    {
      linkFrom: "/handover",
      component: <HandoverPage />,
    },
    {
      linkFrom: "/handover/:id",
      component: <HandoverDetailPage />,
    },
    {
      linkFrom: "/handover/:id",
      component: <HandoverDetailPage />,
    },
    {
      linkFrom: "/programtreesettings",
      component: <ProgramTreeSettingsPage />,
    },
    {
      linkFrom: "/monitoring",
      component: <MonitoringPage />,
    },
    {
      linkFrom: "/workordertemplates",
      component: <WorkOrderTemplatePage />,
    },
    {
      linkFrom: "/workordertemplates/:id",
      component: <WorkOrderTemplateDetailPage />,
    },
    {
      linkFrom: "/costreport/:id",
      component: <CostReportPage />,
    },
    {
      linkFrom: "/woproblemcatalog",
      component: <WoProblemCatalogPage />,
    },
    {
      linkFrom: "/attendance",
      component: <AttendancePage />,
    },
    {
      linkFrom: "/productmonitoring",
      component: <ProductMonitoringPage />,
    },
    {
      linkFrom: "/remotecontrol",
      component: <RemoteControlPage />,
    },
    {
      linkFrom: "/holidaycalendar",
      component: <HolidayCalendarPage />,
    },
    {
      linkFrom: "/whatsapp",
      component: <WhatsappPage />,
    },
    {
      linkFrom: "/mp-assistance",
      component: <MPAssistancePage />,
    },
    {
      linkFrom: "/rcem-kpi",
      component: <RcemKpiPage />,
    },
    {
      linkFrom: "/serialnumbers",
      component: <SerialNumberReportPage />,
    },
    {
      linkFrom: "/planningreport",
      component: <PlanningReport />,
    },
    {
      linkFrom: "/jobdepttemplates",
      component: <JobDeptTemplatesPage />,
      bypassLogin: true,
    },
    {
      linkFrom: "/dnmanual",
      component: <DNManualPage />,
    },
    {
      linkFrom: "/printdn/:id",
      component: <PrintDNPage />,
    },
    {
      linkFrom: "/deliveryplan",
      component: <DeliveryPlanPage />,
    },
    {
      linkFrom: "/projectdocumentintegrations",
      component: <ProjectDocumentIntegrationPage />,
    },
    {
      linkFrom: "/pt-multiplier",
      component: <ProgramTreeMultiplierPage />,
    },
    {
      linkFrom: "/automr-warehouse-filter",
      component: <AutoMRWarehouseFilterPage />,
    },
    {
      linkFrom: "/inventory-transaction-report",
      component: <InventoryTransactionReportPage />,
    },
    {
      linkFrom: "/mrreport",
      component: <MRReportPage />,
    },
    {
      linkFrom: "/new-job-notification",
      component: <NewJobNotificationPage />,
    },
    {
      linkFrom: "/double-pn-report",
      component: <DoublePNReportPage />,
    },
    {
      linkFrom: "/materialrequestsprint",
      component: <MRPrintPage />,
    },
    {
      linkFrom: "/default-mr-auth",
      component: <DefaultMRAuthPage />,
    },
    {
      linkFrom: "/preprs/:id",
      component: <PrePRPage />,
    },
    {
      linkFrom: "/handover/:id/print",
      component: <HandoverPrintPage />,
    },
    {
      linkFrom: "/tools",
      component: <ToolsPage />,
    },
    {
      linkFrom: "/wotemplatereportpage",
      component: <WoTemplateReportPage />,
    },
    {
      linkFrom: "/tooldetail/:id",
      component: <ToolDetailPage />,
    },
    {
      linkFrom: "/ncr",
      component: <NcrPage />,
    },
  ];

  const bypassLoginRoutes = [
    "#/jobdepttemplates",
    "#/printdn",
    "#/purchaserequests/print",
  ];

  useEffect(() => {
    const foundBypassLoginRoutes = bypassLoginRoutes.find((r) =>
      window.location.hash.startsWith(r)
    );

    if (foundBypassLoginRoutes) {
      state?.setAppBarVisible(false);
      state?.setLoggedIn(true);
    }
  }, []);

  return state?.loggedIn ? (
    <>
      <Router>
        {state.appBarVisible ? <MainAppBar /> : <></>}
        <RouteSwitcher routes={routes} />
      </Router>
    </>
  ) : (
    <Login />
  );
};

export default MainComponent;
